import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { useTranslation } from "react-i18next";
import { Button } from "@material-ui/core";
import { useDispatch } from 'react-redux';

import useStyles from "./css";

import NoFilesIcon from "../../../assets/icons/NoFilesIcon";
import PatientImportClinicalFileModal from "./../PatientImportClinicalFileModal";
import GridList, { OnChangeEvent } from "../../common/grid/GridList";
import { TableColumn } from "../../common/grid/table-column";
import { CustomerService } from "../../../api/customer-service";
import { CustomerClinicalFile } from "../../../models/customer-clinical-file";
import { useAppSelector } from "../../../redux/hooks";
import { DownloadFileCloud, ImageIcon, PDFIcon, TrashIcon, WordIcon } from "../../../assets/icons";
import ConfirmDeleteModal from "../../common/ConfirmDeleteModal";
import { alertsActions } from "../../../redux/alerts-slice";
import { navbarActions } from "../../../redux/navbar-slice";

const CustomerClinicalFiles = () => {
  const { t } = useTranslation(["general"]);
  const classes = useStyles();
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const { customerId } = useParams<any>();
  const dispatch = useDispatch();
  const { enqueueAlert } = alertsActions;
  
  const [importModalIsOpen, setImportModalIsOpen] = useState<boolean>(false);
  const [deleteFileModalIsOpen, setDeleteFileModalIsOpen] = useState<boolean>(false);
  const [deletableFile, setDeletableFile] = useState<CustomerClinicalFile>();
  const [customerClinicalFiles, setCustomerClinicalFiles] = useState<CustomerClinicalFile[]>([]);
  const [page, setPage] = useState<number>(1);
  const [total, setTotal] = useState<number>(1);
  const pageSize: number = 20;
  
  useEffect(() => {
    getData();
  }, [page]);
  
  const getData = () => {
    (async () => {
      dispatch(navbarActions.setShowLoader(true));
      let data = await CustomerService.getPatientClinicalFiles(
        page,
        pageSize,
        customerId
      );
      dispatch(navbarActions.setShowLoader(false));
      setCustomerClinicalFiles(data.entity!);
      setTotal(data.total!)
    })();
  };
  
  let handleUploadFilesButton = () => {
    setImportModalIsOpen(true);
  };
  
  const getUploadDate = (startDate: Date): string => {
    const date = new Date(startDate);
    
    const day = date.toLocaleString(localeApp, {
      day: "2-digit",
    });
    
    const month = date.toLocaleString(localeApp, {
      month: "long",
    }).toLowerCase();
    
    const year = date.toLocaleString(localeApp, {
      year: "numeric",
    });
    
    const time = date.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    
    if (localeApp === "es")
      return `${day}/${month}/${year} \n ${time} hrs`;
    else
      return `${month}/${day}/${year} \n ${time} hrs`;
  };
  
  const handleOnChangeGrid = (event: OnChangeEvent) => {
    setPage(event.page);
  };
  const getExtenstionImage = (name: string) => {
    if (name) {
      let fileExtension = name.split(".").pop();
      switch (fileExtension) {
        case "pdf":
          return <div style={{ padding: 8 }}><PDFIcon width={16} style={{ color: "#5C6477" }}></PDFIcon></div>
        case "docx":
          return <WordIcon width={32} style={{ color: "#5C6477" }}></WordIcon>
        case "jpg":
          return <ImageIcon width={32} style={{ color: "#5C6477" }}></ImageIcon>
        case "png":
          return <ImageIcon width={32} style={{ color: "#5C6477" }}></ImageIcon>
        default:
          return <PDFIcon width={16} style={{ color: "#5C6477" }}></PDFIcon>
      }
    }
    
  };
  const handleDeleteFile = (file: CustomerClinicalFile) => {
    setDeletableFile(file);
    setDeleteFileModalIsOpen(true);
  };
  
  const handleOnRemoveFileConfirm = () => {
    (async () => {
      const data = await CustomerService.deletePatientClinicalFile(deletableFile?.s3Key!);
      if (data) {
        getData();
        dispatch(
          enqueueAlert({
            type: "Success",
            title: t("File successfully deleted"),
            description: deletableFile?.fileName,
          }));
      }
    })();
    setDeleteFileModalIsOpen(false);
  };
  
  const handleDownloadFile = (s3Key: string) => {
    (async () => {
      const data = await CustomerService.getPatientClinicalFileUrl(s3Key);
      if (data.entity) {
        let fileUrl = data.entity.toString();
        const link = document.createElement("a");
        link.setAttribute("href", fileUrl);
        link.setAttribute("target", "_blank");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
      }
    })();
  };
  
  const columns: TableColumn[] = [
    {
      id: "fileName",
      label: t("NAME"),
      sorting: false,
      completeObject: true,
      width: 130,
      component: (item: any) => <>
        <div className={classes.fileNameContainer}>
          <div style={{ fontWeight: 700 }}>
            {getExtenstionImage(item.fileName)}
          </div>
          <div style={{ wordBreak: "break-word" }}>
            {t(item.fileName)}
          </div>
        </div>
      </>,
    },
    {
      id: "uploadedBy",
      label: t("UPLOADED"),
      width: 80,
      completeObject: true,
      component: (item: any) => <>
        <div style={{ marginLeft: 3 }}>
          {item.uploadedBy}
        </div>
      </>,
    },
    {
      id: "created",
      label: t("DATE AND TIME"),
      width: 20,
      completeObject: true,
      component: (item: any) => <>
        <div style={{ whiteSpace: "pre-line", marginLeft: 3 }}>
          {getUploadDate(item.created)}
        </div>
      </>,
    },
    {
      id: "comments",
      label: t("COMMENTS"),
      width: 130,
      completeObject: true,
      component: (item: any) => <>
        {item.comments
          ? item.comments
          : <div style={{ color: "#919CA5", marginLeft: 3 }}>
            {t("No comments")}
          </div>}
      </>,
    },
    {
      id: "s3Key",
      label: t("ACTIONS"),
      width: 60,
      completeObject: true,
      component: (item: any) => <>
        <div className={classes.actionsContainer}>
          <IconButton
            onClick={() => handleDownloadFile(item.s3Key)}>
            <DownloadFileCloud/>
          </IconButton>
          <IconButton
            onClick={() => handleDeleteFile(item)}>
            <TrashIcon style={{ color: "#5C6477" }}/>
          </IconButton>
        </div>
      </>,
    },
  ];
  
  const RemoveFileModalContent: React.FC = () => {
    return (<div className={classes.removeModalContentContainer}>
      <Typography className={classes.closeModalContentHeaderText}>
        {t("Are you sure to delete the file?")}
      </Typography>
      <div className={classes.separator}/>
      <Typography className={classes.closeModalContentText}>
        {deletableFile?.fileName}
      </Typography>
    </div>);
  };
  
  return (
    <div>
      <Button
        className={`${classes.addButton}`}
        onClick={handleUploadFilesButton}
      >
        {t("Upload file")}
      </Button>
      
      
      {customerClinicalFiles.length === 0 &&
          <div className={classes.noFilesIcon}>
              <NoFilesIcon></NoFilesIcon>
              <Typography className={classes.noFilesCaption}>
                {t("No files")}
              </Typography>
          </div>}
      
      {customerClinicalFiles.length > 0 &&
          <div className={classes.grid}>
              <GridList
                  columns={columns}
                  items={customerClinicalFiles}
                  height={460}
                  totalItems={total}
                  pageSize={pageSize}
                  showPaginationAlways={false}
                  onChange={handleOnChangeGrid}
                  classRow={classes.classRow}
              />
          </div>
      }
      
      <PatientImportClinicalFileModal
        open={importModalIsOpen}
        refreshFiles={getData}
        onClose={() => setImportModalIsOpen(false)}
      ></PatientImportClinicalFileModal>
      <ConfirmDeleteModal
        textButtonConfirm={t("Delete")}
        classModal={classes.closeConfirmationModal}
        open={deleteFileModalIsOpen}
        item={deletableFile}
        componentInfo={<RemoveFileModalContent/>}
        onClose={() => {
          setDeleteFileModalIsOpen(false)
          setDeletableFile(undefined);
        }}
        onDelete={handleOnRemoveFileConfirm}
      />
    </div>
  );
};

export default CustomerClinicalFiles;
