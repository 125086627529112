import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core//Link';

import { Url } from "./../../../constants/url";
import AppointmentStepOne from "./../AppointmentStepOne";
import AppointmentStepTwo from "./../AppointmentStepTwo";
import { CreateAppointmentModel } from "../../../models/create-appointment-model";
import AppointmentCustomerSection from "./../AppointmentCustomerSection";
import { AppointmentService } from "../../../api/appointment-service";
import { useAppDispatch, useAppSelector } from "./../../../redux/hooks";
import { alertsActions } from "./../../../redux/alerts-slice";
import { ApiClient } from "../../../api/api-client";
import AppointmentScheduleBusyModal from "../AppointmentScheduleBusyModal";
import useStyles from "./css";
import BusyScheduleQuestionModal from "../../common/BusyScheduleQuestion";
import { AppointmentBusyModalDataModel } from "../../../models/appointment-busy-modal-data-model";
import { AppointmentScheduleBusyReason } from "../../../models/enums/appointment-schedule-busy-reason";
import { AppointmentBlocker } from "../../../models/appointment-blocker";
import AppointmentWorkAreaBusyModal from "../../common/AppointmentWorkAreaBusyModal";
import { navbarActions } from "../../../redux/navbar-slice";
import { applicationInterfaceActions } from "../../../redux/application-interface-slice";
import OutsideBusinessHoursModal from "../../common/OutsideBusinessHoursModal";
import AppointmentOutsideScheduleModal from "../AppointmentOutsideScheduleModal";
import useCurrencyValueTemplate from "../../../hooks/useCurrencyValueTemplate";
import { selectAccountSettings } from "../../../redux/store";
import TimeIsBusyAnotherAppointmentCanNotContinueModal
  from "../../common/TimeIsBusyAnotherAppointmentCanNotContinueModal";

const AppointmentNew = () => {

  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;
  const { t } = useTranslation(["general"]);
  const [appointment, setAppointment] = useState<CreateAppointmentModel>(
    new CreateAppointmentModel()
  );
  const [step1Valid, setStep1Valid] = useState<boolean>(false);
  const [step2Valid, setStep2Valid] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [isClicked, setIsClicked] = useState<boolean>(false);
  const [refreshAvailableHours, setRefreshAvailableHours] = useState<number>(0);
  const [openExclamationScheduleBusyModal, setOpenExclamationScheduleBusyModal] = useState<boolean>(false);
  const [openOutsideScheduleModal, setOpenOutsideScheduleModal] = useState<boolean>(false);
  const [openQuestionScheduleBusyModal, setOpenQuestionScheduleBusyModal] = useState<boolean>(false);
  const [openOutsideBusinessHoursModal, setOpenOutsideBusinessHoursModal] = useState<boolean>(false);
  const [openAppointmentWorkAreaBusyModal, setOpenAppointmentWorkAreaBusyModal] = useState<boolean>(false);
  const [openTimeIsBusyAnotherAppointmentCanNotContinueModal, setOpenTimeIsBusyAnotherAppointmentCanNotContinueModal] = useState<boolean>(false);
  const [appointmentBusyModalData, setAppointmentBusyModalData] = useState<AppointmentBusyModalDataModel>();
  const [recurringCount, setRecurringCount] = useState<number>();
  const [isRecurring, setIsRecurring] = useState<boolean>();
  const { customerId } = useParams<any>();
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const accountSettings = useAppSelector(selectAccountSettings);
  const getTemplatedCurrencyValue = useCurrencyValueTemplate(accountSettings.currencyTemplate);
  const userInfo = useAppSelector(state => state.userInfo);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });

    dispatch(applicationInterfaceActions.updateTitle(t("New appointment")));
  }, []);

  useEffect(() => {
    if (isClicked) {
      dispatch(navbarActions.setShowLoader(true));
      AppointmentService.create(appointment).then((data) => {
        dispatch(navbarActions.setShowLoader(false));
        if (data.isScheduleBusy) {
          setAppointmentBusyData(data.appointmentBlocker, data.appointmentScheduleBusyReason);
          setRefreshAvailableHours(refreshAvailableHours + 1);
          setStep2Valid(false);
          setIsClicked(false);
          return;
        } else {
          dispatch(applicationInterfaceActions.updateTitle(t("Appointments")));
          dispatch(
            enqueueAlert({
              type: "Success",
              title: t("Appointment created"),
              description: `${appointment.firstName} ${
                appointment.lastName
              } - ${formatDate(appointment.startDate)}`,
            })
          );
          history.push(`${Url.Appointments.Main}`);
        }
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClicked]);

  function setAppointmentBusyData(appointmentBlocker: AppointmentBlocker, appointmentScheduleBusyReason: AppointmentScheduleBusyReason) {
    setAppointmentBusyModalData(
      new AppointmentBusyModalDataModel({
        customerName: appointmentBlocker?.customerName,
        serviceName: appointmentBlocker?.serviceName,
        workArea: appointmentBlocker?.workArea,
        startData: appointmentBlocker?.startData,
        appointmentDuration: appointmentBlocker?.appointmentDuration,
        appointmentScheduleBusyReason: appointmentScheduleBusyReason,
      }));
    openScheduleBusyModal(appointmentScheduleBusyReason);
  }

  function openScheduleBusyModal(data: AppointmentScheduleBusyReason) {
    switch (data) {
      case AppointmentScheduleBusyReason.TimeIsBusyAnotherAppointment:
        setOpenQuestionScheduleBusyModal(true);
        break;
      case AppointmentScheduleBusyReason.BlockedScheduleTime:
        setOpenExclamationScheduleBusyModal(true);
        break;
      case AppointmentScheduleBusyReason.OutsideUserScheduleTime:
        setOpenOutsideScheduleModal(true);
        break;
      case AppointmentScheduleBusyReason.OutsideUserScheduleTimeAndOutsideBusinessHours:
        setOpenOutsideScheduleModal(true);
        break;
      case AppointmentScheduleBusyReason.WorkAreaIsBusyAnotherAppointment:
        setOpenAppointmentWorkAreaBusyModal(true);
        break;
      case AppointmentScheduleBusyReason.OutsideBusinessHours:
        setOpenOutsideBusinessHoursModal(true);
        break;
      case AppointmentScheduleBusyReason.TimeIsBusyAnotherAppointmentCanNotContinue:
        setOpenTimeIsBusyAnotherAppointmentCanNotContinueModal(true);
        break;
      default:
        setOpenExclamationScheduleBusyModal(true);
        break;
    }
  }
  
  const totalServicesPrice = appointment.services.reduce((total, service) => total + service.price, 0); 

  const formatDate = (date: Date | null): string => {
    const day = date?.toLocaleString(localeApp, {
      day: "2-digit",
    });
    const month = date?.toLocaleString(localeApp, {
      month: "long",
    });
    const year = date?.toLocaleString(localeApp, {
      year: "numeric",
    });

    return `${day}/${month}/${year}`;
  };

  const handleCreateButton = (event: any) => {
    event.preventDefault();
    setIsClicked(true);
  };

  const handleContinueScheduleBusyModal = () => {
    const newAppointment = {
      ...appointment,
      isIgnoreScheduleBusy: true
    };
    setAppointment(newAppointment);
    setIsClicked(true);
  };
  
  function onRecurringChange(recurringCount: number, isRecurring: boolean) {
    setIsRecurring(isRecurring);
    setRecurringCount(recurringCount);
  }

  return (<>
    <div style={{ paddingBottom: 75 }}>
      <div className={classes.breadcrumbContainer}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
          <Link
            underline="hover"
            color="inherit"
            href="/appointments"
          >
            {t("Appointments")}
          </Link>
          <div className={classes.boldFont}>
            <Typography> {t("New appointment")}</Typography>
          </div>
        </Breadcrumbs>
      </div>
      <div className={classes.root}>
        <AppointmentCustomerSection
          appointment={appointment}
          customerId={customerId}
          onChange={(eventAppointment) => {
            setAppointment(eventAppointment);
          }}
        />
        <div className={classes.appointmentSection}>
          <AppointmentStepOne
            appointment={appointment}
            onChange={(appointment: any, isValid: any) => {
              setAppointment(appointment);
              setStep1Valid(isValid);
            }}
          />
          <AppointmentStepTwo
            appointment={appointment}
            step1Valid={step1Valid}
            isEdition={false}
            refreshData={refreshAvailableHours}
            onChange={(appointment: any, isValid: any) => {
              if (isValid) {
                setAppointment(appointment);
              }

              setStep2Valid(isValid);
            }}
            onRecurringChange={onRecurringChange}
          />
        </div>
      </div>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={cancelModal}>
          <div className={classes.modalCancelPaper}>
            <div>
              <div
                className={`${classes.modalCenter} ${classes.modalTitleSpace}`}
              >
                <Typography className={classes.modalTitleText}>
                  {t("Cancel new appointment registration?")}
                </Typography>
              </div>
              <div className={classes.modalActions}>
                <Button
                  className={`${classes.button} ${classes.goBackCancel}`}
                  onClick={() => setCancelModal(false)}
                >
                  {t("Go back")}
                </Button>
                <Button
                  className={`${classes.button} ${classes.cancelConfirmation}`}
                  onClick={() => {
                    setCancelModal(false);
                    history.push(`${Url.Appointments.Main}`);
                  }}
                >
                  {t("Yes, cancel it")}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>

      <BusyScheduleQuestionModal
        open={openQuestionScheduleBusyModal}
        appointment={appointment}
        appointmentBusyModalData={appointmentBusyModalData}
        onClose={() => setOpenQuestionScheduleBusyModal(false)}
        onContinue={handleContinueScheduleBusyModal}
      />
      <OutsideBusinessHoursModal
        open={openOutsideBusinessHoursModal}
        onClose={() => setOpenOutsideBusinessHoursModal(false)}
        onCancel={() => setOpenOutsideBusinessHoursModal(false)}
        onConfirm={handleContinueScheduleBusyModal}
      />
      <AppointmentWorkAreaBusyModal
        open={openAppointmentWorkAreaBusyModal}
        appointment={appointment}
        appointmentBusyModalData={appointmentBusyModalData}
        onClose={() => setOpenAppointmentWorkAreaBusyModal(false)}
      />
      <AppointmentScheduleBusyModal
        open={openExclamationScheduleBusyModal}
        appointment={appointment}
        appointmentBusyModalData={appointmentBusyModalData}
        onClose={() => setOpenExclamationScheduleBusyModal(false)}
      />
      <AppointmentOutsideScheduleModal
        open={openOutsideScheduleModal}
        appointment={appointment}
        appointmentBusyModalData={appointmentBusyModalData}
        onClose={() => setOpenOutsideScheduleModal(false)}
        onContinue={handleContinueScheduleBusyModal}
      />
      <TimeIsBusyAnotherAppointmentCanNotContinueModal
        open={openTimeIsBusyAnotherAppointmentCanNotContinueModal}
        onClose={() => setOpenTimeIsBusyAnotherAppointmentCanNotContinueModal(false)}
      />
      <div className={classes.actions}>
        <div >
          {(isRecurring && userInfo.showPrices) && <div>
            <div className={classes.totalPriceBoldText}>
              {t("Total for the {{count}} appointments: {{total}}", {
                count: recurringCount ?? 0,
                total: getTemplatedCurrencyValue((totalServicesPrice * recurringCount!) ?? 0),
              })}
            </div>
            <div className={classes.totalPriceText}>
              {t("Total per appointment: {{price}}", {
                price: getTemplatedCurrencyValue(recurringCount && recurringCount > 0 ? totalServicesPrice : 0),
              })}
            </div>
          </div>}
        </div>
        <div>
          <Button
            className={`${classes.button} ${classes.goBack}`}
            onClick={() => {
              if (
                appointment.services &&
                appointment.services.length &&
                appointment.customerId
              ) {
                setCancelModal(true);
              } else {
                history.push(`${Url.Appointments.Main}`);
              }
            }}
          >
            {t("Go back")}
          </Button>
          <Button
            className={`${classes.button} ${classes.newAppointment}`}
            disabled={
              (!appointment.customerId || !step1Valid || !step2Valid) &&
              !isClicked
            }
            onClick={handleCreateButton}
          >
            {t("Create appointment")}
          </Button>
        </div>
      </div>
      
    </div>
  </>);
};

export default AppointmentNew;
