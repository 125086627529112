import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function CopyIcon(props: any) {
    const { style, viewBox, color, border, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 25, height: 25, ...style }}
            viewBox={viewBox || "0 0 25 25"}
        >
            <path
              d="M18.2197 5.45703H10.3447C9.60645 5.45703 9.03223 6.05859 9.03223 6.76953V14.6445C9.03223 15.3828 9.60645 15.957 10.3447 15.957H18.2197C18.9307 15.957 19.5322 15.3828 19.5322 14.6445V6.76953C19.5322 6.05859 18.9307 5.45703 18.2197 5.45703ZM10.3447 16.832C9.11426 16.832 8.15723 15.875 8.15723 14.6445V8.95703H6.84473C6.10645 8.95703 5.53223 9.55859 5.53223 10.2695V18.1445C5.53223 18.8828 6.10645 19.457 6.84473 19.457H14.7197C15.4307 19.457 16.0322 18.8828 16.0322 18.1445V16.832H10.3447Z"
              fill={color ?? style?.color ?? "white"}
              stroke={border ?? "white"}
            />
        </SvgIcon>
    );
}