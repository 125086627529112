import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";

import InputWithLabel from "../../../common/InputWithLabel";
import useStyles from "./css";
import { MassiveSettingsGeneralSettingsProps } from "./props";
import ChipSelect from "../../../common/ChipSelect";
import IconTooltip from "../../../common/IconTooltip";
import { InfoIcon } from "../../../../assets/icons";
import RadioWithText from "../../../common/RadioWithText";
import TerminationDate from "../TerminationDate";
import TimeOfSending from "../TimeOfSending";
import SelectKey from "../../../common/SelectKey";
import DateInput from "../../../common/DateInput";
import SelectMonthDayDate from "../../../common/SelectMonthDayDate";
import Switch from "../../../common/Switch";
import useCustomerTerminology from "../../../../hooks/useCustomerTerminology";


export default function MassiveSendingGeneralSettings(props: MassiveSettingsGeneralSettingsProps) {

  const { mode, massiveSending, setMassiveSending, sendingTypeNames, customerFrequencyTypes, customerGenders, birthdaySendingRules } = props;
  const { t } = useTranslation("massivesendings");

  const newDate = new Date();
  const currentYear = newDate.getFullYear();
  const currentMonth = newDate.getMonth();
  const nextDay = newDate.getDate() + 1;
  const tomorrowDate = new Date(currentYear, currentMonth, nextDay);

  const classes = useStyles();

  const sendingTypeTooltips = [
    t("Send the message once"),
    t("Send the message every year"),
    t("Send the message according the birthday date")
  ]

  function nameChangeHandler(event: any) {
    const name = event.target.value;
    setMassiveSending({ ...massiveSending, name });
  }

  function setCustomerFrequency(customerFrequency: number) {
    setMassiveSending({ ...massiveSending, customerFrequency });
  }

  function setCustomerGender(customerGender: number) {
    setMassiveSending({ ...massiveSending, customerGender });
  }

  function setTerminationDate(terminationDate: Date | null) {
    setMassiveSending({ ...massiveSending, terminationDate });
  }

  function setSendingDate(dateOfSending: Date | null) {
    setMassiveSending({ ...massiveSending, dateOfSending });
  }

  function setDateOfSingleOrAnnualSending(date: Date) {
    setSendingDate(date);
  }

  function setTimeOfSending(timeOfSending: string) {
    setMassiveSending({ ...massiveSending, timeOfSending });
  }

  function setBirthdaySendingType(value: number | null) {
    setMassiveSending({ ...massiveSending, birthdaySendingType: value });
  }

  function setSendImmediately(event: any) {
    setMassiveSending({ ...massiveSending, sendImmediately: event.target.checked });
  }

  function handleSingleSendingCheck() {
    setMassiveSending({
      ...massiveSending,
      type: 0,
      terminationDate: null,
      birthdaySendingType: null,
      dateOfSending: massiveSending.dateOfSending === null ? tomorrowDate : massiveSending.dateOfSending
    });
  }

  function handleYearlySendingCheck() {
    setMassiveSending({
      ...massiveSending,
      type: 1,
      birthdaySendingType: null,
      dateOfSending: massiveSending.dateOfSending === null ? tomorrowDate : massiveSending.dateOfSending
    });
  }

  function handleBirthdaySendingCheck() {
    setMassiveSending({
      ...massiveSending,
      type: 2,
      birthdaySendingType: massiveSending.birthdaySendingType === null ? 0 : massiveSending.birthdaySendingType,
      dateOfSending: null
    });
  }

  return (
    <div className={classes.root}>
      <InputWithLabel
        label={() => (<>
          <Typography className={classes.label}>
            {t("Sending name")}
          </Typography>
          <span className={classes.dot}>*</span>
        </>)}
        placeholder={t("Enter the name of you new massive sending")}
        value={massiveSending.name || ""}
        onChange={nameChangeHandler}
        width={700}
      />
      <div className={classes.divider} />
      <div className={classes.customerTypes}>
        <div>
          <Typography className={classes.controlLabel}>
            {useCustomerTerminology(t("Type of customers to send"), "customers")}
          </Typography>
          <span className={classes.dot}>*</span>
        </div>
        <div className={classes.customerTypeSelectors}>
          <div className={classes.customerTypeSelector}>
            <ChipSelect
              items={customerFrequencyTypes}
              item={massiveSending.customerFrequency}
              setItem={setCustomerFrequency}
            />
          </div>
          <div className={classes.customerTypeSelector}>
            <ChipSelect
              items={customerGenders}
              item={massiveSending.customerGender}
              setItem={setCustomerGender}
            />
          </div>
        </div>
      </div>
      <div className={classes.divider} />
      <div>
        {
          mode === "new"
            ?
            <div>
              <div>
                <Typography className={classes.controlLabel}>
                  {t("Sending type")}
                </Typography>
                <span className={classes.dot}>*</span>
              </div>
              <div className={classes.radios}>
                <div className={classes.radio}>
                  <RadioWithText
                    value={0}
                    checked={massiveSending.type === 0}
                    onCheck={handleSingleSendingCheck}
                    text={sendingTypeNames[0]}
                  />
                  <IconTooltip
                    icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 5 }} viewBox={"0 0 12 12"} />}
                    tooltipText={sendingTypeTooltips[0]}
                  />
                </div>
                <div className={classes.radio}>
                  <RadioWithText
                    value={1}
                    checked={massiveSending.type === 1}
                    onCheck={handleYearlySendingCheck}
                    text={sendingTypeNames[1]}
                  />
                  <IconTooltip
                    icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 5 }} viewBox={"0 0 12 12"} />}
                    tooltipText={sendingTypeTooltips[1]}
                  />
                </div>
                <div className={classes.radio}>
                  <RadioWithText
                    value={2}
                    checked={massiveSending.type === 2}
                    onCheck={handleBirthdaySendingCheck}
                    text={sendingTypeNames[2]}
                  />
                  <IconTooltip
                    icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 5 }} viewBox={"0 0 12 12"} />}
                    tooltipText={sendingTypeTooltips[2]}
                  />
                </div>
              </div>
            </div>
            :
            <div className={classes.sendingTypes}>
              <span className={classes.sendingTypeEditText}>{t("Sending type") + " |"}</span>
              <span className={classes.sendingTypeName}>{sendingTypeNames[massiveSending.type]}</span>
              <IconTooltip
                icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 5 }} viewBox={"0 0 12 12"} />}
                tooltipText={sendingTypeTooltips[massiveSending.type]}
              />
            </div>
        }

        {
          massiveSending.type === 0 &&
          <div className={classes.singleSendingSettings}>
            <div className={classes.controlRow}>
              <div className={classes.inputWithLabel}>
                <div>
                  <Typography className={classes.controlLabel}>
                    {t("Date of sending")}
                  </Typography>
                  <span className={classes.dot}>*</span>
                </div>
                <DateInput
                  placeholder={t("dd/mm/yy")}
                  format={"dd/MMMM/yyyy"}
                  value={massiveSending.dateOfSending}
                  onChange={setDateOfSingleOrAnnualSending}
                  minDate={tomorrowDate}
                  inputTextClass={classes.dateText}
                  iconColor={"#919CA5"}
                  width={300}
                  disabled={massiveSending.sendImmediately}
                />
              </div>
              <div className={classes.inputWithLabel}>
                <div>
                  <Typography className={classes.controlLabel}>
                    {t("Time of sending")}
                  </Typography>
                  <span className={classes.dot}>*</span>
                </div>
                <TimeOfSending
                  time={massiveSending.timeOfSending}
                  setTime={setTimeOfSending}
                  disabled={massiveSending.sendImmediately}
                />
              </div>
            </div>
            <div className={classes.controlRowSwitch}>
              <Switch
                value={massiveSending.sendImmediately ?? false}
                onChange={setSendImmediately}
                baseClass={classes.switchBase} />
              <Typography className={`${classes.switchLabel} ${massiveSending.sendImmediately ? classes.switchLabelActive : null}`}>
                {t("Send right now")}
              </Typography>
              <IconTooltip
                icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 5 }} viewBox={"0 0 12 12"} />}
                tooltipText={t("The sending will be made at the end of the massive sending creation")}
              />
            </div>
          </div>
        }

        {
          massiveSending.type === 1 &&
          <div className={classes.annualSendingSettings}>
            <div className={classes.annualControlRow}>
              <SelectMonthDayDate
                date={massiveSending.dateOfSending}
                setDate={setDateOfSingleOrAnnualSending}
                minDate={tomorrowDate}
                width={430}
              />
              <div className={classes.inputWithLabel}>
                <div>
                  <Typography className={classes.controlLabel}>
                    {t("Time of sending")}
                  </Typography>
                  <span className={classes.dot}>*</span>
                </div>
                <TimeOfSending
                  time={massiveSending.timeOfSending}
                  setTime={setTimeOfSending}
                  width={200}
                />
              </div>
            </div>
            <div className={classes.controlRow}>
              <div className={classes.inputWithLabel}>
                <div>
                  <Typography className={classes.controlLabel}>
                    {t("Termination of sending")}
                  </Typography>
                  <span className={classes.dot}>*</span>
                </div>
                <div style={{ marginLeft: -5 }}>
                  <TerminationDate
                    date={massiveSending.terminationDate}
                    setDate={setTerminationDate}
                    nullText={t("Never ends")}
                    minDate={tomorrowDate}
                  />
                </div>
              </div>
            </div>
          </div>
        }
        {
          massiveSending.type === 2 &&
          <div className={classes.birthdaySettings}>
            <div className={classes.controlRow}>
              <div className={classes.inputWithLabel}>
                <div>
                  <Typography className={classes.controlLabel}>
                    {t("Sending rules")}
                  </Typography>
                  <span className={classes.dot}>*</span>
                </div>
                <SelectKey
                  value={massiveSending.birthdaySendingType}
                  setValue={setBirthdaySendingType}
                  name={"birthdaySendingType"}
                  items={birthdaySendingRules}
                  width={300}
                />
              </div>
              <div className={classes.inputWithLabel}>
                <div>
                  <Typography className={classes.controlLabel}>
                    {t("Time of sending")}
                  </Typography>
                  <span className={classes.dot}>*</span>
                </div>
                <TimeOfSending
                  time={massiveSending.timeOfSending}
                  setTime={setTimeOfSending}
                />
              </div>
            </div>
            <div className={classes.inputWithLabel}>
              <div>
                <Typography className={classes.controlLabel}>
                  {t("Termination of sending")}
                </Typography>
                <span className={classes.dot}>*</span>
              </div>
              <div style={{ marginLeft: -5 }}>
                <TerminationDate
                  date={massiveSending.terminationDate}
                  setDate={setTerminationDate}
                  nullText={t("Never ends")}
                  minDate={tomorrowDate}
                />
              </div>
            </div>

          </div>
        }
      </div>
    </div >
  );
}