import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    ...theme.typography.txtBody2,
    borderRadius: "100px",
    background: "#F6F6F6",
    border: `1px solid ${theme.colors.grey2}`,
    height: 32,
    paddingLeft: 12,
    "&:focus": {
      border: "solid 1px #6462F3",
    },
  },
  noBorder: {
    border: "none!important",
    "&:focus": {
      border: "none!important",
    },
    paddingLeft: "16px!important",
  },
  inputSearchClass: {
    height: 32,
  },
  inputFocused: {
    border: "solid 1px #6462F3",
  },
  searchIcon: {
    padding: 6,
  },
}));

export default useStyles;
