import { AppointmentStatus } from "./enums/appointment-status";
import { AppointmentPaymentStatus } from "./enums/appointment-payment-status";


export default class CustomerHistory {
    yearMonth: number;
    monthYear: string;
    appointments: CustomerApptsHistory[];

    constructor(x?: any) {
        if (typeof x === "object") {
            this.yearMonth = x.yearMonth;
            this.monthYear = x.monthYear;
            this.appointments = x.appointments;
        } else {
            this.yearMonth = 0;
            this.monthYear = "";
            this.appointments = [];
        }
    }
}


export class CustomerApptsHistory {
    id: string;
    status: AppointmentStatus;
    paymentStatus: AppointmentPaymentStatus;
    recurringAppointmentCount: number | null;
    recurringAppointmentOrder: number | null;
    services: CustomerApptsServicesHistory[]

    constructor(x?: any) {
        if (typeof x === "object") {
            this.id = x.id;
            this.services = x.services;
            this.paymentStatus = x.paymentStatus;
            this.status = x.status;
            this.recurringAppointmentCount = x.recurringAppointmentCount;
            this.recurringAppointmentOrder = x.recurringAppointmentOrder;
        } else {
            this.id = "";
            this.status = 0;
            this.paymentStatus = AppointmentPaymentStatus.Unpaid;
            this.services = [];
            this.recurringAppointmentCount = null;
            this.recurringAppointmentOrder = null;
        }
    }
}


export class CustomerApptsServicesHistory {
    id: string; 
    name: string;
    specialist: string;
    start: Date;
    end: Date;
    duration: number;
    price: number | null;
    statusId: AppointmentStatus;
    notes: string;
    hasEvolutionNotes: boolean;

    constructor(x?: any) {
        if (typeof x === "object") {
            this.id = x.serviceId;
            this.name = x.name;
            this.specialist = x.specialist;
            this.start = x.start;
            this.end = x.end;
            this.duration = x.duration;
            this.price = x.price;
            this.statusId = x.statusId;
            this.notes = x.notes || "";
            this.hasEvolutionNotes = x.hasEvolutionNotes;
        } else {
            this.id = "";
            this.name = "";
            this.specialist = "";
            this.start = new Date();
            this.end = new Date();
            this.duration = 0;
            this.price = null;
            this.statusId = 0;
            this.notes = "";
            this.hasEvolutionNotes = false;
        }
    }
}
