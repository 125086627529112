import { Message } from "./message";

export class GetSpecificConversationMessagesResponse {
    currentPageNumber: number;
    messages: Message[];

    constructor(x?: any) {
        if (typeof x === "object") {
            this.currentPageNumber = x.currentPageNumber;
            this.messages = x.messages;
        }
        else {
            this.currentPageNumber = 1;
            this.messages = [];
        }
    }
}