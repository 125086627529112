import React, { useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../redux/hooks";
import { selectAccountSettings } from "../../../../../redux/store";
import { applicationInterfaceActions } from "../../../../../redux/application-interface-slice";

import { Button, CircularProgress } from "@material-ui/core";

import useStyles from "./css";
import useControlLabel from "../../../../../hooks/useControlLabel";
import DateInput from "../../../../common/DateInput";
import moment from "moment";
import SelectKey from "../../../../common/SelectKey";
import { SelectItem } from "../../../../../models/interfaces/select-item";
import { PredeterminedReportType } from "../../../../../models/enums/predetermined-report-type";
import { ReportsService } from "../../../../../api/reports-service";
import useTerminology from "../../../../../hooks/useTerminology";
import { TerminologyType } from "../../../../../models/enums/terminology-type";
import { TerminologyForm } from "../../../../../models/enums/terminology-form";
import ReportsNavTabs from "../../../ReportsNavTabs";
import useCustomerTerminology from "../../../../../hooks/useCustomerTerminology";


export default function PredeterminedReportsPage() {
  
  const classes = useStyles();
  const { t } = useTranslation("general");
  const dispatch = useDispatch();
  
  const todayDate = new Date();
  const lastMonthDate = addMonths(new Date(), -1);
  const nextDayOfLastMonthDate = addDays(addMonths(new Date(), -1), 1);
  
  const [startDate, setStartDate] = useState<Date>(lastMonthDate);
  const [endDate, setEndDate] = useState<Date>(nextDayOfLastMonthDate);
  
  const { updateIsWideLayout } = applicationInterfaceActions;
  
  const commissions = useTerminology({ type: TerminologyType.Commission, form: TerminologyForm.Plural });
  const commissionsReport = useTerminology({ type: TerminologyType.Commission, template: t("Commissions report"), form: TerminologyForm.Plural });
  
  const accountSettings = useAppSelector(selectAccountSettings);
  
  const customerTerminology = useCustomerTerminology(t("Customers"), "Customers");
  const customerReportNameTerminology = useCustomerTerminology(t("Customers report"), "Customers report");
  
  const [reportType, setReportType] = useState<PredeterminedReportType | null>(null);
  const reportTypes: SelectItem[] = 
    [
      { key: PredeterminedReportType.Appointments, value: t("Appointments") },
      { key: PredeterminedReportType.Commissions, value: commissions },
      { key: PredeterminedReportType.Services, value: t("Services") },
      { key: PredeterminedReportType.Customers, value: customerTerminology },
    ]
  
  if (accountSettings.paymentsAndCollectionsEnabled) {
    reportTypes.push({ key: PredeterminedReportType.CashClosing, value: t("Cash closing") });
  }
 
  //sorted alphabetically
  const sortedReportTypes = reportTypes.sort((a, b) => a.value.localeCompare(b.value))
  
  const [isDownloading, setIsDownloading] = useState<boolean>(false);
  
  const locale = useAppSelector(state => state.applicationInterface.locale);

  const accountName = accountSettings.accountName;
  
  const dateFormatPlaceholder = locale === "en" ? "mm/dd/yy" : "dd/mm/yy";
  const dateFormat = locale === "en" ? "MM/dd/yy" : "dd/MM/yy";
  const fileDateFormat = locale === "en" ? "MM-DD-YYYY" : "DD-MM-YYYY";
  
  const StartDateLabel = useControlLabel({ label: t("Start date"), required: true, labelClass: classes.dateLabel, })
  const EndDateLabel = useControlLabel({ label: t("End date"), required: true, labelClass: classes.dateLabel, })
  
  useEffect(() => {
    dispatch(updateIsWideLayout(true));
  });
  
  function addDays(date: Date, days: number): Date {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  
  function addMonths(date: Date, months: number): Date {
    let result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  }
  
  function getReportTypeName(type: PredeterminedReportType) {
    switch (type) {
      case PredeterminedReportType.Appointments:
        return t("Appointments report");
      case PredeterminedReportType.CashClosing:
        return t("Cash closing report");
      case PredeterminedReportType.Commissions:
        return commissionsReport;
      case PredeterminedReportType.Services:
        return t("Services report");
      case PredeterminedReportType.Customers:
        return customerReportNameTerminology;
      default:
        return t("Report");
    }
  }
  
  function getReportFileName(type: PredeterminedReportType, dateFrom: Date, dateTo: Date) {
    const reportTypeName = getReportTypeName(type)
    const dateFromFormatted = moment(dateFrom).format(fileDateFormat)
    const dateToFormatted = moment(dateTo).format(fileDateFormat)
    return `${accountName} - ${reportTypeName} - ${dateFromFormatted} - ${dateToFormatted}`;
  }
  
  async function handleDownloadReportClick() {
    if (reportType === null) return;
    
    setIsDownloading(true);
    
    try {
      const blob = await ReportsService.getPredeterminedReport(startDate, endDate, reportType);
      const url = window.URL.createObjectURL(new Blob([blob], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml"
      }));
      const link = document.createElement('a');
      const name = getReportFileName(reportType, startDate, endDate);
      link.href = url;
      link.setAttribute('download', `${name}.xlsx`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    finally {
      setIsDownloading(false);
    }
  }
  
  return (
    <div className={classes.root}>
      <ReportsNavTabs/>
      <div className={classes.mainContainer}>
        <div className={classes.inputsBlock}>
          <div className={classes.controlWithLabel}>
            <StartDateLabel/>
            <DateInput
              width={158}
              placeholder={t(dateFormatPlaceholder)}
              format={dateFormat}
              value={startDate}
              onChange={setStartDate}
              maxDate={todayDate}
              inputTextClass={classes.dateInputText}
              iconColor={"#ACB7C0"}
            />
          </div>
          <div className={classes.controlWithLabel}>
            <EndDateLabel/>
            <DateInput
              width={158}
              placeholder={t(dateFormatPlaceholder)}
              format={dateFormat}
              value={endDate}
              onChange={setEndDate}
              minDate={startDate}
              maxDate={todayDate}
              inputTextClass={classes.dateInputText}
              iconColor={"#ACB7C0"}
            />
          </div>
          <div className={classes.reportTypeSelect}>
            <SelectKey
              value={reportType}
              setValue={setReportType}
              items={sortedReportTypes}
              width={348}
              placeholder={t("Select a report")}
            />
          </div>
        
        </div>
        <div className={classes.downloadReportButtonBlock}>
          <Button
            className={classes.downloadReportButton}
            disabled={isDownloading || reportType === null}
            onClick={handleDownloadReportClick}
          >
            {
              <span className={classes.downloadReportButtonLabel}>
              {isDownloading
                ? <CircularProgress size="1rem" style={{ color: "#FFFFFF" }}/>
                : null
              }
                {t("Download report")}
            </span>
            }
          </Button>
        </div>
      </div>
    </div>
  );
}