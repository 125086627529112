import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function PatientStethoscopeIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M17.9688 7.27161C16.875 7.30093 16 8.12192 16 9.11883C15.9688 9.82254 16.375 10.4676 17 10.7901V14.0741C17 15.7747 15.4062 17.1235 13.5 17.1235C11.625 17.1235 10.0625 15.8333 10 14.2207C12.2812 13.7809 14 11.9043 14 9.61729V5.07254C14 4.75001 13.7188 4.4568 13.375 4.39816L11.4062 4.01698C11 3.92902 10.625 4.19291 10.5312 4.57408L10.4375 5.0139C10.3438 5.39507 10.625 5.77624 11.0312 5.8642L11.9688 6.04013V9.58797C11.9688 11.142 10.6562 12.4321 9 12.4321C7.34375 12.4614 5.96875 11.2006 5.96875 9.61729V6.04013L6.9375 5.8642C7.34375 5.77624 7.625 5.42439 7.53125 5.04322L7.4375 4.57408C7.34375 4.19291 6.96875 3.92902 6.5625 4.01698L4.59375 4.36883C4.25 4.4568 4 4.75001 4 5.07254V9.61729C4 11.9043 5.71875 13.7809 8 14.2207C8.0625 16.8889 10.5 19 13.5 19C16.5312 19 19 16.8009 19 14.0741V10.7901C19.5938 10.4676 20 9.85186 20 9.14815C20 8.12192 19.0625 7.27161 17.9688 7.27161ZM18 9.61729C17.7188 9.61729 17.5 9.41204 17.5 9.14815C17.5 8.91358 17.7188 8.67902 18 8.67902C18.25 8.67902 18.5 8.91358 18.5 9.14815C18.5 9.41204 18.25 9.61729 18 9.61729Z"
                fill={style?.color || Color.clear2}
            />
        </SvgIcon>
    );
}