import React from "react";

import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import useStyles from "./css";
import { BackdropProps } from "./props";


export default function Backdrop(props: BackdropProps) {

  const { show, text } = props;

  const classes = useStyles();

  
  return (
    <>
      
      <div className={`${classes.backdrop} ${show ? classes.fadeIn : ""}`}>
        <Typography>{text}</Typography>
      </div>
     
    </>
  );
}