import React from "react";

import { useTranslation } from "react-i18next";
import Interweave from "interweave";
import moment from "moment";

import { MassiveSendingPreviewProps } from "./props";
import useStyles from "./css";
import WhatsappBackground from "../../../../assets/images/whatsapp-background.jpg"
import { ExportIcon, SideArrowForMessageIcon } from "../../../../assets/icons";
import useCustomerTerminology from "../../../../hooks/useCustomerTerminology";


export default function MassiveSendingPreview(props: MassiveSendingPreviewProps) {

  const { massiveSending, customerFrequencyTypes, sendingTypeNames, customerGenders, birthdaySendingRules } = props;
  const { t } = useTranslation("massivesendings");
  const classes = useStyles();

  const greetingMessage = <span>{t("Hello")} <b>{useCustomerTerminology(t("[Customer name]"), "[Customer name]")}</b>,</span>;
  const scheduleAppointments = t("To schedule your appointment on our Site for appointments click on the following button.");
  const goToSite = t("GO TO APPT SITE");
  const sendingCustomerGender = customerGenders.find(x => x.key == massiveSending.customerGender)!.value;
  const sendingCustomerFrequencyType = customerFrequencyTypes.find(x => x.key == massiveSending.customerFrequency)!.value;
  const sendingBirthdaySendingRules = massiveSending.birthdaySendingType === null ? "" : birthdaySendingRules.find(x => x.key == massiveSending.birthdaySendingType)!.value;

  const sendingRules = [
    `${formattedDate(massiveSending.dateOfSending!)} - ${formattedTime(massiveSending.timeOfSending)}`,
    `${formattedDate(massiveSending.dateOfSending!)} - ${formattedTime(massiveSending.timeOfSending)}`,
    `${sendingBirthdaySendingRules} - ${formattedTime(massiveSending.timeOfSending)}`
  ];
  
  function formattedDate(date: Date) : string {
    return `${moment(date).format("DD")}/${t(moment(date).format("MMMM"))}/${moment(date).format("yyyy")}`;
  }
  
  function formattedTime(time: string) : string {
    return `${time}hrs`;
  }
  
  interface ValueBlockProps {
    label: string;
    value: string;
  }
  
  function ValueBlock(props: ValueBlockProps) {
    return (
      <div className={classes.value}>
        <div className={classes.valueLabel}>{props.label}</div>
        <div className={classes.valueText}>{props.value}</div>
      </div>
    )
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.header}>{t("Verify that all the data of your massive sending are correct")}</div>
      <div className={classes.blocks}>
        <div className={classes.valuesBlock}>
          
          <ValueBlock label={t("Name of the sending")} value={massiveSending.name} />
          <ValueBlock label={useCustomerTerminology(t("Type of customers to send"), "customers")} value={`${sendingCustomerFrequencyType} / ${sendingCustomerGender}`} />
          
          <div className={classes.divider} />
          
          <ValueBlock label={t("Sending type")} value={sendingTypeNames[massiveSending.type]} />
          <ValueBlock label={t("Sending rules")} value={sendingRules[massiveSending.type]} />
          
          <div className={classes.divider} />
          {
            massiveSending.type === 1 || massiveSending.type === 2 && 
            <ValueBlock label={t("Termination of sending")} value={massiveSending.terminationDate === null ? t("Never ends") : formattedDate(massiveSending.terminationDate)} />
          }
        </div>
        <div className={classes.previewBlock} style={{ backgroundImage: `url(${WhatsappBackground})` }}>
          <div className={classes.messageBlock}>
            {
              massiveSending.imageUrl && massiveSending.contentType === 1 && 
              <div className={classes.imageBlock}>
                <img src={massiveSending.imageUrl} alt="image" className={classes.image} />
              </div>
            }
            <div className={classes.text}>
              <span>{greetingMessage}</span>
              <p></p>
              <Interweave content={massiveSending.text} />
              <span>{scheduleAppointments}</span>
            </div>
            
            <SideArrowForMessageIcon className={classes.arrow} />
            <div className={classes.timeBlock}>
              {formattedTime(massiveSending.timeOfSending)}
            </div>
          </div>
          <div className={classes.goToSiteButton}>
            <div className={classes.goToSiteButtonInnerContainer}>
              <div className={classes.goToSiteIcon}>
                <ExportIcon color={"#0BCBE0"} />
              </div>
              {goToSite}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}