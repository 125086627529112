import { makeStyles } from "@material-ui/core/styles";
import { Color } from "./../../../constants/colors"

export const useStyles = (imageWidth: number) => makeStyles((theme) => ({
    outboundMessageContainer: {
        maxWidth: "48%",
        padding: "4px 8px",
        borderRadius: "5px",
        backgroundColor: "#EAFFD7",
    },
    inboundMessageContainer: {
        maxWidth: "48%",
        padding: "4px 8px",
        borderRadius: "5px",
        backgroundColor: Color.gray1,
    },
    outboundMessageLayout: {
        display: "flex",
        justifyContent: "flex-end",
        whiteSpace: "break-spaces",
        margin: "10px 40px 10px 40px",
        '& a': {
            '&:link': {
                color: theme.colors.clearMain + " !important"
            },
            '&:visited': {
                color: theme.colors.clearMain + " !important"
            }
        }
    },
    inboundMessageLayout: {
        display: "flex",
        justifyContent: "flex-start",
        whiteSpace: "break-spaces",
        margin: "10px 40px 10px 40px",
        '& a': {
            '&:link': {
                color: theme.colors.clearMain + " !important"
            },
            '&:visited': {
                color: theme.colors.clearMain + " !important"
            }
        }
    },
    inboundMessageText: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        color: theme.colors.grey5,
        overflowWrap: "break-word",
        alignSelf: "flex-start"
    },
    outboundMessageText: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        color: theme.colors.grey6,
        overflowWrap: "break-word"
    },
    messageTimeContainer: {

    },
    messageTimeText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "11px",
        color: Color.gray5,
    },
    image: {
        maxWidth: "100%",
        maxHeight: "50vh",
        borderRadius: "10px 10px 0 0",
        cursor: "pointer",
        objectFit: "cover",
        display: "block"
    },
    pdfPreviewImage: {
        maxWidth: "300px",
        maxHeight: "50vh",
        borderRadius: "10px 10px 0 0",
        objectFit: "cover",
        display: "block"
    },
    documentMessageFooter: {
        backgroundColor: "#ECECEC",
        padding: "20px 0px 15px 0px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        justifyContent: "space-between",
        borderRadius: "0px 0px 10px 10px"
    },
    documentMessageFileNameText: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px"
    },
    documentContentBox: {
        paddingBottom: 5,
        paddingTop: 4
    },
    pdfPage: {
        display: "flex",
        justifyContent: "space-around",
    },
    downloadFileIcon: {
        width: "24px !important",
        height: "18px !important",
        cursor: "pointer",
        color: theme.colors.grey5 + " !important"
    },
    fileCaptionText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        color: theme.colors.grey5,
        whiteSpace: "normal",
        overflow: "hidden",
        textOverflow: "ellipsis",
        display: "-webkit-box",
        WebkitBoxOrient: "vertical",
        WebkitLineClamp: 2,
        maxHeight: "2.8em",
        marginRight: "10px",
        flexGrow: 1,
        overflowWrap: "break-word"
    },
    fileMessageBox: {
        padding: 5,
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
    },
    previewMessageFooter: {
        backgroundColor: "#ECECEC",
        display: "flex",
        alignItems: "center",
        paddingLeft: 5,
        paddingRight: 5,
        gap: 5,
        borderRadius: "0px 0px 10px 10px",
        height: "60px",
        width: imageWidth + "px",
        minWidth: "102px",
        maxWidth: "100%",
        marginBottom: 20
    },
    documentMessageContainer: {
        backgroundColor: "#ECECEC",
        display: "flex",
        alignItems: "center",
        paddingLeft: 5,
        paddingRight: 5,
        gap: 5,
        borderRadius: "0px 0px 10px 10px",
        height: "60px",
        minWidth: "102px",
        maxWidth: "100%"
    },
    circularLoaderContainer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        height: "200px",
        width: "300px"
    },
    blockLabel: {
        display: "flex",
        justifyContent: "center"
    },
    blockLabelBox: {
        background: "rgba(237, 76, 92, 0.1)",
        padding: "4px 8px",
        borderRadius: "5px",
        whiteSpace: "break-spaces",
        margin: "10px 40px 10px 40px",
    },
    blockLabelText: {
        color: Color.red,
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
    },
    unblockLabelBox: {
        background: "rgba(54, 206, 145, 0.1)",
        padding: "4px 8px",
        borderRadius: "5px",
        whiteSpace: "break-spaces",
        margin: "10px 40px 10px 40px",
    },
    unblockLabelText: {
        color: Color.green,
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
    },
    videoPreviewImage: {
        position: "relative"
    },
    videoPreviewPlayIcon: {
        position: "absolute",
        top: "calc(50% - 50px)",
        left: "calc(50% - 50px)",
    },
    mediaCaption: {
        width: 400
    }
}));