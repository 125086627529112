export class SectionFieldItemModel {
    id: string | null;
    name: string;
    order: number;
    isDeleted: boolean;

    constructor(x?: any) {
        if (typeof x === "object") {
            this.id = x.id;
            this.name = x.name;
            this.order = x.order;
            this.isDeleted = x.isDeleted;
        } else {
            this.id = null;
            this.name = "";
            this.order = 1;
            this.isDeleted = false;
        }
    }
}