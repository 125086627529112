import React, { useState } from 'react';

import { DraggableBaseProps } from "./props";
import useStyles from "./css";
import { Button } from "@material-ui/core";
import { Draggable, DraggableProvided } from "react-beautiful-dnd";
import { DraggableIcon, SectionEditIcon, SectionDeleteIcon } from "../../../assets/icons";

type DraggableFunction = (provided: DraggableProvided) => JSX.Element;


export default function DraggableBase(props: DraggableBaseProps) {
    const {
        className,
        height,
        draggableId,
        index,
        innerContainerStyle,
        mainBoxClass,
        isEditable,
        isEditableByButton,
        isRemovable,
        children,
        onEditClick,
        onDeleteClick,
        isShowActionOnMouseHover,
        innerContainerClass,
        closeIcon,
        ...otherProps
    } = props;

    const classes = useStyles();
    const [isHovered, setIsHovered] = useState<boolean>(false);

    const tryGetEditButton = () => {
        if (isEditable && isEditableByButton)
            return (<Button
                onClick={() => { onEditClick && onEditClick() }}
                className={classes.actionButton}
            >
                <SectionEditIcon />
            </Button>);
        return <></>;
    };

    const tryGetDeleteButton = () => {
        if (isRemovable)
            return (<Button
                onClick={() => { onDeleteClick && onDeleteClick() }}
                className={classes.actionButton}
            >
                {closeIcon ? closeIcon : <SectionDeleteIcon />}
            </Button>);
        return <></>;
    };

    const getActions = () => {
        return <>
            {tryGetEditButton()}
            {tryGetDeleteButton()}
        </>
    };

    const getBaseNode = (innerContent: JSX.Element | DraggableFunction): JSX.Element => {
        if (isEditable)
            return <Draggable
                key={draggableId}
                draggableId={draggableId}
                index={index}
                isDragDisabled={!isEditable}
                {...otherProps}
            >
                {(provided) => (innerContent as DraggableFunction)(provided)}
            </ Draggable>
        else
            return <>
                {innerContent}
            </>
    }

    const innerContentDraggable = (): DraggableFunction =>
        (provided) => (
            <div className={className}
                ref={provided.innerRef}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
            >
                {innerContentCommon()}
            </div>
        );

    const innerContentStatic = (): JSX.Element => {
        return <div className={className}>
            {innerContentCommon()}
        </div>
    }

    const innerContentCommon = (): JSX.Element => {
        return <div className={mainBoxClass ?? classes.mainBox}>
            <div className={innerContainerClass ?? classes.innerContainer} style={innerContainerStyle ?? { height: 48 }}>
                <div>
                    {isEditable ? <DraggableIcon style={{ cursor: "grab" }} /> : <DraggableIcon style={{ display: "none" }} />}
                </div>
                <div>
                    {children}
                </div>
                <div className={classes.justifyEnd}>
                    {isShowActionOnMouseHover ? isHovered && getActions() : getActions()}
                </div>
            </div>
        </div>
    }


    return getBaseNode(isEditable ? innerContentDraggable() : innerContentStatic());
};