import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.colors.grey5,
  },
  icon: {
    width: "max-content",
    height: "max-content"
  },
  box: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    maxWidth: 174,
    padding: 8,
    zIndex: 1500,
    
    backgroundColor: theme.colors.grey1,

    ...theme.typography.subtitle2,
    color: theme.colors.grey5,
    
    borderRadius: 5,
    boxShadow: "0px 4px 4px rgba(166, 166, 166, 0.25)",
  },
  arrowBottom: {
    position: "absolute",
    bottom: -4,
    width: 8,
    height: 8,
    
    backgroundColor: theme.colors.grey1,
    transform: "rotate(45deg)",
  },
  arrowLeft: {
    position: "absolute",
    left: -4,
    width: 8,
    height: 8,

    backgroundColor: theme.colors.grey1,
    transform: "rotate(45deg)",
  },
  arrowRight: {
    position: "absolute",
    right: -4,
    width: 8,
    height: 8,

    backgroundColor: theme.colors.grey1,
    transform: "rotate(45deg)",
  }
  })
);

export default useStyles;