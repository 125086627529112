export class CustomerCommunicationWa {
    accountId: string | null;
    availabilityStatusResetTime: string | null;
    availabilityStatusResetEnabled: boolean | null;
    whatsAppInitialMessage: string | null;

    constructor(x?: any) {
        if (typeof x === "object") {
            this.accountId = x.accountId;
            this.availabilityStatusResetTime = x.availabilityStatusResetTime;
            this.availabilityStatusResetEnabled = x.availabilityStatusResetEnabled;
            this.whatsAppInitialMessage = x.whatsAppInitialMessage;
        }
        else {
            this.accountId = null;
            this.availabilityStatusResetTime = null;
            this.availabilityStatusResetEnabled = null;
            this.whatsAppInitialMessage = null;
        }
    }
}