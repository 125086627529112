import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NotesIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M9.33333 8.55555L9.24233 8.561C9.06872 8.58147 8.90707 8.65984 8.78345 8.78345C8.65984 8.90707 8.58147 9.06872 8.561 9.24233L8.55556 9.33333V14H0.776222C0.570902 14.0004 0.373816 13.9193 0.228268 13.7745C0.0827208 13.6297 0.000618019 13.433 0 13.2277V0.772333C0 0.346111 0.346111 0 0.772333 0H13.2277C13.6539 0 14 0.347667 14 0.777V8.55555H9.33333ZM14 10.1111L10.1111 13.9977V10.1111H14Z" />
    </SvgIcon>
  );
};

export default NotesIcon;
