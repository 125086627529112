export class FileUploadResponse {
    uploaded: boolean | null;
    fileName: string | null;
    fileURL: string | null;
    fileSizeInBytes: string | null
    constructor(x?: any){
        if (typeof x === "object") {
            this.uploaded = x.uploaded;
            this.fileName = x.fileName;
            this.fileURL = x.fileURL;
            this.fileSizeInBytes = x.fileSizeInBytes;
        } else {
            this.uploaded = null;
            this.fileName = null;
            this.fileURL = null;
            this.fileSizeInBytes = null;
        }
    }
}
