import { User } from "./user";

export class UsersResponse {
  users: User[] | null;
  totalUsers: number | null;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.users = x.users;
      this.totalUsers = x.totalUsers;
    } else {
      this.users = null;
      this.totalUsers = null;
    }
  }
}