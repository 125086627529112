import { UserGeneralInfo } from "./user-general-info";
import { UserScheduleInfo } from "./user-schedule-info";
import { UserServicesInfo } from "./user-services-info";
import { UserCommissionInfo } from "./user-commission-info";
import { UserPermissionsInfo } from "./user-permissions-info";
import { UserProfessionalInfo } from "./user-professional-info";

export class UserEntity {
  accountId?: string;
  general: UserGeneralInfo;
  schedule: UserScheduleInfo;
  services: UserServicesInfo;
  commission: UserCommissionInfo;
  permissions: UserPermissionsInfo;
  professionalInformation: UserProfessionalInfo;

  constructor(
    accountId?: string,
    general?: UserGeneralInfo,
    schedule?: UserScheduleInfo,
    services?: UserServicesInfo,
    commission?: UserCommissionInfo,
    permissions?: UserPermissionsInfo,
    professionalInformation?: UserProfessionalInfo
    )
  {
    this.accountId = accountId;
    this.general = general !== undefined ? new UserGeneralInfo(general) : new UserGeneralInfo();
    this.schedule = schedule ? new UserScheduleInfo(schedule.schedule) : new UserScheduleInfo([]);
    this.services = services ? new UserServicesInfo(services.serviceCategories) : new UserServicesInfo();
    this.commission = commission ? new UserCommissionInfo(commission) : new UserCommissionInfo();
    this.permissions = permissions ? new UserPermissionsInfo(permissions) : new UserPermissionsInfo();
    this.professionalInformation = professionalInformation ? new UserProfessionalInfo(professionalInformation) : new UserProfessionalInfo();
  }
}