import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import useStyles from "./css";
import { Button, Typography } from "@material-ui/core";
import { CameraIcon, LogoIcon } from "../../../../../../assets/icons";
import { useAppDispatch, useAppSelector } from "../../../../../../redux/hooks";
import { navbarActions } from "../../../../../../redux/navbar-slice";
import { selectAccountSettings } from "../../../../../../redux/store";
import { alertsActions } from "../../../../../../redux/alerts-slice";
import DropImage from "../../../../../common/DropImage";
import ServicesService from "../../../../../../api/settings-services-service";
import { ControlInput } from "../../../../../../models/establishmentinfo";

import { EnterpriseInfo } from "../../../../../../models/enterpriseinfo";
import { EnterpriseInfoService } from "../../../../../../api/settings-enterpriseinfo-service";
import { EnterpriseSettingsFieldUpdate, getEnum } from "../../../../../../models/enums/enterprise-settings-field-update";
import InputWithLabel from "../../../../../common/InputWithLabel";


export default function General() {
  
  const { t } = useTranslation(["general"]);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [establishmentInfoShadow, setEstablishmentInfoShadow] = useState<EnterpriseInfo>(new EnterpriseInfo());
  
  const [readOnlyControl, setReadOnlyControl] = useState<ControlInput>(new ControlInput(true));
  const [enterpriseInfo, setEnterpriseInfo] = useState<EnterpriseInfo>(new EnterpriseInfo());
  
  const [logoURL, setLogoURL] = useState<string>("");
  const accountSettings = useAppSelector(selectAccountSettings);
  const { enqueueAlert } = alertsActions;
  
  useEffect(() => {
    
    (async () => {
      dispatch(navbarActions.setShowLoader(true));
      let enterprise = await EnterpriseInfoService.getEnterpriseInfo();
      setEnterpriseInfo(enterprise);
      setEstablishmentInfoShadow(enterprise);
      await getImage(enterprise.logo);
      dispatch(navbarActions.setShowLoader(false));
    })();
    
  }, [])
  
  const saveByEnter = (newInfo: EnterpriseInfo) => {
    (async () => {
      await EnterpriseInfoService.updateEnterpriseInfo(newInfo);
    })();
  };
  
  async function getImage(fileId: string | null = "") {
    if (!fileId) return;
    const data = await ServicesService.getImage(fileId);
    
    if (data)
      setLogoURL(data.fileURL);
  }
  
  function onKeyPress(e: any) {
    if (e.which === 13) {
      e.target.blur();
    }
  }
  
  function handleInputChange(e: any) {
    const { name, value } = e.target;
    setEnterpriseInfo({
      ...enterpriseInfo,
      [name]: value
    });
  }
  
  async function handleLogoUpload(files: any) {
    if (files && files.length > 0) {
      let data: any = await ServicesService.saveImage(files[0]);
      if (data) {
        let newInfo: EnterpriseInfo = {
          ...enterpriseInfo,
          logo: data.fileName,
          fieldUpd: EnterpriseSettingsFieldUpdate.Logo
        };
        
        saveByEnter(newInfo);
      }
    }
  }
  
  function clickTextbox(e: any) {
    const { name } = e.target;
    
    setReadOnlyControl({
      ...readOnlyControl,
      [name]: false
    });
  }
  
  const DivLogoEmpty: React.FC = () => {
    return (
      <div className={classes.divLogoIcon}>
        <LogoIcon className={classes.logoIconClear}/>
      </div>);
  };
  
  function handleEmptyRequired(name: string, value: any): boolean {
    switch (name) {
      case "name": {
        if (value === "") {
          setEnterpriseInfo({
            ...enterpriseInfo,
            [name]: establishmentInfoShadow.name
          });
          dispatch(
            enqueueAlert({
              type: "Error",
              title: t("Error"),
              description: t("Enter establishment name is required")
            })
          );
          return false;
        } else if (value === establishmentInfoShadow.name)
          return false;
        
        return true;
      }
      default: {
        return false;
      }
    }
  }
  
  function updateOnBlur(e: any) {
    const { name, value } = e.target;
    
    setReadOnlyControl({
      ...readOnlyControl,
      [name]: true
    });
    
    let newInfo: EnterpriseInfo = {
      ...enterpriseInfo,
      fieldUpd: getEnum(name)
    };
    
    return newInfo;
  }
  
  function handleCopyLink() {
    navigator.clipboard.writeText(`https://sitiodereservas.somosclear.com/${accountSettings.siteForFinalCustomersSmbName}`);
  }
  
  function onBlur(e: any) {
    const { name, value } = e.target;
    
    const newInfo = updateOnBlur(e);
    
    const canSave = handleEmptyRequired(name, value);
    
    if (canSave)
      saveByEnter(newInfo);
  }
  
  const DivLogoEmptyHover: React.FC = () => {
    return (
      <>
        <div className={classes.divLogoIconHover}>
          <div className={classes.iconCam}>
            <CameraIcon/>
          </div>
          <div className={classes.labelChangePhoto}>
            {t("upload photo")}
          </div>
        </div>
      </>);
  };
  
  
  return (
    <>
      <Typography className={classes.title}>
        {t("General settings")}
      </Typography>
      <div className={classes.divArea}>
        <div className={classes.titleSection}>
          <Typography className={classes.titleSection}>
            {t("Enterprise information")}
          </Typography>
        </div>
        <div className={classes.enterpriseInformationContainer}>
          <div className={classes.enterpriseLeftContainer}>
            <Typography className={classes.label}>
              {t("Enterprise logo")}
            </Typography>
            
            <span className={classes.optional}>({t("Optional")})</span>
            <br/>
            
            <div className={classes.logoSection}>
              <DropImage
                classDivUploadImage={classes.divLogo}
                onChange={handleLogoUpload}
                imageURL={logoURL}
                componentShowEmptyHover={<DivLogoEmptyHover/>}
                componentShowEmpty={<DivLogoEmpty/>}
              />
              <div style={{ marginTop: 10, marginLeft: 14 }} className={classes.labelSecondary}>
                {t("Max size")}: <b>800px x 800px</b>
              </div>
            </div>
          </div>
          <div className={classes.enterpriseRightContainer}>
            <InputWithLabel
              name="name"
              showCounter={true}
              value={enterpriseInfo.name}
              onChange={handleInputChange}
              onKeyPress={onKeyPress}
              onBlurInput={onBlur}
              inputReadOnly={readOnlyControl.name}
              onClickInput={clickTextbox}
              width="100%"
              inputClass={classes.inputClass}
              label={() => (
                <>
                  <Typography className={classes.label}>
                    {t("Enterprise name")}
                  </Typography>
                  <span className={classes.required}>*</span>
                </>)}
              labelClass={classes.label}
              placeholder={t("Enter the name of the establishment")}
              maxLength={60}
            />
            <div className={classes.copyLinkButtonContainer}>
              <Button
                className={classes.copyLinkButton}
                onClick={handleCopyLink}
              >
                {t("Copy enterprise site for appointments link")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
};