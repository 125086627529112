import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CalendarIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 14, height: 16, ...style }}
      viewBox={viewBox || "0 0 14 16"}
    >
      <path d="M0 14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V6H0V14.5ZM10 8.375C10 8.1875 10.1562 8 10.375 8H11.625C11.8125 8 12 8.1875 12 8.375V9.625C12 9.84375 11.8125 10 11.625 10H10.375C10.1562 10 10 9.84375 10 9.625V8.375ZM10 12.375C10 12.1875 10.1562 12 10.375 12H11.625C11.8125 12 12 12.1875 12 12.375V13.625C12 13.8438 11.8125 14 11.625 14H10.375C10.1562 14 10 13.8438 10 13.625V12.375ZM6 8.375C6 8.1875 6.15625 8 6.375 8H7.625C7.8125 8 8 8.1875 8 8.375V9.625C8 9.84375 7.8125 10 7.625 10H6.375C6.15625 10 6 9.84375 6 9.625V8.375ZM6 12.375C6 12.1875 6.15625 12 6.375 12H7.625C7.8125 12 8 12.1875 8 12.375V13.625C8 13.8438 7.8125 14 7.625 14H6.375C6.15625 14 6 13.8438 6 13.625V12.375ZM2 8.375C2 8.1875 2.15625 8 2.375 8H3.625C3.8125 8 4 8.1875 4 8.375V9.625C4 9.84375 3.8125 10 3.625 10H2.375C2.15625 10 2 9.84375 2 9.625V8.375ZM2 12.375C2 12.1875 2.15625 12 2.375 12H3.625C3.8125 12 4 12.1875 4 12.375V13.625C4 13.8438 3.8125 14 3.625 14H2.375C2.15625 14 2 13.8438 2 13.625V12.375ZM12.5 2H11V0.5C11 0.25 10.75 0 10.5 0H9.5C9.21875 0 9 0.25 9 0.5V2H5V0.5C5 0.25 4.75 0 4.5 0H3.5C3.21875 0 3 0.25 3 0.5V2H1.5C0.65625 2 0 2.6875 0 3.5V5H14V3.5C14 2.6875 13.3125 2 12.5 2Z" />
    </SvgIcon>
  );
};

export default CalendarIcon;
