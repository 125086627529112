import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState, selectAccountSettings } from "./redux/store";
import { useAppSelector } from "./redux/hooks";
import i18n from "./i18n";

import Layout from "./components/Layout";
import AuthenticationRouter from "./components/authentication/AuthenticationRouter";
import AppointmentRouter from "./components/appointments/AppointmentRouter";
import CustomerRouter from "./components/customers/CustomerRouter";
import SettingsRouter from "./components/settings/SettingsRouter";
import ReportsRouter from "./components/reports/ReportsRouter";
import WhatsAppRouter from "./components/whatsapp/WhatsAppRouter"


import Todo from "./components/Todo";
import { Url } from "./constants/url";
import { useAlert } from "./components/common/notifications/AlertProvider";

import "./custom.css";
import AccountSettingsProvider from "./components/common/infrastructure/AccountSettingsProvider";
import EstablishmentSettingsRouter from "./components/establishment-settings/EstablismentSettingsRouter";
import PrivacyNoticePage from "./components/customers/PrivacyNoticePage";
import InformedConsentLetterPage from "./components/customers/InformedConsentLetterPage";
import { useGlobalThemeCss } from "./constants/styles";

function App() {
  const dotenv = require("dotenv");
  dotenv.config();
  const isAuthenticated = useSelector((state: RootState) => state.authentication.value);
  let localeApp = useAppSelector(state => state.applicationInterface.locale);
  if (!isAuthenticated) {
    const language = navigator.language;
    switch (language) {
      case 'en':
      case 'en-US':
      //case 'ru-RU':
        localeApp = 'en';
        break;
      default:
        localeApp = 'es';
    }
  }
  if (i18n.language != localeApp) {
    // sometimes useEffect calls to late, so set locale with first run
    void i18n.changeLanguage(localeApp);
  }
  
  const accountSettings = useAppSelector(selectAccountSettings);
  
  useGlobalThemeCss();
  useAlert();

  return isAuthenticated ? (
    <Layout>
      <AccountSettingsProvider />
      <Switch>
        <Route path={Url.Appointments.Main} component={AppointmentRouter} />
        <Route path={Url.Customers.Main} component={CustomerRouter} />
        <Route path={Url.FollowUps.Main} component={Todo} />
        <Route 
          path={[
            Url.WhatsApp.Main, 
            Url.WhatsApp.MassiveSendings
          ]}
          component={WhatsAppRouter}
        />
        <Route 
          path={[
            Url.Reports.Main,
            Url.Reports.CustomReport
          ]}
          component={ReportsRouter}
        />
        {!accountSettings.isEnterprise &&
          <Route
            path={[
              Url.Settings.Main,
              Url.Settings.CustomerCommunications,
              Url.Settings.WorkAreas,
              Url.Settings.Services,
              Url.Settings.Users,
              Url.Settings.Payments,
              Url.Settings.ClinicHistory,
              Url.Settings.EvolutionNotes,
              Url.Settings.PatientForm,
              Url.Settings.Commissions,
            ]}
            component={SettingsRouter}
          />
        }
        {accountSettings.isEnterprise &&
          <Route
            path={[
              Url.EstablishmentSettings.General,
              Url.EstablishmentSettings.ClinicHistory,
              Url.EstablishmentSettings.EvolutionNotes,
              Url.EstablishmentSettings.PatientForm,
            ]}
            component={EstablishmentSettingsRouter}
          />
        }
        <Route path={Url.PrivacyNotice} component={PrivacyNoticePage} />
        <Route path={Url.InformedConsentLetter} component={InformedConsentLetterPage} />
        <Route path={Url.Default}>
          <Redirect to={Url.Appointments.Main} />
        </Route>
      </Switch>
    </Layout>
  ) : (
    <AuthenticationRouter />
  );
}

export default App;
