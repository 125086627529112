import { CustomerFrequencyType } from "./enums/customer-frequency-type";
import { Gender } from "./enums/gender";
import { EvolutionNoteFieldAndValue } from "./evolution-note-field-and-value";

export class PatientDataResponse {
    firstName: string;
    lastName: string;
    dateOfBirth?: Date;
    gender?: Gender;
    customerFrequency: CustomerFrequencyType;

    email?: string;
    mobileCodeIso3: string;
    mobileDigits: number;
    mobileCode: number;
    mobileTemplate: string;
    mobile?: string;
    contactable: boolean;

    arrivedAppointmentsCount: number;
    totalAppointmentsCount: number;
    favoriteServiceName?: string;
    favoriteServiceRequestedCount: number;
    totalServicesCount: number;

    evolutionNoteSectionName?: string;
    evolutionNoteFieldsLastUpdated?: Date;
    evolutionNoteFieldsValues?: EvolutionNoteFieldAndValue[];
  
    constructor(x?: any) {
      if (typeof x === "object") {
        this.firstName = x.firstName;
        this.lastName = x.lastName;
        this.dateOfBirth = x.dateOfBirth;
        this.gender = x.gender;
        this.customerFrequency = x.customerFrequency;
        this.email = x.email;
        this.mobileCodeIso3 = x.mobileCodeIso3;
        this.mobileDigits = x.mobileDigits;
        this.mobileCode = x.mobileCode;
        this.mobileTemplate = x.mobileTemplate;
        this.mobile = x.mobile;
        this.contactable = x.contactable;
        this.arrivedAppointmentsCount = x.arrivedAppointmentsCount;
        this.totalAppointmentsCount = x.totalAppointmentsCount;
        this.favoriteServiceName = x.favoriteServiceName;
        this.favoriteServiceRequestedCount = x.favoriteServiceRequestedCount;
        this.totalServicesCount = x.totalServicesCount;
        this.evolutionNoteSectionName = x.evolutionNoteSectionName;
        this.evolutionNoteFieldsLastUpdated = x.evolutionNoteFieldsLastUpdated;
        this.evolutionNoteFieldsValues = x.evolutionNoteFieldsValues;
      }
      else {
        this.firstName = "";
        this.lastName = "";
        this.dateOfBirth = undefined;
        this.gender = undefined;
        this.customerFrequency = CustomerFrequencyType.Inactive;
        this.email = undefined;
        this.mobileCodeIso3 = "MEX";
        this.mobileDigits = 10;
        this.mobileCode = 52;
        this.mobileTemplate = "000-000-0000";
        this.mobile = undefined;
        this.contactable = false;
        this.arrivedAppointmentsCount = 0;
        this.totalAppointmentsCount = 0;
        this.favoriteServiceName = undefined;
        this.favoriteServiceRequestedCount = 0;
        this.totalServicesCount = 0;
        this.evolutionNoteSectionName = undefined;
        this.evolutionNoteFieldsLastUpdated = undefined;
        this.evolutionNoteFieldsValues = undefined;
      }
    }
  }