import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        paddingBottom: 32
    },
    sectionContainer: {
        border: "1px solid",
        borderColor: "#DBDCDD",
        boxSizing: "border-box",
        borderRadius: "12px 12px 12px 12px",
        height: "auto",
        overflow: "hidden",
    },
    headerContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        backgroundColor: Color.gray1,
        height: 57,
        width: "100%",
        paddingLeft: 32,
        paddingRight: 22
    },
    headerTitle: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray7
    },
    headerButtonsContainer: {

    },
    headerButton: {
        "&.MuiButton-root": {
            minWidth: 0
        },
    },
    headerButtonDisabled: {
        "&.MuiButton-root": {
            minWidth: 0
        },
        "& svg path": {
            fill: Color.gray2
        }
    },
    innerLayout: {
        width: "100%",
        padding: 16
    },
    fieldsContainer: {

    },
    noItemsBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: 80,
        width: "100%",
        backgroundColor: Color.gray1,
        borderRadius: 10,
    },
    noItemsLabel: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "21px",
        lineHeight: "25px",
        color: Color.gray4
    },
    footerContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        paddingTop: 24,
        paddingBottom: 8
    },
    newButton: {
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "space-between",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        background: Color.clear2,
        color: Color.gray1,
        "&:hover": {
            background: Color.clear1,
        },
    },
    deleteConfirmationModal: {
        height: "auto !important"
    },
    deleteModalContentContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 15
    },
    deleteModalTitleText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#26292B",
        textAlign: "center"
    },
    deleteModalContentText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray5,
        textAlign: "center"
    },
    deleteModalContentBoldText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray5
    },
    separator: {
        height: 0,
        borderTop: "1px solid rgba(201, 205, 211, 0.38)",
        margin: "16px 0px 16px 0px",
        width: 217
    },
    sectionEditNameInput: {
        backgroundColor: Color.white + " !important",
        border: "1px solid " + Color.gray2,
        borderRadius: "5px !important" ,
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "14px",
        color: Color.gray5 + " !important"
    },
    
}));

export default useStyles;