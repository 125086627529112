import { ApiClient } from "./api-client";
import BlockedSchedule from "../models/blocked-schedule";
import { Response } from "../models/response";
import { Appointment } from "../models/appointment";
import BlockSchedule from "../models/blocked-schedule";


export default class BlockedScheduleService {

    private static baseUrl: string = "/blockedschedule";

    private static isoDate(date: Date): string {
        const year = date.toLocaleString("en-US", {
          year: "numeric",
        });
        const month = date.toLocaleString("en-US", {
          month: "2-digit",
        });
        const day = date.toLocaleString("en-US", {
          day: "2-digit",
        });
    
        return `${year}-${month}-${day}`;
    }

    private static handleTime(date: Date): string {
        const time = date.toLocaleString("en-US", {
        hourCycle: "h23",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit"
        });

        return `${this.isoDate(date)}T${time}`;
    }

    static createBlockedSchedule(blocked: BlockedSchedule, forceSave: boolean = false): Promise<any> {
        const blockSchedule = {
            ...blocked,
            start: this.handleTime(blocked.start.toJSDate()),
            end: this.handleTime(blocked.end.toJSDate()),
            forceSave
        }

        return ApiClient
          .post(`${this.baseUrl}/createBlockedSchedule`, blockSchedule)
          .then(data => {
              const blockedschedule = new BlockedSchedule(data.blockedschedule);
              return {
                  blockedschedule,
                  forbiddenToBlock: data.forbiddenToBlock,
                  appointmentsNotServed: data.appointmentsNotServed,
              }
          });
    }

    static updateBlockedSchedule(blocked: BlockedSchedule, forceSave: boolean = false): Promise<any> {
        const blockSchedule = {
            ...blocked,
            start: this.handleTime(blocked.start.toJSDate()),
            end: this.handleTime(blocked.end.toJSDate()),
            forceSave
        }

        return ApiClient
                .put(`${this.baseUrl}/updateBlockedSchedule`, blockSchedule)
                .then(data => {
                    const blockedschedule = new BlockedSchedule(data.blockedschedule);
                    return {
                        blockedschedule,
                        forbiddenToBlock: data.forbiddenToBlock,
                        appointmentsNotServed: data.appointmentsNotServed,
                    }
                });
    }

    static getBlockedScheduleByAccount(dateFrom: Date, dateTo: Date): Promise<any[]> {
        const params = `${"?DateFrom=" + dateFrom.toISOString().replace("Z", "") + "&DateTo=" + dateTo.toISOString().replace("Z", "")}`;
        let urlEndpoint = `${this.baseUrl}/getblockedschedule${params}`;
        return ApiClient.get(urlEndpoint).then((data) => data.blockedSchedules.map((a: any) => new BlockSchedule(a)));
    }
    
    static getBlockedScheduleByEstablishments(dateFrom: Date, dateTo: Date, establishmentIds: string[] | undefined): Promise<any[]> {
        let urlEndpoint = `${this.baseUrl}/getblockedschedulebyestablishments?` +
          Object.entries({
              DateFrom: dateFrom.toISOString().replace("Z", ""),
              DateTo: dateTo.toISOString().replace("Z", ""),
              EstablishmentIds: establishmentIds && establishmentIds.length > 0 ? establishmentIds : [],
          }).flatMap(([key, value]) => [value].flat().map(v => [key, v]))
            .map(it => it.join("="))
            .join("&");
        return ApiClient.get(urlEndpoint).then((data) => data.blockedSchedules.map((a: any) => new BlockSchedule(a)));
    }

    
    static getBlockedScheduleById(id: string): Promise<BlockSchedule> {
        const params = `?id=${id}`;
        let urlEndpoint = `${this.baseUrl}/getblockedschedulebyid${params}`;
        return ApiClient.get(urlEndpoint).then((data) => {
            const a = data.blockedSchedule;
            return new BlockSchedule(a);
        });
    }

    static deleteBlockedSchedule(blocked: BlockedSchedule): Promise<any> {
        return ApiClient.remove(`${this.baseUrl}/deleteBlockedSchedule?id=${blocked.id}`)
            .then(data => {
                return {
                    entity: new BlockedSchedule(data.blockedschedule),
                    forbiddenDelete: data.forbiddenDelete
                };
            });
    }

}
