import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";
export const useStyles = makeStyles((theme) => ({
    root: {},
    conversationBox: {
        width: "360px",
        maxWidth: "360px",
        height: "80px",

        borderBottom: "1px solid",
        borderBottomColor: theme.colors.grey2,
        padding: "16px 16px 16px 40px",
    },
    conversationSelected: {
        backgroundColor: theme.colors.clear5,
    },
    conversationNotSelected: {
        backgroundColor: theme.colors.grey1 + " !important",
        "&:hover": {
            backgroundColor: theme.colors.clear5 + " !important",
        },
    },
    conversationEnabled: {
        backgroundColor: theme.colors.clear5,
        color: theme.colors.grey5
    },
    conversationDisabled: {
        color: theme.colors.grey3
    },
    customerContactName: {
        display: "flex",
        alignItems: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        "& svg": {
            marginRight: "5px"
        }
    },
    establishmentName: {
        ...theme.typography.txtBody2,
        paddingLeft: 2,
    },
    messagePreview: {
        ...theme.typography.txtBody2,
    },
    dateTime: {
        ...theme.typography.txtBody3,
    },
    messageCounter: {
        backgroundColor: theme.colors.clearBlue,
        color: theme.colors.grey1,
    },
    messageSentIcon: {
        color: theme.colors.grey5,
        width: "14px",
    },
    messageDeliveredIcon: {
        color: theme.colors.grey5,
        width: "20px",
    },
    messageReadIcon: {
        color: theme.colors.clearBlue,
        width: "20px",
    }
}));