import React from "react";

import { FormControlLabel } from "@material-ui/core";

import useStyles from "./css";
import { SwitchWithLabelProps } from "./props";
import MuiSwitch from "@material-ui/core/Switch";


export default function SwitchWithLabel(props: SwitchWithLabelProps) {
  
  const { className, value, setValue, label, icon, disabled } = props;
  
  const classes = useStyles(value)();

  const Icon = icon;

  const Switch =
    <MuiSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      onChange={() => setValue(!value)}
      checked={value}
    />
  
  return (
    <FormControlLabel
      className={className}
      classes={{
        label: classes.label
      }}
      disabled={disabled ?? false}
      value={value}
      control={Switch}
      labelPlacement="end"
      label={<span><span className={icon && classes.icon}>{icon && <Icon />}</span>{label}</span>}
    />
  );
}