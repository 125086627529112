import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import MuiSelect from "@material-ui/core/Select";
import Input from "@material-ui/core/Input";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import { InputAdornment, TextField } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";

import { JobPositionSelectProps, SelectItem } from "./props";
import useStyles from "./css";
import { EditIcon, PlusIcon, TrashIcon } from "../../../../../assets/icons";

export default function JobPositionSelect(props: JobPositionSelectProps) {

  const { label, labelClass, placeholder, items, itemState, setItemState, width, onCreate, edit, remove, isRefresh } = props;

  const [addButtonState, setAddButtonState] = useState<"button" | "input">("button");
  const [addInputValue, setAddInputValue] = useState<string>("");

  const { t } = useTranslation("settings");
  const classes = useStyles(width)();

  function isItemSelected(item: SelectItem) {
    if (typeof itemState === null || typeof item === null) return false;
    return item?.id === itemState?.id;
  }

  const resetAddInputState = () => {
    setAddButtonState("button");
    setAddInputValue("");
  };

  const handleOnChange = (event: any) => {
    const item = items.find(x => x.id === event.target.value) ?? null;
    setItemState(item);
    resetAddInputState();
  };

  const handleEdit = (item: any, event: any) => {
    if (edit) {
      edit(item);
      event.stopPropagation();
    }
  };

  const handleRemove = (item: any, event: any) => {
    if (remove) {
      remove(item);
      event.stopPropagation();
    }
  };

  const handleAddButtonClick = (event: any) => {
    setAddButtonState("input");
    event.stopPropagation();
  };

  const handleAddInputClick = (event: any) => {
    event.target.focus();
    event.stopPropagation();
  };

  const handleAddInputChange = (event: any) => {
    setAddInputValue(event.target.value);
    event.target.focus();
  };

  const handleOnCreate = (event: any) => {
    if (onCreate) {
      onCreate(addInputValue);
      event.stopPropagation();
    }
    resetAddInputState();
  };

  const LabelComponent = label;

  const getItemName = (item: SelectItem) => {
    return item.name.substring(0, 3) === "jp_" ? t(item.name) : item.name;
  };

  return (
    <div className={classes.root}>
      <label className={labelClass ?? classes.label}>
        {typeof label === "string" ? label : <LabelComponent/>}
      </label>
      <MuiSelect
        disableUnderline
        className={classes.select}
        MenuProps={{
          classes: { paper: classes.menuPaper },
          getContentAnchorEl: null,
          disablePortal: true,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
        }}
        classes={{ root: classes.focused }}
        input={<Input/>}
        displayEmpty
        onChange={handleOnChange}
        onClose={resetAddInputState}
        value={itemState?.id || ""}
      >
        {placeholder ? (
          <MenuItem value="" disabled>
            {placeholder}
          </MenuItem>
        ) : null}
        {items.map((item: SelectItem) =>
          <MenuItem
            key={item.id}
            value={item.id}
            className={classes.menuItem}
          >
            {getItemName(item)}
            {!isItemSelected(item) && item.isEditable &&
                <div className={classes.icons}>
                    <EditIcon
                        onClick={(event: any) => handleEdit(item, event)}
                        className={classes.editIcon}
                    />
                    <TrashIcon
                        onClick={(event: any) => handleRemove(item, event)}
                        style={{ width: 13, height: 17, color: "#919CA5" }}
                    />
                </div>
            }
          </MenuItem>
        )}
        <MenuItem value="d0429362-35e0-43dc-8acd-a8f57be02c27">
          {addButtonState === "button" ?
            <Button
              className={classes.addButton}
              variant="outlined"
              color="primary"
              onClick={handleAddButtonClick}
            >
              {t("New job position")}
            </Button>
            :
            <>
              <TextField
                variant="outlined"
                value={addInputValue}
                placeholder={t("Job title")}
                onChange={handleAddInputChange}
                onClick={handleAddInputClick}
                onKeyDown={e => e.stopPropagation()}
                InputProps={{
                  className: classes.input,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        onClick={(event: any) => handleOnCreate(event)}
                        disabled={addInputValue.length === 0}
                      >
                        <PlusIcon
                          style={{ width: 16, height: 16, color: addInputValue.length === 0 ? "#ACB7C0" : "#6462F3" }}
                        />
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />

            </>
          }
        </MenuItem>
      </MuiSelect>
    </div>
  );
}