import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CalendarCancelIcon = (props: any) => {
  const { style, ...otherProps } = props;
  return (
    <SvgIcon style={style} {...otherProps} width="18" height="20" viewBox="0 0 18 20" fill="none" >
     <path d="M6.7 16.7L5.3 15.3L7.6 13L5.3 10.7L6.7 9.3L9 11.6L11.3 9.3L12.7 10.7L10.4 13L12.7 15.3L11.3 16.7L9 14.4L6.7 16.7ZM2 20C1.45 20 0.979 19.8043 0.587 19.413C0.195667 19.021 0 18.55 0 18V4C0 3.45 0.195667 2.97933 0.587 2.588C0.979 2.196 1.45 2 2 2H3V0H5V2H13V0H15V2H16C16.55 2 17.021 2.196 17.413 2.588C17.8043 2.97933 18 3.45 18 4V18C18 18.55 17.8043 19.021 17.413 19.413C17.021 19.8043 16.55 20 16 20H2ZM2 18H16V8H2V18ZM2 6H16V4H2V6ZM2 6V4V6Z" 
        fill="#6462F3"
      />
    </SvgIcon>
  );
};

export default CalendarCancelIcon;
