import { UserInfoModel } from "./interfaces/user-info-model"

export class UserInfo implements UserInfoModel {
  email: string | null;
  username: string | null;
  fullName: string | null;
  accountId: string | null;
  userId: string | null;
  isAdministrator: boolean;
  hasCustomerInfoVisible: boolean;
  hasMassiveSendings: boolean;
  canRegisterPayments: boolean;
  canOpenCloseCashRegister: boolean;
  canMakeCashRegisterMovements: boolean;
  hasAccessToReports: boolean;
  canSeeClinicalFile: boolean;
  canInteractWithEvolutionNotes: boolean;
  canInteractWithPatientFiles: boolean;
  hasAccessToWhatsAppConversationsAndNotifications: boolean;
  hasAccessToScheduleAllSpecialists: boolean;
  canPrescribeTreatment: boolean;
  showPrices: boolean;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.email = x.email;
      this.username = x.username;
      this.fullName = x.fullname;
      this.accountId = x.accountid;
      this.userId = x.userid;
      this.isAdministrator = x.isadministrator === 'True';
      this.hasCustomerInfoVisible = x.hascustomerinfovisible === 'True';
      this.hasMassiveSendings = x.hasmassivesendings === 'True';
      this.canRegisterPayments = x.canregisterpayments === 'True';
      this.hasAccessToReports = x.hasaccesstoreports === 'True';
      this.hasAccessToScheduleAllSpecialists = x.hasaccesstoscheduleallspecialists === 'True';
      this.canOpenCloseCashRegister = x.canopenclosecashregister === 'True';
      this.canMakeCashRegisterMovements = x.canmakecashregistermovements === 'True';
      this.canSeeClinicalFile = x.canseeclinicalfile === 'True';
      this.canInteractWithEvolutionNotes = x.caninteractwithevolutionnotes === 'True';
      this.canInteractWithPatientFiles = x.caninteractwithpatientfiles === 'True';
      this.hasAccessToWhatsAppConversationsAndNotifications = x.hasaccesstowhatsappconversationsandnotifications === 'True';
      this.canPrescribeTreatment = x.canprescribetreatment === 'True';
      this.showPrices = x.showprices === 'True';
    } else {
      this.email = null;
      this.username = null;
      this.fullName = "Unknown user";
      this.accountId = null;
      this.userId = null;
      this.isAdministrator = false;
      this.hasCustomerInfoVisible = false;
      this.hasMassiveSendings = false;
      this.canRegisterPayments = false;
      this.canOpenCloseCashRegister = false;
      this.canMakeCashRegisterMovements = false;
      this.canSeeClinicalFile = false;
      this.canInteractWithEvolutionNotes = false;
      this.canInteractWithPatientFiles = false;
      this.hasAccessToWhatsAppConversationsAndNotifications = false;
      this.hasAccessToReports = false;
      this.hasAccessToScheduleAllSpecialists = false;
      this.canPrescribeTreatment = false;
      this.showPrices = false;
    }
  }
}