import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { TerminationDateProps } from "./props";
import useStyles from "./css";
import RadioWithText from "../../../common/RadioWithText";
import DateInput from "../../../common/DateInput";


export default function TerminationDate(props: TerminationDateProps) {
  
  const { date, setDate, nullText, minDate } = props;
  const classes = useStyles();
  const { t } = useTranslation("general");
  
  const neverEndsState = "null";
  const dateState = "date";
  const innerMinDate = minDate ?? new Date(new Date().setDate(new Date().getDate() + 1));
  
  const [innerValue, setInnerValue] = useState<"null" | "date">("null");
  const [innerDate, setInnerDate] = useState<Date>(innerMinDate);
  
  useEffect(() => {
    if (date === null) {
      setInnerValue(neverEndsState)
      setInnerDate(innerMinDate);
    } else {
      setInnerValue(dateState);
      setInnerDate(date);
    }
  }, [])
  
  function handleNullCheck() {
    setInnerValue(neverEndsState);
    setDate(null);
  }
  
  function handleDateCheck() {
    setInnerValue(dateState);
  }
  
  function handleInnerDateChange(date: Date) {
    setInnerDate(date);
  }
  
  useEffect(() => {
    if (innerValue === neverEndsState) return;
    setDate(innerDate);
  }, [innerDate, innerValue])
  
  return (
    <div className={classes.root}>
      {nullText &&
        <div className={classes.controlRow}>
          <RadioWithText 
            value={neverEndsState}
            checked={innerValue === neverEndsState}
            onCheck={handleNullCheck}
            text={nullText}
          />
        </div>
      }
      <div className={classes.controlRow}>
        <RadioWithText
          value={dateState}
          checked={innerValue === dateState}
          onCheck={handleDateCheck}
          text={
            <DateInput
              disabled={innerValue === neverEndsState}
              placeholder={t("dd/mm/yy")}
              format={"dd/MMMM/yyyy"}
              value={innerDate}
              onChange={handleInnerDateChange}
              minDate={minDate}
              width={140}
            />
          }
        />
      </div>
    </div>
  );
}