import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SortIcon = () => (
  <SvgIcon>
    <path
      d="M9.515 12.794c-.46 0-.691.653-.356 1.03l2.492 2.988c.188.25.502.25.712 0l2.492-2.988c.314-.377.083-1.03-.356-1.03H9.515zm5.34-2.636-2.492-2.989c-.21-.225-.524-.225-.712 0l-2.492 2.989c-.335.401-.105 1.03.356 1.03h4.984c.44 0 .67-.629.356-1.03z"
      fill="#5C6477"
    />
  </SvgIcon>
);

export default SortIcon;
