import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #dbdcdd",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: "32px 24px",
    width: 464,
    height: 237,
  },
  content: {
    display: "flex",
    flexDirection: "column"
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    marginBottom: "32px"
  },
  modalHeader: {
    alignSelf: "flex-start",
    marginBottom: "24px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    color: theme.colors.grey5
  },
  modalFooter: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-evenly"
  },
  goBack: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  continueButton: {
    background: "#36CE91",
    color: "#F6F6F6",
    "&:hover": {
      background: "#36CE91",
    },
  },
  createDisabled: {
    color: "#F6F6F6 !important",
    opacity: "0.4",
  },
}));

export default useStyles;