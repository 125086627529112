import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { Button, Typography } from "@material-ui/core";

import useStyles from "./css";
import { InfoIcon, SectionEditIcon } from "../../../../../assets/icons";
import IconTooltip from "../../../../common/IconTooltip";
import CommissionList from "../CommissionList";
import EditTerminologyModal from "../../../../common/EditTerminologyModal";
import { TerminologyType } from "../../../../../models/enums/terminology-type";
import { TerminologyForm } from "../../../../../models/enums/terminology-form";
import useTerminology from "../../../../../hooks/useTerminology";
import { SettingsEnterpriseProps } from "../../../../../models/interfaces/settings-enterprise-props";
import { AccountService } from "../../../../../api/account-service";
import SectionNote from "../../../../establishment-settings/Sections/FormsSettingsTabs/SectionNote";
import { SectionNoteData } from "../../../../../models/enums/section-note-data";


export default function CommissionsMain(props: SettingsEnterpriseProps) {

  const { settingsSelectedEstablishmentId } = props;

  const { t } = useTranslation(["settings"]);

  const classes = useStyles();
  
  const [isTerminologyModalOpen, setIsTerminologyModalOpen] = useState<boolean>(false);
  const [establishmentCommissions, setEstablishmentCommissions] = useState<string>('');

  const commissions = useTerminology({ type: TerminologyType.Commission, form: TerminologyForm.Plural });

  useEffect(() => {
    async function fetchEstablishmentCommissions() {
      if (settingsSelectedEstablishmentId) {
        const result = await AccountService.getAccountTerminology(TerminologyType.Commission, settingsSelectedEstablishmentId);
        setEstablishmentCommissions(result.pluralForm);
      }
    }
    fetchEstablishmentCommissions();
  }, [settingsSelectedEstablishmentId, isTerminologyModalOpen]);

  return (
      <>
      {settingsSelectedEstablishmentId && (
        <>
          <div className={classes.header}>
            <Typography className={classes.tabTitle}>
              {t("Commissions")}
            </Typography>
          </div>
          <SectionNote 
            sectionData={SectionNoteData.Commissions}
          />
        </>
      )}
      <div className={classes.commissionsHeaderBlock}>
        <div className={classes.title}>
        {settingsSelectedEstablishmentId ? establishmentCommissions : commissions}
        </div>
        <IconTooltip
          icon={
          <InfoIcon
            style={{ width: 12, height: 12, marginBottom: 1 }}
            viewBox={"0 0 12 12"}
          />}
          tooltipText={t("The payment from an establishment to a user for performing services")}
          placement={"right"}
          arrowPlacement={"left"}
          classNameRoot={classes.iconTooltipRoot}
        />
        <Button
          onClick={() => setIsTerminologyModalOpen(true)}
          className={classes.actionButton}
        >
          <SectionEditIcon />
        </Button>
      </div>
      <CommissionList 
        settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
      />
      <EditTerminologyModal
        isOpen={isTerminologyModalOpen}
        setOpen={setIsTerminologyModalOpen}
        type={TerminologyType.Commission}
        settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
      />
    </>
  );
}