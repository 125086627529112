import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function InternalNotesIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M17.4444 5.6H14.1933C13.8667 4.672 13.0111 4 12 4C10.9889 4 10.1333 4.672 9.80667 5.6H6.55556C5.7 5.6 5 6.32 5 7.2V18.4C5 19.28 5.7 20 6.55556 20H17.4444C18.3 20 19 19.28 19 18.4V7.2C19 6.32 18.3 5.6 17.4444 5.6ZM12 5.6C12.4278 5.6 12.7778 5.96 12.7778 6.4C12.7778 6.84 12.4278 7.2 12 7.2C11.5722 7.2 11.2222 6.84 11.2222 6.4C11.2222 5.96 11.5722 5.6 12 5.6ZM13.5556 16.8H8.11111V15.2H13.5556V16.8ZM15.8889 13.6H8.11111V12H15.8889V13.6ZM15.8889 10.4H8.11111V8.8H15.8889V10.4Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}