import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 360,
    height: 72,
    display: "flex",
    justifyContent: "space-between"
  },
  titleBlock: {
    justifySelf: "flex-start",
    alignSelf: "center",
    marginLeft: 20
  },
  titleText: {
    ...theme.typography.header4,
    color: theme.colors.grey4,
    fontWeight: "bold",
  },
  closeIconButton: {
    
  },
  closeButtonContainer: {
    justifySelf: "flex-end",
    alignSelf: "center",
    marginRight: 25,
    color: theme.colors.clearMain
  }
  })
);

export default useStyles;