export class SectionFieldDeleteRequest {
  establishmentAccountId?: string|null;
  id: string;
  sectionId: string;
  order: number;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.establishmentAccountId = x.establishmentAccountId;
      this.id = x.id;
      this.sectionId = x.sectionId;
      this.order = x.order;
    } else {
      this.establishmentAccountId = null;
      this.id = "";
      this.sectionId = "";
      this.order = 0;
    }
  }
}