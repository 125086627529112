export class BankTransferInfo {
  accountId: string | null;
  bankTransferId: string | null;
  bankName: string;
  bankTransferType: number | null;
  cardNumber: string | null;
  accountNumber: string | null;
  interbankClabe: string | null;
  index: number;
  bankNameValid: boolean;
  cardNumberValid: boolean;
  interbankClabeValid: boolean;
  accountNumberValid: boolean;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.accountId = x.accountId;
      this.bankTransferId = x.bankTransferId;
      this.bankName = x.bankName;
      this.bankTransferType = x.bankInfoType;
      this.cardNumber = x.cardNumber;
      this.interbankClabe = x.interbankClabe;
      this.index = x.index;
      this.bankNameValid = x.bankNameValid;
      this.cardNumberValid = x.cardNumberValid;
      this.interbankClabeValid = x.interbankClabeValid;
      this.accountNumberValid = x.accountNumberValid;
      this.accountNumber = x.accountNumber;
    }
    else {
      this.accountId = null;
      this.bankTransferId = null;
      this.bankName = "";
      this.bankTransferType = 0;
      this.cardNumber = null;
      this.interbankClabe = null;
      this.index = 0;
      this.bankNameValid = true;
      this.cardNumberValid = true;
      this.interbankClabeValid = true;
      this.accountNumberValid = true;
      this.accountNumber = null;
    }
  }
}