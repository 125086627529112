import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexDirection: "column"
    },
    label: {
        display: "inline-flex",
        margin: 0,
        marginBottom: 5,
        fontFamily: "Inter, sans-serif",
        fontSize: "12px",
        fontWeight: 700,
        color: theme.colors.grey4
    },
    fieldsContainer: {
        width: 706,
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        columnGap: "18px",
        rowGap: "24px"
    },
    inputContainer: {
        width: 344,
        flex: `1 0 calc(50% - ${theme.spacing(3)}px)`
    },
    dot: {
        ...theme.typography.txtBody2,
        color: theme.colors.clearMain,
        marginLeft: 5
    },
    requiredFieldsNote: {
        ...theme.typography.txtBody2,
        color: theme.colors.grey3,
        marginTop: 13,
        marginBottom: 22
    },
    note: {
        ...theme.typography.txtBody2,
        color: theme.colors.grey3,
    },
    signSectionContainer: {
        display: "flex",
        flexDirection: "column",
        rowGap: 18
    },
    signInteractiveSection: {
        height: 233,
        width: 706,
    },
    modeButtonsBox: {
        display: "flex",
        height: 32,
        borderTop: "1px solid " + theme.colors.clearMain,
        borderLeft: "1px solid " + theme.colors.clearMain,
        borderRight: "1px solid " + theme.colors.clearMain,
        borderRadius: "5px 5px 0px 0px",
    },
    signModeButtonDisabled: {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        gap: 8,
        justifyContent: "center",
        backgroundColor: theme.colors.clearMain,
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        color: theme.colors.grey1,
        "&.MuiButton-root": {
            borderRadius: 0,
        },
        "&:hover": {
            background: theme.colors.clearMain,
        },
    },
    signModeButtonEnabled: {
        width: "50%",
        display: "flex",
        flexDirection: "row",
        gap: 8,
        justifyContent: "center",
        backgroundColor: theme.colors.white,
        fontFamily: "Roboto, sans-serif",
        fontSize: "14px",
        lineHeight: "20px",
        letterSpacing: "0.1px",
        color: theme.colors.clearMain,
        "&:hover": {
            background: theme.colors.white,
        },
    },
    rightSignModeButton: {
        "&.MuiButton-root": {
            borderRadius: "0px 4px 0px 0px",
        },
    },
    leftSignModeButton: {
        "&.MuiButton-root": {
            borderRadius: "4px 0px 0px 0px",
        },
    },
    signInteractiveArea: {
        width: 706,
        height: 201,
    },
    drawerBox: {
        position: "relative",
        width: 706,
        height: 201,
        borderRadius: "0px 0px 5px 5px",
        border: "1px solid " + theme.colors.clearMain,
    },
    dropzoneBoxEmpty: {
        width: 706,
        height: 201,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        background: theme.colors.clearLilac,
        borderRadius: "0px 0px 5px 5px",
        border: "1px dashed " + theme.colors.clearMain,

    },
    dropzoneBoxImage: {
        width: 706,
        height: 201,
        background: theme.colors.white,
        borderRadius: "0px 0px 5px 5px",
        border: "1px solid " + theme.colors.clearMain,
        backgroundSize: "contain !important",
    },
    canvasSignSectionFooter: {
        display: "flex",
        paddingBottom: 10,
    },
    uploadSignSectionFooter: {
        display: "flex",
        alignItems: "center",
        paddingBottom: 10,
        marginTop: -10
    },
    signSectionLabel: {
        display: "inline-flex",
        marginTop: 21,
        marginBottom: 15,
        fontFamily: "Inter, sans-serif",
        fontSize: "12px",
        fontWeight: 700,
        color: theme.colors.grey4
    },
    addPhotoLabel: {
        fontFamily: "Roboto, sans-serif",
        fontSize: "12px",
        fontWeight: 700,
        color: theme.colors.clearMain
    },
    recommendedSizeLabel: {
        fontFamily: "Inter, sans-serif",
        fontSize: "9px",
        fontWeight: 400,
        color: theme.colors.clear2
    },
    deleteCanvasSignButton: {
        marginLeft: "calc(100% - 123px)",
        width: 103,
        height: 32,
        background: theme.colors.white,
        color: theme.colors.grey4,
        borderRadius: "51px",
        border: "1px solid " + theme.colors.grey4,
        fontFamily: "Inter, sans-serif",
        fontSize: "12px",
        fontWeight: 700,
    },
    editUploadedSignButton: {
        marginLeft: "auto",
        "&.MuiButton-root": {
            minWidth: 0
        }
    },
    deleteUploadedSignButton: {
        marginRight: 16,
        "&.MuiButton-root": {
            minWidth: 0
        }
    },
    deleteConfirmationModal: {
        height: "200px !important"
    },
    deleteModalContentContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 15
    },
    deleteModalTitleText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#26292B",
        textAlign: "center"
    }
})
);

export default useStyles;