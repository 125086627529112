import { TreatmentDocumentSize } from "./enums/treatment-document-size";
import { TreatmentDocumentFieldSetting } from "./treatment-document-field-setting";

export class TreatmentDocumentFieldSettingsModel {
    documentSize: TreatmentDocumentSize;
    logoIsEnabled: boolean;
    logoUrl: string | null;
    logoFile: File | null;
    fieldSettings: TreatmentDocumentFieldSetting[];
    shouldRemoveLogo: boolean;
    specialistJobPositionName: string;
    specialistPhoneNumberIso: string;
    specialistPhoneNumberBody: string;
    accountPhoneNumberIso: string;
    accountPhoneNumberBody: string;
    accountAddress: string;

    constructor(x?: any) {
        if (typeof x === "object") {
            this.documentSize = x.documentSize;
            this.logoIsEnabled = x.logoIsEnabled;
            this.logoUrl = x.logoUrl;
            this.logoFile = x.logoFile === undefined ? null : x.logoFile;
            this.fieldSettings = x.fieldSettings;
            this.shouldRemoveLogo = x.shouldRemoveLogo;
            this.specialistJobPositionName = x.specialistJobPositionName;
            this.specialistPhoneNumberIso = x.specialistPhoneNumberIso;
            this.specialistPhoneNumberBody = x.specialistPhoneNumberBody;
            this.accountPhoneNumberIso = x.accountPhoneNumberIso;
            this.accountPhoneNumberBody = x.accountPhoneNumberBody;
            this.accountAddress = x.accountAddress;
        }
        else {
            this.documentSize = TreatmentDocumentSize.Letter;
            this.logoIsEnabled = false;
            this.logoUrl = null;
            this.logoFile = null;
            this.fieldSettings = [];
            this.shouldRemoveLogo = false;
            this.specialistJobPositionName = "";
            this.specialistPhoneNumberIso = "";
            this.specialistPhoneNumberBody = "";
            this.accountPhoneNumberIso = "";
            this.accountPhoneNumberBody = "";
            this.accountAddress = "";
        }
    }
}