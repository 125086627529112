import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

import { Backdrop, Box, Button, ClickAwayListener, Fade, Grid, Modal, Typography } from "@material-ui/core";
import { SectionFieldModalProps } from "./props";
import useStyles from "./css";

import { PlusIcon, SectionEditIcon, SectionFieldEmailIcon, SectionFieldNewIcon, SectionFieldPhoneIcon, SectionFieldRadioIcon, SectionFieldRadioItemIcon, SectionFieldTextIcon } from "../../../../../assets/icons";
import InputWithLabel from "../../../../common/InputWithLabel";
import { SectionFieldModel } from "../../../../../models/section-field-model";
import SelectWithLabel from "../../../../common/SelectWithLabel";
import { SelectItem } from "../../../../common/Select";
import { SectionFieldType } from "../../../../../models/enums/section-field-type";
import { Color } from "../../../../../constants/colors";
import SwitchWithLabel from "../../../../common/SwitchWithLabel";
import { SectionFieldItemModel } from "../../../../../models/section-field-item-model";
import DraggableBase from "../../../../common/DraggableBase/draggable-base";
import Input from "../../../../common/Input";
import ConfirmDeleteModal from "../../../../common/ConfirmDeleteModal";
import ConfirmActionModal from "../../../../common/ConfirmActionModal";
import { SectionType } from "../../../../../models/enums/section-type";

export default function SectionFieldModal(props: SectionFieldModalProps) {
    const sectionTNamespace = "sections";
    const { t } = useTranslation(["general", sectionTNamespace]);
    const { isOpen, section, sections, mode, field, setOpen, onSave } = props;
    const classes = useStyles();
    const isNew = mode === "new";
    const maxNameLength = 200;

    const getFieldTypeSelectItemsForSectionType = (): SelectItem[] => {
        if (section.type === SectionType.ClinicHistory)
            return [
                {
                    key: SectionFieldType.Radio, value:
                        <div className={classes.fieldTypeSelectItemContainer}>
                            <SectionFieldRadioIcon style={{ color: Color.gray5 }} />
                            <Typography>
                                {t("Multiple choice")}
                            </Typography>
                        </div>
                },
                {
                    key: SectionFieldType.Text, value:
                        <div className={classes.fieldTypeSelectItemContainer}>
                            <SectionFieldTextIcon style={{ color: Color.gray5 }} />
                            <Typography>
                                {t("Free text")}
                            </Typography>
                        </div>
                }
            ]
        else if (section.type === SectionType.UserTreatment)
            return [
                {
                    key: SectionFieldType.Text, value:
                        <div className={classes.fieldTypeSelectItemContainer}>
                            <SectionFieldTextIcon style={{ color: Color.gray5 }} />
                            <Typography>
                                {t("Free text")}
                            </Typography>
                        </div>
                }
            ]
        else 
            return [
                {
                    key: SectionFieldType.Email, value:
                        <div className={classes.fieldTypeSelectItemContainer}>
                            <SectionFieldEmailIcon style={{ color: Color.gray5 }} />
                            <Typography>
                                {t("Email")}
                            </Typography>
                        </div>
                },
                {
                    key: SectionFieldType.PhoneNumber, value:
                        <div className={classes.fieldTypeSelectItemContainer}>
                            <SectionFieldPhoneIcon style={{ color: Color.gray5 }} />
                            <Typography>
                                {t("PhoneNumberPatientFormsSections")}
                            </Typography>
                        </div >
                },
                {
                    key: SectionFieldType.Radio, value:
                        <div className={classes.fieldTypeSelectItemContainer}>
                            <SectionFieldRadioIcon style={{ color: Color.gray5 }} />
                            <Typography>
                                {t("Multiple choice")}
                            </Typography>
                        </div>
                },
                {
                    key: SectionFieldType.Text, value:
                        <div className={classes.fieldTypeSelectItemContainer}>
                            <SectionFieldTextIcon style={{ color: Color.gray5 }} />
                            <Typography>
                                {t("Free text")}
                            </Typography>
                        </div>
                }
            ];
    }

    const defaultSectionField = new SectionFieldModel();
    const defaultControlTypeFieldItems: SectionFieldItemModel[] = [
        new SectionFieldItemModel({ id: 1, name: t("Option") + " " + 1, order: 1 }),
        new SectionFieldItemModel({ id: 2, name: t("Option") + " " + 2, order: 2 }),
    ];
    defaultSectionField.items = defaultControlTypeFieldItems;

    const [nameIsValid, setNameIsValid] = useState<boolean>(isNew ? false : true);
    const [formIsValid, setFormIsValid] = useState<boolean>(false);

    const [sectionSelectItems, setSectionSelectItems] = useState<SelectItem[]>([]);
    const [selectedSectionId, setSelectedSectionId] = useState<string>(section.id!);

    const [localField, setLocalField] = useState<SectionFieldModel>(isNew ? new SectionFieldModel() : new SectionFieldModel({ ...field, items: field!.items.map(i => ({ ...i })) }));
    const [editingItem, setEditingItem] = useState<SectionFieldItemModel | null>(null);
    const [deletingItem, setDeletingItem] = useState<SectionFieldItemModel | null>(null);

    const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] = useState<boolean>(false);
    const [closeConfirmationModalIsOpen, setCloseConfirmationModalIsOpen] = useState<boolean>(false);

    const [validationCaller, forceValidation] = useState<number>(-1);

    const itemInputRef = useRef<HTMLInputElement>();

    const fieldIdStub = localField.id ?? "fieldId";

    useEffect(() => {
        //when edit and nothing changed
        if (!isNew && JSON.stringify(localField, Object.keys(localField).sort()) === JSON.stringify(field, Object.keys(field!).sort())) {
            setFormIsValid(false);
            return;
        }
        //when new and default state and options are default
        if (isNew && JSON.stringify(localField, Object.keys(localField).sort()) === JSON.stringify(defaultSectionField, Object.keys(defaultSectionField).sort())
            && localField.type === SectionFieldType.Radio && JSON.stringify(localField.items) === JSON.stringify(defaultControlTypeFieldItems)) {
            setFormIsValid(false);
            return;
        }
        //when <2 items when control component
        if (localField.type === SectionFieldType.Radio && localField.items.length < 2) {
            setFormIsValid(false);
            return;
        }
        //when name isn't valid
        if (!nameIsValid) {
            setFormIsValid(false);
            return;
        }

        setFormIsValid(true);
    }, [localField, ...localField.items, validationCaller]);

    useEffect(() => {
        if (!isOpen)
            return;
        if (field) {
            const existingFieldCopy = new SectionFieldModel({ ...field, items: field!.items.map(i => ({ ...i })) })
            setLocalField(existingFieldCopy);
            setNameIsValid(true);
        }
        else {
            const newSectionField = new SectionFieldModel();
            newSectionField.items = defaultControlTypeFieldItems.map(i => ({ ...i }));
            setLocalField(newSectionField);
            setNameIsValid(false);
        }
    }, [field, mode, isOpen]);

    useEffect(() => {
        if (isNew && localField.type === SectionFieldType.Radio)
            setLocalField({ ...localField, items: defaultControlTypeFieldItems.map(i => ({ ...i })) });
        else if (isNew && localField.type !== SectionFieldType.Radio)
            setLocalField({ ...localField, items: [] })
        else if (!isNew && localField.type === SectionFieldType.Radio && field?.type !== SectionFieldType.Radio)
            setLocalField({ ...localField, items: defaultControlTypeFieldItems.map(i => ({ ...i })), isAdditionalTextareaEnabled: true })
        else if (!isNew && localField.type !== SectionFieldType.Radio && field?.type === SectionFieldType.Radio)
            setLocalField({ ...localField, items: field!.items.map(i => ({ ...i })) })
    }, [localField.type]);

    useEffect(() => {
        const selectItems: SelectItem[] = sections.sort((s1, s2) => s1.order! - s2.order!).map((s) => ({
            key: s.id,
            value: t(s.name!, { ns: sectionTNamespace })
        }));
        setSectionSelectItems(selectItems);
    }, [sections]);

    useEffect(() => {
        if (editingItem)
            itemInputRef?.current?.focus();
    }, [editingItem]);

    const getReorderedFieldItems = (items: SectionFieldItemModel[], prevOrder: number, newOrder: number, movedItemId: string) => {
        const result = Array.from(items);

        const positionsMoved = newOrder - prevOrder;
        const isMovedDown = positionsMoved > 0;
        const affectedItemsIds = isMovedDown
            ? items.filter(i => i.id === movedItemId ||
                i.order! <= newOrder &&
                i.order! >= prevOrder)
                .map(i => i.id)
            : items.filter(i => i.id === movedItemId ||
                i.order! >= newOrder &&
                i.order! <= prevOrder)
                .map(i => i.id);

        result.forEach(i => {
            if (!affectedItemsIds.includes(i.id!))
                return;
            if (i.order.toString() === movedItemId) {
                i.order = newOrder;
                return;
            }
            i.order = isMovedDown ? i.order! - 1 : i.order! + 1;
        });

        return result;
    };

    const handleOnClose = () => {
        setOpen(false);
        setNameIsValid(false);
        setFormIsValid(false);
        setCloseConfirmationModalIsOpen(false);
    };

    const handleAddButtonClick = async () => {
        if (!nameIsValid)
            return;

        await onSave(localField, selectedSectionId);
        handleOnClose();
    };

    const tryClose = () => {
        if (!isNew && JSON.stringify(localField, Object.keys(localField).sort()) === JSON.stringify(field, Object.keys(field!).sort())) {
            handleOnClose();
            return;
        }
        if (isNew && JSON.stringify(localField, Object.keys(localField).sort()) === JSON.stringify(defaultSectionField, Object.keys(defaultSectionField).sort())) {
            handleOnClose();
            return;
        }
        setCloseConfirmationModalIsOpen(true);
    };

    const handleNewItemButtonClick = () => {
        const newItems = Array.from(localField.items);
        const lastItemOrder = newItems.length;
        newItems.push(new SectionFieldItemModel({
            id: lastItemOrder + 1,
            name: t("Option") + " " + (lastItemOrder + 1).toString(),
            order: lastItemOrder + 1
        }));

        setLocalField({ ...localField, items: newItems });
    };

    const handleItemEditClicked = (item: SectionFieldItemModel) => {
        if (editingItem) {
            return;
        }
        const itemCopy = new SectionFieldItemModel({ id: item.id, name: item.name, order: item.order });
        setEditingItem(itemCopy);
    };

    function handleItemFocus(event: any) {
        event.target.select();
    }

    function handleItemKeyDown(event: any) {
        if (event.keyCode === 13)
            event.target.blur();
    }

    function handleItemInputClickAway() {
        handleItemEditBlur()
    }

    const handleItemEditBlur = () => {
        if (editingItem!.name.length === 0 || editingItem!.name.length > maxNameLength) {
            setEditingItem(null);
            return;
        }

        const updatedItems = Array.from(localField.items);
        const itemToUpdate = updatedItems.find(i => i.id === editingItem?.id)
        if (itemToUpdate && editingItem) {
            itemToUpdate.name = editingItem.name;
            setLocalField({ ...localField, items: updatedItems });
            setEditingItem(null);
        }
    };

    const handleItemNameChange = (event: React.ChangeEvent<{ value: string }>) => {
        const name = event.target.value;
        if (name.length > maxNameLength) {
            return;
        }

        if (editingItem)
            setEditingItem((prev) => (prev ? {
                ...prev,
                name: name
            } : null));
    };

    const handleDeleteItemButtonClicked = (item: SectionFieldItemModel) => {
        const itemToDelete = localField.items.find(i => i.id === item.id)
        if (itemToDelete) {
            setDeletingItem(itemToDelete);
            setDeleteConfirmationModalIsOpen(true);
        }
    };

    const handleDeleteItem = (item: SectionFieldItemModel) => {
        const itemOrder = item.order;
        const updatedItems = localField.items.filter(i => i.id !== item.id);
        updatedItems.forEach(i => {
            if (i.order! < itemOrder!)
                return;
            i.order = i.order! - 1;
        });

        setLocalField({ ...localField, items: updatedItems });
        setDeletingItem(null);
        setDeleteConfirmationModalIsOpen(false);
    };

    const handleNameChange = (event: React.ChangeEvent<{ value: string }>) => {
        const newName = event.target.value;
        if (newName.length > maxNameLength || newName.trim().length == 0) {
            setNameIsValid(false);
            setLocalField((prev) => ({
                ...prev,
                name: newName
            }));
            return;
        }

        setLocalField((prev) => ({
            ...prev,
            name: newName
        }));
        setNameIsValid(true);
    };

    const dragEndHandler = async (result: DropResult) => {
        const { destination, source, draggableId } = result;

        //dropped not at droppable
        if (!destination)
            return;

        //dropped at other item list
        if (destination.droppableId !== source.droppableId)
            return;

        //dropped at the same spot
        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;

        const prevOrder = source.index + 1;
        const newOrder = destination.index + 1;

        localField.items = getReorderedFieldItems(localField.items, prevOrder, newOrder, draggableId);
        forceValidation(Math.random());
    };

    const getItemIcon = (fieldType: SectionFieldType) => {
        switch (fieldType) {
            case SectionFieldType.Radio:
                return <SectionFieldRadioItemIcon />;
            default:
                return <></>;
        }
    };

    const getInnerItemContent = (item: SectionFieldItemModel) => {
        return <>
            {editingItem && editingItem.id === item.id
                ?
                <ClickAwayListener onClickAway={handleItemInputClickAway}>
                    <div className={classes.innerItemContentContainer}>
                        <Input
                            inputClass={classes.itemEditNameInput}
                            value={t(editingItem.name, { ns: sectionTNamespace })}
                            isValid={() => { return editingItem.name.length > 0 && editingItem.name.length <= maxNameLength }}
                            onChange={(event) => handleItemNameChange(event)}
                            onFocus={(event: any) => handleItemFocus(event)}
                            onKeyDown={(event: any) => handleItemKeyDown(event)}
                            onBlur={() => handleItemEditBlur()}
                            disabled={!editingItem || !localField.isEditable}
                            maxLength={maxNameLength}
                            width={500}
                            ref={itemInputRef}
                            autoFocus
                        />
                    </div>
                </ClickAwayListener>
                :
                <div
                    className={classes.innerItemContentContainer}
                    onClick={() => handleItemEditClicked(item)}
                >
                    {getItemIcon(localField.type)}
                    <Typography className={classes.itemNameLabel}>
                        {t(item.name!, { ns: sectionTNamespace })}
                    </Typography>
                </div>}
        </>
    };

    const mapItemsToComponents = (items: SectionFieldItemModel[]) => {
        return items.sort((i1, i2) => i1.order! - i2.order!).map(i =>
            <div onClick={() => handleItemEditClicked(i)}>
                <DraggableBase
                    key={i.order!.toString()}
                    className={classes.draggableClassName}
                    height={48}
                    draggableId={i.order!.toString()}
                    index={i.order! - 1}
                    isEditable={true}
                    isEditableByButton={false}
                    isRemovable={true}
                    onEditClick={() => { }}
                    onDeleteClick={() => { handleDeleteItemButtonClicked(i) }}
                >
                    {getInnerItemContent(i)}
                </DraggableBase>
            </div>
        )
    };

    const DeleteModalContent: React.FC = () => {
        return (<div className={classes.deleteModalContentContainer}>
            <Typography className={classes.deleteModalTitleText}>
                {t("Delete item response?")}
            </Typography>
        </div>);
    };

    const CloseModalContent: React.FC = () => {
        return (<div className={classes.closeModalContentContainer}>
            <Typography className={classes.closeModalTitleText}>
                {isNew
                    ? t("Are you sure you want to cancel the creation of the new item?")
                    : t("Are you sure you want to cancel the edition of the item?")}
            </Typography>
            <div className={classes.separator} />
            <Typography className={classes.closeModalContentText}>
                {t("The changes made will not be saved")}
            </Typography>
        </div>);
    };

    return <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={isOpen}
            onClose={tryClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }} >
            <Fade in={isOpen}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <div className={classes.modalTitle}>
                            {isNew
                                ? <>
                                    <SectionFieldNewIcon />
                                    <Typography className={classes.modalTitleText}>
                                        {t("New item")}
                                    </Typography>
                                </>
                                : <>
                                    <SectionEditIcon fill={Color.gray5} />
                                    <Typography className={classes.modalTitleText}>
                                        {t("Edit item")}
                                    </Typography>
                                </>}

                        </div>
                        <div className={classes.modalActions}>
                            <Button
                                className={`${classes.button} ${classes.goBack}`}
                                onClick={tryClose}
                            >
                                {t('Go back')}
                            </Button>
                            <Button
                                className={`${classes.button} ${classes.continueButton}`}
                                disabled={!formIsValid}
                                classes={{ disabled: classes.createDisabled }}
                                onClick={handleAddButtonClick}
                            >
                                {isNew ? t("Add") : t("Save changes")}
                            </Button>
                        </div>
                    </div>
                    <div className={classes.scrollbarWrapper}>
                        <div className={classes.content}>
                            <div className={classes.contentRowContainer}>
                                <InputWithLabel
                                    label={() => (<>{t("Name")} <span className={classes.requiredAsterisk}>*</span></>)}
                                    placeholder={t("Enter the name of the item")}
                                    value={t(localField.name, { ns: sectionTNamespace })}
                                    width={isNew ? 305 : 637}
                                    name="name"
                                    onChange={(e) => handleNameChange(e)}
                                    isValid={() => true}
                                />
                                {isNew
                                    ? <SelectWithLabel
                                        label={() => (<>{t("Add to")} <span className={classes.requiredAsterisk}>*</span></>)}
                                        items={sectionSelectItems}
                                        value={selectedSectionId}
                                        name={"sections"}
                                        width={305}
                                        isValid={() => true}
                                        onChange={(event) => setSelectedSectionId(event.target.value)} />
                                    : <></>}

                            </div>
                            <div className={classes.contentRowContainer}>
                                <SelectWithLabel
                                    label={() => (<>{t("Type")} <span className={classes.requiredAsterisk}>*</span></>)}
                                    items={getFieldTypeSelectItemsForSectionType()}
                                    value={localField.type}
                                    name={"fieldTypes"}
                                    width={305}
                                    isValid={() => true}
                                    onChange={(event) => setLocalField((prev) => ({
                                        ...prev,
                                        type: event.target.value
                                    }))} />
                                {localField.type === SectionFieldType.Radio
                                    ? <SwitchWithLabel
                                        value={localField.isAdditionalTextareaEnabled}
                                        setValue={(value) => setLocalField((prev) => ({
                                            ...prev,
                                            isAdditionalTextareaEnabled: value
                                        }))}
                                        label={t("Add free text area at the end")}
                                    />
                                    : <></>}
                            </div>
                            {localField.type === SectionFieldType.Radio
                                ? <>
                                    <div className={classes.contentRowContainer}>
                                        <DragDropContext onDragEnd={dragEndHandler}>
                                            <Droppable key={fieldIdStub} droppableId={fieldIdStub}>
                                                {(provided) => (
                                                    <Box style={{ width: "100%" }}>
                                                        <div className={classes.fieldItemsContainer} >
                                                            <Grid
                                                                container
                                                                justifyContent="center"
                                                                className={classes.fieldItemsLayout}
                                                                ref={provided.innerRef}
                                                                {...provided.droppableProps}
                                                            >
                                                                {mapItemsToComponents(localField.items)}
                                                                {provided.placeholder}
                                                            </Grid>
                                                        </div>
                                                    </Box>
                                                )}
                                            </Droppable>
                                        </DragDropContext>
                                    </div>
                                    <div className={classes.contentRowContainer}>
                                        <Button
                                            className={classes.addItemButton}
                                            onClick={handleNewItemButtonClick}
                                        >
                                            <PlusIcon style={{ paddingRight: 10 }} />
                                            {t("Add another option")}
                                        </Button>
                                    </div>
                                </>
                                : <></>}
                            <div className={classes.contentRowContainer}>
                                <SwitchWithLabel
                                    value={localField.isRequired}
                                    setValue={(value) => setLocalField((prev) => ({
                                        ...prev,
                                        isRequired: value
                                    }))}
                                    label={t("Required")} />
                            </div>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
        <ConfirmActionModal
            classModal={classes.closeConfirmationModal}
            content={<CloseModalContent />}
            open={closeConfirmationModalIsOpen}
            onClose={() => setCloseConfirmationModalIsOpen(false)}
            onConfirm={handleOnClose}
        />
        <ConfirmDeleteModal
            classModal={classes.deleteConfirmationModal}
            open={deleteConfirmationModalIsOpen}
            item={deletingItem}
            componentInfo={<DeleteModalContent />}
            onClose={() => { setDeleteConfirmationModalIsOpen(false); setDeletingItem(null); }}
            onDelete={() => deletingItem && handleDeleteItem(deletingItem)}
        />
    </>
};