import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: 40,
    height: 40,
    backgroundColor: "#FFF",
    "&:hover": {
      color: "#fff",
      backgroundColor: theme.colors.clearRed,
    },
    "&.blocked:hover": {
      color: "#fff",
      backgroundColor: theme.colors.clearGreen,
    },
  },
  tooltipBlock: {
    backgroundColor: theme.colors.grey1,
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    borderRadius: 5,
    boxShadow: "0px 4px 4px 0px",
  },
  })
);

export default useStyles;