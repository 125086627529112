import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MenuIconCustomer = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M8 0C3.584 0 0 3.584 0 8C0 12.416 3.584 16 8 16C12.416 16 16 12.416 16 8C16 3.584 12.416 0 8 0V0ZM10.888 5.072C11.744 5.072 12.432 5.76 12.432 6.616C12.432 7.472 11.744 8.16 10.888 8.16C10.032 8.16 9.344 7.472 9.344 6.616C9.336 5.76 10.032 5.072 10.888 5.072V5.072ZM6.088 3.808C7.128 3.808 7.976 4.656 7.976 5.696C7.976 6.736 7.128 7.584 6.088 7.584C5.048 7.584 4.2 6.736 4.2 5.696C4.2 4.648 5.04 3.808 6.088 3.808ZM6.088 11.112V14.112C4.168 13.512 2.648 12.032 1.976 10.144C2.816 9.248 4.912 8.792 6.088 8.792C6.512 8.792 7.048 8.856 7.608 8.968C6.296 9.664 6.088 10.584 6.088 11.112ZM8 14.4C7.784 14.4 7.576 14.392 7.368 14.368V11.112C7.368 9.976 9.72 9.408 10.888 9.408C11.744 9.408 13.224 9.72 13.96 10.328C13.024 12.704 10.712 14.4 8 14.4V14.4Z" 
            />
        </SvgIcon>
    )
};

export default MenuIconCustomer;
