import React from "react";
import { Route } from "react-router-dom";

import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useAppSelector } from "../../../redux/hooks";

import Login from "../Login";
import Recovery from "../Recovery";
import { Url } from "../../../constants/url";
import ResetPassword from "../ResetPassword";
import Join from "../Join";

export default function AuthenticationRouter() {

    const localeApp = useAppSelector(state => state.applicationInterface.locale);
    
    const wrapperCaptcha: any = (children: JSX.Element) => (
        <GoogleReCaptchaProvider
            language={localeApp}
            reCaptchaKey={process.env.REACT_APP_RECAPTCHA_TOKEN}>
            {children}
        </GoogleReCaptchaProvider>
    );

    return (<>
        <Route path={Url.Authentication.Login} component={(props: any) => wrapperCaptcha(<Login {...props}/>)} />
        <Route path={Url.Authentication.Logout} />
        <Route path={Url.Authentication.Recovery} component={(props: any) => wrapperCaptcha(<Recovery {...props}/>)} />
        <Route path={Url.Authentication.ResetPassword} component={(props: any) => wrapperCaptcha(<ResetPassword {...props}/>)} />
        <Route path={Url.Authentication.Join} component={(props: any) => wrapperCaptcha(<Join {...props}/>)} />
    </>);
}