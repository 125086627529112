import { makeStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";

const useStyles = makeStyles((theme) => ({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      '& input[type=number]::-webkit-inner-spin-button, input[type=number]::-webkit-outer-spin-button': {
        '-webkit-appearance': 'none',
        margin: '0',
      },
    },
    modalCancelPaper: {
      backgroundColor: theme.palette.background.paper,
      borderRadius: "12px",
      boxShadow: theme.shadows[5],
      padding: "24px",
      width: 472,
      minHeight: 153,
    },
    modalTitleSpace: {
      margin: "24px 0px 8px 0px",
    },
    modalContentText: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "17px",
      textAlign: "center",
      color: "#4A4D51",
    },
    modalTitleTextCancel: {
      marginTop: 0,
      marginBottom: 20,
      fontSize: 18,
      fontWeight: "bold"
    },
    modalActions: {
      display: "flex",
      justifyContent: "flex-end",
    },
    button: {
      width: 200,
      height: 32,
      borderRadius: 51,
      padding: "11px 16px",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 12,
      lineHeight: "15px",
    },
    goBack: {
      marginRight: 16,
      background: "#F6F6F6",
      color: "#ACB7C0",
    },
    cancelConfirmation: {
      background: theme.colors.clearGreen,
      color: "#F6F6F6",
      "&:hover": {
        background: theme.colors.clearGreen,
      },
    },
    inputContainer: {
      display: "flex",
      width: 344,
      gap: 5,
      alignItems: "center",
      marginBottom: 18
    },
    label: {
      fontFamily: "Inter",
      display: "inline-flex",
      margin: 0,
      color: "#4a4d51",
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: "bold",
      fontStyle: "normal",
      marginBottom: 5,
    },
    labelAppointment: {
      fontFamily: 'Roboto',
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: 12,
      color: theme.colors.grey4,
    },
    arrowContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    arrowUp: {
      width: '20px',
      height: '20px',
      backgroundColor: 'white',
      border: 'none',
      padding: "5px",
      outline: 'none',
      '&:before': {
        content: '""',
        display: 'block',
        width: '0',
        height: '0',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderBottom: '5px solid black',
      },
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        backgroundColor: 'lightgray',
        borderRadius: '50%',
      },
    },
    
    arrowDown: {
      width: '20px',
      height: '20px',
      backgroundColor: 'white',
      border: 'none',
      padding: "5px",
      outline: 'none',
      '&:before': {
        content: '""',
        display: 'block',
        width: '0',
        height: '0',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent',
        borderTop: '5px solid black',
      },
      '&:focus': {
        outline: 'none',
      },
      '&:hover': {
        backgroundColor: 'lightgray',
        borderRadius: '50%',
      },
    },
    tooltipBlock: {
      backgroundColor: theme.colors.grey1,
      ...theme.typography.txtBody2,
      color: theme.colors.grey5,
    },
    cellWithTooltip: {
      cursor: "pointer !important",
    },
    arrowButtonContainer: {
      cursor: 'pointer',
      borderRadius: '100%',
    },
    cancelCustomRecurrenceModal: {
      height: "180px !important"
    },
    closeModalContentContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column"
    },
    closeModalTitleText: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "22px",
      color: "#26292B",
      textAlign: "center",
      maxWidth: "80%"
    },
    modalHeader: {
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      color: theme.colors.grey5
    },
    subhead: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      color: theme.colors.grey4
    },
    inputInfo: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      color: theme.colors.grey4
    },
    marginLeft10: {
      marginLeft: 10
    },
    marginRight10: {
      marginRight: 10
    },
    daysWeekContainer: {
      marginBottom: 15,
      marginLeft: 15,
      display: 'flex',
      gap: 10
    },
    daysWeekTitle: {
      fontFamily: 'Roboto',
      fontSize: '12px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: 'normal',
      color: theme.colors.grey4,
      
      paddingBottom: 13,
      paddingLeft: 6,
    },
  })
);

export default useStyles;