import React from "react";

import InputLabel from "@material-ui/core/InputLabel";

import Select from "./../Select";

import useStyles from "./css";
import { SelectWithLabelProps } from "./props";

const SelectWithLabel = (props: SelectWithLabelProps) => {
  const {
    label,
    value,
    placeholder,
    name,
    items,
    width,
    onChange,
    onBlur,
    styleClass,
    itemRootClass,
    isValid,
    disabled,
    disableScrollLock,
    colorArrowIcon,
    showTooltip,
    rootStyle
  } = props;
  const classes = useStyles();

  const LabelComponent = label;

  const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div className={classes.root} style={rootStyle}>
      <InputLabel
        className={`${classes.label} ${disabled ? classes.disabled : ""}`}
      >
        {typeof label === "string" ? label : <LabelComponent />}
      </InputLabel>
      <Select
        styleClass={styleClass}
        itemRootClass={itemRootClass}
        name={name}
        placeholder={placeholder}
        width={width}
        items={items}
        value={value}
        isValid={isValid}
        onChange={handleOnChange}
        onBlur={(e) => { if (onBlur) onBlur(e)}}
        disabled={disabled}
        disableScrollLock={disableScrollLock}
        colorArrowIcon={colorArrowIcon}
        showTooltip={showTooltip}
      />
    </div>
  );
};

export default SelectWithLabel;
