import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid",
    borderColor: theme.colors.clearYellow,
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    width: 534,
    height: 311,
    padding: "16px 24px",
  },
  closeButton: {
    position: "absolute",
    width: 24,
    height: 24,
    top: 18,
    right: 28,
    color: theme.colors.grey4
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  exclamationIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 18,
    width: 50,
    height: 50,
    borderRadius: 25,
    borderColor: theme.colors.clearYellow,
    color: theme.colors.clearYellow,
    border: "1px solid"
  },
  modalTitle: {
    ...theme.typography.h4,
    fontFamily: "Inter",
    fontWeight: "bold",
    color: theme.colors.grey7,
    justifySelf: "center",
    marginBottom: 24
  },
  modalText: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
  },
  modalTextBlock: {
    height: 84,
    marginBottom: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    textAlign: "center"
  },
  modalFooter: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  goBackButton: {
    marginRight: 16,
    background: theme.colors.grey1,
    color: theme.colors.grey3,
  },
  submitButton: {
    background: theme.colors.clearGreen,
    color: "#FFFFFF",
    "&:hover": {
      background: theme.colors.clearGreen,
    },
  }
}));

export default useStyles;