import { ApiClient } from "./api-client";
import { JobPosition } from "../models/job-position";


export class JobPositionService {
  private static baseUrl: string = "/jobposition";
  
  static getJobPositionList(establishmentAccountId?: string) : Promise<JobPosition[]> {
    let endpoint = `${this.baseUrl}/getlist`;
    if (establishmentAccountId) {
          endpoint += `?accountId=${establishmentAccountId}`;
    }
    return ApiClient.get(endpoint).then(
      (data) => data.jobPositions
    );
  }
  
  static createJobPosition(jobPositionName: string, establishmentAccountId?: string) : Promise<JobPosition> {
    let request: any = { name: jobPositionName }
    if (establishmentAccountId) {
        request.accountId = establishmentAccountId;
    }
    return ApiClient.post(`${this.baseUrl}/create`, request).then(
      (data) => new JobPosition(data)
    );
  }
  
  static updateJobPosition(jobPosition: JobPosition, establishmentAccountId?: string) : Promise<JobPosition> {
    let request: any = { jobPosition: jobPosition }
    if (establishmentAccountId) {
        request.accountId = establishmentAccountId;
    }
    return ApiClient.put(`${this.baseUrl}/update`, request).then(
      (data) => new JobPosition(data)
    );
  }

  static deleteJobPosition(jobPositionId: string, establishmentAccountId?: string) : Promise<any> {
    let endpoint = `${this.baseUrl}/delete?jobPositionId=${jobPositionId}`;
    if (establishmentAccountId) {
        endpoint += `&accountId=${establishmentAccountId}`;
    }
    return ApiClient.remove(endpoint);
  }
}