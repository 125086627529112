import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DiaryIcon = () => (
  <SvgIcon  style={{ width: 266, height: 191 }}
           viewBox={ "0 0 266 191"}>
    <path
      d="M 65.6182 177.423 C 65.6182 177.423 76.259 186.63 78 186.5 C 79.1836 186.434 184.327 190.828 185.5 191 L 70.1187 175.861 L 65.6182 177.423 Z"
      fill="#6462F3"
    />
    <path
      d="M 0.782343 55.4022 C -4.30393 95.4126 16.2201 136.431 48.6458 160.419 C 81.0716 184.408 123.742 192.326 163.668 186.578 C 192.171 182.494 220.44 171.319 240.529 150.694 C 260.618 130.068 271.208 99.0798 263.116 71.4481 C 260.057 61.0132 254.053 50.9002 244.524 45.6589 C 229.307 37.311 210.536 43.4288 194.329 49.7017 C 178.122 55.9746 159.381 62.1521 144.128 53.8757 C 132.411 47.5014 126.389 34.3773 117.803 24.169 C 103.2 6.81121 79.4736 -2.4073 56.9818 0.544297 C 54.1657 0.91488 51.377 1.47063 48.6339 2.20792 C 24.7051 8.62987 6.61398 28.2893 1.39055 52.5102 L 0.782343 55.4022 Z"
      fill="#E1E9FE"
    />
    <path
      opacity={0.6}
      d="M 185.251 190.924 L 78.3671 186.281 C 78.0422 186.268 77.7231 186.19 77.4282 186.053 C 77.1333 185.916 76.8684 185.722 76.6487 185.482 C 76.429 185.242 76.2588 184.962 76.148 184.656 C 76.0371 184.35 75.9877 184.026 76.0026 183.701 C 77.8053 124.829 80.7254 54.9563 82.4108 34.2994 C 82.4553 33.6552 82.7503 33.054 83.2327 32.6247 C 83.715 32.1955 84.3464 31.9722 84.9914 32.0028 L 191.949 36.6453 C 192.278 36.6579 192.601 36.736 192.899 36.8751 C 193.197 37.0142 193.464 37.2114 193.685 37.4553 C 193.906 37.6991 194.076 37.9847 194.185 38.2951 C 194.294 38.6055 194.34 38.9346 194.32 39.2629 L 187.838 188.516 C 187.812 189.178 187.525 189.802 187.041 190.253 C 186.556 190.705 185.913 190.946 185.251 190.924 Z"
      fill="#6462F3"
    />
    <path
      d="M 184.287 188.641 L 77.5333 184.788 L 69.1187 178.343 L 74.1872 25.4351 L 184.769 32.973 L 190.233 39.0973 L 184.287 188.641 Z"
      fill="#FFFFFF"
    />
    <path
      d="M 184.5 188.5 L 174 179.352 L 182.618 31 L 190.5 39 L 184.5 188.5 Z"
      fill="#E0E0E0"
    />
    <path
      d="M 175.743 182.973 L 67.4085 178.269 C 66.7502 178.243 66.1285 177.959 65.6779 177.478 C 65.2273 176.998 64.9841 176.359 65.0008 175.701 C 66.7912 116.823 69.7113 46.9561 71.409 26.2992 C 71.4627 25.6529 71.7647 25.0526 72.2517 24.6243 C 72.7386 24.1961 73.3726 23.9732 74.0205 24.0026 L 182.435 28.7069 C 183.1 28.7373 183.726 29.0297 184.176 29.5202 C 184.626 30.0107 184.864 30.6594 184.837 31.3245 L 178.361 180.578 C 178.33 181.242 178.038 181.867 177.547 182.316 C 177.056 182.765 176.408 183.002 175.743 182.973 Z"
      fill="#9FA9E6"
    />
    <path
      opacity={0.6}
      d="M 71.6316 67.447 L 69.0776 67.3359 L 65.9916 138.302 L 68.5455 138.413 C 70.7526 138.509 72.6196 136.798 72.7155 134.591 L 75.454 71.617 C 75.55 69.4099 73.8386 67.543 71.6316 67.447 Z"
      fill="#6462F3"
    />
    <circle xmlns="http://www.w3.org/2000/svg" cx="94.9628" cy="95.9628" r="5.96281" fill="#323947"/>
    <circle xmlns="http://www.w3.org/2000/svg" cx="158.963" cy="99.9628" r="5.96281" fill="#323947"/>
    <path
      fillRule= "evenodd"
      clipRule= "evenodd"
      d="M 137.399 111.379 C 137.273 111.486 137.084 111.469 136.976 111.345 C 130.955 104.46 120.549 103.582 113.46 109.362 C 113.333 109.466 113.144 109.451 113.037 109.325 V 109.325 C 112.931 109.2 112.948 109.011 113.075 108.907 C 120.416 102.914 131.198 103.823 137.431 110.96 C 137.54 111.084 137.524 111.273 137.399 111.379 V 111.379 Z"
      stroke="#323947"
      strokeWidth={3}
    />
  </SvgIcon>
);

export default DiaryIcon;
