import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function TimeLimitIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 18, height: 17, ...style }}
            viewBox={viewBox || "0 0 18 12"}
        >
            <path d="M12.3359 18.8984C14.0026 18.8984 15.4193 18.3151 16.5859 17.1484C17.7526 15.9818 18.3359 14.5651 18.3359 12.8984C18.3359 11.2318 17.7526 9.8151 16.5859 8.64844C15.4193 7.48177 14.0026 6.89844 12.3359 6.89844V12.8984L8.08594 17.1484C8.66927 17.6984 9.32344 18.1276 10.0484 18.4359C10.7734 18.7443 11.5359 18.8984 12.3359 18.8984ZM12.3359 22.8984C10.9526 22.8984 9.6526 22.6359 8.43594 22.1109C7.21927 21.5859 6.16094 20.8734 5.26094 19.9734C4.36094 19.0734 3.64844 18.0151 3.12344 16.7984C2.59844 15.5818 2.33594 14.2818 2.33594 12.8984C2.33594 11.5151 2.59844 10.2151 3.12344 8.99844C3.64844 7.78177 4.36094 6.72344 5.26094 5.82344C6.16094 4.92344 7.21927 4.21094 8.43594 3.68594C9.6526 3.16094 10.9526 2.89844 12.3359 2.89844C13.7193 2.89844 15.0193 3.16094 16.2359 3.68594C17.4526 4.21094 18.5109 4.92344 19.4109 5.82344C20.3109 6.72344 21.0234 7.78177 21.5484 8.99844C22.0734 10.2151 22.3359 11.5151 22.3359 12.8984C22.3359 14.2818 22.0734 15.5818 21.5484 16.7984C21.0234 18.0151 20.3109 19.0734 19.4109 19.9734C18.5109 20.8734 17.4526 21.5859 16.2359 22.1109C15.0193 22.6359 13.7193 22.8984 12.3359 22.8984ZM12.3359 20.8984C14.5693 20.8984 16.4609 20.1234 18.0109 18.5734C19.5609 17.0234 20.3359 15.1318 20.3359 12.8984C20.3359 10.6651 19.5609 8.77344 18.0109 7.22344C16.4609 5.67344 14.5693 4.89844 12.3359 4.89844C10.1026 4.89844 8.21094 5.67344 6.66094 7.22344C5.11094 8.77344 4.33594 10.6651 4.33594 12.8984C4.33594 15.1318 5.11094 17.0234 6.66094 18.5734C8.21094 20.1234 10.1026 20.8984 12.3359 20.8984Z" fill="#6462F3"/>
        </SvgIcon>
    );
}