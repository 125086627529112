import React, { useEffect } from 'react';

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectAccountSettings, selectUserInfo } from "../../../../redux/store";
import { applicationInterfaceActions } from "../../../../redux/application-interface-slice";
import { navbarActions } from "../../../../redux/navbar-slice";
import { setAccountSettings } from "../../../../redux/account-settings-slice";
import { setUserInfo } from "../../../../redux/user-info-slice";

import { AccountService } from "../../../../api/account-service";
import { ApiClient } from "../../../../api/api-client";
import CountryService from "../../../../api/country-service";


export default function AccountSettingsProvider() {
  
  const { t } = useTranslation(["general"]);
  
  const dispatch = useAppDispatch();
  
  const userInfo = useAppSelector(selectUserInfo);
  const accountSettings = useAppSelector(selectAccountSettings);
  
  const localeStorage = localStorage.getItem('locale') ?? process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'es';
  
  async function requestAccountSettings() {
    dispatch(navbarActions.setShowLoader(true));
    const accountSettingsResponse = await AccountService.getAccountSettings();
    const countriesResponse = await CountryService.getCountries(localeStorage);
    dispatch(navbarActions.setShowLoader(false));
    accountSettingsResponse.paymentMethods = accountSettingsResponse.paymentMethods.map(x => {
      return { key: x.key, value: t(x.value) }
    });
    accountSettingsResponse.countries = countriesResponse.country;
    accountSettingsResponse.countryInSettings = countriesResponse.countryInSettings;
    dispatch(setAccountSettings(accountSettingsResponse));
  }
  
  useEffect(() => {
    if (accountSettings.accountName === "") {
      requestAccountSettings();
    }
  
    AccountService.getAccountOverdue().then(isOverdue => {
      dispatch(applicationInterfaceActions.updateAccountOverdue(isOverdue));
    });
  
    if (!userInfo.email || !userInfo.fullName) {
      dispatch(setUserInfo(ApiClient.getUserInfo()))
    }
  }, [])
  
  return null;
}