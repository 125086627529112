export class OpenCashRegisterRequest {
  initialCash: number;
  chargingUserId: string;
  chargingUserName: string;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.initialCash = x.initialCash;
      this.chargingUserId = x.chargingUserId;
      this.chargingUserName = x.chargingUserName;
    }
    else {
      this.initialCash = 0;
      this.chargingUserId = "";
      this.chargingUserName = "";
    }
  }
}