import React from "react";
import { Menu, Theme, styled } from '@material-ui/core';

const SMenu = styled(Menu)<Theme, { borders?: boolean }>(({ theme, borders }) => ({
    '& .MuiPaper-root': {
        borderRadius: 8,
        boxShadow: `0 2px 6px -1px ${theme.colors.grey3}`,
    },

    '& .MuiMenuItem-root': {
        borderBottom: borders ? `1px solid ${theme.colors.grey2}` : undefined,

        '&:last-child': {
            borderBottom: borders ? 'none' : undefined,
        },
    },
}));

export default SMenu;
