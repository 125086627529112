import { AppointmentTimeIntervalGapMinute } from "./enums/appointment-time-interval-gap-minute";
import { SettingsFieldUpdate } from "./enums/settings-field-update";

export class EstablishmentInfo {
    accountId: string | null;
    name: string;
    fiscalId: string;
    mobileCodeIso3: string;
    mobileCountry: MobileCountry | null;
    phoneNumber: string;
    address: string;
    addressReferences?: string;
    cancellationPolicies?: string;
    showPrices: boolean;
    showSuggestedServices: boolean;
    allowCustomerToCancelFromSite: boolean;
    allowCustomerToScheduleTodayFromSite: boolean;
    minuteLimitToModifyAppointmentFromSite: number;
    allowCustomerToModifyFromSite: boolean;
    allowCustomerScheduleSimultaneousServices: boolean;
    requireSelectSpecialistFromSite: boolean;
    showAnySpecialistFromSite: boolean;
    showCancellationPolicies: boolean;
    gapBetweenTimeIntervalsFromSite: AppointmentTimeIntervalGapMinute;
    logo?: string;
    fieldUpd: SettingsFieldUpdate;
    constructor(x?: EstablishmentInfo) {
        if (typeof x === "object" && x !== null) {
            this.accountId = x.accountId || null;
            this.name = x.name || "";
            this.fiscalId = x.fiscalId || "";
            this.mobileCodeIso3 = x.mobileCodeIso3 || "";
            this.mobileCountry = x.mobileCountry;
            this.phoneNumber = x.phoneNumber || "";
            this.address = x.address || "";
            this.addressReferences = x.addressReferences || "";
            this.cancellationPolicies = x.cancellationPolicies || "";
            this.showPrices = x.showPrices || false;
            this.showSuggestedServices = x.showSuggestedServices || false;
            this.allowCustomerToCancelFromSite = x.allowCustomerToCancelFromSite;
            this.allowCustomerToScheduleTodayFromSite = x.allowCustomerToScheduleTodayFromSite;
            this.minuteLimitToModifyAppointmentFromSite = x.minuteLimitToModifyAppointmentFromSite;
            this.allowCustomerToModifyFromSite = x.allowCustomerToModifyFromSite;
            this.allowCustomerScheduleSimultaneousServices = x.allowCustomerScheduleSimultaneousServices;
            this.requireSelectSpecialistFromSite = x.requireSelectSpecialistFromSite;
            this.showAnySpecialistFromSite = x.showAnySpecialistFromSite;
            this.gapBetweenTimeIntervalsFromSite = x.gapBetweenTimeIntervalsFromSite;
            this.showCancellationPolicies = x.showCancellationPolicies;
            this.fieldUpd = x.fieldUpd || SettingsFieldUpdate.None;
            this.logo = x.logo || "";
        }
        else {
            this.accountId = null;
            this.name = "";
            this.fiscalId = "";
            this.mobileCodeIso3 = "";
            this.mobileCountry = null;
            this.phoneNumber = "";
            this.address = "";
            this.addressReferences = undefined;
            this.cancellationPolicies = undefined;
            this.showPrices = false;
            this.showSuggestedServices = false;
            this.allowCustomerToCancelFromSite = true;
            this.allowCustomerToScheduleTodayFromSite = true;
            this.allowCustomerToModifyFromSite = true;
            this.allowCustomerScheduleSimultaneousServices = false;
            this.requireSelectSpecialistFromSite = true;
            this.showAnySpecialistFromSite = true;
            this.gapBetweenTimeIntervalsFromSite = AppointmentTimeIntervalGapMinute.Thirty;
            this.logo = "";
            this.showCancellationPolicies = false;
            this.minuteLimitToModifyAppointmentFromSite = 0;
            this.fieldUpd = SettingsFieldUpdate.None;
        }
    }
}


export class ControlInput {
    name: boolean = true;
    fiscalId: boolean = true;
    phoneNumber: boolean = false;
    address: boolean = false;
    addressReferences: boolean = false;
    cancellationPolicies: boolean = false;
    showPrices: boolean = false;
    showSuggestedServices: boolean = false;

    constructor(x: boolean) {
        this.name = x;
        this.fiscalId = x;
        this.phoneNumber = x;
        this.address = x;
        this.addressReferences = x;
        this.cancellationPolicies = x;
        this.showPrices = x;
        this.showSuggestedServices = x;
    }
}


export class MobileCountry {
    iso3?: string;
    dialCode?: number;
    dialDigits?: number;
    dialTemplate?: string;

    constructor(x: MobileCountry) {
        if (typeof x === "object" && x !== null) {
            this.iso3 = x.iso3;
            this.dialCode = x.dialCode;
            this.dialDigits = x.dialDigits;
            this.dialTemplate = x.dialTemplate;
        } else {
            this.iso3 = "MEX";
            this.dialCode = 52;
            this.dialDigits = 10;
            this.dialTemplate = "000-000-0000";
        }
    }
}
