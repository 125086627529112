import { AppointmentTimeIntervalGapMinute } from "./enums/appointment-time-interval-gap-minute";
import { SchedulerBlockDurationMinute } from "./enums/scheduler-block-duration-minute";

export class AccountScheduleSectionSettingsModel {
  accountId: string;
  hasMultipleAppointments: boolean;
  scheduleSimultaneousServicesEnabled: boolean;
  gapBetweenTimeIntervals: AppointmentTimeIntervalGapMinute;
  schedulerBlockDurationMinutes: SchedulerBlockDurationMinute;
  
  constructor(x?: any) {
      if (typeof x == "object") {
      this.accountId = x.accountId;
      this.hasMultipleAppointments = x.hasMultipleAppointments;
      this.scheduleSimultaneousServicesEnabled = x.scheduleSimultaneousServicesEnabled;
      this.gapBetweenTimeIntervals = x.gapBetweenTimeIntervals;
      this.schedulerBlockDurationMinutes = x.schedulerBlockDurationMinutes;
      } else {
      this.accountId = "";
      this.hasMultipleAppointments = false;
      this.scheduleSimultaneousServicesEnabled = false;
      this.gapBetweenTimeIntervals = AppointmentTimeIntervalGapMinute.Thirty;
      this.schedulerBlockDurationMinutes = SchedulerBlockDurationMinute.Thirty;
    }
  }
}
  