import * as React from "react";
import Container from "@material-ui/core/Container";

import NavBar from "./navbar/NavBar";
import { useAppDispatch, useAppSelector } from "../redux/hooks";
import { useEffect } from "react";
import { applicationInterfaceActions } from "../redux/application-interface-slice";
import { useLocation } from "react-router-dom";
import LoaderLine from "./navbar/LoaderLine";
import Fade from "@material-ui/core/Fade";
import { Url } from "../constants/url";
import FileDownloadProvider from "./common/FileDownloadProvider";

export default function Layout(props: any) {
  
  const dispatch = useAppDispatch();
  const location = useLocation();
  const isAccountOverdue = useAppSelector(state => state.applicationInterface.isAccountOverdue);
  const isWideLayout = useAppSelector(state => state.applicationInterface.isWideLayout);
  const showLoaderIsActive = useAppSelector(state => state.navbar.showLoader);
  const maxWidth = isWideLayout ? false : undefined;
  const wideScreenUrls = [Url.Reports.Main, Url.Reports.CustomReport];
  
  useEffect(() => {
    if (!wideScreenUrls.includes(location.pathname)) {
      dispatch(applicationInterfaceActions.updateIsWideLayout(false));
    }
  }, [location.pathname]);
  
  return (
    <div>
      <NavBar/>
      <Fade
        in={showLoaderIsActive ?? false}
        style={{
          transitionDelay: '800ms',
          zIndex: 999,
          position: "absolute",
          width: "100%",
          top: 0
        }}
        
      >
        <span>
          <LoaderLine />
        </span>
      </Fade>
      <div style={{ position: "relative", top: isWideLayout ? 61 : isAccountOverdue ? 113 : 80 }}>
        <Container maxWidth={maxWidth}>{props.children}</Container>
      </div>
      <FileDownloadProvider />
    </div>
  );
}
