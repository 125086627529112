import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

import Input from "./../../../common/Input";
import { ArrowLineDown, PlusIcon, SaveIcon, EditIcon, TrashIcon } from "./../../../../assets/icons";
import Category from './../../../../models/category';
import CategoryService from "./../../../../api/settings-category-service"

import { WorkareasCategorySelectProps } from "./props";
import useStyles from "./css";
import { useAppDispatch } from "../../../../redux/hooks";
import { alertsActions } from "../../../../redux/alerts-slice";

const WorkareasCategorySelect = (props: WorkareasCategorySelectProps) => {

    const { t } = useTranslation(["general"]);
    const { settingsSelectedEstablishmentId, widthSelect, widthList, isOptional, onSelect, item } = props;
    const classes = useStyles();

    const dispatch = useAppDispatch();
    const { enqueueAlert } = alertsActions;

    const [list, setList] = useState<Category[]>([]);
    const [showNew, setShowNew] = useState<boolean>(false);
    const [categorySelected, setCategorySelected] = useState<Category>();
    const [categoryNew, setCategoryNew] = useState<Category>();

    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleEditItem = (i: Category) => {
        setShowNew(false);
        const newList = list.map((item) => {
            if (item.id === i.id) {
                const updatedItem = {
                    ...item,
                    isEdit: !item.isEdit,
                };
                setCategoryNew(item);
                return updatedItem;
            }
            else {
                const updatedItem = {
                    ...item,
                    isEdit: false,
                };

                return updatedItem;
            }
        });

        setList(newList);
    }

    const itemSelected = (i: Category) => {
        setCategorySelected(i);
        onSelect(i);
        setOpenModal(false);
    }


    useEffect(() => {
        (async () => {
            await loadData();
        })();

        if (item) {
          let cat = new Category({
            id: item.id,
            name: item.category,
            isEdit: false
          });

          setCategorySelected(cat);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsSelectedEstablishmentId]);


    const loadData = async () => {
        const data = settingsSelectedEstablishmentId
            ? await CategoryService.getCategory(settingsSelectedEstablishmentId)
            : await CategoryService.getCategory();
        setList(data.entity || []);
    };

    const handleCategoryNew = () => {
        setCategoryNew(new Category());
        handleEditItem(new Category());
        setShowNew(true);

    }

    const changeCategoryNew = (event: React.ChangeEvent<{ name: string, value: string }>) => {
        let value = event.target.value;
        let newCat = new Category({ name: value });
        setCategoryNew(newCat);
    }

    const changeCategoryEdit = (i: Category) => (event: React.ChangeEvent<{ name: string, value: string }>) => {
        setCategoryNew(i);
        let value = event.target.value;

        setCategoryNew({ ...i, name: value });
    }

    const saveCategoryEdit = async (i: Category) => {
        if (categoryNew && categoryNew?.name !== "") {
            categoryNew.accountId = settingsSelectedEstablishmentId || null;
            const data = await CategoryService.updateCategory(categoryNew);
            if (data) {

                setCategoryNew(new Category());
                await loadData();
            }
        }
    }

    const saveCategoryNew = async () => {
        if (categoryNew && categoryNew?.name !== "") {
            categoryNew.accountId = settingsSelectedEstablishmentId || null;
            const data = await CategoryService.createCategory(categoryNew);
            if (data) {
                setShowNew(false);
                setCategoryNew(new Category());
                await loadData();
            }
        }
    }

    const handleDelete = async (i: Category) => {
        if (i) {
            i.accountId = settingsSelectedEstablishmentId || null;
            const data = await CategoryService.deleteCategory(i);
            if (data.forbiddenDelete) {
                // it wasn't deleted
                dispatch(
                  enqueueAlert({
                      type: "Error",
                      title: t("It is not possible to delete a category linked to a work area or service"),
                      description: ""
                  })
                );
            }
            else if (data.entity) {
                dispatch(
                  enqueueAlert({
                      type: "Success",
                      title: t("Category successfully removed"),
                      description: ""
                  })
                );
                await loadData();
            }
        }
    }


    const handleOpenModal = () => {
        setCategoryNew(new Category());
        handleEditItem(new Category());
        setShowNew(false);
        setOpenModal(true);
    }


    const handleCloseModal = () => {
        setOpenModal(false);
        itemSelected(new Category());
    }


    return (<div className={classes.component}>

        <div className={classes.divDropdown} style={{ width: widthSelect || 350}}  >

            <label className={classes.labelCategory}>
                <Typography className={classes.labelCategory}>


                    {t("Category")}{" "}

                    {(isOptional ?? true) ?
                        <span className={classes.optional}>({t("Optional")})</span>
                        :
                        <span className={classes.required}>*</span>
                    }


                </Typography >
            </label>

            <div onClick={handleOpenModal}>
                <Input
                    id={"itemSelect"}
                    name={"categorySelected"}
                    value={categorySelected?.name || ""}
                    placeholder={t("Select a category")}
                    width={widthSelect || 350}
                    inputClass={classes.inputSelectBtn}
                    isValid={() => isOptional ? isOptional : categorySelected?.name != ""}
                />
            </div>
            <div className={classes.iconDropdown} onClick={handleOpenModal}>
                <ArrowLineDown />
            </div>

        </div>

        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
            onBackdropClick={handleCloseModal}
            disablePortal={true}
            style={{ position: "relative", top: -20 }} >

            <div className={classes.divModalSelect} style={{ width: widthList || 350}}>

                <div className={classes.listCategoryScroll} >
                    {list.map((item) => {
                        return <div key={item.id} className={classes.listCategory}>

                            {!item.isEdit &&
                                <div className={`${classes.elementList} ${classes.classRow}`}>
                                    <div className={classes.categoryName} onClick={() => itemSelected(item)}>
                                        {item.name}
                                    </div>
                                    <div className={classes.divIconsRow} >
                                        <button className={classes.btnRow} onClick={(e) => { e.stopPropagation(); handleEditItem(item); }} >
                                            <EditIcon className={classes.editCategory} />
                                        </button>
                                        <button className={classes.btnRow} onClick={(e) => { e.stopPropagation(); handleDelete(item); }} >
                                            <TrashIcon className={classes.deleteCategory} style={{ color: "#919CA5" }} />
                                        </button>
                                    </div >
                                </div>
                            }
                            {item.isEdit &&
                                <div className={classes.divItemEdit} >
                                    <div className={classes.divInputEdit} >
                                        <Input
                                            id={`inputEdit${categoryNew?.id}`}
                                            name={"categoryEdit"}
                                            value={categoryNew?.name}
                                            width={widthList || 300}
                                            maxLength={50}
                                            placeholder={t("Name of the category")}
                                            inputClass={classes.input}
                                            isValid={() => categoryNew?.name != "" }
                                            autoFocus={true}
                                            onChange={changeCategoryEdit(item)}
                                        />
                                    </div>
                                    <button className={classes.iconSave} onClick={() => saveCategoryEdit(item)}>
                                        <SaveIcon />
                                    </button>
                                </div>
                            }
                        </div >
                    })}
                </div >

                <div className={classes.divNew} >
                    {!showNew &&
                        <div className={classes.btnInputNew}>
                            <Button className={`${classes.button} ${classes.btnNewWorkareaCategory}`} onClick={handleCategoryNew} >
                                {t('New category')}
                            </Button>
                        </div>
                    }
                    {showNew &&
                        <div className={classes.btnInputNew}>
                            <div  >
                                <Input
                                    id={"category"}
                                    name={"category"}
                                    value={categoryNew?.name}
                                    width={widthList || 300}
                                    maxLength={50}
                                    placeholder={t("Name of the category")}
                                    inputClass={classes.input}
                                    isValid={() => categoryNew?.name != ""}
                                    autoFocus={true}
                                    onChange={changeCategoryNew}
                                />
                            </div>
                            <button className={classes.iconPlus} onClick={saveCategoryNew}>
                                <PlusIcon style={{ color: "#6462F3", height: 15, width: 15 }} />
                            </button>
                        </div>
                    }
                </div>

            </div>
        </Modal>
    </div>);
};

export default WorkareasCategorySelect;
