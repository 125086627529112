import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    backdrop: {
      opacity: 0,
      visibility : "hidden",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
      position: "fixed",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: 10000,
      transition: "all 500ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      "&>p": {
        color: theme.colors.white,
        fontSize: 18,
        fontWeight: 700,
        fontFamily: "Inter, sans-serif"
      }
    },
    fadeIn: {
      visibility: "visible",
      opacity: 1,
    }
  })
);

export default useStyles;