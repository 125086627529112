import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from 'react-i18next';

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import EntityService from "../../../../../api/settings-services-service";
import ServiceForm from './../ServiceForm'
import Service from "../../../../../models/service";
import { TrashIcon } from "../../../../../assets/icons";
import useStyles from "./css";


export default function ServiceModal(props: any) {
  
  const {settingsSelectedEstablishmentId, open, onClose, onCreate, item, onDelete } = props;
  
  const { t } = useTranslation(["general"]);
  const classes = useStyles();
  
  const isNewItem: boolean = item === null;
  const [entityState, setEntityState] = useState<Service>(new Service());
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [madeChange, setMadeChange] = useState<boolean>(false);
  const [disableSave, setDisableSave] = useState<boolean>(true);
  const [isValid, _setIsValid] = useState<boolean>(false);
  const isValidRef = useRef(isValid);
  
  const setIsValid = (data: boolean) => {
    isValidRef.current = data;
    _setIsValid(data);
  };
  
  
  useEffect(() => {
    setOpenModal(open);
    setMadeChange(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  
  
  async function handleOnCreateClick() {
    
    if (isValid) {
      
      let filename: string = "";
      
      const file = entityState.file;
      
      if (file) {
        let data: any = await EntityService.saveImage(file);
        filename = data.fileName;
      }
      
      const service: Service = {
        ...entityState,
        photoName: filename || entityState.photoName || "",
        file: null
      };
      let entity: any;
      if (isNewItem) {
        entity = await EntityService.createService(service, settingsSelectedEstablishmentId);
      } else {
        entity = await EntityService.updateService(service, settingsSelectedEstablishmentId);
      }
      
      
      const newEntity: Service = { ...entityState, id: entity.id, photoName: filename, file: null };
      setEntityState(newEntity);
      
      if (onCreate) {
        onCreate(newEntity, isNewItem);
        onClose();
      }
    }
  }
  
  
  function handleFormChange(isValid: boolean, entity: Service)  {
    setIsValid(isValid);
    setEntityState(entity);
    
    setDisableSave(!(madeChange && isValid));
  }
  
  function handleModifying() {
    setMadeChange(true);
    setDisableSave(!isValid);
  }
  
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={(event, reason) => {
          if (reason === 'backdropClick') {
            if (madeChange)
              setCancelModal(true);
            else
              onClose();
          }
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}>
        <Fade in={openModal}>
          <div className={classes.paper}>
            <div className={classes.modalHeader}>
              
              <div className={classes.modalTitle}>
                <Typography className={classes.modalTitleText} variant="h1">
                  {isNewItem ? t('New service') : t('Service detail')}
                </Typography>
              </div>
              
              <div className={classes.modalActions}>
                
                {!isNewItem &&
                  <Button className={`${classes.iconTrash}`} onClick={() => onDelete(item)}>
                    <TrashIcon style={{ color: "#919CA5", fontSize: 14 }}/>
                  </Button>
                }
                
                <Button
                  className={`${classes.button} ${classes.goBack}`}
                  onClick={() => { madeChange ? setCancelModal(true) : onClose()}}
                >
                  {t('Go back')}
                </Button>
                
                <Button
                  className={`${classes.button} ${classes.createCustomer}`}
                  disabled={disableSave}
                  classes={{ disabled: classes.createDisabled }}
                  onClick={handleOnCreateClick}>
                  {
                    isNewItem ? t('Create service') : t('Save changes')
                  }
                </Button>
              </div>
            </div>
            
            <ServiceForm
              item={item}
              onChange={handleFormChange}
              onModify={handleModifying}
              settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
            />
          </div>
        </Fade>
      </Modal>
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={cancelModal}>
          <div className={classes.modalCancelPaper}>
            <div className={classes.modalContent}>
              <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`}>
                <Typography className={classes.modalTitleTextCancel}>
                  {item ? t("Cancel service edition?") : t("Cancel service registration?")}
                </Typography>
              </div>
              <div className={classes.modalActions}>
                <Button
                  className={`${classes.button} ${classes.goBack}`}
                  
                  onClick={() => setCancelModal(false)}
                >
                  {t("Go back")}
                </Button>
                <Button
                  className={`${classes.button} ${classes.cancelConfirmation}`}
                  onClick={() => {
                    setCancelModal(false);
                    onClose();
                  }}
                >
                  {t("WorkAreaModalCancelEditConfirm")}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}