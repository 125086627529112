import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 360,
    minHeight: 90,

    display: "flex",
    flexDirection: "column",
    position: "relative",
    justifyContent: "space-between",
    
    padding: "16px 16px 12px 20px",
    
    border: "1px solid #F6F6F6",
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: theme.colors.clear5,
    }
  },
  unreadDot: {
    width: 8,
    height: 8,
    
    display: "block",
    position: "absolute",
    left: 7,
    top: 20,
    backgroundColor: theme.colors.clearMain,
    borderRadius: "50%",
  },
  headerRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
  },
  headerIcon: {
    height: 16,
    width: 16,
    marginRight: 4,
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  headerText: {
    ...theme.typography.txtBody3,
    color: theme.colors.grey5
  },
  senderRow: {
    minHeight: 17
  },
  mainText: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey6
  },
  textRow: {
    minHeight: 17,
    
    justifySelf: "flex-end",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-end"
  },
  additionalText: {
    width: 280,
    
    ...theme.typography.txtBody1,
    color: theme.colors.grey5
  },
  strongText: {
    fontWeight: "bold"
  },
  messageCounter: {
    width: 22,
    height: 22,
    
    
    alignSelf: "flex-end",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    backgroundColor: theme.colors.clearBlue,
    borderRadius: 20,
    
    color: theme.colors.grey1,
    ...theme.typography.txtBody2,
    fontWeight: "bold"
  },
  expander: {
    width: 26,
    height: 22,
    
    alignSelf: "flex-start",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    backgroundColor: theme.colors.clearMain,
    borderRadius: 20,
  
    color: theme.colors.grey1,
    ...theme.typography.txtBody2,
    fontWeight: "bold"
  },
  upsideDown: {
    "-moz-transform": "scaleY(-1)",
    "-o-transform": "scaleY(-1)",
    "-webkit-transform": "scaleY(-1)",
    transform: "scaleY(-1)",
  },
  timeContainer: {
    ...theme.typography.txtBody3,
    color: theme.colors.grey5
  },
  fromToDateTimeRow: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    paddingTop: 7,
    fontFamily: "Inter, sans-serif",
  },
  fromToLabel: {
    color: theme.colors.grey5,
    fontWeight: "bold",
    fontFamily: "Inter, sans-serif",
  }
  })
);

export default useStyles;