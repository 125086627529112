import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import InputWithLabel from "../../common/InputWithLabel";

import { CustomerEntity } from "../../../models/customer-entity";

import useStyles from "./css";
import { CustomerInformationTabProps } from "./props";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { applicationInterfaceActions } from "../../../redux/application-interface-slice";
import { EmailRegex, MultipleSpacesNameRegex, NameRegex } from "../../../constants/validator";
import { Backdrop, Button, Fade, Modal, Typography } from "@material-ui/core";
import {
  CrossIcon,
  EditIcon,
  GroupIcon,
  NoteIcon,
  PhoneIcon,
  PlusIcon,
  SaveIcon,
  TrashIcon
} from "../../../assets/icons";
import TabPanel from "../../common/tabs/TabPanel";
import CustomerEmergencyContactList from "../CustomerEmergencyContactList";
import CustomerGeneral from "../CustomerGeneral";
import { ApiClient } from "../../../api/api-client";
import { Gender } from "../../../models/enums/gender";
import { EmergencyContact } from "../../../models/emergency-contact";
import { CustomerService } from "../../../api/customer-service";
import { CreateEmergencyContacts } from "../../../models/create-emergency-contacts";
import { GetMobileMaskByTemp } from "../../../constants/mask";
import { conformToMask } from "react-text-mask";
import CountryFlagIcon from "../../../assets/flags";
import { alertsActions } from "../../../redux/alerts-slice";
import { CustomerInfoDetails } from "../../../models/customer-info-details";
import { useHistory } from "react-router-dom";
import { Url } from "../../../constants/url";
import { navbarActions } from "../../../redux/navbar-slice";
import BellIcon from "../../../assets/icons/BellIcon";
import CustomerNotifications from "../CustomerNotifications";
import { selectAccountSettings } from "../../../redux/store";

const CustomerInformationTab = (props: CustomerInformationTabProps) => {
  const { customerId, customer, currentTabIndex, setCustomer } = props;

  const hasCustomerInfoVisible = ApiClient.getUserInfo().hasCustomerInfoVisible;
  const { t } = useTranslation(["general"]);
  const history = useHistory();
  const classes = useStyles();
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const accountSettings = useAppSelector(selectAccountSettings);
  const appDispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;

  const [isValid, _setIsValid] = useState<boolean>(true);
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [emergencyContact, setEmergencyContact] = useState<EmergencyContact>();
  const [contactIndex, setContactIndex] = useState<number>(0);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [contacts, setContacts] = useState<EmergencyContact[]>([]);
  const [contactsCopy, setContactsCopy] = useState<EmergencyContact[]>([]);
  const [contactDeleted, setContactDeleted] = useState<EmergencyContact[]>([]);
  const [customerValid, setCustomerValid] = useState<boolean>(false);
  const [contactValid, setContactValid] = useState<boolean>(false);
  const [isEdition, setIsEdition] = useState<boolean>(false);
  const [customerCopy, setCustomerCopy] = useState<CustomerInfoDetails | null>(null);


  const isValidRef = useRef(isValid);
  const customerRef = useRef(customer);
  const innerSetCustomer = (data: CustomerInfoDetails) => {
    customerRef.current = data;
    setCustomer(data);
  };

  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);
  const mobileTemplate: string = "000-000-0000";

  const [loadedGeneral, setLoadedGeneral] = useState<boolean>(false);
  const [loadedContacts, setLoadedContacts] = useState<boolean>(false);
  const [changed, setChanged] = useState<boolean>(false);

  const setIsValid = (data: boolean) => {
    isValidRef.current = data;
    _setIsValid(data);
  };

  useEffect(() => {
    async function fetchData() {
      await loadData();
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setIsValid(customerValid && contactValid);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerValid, contactValid]);


  useEffect(() => {

    let validContacts = true;

    if (contacts.length !== 0)
      for (let c of contacts) {
        if (!NameRegex.test(c?.name || "") ||
          !NameRegex.test(c?.relationship || "") ||
          !validateMobile(c)
        ) {
          validContacts = false;
          break;
        }
      }

    setContactValid(validContacts);
    innerSetCustomer({ ...customer, emergencyContacts: contacts });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contacts]);

  const validateMobile = (c: EmergencyContact | CustomerInfoDetails | CustomerEntity): boolean => {
    const digits: number = c.mobileDigits!;
    let mobile: string = c.mobile!;
    if (mobile) mobile = mobile.length > 0 ? mobile.substring(0, digits) : mobile;
    const validDig = (digits === 7 && /^\d{7}$/.test(mobile!)) ||
      (digits === 8 && /^\d{8}$/.test(mobile!)) ||
      (digits === 9 && /^\d{9}$/.test(mobile!)) ||
      (digits === 10 && /^\d{10}$/.test(mobile!)) ||
      (digits === 11 && /^\d{11}$/.test(mobile!)) ||
      (digits === 12 && /^\d{12}$/.test(mobile!));

    return validDig;
  }

  const listener = (event: any) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      const fetchData = async () => {
        if (customerRef.current && isValidRef.current) {
          // await CustomerService.updateCustomer(customerRef.current);
          const customerSaved = await updateData(customerRef.current);

          if (customerSaved.isMobileDuplicate) {

            showDuplicated(customerSaved);

          } else {

            await CustomerService.createEmergencyContacts(
              new CreateEmergencyContacts({
                customerId: customerRef.current.id,
                emergencyContacts: customerRef.current.emergencyContacts,
              })
            );
            setIsEdition((currentIsEdition) => false);

          }

        }
      };

      fetchData();
    }
  };

  useEffect(() => {

    if (isEdition) {
      window.addEventListener("keypress", listener);
    } else {
      window.removeEventListener("keypress", listener);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdition]);

  useEffect(() => {
    if (accountSettings.countries.length && customerCopy) {
      fillMobileData(customerCopy);
      setCustomerCopy(customerCopy);
      innerSetCustomer(customerCopy);
      setCustomerCopy(customerCopy);
      setContacts(customerCopy.emergencyContacts || []);
      setContactsCopy(customerCopy.emergencyContacts || []);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSettings.countries]);
  

  function fillMobileData(customerData: CustomerInfoDetails) {
    const countries = accountSettings.countries;
    let mobileCode = customerData.mobileCodeIso3 ?? "MEX";
    customerData.mobileDigits = countries.find(x => x.iso3 === mobileCode)!.dialDigits;
    customerData.mobileCode =  countries.find(x => x.iso3 === mobileCode)!.dialCode;
    customerData.mobileTemplate =  countries.find(x => x.iso3 === mobileCode)!.dialTemplate.toString();

    if (customerData.emergencyContacts?.length) {
      [...customerData.emergencyContacts].map(emergencyContact => {
        emergencyContact.mobileDigits = countries.find(x => x.iso3 === emergencyContact.mobileCodeIso3)!.dialDigits;
        emergencyContact.mobileCode =  countries.find(x => x.iso3 === emergencyContact.mobileCodeIso3)!.dialCode;
        emergencyContact.mobileTemplate =  countries.find(x => x.iso3 === emergencyContact.mobileCodeIso3)!.dialTemplate.toString();
        return customer;
      })
    }
    fillData(customerData);
  }

  const loadData = async () => {
    let customerData;
    if (!customerCopy) {
      appDispatch(navbarActions.setShowLoader(true));
      customerData = await CustomerService.getCustomerInfoDetails(customerId);
      appDispatch(navbarActions.setShowLoader(false));
      if (accountSettings.countries.length) {
        fillMobileData(customerData);
      }
      
      setCustomerCopy(customerData);
    } else {
      customerData = customerCopy;
    }

    await fillData(customerData);
  };

  const fillData = async (customerData: CustomerInfoDetails) => {
    innerSetCustomer(customerData);
    setContacts(customerData.emergencyContacts || []);
    setContactsCopy(customerData.emergencyContacts || []);

    let titleNav =
      t("Customer detail") +
      " / " +
      customerData.firstName +
      " " +
      customerData.lastName;
    appDispatch(applicationInterfaceActions.updateTitle(titleNav));
    setLoadedGeneral(true);
    setLoadedContacts(true);
  };

  const updateData = async (customer: any) => {
    const responseCustomer = await CustomerService.updateCustomer(customer);
    responseCustomer.mobileTemplate =
      accountSettings.countries.find(c => c.iso3 === responseCustomer.mobileCodeIso3)!.dialTemplate;
    return responseCustomer;
  };

  const date = (created: Date | null | undefined): string => {
    if (!created) {
      return "";
    }

    const createdDate = new Date(created);
    const day = createdDate.toLocaleString(
      localeApp,
      {
        day: "numeric",
      }
    );

    let month = createdDate.toLocaleString(
      localeApp,
      {
        month: "long",
      }
    );

    month = month.charAt(0).toUpperCase() + month.slice(1);

    const year = createdDate.toLocaleString(
      localeApp,
      {
        year: "numeric",
      }
    );

    return `${day} / ${month} / ${year}`;
  };

  const gender = (gender: Gender | null | undefined): string | null => {
    switch (gender) {
      case Gender.Female:
        return t("Female");
      case Gender.Male:
        return t("Male");
      case Gender.PreferNotToSay:
        return t("I prefer not to say");
      case Gender.Custom:
        return t("gender_custom");
      default:
        return null;
    }
  };

  const handleSave = async () => {
    const customerSaved = await updateData(customer);

    if (customerSaved.isMobileDuplicate) {

      showDuplicated(customerSaved);

    } else {

      if (contacts.length) {
        let emergencyContact = await createEmergencyContacts();
        customerSaved.emergencyContacts = [...emergencyContact];
        if (customerSaved.emergencyContacts?.length) {
          const countries = accountSettings.countries;
          [...customerSaved.emergencyContacts].map(emergencyContact => {
            emergencyContact.mobileDigits = countries.find(x => x.iso3 === emergencyContact.mobileCodeIso3)!.dialDigits;
            emergencyContact.mobileCode = countries.find(x => x.iso3 === emergencyContact.mobileCodeIso3)!.dialCode;
            emergencyContact.mobileTemplate = countries.find(x => x.iso3 === emergencyContact.mobileCodeIso3)!.dialTemplate.toString();
            return customer;
          })
        }
      }

      if (contactDeleted.length) {
        await deleteEmergencyContacts();
      }

      setIsEdition(!isEdition);
      appDispatch(
        enqueueAlert({
          type: "Success",
          title: t("Customer Updated"),
          description: `${customer.firstName} ${customer.lastName}`
        })
      );

      setChanged(false);
      setCustomerCopy(customerSaved);
      await fillData(customerSaved);
    }
  };

  const handleEdit = () => {
    setIsEdition(!isEdition);
    innerSetCustomer(customerCopy || new CustomerInfoDetails());
    setContacts([...contactsCopy]);
    setContactDeleted([]);
  };


  const showDuplicated = (duplicated: any) => {
    appDispatch(
      enqueueAlert({
        type: "Error",
        title: t("Invalid number"),
        description: t("This number already belongs to customer"),
        link: {
          onClick: (e: any) => {
            e.preventDefault();
            const location = `${Url.Customers.Main}/${duplicated.id}`;
            history.push(location);
            history.replace(location);
            history.go(0);
          },
          title: `${duplicated.firstName} ${duplicated.lastName}`,
        }
      })
    );
    setIsDuplicated(true);
  }

  const handlerCustomerGeneralChange = (customerEntity: CustomerEntity, valid: boolean) => {

    if (loadedGeneral)
      setLoadedGeneral(false);
    else
      setChanged(true);

    let validGroup = true;

    const validName = MultipleSpacesNameRegex.test(customerEntity.firstName || "");
    const validLastName = MultipleSpacesNameRegex.test(customerEntity.lastName || "");
    const validMobile = validateMobile(customerEntity);
    const validEmail = !customerEntity.email ? true : EmailRegex.test(customerEntity.email || "");
    const validGender = customer.gender !== Gender.Custom || MultipleSpacesNameRegex.test(customer.customGender || "");

    if (!validName ||
      !validLastName ||
      !validEmail ||
      !validMobile ||
      !validGender
    ) {
      validGroup = false;
    }

    innerSetCustomer({ ...customer, ...customerEntity });
    setCustomerValid(valid && validGroup);
  };

  const handlerCustomerNotificationsChange = (customerEntity: CustomerEntity) => {
    innerSetCustomer({ ...customer, ...customerEntity });
  };

  const handleNoteChange = (event: React.ChangeEvent<{ value: string }>) => {
    innerSetCustomer({ ...customer, notes: event.target.value });
  };

  const handleAddContact = () => {
    const arrContacts = contacts;
    const countryDefault = accountSettings.countryInSettings;

    let newContact = new EmergencyContact();
    newContact.mobileCodeIso3 = countryDefault.iso3;
    newContact.mobileDigits = countryDefault.dialDigits;
    newContact.mobileCode = countryDefault.dialCode;
    newContact.mobileTemplate = countryDefault.dialTemplate;

    arrContacts.push(newContact);
    setContacts([...arrContacts]);
  };

  const handleChangeContact = (contact: EmergencyContact, index: any) => {

    if (loadedContacts)
      setLoadedContacts(false);
    else
      setChanged(true);

    const arrContacts = contacts;
    arrContacts[index] = contact;
    setContacts([...arrContacts]);
  };

  const handleDeleteContact = (contact: EmergencyContact, index: any) => {
    setContactIndex(index);
    setEmergencyContact(contact);
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const createEmergencyContacts = async () => {
    return await CustomerService.createEmergencyContacts(
      new CreateEmergencyContacts({
        customerId: customer.id,
        emergencyContacts: contacts,
      })
    );
  };

  const deleteEmergencyContacts = async () => {
    if (contactDeleted.length) {
      await CustomerService.deleteBulkEmergencyContacts(
        contactDeleted.map((contact) => contact.id || "")
      );
    }
  };

  const removeEmergencyContact = () => {
    const contactsDeleted = contactDeleted;
    if (contacts[contactIndex] && contacts[contactIndex].id) {
      contactsDeleted.push(contacts[contactIndex]);
    }
    setContactDeleted(contactsDeleted);

    const arrContacts = contacts.filter(
      (contact, index) => index !== contactIndex
    );

    setContacts(arrContacts);

    setChanged(true);

    setEmergencyContact(undefined);
    setOpenModal(false);
  };

  return (<>
    <TabPanel
      className={classes.customerInfoPanel}
      value={currentTabIndex}
      index={0}
    >
      <div className={classes.customerInfoPanelContent}>
        <div className={classes.customerInfoTitle}>
          <GroupIcon />
          <Typography className={classes.iconTitle}>
            {t("Customer info")}
          </Typography>
        </div>
        <div className={classes.customerInfoContent}>
          {isEdition ? (
            <CustomerGeneral
              onChange={handlerCustomerGeneralChange}
              mobilePhoneDuplicated={isDuplicated}
              value={new CustomerEntity(customer)}
              isEdition
            />
          ) : (
            <div>
              <div className={classes.infoContentFields}>
                <div>
                  <Typography className={classes.caption}>
                    {t("Name(s)")}
                  </Typography>
                  <Typography className={classes.captionValue}>
                    {customer.firstName}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.caption}>
                    {t("Last names")}
                  </Typography>
                  <Typography className={classes.captionValue}>
                    {customer.lastName}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.caption}>
                    {t("Date of birth")}
                  </Typography>
                  <Typography className={classes.captionValue}>
                    {date(customer.birthDate)}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.caption}>
                    {t("Gender")}
                  </Typography>
                  <Typography className={classes.captionValue}>
                    {gender(customer.gender)}
                  </Typography>
                </div>
                {customer.gender === Gender.Custom &&
                  <div>
                    <Typography className={classes.caption}>
                      {t("Custom gender")}
                    </Typography>
                    <Typography className={classes.captionValue}>
                      {customer.customGender}
                    </Typography>
                  </div>
                }
                <div>
                  <Typography className={classes.caption}>
                    {hasCustomerInfoVisible ? t("Email") : t("Contactable")}
                  </Typography>
                  <Typography className={classes.captionValue}>
                    {hasCustomerInfoVisible ? customer.email : customer.contactableAppointments || customer.contactableMassiveSendings ? t("Yes") : t("No")}
                  </Typography>
                </div>
                <div>
                  <Typography className={classes.caption}>
                    {hasCustomerInfoVisible && t("Mobile")}
                  </Typography>
                  <div className={classes.captionValue} style={{ display: `${hasCustomerInfoVisible ? "" : "none"}` }}>
                    <div className={classes.divFlag}>
                      <CountryFlagIcon iso={customer?.mobileCodeIso3 || "MEX"} />
                    </div>
                    <div className={classes.divCode}>
                      {customer.mobileCode !== undefined ? `+${customer.mobileCode}` : "+52"}
                    </div>
                    <div className={classes.divMobile}>
                      {
                        customer.mobile ? conformToMask(customer.mobile, GetMobileMaskByTemp(customer.mobileTemplate || mobileTemplate)).conformedValue : ""
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

          <div className={classes.separator} style={{ width: 360 }}></div>
          <div className={classes.customerInfoTitle}>
            <BellIcon />
            <Typography className={classes.iconTitle}>
              {t("Notifications")}
            </Typography>
          </div>
          <CustomerNotifications
            onChange={handlerCustomerNotificationsChange}
            value={new CustomerEntity(customer)}
            isEdition={isEdition}
          />

          <div className={classes.separator} style={{ width: 360 }}></div>
          <div className={classes.customerInfoTitle}>
            <PhoneIcon />
            <Typography className={classes.iconTitle}>
              {t("Emergency contacts")}
            </Typography>
          </div>
          {contacts && contacts.length > 0 ? (
            <>
              {isEdition ? (
                <CustomerEmergencyContactList
                  list={contacts}
                  onChange={handleChangeContact}
                  onDelete={handleDeleteContact}
                />
              ) : (
                <>
                  <div className={classes.infoContentRow}>
                    <div>
                      <Typography className={classes.caption}>
                        {t("Name")}
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.caption}>
                        {t("Relationship")}
                      </Typography>
                    </div>
                    <div>
                      <Typography className={classes.caption}>
                        {t("ContactMobileNumber")}
                      </Typography>
                    </div>
                  </div>
                  {contacts.map((contact, index) => (
                    <div key={index} className={classes.contactRow}>
                      <div>
                        <Typography className={classes.captionValue}>
                          {contact.name}
                        </Typography>
                      </div>
                      <div>
                        <Typography className={classes.captionValue}>
                          {contact.relationship}
                        </Typography>
                      </div>
                      <div className={classes.captionValue}>
                        <div>
                          <div className={classes.divFlag}>
                            <CountryFlagIcon iso={contact?.mobileCodeIso3 || "MEX"} />
                          </div>
                          <div className={classes.divCode}>
                            {(customer.mobileCode !== undefined && customer.mobileCode !== null) ? `+${customer.mobileCode}` : "+52"}
                          </div>
                          <div className={classes.divMobile}>
                            {
                              conformToMask(contact.mobile!, GetMobileMaskByTemp(contact.mobileTemplate || mobileTemplate)).conformedValue
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </>
          ) : (
            <div className={classes.infoContentRow}>
              <Typography className={classes.captionValue}>
                {t("No Emergency Contacts")}
              </Typography>
            </div>
          )}
          {isEdition ? (
            <div className={classes.infoContentRow}>
              <Button
                className={classes.addContactButton}
                onClick={handleAddContact}
              >
                <PlusIcon />
                {t("Add another")}
              </Button>
            </div>
          ) : null}
          <div className={classes.separator} style={{ width: 360 }}></div>
          <div className={classes.customerInfoTitle}>
            <NoteIcon />
            <Typography className={classes.iconTitle}>
              {t("Notes")}
            </Typography>
          </div>
          <div className={classes.infoContentRow}>
            <div style={{ marginBottom: 98 }}>
              {isEdition ? (
                <InputWithLabel
                  label={t("Allergies")}
                  placeholder={t("Enter customer allergies")}
                  width={730}
                  value={customer.notes || ""}
                  maxLength={180}
                  showCounter={true}
                  onChange={handleNoteChange}
                />
              ) : (
                <>
                  <Typography className={classes.caption}>
                    {t("Allergies")}
                  </Typography>
                  <Typography className={classes.captionValueTextArea}>
                    {customer.notes || t("No allergies") + "."}
                  </Typography>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      {currentTabIndex === 0 ? (
        <div className={classes.detailsActions}>
          {isEdition ? (
            <div>
              <Button
                aria-label={t("Edit Customer")}
                className={`${classes.floatButton} ${classes.cancelButton}`}
                onClick={() => setCancelModal(true)}
              >
                <CrossIcon
                  style={{ width: 16, height: 16, marginRight: "12px" }}
                  viewBox="0 0 16 16"
                />
                <Typography className={classes.floatButtonTxt}>
                  {t("Cancel")}
                </Typography>
              </Button>
              <Button
                aria-label={t("Save changes")}
                className={`${classes.floatButton} ${classes.saveButton}`}
                onClick={handleSave}
                disabled={!isValid || !changed}
              >
                <SaveIcon
                  style={{ width: 16, height: 16, marginRight: "12px" }}
                  viewBox="0 0 16 16"
                />
                <Typography className={classes.floatButtonTxt}>
                  {t("Save changes")}
                </Typography>
              </Button>
            </div>
          ) : (
            !hasCustomerInfoVisible ? <></> : <>
              <Button
                aria-label={t("Edit Customer")}
                className={`${classes.floatButton} ${classes.editButton}`}
                onClick={handleEdit}
              >
                <EditIcon
                  style={{ width: 16, height: 16, marginRight: "12px" }}
                />
                <Typography className={classes.floatButtonTxt}>
                  {t("Edit info")}
                </Typography>
              </Button></>
          )}
        </div>
      ) : null}
    </TabPanel>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <div className={classes.modalPaper}>
          <div className={classes.modalHeader}>
            <div className={classes.modalClose}>
              <span
                className={classes.closeButton}
                onClick={handleCloseModal}
              >
                <CrossIcon />
              </span>
            </div>
          </div>
          <div className={classes.modalContent}>
            <div className={classes.modalCenter}>
              <div className={classes.modalCircle}>
                <TrashIcon />
              </div>
            </div>
            <div
              className={`${classes.modalCenter} ${classes.modalTitleSpace}`}
            >
              <Typography className={classes.modalTitleText}>
                {t("Are you sure to delete the emergency contact?")}
              </Typography>
            </div>
            <div className={classes.modalCenter}>
              <div className={classes.separator} style={{ width: 217 }}></div>
            </div>
            <div className={classes.modalCenter}>
              <Typography className={classes.modalContentText}>
                {emergencyContact?.name} - {emergencyContact?.relationship}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={handleCloseModal}
              >
                {t("Go back")}
              </Button>
              <Button
                className={`${classes.button} ${classes.deleteContact}`}
                onClick={removeEmergencyContact}
              >
                {t("Delete Contact")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={cancelModal}
      onClose={() => setCancelModal(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={cancelModal}>
        <div className={classes.modalCancelPaper}>
          <div className={classes.modalContent}>
            <div
              className={`${classes.modalCenter} ${classes.modalTitleSpace}`}
            >
              <Typography className={classes.modalTitleText}>
                {t(
                  "Are you sure you want to cancel the data editing? The changes made will not be saved?"
                )}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={() => setCancelModal(false)}
              >
                {t("Go back")}
              </Button>
              <Button
                className={`${classes.button} ${classes.cancelConfirmation}`}
                onClick={() => {
                  handleEdit();
                  setCancelModal(false);
                }}
              >
                {t("Yes")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  </>
  );
};

export default CustomerInformationTab;
