import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import CustomerGeneral from "./../CustomerGeneral";
import { CustomerService } from "../../../api/customer-service";
import { CustomerEntity } from "../../../models/customer-entity";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { applicationInterfaceActions } from "../../../redux/application-interface-slice";
import { EmailRegex, MultipleSpacesNameRegex } from "../../../constants/validator";
import Country from "../../../models/country";

import { CustomerModalProps } from "./props";
import useStyles from "./css";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";
import { selectAccountSettings } from "../../../redux/store";
import { Gender } from "../../../models/enums/gender";


const CustomerModal = (props: CustomerModalProps) => {
  const appDispatch = useAppDispatch();
  const { t } = useTranslation(["general"]);
  const { open, onDuplicate, onClose, onCreate } = props;
  const accountSettings = useAppSelector(selectAccountSettings);
  const classes = useStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [customer, _setCustomer] = useState<CustomerEntity>();
  const [isValid, _setIsValid] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [countryDefault, setCountryDefault] = useState<Country>(new Country({
    iso3: "MEX", dialCode: 52, dialDigits: 10, dialTemplate: "000-000-0000", name: "México"
  }));

  const customerRef = useRef(customer);
  const setCustomer = (data?: CustomerEntity) => {
    customerRef.current = data;
    _setCustomer(data);
  };

  const isValidRef = useRef(isValid);
  const setIsValid = (data: boolean) => {
    isValidRef.current = data;
    _setIsValid(data);
  };
  const titleTerminology = useCustomerTerminology(t("Customers"), "Customers");
  const listener = (event: any) => {
    if (event.key === "Enter" || event.key === "NumpadEnter") {
      const fetchData = async () => {
        if (customerRef.current && isValidRef.current) {
          const createdCustomer = await CustomerService.createCustomer(customerRef.current);

          if (createdCustomer.isMobileDuplicate) {

            onDuplicate(new CustomerEntity(createdCustomer));
            
          } else {
            
            if (onCreate) {
              onCreate({ ...customerRef.current, id: createdCustomer.id });
            }
  
            setCustomer();

          }

        }
      };

      fetchData();
    }
  };

  useEffect(() => {
    setOpenModal(open);
    if (open) {
      window.addEventListener("keypress", listener, false);
    } else {
      window.removeEventListener("keypress", listener, false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  
  useEffect(() => {
    (async () => {
      const countryInSettings: Country = accountSettings.countryInSettings;
      const entity = new CustomerEntity ({...customer,
        mobileCodeIso3: countryInSettings.iso3,
        mobileDigits: countryInSettings.dialDigits,
        mobileCode: countryInSettings.dialCode,
        mobileTemplate: countryInSettings.dialTemplate
      });
      setCountryDefault(countryInSettings);
      setCustomer(entity);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  const handleCloseModal = () => {
    setCustomer();
    appDispatch(applicationInterfaceActions.updateTitle(titleTerminology));
    setOpenModal(false);
    if (onClose) {
      onClose();
    }
  };
  
  const isUndefinedOrEmpty = (str: string | undefined) => {
    return str === undefined || str === "";
  }
  
  const isFormEmpty = () => {
    return isUndefinedOrEmpty(customer?.firstName) &&
      isUndefinedOrEmpty(customer?.lastName) &&
      isUndefinedOrEmpty(customer?.mobile) &&
      isUndefinedOrEmpty(customer?.email) &&
      customer?.birthDate === undefined
  }
  
  const handleGoBackClick = () => {
    if (isFormEmpty()) {
      handleCloseModal();
    }
    else {
      setCancelModal(true);
    }
  }

  const onCloseHandler = (event: any, reason: any) => {
    if (reason === "backdropClick") {
      handleGoBackClick()
    }
  };

  const handleOnCreateClick = async () => {
    if (customer && isValid) {
      setIsValid(false);
      try {
        const entity = new CustomerEntity ({...customer,
          mobileCodeIso3: customer.mobileCodeIso3 || countryDefault.iso3,
          mobileDigits: customer.mobileDigits || countryDefault.dialDigits,
          mobileCode: customer.mobileCode || countryDefault.dialCode,
          mobileTemplate: customer.mobileTemplate || countryDefault.dialTemplate
        });
        
        const createdCustomer = await CustomerService.createCustomer(entity);

        if (createdCustomer.isMobileDuplicate) {
          onDuplicate(new CustomerEntity(createdCustomer));
        } 
        else {
          setCustomer(createdCustomer);
          if (onCreate) {
            onCreate({ ...entity, id: createdCustomer.id });
            setOpenModal(false);
          }
        }
      } catch (error) {
        setIsValid(true);
      }
    }
  };

  const handleCustomerGeneralChange = (customer: CustomerEntity, valid: boolean) => {

    let validGroup = true;

    const validName = MultipleSpacesNameRegex.test(customer.firstName || "");
    const validLastName = MultipleSpacesNameRegex.test(customer.lastName || "");
    const validMobile = validateMobile(customer);
    const validEmail = !customer.email ? true : EmailRegex.test(customer.email || "");
    const validGender = customer.gender !== Gender.Custom || MultipleSpacesNameRegex.test(customer.customGender || "");

    if (!validName ||
        !validLastName ||
        !validEmail ||
        !validMobile ||
        !validGender
       ) {
      validGroup = false;
    }

    setCustomer(customer);
    setIsValid(valid && validGroup);
  };

  
  const validateMobile = (c: CustomerEntity): boolean => {
    const digits: number = c.mobileDigits || countryDefault.dialDigits;
    let mobile: string = c.mobile!;
    if (mobile) mobile = mobile.length > 0 ? mobile.substring(0, digits) : mobile;
    const validDig = (digits === 7  && /^\d{7}$/.test(mobile!))  ||
                     (digits === 8  && /^\d{8}$/.test(mobile!))  ||
                     (digits === 9  && /^\d{9}$/.test(mobile!))  ||
                     (digits === 10 && /^\d{10}$/.test(mobile!)) ||
                     (digits === 11 && /^\d{11}$/.test(mobile!)) ||
                     (digits === 12 && /^\d{12}$/.test(mobile!));

    return validDig;
}

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={onCloseHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <div className={classes.modalTitle}>
              <Typography className={classes.modalTitleText} variant="h1">
                {useCustomerTerminology(t("New customer"), "customer")}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={handleGoBackClick}
              >
                {t("Go back")}
              </Button>
              <Button
                className={`${classes.button} ${classes.createCustomer}`}
                disabled={!isValid}
                classes={{ disabled: classes.createDisabled }}
                onClick={handleOnCreateClick}
              >
                {useCustomerTerminology(t("Create customer"), "customer")}
              </Button>
            </div>
          </div>
          <CustomerGeneral onChange={handleCustomerGeneralChange} isModal={true} />
          <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={cancelModal}
            onClose={() => setCancelModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={cancelModal}>
              <div className={classes.cancelModalPaper}>
                <div>
                  <div
                    className={`${classes.cancelModalCenter} ${classes.cancelModalTitleSpace}`}
                  >
                    <Typography className={classes.modalTitleText}>
                      {t(
                        "Are you sure you want to cancel customer creation? The changes made will not be saved?"
                      )}
                    </Typography>
                  </div>
                  <div className={classes.modalActions}>
                    <Button
                      className={`${classes.button} ${classes.goBack}`}
                      onClick={() => setCancelModal(false)}
                    >
                      {t("Go back")}
                    </Button>
                    <Button
                      className={`${classes.button} ${classes.cancelConfirmation}`}
                      onClick={() => {
                        handleCloseModal();
                        setCancelModal(false);
                      }}
                    >
                      {t("Yes")}
                    </Button>
                  </div>
                </div>
              </div>
            </Fade>
          </Modal>
        </div>
      </Fade>
    </Modal>
  );
};

export default CustomerModal;
