import React from 'react';
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";
import { Url } from "../../../constants/url";
import ClinicHistory from '../Sections/FormsSettingsTabs/ClinicHistory';
import EvolutionNotes from '../Sections/FormsSettingsTabs/EvolutionNotes';
import PatientForm from '../Sections/FormsSettingsTabs/PatientForm';
import EstablishmentSettingsNavTabs from "../EstablismentSettingsNavTabs";
import General from "../Sections/FormsSettingsTabs/General/general-page";
import CustomerCommunications from "../../settings/Sections/CustomerCommunications/CustomerCommunicationsMain";
import { useSelector } from 'react-redux';
import { RootState, selectUserInfo } from '../../../redux/store';
import PaymentMain from '../../settings/Sections/Payments/PaymentMain';
import Workareas from "../../settings/Sections/WorkArea/WorkAreaMain";
import Users from "../../settings/Sections/Users/UsersMainPage";
import Services from "../../settings/Sections/Services/ServiceMain";
import Commissions from '../../settings/Sections/Commissions/CommissionsMain';
import { useAppSelector } from '../../../redux/hooks';


export default function EstablishmentSettingsRouter() {

  const userInfo = useAppSelector(selectUserInfo);
  const settingsSelectedAccountId = useSelector((state: RootState) => state.enterprise.settingsEstablishmentId);
  const enterpriseSelected = userInfo.accountId === settingsSelectedAccountId; 

  return (
    <>
      {
        userInfo.isAdministrator ?
          <BrowserRouter>
            {enterpriseSelected && <Redirect from="*" to={Url.EstablishmentSettings.General} />}
            <EstablishmentSettingsNavTabs />
            <Switch>
              <Route exact path={Url.EstablishmentSettings.General} component={General} />
              <Route exact 
                  path={Url.EstablishmentSettings.ClinicHistory} 
                  render={(props) => 
                      <ClinicHistory {...props}
                          settingsSelectedEstablishmentId={settingsSelectedAccountId}
                      />}
               />
              <Route exact 
                  path={Url.EstablishmentSettings.EvolutionNotes} 
                  render={(props) => 
                      <EvolutionNotes {...props}
                          settingsSelectedEstablishmentId={settingsSelectedAccountId}
                      />} 
              />
              <Route exact 
                  path={Url.EstablishmentSettings.PatientForm} 
                  render={(props) => 
                      <PatientForm {...props}
                          settingsSelectedEstablishmentId={settingsSelectedAccountId}
                      />} 
              />
              <Route path={Url.Settings.ClinicHistory}>
                <Redirect to={Url.Settings.ClinicHistory} />
              </Route>
              <Route exact 
                  path={Url.EstablishmentSettings.CustomerCommunications} 
                  render={(props) => 
                      <CustomerCommunications {...props}
                          settingsSelectedEstablishmentId={settingsSelectedAccountId}
                      />}
              />
              <Route exact 
                  path={Url.EstablishmentSettings.WorkAreas} 
                  render={(props) => 
                      <Workareas {...props}
                          settingsSelectedEstablishmentId={settingsSelectedAccountId}
                      />} 
              />
              <Route exact 
                  path={Url.EstablishmentSettings.Users} 
                  render={(props) => 
                      <Users {...props}
                          settingsSelectedEstablishmentId={settingsSelectedAccountId}
                      />} 
              />
              <Route exact 
                  path={Url.EstablishmentSettings.Services} 
                  render={(props) => 
                      <Services {...props}
                          settingsSelectedEstablishmentId={settingsSelectedAccountId}
                      />} 
              />
              <Route exact 
                  path={Url.EstablishmentSettings.Payments} 
                  render={(props) => 
                      <PaymentMain {...props}
                          settingsSelectedEstablishmentId={settingsSelectedAccountId}
                      />}
              />
              <Route exact 
                  path={Url.EstablishmentSettings.Commissions} 
                  render={(props) => 
                      <Commissions {...props}
                          settingsSelectedEstablishmentId={settingsSelectedAccountId}
                      />} 
              />
            </Switch>
          </BrowserRouter>
          :
          <Redirect to={Url.Appointments.Main} />
      }
    </>
  );
}
