export default function useCurrencyValueTemplate(valueTemplate?: string) {

  const valuePlaceholder = "{0}";
  const template = valueTemplate ?? valuePlaceholder;
  
  function templatedValue(value: number) {
    const stringValue = value.toFixed(2);
    const valueWithCommas = stringValue.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return template.replace(valuePlaceholder, valueWithCommas);
  }
  
  return templatedValue;
}