import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        overflowY: "auto",
        "& ::-webkit-scrollbar": {
            width: "6px",
        },
        "& ::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#EFEFEF",
            marginTop: "33px",
            marginBottom: "33px"
        },
        "& ::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: "#ACB7C0",
        },
        "& ::-webkit-scrollbar-thumb:hover": {
            background: Color.gray4
        },
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        width: 720,
        maxHeight: 500,
    },
    modalHeader: {
        display: "flex",
        alignItems: "center",
        padding: "24px 40px",
    },
    modalTitle: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 5,
        width: "100%",
        "& svg path": {
            fill: theme.colors.grey5
        }
    },
    modalTitleText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray5
    },
    modalActions: {
        display: "flex",
        justifyContent: "flex-end",
        gap: 16
    },
    goBack: {
        background: "#F6F6F6",
        color: "#ACB7C0",
    },
    continueButton: {
        background: "#36CE91",
        color: "#F6F6F6",
        "&:hover": {
            background: "#36CE91",
        },
    },
    createDisabled: {
        color: "#F6F6F6 !important",
        opacity: "0.4",
    },
    button: {
        width: 200,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
    },
    scrollbarWrapper: {
        width: "100%",
        borderRadius: "0 0 10px 10px",
        backgroundColor: "#FAFAFA",
    },
    content: {
        backgroundColor: "#FAFAFA",
        maxHeight: 420,
        padding: "24px 25px 16px 40px",
        borderRadius: "0 0 10px 10px",
        display: "flex",
        flexDirection: "column",
        gap: 25,
        overflowY: "auto",
        width: "calc(100% - 15px)",
    },
    requiredAsterisk: {
        color: Color.clear1,
        fontWeight: "bold",
        fontSize: 14,
    },
    contentRowContainer: {
        display: "flex",
        alignItems: "flex-end",
        gap: 30
    },
    fieldTypeSelectItemContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 10
    },
    fieldItemsContainer: {
        boxSizing: "border-box",
        overflow: "hidden",
    },
    fieldItemsLayout: {
        display: "flex",
        flexDirection: "column",
    },
    draggableClassName: {
        padding: "4px 0px",
        cursor: "text !important",
    },
    innerItemContentContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 3,
    },
    itemNameLabel: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "17px",
        color: theme.colors.grey5
    },
    addItemButton: {
        justifyContent: "space-around",
        
        marginTop: -17,
        height: 32,
        
        ...theme.typography.buttons,
        fontWeight: 700,
        color: theme.colors.clearMain,
        
        borderRadius: 51,
        border: `1px solid ${theme.colors.clearMain}`,
        
        "&.Mui-disabled": {
            color: theme.colors.clearMain,
        }
    },
    itemEditNameInput: {
        backgroundColor: Color.white + " !important",
        border: "1px solid " + Color.gray2,
        borderRadius: "5px !important",
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "14px",
        color: Color.gray5 + " !important"
    },
    closeConfirmationModal: {
        height: "234px + !important"
    },
    closeModalContentContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    closeModalTitleText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#26292B",
        textAlign: "center",
        maxWidth: "80%"
    },
    closeModalContentText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray5
    },
    separator: {
        height: 0,
        borderTop: "1px solid rgba(201, 205, 211, 0.38)",
        margin: "16px 0px 16px 0px",
        width: 217
    },
    deleteConfirmationModal: {
        height: 194
    },
    deleteModalContentContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 15
    },
    deleteModalTitleText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#26292B"
    },
}));

export default useStyles;