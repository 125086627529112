import { ApiClient } from "./api-client";
import Service from "../models/service";
import { Response } from "./../models/response";
import { SelectGroupServiceModel } from "../models/select-group-service-model";
import { ServiceGroupModel } from "../models/service-group-model";
import { ImportExcelResponse } from "../models/import-excel-response";

export default class ServicesService {
  private static baseUrl: string = "/service";

  static saveImage(file: File): Promise<any> {
    const formData = new FormData();
    formData.append("File", file);

    return ApiClient.post(
      `${this.baseUrl}/saveserviceimage`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      })
      .then(data => {
        return data;
      });
  }


  static getImage(fileName: string): Promise<any> {

    let urlEndpoint = `${this.baseUrl}/getserviceimage?FileName=${fileName}`;

    return ApiClient.get(urlEndpoint)
      .then((data) => {
        return data;
      });
  }

  static getServices(
    page: number,
    pageSize: number,
    order?: string,
    orderBy?: string,
    establishmentAccountId?: string,
    value?: string,
    properties?: string[]
  ): Promise<Response> {

    let urlEndpoint = `${this.baseUrl}/getservices?page=${page}&pageSize=${pageSize}`;

    if (order && orderBy) {
      urlEndpoint += `&order=${order}&orderBy=${orderBy}`;
    }
    if (establishmentAccountId) {
          urlEndpoint += `&accountId=${establishmentAccountId}`;
    }
    if (value && properties && properties.length > 0) {
      urlEndpoint += `&value=${value}`;
      for (const property of properties) {
        urlEndpoint += `&Properties=${property}`;
      }
    }

    return ApiClient.get(urlEndpoint)
      .then((data) => {

        let services: Service[] = [];
        const list = data.services;

        list.forEach((data: any, index: number) => {
          services.push(data);
        });

        return new Response(services, data.total);

      });
  }

  static createService(entity: Service, establishmentAccountId?: string,): Promise<Service> {
    const request = {
      ...entity,
      AccountId: establishmentAccountId
    };
    return ApiClient.post(`${this.baseUrl}/createservice`, request).then(
      (data) => {
        return new Service(data.service);
      }
    );
  }

  static updateService(entity: Service, establishmentAccountId?: string,): Promise<Service> {
    const request = {
      ...entity,
      accountId: establishmentAccountId
    };
    return ApiClient.put(`${this.baseUrl}/updateservice`, request).then(
      (data) => {
        return new Service(data.service);
      }
    );
  }

  static deleteService(entity: Service): Promise<any> {
    return ApiClient.remove(`${this.baseUrl}/deleteservice?id=${entity.id}`).then(
      (data) => {
        return {
          entity: new Service(data.service),
          forbiddenDelete: data.forbiddenDelete,
        };
      }
    );
  }


  static getRecurrences(): Promise<Response> {

    let urlEndpoint = `/account/getrecurrences`;//TODO: CHANGE

    return ApiClient.get(urlEndpoint)
      .then((data) => {
        return new Response(data.recurrences, data.total);
      });
  }

  static getServicesSelect(): Promise<SelectGroupServiceModel[]> {
    let urlEndpoint = `${this.baseUrl}/getservicestoappt`;

    return ApiClient.get(urlEndpoint).then((data) => {
      return data.services.map(
        (service: any) => new SelectGroupServiceModel(service)
      );
    });
  }
  
  static async getServicesGroupByCategory(): Promise<ServiceGroupModel[]> {
    const data = await ApiClient.get(
      `${this.baseUrl}/GetServicesGroupByCategory`
    );
    return data.services.map((service: any) => new ServiceGroupModel(service));
  }

  static createServicesFromFile(file: File, establishmentAccountId?: string): Promise<ImportExcelResponse> {
    const formData = new FormData();
    formData.append("File", file);

    if (establishmentAccountId) {
      formData.append("AccountId", establishmentAccountId);
    }

    return ApiClient.post(`${this.baseUrl}/createfromfile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },    
    }).then((data) => {
      return new ImportExcelResponse(data);
    });
  }

}
