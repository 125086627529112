import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  multiSelect: {
    width: 205,
    height: 32,
    
    border: "solid",
    borderWidth: 1,
    borderColor: "rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
    
    ...theme.typography.txtBody2,
    
    "& .MuiSelect-select": {
      paddingRight: 34
    },
  },
  selected: {
    paddingTop: 8,
    paddingBottom: 8,
    paddingLeft: 15,
    
    fontWeight: "bold",
    color: theme.colors.clearMain,
  },
  focused: {
    "&:focus": {
      backgroundColor: theme.colors.grey1,
    },
    "&.MuiSelect-select": {
      "&:focus": {
        borderRadius: 5,
      },
    },
  },
}));

export default useStyles;
