import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Interweave from "interweave";

import moment from "moment";
import "moment-timezone";

import { CircularProgress, Tooltip } from "@material-ui/core";
import Typography from '@material-ui/core/Typography';

import { useStyles } from "./css";
import {
    DownloadFileCloud,
    ImageIcon,
    WhatsAppDocumentIcon,
    WhatsAppPDFIcon,
    WhatsAppPlayVideoIcon,
    WhatsAppVideoIcon
} from "../../../assets/icons"
import { MessageProps } from "./props";
import { Message as MessageModel } from "./../../../models/message"
import { ConversationMessageType } from "../../../models/enums/conversation-message-type";
import { Color } from "../../../constants/colors";


const Message = React.memo((props: MessageProps) => {
    const {
        message,
        openCarousel,
        setClickedMessageId,
        isCustomerRegistered,
        ...otherProps } = props;
    
    const { t } = useTranslation(["general"]);
    const maxImageWidth = 600;
    const [imageWidth, setImageWidth] = useState<number>(maxImageWidth / 2);
    const [isLoading, setIsLoading] = useState(false);
    const [isPlayIconDisplayed, setPlayIconDisplayed] = useState(false);

    const classes = useStyles(imageWidth)();
    const linkRegex = new RegExp("[Hh]ttps?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\+.~#?&/=]*)");

    useEffect(() => {
        if (!message.previewFileUrl || message.type === ConversationMessageType.Image) {
            setIsLoading(false);
            return;
        }

        const abortController = new AbortController();
        const onAbort = () => {
            // Nothing. Any logic can be added
            // This function will be triggered at abortController.signal on abortController.abort()
        };
        abortController.signal.addEventListener('abort', onAbort);

        checkImageUrl(message.previewFileHeadUrl, abortController);

        return () => {
            abortController.abort(); // Abort any ongoing fetch and stop recursive execution on unmount
            abortController.signal.removeEventListener('abort', onAbort);
        };
    }, [message.previewFileUrl]);

    const handleOnImageLoad = (i: React.SyntheticEvent<HTMLImageElement, Event>) => {
        let imageWidth = i.currentTarget.clientWidth;
        imageWidth = imageWidth > maxImageWidth ? maxImageWidth : imageWidth;
        setImageWidth(imageWidth);
    };

    const checkImageUrl = (headUrl: string | undefined, abortController: AbortController) => {
        fetch(headUrl!, { method: 'HEAD', signal: abortController.signal })
            .then(res => {
                if (res.ok) {
                    setIsLoading(false);
                } else {
                    if (!abortController.signal.aborted) {
                        setIsLoading(true);
                        setTimeout(() => checkImageUrl(headUrl, abortController), 5000);
                    }
                }
            })
            .catch(() => {/*Swallowing "Unhandled Rejection (AbortError): The operation was aborted"*/ });
    };

    const getMessageTimeString = (messageTime: Date) => {
        const messageMoment = moment(messageTime);
        return messageMoment.format("hh:mm[hrs]");
    };

    const encaseLinksWithTags = (str: string) => {
        if (str === null) return str;
        const matches = str.match(linkRegex);
        if (matches && matches.length > 0) {
            let newStr = str;
            newStr = newStr.replace(matches[0], `<a target="_blank" href="${matches[0]}">${matches[0]}</a>`)
            return newStr;
        } else {
            return str;
        }
    }

    const getMessageTextComponent = () => {
        const text = (message.text && message.text !== message.originalFileName) ? message.text : "";

        if (message.type === ConversationMessageType.Document ||
            message.type === ConversationMessageType.Pdf ||
            message.type === ConversationMessageType.Video ||
            message.type === ConversationMessageType.Image) {
            return <Tooltip title={text}>
                <Typography className={`${getTextComponentClassName()} ${classes.mediaCaption}`}>
                    <Interweave content={encaseLinksWithTags(text)} />
                </Typography>
            </Tooltip>
        }
        else {
            return <Typography className={getTextComponentClassName()}>
                <Interweave content={encaseLinksWithTags(text)} />
            </Typography>
        }
    };

    const getFileCaptionComponent = () => {
        if (message.type === ConversationMessageType.Document ||
            message.type === ConversationMessageType.Pdf ||
            message.type === ConversationMessageType.Video ||
            message.type === ConversationMessageType.Image) {
            const fileName = message.originalFileName ?? "";
            return (
                <Tooltip title={fileName}>
                    <Typography className={classes.fileCaptionText}>
                        {fileName}
                    </Typography>
                </Tooltip>
            );
        }
    };

    const getTextComponentClassName = () => {
        return message.isInbound
            ? classes.inboundMessageText
            : classes.outboundMessageText;
    };

    const downloadDocument = () => {
        const link = document.createElement("a");
        link.setAttribute("href", message.fileUrl!);
        link.setAttribute("download", message.originalFileName!);
        link.setAttribute("target", "_blank");
        link.setAttribute("rel", "noopener");
        document.body.appendChild(link);
        link.click();
        link.parentNode?.removeChild(link);
    };

    const openCarouselModal = (messageId: string) => {
        setClickedMessageId(messageId);
        openCarousel();
    }

    const getInnerContent = (message: MessageModel) => {
        if (message.type === ConversationMessageType.Image) {
            return <div className={classes.fileMessageBox}>
                <img
                    loading="lazy"
                    onLoad={i => handleOnImageLoad(i)}
                    className={classes.image}
                    src={message.previewFileUrl}
                    alt={"image"}
                    onClick={() => openCarouselModal(message.id!)}>
                </img>
                <div className={`${classes.previewMessageFooter}`}>
                    <ImageIcon width={40} height={40} style={{ color: Color.gray5 }} />
                    {getFileCaptionComponent()}
                    <DownloadFileCloud onClick={downloadDocument} className={classes.downloadFileIcon} style={{ marginRight: "8px" }} />
                </div>
                {getMessageTextComponent()}
            </div>
        }
        else if (message.type === ConversationMessageType.Pdf) {
            return <div className={classes.fileMessageBox}>
                {isLoading
                    ? <div className={classes.circularLoaderContainer}><CircularProgress size={"2rem"} /></div>
                    : <img
                        loading="lazy"
                        onLoad={i => handleOnImageLoad(i)}
                        className={classes.pdfPreviewImage}
                        src={message.previewFileUrl}
                        alt={"image"}>
                    </img>}
                <div className={`${classes.previewMessageFooter}`}>
                    <WhatsAppPDFIcon />
                    {getFileCaptionComponent()}
                    <DownloadFileCloud onClick={downloadDocument} className={classes.downloadFileIcon} style={{ marginRight: "8px" }} />
                </div>
                {getMessageTextComponent()}
            </div>
        }
        else if (message.type === ConversationMessageType.Video) {
            return (
              <div className={classes.fileMessageBox}>
                {isLoading
                  ? <div className={classes.circularLoaderContainer}><CircularProgress size={"2rem"} /></div>
                  :
                  <div className={classes.videoPreviewImage}
                       onMouseEnter={() => setPlayIconDisplayed(true)}
                       onMouseLeave={() => setPlayIconDisplayed(false)}
                  >
                      <img
                        loading="lazy"
                        onLoad={i => handleOnImageLoad(i)}
                        className={classes.pdfPreviewImage}
                        src={message.previewFileUrl}
                        alt={"image"}
                      />
                      {
                          isPlayIconDisplayed &&
                        <div className={classes.videoPreviewPlayIcon}
                             onClick={() => openCarouselModal(message.id!)}
                        >
                            <WhatsAppPlayVideoIcon />
                        </div>
                      }
                  </div>
                    }
                <div className={`${classes.previewMessageFooter}`}>
                    <WhatsAppVideoIcon />
                    {getFileCaptionComponent()}
                    <DownloadFileCloud onClick={downloadDocument} className={classes.downloadFileIcon} style={{ marginRight: "8px" }} />
                </div>
                {getMessageTextComponent()}
              </div>
            )
        }
        else if (message.type === ConversationMessageType.Document) {
            return <div className={classes.fileMessageBox}>
                <div className={`${classes.documentMessageContainer}`}>
                    <WhatsAppDocumentIcon style={{ marginRight: "5px", marginLeft: "5px" }} />
                    {getFileCaptionComponent()}
                    <DownloadFileCloud onClick={downloadDocument} className={classes.downloadFileIcon} style={{ marginRight: "8px" }} />
                </div>
                {getMessageTextComponent()}
            </div>
        }

        return getMessageTextComponent();
    }

    return (
        <>
            {message.isBlock === null &&
                <div id={`target${message.id}`}>
                    <div className={message.isInbound ? classes.inboundMessageLayout : classes.outboundMessageLayout}>
                        <div className={message.isInbound ? classes.inboundMessageContainer : classes.outboundMessageContainer}>
                            {getInnerContent(message)}
                        </div>
                    </div>
                    <div style={{ marginTop: "-2px" }} className={message.isInbound ? classes.inboundMessageLayout : classes.outboundMessageLayout}>
                        <Typography className={classes.messageTimeText}>
                            {getMessageTimeString(message.time!)}
                        </Typography>
                    </div>
                </div>
            }
            {message.isBlock === true &&
                <div id={`target-block-${ message.id}`}>
                    <div className={classes.blockLabel}>
                        <div className={classes.blockLabelBox}>
                            <Typography className={classes.blockLabelText}>
                                {isCustomerRegistered &&
                                    t("You have blocked this customer.")
                                }
                                {!isCustomerRegistered &&
                                  t("You have blocked this number.")
                                }
                            </Typography>
                        </div>
                    </div>
                </div>
            }
            {message.isBlock === false &&
              <div id={`target-unblock-${ message.id}`}>
                  <div className={classes.blockLabel}>
                      <div className={classes.unblockLabelBox}>
                          <Typography className={classes.unblockLabelText}>
                              {isCustomerRegistered &&
                                t("You have unblocked this customer.")
                              }
                              {!isCustomerRegistered &&
                                t("You have unblocked this number.")
                              }
                          </Typography>
                      </div>
                  </div>
              </div>
            }
        </>
    );
})

export default Message;