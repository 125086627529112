import { ApiClient } from "./api-client";
import { UsersResponse } from "../models/users-response";

import { UserModel } from "../models/user-model";
import { UserEntity } from "../models/user-entity";
import { ServiceCategoryEntity } from "../models/service-category-entity";
import { UserScheduleInfo } from "../models/user-schedule-info";
import { IsUserPhoneDuplicatedModel } from "../models/is-user-phone-duplicated-response";
import { IsUserEmailDuplicatedModel } from "../models/is-user-email-duplicated-response";
import { CommissionByServiceEntity } from "../models/commission-by-service-entity";

export class UserService {
  private static baseUrl: string = "/user";

  static getUserServices(establishmentAccountId?: string): Promise<ServiceCategoryEntity[]> {
    let endpoint = `${this.baseUrl}/getuserservices`;
    if (establishmentAccountId) {
        endpoint += `?accountId=${establishmentAccountId}`;
    }
    return ApiClient.get(endpoint).then(
      (data) => data.serviceCategories
    );
  }

  static getCommissionsByServices(establishmentAccountId?: string): Promise<CommissionByServiceEntity[]> {
    let endpoint = `${this.baseUrl}/getcommissionsbyservices`;
    if (establishmentAccountId) {
        endpoint += `?accountId=${establishmentAccountId}`;
    }
    return ApiClient.get(endpoint).then(
      (data) => data.commissionsByServices
    );
  }

  static getUsers(
    page: number,
    pageSize: number,
    order?: string,
    orderBy?: string,
    establishmentAccountId?: string
  ): Promise<UsersResponse> {
    let urlEndpoint = `${this.baseUrl}/getbyaccount?page=${page}&pageSize=${pageSize}`;
    if (order && orderBy) {
      urlEndpoint += `&order=${order}&orderBy=${orderBy}`;
    }
    if(establishmentAccountId){
      urlEndpoint += `&accountId=${establishmentAccountId}`;
    }

    return ApiClient.get(urlEndpoint).then((data) => new UsersResponse(data));
  }

  static async getUserById(userId: string, establishmentAccountId?: string): Promise<UserEntity> {
      let endpoint = `${this.baseUrl}/get?userId=${userId}`;
      if (establishmentAccountId) {
          endpoint += `&accountId=${establishmentAccountId}`;
      }
      return ApiClient.get(endpoint).then(
      (data) =>
        new UserEntity(
          data.accountId,
          data.user?.general,
          data.user?.schedule,
          data.user?.services,
          data.user?.commission,
          data.user?.permissions,
          data.user?.professionalInformation
        )
    );
  }

  static async createUser(user: UserEntity, signatureImage: File | null, establishmentAccountId?: string): Promise<UserEntity> {
    const formData = new FormData();

    formData.append("UserEntityDtoSerialized", JSON.stringify(user));
    if (establishmentAccountId) {
      formData.append("AccountId", establishmentAccountId);
    }

    // Append the signature if it exists
    if (signatureImage instanceof File) {
      formData.append("SignatureImage", signatureImage);
    }

    return await ApiClient
      .post(`${this.baseUrl}/create`, formData)
      .then(data => new UserEntity(data.user));
  }

  static async updateUser(user: UserEntity, signatureImage: File | null, establishmentAccountId?: string): Promise<UserEntity> {
    const formData = new FormData();

    formData.append("UserEntityDtoSerialized", JSON.stringify(user));
    if (establishmentAccountId) {
      formData.append("AccountId", establishmentAccountId);
    }

    // Append the signature if it exists
    if (signatureImage instanceof File) {
      formData.append("SignatureImage", signatureImage);
    }

    return await ApiClient
      .put(`${this.baseUrl}/update`, formData)
      .then(data => new UserEntity(data.user));
    }

  static async deleteUser(userId: string, establishmentAccountId?: string): Promise<boolean> {
    let endpoint = `${this.baseUrl}/Delete?Id=${userId}`;
    if(establishmentAccountId){
      endpoint += `&accountId=${establishmentAccountId}`;
    }
    return await ApiClient.remove(endpoint).then(
      (data) => (data.succeeded)
    );
  }

  static getUsersBaseInfoByAccount(onlyWithServicesInCharge: boolean): Promise<UserModel[]> {
    return ApiClient.get(`${this.baseUrl}/GetUsersByAccount?onlyWithServicesInCharge=${onlyWithServicesInCharge}`).then((data) =>
      data.users.map((user: any) => new UserModel(user))
    );
  }

  static getUsersBaseInfoByEstablishments(establishmentIds: string[] | undefined): Promise<UserModel[]> {
    return ApiClient.get(
      `${this.baseUrl}/GetUsersByEstablishments?` +
      Object.entries({
        EstablishmentIds: establishmentIds && establishmentIds.length > 0 ? establishmentIds : [],
      }).flatMap(([key, value]) => [value].flat().map(v => [key, v]))
        .map(it => it.join("="))
        .join("&")
    ).then((data) =>
      data.users.map((user: any) => new UserModel(user))
    );
  }

  static getUsersByServiceId(serviceId: string): Promise<UserModel[]> {
    return ApiClient.get(
      `${this.baseUrl}/GetUsersByServiceId?serviceId=${serviceId}`
    ).then((data) => data.users.map((user: any) => new UserModel(user)));
  }

  static getUserAppointmentCount(userId: string, userSchedule: UserScheduleInfo): Promise<number> {
    return ApiClient.post(
      `${this.baseUrl}/getAppointmentCount`, { userId, userSchedule }
    ).then((data) => data.appointmentCount)
  }

  static async getUserUpcomingAppointmentsCount(userId: string, establishmentAccountId?: string): Promise<number> {
    let endpoint = `${this.baseUrl}/GetUserUpcomingAppointmentsCount?Id=${userId}`;
    if (establishmentAccountId) {
          endpoint += `&accountId=${establishmentAccountId}`;
    }
    return await ApiClient.get(endpoint).then(
      (data) => (data.userUpcomingAppointmentsCount)
    );
  }
  
  static getAllUserAppointmentCount(userId: string, disabledServicesIds?: string[], establishmentAccountId?: string) : Promise<number> {
    return ApiClient.post(
      `${this.baseUrl}/getAllAppointmentCount`, { userId, disabledServicesIds, establishmentAccountId }
    ).then((data) => data.appointmentCount)
  }
  
  static getIsPhoneDuplicated(iso3Code: string, phoneNumber: string,  establishmentAccountId?: string) : Promise<IsUserPhoneDuplicatedModel> {
    let endpoint = `${this.baseUrl}/GetIsPhoneDuplicated?mobileCodeIso3=${iso3Code}&mobileNumber=${phoneNumber}` ;
    if (establishmentAccountId) {
          endpoint += `&accountId=${establishmentAccountId}`;
    }
    return ApiClient.get(endpoint).then(
        (data) => new IsUserPhoneDuplicatedModel(data));
  }
  
  static getIsUserEmailDuplicated(email: string,  establishmentAccountId?: string) : Promise<IsUserEmailDuplicatedModel> {
    let endpoint = `${this.baseUrl}/CheckIsUserEmailDuplicated?email=${email}`;
    if (establishmentAccountId) {
          endpoint += `&accountId=${establishmentAccountId}`;
    }
    return ApiClient.get(endpoint).then(
        (data) => new IsUserEmailDuplicatedModel(data));
  }
}
