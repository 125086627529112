import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  label: {
    display: "block",
    color: theme.colors.grey6,
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    lineHeight: "15px",
    fontWeight: "bold",
  },
  rememberMeLabel: {
    color: "#899198",
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: 500,
  },
  inputBox: {
    marginTop: "25px"
  },
  textInput: {
    marginTop: "8px",
    width: "370px",
  },
  input: {
    color: theme.colors.grey5,
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    lineHeight: "14px",
    backgroundColor: "#F6F6F6",
    borderRadius: "5px",
    "& input": {
      height: 24,
      backgroundColor: "#F6F6F6 !important"
    }
  },
  inputNormalWeight: {
    fontWeight: "normal"
  },
  inputBoldWeight: {
    fontWeight: "bold"
  },
  logInButton: {
    width: "370px",
    borderRadius: "51px",
    padding: "9px 16px",
    fontFamily: "Inter, sans-serif",
    fontWeight: "bold",
  },
  forgetLink: {
    color: theme.colors.grey4,
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: "bold",
    "&:hover": {
       color: theme.colors.grey4
    },
  },
  utilities: {
    display: "flex",
    alignItems: "center",
    marginTop: "25px",
    marginBottom: "33px"
  },
  form: {
    marginTop: 33
  }
}));

export default useStyles;