import React, { useImperativeHandle, useRef } from "react";

import useStyles from "./css";
import { ImageDropzoneProps } from "./props";
import ImageDropzoneRefs from "./refs";


const ImageDropzone = React.forwardRef<ImageDropzoneRefs, ImageDropzoneProps>((props: ImageDropzoneProps, ref) => {

  const {
    imageUrl,
    emptyFileComponent,
    onChange,
    validationFunction,
    dropzoneClass,
    disableChange
  } = props;

  const classes = useStyles();
  const imageRef = useRef<HTMLInputElement>(null);

  useImperativeHandle(ref, () => ({
    openFileExplorerDialog: showOpenFileDialog,
    removeImage: () => {
      if (!imageRef?.current || !imageRef.current.files || imageRef.current.files.length === 0)
        return;

      imageRef.current.files = new DataTransfer().files;
    }
  }));

  const showOpenFileDialog = () => {
    if (disableChange) return;
    if (imageRef && imageRef.current != undefined) {
      imageRef.current.click();
    }
  };

  const dragOver = (e: any) => {
    e.preventDefault();
  }

  const dragEnter = (e: any) => {
    e.preventDefault();
  }

  const dragLeave = (e: any) => {
    e.preventDefault();
  }

  const handleFileDrop = async (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    if (disableChange) return;

    if (imageRef.current) {
      const files = e.dataTransfer.files;
      imageRef.current.files = files;
      await handleFileSelected(files);
    }
  }

  const handleChangeFile = async (e: React.FormEvent<HTMLInputElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (disableChange) return;

    const files = imageRef.current?.files;
    await handleFileSelected(files);
  };

  const handleFileSelected = async (files: any) => {

    if (files && files.length > 0) {
      const file = files[0];
      if (typeof validationFunction === "function" && validationFunction) {
        const validated = await validationFunction(file);
        if (!validated) return;
      }
      if (onChange) onChange(file);
    }
  }

  return (
    <div>
      <input
        ref={imageRef!}
        type="file"
        style={{ display: 'none' }}
        accept=".jpg,.jpeg,.png"
        onChange={handleChangeFile}
      />
      <div
        className={`${classes.bkgPic} ${dropzoneClass}`}
        onClick={showOpenFileDialog}
        style={{ backgroundImage: `url(${imageUrl})` }}
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={handleFileDrop}
      >
        {
          !imageUrl && emptyFileComponent
        }
      </div>
    </div>);
})

export default ImageDropzone;
