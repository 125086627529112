import { EvolutionNoteFieldAndValue } from "./evolution-note-field-and-value";

export default class EvolutionNotesGrouped {
  
  year: number;
  month: number;
  evolutionNotes: EvolutionNote[];
  vitalSignsFields: EvolutionNoteFieldAndValue[];
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.year = x.year;
      this.month = x.month;
      this.evolutionNotes = x.evolutionNotes;
      this.vitalSignsFields = x.vitalSignsFields;
      
    } else {
      this.year = 0;
      this.month = 0;
      this.evolutionNotes = [];
      this.vitalSignsFields = [];
    }
  }
}

export class EvolutionNote {
  id: string;
  customerId: string;
  appointmentId: string | undefined;
  serviceId: string | undefined;
  user: string;
  appointmentDate: Date | null;
  dateCreated: Date;
  editMode: boolean;
  evolutionNoteText: string;
  diagnosis: string | null;
  additionalInstructions: string | null;
  clinicalFiles: EvolutionNoteClinicalFile[];
  treatments: EvolutionNoteTreatmentInput[];
  studies: EvolutionNoteDynamicInput[];
  vitalSigns: EvolutionNoteFieldAndValue[];
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.customerId = x.customerId;
      this.appointmentId = x.appointmentId;
      this.serviceId = x.serviceId;
      this.editMode = x.editMode;
      this.user = x.user;
      this.evolutionNoteText = x.evolutionNoteText;
      this.diagnosis = x.diagnosis;
      this.additionalInstructions = x.additionalInstructions;
      this.treatments = x.treatments;
      this.vitalSigns = x.vitalSigns;
      this.studies = x.studies;
      this.clinicalFiles = x.clinicalFiles;
      this.appointmentDate = x.appointmentDate;
      this.dateCreated = x.dateCreated;
    } else {
      this.id = "";
      this.customerId = "";
      this.appointmentId = undefined;
      this.serviceId = undefined;
      this.user = "";
      this.editMode = false;
      this.evolutionNoteText = "";
      this.diagnosis = null;
      this.additionalInstructions = null;
      this.treatments = [];
      this.vitalSigns = [];
      this.clinicalFiles = [];
      this.studies = [];
      this.appointmentDate = new Date();
      this.dateCreated = new Date();
    }
  }
}

export class EvolutionNoteDynamicInput {
  id: string | null;
  name: string;
  order: number;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.name = x.name;
      this.order = x.order;
    } else {
      this.id = null;
      this.name = "";
      this.order = 0;
    }
  }
}

export class EvolutionNoteTreatmentInput {
  id: string | null;
  medication: string;
  prescription: string;
  order: number;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.medication = x.medication;
      this.prescription = x.prescription;
      this.order = x.order;
    } else {
      this.id = null;
      this.medication = "";
      this.prescription = "";
      this.order = 0;
    }
  }
}

export class EvolutionNoteClinicalFile {
  id: string | null;
  fileName: string;
  fileURL: string;
  s3Key: string;
  customerId: number;
  evolutionNoteId: string;
  fileComment: string;
  uploadedBy: string;
  created: Date;
  fileBlob: Blob;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.fileName = x.fileName;
      this.fileURL = x.fileURL;
      this.s3Key = x.s3Key;
      this.customerId = x.customerId;
      this.evolutionNoteId = x.evolutionNoteId;
      this.fileComment = x.fileComment;
      this.uploadedBy = x.uploadedBy;
      this.created = x.created;
      this.fileBlob = x.fileBlob;
    } else {
      this.id = null;
      this.fileName = "";
      this.fileURL = "";
      this.s3Key = "";
      this.customerId = 0;
      this.evolutionNoteId = "";
      this.fileComment = "";
      this.uploadedBy = "";
      this.created = new Date();
      this.fileBlob = new Blob();
    }
  }
}