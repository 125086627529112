export enum Colors {
    Transparent = 'transparent',
    White = '#FFFFFF',
    Neutral2 = '#FAFAFA',
    Neutral3 = '#EFEFEF',
    Neutral4 = '#C9CDD3',
    Neutral5 = '#ACB7C0',
    Neutral6 = '#899198',
    Neutral7 = '#6A6E72',
    Neutral8 = '#4A4D51',
    Neutral9 = '#26292B',
    Black = '#26292B',
    GrayBlue = '#63799B',

    Success = '#36CE91',
    Success_dark = '#33AF7E',
    Success_background = '#D7F5E9',
    Error = '#F15857',
    Error_dark = '#CA4F4F',
    Error_background = '#FCDEDD',
    Warning = '#FFC626',
    Warning_dark = '#E8B423',

    CM1 = '#0069FF',
    CM1_dark = '#075DD7',
    CM2 = '#467CFC',
    CM3 = '#6797F5',
    CM4 = '#7BABF9',
    CM5 = '#E6EEFE',

    Grey5 = '#5C6477',
    Black0 = '#000000',
}
