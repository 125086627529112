import React, { useEffect, useState, useRef, useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';

import ServicesService from "../../../../../api/settings-services-service";
import { EstablishmentPhotoService } from "../../../../../api/settings-establishmentphoto-service";
import { EstablishmentPhoto } from "../../../../../models/establishmentphoto";

import Alert from "../../../../../models/alert";
import { AlertType } from "../../../../../models/enums/alert-type";

import DropImage from "../../../../common/DropImage";
import ConfirmDeleteModal from "../../../../common/ConfirmDeleteModal";

import { CameraIcon, PlusIcon, TrashIcon } from "../../../../../assets/icons";
import { useStyles } from "./css";

import { SettingsEnterpriseProps } from "../../../../../models/interfaces/settings-enterprise-props";

export default (props: SettingsEnterpriseProps) => {

    const { settingsSelectedEstablishmentId } = props;

    const { t } = useTranslation(["general"]);
    const classes = useStyles();
    const imageRef = useRef<HTMLInputElement>(null);

    const [photos, setPhotos] = useState<EstablishmentPhoto[]>([]);
    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [photoToDel, setPhotoToDel] = useState<number>(0);
    const [photoToUp, setPhotoToUp] = useState<number>(0);

    const [, updateState] = useState<object>();
    const forceUpdate = useCallback(() => updateState({}), []);

    useEffect(() => {

        (async () => {

            const photosDb = settingsSelectedEstablishmentId
                ? await EstablishmentPhotoService.getEstablishmentPhotos(settingsSelectedEstablishmentId)
                : await EstablishmentPhotoService.getEstablishmentPhotos();
            let picturls: EstablishmentPhoto[] = [];

            for (let pic: number = 0; pic < 3; pic++) {
                const p = photosDb.filter(x => x.order === pic);
                if (p.length > 0) {
                    picturls.push(p[0]);
                } else {
                    picturls.push(new EstablishmentPhoto());
                }
            }

            setPhotos(picturls);

        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [settingsSelectedEstablishmentId]);

    const handlePhotoUpload = async (files: any, photoIndex: number) => {

        if (files && files.length > 0) {

            const validate = await isFileNotValid(files[0]);
            if (validate) return;

            let dataPhoto: any = await ServicesService.saveImage(files[0]);
            if (dataPhoto) {
                const newPhoto: EstablishmentPhoto = new EstablishmentPhoto();
                newPhoto.photoId = dataPhoto.fileName;
                //newPhoto.photoURL = dataPhoto.fileURL;
                newPhoto.order = photoIndex;

                if (settingsSelectedEstablishmentId)
                    newPhoto.accountId = settingsSelectedEstablishmentId

                const establishmentPhoto: EstablishmentPhoto = await EstablishmentPhotoService.updateEstablishmentPhoto(newPhoto);

                if (establishmentPhoto.photoId) {
                    photos[photoIndex] = establishmentPhoto;
                    setPhotos(photos);

                    forceUpdate();
                }
            }
        }
    }


    const isFileNotValid = async (file: any) => {
        const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
        if (validTypes.indexOf(file.type) === -1) {
            //console.log("File is not jpg/png");
            return true;
        }

        if (file.size > (800 * 1024)) {
            //console.log("File is too large. Maximium is 800kb");
            return true;
        }

        return false;
    }


    const handleChangeFile = async (e: React.FormEvent<HTMLInputElement>) => {
        e.stopPropagation();
        e.preventDefault();

        const files = imageRef.current?.files;
        await handlePhotoUpload(files, photoToUp);
    }


    const showOpenFileDialog = (index: number) => {
        if (imageRef && imageRef != null && imageRef != undefined && imageRef.current != undefined) {
            imageRef.current.click();
            setPhotoToUp(index);
        }
    };


    const confirmDelete = (index: number) => {
        setOpenDeleteDialog(true);
        setPhotoToDel(index);
    };

    const handleDelete = async (photoIndex: number) => {

        const delPhoto: EstablishmentPhoto = photos.filter(x => x.order === photoIndex)[0];

        if (settingsSelectedEstablishmentId)
            delPhoto.accountId = settingsSelectedEstablishmentId;

        let establishmentPhoto = await EstablishmentPhotoService.deleteEstablishmentPhoto(delPhoto);

        if (establishmentPhoto) {
            photos[photoIndex] = new EstablishmentPhoto();
            setPhotos(photos);
            setOpenDeleteDialog(false);
            forceUpdate();
        }
    };


    const DivPhotoEmpty: React.FC = () => {
        return (<>
            <div className={classes.iconPlus}>
                <PlusIcon style={{ color: "#6462F3" }} />
            </div>
            <div className={classes.labelAddPhoto}>
                {t("Add photo")}
            </div>
            <div className={classes.labelAddPhotoIns}>
                {t("Min size") + ": 660px x 450px"}
            </div>
        </>);
    };


    const DivInfoDelete: React.FC = () => {
        return (<>
            <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`} >
                <Typography className={classes.modalTitleText}>
                    {t("Delete this establishment photo?")}
                </Typography>
            </div>
        </>);
    };


    return (<>
        <input
            ref={imageRef!}
            type="file"
            style={{ display: 'none' }}
            accept="image/jpeg, image/jpg, image/png"
            onChange={(e) => handleChangeFile(e)}
        />

        <Typography className={classes.label}>
            {t("Photographs of the establishment")}
        </Typography>
        <span className={classes.optional}>({t("Optional")})</span>
        <br />
        <div className={classes.labelSecondary}>
            {t("These photos will appear on your site for appointments")}.
        </div>

        <div className={classes.photosEstablishmentRow}>
            {
                photos.map((photo, index) => {
                    return (<div key={index} className={classes.photosEstablishmentCol}>

                        <DropImage
                            key={index}
                            classDivUploadImage={photo.photoURL ? classes.photoEstablishment : classes.photoEstablishmentEmpty}
                            onChange={e => handlePhotoUpload(e, index)}
                            imageURL={photo.photoURL}
                            disableChange={photo.photoId !== ""}
                            funcToValid={isFileNotValid}
                            disableBlob={true}
                            componentShowEmpty={<DivPhotoEmpty />} />

                        <div className={classes.photosEstablishmentControls}>
                            {
                                <div style={{ display: photo.photoId ? "" : "none" }}>
                                    <CameraIcon className={classes.iconCamera} onClick={() => showOpenFileDialog(index)} />
                                    <TrashIcon style={{ color: "#919CA5" }} className={classes.iconTrash} onClick={() => confirmDelete(index)} />
                                </div>
                            }
                        </div>
                    </div>)
                })
            }
        </div>

        <ConfirmDeleteModal
            classModal={classes.modalDelete}
            open={openDeleteDialog}
            item={photoToDel}
            componentInfo={<DivInfoDelete />}
            onClose={() => setOpenDeleteDialog(false)}
            onDelete={handleDelete}
        />

    </>
    );
};
