import { Colors } from './Colors';

const scrollbarWidth = 6;

type ScrollbarOptions = {
    widerHorizontal?: boolean;
};

export const scrollbarStyle = (opts?: ScrollbarOptions) => ({
    '&::-webkit-scrollbar': {
        width: scrollbarWidth,
        height: scrollbarWidth * (opts?.widerHorizontal ? 2 : 1),
    },
    /* Track */
    '&::-webkit-scrollbar-track': {
        background: `${Colors.Neutral3}!important`,
        borderRadius: '50px',
    },

    /* Handle */
    '&::-webkit-scrollbar-thumb': {
        background: `${Colors.Neutral5} !important`,
        borderRadius: '50px',
        marginTop: '10%',
        marginBottom: '10%',

        '&:hover': {
            background: `${Colors.Neutral7} !important`,
        },
    },
});
