import { ApiClient } from "./api-client";
import { ClinicalHistoryCommunicationSettings } from "../models/clinical-history-communication-settings-model";
import { CopyEstablishmentSettingsModel } from "../models/copy-establishment-settings-model";

export class SettingsEvolutionNotesCommunicationService {
  
  private static baseUrl: string = "/account";
  
  static async copyEvolutionNotesSetting(copyModel: CopyEstablishmentSettingsModel): Promise<boolean> {
    return await ApiClient.post(`${this.baseUrl}/copyevolutionnotessetting`, copyModel).then(
      (data) => (data.succeeded)
    );
  }
}
