import React, { useEffect, useState } from 'react';

import { Editor } from "@tinymce/tinymce-react";

import { HtmlTextAreaProps } from "./props";
import useStyles from "./css";


export default function HtmlTextArea(props: HtmlTextAreaProps) {

  const { text, setText, maxLength, onBlur } = props;

  const classes = useStyles();

  const [focused, setFocused] = useState<boolean>(false);
  const [textLength, setTextLength] = useState<number>(0);

  function getTextInitialValue(): string {
    if (!text || text === "") {
      return "";
    }
    return text;
  }

  useEffect(() => {
    setText(getTextInitialValue())
  }, [])

  function setLength(evt: any, editor: any) {
    setTextLength(editor.getContent({ format: "text" }).length);
  }

  const handleEditorChange = (c: string, editor: any) => {
    const contentText = editor.getContent({ format: "text" });
    const contentHtml = editor.getContent();

    if (contentText.length > maxLength) {
      return;
    }

    setText(contentHtml);
    setTextLength(contentText.length);
  }

  function handleFocusOut() {
    setFocused(false)
    onBlur?.();
  }

  return (
    <div className={`${classes.tinyMceOverrides} ${textLength === 0
      ? classes.tinyMceInvalid
      : (focused
        ? classes.tinyMceFocused
        : classes.tinyMceUnfocused)}`}>
      <Editor
        apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
        init={{
          height: 160,
          width: 680,
          menubar: false,
          statusbar: false,
          plugins: 'wordcount paste lists',
          toolbar: 'bold italic underline | numlist bullist styleselect ',
          content_style: "body {fontFamily: Inter, sans-serif; font-weight: 400; font-size: 14px; color: #323947 }",
          entity_encoding: "raw",
          formats: {
            bold: { inline: 'b' },
            italic: { inline: 'i' },
            underline: { inline: 'u' }
          },
          paste_as_text: true,
          extended_valid_elements: "b, i, u",
          invalid_elements: "strong, em, span",
          remove_linebreaks: true,
          setup: (editor) => {
            editor.on("keydown", (e) => {
              //without the next block, the cursor will lose its position immediately after the text becomes invalid
              if (textLength > maxLength) {
                editor.focus();
                editor.selection.select(editor.getBody(), true);
                editor.selection.collapse(false);
              }
            });
          }
        }}
        value={text}
        onFocusIn={() => setFocused(true)}
        onFocusOut={handleFocusOut}
        onInit={setLength}
        onEditorChange={handleEditorChange}
        outputFormat="html"
        onChange={(e) => {
          if (textLength >= maxLength) {
            e.preventDefault();
          }
        }}
      />
      <span className={`${classes.charCounter} ${textLength > maxLength ? classes.charCounterInvalid : ""}`}>{textLength + "/" + maxLength}</span>
    </div>
  )
}