import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";


export default function FlatImageIcon(props: any) {

  const { style, viewBox, color, ...otherProps } = props;

  return (
    <SvgIcon
      {...otherProps}
      style={style || { width: 41, height: 41 }}
      viewBox={viewBox || "0 0 41 41"}
    >
      <path 
        d="M29.5709 28.4928C30.7558 28.4928 31.7584 27.5814 31.7584 26.4095V13.9095C31.7584 12.781 30.7558 11.8262 29.5709 11.8262H10.6125C9.38208 11.8262 8.42505 12.781 8.42505 13.9095V26.4095C8.42505 27.5814 9.38208 28.4928 10.6125 28.4928H29.5709ZM13.5292 14.2567C14.8964 14.2567 16.0813 15.3852 16.0813 16.6873C16.0813 18.0328 14.8964 19.1178 13.5292 19.1178C12.1165 19.1178 10.9771 18.0328 10.9771 16.6873C10.9771 15.3852 12.1165 14.2567 13.5292 14.2567ZM11.3417 25.7151V23.6317L14.5774 20.5501C14.8053 20.3331 15.1243 20.3331 15.3521 20.5501L17.9042 22.9373L24.0566 17.0779C24.2844 16.8609 24.6034 16.8609 24.8313 17.0779L28.8417 20.854V25.7151H11.3417Z" 
        fill={ color }
      />

    </SvgIcon>
  );
}
