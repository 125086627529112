import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  paper: {
    marginTop: -10,
    width: 360,
    borderRadius: 10
  },
  list: {
    padding: 0
  },
  iconNotificationButton: {
    width: 40,
    height: 40,
    backgroundColor: "#F6F6F6",
    "&:hover": {
      color: "#fff",
      backgroundColor: "#6462F3",
    },
    
    position: "relative"
  },
  notificationDot: {
    width: 16,
    height: 16,
    
    display: "block",
    position: "absolute",
    top: 0,
    right: 0,
    textAlign: "center",
    paddingTop: 0.7,
    
    backgroundColor: theme.colors.clearRed,
    borderRadius: "50%",
  
    fontFamily: "Inter, sans-serif",
    fontSize: 9,
    fontWeight: 700,
  
    color: theme.colors.grey1,
    
  }
  })
);

export default useStyles;