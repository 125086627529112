import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { AppointmentPaymentPartProps } from "./props";
import useStyles from "./css";
import Typography from "@material-ui/core/Typography";
import InputCurrency from "../../common/InputCurrency";
import { useAppSelector } from "../../../redux/hooks";
import { selectAccountSettings } from "../../../redux/store";
import { AppointmentPaymentPartEntity } from "../../../models/appointment-payment-part-entity";
import { PaymentMethodType } from "../../../models/enums/payment-method-type";
import SelectKey from "../../common/SelectKey";
import { PaymentEditionType } from "../../../models/enums/payment-edition-type";
import { BillIcon } from "../../../assets/icons";

export default function AppointmentPaymentPart(props: AppointmentPaymentPartProps) {

  const { paymentPart, onChange, editionType, paymentMethods, excludedPaymentMethodTypes } = props;

  const classes = useStyles();
  const { t } = useTranslation(["general"]);

  const accountSettings = useAppSelector(selectAccountSettings);
  const currencyTemplate: string = accountSettings.currencyTemplate;
  
  const allowedPaymentMethods = paymentMethods.filter(pm => !excludedPaymentMethodTypes.some(t => pm.key === t && pm.key !== paymentPart.paymentMethod));

  function setPaymentPart(part: AppointmentPaymentPartEntity) {
    onChange(part)
  }

  function setPaymentAmount(amountPaid: number) {
    if (amountPaid >= 0) {
      setPaymentPart({ ...paymentPart, amountPaid })
    }
  }
  
  function setPaymentMethod(paymentMethod: PaymentMethodType) {
    setPaymentPart({ ...paymentPart, paymentMethod })
  }
  
  function getAmountPaidTitle() : string {
    switch (editionType) {
      case PaymentEditionType.AddAmount:
        return t("Add amount");
      case PaymentEditionType.SubtractAmount:
        return t("Subtract amount");
      default:
        return t("Amount paid");
    }
  }

  return (
    <>
      <div className={classes.inputWithLabel}>
        <div>
          <Typography className={classes.controlLabel}>
            {getAmountPaidTitle()}
          </Typography>
          <span className={classes.dot}> *</span>
        </div>
        <InputCurrency
          value={paymentPart.amountPaid}
          setValue={setPaymentAmount}
          valueTemplate={currencyTemplate}
          width={"100%"}
          isZeroAllowed={true}
          isValid={() => {
            return (paymentPart.amountPaid >= 0);
          }}
        />
      </div>
  
      <div className={classes.inputWithLabel}>
        <div>
          <Typography className={classes.controlLabel}>
            {t("Payment method")}
          </Typography>
          <span className={classes.dot}> *</span>
        </div>
        <SelectKey
          value={paymentPart.paymentMethod}
          setValue={setPaymentMethod}
          itemRootClass={classes.itemRoot}
          placeholder={t("Select a payment method")}
          items={allowedPaymentMethods}
          width={"100%"}
          arrowIcon={<BillIcon />}
        />
      </div>
    </>
  );
}