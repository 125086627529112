import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ExcelIcon = (props: any) => {
  const { style, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{fontSize: '66px'}}
      viewBox="0 0 64 64"
    >
      <path
        d="M46.75 18.8332L39.8333 11.8332C39.0833 11.0832 38 10.6665 37 10.6665H20C17.75 10.6665 16 12.4998 16 14.6665V49.3332C16 51.5832 17.75 53.3332 20 53.3332H44C46.1667 53.3332 48 51.5832 48 49.3332V21.6665C48 20.6665 47.5 19.5832 46.75 18.8332ZM43.6667 21.3332H37.3333V14.9998L43.6667 21.3332ZM20 49.3332V14.6665H33.3333V23.3332C33.3333 24.4998 34.1667 25.3332 35.3333 25.3332H44V49.3332H20ZM37.6667 29.3332H35.25C34.8333 29.3332 34.5 29.5832 34.3333 29.9165C32.8333 32.6665 32.5 33.4165 32 34.6665C30.8333 32.2498 31.4167 33.2498 29.5833 29.9165C29.4167 29.5832 29.0833 29.3332 28.6667 29.3332H26.3333C25.5 29.3332 25.0833 30.1665 25.4167 30.8332L29.25 37.3332L25.4167 43.8332C25 44.4998 25.5 45.3332 26.3333 45.3332H28.6667C29.0833 45.3332 29.4167 45.1665 29.5833 44.8332C31.4167 41.4998 31.5 41.0832 32 39.9998C33.1667 42.5832 32.4167 41.3332 34.3333 44.8332C34.5 45.1665 34.8333 45.3332 35.25 45.3332H37.6667C38.4167 45.3332 38.9167 44.4998 38.5 43.8332L34.6667 37.3332C34.6667 37.2498 37.1667 33.1665 38.5 30.8332C38.9167 30.1665 38.4167 29.3332 37.6667 29.3332Z"
        fill={style?.color || "#919CA5"}
      />
    </SvgIcon>
  )
};
export default ExcelIcon;
