import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function PersonSpeechBalloonIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={style || { width: 12, height: 12 }}
            viewBox={viewBox || "0 0 12 12"}
        >
            <path
                d="M4.65123 6.25C5.63599 6.25 6.45193 5.41504 6.45193 4.375C6.45193 3.34961 5.63599 2.5 4.65123 2.5C3.6524 2.5 2.85053 3.34961 2.85053 4.375C2.85053 5.41504 3.6524 6.25 4.65123 6.25ZM5.90328 6.71875H5.66413C5.35463 6.87988 5.017 6.95312 4.65123 6.95312C4.28546 6.95312 3.93376 6.87988 3.62427 6.71875H3.38511C2.34408 6.71875 1.5 7.6123 1.5 8.69629V9.29688C1.5 9.69238 1.79543 10 2.17526 10H7.1272C7.49297 10 7.80246 9.69238 7.80246 9.29688V8.69629C7.80246 7.6123 6.94431 6.71875 5.90328 6.71875Z"
                fill={style?.color || "#7175FA"} />
            <path
                d="M8.75134 2C7.78138 2 7.00268 2.73438 7.00268 3.625C7.00268 4.01562 7.14612 4.36719 7.39203 4.64844C7.30323 5.04688 7.01634 5.39062 7.01634 5.39844C7.00268 5.41406 6.99585 5.44531 7.00268 5.46875C7.01634 5.49219 7.03 5.5 7.05733 5.5C7.50815 5.5 7.84969 5.25781 8.01362 5.10156C8.23904 5.19531 8.48494 5.25 8.75134 5.25C9.71447 5.25 10.5 4.52344 10.5 3.625C10.5 2.73438 9.71447 2 8.75134 2Z"
                fill={style?.color || "#7175FA"} />
        </SvgIcon>
    );
};