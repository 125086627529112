export class UserPermissionsInfo {
    hasAdminAccess: boolean;
    hasCustomerInfoVisible: boolean;
    hasMassiveSendings: boolean;
    notifyOnCustomerRequiresAttention: boolean;
    notifyOnCashRegisterClosing: boolean;
    showAllAppointments: boolean;
    canRegisterPayments: boolean;
    canOpenCloseCashRegister: boolean;
    canMakeCashRegisterMovements: boolean;
    canSeeClinicalFile: boolean;
    canInteractWithEvolutionNotes: boolean;
    canInteractWithPatientFiles: boolean;
    hasAccessToWhatsAppConversationsAndNotifications: boolean;
    hasAccessToReports: boolean;
    hasAccessToScheduleAllSpecialists: boolean;
    showPrices: boolean;

    constructor(x?: any) {
        if (typeof x === "object") {
            this.hasAdminAccess = x.hasAdminAccess;
            this.hasMassiveSendings = x.hasMassiveSendings;
            this.notifyOnCustomerRequiresAttention = x.notifyOnCustomerRequiresAttention;
            this.hasCustomerInfoVisible = x.hasCustomerInfoVisible ?? true;
            this.notifyOnCashRegisterClosing = x.notifyOnCashRegisterClosing;
            this.showAllAppointments = x.showAllAppointments;
            this.canRegisterPayments = x.canRegisterPayments;
            this.canOpenCloseCashRegister = x.canOpenCloseCashRegister;
            this.canMakeCashRegisterMovements = x.canMakeCashRegisterMovements;
            this.canSeeClinicalFile = x.canSeeClinicalFile;
            this.canInteractWithEvolutionNotes = x.canInteractWithEvolutionNotes;
            this.canInteractWithPatientFiles = x.canInteractWithPatientFiles;
            this.hasAccessToWhatsAppConversationsAndNotifications = x.hasAccessToWhatsAppConversationsAndNotifications;
            this.hasAccessToReports = x.hasAccessToReports;
            this.hasAccessToScheduleAllSpecialists = x.hasAccessToScheduleAllSpecialists;
            this.showPrices = x.showPrices;
        }
        else {
            this.notifyOnCustomerRequiresAttention = false;
            this.hasCustomerInfoVisible = true;
            this.hasAdminAccess = false;
            this.hasMassiveSendings = false;
            this.notifyOnCashRegisterClosing = false;
            this.showAllAppointments = false;
            this.canRegisterPayments = false;
            this.canOpenCloseCashRegister = false;
            this.canMakeCashRegisterMovements = false;
            this.canSeeClinicalFile = false;
            this.canInteractWithEvolutionNotes = false;
            this.canInteractWithPatientFiles = false;
            this.hasAccessToWhatsAppConversationsAndNotifications = true;
            this.hasAccessToReports = true;
            this.hasAccessToScheduleAllSpecialists = true;
            this.showPrices = false;
        }
    }
}