import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  select: {
    ...theme.typography.subtitle1,
    backgroundColor: theme.colors.clearGreen,
    height: 40,
    justifyContent: "center",
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 100,
    cursor: "pointer",
    width: 600,
    "& .MuiSvgIcon-root": {
      right: "unset",
      left: 190,
      top: 14
    },
    
    "&.Mui-disabled": {
      opacity: 0.5,
      border: "solid 1px rgba(0, 0, 0, 0.05)",
    }
  },
  tooltipBlock: {
    backgroundColor: theme.colors.grey1,
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    boxShadow: "0px 4px 4px 0px",
    borderRadius: 5,
    "& .MuiTooltip-arrow": {
      color: theme.colors.grey1,
    },
  },
  selected: {
    fontWeight: "bold",
    textAlign: "center",
    width: 450,
    padding: "11px 0px 12px 12px",
  },
  tooltipContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
  selectContainer: {
    display: "flex",
    alignItems: "center",
    position: "relative",
  },
  arrowIcon: {
    position: "absolute",
    zIndex: 5,
    color: "#F6F6F6",
    left: "8px",
  },
  focused: {
    color: theme.colors.grey1,
    textAlign: "center",
    "&:focus": {
      backgroundColor: theme.colors.clearGreen,
    },
    "& .MuiSelect-select": {
      "&:focus": {
        borderRadius: 100,
      },
    },
  },
  menuPaper: {
    maxHeight: 300,
    marginTop: 6,
  },
  itemRoot: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    height: 40,
    display: "flex",
    gap: 12,
    alignItems: "center",
    "&.Mui-focusVisible": {
      backgroundColor: "inherit",
      "&:hover": {
        backgroundColor: "#F6F6F6 !important",
      },
    },
    "&.Mui-disabled": {
      opacity: "1!important",
      backgroundColor: "#F6F6F6",
    },
  },
  itemSelected: {
    fontWeight: "bold",
    color: theme.colors.clearMain,
  },
}));

export default useStyles;
