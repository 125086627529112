import React, { useState, useEffect } from "react";

import { SelectItem } from "../../../models/interfaces/select-item";
import useStyles from "./css";
import { SelectMonthDayDateProps } from "./props";
import SelectKey from "../SelectKey";
import Typography from "@material-ui/core/Typography";
import DateInput from "../DateInput";
import { useTranslation } from "react-i18next";

export default function SelectMonthDayDate(props: SelectMonthDayDateProps) {
  
  const { date, setDate, width, minDate } = props;
  const { t } = useTranslation("massivesendings")
  
  const newDate = new Date();
  const currentYear = newDate.getFullYear();
  const currentMonth = newDate.getMonth();
  const nextYear = newDate.getFullYear() + 1;
  
  const [innerDate, setInnerDate] = useState<Date>(date ?? minDate);
  const [month, setMonth] = useState<number>(innerDate.getMonth());
  const [minDayDate, setMinDayDate] = useState<Date>(getFirstDayOfMonthDate(date ?? minDate));
  const [maxDayDate, setMaxDayDate] = useState<Date>(getLastDayOfMonthDate(date ?? minDate));
  
  const classes = useStyles(width)();

  useEffect(() => {
    if (date !== innerDate) {
      const validDate = date ?? minDate;
      setInnerDate(validDate);
      setMonth(validDate.getMonth());
      setMinDayDate(getFirstDayOfMonthDate(validDate));
      setMaxDayDate(getLastDayOfMonthDate(validDate));
    }
  }, [date]);

  useEffect(() => {
    setDate(innerDate);
  }, [innerDate]);

  useEffect(() => {
    const year = getCorrectYear(innerDate);
    const day = innerDate.getDate();
    const newInnerDate = new Date(year, month || 0, day || 1);
    setInnerDate(newInnerDate);
    setMinDayDate(getFirstDayOfMonthDate(newInnerDate));
    setMaxDayDate(getLastDayOfMonthDate(newInnerDate));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [month]);
  
  function getCorrectYear(date: Date) : number {
    const innerDateYear = date.getFullYear();
    if (innerDateYear > newDate.getFullYear()) {
      return innerDateYear;
    }
    if (month < currentMonth || (month == currentMonth && innerDate.getDate() <= newDate.getDate())) {
      return nextYear;
    }
    return currentYear;
  }
  
  function getFirstDayOfMonthDate(date: Date) : Date {
    const year = getCorrectYear(date);
    const month = date.getMonth();
    return new Date(year, month, 1);
  }

  function getLastDayOfMonthDate(date: Date) : Date {
    const year = getCorrectYear(date);
    const month = date.getMonth();
    return new Date(year, month + 1, 0);
  }

  const months: SelectItem[] = [
    { key: 0, value: t("January") },
    { key: 1, value: t("February") },
    { key: 2, value: t("March") },
    { key: 3, value: t("April") },
    { key: 4, value: t("May") },
    { key: 5, value: t("June") },
    { key: 6, value: t("July") },
    { key: 7, value: t("August") },
    { key: 8, value: t("September") },
    { key: 9, value: t("October") },
    { key: 10, value: t("November") },
    { key: 11, value: t("December") },
  ];
  
  const LabelWithDot = (props: any) => 
    <div>
    <Typography className={classes.controlLabel}>
      {props.label}
    </Typography>
    <span className={classes.dot}>*</span>
  </div>

  return (
    <div className={classes.root}>
      <div className={classes.date}>
        <div className={classes.controlWithLabel}>
          <LabelWithDot label={t("Month")} />
          <SelectKey
            value={month}
            setValue={setMonth}
            items={months}
            width={200}
            itemHeight={32}
          />
        </div>
        <div className={classes.controlWithLabel}>
          <LabelWithDot label={t("Day")} />
          <DateInput
            format="dd"
            value={innerDate}
            onChange={setInnerDate}
            minDate={minDayDate}
            maxDate={maxDayDate}
            inputTextClass={classes.dateText}
            iconColor={"#919CA5"}
            width={200}
          />
        </div>
      </div>
    </div>
  );
};
