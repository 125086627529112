import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    snackbarChatWindow: {
        marginBottom: "58px",
        // center it in the message window rather than entire screen,
        // chat list sidebar is 360px
        left: "calc(50% + 180px)",
    },
}));
