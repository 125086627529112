import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    root: {},
    customerHistoryPanel: {
        height: 560,
        position: "absolute",
        width: 779,
        overflow: "hidden",
    },
    customerHistoryPanelContent: {
        overflowY: "scroll",
        maxHeight: "calc(100vh - 152px)",
        padding: "28px 28px 0px 28px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
    },
    customerDetails: {
        width: 779,
        height: 610,
        margin: "0px 0px 0px 36px",
    },
    customerDetailsContent: {
        width: 779,
        height: 610,
        borderRadius: 12,
        border: "1px solid #DBDCDD",
        backgroundColor: "#FFF",
        boxShadow: "none",
    },
    customerHistoryTitle: {
        display: "flex",
        alignItems: "center",
    },
    customerHistoryContent: {
        margin: "20px 0px 0px 0px",
    },
    infoContentRow: {
        display: "flex",
        justifyContent: "space-between",
        margin: "20px 0px 0px 0px",
    },
    contactRow: {
        display: "flex",
        justifyContent: "space-between",
    },
    iconTitle: {
        ...theme.typography.txtBody1,
        fontWeight: "bold",
        color: theme.colors.grey4,
        margin: "0px 0px 0px 10px",
    },
    recurringAppointment: {
        ...theme.typography.txtBody1,
        fontWeight: "bold",
        color: theme.colors.grey5,
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTop: "1px solid #C9CDD361",
        paddingTop: 10,
        marginTop: 10,
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto"
    },
    title: {
        ...theme.typography.txtBody2,
        color: theme.colors.grey4,
        margin: 0,
    },
    required: {
        color: "#6462F3",
    },
    optional: {
        fontWeight: "normal",
    },
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "24px 0px 0px 0px",
    },
    switch: {
        display: "flex",
        alignItems: "center",
    },
    switchLabel: {
        margin: "0px 0px 0px 16px",
    },
    label: {
        display: "flex",
        margin: 0,
        ...theme.typography.txtBody2,
        fontWeight: "bold",
        color: theme.colors.grey5,
    },
    description: {
        margin: 0,
        ...theme.typography.txtBody2,
        color: theme.colors.grey5,
    },
    monthYear: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: 18,
        color: "#6462F3"
    },
    accordion: {
        border: "none",
        boxShadow: "none"
    },
    accordionSummary: {
        padding: 0
    },
    accordionDetails: {
        padding: 0
    },
    appointmentsBox: {
        border: "1px solid #C9CDD3",
        boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.15)",
        borderRadius: 4,
        background: "#FFFFFF",
        //opacity: 0.5,
        width: "100%", //680,
        marginBottom: 20,
        padding: 15
    },
    appointmentsGrid: {
        width: "100%",
    },
    appointmentsGridLine: {
        borderBottom: "1px solid #C9CDD3",
        marginTop: 10,
        marginBottom: 10,
        width: "80%",
        alignContent: "center",
        marginLeft: "auto",
        marginRight: "auto",
        opacity: 0.5
    },
    headerAppointments: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        lineHight: 14,
        display: "flex",
        alignItems: "center",
        color: "#919CA5"
    },
    serviceName: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        color: "#5C6477"
    },
    serviceDate: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        color: "#5C6477"
    },
    servicePrice: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontSize: 14,
        display: "flex",
        alignItems: "center",
        color: "#5C6477"
    },
    serviceStatus: {
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        display: "flex",
        alignItems: "center",
        color: "#5C6477",
    },
    expandIcon: {
        flexDirection: 'row-reverse',
        display: "none"
        //order: -1
    },
    expanded: {
        "& * svg": {
            flexDirection: 'row-reverse',
            transform: 'rotate(180deg)',

        }
    },
    ArrowIcon: {
        color: "#6462F3",
        fontSize: 24,
        width: 18,
        marginLeft: 10,
        height: 15
    },
    divStatus: {
        borderRadius: 5,
        color: "#FFFFFF",
        padding: "5px 10px",
        width: "fit-content",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        marginBottom: 10,
        marginRight: 5,
        display: "inline-block"
    },
    statusConfirmed: {
        color: "#0BCBE0",
        backgroundColor: "rgba(11, 203, 224, 0.2)"
    },
    statusArrived: {
        marginLeft: -2,
        color: "#36CE91",
        backgroundColor: "rgba(54, 206, 145, 0.2)"
    },
    statusNotConfirmed: {
        color: "#F15857",
        backgroundColor: "rgba(241, 88, 87, 0.2)",
    },
    statusNotArrived: {
        color: "#F15857",
        backgroundColor: "rgba(241, 88, 87, 0.2)",
    },
    statusCanceled: {
        color: "#F15857",
        backgroundColor: "rgba(241, 88, 87, 0.2)"
    },
    apptDisabledGrid: {
        "& * p": {
            opacity: 0.5
        }
    },
    divNotes: {
        borderRadius: 5,
        color: "#FFFFFF",
        padding: "5px 8px 5px 10px",
        width: "fit-content",
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        cursor: "pointer"
    },
    divNotesNone: {
        marginLeft: -2,
        color: "#36CE91",
        backgroundColor: "rgba(145, 156, 165, 0.2)"
    },
    divNotesExists: {
        marginLeft: -2,
        color: "#36CE91",
        backgroundColor: "rgba(100, 98, 243, 0.2)"
    },
    drawerHistoryDetails: {
        color: "#000",
        background: "#fff",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        width: "250px",
    },
    statusPaid: {
        color: "#36CE91",
        backgroundColor: "rgba(54, 206, 145, 0.2)"
    },
    statusUnpaid: {
        color: "#F15857",
        backgroundColor: "rgba(241, 88, 87, 0.2)",
    },
}));

export default useStyles;
