import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CheckIcon = (props: any) => {
  const { viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      viewBox={viewBox || "0 0 12 7"}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
              d="M7.7463 0.00841291C7.59429 0.0292177 7.45375 0.10344 7.34815 0.218706L2.85724 4.87517L1.09004 3.41264C1.02126 3.35556 0.942307 3.31308 0.857695 3.28764C0.773082 3.26219 0.684466 3.2543 0.596905 3.26437C0.509344 3.27444 0.424554 3.3023 0.347374 3.34634C0.270195 3.39039 0.202137 3.44977 0.147089 3.52108C0.0920405 3.5924 0.0510796 3.67424 0.0265434 3.76197C0.00200717 3.8497 -0.00562284 3.94161 0.00408835 4.0324C0.0137995 4.12319 0.0406614 4.21109 0.0831415 4.29112C0.125622 4.37114 0.182888 4.44171 0.25167 4.49878L2.48675 6.35088C2.61668 6.45701 2.77985 6.50959 2.94492 6.49856C3.10998 6.48753 3.26529 6.41365 3.38101 6.29111L8.29772 1.19312C8.39828 1.09138 8.46548 0.959472 8.48984 0.816003C8.5142 0.672534 8.4945 0.524753 8.43351 0.39352C8.37251 0.262286 8.27331 0.154224 8.14991 0.0845882C8.0265 0.014952 7.88513 -0.0127306 7.74573 0.00542278L7.7463 0.00841291Z"
      />
    </SvgIcon>
  );
};

export default CheckIcon;
