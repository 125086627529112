import React, { useState } from "react";

import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Typography from "@material-ui/core/Typography";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Modal from "@material-ui/core/Modal";

import ArrowDrop from "./../ArrowDrop";
import { TableColumn } from "./../table-column";

import { EnhancedTableHeadProps } from "./props";
import useStyles from "./css";
import { FiltersIcon } from "../../../../assets/icons";
import { useTranslation } from "react-i18next";

const EnhancedTableHead = (props: EnhancedTableHeadProps) => {
  const { order, orderBy, columns, onRequestSort } = props;
  const classes = useStyles();
  const [hover, setHover] = useState<string>("");
  
  const [modalFilter, setModalFilter] = useState<number>();

  const sortHandler = (property: string) => (event: any) => {
    onRequestSort(event, property);
  };

  const FilterControl = (index: number, column: TableColumn)  => {
    const filtering = column.filtering
    const filterList = column.filterList;
    const setFilter = column.setFilter;

    return <>
      {
        filtering && filterList  && setFilter ? 
          <>
            <span style={{marginRight: 5, cursor: "pointer"}} onClick={() => setModalFilter(index)}>
              <FiltersIcon />
            </span>
            <Modal
              open={index === modalFilter }
              onClose={() => setModalFilter(-1)}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              BackdropProps={{ style: { backgroundColor: "transparent" } }}
              disablePortal={true}
              style={{ position: "absolute", top: 0, float: "right" }} >
              <div className={classes.modalActionsAppt} >
                {filterList.map(item => 
                <div 
                    className={`${classes.modalActionFont} ${classes.modalActionFirst}`} 
                    onClick={() => {
                      setModalFilter(-1); 
                      if (setFilter) setFilter(item)
                    }}>
                 {item.value} 
                </div>
                )}
              </div>
          </Modal>
        </> 
        : 
        <></>
      }
    </>
};

  return (<>
    <TableHead>
      <TableRow>
        {columns.map((column: TableColumn, indexColumn) => (
          (column.visible! ?? true) && <>
          <TableCell
            className={classes.tableHeader}
            style={{
              width: column.width,
              minWidth: column.minWidth,
              maxWidth: column.maxWidth,
              textAlign: column.headerTextAlign
                ? column.headerTextAlign
                : "left",
              ...column.cellHeaderStyle
            }}
            key={column.id}
            sortDirection={orderBy === column.id ? order : false}
          >
            {column.sorting ? (<>
              <span className={`${classes.headerText}`}>
                {FilterControl(indexColumn, column)} 
              </span>
              <TableSortLabel
                onMouseOver={() => {setHover(column.id)}}
                onMouseOut={() => {setHover("")}}
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={sortHandler(column.id)}
                IconComponent={() => (
                  <ArrowDrop
                    classes={{
                      dropdown: classes.arrowDropdown,
                      show: classes.arrowShow,
                      hide: classes.arrowHide,
                    }}
                    active={orderBy === column.id}
                    hover={hover}
                    propertyName={column.id}
                    direction={orderBy === column.id ? order : "asc"}
                  />
                )}
              >
                <Typography
                  className={`${classes.headerText} ${column.headerClassName || ""}`}
                  style={column.headerStyle}
                >
                  <>
                  {column.label}
                  </>
                </Typography>
                {orderBy === column.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === "asc" ? "sorted descending" : "sorted ascending"}
                  </span>
                ) : null}
              </TableSortLabel>
            </>) : (
              <Typography
                className={`${classes.headerText} ${column.headerClassName || ""}`}
                style={column.headerStyle}
              >
                <>
                {FilterControl(indexColumn, column)}
                  {column.label}
                </>
              </Typography>
            )}
          </TableCell>
          </>
        ))}
      </TableRow>
    </TableHead>
  </>);
};

export default EnhancedTableHead;
