import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  checkbox: {
    color: theme.colors.clearMain,
    '&.Mui-checked': {
      color: theme.colors.clearMain,
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  disabledCheckbox: {
    opacity: 0.5
  },
  text: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    fontWeight: 700
  },
  textChecked: {
    color: "#6462F3!important"
  }
  })
);

export default useStyles;