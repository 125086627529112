import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../constants/colors";

const useStyles = () => makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    backgroundColor: theme.colors.white,
  },
  datePicker: {
    position: "relative",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
  },
  input: {
    width: "auto",
    height: 40,
    backgroundColor: theme.colors.white,
    padding: "13px 0px 13px 12px",
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
    ...theme.typography.txtBody2,
    color: theme.colors.grey5
  },
  inputText: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: theme.colors.grey5,
    "&::placeholder": {
      fontFamily: "Roboto, sans-serif",
      fontStyle: "normal",
      fontSize: "0.9rem",
      lineHeight: "1.1876em",
      fontWeight: 400,
      color: "rgba(0, 0, 0, 0.87)"
    },
  },
  inputTextError: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    color: theme.colors.clearRed,
  },

  inputDisabled: {
    backgroundColor: theme.colors.grey1,
    color: theme.colors.grey4,
  },
  inputError: {
    color: theme.colors.clearRed,
    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.clearRed,
  },
  icon: {
    position: "absolute",
    right: 6,
    top: 6,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "unset"
  },
  popoverOverride: {
    borderRadius: "12px"
  },
  toolbarBox: {
    backgroundColor: Color.clear1,
    height: 70,
    borderRadius: "10px 10px 0px 0px"
  },
  toolbarDateContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 3,
    padding: 20
  },
  toolbarYearLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "11px",
    lineHeight: "13px",
    color: Color.clear3,
    cursor: "pointer",
    width: "fit-content"
  },
  toolbarWeekDayDayMonthLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: "#FAFAFA",
    cursor: "pointer",
    width: "fit-content"
  },
  toolbarDateLabelContainer: {
    width: "fit-content"
  }
}));

export default useStyles;
