import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 11
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    
    height: 90,
    
    paddingTop: 0,
    paddingLeft: 11,
    paddingRight: 11,
    paddingBottom: 13,
    
    border: "1px solid #C9CDD3",
    borderRadius: 5,
    
    backgroundColor: theme.colors.white,
  },
  bottomRoot: {
    display: "flex",
    flexDirection: "row",
    gap: 10,
    marginTop: 8
  },
  columnNotFoundContainer: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: 12,
    marginTop: 20
  },
  columnNotFoundCaptionBold: {
    ...theme.typography.header4,
    color: theme.colors.clear2,
    fontWeight: 700,
    marginTop: 10
  },
  columnNotFoundCaption: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4
  },
  selectEstablishmentContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginRight: 27
  },
  scrollColumnsArea: {
    height: "calc(100vh - 402px)",
    paddingLeft: 16,
    overflowY: "auto",
    
    "&::-webkit-scrollbar": {
      backgroundColor: theme.colors.white,
      width: 16,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.colors.white,
    },
    "&::-webkit-scrollbar-track:hover": {
      backgroundColor: "#F4F4F4",
      width: 16,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      
      border: "solid",
      borderRadius: 16,
      borderWidth: 5,
      borderColor: theme.colors.white,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a0a0a5",
      
      border: "solid",
      borderWidth: 4,
      borderColor: theme.colors.white,
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  },
  columnsContainer: {
    height: "calc(100vh - 262px)",
    flexBasis: "17%",
    
    border: "1px solid #C9CDD3",
    borderRadius: 5,
    
    backgroundColor: theme.colors.white,
  },
  columnsHeader: {
    ...theme.typography.buttons,
    fontWeight: 700,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    
    display: "flex",
    alignItems: "center",
    height: 80,
    color: "#6A6E72",
    justifyContent: "center",
    backgroundColor: theme.colors.grey1,
  },
  columnsContent: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    fontWeight: 400,
    
    marginBottom: 24
  },
  draggableClassName: {},
  emptyPreviewBoxCaption: {
    ...theme.typography.txtBody3,
    color: "#6A6E72"
  },
  emptyPreviewBoxTitle: {
    fontFamily: "Inter",
    fontSize: "34px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "normal",
    color: theme.colors.clearMain,
  },
  checkboxLabel: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    fontWeight: 400
  },
  columnCheckbox: {
    paddingBottom: 3,
    paddingTop: 0,
    paddingRight: 8,
    paddingLeft: 12,
    
    '&.Mui-checked': {
      color: "#6462F3!important",
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    "&:hover": {
      backgroundColor: "transparent"
    },
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    fontWeight: 400
  },
  groupHeader: {
    marginBottom: 12,
    display: "flex",
    ...theme.typography.subtitle1,
    fontFamily: "Inter, sans-serif",
  },
  selectedColumnsBox: {
    ...theme.typography.txtBody2
  },
  mainBoxClass: {
    boxSizing: "border-box",
    borderRadius: "5px",
    width: "100%",
    overflow: "hidden",
  },
  modalActionsAppt: {
    position: "absolute",
    top: 6,
    right: 5,
    background: "white",
    width: 150,
    textAlign: "center",
    border: "1px solid #DBDCDD",
    boxShadow: "0px 4px 4px rgb(166 166 166 / 25%)",
    borderRadius: 5,
    backgroundColor: "#FFFFFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    color: theme.colors.grey5,
  },
  modalActionFont: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    color: theme.colors.grey5,
    padding: 5,
    cursor: "pointer",
    position: "relative",
    background: "white",
    width: 150,
    textAlign: "left",
    paddingLeft: 15,
    height: 35,
      "&:hover": {
        backgroundColor: "#E1E9FE"
      }
  },
  modalHeader: {
    ...theme.typography.txtBody1,
    fontWeight: 700
  },
  cancelModalContainer: {
    textAlign: "center"
  },
  disabledButton: {
    opacity: 0.5, 
    pointerEvents: 'none', 
  },
  cancelModalContent: {
    fontFamily: "Roboto",
    fontSize: "11px",
    fontStyle: "normal",
    fontWeight: 400,
    lineHeight: "normal",
    color: "#6A6E72"
  },
  cancelButton: {
    width: 200,
    height: 32,
    borderRadius: 5,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  sectionContainer: {
    marginLeft: 10
  },
  sectionLayout: {
    display: "flex",
    flexDirection: "column",
    paddingTop: 0,
    marginBottom: 16
  },
  iconTooltipRoot: {
    width: 150,
    position: "relative"
  },
  radioGroup: {
    padding: 10,
    paddingTop: 0,
    paddingLeft: 5
  },
  radioLabelRoot: {
    margin: "-7px 15px 0 0"
  },
  emptyPreviewBox: {
    display: "flex",
    flexDirection: "column",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    gap: 8,
    
    [theme.breakpoints.down(1536)]: {
      transform: "scale(0.5)"
    },
  },
  radioLabel: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
  },
  radioOption: {
    "&.Mui-checked": {
      "&, & + .MuiFormControlLabel-label": {
        color: "#6462F3",
      },
      "&::after": {
        content: '""',
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: 7,
        height: 7,
        borderRadius: "50%",
        backgroundColor: "#6462F3",
        borderColor: "#6462F3"
      },
    },
  },
  previewRoot: {
    flexBasis: "83%",
    width: "50%",
  },
  totalResultText: {
    ...theme.typography.caption2,
    color: theme.colors.clearMain,
  },
  draggableInnerContainer: {
    display: "flex",
    alignItems: "center",
    height: "27px!important",
    padding: 0,
    gap: 0,
    width: "95%",
    backgroundColor: Color.white,
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "#EAE9FF"
    },
  },
  rowStyle: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    width: "inherit"
  },
  classTableBody: {
    overflowY: "auto",
    overflowX: "auto",
    height: "100%",
  },
  classTableContainer: {
    border: "1px solid #DBDCDD",
    boxSizing: "border-box",
    borderRadius: "12px 12px 12px 12px",
    height: "100%",
    "& ::-webkit-scrollbar": {
      width: "6px",
      height: "10px",
      cursor: "pointer",
    },
    "& ::-webkit-scrollbar-track": {
      borderRadius: "10px",
      background: "#EFEFEF",
      marginTop: "11px",
      marginBottom: "11px"
    },
    "& ::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      background: "#ACB7C0",
    },
    "& ::-webkit-scrollbar-thumb:hover": {
      background: "#919CA5"
    },

  },
  inputSearchClass: {
    height: 32,
    width: "85%"
  },
  inputSearchContainer: {
    display: "flex",
    justifyContent: "center",
    marginTop: 14, 
    marginBottom: 7
  },
  
  informativeBoxContainer: {
    ...theme.typography.buttons,
    borderRadius: 5,
    
    padding: "9px 0 9px 19px",
    
    color: theme.colors.clearMain,
    backgroundColor: theme.colors.clearLilac,
  },
  
  previewContainer: {
    border: "1px solid #C9CDD3",
    borderRadius: 12,
    
    backgroundColor: theme.colors.white,
    
    height: "calc(100vh - 302px)",
    marginTop: 8
  },
  
  inputSearchRoot: {
    ...theme.typography.txtBody2,
    borderRadius: 100,
    background: "#F6F6F6",
    border: `1px solid ${theme.colors.grey2}`,
    height: 40,
    paddingLeft: 12,
    "&:focus": {
      border: "solid 1px #6462F3",
    },
  },
  
  inputsBlock: {
    display: "flex",
    flexDirection: "row",
    gap: 8
  },
  itemRootClass: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    height: 40,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingRight: 8,
    paddingLeft: 12
  },
  dateInputText: {
    ...theme.typography.txtBody2,
    fontWeight: 700,
    color: theme.colors.grey5,
    opacity: 0.8,
  },
  controlWithLabel: {},
  dateLabel: {
    ...theme.typography.buttons,
    fontFamily: "Inter, sans-serif",
    fontWeight: "bold",
    display: "inline-flex",
    color: Color.gray4,
    marginBottom: 8
  },
  deleteModalContentReportName: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    color: Color.gray5,
    textAlign: "center",
    marginBottom: 10
  },
  deleteModalContentText: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "11px",
    lineHeight: "17px",
    color: "#6A6E72"
  },
  deleteModalTitleText: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#26292B",
    textAlign: "center"
  },
  separator: {
    height: 0,
    borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    margin: "16px 0px 16px 0px",
    width: 217
  },
  reportTypeSelect: {
    alignSelf: "flex-end",
  },
  downloadReportButtonBlock: {},
  downloadReportButton: {
    width: 130,
    height: 40,
    
    backgroundColor: theme.colors.clearMain,
    borderRadius: 5,
    
    ...theme.typography.buttons,
    color: theme.colors.grey1,
    fontWeight: 700,
    
    "&:hover": {
      backgroundColor: theme.colors.clearMain,
      color: theme.colors.grey1,
    },
    "&:disabled": {
      backgroundColor: theme.colors.clearMain,
      opacity: 0.3,
      color: theme.colors.grey1,
    }
  },
  downloadReportButtonLabel: {
    display: "flex",
    gap: 10,
  },
  deleteModalContentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    paddingTop: 15
  },
  columnWithTooltipLabel: {
    display: "flex",
  },
  columnLabel: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    fontWeight: 700,
  },
  columnCheckedLabel: {
    ...theme.typography.txtBody2,
    color: theme.colors.clearMain,
    fontWeight: 700,
  },
}));

export default useStyles;
