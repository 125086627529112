export class OpenCashRegisterFormData {
  initialCash: number;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.initialCash = x.initialCash;
    } else {
      this.initialCash = 1000;
    }
  }
}