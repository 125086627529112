import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import Typography from "@material-ui/core/Typography";

import { AppointmentOutsideScheduleModalProps } from "./props";
import useStyles from "./css";
import { Appointment } from "../../../models/appointment";
import { AppointmentScheduleBusyReason } from "../../../models/enums/appointment-schedule-busy-reason";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { BroadcastIcon, CrossIcon } from "../../../assets/icons";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";

const AppointmentOutsideScheduleModal = (props: AppointmentOutsideScheduleModalProps) => {

    const { t } = useTranslation(["general"]);
    const { open, appointmentBusyModalData, appointment, onClose, onContinue } = props;
    const classes = useStyles();
    
    const [appointmentSchedule, setAppointmentSchedule] = useState<Appointment>()

    useEffect(() => {

        if (appointment.startDate && appointment.services) {
            const duration = appointment.services
              .map((service: { duration: any; }) => service.duration)
              .reduce((prev: any, next: any) => (prev || 0) + (next || 0));
            const endDate = new Date(
              new Date(appointment.startDate).getTime() + (duration || 0) * 60000
            );

            setAppointmentSchedule({
                ...appointment,
                endDate: endDate
            });
        }

    }, [appointment]);

    const textLine = (appointmentBusyModalReason: AppointmentScheduleBusyReason) => {
        switch (appointmentBusyModalReason) {
            case AppointmentScheduleBusyReason.OutsideUserScheduleTime:
                return "This appointment is outside the specialist's working hours."
            case AppointmentScheduleBusyReason.OutsideUserScheduleTimeAndOutsideBusinessHours:
                return "This appointment is outside the establishment's and specialist's working hours."
        }
    }

    return (
      <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modalYellow}
          open={open}
          onClose={onClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
              timeout: 500,
          }}>
        
            <Fade in={open}>
                <div className={classes.modalPaperYellow}>
                    <div className={classes.modalHeader}>
                        <div className={classes.modalHeaderContent}>
                            <div className={classes.modalCircle}>
                                <BroadcastIcon  />
                            </div>
                        </div>
                        <div className={classes.modalClose}>
                                <span className={classes.closeButton} onClick={onClose}>
                                    <CrossIcon />
                                </span>
                        </div>
                    </div>
                
                    <div className={classes.modalContent}>
                    
                        <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`} >
                            <Typography className={classes.modalTitleText}>
                                {t("Out-of-hours appointment")}
                            </Typography>
                        </div>
                    
                        <div className={classes.separator}></div>
                    
                        <div className={classes.bodyContent}>
                            <div>
                                <Trans i18nKey={textLine(appointmentBusyModalData?.appointmentScheduleBusyReason ?? AppointmentScheduleBusyReason.OutsideUserScheduleTime)} t={t} components={{ bold: <b /> }} />
                            </div>
                            <br/>
                            <div>
                                {t("Do you want to continue?")}
                            </div>
                        </div>
                    
                        <div className={classes.modalActions}>
                            <Button className={`${classes.button} ${classes.btnCancel}`} onClick={onClose} >
                                {t("Go back")}
                            </Button>
                            <Button className={`${classes.button} ${classes.btnOk}`} onClick={onContinue} >
                                {t("Yes, continue")}
                            </Button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    )
};

export default AppointmentOutsideScheduleModal;
