import {UserCommissionType} from "./enums/user-commission-type";
import {CommissionByServiceEntity} from "./commission-by-service-entity";
import {CommissionType} from "./enums/commission-type";

export class UserCommissionInfo {
    isCommissionEnabled: boolean;
    userCommissionType: UserCommissionType;
    initialUserCommissionType: UserCommissionType;
    commissionType: CommissionType | null;
    commissionValue: number | null;
    commissionsByServices: CommissionByServiceEntity[];

    constructor(x?: any) {
        if (Array.isArray(x)){
            this.commissionsByServices = x;
            this.isCommissionEnabled = true;
            this.commissionType = CommissionType.Absolute;
            this.commissionValue = 0;
            this.userCommissionType = UserCommissionType.ByService;
            this.initialUserCommissionType = UserCommissionType.ByService;
        }
        else if (typeof x === "object") {
            this.isCommissionEnabled = x.isCommissionEnabled;
            this.userCommissionType = x.userCommissionType;
            this.initialUserCommissionType = x.userCommissionType;
            this.commissionType = x.commissionType;
            this.commissionValue = x.commissionValue;
            this.commissionsByServices = x.commissionsByServices || [];
        }
        else {
            this.isCommissionEnabled = true;
            this.userCommissionType = UserCommissionType.ByService;
            this.initialUserCommissionType = UserCommissionType.ByService;
            this.commissionType = CommissionType.Absolute;
            this.commissionValue = 0;
            this.commissionsByServices = [];
        }
        this.commissionsByServices = this.commissionsByServices.sort((a, b) => a.serviceName.localeCompare(b.serviceName))
    }
}