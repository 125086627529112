import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    border: "solid 1px #DBDCDD",
    borderRadius: 12,
    padding: "40px 40px 32px",
    display: "flex",
    backgroundColor: "#FFF",
    width: 1070,
    margin: "auto",
  },
  appointmentSection: {
    display: "block",
    width: "100%",
  },
  breadcrumb: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    color: "#5C6477"
  },
  breadcrumbContainer: {
    padding: "0 80px 16px",
    margin: "auto",
  },
  boldFont: {
    "& .MuiTypography-root": {
      color: "#1D2027",
      fontWeight: 500,
      fontStyle: "normal",
      fontSize: 14,
    }
  },
  actions: {
    height: 72,
    position: "fixed",
    bottom: 0,
    left: 0,
    width: "100%",
    backgroundColor: "#FAFAFA",
    padding: "14px 61px 14px 77px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    zIndex: 999
  },
  button: {
    width: 200,
    height: 32,
    borderRadius: 5,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "15px",
  },
  goBack: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  newAppointment: {
    background: "#36CE91",
    color: "#F6F6F6",
    "&:hover": {
      background: "#36CE91",
    },
    "&.Mui-disabled": {
      color: "#F6F6F6",
      opacity: 0.3,
    },
  },
  deleteButton: {
    height: 32,
    width: 32,
    marginRight: 15,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalCancelPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "12px",
    boxShadow: theme.shadows[5],
    padding: "24px",
    width: 472,
    minHeight: 153,
  },
  modalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  modalTitleSpace: {
    margin: "24px 0px 8px 0px",
  },
  modalTitleText: {
    fontSize: "18px",
    lineHeight: "22px",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "#26292B",
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "24px 0px 8px 0px",
  },
  totalPriceBoldText: {
    marginBottom: 8,
    ...theme.typography.txtBody1,
    fontWeight: 700,
    color: theme.colors.grey6,
  },
  totalPriceText: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey6,
  },
  goBackCancel: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
    "&:hover": {
      background: theme.colors.grey3,
      color: theme.colors.grey1,
    },
  },
  cancelConfirmation: {
    background: theme.colors.clearGreen,
    color: "#F6F6F6",
    "&:hover": {
      background: theme.colors.clearGreen,
    },
  },
}));

export default useStyles;
