import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NoteIcon = (props: any) => {
  const { style, viewBox, color, ...otherProps } = props;
  return (
  <SvgIcon 
      {...otherProps}
      style={style || { width: 16, height: 16 }}
      viewBox={viewBox || "0 0 16 16"}>
    <path
      d="M12.4444 1.6H9.19333C8.86667 0.672 8.01111 0 7 0C5.98889 0 5.13333 0.672 4.80667 1.6H1.55556C0.7 1.6 0 2.32 0 3.2V14.4C0 15.28 0.7 16 1.55556 16H12.4444C13.3 16 14 15.28 14 14.4V3.2C14 2.32 13.3 1.6 12.4444 1.6ZM7 1.6C7.42778 1.6 7.77778 1.96 7.77778 2.4C7.77778 2.84 7.42778 3.2 7 3.2C6.57222 3.2 6.22222 2.84 6.22222 2.4C6.22222 1.96 6.57222 1.6 7 1.6ZM8.55556 12.8H3.11111V11.2H8.55556V12.8ZM10.8889 9.6H3.11111V8H10.8889V9.6ZM10.8889 6.4H3.11111V4.8H10.8889V6.4Z"
      fill={color || "#919CA5"}
    />
  </SvgIcon>
  );
};

export default NoteIcon;
