import React, { useState } from "react";
import TextareaAutosize from "@material-ui/core/TextareaAutosize";
import useStyles from "./css";
import { TextareaProps } from "./props";

const Textarea = (props: TextareaProps) => {
  
  const { id,
    value,
    minRows,
    maxRows,
    name,
    placeholder,
    isValid,
    width,
    inputClass,
    onChange,
    onKeyPress,
    disabled,
    readOnly,
    showCounter,
    onClick,
    onBlur,
    maxLength,
    styles,
  } = props;

  const classes = useStyles(width)();

  const isError = (): boolean => {
    if (!isValid) {
      return false;
    }

    if (isValid() === undefined) {
      return false;
    }

    return !isValid();
  };

  const handleOnChange = (event: any) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleOnKeyPress = (event: any) => {
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

    const handleOnClick = (event: any) => {
        if (onClick) {
            onClick(event);
        }
    };

    const handleOnBlur = (event: any) => {
        if (onBlur) {
            onBlur(event);
        }
    };
  
  
  return (
    <div className={classes.container}>
      <TextareaAutosize
        minRows={minRows || 3}
        maxRows={maxRows || 0}
        id={id}
        className={`${inputClass} ${classes.input} ${isError() ? classes.inputError : ""}`}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={handleOnChange}
        onKeyPress={handleOnKeyPress}
        disabled={disabled}
        readOnly={readOnly}
        onClick={handleOnClick}
        onBlur={handleOnBlur}
        maxLength={maxLength}
        style={styles}
      />
      {showCounter && <div className={classes.counter}>{`${value?.length}/${maxLength}`}</div>}
    </div>
  );
};

export default Textarea;