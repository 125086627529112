
export class CreateFollowUpModel {
  
  appointmentId: string | null;
  customerId: string | null;
  serviceId: string | null;
  scheduled: Date | null;
  notes: string | null;

  constructor(x?: any) {
    if (typeof x == "object") {
      this.appointmentId = x.appointmentId;
      this.customerId = x.customerId;
      this.serviceId = x.serviceId;
      this.scheduled = x.scheduled;
      this.notes = x.notes;
    } else {
      this.appointmentId = null;
      this.customerId = null;
      this.serviceId = null;
      this.scheduled = null;
      this.notes = null;
    }

  }
}
