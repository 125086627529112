import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAccountSettings, selectCashRegisterOpen, selectUserInfo } from "../../../redux/store";

import { Button, Drawer, LinearProgress, Tab, Tabs } from "@material-ui/core";

import { AppointmentPaymentSidebarProps } from "./props";
import useStyles from "./css";
import { CrossIcon } from "../../../assets/icons";
import Typography from "@material-ui/core/Typography";
import { PaymentMethodType } from "../../../models/enums/payment-method-type";
import SelectKey from "../../common/SelectKey";
import Textarea from "../../common/Textarea";
import InputCurrency from "../../common/InputCurrency";
import InputEditableItem from "../../common/InputEditableItem";
import InputEditableWithSelectItem from "../../common/InputEditableWithSelectItem";
import useCurrencyValueTemplate from "../../../hooks/useCurrencyValueTemplate";
import { TabContext, TabPanel } from "@material-ui/lab";
import InputDateWithLabel from "../../common/InputDateWithLabel";
import moment from "moment";
import { SelectItem } from "../../common/SelectWithLabel";
import { DiscountType } from "../../../models/enums/discount-type";
import { alertsActions } from "../../../redux/alerts-slice";
import { PaymentEditionType } from "../../../models/enums/payment-edition-type";
import AppointmentPaymentParts from "../AppointmentPaymentParts";
import { AppointmentPaymentPartEntity } from "../../../models/appointment-payment-part-entity";
import { AppointmentPaymentEntity } from "../../../models/appointment-payment-entity";
import AppointmentPaymentPartsEdit from "../AppointmentPaymentPartsEdit";
import { AppointmentPaymentChangeRecord } from "../../../models/appointment-payment-change-record";
import { AppointmentPaymentValues } from "../../../models/appointment-payment-values";


export default function AppointmentPaymentSidebar(props: AppointmentPaymentSidebarProps) {
  
  const { mode,
    isOpen,
    setOpen,
    userName,
    paymentEntity,
    setPaymentEntity,
    formData,
    onSave,
    onClose,
    isUnchanged,
    recurredAppointmentOrder,
    recurredAppointmentsCount,
    totalRecurringApptPriceExcludingSelected,
    paidPreviously
  } = props;
  
  const accountSettings = useAppSelector(selectAccountSettings);
  const { enqueueAlert } = alertsActions;
  const currencyTemplate: string = accountSettings.currencyTemplate;
  
  const { t } = useTranslation("general");
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const classes = useStyles();
  const newDate = new Date();
  const firstDayOfCurrentYear = new Date(newDate.getFullYear(), 1, 1);
  const firstDayOfNextYear = new Date(newDate.getFullYear(), 11, 31);
  const dateAndTimeFormat = localeApp === "en" ? "MM/DD/YYYY HH:mm a" : "DD/MM/YYYY HH:mm a";
  
  const editionTypes = [
    { key: PaymentEditionType.AddAmount, value: t("Add amount") },
    { key: PaymentEditionType.SubtractAmount, value: t("Subtract amount"), disabled: !paymentEntity.isEditable }
  ];
  
  const [servicesValid, setServicesValid] = useState<boolean[]>(paymentEntity.services.map(x => true));
  const [activeTab, setActiveTab] = useState<"payment" | "history">("payment");
  
  const canRegisterPayments = useAppSelector(selectUserInfo).canRegisterPayments;
  const cashRegisterIsOpen = useAppSelector(selectCashRegisterOpen);
  
  const templatedValue = useCurrencyValueTemplate(currencyTemplate);
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    if (paymentEntity?.services) {
      setServicesValid(paymentEntity.services.map(x => true))
    }
  }, [paymentEntity?.services])
  
  function setPaymentDate(date: Date) {
    setPaymentEntity({ ...paymentEntity, date })
  }
  
  function getCurrencySign() {
    return currencyTemplate.replace("{0}", "");
  }
  
  function setEditingPart(editingPart: AppointmentPaymentPartEntity) {
    setPaymentEntity({ ...paymentEntity, editingPart })
  }
  
  function setEditionType(editionType: PaymentEditionType) {
    const editingPart = new AppointmentPaymentPartEntity();
    setPaymentEntity({ ...paymentEntity, editionType, editingPart })
  }
  
  function setPaymentDiscount(discountAmount: number, discountType: DiscountType, index: number) {
    const newServices = [...paymentEntity.services];
    newServices[index] = { ...newServices[index], discountAmount };
    newServices[index] = { ...newServices[index], discountType };
    setPaymentEntity({ ...paymentEntity, services: newServices })
  }
  
  function setPaymentNotes(event: any) {
    const notes = event.target.value;
    setPaymentEntity({ ...paymentEntity, notes })
  }
  
  function setPaymentServicePrice(index: number, price: number) {
    const newServices = [...paymentEntity.services];
    newServices[index] = { ...newServices[index], price };
    setPaymentEntity({ ...paymentEntity, services: newServices })
  }
    
  function countTotalToBePaid(): number {
    let total = 0;
    paymentEntity.services?.map(service => {
      if (service.discountType === DiscountType.Percentage) {
        total += service.price - (service.discountAmount / 100) * service.price;
      } else {
        total += service.price - service.discountAmount;
      }
    });
    if (total <= 0) return 0;
    return total + totalRecurringApptPriceExcludingSelected;
  }
  
  const totalToBePaid = countTotalToBePaid()
  
  function countPaidAmount(): number {
    const partsSum = paymentEntity.parts?.map(x => x.amountPaid).reduce((sum, x) => sum + x) || 0;
    const editingAmount = (paymentEntity.editingPart?.amountPaid || 0)
    switch (paymentEntity.editionType) {
      case PaymentEditionType.AddAmount: 
        return paidPreviously + editingAmount;
      case PaymentEditionType.SubtractAmount:
        const amount = paidPreviously - editingAmount;
        return amount <= 0 ? 0 : amount;
      default:
        return partsSum;
    }
  }
  
  const paidAmount = countPaidAmount()
  
  function countPendingToBePaid(): number {
    const toBePaid = paidAmount < 0 
      ? totalToBePaid 
      : totalToBePaid - paidAmount;
    if (toBePaid <= 0) return 0;
    return toBePaid;
  }
  
  const pendingToBePaid = countPendingToBePaid()
  
  function countChange(): number {
    const change = paidAmount - totalToBePaid;
    if (change <= 0) return 0;
    return change;
  }
  
  const change = countChange();
  
  const paymentActualProgress = (paidAmount / totalToBePaid) * 100;
  const paymentProgress = paymentActualProgress > 100 ? 100 : paymentActualProgress;
  
  async function handleSaveButtonClick() {
    let doCancel = false;
    paymentEntity.services.forEach((service) => {
      if (service.discountType === DiscountType.Value && service.discountAmount > service.price) {
        displayAlert(t("The discount must not be greater than the total to be paid for the service"));
        doCancel = true;
        return;
      }
    });
    if (doCancel) return;
    paymentEntity.services.forEach((service) => {
      if (service.discountType === DiscountType.Percentage && service.discountAmount > 100) {
        displayAlert(t("It is not possible to add more than 100% discount"));
        doCancel = true;
        return;
      }
    });
    if (doCancel) return;
    if (mode === "edit" && paymentEntity.editionType === PaymentEditionType.SubtractAmount && paidPreviously - (paymentEntity.editingPart?.amountPaid || 0) < 0) {
      displayAlert(t("It is not possible to subtract an amount greater than the amount already paid."));
      return;
    }
    await onSave(totalToBePaid).then(setActiveTabToHistory);
  }
  
  function handleCloseDrawer() {
    drawerOnClose()
  }
  
  function drawerOnClose() {
    setOpen(false);
    if (onClose) onClose();
    setActiveTab("payment");
  }
  
  function setActiveTabToHistory() {
    setActiveTab("history");
  }
  
  function handleTabChange(event: any, newValue: "payment" | "history") {
    setActiveTab(newValue);
  }
  
  function setServiceValid(value: boolean, index: number) {
    const newValid = [...servicesValid];
    newValid[index] = value;
    setServicesValid(newValid)
  }
  
  function getSaveButtonTitle() {
    switch (mode) {
      case "new":
        return t("Register payment")
      case "edit":
        return t("Save payment details")
    }
  }
  
  function displayAlert(message: string) {
    dispatch(
      enqueueAlert({
        type: "Error",
        title: null,
        description: message,
      })
    )
  }
  
  function isSaveButtonDisabled() {
    return !servicesValid.every(x => x) ||
      paymentEntity.parts.some(x => x.paymentMethod === null || !(x.amountPaid !== 0)) ||
      (mode === "edit" && (isUnchanged() || paymentEntity.editionType === null || !((paymentEntity.editingPart?.amountPaid || 0) !== 0 && paymentEntity.editingPart?.paymentMethod !== null)));
  }
  
  const currencySelectList: SelectItem[] = [
    { key: DiscountType.Value, value: getCurrencySign() },
    { key: DiscountType.Percentage, value: "%" }];
  
  function getPaymentMethodName(paymentMethod: PaymentMethodType) {
    switch (paymentMethod) {
      case PaymentMethodType.Cash:
        return t("Cash")
      case PaymentMethodType.CreditCard:
        return t("Credit/debit card")
      case PaymentMethodType.BankTransfer:
        return t("Bank transfer")
      case PaymentMethodType.Oxxo:
        return t("Oxxo")
      case PaymentMethodType.MercadoPago:
        return t("Mercado Pago")
    }
  }
  
  function formatChangedField(changedField: string): string {
    switch (changedField) {
      case "Date":
        return t(changedField);
      case "Payment method":
        return t(changedField);
      case "Paid amount":
        return t(changedField);
      case "Discount":
        return t(changedField);
      case "Total paid":
        return t(changedField);
      case "Notes":
        return t("Comments");
      case "Discount type":
        return t(changedField);
      default:
        return t("Price of") + ": " + changedField;
    }
  }
  
  function formatChangeRecordField(fieldValue: string, changedField: string) {
    switch (changedField) {
      case "Date":
        return moment(fieldValue).format(dateAndTimeFormat);
      case "Payment method":
        return fieldValue.split(', ').map(x => t(x)).join(', ');
      case "Paid amount":
        return templatedValue(parseFloat(fieldValue));
      case "Discount":
        return templatedValue(parseFloat(fieldValue));
      case "Total paid":
        return templatedValue(parseFloat(fieldValue));
      case "Notes":
        return fieldValue;
      case "Discount type":
        return t(fieldValue);
      default: {
        if (!isNaN(parseFloat(fieldValue))) {
          return templatedValue(parseFloat(fieldValue));
        }
        return fieldValue;
      }
    }
  }
  
  function handleDeletePart(index: number) {
    const arrParts = paymentEntity!.parts!.filter((part, idx) => idx !== index);
    setParts(arrParts);
  }
  
  function handleOnChangePart(index: number, part: AppointmentPaymentPartEntity) {
    let arrParts = [...paymentEntity!.parts!];
    arrParts[index] = part;
    setParts(arrParts)
  }
  
  function setParts(parts: AppointmentPaymentPartEntity[]) {
    setPaymentEntity(new AppointmentPaymentEntity({ ...paymentEntity, parts }))
  }
  
  function handleAddPart() {
    setParts([...paymentEntity?.parts ?? [], new AppointmentPaymentPartEntity()]);
  }
  
  function lpad(value: number, padding: number) {
    var zeroes = new Array(padding + 1).join("0");
    return (zeroes + value).slice(-padding);
  }
  
  function displayFriendlyId(friendlyId: any) {
    return friendlyId < 1000 ? lpad(friendlyId, 4) : friendlyId
  }
  
  const historyData = () => {
    const paymentValues = formData.paymentValues.map(x => {return {
      date: new Date(x.date),
      data: x,
      type: "paymentValues"
    }});
    const changeRecords = formData.changeRecords.map(x => {return {
      date: new Date(x.date),
      data: x,
      type: "changeRecords"
    }});
    // @ts-ignore
    let data = paymentValues.concat(changeRecords);
    data.sort((a,b)=>b.date.getTime()-a.date.getTime());
    return data;
  }
  
  const RegisterPaymentChip = () =>
    <div className={classes.chip}>
      {t("Payment register")}
    </div>
  
  const CrossCloseButton = () =>
    <div className={classes.closeIcon}>
      <CrossIcon
        style={{
          width: 16,
          height: 16
        }}
        viewBox="0 0 16 16"
        onClick={handleCloseDrawer}
      />
    </div>
  
  const TabsHeader = () =>
    <TabContext
      value={activeTab}
    >
      <Tabs
        className={classes.tabs}
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          className={classes.tab}
          label={t("Payment detail")}
          value="payment"
        />
        <Tab
          className={classes.tab}
          label={t("Movements history")}
          value="history"
        />
      </Tabs>
    </TabContext>
  
  const ChargingUserHeader = () =>
    <div className={classes.newHeader}>
      {mode === "new" && <span className={classes.headerTitle}>{t("Register payment")}</span>}
      <span className={classes.headerChargingInfo}>{t("Charging user:")}<span className={classes.headerChargingUserName}>{userName}</span></span>
      {!!(paymentEntity?.friendlyId)
        ? <span className={classes.headerChargingInfo}>{t("Payment ID:")}<span className={classes.headerChargingPaymentId}>{displayFriendlyId(paymentEntity.friendlyId)}</span></span>
        : null
      }
      <CrossCloseButton/>
    </div>
  
  const PaymentProgressBar = () => 
    <div className={classes.paymentProgressBar}>
      <LinearProgress 
        variant="determinate" 
        value={paymentProgress} 
        classes={{
          barColorPrimary: classes.progressBarPaid,
          colorPrimary: classes.progressBarUnpaid
        }}
      />
      <div className={classes.paymentProgressBarLegend}>
        <div className={classes.paymentProgressBarLegendItem}>
          <div className={`${classes.paymentProgressBarLegendIcon} ${classes.paidIcon}`} />
          <span className={classes.paymentProgressBarLegendText}>{t("Paid")} <strong>{templatedValue(paidAmount > totalToBePaid ? totalToBePaid : paidAmount)}</strong></span>
        </div>
        <div className={classes.paymentProgressBarLegendItem}>
          <div className={`${classes.paymentProgressBarLegendIcon} ${classes.unpaidIcon}`} />
          <span className={classes.paymentProgressBarLegendText}>{t("Unpaid")} <strong>{templatedValue(countPendingToBePaid())}</strong></span>
        </div>
      </div>
    </div>
  
  const RegisterPaymentButton = () =>
    <div className={classes.footer}>
      <Button
        className={classes.registerPaymentButton}
        onClick={async () => {
          await handleSaveButtonClick()
        }}
        disabled={isSaveButtonDisabled()}
      >
        {getSaveButtonTitle()}
      </Button>
    </div>
  
  const paidWithList = () => {
    let parts = paymentEntity.parts.filter(p => p.paymentMethod !== null && p.amountPaid).map(part =>
    ({
      paymentMethod: part.paymentMethod,
      amountPaid: part.amountPaid
    }));
    
    if (mode === "edit" &&
        paymentEntity.editionType !== null &&
        (paymentEntity.editingPart?.amountPaid || 0) !== 0 &&
        paymentEntity.editingPart?.paymentMethod !== null) {
      const editionSign = paymentEntity.editionType === PaymentEditionType.AddAmount ? 1 : -1;
      const part = parts.find(x => x.paymentMethod === paymentEntity.editingPart!.paymentMethod && Math.sign(x.amountPaid) === editionSign);
      if (part) { // part has the same paymentMethod and amountPaid sign as editingPart
        part.amountPaid += paymentEntity.editingPart!.amountPaid * editionSign;
      } else {
        parts.push({
          paymentMethod: paymentEntity.editingPart!.paymentMethod,
          amountPaid: paymentEntity.editingPart!.amountPaid * editionSign
        })
      }
    }
    
    return parts.sort((a, b) => {
      if (Math.sign(a.amountPaid) < Math.sign(b.amountPaid)) {
        return 1;
      } else if (Math.sign(a.amountPaid) > Math.sign(b.amountPaid)) {
        return -1;
      }
  
      if (a.paymentMethod! < b.paymentMethod!) {
        return -1;
      } else if (a.paymentMethod! > b.paymentMethod!) {
        return 1
      } else {
        return 0;
      }
    });
  }
  
  const PaymentContent = () =>
    <>
      <div>
        <ChargingUserHeader />
      </div>
      <div className={classes.divider} />
      <PaymentProgressBar />
  
      {mode === "new" &&
        <AppointmentPaymentParts
          parts={paymentEntity.parts}
          onChange={handleOnChangePart}
          onDelete={handleDeletePart}
          onAdd={handleAddPart}/>
      }
      {
        mode === "edit" &&
        <>
          <div className={classes.inputWithLabel}>
            <div>
              <Typography className={classes.controlLabel}>
                {t("Payment edition")}
              </Typography>
              <span className={classes.dot}>*</span>
            </div>
            <SelectKey
              value={paymentEntity.editionType}
              setValue={setEditionType}
              itemRootClass={classes.itemRoot}
              placeholder={t("Select a type")}
              items={editionTypes}
              disabled={!cashRegisterIsOpen}
            />
          </div>
          {paymentEntity.editingPart !== null && paymentEntity.editionType !== null &&
            <AppointmentPaymentPartsEdit
              part={paymentEntity.editingPart!}
              onChange={part => setEditingPart(part)}
              editionType={paymentEntity.editionType}
            />
          }
          {paymentEntity.editionType === null &&
            <div className={classes.inputWithLabel}>
              <div>
                <Typography className={classes.controlLabel}>
                  {t("Amount paid")}
                </Typography>
                <span className={classes.dot}>*</span>
              </div>
              <InputCurrency
                value={paidAmount}
                setValue={()=>{}}
                valueTemplate={currencyTemplate}
                isZeroAllowed
                disabled={true}
              />
            </div>
          }
        </>
      }
  
      <div className={classes.inputWithLabel} style={{marginTop: 21}}>
    
        <InputDateWithLabel
          label={() =>
            <div>
              <Typography className={classes.controlLabel}>
                {t("Payment date")}
              </Typography>
              <span className={classes.dot}>*</span>
            </div>}
          placeholder={t("dd/mm/yy")}
          format={"dd/MM/yyyy"}
          value={paymentEntity.date}
          onChange={setPaymentDate}
          inputTextClass={classes.controlText}
          iconColor={"#5C6477"}
          width={368}
          minDate={firstDayOfCurrentYear}
          maxDate={firstDayOfNextYear}
          disabled={!cashRegisterIsOpen}
        />
      </div>
      
      <div className={classes.inputWithLabel}>
        <div>
          <Typography className={classes.controlLabel}>
            {t("Comments")}
          </Typography>
        </div>
        <Textarea
          name="notes"
          inputClass={classes.textArea}
          placeholder={t("Write your comments here")}
          maxLength={180}
          value={paymentEntity.notes}
          onChange={setPaymentNotes}
          width={368}
          disabled={!cashRegisterIsOpen}
        />
      </div>
      
      <div className={`${classes.divider} ${classes.marginTopBottom}`}/>
      
      <div className={classes.servicesDetail}>
        <div className={classes.servicesDetailRow}>
          <div className={`${classes.servicesDetailHeader} ${classes.servicesDetailColumn}`}>{t("Detail")}</div>
          <div className={`${classes.servicesDetailHeader} ${classes.servicesDetailColumn}`}>{t("Price")}</div>
        </div>
        {
          paymentEntity.services.map((service, index) =>
            <>
              <div className={classes.servicesDetailRow}>
                <div className={`${classes.servicesDetailColumn} ${classes.servicesDetailServiceBlock}`}>
                  <div className={`${classes.servicesDetailText}`}>{service.categoryName + " - " + service.name}</div>
                  <div className={`${classes.servicesDetailSubheader}`}>{t("Service")}</div>
                </div>
                <InputEditableItem
                  value={service.price}
                  setValue={(price: number) => setPaymentServicePrice(index, price)}
                  valueTemplate={currencyTemplate}
                  setValid={(value: boolean) => setServiceValid(value, index)}
                  disabled={!cashRegisterIsOpen}
                />
              </div>
              <div className={classes.servicesDetailRow}>
                <div className={`${classes.servicesDetailDiscount} ${classes.servicesDetailColumn}`}>{t("Discount")}</div>
                <div className={classes.servicesDetailColumn}>
                  <InputEditableWithSelectItem
                    value={service.discountAmount}
                    selectValue={service.discountType}
                    setValue={(amount, type) => setPaymentDiscount(amount, type, index)}
                    valueTemplate={"-" + currencyTemplate}
                    color={"#6462F3"}
                    selectItems={currencySelectList}
                    subTotal={service.price}
                    maxValue={{
                      maxValue: service.price
                    }}
                    disabled={!cashRegisterIsOpen}
                  />
                </div>
              </div>
              <div className={classes.servicesDetailDivider}/>
            </>
          )
        }
        {recurredAppointmentOrder && recurredAppointmentsCount && <>
          <div className={classes.servicesDetailRow}>
            <div className={`${classes.servicesDetailText} ${classes.servicesDetailColumn}`}>
              {t("Recurring appointment")}
            </div>
            <div className={classes.servicesDetailValue}>
              {`${recurredAppointmentOrder} ${t("of")} ${recurredAppointmentsCount}`}
            </div>
          </div>
          <div className={classes.servicesDetailDivider}/>
        </>}
        <div className={classes.servicesDetailRow}>
          <div className={`${classes.servicesDetailTotal} ${classes.servicesDetailColumn}`}>
            {t("Total to be paid")}
          </div>
          <div className={`${classes.servicesDetailValue} ${classes.greenText} ${classes.servicesDetailColumn}`}>{templatedValue(totalToBePaid)}</div>
        </div>
        {paidWithList().map(part =>
          <div className={classes.servicesDetailRow}>
            <div className={`${classes.servicesDetailPaidWith} ${classes.servicesDetailColumn}`}>
              {part.amountPaid > 0 &&
                t("Paid with")
              }
              {part.amountPaid < 0 &&
                t("Subtracted with")
              }
              &nbsp;
              <span style={{textTransform: "lowercase"}}>{getPaymentMethodName(part.paymentMethod!)}</span>
            </div>
            
            <div className={`${classes.servicesDetailValue} ${classes.servicesDetailColumn}`}>{templatedValue(part.amountPaid)}</div>
          </div>
        )}
        <div className={classes.servicesDetailRow}>
          <div className={`${classes.servicesDetailText} ${classes.servicesDetailColumn}`}>
            {t("Amount paid")}
          </div>
          <div className={`${classes.servicesDetailValue} ${classes.servicesDetailColumn}`}>{templatedValue(paidAmount)}</div>
        </div>
        <div className={classes.servicesDetailRow}>
          <div className={`${classes.servicesDetailText} ${classes.servicesDetailColumn}`}>
            {t("Pending to be paid")}
          </div>
          <div className={`${classes.servicesDetailValue} ${classes.servicesDetailColumn}`}>{templatedValue(pendingToBePaid)}</div>
        </div>
        <div className={classes.servicesDetailRow}>
          <div className={`${classes.servicesDetailText} ${classes.servicesDetailColumn}`}>{t("Change")}</div>
          <div className={`${classes.servicesDetailValue} ${classes.servicesDetailColumn}`}>{templatedValue(change)}</div>
        </div>
      </div>
      
      {
        canRegisterPayments && cashRegisterIsOpen &&
          <RegisterPaymentButton/>
      }
    </>
  
  const HistoryContent = () =>
    <>
      <div>
        <ChargingUserHeader/>
      </div>
      {historyData().map((data, index) => {
        if (data.type === "paymentValues") {
          const paymentValues: AppointmentPaymentValues = data.data;
          const isFirstPaymentHistoryBlock = index === historyData().length - 1;
          return <>
            <div className={classes.divider}/>
            <div className={classes.initialValuesBlock}>
              <RegisterPaymentChip/>
              <div className={classes.initialValuesRow}>
                <div className={classes.initialValuesLeftColumn}>{t("User") + ":"}</div>
                <div className={classes.historyRightColumn}>{paymentValues.userName}</div>
              </div>
              <div className={classes.initialValuesRow}>
                <div className={classes.initialValuesLeftColumn}>{t("Payment date") + ":"}</div>
                <div className={classes.historyRightColumn}>{moment(paymentValues.date).format(dateAndTimeFormat)}</div>
              </div>
              <div className={classes.initialValuesRow}>
                <div className={classes.initialValuesLeftColumn}>{t("Payment method") + ":"}</div>
                <div className={classes.historyRightColumn}>{getPaymentMethodName(paymentValues.paymentMethod)}</div>
              </div>
              {isFirstPaymentHistoryBlock &&
              <>
                {recurredAppointmentOrder && recurredAppointmentsCount &&
                 <div className={classes.initialValuesRow}>
                    <div className={classes.initialValuesLeftColumn}>{t("Recurring appointments") + ":"}</div>
                    <div className={classes.historyRightColumn}>{recurredAppointmentsCount}</div>
                 </div>
                }
                <div className={classes.initialValuesRow}>
                    <div className={classes.initialValuesLeftColumn}>{t("Total to be paid") + ":"}</div>
                    <div className={classes.historyRightColumn}>{templatedValue(totalToBePaid)}</div>
                </div>
                <div className={classes.initialValuesRow}>
                    <div className={classes.initialValuesLeftColumn}>{t("Discount") + ":"}</div>
                    <div className={classes.historyRightColumn}>{`-${templatedValue(paymentValues.discount)}`}</div>
                </div>
              </>
              }
              <div className={classes.initialValuesRow}>
                <div className={classes.initialValuesLeftColumn}>
                  {
                    (index === formData.paymentValues.length-1 ? t("Amount paid") : paymentValues.amountPaid >= 0 ? t("Add amount") : t("Subtract amount"))
                    + ":"
                  }
                </div>
                <div className={classes.historyRightColumn}>{templatedValue(paymentValues.amountPaid)}</div>
              </div>
              <div className={classes.initialValuesRow}>
                <div className={classes.initialValuesLeftColumn}>{t("Pending to be paid") + ":"}</div>
                      <div className={classes.historyRightColumn}>{templatedValue(pendingToBePaid > 0 ? pendingToBePaid : 0)}</div>
              </div>
              {/*{*/}
              {/*  paymentValues.amountPaid > prevPendingToBePaid &&*/}
              {/*  <div className={classes.initialValuesRow}>*/}
              {/*    <div className={classes.initialValuesLeftColumn}>{t("Change") + ":"}</div>*/}
              {/*    <div className={classes.historyRightColumn}>{templatedValue(paymentValues.amountPaid - prevPendingToBePaid)}</div>*/}
              {/*  </div>*/}
              {/*}*/}
              <div className={classes.initialValuesRow}>
                <div className={classes.initialValuesLeftColumn}>{t("Comments") + ":"}</div>
                <div className={classes.historyRightColumn}>{ paymentValues.notes !== null && paymentValues.notes.trim() !== "" ? paymentValues.notes : "--" }</div>
              </div>
            </div>
          </>
        } else {
          // @ts-ignore
          const changeRecord: AppointmentPaymentChangeRecord = data.data;
          return <>
            <div className={classes.divider}/>
            <div className={classes.changeRecords}>
                <div className={classes.changeRecordBlock}>
                  <div className={classes.changeRecordRow}>
                    <div className={classes.changeRecordLeftColumn}>{t("User") + ":"}</div>
                    <div className={classes.historyRightColumn}>{changeRecord.chargingUserName}</div>
                  </div>
                  <div className={classes.changeRecordRow}>
                    <div className={classes.changeRecordLeftColumn}>{t("Changed") + ":"}</div>
                    <div className={classes.historyRightColumn}>{formatChangedField(changeRecord.changedField)}</div>
                  </div>
                  <div className={classes.changeRecordRow}>
                    <div className={classes.changeRecordLeftColumn}>{t("Date and time") + ":"}</div>
                    <div className={classes.historyRightColumn}>{moment(changeRecord.date).format(dateAndTimeFormat)}</div>
                  </div>
                  <div className={classes.changeRecordRow}>
                    <div className={classes.changeRecordLeftColumn}>{t("From") + ":"}</div>
                    <div className={classes.historyRightColumnStrike}><s>{formatChangeRecordField(changeRecord.from, changeRecord.changedField)}</s></div>
                  </div>
                  <div className={classes.changeRecordRow}>
                    <div className={classes.changeRecordLeftColumn}>{t("To") + ":"}</div>
                    <div className={classes.historyRightColumn}>{formatChangeRecordField(changeRecord.to, changeRecord.changedField)}</div>
                  </div>
                </div>
            </div>
          </>
        }
      })
      }
    </>
  
  
  return (
    <Drawer
      open={isOpen}
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={drawerOnClose}
    >
      {
        mode === "edit" && <TabsHeader/>
      }
      <div className={classes.root}>
        {mode === "edit"
          ?
          <>
            <TabContext
              value={activeTab}
            >
              <TabPanel
                classes={{
                  root: classes.tabPanelRoot
                }}
                value="payment"
              >
                {PaymentContent()}
              </TabPanel>
              <TabPanel
                value="history"
                classes={{
                  root: classes.tabPanelRoot
                }}
              >
                {HistoryContent()}
              </TabPanel>
            </TabContext>
          </>
          :
          <>
            {PaymentContent()}
          </>
        }
      
      </div>
    
    </Drawer>
  );
}