import * as React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { Url } from "../../../constants/url";
import AppointmentList from "../AppointmentList";
import AppointmentDetails from "../AppointmentDetails";
import AppointmentNew from "./../AppointmentNew";

export default function AppointmentRouter() {
  return (
    <Switch>
      <Route exact path={Url.Appointments.Main} component={AppointmentList} />
      <Route
        exact
        path={Url.Appointments.Main + "/new"}
        component={AppointmentNew}
      />
      <Route
        exact
        path={Url.Appointments.Main + "/new/:customerId"}
        component={AppointmentNew}
      />
      <Route
        exact
        path={Url.Appointments.Main + "/:appointmentId"}
        component={AppointmentDetails}
      />
      <Route path={Url.Appointments.Main}>
        <Redirect to={Url.Appointments.Main} />
      </Route>
    </Switch>
  );
}
