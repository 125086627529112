import { TerminologyType } from "./enums/terminology-type";

export class Terminology {
  terminologyType: TerminologyType;
  singularForm: string;
  pluralForm: string;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.terminologyType = x.terminologyType;
      this.singularForm = x.singularForm;
      this.pluralForm = x.pluralForm;
    }
    else {
      this.terminologyType = 0;
      this.singularForm = "";
      this.pluralForm = "";
    }
  }
}