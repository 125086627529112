import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "row",

    marginTop: 10,

    position: "relative",
  },
  header: {
    ...theme.typography.buttons,
    color: theme.colors.grey4,
    fontWeight: "bold",
    marginBottom: 8
  },
  switchContainer: {
    justifySelf: "flex-start",
    alignSelf: "flex-start",
    width: 140
  },
  switch: {
    display: "flex",
    alignItems: "center",

    height: 46,
  },
  windows: {
    display: "flex",
    flexDirection: "column",
  },
  datePickers: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 6,

    marginBottom: 24,
    position: "relative"
  },
  datePickerMargin: {
    width: 24,
    height: 40
  },
  timePickersContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: 6,

    width: 426,
  },
  timePickerContainer: {
    width: 210,
    display: "flex",
    flexDirection: "column"
  },
  timePickerInput: {
    
    color: theme.colors.grey5,
    fontWeight: "bold",
    fontSize: 12,
    height: 40,
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    backgroundColor: "#F6F6F6",
    borderRadius: 5,
    paddingRight: 10,
    "& * .ant-picker-suffix, .ant-picker-clear": {
      display: "none"
    },
    "& * input": {
      color: theme.colors.grey5,
      fontFamily: "Roboto",
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: "bold",
      borderRadius: "5px",
      height: 24,
    }
  },
  timePickerPopup: {
    zIndex: 2500
  },
  timePickerInvalid: {
    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.clearRed
  },
  addWindowButtonContainer: {
    width: 40,
    height: 40,

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  addWindowButton: {
    width: 16,
    height: 16
  },
  copyToAllButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    position: "absolute",
    right: 4,

    width: 102,
    height: 32,

    backgroundColor: theme.colors.grey1,
    marginTop: 28,

    ...theme.typography.caption2,
    color: theme.colors.clearMain,
    fontWeight: 700,

    "&:hover": {
      backgroundColor: theme.colors.grey2,
    },
  },
  removeWindowButtonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    width: 40,
    height: 40,
  },
  removeWindowButton: {
    width: 16,
    height: 16
  },
  divider: {
    width: 524,
    height: 0,
    marginBottom: 39,
    border: "1px solid #F6F6F6"
  },
  topRowMargin: {
    marginTop: 24,
  },
  tooltip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",

    maxWidth: 150,
    paddingLeft: 8,
    paddingRight: 8,
    zIndex: 1500,

    backgroundColor: theme.colors.grey1,

    ...theme.typography.subtitle2,
    color: theme.colors.grey5,

    borderRadius: 5,
    boxShadow: "0px 4px 4px rgba(166, 166, 166, 0.25)",
  },
  })
);

export default useStyles;