import OrderedReportProperty from "./ordered-report-property";

export class PreviewCustomReport {
  properties: Array<OrderedReportProperty>;
  resultNumbers: Array<number>;
  dataTable: Array<Array<Record<string, any>>>;
  constructor(x?: any) {
    if (typeof x === "object") {
      this.properties = x.properties;
      this.resultNumbers = x.resultNumbers;
      this.dataTable = x.dataTable;
    } else {
      this.properties = [];
      this.resultNumbers = [];
      this.dataTable = [];
    }
  }
}