import { createSlice } from '@reduxjs/toolkit';
import BlockSchedule from "../models/blocked-schedule";

export interface MultipleBlockedScheduleState {
  isModalOpen: boolean,
  blockerCount: number,
  blockers: BlockSchedule[]
}

const initialState: MultipleBlockedScheduleState = {
  isModalOpen: false,
  blockerCount: 0,
  blockers: [],
}

const multipleBlockedScheduleSlice = createSlice({
  name: "multipleBlockedSchedule",
  initialState: initialState,
  reducers: {
    setState(currentState, action) {
      return action.payload;
    },
    resetState() {
      return initialState;
    }
  }
});

export const multipleBlockedScheduleActions = multipleBlockedScheduleSlice.actions;
export default multipleBlockedScheduleSlice.reducer;