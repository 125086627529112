import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { DeleteIcon, CrossIcon, TrashIcon, ArrowDownIcon, ListPlusIcon  } from "../../../assets/icons";

import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";
import { CreateAppointmentService } from "../../../models/create-appointment-service";
import AppointmentService from "./../AppointmentService";

import { AppointmentServicesProps } from "./props";
import useStyles from "./css";


export default function AppointmentServices(props: AppointmentServicesProps) {
  
  const { services, desiredUserId, onDelete, onChange, disabled } = props;
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [service, setService] = useState<CreateAppointmentService>();
  const [indexService, setIndexService] = useState<number>(0);
  const [expandedAccordionIndex, setExpandedAccordionIndex] = useState<number>(0);

  const handleDeleteService = (e: any, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    const service = services.find((service, idx) => idx === index);
    setService(service);
    setIndexService(index);
    setOpenModal(true);
  };

  const removeService = () => {
    onDelete(indexService);
    setOpenModal(false);
  };

  const handleOnChange =
    (index: number) => (appointmentService: CreateAppointmentService) => {
      setExpandedAccordionIndex(index);
      onChange(index, appointmentService);
    };
  
  function handleAccordionChange(index: number) {
    const newIndex = expandedAccordionIndex === index ? -1 : index;
    setExpandedAccordionIndex(newIndex);
  }

  return (
    <div className={classes.root}>
      {services.map((service, index) => (
        <Accordion
          style={{width: "97%", marginLeft: "auto"}}
          expanded={expandedAccordionIndex === index}
          onChange={() => handleAccordionChange(index)}
        >
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ArrowDownIcon style={{height: 15, width: 15, color: "#5c6477"}} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div style={{width: 25}}>
              {
                index > 0 ?
                  <span  onClick={(e) => handleDeleteService(e, index)}>
                      <DeleteIcon />
                    </span>
                  :
                  <span>
                      <ListPlusIcon />
                  </span>
              }
            </div>
            <Typography className={classes.accordionTitle}>
              {`${t("Service")} ${index+1}` }
            </Typography>
          </AccordionSummary>
          <AccordionDetails
          style={{display: "block", padding: 28}}
          >
            <AppointmentService
              appointmentService={service}
              desiredUserId={desiredUserId}
              onChange={handleOnChange(index)}
              disabled={disabled}
            />
          </AccordionDetails>
        </Accordion>
      ))}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.modalPaper}>
            <div className={classes.modalHeader}>
              <div className={classes.modalClose}>
                <span
                  className={classes.closeButton}
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <CrossIcon />
                </span>
              </div>
            </div>
            <div className={classes.modalContent}>
              <div className={classes.modalCenter}>
                <div className={classes.modalCircle}>
                  <TrashIcon />
                </div>
              </div>
              <div
                className={`${classes.modalCenter} ${classes.modalTitleSpace}`}
              >
                <Typography className={classes.modalTitleText}>
                  {t("Are you sure to delete this service?")}
                </Typography>
              </div>
              <div className={classes.modalCenter}>
                <div className={classes.separator} style={{ width: 217 }}></div>
              </div>
              <div className={classes.modalCenter}>
                <Typography className={classes.modalContentText}>
                  {service?.name || "--"}
                </Typography>
              </div>
              <div className={classes.modalActions}>
                <Button
                  className={`${classes.button} ${classes.goBack}`}
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  {t("Go back")}
                </Button>
                <Button
                  className={`${classes.button} ${classes.deleteContact}`}
                  onClick={removeService}
                >
                  {t("Delete")}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}