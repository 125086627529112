import { ApiClient } from "./api-client";
import { Weekday } from "../models/weekday";
import { Response } from "./../models/response";
import { UserScheduleInfo } from "../models/user-schedule-info";

export class WeekdayService {

    private static baseUrl: string = "/weekdays";

    static updateWeekday(day: Weekday, establishmentAccountId?: string): Promise<number> {
        let endpoint = `${this.baseUrl}/updateweekday`;

        day.accountId = establishmentAccountId ?? null;

        return ApiClient.post(endpoint, day)
            .then(data => {
                return data.appointmentsAffected;
            });
    }
    
    static async getWeekdaysForEstablishments(establishmentIds: string[] | undefined): Promise<Weekday[]> {
        let days: Weekday[] = [];
        const endpoint = `${this.baseUrl}/getweekdaysforestablishments?`
    
        Object.entries({
            EstablishmentIds: establishmentIds && establishmentIds.length > 0 ? establishmentIds : [],
        }).flatMap(([key, value]) => [value].flat().map(v => [key, v]))
          .map(it => it.join("="))
          .join("&")
        
        const data = await ApiClient.get(endpoint);
        
        data.weekdays.forEach((i: any) => {
            if (i)
                days.push(new Weekday(i));
        });
        
        return days;
    }

    static async getWeekdays(establishmentAccountId?: string): Promise<Weekday[]> {
        let days: Weekday[] = [];

        let endpoint = `${this.baseUrl}/getweekdays`;

        if (establishmentAccountId) {
            endpoint += `?AccountId=${establishmentAccountId}`;
        }

        const data = await ApiClient.get(endpoint);

        data.weekdays.forEach((i: any) => {
            if (i)
                days.push(new Weekday(i));
        });

        return days;
    }

    static async getAccountWeekdaysAsUserSchedule(establishmentAccountId?: string): Promise<UserScheduleInfo> {
        let endpoint = `${this.baseUrl}/getaccountweekdaysasuserschedule`;

        if (establishmentAccountId) {
            endpoint += `?AccountId=${establishmentAccountId}`;
        }
        return await ApiClient.get(endpoint,)
          .then((data) => data.schedule);
      }
}
