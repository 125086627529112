import React, { useState } from "react";

import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import useStyles from "./css";
import { InputDateWithLabelProps } from "./props";
import { CalendarIcon } from "../../../assets/icons";
import { useAppSelector } from "../../../redux/hooks";

const InputDateWithLabel = (props: InputDateWithLabelProps) => {
  const {
    id,
    label,
    name,
    value,
    isValid,
    placeholder,
    width,
    minDate,
    maxDate,
    onChange,
    onKeyPress,
    disabled
  } = props;
  const classes = useStyles(width)();
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  
  const getId = (): string => {
    if (id) {
      return id;
    }

    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const LabelComponent = label;

  const [inputId] = useState<string>(id || getId());

  const handleOnChange = (event: any) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleOnKeyPress = (event: any) => {
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  const isError = (): boolean => {
    if (!isValid) {
      return false;
    }

    if (isValid() === undefined) {
      return false;
    }

    return !isValid();
  };

  const getLocale = (): any => {
    switch (localeApp) {
      case "es":
        return esLocale;

      default:
        return enLocale;
    }
  };

  return (
    <div className={classes.root}>
      <label htmlFor={inputId} className={classes.label}>
        {typeof label === "string" ? label : <LabelComponent />}
      </label>
      <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
        <KeyboardDatePicker
          id={id}
          name={name}
          placeholder={placeholder}
          autoOk
          variant="inline"
          format="dd/MM/yy"
          InputProps={{
            disableUnderline: true,
            className: `${classes.input} ${
              isError() ? classes.inputError : ""
            }`,
            classes: { input: classes.inputText },
          }}
          margin="none"
          value={value || null}
          minDate={minDate}
          maxDate={maxDate}
          onChange={handleOnChange}
          onKeyPress={handleOnKeyPress}
          helperText={null}
          keyboardIcon={<CalendarIcon style={{ color: "#6462F3" }} />}
          disabled={disabled ?? false}
        />
      </MuiPickersUtilsProvider>
    </div>
  );
};

export default InputDateWithLabel;
