import { FormFieldItemValue } from "./form-field-item-value";

export class FormRadioValue {
  item: FormFieldItemValue;
  additionalFreeTextValue: string;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.item = x.item;
      this.additionalFreeTextValue = x.additionalFreeTextValue;
    }
    else {
      this.item = new FormFieldItemValue();
      this.additionalFreeTextValue = "";
    }
  }
}