import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 32,
    height: 21,
    padding: 0,
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    height: "100%",
    border: "3px solid #323947",
    "&$checked": {
      transform: "translateX(13px)",
      border: "0px",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#6462F3",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#6462F3",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 15,
    height: 15,
    boxShadow: "none",
  },
  track: {
    borderRadius: 23 / 2,
    border: "3px solid #323947",
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    //transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
}));

export default useStyles;
