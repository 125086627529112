import { createSlice } from "@reduxjs/toolkit";
import { CancelToken } from "axios";

interface FileDownloadState {
  callback: ((setPercentage: (num: number) => void, cancel: CancelToken) => Promise<Blob>) | null;
  isDownloading: boolean;
  downloadProgress: number;
  fileName: string | null;
  mimeType: string | undefined;
}

const initialState: FileDownloadState = {
  callback: null,
  isDownloading: false,
  downloadProgress: 0,
  fileName: null,
  mimeType: undefined
}

const fileDownloaderSlice = createSlice({
  name: 'fileDownloader',
  initialState: initialState,
  reducers: {
    startDownload(currentState, action) {
      if (currentState.isDownloading) return currentState;
      return {
        callback: action.payload.callback,
        isDownloading: true,
        downloadProgress: 0,
        fileName: action.payload.fileName,
        mimeType: action.payload.mimeType
      }
    },
    setProgress(currentState, action) {
      if (action.payload < 0 || action.payload > 100) return currentState;

      if (action.payload === 100) {
        return initialState
      }

      return  { ...currentState, downloadProgress: action.payload };
    },
    finishDownload() {
      return initialState;
    },
    cancelDownload() {
      return initialState;
    }
  }
});

export const fileDownloadActions = fileDownloaderSlice.actions;
export default fileDownloaderSlice.reducer;