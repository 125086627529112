import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MenuIconWhatsapp = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.89765 1.60087C9.45555 1.57519 10.9693 2.11915 12.1547 3.13066C13.3402 4.14218 14.1159 5.55171 14.3363 7.09474C14.5567 8.63778 14.2066 10.2082 13.3517 11.5114C12.4968 12.8145 11.196 13.7608 9.69324 14.1726C8.19049 14.5844 6.58917 14.4334 5.1898 13.748C5.0373 13.6733 4.865 13.6491 4.69784 13.6789L2.12694 14.1373L2.85272 12.4651C2.9638 12.2092 2.93357 11.9139 2.77295 11.6858C1.87564 10.4115 1.47402 8.85344 1.64347 7.30398C1.81291 5.75452 2.54178 4.32018 3.69328 3.27014C4.84478 2.22011 6.33976 1.62655 7.89765 1.60087ZM13.1926 1.91333C11.7108 0.648934 9.81867 -0.0310146 7.8713 0.0010869C5.92393 0.0331884 4.05521 0.775133 2.61584 2.08768C1.17646 3.40023 0.265378 5.19315 0.0535681 7.12998C-0.141622 8.91483 0.269398 10.7088 1.2129 12.2259L0.0661731 14.8679C-0.0511764 15.1382 -0.0104107 15.4512 0.172268 15.6825C0.354946 15.9138 0.649942 16.0258 0.940009 15.9741L4.72864 15.2986C6.41899 16.0576 8.32347 16.2069 10.1158 15.7157C11.9942 15.201 13.6203 14.0182 14.6889 12.3892C15.7575 10.7603 16.1951 8.79722 15.9196 6.86843C15.6441 4.93964 14.6745 3.17772 13.1926 1.91333ZM5.90669 4.41406C5.58391 4.41406 5.27435 4.54228 5.04611 4.77052C4.81787 4.99876 4.68965 5.30832 4.68965 5.6311V6.44246C4.68965 7.73357 5.20254 8.97181 6.1155 9.88476C7.02845 10.7977 8.26669 11.3106 9.5578 11.3106H10.3692C10.6919 11.3106 11.0015 11.1824 11.2297 10.9542C11.458 10.7259 11.5862 10.4164 11.5862 10.0936C11.5862 9.7708 11.458 9.46124 11.2297 9.233C11.0015 9.00476 10.6919 8.87654 10.3692 8.87654H9.5578C9.23503 8.87654 8.92547 9.00476 8.69723 9.233C8.61515 9.31507 8.54601 9.40766 8.49112 9.5076C8.03317 9.34822 7.61232 9.08672 7.26293 8.73733C6.91355 8.38794 6.65205 7.9671 6.49267 7.50914C6.5926 7.45425 6.68519 7.38511 6.76726 7.30304C6.9955 7.0748 7.12373 6.76524 7.12373 6.44246V5.6311C7.12373 5.30832 6.9955 4.99876 6.76726 4.77052C6.53902 4.54228 6.22946 4.41406 5.90669 4.41406Z" 
            />
        </SvgIcon>
    )
};

export default MenuIconWhatsapp;
