import * as React from "react";

import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export default function AppointmentDetails() {
    return (
        <Box>
            <Typography variant="h3">TODO</Typography>
        </Box>
    );
}