import {
  AppointmentModel,
  SchedulerDateTime,
} from "@devexpress/dx-react-scheduler";
import { AppointmentCreatedBy } from "./enums/appointment-created-by";
import { AppointmentRecurrenceInterval } from "./enums/appointment-recurrence-interval";
import { AppointmentStatus } from "./enums/appointment-status";
import { AppointmentPaymentStatus } from "./enums/appointment-payment-status";
import {AppointmentServiceModel} from "./appointment-service-model";
import { CustomRecurrenceMode } from "./enums/custom-recurrence-mode";
import { WeekDaysFlags } from "./enums/week-days-flags";

export class AppointmentTooltipModel {
  appointmentId: string;
  paymentStatus: AppointmentPaymentStatus;
  paidAmount: number;
  customerId: string;
  customerEmail: string;
  customerMobile: string;
  customerMobileCodeIso3: string | null;
  customerMobileDigits: number | null;
  customerMobileCode: number | null;
  customerMobileTemplate: string | null;
  notes: string;
  isRecurring: boolean;
  totalRecurringApptPriceExcludingSelected: number;
  totalRecurringApptPrice: number;
  recurredAppointmentsIds: string[];
  recurringApptTotalToPaid: number;
  appointmentRecurrenceWeekInterval: AppointmentRecurrenceInterval | null;
  recurredAppointmentsCount: number | null;
  recurringAppointmentOrder: number | null;
  services: AppointmentServiceModel[];
  scheduledSimultaneousServices: boolean;
  customRecurrenceInterval: number | null;
  customRecurrenceMode: CustomRecurrenceMode | null;
  customRecurrenceWeekDays: WeekDaysFlags | null;

  constructor(x?: any) {
    if (typeof x == "object") {
      this.appointmentId = x.appointmentId;
      this.paymentStatus = x.paymentStatus;
      this.paidAmount = x.paidAmount;
      this.customerId = x.customerId;
      this.customerEmail = x.customerEmail;
      this.customerMobile = x.customerMobile;
      this.customerMobileCodeIso3 = x.customerMobileCodeIso3;
      this.customerMobileDigits = x.mobileDigits;
      this.customerMobileCode = x.customerMobileCode;
      this.customerMobileTemplate = x.customerMobileTemplate;
      this.notes = x.notes;
      this.isRecurring = x.isRecurring;
      this.totalRecurringApptPriceExcludingSelected = x.totalRecurringApptPriceExcludingSelected;
      this.totalRecurringApptPrice = x.totalRecurringApptPrice;
      this.recurredAppointmentsIds = x.recurredAppointmentsIds;
      this.recurringApptTotalToPaid = x.recurringApptTotalToPaid;
      this.appointmentRecurrenceWeekInterval = x.appointmentRecurrenceWeekInterval;
      this.recurredAppointmentsCount = x.recurredAppointmentsCount;
      this.recurringAppointmentOrder = x.recurringAppointmentOrder;
      this.services = x.services;
      this.scheduledSimultaneousServices = x.scheduledSimultaneousServices;
      this.customRecurrenceInterval = x.customRecurrenceInterval;
      this.customRecurrenceMode = x.customRecurrenceMode;
      this.customRecurrenceWeekDays = x.customRecurrenceWeekDays;
    } else {
      this.appointmentId = "";
      this.paymentStatus = AppointmentPaymentStatus.Unpaid;
      this.paidAmount = 0.00;
      this.customerId = "";
      this.customerEmail = "";
      this.customerMobile = "";
      this.customerMobileCodeIso3 = null;
      this.customerMobileDigits = null;
      this.customerMobileCode = null;
      this.customerMobileTemplate = null;
      this.notes = "";
      this.isRecurring = false;
      this.totalRecurringApptPriceExcludingSelected = 0.00;
      this.totalRecurringApptPrice = 0.00;
      this.recurredAppointmentsIds = [];
      this.recurringApptTotalToPaid = 0.00;
      this.appointmentRecurrenceWeekInterval = null;
      this.recurredAppointmentsCount = null;
      this.recurringAppointmentOrder = null;
      this.services = [];
      this.customRecurrenceInterval = null;
      this.scheduledSimultaneousServices = false;
      this.customRecurrenceMode = null;
      this.customRecurrenceWeekDays = null;
    }
  }
}
