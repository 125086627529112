export class Response {
    entity: any[] | null;
    total: number | null;

    constructor(entity?: any, total?: number) {
        if ((typeof entity === "object" || typeof entity === "string" )&& entity !== null && entity !== undefined) {
            this.entity = entity;
            this.total = total ?? 0;

        } else {
            this.entity = null;
            this.total = null;
        }
    }
}
