import React from "react";

import Typography from "@material-ui/core/Typography";

import { CustomerClassificationItemProps } from "./props";
import useStyles from "./css";

const CustomerClassificationItem = (props: CustomerClassificationItemProps) => {
  const { title, value, icon, ...otherProps } = props;
  const classes = useStyles();
  const MainIcon = icon;
  return (
    <div {...otherProps} className={classes.root}>
      <div className={classes.classification}>
        <Typography className={classes.titles}>{title}</Typography>
        <Typography className={classes.icons}>
          <MainIcon />
          {value}
        </Typography>
      </div>
    </div>
  );
};

export default CustomerClassificationItem;
