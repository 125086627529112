import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function PersonCheckmarkIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={style || { width: 12, height: 12 }}
            viewBox={viewBox || "0 0 12 12"}
        >
            <path
                d="M4.65123 5.75C5.63599 5.75 6.45193 4.91504 6.45193 3.875C6.45193 2.84961 5.63599 2 4.65123 2C3.6524 2 2.85053 2.84961 2.85053 3.875C2.85053 4.91504 3.6524 5.75 4.65123 5.75ZM5.90328 6.21875H5.66413C5.35463 6.37988 5.017 6.45312 4.65123 6.45312C4.28546 6.45312 3.93376 6.37988 3.62427 6.21875H3.38511C2.34408 6.21875 1.5 7.1123 1.5 8.19629V8.79688C1.5 9.19238 1.79543 9.5 2.17526 9.5H7.1272C7.49297 9.5 7.80246 9.19238 7.80246 8.79688V8.19629C7.80246 7.1123 6.94431 6.21875 5.90328 6.21875ZM10.4472 4.34375L10.0533 3.93359C9.99707 3.86035 9.88452 3.86035 9.82825 3.93359L8.35111 5.45703L7.70399 4.7832C7.64771 4.70996 7.53517 4.70996 7.46483 4.7832L7.07093 5.19336C7.01465 5.25195 7.01465 5.36914 7.07093 5.44238L8.2245 6.64355C8.29484 6.7168 8.39332 6.7168 8.46366 6.64355L10.4472 4.59277C10.5176 4.51953 10.5176 4.41699 10.4472 4.34375Z"
                fill={style?.color || "#7175FA"}
            />
        </SvgIcon>
    );
};