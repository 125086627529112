import React from "react";

import moment, { Moment } from "moment";
import { TimePicker } from "antd";

import useStyles from "./css";
import { TimeOfSendingProps } from "./props";
import { ClockIcon } from "../../../../assets/icons";

export default function TimeOfSending(props: TimeOfSendingProps) {

  const format = 'HH:mm';
  
  const { time, setTime, disabled, width } = props;
  const classes = useStyles(width)();

  const handleTimeChange = (value: Moment) => {
    setTime(value.format(format))
  }
  
  return (
    <div>
      <TimePicker
        key={`timeOfSending`}
        className={disabled ? classes.timePickerInputDisabled : classes.timePickerInput}
        popupClassName={classes.timePickerPopup}
        onOk={(time) => handleTimeChange(time)}
        inputReadOnly={true}
        showNow={false}
        value={moment(time, format)}
        defaultValue={moment(time, format)}
        disabled={disabled ?? false}
        format={format}
      />
      <ClockIcon
        style={{
          top: "inherit",
          right: 25,
          pointerEvents: "none",
          color: "#919CA5",
          position: "relative"
        }}
      />
    </div>
  );
}