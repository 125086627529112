import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionFieldDateIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M5 18.5C5 19.3438 5.65625 20 6.5 20H17.5C18.3125 20 19 19.3438 19 18.5V10H5V18.5ZM15 12.375C15 12.1875 15.1562 12 15.375 12H16.625C16.8125 12 17 12.1875 17 12.375V13.625C17 13.8438 16.8125 14 16.625 14H15.375C15.1562 14 15 13.8438 15 13.625V12.375ZM15 16.375C15 16.1875 15.1562 16 15.375 16H16.625C16.8125 16 17 16.1875 17 16.375V17.625C17 17.8438 16.8125 18 16.625 18H15.375C15.1562 18 15 17.8438 15 17.625V16.375ZM11 12.375C11 12.1875 11.1562 12 11.375 12H12.625C12.8125 12 13 12.1875 13 12.375V13.625C13 13.8438 12.8125 14 12.625 14H11.375C11.1562 14 11 13.8438 11 13.625V12.375ZM11 16.375C11 16.1875 11.1562 16 11.375 16H12.625C12.8125 16 13 16.1875 13 16.375V17.625C13 17.8438 12.8125 18 12.625 18H11.375C11.1562 18 11 17.8438 11 17.625V16.375ZM7 12.375C7 12.1875 7.15625 12 7.375 12H8.625C8.8125 12 9 12.1875 9 12.375V13.625C9 13.8438 8.8125 14 8.625 14H7.375C7.15625 14 7 13.8438 7 13.625V12.375ZM7 16.375C7 16.1875 7.15625 16 7.375 16H8.625C8.8125 16 9 16.1875 9 16.375V17.625C9 17.8438 8.8125 18 8.625 18H7.375C7.15625 18 7 17.8438 7 17.625V16.375ZM17.5 6H16V4.5C16 4.25 15.75 4 15.5 4H14.5C14.2188 4 14 4.25 14 4.5V6H10V4.5C10 4.25 9.75 4 9.5 4H8.5C8.21875 4 8 4.25 8 4.5V6H6.5C5.65625 6 5 6.6875 5 7.5V9H19V7.5C19 6.6875 18.3125 6 17.5 6Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}