import * as React from "react";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import useStyles from "./css";
import { LocalStorageKey } from "../../../constants/local-storage-key";
import EstablishmentSelector from "../../navbar/EstablishmentSelector";
import useLocalStorageState from "../../../hooks/useLocalStorageState";
import { enterpriseActions } from "../../../redux/enterprise-slice";
import { navbarActions } from "../../../redux/navbar-slice";


export default function EstablishmentsToolbar() {
  
  const classes = useStyles();
  const dispatch = useAppDispatch();
  
  const userInfo = useAppSelector(state => state.userInfo)
  const accountSettings = useAppSelector((state) => state.accountSettings);
  const establishmentsStorageKey = LocalStorageKey.Establishments + userInfo.userId;
  const { setSelectedEstablishments } = enterpriseActions;
  // @ts-ignore
  const [accountEstablishments, setAccountEstablishments] = useLocalStorageState({ itemKey: establishmentsStorageKey, dispatchFunction: setSelectedEstablishments });
  
  useEffect(
    () => {
      dispatch(navbarActions.setExternalContent((
        <>
          {
            accountSettings.isEnterprise ?
              <div className={classes.container}>
                <EstablishmentSelector
                  establishments={accountEstablishments ?? []}
                  setEstablishments={setAccountEstablishments}
                  items={accountSettings?.establishments ?? []}
                />
              </div>
              : null
          }
        </>)));
    },
    [accountSettings, accountEstablishments]
  );
  
  return null;
}
