import React from 'react';

import { Link, useRouteMatch } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAppSelector } from '../../../redux/hooks';
import { RootState, selectAccountSettings } from '../../../redux/store';
import { Tab, Tabs } from "@material-ui/core";
import { Url } from "../../../constants/url";
import useStyles from "./css";
import EstablishmentSelector from '../SettingsEstablishmentSelector';

import { useSelector } from 'react-redux';
import { selectUserInfo } from '../../../redux/store';


export default function EstablishmentSettingsNavTabs() {
  
  const routeMatch = useRouteMatch({
    path: [
      Url.EstablishmentSettings.General,
      Url.EstablishmentSettings.ClinicHistory,
      Url.EstablishmentSettings.EvolutionNotes,
      Url.EstablishmentSettings.PatientForm,
      Url.EstablishmentSettings.CustomerCommunications,
      Url.EstablishmentSettings.WorkAreas,
      Url.EstablishmentSettings.Users,
      Url.EstablishmentSettings.Services,
      Url.EstablishmentSettings.Commissions,
      Url.EstablishmentSettings.Payments,
    ],
    exact: true
  });
  
  const currentTab = routeMatch?.path;
  
  const { t } = useTranslation(["settings"]);
  
  const classes = useStyles();
  
  const accountSettings = useAppSelector(selectAccountSettings);

  const userInfo = useAppSelector(selectUserInfo);
  const settingsSelectedAccountId = useSelector((state: RootState) => state.enterprise.settingsEstablishmentId);
  const enterpriseSelected = userInfo.accountId === settingsSelectedAccountId; 

  return (
      <>
      <div className={classes.headerBack} />
      <EstablishmentSelector />
      <div className={classes.mainTab}>
        <div className={classes.tabBar}>
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
          >
            <Tab
              className={classes.tab}
              label={t("General")}
              value={Url.EstablishmentSettings.General}
              to={Url.EstablishmentSettings.General}
              component={Link}
            />
            {(settingsSelectedAccountId && !enterpriseSelected) ?
              <Tab
                className={classes.tab}
                label={t("Communication with the customer")}
                value={Url.EstablishmentSettings.CustomerCommunications}
                to={Url.EstablishmentSettings.CustomerCommunications}
                component={Link}
                /> :                
                <div/>
            }
            {(settingsSelectedAccountId && !enterpriseSelected) ?
              <Tab
                className={classes.tab}
                label={t("Work areas")}
                value={Url.EstablishmentSettings.WorkAreas}
                to={Url.EstablishmentSettings.WorkAreas}
                component={Link} 
                /> :                
                <div/>
                      }
            {(settingsSelectedAccountId && !enterpriseSelected) ?
              <Tab
                className={classes.tab}
                label={t("Services")}
                value={Url.EstablishmentSettings.Services}
                to={Url.EstablishmentSettings.Services}
                component={Link} 
                /> :                
                <div/>
            }
            {(settingsSelectedAccountId && !enterpriseSelected) ?
              <Tab
                className={classes.tab}
                label={t("Users")}
                value={Url.EstablishmentSettings.Users}
                to={Url.EstablishmentSettings.Users}
                component={Link} 
                /> :                
                <div/>
            }
            {(settingsSelectedAccountId && !enterpriseSelected) ?
              <Tab
                className={classes.tab}
                label={t("Payments")}
                value={Url.EstablishmentSettings.Payments}
                to={Url.EstablishmentSettings.Payments}
                component={Link}
                /> :              
                <div/>
            }
            {(accountSettings.hasEstablishmentsInClinicMode) ?
                <Tab
                  className={classes.tab}
                  label={t("Clinical history")}
                  value={Url.EstablishmentSettings.ClinicHistory}
                  to={Url.EstablishmentSettings.ClinicHistory}
                  component={Link}
                /> :
                <div/>
            }
            {(accountSettings.hasEstablishmentsInClinicMode) ?
                <Tab
                  className={classes.tab}
                  label={t("Evolution notes")}
                  value={Url.EstablishmentSettings.EvolutionNotes}
                  to={Url.EstablishmentSettings.EvolutionNotes}
                  component={Link}
                /> :
                <div/>
            }
            {(accountSettings.hasEstablishmentsInClinicMode) ?
                <Tab
                  className={classes.tab}
                  label={t("Patient Form")}
                  value={Url.EstablishmentSettings.PatientForm}
                  to={Url.EstablishmentSettings.PatientForm}
                  component={Link}
                /> :
                <div/>
            }
            {(settingsSelectedAccountId && !enterpriseSelected) ?
              <Tab
                className={classes.tab}
                label={t("Commissions")}
                value={Url.EstablishmentSettings.Commissions}
                to={Url.EstablishmentSettings.Commissions}
                component={Link} 
                /> :                
                <div/>
            }
          </Tabs>
        </div>
      </div>
    </>);
}