import React, { useEffect, useRef, useState } from 'react';

import { useTranslation } from "react-i18next";

import CanvasDraw from "react-canvas-draw";

import { Button } from "@material-ui/core";

import { PatientSignatureProps } from "./props";
import useStyles from "./css";
import { PenIcon } from "../../../../assets/icons";


export default function PatientSignature(props: PatientSignatureProps) {
  
  const { signature, setSignature, onSave, enabled } = props;

  const [isDrawingEnabled, setIsDrawingEnabled] = useState<boolean>(false);
  const [showHoverImage, setShowHoverImage] = useState<boolean>(false);

  const { t } = useTranslation("general");
  
  const classes = useStyles();
  
  const canvas = useRef<CanvasDraw | null>(null);
  const brushColor = "#000000";
  const brushRadius = 2;
  const lazyRadius = 3;
  
  useEffect(() => {
    if (signature) {
      canvas.current?.loadSaveData(signature)
    }
  }, [])
  
  function handleDeleteSignatureClick() {
    canvas.current?.clear();
    setSignature(null)
  }

  function handleSaveSignatureClick() {
    onSave();
    setIsDrawingEnabled(false)
  }
  
  function isDeleteSignatureButtonDisabled() {
    return signature === null;
  }

  function isSaveSignatureButtonDisabled() {
    return signature === null;
  }
  
  function handleCanvasOnChange(canvas: CanvasDraw) {
    setSignature(canvas.getSaveData())
  }

  function handleOnMouseEnter() {
    if (!enabled) return;
    if (isDrawingEnabled) return;

    setShowHoverImage(true)
  }

  function handleOnMouseLeave() {
    if (isDrawingEnabled) return;

    setShowHoverImage(false)
  }

  function handleHoverClick() {
    setIsDrawingEnabled(true)
    setShowHoverImage(false)
  }
  
  return (
    <div className={classes.root}>
      <div
        onMouseEnter={() => handleOnMouseEnter()}
        onMouseLeave={() => handleOnMouseLeave()}
      >
        {
          showHoverImage
            ?
            <div
              className={classes.hoverBackground}
              onClick={handleHoverClick}
            >
              <div className={classes.hoverContent}>
                <div className={classes.hoverIcon}>
                  <PenIcon />
                </div>
                <div className={classes.hoverText}>
                  {t("Draw signature")}
                </div>
              </div>
            </div>
            :
            <CanvasDraw
              ref={canvasDraw => (canvas.current = canvasDraw)}
              className={classes.signatureBlock}
              canvasWidth={329}
              canvasHeight={118}
              brushRadius={brushRadius}
              brushColor={brushColor}
              lazyRadius={lazyRadius}
              disabled={!isDrawingEnabled}
              onChange={handleCanvasOnChange}
              saveData={signature ?? canvas.current?.getSaveData()}
              immediateLoading
              clampLinesToDocument
              hideInterface
              hideGrid
            />
        }
      </div>
      {
        isDrawingEnabled &&
        <div className={classes.controlSignatureButtonsBlock}>
          <Button
            className={classes.deleteSignatureButton}
            onClick={handleDeleteSignatureClick}
            disabled={isDeleteSignatureButtonDisabled()}
          >
            {t("Delete signature")}
          </Button>
          <Button
            className={classes.saveSignatureButton}
            onClick={handleSaveSignatureClick}
            disabled={isSaveSignatureButtonDisabled()}
          >
            {t("Save signature")}
          </Button>
        </div>
      }

    </div>
  );
}