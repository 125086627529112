import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    showMenu: false,
    showModal: false,
    date: null,
    userId: "",
    blockedSheduleId: ""
};

const blockedScheduleSlice = createSlice({
    name: 'blockedSchedule',
    initialState: initialState,
    reducers: {
        setShowMenu(currentState, action) {
            currentState.showMenu = action.payload;
        },
        setShowModal(currentState, action) {
            currentState.showModal = action.payload;
        },
        setDate(currentState, action) {
            currentState.date = action.payload;
        },
        setUserId(currentState, action) {
            currentState.userId = action.payload;
        },
        setBlockedSheduleId(currentState, action) {
            currentState.blockedSheduleId = action.payload;
        }
    }
});

export const blockedScheduleActions = blockedScheduleSlice.actions;
export default blockedScheduleSlice.reducer;