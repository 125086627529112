import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";

import { useHistory } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { alertsActions } from "../../../redux/alerts-slice";
import { navbarActions } from "../../../redux/navbar-slice";
import { selectAccountSettings } from "../../../redux/store";

import Button from "@material-ui/core/Button";

import CustomerClassification from "../CustomerClassification";
import { ImportIcon } from "../../../assets/icons";
import CustomerModal from "./../CustomerModal";
import CustomerImportModal from "./../CustomerImportModal";
import CustomerList from "./../CustomerList";
import { Url } from "../../../constants/url";
import useStyles from "./css";
import { CustomersClassification } from "../../../models/customers-classification";
import { CustomerClassificationService } from "../../../api/customer-classification-service";
import EstablishmentsToolbar from "../../common/EstablishmentsToolbar/establishments-toolbar";


export default function CustomerMainPage() {
  
  const { t } = useTranslation(["general"]);
  const classes = useStyles();
  const history = useHistory();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openImportModal, setOpenImportModal] = useState<boolean>(false);

  const [isRefresh, setIsRefresh] = useState<any>();
  const [customerClassification, setCustomerClassification] = useState<CustomersClassification>(new CustomersClassification());

  const dispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;
  
  const accountSettings = useAppSelector(selectAccountSettings);
  const selectedEstablishments = useAppSelector(state => state.enterprise.selectedEstablishments)
  
  useEffect(() => {
    async function fetchData() {
      await loadCustomerClassification();
    }
    
    fetchData();
  }, [isRefresh, accountSettings.isEnterprise, selectedEstablishments]);
  
  async function loadCustomerClassification() {
    dispatch(navbarActions.setShowLoader(true));
    if (accountSettings.isEnterprise === null) return;
    const classification = accountSettings.isEnterprise 
      ? await CustomerClassificationService.getCustomerClassificationByEstablishments(selectedEstablishments)
      : await CustomerClassificationService.getCustomerClassification();
    dispatch(navbarActions.setShowLoader(false));
    setCustomerClassification(classification);
  }

  function handleOpenModal() {
    setOpenModal(true);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }
  
  function handleOpenImportModal() {
    setOpenImportModal(true);
  }

  function handleCloseImportModal() {
    setOpenImportModal(false);
  }

  function handleCustomerCreated(customer: any) {
    setIsRefresh(customer);
    setOpenModal(false);
    dispatch(
      enqueueAlert({
        type: "Success",
        title: t("Customer Created"),
        description: `${customer.firstName} ${customer.lastName}`
      })
    );
  }

  function handleCustomerDuplicated(customer: any) {
    setIsRefresh(customer);
    dispatch(
      enqueueAlert({
        type: "Error",
        title: t("Invalid number"),
        description: t("This number already belongs to customer"),
        link: {
          onClick: (e: any) => {
            e.preventDefault();
            history.push(`${Url.Customers.Main}/${customer.id}`);
          },
          title: `${customer.firstName} ${customer.lastName}`,
        }
      })
    );
  }
  
  const importCustomersTitle = useCustomerTerminology(t("Import customers"), "customers")
  const createCustomerTitle = useCustomerTerminology(t("Create customer"), "customer")

  return (
    <>
      <EstablishmentsToolbar />
      <div className={classes.header}>
        <CustomerClassification 
          classification={customerClassification} 
        />
        {
          accountSettings.isEnterprise 
            ? null
            : 
            <>
              <div className={classes.root}>
                <Button
                  className={`${classes.button} ${classes.importCustomer}`}
                  onClick={handleOpenImportModal}
                >
                  <ImportIcon /> {importCustomersTitle}
                </Button>
                <Button
                  className={`${classes.button} ${classes.newCustomer}`}
                  onClick={handleOpenModal}
                >
                  {createCustomerTitle}
                </Button>
              </div>
            </>
        }
      </div>
      <CustomerList
        isRefresh={isRefresh}
        openCustomerModal={handleOpenModal}
        refreshCustomers={() => { setIsRefresh(Math.random()) }}
      />
      <CustomerImportModal
        open={openImportModal}
        onClose={handleCloseImportModal}
        refreshCustomers={() => { setIsRefresh(Math.random()) }}
      />
      <CustomerModal
        open={openModal}
        onClose={handleCloseModal}
        onDuplicate={handleCustomerDuplicated}
        onCreate={handleCustomerCreated}
      />
    </>
  );
};
