import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function CaptionImageMediaIcon(props: any) {
    const { style, height, width, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: width || 12, height: height || 11, ...style }}
            viewBox={viewBox || "0 0 12 11"}
        >
            <path 
            d="M1.48068 8.28627L0.819037 9.10139H1.86889H9.74722H10.7802L10.1394 8.29122L7.77106 5.29678L7.38451 4.80804L6.99112 5.29129L5.26255 7.41475L4.14963 5.97357L3.76366 5.47376L3.36568 5.96405L1.48068 8.28627ZM10.966 0.637811L11.3086 0.276463L10.966 0.637812C11.0697 0.736168 11.1 0.822573 11.1 0.916667V10.0833C11.1 10.1774 11.0697 10.2638 10.966 10.3622C10.8621 10.4607 10.7584 10.5 10.6333 10.5H0.966667C0.841592 10.5 0.737946 10.4607 0.634046 10.3622C0.530326 10.2638 0.5 10.1774 0.5 10.0833V0.916667C0.5 0.822572 0.530326 0.736167 0.634046 0.637812C0.737947 0.539285 0.841593 0.5 0.966667 0.5H10.6333C10.7584 0.5 10.8621 0.539285 10.966 0.637811Z" 
            fill="transparent" 
            stroke={style?.color || Color.gray5}/>
        </SvgIcon>
    );
}