import { makeStyles } from "@material-ui/core/styles";

const useStyles = (active: boolean) => makeStyles((theme) => ({
  root: {
    width: 32,
    height: 21,
    padding: 0,
    marginLeft: 11
    // margin: theme.spacing(1),
  },
  switchBase: {
    padding: 1,
    height: "100%",
    border: "3px solid #323947",
    "&$checked": {
      transform: "translateX(13px)",
      border: "0px",
      color: theme.palette.common.white,
      "& + $track": {
        backgroundColor: "#36CE91",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#36CE91",
      border: "6px solid #fff",
    },
  },
  thumb: {
    width: 15,
    height: 15,
    boxShadow: "none",
  },
  track: {
    borderRadius: 23 / 2,
    border: "3px solid #323947",
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    //transition: theme.transitions.create(["background-color", "border"]),
  },
  checked: {},
  focusVisible: {},
  label: {
    marginLeft: 16,
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: active ? theme.colors.grey5 : theme.colors.grey3
  },
  icon: {
    marginRight: 6
  }
  })
);

export default useStyles;