import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CashIcon = (props: any) => {
  
  const { style, color, ...otherProps } = props;
  
  return (
    <SvgIcon
      {...otherProps}
      style={{ ...style, fontSize: '24px' }}
      viewBox="0 0 24 24"
    >
      <path
        d="M3.59481 6H20.4053C20.921 6 21.3393 6.41969 21.3393 6.93721V16.3093C21.3393 16.8268 20.921 17.2465 20.4053 17.2465H3.59481C3.07911 17.2465 2.66089 16.8268 2.66089 16.3093V6.93721C2.66089 6.41969 3.07911 6 3.59481 6ZM18.0705 15.8407C18.0705 14.8053 18.9067 13.9663 19.9384 13.9663V9.28022C18.9067 9.28022 18.0705 8.44113 18.0705 7.40581H5.9296C5.9296 8.44113 5.09345 9.28022 4.06177 9.28022V13.9663C5.09345 13.9663 5.9296 14.8053 5.9296 15.8407H18.0705ZM11.9343 13.7609C13.133 13.7609 14.1047 12.7891 14.1047 11.5905C14.1047 10.3918 13.133 9.42011 11.9343 9.42011C10.7356 9.42011 9.76394 10.3918 9.76394 11.5905C9.76394 12.7891 10.7356 13.7609 11.9343 13.7609Z"
        fill={color || style?.color || "#919CA5"}
      />
    </SvgIcon>
  )
};
export default CashIcon;