import React, {  } from "react";

import CustomerEmergencyContacts from "./../CustomerEmergencyContacts";

import { CustomerEmergencyContactListProps } from "./props";

const CustomerEmergencyContactList = (
  props: CustomerEmergencyContactListProps
) => {
  const { list, onChange, onDelete } = props;

  return (
    <>
      {list.map((contact, index) => (
        <CustomerEmergencyContacts
          zIndex={index}
          key={index}
          value={contact}
          onChange={(contact) => {
            onChange(contact, index);
          }}
          onDelete={(contact) => {
            onDelete(contact, index);
          }}
        />
      ))}
    </>
  );
};

export default CustomerEmergencyContactList;
