import React, { useState } from 'react';
import AppointmentCard from '../AppointmentCard';
import { Appointment } from "../../../../../models/appointment";
import AppointmentItemTooltip from "../../../../appointments/AppointmentItemTooltip";
import { useDrag } from "react-dnd";
import { schedulerActions } from "../../../../../redux/scheduler-slice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { selectAccountSettings } from "../../../../../redux/store";

type AppointmentTicketProps = {
    appointment: Appointment;
};

export default function AppointmentTicket({ appointment }: AppointmentTicketProps) {
    const accountSettings = useAppSelector(selectAccountSettings);
    const mode = useAppSelector((state) => state.scheduler.currentViewName);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [isAppointmentPopupOpened, setIsAppointmentPopupOpened] = useState<boolean>(false);
    const { setIsDragging } = schedulerActions;
    const dispatch = useAppDispatch();
    
    let dragZone: string;
    if (mode === "Day"){
      if (accountSettings.isEnterprise && accountSettings.establishments.length > 1){
        dragZone = `Appointment-${appointment.establishmentId}`;
      } else {
        dragZone = `Appointment-${appointment.userId}`;
      }
    } else {
      dragZone = "Appointment";
    }
    
    const [{ opacity }, drag] = useDrag(
      () => ({
        type: dragZone,
        item: { appointment: appointment },
        canDrag: appointment.isDraggable && appointment.startDate.toMillis() > Date.now(),
        collect: (monitor) => {
          if (monitor.isDragging() && monitor.getItem()?.appointment.appointmentId === appointment.appointmentId)
          {
            dispatch(setIsDragging(true));
          }
          if (monitor.didDrop())
          {
            dispatch(setIsDragging(false));
          }
          return ({opacity: monitor.isDragging() ? 0.4 : 1})},
      }),
    )
  
    const onDragEnd = function (){
      dispatch(setIsDragging(false));
    }
    
    return (
      <div ref={drag} style={{ height: "100%", opacity}} onDragEnd={onDragEnd}>
        <AppointmentCard
            ref={setAnchorEl}
            anchorEl={anchorEl}
            appointment={appointment}
            onClick={() => setIsAppointmentPopupOpened(true)}
        />
        <AppointmentItemTooltip
          anchorEl={anchorEl}
          appointmentMeta={appointment}
          visible={isAppointmentPopupOpened}
          onHide={() => setIsAppointmentPopupOpened(false)}
        />
      </div>
    );
}
