import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import { CalendarCheckmarkIcon, EditIcon, PersonCheckmarkIcon, PersonSpeechBalloonIcon, TrashIcon } from "../../../../assets/icons";
import GridList, { OnChangeEvent } from "../../../common/grid/GridList";
import { TableColumn } from "../../../common/grid/table-column";
import { Color } from "../../../../constants/colors";

import { ApiClient } from "../../../../api/api-client";
import { MassiveSendingService } from "../../../../api/massive-sending-service";
import SendingModel from "../../../../models/massive-sending-model";

import { MassiveSendingListProps } from "./props";
import useStyles from "./css";
import { SelectItem } from "../../../common/Select";
import { GridInfoModel } from "../../../../models/helpers/grid-info.model";
import ConfirmDeleteModal from "../../../common/ConfirmDeleteModal";
import { Typography } from "@material-ui/core";
import { GetMonthStringEnum } from "../../../../models/enums/months";
import { MassiveSendingType } from "../../../../models/enums/massive-sending-type";
import { GetSendingBirthdayStringEnum, MassiveSendingBirthdayType } from "../../../../models/enums/massive-sending-birthday-type";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import IconCounter from "../../../common/IconCounter";
import IconTooltip from "../../../common/IconTooltip";
import useCustomerTerminology from "../../../../hooks/useCustomerTerminology";
import { navbarActions } from "../../../../redux/navbar-slice";


const MassiveSendingList = (props: MassiveSendingListProps) => {

    const { t } = useTranslation(["massivesendings", "general"]);
    const localeApp = useAppSelector(state => state.applicationInterface.locale);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const hasMassiveSendings = ApiClient.getUserInfo().hasMassiveSendings;

    const {
        isRefresh,
        onRowClick,
        onDelete,
        setTotalRecords
    } = props;

    const pageSize: number = 20;
    const [entityList, setEntityList] = useState<SendingModel[]>([]);
    const [total, setTotal] = useState<number>(0);

    const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
    const [itemToDelete, setItemToDelete] = useState<SendingModel | null>(null);

    const [filterCustomer, setFilterCustomer] = useState<SelectItem>({ key: 0, value: useCustomerTerminology(t("All customers"), "customers") });
    const [filterStatus, setFilterStatus] = useState<SelectItem | null>(null);
    
    const sentToCustomersIconTooltipRecently = useCustomerTerminology(t("Customers to whom the WhatsApp message was sent"), "Customers");
    const sentToCustomersIconTooltipThisYear = useCustomerTerminology(t("Customers to whom the WhatsApp message was sent this year"), "Customers");
    const sentToCustomersIconTooltipThisMonth = useCustomerTerminology(t("Customers to whom the WhatsApp message was sent this month"), "Customers");
    const customersRespondedTooltip = useCustomerTerminology(t("Customers responded to the massive sending"), "Customers")
    const customersTypeAllCustomers = useCustomerTerminology(t("AllCustomers"), "AllCustomers")
    const customersTypeFrequent = useCustomerTerminology(t("Frequent"), "Frequent")
    const customersTypeInfrequent = useCustomerTerminology(t("Infrequent"), "Infrequent")
    const customersTypeInactive = useCustomerTerminology(t("Inactive"), "Inactive")

    const getTerminologyForFrequencyType = (str: string) => {
        switch (str) {
            case "AllCustomers": {
                return customersTypeAllCustomers;
            }
            case "Frequent": {
                return customersTypeFrequent;
            }
            case "Infrequent": {
                return customersTypeInfrequent;
            }
            case "Inactive": {
                return customersTypeInactive;
            }
            default: return "AllCustomers";
        }
    }
    
    const [gridInfo, setGridInfo] = useState<GridInfoModel>(new GridInfoModel({
        page: 1,
        pageSize,
        order: "asc",
        orderBy: "name",
        value: null,
        properties: [],
        total: 0
    }));


    useEffect(() => {
        (async () => {
            await loadData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [gridInfo, isRefresh, filterCustomer, filterStatus]);


    const loadData = async () => {
        dispatch(navbarActions.setShowLoader(true));
        const data = await MassiveSendingService.getSendings(
            gridInfo, filterCustomer?.key, filterStatus?.key
        );
        dispatch(navbarActions.setShowLoader(false));
        setEntityList(data.entity || []);
        setTotal(data.total || 0);
        if (setTotalRecords) setTotalRecords(data.total || 0);
    };

    const StatusStylized = (status: string) => {
        if (status === "Active") {
            return (<div className={`${classes.divStatus} ${classes.statusActive}`}>
                {t(status)}
            </div>)
        }
        else if (status === "Finalized") {
            return (<div className={`${classes.divStatus} ${classes.statusDone}`}>
                {t(status)}
            </div>)
        }
        else {
            return null
        }
    };

    const DateStylizedStart = (dateParam: Date, stringTimeOfSending: string, sendingType: MassiveSendingType, birthdayType: MassiveSendingBirthdayType | number) => {

        const date = new Date(dateParam);

        return <>{
            (sendingType === MassiveSendingType.Birthdays)
                ?
                t(GetSendingBirthdayStringEnum(birthdayType)) + " " + stringTimeOfSending + "hrs"
                :
                <>
                    <div>
                        {
                            localeApp === "es"
                                ?
                                date.getDate().toString().padStart(2, "0") + "/" + t(GetMonthStringEnum(date.getMonth())) + "/" + date.getFullYear()
                                :
                                t(GetMonthStringEnum(date.getMonth())) + "/" + date.getDate().toString().padStart(2, "0") + "/" + date.getFullYear()
                        }
                    </div>
                    <div>
                        {stringTimeOfSending + "hrs"}
                    </div>
                </>
        }</>
    };


    const DateStylizedEnd = (dateStartParam: Date, stringTimeOfSending: string, dateEndParam: Date, sendingType: MassiveSendingType) => {

        const dateStart = new Date(dateStartParam);
        const dateEnd = new Date(dateEndParam);

        return <>{
            (sendingType === MassiveSendingType.SingleSending)
                ?
                <>
                    <div>
                        {
                            localeApp === "es"
                                ?
                                dateStart.getDate().toString().padStart(2, "0") + "/" + t(GetMonthStringEnum(dateStart.getMonth())) + "/" + dateStart.getFullYear()
                                :
                                t(GetMonthStringEnum(dateStart.getMonth())) + "/" + dateStart.getDate().toString().padStart(2, "0") + "/" + dateStart.getFullYear()
                        }
                    </div>
                    <div>
                        {stringTimeOfSending + "hrs"}
                    </div>
                </>
                :
                dateEndParam
                    ?
                    <>{
                        localeApp === "es"
                            ?
                            dateEnd.getDate().toString().padStart(2, "0") + "/" + t(GetMonthStringEnum(dateEnd.getMonth())) + "/" + dateEnd.getFullYear()
                            :
                            t(GetMonthStringEnum(dateEnd.getMonth())) + "/" + dateEnd.getDate().toString().padStart(2, "0") + "/" + dateEnd.getFullYear()
                    }
                        <div>
                            {stringTimeOfSending + "hrs"}
                        </div></>
                    :
                    <>{t("Never ends")}</>
        }</>
    };

    const GetImpactedCustomerComponent = (massiveSendingType: number, sentToCustomersCount: number, responsesReceivedCount: number, appointmentsScheduledCount: number) => {
        let sentToCustomersIconTooltip;
        switch (massiveSendingType) {
            case 0: {
                sentToCustomersIconTooltip = sentToCustomersIconTooltipRecently;
                break;
            } 
            case 1: {
                sentToCustomersIconTooltip =  sentToCustomersIconTooltipThisYear;
                break;
            } 
            case 2: {
                sentToCustomersIconTooltip = sentToCustomersIconTooltipThisMonth;
                break;
            } 
            default: sentToCustomersIconTooltip = "";
        }
        return <div className={classes.impactedCustomersContainer}>
            <IconTooltip
                icon={<IconCounter count={sentToCustomersCount} icon={<PersonCheckmarkIcon />} />}
                tooltipText={sentToCustomersIconTooltip} />
            <IconTooltip
                icon={<IconCounter count={responsesReceivedCount} icon={<PersonSpeechBalloonIcon />} />}
                tooltipText={customersRespondedTooltip} />
            <IconTooltip
                icon={<IconCounter count={appointmentsScheduledCount} icon={<CalendarCheckmarkIcon />} />}
                tooltipText={t("Appointments scheduled 72 hours after massive sending")} />
        </div>
    }

    const filterListCustomer: SelectItem[] = [
        { key: 0, value: useCustomerTerminology(t("All customers"), "customers") },
        { key: 1, value: useCustomerTerminology(t("Frequents customers"), ("Frequents customers"))},
        { key: 2, value: useCustomerTerminology(t("Infrequents customers"), "Infrequents customers")},
        { key: 3, value: useCustomerTerminology(t("Inactives customers"), "Inactives customers") }
    ];


    const filterListStatus: SelectItem[] = [
        { key: 0, value: t("Active") },
        { key: 1, value: t("Finalized") },
    ];

    const columns: TableColumn[] = [
        {
            id: "name",
            label: t("SENDING NAME"),
            sorting: false,
            width: 180,
        },
        {
            id: "customerFrequency",
            label: useCustomerTerminology( t("CUSTOMERS"), "CUSTOMERS"),
            sorting: false,
            width: 170,
            completeObject: true,
            component: (item: any) => <>
                <div style={{ fontWeight: 700 }}>
                    { getTerminologyForFrequencyType(item.customerFrequency) }
                </div>
                <div>
                    {t(item.customerGender)}
                </div>
            </>,
            filtering: true,
            filterList: filterListCustomer,
            setFilter: setFilterCustomer
        },
        {
            id: "type",
            label: t("TYPE OF SENDING"),
            sorting: false,
            width: 130,
            completeObject: true,
            component: (item: any) => <>{t(item.type)}</>
        },
        {
            id: "dateOfSending",
            label: t("SEND DATE"),
            sorting: true,
            width: 130,
            completeObject: true,
            component: (item: any) => DateStylizedStart(item.dateOfSending, item.stringTimeOfSending, item.typeId, item.birthdaySendingType),
        },
        {
            id: "terminationDate",
            label: t("ENDING DATE"),
            sorting: false,
            width: 130,
            completeObject: true,
            component: (item: any) => DateStylizedEnd(item.dateOfSending, item.stringTimeOfSending, item.terminationDate, item.typeId),
        },
        {
            id: "impactedCustomers",
            label: useCustomerTerminology(t("IMPACTED CUSTOMERS"), "CUSTOMERS"),
            sorting: false,
            width: 150,
            completeObject: true,
            component: (item: any) => GetImpactedCustomerComponent(item.typeId, item.sentToCustomersCount, item.responsesReceivedCount, item.appointmentsScheduledCount)
        },
        {
            id: "status",
            label: t("STATUS"),
            sorting: false,
            width: 80,
            completeObject: true,
            headerTextAlign: "center",
            textAlign: "center",
            filtering: true,
            filterList: filterListStatus,
            setFilter: setFilterStatus,
            component: (item: any) => StatusStylized(item.status)
        },
        {
            id: "id",
            label: "",
            sorting: false,
            completeObject: true,
            component: (item: any) =>
                item.status === "Finalized"
                    ? null
                    :
                    <>
                        <div className={`${classes.buttonRow} buttonRow`} style={{ marginRight: 10 }} onClick={(e) => { e.stopPropagation(); handleOnEdit(item); }} >
                            <EditIcon style={{ color: Color.gray4, width: 13, height: 17, }} />
                        </div>
                        <div className={`${classes.buttonRow} buttonRow`} onClick={(e) => { e.stopPropagation(); handleDeleteButtonClick(item); }} >
                            <TrashIcon style={{ color: Color.gray4, width: 13, height: 17, }} />
                        </div>
                    </>,
            width: 100,
            headerTextAlign: "center",
            textAlign: "center",
            visible: hasMassiveSendings
        },
    ];


    const handleOnChangeGrid = (event: OnChangeEvent) => {

        const page = event.page;

        if (event.sorting) {
            const order = event.sorting.direction;
            const orderBy = event.sorting.orderBy;

            setGridInfo({
                ...gridInfo,
                page,
                order,
                orderBy
            });

        } else if (gridInfo.page !== page) {
            setGridInfo({
                ...gridInfo,
                page
            });
        }

    };


    const handleOnEdit = (item: any) => {
        if (onRowClick && hasMassiveSendings) onRowClick(item.id);
    };

    function handleDeleteButtonClick(item: SendingModel) {
        if (hasMassiveSendings) {
            setItemToDelete(item);
            setOpenDeleteDialog(true);
        }
    }

    const handleOnDelete = (item: any) => {
        if (onDelete) onDelete(item);
        setItemToDelete(null);
        setOpenDeleteDialog(false);
    };

    const DivInfoDelete: React.FC = () => {
        return (<>
            <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`} >
                <Typography className={classes.modalTitleText}>
                    {t("Delete massive sending?")}
                </Typography>
            </div>
            <div className={classes.modalCenter}>
                <div className={classes.separator} style={{ width: 217 }} />
            </div>
            <div className={classes.modalCenter}>
                <Typography className={classes.modalContentText}>
                    {t("If you delete the massive sending, its delivery will be canceled.")}
                </Typography>
            </div>
        </>);
    };

    return (<>
        <GridList
            columns={columns}
            items={entityList}
            totalItems={total}
            pageSize={pageSize}
            onChange={handleOnChangeGrid}
            classRow={classes.classRow}
            showPaginationAlways={true}
        />
        <ConfirmDeleteModal
            textButtonConfirm={t("Yes, delete")}
            classModal={classes.modalDelete}
            open={openDeleteDialog}
            item={itemToDelete}
            componentInfo={<DivInfoDelete />}
            onClose={() => setOpenDeleteDialog(false)}
            onDelete={handleOnDelete}
        />
    </>);
};

export default MassiveSendingList;
