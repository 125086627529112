import { makeStyles } from "@material-ui/core/styles";

const useStyles = (width?: number | string, itemHeight?: number | string) =>
  makeStyles((theme) => ({
    select: {
      ...theme.typography.txtBody2,
      backgroundColor: theme.colors.grey1,
      height: 40,
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      cursor: "pointer",
      width: width || 370,
      "& ::-webkit-scrollbar": {
        width: "6px",
        cursor: "pointer",
      },
      "& ::-webkit-scrollbar-track": {
        borderRadius: "10px",
        background: "#EFEFEF",
        marginTop: "11px",
        marginBottom: "11px"
      },
      "& ::-webkit-scrollbar-thumb": {
        borderRadius: "10px",
        background: "#ACB7C0",
      },
      "& ::-webkit-scrollbar-thumb:hover": {
        background: "#919CA5"
      },
    },
    selected: {
      fontWeight: "bold",
      padding: "11px 0px 12px 12px",
    },
    placeholder: {
      color: "#676767",
      opacity: "0.42",
      padding: "13px 0px 13px 12px",
      "&.Mui-disabled": {
        opacity: "0.42",
      },
    },
    focused: {
      color: "#5C6477",
      "&:focus": {
        backgroundColor: theme.colors.grey1,
      },
      "&.MuiSelect-select": {
        "&:focus": {
          borderRadius: 5,
        },
      },
    },
    menuPaper: {
      maxHeight: 300,
      marginTop: 6,

    },
    itemRoot: {
      ...theme.typography.txtBody1,
      color: theme.colors.grey5,
      height: itemHeight || 40,
      display: "flex",
      alignItems: "center",
    },
    itemSelected: {
      fontWeight: "bold",
      color: theme.colors.clearMain,
    },
    inputError: {
      color: theme.colors.clearRed,
      border: `solid 1px ${theme.colors.clearRed}`,
    },
    group: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey4,
      height: 40,
      display: "flex",
      alignItems: "center",
      "&.Mui-disabled": {
        opacity: 1,
      },
    },
  }));

export default useStyles;