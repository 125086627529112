import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    
    width: 12,
    height: 12,
  },
  })
);

export default useStyles;