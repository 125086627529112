import React, { useEffect, useState } from "react";
import { useAppSelector } from '../../../../../redux/hooks';
import { selectAccountSettings } from "../../../../../redux/store";
import { useTranslation } from "react-i18next";

import useStyles from "./css";
import { Divider } from "@material-ui/core";
import { UserModalCommissionsProps } from "./props";
import { InfoIcon } from "../../../../../assets/icons";
import IconTooltip from "../../../../common/IconTooltip";
import { UserCommissionType } from "../../../../../models/enums/user-commission-type";
import InputCurrency from "../../../../common/InputCurrency";
import { CommissionByServiceEntity } from "../../../../../models/commission-by-service-entity";
import Select from "../../../../common/Select";
import { CommissionType } from "../../../../../models/enums/commission-type";
import useCurrencyValueTemplate from "../../../../../hooks/useCurrencyValueTemplate";
import SwitchWithLabel from "../../../../common/SwitchWithLabel";
import ConfirmationModal from "../../Commissions/ConfirmationModal";
import { TerminologyType } from "../../../../../models/enums/terminology-type";
import RadioWithContent from "../../../../common/RadioWithContent";
import { TerminologyForm } from "../../../../../models/enums/terminology-form";
import useCustomerTerminology from "../../../../../hooks/useCustomerTerminology";
import useTerminology from "../../../../../hooks/useTerminology";


export default function UserModalCommissions(props: UserModalCommissionsProps) {
  const { t } = useTranslation("settings");
  const classes = useStyles();

  const { userCommissionsInfo, setUserCommissionsInfo, selectedServiceIds, hasServicesInCharge, mode } = props;

  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  const [isUserCommissionTypeChangeConfirmed, setIsUserCommissionTypeChangeConfirmed] = useState<boolean>(false);

  const accountSettings = useAppSelector(selectAccountSettings);
  const percentageTemplate = "{0}%";
  const displayableCommissionsByServices = userCommissionsInfo.commissionsByServices
    .filter(cs => selectedServiceIds.includes(cs.serviceId));
  const getTemplatedCurrencyValue = useCurrencyValueTemplate(accountSettings.currencyTemplate);

  function needConfirmUserCommissionTypeChange(userCommissionType?: UserCommissionType) {
    const type = userCommissionType !== undefined ? userCommissionType : userCommissionsInfo.userCommissionType;
    return userCommissionsInfo.initialUserCommissionType !== type && mode === "edit" && !isUserCommissionTypeChangeConfirmed;
  }

  function getCurrencySign() {
    return accountSettings.currencyTemplate.replace("{0}", "");
  }

  function commissionTypeToDisplayable(type: CommissionType | null) {
    if (type === CommissionType.Absolute) {
      return getCurrencySign();
    }
    else if (type === CommissionType.Percentage) {
      return "%";
    }
    return null;
  }

  function displayableToCommissionType(type: string) {
    if (type === "%") {
      return CommissionType.Percentage;
    }
    return CommissionType.Absolute;
  }

  function getPercentageValueByAbsolute(service: CommissionByServiceEntity): number {
    let newVal = service.commissionValue / service.servicePrice * 100;
    newVal = Math.round((newVal + Number.EPSILON) * 100) / 100;
    return newVal > 100 ? 100 : newVal;
  }

  function getAbsoluteValueByPercentage(service: CommissionByServiceEntity): number {
    const newVal = service.servicePrice * (service.commissionValue / 100);
    return Math.round((newVal + Number.EPSILON) * 100) / 100;
  }

  function getServiceAbsoluteTemplate(service: CommissionByServiceEntity): string {
    return accountSettings.currencyTemplate + ` (${percentageTemplate.replace("{0}", getPercentageValueByAbsolute(service) + "")})`
  }

  function getServicePercentageTemplate(service: CommissionByServiceEntity): string {
    return percentageTemplate + ` (${getTemplatedCurrencyValue(getAbsoluteValueByPercentage(service))})`
  }

  const displayableCommissionTypes: string[] = [
    commissionTypeToDisplayable(CommissionType.Absolute)!,
    commissionTypeToDisplayable(CommissionType.Percentage)!,
  ];

  useEffect(() => {
    if (!hasServicesInCharge){
      setUserCommissionsInfo({ ...userCommissionsInfo, userCommissionType: UserCommissionType.Default });
    }
  }, [hasServicesInCharge])

  const setIsCommissionEnabled = (value: boolean) => {
    setUserCommissionsInfo({ ...userCommissionsInfo, isCommissionEnabled: value });
  }

  const handleUserCommissionTypeCheck = (value: UserCommissionType) => {
    if (needConfirmUserCommissionTypeChange(value)){
      if (value === UserCommissionType.Default) {
        setUserCommissionsInfo({ ...userCommissionsInfo, commissionType: null, commissionValue: null, userCommissionType: value });
      }
      else if (value === UserCommissionType.ByService) {
        userCommissionsInfo.commissionsByServices.forEach(service => {
          service.commissionValue = userCommissionsInfo.commissionValue!;
          service.commissionType = userCommissionsInfo.commissionType!;
        });
        setUserCommissionsInfo({ ...userCommissionsInfo, commissionsByServices: userCommissionsInfo.commissionsByServices, userCommissionType: value });
      }
    }
    else {
      setUserCommissionsInfo({ ...userCommissionsInfo, userCommissionType: value });
    }
  }

  const setServiceCommissionValue = (service: CommissionByServiceEntity, value: number) => {
    service.commissionValue = value;
    setUserCommissionsInfo({ ...userCommissionsInfo, commissionsByServices: userCommissionsInfo.commissionsByServices });
    if (needConfirmUserCommissionTypeChange() && userCommissionsInfo.commissionValue !== value) {
      setConfirmationModalOpen(true);
    }
  }

  const setServiceCommissionType = (service: CommissionByServiceEntity, type: string) => {
    service.commissionType = displayableToCommissionType(type);
    if (service.commissionType === CommissionType.Percentage) {
      service.commissionValue = getPercentageValueByAbsolute(service);
    }
    else if (service.commissionType === CommissionType.Absolute) {
      service.commissionValue = getAbsoluteValueByPercentage(service);
    }
    setUserCommissionsInfo({ ...userCommissionsInfo, commissionsByServices: userCommissionsInfo.commissionsByServices });
    if (needConfirmUserCommissionTypeChange() && userCommissionsInfo.commissionType !== service.commissionType) {
      setConfirmationModalOpen(true);
    }
  }

  const setCommissionValue = (value: number) => {
    setUserCommissionsInfo({ ...userCommissionsInfo, commissionValue: value });
    if (needConfirmUserCommissionTypeChange()) {
      setConfirmationModalOpen(true);
    }
  }

  const setCommissionType = (displayableType: string) => {
    const type = displayableToCommissionType(displayableType);
    if (type === CommissionType.Percentage) {
      let value: number | null = null;
      if (userCommissionsInfo.commissionValue && userCommissionsInfo.commissionValue > 100) {
        value = 100;
      }
      if (value !== null) {
        setUserCommissionsInfo({ ...userCommissionsInfo, commissionType: type, commissionValue: value });
        return;
      }
    }
    setUserCommissionsInfo({ ...userCommissionsInfo, commissionType: type });
    if (needConfirmUserCommissionTypeChange()) {
      setConfirmationModalOpen(true);
    }
  }

  const confirmUserCommissionTypeChange = () => {
    setIsUserCommissionTypeChangeConfirmed(true);
    setConfirmationModalOpen(false);
  }

  const rejectUserCommissionTypeChange = () => {
    if (userCommissionsInfo.userCommissionType === UserCommissionType.Default) {
      setUserCommissionsInfo({ ...userCommissionsInfo, userCommissionType: UserCommissionType.ByService });
    }
    else if (userCommissionsInfo.userCommissionType === UserCommissionType.ByService) {
      setUserCommissionsInfo({ ...userCommissionsInfo, userCommissionType: UserCommissionType.Default });
    }
    setConfirmationModalOpen(false);
  }
  
  const getTerminology = (form: TerminologyForm): string => {
    const terminology = accountSettings.terminologies.find(t => t.terminologyType === TerminologyType.Commission);
    return (form === TerminologyForm.Plural ? terminology?.pluralForm ?? t('commissions') : terminology?.singularForm ?? t('commission'))!.toLowerCase();
  }
  
  const userWithCommission = useTerminology({ type: TerminologyType.Commission, template: t("User with commission"), templateTermToLowercase: true });
  const userWillBeAbleToReceiveCommissions = useTerminology({ type: TerminologyType.Commission, form: TerminologyForm.Plural, template: t("This user will be able to receive commissions for the services performed"), templateTermToLowercase: true });
  const commissionByService = useTerminology({ type: TerminologyType.Commission, template: t("Commission by service") });
  const defaultCommission = useTerminology({ type: TerminologyType.Commission, template: t("Default commission") });
  const userWithoutServicesInCharge = useTerminology({ type: TerminologyType.Commission, form: TerminologyForm.Plural, template: t("A user without services in charge may receive commissions for all services performed. It can be an amount or a percentage."), templateTermToLowercase: true });
  
  return (<>
    <div className={classes.switchBox}>
      <SwitchWithLabel
        value={userCommissionsInfo.isCommissionEnabled}
        setValue={setIsCommissionEnabled}
        label={userWithCommission}
      />
      <IconTooltip
        icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 4 }} viewBox={"0 0 12 12"} />}
        tooltipText={userWillBeAbleToReceiveCommissions}
        placement={"right"}
        arrowPlacement={"left"}
        classNameRoot={classes.tooltip}
      />
    </div>

    <Divider className={classes.divider} />
    {userCommissionsInfo.isCommissionEnabled &&
      <>
        <div className={classes.radios}>
          <div className={classes.radio}>
            <RadioWithContent
              value={UserCommissionType.ByService}
              checked={userCommissionsInfo.userCommissionType === UserCommissionType.ByService}
              onCheck={() => handleUserCommissionTypeCheck(UserCommissionType.ByService)}
              content={commissionByService}
              contentClass={classes.radioContent}
              disabled={!hasServicesInCharge}
            />
            <IconTooltip
              icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 4 }} viewBox={"0 0 12 12"} />}
              tooltipText={hasServicesInCharge
                ? t("Can be different for each service, based on a fixed amount or a percentage.")
                : t("It is not possible to select this option because the user is not in charge of any services.")}
              placement={"right"}
              arrowPlacement={"left"}
            />
          </div>
          <div className={classes.radio}>
            <RadioWithContent
              value={UserCommissionType.Default}
              checked={userCommissionsInfo.userCommissionType === UserCommissionType.Default}
              onCheck={() => handleUserCommissionTypeCheck(UserCommissionType.Default)}
              content={defaultCommission}
              contentClass={classes.radioContent}
            />
            <IconTooltip
              icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 4 }} viewBox={"0 0 12 12"} />}
              tooltipText={hasServicesInCharge
                ? t("Fixed amount or percentage which applies equally to all services performed.")
                : userWithoutServicesInCharge}
              placement={"right"}
              arrowPlacement={"left"}
            />
          </div>
        </div>
        {userCommissionsInfo.userCommissionType === UserCommissionType.ByService &&
          <>
            <div className={classes.commissionsByServices}>
              {displayableCommissionsByServices.map((service) => {
                return <div key={service.serviceId} className={classes.commissionByServiceItem}>
                  <div className={classes.serviceName}>{service.serviceName} {getTemplatedCurrencyValue(service.servicePrice)}</div>
                  <div className={classes.commissionByServiceValues}>
                    <InputCurrency
                      value={service.commissionValue}
                      setValue={(value: number) => setServiceCommissionValue(service, value)}
                      valueTemplate={service.commissionType === CommissionType.Absolute
                        ? getServiceAbsoluteTemplate(service)
                        : getServicePercentageTemplate(service)}
                      isZeroAllowed={true}
                      isValid={() => {
                        return true;
                      }}
                      maxValue={service.commissionType === CommissionType.Percentage ? 100 : undefined}
                    />
                    <Select
                      key={service.serviceId}
                      width={100}
                      value={commissionTypeToDisplayable(service.commissionType)}
                      onChange={(event) => setServiceCommissionType(service, event.target.value)}
                      colorArrowIcon={"#5C6477"}
                      items={displayableCommissionTypes}/>
                  </div>
                </div >
              })}
            </div>
          </>
        }
        {userCommissionsInfo.userCommissionType === UserCommissionType.Default &&
          <>
            {hasServicesInCharge &&
              <>
                <div className={classes.contentHeader}>{t("Services in charge")}:</div>
                <div className={classes.defaultServices}>
                  {displayableCommissionsByServices.map((service) => {
                    return <div key={service.serviceId} className={classes.defaultServiceItem}>
                      {service.serviceName}
                    </div >
                  })}
                </div>
              </>
            }
            <div className={classes.contentHeader}>
              {defaultCommission}
              <span className={classes.dot}>*</span>
            </div>
            <div className={classes.defaultCommissionValues}>
              <InputCurrency
                value={userCommissionsInfo.commissionValue}
                setValue={(value: number) => setCommissionValue(value)}
                valueTemplate={userCommissionsInfo.commissionType === CommissionType.Absolute
                  ? accountSettings.currencyTemplate
                  : userCommissionsInfo.commissionType === CommissionType.Percentage ? percentageTemplate : "{0}"}
                placeholder={"--"}
                isZeroAllowed={true}
                isValid={() => {
                  return true;
                }}
                maxValue={userCommissionsInfo.commissionType === CommissionType.Percentage ? 100 : undefined}
              />
              <Select
                key={""}
                width={100}
                value={commissionTypeToDisplayable(userCommissionsInfo.commissionType)}
                placeholder={"--"}
                onChange={(event) => setCommissionType(event.target.value)}
                colorArrowIcon={"#5C6477"}
                items={displayableCommissionTypes}/>
            </div>
          </>
        }
      </>
    }
    <ConfirmationModal
      open={isConfirmationModalOpen}
      title={t("Do you want to continue?")}
      body={
        <>
          <div style={{display: userCommissionsInfo.userCommissionType === UserCommissionType.ByService ? "none" : ""}}>
            {useCustomerTerminology(t("If you modify this field, this user can no longer have different commissions for each service and instead will have a default commission for all services."), getTerminology(TerminologyForm.Singular), getTerminology(TerminologyForm.Plural))}
          </div>
          <div style={{display: userCommissionsInfo.userCommissionType === UserCommissionType.Default ? "none" : ""}}>
            {useCustomerTerminology(t("If you modify this field, this user will no longer have a default commission for all services and instead can have different commissions for each service."), getTerminology(TerminologyForm.Singular), getTerminology(TerminologyForm.Plural))}
          </div>
        </>
      }
      okTextButton={t("Yes, continue")}
      cancelTextButton={t("Go back")}
      onConfirm={confirmUserCommissionTypeChange}
      onCancel={rejectUserCommissionTypeChange}
      onClose={confirmUserCommissionTypeChange}
    />
  </>);
}