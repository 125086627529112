import { createSlice } from "@reduxjs/toolkit";
import {
    HubConnectionBuilder,
    LogLevel,
    IHttpConnectionOptions,
    HttpTransportType,
    HubConnection,
    HubConnectionState
} from '@microsoft/signalr';
import { LocalStorageKey } from "../constants/local-storage-key";
import { Url } from "../constants/url";

const startSignalRConnection = async (connection: HubConnection) => {
    try {
        await connection.start();
    } catch (err) {
        setTimeout(() => startSignalRConnection(connection), 5000);
    }
};

const initializeConnection = () => {
    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken)    

    if (accessToken === null){
        return;
    }

    const connectionOptions: IHttpConnectionOptions = {
        accessTokenFactory: () => {
            return accessToken;
        },
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
    }

    const connection = new HubConnectionBuilder()
        .withUrl(process.env.REACT_APP_SERVER_URL + 
            Url.SignalRHubs.CommonHub, connectionOptions)
        .withAutomaticReconnect()
        .configureLogging(LogLevel.Information)
        .build();

    connection.serverTimeoutInMilliseconds = 60000;

    startSignalRConnection(connection);
    return connection;
}

const initialState = {
    connection: undefined as undefined | HubConnection
};

export const commonHubSlice = createSlice({
    name: "commonHub",
    initialState: initialState,
    reducers: {
        connect: (state) => {
            if(state.connection === undefined || state.connection.state == HubConnectionState.Disconnected){
                state.connection = initializeConnection();
            }
        },
        disconnect: (state) => {
            if(state.connection?.state == HubConnectionState.Connected){
                state.connection.stop();
            }
        }
    }
});

export const { connect, disconnect } = commonHubSlice.actions;

export default commonHubSlice.reducer;