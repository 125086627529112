import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useDebounce from "../../../../../hooks/useDebounce";
import useStyles from "./css";
import { useAppDispatch } from "../../../../../redux/hooks";
import { alertsActions } from "../../../../../redux/alerts-slice";

import Typography from "@material-ui/core/Typography";

import { UserService } from "../../../../../api/user-service";
import { JobPositionService } from "../../../../../api/job-position-service";
import { Url } from "../../../../../constants/url";

import { UserModalGeneralInfoProps } from "./props";
import SwitchWithLabelAndTooltip from "../../../../common/SwitchWithLabelAndTooltip";
import InputWithLabel from "../../../../common/InputWithLabel";
import { JobPosition } from "../../../../../models/job-position";
import Country from "../../../../../models/country";
import JobPositionSelect, { SelectItem } from "../JobPositionSelect";
import JobPositionEditModal from "../JobPositionEditModal";
import JobPositionDeleteModal from "../JobPositionDeleteModal";
import InputMobileCode from "../../../../common/InputMobileCode";
import UserModalHasUpcomingAppointment from "../UserModalHasUpcomingAppointment";
import useTerminology from "../../../../../hooks/useTerminology";
import { TerminologyType } from "../../../../../models/enums/terminology-type";
import { TerminologyForm } from "../../../../../models/enums/terminology-form";



export default function UserModalGeneralInfo(props: UserModalGeneralInfoProps) {

  const { settingsSelectedEstablishmentId } = props;
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;
  const { t } = useTranslation("settings");
  const classes = useStyles();
  const treatmentTerm = useTerminology({ type: TerminologyType.Treatment, form: TerminologyForm.Singular });


  const [jobPositionEditModalOpen, setJobPositionEditModalOpen] = useState<boolean>(false);
  const [jobPositionEditItem, setJobPositionEditItem] = useState<JobPosition>(new JobPosition());
  const [jobPositionDeleteModalOpen, setJobPositionDeleteModalOpen] = useState<boolean>(false);
  const [jobPositionDeleteItem, setJobPositionDeleteItem] = useState<JobPosition>(new JobPosition());
  const [hasUpcomingAppointmentModalIsOpen, setHasUpcomingAppointmentModalIsOpen] = useState<boolean>(false);
  const [jobPositions, setJobPositions] = useState<JobPosition[]>([])

  const { userServices, userGeneralInfo, setUserGeneralInfo, isRefresh, setRefresh, setPhoneNumberIsValid, setEmailIsValid, emailIsValid } = props;
  const [phoneFormatIsValid, setPhoneFormatIsValid] = useState<boolean>(userGeneralInfo.mobileNumber ? true : false);

  const debouncedPhoneValue = useDebounce({ iso3: userGeneralInfo.mobileCodeIso3, phoneNumber: userGeneralInfo.mobileNumber }, 1000);

  const debouncedEmailValue = useDebounce({ email: userGeneralInfo.email }, 1000);

  useEffect(() => {
    async function getJobPositions() {
      const jobPositions = await JobPositionService.getJobPositionList(settingsSelectedEstablishmentId)
      setJobPositions(jobPositions ?? []);
    }

    getJobPositions()
  }, [isRefresh])

  useEffect(() => {
    (async () => {
      const isUserEmailDuplicatedResponse = await UserService.getIsUserEmailDuplicated(debouncedEmailValue.email, settingsSelectedEstablishmentId);
      if (isUserEmailDuplicatedResponse.isDuplicated && userGeneralInfo.id !== isUserEmailDuplicatedResponse.userId) {
        dispatch(
          enqueueAlert({
            type: "Error",
            title: t("Invalid email"),
            description: t("This email already belongs to user"),
            link: {
              onClick: (e: any) => {
                e.preventDefault();
                const location = `${Url.Settings.Users}/${isUserEmailDuplicatedResponse.userId}`;
                history.push(location);
                history.replace(location);
                history.go(0);
              },
              title: `${isUserEmailDuplicatedResponse.userFullName}`,
            }
          })
        );
        setEmailIsValid(false);
      }
      else {
        setEmailIsValid(true);
      }
    })();
  }, [debouncedEmailValue.email])

  useEffect(() => {
    (async () => {
      if (!phoneFormatIsValid) return;
      const isUserPhoneDuplicatedResponse = await UserService.getIsPhoneDuplicated(debouncedPhoneValue.iso3, debouncedPhoneValue.phoneNumber, settingsSelectedEstablishmentId);
      if (isUserPhoneDuplicatedResponse.isDuplicated && userGeneralInfo.id !== isUserPhoneDuplicatedResponse.userId) {
        dispatch(
          enqueueAlert({
            type: "Error",
            title: t("Invalid number"),
            description: t("This number already belongs to user"),
            link: {
              onClick: (e: any) => {
                e.preventDefault();
                const location = `${Url.Settings.Users}/${isUserPhoneDuplicatedResponse.userId}`;
                history.push(location);
                history.replace(location);
                history.go(0);
              },
              title: `${isUserPhoneDuplicatedResponse.userFirstName} ${isUserPhoneDuplicatedResponse.userLastName}`,
            }
          })
        );
        setPhoneNumberIsValid(false);
      }
      else
        setPhoneNumberIsValid(true);
    })();
  }, [debouncedPhoneValue.iso3, debouncedPhoneValue.phoneNumber])

  const setHasServicesInCharge = async (value: boolean) => {
    if (value || !userGeneralInfo.id) {
      setUserGeneralInfo({ ...userGeneralInfo, hasServicesInCharge: value });
    } 
    else 
    {
      let disabledServiceIds = userServices.serviceCategories
      .flatMap(category => (userGeneralInfo.hasServicesInCharge
        ? category.services.filter(service => !service.value)
        : category.services))
      .map(service => service.id);

      let userAppointmentCount = await UserService.getAllUserAppointmentCount(userGeneralInfo.id, disabledServiceIds, settingsSelectedEstablishmentId);
      if (userAppointmentCount > 0) {
        setHasUpcomingAppointmentModalIsOpen(true);
      }
      else {
        setUserGeneralInfo({ ...userGeneralInfo, hasServicesInCharge: value });
      }
    }
  }

  const setCanPrescribeTreatment = (value: boolean) => {
    setUserGeneralInfo({ ...userGeneralInfo, canPrescribeTreatment: value });
  }

  const handleSaveClickUserHasUpcomingAppointmentModal = () => {
    setUserGeneralInfo({ ...userGeneralInfo, hasServicesInCharge: false });
  }

  const setJobPosition = (value: SelectItem | null) => {
    setUserGeneralInfo({ ...userGeneralInfo, jobPosition: value ? new JobPosition(value) : null });
  }

  const setName = (value: string) => {
    setUserGeneralInfo({ ...userGeneralInfo, name: value });
  }

  const setLastNames = (value: string) => {
    setUserGeneralInfo({ ...userGeneralInfo, lastName: value });
  }

  const setEmail = (value: string) => {
    setUserGeneralInfo({ ...userGeneralInfo, email: value });
  }

  const nameChangeHandler = (event: any) => {
    setName(event.target.value)
  }

  const lastNameChangeHandler = (event: any) => {
    setLastNames(event.target.value)
  }

  const emailChangeHandler = (event: any) => {
    setEmail(event.target.value)
  }

  const handleMobileCodeChange = (c: Country) => {
    setUserGeneralInfo({
      ...userGeneralInfo,
      mobileCodeIso3: c.iso3,

    });
  };

  const handleMobilePhoneChange = (p: string) => {
    setUserGeneralInfo({
      ...userGeneralInfo,
      mobileNumber: p
    });
  };

  const handleJobOnCreate = async (jobPositionName: string) => {
    const newJob = await JobPositionService.createJobPosition(jobPositionName, settingsSelectedEstablishmentId);
    setRefresh(newJob);
  }

  const handleJobEdit = (selectedJob: any) => {
    setJobPositionEditItem(new JobPosition(selectedJob));
    setJobPositionEditModalOpen(true);
  }

  const handleJobOnEdit = async (updatedJob: JobPosition) => {
      const editedJob = await JobPositionService.updateJobPosition(updatedJob, settingsSelectedEstablishmentId);
    setRefresh(editedJob);
  }

  const handleJobDelete = (selectedJob: any) => {
    setJobPositionDeleteItem(new JobPosition(selectedJob));
    setJobPositionDeleteModalOpen(true);
  }

  const handleJobOnDelete = async (job: JobPosition) => {
    if (!job?.id) return;
    const response = await JobPositionService.deleteJobPosition(job.id, settingsSelectedEstablishmentId);
    setRefresh(response);
  }

  const validatePhoneNumber = (innerValidationResult: boolean) => {
    if (innerValidationResult && userGeneralInfo.mobileNumber) {
      setPhoneFormatIsValid(true);
    }
    else {
      setPhoneNumberIsValid(false);
      setPhoneFormatIsValid(false);
    }
  }

  const LabelWithDot = (props: any) => {
    return (
      <span>
        {props.label} <span className={classes.dot}>*</span>
      </span>
    );
  }

  return (
    <div className={classes.root}>
      <JobPositionEditModal
        open={jobPositionEditModalOpen}
        setOpen={setJobPositionEditModalOpen}
        item={jobPositionEditItem}
        setItem={setJobPositionEditItem}
        onEdit={handleJobOnEdit}
      />
      <JobPositionDeleteModal
        open={jobPositionDeleteModalOpen}
        setOpen={setJobPositionDeleteModalOpen}
        item={jobPositionDeleteItem}
        onDelete={handleJobOnDelete}
      />
      <span className={classes.requiredFieldsNote}><LabelWithDot label={t("Required fields")} /></span>
      <div className={classes.switchBox}>
        <SwitchWithLabelAndTooltip
          value={userGeneralInfo.hasServicesInCharge}
          setValue={setHasServicesInCharge}
          label={t("User with services in charge")}
          inactiveLabel={t("User without services in charge")}
        />
        <SwitchWithLabelAndTooltip
          value={userGeneralInfo.canPrescribeTreatment}
          setValue={setCanPrescribeTreatment}
          label={`${t("User prescribes")} ${treatmentTerm.toLowerCase()}`}
          inactiveLabel={`${t("User prescribes")} ${treatmentTerm.toLowerCase()}`}
        />
      </div>
      <div className={classes.inputRow}>
        <div className={classes.inputContainer}>
          <InputWithLabel
            label={() => (<>
              <Typography className={classes.label}>
                {t("Name")}
              </Typography>
              <span className={classes.dot}>*</span>
            </>)}
            placeholder={t("Enter the name of the user")}
            value={userGeneralInfo.name || ""}
            onChange={nameChangeHandler}
            width={344}
          />
        </div>
        <div className={classes.inputContainer}>
          <InputWithLabel
            label={() => (<>
              <Typography className={classes.label}>
                {t("Last names")}
              </Typography>
              <span className={classes.dot}>*</span>
            </>)}
            placeholder={t("Enter the last name of the user")}
            value={userGeneralInfo.lastName || ""}
            onChange={lastNameChangeHandler}
            width={344}
          />
        </div>
      </div>

      <div className={classes.inputRow}>
        <div className={classes.inputContainer}>
          <InputWithLabel
            label={() => (<>
              <Typography className={classes.label}>
                {t("Email")}
              </Typography>
              <span className={classes.dot}>*</span>
            </>)}
            placeholder={t("Enter the email of the user")}
            value={userGeneralInfo.email || ""}
            onChange={emailChangeHandler}
            isValid={() => emailIsValid}
            width={344}
          />
        </div>
        <div className={classes.inputContainer}>
          <InputMobileCode
            isOptional={false}
            widthSelect={344}
            widthList={344}
            labelClass={classes.phoneLabel}
            requiredDotClass={classes.dot}
            isValid={validatePhoneNumber}
            onChangeItem={handleMobileCodeChange}
            onChangeMobile={handleMobilePhoneChange}
            codeIso3={userGeneralInfo.mobileCodeIso3 ?? "MEX"}
            mobile={userGeneralInfo.mobileNumber ?? ""} />
        </div>
      </div>
      <div className={classes.inputRow}>
        <div className={classes.inputContainer}>
          <JobPositionSelect
            label={() => (<>
              <Typography className={classes.label}>
                {t("Job position")}
              </Typography>
              <span className={classes.dot}>*</span>
            </>)}
            placeholder={t("Select the job title")}
            items={jobPositions}
            itemState={userGeneralInfo.jobPosition}
            setItemState={setJobPosition}
            edit={handleJobEdit}
            remove={handleJobDelete}
            onCreate={handleJobOnCreate}
            width={344}
          />
        </div>
      </div>
      <span className={classes.note}>{t("NOTE: The password will be defined by the user of your team.")}</span>
      <UserModalHasUpcomingAppointment
        open={hasUpcomingAppointmentModalIsOpen}
        onCancelClick={() => setHasUpcomingAppointmentModalIsOpen(false)}
        onSaveClick={handleSaveClickUserHasUpcomingAppointmentModal}
      />
    </div>
  );
}