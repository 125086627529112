import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";

import { TimePicker } from "antd";
import moment from "moment";

import SelectWithLabel, { SelectItem } from "./../../common/SelectWithLabel";
import ServicesService from "./../../../api/settings-services-service";
import { UserService } from "../../../api/user-service";
import WorkareasService from "../../../api/settings-workareas-service";
import { UserModel } from "../../../models/user-model";
import { SelectWorkAreaModel } from "../../../models/select-work-area-model";
import { SelectGroupServiceModel } from "../../../models/select-group-service-model";
import { CreateAppointmentService } from "../../../models/create-appointment-service";
import SelectSearch from "../../common/SelectSearch";

import { AppointmentServiceProps } from "./props";
import useStyles from "./css";
import { CurrencyRegex } from "../../../constants/validator";
import Typography from "@material-ui/core/Typography";
import InputCurrency from "../../common/InputCurrency";
import { useAppSelector } from "../../../redux/hooks";
import { selectAccountSettings } from "../../../redux/store";
import { ArrowDropDownIcon } from "../../../assets/icons";
import Input from "../../common/Input";
import InputWithLabel from "../../common/InputWithLabel";

export default function AppointmentService(props: AppointmentServiceProps) {

  const { appointmentService, desiredUserId, onChange, disabled } = props;

  const classes = useStyles();
  const { t } = useTranslation(["general"]);

  const [services, setServices] = useState<SelectItem[]>([]);
  const [servicesArr, setServicesArr] = useState<SelectGroupServiceModel[]>([]);
  const [usersArr, setUsersArr] = useState<UserModel[]>([]);
  const [users, setUsers] = useState<SelectItem[]>([]);
  const [workAreasArr, setWorkAreasArr] = useState<SelectWorkAreaModel[]>([]);
  const [workAreas, setWorkAreas] = useState<SelectItem[]>([]);
  const [estimatedDuration, setEstimatedDuration] = useState<moment.Moment>(moment().utcOffset(0).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }));
  const [timePickerIsOpen, setTimePickerIsOpen] = useState<boolean>(false);
  const format = "HH:mm";

  const accountSettings = useAppSelector(selectAccountSettings);
  const currencyTemplate: string = accountSettings.currencyTemplate;
  const userInfo = useAppSelector(state => state.userInfo);

  function setAppointmentService(appointmentService: CreateAppointmentService) {
    onChange(appointmentService)
  }

  useEffect(() => {
    async function fetchData() {
      await loadServices();
      await loadWorkAreas();
    }

    fetchData();
  }, []);

  useEffect(() => {
    setEstimatedDuration(moment().utcOffset(0).set({ hour: 0, minute: appointmentService?.estimatedDuration ?? appointmentService?.duration ?? 0, second: 0, millisecond: 0 }))
  }, [appointmentService?.serviceId])

  useEffect(() => {
    async function fetchData() {
      await loadUsers();
    }

    if (appointmentService.serviceId) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentService.serviceId]);

  useEffect(() => {
    if (!userInfo.hasAccessToScheduleAllSpecialists){
      const user = usersArr.find((svc) => svc.authenticationId === userInfo.userId);
      if (user) {
        setUserByValue(user);
      }
    } else if (desiredUserId) {
      const desiredUser = usersArr.find((svc) => svc.id === desiredUserId);
      if (desiredUser) {
        setUserByValue(desiredUser);
      }
    }
  }, [usersArr]);

  const loadServices = async () => {
    const services = await ServicesService.getServicesSelect();
    setServicesArr(services);

    const arrServices: SelectItem[] = [];
    let x = 0;
    for (let category of services) {
      arrServices.push({
        key: x,
        value: `${category.category} (${category.services?.length})`,
        isGroup: true,
      });
      if (category.services) {

        arrServices.push(
          ...category.services.map((service) => ({
            key: service.id,
            value: `${service.name} (${service.duration} min)`,
          }))
        );
      }
      x++;
    }

    setServices(arrServices);
  };

  const getTimePickerDurationPostfixClass = () => {
    const postfix = estimatedDuration.hours() > 1 ? "hrs" : (estimatedDuration.hours() === 1 ? "hr" : "min");
    switch (postfix) {
      case "hrs":
        return classes.selectHrs;
      case "hr":
        return classes.selectHr;
      case "min":
        return classes.selectMin;
    }
  }

  const handleOpenTimepicker = (isOpen: boolean) => {
    setTimePickerIsOpen(isOpen);
  };

  const loadUsers = async () => {
    let users = await UserService.getUsersByServiceId(appointmentService.serviceId!);
    if (!users.some(u => u.id == appointmentService.userId)) {
      setUserByValue(undefined);
    }
    setUsersArr(users);
    setUsers(
      users.map((user) => {
        return { key: user.id, value: `${user.firstName} ${user.lastName}` };
      })
    );
  };

  const loadWorkAreas = async () => {
    const workAreas = await WorkareasService.getWorkAreasSelect();
    setWorkAreasArr(workAreas);
    setWorkAreas(
      workAreas.map((workArea) => {
        return { key: workArea.id, value: workArea.workArea };
      })
    );
  };

  const handleDurationTimeChange = (time: moment.Moment) => {

    const minutes = (time.hour() * 60) + time.minute();

    setEstimatedDuration(time);

    setAppointmentService(
      new CreateAppointmentService({
        ...appointmentService,
        estimatedDuration: minutes
      })
    );

    setTimePickerIsOpen(false);
  };

  const handleServiceChange = (event: any) => {
    const service = event.key;//event.target.value;
    const category = servicesArr.find((cat) => cat.services?.findIndex((svc) => svc.id === service) !== -1);
    if (category && category.services) {
      const selectedService = category.services.find((svc) => svc.id === service);
      if (selectedService && selectedService.workAreaId) {
        setAppointmentService(
          new CreateAppointmentService({
            ...appointmentService,
            serviceId: service,
            name: selectedService.name || "",
            duration: selectedService.duration,
            estimatedDuration: selectedService.duration,
            workAreaId: selectedService.workAreaId,
            workArea: selectedService.workArea || "",
            price: selectedService.price,
            userId: null,
            user: null,
          })
        );
      } else {
        setAppointmentService(
          new CreateAppointmentService({
            ...appointmentService,
            serviceId: service,
            name: selectedService?.name || "",
            duration: selectedService?.duration,
            estimatedDuration: selectedService?.duration,
            price: selectedService?.price,
            userId: null,
            user: null,
            workAreaId: null,
            workArea: "",
          })
        );
      }
    }
  };

  const handleUserChange = (event: any) => {
    const user = usersArr.find((svc) => svc.id === event.key);
    setUserByValue(user);
  };

  const setUserByValue = (user: UserModel | undefined) => {
    setAppointmentService(
      new CreateAppointmentService({
        ...appointmentService,
        userId: user?.id,
        user: `${user?.firstName} ${user?.lastName}`,
      })
    );
  };

  const handleWorkAreaChange = (event: any) => {
    const workArea = workAreasArr.find((svc) => svc.id === event.target.value);
    setAppointmentService(
      new CreateAppointmentService({
        ...appointmentService,
        workAreaId: event.target.value,
        workArea: workArea?.workArea || "",
      })
    );
  };

  const handlePriceChange = (price: number) => {
    setAppointmentService(
      new CreateAppointmentService({
        ...appointmentService,
        price: price
      })
    );
  };

  return (
  <>
    <div className={classes.column}>
      <div className={classes.form}>
        <div className={classes.formItem}>
          <SelectSearch
            label={t("Service")}
            items={services}
            itemId={appointmentService.serviceId}
            placeholderSelect={t("Select a service")}
            placeholderInput={t("Search one of our services")}
            widthSelect={320}
            widthList={320}
            isOptional={false}
            onSelect={handleServiceChange}
            disabled={disabled}
            disableScrollLock={true}
          />
        </div>
        {userInfo.showPrices && (
          <div className={classes.formItem}>
            <Typography className={classes.controlLabel}>
              {t("Price")} <span className={classes.dot}>*</span>
            </Typography>
            <InputCurrency
              value={appointmentService.price}
              setValue={handlePriceChange}
              valueTemplate={currencyTemplate}
              disabled={disabled || !appointmentService.serviceId}
              isZeroAllowed={true}
              isValid={() =>
                disabled ||
                !appointmentService.serviceId ||
                (appointmentService.price > 0 &&
                  CurrencyRegex.test(String(appointmentService.price)))
              }
              width={320}
            />
          </div>
        )}
        <div className={classes.formItem}>
          <div style={{ position: "relative" }}>
            <Typography className={classes.controlLabel}>
              {t("Estimated duration")} <span className={classes.dot}>*</span>
            </Typography>
            <TimePicker
              onOk={(time) => {
                handleDurationTimeChange(time);
              }}
              className={`${
                classes.selectStyle
              } ${getTimePickerDurationPostfixClass()}`}
              placeholder="HH:mm"
              inputReadOnly={true}
              showNow={false}
              value={estimatedDuration}
              disabled={disabled || !appointmentService.serviceId}
              disabledHours={() => [21, 22, 23]}
              onOpenChange={(e) => handleOpenTimepicker(e)}
              format={format}
            />
            <ArrowDropDownIcon
              style={{
                top: "60%",
                right: 15,
                pointerEvents: "none",
                color: "#5C6477",
                position: "absolute",
                transform: timePickerIsOpen ? "rotate(180deg)" : "rotate(0deg)",
              }}
            />
          </div>
        </div>
        {userInfo.hasAccessToScheduleAllSpecialists && (
          <div className={classes.formItem}>
            <SelectSearch
              label={t("appointment_step_one_Specialist")}
              items={users}
              itemId={appointmentService.userId}
              placeholderSelect={t("Select a specialist")}
              placeholderInput={t("Search specialist")}
              widthSelect={320}
              widthList={320}
              isOptional={false}
              onSelect={handleUserChange}
              disabled={disabled || !appointmentService.serviceId}
              disableScrollLock={true}
            />
          </div>
        )}
        {!userInfo.hasAccessToScheduleAllSpecialists && (
          <div className={classes.formItem}>
            <InputWithLabel
              label={t("appointment_step_one_Specialist")}
              value={userInfo.fullName}
              inputClass={classes.input}
              inputDisabled={true}
              inputReadOnly={true}
            />
          </div>
        )}
        <div className={classes.formItem}>
          <SelectWithLabel
            label={() => (
              <>
                {t("Work area")}{" "}
                <span className={classes.optional}>({t("Optional")})</span>
              </>
            )}
            itemRootClass={classes.itemRootClass}
            placeholder={t("Select a work area")}
            width="320px"
            items={workAreas}
            value={appointmentService.workAreaId}
            onChange={handleWorkAreaChange}
            disabled={disabled || !appointmentService.serviceId}
            disableScrollLock={true}
          />
        </div>
      </div>
    </div>
  </>
  );
}