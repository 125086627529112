import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ExportIcon = (props: any) => {
  const { style, viewBox, color, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={style || { width: 14, height: 13 }}
      viewBox={viewBox || "0 0 14 13"}
    >
      <path d="M13.5 1.0625C13.5 0.757812 13.2422 0.5 12.9375 0.5H9.9375C9.42188 0.5 9.16406 1.10938 9.53906 1.46094L10.3594 2.30469L4.66406 8.02344C4.42969 8.23438 4.42969 8.58594 4.66406 8.82031L5.17969 9.33594C5.41406 9.57031 5.76562 9.57031 5.97656 9.33594L11.6953 3.64062L12.5391 4.46094C12.8906 4.8125 13.5 4.57812 13.5 4.0625V1.0625ZM9.53906 6.85156L9.16406 7.22656C9.07031 7.32031 9 7.50781 9 7.625V11H1.5V3.5H7.6875C7.80469 3.5 7.99219 3.42969 8.0625 3.33594L8.4375 2.96094C8.8125 2.60938 8.55469 2 8.0625 2H1.125C0.492188 2 0 2.51562 0 3.125V11.375C0 12.0078 0.492188 12.5 1.125 12.5H9.375C9.98438 12.5 10.5 12.0078 10.5 11.375V7.25C10.5 6.75781 9.89062 6.5 9.53906 6.85156Z" 
            fill={color ?? "#C9CDD3"}
      />
    </SvgIcon>
  );
};

export default ExportIcon;
