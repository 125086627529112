import { createSlice } from "@reduxjs/toolkit";
import { CashRegisterStateModel } from "../models/interfaces/cash-register-state-model";
import { CashRegisterCurrentStateModel } from "../models/interfaces/cash-register-current-state-model";
import { CashRegisterMovementHistory } from "../models/cash-register-movement-history";


const initialState: CashRegisterStateModel = {
  isOpen: false,
  state: new CashRegisterCurrentStateModel(),
  history: new CashRegisterMovementHistory()
};


export const cashRegisterSlice = createSlice({
  name: "cashRegisterState",
  initialState: initialState,
  reducers: {
    setCashRegisterFullState: (currentState, action) => {
      return action.payload;
    },
    setIsOpen: (currentState, action) => {
      currentState = {...currentState, isOpen: action.payload };
    },
    setCashRegisterCurrentState: (currentState, action) => {
      currentState = {...currentState, state: action.payload };
    },
  }
})

export const { setCashRegisterFullState, setIsOpen, setCashRegisterCurrentState } = cashRegisterSlice.actions;
export default cashRegisterSlice.reducer;