import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import { Backdrop, Button, Fade, Modal, Typography } from "@material-ui/core";
import useStyles from "./css";
import Tooltip from "@material-ui/core/Tooltip";
import ConfirmActionModal from "../../common/ConfirmActionModal";
import Input from "../../common/Input";
import Select from "../../common/Select";

import { CustomRecurrenceModalProps } from "./props";
import { SelectItem } from "../../common/SelectWithLabel";
import { CustomRecurrenceMode } from "../../../models/enums/custom-recurrence-mode";
import { RecurrenceModalData } from "../../../models/recurrence-modal-data";
import CircleCheckbox from "../../common/CircleCheckbox";
import { WeekDaysFlags } from "../../../models/enums/week-days-flags";


export default function CustomRecurrenceModal(props: CustomRecurrenceModalProps) {
  
  const { isOpen, handleClose, handleConfirm } = props;
  
  const classes = useStyles();
  const { t } = useTranslation("general");
  
  const [recurrenceInterval, setRecurrenceInterval] = useState<number>(1);
  const [recurrenceItemId, setRecurrenceItemId] = useState<CustomRecurrenceMode | null>(null);
  const [recurrenceAfterAppointments, setRecurrenceAfterAppointments] = useState<number | null>(6);
  
  const [isValidRecurrenceAfterAppointments, setIsValidRecurrenceAfterAppointments] = useState<boolean>(true);
  const [isValidRecurrenceInterval, setIsValidRecurrenceInterval] = useState<boolean>(true);
  
  const [cancelCustomRecurrenceModalIsOpen, setCancelCustomRecurrenceModalIsOpen] = useState<boolean>(false);
  const [inputDataHasChange, setInputDataHasChange] = useState<boolean>(false);
  
  const [recurrenceWeekDays, setRecurrenceWeekDays] = useState<WeekDaysFlags>(WeekDaysFlags.None);
  
  const frequencyItems: SelectItem[] = [
    { key: "0", value: recurrenceInterval > 1 ? `${t("days")}` : `${t("day")}` } as SelectItem,
    { key: "1", value: recurrenceInterval > 1 ? `${t("weeks")}` : `${t("week")}` } as SelectItem,
    { key: "2", value: recurrenceInterval > 1 ? `${t("months")}` : `${t("month")}` } as SelectItem,
    { key: "3", value: recurrenceInterval > 1 ? `${t("years")}` : `${t("year")}` } as SelectItem,
  ];
  
  function recurrenceChangeHandler(event: any) {
    setInputDataHasChange(true);
    const inputValue = event.target.value;
    if (inputValue.startsWith('0')) {
      return;
    }
    setRecurrenceInterval(Number(inputValue));
  }
  
  function handleIncrease() {
    setInputDataHasChange(true);
    if (recurrenceInterval > 998) {
      return;
    }
    setRecurrenceInterval(recurrenceInterval + 1);
  }
  
  function handleDecrease() {
    setInputDataHasChange(true);
    if (recurrenceInterval < 2) {
      return;
    }
    setRecurrenceInterval(recurrenceInterval - 1);
  }
  
  function inputDataIsValid(): boolean {
    const isRecurrenceAfterValid = recurrenceAfterAppointments && recurrenceAfterAppointments > 1 && recurrenceAfterAppointments < 51;
    const isRecurrenceIntervalValid = recurrenceInterval > 0 && recurrenceInterval < 1000;
    const forWeekModeDayIsChosen = recurrenceItemId !== CustomRecurrenceMode.Week || recurrenceWeekDays > 0;
    return !!(isRecurrenceAfterValid && isRecurrenceIntervalValid && forWeekModeDayIsChosen);
  }
  
  function handleRecurrenceSelect(event: any) {
    setInputDataHasChange(true);
    const value = parseInt(event.target.value);
    if (value === CustomRecurrenceMode.Week) {
      const currentDayIndex = new Date().getDay();
      const initialRecurrenceWeekDays = 1 << currentDayIndex;
      setRecurrenceWeekDays(initialRecurrenceWeekDays);
    } else {
      setRecurrenceWeekDays(WeekDaysFlags.None);
    }
    setRecurrenceItemId(Number(value));
  }
  
  function handleRecurrenceAfterAppointments(event: any) {
    const value = event.target.value.replace(/\D/g, "");
    if (value.startsWith('0')) {
      return;
    }
    setInputDataHasChange(true);
    setRecurrenceAfterAppointments(value);
  }
  
  function handleBlurRecurrencesAfterAppointments(event: any) {
    let value = event.target.value;
    const isNotValid = (value === "" || +value < 0) || !(value >= 2 && value <= 50);
    if (isNotValid) {
      setRecurrenceAfterAppointments(null);
    }
    setIsValidRecurrenceAfterAppointments(!isNotValid);
  }
  
  function handleBlurRepeatEvery(event: any) {
    let value = event.target.value;
    const isNotValid = (value === "" || +value < 0) || !(value >= 1 && value <= 999);
    setIsValidRecurrenceInterval(!isNotValid);
  }
  
  function handleOnClickSave() {
    const modalData = new RecurrenceModalData({
      customRecurrenceInterval: +recurrenceInterval!,
      customRecurrenceMode: +recurrenceItemId!,
      customRecurredAppointmentsCount: +recurrenceAfterAppointments!,
      customRecurrenceWeekDays: +recurrenceWeekDays!
    });
    setInputDataHasChange(false);
    handleConfirm(modalData);
  }
  
  function handleOnClickConfirmModalCancel() {
    setInputDataHasChange(false);
    setCancelCustomRecurrenceModalIsOpen(false);
    handleClose();
  }
  
  function handleOnClose() {
    if (inputDataHasChange) {
      setCancelCustomRecurrenceModalIsOpen(true);
    } else {
      setInputDataHasChange(false);
      handleClose();
    }
  }
  
  function handleCheckboxChange(day: WeekDaysFlags) {
    setInputDataHasChange(true);
    setRecurrenceWeekDays((prevDays) => {
      return prevDays ^ day;
    })
  }
  
  const CancelCustomRecurrenceModalContent: React.FC = () => {
    return (
      <div className={classes.closeModalContentContainer}>
        <Typography className={classes.closeModalTitleText}>
          {t("Cancel custom recurrence?")}
        </Typography>
      </div>);
  };
  
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleOnClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isOpen}>
        <div className={classes.modalCancelPaper}>
          <div>
            <Typography className={classes.modalHeader}>
              {t("Custom recurrence")}
            </Typography>
            <div className={`${classes.modalTitleSpace}`}>
              
              <Typography className={classes.modalTitleTextCancel}>
                <div className={classes.inputContainer}>
                  <div className={`${classes.inputInfo} ${classes.marginRight10}`}>
                    {t("Repeat every")}
                  </div>
                  <Input
                    id={"inputRecurrence"}
                    name={"inputRecurrence"}
                    value={recurrenceInterval}
                    onChange={recurrenceChangeHandler}
                    width={50}
                    onBlur={handleBlurRepeatEvery}
                    inputType={"number"}
                    isValid={() => isValidRecurrenceInterval}
                  />
                  <div className={classes.arrowContainer}>
                    <Tooltip
                      title={<span>{t("Increase")} </span>}
                      classes={{ tooltip: classes.tooltipBlock }}
                    >
                      <button
                        className={classes.arrowUp}
                        onClick={handleIncrease}
                      />
                    </Tooltip>
                    <Tooltip
                      title={<span>{t("Decrease")} </span>}
                      classes={{ tooltip: classes.tooltipBlock }}
                    >
                      <button
                        className={classes.arrowDown}
                        onClick={handleDecrease}
                      />
                    </Tooltip>
                  </div>
                  <Select key={"inputRecurrenceMode-id"}
                          width={100}
                          value={recurrenceItemId || "0"}
                          onChange={(e) => handleRecurrenceSelect(e)}
                          colorArrowIcon={"#5C6477"}
                          items={frequencyItems}/>
                </div>
                
                {recurrenceItemId === CustomRecurrenceMode.Week && <>
                  <div className={classes.daysWeekTitle}>{t("Days")}</div>
                  <div className={classes.daysWeekContainer}>
                    <CircleCheckbox
                      tooltipTitle={t("Sunday")}
                      checked={(recurrenceWeekDays & WeekDaysFlags.Sunday) === WeekDaysFlags.Sunday}
                      setChecked={() => handleCheckboxChange(WeekDaysFlags.Sunday)}
                      label={t("short_weekday_Sunday")}
                    />
                    <CircleCheckbox
                      tooltipTitle={t("Monday")}
                      checked={(recurrenceWeekDays & WeekDaysFlags.Monday) === WeekDaysFlags.Monday}
                      setChecked={() => handleCheckboxChange(WeekDaysFlags.Monday)}
                      label={t("short_weekday_Monday")}
                    />
                    <CircleCheckbox
                      tooltipTitle={t("Tuesday")}
                      checked={(recurrenceWeekDays & WeekDaysFlags.Tuesday) === WeekDaysFlags.Tuesday}
                      setChecked={() => handleCheckboxChange(WeekDaysFlags.Tuesday)}
                      label={t("short_weekday_Tuesday")}
                    />
                    <CircleCheckbox
                      tooltipTitle={t("Wednesday")}
                      checked={(recurrenceWeekDays & WeekDaysFlags.Wednesday) === WeekDaysFlags.Wednesday}
                      setChecked={() => handleCheckboxChange(WeekDaysFlags.Wednesday)}
                      label={t("short_weekday_Wednesday")}
                    />
                    <CircleCheckbox
                      tooltipTitle={t("Thursday")}
                      checked={(recurrenceWeekDays & WeekDaysFlags.Thursday) === WeekDaysFlags.Thursday}
                      setChecked={() => handleCheckboxChange(WeekDaysFlags.Thursday)}
                      label={t("short_weekday_Thursday")}
                    />
                    <CircleCheckbox
                      tooltipTitle={t("Friday")}
                      checked={(recurrenceWeekDays & WeekDaysFlags.Friday) === WeekDaysFlags.Friday}
                      setChecked={() => handleCheckboxChange(WeekDaysFlags.Friday)}
                      label={t("short_weekday_Friday")}
                    />
                    <CircleCheckbox
                      tooltipTitle={t("Saturday")}
                      checked={(recurrenceWeekDays & WeekDaysFlags.Saturday) === WeekDaysFlags.Saturday}
                      setChecked={() => handleCheckboxChange(WeekDaysFlags.Saturday)}
                      label={t("short_weekday_Saturday")}
                    />
                  </div>
                </>}
                <div className={classes.subhead} style={{ marginBottom: 18 }}>
                  {t("Term of recurrence")}*
                </div>
                <div style={{ display: "inline-block" }}>
                    <span className={classes.inputInfo}>
                      {t("After")} 
                    </span>
                </div>
                
                <div style={{ display: "inline-block", position: "relative", top: 0, left: 20 }}>
                  <Input
                    type={"text"}
                    value={recurrenceAfterAppointments}
                    isValid={() => isValidRecurrenceAfterAppointments}
                    width={50}
                    onChange={handleRecurrenceAfterAppointments}
                    onBlur={handleBlurRecurrencesAfterAppointments}
                  />
                  <span className={`${classes.inputInfo} ${classes.marginLeft10}`}>
                      {t("appointments")}
                    </span>
                </div>
              
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={handleOnClose}>
                {t("Close")}
              </Button>
              <Button
                style={{ opacity: inputDataIsValid() ? 1 : 0.5 }}
                className={`${classes.button} ${classes.cancelConfirmation}`}
                onClick={handleOnClickSave}
                disabled={!inputDataIsValid()}
              >
                {t("Save")}
              </Button>
            </div>
          </div>
          <ConfirmActionModal
            classModal={classes.cancelCustomRecurrenceModal}
            content={<CancelCustomRecurrenceModalContent/>}
            open={cancelCustomRecurrenceModalIsOpen}
            onClose={() => setCancelCustomRecurrenceModalIsOpen(false)}
            onConfirm={handleOnClickConfirmModalCancel}
          />
        </div>
      </Fade>
    </Modal>
  );
}