import { AppointmentScheduleBusyReason } from "./enums/appointment-schedule-busy-reason";

export class AppointmentBusyModalDataModel {
  customerName: string | null;
  serviceName: string | null;
  workArea: string | null;
  startData: Date;
  appointmentDuration:  number | null;
  appointmentScheduleBusyReason: AppointmentScheduleBusyReason | null;

  constructor(x?: any) {
    if (typeof x == "object") {
      this.customerName = x.customerName;
      this.serviceName = x.serviceName;
      this.workArea = x.workArea;
      this.startData = x.startData;
      this.appointmentDuration = x.appointmentDuration;
      this.appointmentScheduleBusyReason = x.appointmentScheduleBusyReason;
    } else {
      this.customerName =null;
      this.serviceName = null;
      this.workArea = null;
      this.startData = new Date();
      this.appointmentDuration = null;
      this.appointmentScheduleBusyReason = null;
    }
  }
}
