import React, { useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";

import useStyles from "./css";
import UserList from "../UserList";
import UserModal from "../UserModal";
import { EditableItemProps } from "../../../../../models/interfaces/editable-item-props";
import { Url } from "../../../../../constants/url";
import { UserService } from "../../../../../api/user-service";
import { AccountService } from '../../../../../api/account-service';
import { UserEntity } from "../../../../../models/user-entity";
import { UserServicesInfo } from "../../../../../models/user-services-info";
import { useAppDispatch } from "../../../../../redux/hooks";
import { alertsActions } from "../../../../../redux/alerts-slice";
import { UserScheduleInfo } from "../../../../../models/user-schedule-info";
import { WeekdayService } from "../../../../../api/settings-weekdays-service";
import { UserCommissionInfo } from "../../../../../models/user-commission-info";
import UserStatistic from '../UserStatistic/user-statistic';
import { SectionType } from '../../../../../models/enums/section-type';
import { SettingsPatientFormsService } from '../../../../../api/settings-patient-forms-service';
import { FieldAndValue } from '../../../../../models/field-and-value';
import SectionNote from '../../../../establishment-settings/Sections/FormsSettingsTabs/SectionNote';
import { Typography } from '@material-ui/core';
import { SectionNoteData } from '../../../../../models/enums/section-note-data';

export default function UsersMainPage(props: any) {

  const { settingsSelectedEstablishmentId } = props;
  const [isRefresh, setIsRefresh] = useState<any>();
  const [modalMode, setModalMode] = useState<EditableItemProps>({ mode: "new", itemId: null });
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [user, setUser] = useState<UserEntity>(new UserEntity());
  const [oldUser, setOldUser] = useState<UserEntity>(new UserEntity());
  const [customUserSchedule, setCustomUserSchedule] = useState<UserScheduleInfo>();
  const [totalUsers, setTotalUsers] = useState<number>(0);

  const dispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;

  const getUser = async (itemId: any) => {
    const response = await UserService.getUserById(itemId, settingsSelectedEstablishmentId);
    const user = new UserEntity(settingsSelectedEstablishmentId, response.general, response.schedule, response.services, response.commission, response.permissions, response.professionalInformation);
    const userCopy: UserEntity = JSON.parse(JSON.stringify(user))
    setUser(user);
    setOldUser(userCopy);
  };

  const handleOpenModal = async (itemId?: any) => {
    if (itemId) {
      await getUser(itemId);
    } else {
      const userProfessionalInfoFields = (await SettingsPatientFormsService.getAccountSettingsTabSections(SectionType.UserTreatment)).flatMap(sm => sm.fields);
      const userServices = await UserService.getUserServices(settingsSelectedEstablishmentId);
      const userCommissionsByServices = await UserService.getCommissionsByServices(settingsSelectedEstablishmentId);
      const accountCountryCode = await AccountService.getAccountCountryCode(settingsSelectedEstablishmentId);

      let userInfo;

      userInfo = {
        ...user,
        commission: user.commission?.commissionsByServices?.length ? user.commission : new UserCommissionInfo(userCommissionsByServices),
        services: user.services.serviceCategories.length === 0 ? new UserServicesInfo(userServices) : user.services,
        schedule: user.schedule ? user.schedule : new UserScheduleInfo([]),
        general: { ...user.general, mobileCodeIso3: accountCountryCode },
        professionalInformation: { ...user.professionalInformation, fieldsAndValues: userProfessionalInfoFields.map(f => new FieldAndValue({ field: f, value: null })) }
      };

      setUser(userInfo);
    }

    setOpenModal(true);
  };

  useEffect(() => {
    if (props.match.params?.userId) {
      setModalMode({ mode: "edit", itemId: props.match.params?.userId });
      handleOpenModal(props.match.params?.userId);
    }
    const fetchAccountSchedule = async () => {
      const result = await WeekdayService.getAccountWeekdaysAsUserSchedule(settingsSelectedEstablishmentId);
      return result;
    };
    fetchAccountSchedule().then(s => setCustomUserSchedule(s));
  }, [settingsSelectedEstablishmentId]);

  const handleCloseModal = () => {
    const title = document.title;
    window.history.pushState("", title, `${Url.Settings.Users}`);
    setModalMode({ mode: "new", itemId: null });
    setOpenModal(false);
  };

  const handleUserEdit = async (userId: string) => {
    const title = document.title;
    window.history.pushState("", title, `${Url.Settings.Users}/${userId}`);
    setModalMode({ mode: "edit", itemId: userId });
    await handleOpenModal(userId);
  };

  const handleUserInvited = async (user: UserEntity, signatureImage: File | null) => {
    const newUser = modalMode.mode === "new"
      ? await UserService.createUser(user, signatureImage, settingsSelectedEstablishmentId)
        .then(() => {
          dispatch(
            enqueueAlert({
              type: "Success",
              title: t("User invited"),
              description: `${user.general.name} ${user.general.lastName} (${user.general.jobPosition?.name}).`
            })
          );
        })
        .catch((e) => {
          console.log(e);
        })
      : await UserService.updateUser(user, signatureImage, settingsSelectedEstablishmentId)
        .then(() => {
          dispatch(
            enqueueAlert({
              type: "Success",
              title: t("User modified"),
              description: `${user.general.name} ${user.general.lastName} (${user.general.jobPosition?.name}).`
            })
          );
        })
        .catch((e) => {
          console.log(e);
        });

    setIsRefresh(user);
  };

  const { t } = useTranslation(["settings"]);
  const classes = useStyles();

  return (
    <div>
       {settingsSelectedEstablishmentId && (
        <>
          <div className={classes.noteHeader}>
            <Typography className={classes.tabTitle}>
              {t("Users")}
            </Typography>
          </div>
            <SectionNote 
              sectionData={SectionNoteData.Users} 
            />
        </>
      )}
      <div className={classes.header}>
        <UserStatistic totalUsers={totalUsers} />
        <div className={classes.root}>
          <Button
            className={`${classes.button} ${classes.newUser}`}
            onClick={() => handleOpenModal()}
          >
            {t('Invite user')}
          </Button>
        </div>
      </div>
      <UserList
        settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
        isRefresh={isRefresh}
        onRowClick={handleUserEdit}
        setTotalUsers={setTotalUsers}
      />
      <UserModal
        settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
        user={user}
        setUser={setUser}
        oldUser={oldUser}
        mode={modalMode.mode}
        itemId={modalMode.itemId}
        open={openModal}
        setOpen={setOpenModal}
        onClose={handleCloseModal}
        onCreate={handleUserInvited}
        customUserSchedule={customUserSchedule}
      />
    </div>
  );
}