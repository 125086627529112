import { JobPosition } from "./job-position";

export class UserGeneralInfo {
  id: string | null;
  friendlyId: number;
  name: string | null;
  lastName: string | null;
  email: string | null;
  mobileCodeIso3: string | null;
  mobileNumber: string | null;
  jobPosition: JobPosition | null;
  hasServicesInCharge: boolean;
  canPrescribeTreatment: boolean

  constructor(x? : any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.friendlyId = x.friendlyId;
      this.name = x.name;
      this.lastName = x.lastName;
      this.email = x.email;
      this.mobileCodeIso3 = x.mobileCodeIso3;
      this.mobileNumber = x.mobileNumber;
      this.jobPosition = x.jobPosition ?? null;
      this.hasServicesInCharge = x.hasServicesInCharge;
      this.canPrescribeTreatment = x.canPrescribeTreatment;

    } else {
      this.id = null;
      this.friendlyId = 0;
      this.name = null;
      this.lastName = null;
      this.email = null;
      this.mobileCodeIso3 = null;
      this.mobileNumber = null;
      this.jobPosition = null;
      this.hasServicesInCharge = true;
      this.canPrescribeTreatment = false;
    }
  }
}