import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function WhatsAppPDFIcon(props: any) {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 40, height: 40, ...style }}
      viewBox={viewBox || "0 0 40 40"}
    >
      <path 
        d="M29.2188 11.7682L24.8958 7.39323C24.4271 6.92448 23.75 6.66406 23.125 6.66406H12.5C11.0938 6.66406 10 7.8099 10 9.16406V30.8307C10 32.237 11.0938 33.3307 12.5 33.3307H27.5C28.8542 33.3307 30 32.237 30 30.8307V13.5391C30 12.9141 29.6875 12.237 29.2188 11.7682ZM27.2917 13.3307H23.3333V9.3724L27.2917 13.3307ZM12.5 30.8307V9.16406H20.8333V14.5807C20.8333 15.3099 21.3542 15.8307 22.0833 15.8307H27.5V30.8307H12.5ZM25.5208 23.3828C24.8958 22.7578 23.0729 22.9141 22.1354 23.0182C21.25 22.4974 20.6771 21.7161 20.2604 20.6224C20.4167 19.7891 20.7812 18.487 20.5208 17.7057C20.3125 16.3516 18.5417 16.4557 18.2812 17.3932C18.0729 18.2266 18.2812 19.4245 18.6458 20.8828C18.125 22.1328 17.3438 23.7995 16.8229 24.7891C15.7812 25.3099 14.375 26.1432 14.1667 27.1849C14.0104 28.0182 15.5208 30.0495 18.125 25.5703C19.2708 25.1536 20.5729 24.6849 21.6667 24.4766C22.6562 25.0495 23.8021 25.362 24.5833 25.362C25.9375 25.362 26.0417 23.9036 25.5208 23.3828ZM15.2083 27.4453C15.4688 26.7161 16.4583 25.8828 16.7708 25.6224C15.7812 27.1849 15.2083 27.4453 15.2083 27.4453ZM19.4271 17.4974C19.8438 17.4974 19.7917 19.1641 19.5312 19.6328C19.3229 18.9036 19.3229 17.4974 19.4271 17.4974ZM18.1771 24.6328C18.6979 23.7474 19.1146 22.7057 19.4792 21.7682C19.8958 22.5495 20.4167 23.1745 21.0417 23.5911C19.9479 23.8516 19.0104 24.2682 18.1771 24.6328ZM25 24.3724C25 24.3724 24.7396 24.6849 23.0729 23.9557C24.8958 23.7995 25.2083 24.2161 25 24.3724Z" 
        fill={style?.color || Color.gray5}
      />
    </SvgIcon>
  );
}
