import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  pagination: {
    marginTop: "12px",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center", 
    "& .MuiPaginationItem-page": {
        color: "#919CA5",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
    },
    "& .Mui-selected": {
        backgroundColor: "#6462F3",
        color: "#F6F6F6",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        },
    "& .Mui-selected:hover": {
        backgroundColor: "#6462F3",
        opacity: 0.5,
        color: "#F6F6F6",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12
        },
    "& svg": {
        color: "#6462F3"
    }
  },
});

export default useStyles;
