import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    
    icon: {
      userSelect: "none",
      pointerEvents: "all",
      
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: '50%',
      
      width: 40,
      height: 40,
      
      backgroundColor: theme.colors.grey1,
      
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
    },
    checkedIcon: {
      userSelect: "none",
      pointerEvents: "all",
      
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      borderRadius: '50%',
      
      width: 40,
      height: 40,
      
      backgroundColor: theme.colors.clearMain,
      color: '#FFFFFF',
      
      boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.5)',
    },
    tooltipBlock: {
      backgroundColor: theme.colors.grey1,
      ...theme.typography.txtBody2,
      color: theme.colors.grey5,
      
      position: "relative",
      top: -5
    },
  })
);

export default useStyles;