import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  header: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    
    marginTop: 16,
    marginBottom: 16
  },
  blocks: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  valuesBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    padding: 16,
    minWidth: 362
  },
  value: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 16
  },
  valueLabel: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4
  },
  valueText: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey6
  },
  divider: {
    width: "100%",
    marginTop: 20,
    marginBottom: 20,
    height: 0,
    border: "1px solid",
    borderColor: theme.colors.grey1
  },
  previewBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 5,
    padding: 24,
    backgroundPosition: "center",
    minWidth: 346
  },
  messageBlock: {
    width: 285,
    backgroundColor: "#FFFFFF",
    borderRadius: "0 5px 5px 5px",
    padding: 8,
    position: "relative"
  },
  imageBlock: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    width: "max-content",
    maxWidth: 269,
    height: "max-content",
    maxHeight: 152,
    marginBottom: 16
  },
  image: {
    width: 269,
    height: 152,
    objectFit: "cover",
    objectPosition: "center",
    borderRadius: 15
  },
  text: {
    width: 269,
    
    ...theme.typography.txtBody2,
    color: theme.colors.grey6,
    paddingBottom: 12,
    wordBreak: "break-word"
  },
  arrow: {
    position: "absolute",
    top: 0,
    left: -11
  },
  timeBlock: {
    position: "absolute",
    bottom: 8,
    right: 8,
    
    ...theme.typography.caption1,
    color: theme.colors.grey5
  },
  goToSiteButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    width: 285,
    backgroundColor: "#FFFFFF",
    borderRadius: 5,
    marginTop: 3
  },
  goToSiteButtonInnerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    color: theme.colors.clearBlue,
    fontWeight: 700,

    width: 677,
    height: 32,
    borderRadius: 5,
  },
  goToSiteIcon: {
    margin: "0px 5px 4px 0px"
  },
  })
);

export default useStyles;