export class JobPosition {
  id: string | null;
  name: string | null;
  isEditable: boolean;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.name = x.name;
      this.isEditable = x.isEditable;
    } else {
      this.id = null;
      this.name = null;
      this.isEditable = false;
    }
  }
}