import { makeStyles } from "@material-ui/core/styles";
import {Color} from "../../../../../constants/colors";

export const useStyles = makeStyles((theme) => ({
    root: {},
    section: {
        paddingBottom: 73
    },
    divArea: {
        backgroundColor: "#fff",
        borderRadius: 20,
        border: "1px solid #dbdcdd",
        padding: "40px 40px 31px 40px",
        margin: "10px 0"
    },
    title: {
        fontSize: "14px",
        color: theme.colors.grey5,
        marginBottom: 16,
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        "&:first-letter": {
            textTransform: "capitalize"
        }
    },
    label: {
        ...theme.typography.txtBody2,
        color: theme.colors.grey4,
        fontWeight: "bold",
        display: "block",
        marginBottom: "0.5rem",
    },
    divider: {
        borderTop: "1px solid",
        borderColor: theme.colors.grey2,
        opacity: 0.3,
        marginTop: "15px",
        marginBottom: "15px",
    },
    notesTitle: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "14px",
        display: "flex",
        color: theme.colors.grey5,
    },
    notes: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "14px",
        display: "flex",
        color: theme.colors.grey5,
    },
    divRowWhats: {
        paddingTop: 5
    },
    divRowWhatsHeight60: {
        height: 60
    },
    divCheck: {
        display: "inline-block",
        width: 45,
    },
    divLabel: {
        ...theme.typography.txtBody2,
        color: theme.colors.grey5,
        fontSize: 14,
        fontWeight: 700,
        fontStyle: "normal",
        marginBottom: "0.5rem",
        display: "inline-block",
    },
    divSelect: {
        marginTop: "-8px"
    },
    switchBase: {
        padding: 1,
        opacity: 1,
        height: "100%",
        border: "3px solid #323947",
        "&.Mui-checked": {
            transform: "translateX(13px)",
            border: "0px",
            color: theme.palette.common.white,
            "& + .MuiSwitch-track": {
                backgroundColor: "#36ce91",
                opacity: 1,
                border: "none",
            },
            "& * .MuiSwitch-thumb": {
                width: 15,
            },
            //
        },
        "&$focusVisible .MuiSwitch-thumb": {
            color: "#6462F3",
            border: "6px solid #fff",
        },
        "& * .MuiSwitch-thumb": {
            width: 12,
            height: 15,
            boxShadow: "none",
        },
    },
    divNote: {
        marginTop: 20,
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontSize: 12,
        color: "#5C6477"
    },
    disabledSelect: {
        opacity: "0.42"
    },
    tinyMceOverrides: {
        "& svg": {
            fill: "#919CA5 !important"
        },
        "& button.tox-tbtn--select": {
            visibility: "hidden"
        },
        "& iframe.tox-edit-area__iframe": {
            backgroundColor: theme.colors.grey1 + " !important"
        },
        "& div.tox-toolbar__primary": {
            backgroundColor: theme.colors.grey1 + " !important"
        },
        "& div.tox-sidebar-wrap": {
            backgroundColor: theme.colors.grey1 + " !important"
        },
        "& div.tox-edit-area": {
            marginBottom: "22px"
        },
        position: "relative",
        margin: "0px 0px 0px 43px",
    },
    tinyMceUnfocused: {
        "& div.tox-tinymce": {
            borderRadius: 5,
            borderColor: "#C9CDD3 !important"
        }
    },
    tinyMceFocused: {
        "& div.tox-tinymce": {
            borderRadius: 5,
            borderColor: "#6462F3 !important"
        }
    },
    tinyMceInvalid: {
        "& div.tox-tinymce": {
            borderRadius: 5,
            borderColor: theme.colors.clearRed + " !important"
        }
    },
    resetTimeContainer: {
        display: "inline-block"
    },
    charCounter: {
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "15px",
        position: "absolute",
        paddingLeft: "10px",
        marginTop: "-23px",
        color: theme.colors.grey5
    },
    saveCustomMessageButton: {
        height: 32,
        background: "#36CE91",
        color: "#F6F6F6",
        "&:hover": {
            background: "#F6F6F6",
            color: "#36CE91",
        },
        "&:disabled": {
            background: "#36CE91",
            opacity: 0.3,
            color: "#F6F6F6"
        },
        position: "relative",
        top: -40,
        right: 5,
        float: "right",
        borderRadius: 51,
        textTransform: "none",
        boxShadow: "none",
        display: "inline-block",
        padding: "0px 16px",
        margin: "1px -157px -33px 0px"
    },
    saveCustomMessageText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        display: "inline-block"
    },
    whatsAppRequestQuotesDelay: {
        marginTop: 10,
        marginLeft: 43,
        "& label": {
            ...theme.typography.txtBody2,
            display: "block",
            color: theme.colors.grey5,
            fontSize: 12,
            fontWeight: 700,
            fontStyle: "normal",
        }
    },
    selectStyle: {
        color: Color.gray5,
        fontWeight: "bold",
        fontSize: 12,
        height: 40,
        padding: "13px 0px 13px 12px",
        border: "solid 1px rgba(0, 0, 0, 0.1)",
        borderRadius: 5,
        width: 200,
        paddingRight: 10,
        backgroundColor: "#F6F6F6",
        cursor: "pointer",
        "& * .ant-picker-suffix, .ant-picker-clear": {
            display: "none",
        },
        "& * input": {
            fontFamily: "Roboto",
            fontWeight: "bold",
            fontSize: 12,
            fontStyle: "normal",
            color: Color.gray5,
            cursor: "pointer",
        },
    },
    selectHrs: {
        "& div:after": {
            content: "'hrs'",
            marginLeft: "35px",
            marginBottom: "2px",
            position: "absolute"
        },
    },
    selectHrsClose: {
        "& div:after": {
            content: "'hrs'",
            marginLeft: "35px",
            marginBottom: "2px",
            position: "absolute",
        },
    },
    availabilityStatusSelectHrsClose: {
        "& div:after": {
            content: "'hrs'",
            color: "rgba(0, 0, 0, 0.25)",
            marginLeft: "35px",
            marginBottom: "2px",
            position: "absolute",
        },
    },
    initialMessage: {
        padding: 20,
        backgroundColor: theme.colors.grey1,
        borderRadius: 14,
        borderColor: theme.colors.grey2,
        borderWidth: 1,
        borderStyle: "solid",
        width: 730,
        "& .tinyMceOverrides": {
            "& iframe.tox-edit-area__iframe": {
                backgroundColor: "white !important"
            },
            "& div.tox-toolbar__primary": {
                backgroundColor: "white !important"
            },
            "& div.tox-sidebar-wrap": {
                backgroundColor: "white !important"
            },
            margin: 0,
        },
        "& .saveCustomMessageButton": {
            right: 162
        },
        "& .staticText": {
            marginBottom: 15,
            "& p": {
                margin: 0
            },
            "& p:first-child": {
                margin: "10px 0 20px"
            }
        },
        "& .menuButton": {
            borderRadius: 7,
            borderColor: theme.colors.grey2,
            borderWidth: 1,
            borderStyle: "solid",
            color: theme.colors.grey2,
            fontWeight: 700,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: 32,
            "& span": {
                paddingBottom: 2
            }
        }
    },
    scheduleFollowUp: {
        width: 733,
        display: "flex",
        justifyContent: "space-between",
        "&>div:first-child": {
            display: "flex"
        }
    },
    resetAvailableStatus: {
        marginTop: 16,
        width: 745,
        display: "flex",
        justifyContent: "space-between",
        alignItems: "end",
        "&>div:first-child": {
            display: "flex",
            alignItems: "center",
            marginBottom: 9,
            "& div": {
                marginBottom: 0
            },
            "& label": {
                marginBottom: 0
            }
        }
    },
    showCancellationPoliciesDisabledBlock: {
        width: "100%",

        marginTop: 8,
        marginBottom: 8,

        paddingTop: 18,
        paddingRight: 18,
        paddingBottom: 18,
        paddingLeft: 18,

        backgroundColor: theme.colors.clearLilac,

        ...theme.typography.txtBody2,
        color: theme.colors.grey5,
    },
    iconTooltipRoot: {

        display: "inline-block",
        marginLeft: 5,

        "&>div": {
            maxWidth: 220
        }
    },
    header: {
        display: "flex",
        alignItems: "center",
        marginBottom: 15,
        gap: 20,
        width: "100%",
        justifyContent: "space-between",
    },
    tabTitle: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: Color.gray5
    },
}));

