import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  tableHeader: {
    backgroundColor: "#F6F6F6",
    left: "unset"
  },
  headerText: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey5,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  arrowDropdown: {
    display: "inline-grid",
  },
  arrowShow: {
    visibility: "visible",
  },
  arrowHide: {
    visibility: "hidden",
  },
  totalResultText: {
    ...theme.typography.caption2,
    color: theme.colors.clearMain,
  },
  modalActionsAppt: {
    position: "relative",
    top: 58,
    left: 15,
    background: "white",
    width: 202,
    textAlign: "center",
    border: "1px solid #DBDCDD",
    boxShadow: "0px 4px 4px rgb(166 166 166 / 25%)",
    borderRadius: 5,
    backgroundColor: "#FFFFFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    color: theme.colors.grey5, 
  },
  modalActionFont: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    color: theme.colors.grey5, 
    padding: 5,
    cursor: "pointer",
    position: "relative",
    background: "white",
    width: 200,
    textAlign: "left",
    paddingLeft: 15,
    height: 35
  },
  modalActionFirst: {
    paddingTop: 10,
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottom: "1px solid #DBDCDD",
    "&:hover": {
      backgroundColor: "#E1E9FE"
    }
  },
  modalActionLast: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    "&:hover": {
      backgroundColor: "#E1E9FE"
    }
  },
}));

export default useStyles;
