import { ServiceSelectModel } from "./service-select-model";

export class ServiceGroupModel {
    category: string | null;
    services: ServiceSelectModel[] | null;
    constructor(x?: any) {
      if (typeof x === "object") {
        this.category = x.category;
        this.services = x.services;
      } else {
        this.category = null;
        this.services = null;
      }
    }
}