import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import moment from "moment";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { navbarActions } from "../../../../../redux/navbar-slice";

import { ImportIcon, TrashIcon } from "../../../../../assets/icons";
import GridList, { OnChangeEvent } from "./../../../../common/grid/GridList";
import { TableColumn } from "../../../../common/grid/table-column";
import { Button } from "@material-ui/core";
import { Typography } from "@material-ui/core";

import EntityService from "../../../../../api/settings-services-service";
import Service from "../../../../../models/service";
import { ServiceListProps } from "./props";

import { Color } from "../../../../../constants/colors";
import useStyles from "./css";

import { FlagIcon } from "../../../../../assets/icons";
import Autocomplete from "../../../../../components/common/Autocomplete";


export default function ServicesGrid(props: ServiceListProps) {

  const { settingsSelectedEstablishmentId } = props;

  const { t } = useTranslation(["general"]);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  
  const {
    isRefresh,
    item,
    openImportModal,
    openItemEditModal,
    openDeleteModal,
    setTotalRecords,
    handleCreate,
  } = props;

  const [entityList, setEntityList] = useState<Service[]>([]);
  const [total, setTotal] = useState<number>(0);

  const [serviceSearch, setServiceSearch] = useState<Service[]>([]);
  const [search, setSearch] = useState<string>();

  const [page, setPage] = useState<number>(1);
  const pageSize: number = 20;
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("name");
  const [value, setValue] = useState<string>();
  const format = "HH:mm";

  const userInfo = useAppSelector(state => state.userInfo);

  useEffect(() => {
    if (item) {
      const newList = [item].concat(entityList);
      setEntityList(newList);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {
    (async () => {
      await loadData();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsSelectedEstablishmentId, page, orderBy, order, value, isRefresh]);

  useEffect(() => {
    if (search && search.length > 2) {
      (async () => {
        await loadSearchData();
      })();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const loadData = async () => {
    dispatch(navbarActions.setShowLoader(true));
    const data = await EntityService.getServices(
      page,
      pageSize,
      order,
      orderBy,
      settingsSelectedEstablishmentId,
      value,
      ["Name", "Category"]
    );
    dispatch(navbarActions.setShowLoader(false));
    data.entity?.forEach((item, index) => {
      item.recurrence = t(item.recurrence)
                        .replace("weeks", t("weeks"))
                        .replace("week", t("week"))
                        .replace("months", t("months"))
                        .replace("month", t("month"));
    });
    setEntityList(data.entity || []);
    setTotal(data.total || 0);
    if (setTotalRecords) setTotalRecords(data.total || 0);
  };

  const loadSearchData = async () => {
    const data = await EntityService.getServices(1, 20, "asc", "Name",  settingsSelectedEstablishmentId, search, [
      "Name",
      "Category",
    ]);

    // avoid names duplicated
    const list: Service[] = data.entity ?? [];
    const names = list.map((o) => o.name);
    const groupByName = list.filter(
      ({ name }, index) => !names.includes(name, index + 1)
    );

    setServiceSearch(groupByName || []);
  };

  const numberWithCommas = (x: number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const duration = (minDuration: number) => {
    const minDateTime = minutesToDateTime(minDuration);
    const minutes = moment(minDateTime).format(format);

    return `${minutes} ${ minDateTime.getHours() > 1 ? "hrs" : (minDateTime.getHours() === 1 ? "hr" : "min") }`;
  };

  const minutesToDateTime = (minutes: number): Date => {
    let newDate = new Date(2000, 1, 1, 0, 0, 0, 0);
    newDate.setMinutes( newDate.getMinutes() + minutes );
    return newDate;
  };

  const columns: TableColumn[] = [
    {
      id: "name",
      label: t("SERVICE NAME"),
      sorting: true,
      width: "20%",
      headerClassName: classes.classHead,
      rowClassName: classes.classCell,
    },
    {
      id: "price",
      label: t("PRICE"),
      sorting: false,
      component: (price: any) => (
        <Typography className={classes.colText}>
          $ {numberWithCommas(price)}
        </Typography>
      ),
      width: "10%",
      headerClassName: classes.classHead,
      rowClassName: classes.classCell,
    },
    {
      id: "category",
      label: t("CATEGORY"),
      sorting: true,
      width: "10%",
      headerClassName: classes.classHead,
      rowClassName: classes.classCell,
    },
    {
      id: "duration",
      label: t("DURATION"),
      sorting: false,
      component: (min: any) => (
        <Typography className={classes.colText}>{duration(min)}</Typography>
      ),
      width: "15%",
      headerClassName: classes.classHead,
      rowClassName: classes.classCell,
    },
    {
      id: "recurrence",
      label: t("RECURRENCE"),
      sorting: false,
      width: "10%",
      headerClassName: classes.classHead,
      rowClassName: classes.classCell,
      component: (r: any) => (
        <Typography className={classes.colText}>{r.toString()}</Typography>
      ),
    },
    {
      id: "notes",
      label: t("NOTES"),
      width: "35%",
      sorting: false,
      completeObject: true,
      component: (item: any) => (
        <>
          <div className={classes.divNotesTrash}>
            <div className={classes.divNotes}>{item.notes}</div>
            <div id="divBackTrash" className={classes.divBackTrash}></div>
            <div id="divTrash" className={classes.divTrash}>
              <Button
                className={classes.buttonDel}
                onClick={(e) => {
                  e.stopPropagation();
                  handleOnDeleteBtnClick(item);
                }}
              >
                <TrashIcon
                  style={{ color: Color.gray4, width: 13, height: 17 }}
                />
              </Button>
            </div>
          </div>
        </>
      ),
      headerTextAlign: "center",
      textAlign: "center",
      headerClassName: classes.classHead,
      rowClassName: classes.classCell,
    },
  ];

  const handleOnChangeGrid = (event: OnChangeEvent) => {
    setPage(event.page);
    if (event.sorting) {
      setOrder(event.sorting.direction);
      setOrderBy(
        event.sorting.orderBy === "name" ? "name" : event.sorting.orderBy
      );
    }
  };

  const handleOnRowClick = (item: Service, showModal: boolean) => {
    if (typeof openItemEditModal === "function") {
      openItemEditModal(item);
    }
  };

  const handleOnDeleteBtnClick = (item: any) => {
    if (typeof openDeleteModal === "function") {
      openDeleteModal(item);
    }
  };

  const handleOnServiceCreate = () => {
    if (typeof handleCreate === "function") {
      handleCreate();
    }
  };

  return (
    <>
      <div className={classes.header}>
        <div className={classes.headerLeft}>
          <Typography className={classes.titles} variant="subtitle1">
            {t("Services created")}
          </Typography>
          <Typography className={classes.icons} variant="h4">
            <FlagIcon className={classes.icon} />
            {total}
          </Typography>
        </div>

        <div className={classes.headerCenter}>
          <Autocomplete
            style={{ width: 200, marginBottom: 19 }}
            items={serviceSearch}
            placeholder={t("Find service")}
            renderOption={(option: any) => `${option.name}`}
            getOptionSelected={(option, value) => option.name === value.name}
            getOptionLabel={(option) => option.name}
            onChange={(value: any, reason) => {
            if (reason === "input" && value.length >= 3)
              setSearch(value); 
            else
              setServiceSearch([]);
            }}
            onSelected={(option: any) => {
              setValue(option?.name || undefined);
            }}
          />
        </div>

        <div className={classes.headerRight}>
          <Button
            className={`${classes.button} ${classes.serviceImportButton}`}
            onClick={openImportModal}
          >
          <ImportIcon /> {t("Import services")}
          </Button>

          <Button
            className={`${classes.button} ${classes.buttonNew}`}
            onClick={handleOnServiceCreate}
          >
            {t("Create Service")}
          </Button>
        </div>
      </div>

      <GridList
        columns={columns}
        items={entityList}
        totalItems={total}
        pageSize={20}
        onChange={handleOnChangeGrid}
        onRowClick={(e) => handleOnRowClick(e, true)}
        classRow={classes.classRow}
      />
    </>
  );
}