import { CashRegisterMovementType } from "./enums/cash-register-movement-type";

export class RegisterCashRegisterMovementRequest {
  type: CashRegisterMovementType;
  amount: number;
  time: Date;
  chargingUserId: string;
  chargingUserName: string;
  notes: string;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.type = x.type;
      this.amount = x.amount;
      this.time = x.time;
      this.chargingUserId = x.chargingUserId;
      this.chargingUserName = x.chargingUserName;
      this.notes = x.notes;
    } else {
      this.type = CashRegisterMovementType.CashOpening;
      this.amount = 0.0;
      this.time = new Date();
      this.chargingUserId = "";
      this.chargingUserName = "";
      this.notes = "";
    }
  }
}