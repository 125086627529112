import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const InfoIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
                d="M6.00008 0.666992C3.05384 0.666992 0.666748 3.07559 0.666748 6.00033C0.666748 8.94656 3.05384 11.3337 6.00008 11.3337C8.92481 11.3337 11.3334 8.94656 11.3334 6.00033C11.3334 3.07559 8.92481 0.666992 6.00008 0.666992ZM6.00008 3.03258C6.4947 3.03258 6.90331 3.44119 6.90331 3.93581C6.90331 4.45194 6.4947 4.83904 6.00008 4.83904C5.48395 4.83904 5.09686 4.45194 5.09686 3.93581C5.09686 3.44119 5.48395 3.03258 6.00008 3.03258ZM7.20438 8.49495C7.20438 8.64549 7.07535 8.75301 6.94632 8.75301H5.05384C4.90331 8.75301 4.79578 8.64549 4.79578 8.49495V7.97882C4.79578 7.84979 4.90331 7.72076 5.05384 7.72076H5.31191V6.34441H5.05384C4.90331 6.34441 4.79578 6.23688 4.79578 6.08635V5.57022C4.79578 5.44119 4.90331 5.31215 5.05384 5.31215H6.43019C6.55922 5.31215 6.68825 5.44119 6.68825 5.57022V7.72076H6.94632C7.07535 7.72076 7.20438 7.84979 7.20438 7.97882V8.49495Z" 
            />
        </SvgIcon>
    )
};

export default InfoIcon;
