import React, { useState } from "react";

import { useAppDispatch } from "../../../redux/hooks";
import { alertsActions } from "../../../redux/alerts-slice";

import { InputEditableItemProps } from "./props";
import useStyles from "./css";
import { EditIcon, SaveIcon } from "../../../assets/icons";
import { ClickAwayListener } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import useCurrencyValueTemplate from "../../../hooks/useCurrencyValueTemplate";
import { CurrencyRegex } from "../../../constants/validator";


enum InputState {
  Idle,
  Edit
}


export default function InputEditableItem(props: InputEditableItemProps) {
  
  const { value, setValue, valueTemplate, setValid, disabled, maxLength, maxValue, color } = props;
  
  const actualMaxLength = maxLength ?? 10;
  const maxDecimals = 2;
  
  const classes = useStyles(color)();
  const dispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;
  
  const templatedValue = useCurrencyValueTemplate(valueTemplate);
  
  const [isError, setError] = useState<boolean>(false);
  const [inputState, setInputState] = useState<InputState>(InputState.Idle);
  const [displayValue, setDisplayValue] = useState<string>(templatedValue(value));
  
  function handleEditButtonClick() {
    setDisplayValue(value.toString());
    setInputState(InputState.Edit);
    setValid(false);
  }
  
  function handleEditClickAway() {
    setError(true);
  }
  
  function handleEditFocus() {
    setError(false);
  }
  
  function handleSaveButtonClick() {
    let parsedValue = parseFloat(displayValue);
    if (Number.isNaN(parsedValue)) parsedValue = 0;
    if (maxValue && parsedValue > maxValue.maxValue) {
      displayAlert(maxValue.errorMessage)
      return;
    }
    setValue(parsedValue);
    setDisplayValue(templatedValue(parsedValue));
    setError(false);
    setValid(true);
    setInputState(InputState.Idle);
  }
  
  function displayAlert(message: string) {
    dispatch(
      enqueueAlert({
        type: "Error",
        title: null,
        description: message,
      })
    )
  }
  
  function handleEditOnChange(event: any) {
    event.preventDefault();

    if (Number.isNaN(+event.target.value)) return;

    const value = event.target.value+"";
    const actualValue = value.replace(",", ".").replace(/[^\d.]/g, "");

    // verify decimals
    const pointIndex = value.indexOf(".");
    const hasDecimals = pointIndex>-1;

    if (hasDecimals) {
      if ((value.length-pointIndex-1) > maxDecimals) return;
    }
    else  {
      if (value.length > actualMaxLength) return;
    }

    setDisplayValue((actualValue)+"");
  }
  
  const SaveButton = () => 
    <SaveIcon 
      style={{ width: 16, height: 16, color: "6462F3" }}
      viewBox={"0 0 16 16"}
      className={classes.editSaveButton}
    />
  
  
  return (
    <div>
      {
        inputState === InputState.Idle
          ?
          <div className={classes.idleRoot}>
            <div className={classes.idleValue}>{displayValue}</div>
            <div>
              {disabled 
                ? "" 
                :
                <EditIcon
                  style={{width: 11, height: 11, color: "#919CA5"}}
                  viewBox={"0 0 16 16"}
                  onClick={handleEditButtonClick}
                />
              }
            </div>
          </div>
          : 
          <ClickAwayListener onClickAway={handleEditClickAway}>
            <div className={classes.editRoot}>
              <InputBase
                className={`${classes.input} ${isError ? classes.inputError : ""}`}
                classes={{
                  focused: classes.inputFocused,
                  disabled: classes.inputDisabled,
                  input: classes.inputText,
                }}
                value={displayValue}
                placeholder={templatedValue(0.00)}
                onChange={handleEditOnChange}
                onFocus={handleEditFocus}
                disabled={disabled ?? false}
                inputProps={{
                  maxLength: maxLength ?? 10,
                }}
                type="text"
              />
              <div className={classes.editSaveIcon} onClick={() => handleSaveButtonClick()}>
                <SaveButton />
              </div>
            </div>
          </ClickAwayListener>
      }
    </div>
  );
}