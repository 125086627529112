import React, { useState, useEffect } from "react";
import { Accordion, AccordionSummary, AccordionDetails, Grid, Drawer } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
import moment from 'moment';

import { ArrowDropDownIcon, CalendarIcon, CheckIcon, CrossIcon, MenuIconLogoClear, NoteIcon, NotesIcon, PersonIcon } from "../../../assets/icons";
import { CustomerService } from "../../../api/customer-service";
import CustomerHistoryEntity, { CustomerApptsServicesHistory } from "../../../models/customer-history";
import { AppointmentStatus } from "../../../models/enums/appointment-status";
import { AppointmentPaymentStatus } from "../../../models/enums/appointment-payment-status";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import { CustomerHistoryProps } from "./props";
import useStyles from "./css";
import CustomerHistoryDetails from "../CustomerHistoryDetails";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";
import { selectAccountSettings, selectCommonHubConnection } from "../../../redux/store";
import useCurrencyValueTemplate from "../../../hooks/useCurrencyValueTemplate";
import { navbarActions } from "../../../redux/navbar-slice";


const CustomerHistory = (props: CustomerHistoryProps) => {

    const { customer } = props;
    const { t } = useTranslation(["general"]);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const [appointmentsEntity, setAppointmentsEntity] = useState<CustomerHistoryEntity[]>([]);
    const [appointmentsTotal, setAppointmentsTotal] = useState<number>(0);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [service, setService] = useState<CustomerApptsServicesHistory>();
    const [serviceStatus, setServiceStatus] = useState<any>();
    const [servicePaymentStatus, setServicePaymentStatus] = useState<any>();
    const [appointmentId, setAppointmentId] = useState<any>();
    const [notesSaved, setNotesSaved] = useState<boolean>(true);

    const localeApp = useAppSelector(state => state.applicationInterface.locale);

    const customerDidNotArriveTerminology = useCustomerTerminology(t("Customer did not arrive"), "Customer");
    const customerArrivedTerminology = useCustomerTerminology(t("Customer arrived"), "Customer");

    const accountSettings = useAppSelector(selectAccountSettings);
    const hubConnection = useAppSelector(selectCommonHubConnection);
    const getTemplatedCurrencyValue = useCurrencyValueTemplate(accountSettings.currencyTemplate);

    useEffect(() => {

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = () => {
        (async () => {
            if (customer && customer.id) {
                dispatch(navbarActions.setShowLoader(true));
                const values = await CustomerService.getCustomerHistory(customer.id, localeApp);
                dispatch(navbarActions.setShowLoader(false));

                if (values) {
                    setAppointmentsEntity(values.entity!);
                    setAppointmentsTotal(values.total!);
                }

            }
        })();
    }
    
    // unsubscribe
    hubConnection?.off("changeAppointmentsListPaymentStatus");
    // subscribe
    hubConnection?.on("changeAppointmentsListPaymentStatus", (appointmentIds, status) => {updateAppointmentsPaymentStatus(appointmentIds, status)});

    const toggleDrawer = (s: CustomerApptsServicesHistory, apptId: string, divStatus: any, divPaymentStatus: any) => {
        setDrawerOpen(!drawerOpen);

        if (s)
            setService(s);

        if (divStatus)
            setServiceStatus(divStatus);

        if (divPaymentStatus)
            setServicePaymentStatus(divPaymentStatus)

        if (apptId)
            setAppointmentId(apptId);
    };

    const refreshClose = (s: CustomerApptsServicesHistory) => {
        // setDrawerOpen(false);
        getData();

        if (s) setService(s);
    }

    const changedNotesWithoutSave = (saved: boolean) => {
        setNotesSaved(saved);
    }

    const DivStatus = (status: AppointmentStatus | number) => {
        if (status == AppointmentStatus.Arrived) {
            return (<div className={`${classes.divStatus} ${classes.statusArrived}`}>
                <CheckIcon style={{ color: "#36CE91", width: 14, height: 10, marginRight: 3 }} />
                {customerArrivedTerminology}
            </div>)
        }
        else if (status == AppointmentStatus.Confirmed) {
            return (<div className={`${classes.divStatus} ${classes.statusConfirmed}`}>
                <MenuIconLogoClear viewBox="0 0 16 16" style={{ color: "#0BCBE0", width: 16, height: 10, marginRight: 5, marginLeft: -4 }} />
                {t("Confirmed")}
            </div>)
        }
        else if (status == AppointmentStatus.NotArrived) {
            return (<span className={`${classes.divStatus} ${classes.statusNotArrived}`}>
                <PersonIcon style={{ color: "#F15857", width: 10, height: 10, marginRight: 5 }} />
                {customerDidNotArriveTerminology}
            </span>);
        }
        else if (status == AppointmentStatus.NotConfirmed) {
            return (<div className={`${classes.divStatus} ${classes.statusNotConfirmed}`}>
                <CrossIcon viewBox="0 0 16 16" style={{ color: "#F15857", width: 10, height: 10, marginRight: 5 }} />
                {t("Not Confirmed")}
            </div>);
        } else {
            return <div></div>
        }
    };
    
    function updateAppointmentsPaymentStatus(appointmentIds: string[], paymentStatus: AppointmentPaymentStatus) {
        setAppointmentsEntity(prevAppointmentsEntity => {
            return prevAppointmentsEntity.map(monthData => {
                const updatedAppointments = monthData.appointments.map(appointment => {
                    if (appointmentIds.includes(appointment.id)) {
                        return { ...appointment, paymentStatus: paymentStatus, };
                    }
                    return appointment;
                });
                return { ...monthData, appointments: updatedAppointments, };
            });
        });
    }

    const DivPaymentStatus = (status: AppointmentPaymentStatus | number) => {
        if (status === AppointmentPaymentStatus.Paid) {
            return (<div className={`${classes.divStatus} ${classes.statusPaid}`}>
                {t("Paid")}
            </div>)
        }
        else if (status === AppointmentPaymentStatus.Unpaid || status === AppointmentPaymentStatus.PaidPartially) {
            return (<span className={`${classes.divStatus} ${classes.statusUnpaid}`}>
                {t("Unpaid")}
            </span>);
        } else {
            return <div></div>
        }
    };

    const DivNotes = (service: CustomerApptsServicesHistory | null, apptId: string, divStatus: any, divPaymentStatus: any) => {
        if (service?.notes) {
            return (<div className={`${classes.divNotes} ${classes.divNotesExists}`} onClick={() => toggleDrawer(service, apptId, divStatus, divPaymentStatus)} >
                <NoteIcon style={{ color: "#6462F3", width: 14, height: 10, marginRight: 0 }} color={"#6462F3"} />
            </div>)
        } else {
            return (<div className={`${classes.divNotes} ${classes.divNotesNone}`} onClick={() => toggleDrawer(service!, apptId, divStatus, divPaymentStatus)}>
                <NoteIcon style={{ color: "#919CA5", width: 14, height: 10, marginRight: 0 }} />
            </div>)
        }
    };

    return (<div className={classes.customerHistoryPanelContent}>

        <div className={classes.customerHistoryTitle}>
            <CalendarIcon style={{ color: "#919CA5" }} />
            <Typography className={classes.iconTitle}>
                {t("Appointments")} ({appointmentsTotal})
            </Typography>
        </div>

        <div className={classes.customerHistoryContent}>
            {
                appointmentsEntity.map((history, indexHistory) =>
                (<Accordion key={indexHistory} defaultExpanded={true} className={classes.accordion}  >

                    <AccordionSummary
                        className={classes.accordionSummary}
                        classes={{
                            expandIcon: classes.expandIcon,
                            expanded: classes.expanded
                        }} >
                        <div className={classes.monthYear} >
                            <b>{history.monthYear}</b>
                            {" (" + history.appointments.length + ")"}
                            <ArrowDropDownIcon className={classes.ArrowIcon} style={{ width: 15, height: 15 }} />
                        </div>
                    </AccordionSummary>

                    <AccordionDetails className={classes.accordionDetails} >

                        <div className={classes.appointmentsGrid}>
                            {
                                history.appointments.map((appointments, indexAppointments) =>
                                (<div key={appointments.id} className={classes.appointmentsBox}>

                                    <Grid container spacing={2} className={classes.appointmentsGrid}>
                                        <Grid item xs={3}>
                                            <Typography className={classes.headerAppointments}>
                                                {t("Service & specialist")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className={classes.headerAppointments}>
                                                {t("Date & Time")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={2}>
                                            <Typography className={classes.headerAppointments}>
                                                {t("Price")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={3}>
                                            <Typography className={classes.headerAppointments}>
                                                {t("Status")}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={1}>
                                            <Typography className={classes.headerAppointments}>
                                                {t("NotesDetails")}
                                            </Typography>
                                        </Grid>

                                    </Grid>

                                    {
                                        appointments.services.map((service, indexService) =>
                                        (<div key={indexService}>
                                            <Grid
                                                key={indexService}
                                                container
                                                spacing={2}
                                                className={`${classes.appointmentsGrid} ${appointments.status === AppointmentStatus.NotArrived ? classes.apptDisabledGrid : ""}`}
                                            >
                                                <Grid item xs={3}>
                                                    <Typography className={classes.serviceName}>
                                                        <b>{service.name}</b>
                                                    </Typography>
                                                    <Typography className={classes.serviceName}>
                                                        {service.specialist}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    <Typography className={classes.serviceDate}>
                                                        {
                                                            new Date(service.start).toLocaleDateString(
                                                                localeApp,
                                                                {
                                                                    month: "long",
                                                                    day: "numeric",
                                                                })
                                                            + " " + new Date(service.start).getFullYear()
                                                        }
                                                        <br />
                                                        {moment(service.start).format("HH:mm") + " - " + moment(service.end).format("HH:mm")}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={2}>
                                                    <Typography className={classes.servicePrice}>
                                                        {service.price ? getTemplatedCurrencyValue(service.price) : "--"}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={3}>
                                                    {DivStatus(appointments.status)}
                                                    {DivPaymentStatus(appointments.paymentStatus)}
                                                </Grid>
                                                <Grid item xs={1}>
                                                    {DivNotes(service, appointments.id, DivStatus(appointments.status), DivPaymentStatus(appointments.paymentStatus))}
                                                </Grid>
                                            </Grid>
                                            {
                                                (indexService !== appointments.services.length - 1) && <div className={classes.appointmentsGridLine}></div>
                                            }

                                        </div>))
                                    }
                                    
                                    {appointments?.recurringAppointmentOrder &&
                                      <div className={classes.recurringAppointment}>
                                          {`${t("Recurring appointment")} (${appointments?.recurringAppointmentOrder}/${appointments?.recurringAppointmentCount})`}                                            </div>
                                    }
                                </div>))
                            }

                        </div>

                    </AccordionDetails>

                </Accordion>)
                )
            }
        </div>

        {service && <>
            <Drawer
                open={drawerOpen}
                anchor="right"
                classes={{ paper: classes.drawerHistoryDetails }}
                onClose={() => { if (notesSaved) setDrawerOpen(false) }}
            >

                <CustomerHistoryDetails
                    serviceHistory={service}
                    appointmentId={appointmentId}
                    serviceStatus={serviceStatus}
                    servicePaymentStatus={servicePaymentStatus}
                    onChangedWithoutSave={changedNotesWithoutSave}
                    onClose={() => setDrawerOpen(false)}
                    onRefresh={refreshClose} />

            </Drawer>
        </>}

    </div>);
};

export default CustomerHistory;
