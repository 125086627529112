import { SettingsFieldUpdate } from "./enums/settings-field-update";
import { EnterpriseSettingsFieldUpdate } from "./enums/enterprise-settings-field-update";

export class EnterpriseInfo {
    name: string;
    logo?: string;
    fieldUpd: EnterpriseSettingsFieldUpdate;
    constructor(x?: EnterpriseInfo) {
        if (typeof x === "object" && x !== null) {
            this.name = x.name || "";
            this.logo = x.logo || "";
            this.fieldUpd = x.fieldUpd || EnterpriseSettingsFieldUpdate.None;
        }
        else {
            this.name = "";
            this.logo = "";
            this.fieldUpd = EnterpriseSettingsFieldUpdate.None;
        }
    }
}
