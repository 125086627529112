import React from "react";

import { createSlice } from "@reduxjs/toolkit";

interface EnterpriseState {
    selectedEstablishments: string[] | undefined;
    settingsEstablishmentId: string | undefined;
}

const initialState: EnterpriseState = {
    selectedEstablishments: undefined,
    settingsEstablishmentId: undefined
};


const enterpriseSlice = createSlice({
  name: 'enterprise',
  initialState: initialState,
  reducers: {
    setSelectedEstablishments(currentState, action) {
      currentState.selectedEstablishments = action.payload;
      },
    setSettingsEstablishmentId(currentState, action) {
      currentState.settingsEstablishmentId = action.payload;
    },
  }
});

export const enterpriseActions = enterpriseSlice.actions;
export default enterpriseSlice.reducer;