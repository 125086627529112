import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    width: "100%",
  },
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    width: "100%",
    justifyContent: "flex-end",
  },
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  importCustomer: {
    border: "1px solid #6462F3",
    marginRight: 25,
    color: "#6462F3",
  },
  newCustomer: {
    background: "#7175FA",
    color: "#F6F6F6",
    "&:hover": {
      background: "#6462F3",
    },
  },
}));

export default useStyles;
