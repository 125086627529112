import { makeStyles } from "@material-ui/core/styles";

import { Color } from "../../../../../constants/colors";

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "gray",
  },
  section: {
    marginTop: 30,
  },
  title: {
    fontSize: "14px",
    color: Color.gray5,
    marginBottom: 20,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  titleSection: {
    fontSize: 12,
    color: Color.gray4,
    marginBottom: 10,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  selectTimezone: {
    ...theme.typography.body2,
    fontWeight: "bold",
    marginTop: 10,
    marginBottom: 30,
  },
  headerDays: {
    fontSize: 12,
    color: Color.gray4,
    marginBottom: 5,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
  },
  bodyBackground: {
    backgroundColor: "#fff",
  },
  divArea: {
    backgroundColor: "#fff",
    borderRadius: 20,
    border: "1px solid #dbdcdd",
    padding: "40px",
    margin: "20px 0",
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "24px 0px 0px 0px",
  },
  label: {
    display: "flex",
    margin: 0,
    color: "#4a4d51",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    marginBottom: 5,
  },
  required: {
    color: "#6462F3",
    marginLeft: 5,
    fontWeight: "bold",
    fontSize: 14,
  },
  optional: {
    fontWeight: "normal",
    marginLeft: 10,
  },
  description: {
    margin: 0,
    color: "#919CA5",
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: "normal",
  },
  iconMoney: {
    color: "#6462F3",
    paddingTop: 4,
    fontSize: 30,
    fontWeight: "bolder",
  },
  labelH2: {
    display: "flex",
    paddingTop: 7,
    color: "#5C6477",
    fontSize: 14,
    lineHeight: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    marginBottom: 5,
  },
  inputClass: {
    color: Color.gray5,
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  switch: {
    display: "flex",
    alignItems: "center",
  },
  switchLabel: {
    margin: "0px 0px 0px 16px",
    display: "inline-flex",
    verticalAlign: "middle",
  },
  switchRoot: {
    width: 32,
    height: 21,
    padding: 0,
  },
  switchBase: {
    padding: 1,
    opacity: 1,
    height: "100%",
    border: "3px solid #323947",
    "&.Mui-checked": {
      transform: "translateX(13px)",
      border: "0px",
      color: Color.white, //theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: Color.green,
        opacity: 1,
        border: "none",
      },
      "& * .MuiSwitch-thumb": {
        width: 15,
      },
      //
    },
    "&$focusVisible .MuiSwitch-thumb": {
      color: "#6462F3",
      border: "6px solid #fff",
    },
    "& * .MuiSwitch-thumb": {
      width: 12,
      height: 15,
      boxShadow: "none",
    },
  },
  switchThumb: {
    width: 12,
    height: 15,
    boxShadow: "none",
  },
  switchTrack: {
    borderRadius: 23 / 2,
    border: "3px solid #323947",
    backgroundColor: theme.palette.grey[50],
    opacity: 1,
    // .MuiSwitch-track
  },
  switchChecked: {},
  focusVisible: {},
  rowWeekDays: {
    height: 60,
  },
  dayLabel: {
    fontFamily: "Inter",
    width: 100,
    color: Color.gray5,
    fontSize: 14,
    display: "inline",
    fontWeight: "bold",
  },
  headerLabel: {
    fontFamily: "Inter",
    fontStyle: "normal",
    color: Color.gray4,
    fontSize: 12,
    fontWeight: "bold",
  },
  selectStyle: {
    color: Color.gray5,
    fontWeight: "bold",
    fontSize: 12,
    height: 40,
    padding: "13px 0px 13px 12px",
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
    width: 200,
    paddingRight: 10,
    backgroundColor: "#F6F6F6",
    cursor: "pointer",
    "& * .ant-picker-suffix, .ant-picker-clear": {
      display: "none",
    },
    "& * input": {
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: 12,
      fontStyle: "normal",
      color: Color.gray5,
      cursor: "pointer",
    },
  },
  selectHrs: {
    "& div:after": {
      content: "'hrs'",
      marginLeft: "35px",
      marginBottom: "2px",
      position: "absolute",
      color: Color.gray5,
    },
  },
  selectHrsClose: {
    "& div:after": {
      content: "'hrs'",
      color: "rgba(0, 0, 0, 0.25)",
      marginLeft: "35px",
      marginBottom: "2px",
      position: "absolute",
    },
  },
  selectStyleHrs: {
    position: "relative",
    top: 1,
    left: -150,
    fontFamily: "Roboto",
    fontWeight: "bold",
    fontSize: 12,
    fontStyle: "normal",
  },
  multipleAppointments: {
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: theme.colors.grey5,
  },
  iconTooltipRoot: {
    display: "inline-block"
  },
  titleWithTooltipContainer: {
    display: "flex",
    flexDirection: "row",
    gap: 7
  }
}));
