import { createSlice } from '@reduxjs/toolkit';

const localeStorage = localStorage.getItem('locale') ?? process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'es';

const initialApplicationInterfaceState = {
    title: "Clear",
    isAccountOverdue: false,
    locale: localeStorage,
    isWideLayout: false
};

const applicationInterfaceSlice = createSlice({
    name: 'applicationInterface',
    initialState: initialApplicationInterfaceState,
    reducers: {
        updateTitle(currentState, action) {
            currentState.title = action.payload;
        },
        updateAccountOverdue(currentState, action) {
            currentState.isAccountOverdue = action.payload;
        },
        updateLocale(currentState, action) {
            currentState.locale = action.payload;
        },
        updateIsWideLayout(currentState, action) {
            currentState.isWideLayout = action.payload;
        }
    }
});

export const applicationInterfaceActions = applicationInterfaceSlice.actions;
export default applicationInterfaceSlice.reducer;