export default class ConversationsCounterModel {
    availableConversationsCount: number;
    monthlyConversationsLimit: number;

    /**
     *
     */
    constructor(x?: any) {
        if (typeof x === "object") {
            this.availableConversationsCount = x.availableConversationsCount;
            this.monthlyConversationsLimit = x.monthlyConversationsLimit;
        } else {
            this.availableConversationsCount = 0;
            this.monthlyConversationsLimit = 1000;
        }
    }
}