export enum StaticTreatmentDocumentFieldType {
    SpecialistFullName = 1,
    SpecialistPhoneNumber,
    PatientFullName,
    AppointmentDate,
    PatientAge,
    PatientGender,
    VitalSigns,
    Diagnosis,
    Treatment,
    SpecialistSign
}