import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
    sectionNotes:{
      backgroundColor: theme.colors.clearLilac,
      borderRadius: 5,
      padding: "20px 30px",
      marginBottom: 32
    },
    sectionNotesTitle: {
      color: theme.colors.clearMain,
      fontSize: 12,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 700,
      wordWrap: "break-word"
    },
    sectionNotesDescription: {
      color: theme.colors.grey5,
      fontSize: 12,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      wordWrap: "break-word"
    },
}));

export default useStyles;