import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { DateTime } from 'luxon';

import { CrossIcon, ExclamationIcon } from "../../../../assets/icons";

import { DragNDropConfirmationModalProps } from "./props";
import useStyles from "./css";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import {useAppSelector} from "../../../../redux/hooks";


const DragNDropConfirmationModal = (props: DragNDropConfirmationModalProps) => {
  const { open, appointment, toDateTime, onClose, onContinue } = props;
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  
  const optionUpdateOnlyThisAppointment = 0;
  const optionUpdateThisAndFutureAppointment = 1;
  const [selectedOptionToUpdate, setSelectedOptionToUpdate] = useState<number>(-1);
  const [disableButton, setDisableButton] = useState<boolean>(false);

  const date = (startDate: Date, endDate: Date): string => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    let weekDay = start.toLocaleString(localeApp, {
      weekday: "long",
    });

    weekDay = `${weekDay.charAt(0).toUpperCase()}${weekDay.slice(1)}`;

    const monthDay = start.toLocaleString(localeApp, {
      day: "2-digit",
      month: "long",
    });

    const startTime = start.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    const endTime = end.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    return `${weekDay}, ${monthDay} \u00B7 ${startTime} - ${endTime}hrs`;
  };

  const timeDifference = (today: Date, endDate: Date): string => {
    const hours = parseInt(
      `${
        (Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60 * 60)) % 24
      }`
    );
    const minutes = parseInt(
      `${(Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60)) % 60}`
    );
    if (hours > 0 && minutes < 1) {
      return `${hours}hr`;
    } else if (hours < 1 && minutes > 0) {
      return `${minutes}min`;
    } else {
      return `${hours}hr ${minutes}min`;
    }
  };
  
  const getNewEndDateTime = () => {
    const duration = appointment.endDate.diff(appointment.startDate);
    return DateTime.fromJSDate(toDateTime).plus(duration).toJSDate();
  }
  
  const handleUpdateOption = (event: any) => {
    setSelectedOptionToUpdate(+event.target.value);
  }

  useEffect(() => {
    setSelectedOptionToUpdate(-1);
    setDisableButton(false);
  }, [open]);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <div className={classes.modalHeader}>
            <div className={classes.modalClose}>
              <CrossIcon
                className={classes.closeButton}
                onClick={onClose}
                viewBox="0 0 14 14"
                style={{ width: 14, height: 14 }}
              />
            </div>
            <div className={classes.modalHeaderContent}>
              <div className={classes.modalCircle}>
                <ExclamationIcon viewBox="0 0 5 16" />
              </div>
            </div>
          </div>
          <div className={classes.modalContent}>
            <div
              className={`${classes.modalCenter} ${classes.modalTitleSpace}`}
            >
              <Typography className={classes.modalTitleText}>
                {appointment.isRecurring ? t("Move recurring appointment?") : ""}
                {!appointment.isRecurring ? t("Move this appointment?") : ""}
              </Typography>
            </div>
            <div className={classes.modalCenter}>
              <div className={classes.separator} style={{ width: 217 }}></div>
            </div>
            <div className={classes.modalCenter}>
              <Typography className={classes.modalContentText}>
                {appointment.customerName} - {appointment.services[0]}
              </Typography>
            </div>
            <div className={classes.modalDateTextContainer}>
              <Typography className={classes.modalDateText}>
                <span className={classes.fromToLabel}>{t("From")}:</span>&nbsp;
                {`${date(
                  appointment.startDate.toJSDate(),
                  appointment.endDate.toJSDate()
                )} (${timeDifference(
                  appointment.startDate.toJSDate(),
                  appointment.endDate.toJSDate()
                )})`}
              </Typography>
              <Typography className={classes.modalDateText}>
                <span className={classes.fromToLabel}>{t("ToA")}:</span>&nbsp;
                {`${date(
                  toDateTime, getNewEndDateTime()
                )} (${timeDifference(
                  appointment.startDate.toJSDate(),
                  appointment.endDate.toJSDate()
                )})`}
              </Typography>
            </div>
  
            {appointment.isRecurring &&
              <div style={{ textAlign: "center", marginTop: 15 }}>
                <div style={{ display: "inline-block" }}>
                  <RadioGroup
                    defaultValue={-1}
                    aria-label="appointmentEnds"
                    name="appointmentEnds"
                    onChange={handleUpdateOption}
                    value={selectedOptionToUpdate}
                    row
                  >

                    <FormControlLabel
                      classes={{
                        root: classes.radioLabelRoot,
                        label: classes.radioLabel,
                      }}
                      value={optionUpdateOnlyThisAppointment}
                      control={
                        <Radio
                          className={classes.radioOption}
                        />
                      }
                      label={t("This appointment")}
                    />

                    <FormControlLabel
                      classes={{
                        root: classes.radioLabelRoot,
                        label: classes.radioLabel,
                      }}
                      value={optionUpdateThisAndFutureAppointment}
                      control={
                        <Radio
                          className={classes.radioOption}
                        />
                      }
                      label={t("This and the following appointments")}
                    />

                  </RadioGroup>
                </div>
              </div>
            }

            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={onClose}
              >
                {t("Go back")}
              </Button>
              <Button
                disabled={disableButton || appointment.isRecurring && selectedOptionToUpdate===-1}
                className={`${classes.button} ${classes.saveAppointment}`}
                onClick={() => {
                  setDisableButton(true);
                  onContinue(selectedOptionToUpdate === optionUpdateThisAndFutureAppointment);
                }}
              >
                {t("Save changes")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default DragNDropConfirmationModal;
