import React, { createContext, useContext, useState } from 'react';

export type KeyGetterFunction<T> = (value: T) => string;

export function normalizeGroupKey(k: string) {
    return 'g-' + b64EncodeUnicode(k).replaceAll('=', '_');
}

export function getGroupKeyOffsetVariable(k: string) {
    return `--cmos-aps--x-${normalizeGroupKey(k)}`;
}

function b64EncodeUnicode(str: string) {
    // first we use encodeURIComponent to get percent-encoded UTF-8,
    // then we convert the percent encodings into raw bytes which
    // can be fed into btoa.
    return btoa(
        encodeURIComponent(str).replace(/%([0-9A-F]{2})/g, function toSolidBytes(match, p1) {
            return String.fromCharCode(+('0x' + p1));
        })
    );
}

export type AppointmentSchedulerContextData = {
    from: number;
    to: number;
    pixelsPerMinute: number;
    groups: unknown[];
    rowHeight: number;
    minColumnWidth: number;
    columnWidth: number;
    headerHeight: number;
    timelineWidth: number;
    innerWidth: number;
    innerHeight: number;
};

export const AppointmentSchedulerContext =
    React.createContext<AppointmentSchedulerContextData | null>(null);

export function useAppointmentSchedulerContext(): AppointmentSchedulerContextData {
    const data = useContext(AppointmentSchedulerContext);
    if (data === null) throw new Error('could not find AppointmentSchedulerContextData');
    return data;
}

export function generateStops(from: number, to: number, intervalInMinutes: number) {
    const stops: number[] = [];
    let stop = from;
    do {
        stops.push(stop);
        stop += intervalInMinutes;
        // given intervalInMinutes = 30 and from = 0 and to = 24 * 60 (24:00 time)
        // last stop will be 23 * 60 + 30 or in other words 23:30
    } while (stop + intervalInMinutes <= to);
    return stops;
}

export const AppointmentSchedulerRootElementContext = createContext<HTMLElement | null>(null);

type InnerElementContext = {
    element: HTMLElement | null;
    setInnerElement(element: HTMLElement | null): void;
};

/**
 * context for passing "inner" element (i.e. the element where all grid cells and appointments are)
 * to all children of the scheduler, can be used to subscribe to scrolling of the inner
 * element to synchornize scrolling between inner element and timeline/header
 *
 * we can probably do the same with props instead of context, but context is easier to work with IMO
 */
export const AppointmentSchedulerInnerElementContext = createContext<InnerElementContext>({
    element: null,
    setInnerElement: () => {},
});

export function AppointmentSchedulerInnerElementContextProvider({
    children,
}: React.PropsWithChildren<{}>) {
    const [element, setInnerElement] = useState<HTMLElement | null>(null);

    return (
        <AppointmentSchedulerInnerElementContext.Provider value={{ element, setInnerElement }}>
            {children}
        </AppointmentSchedulerInnerElementContext.Provider>
    );
}
