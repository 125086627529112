export enum ColumnGroupNameId {
  Appointments = "7998866e-9e64-4266-9fc3-f056756d4025",
  Customer = "860a48fe-d46d-4f61-a9d5-ab3314cedc9b",
  Specialist = "c429a37d-0cce-4e1e-8243-4791825ab33c",
  Commissions = "2f21c735-b174-4113-9247-8fe8fe5c1ee7",
  Payments = "80335ed6-f2d7-4f71-a0ea-811daa56e487",
  Services = "8036b1bf-3bd9-4e58-a630-992e9a4e4335",
  GeneralInformation = "303e5ed5-95da-46c0-bc22-db42089a8695",
}

export enum ColumnNameId {
  // Appointments
  Appointment = "24a7305c-875c-418d-a85f-42a0078ef3b8",
  AppointmentStatus = "22fa4585-cf52-44ab-80e0-b137f2d2ab16",
  PaymentStatus = "70af27d9-1da0-49f3-b3bc-353cc3f88256",
  AppointmentDate = "e02b5859-5941-4fd5-b4c8-5229ca0a357a",
  AppointmentTime = "43bdbd03-4474-44cc-9e37-8a9f969281e3",
  AppointmentCreatedBy = "5b653b33-f075-4788-8f0b-b5a672f0b113",
  RecurringAppointment = "dafdc3dd-5572-4ca2-b304-0a03259c2e7f",

  // Customer
  Customer = "3fca98b7-2606-4256-ba86-21f98163534e",
  CustomerMobile = "a195db5b-61e0-4432-b997-1ec98cbd6bcd",
  CustomerGender = "d1e5e8bb-0223-40c2-9426-8136165c7c0a",
  CustomerContactableForAppointments = "df73d3b4-15f8-4975-9144-c6c9e5fc8e46",
  CustomerContactableForMassiveSendings = "355c86eb-25c9-483e-9daf-8212f7296d0d",
  
  // Specialist
  Specialist = "5239e9a8-6407-4826-abcd-04c8ae2246f4",
  SpecialistID = "26749ec1-804e-4125-ba4c-669f2a079858",
  SpecialistEmail = "a2dce254-ef17-4069-9ad6-e368b1e8f971",
  SpecialistJobTitle = "26f8543a-527c-4803-acbb-733cd402bda0",
  
  // Commissions
  CommissionPerService = "af847f96-0ab6-436c-8aad-0818b32280ab",
  CommissionAmount = "eb2b51f0-aee6-42cb-8fd6-2172ccbbc191",
  
  // Payments
  PaymentID = "09739a33-5089-44a5-a20e-069bbd4467d3",
  UserWhoRegisteredThePayment = "1a153857-8b48-457e-a5a4-1531d5ab91d9",
  PaymentDate = "2fe5bd5b-b27a-479e-a8fb-041533bee854",
  PaymentTime = "eb251570-4e8d-4ddf-99c6-a8d315b11a97",
  PaymentMethod = "7a88309c-c600-4633-ae5e-0ab086660187",
  TotalToPay = "1d5b5bf0-94ad-45cb-b983-21f640956d12",
  AmountPaid = "54f4c1f8-3d33-4e4d-9b16-d3ae6b9567eb",
  AmountDue = "c75a8ee2-c5eb-4333-8989-32a66b1d171e",
  TotalToPayRecurringAppt = "0cd182d5-2016-40b7-979a-c0d632e14838",
  AmountPaidRecurringAppt = "fb2c007e-42f6-4926-8b75-9e2e3aa4572f",
  AmountDueRecurringAppt = "49adebfe-5aa2-4c88-8071-e361d96e8306",

  // Services
  ServiceName = "e3b7f7a4-8f7d-406f-b419-d3aa28de2c18",
  ServicePrice = "4f2f473a-f752-4b5b-b861-c3d80fe5cf87",
  ServiceCategory = "f23191ce-1edc-4f3c-a1bb-af1c365acf0e",
  ServiceDuration = "90e2bb8b-e001-4525-bd3a-277232df0085",
  ServiceRecurrence = "8ca824e7-3df8-4157-b54d-a9321f14b5c1",
  ServiceWorkArea = "50df2d35-ce52-4611-9e42-6372bf8626d1",
  
  // General Information
  Establishment = "d9c6dca2-85ff-4b9d-874b-501952df5738",
}
