import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

const useStyles = (firstItemComponentHeight: number) => makeStyles(() => ({
  root: {
    width: 320,
    "& .MuiAccordion-root": {
      border: "1px solid " + Color.gray2,
      borderRadius: 10,
    },
    "& .MuiAccordionSummary-root": {
      padding: "5px 15px",
      background: Color.gray1,
      position: "static",
      borderRadius: 10,
      transition: "0.5s",
      height: `calc(48px + ${firstItemComponentHeight}px)`
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiAccordionSummary-content.Mui-expanded": {
      margin: 0
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none"
    },
    "& .MuiAccordionSummary-expandIcon": {
      position: "absolute",
      top: "5px",
      right: "18px"
    },
    "& .MuiAccordionDetails-root": {
      padding: "0px 16px 16px 16px"
    },
    "& .MuiButtonBase-root": {
      cursor: "pointer !important"
    },
    "& .MuiAccordionSummary-root:hover:not(.Mui-disabled)": {
      cursor: "default !important"
    }
  },
  headerTitleContainer: {
    background: Color.gray1,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 8,
    height: 48,
    position: "absolute",
    top: 0
  },
  headerLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.clear2,
  },
  allItemsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  headerItemContainer: {
    maxWidth: "100%",
    background: Color.white,
    position: "absolute",
    top: 48,
    left: 0,
    right: 0,
    height: firstItemComponentHeight,
    borderRadius: "0px 0px 10px 10px",
    padding: 16
  },  
  headerButton: {
    "&.MuiButton-root": {
      minWidth: 0
    },
  },
  divFlag: {
    display: "inline-block",
    position: "relative",
    height: 20,
    width: 20,
    "& svg": {
      width: "20px !important",
      height: "20px !important"
    }
  },
  divCode: {
    position: "relative",
    display: "inline-block",
    fontStyle: "normal",
    fontFamily: "Inter, sans-serif",
    fontWeight: "normal",
    width: 40,
    textAlign: "center",
    fontSize: 14
  },
  divMobile: {
    display: "inline-block",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontSize: 14,
  },
  itemTitleLabel: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: Color.gray4,
  },
  itemValueLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.gray5,
  },
  divider: {
    height: 0,
    borderTop: "1px solid " + Color.gray2,
    opacity: "0.5",
    marginBottom: "16px",
  }
}));

export default useStyles;