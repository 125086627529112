import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function PatientFemaleIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 16, height: 16, ...style }}
            viewBox={viewBox || "0 0 16 16"}
        >
            <path
                d="M8 2C5.79108 2 4 3.72598 4 5.85462C4 7.73213 5.39376 9.29643 7.23824 9.63941V10.8802H5.52274V12.3476H7.23823V14H8.76098V12.3476H10.4765V10.8802H8.76099V9.6394C10.6058 9.29671 12 7.73238 12 5.85461C12 3.72598 10.2089 2 8 2ZM8 3.53278C9.33067 3.53278 10.4094 4.57231 10.4094 5.85462C10.4094 7.13693 9.33068 8.17647 8 8.17647C6.66932 8.17647 5.59059 7.13693 5.59059 5.85462C5.59059 4.57231 6.66933 3.53278 8 3.53278Z"
                fill={style?.color || Color.red}
            />
        </SvgIcon>
    );
}