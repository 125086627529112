import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function SideArrowForMessageIcon(props: any) {
  const { style, viewBox, color, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 22, height: 18, ...style }}
      viewBox={viewBox || "0 0 22 18"}
    >
      <path
        d="M11.55 17.5L0 0H22L11.55 17.5Z"
        fill={color ?? "#FFFFFF"}
      />
    </SvgIcon>
  );
}