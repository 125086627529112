import { makeStyles } from "@material-ui/core/styles";
import { Clear } from "@material-ui/icons";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        alignItems: "center",
        marginBottom: 24,
        width: "100%",
        gap: 20
    },
    tabTitle: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: Color.gray5
    },
    newButton: {
        marginLeft: "auto",
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "space-between",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        background: Color.clear2,
        color: Color.gray1,
        "&:hover": {
            background: Color.clear1,
        },
    },
    footerContainer: {
        paddingTop: 10,
        paddingBottom: 80
    },
    footerNoteLabel: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "14px",
        color: Color.gray5,
    },
    footerNoteLabelBold: {
        fontWeight: 700,
    },
    footerNoteLabelLink: {
        textDecoration: "underline",
        fontWeight: 700,
        color: Color.clear1,
        "&:hover": {
            color: Color.blue
        }
    },
    previewButton: {
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 12,
        border: "1px solid " + Color.clear1,
        color: Color.clear1,
    }
}));

export default useStyles;