import { ApiClient } from "./api-client";

import {UsersCommissionsResponse} from "../models/users-commissions-response";
import {UserCommissionInfo} from "../models/user-commission-info";
import {UserCommissionEntity} from "../models/user-commission-entity";
import {UserEntity} from "../models/user-entity";

export class CommissionService {
  private static baseUrl: string = "/commission";

  static getUsersCommissions(
    page: number,
    pageSize: number,
    establishmentAccountId?: string,
    order?: string,
    orderBy?: string
  ): Promise<UsersCommissionsResponse> {
    let urlEndpoint = `${this.baseUrl}/getbyaccount?page=${page}&pageSize=${pageSize}`;
    if (order && orderBy) {
      urlEndpoint += `&order=${order}&orderBy=${orderBy}`;
    }
    if (establishmentAccountId) {
      urlEndpoint += `&accountId=${establishmentAccountId}`;
    }

    return ApiClient.get(urlEndpoint).then((data) => new UsersCommissionsResponse(data));
  }

  static getUserCommissionsById(userId: string, establishmentAccountId?: string): Promise<UserCommissionInfo> {
    let urlEndpoint = `${this.baseUrl}/get?userId=${userId}`;
    if (establishmentAccountId) {
      urlEndpoint += `&accountId=${establishmentAccountId}`;
    }
    return ApiClient.get(urlEndpoint).then(
      (data) => new UserCommissionInfo(data.userCommission));
  }

  static updateUserCommission(userId: string, userCommission: UserCommissionInfo, establishmentAccountId?: string): Promise<UserCommissionInfo> {
    const user = new UserCommissionEntity(userId, userCommission,establishmentAccountId);
    const request = { accountId: establishmentAccountId, userId: userId, user: user}
    return ApiClient.put(`${this.baseUrl}/update`, user).then(
      (data) => data.userCommission
    );
  }
}
