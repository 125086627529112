import { makeStyles } from '@material-ui/core';
import { scrollbarStyle } from '../../styles/ScrollbarStyles';

const useAppointmentItemListStyles = makeStyles((theme) => ({
    root: {
        width: 420,
        marginTop: 47,
        padding: '0 0 20px 0',
        flexDirection: 'column',
        justifyContent: 'flex-end',
        alignItems: 'center',
        boxSizing: 'border-box',
        height: '100%',
        overflowY: 'hidden',
        borderRadius: 0,
        fontFamily: "Inter, sans-serif"
    },

    header: {
        margin: 0,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        height: 52,
        paddingLeft: 24,
        paddingRight: 11,
        backgroundColor: theme.colors.grey5,
    },

    title: {
        color: '#fff',
        fontSize: 18,
        fontWeight: 700,
        lineHeight: "22px"
    },

    list: {
        marginTop: 20,
        padding: '0 14px 0 0',
        maxHeight: 'calc(100vh - 150px)',
        overflowY: 'auto',
        overflowX: 'hidden',
        ...scrollbarStyle(),
    },

    labelContainer: {
        display: 'flex',
        alignItems: 'baseline',
        marginBottom: 10,
        gap: 4,
        flexDirection: 'row',
        justifyContent: 'flex-start',
    },

    label: {
        padding: 0,
        margin: 0,
        fontSize: 14,
        fontWeight: 700,
        color: theme.colors.clear3,
    },

    timezone: {
        padding: 0,
        margin: 0,
        fontSize: 10,
        fontWeight: 700,
        color: theme.colors.grey5,
    },

    item: {
        display: 'flex',
        marginBottom: 17,
        flexDirection: 'column',
        width: '100%',
        height: 'auto',
        '&:last-child': {
            marginBottom: 20,
        },
    },

    wrapper: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        padding: '0 13px 0 25px',
    },
}));

export default useAppointmentItemListStyles;
