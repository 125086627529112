import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const StopHandIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 24, height: 24, marginLeft: -5, ...style }}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path
        d="M24 5.5V20C24 22.2 22.0364 24 19.6364 24H11.6727C10.4945 24 9.38182 23.57 8.56364 22.81L0 14.83C0 14.83 1.37455 13.6 1.41818 13.58C1.65818 13.39 1.95273 13.29 2.28 13.29C2.52 13.29 2.73818 13.35 2.93455 13.45C2.97818 13.46 7.63636 15.91 7.63636 15.91V4C7.63636 3.17 8.36727 2.5 9.27273 2.5C10.1782 2.5 10.9091 3.17 10.9091 4V11H12V1.5C12 0.67 12.7309 0 13.6364 0C14.5418 0 15.2727 0.67 15.2727 1.5V11H16.3636V2.5C16.3636 1.67 17.0945 1 18 1C18.9055 1 19.6364 1.67 19.6364 2.5V11H20.7273V5.5C20.7273 4.67 21.4582 4 22.3636 4C23.2691 4 24 4.67 24 5.5Z"
        fill={style?.color || "#FFC626"}
      />
    </SvgIcon>
  );
};

export default StopHandIcon;
