import React from "react";

import { Checkbox, FormControlLabel } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import useStyles from "./css";
import { CircleCheckboxProps } from "./props";

export default function CircleCheckbox(props: CircleCheckboxProps) {
  const { checked, setChecked, label, tooltipTitle } = props;
  const classes = useStyles();
  
  function handleChecking() {
    setChecked(!checked);
  }
  
  const CheckboxComponent = 
    <Checkbox
      checked={checked}
      onChange={handleChecking}
      hidden={true}
    />
  
  return (
      <Tooltip
        title={tooltipTitle}
        classes={{ tooltip: classes.tooltipBlock }}
      >
        <FormControlLabel
          control={CheckboxComponent}
          label={label}
          classes={{
            label: checked 
              ? classes.checkedIcon
              : classes.icon
          }}
        />
      </Tooltip>
  );
}