import React, { useEffect } from "react";

import { useHistory, useLocation } from "react-router-dom";

import { useTranslation } from "react-i18next";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { applicationInterfaceActions } from "../../../redux/application-interface-slice";

import Typography from "@material-ui/core/Typography";

import { useStyles } from "./css";


export interface NavTitle {
  label: string;
  path: string;
}


export default function Title() {
  
  const classes = useStyles();
  
  const { t } = useTranslation(["general", "nav", "sections"]);
  
  const location = useLocation();
  
  const dispatch = useAppDispatch();
  const titleNav = useAppSelector(state => state.applicationInterface.title);

  const navTitles: NavTitle[] = [
    { label: t('Appts'), path: "/appointments" },
    { label: t('New appointment'), path: "/appointments/new" },
    { label: useCustomerTerminology(t("Customers"), "Customers"), path: "/customers" },
    { label: t("Conversations"), path: "/whatsapp" },
    { label: t("Massive sendings"), path: "/massive-sendings" },
    { label: t('Settings'), path: "/settings" },
    { label: t('Reports'), path: "/reports" },
  ];

  useEffect(() => {
    const menuItem = navTitles.find(x => x.path === location.pathname);
    if (menuItem) {
      const titleUrl = menuItem.label || "Clear";
      dispatch(applicationInterfaceActions.updateTitle(titleUrl));
    }
  }, [location.pathname]);

  return (
    <Typography variant="h6" className={classes.title}>
      {titleNav}
    </Typography>
  );
};