import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { conformToMask } from "react-text-mask";

import { Customer } from "../../../models/customer";
import { Gender } from "../../../models/enums/gender";
import StatusIcon from "../../../assets/icons/StatusIcon";
import { CustomerService } from "../../../api/customer-service";
import GridList, { OnChangeEvent } from "./../../common/grid/GridList";
import { TableColumn } from "../../common/grid/table-column";
import { Url } from "./../../../constants/url";
import { GetMobileMaskByTemp } from "./../../../constants/mask";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { applicationInterfaceActions } from "../../../redux/application-interface-slice";
import Autocomplete from "../../common/Autocomplete";
import { CustomerSearch } from "../../../models/customer-search";

import { CustomerListProps } from "./props";
import CountryFlagIcon from "./../../../assets/flags";
import { SearchIcon, TrashIcon } from "../../../assets/icons";
import useStyles from "./css";
import { ApiClient } from "../../../api/api-client";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";
import { createFilterOptions } from "@material-ui/lab";
import { navbarActions } from "../../../redux/navbar-slice";
import { Color } from "../../../constants/colors";
import { Button, Typography } from "@material-ui/core";
import ConfirmDeleteModal from "../../common/ConfirmDeleteModal";
import { alertsActions } from "../../../redux/alerts-slice";
import { selectAccountSettings } from "../../../redux/store";


export default function CustomerList(props: CustomerListProps) {
  
  const appDispatch = useAppDispatch();
  const { t } = useTranslation(["general"]);
  const classes = useStyles();
  const { isRefresh, openCustomerModal, refreshCustomers } = props;
  const [customers, setCustomers] = useState<Customer[]>([]);
  const [customerToDelete, setCustomerToDelete] = useState<Customer | null>(null);
  const [customersSearch, setCustomersSearch] = useState<CustomerSearch[]>([]);
  const [totalCustomers, setTotalCustomers] = useState<number>(0);
  const [page, setPage] = useState<number>(1);
  const pageSize: number = 20;
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("firstName");
  const [currentCustomerId, setCurrentCustomerId] = useState<string>();
  const [search, setSearch] = useState<string>();
  const [customerFound, setCustomerFound] = useState<boolean>(true);
  const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] = useState<boolean>(false);
  const history = useHistory();
  const mobileTemplate: string = "000-000-0000";
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const accountSettings = useAppSelector(selectAccountSettings);

  const dispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;
  
  const selectedEstablishments = useAppSelector(state => state.enterprise.selectedEstablishments)

  const hasCustomerInfoVisible = ApiClient.getUserInfo().hasCustomerInfoVisible;
  
  const titleTerminology = useCustomerTerminology(t("Customers"), "Customers");
  const customerDeletedLabel = useCustomerTerminology(t("Deleted customer"), "customer");
  const deleteCustomerQuestionLabel = useCustomerTerminology(t("Are you sure you want to delete this customer?"), "customer");
  const deleteCustomerAlertLabel = useCustomerTerminology(t("All data for this customer will be deleted."), "customer");
  const deleteCustomerButtonLabel = useCustomerTerminology(t("Delete customer"), "customer");
  const deleteCustomerUpcomingAppointmentsQuestionPartlyLabel = useCustomerTerminology(t("existing appointments with this customer that cannot be served if you delete this customer."), "customer", "customer");
  const deleteCustomerUpcomingAppointmentsAlertLabel = useCustomerTerminology(t("We suggest you confirm that you want to delete this customer."), "customer");

  appDispatch(applicationInterfaceActions.updateTitle(titleTerminology));

  useEffect(() => {
    appDispatch(applicationInterfaceActions.updateTitle(titleTerminology));

    (async () => {
      await loadData();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order, isRefresh, accountSettings.isEnterprise, selectedEstablishments]);

  useEffect(() => {

    if (search && search.length > 2) {
      (async () => {
        await loadSearchData();
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, accountSettings.isEnterprise, selectedEstablishments]);

  function fillMobileData() {
    const countries = accountSettings.countries;
    let customersWithMobileData = [...customers].map(customer => {
      let mobileCode = customer.mobileCodeIso3 ?? "MEX";
      customer.mobileDigits = countries.find(x => x.iso3 === mobileCode)!.dialDigits;
      customer.mobileCode = countries.find(x => x.iso3 === mobileCode)!.dialCode;
      customer.mobileTemplate = countries.find(x => x.iso3 === mobileCode)!.dialTemplate.toString();
      return customer;
    });
    setCustomers(customersWithMobileData);
  }

  useEffect(() => {
    if (accountSettings.countries.length && customers.length) {
      fillMobileData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSettings.countries, customers[0]]);

  const loadData = async () => {
    appDispatch(navbarActions.setShowLoader(true));
    if (accountSettings.isEnterprise === null) return;
    const customers = accountSettings.isEnterprise 
      ? await CustomerService.getCustomersByEstablishments(
        page,
        pageSize, 
        selectedEstablishments,
        order,
        orderBy,
      )
      : await CustomerService.getCustomers(
      page,
      pageSize,
      order,
      orderBy,
      localeApp
    );
    appDispatch(navbarActions.setShowLoader(false));

    if (accountSettings.countries.length) {
      fillMobileData();
    }

    setCustomers(customers.customers || []);
    setTotalCustomers(customers.totalCustomers || 0);
    setCustomerFound(customers.customers?.length! > 0);
  };

  const handleOnSelectCustomer = async (id: string) => {
    const response = await CustomerService.getById(id);
    if (response.customer) {
      setCustomers([response.customer] || []);
      setTotalCustomers(1);
      setCustomerFound(true);
    }
  };
  
  const loadSearchData = async () => {
    const customers = accountSettings.isEnterprise 
      ? await CustomerService.getSearchByEstablishments(selectedEstablishments, search)
      : await CustomerService.getSearch(search);
    const countries = accountSettings.countries;

    [...customers].map(customer => {
      let mobileCode = customer.mobileCodeIso3 ?? "MEX";
      customer.mobileDigits = countries.find(x => x.iso3 === mobileCode)!.dialDigits.toString();
      customer.mobileCode = countries.find(x => x.iso3 === mobileCode)!.dialCode.toString();
      customer.mobileTemplate = countries.find(x => x.iso3 === mobileCode)!.dialTemplate.toString();
      return customer;
    })
    
    if (customers.length !== 0) {
      setCustomerFound(true);
      setCustomersSearch(customers || []);
    } else {
      resetSearchResults();
    }
  };
  
  const getCustomerGender = (customer: Customer) => {
    switch (customer.gender){
      case Gender.Male:
        return t("Male");
      case Gender.Female:
        return t("Female");
      case Gender.PreferNotToSay:
        return t("I prefer not to say");
      case Gender.Custom:
        return customer.customGender;
      default:
        return "--";
    }
  };

  const resetSearchResults = () => {
    setCustomerFound(false);
    setCustomersSearch([]);
    setCustomers([]);
    setTotalCustomers(0);
  }

  function shortString(value: string) {
    return value?.length > 50 
      ? value.substring(0, 47) + "..."
      : value;
  }

  const columns: TableColumn[] = [
    {
      id: "name",
      label: useCustomerTerminology(t("CUSTOMER"), "CUSTOMER"),
      sorting: true,
      width: 240,
    },
    {
      id: "establishmentName",
      label: t("ESTABLISHMENT"),
      width: 115,
      sorting: true,
      component: (item: any) => shortString(item),
      visible: accountSettings?.isEnterprise ?? false
    },
    {
      id: "mobileCodeIso3",
      label: t("COUNTRY"),
      sorting: true,
      completeObject: true,
      component: (item: any) => <>
        {item && <>
          <CountryFlagIcon iso={item.mobileCodeIso3} />
          <div style={{ width: 30, display: "inline-block", textAlign: "center" }}>
            {`+${item.mobileCode}`}
          </div>
        </>}
      </>,
      width: 115,
      headerTextAlign: "center",
      textAlign: "center",
      visible: hasCustomerInfoVisible
    },
    {
      id: "mobile",
      label: t("MOBILE"),
      sorting: true,
      completeObject: true,
      component: (item: any) => (
        <>
          {
            item.mobile ? conformToMask(item.mobile, GetMobileMaskByTemp(item.mobileTemplate || mobileTemplate)).conformedValue : ""
          }
        </>
      ),
      width: 100,
      visible: hasCustomerInfoVisible
    },
    {
      id: "email",
      label: t("EMAIL"),
      sorting: true,
      width: 240,
      component: (email: any) => <>{email || "--"}</>,
      visible: hasCustomerInfoVisible
    },
    {
      id: "gender",
      label: t("GENDER"),
      sorting: true,
      completeObject: true,
      component: getCustomerGender,
      width: 80,
    },
    {
      id: "contactable",
      label: t("CONTACTABLE"),
      sorting: true,
      component: (contactable: any) => <>{contactable ? t("Yes") : t("No")}</>,
      width: 115,
      textAlign: "left",
    },
    {
      id: "frequencyType",
      label: t("FREQUENCY"),
      sorting: true,
      component: (frequencyType: any) => <StatusIcon status={frequencyType} />,
      width: 115,
      textAlign: "left",
    },
    {
      id: "actions",
      label: t("ACTIONS"),
      sorting: false,
      completeObject: true,
      component: (customer: Customer) => <Button onClick={(e) => { e.stopPropagation(); handleDeleteCustomerClick(customer) }}>
        <TrashIcon style={{ color: Color.gray5, width: 13, height: 17, }} />
      </Button>,
      width: 50,
      textAlign: "center",
      visible: !accountSettings.isEnterprise
    },
  ];

  const handleOnChangeGrid = (event: OnChangeEvent) => {
    setPage(event.page);
    if (event.sorting) {
      setOrder(event.sorting.direction);
      setOrderBy(
        event.sorting.orderBy === "name" ? "firstName" : event.sorting.orderBy
      );
    }
  };

  const handleOnRowClick = (item: Customer) => {
    if (!accountSettings.isEnterprise) {
      history.push(`${Url.Customers.Main}/${item.id}`);
    }
  };

  const handleDeleteCustomerClick = (customer: Customer) => {
    setCustomerToDelete(customer);
    setDeleteConfirmationModalIsOpen(true);
  };

  const handleDeleteCustomerConfirmClick = async () => {
    const isDeleted = await CustomerService.deleteCustomer(customerToDelete!.id!)

    if (isDeleted) {
      const customersWithoutDeletedCustomer = [...customers].filter(c => c.id !== customerToDelete!.id);
      setCustomers(customersWithoutDeletedCustomer);
      refreshCustomers();
      dispatch(
        enqueueAlert({
          type: "Success",
          title: customerDeletedLabel.charAt(0).toUpperCase() + customerDeletedLabel.slice(1),
          description: customerToDelete?.name
        })
      );
    }

    setCustomerToDelete(null);
    setDeleteConfirmationModalIsOpen(false);
  };

  const DeleteCustomerModalContent: React.FC = () => {
    return (<div className={classes.deleteModalContentContainer}>
      <Typography className={classes.deleteModalTitleText}>
        {deleteCustomerQuestionLabel}
      </Typography>
      <div className={classes.separator} />
      <Typography className={classes.deleteModalContentBoldText}>
        {customerToDelete?.name}
      </Typography>
      <Typography className={classes.deleteModalContentText}>
        {deleteCustomerAlertLabel}
      </Typography>
    </div>);
  };

  const DeleteCustomerWithAppointmentsModalContent: React.FC = () => {
    return (<div className={classes.deleteModalContentContainer}>
      <Typography className={classes.deleteModalTitleText}>
        {t("There are") + " " + customerToDelete?.upcomingAppointmentsCount + " "
          + deleteCustomerUpcomingAppointmentsQuestionPartlyLabel}
      </Typography>
      <div className={classes.separator} />
      <Typography className={classes.deleteModalContentText}>
        {deleteCustomerUpcomingAppointmentsAlertLabel}
      </Typography>
    </div>);
  };


  return (
    <div className={classes.divMain}>
      <Autocomplete
        style={{ width: 464, marginBottom: 19 }}
        items={customersSearch}
        placeholder={useCustomerTerminology(t("Search customer"), "customer")}
        renderOption={(option: CustomerSearch) => {
          return <div className={classes.searchResultLabel}>
            {`${option.firstName} ${option.lastName}`}
            {hasCustomerInfoVisible
              ? <span>
                {" / "}
                <CountryFlagIcon iso={option.mobileCodeIso3!} />
                {" +" + option.mobileCode + "  "}
                {conformToMask(option.mobile!, GetMobileMaskByTemp(option.mobileTemplate!)).conformedValue}
              </span>
              : <></>}
          </div>
        }
        }
        getOptionSelected={(option, value) =>
          `${option.firstName} ${option.lastName}` ===
          `${value.firstName} ${value.lastName}`
        }
        getOptionLabel={(option) =>
          `${option?.firstName || ""} ${option?.lastName || ""}`.trim()
        }
        filterOptions={createFilterOptions({
          stringify: (option) => search || ""
        })}
        onChange={(value: any, reason) => {
          if (reason === "input") {
            setSearch(value);
          }
        }}
        onSelected={(option: any) => {
          const customerId = option?.id || undefined;
          if (customerId !== currentCustomerId) {
            resetSearchResults();
            setCurrentCustomerId(option?.id || undefined);
            handleOnSelectCustomer(option?.id);
          }
        }}
        cleanInput={isRefresh}
      />
      <GridList
        columns={columns}
        items={customers}
        totalItems={totalCustomers}
        pageSize={20}
        onChange={handleOnChangeGrid}
        onRowClick={handleOnRowClick}
      />
      {
        !customerFound && <>
          <div className={classes.notFound}>
            <SearchIcon style={{ fontSize: 48 }} />
            <br /><br />
            {
              accountSettings.isEnterprise
                ?
                  <>
                    {t("There are no registered customers. Register customers from the account to which it belongs.")}
                  </>
                : 
                <>
                  {t("Customer not found")},
                  <br />
                  {t("please")}
                  <div className={classes.linkRegister} onClick={() => openCustomerModal()}>
                    {t("register it")}.
                  </div>
                </>
            }
          </div>
        </>
      }
      {customerToDelete
        ? <ConfirmDeleteModal
          classModal={classes.deleteConfirmationModal}
          open={deleteConfirmationModalIsOpen}
          item={customerToDelete}
          componentInfo={customerToDelete!.upcomingAppointmentsCount! > 0
            ? <DeleteCustomerWithAppointmentsModalContent />
            : <DeleteCustomerModalContent />}
          onClose={() => setDeleteConfirmationModalIsOpen(false)}
          onDelete={() => handleDeleteCustomerConfirmClick()}
          textButtonConfirm={deleteCustomerButtonLabel} />
        : <></>}
    </div>
  );
};