import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        alignItems: "center",
        marginBottom: 15,
        gap: 20,
        width: "100%",
        justifyContent: "space-between",
    },
    tabTitle: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: Color.gray5
    },
    newSectionButtonContainer: {
      display: "flex",
      marginBottom: 24,
    },
    newButton: {
        marginLeft: "auto",
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "space-between",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        background: Color.clear2,
        color: Color.gray1,
        "&:hover": {
            background: Color.clear1,
        },
    },
    copyNotes:{
      backgroundColor: theme.colors.clearLilac,
      borderRadius: 5,
      padding: "20px 30px",
      marginBottom: 32
    },
    copyNotesTitle: {
      color: theme.colors.clearMain,
      fontSize: 12,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 700,
      wordWrap: "break-word"
    },
    copyNotesDescription: {
      color: theme.colors.grey5,
      fontSize: 12,
      fontFamily: "Roboto, sans-serif",
      fontWeight: 400,
      wordWrap: "break-word"
    },
    copyControls:{
      display: "flex",
    },
    copyButton:{
      marginLeft: 16,
      height: 32,
      borderRadius: 5,
      padding: "11px 16px",
      alignItems: "center",
      justifyContent: "space-between",
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 12,
      background: Color.clear2,
      color: Color.gray1,
      "&:hover": {
        background: Color.clear1,
      },
    },
  cancelButton:{
    marginLeft: 16,
    height: 32,
    borderRadius: 5,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    background: Color.gray1,
    color: Color.clear2,
    borderColor: Color.clear2,
    borderWidth: 1,
    borderStyle: "solid",
    "&:hover": {
      background: Color.white,
    },
  },
    focused: {
      "&:focus": {
        backgroundColor: theme.colors.grey1,
      },
      "&.MuiSelect-select": {
        "&:focus": {
          borderRadius: 5,
        },
      },
    },
    select: {
      ...theme.typography.txtBody2,
      height: 32,
      width: 205,
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      "& .MuiPaper-rounded": {
        borderRadius: 10,
        marginTop: 8
      },
      "& .MuiPaper-rounded .MuiListItem-root.Mui-selected:not(:hover)": {
        backgroundColor: "white",
      },
      "& .MuiPaper-rounded .MuiListItem-root": {
        fontSize: 12,
      },
      "& .MuiPaper-rounded .MuiListItem-root:hover": {
        backgroundColor: theme.colors.grey1
      },
      "& .MuiSelect-select": {
        paddingRight: 34
      },
    },
    selected: {
      fontWeight: "bold",
      padding: "8px 0px 8px 15px",
      color: theme.colors.clearMain
    },
    multiSelect: {
      ...theme.typography.txtBody2,
      height: 32,
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      width: 250,
      marginLeft: 16,
      "& .MuiSelect-select": {
        paddingRight: 34
      },
      "& .MuiMenu-list": {
        borderRadius: "0 0 10px 10px",
        marginTop: 8
      },
    },
    sectionsContainer: {
        border: "1px solid",
        borderColor: "#DBDCDD",
        boxSizing: "border-box",
        borderRadius: "12px 12px 12px 12px",
        height: "auto",
        overflow: "hidden"
    },
    communicationSettingsContainer: {
      border: "1px solid",
      borderColor: "#DBDCDD",
      boxSizing: "border-box",
      borderRadius: "12px 12px 12px 12px",
      height: "auto",
      backgroundColor: "#FFFFFF",
      overflow: "hidden"
    },
    mainTitle: {
      fontWeight: 700,
      fontSize: 14,
      color: "#919CA5"
      },
      iconTooltipRoot: {
          display: "inline-block"
      },
      checkboxTitle: {
          fontWeight: 400,
          fontSize: 14,
          color: "#919CA5"
      },
      tinyMceOverrides: {
          "& svg": {
              fill: "#919CA5 !important"
          },
          "& button.tox-tbtn--select": {
              visibility: "hidden"
          },
          position: "relative",
          padding: "15px 0px 23px 47px"
      },
      tinyMceUnfocused: {
          "& div.tox-tinymce": {
              borderRadius: 5,
              borderColor: "#C9CDD3 !important"
          }
      },
      tinyMceFocused: {
          "& div.tox-tinymce": {
              borderRadius: 5,
              borderColor: "#6462F3 !important"
          }
      },
      charCounter: {
          fontFamily: 'Inter',
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "10px",
          lineHeight: "12px",
          position: "absolute",
          paddingRight: 15,
          marginTop: "-18px",
          marginRight: "15px",
          color: theme.colors.grey5
      },
     communicationSettingsMainTitle: {
          fontFamily: 'Inter',
          fontStyle: "normal",
          fontWeight: 700,
          fontSize: "14px",
          lineHeight: "14px",
          marginBottom: "13px",
          color: "#919CA5"
      },
     communicationSettingsCheckboxGroupTitle: {
          fontFamily: 'Inter',
          fontStyle: "normal",
          fontWeight: 400,
          fontSize: "14px",
          lineHeight: "17px",
          marginBottom: "20px",
          color: "#919CA5"
      },
    communicationSettingsHeader: {
            fontFamily: 'Inter',
            fontStyle: "normal",
            fontWeight: 700,
            fontSize: "14px",
            lineHeight: "17px",
            marginBottom: "16px",
            color: "#5C6477"
    },
    previewButton: {
      height: 32,
      borderRadius: 51,
      padding: "11px 16px",
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: 12,
      border: "1px solid " + Color.clear1,
      color: Color.clear1,
    },
    counterWrapper: {
          display: "flex",
          justifyContent: "flex-end"
    }
}));

export default useStyles;