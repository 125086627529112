import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "calc(100vh - 152px)",
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
  sectionsListContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "0px 16px",
  },
  sectionContainer: {
    paddingTop: 32,
    display: "flex",
    flexDirection: "column",
    gap: 22,
    minHeight: 80
  },
  fieldsContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    rowGap: 16
  },
  fieldsEditionContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    gap: 24
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    minWidth: "50%",
    maxWidth: "50%",
    gap: 2
  },
  fieldEditionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 2
  },
  divider: {
    height: 0,
    borderTop: "1px solid " + Color.gray2,
    opacity: "0.5",
    marginTop: 47
  },
  editDivider: {
    height: 0,
    borderTop: "1px solid " + Color.gray2,
    opacity: "0.5",
    marginTop: 32
  },
  sectionLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.gray4
  },
  fieldNameLabel: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: Color.gray4
  },
  fieldNameAsterisk: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: Color.clear1
  },
  fieldValueLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.gray5
  },
  phoneNumberCodeLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.clear1
  },
  noNotes: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.gray4,
    paddingBottom: 32,
  },
  notes: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.gray5,
    paddingBottom: 32,
  },
  phoneNumberContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 4,
    "& svg": {
      width: "20px !important",
      height: "20px !important"
    }
  },
  editButtonContainer: {
    position: "absolute",
    bottom: 32,
    right: 16
  },
  editButton: {
    borderRadius: 51,
    padding: "0px 16px",
    height: 32,
    background: Color.gray1,
    color: Color.clear1,
    "div > span": {
      "&:hover": {
        background: Color.clear1,
        color: Color.gray1,
      },
    },
    "&:hover": {
      background: Color.clear1,
      color: Color.gray1,
    },
  },
  editButtonLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "15px",
  },
  cancelButton: {
    borderRadius: 51,
    padding: "0px 16px",
    height: 32,
    background: Color.gray1,
    color: Color.gray5,
    "div > span": {
      "&:hover": {
        background: Color.red,
        color: Color.gray1,
      },
    },
    "&:hover": {
      background: Color.red,
      color: Color.gray1,
    },
  },
  cancelButtonLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "15px",
  },
  saveButton: {
    borderRadius: 51,
    padding: "0px 16px",
    height: 32,
    background: Color.gray1,
    color: Color.green,
    "div > span": {
      "&:hover": {
        background: Color.green,
        color: Color.gray1,
      },
    },
    "&:hover": {
      background: Color.green,
      color: Color.gray1,
    },
  },
  saveButtonLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "15px",
  },
  requiredFieldsNoteLabel: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: Color.gray4
  },
  editModeButtonsGroupContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    gap: 16,
    paddingTop: 40,
    paddingBottom: 16
  },
  closeConfirmationModal: {
    height: "234px + !important"
  },
  closeModalContentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  closeModalTitleText: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#26292B",
    textAlign: "center",
    maxWidth: "80%"
  },
  closeModalContentText: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.gray5
  },
  separator: {
    height: 0,
    borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    margin: "16px 0px 16px 0px",
    width: 217
  },
  switchOverrides: {
    "& .MuiFormControlLabel-root": {
      marginLeft: "unset",
      marginRight: "unset",
    },
    "& #labelId": {
      fontFamily: "Roboto, sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "14px",
      color: Color.gray5,
      marginBottom: 5,
    },
    "& #labelAsterixId": {
      fontFamily: "Roboto, sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "12px",
      lineHeight: "14px",
      color: Color.clear1
    },
    "& #tooltipTextId": {
      fontFamily: "Roboto, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "14px",
      color: Color.gray4,
    }
  },
  muiSwitchTrackOverride: {
    "& .MuiSwitch-track": {
      backgroundColor: Color.clear1 + " !important"
    }
  },
  notesEditLabelContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    rowGap: 8,
    alignItems: "center"
  },
  tinyMceOverrides: {
    "& svg": {
      fill: "#919CA5 !important"
    },
    "& button.tox-tbtn--select": {
      visibility: "hidden"
    },
    position: "relative",
  },
  tinyMceUnfocused: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: "#C9CDD3 !important"
    }
  },
  tinyMceFocused: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: "#6462F3 !important"
    }
  },
  charCounter: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "12px",
    position: "absolute",
    paddingRight: 15,
    marginTop: "-18px",
    color: theme.colors.grey5
  },
  counterWrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  tinyMceContainer: {
    width: "100%"
  },
  switch: {
    display: "flex",
    alignItems: "center",
  },
  switchLabel: {
    margin: "0px 0px 0px 16px",
  },
  label: {
    display: "flex",
    margin: 0,
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey5,
  },
  description: {
    margin: 0,
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
  },
}));

export default useStyles;
