import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";

import { PatientFormProps } from "./props";
import useStyles from "./css";
import { Button, Typography } from "@material-ui/core";
import { SectionNewIcon } from "../../../../../assets/icons";
import { SectionType } from "../../../../../models/enums/section-type";
import { SettingsPatientFormsService } from "../../../../../api/settings-patient-forms-service";
import { SectionModel } from "../../../../../models/section-model";
import SectionNewModal from "../SectionNewModal";
import SectionList from "../SectionList";
import SectionListRefs from "../SectionList/section-list-refs";
import { Url } from "../../../../../constants/url";
import Section from "../Section";
import { SectionFieldModel } from "../../../../../models/section-field-model";
import { SectionFieldType } from "../../../../../models/enums/section-field-type";
import { SectionFieldItemModel } from "../../../../../models/section-field-item-model";
import { selectAccountSettings, selectUserInfo } from "../../../../../redux/store";
import { navbarActions } from "../../../../../redux/navbar-slice";
import { alertsActions } from "../../../../../redux/alerts-slice";
import Select from "../../../../common/Select";
import MultiSelect from "../../../../common/MultiSelect";
import CopyIcon from "../../../../../assets/icons/CopyIcon";
import Backdrop from "../../../../common/Backdrop";
import { CopyEstablishmentSettingsModel } from "../../../../../models/copy-establishment-settings-model";
import { AccountService } from "../../../../../api/account-service";
import SectionNote from "../SectionNote";
import { SectionNoteData } from "../../../../../models/enums/section-note-data";


export default function PatientForm(props: PatientFormProps) {
    const sectionTNamespace = "sections";
    const { t } = useTranslation(["general", sectionTNamespace]);
    const { settingsSelectedEstablishmentId } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const currentTabSectionType = SectionType.PatientForm;
    const accountSettings = useAppSelector(selectAccountSettings);
    const { enqueueAlert } = alertsActions;
    
    const [currentEstablishmentId, setCurrentEstablishmentId] = useState<string|null|undefined>(undefined);
    const [copyToEstablishments, setCopyToEstablishments] = useState<string[]>([]);
    const [isCopyMode, setIsCopyMode] = useState<boolean>(false);
    const [isCopying, setIsCopying] = useState<boolean>(false);
    const [sections, setSections] = useState<SectionModel[]>([]);
    const [newSectionModalIsOpen, setNewSectionModalIsOpen] = useState<boolean>(false);
    const [businessNameUrl, setBusinessNameUrl] = useState<string>();

    const sectionListRef = useRef<SectionListRefs>(null);

    const pacientBasicInfoSectionStaticModel = new SectionModel({
        id: "patientBasicInfoId",
        name: t("default_section_name_Basic patient information", { ns: sectionTNamespace }),
        order: 0,
        type: SectionType.PatientForm,
        fields: [
            new SectionFieldModel({
                id: "firstName",
                name: t("default_field_name_First name(s)", { ns: sectionTNamespace }),
                order: 1,
                type: SectionFieldType.Text,
                isRequired: true,
                isAdditionalTextareaEnabled: false,
                isEditable: false,
                isRemovable: false
            }),
            new SectionFieldModel({
                id: "lastName",
                name: t("default_field_name_Last name", { ns: sectionTNamespace }),
                order: 2,
                type: SectionFieldType.Text,
                isRequired: true,
                isAdditionalTextareaEnabled: false,
                isEditable: false,
                isRemovable: false
            }),
            new SectionFieldModel({
                id: "mobile",
                name: t("default_field_name_Mobile", { ns: sectionTNamespace }),
                order: 3,
                type: SectionFieldType.PhoneNumber,
                isRequired: true,
                isAdditionalTextareaEnabled: false,
                isEditable: false,
                isRemovable: false
            }),
            new SectionFieldModel({
                id: "email",
                name: t("default_field_name_Email", { ns: sectionTNamespace }),
                order: 4,
                type: SectionFieldType.Email,
                isRequired: false,
                isAdditionalTextareaEnabled: false,
                isEditable: false,
                isRemovable: false
            }),
            new SectionFieldModel({
                id: "gender",
                name: t("default_field_name_Gender", { ns: sectionTNamespace }),
                order: 5,
                type: SectionFieldType.Radio,
                isRequired: false,
                isAdditionalTextareaEnabled: false,
                isEditable: false,
                isRemovable: false,
                items: [
                    new SectionFieldItemModel({
                        id: "male",
                        name: t("default_field_item_name_Male", { ns: sectionTNamespace }),
                        order: 1
                    }),
                    new SectionFieldItemModel({
                        id: "Female",
                        name: t("default_field_item_name_Female", { ns: sectionTNamespace }),
                        order: 2
                    }),
                ]
            }),
            new SectionFieldModel({
                id: "dateOfBirth",
                name: t("default_field_name_Date of Birth", { ns: sectionTNamespace }),
                order: 6,
                type: SectionFieldType.Date,
                isRequired: false,
                isAdditionalTextareaEnabled: false,
                isEditable: false,
                isRemovable: false
            }),
        ],
        isEditable: false,
        isRemovable: false
    });

    const userInfo = useAppSelector(selectUserInfo);
    const [enterpriseSelected, setEnterpriseSelected] = useState<boolean>(false);

    useEffect(() => {
        async function fetchData() {
            dispatch(navbarActions.setShowLoader(true));
            await loadData();
            dispatch(navbarActions.setShowLoader(false));
        }
        if (currentEstablishmentId){
            void fetchData();
        }
    }, [currentEstablishmentId]);
    
    useEffect(() => {
        const enterpriseSelected = userInfo.accountId === settingsSelectedEstablishmentId;
        setEnterpriseSelected(enterpriseSelected);
        if (settingsSelectedEstablishmentId && !enterpriseSelected)
            setCurrentEstablishmentId(settingsSelectedEstablishmentId);
        else {
            if (accountSettings?.establishments?.length) {
                setCurrentEstablishmentId(accountSettings.establishments[0].key)
            } else {
                setCurrentEstablishmentId(null);
            }
        }
    }, [settingsSelectedEstablishmentId, accountSettings.establishments]);

    const handleCreateSection = async (name: string) => {
        await sectionListRef?.current?.createSection(name);
    }

    const loadData = async () => {
        const sectionsData =
            await SettingsPatientFormsService.getAccountSettingsTabSections(currentTabSectionType, currentEstablishmentId);
        setSections(sectionsData);
        const businessNameUrl = await AccountService.getAccountSubdomain(currentEstablishmentId!);
        setBusinessNameUrl(businessNameUrl);
    };
    
    const handleCopyClick = async () => {
        if (isCopyMode) {
            setIsCopying(true);
            await SettingsPatientFormsService.copyPatientFormSetting(
              new CopyEstablishmentSettingsModel(currentEstablishmentId!, copyToEstablishments))
            setIsCopying(false);
            setIsCopyMode(false);
            dispatch(enqueueAlert({
                type: "Success",
                title: t("Patient form copied"),
                description: t("To the selected establishments")
            }));
        } else {
            setIsCopyMode(true);
        }
    }
    
    const handleSelectEstablishment = (event: any) => {
        setCurrentEstablishmentId(event.target.value);
    }
    
    const handleSaveCopyToEstablishments = (establishments: string[]) => {
        setCopyToEstablishments(establishments);
    }
    
    const copyToEstablishmentsItems = () => {
        return accountSettings.establishments.filter(x => x.key !== currentEstablishmentId);
    }

    const handleOpenPreview = () => {
        window.open(`https://sitiodereservas.somosclear.com/${businessNameUrl}/patient-form?previewStep=first`, '_blank', 'noopener,noreferrer');
    }

    return <>
        {settingsSelectedEstablishmentId && (
            <>
                <div className={classes.header}>
                    <Typography className={classes.tabTitle}>
                        {t("Patient form")}
                    </Typography>
                </div>
                <SectionNote
                    sectionData={SectionNoteData.PatientForm}
                />
            </>
        )}
        {enterpriseSelected &&
            <>
                <div className={classes.copyControls}>
                    <Select
                        items={accountSettings.establishments}
                        value={currentEstablishmentId}
                        classes={{ root: `${classes.focused} ${classes.selected}` }}
                        styleClass={classes.select}
                        onChange={handleSelectEstablishment}
                    />
                    {accountSettings.establishments.length > 1 &&
                        <>
                            {isCopyMode &&
                                <MultiSelect
                                    allItemsPlaceholder={t("Copy to all establishments")}
                                    filterPlaceholder={t("Select destination establishment")}
                                    items={copyToEstablishmentsItems()}
                                    values={copyToEstablishments}
                                    classes={{ root: `${classes.focused} ${classes.selected}` }}
                                    styleClass={classes.multiSelect}
                                    width={250}
                                    height={372}
                                    onChange={(x: any) => handleSaveCopyToEstablishments(x)}
                                    onReset={() => handleSaveCopyToEstablishments([])}
                                />
                            }
                            <Button
                                className={classes.copyButton}
                                onClick={handleCopyClick}
                            >
                                <CopyIcon style={{ paddingRight: 5 }} />
                                {t("Copy form")}
                            </Button>
                            <Button
                                hidden={!isCopyMode}
                                className={classes.cancelButton}
                                onClick={() => setIsCopyMode(false)}
                            >
                                {t("Cancel")}
                            </Button>
                        </>
                    }
                </div>
                <div className={classes.newSectionButtonContainer}>
                    <Button
                        className={classes.newButton}
                        onClick={() => setNewSectionModalIsOpen(true)}
                    >
                        <SectionNewIcon style={{ paddingRight: 5 }} />
                        {t("New section")}
                    </Button>
                </div >
            </>
        } 
        {!enterpriseSelected &&
            <>
                <div className={classes.newSectionButtonContainer}>
                    <div className={classes.header}>
                        <Typography className={classes.tabTitle}>
                            {t("Patient form")}
                        </Typography>
                        <Button
                            className={classes.previewButton}
                            onClick={handleOpenPreview}
                        >
                            {t("Preview")}
                        </Button>
                        <Button
                            className={classes.newButton}
                            onClick={() => setNewSectionModalIsOpen(true)}
                        >
                            <SectionNewIcon style={{ paddingRight: 5 }} />
                            {t("New section")}
                        </Button>

                    </div >
                </div >
            </>
        }
        {currentEstablishmentId !== undefined &&
          <>
            <Section
                accountId={currentEstablishmentId!}
                key={"patientBasicInfo"}
                section={pacientBasicInfoSectionStaticModel}
            />
            <SectionList
                accountId={currentEstablishmentId}
                ref={sectionListRef}
                sectionType={currentTabSectionType}
                sections={sections}
                setSections={setSections}
            />
            <div className={classes.footerContainer}>
                <Typography className={classes.footerNoteLabel}>
                    <span className={classes.footerNoteLabelBold}>
                        {t("Note") + ": "}
                    </span>
                    {t("The customization of the Clinical History to be displayed in the form is done in the") + " "}
                    <Link
                        to={Url.Settings.ClinicHistory}
                        className={classes.footerNoteLabelLink}
                    >
                        {t("Clinical History")}
                    </Link>
                    {" " + t("tab of the Settings screen") + "."}
                </Typography>
            </div>
            <SectionNewModal
                accountId={currentEstablishmentId!}
                sections={sections}
                isOpen={newSectionModalIsOpen}
                setOpen={setNewSectionModalIsOpen}
                onCreate={handleCreateSection}
            />
          </>
        }
        <Backdrop show={isCopying} text={t("Copying")+"..."} />
    </>
};