import { ApiClient } from "./api-client"
import NotificationEntity from "../models/notification-entity";
import { JobPosition } from "../models/job-position";
import ConversationsCounterModel from "../models/conversations-counter-model";
import { Response } from "../models/response";
import { CustomerSearch } from "../models/customer-search";

export class NotificationsService {
  private static baseUrl = "/notifications";
  private static whatsAppUrl = "/whatsApp/api";

  static getNotifications(): Promise<NotificationEntity[]> {
    return ApiClient.get(`${this.baseUrl}/getlist`).then((data) =>
      data.map((n: any) => new NotificationEntity(n)));
  }

  static markNotificationsAsWasShown(): Promise<boolean> {
    return ApiClient.post(`${this.baseUrl}/markAsWasShown`);
  }

  static markNotificationAsRead(id: string) : Promise<boolean> {
    return ApiClient.post(`${this.baseUrl}/markAsRead`, { id })
      .catch(err => console.error(err.response));
  }
  
  static getAvailableConversationsCount() : Promise<ConversationsCounterModel> {
    return ApiClient.get(`${this.whatsAppUrl}/account/getAvailableConversationsCount`)
      .catch(err => console.error(err.response));
  }
}