import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    // justifyContent: "space-between",
    margin: "20px 0px 0px 0px",
  },
  spaceBetween: {
    marginRight: 24,
  },
  deleteContactDiv: {
    paddingTop: 25,
  },
  deleteContactButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  required: {
    color: "#6462F3",
  },
}));

export default useStyles;
