import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionNewIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M20.3743 15.1783H17.8217V12.6258C17.8217 12.3244 17.5558 12.0585 17.2545 12.0585H16.6873C16.3682 12.0585 16.12 12.3244 16.12 12.6258V15.1783H13.5675C13.2484 15.1783 13.0002 15.4442 13.0002 15.7456V16.3128C13.0002 16.6319 13.2484 16.88 13.5675 16.88H16.12V19.4326C16.12 19.7516 16.3682 19.9998 16.6873 19.9998H17.2545C17.5558 19.9998 17.8217 19.7516 17.8217 19.4326V16.88H20.3743C20.6756 16.88 20.9415 16.6319 20.9415 16.3128V15.7456C20.9415 15.4442 20.6756 15.1783 20.3743 15.1783Z" fill="#F6F6F6" /> <path fillRule="evenodd" clipRule="evenodd" d="M4.47061 5C3.65841 5 3 5.65841 3 6.47061V8.82358V11.3236V12.7942V15.2942V16.0295C3 16.4356 3.32921 16.7648 3.7353 16.7648H11.5295C11.692 16.7648 11.8236 16.6332 11.8236 16.4707V15.5884C11.8236 15.4259 11.692 15.2942 11.5295 15.2942H4.76473L4.76473 12.7942H14.4707C14.6332 12.7942 14.7648 12.6625 14.7648 12.5001V11.6177C14.7648 11.4553 14.6332 11.3236 14.4707 11.3236H4.76473L4.76473 8.82358H15.9413V10.2942C15.9413 10.4566 16.073 10.5883 16.2355 10.5883H17.4119C17.5744 10.5883 17.7061 10.4566 17.7061 10.2942V8.82358V8.38239V6.47061C17.7061 5.65841 17.0476 5 16.2355 5H4.47061Z"
                    fill={style?.color || Color.gray1}
            />
        </SvgIcon>
    );
}