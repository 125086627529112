import { Gender } from "./enums/gender";
import { PatientFormFieldAndValue } from "./patient-form-field-and-value";
import { PatientGeneralInfoResponse } from "./patient-general-info-response";

export class UpdatePatientFormDataRequest {
    customerId: string;
    firstName: string;
    lastName: string;
    dateOfBirth?: Date;
    gender?: Gender;
    customGender?: string | null;

    email?: string;
    mobileCodeIso3: string;
    mobile: string;
    contactableAppointments: boolean;
    contactableMassiveSendings: boolean;

    notes: string;

    patientFormSectionFields: PatientFormFieldAndValue[];

    constructor(x?: any | PatientGeneralInfoResponse) {
      if (x instanceof PatientGeneralInfoResponse) {
        this.customerId = x.id;
        this.firstName = x.firstName;
        this.lastName = x.lastName;
        this.dateOfBirth = x.dateOfBirth;
        this.gender = x.gender;
        this.customGender = x.customGender;
        this.email = x.email;
        this.mobileCodeIso3 = x.mobileCodeIso3;
        this.mobile = x.mobile ?? "";
        this.contactableAppointments = x.contactableAppointments;
        this.contactableMassiveSendings = x.contactableMassiveSendings;
        this.patientFormSectionFields = x.patientFormSections.flatMap(s => s.patientFormSectionFields);
        this.notes = getHtmledNotes(x.notes);
      }
      else if (typeof x === "object") {
        this.customerId = x.id;
        this.firstName = x.firstName;
        this.lastName = x.lastName;
        this.dateOfBirth = x.dateOfBirth;
        this.gender = x.gender;
        this.customGender = x.customGender;
        this.email = x.email;
        this.mobileCodeIso3 = x.mobileCodeIso3;
        this.mobile = x.mobile;
        this.contactableAppointments = x.contactableAppointments;
        this.contactableMassiveSendings = x.contactableMassiveSendings;
        this.patientFormSectionFields = x.patientFormSectionFields;
        this.notes = x.notes;
      }
      else {
        this.customerId = "";
        this.firstName = "";
        this.lastName = "";
        this.dateOfBirth = undefined;
        this.gender = undefined;
        this.customGender = undefined;
        this.email = undefined;
        this.mobileCodeIso3 = "MEX";
        this.mobile = "";
        this.contactableAppointments = false;
        this.contactableMassiveSendings = false;
        this.patientFormSectionFields = [];
        this.notes = "";
      }
    }
  }

  //need to make stringify validation work - TinyMCE/HTML eats whitespaces on init
  function getHtmledNotes(v: string) {
    if (!v) return "";

    let htmledNotes = v.trim();
    while (htmledNotes.includes("  ")) {
      htmledNotes = htmledNotes.replace("  ", " ");
    }

    return htmledNotes;
  }

