import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Backdrop from "@material-ui/core/Backdrop";
import { Dialog, DialogContent, DialogTitle, Fade, Tab, Tabs } from "@material-ui/core";

import { MassiveSendingModalProps } from "./props";
import useStyles from "./css";
import MassiveSendingGeneralSettings from "../MassiveSendingGeneralSettings";
import MassiveSendingStepper from "../MassiveSendingStepper";
import { TabContext, TabPanel } from "@material-ui/lab";
import { MassiveSendingEntity } from "../../../../models/massive-sending-entity";
import CancelModal from "../../../common/CancelModal";
import MassiveSendingMessageSettings from "../MassiveSendingMessageSettings";
import MassiveSendingPreview from "../MassiveSendingPreview";
import { MassiveSendingContentType } from "../../../../models/enums/massive-sending-content-type";
import useCustomerTerminology from "../../../../hooks/useCustomerTerminology";
import { URLRegex } from "../../../../constants/validator";


export default function MassiveSendingModal(props: MassiveSendingModalProps) {

  const { open, setOpen, onClose, onCreate, mode, itemId, massiveSending, setMassiveSending, oldMassiveSending } = props;

  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [activeStep, setActiveStep] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<"first" | "second" | "third">("first");
  const [firstStepCompleted, setFirstStepCompleted] = useState<boolean>(false);
  const [secondStepCompleted, setSecondStepCompleted] = useState<boolean>(false);

  const [textLength, setTextLength] = useState<number>(0);
  const maxTextLength: number = 900;
  const [textContainsUrl, setTextContainsUrl] = useState<boolean>(false);

  const { t } = useTranslation("massivesendings");
  const classes = useStyles();

  const sendingTypeNames = [
    t("SingleSending"),
    t("Annual sending"),
    t("Birthdays")
  ]

  const customerFrequencyTypes = [
    { key: 0, value: useCustomerTerminology(t("All customers"), "customers") },
    { key: 1, value: useCustomerTerminology(t("Frequent customers"), "Frequent customers") },
    { key: 2, value: useCustomerTerminology(t("Infrequent customers"), "Infrequent customers") },
    { key: 3, value: useCustomerTerminology(t("Inactive customers"), "Inactive customers") }
  ];

  const customerGenders = [
    { key: 0, value: t("AllGenders") },
    { key: 256, value: t("MaleAndFemale") },
    { key: 1, value: t("Male") },
    { key: 2, value: t("Female") },
    { key: 3, value: t("PreferNotToSay") },
    { key: 4, value: t("Custom") },
  ];

  const birthdaySendingRules = [
    { key: 0, value: t("Same day of the birthday") },
    { key: 1, value: t("One day before the birthday") },
    { key: 2, value: t("One week before the birthday") },
    { key: 3, value: t("Two weeks before the birthday") },
    { key: 4, value: t("One month before the birthday") },
  ];

  const handleTabChange = (event: any, newValue: "first" | "second" | "third") => {
    setActiveTab(newValue);
  };

  function resetForm() {
    setMassiveSending(new MassiveSendingEntity());
    setActiveStep(0);
    setActiveTab("first");
    setFirstStepCompleted(false);
    setSecondStepCompleted(false);
  }

  function handleCloseModal() {
    setOpen(false);
    resetForm();
    if (onClose) {
      onClose();
    }
  }

  function onCloseHandler(event: any, reason: any) {
    if (reason === "backdropClick") {
      if (mode === "edit") {
        if (sendingHasNotBeenEdited()) {
          handleCloseModal();
        } else {
          setCancelModalOpen(true);
        }
      } else {
        if (sendingIsEmpty()) {
          handleCloseModal();
        } else {
          setCancelModalOpen(true);
        }
      }
    }
  }

  const handleFormSubmit = async () => {
    if (!isFormValid()) return;
    if (mode === "new") {
      executeSubmitAndCloseModal();
    }

    if (!massiveSending.id) return;
    executeSubmitAndCloseModal();
  }

  function sendingIsEmpty() {
    return JSON.stringify(massiveSending) == JSON.stringify(new MassiveSendingEntity());
  }

  function sendingHasNotBeenEdited() {
    return JSON.stringify(massiveSending) == JSON.stringify(oldMassiveSending);
  }

  function executeSubmitAndCloseModal() {
    if (onCreate) {
      onCreate(massiveSending);
    }
    handleCloseModal();
  }

  function handleGoBackButtonClick() {
    if (mode === "edit") {
      if (sendingHasNotBeenEdited()) {
        handleCloseModal();
      } else {
        setCancelModalOpen(true);
      }
    }
    else {
      if (activeStep === 0) {
        if (sendingIsEmpty()) {
          handleCloseModal();
        } else {
          setCancelModalOpen(true);
        }
      } else {
        setActiveStep((prev) => prev - 1);
      }
    }
  }

  function handleContinueButtonClick() {
    if (mode === "edit" || activeStep === 2) {
      handleFormSubmit();
    }
    else {
      setActiveStep((prev) => prev + 1);
    }
  }

  function isContinueButtonDisabled() {
    if (mode === "new") {
      switch (activeStep) {
        case 0:
          return !validateGeneralSettings();
        case 1:
          return !validateMessageSettings();
        case 2:
          return !isFormValid();
        default:
          return false;
      }
    } else {
      return !isFormValid() || sendingHasNotBeenEdited()
    }
  }

  function validateGeneralSettings() {
    return !(massiveSending.name === "")
  }

  function validateMessageSettings() {
    return !(massiveSending.text === ""
      || (massiveSending.contentType === MassiveSendingContentType.ImageAndText && !massiveSending.imageUrl)
      || textLength > maxTextLength
      || textContainsUrl)
  }

  function isFormValid() {
    return validateGeneralSettings() && validateMessageSettings();
  }

  const getContinueButtonTitle: string =
    mode === "edit"
      ? t('Save changes')
      : activeStep === 2
        ? t('Confirm sending')
        : t('Continue')

  const LabelWithDot = (props: any) => {
    return (
      <span>
        {props.label} <span className={classes.dot}>*</span>
      </span>
    );
  }

  const FirstStepContent = () =>
    <MassiveSendingGeneralSettings
      mode={mode}
      massiveSending={massiveSending}
      setMassiveSending={setMassiveSending}
      sendingTypeNames={sendingTypeNames}
      customerFrequencyTypes={customerFrequencyTypes}
      customerGenders={customerGenders}
      birthdaySendingRules={birthdaySendingRules}
    />

  const SecondStepContent = () =>
    <MassiveSendingMessageSettings
      massiveSending={massiveSending}
      setMassiveSending={setMassiveSending}
      textLength={textLength}
      setTextLength={setTextLength}
      maxTextLength={maxTextLength}
      textContainsUrl={textContainsUrl}
      setTextContainsUrl={setTextContainsUrl}
    />

  const ThirdStepContent = () =>
    <MassiveSendingPreview
      massiveSending={massiveSending}
      sendingTypeNames={sendingTypeNames}
      customerFrequencyTypes={customerFrequencyTypes}
      customerGenders={customerGenders}
      birthdaySendingRules={birthdaySendingRules}
    />

  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      classes={{
        root: classes.modal,
        container: classes.container
      }}
      open={open}
      onClose={onCloseHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      PaperProps={{
        className: classes.paper,
        elevation: 0,
        square: false
      }}
      maxWidth={false}
      scroll="paper"
    >
      <DialogTitle
        className={classes.dialogHeader}
      >
        <div className={classes.modalHeader}>
          <div className={classes.modalTitle}>
            <Typography className={classes.modalTitleText} variant="h1">
              {mode === "new"
                ? t("New massive sending")
                : t("Edit massive sending")
              }
            </Typography>
            <Typography className={classes.modalTitleRequiredFields}>
              <LabelWithDot label={t('Required fields')} />
            </Typography>
          </div>
          <div className={classes.modalActions}>
            <Button
              className={`${classes.button} ${classes.goBack}`}
              onClick={handleGoBackButtonClick}
            >
              {t('Go back')}
            </Button>
            <Button
              className={`${classes.button} ${classes.continueButton}`}
              disabled={isContinueButtonDisabled()}
              classes={{ disabled: classes.createDisabled }}
              onClick={handleContinueButtonClick}
            >
              {getContinueButtonTitle}
            </Button>
          </div>
        </div>
        {
          mode === "new"
            ?
            <MassiveSendingStepper
              activeStep={activeStep}
            />
            :
            <TabContext
              value={activeTab}
            >
              <Tabs
                className={classes.tabs}
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  className={classes.tab}
                  label={t('Sending rules')}
                  value="first"
                />
                <Tab
                  className={classes.tab}
                  label={t('WhatsApp template')}
                  value="second"
                />
                <Tab
                  className={classes.tab}
                  label={t('Preview')}
                  value="third"
                />
              </Tabs>
            </TabContext>
        }
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <Fade in={open}>
          <div>
            {
              mode === "new"
                ?
                <>
                  {activeStep === 0 && FirstStepContent()}
                  {activeStep === 1 && SecondStepContent()}
                  {activeStep === 2 && ThirdStepContent()}
                </>
                :
                <>
                  <TabContext
                    value={activeTab}
                  >
                    <TabPanel
                      classes={{
                        root: classes.tabPanelRoot
                      }}
                      value="first"
                    >
                      {FirstStepContent()}
                    </TabPanel>
                    <TabPanel
                      value="second"
                      classes={{
                        root: classes.tabPanelRoot
                      }}
                    >
                      {SecondStepContent()}
                    </TabPanel>
                    <TabPanel
                      value="third"
                      classes={{
                        root: classes.tabPanelRoot
                      }}
                    >
                      {ThirdStepContent()}
                    </TabPanel>
                  </TabContext>
                </>
            }
            <CancelModal
              open={cancelModalOpen}
              setOpen={setCancelModalOpen}
              title={mode === "new" ? t("Cancel massive sending?") : t("Cancel massive sending edition?")}
              onSubmit={handleCloseModal}
            />
          </div>
        </Fade>
      </DialogContent>
    </Dialog>
  );
}