import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  iconButton: {
    width: 40,
    height: 40,
    backgroundColor: "#F6F6F6",
    "&:hover": {
      color: "#fff",
      backgroundColor: theme.colors.clearGreen,
    },
  },
  })
);

export default useStyles;