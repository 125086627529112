import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { EvolutionNotesProps } from "./props";
import useStyles from "./css";
import { Button, Typography } from "@material-ui/core";
import SectionList from "../SectionList";
import { SectionType } from "../../../../../models/enums/section-type";
import { SectionModel } from "../../../../../models/section-model";
import { SettingsPatientFormsService } from "../../../../../api/settings-patient-forms-service";
import { navbarActions } from "../../../../../redux/navbar-slice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { selectAccountSettings, selectUserInfo } from "../../../../../redux/store";
import Select from "../../../../common/Select";
import MultiSelect from "../../../../common/MultiSelect";
import CopyIcon from "../../../../../assets/icons/CopyIcon";
import { CopyEstablishmentSettingsModel } from "../../../../../models/copy-establishment-settings-model";
import { alertsActions } from "../../../../../redux/alerts-slice";
import Backdrop from "../../../../common/Backdrop";
import { SettingsEvolutionNotesCommunicationService } from "../../../../../api/settings-evolution-notes-communication-service";
import SectionNote from "../SectionNote";
import { SectionNoteData } from "../../../../../models/enums/section-note-data";


const EvolutionNotes = (props: EvolutionNotesProps) => {

    const { t } = useTranslation(["general"]);
    const { settingsSelectedEstablishmentId } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { enqueueAlert } = alertsActions;
    const currentTabSectionType = SectionType.EvolutionNoteForm;
    const accountSettings = useAppSelector(selectAccountSettings);
    
    const [currentEstablishmentId, setCurrentEstablishmentId] = useState<string|null|undefined>(undefined);
    const [copyToEstablishments, setCopyToEstablishments] = useState<string[]>([]);
    const [isCopyMode, setIsCopyMode] = useState<boolean>(false);
    const [isCopying, setIsCopying] = useState<boolean>(false);
    const [sections, setSections] = useState<SectionModel[]>([]);

    const userInfo = useAppSelector(selectUserInfo);
    const [enterpriseSelected, setEnterpriseSelected] = useState<boolean>(false);

    useEffect(() => {
        async function fetchData() {
            dispatch(navbarActions.setShowLoader(true));
            await loadData();
            dispatch(navbarActions.setShowLoader(false));
        }
        if (currentEstablishmentId !== undefined){
            void fetchData();
        }
    }, [currentEstablishmentId]);
    
    useEffect(() => {
        const enterpriseSelected = userInfo.accountId === settingsSelectedEstablishmentId;
        setEnterpriseSelected(enterpriseSelected);
        if (settingsSelectedEstablishmentId && !enterpriseSelected)
            setCurrentEstablishmentId(settingsSelectedEstablishmentId);
        else {
            if (accountSettings?.establishments?.length) {
                setCurrentEstablishmentId(accountSettings.establishments[0].key)
            } else {
                setCurrentEstablishmentId(null);
            }
        }
    }, [settingsSelectedEstablishmentId, accountSettings.establishments]);
    
    const loadData = async () => {
        const sectionsData =
          await SettingsPatientFormsService.getAccountSettingsTabSections(currentTabSectionType, currentEstablishmentId);
        setSections(sectionsData);
    };
    
    const handleCopyClick = async () => {
        if (isCopyMode) {
            setIsCopying(true);
            await SettingsEvolutionNotesCommunicationService.copyEvolutionNotesSetting(
              new CopyEstablishmentSettingsModel(currentEstablishmentId!, copyToEstablishments));
            setIsCopying(false);
            setIsCopyMode(false);
            dispatch(enqueueAlert({
                type: "Success",
                title: t("Vital signs copied"),
                description: t("To the selected establishments")
            }));
        } else {
            setIsCopyMode(true);
        }
    }
    
    const handleSelectEstablishment = (event: any) => {
        setCurrentEstablishmentId(event.target.value);
    }
    
    const handleSaveCopyToEstablishments = (establishments: string[]) => {
        setCopyToEstablishments(establishments);
    }
    
    const copyToEstablishmentsItems = () => {
        return accountSettings.establishments.filter(x => x.key !== currentEstablishmentId);
    }

    return <>
        <div className={classes.copySection}>
            <div className={classes.header}>
                <Typography className={classes.tabTitle}>
                    {t("Evolution notes")}
                </Typography>
            </div>
            {settingsSelectedEstablishmentId && (
                <SectionNote
                    sectionData={SectionNoteData.EvolutionNotes}
                />
            )}
        </div>
        {enterpriseSelected && <>
            <div className={classes.copyControls}>
                <Select
                    items={accountSettings.establishments}
                    value={currentEstablishmentId}
                    classes={{ root: `${classes.focused} ${classes.selected}` }}
                    styleClass={classes.select}
                    onChange={handleSelectEstablishment}
                />
                {accountSettings.establishments.length > 1 &&
                    <>
                        {isCopyMode &&
                            <MultiSelect
                                allItemsPlaceholder={t("Copy to all establishments")}
                                filterPlaceholder={t("Select destination establishment")}
                                items={copyToEstablishmentsItems()}
                                values={copyToEstablishments}
                                classes={{ root: `${classes.focused} ${classes.selected}` }}
                                styleClass={classes.multiSelect}
                                width={250}
                                height={372}
                                onChange={(x: any) => handleSaveCopyToEstablishments(x)}
                                onReset={() => handleSaveCopyToEstablishments([])}
                            />
                        }
                        <Button
                            className={classes.copyButton}
                            onClick={handleCopyClick}
                        >
                            <CopyIcon style={{ paddingRight: 5 }} />
                            {t("Copy vital signs")}
                        </Button>
                        <Button
                            hidden={!isCopyMode}
                            className={classes.cancelButton}
                            onClick={() => setIsCopyMode(false)}
                        >
                            {t("Cancel")}
                        </Button>
                    </>
                }
            </div>
            <div className={classes.newSectionButtonContainer} />
        </>}
        {!enterpriseSelected &&
            <>
                <div className={classes.newSectionButtonContainer}>
                    <div className={classes.header}>
                        <Typography className={classes.tabTitle}>
                        {t("Evolution notes")}
                        </Typography>
                    </div >
                </div >
            </>
        }
        {currentEstablishmentId !== undefined &&
          <SectionList
            accountId={currentEstablishmentId}
            sectionType={currentTabSectionType}
            sections={sections}
            setSections={setSections} />
        }
    
        <Backdrop show={isCopying} text={t("Copying")+"..."} />
    </>
};

export default EvolutionNotes;
