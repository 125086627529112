import { createSlice } from "@reduxjs/toolkit";
import Country from "../models/country";
import { AccountSettingsModel } from "../models/interfaces/account-settings-model";

const initialState: AccountSettingsModel = {
  accountId: "",
  accountName: "",
  currencyTemplate: "{0}",
  paymentMethods: [],
  isCashRegisterActivated: false,
  paymentsAndCollectionsEnabled: false,
  isClinicMode: false,
  hasEstablishmentsInClinicMode: false,
  countries: [],
  countryInSettings: new Country(),
  siteForFinalCustomersSmbName: "",
  scheduleSimultaneousServicesEnabled: false,
  hasMultipleAppointments: false,
  accountIanaTimezone: "",
  isEnterprise: null,
  establishments: [],
  terminologies: [],
  schedulerBlockDurationMinutes: 30
};

export const accountSettingsSlice = createSlice({
  name: "accountSettings",
  initialState: initialState,
  reducers: {
    setAccountSettings: (currentState, action) => {
      return action.payload;
    },
    setAccountName: (currentState, action) => {
      currentState = {...currentState, accountName: action.payload };
    },
    setCurrencyTemplate: (currentState, action) => {
      currentState = {...currentState, currencyTemplate: action.payload };
    },
    setPaymentMethods: (currentState, action) => {
      currentState = {...currentState, paymentMethods: action.payload };
    },
    setIsCashRegisterActivated: (currentState, action) => {
      currentState = {...currentState, isCashRegisterActivated: action.payload };
    },
    setAccountIanaTimezone: (currentState, action) => {
      currentState = {...currentState, accountIanaTimezone: action.payload };
    },
    setCountries: (currentState, action) => {
      currentState = {...currentState, countries: action.payload };
    },
    setCountryInSettings: (currentState, action) => {
      currentState = {...currentState, countryInSettings: action.payload };
    },
    setSiteForFinalCustomersSmbName: (currentState, action) => {
      currentState = {...currentState, siteForFinalCustomersSmbName: action.payload };
    },
    setScheduleSimultaneousServicesEnabled: (currentState, action) => {
      currentState = {...currentState, scheduleSimultaneousServicesEnabled: action.payload };
    },
    setHasMultipleAppointments: (currentState, action) => {
      currentState = {...currentState, hasMultipleAppointments: action.payload };
    },
    setIsEnterprise: (currentState, action) => {
      currentState = {...currentState, isEnterprise: action.payload };
    },
    setEstablishments: (currentState, action) => {
      currentState = {...currentState, establishments: action.payload };
    },
  }
})

export const { 
  setAccountSettings, 
  setAccountName, 
  setCurrencyTemplate, 
  setPaymentMethods, 
  setIsCashRegisterActivated,
  setHasMultipleAppointments,
  setAccountIanaTimezone,
  setCountries, 
  setCountryInSettings,
  setScheduleSimultaneousServicesEnabled,
  setSiteForFinalCustomersSmbName} = accountSettingsSlice.actions;
  
export default accountSettingsSlice.reducer;