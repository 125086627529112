export interface WeekdaysChangeSettingsModalProps {
    open: boolean;
    count: number;
    type: AlertPopupType;
    onClose?: () => void;
}


export enum AlertPopupType {
    AvailableHours = 0,
    Timezone
}