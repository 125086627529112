import { ApiClient } from "./api-client";
import { CustomerCommunication } from "./../models/customer-communication";
import {FileUploadResponse} from "./../models/file-upload-response"

export class CustomerCommunicationService {

    private static baseUrl: string = "/account";

    static updateCustomerCommunication(customerCommunication: CustomerCommunication): Promise<CustomerCommunication> {
        return ApiClient.post(`${this.baseUrl}/updatecustomercommunications`, customerCommunication)
            .then(data => new CustomerCommunication(data.customerCommunications)
            );
    }
    
    static async getCustomerCommunication(establishmentAccountId?: string): Promise<CustomerCommunication> {
        let endpoint = `${this.baseUrl}/getcustomercommunications`;
        if (establishmentAccountId)
            endpoint += `?AccountId=${establishmentAccountId}`;
        const data = await ApiClient.get(endpoint);
        return new CustomerCommunication(data.customerCommunications);
    }
}