import * as React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { Url } from "../../../constants/url";
import Messenger from "../Messenger";
import WhatsAppConversationRouter from "../WhatsAppConversationRouter"
import MassiveSengingsMain from "../massivesendings/MassiveSendingsMain"
import { useAppSelector } from "../../../redux/hooks";
import { selectUserInfo } from "../../../redux/store";


export default function WhatsAppRouter() {

  const userInfo = useAppSelector(selectUserInfo);

  return (
    <Switch>
      <Route path={Url.WhatsApp.MassiveSendings} component={MassiveSengingsMain} />
      <Route path={Url.WhatsApp.MassiveSendings + "/:sendingId"} component={MassiveSengingsMain} />
      <Route path={Url.WhatsApp.Main + "/:mobile"} component={
        userInfo.hasAccessToWhatsAppConversationsAndNotifications 
        ? WhatsAppConversationRouter
        : () => <Redirect to={Url.Appointments.Main}/>}/>
      <Route path={Url.WhatsApp.Main} component={
        userInfo.hasAccessToWhatsAppConversationsAndNotifications 
        ? Messenger
        : () => <Redirect to={Url.Appointments.Main}/>}/>
    </Switch>
  );
}