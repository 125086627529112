import { EmergencyContact } from "./emergency-contact";
import { CustomerEntity } from "./customer-entity";

export class CustomerInfoDetails extends CustomerEntity {
  notes: string | null;
  emergencyContacts: EmergencyContact[] | null;
  constructor(x?: any) {
    super(x);
    if (typeof x === "object") {
      this.notes = x.notes;
      this.emergencyContacts = x.emergencyContacts;
    } else {
      this.notes = null;
      this.emergencyContacts = null;
    }
  }
}
