import { ApiClient } from "./api-client";
import { AppointmentPaymentEntity } from "../models/appointment-payment-entity";
import { AppointmentPaymentValues } from "../models/appointment-payment-values";
import { AppointmentPricedService } from "../models/appointment-priced-service";
import { AppointmentPaymentChangeRecord } from "../models/appointment-payment-change-record";


export class AppointmentPaymentService {
  private static baseUrl: string = "/appointmentPayment";

  private static isoDate(date: Date): string {
    const year = date.toLocaleString("en-US", {
      year: "numeric",
    });
    const month = date.toLocaleString("en-US", {
      month: "2-digit",
    });
    const day = date.toLocaleString("en-US", {
      day: "2-digit",
    });

    return `${year}-${month}-${day}`;
  }
  
  static getAppointmentPricedServices(appointmentId: string | number): Promise<AppointmentPricedService[]> {
    return ApiClient
      .get(`${this.baseUrl}/getAppointmentPricedServices?AppointmentId=${appointmentId}`)
      .then(data => data.services)
  }

  static create(appointmentId: string | number, payment: AppointmentPaymentEntity, totalToBePaid: number): Promise<{payment: AppointmentPaymentEntity, paymentValues: AppointmentPaymentValues[] }> {
    return ApiClient
      .post(`${this.baseUrl}/create`, { appointmentId, payment, totalToBePaid })
      .then(data => { return { payment: new AppointmentPaymentEntity(data.payment), paymentValues: data.paymentValues }});
  }

  static update(appointmentId: string | number, payment: AppointmentPaymentEntity, previouslyPaid: number, totalToBePaid: number): Promise<{ payment: AppointmentPaymentEntity, changeRecords: AppointmentPaymentChangeRecord[] }> {
    const newPayment = { ...payment, previouslyPaid }
    return ApiClient
      .put(`${this.baseUrl}/update`, { appointmentId, payment: newPayment, totalToBePaid })
      .then(data => { return { payment: new AppointmentPaymentEntity(data.payment), changeRecords: data.changeRecords }});
  }
}