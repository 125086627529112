import React from "react";

import Typography from "@material-ui/core/Typography";

import useStyles from "./css";
import { useTranslation } from "react-i18next";
import { INotificationHeaderProps } from "./props";
import { CrossIcon } from "../../../../assets/icons";
import IconButton from "@material-ui/core/IconButton";


export default function NotificationHeader(props: INotificationHeaderProps) {
  
  const { handleClose } = props;
  
  const classes = useStyles();
  const { t } = useTranslation("general");
  
  return (
    <div className={classes.root}>
      <div className={classes.titleBlock}>
        <Typography className={classes.titleText}>
          {t("Notifications")}
        </Typography>
      </div>
      <div className={classes.closeButtonContainer}>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
          className={classes.closeIconButton}
        >
          <div>
            <CrossIcon style={{ width: 16, height: 16 }} viewBox="0 0 16 16" />
          </div>
        </IconButton>
      </div>
    </div>
  );
}