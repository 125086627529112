import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";

import { PatientFormProps } from "./props";
import useStyles from "./css";
import { Button, Typography } from "@material-ui/core";
import { SectionNewIcon } from "../../../../../assets/icons";
import { SectionType } from "../../../../../models/enums/section-type";
import { SettingsPatientFormsService } from "../../../../../api/settings-patient-forms-service";
import { SectionModel } from "../../../../../models/section-model";
import SectionNewModal from "../SectionNewModal";
import SectionList from "../SectionList";
import SectionListRefs from "../SectionList/section-list-refs";
import { Url } from "../../../../../constants/url";
import Section from "../Section";
import { SectionFieldModel } from "../../../../../models/section-field-model";
import { SectionFieldType } from "../../../../../models/enums/section-field-type";
import { SectionFieldItemModel } from "../../../../../models/section-field-item-model";
import { selectAccountSettings } from "../../../../../redux/store";
import { navbarActions } from "../../../../../redux/navbar-slice";


export default function PatientForm(props: PatientFormProps) {
    const sectionTNamespace = "sections";
    const { t } = useTranslation(["general", sectionTNamespace]);
    const { } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const currentTabSectionType = SectionType.PatientForm;
    const accountSettings = useAppSelector(selectAccountSettings)

    const [sections, setSections] = useState<SectionModel[]>([]);
    const [newSectionModalIsOpen, setNewSectionModalIsOpen] = useState<boolean>(false);

    const sectionListRef = useRef<SectionListRefs>(null);

    const pacientBasicInfoSectionStaticModel = new SectionModel({
        id: "patientBasicInfoId",
        name: t("default_section_name_Basic patient information", { ns: sectionTNamespace }),
        order: 0,
        type: SectionType.PatientForm,
        fields: [
            new SectionFieldModel({
                id: "firstName",
                name: t("default_field_name_First name(s)", { ns: sectionTNamespace }),
                order: 1,
                type: SectionFieldType.Text,
                isRequired: true,
                isAdditionalTextareaEnabled: false,
                isEditable: false,
                isRemovable: false
            }),
            new SectionFieldModel({
                id: "lastName",
                name: t("default_field_name_Last name", { ns: sectionTNamespace }),
                order: 2,
                type: SectionFieldType.Text,
                isRequired: true,
                isAdditionalTextareaEnabled: false,
                isEditable: false,
                isRemovable: false
            }),
            new SectionFieldModel({
                id: "mobile",
                name: t("default_field_name_Mobile", { ns: sectionTNamespace }),
                order: 3,
                type: SectionFieldType.PhoneNumber,
                isRequired: true,
                isAdditionalTextareaEnabled: false,
                isEditable: false,
                isRemovable: false
            }),
            new SectionFieldModel({
                id: "email",
                name: t("default_field_name_Email", { ns: sectionTNamespace }),
                order: 4,
                type: SectionFieldType.Email,
                isRequired: false,
                isAdditionalTextareaEnabled: false,
                isEditable: false,
                isRemovable: false
            }),
            new SectionFieldModel({
                id: "gender",
                name: t("default_field_name_Gender", { ns: sectionTNamespace }),
                order: 5,
                type: SectionFieldType.Radio,
                isRequired: false,
                isAdditionalTextareaEnabled: true,
                isEditable: false,
                isRemovable: false,
                items: [
                    new SectionFieldItemModel({
                        id: "male",
                        name: t("default_field_item_name_Male", { ns: sectionTNamespace }),
                        order: 1
                    }),
                    new SectionFieldItemModel({
                        id: "Female",
                        name: t("default_field_item_name_Female", { ns: sectionTNamespace }),
                        order: 2
                    }),
                    new SectionFieldItemModel({
                        id: "PreferNotToSay",
                        name: t("default_field_item_name_PreferNotToSay", { ns: sectionTNamespace }),
                        order: 3
                    }),
                    new SectionFieldItemModel({
                        id: "Custom",
                        name: t("default_field_item_name_Custom", { ns: sectionTNamespace }),
                        order: 4
                    }),
                ]
            }),
            new SectionFieldModel({
                id: "dateOfBirth",
                name: t("default_field_name_Date of Birth", { ns: sectionTNamespace }),
                order: 6,
                type: SectionFieldType.Date,
                isRequired: false,
                isAdditionalTextareaEnabled: false,
                isEditable: false,
                isRemovable: false
            }),
        ],
        isEditable: false,
        isRemovable: false
    });

    useEffect(() => {
        async function fetchData() {
            dispatch(navbarActions.setShowLoader(true));
            await loadData();
            dispatch(navbarActions.setShowLoader(false));
        }
        fetchData();
    }, []);

    const handleCreateSection = async (name: string) => {
        await sectionListRef?.current?.createSection(name);
    }

    const loadData = async () => {
        const sectionsData =
            await SettingsPatientFormsService.getAccountSettingsTabSections(currentTabSectionType);
        setSections(sectionsData);
    };

    const handleOpenPreview = () => {
        window.open(`https://sitiodereservas.somosclear.com/${accountSettings.siteForFinalCustomersSmbName}/patient-form?previewStep=first`, '_blank', 'noopener,noreferrer');
    }

    return <>
        <div className={classes.header}>
            <Typography className={classes.tabTitle}>
                {t("Patient form")}
            </Typography>
            <Button
                className={classes.previewButton}
                onClick={handleOpenPreview}
            >
                {t("Preview")}
            </Button>
            <Button
                className={classes.newButton}
                onClick={() => setNewSectionModalIsOpen(true)}
            >
                <SectionNewIcon style={{ paddingRight: 5 }} />
                {t("New section")}
            </Button>
        </div>
        <Section
            key={"patientBasicInfo"}
            section={pacientBasicInfoSectionStaticModel}
        />
        <SectionList
            ref={sectionListRef}
            sectionType={currentTabSectionType}
            sections={sections}
            setSections={setSections}
        />
        <div className={classes.footerContainer}>
            <Typography className={classes.footerNoteLabel}>
                <span className={classes.footerNoteLabelBold}>
                    {t("Note") + ": "}
                </span>
                {t("The customization of the Clinical History to be displayed in the form is done in the") + " "}
                <Link
                    to={Url.Settings.ClinicHistory}
                    className={classes.footerNoteLabelLink}
                >
                    {t("Clinical History")}
                </Link>
                {" " + t("tab of the Settings screen") + "."}
            </Typography>
        </div>
        <SectionNewModal
            sections={sections}
            isOpen={newSectionModalIsOpen}
            setOpen={setNewSectionModalIsOpen}
            onCreate={handleCreateSection}
        />
    </>
};