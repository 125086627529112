import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

export const useStyles = makeStyles((theme) => ({
    root: {
        backgroundColor: "gray"
    },
    title: {
        fontFamily: "Inter",
        fontSize: "14px",
        color: Color.gray5,
        marginBottom: 10,
        fontStyle: "normal",
        fontWeight: "bold",
    },
    titleSection: {
        fontFamily: "Inter",
        fontSize: "14px",
        color: Color.gray4,
        marginBottom: 20,
        fontStyle: "normal",
        fontWeight: "normal",
    },
    divArea: {
        backgroundColor: "#fff",
        borderRadius: 20,
        border: "1px solid #dbdcdd",
        padding: "40px 40px 51px 40px",
        margin: "10px 0"
    },
    rowContainer: {
        display: "flex",
        justifyContent: "flex-start",
        margin: "24px 0px 0px 0px",
    },
    label: {
        fontFamily: "Inter",
        display: "inline-flex",
        margin: 0,
        color: "#919CA5",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "bold",
        fontStyle: "normal",
        marginBottom: 5,
    },
    labelSecondary: {
        fontFamily: "Roboto,sans serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        color: "#919CA5"
    },
    required: {
        color: "#6462F3",
        marginLeft: 5,
        fontWeight: "bold",
        fontSize: 14,
    },
    optional: {
        marginLeft: 3,
        fontFamily: "Inter",
        display: "inline-flex",
        margin: 0,
        color: "#919CA5",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        marginBottom: 5,
    },
    description: {
        margin: 0,
        color: "#919CA5",
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "normal",
    },
    iconMoney: {
        color: theme.colors.clearMain,
        paddingTop: 4,
        fontSize: 22,
        fontWeight: "bolder"
    },
    iconSuggestedServices: {
        color: "#6462F3",
        fontSize: 22,
        fontWeight: "bolder",
        height: "24px !important",
        width: "24px !important"
    },
    iconCalendarCancel: {
        color: "#6462F3",
        fontSize: 17,
        fontWeight: "bolder"
    },
    iconEdit: {
        color: "#6462F3",
        paddingTop: 4,
        paddingLeft: 4,
        fontSize: 22,
        fontWeight: "bolder",
        height: "24px !important",
        width: "24px !important"
    },
    iconListMark: {
        color: "#6462F3",
        paddingTop: 4,
        paddingLeft: 4,
        fontSize: 22,
        fontWeight: "bolder",
        height: "24px !important",
        width: "24px !important"
    },
    iconEye: {
        color: "#6462F3",
        paddingTop: 9,
        paddingLeft: 6,
        fontSize: 22,
        fontWeight: "bolder",
        height: "29px !important",
        width: "29px !important"
    },
    iconInCheck: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: 45,
        paddingLeft: 5
    },
    labelH2: {
        fontFamily: "Roboto, sans-serif",
        display: "flex",
        paddingTop: 7,
        color: "#5C6477",
        fontSize: "14px",
        lineHeight: "14px",
        fontWeight: "bold",
        fontStyle: "normal",
        marginBottom: 5,
    },
    inputClass: {
        ...theme.typography.body2,
        color: theme.colors.grey5,
        fontWeight: "bold",
        "&::placeholder": {
            ...theme.typography.txtBody2,
            color: theme.colors.grey5,
            fontWeight: "normal",
            opacity: 0.4,
        }
    },
    inputTextareaClass: {
        ...theme.typography.txtBody2,
        color: theme.colors.grey5,
        fontWeight: "bold",
        "&::placeholder": {
            ...theme.typography.txtBody2,
            fontWeight: "normal",
            opacity: 0.8,
        }
    },
    switch: {
        display: "flex",
        alignItems: "center",
    },
    switchLabel: {
        margin: "0px 0px 0px 5px",
        display: "inline-flex",
        verticalAlign: "middle"
    },
    switchRoot: {
        width: 32,
        height: 21,
        padding: 0,
    },
    switchBase: {
        padding: 1,
        opacity: 1,
        height: "100%",
        border: "3px solid #323947",
        "&.Mui-checked": {
            transform: "translateX(13px)",
            border: "0px",
            color: Color.white,
            "& + .MuiSwitch-track": {
                opacity: 0.3,
                border: "none",
                backgroundColor: Color.green,
            },
            "& * .MuiSwitch-thumb": {
                width: 15,
            },
            //
        },
        "&.Mui-checked:not(.Mui-disabled)": {
            "& + .MuiSwitch-track": {
                opacity: 1,
            }
        },
        "&$focusVisible .MuiSwitch-thumb": {
            color: "#6462F3",
            border: "6px solid #fff",
        },
        "& * .MuiSwitch-thumb": {
            width: 12,
            height: 15,
            boxShadow: "none",
        },
    },
    switchThumb: {
        width: 12,
        height: 15,
        boxShadow: "none",
    },
    switchTrack: {
        borderRadius: 23 / 2,
        border: "3px solid #323947",
        backgroundColor: theme.palette.grey[50],
        opacity: 1,
        // .MuiSwitch-track
    },
    switchChecked: {},
    focusVisible: {},
    rowWeekDays: {
        height: 60
    },
    dayLabel: {
        width: 100,
        color: Color.gray5,
        fontSize: "14px",
        display: "inline",
        fontWeight: "bold"
    },
    headerLabel: {
        color: Color.gray4,
        fontSize: 12,
        fontWeight: "bold"
    },
    divider: {
        borderTop: "1px solid",
        borderColor: "#F0F0F0",
        marginTop: "24px",
        marginBottom: "24px",
    },
    paymentMethodsTitle: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "17px",
        color: theme.colors.grey4,
    },
    paymentLabel: {
        fontFamily: "Inter, sans-serif",
        display: "flex",
        color: theme.colors.grey5,
        fontSize: "14px",
        lineHeight: "14px",
        fontWeight: "bold",
        fontStyle: "normal",
        paddingLeft: "5px",
        paddingTop: "5px"
    },
    logoSection: {
        width: "100%",
        textAlign: "center",
        paddingTop: 5,
        height: 150
    },
    divLogo: {
        width: 120,
        height: 120,
        borderRadius: "50%",
        backgroundColor: "#6462F3",
        margin: "auto",
        position: "relative",
        cursor: "pointer"
    },
    divLogoIcon: {
        zIndex: 2,
        position: "absolute",
        height: "100%"
    },
    logoIconClear: {
        width: "70%",
        height: "100%",
        margin: "auto",
        fontSize: 70,
        color: "#F6F6F6",
    },
    divLogoIconHover: {
        zIndex: 3,
        position: "absolute",
        width: 120,
        height: 120,
        borderRadius: "50%",
        margin: "auto",
        opacity: 0,
        "&:hover": {
            background: "rgba(29, 32, 39, 0.7)",
            display: "flow-root",
            opacity: 0.7
        }
    },
    iconCam: {
        marginTop: 40,
        height: 25,
        textAlign: "center",
        color: "#FFFFFF",
    },
    labelChangePhoto: {
        marginTop: 5,
        fontWeight: "bold",
        fontSize: 12,
        textAlign: "center",
        color: "#FFFFFF",
        opacity: 1
    },
    photosEstablishmentRow: {
        marginTop: 10,
        display: "table",
        width: "100%",
        tableLayout: "fixed",
        textAlign: "center"
    },
    photosEstablishmentCol: {
        display: "inline-grid",
        //marginRight: 30,
        "&:first-child": {
            float: "left"
        },
        "&:last-child": {
            float: "right"
        }
    },
    photoEstablishment: {
        width: 230,
        height: 120,
        backgroundColor: "#F6F6F6",
        border: "1px dashed #DBDCDD;",
        borderRadius: 12,
        cursor: "pointer",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
    },
    iconPlus: {
        marginTop: 40,
        height: 25,
        textAlign: "center",
        color: theme.colors.clearMain
    },
    labelAddPhoto: {
        marginTop: 0,
        fontWeight: "bold",
        fontSize: 12,
        textAlign: "center",
        color: "#919CA5"
    },
    labelAddPhotoIns: {
        marginTop: -5,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        color: "#919CA5",
        textAlign: "center",
    },
    photosEstablishmentControls: {
        textAlign: "right",
        paddingTop: 5,
        paddingRight: 5
    },
    iconCamera: {
        color: "#919CA5",
        fontSize: 20,
        cursor: "pointer",
        marginLeft: 10,
        marginTop: 3,

    },
    iconTrash: {
        color: "#919CA5",
        fontSize: 24,
        cursor: "pointer",
        marginLeft: 10,
    },
    selectStyle: {
        color: Color.gray5,
        fontWeight: "bold",
        fontSize: 12,
        height: 40,
        padding: "13px 0px 13px 12px",
        border: "solid 1px rgba(0, 0, 0, 0.1)",
        borderRadius: 5,
        width: 327,
        paddingRight: 10,
        backgroundColor: "#F6F6F6",
        cursor: "pointer",
        "& * .ant-picker-suffix, .ant-picker-clear": {
            display: "none",
        },
        "& * input": {
            fontFamily: "Roboto",
            fontWeight: "bold",
            fontSize: 12,
            fontStyle: "normal",
            color: Color.gray5,
            cursor: "pointer",
        },
    },
    selectHrs: {
        "& div:after": {
            content: "'hrs'",
            marginLeft: "35px",
            // marginBottom: "2px",
            position: "absolute",
            color: Color.gray5,
            "@media (max-width: 960px)": {
                marginBottom: "2px",
            }
        },
    },
    selectHrsClose: {
        "& div:after": {
            content: "'hrs'",
            color: "rgba(0, 0, 0, 0.25)",
            marginLeft: "35px",
            marginBottom: "2px",
            position: "absolute",
            "@media (max-width: 960px)": {
                marginBottom: "2px",
            }
        },
    },
    selectStyleHrs: {
        position: "relative",
        top: 1,
        left: -150,
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 12,
        fontStyle: "normal",
    },
    selectHr: {
        "& div:after": {
            content: "'hr'",
            marginLeft: "35px",
            position: "absolute",
            color: theme.colors.grey5,
            "@media (max-width: 2150px)": {
                marginBottom: "4px",
            },
            "@media (max-width: 1950px)": {
                marginBottom: "2px",
            },
            "@media (max-width: 1750px)": {
                marginBottom: "0",
            },
            "@media (max-width: 1600px)": {
                marginBottom: "1px",
            },
        },
    },
    selectMin: {
        "& div:after": {
            content: "'min'",
            marginLeft: "35px",
            marginBottom: "2px",
            position: "absolute",
            color: theme.colors.grey5,
        },
    },
    flexContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "90%"

    },
    copyLinkButton: {
        height: 32,
        width: "100%",

        marginTop: 32,

        borderRadius: 51,
        padding: "16px 16px",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 12,
        border: "1px solid " + Color.clear1,
        color: Color.clear1,
    },
    iconTooltipRoot: {
        display: "inline-block",
        marginLeft: 5,
        "&>div": {
            maxWidth: 220
        }
    },
    titleWithTooltipContainer: {
        display: "flex",
        flexDirection: "row",
        gap: 7
    },
    selectContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 6
    },
    terminologyButton: {
        "&.MuiButton-root": {
            minWidth: 0,
            marginLeft: 5,
            padding: 0,
            height: 15,
            "& svg": {
                height: "18px !important",
                width: "18px !important"
            }
        },
    },
    optionalLabel: {
        fontWeight: "normal",
        marginLeft: 3
    },
    header: {
        display: "flex",
        alignItems: "center",
        marginBottom: 15,
        gap: 20,
        width: "100%",
        justifyContent: "space-between",
    },
    tabTitle: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: Color.gray5
    },
}));

