import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { Button } from "@material-ui/core";
import { Editor } from "@tinymce/tinymce-react";
import { ArrowDropDownIcon, InfoIcon, ListIcon, SaveIcon } from "./../../../../../assets/icons"
import { useStyles } from "./css";
import { CustomerCommunicationService } from "../../../../../api/settings-customer-communication-service";
import { CustomerCommunication } from "../../../../../models/customer-communication";
import { Trans, useTranslation } from 'react-i18next';
import Switch from "./../../../../common/Switch";
import { FirstRemindBefore } from "../../../../../models/enums/first-remind-before";
import { SecondRemindBefore } from "../../../../../models/enums/second-remind-before";
import { SelectItem } from "../../../../common/SelectWithLabel/props";
import Select from "../../../../common/Select";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import {WhatsappFollowupSendTime} from "../../../../../models/enums/whatsapp-followup-send-time";
import {TimePicker} from "antd";
import moment from "moment/moment";
import useCustomerTerminology from "../../../../../hooks/useCustomerTerminology";
import { navbarActions } from "../../../../../redux/navbar-slice";
import { WhatsAppService } from "../../../../../api/whatsapp-service";
import { CustomerCommunicationWa } from "../../../../../models/customer-communication-wa";
import IconTooltip from "../../../../common/IconTooltip";
import { SettingsEnterpriseProps } from "../../../../../models/interfaces/settings-enterprise-props";
import SectionNote from "../../../../establishment-settings/Sections/FormsSettingsTabs/SectionNote";
import { selectUserInfo } from "../../../../../redux/store";
import { SectionNoteData } from "../../../../../models/enums/section-note-data";

export default (props: SettingsEnterpriseProps) => {

    const { settingsSelectedEstablishmentId } = props;

    const defaultCustomerCommunication = new CustomerCommunication({
        notifyOnAppointmentCreated: true,
        notifyOnAppointmentModified: false,
        notifyOnAppointmentCanceled: false,
        firstReminderEnabled: true,
        secondReminderEnabled: false,
        firstRemindBefore: FirstRemindBefore.OneDay,
        secondRemindBefore: SecondRemindBefore.OneHour,
        customContactUsMessage: "",
        customContantUsMessageEnabled: false,
        showServicePricesInWhatsApp: true,
        showCancellationPolicies: false,
        hasWhatsAppCancellationPoliciesOnAppointmentConfirmation: false,
        hasWhatsAppCancellationPoliciesOnAppointmentReminder: false,
        hasWhatsAppCancellationPoliciesOnAppointmentCancel: false,
    });
    const defaultCustomerCommunicationWa = new CustomerCommunicationWa({
        availabilityStatusResetTime: "23:59",
        availabilityStatusResetEnabled: true
    });

    const { t } = useTranslation(["general"]);
    const [curCommunications, setCurCommunications] = useState<CustomerCommunication>(defaultCustomerCommunication);
    const [newCommunications, setNewCommunications] = useState<CustomerCommunication>(defaultCustomerCommunication);
    const [curWaCommunications, setCurWaCommunications] = useState<CustomerCommunicationWa>(defaultCustomerCommunicationWa);
    const [newWaCommunications, setNewWaCommunications] = useState<CustomerCommunicationWa>(defaultCustomerCommunicationWa);

    const maxInitialMessageTextLength: number = 800;
    const [initialMessageTextLength, setInitialMessageTextLength] = useState<number>(0);
    const [isInitialMessageTextChanged, setInitialMessageTextChanged] = useState<boolean>(false);
    const [initialMessageFocused, setInitialMessageFocused] = useState<boolean>(false);

    const maxTextLength: number = 1024;
    const [textLength, setTextLength] = useState<number>(0);
    const [isTextChanged, setTextChanged] = useState<boolean>(false);
    const [focused, setFocused] = useState<boolean>(false);

    const maxFollowUpTextLength: number = 1024;
    const [followUpTextLength, setFollowUpTextLength] = useState<number>(0);
    const [isFollowUpTextChanged, setFollowUpTextChanged] = useState<boolean>(false);
    const [followUpFocused, setFollowUpFocused] = useState<boolean>(false);
    const [savedWhatsAppFollowUpMessage, setSavedWhatsAppFollowUpMessage] = useState<string | null>("");

    const maxRequestQuotesTextLength: number = 1024;
    const [requestQuotesTextLength, setRequestQuotesTextLength] = useState<number>(0);
    const [isRequestQuotesTextChanged, setRequestQuotesTextChanged] = useState<boolean>(false);
    const [requestQuotesFocused, setRequestQuotesFocused] = useState<boolean>(false);
    const [savedWhatsAppRequestQuotesMessage, setSavedWhatsAppRequestQuotesMessage] = useState<string | null>("");
    const [isWhatsAppRequestQuotesDelayOpen, setIsWhatsAppRequestQuotesDelayOpen] = useState<boolean>(false);
    const [availabilityStatusResetTimeIsOpen, setAvailabilityStatusResetTimeIsOpen] = useState<boolean>(false);
    const [loadComplete, setLoadComplete] = useState<boolean>(false);

    const dispatch = useAppDispatch();

    const classes = useStyles();

    const timeFormat = "HH:mm";

    const userInfo = useAppSelector(selectUserInfo);
    const enterpriseSelected = userInfo.accountId === settingsSelectedEstablishmentId;

    useEffect(() => {
        (async () => {
            dispatch(navbarActions.setShowLoader(true));
            const data = settingsSelectedEstablishmentId
                ? await CustomerCommunicationService.getCustomerCommunication(settingsSelectedEstablishmentId)
                : await CustomerCommunicationService.getCustomerCommunication();
            const waData = settingsSelectedEstablishmentId
                ? await WhatsAppService.getCustomerCommunication(settingsSelectedEstablishmentId)
                : await WhatsAppService.getCustomerCommunication();
            setCurCommunications(data);
            setCurWaCommunications(waData);
            dispatch(navbarActions.setShowLoader(false));
            setSavedWhatsAppFollowUpMessage(data.whatsAppFollowUpMessage === null
                ? getDefaultFollowUpMessage()
                : data.whatsAppFollowUpMessage);

            setSavedWhatsAppRequestQuotesMessage(data.whatsAppRequestQuotesMessage === null
                ? getDefaultRequestQuotesMessage()
                : data.whatsAppRequestQuotesMessage);
            setLoadComplete(true);
        })();
    }, [settingsSelectedEstablishmentId])

    useEffect(() => {
        if (curCommunications.firstReminderEnabled !== newCommunications.firstReminderEnabled ||
            curCommunications.secondReminderEnabled !== newCommunications.secondReminderEnabled ||
            curCommunications.firstRemindBefore !== newCommunications.firstRemindBefore ||
            curCommunications.secondRemindBefore !== newCommunications.secondRemindBefore ||
            curCommunications.notifyOnAppointmentCreated !== newCommunications.notifyOnAppointmentCreated ||
            curCommunications.notifyOnAppointmentModified !== newCommunications.notifyOnAppointmentModified ||
            curCommunications.notifyOnAppointmentCanceled !== newCommunications.notifyOnAppointmentCanceled ||
            curCommunications.customContactUsMessageEnabled !== newCommunications.customContactUsMessageEnabled ||
            curCommunications.showServicePricesInWhatsApp !== newCommunications.showServicePricesInWhatsApp ||
            curCommunications.scheduleWhatsAppFollowUpMessageEnabled !== newCommunications.scheduleWhatsAppFollowUpMessageEnabled ||
            curCommunications.whatsAppFollowUpMessage !== newCommunications.whatsAppFollowUpMessage ||
            curCommunications.whatsAppFollowUpSendTime !== newCommunications.whatsAppFollowUpSendTime ||
            curCommunications.whatsAppRequestQuotesEnabled !== newCommunications.whatsAppRequestQuotesEnabled ||
            curCommunications.whatsAppRequestQuotesMessage !== newCommunications.whatsAppRequestQuotesMessage ||
            curCommunications.whatsAppRequestQuotesDelay !== newCommunications.whatsAppRequestQuotesDelay ||
            curCommunications.hasWhatsAppCancellationPoliciesOnAppointmentConfirmation !== newCommunications.hasWhatsAppCancellationPoliciesOnAppointmentConfirmation ||
            curCommunications.hasWhatsAppCancellationPoliciesOnAppointmentReminder !== newCommunications.hasWhatsAppCancellationPoliciesOnAppointmentReminder ||
            curCommunications.hasWhatsAppCancellationPoliciesOnAppointmentCancel !== newCommunications.hasWhatsAppCancellationPoliciesOnAppointmentCancel) {
            (async () => await updateSettings(newCommunications))();
        }

    }, [newCommunications])

    useEffect(() => {
        if (curWaCommunications.availabilityStatusResetEnabled !== newWaCommunications.availabilityStatusResetEnabled ||
          curWaCommunications.availabilityStatusResetTime !== newWaCommunications.availabilityStatusResetTime) {
            (async () => await updateWaSettings(newWaCommunications))();
        }

    }, [newWaCommunications])

    const updateSettings = async (model: CustomerCommunication) => {
        if (model.customContactUsMessage === "")
            model.customContactUsMessage = null;
        model.accountId = (settingsSelectedEstablishmentId && !enterpriseSelected) ? settingsSelectedEstablishmentId : null;
        const data = await CustomerCommunicationService.updateCustomerCommunication(model);
        setCurCommunications(data);
    };

    async function updateWaSettings(model: CustomerCommunicationWa) {
        if (model.whatsAppInitialMessage === "")
            model.whatsAppInitialMessage = null;
        model.accountId = (settingsSelectedEstablishmentId && !enterpriseSelected) ? settingsSelectedEstablishmentId : null;
        const data = await WhatsAppService.updateCustomerCommunication(model);
        setCurWaCommunications(data);
    }

    const firstReminderSelectList: SelectItem[] = [
        { key: FirstRemindBefore.OneDay, value: FirstRemindBefore.OneDay + " " + t("hours before the appointment") },
        { key: FirstRemindBefore.TwoDays, value: FirstRemindBefore.TwoDays + " " + t("hours before the appointment") }];

    const secondReminderSelectList: SelectItem[] = [
        { key: SecondRemindBefore.OneHour, value: SecondRemindBefore.OneHour + " " + t("hour before the appointment") },
        { key: SecondRemindBefore.TwoHours, value: SecondRemindBefore.TwoHours + " " + t("hours before the appointment") },
        { key: SecondRemindBefore.ThreeHours, value: SecondRemindBefore.ThreeHours + " " + t("hours before the appointment") }];

    const handleFirstReminderEnabledChanged = (event: any) => {
        setNewCommunications(
            new CustomerCommunication({
                ...curCommunications,
                firstReminderEnabled: event.target.checked
            })
        );
    };
    const handleSecondReminderEnabledChanged = (event: any) => {
        setNewCommunications(
            new CustomerCommunication({
                ...curCommunications,
                secondReminderEnabled: event.target.checked
            })
        );
    };
    const handleFirstRemindBeforeChanged = (event: any) => {
        setNewCommunications(
            new CustomerCommunication({ ...curCommunications, firstRemindBefore: event.target.value })
        );
    };
    const handleSecondRemindBeforeChanged = (event: any) => {
        setNewCommunications(
            new CustomerCommunication({ ...curCommunications, secondRemindBefore: event.target.value })
        );
    };
    const handleNotifyOnAppointmentCreatedChanged = (event: any) => {
        setNewCommunications(
            new CustomerCommunication({
                ...curCommunications,
                notifyOnAppointmentCreated: event.target.checked
            })
        );
    };
    const handleNotifyOnAppointmentModifiedChanged = (event: any) => {
        setNewCommunications(
            new CustomerCommunication({
                ...curCommunications,
                notifyOnAppointmentModified: event.target.checked
            })
        );
    };

    const handleNotifyOnAppointmentCanceledChanged = (event: any) => {
        setNewCommunications(
            new CustomerCommunication({
                ...curCommunications,
                notifyOnAppointmentCanceled: event.target.checked
            })
        );
    };

    const handleShowServicePricesInWhatsAppChanged = (event: any) => {
        setNewCommunications(
            new CustomerCommunication({
                ...curCommunications,
                showServicePricesInWhatsApp: event.target.checked
            })
        );
    };

    const handleCustomContactUsMessageEnabledChanged = (event: any) => {
        const changedCommunicationEntity = new CustomerCommunication({
            ...curCommunications,
            customContactUsMessageEnabled: event.target.checked,
        });

        if (event.target.checked === false && curCommunications.customContactUsMessageEnabled === true)
            setNewCommunications(changedCommunicationEntity)
        else
            setCurCommunications(changedCommunicationEntity)
    };

    const handleInitialMessageChanged = (c: string, editor: any) => {
        const contentText = editor.getContent({ format: "text" });
        const contentHtml = editor.getContent();
        if (contentText.length <= maxInitialMessageTextLength) {
            if (!isInitialMessageTextChanged && curWaCommunications.whatsAppInitialMessage !== contentHtml
            && !(curWaCommunications.whatsAppInitialMessage === null && contentHtml == getDefaultInitialMessage())) {
                setInitialMessageTextChanged(true);
            }
            setCurWaCommunications(
                new CustomerCommunicationWa({
                    ...curWaCommunications,
                    whatsAppInitialMessage: contentHtml
                })
            );
            setInitialMessageTextLength(contentText.length);
        }
    };

    const handleSaveInitialMessageButtonPressed = async () => {
        await updateWaSettings(curWaCommunications);
        setInitialMessageTextChanged(false);
    };

    const handleCustomContactUsMessageChanged = (c: string, editor: any) => {
        const contentText = editor.getContent({ format: "text" });
        const contentHtml = editor.getContent();
        if (contentText.length <= maxTextLength) {
            if (!isTextChanged && curCommunications.customContactUsMessage !== contentHtml) {
                setTextChanged(true);
            }
            setCurCommunications(
                new CustomerCommunication({
                    ...curCommunications,
                    customContactUsMessage: contentHtml
                })
            );
            setTextLength(contentText.length);
        }
    };

    const handleSaveCustomContantUsSettingsButtonPressed = async () => {
        await updateSettings(curCommunications);
        setTextChanged(false);
    };

    const handleScheduleWhatsAppFollowUpMessageEnabledChanged = (event: any) => {
        setNewCommunications(
            new CustomerCommunication({
                ...curCommunications,
                scheduleWhatsAppFollowUpMessageEnabled: event.target.checked,
                whatsAppFollowUpMessage: savedWhatsAppFollowUpMessage
            })
        );
    };

    const handleWhatsAppFollowUpMessageChanged = (c: string, editor: any) => {
        const contentText = editor.getContent({ format: "text" });
        if (contentText.length <= maxFollowUpTextLength) {
            const contentHtml = editor.getContent();
            setCurCommunications(
                new CustomerCommunication({
                    ...curCommunications,
                    whatsAppFollowUpMessage: contentHtml
                })
            );
            setFollowUpTextChanged(savedWhatsAppFollowUpMessage !== contentHtml);
            setFollowUpTextLength(contentText.length);
        }
    };

    const whatsAppFollowUpSendTimeSelectList: SelectItem[] = [
        { key: WhatsappFollowupSendTime.HalfHour, value: t("30 minutes later") },
        { key: WhatsappFollowupSendTime.OneHour, value: t("1 hour later") },
        { key: WhatsappFollowupSendTime.TwoHours, value: t("2 hours later") }];

    const handleWhatsAppFollowUpSendTimeChanged = (event: any) => {
        setNewCommunications(
            new CustomerCommunication({ ...curCommunications, whatsAppFollowUpSendTime: event.target.value })
        );
    };

    const handleSaveWhatsAppFollowUpMessageButtonPressed = async () => {
        await updateSettings(curCommunications);
        setSavedWhatsAppFollowUpMessage(curCommunications.whatsAppFollowUpMessage);
        setFollowUpTextChanged(false);
    };

    const handleWhatsAppRequestQuotesEnabledChanged = (event: any) => {
        setNewCommunications(
            new CustomerCommunication({
                ...curCommunications,
                whatsAppRequestQuotesEnabled: event.target.checked,
                whatsAppRequestQuotesMessage: savedWhatsAppRequestQuotesMessage
            })
        );
    };

    function handleAvailabilityStatusResetEnabledOnChange(event: any) {
        setNewWaCommunications(
            new CustomerCommunicationWa({
                ...curWaCommunications,
                availabilityStatusResetEnabled: event.target.checked,
            })
        );
    }

    function handleAvailabilityStatusResetTimeOnChange(time: any) {
        setNewWaCommunications(
          new CustomerCommunicationWa({
              ...curWaCommunications,
              availabilityStatusResetTime: time,
          })
        );
    }

    const handleWhatsAppRequestQuotesMessageChanged = (c: string, editor: any) => {
        const contentText = editor.getContent({ format: "text" });
        if (contentText.length <= maxRequestQuotesTextLength) {
            const contentHtml = editor.getContent();
            setCurCommunications(
                new CustomerCommunication({
                    ...curCommunications,
                    whatsAppRequestQuotesMessage: contentHtml
                })
            );
            setRequestQuotesTextChanged(savedWhatsAppRequestQuotesMessage !== contentHtml);
            setRequestQuotesTextLength(contentText.length);
        }
    };

    const handleWhatsAppRequestQuotesDelayChanged = (event: any) => {
        setNewCommunications(
            new CustomerCommunication({ ...curCommunications, whatsAppRequestQuotesDelay: event })
        );
    };

    const handleSaveWhatsAppRequestQuotesMessageButtonPressed = async () => {
        await updateSettings(curCommunications);
        setSavedWhatsAppRequestQuotesMessage(curCommunications.whatsAppRequestQuotesMessage);
        setRequestQuotesTextChanged(false);
    };

    function handleWhatsAppCancellationPoliciesOnAppointmentConfirmation(event: any) {
        setNewCommunications(
          new CustomerCommunication({
              ...curCommunications,
              hasWhatsAppCancellationPoliciesOnAppointmentConfirmation: event.target.checked,
          })
        );
    }

    function handleWhatsAppCancellationPoliciesOnAppointmentReminder(event: any) {
        setNewCommunications(
          new CustomerCommunication({
              ...curCommunications,
              hasWhatsAppCancellationPoliciesOnAppointmentReminder: event.target.checked,
          })
        );
    }

    function handleWhatsAppCancellationPoliciesOnAppointmentCancel(event: any) {
        setNewCommunications(
          new CustomerCommunication({
              ...curCommunications,
              hasWhatsAppCancellationPoliciesOnAppointmentCancel: event.target.checked,
          })
        );
    }

    const setInitialMessageLength = (evt: any, editor: any) => {
        setInitialMessageTextLength(editor.getContent({ format: "text" }).length);
    };

    const setLength = (evt: any, editor: any) => {
        setTextLength(editor.getContent({ format: "text" }).length);
    };

    const setFollowUpLength = (evt: any, editor: any) => {
        setFollowUpTextLength(editor.getContent({ format: "text" }).length);
    };

    const setRequestQuotesLength = (evt: any, editor: any) => {
        setRequestQuotesTextLength(editor.getContent({ format: "text" }).length);
    };

    const getBorderStyle = () => {
        if (textLength === 0) {
            return classes.tinyMceInvalid;
        }
        else {
            return focused ? classes.tinyMceFocused : classes.tinyMceUnfocused
        }
    }

    const getInitialMessageBorderStyle = () => {
        if (initialMessageTextLength === 0) {
            return classes.tinyMceInvalid;
        }
        else {
            return initialMessageFocused ? classes.tinyMceFocused : classes.tinyMceUnfocused
        }
    }

    const getFollowUpBorderStyle = () => {
        if (followUpTextLength === 0 || isFollowUpTextChanged) {
            return classes.tinyMceInvalid;
        }
        else {
            return followUpFocused ? classes.tinyMceFocused : classes.tinyMceUnfocused
        }
    }

    const getRequestQuotesBorderStyle = () => {
        if (requestQuotesTextLength === 0 || isRequestQuotesTextChanged) {
            return classes.tinyMceInvalid;
        }
        else {
            return requestQuotesFocused ? classes.tinyMceFocused : classes.tinyMceUnfocused
        }
    }

    const getDefaultInitialMessage = () => {
        return t("default_initial_message_template").replace("{{0}}", curCommunications.smbName)
    }

    const getDefaultMessage = () => {
        return t("default_custom_send_us_a_message_template") + curCommunications.smbPhone + "</b>.</p>"
    }

    const getDefaultFollowUpMessage = () => {
        return t("default_whatsapp_followup_a_message_template")
    }

    const getDefaultRequestQuotesMessage = () => {
        return t("default_whatsapp_request_quotes_message_template")
    }


    return (
        <>
            {settingsSelectedEstablishmentId && (
                <>
                    <div className={classes.header}>
                        <Typography className={classes.tabTitle}>
                            {t("Communication with the customer")}
                        </Typography>
                    </div>
                    <SectionNote
                        sectionData={SectionNoteData.Communication}
                    />
                </>
            )}
            <div className={classes.section}>
                <div>
                    <Typography className={classes.title}>
                        {t('WhatsApp Chatbot terms')}
                    </Typography>
                </div>
                <Box className={classes.divArea}>
                    <Grid container>
                        <Grid item xs={6}>
                            <div className={classes.root}>
                                <label className={classes.label}>
                                    {useCustomerTerminology(t('Initial message that is sent when the customer contacts the WhatsApp Chatbot'), 'customer')}
                                </label>
                                {!loadComplete ? <></> :
                                  <div className={classes.initialMessage}>
                                    <div
                                      className={`${classes.tinyMceOverrides} ${getInitialMessageBorderStyle()} tinyMceOverrides`}>
                                        <Editor
                                          apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
                                          init={{
                                              height: 160,
                                              menubar: false,
                                              statusbar: false,
                                              plugins: 'wordcount paste',
                                              toolbar: 'bold italic strikethrough',
                                              content_style: "body {fontFamily: Inter, sans-serif; font-weight: 400; font-size: 14px; color: #323947 }",
                                              entity_encoding: "raw",
                                              formats: {
                                                  bold: { inline: 'b' },
                                                  italic: { inline: 'i' },
                                                  strikethrough: { inline: 's' }
                                              },
                                              paste_as_text: true,
                                              extended_valid_elements: "b, i, s",
                                              invalid_elements: "strong, em, span",
                                              forced_root_block: false,
                                              setup: (editor) => {
                                                  editor.on("keydown", (e) => {
                                                      if (e.key === "Enter" || e.key === "Tab") {
                                                          e.preventDefault();
                                                          e.stopPropagation();
                                                          return false;
                                                      }
                                                  });
                                              }
                                          }}
                                          value={curWaCommunications?.whatsAppInitialMessage === null || curWaCommunications.whatsAppInitialMessage === null
                                            ? getDefaultInitialMessage()
                                            : curWaCommunications?.whatsAppInitialMessage}
                                          onFocusIn={() => setInitialMessageFocused(true)}
                                          onFocusOut={() => setInitialMessageFocused(false)}
                                          onEditorChange={handleInitialMessageChanged}
                                          outputFormat="html"
                                          onChange={(e) => {
                                              if (initialMessageTextLength >= maxInitialMessageTextLength) {
                                                  e.preventDefault();
                                              }
                                          }}
                                          onInit={setInitialMessageLength}
                                        />
                                        <span
                                          className={classes.charCounter}>{initialMessageTextLength + "/" + maxInitialMessageTextLength}</span>
                                    </div>
                                    <Button
                                      aria-label={t("Save")}
                                      className={`${classes.saveCustomMessageButton} saveCustomMessageButton`}
                                      onClick={handleSaveInitialMessageButtonPressed}
                                      disabled={!isInitialMessageTextChanged || initialMessageTextLength >= maxInitialMessageTextLength || initialMessageTextLength === 0}
                                    >
                                        <SaveIcon
                                          style={{ width: 16, height: 16, marginRight: "12px" }}
                                          viewBox="0 0 16 16"
                                        />
                                        <Typography className={classes.saveCustomMessageText}>
                                            {t("Save")}
                                        </Typography>
                                    </Button>
                                    <div className={"staticText"}>
                                        <p>
                                            <b>{t("Type the number")}</b> {t("of the option you would like to more about.")}
                                        </p>
                                        <p>{t("Type 0 if you want to schedule an appointment.")}</p>
                                        <p>{t("Type 1 if you want personalized attention.")}</p>
                                    </div>
                                    <div className={"menuButton"}>
                                        <ListIcon/>
                                        <span>{t("Menu")}</span>
                                    </div>
                                  </div>
                                }
                            </div>
                        </Grid>
                    </Grid>
                    <hr className={classes.divider} />
                    <Grid container>
                        <Grid item xs={6}>
                            <div className={classes.root}>
                                <>
                                    <div className={classes.divRowWhats}>
                                        <div className={classes.divCheck}>
                                            <Switch
                                                value={curCommunications?.customContactUsMessageEnabled ?? false}
                                                onChange={handleCustomContactUsMessageEnabledChanged}
                                                baseClass={classes.switchBase} />
                                        </div>
                                        <div className={classes.divLabel}>
                                            <label >
                                                {t("Customized Chatbot message for the “I want to chat with someone” option")}
                                            </label>
                                        </div>
                                    </div>
                                    {!curCommunications?.customContactUsMessageEnabled ?
                                        <></> :
                                        <div>
                                            <div className={`${classes.tinyMceOverrides} ${getBorderStyle()}`}>
                                                <Editor
                                                    apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
                                                    init={{
                                                        height: 160,
                                                        width: 690,
                                                        menubar: false,
                                                        statusbar: false,
                                                        plugins: 'wordcount paste',
                                                        toolbar: 'bold italic strikethrough',
                                                        content_style: "body {fontFamily: Inter, sans-serif; font-weight: 400; font-size: 14px; color: #323947 }",
                                                        entity_encoding: "raw",
                                                        formats: {
                                                            bold: { inline: 'b' },
                                                            italic: { inline: 'i' },
                                                            strikethrough: { inline: 's' }
                                                        },
                                                        paste_as_text: true,
                                                        extended_valid_elements: "b, i, s",
                                                        invalid_elements: "strong, em, span",
                                                        forced_root_block: false,
                                                        setup: (editor) => {
                                                            editor.on("keydown", (e) => {
                                                              if (e.key === "Enter" || e.key === "Tab") {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                return false;
                                                              }
                                                            });
                                                          }
                                                    }}
                                                    value={curCommunications?.customContactUsMessage === null || curCommunications.customContactUsMessage === null
                                                        ? getDefaultMessage()
                                                        : curCommunications?.customContactUsMessage}
                                                    onFocusIn={() => setFocused(true)}
                                                    onFocusOut={() => setFocused(false)}
                                                    onInit={setLength}
                                                    onEditorChange={handleCustomContactUsMessageChanged}
                                                    outputFormat="html"
                                                    onChange={(e) => {
                                                        if (textLength >= maxTextLength) {
                                                            e.preventDefault();
                                                        }
                                                    }}
                                                />
                                                <span className={classes.charCounter}>{textLength + "/" + maxTextLength}</span>
                                            </div>
                                            <Button
                                                aria-label={t("Save")}
                                                className={classes.saveCustomMessageButton}
                                                onClick={handleSaveCustomContantUsSettingsButtonPressed}
                                                disabled={!isTextChanged || textLength >= maxTextLength || textLength === 0}
                                            >
                                                <SaveIcon
                                                    style={{ width: 16, height: 16, marginRight: "12px" }}
                                                    viewBox="0 0 16 16"
                                                />
                                                <Typography className={classes.saveCustomMessageText}>
                                                    {t("Save")}
                                                </Typography>
                                            </Button>
                                        </div>
                                    }
                                </>
                            </div>
                        </Grid>
                    </Grid>
                    <hr className={classes.divider} />
                    <Grid container>
                        <Grid item xs={6}>
                            <div className={classes.root}>
                                <>
                                    <div className={classes.divRowWhats}>
                                        <div className={classes.divCheck}>
                                            <Switch
                                              value={curCommunications?.whatsAppRequestQuotesEnabled ?? false}
                                              onChange={handleWhatsAppRequestQuotesEnabledChanged}
                                              baseClass={classes.switchBase} />
                                        </div>
                                        <div className={classes.divLabel}>
                                            <label >
                                                {t("Allow to request quotes from the WhatsApp Chatbot")}
                                            </label>
                                        </div>
                                    </div>
                                    {!curCommunications?.whatsAppRequestQuotesEnabled ?
                                      <></> :
                                      <>
                                          <div className={`${classes.tinyMceOverrides} ${getRequestQuotesBorderStyle()}`}>
                                              <Editor
                                                apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
                                                init={{
                                                    height: 160,
                                                    width: 690,
                                                    menubar: false,
                                                    statusbar: false,
                                                    plugins: 'wordcount paste',
                                                    toolbar: 'bold italic underline',
                                                    content_style: "body {fontFamily: Inter, sans-serif; font-weight: 400; font-size: 14px; color: #323947 }",
                                                    entity_encoding: "raw",
                                                    formats: {
                                                        bold: { inline: 'b' },
                                                        italic: { inline: 'i' },
                                                        strikethrough: { inline: 's' }
                                                    },
                                                    paste_as_text: true,
                                                    extended_valid_elements: "b, i, s",
                                                    invalid_elements: "strong, em, span",
                                                    forced_root_block: false,
                                                    setup: (editor) => {
                                                        editor.on("keydown", (e) => {
                                                            if (e.key === "Enter" || e.key === "Tab") {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                return false;
                                                            }
                                                        });
                                                    }
                                                }}
                                                value={curCommunications?.whatsAppRequestQuotesMessage === null
                                                  ? getDefaultRequestQuotesMessage()
                                                  : curCommunications?.whatsAppRequestQuotesMessage}
                                                onFocusIn={() => setRequestQuotesFocused(true)}
                                                onFocusOut={() => setRequestQuotesFocused(false)}
                                                onInit={setRequestQuotesLength}
                                                onEditorChange={handleWhatsAppRequestQuotesMessageChanged}
                                                outputFormat="html"
                                                onChange={(e) => {
                                                    if (requestQuotesTextLength >= maxRequestQuotesTextLength) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                              />
                                              <span className={classes.charCounter}>{requestQuotesTextLength + "/" + maxRequestQuotesTextLength}</span>
                                          </div>
                                          <Button
                                            aria-label={t("Save")}
                                            className={classes.saveCustomMessageButton}
                                            onClick={handleSaveWhatsAppRequestQuotesMessageButtonPressed}
                                            disabled={!isRequestQuotesTextChanged || requestQuotesTextLength > maxRequestQuotesTextLength || requestQuotesTextLength === 0}
                                          >
                                              <SaveIcon
                                                style={{ width: 16, height: 16, marginRight: "12px" }}
                                                viewBox="0 0 16 16"
                                              />
                                              <Typography className={classes.saveCustomMessageText}>
                                                  {t("Save")}
                                              </Typography>
                                          </Button>

                                          <div className={classes.whatsAppRequestQuotesDelay}>
                                              <label>
                                                  {t("Notify me estimate pending in")}
                                              </label>
                                              <TimePicker
                                                key={`opening_whatsAppRequestQuotesDelay`}
                                                onOk={(time) => {handleWhatsAppRequestQuotesDelayChanged(moment.duration(time.format(timeFormat)).asMinutes())}}
                                                className={`${classes.selectStyle} ${
                                                  isWhatsAppRequestQuotesDelayOpen ? classes.selectHrs : classes.selectHrsClose
                                                }`}
                                                placeholder="Opened"
                                                inputReadOnly={true}
                                                showNow={false}
                                                value={moment().startOf("day").add(curCommunications?.whatsAppRequestQuotesDelay, "minutes")}
                                                onOpenChange={setIsWhatsAppRequestQuotesDelayOpen}
                                                format={timeFormat}
                                              />
                                              <ArrowDropDownIcon
                                                style={{
                                                    top: "inherit",
                                                    right: 25,
                                                    pointerEvents: "none",
                                                    color: isWhatsAppRequestQuotesDelayOpen ? "#5C6477" : "#CCC",
                                                    position: "relative",
                                                    transform: isWhatsAppRequestQuotesDelayOpen
                                                      ? "rotate(180deg)"
                                                      : "rotate(0deg)",
                                                }}
                                              />
                                          </div>
                                      </>
                                    }
                                </>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className={classes.section}>
                <div>
                    <Typography className={classes.title}>
                        {t('Conversations')}
                    </Typography>
                </div>
                <Box className={classes.divArea}>
                    <Grid container>
                        <Grid item xs={6}>
                            <div className={classes.root}>
                                <>
                                    <div className={`${classes.divRowWhats} ${classes.scheduleFollowUp}`}>
                                        <div>
                                            <div className={classes.divCheck}>
                                                <Switch
                                                  value={curCommunications?.scheduleWhatsAppFollowUpMessageEnabled ?? false}
                                                  onChange={handleScheduleWhatsAppFollowUpMessageEnabledChanged}
                                                  baseClass={classes.switchBase} />
                                            </div>
                                            <div className={classes.divLabel} style={{flexBasis:"fit-content", paddingRight: 10}}>
                                                <label>
                                                    {useCustomerTerminology(t('Schedule follow-up message when customer has not responded to a conversation'), 'customer')}
                                                </label>
                                            </div>
                                        </div>
                                        <div className={`${classes.divSelect} ${curCommunications?.scheduleWhatsAppFollowUpMessageEnabled ? "" : classes.disabledSelect}`}>
                                            <Select
                                              key={"whatsAppFollowUpSendTime"}
                                              width={230}
                                              placeholder={t("Send time")}
                                              value={curCommunications?.whatsAppFollowUpSendTime}
                                              onChange={handleWhatsAppFollowUpSendTimeChanged}
                                              colorArrowIcon={"#5C6477"}
                                              items={whatsAppFollowUpSendTimeSelectList}
                                              disabled={!curCommunications.scheduleWhatsAppFollowUpMessageEnabled} />
                                        </div>
                                    </div>
                                    {!curCommunications?.scheduleWhatsAppFollowUpMessageEnabled ?
                                      <></> :
                                      <>
                                          <div className={`${classes.tinyMceOverrides} ${getFollowUpBorderStyle()}`} style={{ marginTop: 10 }}>
                                              <Editor
                                                apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
                                                init={{
                                                    height: 160,
                                                    width: 690,
                                                    menubar: false,
                                                    statusbar: false,
                                                    plugins: 'wordcount paste',
                                                    toolbar: 'bold italic underline',
                                                    content_style: "body {fontFamily: Inter, sans-serif; font-weight: 400; font-size: 14px; color: #323947 }",
                                                    entity_encoding: "raw",
                                                    formats: {
                                                        bold: { inline: 'b' },
                                                        italic: { inline: 'i' },
                                                        strikethrough: { inline: 's' }
                                                    },
                                                    paste_as_text: true,
                                                    extended_valid_elements: "b, i, s",
                                                    invalid_elements: "strong, em, span",
                                                    forced_root_block: false,
                                                    setup: (editor) => {
                                                        editor.on("keydown", (e) => {
                                                            if (e.key === "Enter" || e.key === "Tab") {
                                                                e.preventDefault();
                                                                e.stopPropagation();
                                                                return false;
                                                            }
                                                        });
                                                    }
                                                }}
                                                value={curCommunications?.whatsAppFollowUpMessage === null
                                                  ? getDefaultFollowUpMessage()
                                                  : curCommunications?.whatsAppFollowUpMessage}
                                                onFocusIn={() => setFollowUpFocused(true)}
                                                onFocusOut={() => setFollowUpFocused(false)}
                                                onInit={setFollowUpLength}
                                                onEditorChange={handleWhatsAppFollowUpMessageChanged}
                                                outputFormat="html"
                                                onChange={(e) => {
                                                    if (followUpTextLength >= maxFollowUpTextLength) {
                                                        e.preventDefault();
                                                    }
                                                }}
                                              />
                                              <span className={classes.charCounter}>{followUpTextLength + "/" + maxFollowUpTextLength}</span>
                                          </div>
                                          <Button
                                            aria-label={t("Save")}
                                            className={classes.saveCustomMessageButton}
                                            onClick={handleSaveWhatsAppFollowUpMessageButtonPressed}
                                            disabled={!isFollowUpTextChanged || followUpTextLength > maxFollowUpTextLength || followUpTextLength === 0}
                                          >
                                              <SaveIcon
                                                style={{ width: 16, height: 16, marginRight: "12px" }}
                                                viewBox="0 0 16 16"
                                              />
                                              <Typography className={classes.saveCustomMessageText}>
                                                  {t("Save")}
                                              </Typography>
                                          </Button>
                                      </>
                                    }
                                </>
                                <div className={`${classes.divRowWhats} ${classes.resetAvailableStatus}`}>
                                    <div>
                                        <div className={classes.divCheck}>
                                            <Switch
                                              value={curWaCommunications?.availabilityStatusResetEnabled ?? true}
                                              onChange={handleAvailabilityStatusResetEnabledOnChange}
                                              disabled={true}
                                              baseClass={classes.switchBase} />
                                        </div>
                                        <div className={classes.divLabel}>
                                            <label>
                                                {t("Reset \"Available\" status to \"Away\" to reply in WhatsApp")}
                                            </label>
                                        </div>
                                    </div>
                                    <div className={classes.resetTimeContainer}>
                                        <label className={classes.label}>
                                            {t("Reset time")}
                                        </label>
                                        <TimePicker
                                          onOk={(time) => {
                                              handleAvailabilityStatusResetTimeOnChange(time.format(timeFormat));
                                          }}
                                          className={`${classes.selectStyle} ${curWaCommunications.availabilityStatusResetEnabled
                                            ? classes.selectHrs
                                            : classes.availabilityStatusSelectHrsClose
                                          }`}
                                          inputReadOnly={true}
                                          showNow={false}
                                          value={moment(curWaCommunications.availabilityStatusResetTime, timeFormat)}
                                          disabled={!curWaCommunications.availabilityStatusResetEnabled}
                                          onOpenChange={(e) =>
                                            setAvailabilityStatusResetTimeIsOpen(e)
                                          }
                                          format={timeFormat}
                                        />
                                        <ArrowDropDownIcon
                                          style={{
                                              top: "inherit",
                                              right: 25,
                                              pointerEvents: "none",
                                              color: curWaCommunications.availabilityStatusResetEnabled ? "#5C6477" : "#CCC",
                                              position: "relative",
                                              transform: availabilityStatusResetTimeIsOpen
                                                ? "rotate(180deg)"
                                                : "rotate(0deg)",
                                          }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
            <div className={classes.section}>
                <div>
                    <Typography className={classes.title}>
                        {useCustomerTerminology(t('Customer notifications by WhatsApp'), 'customer')}
                    </Typography>
                </div>
                <Box className={classes.divArea}>
                    <Grid container>
                        <Grid item xs={6}>
                            <div className={classes.root}>
                                <label className={classes.label}>
                                    {t("Notification to:")}
                                </label>
                                <div className={`${classes.divRowWhats} ${classes.divRowWhatsHeight60}`}>
                                    <div className={classes.divCheck}>
                                        <Switch
                                          value={curCommunications?.notifyOnAppointmentCreated}
                                          onChange={handleNotifyOnAppointmentCreatedChanged}
                                          baseClass={classes.switchBase} />
                                    </div>
                                    <div className={classes.divLabel}>
                                        <label >
                                            {t("Create appointment")}
                                        </label>
                                    </div>
                                </div>
                                <div className={`${classes.divRowWhats} ${classes.divRowWhatsHeight60}`}>
                                    <div className={classes.divCheck}>
                                        <Switch
                                          value={curCommunications?.notifyOnAppointmentModified}
                                          onChange={handleNotifyOnAppointmentModifiedChanged}
                                          baseClass={classes.switchBase} />
                                    </div>
                                    <div className={classes.divLabel}>
                                        <label >
                                            {t("Modify appointment")}
                                        </label>
                                    </div>
                                </div>
                                <div className={`${classes.divRowWhats} ${classes.divRowWhatsHeight60}`}>
                                    <div className={classes.divCheck}>
                                        <Switch
                                          value={curCommunications?.notifyOnAppointmentCanceled}
                                          onChange={handleNotifyOnAppointmentCanceledChanged}
                                          baseClass={classes.switchBase} />
                                    </div>
                                    <div className={classes.divLabel}>
                                        <label >
                                            {t("Cancel appointment")}
                                        </label>
                                    </div>
                                </div>
                                <div className={`${classes.divRowWhats} ${classes.divRowWhatsHeight60}`}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <div className={classes.divCheck}>
                                                <Switch
                                                  value={curCommunications?.firstReminderEnabled}
                                                  onChange={handleFirstReminderEnabledChanged}
                                                  baseClass={classes.switchBase} />
                                            </div>
                                            <div className={classes.divLabel}>
                                                <label >
                                                    {t("First appointment reminder")}
                                                </label>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} className={`${classes.divSelect} ${curCommunications?.firstReminderEnabled ? "" : classes.disabledSelect}`}>
                                            <Select
                                              key={"firstReminder"}
                                              width={230}
                                              placeholder={t("Hours before the appointment")}
                                              value={curCommunications?.firstRemindBefore}
                                              onChange={handleFirstRemindBeforeChanged}
                                              colorArrowIcon={"#5C6477"}
                                              items={firstReminderSelectList}
                                              disabled={!curCommunications.firstReminderEnabled} />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div className={`${classes.divRowWhats} ${classes.divRowWhatsHeight60}`}>
                                    <Grid container>
                                        <Grid item xs={6}>
                                            <div className={classes.divCheck}>
                                                <Switch
                                                  value={curCommunications?.secondReminderEnabled}
                                                  onChange={handleSecondReminderEnabledChanged}
                                                  baseClass={classes.switchBase} />
                                            </div>
                                            <div className={classes.divLabel}>
                                                <label >
                                                    {t("Second appointment reminder")}
                                                </label>
                                            </div>
                                        </Grid>
                                        <Grid item xs={6} className={`${classes.divSelect} ${curCommunications?.secondReminderEnabled ? "" : classes.disabledSelect}`}>
                                            <Select
                                              key={"secondReminder"}
                                              width={230}
                                              placeholder={t("Hours before the appointment")}
                                              value={curCommunications?.secondRemindBefore}
                                              onChange={handleSecondRemindBeforeChanged}
                                              colorArrowIcon={"#5C6477"}
                                              items={secondReminderSelectList}
                                              disabled={!curCommunications.secondReminderEnabled} />
                                        </Grid>
                                    </Grid>
                                </div>

                                <div className={`${classes.divRowWhats} ${classes.divRowWhatsHeight60}`}>
                                    <div className={classes.divCheck}>
                                        <Switch
                                          value={curCommunications?.showServicePricesInWhatsApp}
                                          onChange={handleShowServicePricesInWhatsAppChanged}
                                          baseClass={classes.switchBase}
                                        />
                                    </div>
                                    <div className={classes.divLabel}>
                                        <label >
                                            {t("Show prices of my services in WhatsApp messages")}
                                        </label>
                                    </div>
                                </div>


                                <label className={classes.label}>
                                    {t("Send additional messages with cancellation policies:")}
                                </label>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            <div className={classes.showCancellationPoliciesDisabledBlock}>
                                <Trans i18nKey="ShowCancellationPoliciesMustBeTurnedOn" t={t} />
                            </div>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid container xs={6}>
                            <div className={classes.root}>
                                <div className={`${classes.divRowWhats} ${classes.divRowWhatsHeight60}`}>
                                    <div className={classes.divCheck}>
                                        <Switch
                                          value={curCommunications.hasWhatsAppCancellationPoliciesOnAppointmentConfirmation}
                                          onChange={handleWhatsAppCancellationPoliciesOnAppointmentConfirmation}
                                          baseClass={classes.switchBase}
                                          disabled={!curCommunications.showCancellationPolicies}
                                        />
                                    </div>
                                    <div className={classes.divLabel}>
                                        <label >
                                            {t("Appointment confirmation")}
                                        </label>
                                    </div>
                                    <IconTooltip
                                      icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 1 }} viewBox={"0 0 12 12"} />}
                                      tooltipText={t("Send cancellation policies when creating the appointment")}
                                      placement={"right"}
                                      arrowPlacement={"left"}
                                      classNameRoot={classes.iconTooltipRoot}
                                    />
                                </div>
                                <div className={`${classes.divRowWhats} ${classes.divRowWhatsHeight60}`}>
                                    <div className={classes.divCheck}>
                                        <Switch
                                          value={curCommunications.hasWhatsAppCancellationPoliciesOnAppointmentReminder}
                                          onChange={handleWhatsAppCancellationPoliciesOnAppointmentReminder}
                                          baseClass={classes.switchBase}
                                          disabled={!curCommunications.showCancellationPolicies}
                                        />
                                    </div>
                                    <div className={classes.divLabel}>
                                        <label >
                                            {t("Appointment reminder")}
                                        </label>
                                        <IconTooltip
                                          icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 1 }} viewBox={"0 0 12 12"} />}
                                          tooltipText={t("Send cancellation policies in the first appointment reminder")}
                                          placement={"right"}
                                          arrowPlacement={"left"}
                                          classNameRoot={classes.iconTooltipRoot}
                                        />
                                    </div>
                                </div>
                                <div className={`${classes.divRowWhats} ${classes.divRowWhatsHeight60}`}>
                                    <div className={classes.divCheck}>
                                        <Switch
                                          value={curCommunications.hasWhatsAppCancellationPoliciesOnAppointmentCancel}
                                          onChange={handleWhatsAppCancellationPoliciesOnAppointmentCancel}
                                          baseClass={classes.switchBase}
                                          disabled={!curCommunications.showCancellationPolicies}
                                        />
                                    </div>
                                    <div className={classes.divLabel}>
                                        <label >
                                            {t("Appointment cancellation")}
                                        </label>
                                        <IconTooltip
                                          icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 1 }} viewBox={"0 0 12 12"} />}
                                          tooltipText={t("Send the cancellation policies when canceling the appointment")}
                                          placement={"right"}
                                          arrowPlacement={"left"}
                                          classNameRoot={classes.iconTooltipRoot}
                                        />
                                    </div>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                </Box>
            </div>
        </>
    )
}