import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../redux/hooks";
import { selectAccountSettings } from "../../redux/store";


export default function useCustomerTerminology(value: string, ...terms: string[]): string {
    
    function applyInterpolation(arrayTerms: string[]) {
        let result = arrayTerms[0];
        for (let i = 0; i < arrayTerms.length - 1; i++) {
            let reg = new RegExp("\\{\\{" + i + "\\}\\}", "gm");
            result = result.replace(reg, arrayTerms[i + 1]);
        }
        return result;
    }

    const { t } = useTranslation(['clinic_terminology_customer', 'clinic_terminology_patient']);

    const accountSettings = useAppSelector(selectAccountSettings);

    const clinicTerminology = accountSettings.isClinicMode ? "clinic_terminology_patient" : "clinic_terminology_customer";
    let countInterpolationsInTargetString = ((value || '').match(/\{[0-9]+}/g) || []).length;

    if (countInterpolationsInTargetString > terms.length) {
        throw new Error("The number of terms does not match the number of interpolations in the target string.");
    }

    let arrayTerms = [];
    arrayTerms.push(value);

    terms.forEach((item, index, array) => {
        let terminology = t(item, { ns: clinicTerminology });
        arrayTerms.push(terminology);
    });

    return applyInterpolation(arrayTerms);
}