import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {},
  tinyMceOverrides: {
    "& svg": {
      fill: "#919CA5 !important"
    },
    "& button.tox-tbtn--select": {
      visibility: "hidden"
    },
    position: "relative",
    padding: "15px 0px 15px 0px",

    "& div.tox-tinymce": {
      ...theme.typography.body2,
      color: theme.colors.grey5,
      backgroundColor: theme.colors.grey1,
    },
  },
  tinyMceUnfocused: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: "#C9CDD3 !important"
    }
  },
  tinyMceFocused: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: "#6462F3 !important"
    }
  },
  tinyMceInvalid: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: Color.red +" !important"
    }
  },
  charCounter: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "12px",
    position: "absolute",
    right: 20,
    paddingLeft: "629px",
    marginTop: "-18px",
    color: theme.colors.grey5
  },
  charCounterInvalid: {
    color: theme.colors.clearRed
  },
  urlWarning: {
    position: "absolute",
    paddingLeft: "656px",
    marginTop: "-198px",
  },
}));

export default useStyles;
