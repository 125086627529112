import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionFieldCheckboxIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M17.5 18.625C18.3125 18.625 19 17.9688 19 17.125V6.125C19 5.3125 18.3125 4.625 17.5 4.625H6.5C5.65625 4.625 5 5.3125 5 6.125V17.125C5 17.9688 5.65625 18.625 6.5 18.625H17.5ZM11.0938 15.5625C10.9063 15.7813 10.5625 15.7813 10.375 15.5625L7.125 12.3125C6.9375 12.125 6.9375 11.8125 7.125 11.625L7.84375 10.9063C8.03125 10.7188 8.34375 10.7188 8.53125 10.9063L10.75 13.0938L15.4375 8.40625C15.625 8.21875 15.9375 8.21875 16.125 8.40625L16.8438 9.125C17.0313 9.3125 17.0313 9.625 16.8438 9.8125L11.0938 15.5625Z"
                fill={style?.color || Color.gray5}
            />
        </SvgIcon>
    );
}