import React from "react";
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { HeaderCellComponent } from '../AppointmentsScheduler/Header';
import moment from 'moment';
import { memo, useMemo } from 'react';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
    dayName: {
        ...theme.typography.header5,
        fontWeight: 'normal',
        color: "#6A6E72"
    },
    num: {
        ...theme.typography.header5,
        color: "#6A6E72"
    },
    root: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%',
        width: '100%',
        gap: 2,
        color: theme.colors.grey5,
        justifySelf: 'stretch',
    },
    active: {
        color: theme.colors.clear2,
        backgroundColor: '#6462F31A',
        "& span": {
            color: theme.colors.clearMain,
        }
    },
}));

const AppointmentsHeaderCellComponent: HeaderCellComponent<string> = memo(({ value }) => {
    const date = moment(value);
    const { t } = useTranslation(['general']);
    const dayKey = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'][
        date.isoWeekday() - 1
    ];
    const dayName = t(`${dayKey}`);
    const styles = useStyles();
    const isActive = useMemo(() => moment().format('YYYY-MM-DD') === value, [value]);

    return (
        <div className={clsx(styles.root, isActive && styles.active)}>
            <span className={styles.dayName}>{dayName}</span>
            <span className={styles.num}>{date.date()}</span>
        </div>
    );
});

export default AppointmentsHeaderCellComponent;
