import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { JobPositionEditModalProps } from "./props";
import useStyles from "./css";
import InputWithLabel from "../../../../common/InputWithLabel";
import { JobPosition } from "../../../../../models/job-position";


export default function JobPositionEditModal(props: JobPositionEditModalProps) {
  
  const { open, setOpen, item, setItem, onClose, onEdit } = props;
  
  const setItemName = (value: string) => {
    setItem({...item, name: value})
  }
  
  const handleInputChange = (event: any) => {
    setItemName(event.target.value);
  }

  const handleCloseModal = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
    setItem(new JobPosition());
  };
  
  const handleSubmit = () => {
    if (onEdit) {
      onEdit(item);
    }
    handleCloseModal();
  }
  
  const classes = useStyles();
  const { t } = useTranslation("settings");
  
  const GoBackButton = () =>
    <Button
      className={`${classes.button} ${classes.goBack}`}
      onClick={handleCloseModal}
    >
      {t("Go back")}
    </Button>
  
  const SaveChangesButton = () =>
    <Button
      className={`${classes.button} ${classes.continueButton}`}
      disabled={!item?.name || item.name.length === 0}
      onClick={handleSubmit}
    >
      {t("Save Changes")}
    </Button>
  
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.content}>
            <div className={classes.mainContent}>
              <Typography variant="h4" className={classes.modalHeader}>{t("Edit job position")}</Typography>
              <InputWithLabel 
                value={item.name}
                onChange={handleInputChange}
                label={t("Name of the job position")}
                width={416}
              />
            </div>
            <div className={classes.modalFooter}>
              <GoBackButton />
              <SaveChangesButton />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}