import { Backdrop, Button, Fade, Modal, Typography } from "@material-ui/core";
import { TreatmentDocumentConfigurationModalProps } from "./props";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./css";
import { CameraIcon, InfoIcon, PlusIcon, TrashIcon } from "../../../../../../assets/icons";
import IconTooltip from "../../../../../common/IconTooltip";
import SwitchWithLabel from "../../../../../common/SwitchWithLabel";
import { AccountService } from "../../../../../../api/account-service";
import { TreatmentDocumentFieldSettingsModel } from "../../../../../../models/treatment-document-field-settings-model";
import { TreatmentDocumentSize } from "../../../../../../models/enums/treatment-document-size";
import { SelectItem } from "../../../../../common/Select";
import { Color } from "../../../../../../constants/colors";
import { TerminologyType } from "../../../../../../models/enums/terminology-type";
import useTerminology from "../../../../../../hooks/useTerminology";
import { TerminologyForm } from "../../../../../../models/enums/terminology-form";
import SelectWithLabel from "../../../../../common/SelectWithLabel";
import DropImage from "../../../../../common/DropImage";
import DropImageRefs from "../../../../../common/DropImage/refs";
import Input from "../../../../../common/Input";
import { StaticTreatmentDocumentFieldType } from "../../../../../../models/enums/static-treatment-document-field-type";
import { TreatmentDocumentFieldSetting } from "../../../../../../models/treatment-document-field-setting";
import stringify from "safe-stable-stringify";
import Cloner from "../../../../../../utils/cloner";
import ConfirmDeleteModal from "../../../../../common/ConfirmDeleteModal";
import ConfirmActionModal from "../../../../../common/ConfirmActionModal";
import TreatmentDocumentPreview from "./TreatmentDocumentPreview";


const TreatmentDocumentConfigurationModal = (props: TreatmentDocumentConfigurationModalProps) => {

    const { isOpen, closeModal } = props;

    const sectionTNamespace = "sections";
    const { t } = useTranslation(["settings", sectionTNamespace]);
    const treatmentTerm = useTerminology({ type: TerminologyType.Treatment, form: TerminologyForm.Singular });
    const classes = useStyles();

    const documentSizeSelectItems: SelectItem[] = [
        // https://clearmechanic.atlassian.net/browse/CR-2437?focusedCommentId=151047
        // { key: TreatmentDocumentSize.HalfLetter, value: t("Half-letter (5.5 x 8.5 inches)") },
        { key: TreatmentDocumentSize.Letter, value: t("Letter (8.5 x 11 inches)") },
        { key: TreatmentDocumentSize.Legal, value: t("Legal (8.5 x 14 inches)") },
    ];
    const validSignImageExtensions = ["jpg", "jpeg", "png"];
    const maxSignImageSize: number = 800 * 1024; //800 kb

    const dropImageRef = useRef<DropImageRefs>(null);

    const [settings, setSettings] = useState<TreatmentDocumentFieldSettingsModel>(new TreatmentDocumentFieldSettingsModel());
    const [settingsInitialStateToCompare, setSettingsInitialStateToCompare] = useState<TreatmentDocumentFieldSettingsModel>(new TreatmentDocumentFieldSettingsModel());

    const [closeModalConfirmationModalIsOpen, setCloseModalConfirmationModalIsOpen] = useState<boolean>(false);
    const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] = useState<boolean>(false);

    useEffect(() => {
        if (!isOpen)
            return;

        async function innerLoadData() {
            await loadData();
        }

        innerLoadData();
    }, [isOpen]);


    async function loadData(): Promise<void> {
        const response = await AccountService.getTreatmentDocumentFieldSettings();
        setSettings(response);
        setSettingsInitialStateToCompare(Cloner.deepCopy<TreatmentDocumentFieldSettingsModel>(response));
    }

    async function handleSave(): Promise<void> {
        await AccountService.updateTreatmentDocumentFieldSettings(settings);
        closeAndResetModal();
    }

    function tryCloseModal(): void {
        if (stringify(settings) === stringify(settingsInitialStateToCompare)) {
            closeAndResetModal();
        }
        else {
            setCloseModalConfirmationModalIsOpen(true);
        }
    }

    function closeAndResetModal(): void {
        setCloseModalConfirmationModalIsOpen(false);
        closeModal();
    }

    function handleChangeDocumentSize(event: React.ChangeEvent<{ value: TreatmentDocumentSize }>) {
        setSettings({ ...settings, documentSize: event.target.value });
    }

    function handleLogoSwitchChanged(isEnabled: boolean) {
        setSettings({ ...settings, logoIsEnabled: isEnabled });
    }

    function handleLogoUpload(files: FileList) {
        const file = files.item(0);
        if (!file)
            return;
        const fileIsInvalid = validateLogoImage(file);
        if (!fileIsInvalid)
            return;

        setSettings({ ...settings, logoUrl: URL.createObjectURL(file), logoFile: file });
    }

    function openFileDialog() {
        dropImageRef?.current?.openFileExplorerDialog();
    }

    function deleteLogoFile() {
        setSettings((prev) => ({ ...prev, logoUrl: null, logoFile: null, shouldRemoveLogo: true }));
        setDeleteConfirmationModalIsOpen(false);
    }

    function validateLogoImage(file: File) {
        if (validSignImageExtensions.indexOf(file.name.split('.').pop()!) === -1) {
            return false;
        }
        return file.size < maxSignImageSize;
    }

    function getStaticFieldIsEnabled(fieldType: StaticTreatmentDocumentFieldType): boolean {
        return settings.fieldSettings.find(fs => fs.staticTreatmentDocumentFieldType === fieldType)?.isEnabled ?? true;
    }

    function setStaticFieldIsEnabled(isEnabled: boolean, fieldType: StaticTreatmentDocumentFieldType): void {
        let newSettings = { ...settings };
        const index = settings.fieldSettings.findIndex(fs => fs.staticTreatmentDocumentFieldType === fieldType);
        let fieldSetting = { ...settings.fieldSettings[index] } as TreatmentDocumentFieldSetting;
        fieldSetting.isEnabled = isEnabled;
        newSettings.fieldSettings[index] = fieldSetting;
        setSettings(newSettings);
    }

    function getDynamicFieldIsEnabled(fieldSettingId: string): boolean {
        return settings.fieldSettings.find(fs => fs.id === fieldSettingId)?.isEnabled ?? true;
    }

    function setDynamicFieldIsEnabled(isEnabled: boolean, fieldSettingId: string): void {
        let newSettings = { ...settings };
        const index = settings.fieldSettings.findIndex(fs => fs.id === fieldSettingId);
        let fieldSetting = { ...settings.fieldSettings[index] } as TreatmentDocumentFieldSetting;
        fieldSetting.isEnabled = isEnabled;
        newSettings.fieldSettings[index] = fieldSetting;
        setSettings(newSettings);
    }

    function mapDynamicFieldSettingsToComponents(): JSX.Element[] {
        return settings.fieldSettings
            .filter(fs => fs.field !== null)
            .sort((fs1, fs2) => fs1.field!.order - fs2.field!.order)
            .map(fs => (<div className={classes.fieldSettingsRow}>
                <SwitchWithLabel
                    className={classes.fieldSettingsGridItem}
                    value={getDynamicFieldIsEnabled(fs.id)}
                    setValue={(v) => setDynamicFieldIsEnabled(v, fs.id)}
                    label={t(fs.field!.name, { ns: sectionTNamespace })}
                />
            </div>))
    }



    return <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={isOpen}
        onClose={tryCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }} >
        <Fade in={isOpen}>
            <div className={classes.paper}>
                <div className={classes.headerContainer}>
                    <div className={classes.titleContainer}>
                        <Typography className={classes.modalNameLabel}>
                            {`${t("Customize")} ${treatmentTerm.toLowerCase()}`}
                        </Typography>
                        <Typography className={classes.requiredFieldLabel}>
                            {t("Required fields")} <span className={classes.asterix}> *</span>
                        </Typography>
                    </div>
                    <div className={classes.modalManagementButtonsContainer}>
                        <Button
                            className={`${classes.button} ${classes.goBack}`}
                            onClick={tryCloseModal}
                        >
                            {t("Go back")}
                        </Button>
                        <Button
                            className={`${classes.button} ${classes.continueButton}`}
                            disabled={stringify(settings) === stringify(settingsInitialStateToCompare)}
                            classes={{ disabled: classes.saveDisabled }}
                            onClick={handleSave}
                        >
                            {t("Save")}
                        </Button>
                    </div>
                </div>
                <div className={classes.mainContentContainer}>
                    <div className={classes.settingsContainer}>
                        <div className={classes.printDetailsLabelContainer}>
                            <Typography className={classes.violetHeaderLabel}>
                                {t("Print details")}
                            </Typography>
                        </div>
                        <div className={classes.scrollableSettingContainer}>
                            <SelectWithLabel
                                key={"documentSizeSelect"}
                                rootStyle={{ color: Color.clear1 + " !important", paddingBottom: 22 }}
                                width={183}
                                value={settings.documentSize}
                                onChange={handleChangeDocumentSize}
                                colorArrowIcon={Color.gray5}
                                items={documentSizeSelectItems}
                                label={t("Size")} />
                            <SwitchWithLabel
                                value={settings.logoIsEnabled}
                                setValue={handleLogoSwitchChanged}
                                label={t("Logo")}
                            />
                            <div className={`${classes.logoDisableableSection} ${settings.logoIsEnabled ? "" : classes.disabled}`}>
                                <div className={classes.logoConfigurationSection}>
                                    <div className={classes.logoConfigurationLeftSection}>
                                        <Typography className={classes.columnNameLabel}>
                                            {t("Logo") + " *"}
                                        </Typography>
                                        <DropImage
                                            ref={dropImageRef}
                                            classDivUploadImage={settings.logoUrl ? classes.logo : classes.logoEmpty}
                                            onChange={handleLogoUpload}
                                            imageURL={settings.logoUrl ?? undefined}
                                            disableBlob={true}
                                            componentShowEmpty={<div className={classes.emptyLogoStubContainer}>
                                                <div>
                                                    <PlusIcon style={{ color: Color.clear1 }} />
                                                </div>
                                                <div className={classes.addPhotoLabel}>
                                                    {t("Add photo")}
                                                </div>
                                                <div className={classes.addPhotoMinSizeLabel}>
                                                    {t("Min. size") + ": 660px x 450px"}
                                                </div>
                                            </div>} />
                                        {settings.logoUrl
                                            ? <div className={classes.logoControls}>
                                                <CameraIcon
                                                    className={classes.iconCamera}
                                                    onClick={openFileDialog}
                                                />
                                                <TrashIcon
                                                    style={{ color: "#919CA5" }}
                                                    className={classes.iconTrash}
                                                    onClick={() => setDeleteConfirmationModalIsOpen(true)}
                                                />
                                            </div>
                                            : <div className={classes.logoControlsEmptyStub} />
                                        }
                                    </div>
                                </div>
                                <div className={classes.logoNotesContainer}>
                                    <Typography className={classes.notesBold}>
                                        {t("Notes") + ":"}
                                    </Typography>
                                    <Typography className={classes.notes}>
                                        {`${t("The logo will be displayed as part of the")} ${treatmentTerm.toLowerCase()}.`}
                                    </Typography>
                                    <Typography className={classes.notes}>
                                        {t("Allowed formats for the image:")}
                                        <span className={classes.notesBold}>{" " + t(".png or .jpg")}</span>
                                    </Typography>
                                </div>
                            </div>
                            <div className={classes.fieldSettingsContainer}>
                                <div className={classes.fieldSettingsRow}>
                                    <Typography className={`${classes.columnNameLabel} ${classes.fieldSettingsGridItem}`}>
                                        {t("Professional information") + " *"}
                                    </Typography>
                                </div>
                                <div className={classes.fieldSettingsRow}>
                                    <SwitchWithLabel
                                        className={classes.fieldSettingsGridItem}
                                        value={getStaticFieldIsEnabled(StaticTreatmentDocumentFieldType.SpecialistFullName)}
                                        setValue={(v) => setStaticFieldIsEnabled(v, StaticTreatmentDocumentFieldType.SpecialistFullName)}
                                        label={t("Specialist")}
                                    />
                                </div>
                                <div className={classes.fieldSettingsRow}>
                                    <SwitchWithLabel
                                        className={classes.fieldSettingsGridItem}
                                        value={getStaticFieldIsEnabled(StaticTreatmentDocumentFieldType.SpecialistPhoneNumber)}
                                        setValue={(v) => setStaticFieldIsEnabled(v, StaticTreatmentDocumentFieldType.SpecialistPhoneNumber)}
                                        label={t("Mobile")}
                                    />
                                </div>
                                {mapDynamicFieldSettingsToComponents()}
                                <div className={classes.fieldSettingsRow}>
                                    <SwitchWithLabel
                                        className={classes.fieldSettingsGridItem}
                                        value={getStaticFieldIsEnabled(StaticTreatmentDocumentFieldType.AppointmentDate)}
                                        setValue={(v) => setStaticFieldIsEnabled(v, StaticTreatmentDocumentFieldType.AppointmentDate)}
                                        label={t("Date")}
                                    />
                                </div>
                                <div className={classes.fieldSettingsRow}>
                                    <SwitchWithLabel
                                        className={classes.fieldSettingsGridItem}
                                        value={getStaticFieldIsEnabled(StaticTreatmentDocumentFieldType.PatientFullName)}
                                        setValue={(v) => setStaticFieldIsEnabled(v, StaticTreatmentDocumentFieldType.PatientFullName)}
                                        label={t("Patient")}
                                    />
                                </div>
                                <div className={classes.fieldSettingsRow}>
                                    <SwitchWithLabel
                                        className={classes.fieldSettingsGridItem}
                                        value={getStaticFieldIsEnabled(StaticTreatmentDocumentFieldType.PatientAge)}
                                        setValue={(v) => setStaticFieldIsEnabled(v, StaticTreatmentDocumentFieldType.PatientAge)}
                                        label={t("Age")}
                                    />
                                </div>
                                <div className={classes.fieldSettingsRow}>
                                    <SwitchWithLabel
                                        className={classes.fieldSettingsGridItem}
                                        value={getStaticFieldIsEnabled(StaticTreatmentDocumentFieldType.PatientGender)}
                                        setValue={(v) => setStaticFieldIsEnabled(v, StaticTreatmentDocumentFieldType.PatientGender)}
                                        label={t("Gender")}
                                    />
                                </div>
                                <div className={classes.fieldSettingsRow}>
                                    <SwitchWithLabel
                                        className={classes.fieldSettingsGridItem}
                                        value={getStaticFieldIsEnabled(StaticTreatmentDocumentFieldType.VitalSigns)}
                                        setValue={(v) => setStaticFieldIsEnabled(v, StaticTreatmentDocumentFieldType.VitalSigns)}
                                        label={t("Vital signs")}
                                    />
                                </div>
                                <div className={classes.fieldSettingsRow}>
                                    <SwitchWithLabel
                                        className={classes.fieldSettingsGridItem}
                                        value={getStaticFieldIsEnabled(StaticTreatmentDocumentFieldType.Diagnosis)}
                                        setValue={(v) => setStaticFieldIsEnabled(v, StaticTreatmentDocumentFieldType.Diagnosis)}
                                        label={t("Diagnosis")}
                                    />
                                </div>
                                <div className={classes.fieldSettingsRow}>
                                    <SwitchWithLabel
                                        className={classes.fieldSettingsGridItem}
                                        value={getStaticFieldIsEnabled(StaticTreatmentDocumentFieldType.Treatment)}
                                        setValue={(v) => setStaticFieldIsEnabled(v, StaticTreatmentDocumentFieldType.Treatment)}
                                        label={treatmentTerm}
                                    />
                                </div>
                                <div className={classes.fieldSettingsRow}>
                                    <SwitchWithLabel
                                        className={classes.fieldSettingsGridItem}
                                        value={getStaticFieldIsEnabled(StaticTreatmentDocumentFieldType.SpecialistSign)}
                                        setValue={(v) => setStaticFieldIsEnabled(v, StaticTreatmentDocumentFieldType.SpecialistSign)}
                                        label={t("Sign")}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={classes.previewContainer}>
                        <Typography className={`${classes.violetHeaderLabel} ${classes.previewSectionLabel}`}>
                            {t("Preview")}
                        </Typography>
                        <TreatmentDocumentPreview settings={settings} />
                    </div>
                </div>
                <ConfirmActionModal
                    classModal={classes.closeConfirmationModal}
                    content={<Typography className={classes.closeModalTitleText}>
                        {`${t("Cancel_treatment_customization_modal")} ${treatmentTerm.toLowerCase()}${t(" customization?")}`}
                    </Typography>}
                    open={closeModalConfirmationModalIsOpen}
                    onClose={() => setCloseModalConfirmationModalIsOpen(false)}
                    onConfirm={closeAndResetModal}
                    confirmButtonText={t("Yes, cancel")}
                />
                <ConfirmDeleteModal
                    classModal={classes.deleteConfirmationModal}
                    open={deleteConfirmationModalIsOpen}
                    item={null}
                    componentInfo={<div className={classes.deleteModalContentContainer}>
                        <Typography className={classes.deleteModalTitleText}>
                            {t("Delete this image?")}
                        </Typography>
                    </div>}
                    onClose={() => setDeleteConfirmationModalIsOpen(false)}
                    onDelete={deleteLogoFile}
                    textButtonConfirm={t("Delete")}
                />
            </div>
        </Fade>
    </Modal>
};

export default TreatmentDocumentConfigurationModal;