import React, { useState } from "react";
import { useTranslation } from 'react-i18next';
import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import { DoorIcon } from "../../../../../assets/icons";
import EntityGrid from "./../WorkAreaGrid";
import EntityModal from './../WorkAreaModal';
import EntityDeleteModal from './../WorkAreaDelete/work-area-delete';
import Workarea from "../../../../../models/workarea";
import EntityService from "../../../../../api/settings-workareas-service";
import useStyles from "./css";
import { useAppDispatch } from "../../../../../redux/hooks";
import { alertsActions } from "../../../../../redux/alerts-slice";
import { SettingsEnterpriseProps } from "../../../../../models/interfaces/settings-enterprise-props";
import SectionNote from "../../../../establishment-settings/Sections/FormsSettingsTabs/SectionNote";
import { SectionNoteData } from "../../../../../models/enums/section-note-data";


export default (props: SettingsEnterpriseProps) => {

    const { settingsSelectedEstablishmentId } = props;

    const { t } = useTranslation(["general"]);

    const classes = useStyles();
    const [isRefresh, setIsRefresh] = useState<any>();

    const [openItemEditModal, setOpenItemEditModal] = useState<boolean>(false);
    const [openItemDeleteModal, setOpenItemDeleteModal] = useState<boolean>(false);

    const [total, setTotal] = useState<number>(0);

    const [openAlert, setOpenAlert] = useState<boolean>(false);
    const [itemModal, setItemModal] = useState<Workarea | null>(null);

    const [newItem, setNewItem] = useState<Workarea | null>(null);
    
    const dispatch = useAppDispatch();
    const { enqueueAlert } = alertsActions;

    const handleCreate = (entity: any) => {

        setItemModal(null);
        setOpenItemEditModal(true);

        setIsRefresh(entity);
    };

    const handleRowClick = (item: Workarea) => {
        setOpenItemEditModal(true);
        setItemModal(item)
    }

    const handleDelete = (item: any) => {
        setItemModal(item);
        setOpenItemDeleteModal(true);
    }

    const handleCreated = (item: Workarea, edited: boolean) => {

        setOpenItemEditModal(false);

        if (edited) {
            setIsRefresh(item);
            dispatch(
              enqueueAlert({
                  type: "Success",
                  title: t("Work area updated successfully"),
                  description: item.name
              })
            );
        }
        else {
            setNewItem(item);
            dispatch(
              enqueueAlert({
                  type: "Success",
                  title: t("New work area created successfully"),
                  description: item.name
              })
            );
            setTotal(total+1);
        }
    }

    const handleDeleted = async (item: any) => {

        const deletedEntity = await EntityService.deleteWorkarea(item);
        if (deletedEntity.forbiddenDelete) {
            setOpenItemDeleteModal(false);

            let msg: string = "";
            if (deletedEntity.forbiddenDelete == "appointments")
                msg = `${item.name} ${t("cannot be deleted because it has one or more future appointments associate")}`;
            else // services
                msg = `${item.name} ${t("cannot be deleted because it has one or more services associate")}`;

            dispatch(
              enqueueAlert({
                  type: "Error",
                  title: t("Work area cannot be deleted"),
                  description: msg
              })
            );
        }
        else if (deletedEntity.entity) {
            setOpenItemDeleteModal(false);
            setIsRefresh(item);
            dispatch(
              enqueueAlert({
                  type: "Success",
                  title: t("Work area has been deleted"),
                  description: `${item.name} ${t("has been deleted")}`
              })
            );
        }
    }

    const handleTotal = (total: number) => {
        setTotal(total);
    }


    return (<>
        <div>
            {settingsSelectedEstablishmentId && (
                <>
                    <div className={classes.noteHeader}>
                        <Typography className={classes.tabTitle}>
                            {t("Work areas")}
                        </Typography>
                    </div>
                    <SectionNote
                        sectionData={SectionNoteData.WorkAreas}
                    />
                </>
            )}
            <div className={classes.header}>

                <Grid container spacing={2}>

                    <Grid item xs={6}>
                        <div>
                            <Typography className={classes.titles} variant="subtitle1">
                                {t('Work areas created')}
                            </Typography>
                            <Typography className={classes.icons} variant="h4">
                                <DoorIcon className={classes.icon} />
                                {total}
                            </Typography>
                        </div>
                    </Grid>

                    <Grid item xs={6}>
                        <div className={classes.headerRight}>
                            <Button className={`${classes.button} ${classes.btnNewWorkArea}`} onClick={handleCreate}  >
                                {t('Create work area')}
                            </Button>
                        </div>
                    </Grid>

                </Grid>

            </div>

            <EntityGrid
                settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
                isRefresh={isRefresh}
                item={newItem}
                openItemEditModal={handleRowClick}
                openDeleteModal={handleDelete}
                setTotalRecords={handleTotal}
            />

        </div>

        <EntityModal
            settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
            open={openItemEditModal}
            item={itemModal}
            onClose={() => setOpenItemEditModal(false)}
            onCreate={handleCreated}
        />

        <EntityDeleteModal
            open={openItemDeleteModal}
            item={itemModal}
            onClose={() => setOpenItemDeleteModal(false)}
            onDelete={handleDeleted}
        />
    </>);
};
