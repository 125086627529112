import React, { useState } from 'react';

import { useAppSelector } from "../../../../redux/hooks";

import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

import useStyles from "./css";
import { PatientFormDateInputProps } from "./props";

import useControlLabel from "../../../../hooks/useControlLabel";
import useInputFirstTouch from "../../../../hooks/useInputFirstTouch";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import CalendarIcon from "../../../../assets/icons/CalendarIcon";
import { Button, Typography } from '@material-ui/core';
import { ToolbarComponentProps } from '@material-ui/pickers/Picker/Picker';


export default function PatientFormDateInput(props: PatientFormDateInputProps) {

  const { value, setValue, label, isValid, required, minDate, maxDate } = props;

  const classes = useStyles()();

  const [open, setOpen] = useState<boolean>(false);

  const localeApp = useAppSelector(state => state.applicationInterface.locale);

  const yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));

  function getLocale() {
    switch (localeApp) {
      case "es":
        return esLocale;

      default:
        return enLocale;
    }
  }

  function handleChangeDate(date: MaterialUiPickersDate) {
    const actualDate = date as Date;
    setValue(actualDate);
  }

  const ControlLabel = useControlLabel({ label, required });
  const { hasBeenTouched, handleFirstTouch } = useInputFirstTouch();

  function getYear(value: Date | null) {
    if (!value || !isValid(value)) {
      return "--";
    }

    const newDateObj = new Date(value!);
    const year = newDateObj.toLocaleString(localeApp, { year: "numeric", });

    return year;
  }

  function getWeekDayDayMonth(value: Date | null) {
    if (!value || !isValid(value)) {
      return "--";
    }

    const newDateObj = new Date(value!);
    const day = newDateObj.toLocaleString(localeApp, { day: "numeric" });
    let month = newDateObj.toLocaleString(localeApp, { month: "long", });
    const weekDay = newDateObj.toLocaleString(localeApp, { weekday: "short" })

    const result = `${weekDay.charAt(0).toUpperCase() + weekDay.slice(1)}, ${month.charAt(0).toUpperCase() + month.slice(1)} ${day}`;

    return result;
  }

  const ToolbarComponent: React.FC<ToolbarComponentProps> = ({ setOpenView }) => {
    return <div className={classes.toolbarBox}>
      <div className={classes.toolbarDateContainer}>
        <div className={classes.toolbarDateLabelContainer} onClick={() => setOpenView("year")}>
          <Typography className={classes.toolbarYearLabel}>
            {getYear(value)}
          </Typography>
        </div>
        <div className={classes.toolbarDateLabelContainer} onClick={() => setOpenView("date")}>
          <Typography className={classes.toolbarWeekDayDayMonthLabel}>
            {getWeekDayDayMonth(value)}
          </Typography>
        </div>
      </div>
    </div>
  }

  return (
    <div className={classes.root}>
      <ControlLabel />
      <div className={classes.datePicker}>
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={getLocale()}>
          <KeyboardDatePicker
            variant={"inline"}
            format={localeApp === "es" ? "dd/MM/yyyy" : "MM/dd/yyyy"}
            open={open}
            onClose={() => setOpen(false)}
            autoOk
            fullWidth
            openTo="date"
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            inputVariant="standard"
            onChange={handleChangeDate}
            onBlur={() => handleFirstTouch()}
            disableFuture
            keyboardIcon={<></>}
            helperText={<></>}
            PopoverProps={{
              anchorOrigin: { horizontal: "right", vertical: "top" },
              transformOrigin: { horizontal: "right", vertical: "bottom" },
              classes: { paper: classes.popoverOverride }
            }}
            InputProps={{
              disableUnderline: true,
              placeholder: localeApp === "es" ? "dd/mm/yyyy" : "mm/dd/yyyy",
              className: `${classes.input} ${!isValid(value) ? classes.inputError : ""}`,
              classes:
              {
                input: isValid(value) ? classes.inputText : classes.inputTextError,
                disabled: classes.inputDisabled
              }
            }}
            ToolbarComponent={ToolbarComponent}
          />
          <Button className={classes.icon} onClick={() => setOpen(true)}>
            <CalendarIcon style={{ width: 14, height: 16, color: "#5C6477" }} />
          </Button>
        </MuiPickersUtilsProvider>
      </div>
    </div>
  );
}