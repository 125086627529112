import React from "react";

import InputBase, { InputBaseProps } from "@material-ui/core/InputBase";

import useStyles from "./css";
import { MenuIcon } from "../../../assets/icons";
import { styled } from "@material-ui/core";

export default function Chip(props: InputBaseProps) {

  const classes = useStyles();
  
  const useChipStyles = (({ theme } : any) => ({
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "center",

      ...theme?.typography?.subtitle1,
      fontWeight: 700,
      color: theme?.colors?.clear2,
      padding: 4,

      height: 22,
      width: "fit-content",
      backgroundColor: theme?.colors?.clear5,
      borderRadius: 5,
      "&:focus": {
        borderColor: theme?.colors?.clearMain,
      },
    })
  );
  
  const StyledInputBase = styled(InputBase)<InputBaseProps>(useChipStyles);
  
  return (
    <StyledInputBase 
      {...props}
      startAdornment={
        <div className={classes.icon}>
          <MenuIcon />
        </div>
      }
    />
  )
}