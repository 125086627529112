import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";


export default function ReportPersonIcon(props: any) {
  const { style, viewBox, color, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={style || { width: 340, height: 323 }}
      viewBox={viewBox || "0 0 251 222"}
    >
      <path d="M63.61 120.992C63.61 120.992 53.259 121.958 51.7528 127.608C50.2466 133.257 45.3435 203.127 51.7528 213.025C58.1621 222.922 140.682 221.543 146.41 220.185C152.138 218.827 154.838 215.458 154.253 211.34C153.668 207.223 97.475 202.118 97.475 202.118L100.904 129.198C100.904 129.198 80.6987 119.976 63.61 120.992Z" fill="#323947"/>
      <path opacity="0.1" d="M239.869 31.6471L238.371 29.4033C225.712 10.5233 202.823 -0.906333 178.684 0.480619C175.578 0.652898 172.49 1.03642 169.447 1.62794C147.503 5.8832 128.411 20.9218 120.504 40.1867C115.849 51.5147 114.543 64.5564 105.714 73.5461C94.2177 85.2517 74.7015 85.6148 57.5486 85.1646C40.3958 84.7144 20.8635 85.0992 9.4229 96.8411C2.26052 104.204 -0.0147736 114.668 0.562062 124.515C2.09228 150.605 22.1453 173.93 47.6543 185.512C73.1632 197.094 103.183 198.125 131.111 193.02C170.216 185.875 207.406 166.254 229.758 135.952C252.11 105.649 257.759 64.353 239.869 31.6471Z" fill="#6462F3"/>
      <path d="M162.229 120.914L165.522 130.042L147.568 131.313L145.477 126.426L162.229 120.914Z" fill="#FFBE9D"/>
      <path d="M90.2441 25.2089C90.092 24.1906 90.3663 23.1576 91.0129 22.3134C91.6594 21.4693 92.6314 20.8754 93.7372 20.6486L113.558 9.21899C121.113 7.67955 130.631 19.1165 131.985 26.0149C133.353 33.5971 133.89 41.2834 133.587 48.9613C133.042 60.7686 124.502 62.0321 124.502 62.0321L130.911 101.295L100.026 84.0345L90.2441 25.2089Z" fill="#FFBE9D"/>
      <path d="M129.468 35.8111C129.521 36.1972 129.406 36.5868 129.148 36.8973C128.889 37.2078 128.507 37.4146 128.082 37.474C127.665 37.544 127.234 37.461 126.884 37.2431C126.534 37.0253 126.294 36.6904 126.216 36.3121C126.163 35.926 126.278 35.5364 126.537 35.2259C126.795 34.9155 127.177 34.7086 127.602 34.6492C128.019 34.5792 128.45 34.6623 128.8 34.8801C129.15 35.0979 129.391 35.4328 129.468 35.8111Z" fill="#263238"/>
      <path d="M132.021 31.5812C131.86 31.7409 130.547 31.7191 128.888 32.3073C127.23 32.8955 126.132 33.6797 125.924 33.6362C125.716 33.5926 125.924 33.382 126.429 33.0189C127.125 32.5036 127.913 32.1008 128.76 31.828C129.568 31.5462 130.426 31.401 131.292 31.3996C131.868 31.3851 132.101 31.5085 132.021 31.5812Z" fill="#263238"/>
      <path d="M113.063 36.5298C113.114 36.9167 112.997 37.3063 112.737 37.6165C112.477 37.9268 112.094 38.1333 111.669 38.1927C111.253 38.2606 110.824 38.1766 110.476 37.9589C110.127 37.7413 109.888 37.4077 109.81 37.0309C109.755 36.6444 109.87 36.2538 110.129 35.9429C110.388 35.632 110.771 35.4256 111.196 35.368C111.614 35.298 112.045 35.381 112.395 35.5988C112.745 35.8167 112.985 36.1515 113.063 36.5298Z" fill="#263238"/>
      <path d="M114.792 34.6134C114.551 34.7006 113.317 34.0325 111.403 33.7783C109.488 33.5242 107.99 33.7783 107.805 33.6622C107.621 33.546 107.99 33.4225 108.607 33.2846C109.568 33.1116 110.555 33.0944 111.523 33.2337C112.461 33.3501 113.366 33.6261 114.191 34.047C114.759 34.3447 114.944 34.5771 114.792 34.6134Z" fill="#263238"/>
      <path d="M124.665 62.0925C118.68 62.8945 112.568 62.139 107.039 59.9141C107.039 59.9141 112.303 68.076 124.961 65.6362L124.665 62.0925Z" fill="#EB996E"/>
      <path d="M122.817 48.404C122.386 47.8953 121.835 47.4806 121.204 47.1903C120.573 46.9 119.879 46.7416 119.171 46.7266C118.762 46.7343 118.366 46.8544 118.034 47.0709C117.702 47.2875 117.45 47.5903 117.313 47.9392C117.174 48.39 117.158 48.8645 117.265 49.3221C117.373 49.7798 117.601 50.2072 117.929 50.5679C118.252 50.9496 118.691 51.2375 119.192 51.3961C119.693 51.5548 120.235 51.5774 120.75 51.4611C121.585 51.1486 122.297 50.6145 122.793 49.9289C122.972 49.7543 123.101 49.5423 123.169 49.3117C123.198 49.1661 123.192 49.0166 123.151 48.8735C123.109 48.7305 123.033 48.5974 122.929 48.4839" fill="#EB996E"/>
      <path d="M117.56 46.1417C117.848 46.0909 118.073 47.8264 119.867 48.8866C121.662 49.9468 123.681 49.5401 123.729 49.7797C123.729 49.8887 123.32 50.1646 122.455 50.2808C121.336 50.4129 120.202 50.1841 119.25 49.6345C118.337 49.1024 117.688 48.2691 117.44 47.3108C117.271 46.5629 117.432 46.1417 117.56 46.1417Z" fill="#263238"/>
      <path d="M115.355 27.473C115.187 27.9087 113.408 27.7271 111.349 27.9886C109.29 28.25 107.592 28.7801 107.303 28.3952C107.175 28.2064 107.463 27.7925 108.104 27.3568C109 26.8206 110.02 26.4765 111.084 26.3506C112.149 26.2248 113.232 26.3206 114.249 26.6307C115.034 26.9574 115.427 27.2624 115.355 27.473Z" fill="#263238"/>
      <path d="M132.128 27.9949C131.783 28.3943 130.229 28.1765 128.41 28.3435C126.592 28.5105 125.085 28.9317 124.685 28.5831C124.501 28.4161 124.685 28.024 125.334 27.6173C126.216 27.1049 127.222 26.7937 128.265 26.7103C129.309 26.6268 130.359 26.7736 131.326 27.1381C132.015 27.4503 132.272 27.7989 132.128 27.9949Z" fill="#263238"/>
      <path d="M84.8999 53.6543C85.2204 48.3897 86.1498 31.5356 89.8992 22.3426C93.6486 13.1495 98.9283 9.03216 104.36 7.09334C106.412 6.29884 108.655 5.9977 110.872 6.21905C113.09 6.44039 115.207 7.17674 117.018 8.35684C119.806 10.1432 121.425 16.025 121.425 16.025C121.425 16.025 108.246 20.1496 104.6 27.6435C100.378 36.2121 99.6253 40.3802 99.6253 40.3802L98.4876 44.9477C98.4876 44.9477 100.963 64.8007 102.077 68.9107L84.2109 84.5666C84.7958 79.3964 84.5795 58.9116 84.8999 53.6543Z" fill="#263238"/>
      <path d="M118.058 15.8543C118.058 15.8543 125.693 14.293 129.547 21.9104C131.294 25.3596 133.024 35.8017 133.024 35.8017C133.024 35.8017 133.673 27.2694 132.664 22.6293C131.654 17.9891 128.329 12.3252 123.955 9.45685C119.581 6.58854 116.264 5.50657 109.422 6.5958C111.482 7.06271 113.353 8.05272 114.814 9.44958C115.847 10.5461 116.36 11.5191 118.058 15.8543Z" fill="#263238"/>
      <path d="M98.0311 42.6756C97.5664 40.1196 94.2656 38.7254 91.9743 40.2721C90.7165 41.1144 89.8672 42.6393 90.3319 45.3551C91.5817 52.6167 99.3289 49.9589 99.3049 49.7556C99.2809 49.5523 98.5758 45.6601 98.0311 42.6756Z" fill="#FFBE9D"/>
      <path d="M90.7046 44.4214C90.7076 44.3803 90.7076 44.339 90.7046 44.2979L90.5924 43.9711C90.5364 43.8187 90.4803 43.6371 90.4082 43.4338L90.1919 42.7076C89.7551 41.1202 89.5717 39.4843 89.6471 37.8497C89.7489 35.4249 90.4065 33.046 91.5779 30.8641C93.0752 28.2224 95.2113 25.9214 97.8269 24.1326C99.1692 23.1937 100.574 22.3303 102.033 21.5475C103.443 20.7778 104.861 20.1388 106.175 19.4272C108.473 18.1566 110.658 16.7267 112.712 15.1501C114.467 13.8068 115.821 12.6522 116.718 11.8389C117.175 11.4322 117.519 11.1127 117.752 10.8876L118.016 10.6335C118.072 10.5754 118.104 10.5463 118.096 10.5391L118 10.6117L117.712 10.8513L116.654 11.788C115.717 12.5796 114.347 13.7124 112.576 15.0267C110.515 16.5771 108.33 17.9873 106.039 19.2456C104.741 19.9718 103.307 20.5745 101.889 21.3515C100.415 22.134 98.9998 23.0023 97.6507 23.9511C94.991 25.757 92.8209 28.089 91.3055 30.7697C90.1302 32.982 89.4858 35.3951 89.4147 37.8497C89.3596 39.498 89.5729 41.1447 90.0477 42.7367L90.288 43.4628C90.3761 43.6662 90.4482 43.8404 90.5043 43.9929L90.6485 44.3197C90.6806 44.3851 90.6966 44.4214 90.7046 44.4214Z" fill="#455A64"/>
      <path d="M90.0289 41.7321C90.0289 41.7321 90.0289 41.6886 90.0289 41.616L89.9808 41.2674C89.9808 40.9624 89.8526 40.5412 89.7885 39.9385C89.5998 38.303 89.5703 36.6555 89.7004 35.0152C89.8961 32.586 90.4346 30.1895 91.3027 27.8844C92.3872 25.069 93.9702 22.4325 95.9895 20.0783C98.0691 17.7516 100.496 15.6987 103.2 13.9786C105.603 12.352 108.007 11.074 109.922 9.92664C111.836 8.77932 113.431 7.90067 114.496 7.23261C115.033 6.9131 115.434 6.64442 115.706 6.46289L116.019 6.25956L116.123 6.17969C116.123 6.17969 116.075 6.17969 116.003 6.23778L115.674 6.41932L114.432 7.14547C113.359 7.77723 111.772 8.67039 109.81 9.75962C107.847 10.8489 105.507 12.1559 103.04 13.7825C100.33 15.5046 97.9023 17.5655 95.8293 19.904C93.7697 22.2807 92.1589 24.9504 91.0624 27.8045C90.1841 30.1321 89.6454 32.5538 89.4601 35.008C89.3486 36.6555 89.4103 38.3089 89.6443 39.9458C89.7244 40.5267 89.8366 40.9697 89.8927 41.2747C89.8927 41.4126 89.9488 41.5288 89.9728 41.616C89.9968 41.7031 90.0209 41.7321 90.0289 41.7321Z" fill="#455A64"/>
      <path d="M128.031 70.3984C128.031 70.3984 138.823 72.9296 141.497 77.5215C144.171 82.1133 164.935 120.191 164.935 120.191L147.095 128.174L135.83 96.4209L128.031 70.3984Z" fill="#7175FA"/>
      <path d="M88.6454 136.237L92.631 151.044C92.631 151.044 82.7537 171.835 82.9423 189.085C83.0313 197.281 89.6487 213.604 89.6487 213.604C89.6487 213.604 130.928 214.743 152.958 212.638L176.429 210.014L177.609 174.807L150.931 174.803L147.361 129.329C147.361 129.329 151.107 117.019 151.166 113.453C151.36 105.921 150.348 98.3893 141.268 89.2945L129.283 70.9533L125.714 69.7525L129.37 92.7487L97.4765 69.3312L82.8805 75.2954L80.2138 93.8066L88.6454 136.237Z" fill="#7175FA"/>
      <path d="M88.5296 75.7812C88.5296 75.7812 80.1094 78.2066 77.065 82.992C74.0206 87.7773 68.9733 111.014 67.7555 117.274C66.5378 123.533 95.9232 157.883 103.029 161.935L113.445 150.68C113.445 150.68 91.6862 99.2433 91.1013 95.3729C90.5165 91.5025 88.5296 75.7812 88.5296 75.7812Z" fill="#FFBE9D"/>
      <path d="M62.1719 123.387L82.3061 124.54L102.63 123.387L94.0448 100.114L93.3679 71.5312C93.3679 71.5312 75.3965 75.3824 71.5084 87.3854C68.6274 96.2553 62.1719 123.387 62.1719 123.387Z" fill="#7175FA"/>
      <path d="M147.984 160.189C148.01 159.227 146.538 158.159 143.479 159.307C140.927 160.27 138.526 161.819 137.042 161.884C135.557 161.949 132.431 161.795 130.84 161.968C129.909 162.033 128.977 162.074 128.044 162.089C126.82 162.11 125.636 161.631 124.717 160.806L113.818 151.019C113.575 150.801 113.261 150.68 112.937 150.68C112.741 150.68 112.549 150.724 112.374 150.81L110.013 151.972C109.189 152.378 108.499 153.006 108.021 153.786L103.025 161.935L112.584 167.923C114.175 168.92 114.896 170.939 116.138 172.346C116.371 172.609 116.659 172.854 117.016 173.06L118.846 174.118C119.61 174.559 120.24 175.198 120.786 175.889C121.387 176.649 122.294 177.618 123.355 178.163C124.952 178.985 126.18 178.817 127.962 178.842C129.175 178.858 131.312 178.118 132.519 178.025C132.983 177.99 133.484 177.914 133.96 177.768C134.816 177.505 135.642 177.103 136.521 176.924C137.688 176.687 139.239 176.272 139.585 175.698C139.906 175.164 139.701 172.054 139.463 169.447C139.26 167.237 140.491 165.176 142.483 164.231C143.008 163.982 143.528 163.726 144.044 163.462C145.83 162.267 147.952 161.53 147.984 160.189Z" fill="#FFBE9D"/>
      <path d="M129.504 109.242C129.537 109.58 129.537 109.921 129.504 110.259C129.503 110.657 129.47 111.055 129.408 111.45C129.35 111.976 129.262 112.5 129.143 113.018C129.015 113.647 128.844 114.268 128.631 114.877C128.405 115.584 128.137 116.28 127.829 116.961C127.09 118.498 126.171 119.958 125.089 121.318C123.98 122.675 122.71 123.917 121.3 125.021C120.682 125.5 120.035 125.946 119.361 126.358C118.785 126.729 118.183 127.066 117.558 127.367C117.041 127.638 116.506 127.881 115.956 128.093C115.562 128.267 115.155 128.415 114.738 128.536C114.392 128.665 114.032 128.765 113.665 128.834C113.625 128.754 115.163 128.325 117.374 127.156C117.979 126.841 118.565 126.497 119.129 126.125C119.754 125.748 120.355 125.268 121.011 124.782C123.772 122.581 125.961 119.853 127.421 116.794C127.726 116.129 127.994 115.451 128.222 114.761C128.443 114.161 128.628 113.55 128.775 112.931C129.44 110.694 129.424 109.242 129.504 109.242Z" fill="#263238"/>
      <path d="M141.868 129.625H208.347C209.52 129.625 210.645 130.045 211.474 130.793C212.304 131.541 212.77 132.555 212.77 133.613V176.099H137.461V133.606C137.463 132.552 137.928 131.542 138.754 130.796C139.579 130.05 140.699 129.629 141.868 129.625Z" fill="#C9CDD3"/>
      <path d="M212.775 178.998H119.039C119.039 177.842 119.418 176.734 120.092 175.917C120.767 175.1 121.681 174.641 122.635 174.641H212.775V178.998Z" fill="#C9CDD3"/>
      <path d="M210.005 132.719H140.031V176.295H210.005V132.719Z" fill="#C9CDD3"/>
      <ellipse cx="174.716" cy="151.74" rx="6.80986" ry="5.42729" fill="white"/>
    </SvgIcon>
  );
}