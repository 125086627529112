import { CreateEmergencyContacts } from "../models/create-emergency-contacts";
import { CustomerData } from "../models/customer-data";
import { ApiClient } from "./api-client";
import { CustomersResponse } from "../models/customers-response";
import { CustomerEntity } from "../models/customer-entity";
import { CustomerInfoDetails } from "../models/customer-info-details";
import { CustomerSearch } from "../models/customer-search";
import { ImportExcelResponse } from "../models/import-excel-response";
import CustomerHistory from "./../models/customer-history";
import { Response } from "../models/response";
import { PatientDataResponse } from "../models/patient-data-response";
import { PatientGeneralInfoResponse } from "../models/patient-general-info-response";
import { UpdatePatientFormDataRequest } from "../models/update-patient-form-data-request";
import { SectionFieldType } from "../models/enums/section-field-type";
import { FormRadioValue } from "../models/form-radio-value";
import { FormPhoneNumberValue } from "../models/form-phone-number-value";
import { PatientFormFieldAndValue } from "../models/patient-form-field-and-value";
import { PatientFormSection } from "../models/patient-form-section";

import EvolutionNotesGrouped, { EvolutionNote } from "../models/evolution-notes-grouped";
import { FileUploadResponse } from "../models/file-upload-response";
import { UpdatePatientFormDataResponse } from "../models/update-patient-form-data-response";
import { EvolutionNoteFieldAndValue } from "../models/evolution-note-field-and-value";
import { CustomerClinicalFile } from "../models/customer-clinical-file";
import { EvolutionNoteResponse } from "../models/evolution-note-response";
import { ClinicalHistorySection } from "../models/clinical-history-section";
import { ClinicalHistoryFieldAndValue } from "../models/clinical-history-field-and-value";
import { PatientClinicalHistoryTabDataResponse } from "../models/patient-clinical-history-tab-data-response";
import { UpdatePatientObservationsRequest } from "../models/update-patient-observations-request";
import { UpdatePatientClinicalHistoryFieldValueRequest } from "../models/update-patient-clinical-history-field-value-request";
import { CustomersByIdResponse } from "../models/customer-by-id-response";
import { EmergencyContact } from "../models/emergency-contact";
import { PredeterminedReportType } from "../models/enums/predetermined-report-type";
import UpdatePatientSignatureRequest from "../models/update-patient-signature-request";
import UpdatePatientSignatureResponse from "../models/update-patient-signature-response";

export class CustomerService {
  private static baseUrl: string = "/customer";

  static getCustomers(
    page: number,
    pageSize: number,
    order?: string,
    orderBy?: string,
    locale?: string
  ): Promise<CustomersResponse> {
    let urlEndpoint = `${this.baseUrl}/getbyaccount?page=${page}&pageSize=${pageSize}&locale=${locale ?? "es"}`;
    if (order && orderBy) {
      urlEndpoint += `&order=${order}&orderBy=${orderBy}`;
    }
    
    return ApiClient.get(urlEndpoint).then(
      (data) => {
        return new CustomersResponse(data);
      }
    );
  }
  
  static getCustomersByEstablishments(
    page: number,
    pageSize: number,
    establishmentIds: string[] | undefined,
    order?: string,
    orderBy?: string,
  ): Promise<CustomersResponse> {
    let urlEndpoint = `${this.baseUrl}/getbyestablishments?page=${page}&pageSize=${pageSize}`;
    
    if (order && orderBy) {
      urlEndpoint += `&order=${order}&orderBy=${orderBy}`;
    }
  
    urlEndpoint += '&' + Object.entries({
      EstablishmentIds: establishmentIds && establishmentIds.length > 0 ? establishmentIds : [],
    }).flatMap(([key, value]) => [value].flat().map(v => [key, v]))
      .map(it => it.join("="))
      .join("&");
    
    return ApiClient.get(urlEndpoint).then(
      (data) => {
        return new CustomersResponse(data);
      }
    );
  }

  static getSearch(
    value?: string,
    size?: number
  ): Promise<CustomerSearch[]> {
    let urlEndpoint = `${this.baseUrl}/getsearch?value=${value}`;
    
    if (size) {
      urlEndpoint += `&size=${size}`;
    }

    return ApiClient.get(urlEndpoint).then((data) =>
      data.customers.map((customer: any) => new CustomerSearch(customer))
    );
  }
  
  static getSearchByEstablishments(
    establishmentIds: string[] | undefined,
    value?: string,
    size?: number
  ): Promise<CustomerSearch[]> {
    let urlEndpoint = `${this.baseUrl}/getsearchbyestablishments?value=${value}`;
    
    if (size) {
      urlEndpoint += `&size=${size}`;
    }
    
    urlEndpoint += '&' + Object.entries({
      EstablishmentIds: establishmentIds && establishmentIds.length > 0 ? establishmentIds : [],
    }).flatMap(([key, value]) => [value].flat().map(v => [key, v]))
      .map(it => it.join("="))
      .join("&");
    
    return ApiClient.get(urlEndpoint).then((data) =>
      data.customers.map((customer: any) => new CustomerSearch(customer))
    );
  }

  static createCustomer(customer: CustomerEntity): Promise<CustomerEntity> {
    return ApiClient.post(`${this.baseUrl}/createNew`, customer).then(
      (data) => new CustomerEntity(data.customer)
    );
  }

  static async deleteCustomer(customerId: string): Promise<boolean> {
    return await ApiClient.remove(`${this.baseUrl}/delete?customerId=${customerId}`).then(
      (data) => data?.succeeded ?? false
    );
  }

  static createCustomersFromFile(file: File): Promise<ImportExcelResponse> {
    const formData = new FormData();
    formData.append("File", file);

    return ApiClient.post(`${this.baseUrl}/createfromfile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((data) => {
      return new ImportExcelResponse(data);
    });
  }

  static createEmergencyContacts(
    emergencyContacts: CreateEmergencyContacts
  ): Promise<EmergencyContact[]> {
    return ApiClient.post(
      `${this.baseUrl}/createemergencycontacts`,
      emergencyContacts
    ).then((data) => {
      return data.emergencyContacts.map((item: EmergencyContact) => new EmergencyContact(item));
    });
  }

  static deleteBulkEmergencyContacts(
    contactsIds: string[]
  ): Promise<CustomerEntity> {
    return ApiClient.put(`${this.baseUrl}/deletebulkemergencycontacts`, {
      contactIds: contactsIds,
    }).then((data) => new CustomerEntity(data));
  }

  static deleteEmergencyContacts(contactId: string): Promise<boolean> {
    return ApiClient.remove(
      `${this.baseUrl}/deleteemergencycontacts?contactId=${contactId}`
    ).then((data) => true);
  }

  static updateCustomer(
    customer: CustomerInfoDetails
  ): Promise<CustomerInfoDetails> {
    return ApiClient.put(`${this.baseUrl}/update`, customer).then(
      (data) => new CustomerInfoDetails(data.customer)
    );
  }

  static getCustomerData(customerId: string): Promise<CustomerData> {
    return ApiClient.get(
      `${this.baseUrl}/getcustomerdata?customerId=${customerId}`
    ).then((data) => new CustomerData(data.customer));
  }
  
  static getById(customerId: string): Promise<CustomersByIdResponse> {
    return ApiClient.get(
      `${this.baseUrl}/getbyid?customerId=${customerId}`
    ).then((data) => {
      return new CustomersByIdResponse(data);
    });
  }

  static getCustomerInfoDetails(
    customerId: string
  ): Promise<CustomerInfoDetails> {
    return ApiClient.get(
      `${this.baseUrl}/getinfodetails?customerId=${customerId}`
    ).then((data) => new CustomerInfoDetails(data.customer));
  }

  static getCustomerHistory(customerId: string, locale?: string): Promise<Response> {
    return ApiClient.get(`${this.baseUrl}/gethistory?customerId=${customerId}&locale=${locale ?? "es"}`)
      .then((data) => {
        if (data) {
          let entity = data.history.map((item: any) => new CustomerHistory(item));
          return new Response(entity, data.total);
        } else {
          return new Response();
        }
      });
  }

  static async getPatientData(customerId: string): Promise<PatientDataResponse> {
    return ApiClient.get(`${this.baseUrl}/getpatientdata?customerId=${customerId}`)
      .then((data) => {
        if (data) {
          if (data.evolutionNoteFieldsValues)
            data.evolutionNoteFieldsValues.forEach((fv: EvolutionNoteFieldAndValue) => {
              if (!fv?.value?.value)
                return;
              else {
                const objectValue = CustomerService.getJsonObjectValue(fv.value);
                fv.value.value = objectValue;
              }
            })
          return new PatientDataResponse(data);
        } else {
          return new PatientDataResponse();
        }
      });
  }

  static async getPatientGeneralInfo(customerId: string): Promise<PatientGeneralInfoResponse> {
    return ApiClient.get(`${this.baseUrl}/getpatientgeneralinfo?customerId=${customerId}`)
      .then((data) => {
        if (data) {
          data.patientFormSections.forEach((s: PatientFormSection) => {
            s.patientFormSectionFields.forEach((fv: PatientFormFieldAndValue) => {
              if (!fv?.value?.value)
                return;
              else {
                const objectValue = CustomerService.getJsonObjectValue(fv.value);
                fv.value.value = objectValue;
              }
            })
          })
          return new PatientGeneralInfoResponse(data);
        } else {
          return new PatientGeneralInfoResponse();
        }
      });
  }

  static async getPatientClinicalHistoryTabData(customerId: string): Promise<PatientClinicalHistoryTabDataResponse> {
    return ApiClient.get(`${this.baseUrl}/getpatientclinicalhistorytabdata?customerId=${customerId}`)
      .then((data) => {
        if (data) {
          data.clinicalHistorySections.forEach((s: ClinicalHistorySection) => {
            s.fieldsAndValues.forEach((fv: ClinicalHistoryFieldAndValue) => {
              if (!fv?.value?.value)
                return;
              else {
                const objectValue = CustomerService.getJsonObjectValue(fv.value);
                fv.value.value = objectValue;
              }
            })
          })
          return new PatientClinicalHistoryTabDataResponse(data);
        } else {
          return new PatientClinicalHistoryTabDataResponse();
        }
      });
  }

  static async updatePatientFormData(request: UpdatePatientFormDataRequest): Promise<UpdatePatientFormDataResponse> {
    const clonedRequest: UpdatePatientFormDataRequest = structuredClone(request);
    clonedRequest.patientFormSectionFields.forEach(fv => {
      if (!fv.value?.value)
        return;

      //if optional phone number input has country, but phone body is empty
      if (!fv.field.isRequired
        && fv.value.type === SectionFieldType.PhoneNumber
        && ((fv.value.value as FormPhoneNumberValue).body === null
          || (fv.value.value as FormPhoneNumberValue).body === undefined
          || (fv.value.value as FormPhoneNumberValue).body.trim() === "")) {
        fv.value = null;
        return;
      }
      if (fv.value.type === SectionFieldType.Radio || fv.value.type === SectionFieldType.PhoneNumber) {
        fv.value.value = JSON.stringify(fv.value.value);
      }
    });
    return ApiClient.put(`${this.baseUrl}/updatepatientformdata`, clonedRequest)
      .then((data) => new UpdatePatientFormDataResponse(data));
  }
  

  static async updatePatientClinicalHistoryFieldValue(request: UpdatePatientClinicalHistoryFieldValueRequest): Promise<boolean> {
    const clonedRequest: UpdatePatientClinicalHistoryFieldValueRequest = structuredClone(request);
    if (!clonedRequest.fieldAndValue.value?.value)
      return false;

    if (clonedRequest.fieldAndValue.value.type === SectionFieldType.Radio
      || clonedRequest.fieldAndValue.value.type === SectionFieldType.PhoneNumber) {
      clonedRequest.fieldAndValue.value.value = JSON.stringify(clonedRequest.fieldAndValue.value.value);
    }

    return ApiClient.put(`${this.baseUrl}/updatepatientclinicalhistoryfieldvalue`, clonedRequest)
      .then((data) => data.isUpdated);
  }

  static async updatePatientObservations(request: UpdatePatientObservationsRequest): Promise<boolean> {
    return ApiClient.put(`${this.baseUrl}/updatepatientobservations`, request)
      .then((data) => data.isUpdated);
  }

  static async updatePatientSignature(request: UpdatePatientSignatureRequest): Promise<UpdatePatientSignatureResponse> {
    return ApiClient.put(`${this.baseUrl}/updatepatientsignature`, request);
  }

  static async getPatientEvolutionNotes(customerId: string, appointmentId: string | undefined, serviceId: string | undefined): Promise<EvolutionNoteResponse> {
    return ApiClient.get(`${this.baseUrl}/getpatientevolutionnotes?customerId=${customerId}${appointmentId && serviceId ? "&appointmentId=" + appointmentId + "&serviceId=" + serviceId : ""}`)
      .then((data) => {
        if (data.evolutionNotesGrouped) {
          data.evolutionNotesGrouped.map((evolutionNotesGrouped: EvolutionNotesGrouped) => {
            evolutionNotesGrouped.evolutionNotes.map((note: EvolutionNote) => {
              note.vitalSigns.map((fv: EvolutionNoteFieldAndValue) => {
                if (!fv?.value?.value)
                  return;
                else {
                  const objectValue = CustomerService.getJsonObjectValue(fv.value);
                  fv.value.value = objectValue;
                }
                return fv;
              })
              return note;
            })
            return evolutionNotesGrouped;
          });
          return new EvolutionNoteResponse(data);
        } else {
          return new EvolutionNoteResponse();
        }
      });
  }

  static createEvolutionNote(evolutionNote: EvolutionNote): Promise<EvolutionNote> {
    const formData = new FormData();
    
    let clonedRequest: EvolutionNote = structuredClone(evolutionNote);
    clonedRequest.vitalSigns.forEach(fv => {
      if (!fv.value?.value)
        return;
      
      //if optional phone number input has country, but phone body is empty
      if (!fv.field.isRequired
        && fv.value.type === SectionFieldType.PhoneNumber
        && ((fv.value.value as FormPhoneNumberValue).body === null
          || (fv.value.value as FormPhoneNumberValue).body === undefined
          || (fv.value.value as FormPhoneNumberValue).body.trim() === "")) {
        
        fv.value = null;
        return;
      }
      if (fv.value.type === SectionFieldType.Radio || fv.value.type === SectionFieldType.PhoneNumber) {
        fv.value.value = JSON.stringify(fv.value.value);
      }
    });
    
    clonedRequest.clinicalFiles.forEach(function (item, index, array) {
      formData.append("Files", new File([item.fileBlob], item.fileComment + ":" + item.fileName, { type: "multipart/form-data" }));
    });
    formData.append("CustomerId", clonedRequest.customerId);
    if (clonedRequest.appointmentId && clonedRequest.serviceId){
      formData.append("AppointmentId", clonedRequest.appointmentId);
      formData.append("ServiceId", clonedRequest.serviceId);
    }
    formData.append("EvolutionNoteText", clonedRequest.evolutionNoteText);
    formData.append("Diagnosis", clonedRequest.diagnosis ?? "");
    formData.append("AdditionalInstructions", clonedRequest.additionalInstructions ?? "");
    formData.append("Studies", JSON.stringify(clonedRequest.studies));
    formData.append("Treatments", JSON.stringify(clonedRequest.treatments));
    formData.append("VitalSigns", JSON.stringify(clonedRequest.vitalSigns));

    return ApiClient.post(`${this.baseUrl}/createevolutionnote`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((data) => {
      return new EvolutionNote(data.evolutionNote)
    });
  }

  static updateEvolutionNote(evolutionNote: EvolutionNote): Promise<EvolutionNote> {
    const formData = new FormData();
  
    let clonedRequest: EvolutionNote = structuredClone(evolutionNote);
    clonedRequest.vitalSigns.forEach(fv => {
      if (!fv.value?.value)
        return;
    
      //if optional phone number input has country, but phone body is empty
      if (!fv.field.isRequired
        && fv.value.type === SectionFieldType.PhoneNumber
        && ((fv.value.value as FormPhoneNumberValue).body === null
          || (fv.value.value as FormPhoneNumberValue).body === undefined
          || (fv.value.value as FormPhoneNumberValue).body.trim() === "")) {
      
        fv.value = null;
        return;
      }
      if (fv.value.type === SectionFieldType.Radio || fv.value.type === SectionFieldType.PhoneNumber) {
        fv.value.value = JSON.stringify(fv.value.value);
      }
    });
    clonedRequest.clinicalFiles.filter(file => !file.s3Key).forEach(function (item, index, array) {
      formData.append("Files", new File([item.fileBlob], item.fileComment + ":" + item.fileName));
    });
    formData.append("Id", clonedRequest.id);
    formData.append("CustomerId", clonedRequest.customerId);
    formData.append("EvolutionNoteText", clonedRequest.evolutionNoteText);
    formData.append("Diagnosis", clonedRequest.diagnosis ?? "");
    formData.append("AdditionalInstructions", clonedRequest.additionalInstructions ?? "");
    formData.append("Studies", JSON.stringify(clonedRequest.studies));
    formData.append("Treatments", JSON.stringify(clonedRequest.treatments));
    formData.append("VitalSigns", JSON.stringify(clonedRequest.vitalSigns));
  
    return ApiClient.post(`${this.baseUrl}/updateevolutionnote`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((data) => new EvolutionNote(data.evolutionNote));
  }

  static getTreatmentPdf(customerId: string, evolutionNoteId: string) {
    let endpoint = `${this.baseUrl}/gettreatmentpdf?customerId=${customerId}&evolutionNoteId=${evolutionNoteId}`

    return ApiClient.get(endpoint, {
      responseType: 'arraybuffer',
      headers:
        {
          'Content-Type': 'application/pdf'
        }
    }).then((data) => {
      return data;
    });
  }

  static sendTreatmentPdfByWhatsApp(customerId: string, evolutionNoteId: string): Promise<boolean> {
    let endpoint = `${this.baseUrl}/sendtreatmentpdfbywhatsapp?customerId=${customerId}&evolutionNoteId=${evolutionNoteId}`

    return ApiClient.get(endpoint).then(data => data?.succeeded ?? false)
  }

  static uploadPatientClinicalFile(file: File, customerId: string, evolutionNoteId: string, fileComment: string): Promise<FileUploadResponse> {
    const formData = new FormData();
    formData.append("File", file);
    formData.append("CustomerId", customerId);
    formData.append("EvolutionNoteId", evolutionNoteId);
    formData.append("FileComment", fileComment);
    return ApiClient.post(`${this.baseUrl}/uploadpatientclinicalfile`, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }).then((data) => new FileUploadResponse(data));
  }
  
  static async getPatientClinicalFiles(page: number, pageSize: number, customerId: string): Promise<Response> {
    return ApiClient.get(`${this.baseUrl}/getpatientclinicalfiles?customerId=${customerId}&pageSize=${pageSize}&page=${page}`)
      .then((data) => {
        let entity = data.patientClinicalFiles.map((item: any) => new CustomerClinicalFile(item));
        let total = data.total;
        if (data) {
          return new Response(entity, total);
        } else {
          return new Response();
        }
      });
  }
  
  static getPatientClinicalFileUrl(s3Key: string): Promise<Response> {
    return ApiClient.get(`${this.baseUrl}/getpatientclinicalfileurl?s3Key=${s3Key}`)
      .then((data) => {
        if (data) {
          return new Response(data.fileUrl);
        } else {
          return new Response();
        }
      });
  }
  
  static deletePatientClinicalFile(s3Key: string): Promise<Response> {
    return ApiClient.remove(`${this.baseUrl}/deletepatientclinicalfile?s3Key=${s3Key}`)
      .then((data) => {
        if (data) {
          return new Response(data);
        } else {
          return new Response();
        }
      });
  }
  
  static async getPatientClinicalFilePdf(customerId: string): Promise<any> {
    let endpoint = `${this.baseUrl}/getpatientclinicalfilepdf?customerId=${customerId}`
    
    return ApiClient.get(endpoint, {
      responseType: 'arraybuffer',
      headers:
        {
          'Content-Type': 'application/pdf'
        }
    }).then((data) => {
      return data;
    });
  }

  static async getPrivacyNotice(): Promise<{ privacyNotice: string, privacyNoticeEnabled: boolean }> {
    const data = await ApiClient.get(
      `${this.baseUrl}/getprivacynotice`
    );

    return data;
  }

  static async getInformedConsentLetter(): Promise<{ consentLetter: string, consentLetterEnabled: boolean }> {
    const data = await ApiClient.get(
      `${this.baseUrl}/getinformedconsentletter`
    );

    return data;
  }
  
  private static getJsonObjectValue = (x: any) => {
    switch (x.type) {
      case SectionFieldType.PhoneNumber:
        const phoneObj = JSON.parse(x.value);
        return new FormPhoneNumberValue(phoneObj);
      case SectionFieldType.Radio:
        const radioObj = JSON.parse(x.value);
        return new FormRadioValue(radioObj);
      default:
        return x.value;
    }
  };
}
