import React, { useState } from "react";

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { Button, Typography } from "@material-ui/core";

import AuthenticationLayout from "../AuthenticationLayout";
import useStyles from "./css";
import EmailInput from "../EmailInput";
import SuccessBlock from "../SuccessBlock";
import { AuthenticationService } from "../../../api/authentication-service";
import { useTranslation, Trans } from "react-i18next";
import { EmailRegex } from "../../../constants/validator";
import { useAppDispatch } from "../../../redux/hooks";
import { alertsActions } from "../../../redux/alerts-slice";
import { AxiosResponse } from "axios";


export default function Recovery() {
  
  const classes = useStyles();
  const { t } = useTranslation("general");
  
  const isEnterprise = window.location.hostname.includes("enterprise");

  const { executeRecaptcha } = useGoogleReCaptcha();
  
  const [email, setEmail] = useState<string>("");
  
  const [completed, setCompleted] = useState<boolean>(false);
  
  const dispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;
  
  
  const handleEmailChange = (event : any) => {
    setEmail(event.target.value);
  }

  function displayAlert(message: string) {
    dispatch(
      enqueueAlert({
        type: "Error",
        title: t("Error"),
        description: message
      })
    );
  }

  function validate() {
    return EmailRegex.test(email);
  }
  
  async function submitEmail() {
    if (!validate()) {
      displayAlert(t("Please enter valid e-mail."));
      return;
    }

    if (!executeRecaptcha) {
      console.log("no recaptcha");
      return;
    }
    const token = await executeRecaptcha('recovery');
    if (!token) return;
    
    if (isEnterprise) {
      AuthenticationService.recoverPasswordEnterprise(email, token)
        .then(
          () => setCompleted(true),
          () => setCompleted(true)
        );
    } else {
      AuthenticationService.recoverPassword(email, token)
        .then(
          () => setCompleted(true),
          () => setCompleted(true)
        );
    }
  }
    
  
  return (
    <AuthenticationLayout 
      hiddenLogo
      backButton
    >
      
      <div className={classes.form}>
        <Typography className={classes.formHeader}>
          {t("Did you forget your password?")}
        </Typography>
        
        <div className={classes.formText}>
          <Typography className={classes.formTextLine}>
            {t("To reset your password, ")}<span className={classes.formTextLineBold}>{t("enter your email address.")}</span> {t("You may need to check your spam folder.")}
          </Typography>
        </div>
        
        {completed ? 
          <SuccessBlock 
            headerMessage={t("Success!")}
            textMessage={<Trans i18nKey="IfAccountExistsEmailWillBeSent" t={t} values={{ email }}/>}
            blockHeight={220}
          />
          : 
          <>
            <EmailInput 
              value={email} 
              onChangeHandler={handleEmailChange} 
              placeholder="usuario@clear.com"
              required
            />
    
            <Button
              className={classes.sendButton}
              color="primary"
              variant="contained"
              onClick={submitEmail}
              disabled={email.length === 0}
            >
              {t("Send")}
            </Button>
          </>
        }
      </div>
    </AuthenticationLayout>
  )
}