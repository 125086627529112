import React from 'react';

import { InputBase } from "@material-ui/core";

import { ClinicalHistoryFreeTextInputProps } from "./props";
import useStyles from "./css";


export default function ClinicalHistoryFreeTextInput(props: ClinicalHistoryFreeTextInputProps) {
  
  const { value, setValue, placeholder, width, disabled, onBlur, maxLength } = props;
    
  const classes = useStyles(width)();
  
  function handleOnChange(event: any) {
    setValue(event.target.value);
  }
  
  return (
    <div className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        onBlur={() => onBlur && onBlur(value)}
        disabled={disabled}
        multiline
        rows={3}
        inputProps={{
          className: classes.inputWidth,
          maxLength: maxLength
        }}
      />
        <div className={classes.counter}>{`${value?.length}/${maxLength}`}</div>

    </div>
  );
}