import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    ...theme.typography.txtBody1,
    marginBottom: 21
  }
  })
);

export default useStyles;