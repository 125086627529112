import React from "react";
import MuiRadio, { RadioProps } from "@material-ui/core/Radio";

import useStyles from "./css";

const Radio = (props: RadioProps) => {
  const classes = useStyles(props.style?.color)();

  return (
    <MuiRadio
      className={classes.root}
      disableRipple
      color="default"
      checkedIcon={
        <span className={`${classes.icon} ${classes.checkedIcon}`} />
      }
      icon={<span className={classes.icon} />}
      {...props}
    />
  );
};

export default Radio;
