export class FormPhoneNumberValue {
  body: string;
  countryIso: string;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.body = x.body;
      this.countryIso = x.countryIso;
    }
    else {
      this.body = "";
      this.countryIso = "";
    }
  }
}