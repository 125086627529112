import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";


const useStyles = makeStyles((theme) => ({
  root: {
    body: {
    ".ant-picker-dropdown": {
       zIndex: 99999999
      },
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
   
  },
  modalPaper: {
    zIndex: 100,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid white",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: "30px",
    width: 800,
    height: 600,
    "&:focus:not(:focus-visible)": {
      outline: "none",
    },
    "&:focus-visible": {
      outline: "none",
      border: "1px solid white",
    },
  },
  
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    margin: "auto"
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
  },
  modalTitle: {
    width: "100%",
  },
  modalTitleText: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#5C6477",
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  goBack: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  cancelConfirmation: {
    background: theme.colors.clearGreen,
    color: "#F6F6F6",
    "&:hover": {
      background: theme.colors.clearGreen,
    },
  },
  create: {
    background: "#36CE91",
    color: "#F6F6F6",
    "&:hover": {
      background: "#36CE91",
    },
  },
  createDisabled: {
    color: "#F6F6F6 !important",
    opacity: "0.4",
  },
  
  requiredText: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    marginLeft: 2,
    marginTop: 9,
  },
  requiredSymbol: {
    color: theme.colors.clearMain,
    fontWeight: "bold",
  },
  
  labelText: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    fontWeight: 700,
    marginLeft: 2,
    marginTop: 7,
  },

  selectStyle: {
    color: Color.gray5,
    fontWeight: "bold",
    fontSize: 12,
    height: 40,
    padding: "13px 0px 13px 12px",
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
    width: "100%",
    paddingRight: 10,
    backgroundColor: "#F6F6F6",
    cursor: "pointer",
    "& * .ant-picker-suffix, .ant-picker-clear": {
      display: "none",
    },
    "& * input": {
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: 12,
      fontStyle: "normal",
      color: Color.gray5,
      cursor: "pointer",
      "&::placeholder": {
        ...theme.typography.txtBody2,
        fontWeight: "normal",
        opacity: 0.8,
      }
    }
  },
  
  inputTextareaClass: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    fontWeight: "bold",
    "&::placeholder": {
        ...theme.typography.txtBody2,
        fontWeight: "normal",
        opacity: 0.8,
    },
  },
    
  labelNotes: {
    ...theme.typography.txtBody3,
    color: "#8F9AA4",
    fontWeight: 400,
    fontSize: 11,
    marginLeft: 2,
    marginTop: 7,
  },
  divClock: {
    position: "relative",
    right: 10,
    top: -32,
    float: "right"
  },
  
  dateRoot: {
    width: 36,
    height: 36,
    margin: "0 2px",
  },
  date: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey7,
    width: 36,
    height: 36,
    margin: "0 2px",
  },
  currentDate: {
    fontWeight: "bold",
    color: theme.colors.clearMain,
  },
  selectedDate: {
    fontWeight: "bold",
    background: theme.colors.clearMain,
    color: "#FFFFFF",
    "&:hover": {
      background: theme.colors.clearMain,
    },
  },
  stepCaption: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey6,
  },
  availableHours: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey6,
  },
  dateNotSelected: {
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: theme.colors.grey3,
  },
  dateSelected: {
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: theme.colors.clearMain,
  },
  ulSchedule: {
    flexWrap: "wrap",
    display: "flex",
    listStyle: "none",
    padding: 0,
  },
  liSchedule: {
    width: 116,
    height: 32,
    marginRight: 16,
    marginBottom: 16,
    borderRadius: 51,
    cursor: "pointer",
    display: "flex",
    flex: "0 0 30%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    ...theme.typography.buttons,
    fontWeight: "bold",
    color: theme.colors.clearMain,
    border: `1px solid ${theme.colors.clearMain}`,
    "&:nth-child(3n + 3)": {
      marginRight: 0,
    },
    "&:hover": {
      background: theme.colors.clearMain,
      color: theme.colors.grey1,
    },
  },
  liScheduleActive: {
    background: theme.colors.clearMain,
    color: theme.colors.grey1,
  },

  
  selectService: {
    ...theme.typography.txtBody2,
    background: theme.colors.grey1,
    backgroundColor: theme.colors.grey1,
    border: `1px solid ${theme.colors.grey2}`,
    borderRadius: 5,
    height: 40,
    padding: "0px 9px 0px",
  },
  selectRoot: {
    color: theme.colors.grey5,
    "&:focus": {
      backgroundColor: theme.colors.grey1,
    },
    "&.MuiSelect-select": {
      "&:focus": {
        borderRadius: 5,
      },
    },
  },
  selected: {
    fontWeight: "bold",
    color: theme.colors.grey5,
  },
  placeholder: {
    color: "#676767",
    opacity: "0.42",
    "&.Mui-disabled": {
      opacity: "0.42",
    },
  },
  itemRoot: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    height: 40,
    display: "flex",
    alignItems: "center",
  },
  itemSelected: {
    fontWeight: "bold",
    color: theme.colors.clearMain,
  },
  group: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey4,
    height: 40,
    display: "flex",
    alignItems: "center",
    "&.Mui-disabled": {
      opacity: 1,
    },
  },
  divFlag: {
    display: "inline-block",
    position: "relative",
    height: 20,
    width: 20,
    "& svg": {
        width: "20px !important", 
        height: "20px !important"
    }
  },
  divCode: {
    position: "relative",
    display: "inline-block",
    fontStyle: "normal",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "bold",
    width: 40,
    textAlign: "center"
  },
  divMobile: {
    display: "inline-block",
  }
  
}));

export default useStyles;
