import React from "react";
import clsx from 'clsx';
import { MutableRefObject, useEffect, useMemo, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';
import AppointmentSchedulerContent, {
    AppointmentSchedulerContentComponents,
    GetItemDataFunction,
    ItemComponent,
} from './Content';
import AppointmentSchedulerHeader, {
    AppointmentSchedulerHeaderProps,
    HeaderCellComponent,
} from './Header';
import AppointmentSchedulerTimeline, { StopComponent } from './TimeLine';
import {
    AppointmentSchedulerContext,
    AppointmentSchedulerInnerElementContextProvider,
    AppointmentSchedulerRootElementContext,
    KeyGetterFunction,
    getGroupKeyOffsetVariable,
    useAppointmentSchedulerContext,
} from './_util';
import styles from './css.module.css';

export { useAppointmentSchedulerContext };

type SchedulerScrollData = {
    scrollLeft: number;
    scrollTop: number;
    scrollTopMinutes: number;
    scrollBottomMinutes: number;
};

export type OnSchedulerScrollChangedHandler = (
    data: SchedulerScrollData,
    event: React.SyntheticEvent
) => void;

export type AppointmentSchedulerProps<I, G> = {
    groups: G[];
    items: I[];
    getItemKey: KeyGetterFunction<I>;
    getItemGroupKey: KeyGetterFunction<I>;
    getItemData: GetItemDataFunction<I>;
    getGroupKey: KeyGetterFunction<G>;
    components: {
        HeaderCellComponent: HeaderCellComponent<G>;
        ItemComponent: ItemComponent<I>;
        StopComponent: StopComponent;
    } & AppointmentSchedulerContentComponents<G> &
        Pick<AppointmentSchedulerHeaderProps<G>, 'CornerComponent'>;
    classes?: {
        root?: string;
        headerRoot?: string;
        timelineRoot?: string;
        contentRoot?: string;
    };

    range: number[];
    sizes: {
        columnWidth: number;
        headerHeight: number;
        timelineWidth: number;
        pixelsPerMinute: number;
    };
    stopsInterval: number;
    showTimezone: boolean;
    onScrollChanged?: OnSchedulerScrollChangedHandler;
    scrollControllerRef?:
        | MutableRefObject<SchedulerScrollController | null>
        | ((controller: SchedulerScrollController | null) => void);
};

function AppointmentScheduler<I, G>({
    items,
    getItemData,
    getItemGroupKey,
    getItemKey,
    getGroupKey,
    groups,
    range: [from, to],
    stopsInterval,
    showTimezone,
    sizes: { headerHeight, timelineWidth, columnWidth, pixelsPerMinute },
    components: {
        HeaderCellComponent,
        StopComponent,
        ItemComponent,
        ContentGridColumnComponent,
        ContentGridTdComponent,
        ContentInnerWrapperComponent,
        CornerComponent,
    },
    classes,
    onScrollChanged,
    scrollControllerRef,
}: AppointmentSchedulerProps<I, G>) {
    if (stopsInterval < 1) throw new Error('intervalInMinutes must be at least 1');

    const onScroll = useMemo(() => {
        if (!onScrollChanged) return undefined;

        return (event: React.SyntheticEvent) => {
            const el = event.target as HTMLElement;

            onScrollChanged!(
                {
                    scrollTop: el.scrollTop,
                    scrollLeft: el.scrollLeft,
                    scrollTopMinutes: el.scrollTop / pixelsPerMinute,
                    scrollBottomMinutes: (el.scrollTop + el.scrollHeight) / pixelsPerMinute,
                },
                event
            );
        };
    }, [onScrollChanged, pixelsPerMinute]);

    const [rootElement, setRootElement] = useState<HTMLDivElement | null>(null);
    const [contentGridRoot, setContentGridRoot] = useState<HTMLElement | null>(null);

    const [rootElementSize, setRootElementSize] = useState({ width: 0, height: 0 });

    const scrollController = useMemo(
        () =>
            contentGridRoot
                ? new SchedulerScrollControllerImpl(contentGridRoot, pixelsPerMinute)
                : null,
        [contentGridRoot, pixelsPerMinute]
    );

    useEffect(() => {
        if (scrollControllerRef && scrollController) {
            if (typeof scrollControllerRef === 'object')
                scrollControllerRef.current = scrollController;
            else scrollControllerRef(scrollController);
        }
    }, [scrollControllerRef, scrollController]);

    const { ref: resizeDetectorRef } = useResizeDetector<HTMLDivElement>({
        onResize(width, height) {
            setRootElementSize((v) => ({ height: height ?? v.height, width: width ?? v.width }));
        },
    });

    const cssProps = useMemo(() => {
        return Object.fromEntries(
            groups.map((g, i) => [
                getGroupKeyOffsetVariable(getGroupKey(g)),
                `max(${(100 * i) / groups.length}%, calc(var(--cmos-aps-column-size) * ${i}))`,
            ])
        ) as React.CSSProperties;
    }, [getGroupKey, groups]);

    const actualColumnWidth = Math.max(
        (rootElementSize.width - timelineWidth - 6) / Math.max(1, groups.length),
        columnWidth
    );

    const body = (
        <div
            ref={(el) => {
                setRootElement(el);
                resizeDetectorRef.current = el;
            }}
            onScroll={onScroll}
            className={clsx(styles.root, classes?.root)}
            style={
                {
                    '--cmos-aps-column-size': `${actualColumnWidth}px`,
                    '--cmos-aps-row-size': `${pixelsPerMinute * stopsInterval}px`,
                    '--cmos-aps-timeline-size': `${timelineWidth}px`,
                    '--cmos-aps-header-size': `${headerHeight}px`,
                    '--cmos-aps-columns-count': groups.length,
                    '--cmos-aps-pixels-per-minute': `${pixelsPerMinute}px`,
                    '--cmos-aps-viewport-width': `${rootElementSize.width - timelineWidth}px`,
                    '--cmos-aps-viewport-height': `${rootElementSize.height - headerHeight}px`,
                } as React.CSSProperties
            }
        >
            <AppointmentSchedulerHeader<G>
                component={HeaderCellComponent}
                getGroupKey={getGroupKey}
                groups={groups}
                classNameRoot={classes?.headerRoot}
                CornerComponent={CornerComponent}
            />
            <div className={styles.inner} style={cssProps}>
                <AppointmentSchedulerTimeline
                    component={StopComponent}
                    pixelsPerMinute={pixelsPerMinute}
                    to={to}
                    from={from}
                    showTimezone={showTimezone}
                    intervalInMinutes={stopsInterval}
                    classNameRoot={classes?.timelineRoot}
                />

                <AppointmentSchedulerContent<I, G>
                    pixelsPerMinute={pixelsPerMinute}
                    groups={groups}
                    intervalInMinutes={stopsInterval}
                    from={from}
                    to={to}
                    component={ItemComponent}
                    getItemData={getItemData}
                    getItemGroupKey={getItemGroupKey}
                    getItemKey={getItemKey}
                    getGroupKey={getGroupKey}
                    items={items}
                    classNameRoot={classes?.contentRoot}
                    ContentGridColumnComponent={ContentGridColumnComponent}
                    ContentGridTdComponent={ContentGridTdComponent}
                    ContentInnerWrapperComponent={ContentInnerWrapperComponent}
                    contentGridRootRef={setContentGridRoot}
                />
            </div>
        </div>
    );

    return (
        <AppointmentSchedulerRootElementContext.Provider value={rootElement}>
            <AppointmentSchedulerContext.Provider
                value={useMemo(
                    () => ({
                        pixelsPerMinute,
                        from,
                        to,
                        groups,
                        rowHeight: stopsInterval * pixelsPerMinute,
                        minColumnWidth: columnWidth,
                        timelineWidth,
                        headerHeight,
                        innerHeight: rootElementSize.height - headerHeight,
                        innerWidth: rootElementSize.width - timelineWidth,
                        columnWidth: actualColumnWidth,
                    }),
                    [
                        from,
                        to,
                        pixelsPerMinute,
                        groups,
                        stopsInterval,
                        columnWidth,
                        timelineWidth,
                        headerHeight,
                        rootElementSize,
                        actualColumnWidth,
                    ]
                )}
            >
                <AppointmentSchedulerInnerElementContextProvider>
                    {body}
                </AppointmentSchedulerInnerElementContextProvider>
            </AppointmentSchedulerContext.Provider>
        </AppointmentSchedulerRootElementContext.Provider>
    );
}

export default AppointmentScheduler;

export interface SchedulerScrollController {
    scrollAtCenterInMinutes(number: number): void;
    scrollAtTopInMinutes(number: number): void;
}

class SchedulerScrollControllerImpl implements SchedulerScrollController {
    private readonly _element: HTMLElement;
    private readonly _pixelsPerMinute: number;

    constructor(element: HTMLElement, pixelsPerMinute: number) {
        this._element = element;
        this._pixelsPerMinute = pixelsPerMinute;
    }
    scrollAtTopInMinutes(number: number): void {
        const top = Math.max(0, this._pixelsPerMinute * number);
        this._element?.scrollTo({
            top,
        });
    }
    scrollAtCenterInMinutes(number: number): void {
        const closure = this;
        setTimeout(function (){ // timeout is the workaround for _element is become fully rendered and clientHeight is correct
            const top = Math.max(0, closure._pixelsPerMinute * number - closure._element.clientHeight / 2);
            closure._element.scrollTo({
                top,
            });
        }, 0);
    }
}
