import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import { CrossIcon } from "../../../../../assets/icons";
import BroadcastIcon from "../../../../../assets/icons/BroadcastIcon";
import { ConfirmationModalProps } from "./props";
import useStyles from "./css";

const ConfirmationModal = (props: ConfirmationModalProps) => {
  
  const { t } = useTranslation(["general"]);
  const { open, title, body, okTextButton, cancelTextButton, onClose, onCancel, onConfirm } = props;
  const classes = useStyles();
  
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalYellow}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      
      <Fade in={open}>
        <div className={classes.modalPaperYellow}>
          <div className={classes.modalHeader}>
            <div className={classes.modalHeaderContent}>
              <div className={classes.modalCircle}>
                <BroadcastIcon/>
              </div>
            </div>
            <div className={classes.modalClose}>
                            <span className={classes.closeButton} onClick={onClose}>
                                <CrossIcon/>
                            </span>
            </div>
          </div>
          
          <div className={classes.modalContent}>
            
            <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`}>
              <Typography className={classes.modalTitleText}>
                {title}
              </Typography>
            </div>
            
            <div className={classes.separator}></div>
            
            <div className={classes.bodyContent}>
              {body}
            </div>
            
            <div className={classes.modalActions}>
              <Button className={`${classes.button} ${classes.btnCancel}`} onClick={onCancel}>
                {cancelTextButton ? cancelTextButton : t("Cancel")}
              </Button>
              <Button className={`${classes.button} ${classes.btnOk}`} onClick={onConfirm}>
                {okTextButton ? okTextButton : t("Ok")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default ConfirmationModal;
