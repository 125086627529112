import { ApiClient } from "./api-client";
import { AppointmentPaymentFormData } from "../models/appointment-payment-form-data";
import { OpenCashRegisterRequest } from "../models/open-cash-register-request";
import { CloseCashRegisterRequest } from "../models/close-cash-register-request";
import { PaymentMethodType } from "../models/enums/payment-method-type";
import { RegisterCashRegisterMovementRequest } from "../models/register-cash-register-movement-request";
import { CashRegisterStateModel } from "../models/interfaces/cash-register-state-model";
import { Appointment } from "../models/appointment";


export class PaymentsService {
  private static basePaymentsUrl: string = "/payments";
  private static baseCashRegisterUrl: string = "/cashRegister";

  static getPaymentFormData(appointmentId: string | number): Promise<AppointmentPaymentFormData> {
    return ApiClient
      .get(`${this.basePaymentsUrl}/getPaymentFormData?AppointmentId=${appointmentId}`)
  }
  
  static getRecurringAppointmentPaymentFormData(
    appointmentId: string | number,
    recurredAppointmentsIds: string[],
    recurringApptTotalToPaid: number): Promise<AppointmentPaymentFormData> {
    const request = {
      currentAppointmentId: appointmentId,
      recurringAppointmentIds: recurredAppointmentsIds,
      recurringApptTotalToPaid: recurringApptTotalToPaid
    };
    return ApiClient.post(`${this.basePaymentsUrl}/GetRecurringAppointmentPaymentFormData`, request).then((data) => {
      return new AppointmentPaymentFormData(data);
    });
  }

  static getInitialCash(): Promise<number> {
    return ApiClient
      .get(`${this.baseCashRegisterUrl}/getInitialCash`)
      .then(data => data.initialCash)
  }

  static getCashRegisterCurrentState(paymentMethods: PaymentMethodType[]): Promise<CashRegisterStateModel> {
    return ApiClient.post(`${this.baseCashRegisterUrl}/getCashRegisterCurrentState`, { paymentMethods })
      .then(data => {
        return {
          isOpen: data.isOpen,
          state: data.state,
          history: data.history
        }
      });
  }

  static registerMovement(request: RegisterCashRegisterMovementRequest): Promise<void> {
    return ApiClient.post(`${this.baseCashRegisterUrl}/registerMovement`, request);
  }

  static openCashRegister(cashOpeningInfo: OpenCashRegisterRequest): Promise<void> {
    return ApiClient.post(`${this.baseCashRegisterUrl}/openCashRegister`, cashOpeningInfo);
  }

  static closeCashRegister(closeCashRegisterRequest: CloseCashRegisterRequest): Promise<void> {
    return ApiClient.post(`${this.baseCashRegisterUrl}/closeCashRegister`, closeCashRegisterRequest);
  }
}
