import React from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import { CrossIcon, StopHandIcon } from "../../../assets/icons";

import useStyles from "./css";
import { UnblockCustomerModalProps } from "./props";

const UnblockCustomerModal = (props: UnblockCustomerModalProps) => {
  const { open, customerName, onClose, onContinue } = props;
  const classes = useStyles();
  const { t } = useTranslation(["general"]);


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalYellow}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalPaperYellow}>
          <div className={classes.modalHeader}>
            <div className={classes.modalClose}>
              <CrossIcon
                className={classes.closeButton}
                onClick={onClose}
                viewBox="0 0 14 14"
                style={{ width: 14, height: 14 }}
              />
            </div>
            <div className={classes.modalHeaderContent}>
              <div className={classes.modalCircle}>
                <StopHandIcon />
              </div>
            </div>
          </div>
          <div className={classes.modalContent}>
            {isNaN(+customerName) &&
              <>
                <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`}>
                  <Typography className={classes.modalTitleText}>
                    {t("Unblock this customer?")}
                  </Typography>
                </div>
                <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`}>
                  <Typography className={classes.modalSubTitleText}>
                    {customerName}
                  </Typography>
                </div>
              </>
            }
            {!isNaN(+customerName) &&
              <>
                <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`}>
                  <Typography className={classes.modalTitleText}>
                    {t("Unblock")}&nbsp;
                    +{customerName}?
                  </Typography>
                </div>
              </>
            }
            <div className={classes.modalCenter}>
              <div className={classes.separator} style={{ width: 217 }}></div>
            </div>
            <div className={classes.modalCenter}>
              <Typography className={classes.modalContentText}>
                {isNaN(+customerName)
                  ? t("If you proceed, you will be able to send and receive messages from this customer. You can block the customer again at any time.")
                  : t("If you proceed, you will be able to send and receive messages from this number. You can block the number again at any time.")}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={onClose}
              >
                {t("Go back")}
              </Button>
              <Button
                className={`${classes.button} ${classes.continue}`}
                onClick={onContinue}
              >
                {t("Unblock")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default UnblockCustomerModal;
