import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },

    component: {
        zIndex: 2,
        position: "relative",
        left: 0
    },
    labelCategory: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        color: "#919CA5"
    },
    required: {
        color: "#6462F3",
        fontFamily: "Roboto, sans-serif"
    },
    optional: {
        fontWeight: "normal",
    },
    divDropdown: {
        cursor: "pointer",
        width: 350,
        display: "block",
        marginTop: -7,


    },
    inputSelectBtn: {
        cursor: "pointer",
        width: 300,
        pointerEvents: "none"

    },
    iconDropdown: {
        cursor: "pointer",
        position: "relative",
        top: -24,
        textAlign: "right",
        right: 2
    },
    divModalSelect: {
        backgroundColor: "#FFFFFF",
        padding: "0px 0 20px 0", 
        border: "1px solid #DBDCDD",
        borderRadius: 5,
        boxShadow: "0px 4px 4px rgba(166, 166, 166, 0.25)",
        width: 350,
        textAlign: "center",
        position: "fixed"
    },
    listCategoryScroll: {
        maxHeight: 200,
        overflowY: "scroll"
    },
    listCategory: {
        padding: "5px 0",
        "&:first-child": {
            paddingTop: 10,
        },
        borderBottom: "1px solid #F6F6F6",
        marginBottom: 0,
        //maxHeight: 200,
        //overflow: "hidden",
        //overflowY: "auto"
    },
    name: {
        textAlign: "left",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        width: "75%",
        overflow: "hidden",
        display: "inline-block",
        paddingTop: "5px",
        height: 40
    },
    button: {
        width: 300,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        color: "#786EFF",
        border: "1px solid #786EFF",

    },
    btnNewBlockedReason: {
    },
    elementList: {
        height: 32,
        padding: "0px 30px 10px 30px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        display: "flex",
        color: theme.colors.grey5,

    },
    divInputEdit: {
        margin: "auto",
        "& .MuiInputBase-root": {
            height: 32
        }
    },
    input: {
        marginLeft: "auto",
        "& input": {
            width: 250,
        }
    },
    iconPlus: {
        border: "none",
        backgroundColor: "transparent",
        position: "relative",
        top: -30,
        left: 130,
        textAlign: "center",
        verticalAlign: "middle",
        padding: 0,
        color: "#6462F3",
        "&:focus": {
            outline: "none"
        }
    },

    iconSave: {
        border: "none",
        backgroundColor: "transparent",
        position: "relative",
        top: -30,
        left: 130,
        textAlign: "center",
        verticalAlign: "middle",
        padding: 0,
        color: "#6462F3",
        "&:focus": {
            outline: "none"
        }
    },
    divItemEdit: {
        height: 32,
        display: "block"
    },
    btnInputNew: {
        height: 32,
        "& .MuiInputBase-root": {
            height: 32
        }
    },
    classRow: {
        cursor: "pointer",
        "& button": {
            visibility: "hidden",
        },
        "&:hover": {
            "& button": {
                visibility: "visible"
            }
        }
    },
    btnRow: {
        width: 32,
        height: 32,
        borderRadius: 51,
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        textTransform: "none",
        border: "none",
        padding: 0,
        margin: 0,
        backgroundColor: "transparent",
        "&:focus": {
            outline: "none"
        }
    },
    deleteCategory: {
        color: theme.colors.grey4,
        width: 13,
        height: 17,
        padding: 0,
    },
    editCategory: {
        color: theme.colors.grey4,
        width: 13,
        height: 17,
        padding: 0,
    },
    divIconsRow: {
        right: 0,
        textAlign: "center",
        marginLeft: "auto",
        float: "right",
        paddingTop: 0

    },
    divNew: {
        marginTop: 15,
    },
    divTitle: {
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 12,
        display: "flex",
        alignItems: "center",
        color: theme.colors.grey4,
        marginLeft: 25,
        marginTop: 10
    }

}));

export default useStyles;
