import React, { useState } from "react";

import { DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";

import { DateIconProps } from "./props";
import {CalendarIcon2} from "../../../assets/icons";
import useStyles from "./css";
import {useAppSelector} from "../../../redux/hooks";
import IconButton from "@material-ui/core/IconButton";
import Popover from "@material-ui/core/Popover";


export default function DateIcon(props: DateIconProps) {

  const {
    value,
    disabled,
    minDate,
    maxDate,
    onChange,
  } = props;

  const classes = useStyles()();
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const open = Boolean(anchorEl);

  const getId = (): string => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  const [inputId] = useState<string>(getId());
  const id = open ? inputId : undefined;

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOnChange = (event: any) => {
    handleClose();
    if (onChange) {
      onChange(event);
    }
  };

  const getLocale = (): any => {
    switch (localeApp) {
      case "es":
        return esLocale;

      default:
        return enLocale;
    }
  };

  return (
    <div className={classes.root}>
      <IconButton
        aria-describedby={id}
        aria-haspopup={true}
        className={classes.dateIcon}
        onClick={handleOpen}>
        <CalendarIcon2 />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        className={classes.datePicker}
      >
        <MuiPickersUtilsProvider
          utils={DateFnsUtils}
          locale={getLocale()}
        >
          <DatePicker
            autoOk
            disableToolbar
            variant="static"
            openTo="date"
            onChange={handleOnChange}
            disabled={disabled ?? false}
            value={value || null}
            minDate={minDate}
            maxDate={maxDate}
          />
        </MuiPickersUtilsProvider>
      </Popover>
    </div>
  );
}