import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    "@global": {
        ".ant-picker-time-panel-cell-disabled": {
            display: "none"
        },
    },
    root: {},
    title: {
        fontFamily: "Roboto",
        fontSize: "12px",
        color: "#919CA5",
        margin: 0,
    },
    required: {
        color: "#6462F3",
    },

    cropArea: {
        border: "none!important",
        background: `linear-gradient(to right, #e5e5e5 3px, transparent 3px) 0 0,
                 linear-gradient(to right, #e5e5e5 3px, transparent 3px) 0 100%,
                 linear-gradient(to left, #e5e5e5 4px, transparent 3px) 100% 0,
                 linear-gradient(to left, #e5e5e5 4px, transparent 3px) 100% 100%,
                 linear-gradient(to bottom, #e5e5e5 3px, transparent 3px) 0 0,
                 linear-gradient(to bottom, #e5e5e5 3px, transparent 3px) 100% 0,
                 linear-gradient(to top, #e5e5e5 3px, transparent 3px) 0 99.7%,
                 linear-gradient(to top, #e5e5e5 3px, transparent 3px) 100% 99.7%`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: '30% 20%',
    },
    servicePhotoButtonContainer: {
        display: "flex",
        marginTop: 8,
        justifyContent: "end"
    },
    cropModalButtonContainer: {
        display: "flex",
        justifyContent: "center"
    },
    saveCropButton: {
        width: 270,
        height: 32,
        borderRadius: 5,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        lineHeight: "15px",
        background: "#36CE91",
        color: "#F6F6F6",
        "&:hover": {
            background: "#36CE91",
        },
        "&.Mui-disabled": {
            color: "#F6F6F6",
            opacity: 0.3,
        },
    },
    goBackButton: {
        width: 270,
        height: 32,
        borderRadius: 5,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        lineHeight: "15px",
        marginRight: 16,
        background: "#F6F6F6",
        color: "#ACB7C0",
    },
    cropSlider: {
        color: "#ACB7C0",
        '& .MuiSlider-thumb': { 
            width: 25, 
            height: 25,
            marginTop: -10
            
        },
    },
    crossIconContainer: { 
        position: "absolute", 
        right: 8,
        top: 15,
        zIndex: 99,
        cursor: "pointer"
    },
    servicePhotoTooltipBlock: {
        [`& .MuiTooltip-tooltip`]: {
            padding: "4px 8px",
            borderRadius: "5px",
            backgroundColor: theme.colors.grey1,
            fontFamily: "Roboto, sans-serif",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "12px",
            color: theme.colors.grey5,
            boxShadow: "0px 0px 4px #00000034",
            position: "absolute",
            height: "auto",
            width: "max-content"
        },
    },
    cropSliderContainer: {
        display: "flex",
        alignItems: "center",
        gap: 21,
        marginLeft: 33,
        marginRight: 33,
        marginBottom: 42
    },
    cropInternalContainer: {
        height: "83%",
        backgroundColor: "black",
    },
    modalCropPaper: {
        backgroundColor: theme.palette.background.paper,
        borderRadius: "12px",
        boxShadow: theme.shadows[5],
        padding: "29px 24px 47px 24px",
        width: 736,
        height: 594,
        position: "absolute"
    },
    modalCropContainer: {
        margin: "24px 0px 8px 0px",
        height: "95%",
        display: "flex",
        flexDirection: "column-reverse",
        overflow: "hidden",
    },
    servicePhotoHeaderIcon: {
        marginLeft: 10,
        display: "flex",
        width: 20,
        height: 20,
    },
    servicePhotoHeaderButtons: {
        cursor: "pointer",
        color: "#919CA5",
        margin: "0px auto"
    },
    cropContainerBlackBox: {
        marginLeft: "auto",
        marginRight: "auto",
        background: "black",
        height: "108%",
        padding: "23px",
        width: "95%",
        marginTop: "-20px",
    },
    cropContainer: {
        position: "absolute",
        overflow: "hidden",
        top: 80,
        left: 0,
        right: 0,
        bottom: 80,
        marginLeft: 50,
        marginRight: 50,
        padding: 10
    },
    deleteImageModalContentText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        textAlign: "center",
        color: "#26292B",
    },
    deleteImageModalCenter: {
        display: "flex",
        justifyContent: "center",
    },
    modalDeleteImage: {
        height: 206
    },
    optional: {
        fontWeight: "normal",
    },
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",

        marginTop: 24,

        maxWidth: 813,
    },
    switch: {
        display: "flex",
        alignItems: "center",
    },
    switchLabel: {
        margin: "0px 0px 0px 16px",
    },
    label: {
        ...theme.typography.txtBody2,
        margin: 0,
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "bold",
        color: theme.colors.grey4,
        display: "block",
        marginBottom: "0.5rem",
    },
    description: {
        margin: 0,
        color: "#919CA5",
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "normal",
    },
    button: {
        width: 200,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #dbdcdd",
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: "40px 40px 48px",
        width: 812,
        height: 399,
    },
    modalHeader: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        color: "#000",
        textAlign: "center",
        fontFamily: "Roboto",
        fontSize: "24px",
        fontStyle: "normal",
        fontWeight: 700,
        lineHeight: "28px",
    },
    modalTitle: {
        width: "100%",
    },
    modalTitleText: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#5C6477",
    },
    modalActions: {
        display: "flex",
        justifyContent: "flex-end",
    },
    goBack: {
        marginRight: 16,
        background: "#F6F6F6",
        color: "#ACB7C0",
    },
    createCustomer: {
        background: "#36CE91",
        color: "#F6F6F6",
        "&:hover": {
            background: "#36CE91",
        },
    },
    createDisabled: {
        color: "#F6F6F6 !important",
        opacity: "0.4",
    },
    divUploadPhoto: {
        width: 228,
        height: 127,
        backgroundColor: theme.colors.grey1,
        border: "dashed",
        borderWidth: 1,
        borderColor: "#DBDCDD",
        borderRadius: 5,
        cursor: "pointer",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
        display: "grid"
    },
    colPhoto: {
        width: 228
    },
    colNotes: {
        width: 557,
        "& input": {
            height: 190,
            backgroundColor: "#F6F6F6",
            boxSizing: "border-box",
            borderRadius: 5,
            padding: "13px 0px 13px 12px",
            border: "solid 1px rgba(0, 0, 0, 0.1)",
            width: 480,
            "&:focus": {
                outline: "none",
                boxShadow: 0,
                border: "solid 1px #6462F3",
            },
            "&::placeholder": {
                color: "#B5B9C1",
            },
        }
    },
    inputTextareaDescription: {
        height: 90,
        width: 480,

        padding: "13px 0px 13px 12px",

        boxSizing: "border-box",

        borderRadius: 5,
        border: "solid",
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.1)",

        backgroundColor: theme.colors.grey1,

        ...theme.typography.txtBody2,
        color: theme.colors.grey4,

        "&:focus": {
            outline: "none",
            boxShadow: 0,

            border: "solid",
            borderWidth: 1,
            borderColor: theme.colors.clearMain,
        },
        "&::placeholder": {
            color: "#B5B9C1",
        },
    },
    iconTooltipRoot: {
        display: "inline-block"
    },
    inputTextareaNotes: {
        height: 90,
        width: 813,

        padding: "13px 0px 13px 12px",

        boxSizing: "border-box",
        
        overflow: "auto!important",

        borderRadius: 5,
        border: "solid",
        borderWidth: 1,
        borderColor: "rgba(0, 0, 0, 0.1)",

        backgroundColor: theme.colors.grey1,

        ...theme.typography.txtBody2,
        color: theme.colors.grey4,

        "&:focus": {
            outline: "none",
            boxShadow: 0,

            border: "solid",
            borderWidth: 1,
            borderColor: theme.colors.clearMain,
        },
        "&::placeholder": {
            color: "#B5B9C1",
        },
    },
    iconPlus: {
        marginTop: 40,
        height: 25,
        textAlign: "center",
        color: theme.colors.clearMain
    },
    labelAddPhoto: {
        marginTop: 0,
        fontWeight: "bold",
        fontSize: 12,
        textAlign: "center",
        color: "#919CA5"
    },
    labelAddPhotoIns: {
        marginTop: -5,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        color: "#919CA5",
        textAlign: "center",
    },
    divPhotoInfo: {
        width: "100%",
        display: "inline",
    },
    divPhotoInfoIcon: {
        display: "inline",
        width: 20,
        height: 20,
        textAlign: "center",
        position: "relative",
        top: 4,
        left: 2
    },
    divPhotoInfoLabel: {
        display: "inline",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 11,
        color: "#919CA5",
        opacity: "0.8",
        position: "relative",
        left: -5
    },
    divPrice: {
        height: 62,
    },
    moneyIcon: {
        position: "relative",
        top: -30,
        right: -222,
        color: "#5C6477",
        width: 15,
        backgroundColor: theme.colors.grey1
    },
    divCategory: {
        width: 255,
        height: 62,
    },
    divPhotoExistHover: {
        width: 228,
        height: 127,
        borderRadius: 5,
        opacity: 0,
        top: 0,
        position: "relative",
        "&:hover": {
            background: "rgba(29, 32, 39, 0.7)",
            display: "flow-root",
            opacity: 0.7
        }
    },
    iconCam: {
        marginTop: 40,
        height: 25,
        textAlign: "center",
        color: "#FFFFFF",
    },
    labelChangePhoto: {
        marginTop: 5,
        fontWeight: "bold",
        fontSize: 12,
        textAlign: "center",
        color: "#FFFFFF",
        opacity: 1
    },
    selectStyle: {
        color: theme.colors.grey5,
        fontWeight: "bold",
        fontSize: 12,
        height: 40,
        padding: "13px 0px 13px 12px",
        border: "solid 1px rgba(0, 0, 0, 0.1)",
        borderRadius: 5,
        width: 255,
        paddingRight: 10,
        backgroundColor: "#F6F6F6",
        cursor: "pointer",
        "& * .ant-picker-suffix, .ant-picker-clear": {
        display: "none",
        },
        "& * input": {
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 12,
        fontStyle: "normal",
        color: theme.colors.grey5,
        cursor: "pointer",
        },
    },
    selectHrs: {
        "& div:after": {
        content: "'hrs'",
        marginLeft: "35px",
        marginBottom: "2px",
        position: "absolute",
        color: theme.colors.grey5,
        },
    },
    selectHr: {
        "& div:after": {
        content: "'hr'",
        marginLeft: "35px",
        marginBottom: "2px",
        position: "absolute",
        color: theme.colors.grey5,
        },
    },
    selectMin: {
        "& div:after": {
        content: "'min'",
        marginLeft: "35px",
        marginBottom: "2px",
        position: "absolute",
        color: theme.colors.grey5,
        },
    },
    selectStyleHrs: {
        position: "relative",
        top: 1,
        left: -150,
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 12,
        fontStyle: "normal",
    },
}));

export default useStyles;
