import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import Switch from "../../common/Switch";
import { CustomerEntity } from "../../../models/customer-entity";

import useStyles from "./css";
import { CustomerNotificationsProps } from "./props";

import useCustomerTerminology from "../../../hooks/useCustomerTerminology";
import { Typography } from "@material-ui/core";


const CustomerNotifications = (props: CustomerNotificationsProps) => {
  const { t } = useTranslation(["general"]);
  const { value, isEdition, onChange } = props;
  const [customer, setCustomer] = useState<CustomerEntity>(
    value || new CustomerEntity()
  );

  const appointmentDescription = useCustomerTerminology(t("The customer will receive automatic notifications such as: Appointments, reminders, etc."), "customer");
  const massiveSendingsDescription = useCustomerTerminology(t("The customer will receive automated Massive sending messages."), "customer");

  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(customer)) {
      setCustomer(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (onChange) {
      onChange(customer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const handleContactableAppointmentsChange = (
    event: React.ChangeEvent<{ checked: unknown }>
  ) => {
    setCustomer(
      new CustomerEntity({ ...customer, contactableAppointments: event.target.checked })
    );
  };

  const handleContactableMassiveSendingsChange = (
    event: React.ChangeEvent<{ checked: unknown }>
  ) => {
    setCustomer(
      new CustomerEntity({ ...customer, contactableMassiveSendings: event.target.checked })
    );
  };


  const classes = useStyles();
  return (
    <div className={classes.root}>
      {isEdition ?
        <>
          <div className={classes.rowContainer}>
            <div className={classes.switch}>
              <Switch
                onChange={handleContactableAppointmentsChange}
                value={customer?.contactableAppointments}
              />
              <div className={classes.switchLabel}>
                <label className={classes.label}>
                  {t("Appointment")}{" "}
                </label>
                <p className={classes.description}>
                  {appointmentDescription}
                </p>
              </div>
            </div>
          </div>
          <div className={classes.rowContainer}>
            <div className={classes.switch}>
              <Switch
                onChange={handleContactableMassiveSendingsChange}
                value={customer?.contactableMassiveSendings}
              />
              <div className={classes.switchLabel}>
                <label className={classes.label}>
                  {t("Massive sendings")}{" "}
                </label>
                <p className={classes.description}>
                  {massiveSendingsDescription}
                </p>
              </div>
            </div>
          </div>
        </> :
        <>
          <div className={classes.infoContentRow}>
            <div>
              <Typography className={classes.caption}>
                {t("Appointment")}
              </Typography>
              <Typography className={classes.captionValue}>
                {customer.contactableAppointments ? t("Yes") : t("No")}
              </Typography>
            </div>
            <div>
              <Typography className={classes.caption}>
                {t("Massive sendings")}
              </Typography>
              <Typography className={classes.captionValue}>
                {customer.contactableMassiveSendings ? t("Yes") : t("No")}
              </Typography>
            </div>
            <div><div className={classes.caption}></div></div>
          </div>
        </>}
    </div>
  );
};

export default CustomerNotifications;
