import React, { useEffect, useState } from 'react';

import Draggable from 'react-draggable';

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { fileDownloadActions } from "../../../redux/file-downloader-slice";
import { alertsActions } from "../../../redux/alerts-slice";

import useStyles from "./css";
import { CrossIcon, DownloadCloudIcon, DraggableIcon } from "../../../assets/icons";
import { Color } from "../../../constants/colors";
import { Position } from "../../../models/interfaces/position";
import axios from "axios";


export default function FileDownloadProvider() {

  const state = useAppSelector(store => store.fileDownloader);
  const {
    setProgress,
    finishDownload,
    cancelDownload
  } = fileDownloadActions;
  const { enqueueAlert } = alertsActions;

  const dispatch = useAppDispatch();

  const { downloadProgress, fileName, mimeType, isDownloading, callback } = state;

  const { t } = useTranslation("general");
  const classes = useStyles(downloadProgress)();

  const [position, setPosition] = useState<Position>({ x: 0, y: 0 })
  const cancelTokenSource = axios.CancelToken.source();

  function dispatchProgress(progress: number) {
    dispatch(setProgress(progress))
  }

  function onDownloadFinished() {
    dispatch(finishDownload())
  }

  function onDownloadCanceled() {
    cancelTokenSource.cancel()
    dispatch(
      enqueueAlert({
        type: "Error",
        title: t("Download canceled")
      }));
  }

  function onDragStop(event: any, data: any) {
    const { x, y } = data;
    setPosition({ x, y })
  }

  useEffect(() => {
    if (callback === null) return;

    callback(dispatchProgress, cancelTokenSource.token).then(blob => {
      const url = window.URL.createObjectURL(new Blob([blob], {
        type: mimeType
      }));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `${fileName}`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      dispatch(
        enqueueAlert({
          type: "Success",
          title: t("Downloaded report"),
          description: fileName
        }));
    })
    .finally(() => onDownloadFinished())
  }, [callback])

  return (
    isDownloading
      ?
      <Draggable
        position={position}
        enableUserSelectHack={false}
        onStop={onDragStop}
        handle={'#modalDragHandler'}
      >
        <div
          className={classes.root}
          style={{ zIndex: 7000 }}
        >
          <div className={classes.side}/>
          <div className={classes.container}>

            <div className={classes.header}>

              <div
                className={classes.dndIconContainer}
                id={'modalDragHandler'}
              >
                <DraggableIcon style={{ width: 16, height: 16, color: Color.gray4, cursor: "grab" }}/>
              </div>

              <div className={classes.closeIconContainer}>
                <div className={classes.closeIcon} onClick={onDownloadCanceled}>
                  <CrossIcon style={{ width: 12, height: 12 }} color={Color.gray4} viewBox="0 0 16 16"/>
                </div>
              </div>

            </div>

            <div className={classes.contentContainer}>
              <div className={classes.mainIconContainer}>
                <div className={classes.mainIconBackground}>
                  <DownloadCloudIcon style={{ width: 24, height: 24 }} color={Color.white} viewBox="0 0 24 24"/>
                </div>
              </div>
              <div className={classes.content}>
                <div className={classes.title}>{t("Downloading in progress!")}</div>
                <div className={classes.text}>{t("YouCanContinueUsingOtherScreens")}</div>

                <div className={classes.downloadProgressBar}>
                  <span
                    className={downloadProgress > 0 ? classes.downloadProgressBarProgress : classes.downloadProgressBarEmpty}/>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Draggable>
      : null
  )
}