import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  divider: {
    backgroundColor: "rgba(201, 205, 211, 0.5) !important",
    marginTop: "12px !important",
    marginBottom: "18px !important"
  },
  switchBox: {
    display: "flex",
    marginLeft: 12,
    "&>label": {
      marginBottom: 0,
      marginRight: 5
    }
  },
  tooltip: {
    "&>div[role=tooltip]": {
      marginTop: -22
    }
  },
  sectionRow: {
    display: "flex",
    alignItems: "center"
  },
  sectionLabel: {
    color: theme.colors.grey4,
    fontFamily: "Inter, sans-serif",
    fontWeight: 700,
    fontSize: "14px",
    marginLeft: 10
  },
  radios: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    gap: 10,
    marginBottom: 20
  },
  radio: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    "&>div:first-child>div": { // label
      color: theme.colors.grey5,
      opacity: 0.5,
      fontFamily: "Inter, sans-serif",
      fontWeight: 700,
      fontSize: "14px",
      marginRight: 5
    }
  },
  radioContent: {
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "capitalize"
    }
  },
  commissionsByServices: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap"
  },

  commissionByServiceItem: {
    width: "48%",
    marginRight: 10,
    height: 85
  },
  commissionByServiceValues: {
    display: "flex",
    gap: 10
  },
  serviceName: {
    fontFamily: "Inter, sans-serif",
    fontWeight: 700,
    fontSize: 12,
    color: "#919CA5",
    marginBottom: 4
  },
  contentHeader: {
    fontFamily: "Inter, sans-serif",
    fontWeight: 700,
    fontSize: 12,
    color: "#919CA5",
    marginBottom: 4,
    textTransform: "lowercase",
    "&:first-letter": {
      textTransform: "capitalize"
    }
  },
  defaultServices: {
    display: "flex",
    flexWrap: "wrap",
    gap: 15,
    marginBottom: 30
  },
  defaultServiceItem: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#F1F1F1",
    height: 32,
    borderRadius: 51,
    padding: "0 16px",
    fontFamily: "Inter, sans-serif",
    fontWeight: 700,
    fontSize: 12,
    color: "#919CA5",
  },
  dot: {
    ...theme.typography.txtBody2,
    color: theme.colors.clearMain,
    marginLeft: 5
  },
  defaultCommissionValues: {
    display: "flex",
    gap: 10,
    width: 357
  }
})
);

export default useStyles;