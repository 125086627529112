import React, { useState } from 'react';
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import { useDispatch } from 'react-redux';
import { authenticate } from "../../../redux/authentication-slice";
import { applicationInterfaceActions } from "../../../redux/application-interface-slice";
import { alertsActions } from "../../../redux/alerts-slice";

import { Button } from "@material-ui/core";

import useStyles from "./css";
import { AuthenticationService } from "../../../api/authentication-service";
import { ILoginState, ILoginValidationState } from "./props";
import { Url } from "../../../constants/url"
import AuthenticationLayout from "../AuthenticationLayout";
import EmailInput from "../EmailInput";
import PasswordInput from "../PasswordInput";
import { EmailRegex } from '../../../constants/validator';
import { AccountService } from "../../../api/account-service";


export default function Login() {

  const classes = useStyles();
  
  const history = useHistory();
  
  const isEnterprise = window.location.hostname.includes("enterprise");
  
  const dispatch = useDispatch();
  const { enqueueAlert } = alertsActions;

  const { t, i18n } = useTranslation(["general", "nav"]);

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [loginState, setLoginState] = useState<ILoginState>({
    email: "",
    password: ""
  });

  const [error, setError] = useState(null);
  const [validationState, setValidationState] = useState<ILoginValidationState>({
    emailError: true,
    passwordError: true
  });

  function handleEmailChange(event: any) {
    setLoginState({...loginState, email: event.target.value});
  }

  function handlePasswordChange(event: any) {
    setLoginState({...loginState, password: event.target.value});
  }

  function validate() {
    return EmailRegex.test(loginState.email) && loginState.password.length > 0;
  }

  function displayAlert() {
    dispatch(
      enqueueAlert({
        type: "Error",
        title: t("Error"),
        description: t('Incorrect email and/or password. Please try again.'),
      })
    );
  }
  
  async function submitLogin() {
    if (!validate()) {
      displayAlert();
      return;
    }

    if (!executeRecaptcha) {
      console.log("no recaptcha");
      return;
    }
    const token = await executeRecaptcha('login');
    if (!token) return;

    const { email, password } = loginState;

    function setLocaleAndRedirect(language: string){
      localStorage.setItem('locale', language);
      i18n.changeLanguage(language).finally(() => {
        dispatch(applicationInterfaceActions.updateLocale(language));
        dispatch(authenticate());
        history.push(Url.Appointments.Main);
      });
    }

    function requestLocaleAndRedirect(){
      AccountService.getAccountLanguage()
        .then(language => {
          setLocaleAndRedirect(language);
        })
        .catch((resp) => {
          const language = process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'es';
          setLocaleAndRedirect(language);
          return Promise.reject(resp);
        })
    }

    if (isEnterprise) {
      AuthenticationService.loginEnterprise(email, password, token).then(requestLocaleAndRedirect, displayAlert)
    } else {
      AuthenticationService.login(email, password, token).then(requestLocaleAndRedirect, displayAlert)
    }
  }

  const LogInButton =
    <Button
      className={classes.logInButton}
      color="primary"
      variant="contained"
      onClick={submitLogin}
      disabled={loginState.email.length === 0 || loginState.password.length === 0}
    >
      {t('Log in')}
    </Button>


  return (
    <AuthenticationLayout>
      <div className={classes.form}>
        <EmailInput
          value={loginState.email}
          placeholder={t("Enter your email address")}
          onChangeHandler={handleEmailChange}
        />
        <div className={classes.inputBox}>
          <PasswordInput
            value={loginState.password}
            label={t("Password")}
            placeholder={t("Enter your password here")}
            onChangeHandler={handlePasswordChange}
          />
        </div>
        <div className={classes.utilities}>
          <Link to="/recovery" className={classes.forgetLink}>{t('Forgot your password?')}</Link>
        </div>
        {LogInButton}
      </div>
    </AuthenticationLayout>
  );
}