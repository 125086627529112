import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionFieldEmailIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M19.6875 9.96875C18.9688 10.5312 18.0625 11.2188 14.875 13.5312C14.25 14 13.0938 15.0312 12 15.0312C10.875 15.0312 9.75 14 9.09375 13.5312C5.90625 11.2188 5 10.5312 4.28125 9.96875C4.15625 9.875 4 9.96875 4 10.125V16.5C4 17.3438 4.65625 18 5.5 18H18.5C19.3125 18 20 17.3438 20 16.5V10.125C20 9.96875 19.8125 9.875 19.6875 9.96875ZM12 14C12.7188 14.0312 13.75 13.0938 14.2812 12.7188C18.4375 9.71875 18.75 9.4375 19.6875 8.6875C19.875 8.5625 20 8.34375 20 8.09375V7.5C20 6.6875 19.3125 6 18.5 6H5.5C4.65625 6 4 6.6875 4 7.5V8.09375C4 8.34375 4.09375 8.5625 4.28125 8.6875C5.21875 9.4375 5.53125 9.71875 9.6875 12.7188C10.2188 13.0938 11.25 14.0312 12 14Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}