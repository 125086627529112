import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #dbdcdd",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: "16px 24px",
    width: 464,
    height: 227,
  },
  closeButton: {
    position: "absolute",
    width: 24,
    height: 24,
    top: 18,
    right: 28,
    color: theme.colors.grey4
  },
  content: {
    display: "flex",
    flexDirection: "column"
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    marginBottom: 16
  },
  modalHeader: {
    ...theme.typography.h4,
    fontFamily: "Inter",
    fontWeight: "bold",
    color: "#26292B",
    marginTop: 8
  },
  deleteIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 24,
    width: 50,
    height: 50,
    borderRadius: 25,
    borderColor: theme.colors.clearRed,
    color: theme.colors.clearRed,
    border: "1px solid"
  },
  divider: {
    width: 217,
    height: 0,
    marginTop: 16,
    border: "1px solid #E5E7EA",
  },
  itemName: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    marginTop: 16
  },
  modalFooter: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  goBack: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  deleteButton: {
    background: theme.colors.clearRed,
    color: "#FFFFFF",
    "&:hover": {
      background: theme.colors.clearRed,
    },
  },
  createDisabled: {
    color: "#F6F6F6 !important",
    opacity: "0.4",
  },
}));

export default useStyles;