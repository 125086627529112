import { ReportColumn } from "./report-column";

export class Report {
  id: string;
  name: string;
  properties: ReportColumn[];

  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.name = x.name;
      this.properties = x.properties;
    }
    else {
      this.id = "";
      this.name = "";
      this.properties = [];
    }
  }
}