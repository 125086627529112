import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ComputerIcon = (props: any) => {
    return (
        <SvgIcon {...props} viewBox="-3 -3 16 16">
           <path d="M8.58334 0.333008H1.08334C0.661461 0.333008 0.333336 0.676758 0.333336 1.08301V6.08301C0.333336 6.50488 0.661461 6.83301 1.08334 6.83301H4.08334L3.83334 7.58301H2.70834C2.48959 7.58301 2.33334 7.75488 2.33334 7.95801C2.33334 8.17676 2.48959 8.33301 2.70834 8.33301H6.95834C7.16146 8.33301 7.33334 8.17676 7.33334 7.95801C7.33334 7.75488 7.16146 7.58301 6.95834 7.58301H5.83334L5.58334 6.83301H8.58334C8.98959 6.83301 9.33334 6.50488 9.33334 6.08301V1.08301C9.33334 0.676758 8.98959 0.333008 8.58334 0.333008ZM8.33334 5.83301H1.33334V1.33301H8.33334V5.83301Z" />
        </SvgIcon>
    )
};

export default ComputerIcon;
