import React, { useEffect, useState } from "react";

import Carousel from "react-material-ui-carousel";

import { Backdrop, Fade, Modal, Tooltip, Typography } from "@material-ui/core";

import { CrossIcon } from "../../../assets/icons";
import { Message } from "../../../models/message";
import { useStyles } from "./css";
import { ConversationMessageType } from "../../../models/enums/conversation-message-type";
import Interweave from "interweave";


export default function ImageCarousel(props: any) {

    const classes = useStyles();
    const {
        messages,
        carouselIsOpen,
        closeCarousel,
        activeImageIndex,
        imagesCount,
        ...otherProps
    } = props;

    const [zoomedIn, setZoomedIn] = useState(false);
    const [activeChildIndex, setActiveChildIndex] = useState<number>(activeImageIndex);
    const [changedFromArrowKeys, setChangedFromArrowKeys] = useState(false);
    const carouselItems = getMappedObjects();
    const linkRegex = new RegExp("[Hh]ttps?:\\/\\/(www\\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\\.[a-z]{2,4}\\b([-a-zA-Z0-9@:%_\+.~#?&/=]*)");
    //HACK: need to prevent the second(and loop-like) Carousel rerender when using keys to navigate - it's a carousel package bug

    function encaseLinksWithTags(str: string) {
        if (str === null) return str;
        const matches = str.match(linkRegex);
        if (matches && matches.length > 0) {
            let newStr = str;
            newStr = newStr.replace(matches[0], `<a target="_blank" href="${matches[0]}">${matches[0]}</a>`)
            return newStr;
        } else {
            return str;
        }
    }

    useEffect(() => {
        setActiveChildIndex(activeChildIndex);
        document.addEventListener("keydown", changeChild);

        return function cleanup() {
            document.removeEventListener("keydown", changeChild);
        };
    }, []);

    const handleIndexChange = (curIndex: number, prevIndex: number) => {
        if (curIndex === prevIndex)
            return; //HACK: is described above
        if (changedFromArrowKeys) {  //HACK: is described above
            setChangedFromArrowKeys(false);
            return;
        }
        setActiveChildIndex(curIndex);
        setZoomedIn(false);
    };

    const handleZoom = () => {
        setZoomedIn(prev => !prev);
    };

    function getMappedObjects() {
        const mappedObjs = messages
            .filter((m: Message) =>
                m.originalFileName &&
                m.previewFileUrl &&
              (m.type === ConversationMessageType.Image || m.type === ConversationMessageType.Video))
            .map((m: Message) => ({ id: m.id, time: m.time, type: m.type, fileUrl: m.fileUrl!, originalFileName: m.originalFileName, text: m.text || "" }))
            .sort((o: { time: any; }) => o.time);

        return mappedObjs;
    };

    const changeChild = (e: KeyboardEvent) => {
        if (e.key === "ArrowLeft") {
            setChangedFromArrowKeys(true);
            setActiveChildIndex((i: number) => (i <= 0 ? i : i - 1));
        } else if (e.key === "ArrowRight") {
            setChangedFromArrowKeys(true);
            setActiveChildIndex((i: number) => (i >= imagesCount - 1 ? i : i + 1));
        }
        setZoomedIn(false);
    };

    function mapComponents() {
        return carouselItems.map(mapCarouselItem)
    }

    function mapCarouselItem(item: Message) {
        const { type, fileUrl, text, originalFileName } = item;
        const cleanText = text.replace(/<[^<>]+>/g, " ");
        if (type === ConversationMessageType.Video) {
            return (
              <>
                <div className={classes.mediaWrapper}>
                    <video
                        className={classes.carouselItem}
                        src={fileUrl}
                        controls
                    />
                </div>
                <div className={`${classes.indicatorContainer}`}>
                  <Tooltip title={(text && text !== originalFileName) ?
                      <Interweave content={(text && text !== originalFileName) ? encaseLinksWithTags(text) : ""} /> : ""}
                  >
                    <Typography className={`${classes.carouselItemText}`}>
                        <Interweave content={(text && text !== originalFileName) ? encaseLinksWithTags(text) : ""}/>
                    </Typography>
                  </Tooltip>
                </div>
              </>
            )
        }

        return (
          <>
            <div className={classes.mediaWrapper}>
                <img
                    src={fileUrl}
                    className={`${classes.carouselItem} ${classes.zoomInCursor} ${zoomedIn ? classes.zoomedImage : ""}`}
                    onClick={handleZoom}
                    alt="image"
                />
                </div>
 
            <div className={`${classes.indicatorContainer}`}>
              <Tooltip title={(text && text !== originalFileName) ? 
                  <Interweave content={(text && text !== originalFileName) ? encaseLinksWithTags(text) : ""} /> : ""}
              >
                <Typography className={`${classes.carouselItemText}`}>
                   <Interweave content={(text && text !== originalFileName) ? encaseLinksWithTags(text) : ""} />
              </Typography>
            </Tooltip>
            </div>

          </>
        );
    }

    function getIndicatorContainerProps(index: number) {
        const carouselItem = carouselItems[index];

        if (!carouselItem) return;

        const { type, text, originalFileName } = carouselItem;
        const showIndicator = !((type === ConversationMessageType.Image || type === ConversationMessageType.Video) && text && text !== originalFileName);

        return {
            className: showIndicator
                ? classes.indicatorContainer
                : classes.hidden
        };
    };

    return <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={carouselIsOpen}
            onClose={closeCarousel}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade
                in={carouselIsOpen}
            >
                <div className={classes.paper}>
                    <div
                        onClick={closeCarousel}
                        className={classes.exitButton}>
                        <CrossIcon className={classes.crossIcon} />
                    </div>
                    <Carousel
                        index={activeChildIndex}
                        onChange={handleIndexChange}
                        autoPlay={false}
                        indicators={true}
                        swipe={false}
                        cycleNavigation={false}
                        className={classes.carouselRoot}
                        navButtonsWrapperProps={{
                        }}
                        indicatorContainerProps={getIndicatorContainerProps(activeChildIndex)}
                        indicatorIconButtonProps={{
                            className: `${classes.indicatorBulletInactive}`
                        }}
                        activeIndicatorIconButtonProps={{
                            className: `${classes.indicatorBulletActive}`
                        }}
                    >
                        {mapComponents()}
                    </Carousel>
                </div>

            </Fade>
        </Modal>
    </>
}