import { SectionFieldType } from "./enums/section-field-type";

export class SectionFieldCreateRequest {
  establishmentAccountId?: string|null;
  sectionId: string | null;
  name: string;
  type: SectionFieldType;
  isRequired: boolean;
  isAdditionalTextareaEnabled: boolean;
  itemsNames: string[];

  constructor(x?: any) {
    if (typeof x === "object") {
      this.establishmentAccountId = x.establishmentAccountId;
      this.sectionId = x.sectionId;
      this.name = x.name;
      this.type = x.type;
      this.isRequired = x.isRequired;
      this.isAdditionalTextareaEnabled = x.isAdditionalTextareaEnabled;
      this.itemsNames = x.itemsNames;
    } else {
      this.establishmentAccountId = null;
      this.sectionId = null;
      this.name = "";
      this.type = 11;
      this.isRequired = false;
      this.isAdditionalTextareaEnabled = false;
      this.itemsNames = [];
    }
  }
}