import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import { InputLabel } from "@material-ui/core";

import { ArrowDropDownIcon, SearchIcon } from "../../../assets/icons";
import Input from "../Input";
import useDebounce from "../../../hooks/useDebounce";

import { SelectSearchItem, SelectSearchProps } from "./props";
import useStyles from "./css";


export default function SelectSearch(props: SelectSearchProps) {
  
  const { t } = useTranslation(["general"]);
  const classes = useStyles();
  
  const {
    items,
    itemId,
    placeholderSelect,
    placeholderInput,
    label,
    iconSelect,
    disabled,
    disableScrollLock,
    widthSelect,
    widthList,
    isOptional,
    onSelect
  } = props;
  
  const [list, setList] = useState<SelectSearchItem[]>(items);
  const [itemSelected, setItemSelected] = useState<SelectSearchItem>();
  const [search, setSearch] = useState<string>("");
  const [openModal, setOpenModal] = useState<boolean>(false);
  const debouncedSearchValue = useDebounce(search, 400);
  const dropdownRef = useRef<any>(null);
  const menuRef = useRef<any>(null);
  const [menuPlacementTop, setMenuPlacementTop] = useState<boolean>(false);
  
  const handleItemSelected = (i: SelectSearchItem) => {
    if (i.isGroup) return;
    setItemSelected(i);
    onSelect(i);
    setOpenModal(false);
    setSearch("");
  }
  
  useEffect(() => {
    setList([]);
    setItemSelected(undefined);
    if (items.length > 0) {
      setList(items);
      if (itemId) {
        const item = items.find(x => x.key === itemId);
        if (item) setItemSelected(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [items]);
  
  useEffect(() => {
    if (items.length > 0) {
      if (itemId) {
        const item = items.find(x => x.key === itemId);
        if (item) setItemSelected(item);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemId]);
  
  useEffect(() => {
    
    if (debouncedSearchValue) {
      const newList = items.filter(x => !x.isGroup && x.value.toLowerCase().includes(debouncedSearchValue.toLowerCase()));
      setList(newList);
    } else {
      setList(items);
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);
  
  useEffect(() => {
    if (openModal) {
      const menuHeight = menuRef.current.getBoundingClientRect().height;
      const bodyRect = document.body.getBoundingClientRect();
      const elemRect = dropdownRef.current.getBoundingClientRect();
      const offset = elemRect.top - bodyRect.top;
      const canOpenTop = offset >= menuHeight;
      let openTop = false;
      if (canOpenTop) {
        const windowHeight = window.innerHeight;
        if (windowHeight - elemRect.bottom < menuHeight) {
          if (elemRect.top >= menuHeight
            || elemRect.top > windowHeight - elemRect.bottom) {
            openTop = true;
          }
        }
      }
      setMenuPlacementTop(openTop);
    } else {
      setMenuPlacementTop(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openModal])
  
  const handleSearch = (event: React.ChangeEvent<{ name: string, value: string }>) => {
    let value = event.target.value;
    setSearch(value);
  }
  
  const handleOpenModal = () => {
    if (disabled) return;
    setSearch("");
    setOpenModal(true);
  }
  
  const handleCloseModal = () => {
    setSearch("");
    setOpenModal(false);
  }
  
  return (<div className={`${classes.component} ${menuPlacementTop ? classes.menuPlacementTop : ''}`}>
    
    <div className={classes.divDropdown} style={{ width: widthSelect || 350 }}>
      
      {label && <>
        <InputLabel className={`${classes.label}`}>
          {label + " "}
          {
            (isOptional ?? true) ?
              <span className={classes.optional}>({t("Optional")})</span>
              :
              <span className={classes.required}>*</span>
          }
        </InputLabel>
      </>}
      
      <div onClick={handleOpenModal} style={{ cursor: disabled ? "cursor" : "pointer" }} ref={dropdownRef}>
        <Input
          id={"itemSelect"}
          name={"itemSelected"}
          value={itemSelected?.value || ""}
          placeholder={placeholderSelect}
          width={widthSelect || 350}
          inputClass={classes.inputSelectBtn}
          style={{ cursor: disabled ? "cursor" : "pointer" }}
          isValid={() => isOptional ? isOptional : itemSelected?.value !== ""}
        />
      </div>
      
      <div className={classes.iconDropdown} onClick={handleOpenModal}
           style={{ cursor: disabled ? "cursor" : "pointer" }}>
        {iconSelect ??
          <ArrowDropDownIcon style={{ color: "#6462F3", transform: openModal ? "rotate(180deg)" : "rotate(0deg)" }}/>}
      </div>
    
    </div>
    
    <Modal
      open={openModal}
      onClose={handleCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      disableScrollLock={disableScrollLock}
      BackdropProps={{ style: { backgroundColor: "transparent" } }}
      onBackdropClick={handleCloseModal}
      disablePortal={true}
      style={{position: "absolute", top: 65}}>
      
      <div className={classes.divModalSelect} style={{ width: widthList || 350 }} ref={menuRef}>
        
        <div className={classes.divSearch}>
          
          <div className={classes.btnInputNew}>
            <div>
              <Input
                id={"searchInput"}
                name={"searchInput"}
                value={search}
                width={"95%"}
                maxLength={50}
                placeholder={placeholderInput}
                inputClass={classes.input}
                autoFocus={true}
                onChange={handleSearch}
              />
            </div>
            <button className={classes.iconSearch}>
              <SearchIcon style={{ color: "#DDD", height: 15, width: 15 }}/>
            </button>
          </div>
        </div>
        
        <div className={classes.listItemScroll}>
          {items && list.map((i: SelectSearchItem, index) => {
            return <span key={`option-${i.key}-${index}`}>
                            <div key={i.key} className={classes.listItem}>
                                <div
                                  className={`${classes.elementList} ${itemId === i.key ? classes.itemSelected : classes.itemNoSelected} ${i.isGroup ? classes.classRowDisabled : classes.classRow}`}>
                                    <div className={classes.itemValue} onClick={() => handleItemSelected(i)}>
                                        {i.value}
                                    </div>
                                </div>
                            </div>
                        </span>
          })}
        </div>
      
      </div>
    </Modal>
  </div>);
}