import React from "react"
import SvgIcon from "@material-ui/core/SvgIcon";

const ScheduledSendIcon = (props: any) => {
  const { width = 19, height = 18, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{width: width, height: height}}
      viewBox="0 0 19 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0 15.3307V0.664062L14.3 6.16406H14C13.4167 6.16406 12.8667 6.22517 12.35 6.3474C11.8333 6.46962 11.3333 6.63767 10.85 6.85156L2 3.41406V6.6224L8 7.9974L2 9.3724V12.5807L7.4 10.4724C7.26667 10.8238 7.16667 11.1712 7.1 11.5146C7.03333 11.8587 7 12.2141 7 12.5807V12.6266L0 15.3307ZM14 17.1641C12.6167 17.1641 11.4377 16.7173 10.463 15.8239C9.48767 14.9298 9 13.8488 9 12.5807C9 11.3127 9.48767 10.2316 10.463 9.33756C11.4377 8.44412 12.6167 7.9974 14 7.9974C15.3833 7.9974 16.5627 8.44412 17.538 9.33756C18.5127 10.2316 19 11.3127 19 12.5807C19 13.8488 18.5127 14.9298 17.538 15.8239C16.5627 16.7173 15.3833 17.1641 14 17.1641ZM15.65 14.7349L16.35 14.0932L14.5 12.3974V9.83073H13.5V12.7641L15.65 14.7349ZM2 10.4724V3.41406V12.5807V10.4724Z" fill="#36CE91"/>
    </SvgIcon>
  )
};
export default ScheduledSendIcon;