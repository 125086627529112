import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {
        "&.tox-toolbar__group": {
            color: theme.colors.grey4
        },
        "&.tox-editor-container": {
            backgroundColor: theme.colors.grey1
        }},
    customerHistoryDetailsPanel: {
        color: "#000",
        background: "#fff",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        width: "250px",
        padding: "20px 15px"
    },
    counter: {
        position: "absolute",
        bottom: 7,
        right: 153,
        fontSize: 12,
        color: theme.colors.grey5,
        padding: "5px",
    },
    closeDrawer: {
        color: theme.colors.clearMain,
        fontSize: 12,
        marginBottom: 30,
        cursor: "pointer"
    },
    closeDrawerIcon: {
        display: "inline-block"
    },
    closeDrawerText: {
        display: "inline-block",
        fontFamily: 'Inter',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 12,
        position: "relative",
        top: -4
    },
    divGroup: {
        marginBottom: 20
    },
    title: {
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 12,
        color: theme.colors.grey4,
        marginBottom: 5
    },
    service: {
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 14,
        color: theme.colors.grey5
    },
    specialist: {
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        color: theme.colors.grey5
    },
    dateTimeInfo: {
        fontFamily: 'Roboto',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 12,
        color: theme.colors.grey5
    },
    statusLabel: {

    },
    divTiny: {
        position: "relative",
        "& div.tox-tinymce": {
            borderRadius: 5
        },
        "& svg": {
            fill: "#919CA5 !important"
        },
        "& div.tox-toolbar__primary, div.tox-edit-area, iframe.tox-edit-area__iframe, #tinymce": {
            backgroundColor: "#F6F6F6 !important"
        },
        "& iframe.tox-edit-area__iframe": {
            height: "75%"
        },
        "& div.tox-statusbar": {
            display: "none"
        }
    },
    divTinyFocus: {
        "& div.tox-tinymce": {
            borderColor: "#6462F3 !important"
        },
        "& svg": {
            fill: "#323947 !important"
        },
    },
    divTinyWarning: {
        "& div.tox-tinymce": {
            borderColor: "#F15857 !important"
        },
    },
    saveButton: {
        height: 32,
        background: "#36CE91",
        color: "#F6F6F6",
        "&:hover": {
            background: "#F6F6F6",
            color: "#36CE91",
        },
        "&:disabled": {
            background: "#36CE91",
            opacity: 0.3,
            color: "#F6F6F6"
        },
        position: "relative",
        top: -40,
        right: 5,
        float: "right"
    },
    floatButton: {
        borderRadius: 51,
        textTransform: "none",
        boxShadow: "none",
        display: "inline-block",
        padding: "0px 16px",
    },
    floatButtonTxt: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "15px",
        display: "inline-block",
    },

}));

export default useStyles;
