import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { setAccountSettings } from "../../../redux/account-settings-slice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAccountSettings } from "../../../redux/store";

import { Backdrop, Button, Fade, Grid, Modal, Typography } from "@material-ui/core";

import { EditTerminologyModalProps } from "./props";
import useStyles from "./css";
import { AccountService } from "../../../api/account-service";
import { Terminology } from "../../../models/terminology";
import BroadcastIcon from "../../../assets/icons/BroadcastIcon";
import {
  CrossIcon,
  SectionEditIcon,
  SectionExitEditIcon,
  SectionSaveIcon
} from "../../../assets/icons";
import Input from "../Input";
import ConfirmActionModal from "../ConfirmActionModal";


export default function EditTerminologyModal(props: EditTerminologyModalProps) {

  const { settingsSelectedEstablishmentId } = props;

  const { isOpen, setOpen, type, isSingleFieldOnly } = props;
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  
  const dispatch = useAppDispatch();
  
  const maxTextFieldLength = 200;
  
  const accountSettings = useAppSelector(selectAccountSettings);
  const [initialState, setInitialState] = useState<string>("");
  const [terminology, setTerminology] = useState<Terminology>(new Terminology());
  
  const [singularTerm, setSingularTerm] = useState<string>("");
  const [singularTermHover, setSingularTermHover] = useState<boolean>(false);
  const [isSingularTermEditMode, setSingularTermEditMode] = useState<boolean>(false);
  const [pluralTerm, setPluralTerm] = useState<string>("");
  const [pluralTermHover, setPluralTermHover] = useState<boolean>(false);
  const [isPluralTermEditMode, setPluralTermEditMode] = useState<boolean>(false);
  const [cancelEditModalIsOpen, setCancelEditModalIsOpen] = useState<boolean>(false);
  
  useEffect(() => {
    async function fetchData() {
      const terminology = await AccountService.getAccountTerminology(type, settingsSelectedEstablishmentId);
      setInitialState(JSON.stringify(terminology));
      setTerminology(terminology);
    }
    
    if (isOpen) {
      void fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsSelectedEstablishmentId, isOpen]);
  
  const handleOnClose = () => {
    if (canSave()){
      setCancelEditModalIsOpen(true);
    } else {
      setOpen(false);
      setTerminology(new Terminology());
    }
  };
  
  const handleSaveButtonClick = async () => {
    await AccountService.updateAccountTerminology(terminology, settingsSelectedEstablishmentId);
    const newTerms = accountSettings.terminologies.map(t => t.terminologyType === type ? terminology : t);
    dispatch(setAccountSettings({ ...accountSettings, terminologies: newTerms }));
    setOpen(false);
  };
  
  const canSave = () => {
    return initialState !== JSON.stringify(terminology);
  }
  
  const singularTermHandleOnChange = (event: React.ChangeEvent<{ value: string }>) => {
    const val = event.target.value;
    if (val.length > maxTextFieldLength) {
      return;
    }
    setSingularTerm(val);
    if (isSingleFieldOnly) {
      setPluralTerm(val);
    }
  };
  
  const handleEditSingularTerm = async () => {
    setSingularTermEditMode(false);
    if (terminology.singularForm === singularTerm.trim() || singularTerm.trim().length === 0) {
      return;
    }
    if (isSingleFieldOnly) {
      setTerminology({ ...terminology, singularForm: singularTerm, pluralForm: singularTerm });
    } else {
      setTerminology({ ...terminology, singularForm: singularTerm });
    }
  };
  
  const handleExitEditSingularTerm = () => {
    setSingularTermEditMode(false);
  }
  
  const handleEditSingularTermButtonClick = () => {
    setSingularTerm(terminology.singularForm);
    setSingularTermEditMode(true);
  }
  
  const getSaveSingularTermButton = () => {
    if (isSingularTermEditMode)
      return (<Button
        key={"saveButton"}
        onClick={async () => await handleEditSingularTerm()}
        className={classes.termButton}
      >
        <SectionSaveIcon />
      </Button>);
    return <></>;
  };
  
  const getExitEditSingularTermButton = () => {
    if (isSingularTermEditMode)
      return (<Button
        onMouseDown={() => handleExitEditSingularTerm()}
        className={classes.termButton}
      >
        <SectionExitEditIcon />
      </Button>);
    return <></>;
  };
  
  const getEditSingularTermButton = () => {
    return (<Button
      key={"editButton"}
      onClick={() => handleEditSingularTermButtonClick()}
      className={classes.termButton}
    >
      <SectionEditIcon />
    </Button>);
  };
  
  const getSingularTermButtons = () => {
    if (isSingularTermEditMode) {
      return (
        <>
          {getSaveSingularTermButton()}
          {getExitEditSingularTermButton()}
        </>
      );
    }
    return (
      <>
        {getEditSingularTermButton()}
      </>
    );
  };
  
  const pluralTermHandleOnChange = (event: React.ChangeEvent<{ value: string }>) => {
    const val = event.target.value;
    if (val.length > maxTextFieldLength) {
      return;
    }
    setPluralTerm(val);
  };
  
  const handleEditPluralTerm = async () => {
    setPluralTermEditMode(false);
    if (terminology.pluralForm === pluralTerm.trim() || pluralTerm.trim().length === 0) {
      return;
    }
    setTerminology({ ...terminology, pluralForm: pluralTerm });
  };
  
  const handleExitEditPluralTerm = () => {
    setPluralTermEditMode(false);
  }
  
  const handleEditPluralTermButtonClick = () => {
    setPluralTerm(terminology.pluralForm);
    setPluralTermEditMode(true);
  }
  
  const getSavePluralTermButton = () => {
    if (isPluralTermEditMode)
      return (<Button
        key={"saveButton"}
        onClick={async () => await handleEditPluralTerm()}
        className={classes.termButton}
      >
        <SectionSaveIcon />
      </Button>);
    return <></>;
  };
  
  const getExitEditPluralTermButton = () => {
    if (isPluralTermEditMode)
      return (<Button
        onMouseDown={() => handleExitEditPluralTerm()}
        className={classes.termButton}
      >
        <SectionExitEditIcon />
      </Button>);
    return <></>;
  };
  
  const getEditPluralTermButton = () => {
    return (<Button
      key={"editButton"}
      onClick={() => handleEditPluralTermButtonClick()}
      className={classes.termButton}
    >
      <SectionEditIcon />
    </Button>);
  };
  
  const getPluralTermButtons = () => {
    if (isPluralTermEditMode) {
      return (
        <>
          {getSavePluralTermButton()}
          {getExitEditPluralTermButton()}
        </>
      );
    }
    return (
      <>
        {getEditPluralTermButton()}
      </>
    );
  };
  
  const CloseModalContent: React.FC = () => {
    return (
      <div className={classes.closeModalContentContainer}>
        <Typography className={classes.closeModalTitleText}>
          {t("Are you sure you want to cancel the term modification?")}
        </Typography>
        <div className={classes.separator}/>
        <Typography className={classes.closeModalContentText}>
          {t("Changes made will not be saved.")}
        </Typography>
      </div>
    );
  };
  
  const handleCloseEdit = () => {
    setCancelEditModalIsOpen(false);
    setOpen(false);
    setTerminology(new Terminology());
  };
  
  return <>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modalYellow}
      open={isOpen}
      onClose={handleOnClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
    
      <Fade in={isOpen}>
        <div className={classes.modalPaperYellow}>
          <div className={classes.modalHeader}>
            <div className={classes.modalHeaderContent}>
              <div className={classes.modalCircle}>
                <BroadcastIcon/>
              </div>
            </div>
            <div className={classes.modalClose}>
              <span className={classes.closeButton} onClick={handleOnClose}>
                  <CrossIcon/>
              </span>
            </div>
          </div>
        
          <div className={classes.modalContent}>
          
            <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`}>
              <Typography className={classes.modalTitleText}>
                { isSingleFieldOnly
                  ? t("Type the term you want to use")
                  : t("Choose the term you want to modify")}
              </Typography>
            </div>
          
            <div className={classes.separator}></div>
          
            <div className={classes.bodyContent}>
              <Grid item
                    className={classes.termContainer}
                    onMouseEnter={() => { setSingularTermHover(true) }}
                    onMouseLeave={() => { setSingularTermHover(false) }} >
                {!isSingularTermEditMode
                  ? <Typography className={classes.termTitle}>
                    {terminology.singularForm}
                  </Typography>
      
                  : <Input
                    inputClass={classes.termEditNameInput}
                    value={singularTerm}
                    isValid={() => { return singularTerm.trim().length > 0 && singularTerm.length <= maxTextFieldLength }}
                    onChange={(event) => singularTermHandleOnChange(event)}
                    onBlur={async () => await handleEditSingularTerm()}
                    disabled={!isSingularTermEditMode}
                    maxLength={maxTextFieldLength}
                    width={"75%"}
                    autoFocus
                  />}
                {singularTermHover || isSingularTermEditMode
                  ? <div className={classes.termButtonsContainer}>
                    {getSingularTermButtons()}
                  </div>
                  : <></>}
              </Grid>
  
              {isSingleFieldOnly !== true &&
                <Grid item
                      className={classes.termContainer}
                      onMouseEnter={() => { setPluralTermHover(true) }}
                      onMouseLeave={() => { setPluralTermHover(false) }} >
                  {!isPluralTermEditMode
                    ? <Typography className={classes.termTitle}>
                      {terminology.pluralForm}
                    </Typography>
        
                    : <Input
                      inputClass={classes.termEditNameInput}
                      value={pluralTerm}
                      isValid={() => { return terminology.pluralForm.trim().length > 0 && terminology.pluralForm.length <= maxTextFieldLength }}
                      onChange={(event) => pluralTermHandleOnChange(event)}
                      onBlur={async () => await handleEditPluralTerm()}
                      disabled={!isPluralTermEditMode}
                      maxLength={maxTextFieldLength}
                      width={"75%"}
                      autoFocus
                    />}
                  {pluralTermHover || isPluralTermEditMode
                    ? <div className={classes.termButtonsContainer}>
                      {getPluralTermButtons()}
                    </div>
                    : <></>}
                </Grid>
              }
            </div>
          
            <div className={classes.modalActions}>
              <Button className={`${classes.button} ${classes.btnCancel}`} onClick={handleOnClose}>
                {t("Go back")}
              </Button>
              <Button className={`${classes.button} ${classes.btnOk}`} onClick={handleSaveButtonClick} disabled={!canSave()}>
                {t("Save")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
    <ConfirmActionModal
      classModal={classes.closeConfirmationModal}
      content={<CloseModalContent />}
      open={cancelEditModalIsOpen}
      onClose={() => setCancelEditModalIsOpen(false)}
      onConfirm={handleCloseEdit}
    />
  </>
};