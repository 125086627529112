import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionFieldNumericIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M19.736 9.39286L19.9861 7.96429C20.0576 7.67857 19.8432 7.42857 19.5574 7.42857H16.9132L17.4134 4.53571C17.4849 4.25 17.2705 4 16.9846 4H15.5554C15.341 4 15.1623 4.17857 15.1266 4.35714L14.5906 7.42857H11.0531L11.5891 4.53571C11.6248 4.25 11.4104 4 11.1603 4H9.69527C9.51661 4 9.30222 4.17857 9.26649 4.35714L8.7305 7.42857H5.90766C5.69326 7.42857 5.5146 7.60714 5.47887 7.78571L5.22875 9.21429C5.19301 9.5 5.37167 9.71429 5.65753 9.71429H8.33745L7.51561 14.2857H4.69276C4.47837 14.2857 4.29971 14.4643 4.26398 14.6429L4.01385 16.0714C3.94239 16.3571 4.15678 16.5714 4.44264 16.5714H7.08682L6.58657 19.5C6.51511 19.7857 6.7295 20 7.01536 20H8.44464C8.65904 20 8.8377 19.8571 8.87343 19.6786L9.40941 16.5714H12.9469L12.4109 19.5C12.3752 19.7857 12.5896 20 12.8397 20H14.3047C14.4834 20 14.6978 19.8571 14.7335 19.6786L15.2695 16.5714H18.0923C18.3067 16.5714 18.4854 16.4286 18.5211 16.25L18.7713 14.8214C18.807 14.5357 18.6283 14.2857 18.3425 14.2857H15.6626L16.4844 9.71429H19.3072C19.5216 9.71429 19.7003 9.57143 19.736 9.39286ZM13.34 14.2857H9.8382L10.66 9.71429H14.1618L13.34 14.2857Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}