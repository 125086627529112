export class UserModel {
  id: string | null;
  authenticationId: string | null;
  firstName: string | null;
  lastName: string | null;
  hasServicesInCharge : boolean
  servicesInChargeCount: number
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.authenticationId = x.authenticationId;
      this.firstName = x.firstName;
      this.lastName = x.lastName;
      this.hasServicesInCharge = x.hasServicesInCharge;
      this.servicesInChargeCount = x.servicesInChargeCount;
    } else {
      this.id = null;
      this.authenticationId = null;
      this.firstName = null;
      this.lastName = null;
      this.hasServicesInCharge = false;
      this.servicesInChargeCount = 0;
    }
  }
}
