export enum SectionNoteData {
    General = "GeneralSectionNoteParam",
    Communication = "CommunicationSectionNoteParam",
    WorkAreas = "WorkAreasSectionNoteParam",
    Services = "ServicesSectionNoteParam",
    Users = "UsersSectionNoteParam",
    Payments = "PaymentsSectionNoteParam",
    Commissions = "CommissionsSectionNoteParam",
    ClinicalHistory = "ClinicalHistorySectionNoteParam",
    EvolutionNotes = "EvolutionNotesSectionNoteParam",
    PatientForm = "PatientFormSectionNoteParam"
}