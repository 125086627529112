import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function DraggableIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path d="M8.875 7.69235C9.91053 7.69235 10.75 6.86579 10.75 5.84617C10.75 4.82656 9.91053 4 8.875 4C7.83947 4 7 4.82656 7 5.84617C7 6.86579 7.83947 7.69235 8.875 7.69235Z" 
            fill={style?.color || Color.gray5} />
            <path d="M8.875 13.8465C9.91053 13.8465 10.75 13.02 10.75 12.0003C10.75 10.9807 9.91053 10.1542 8.875 10.1542C7.83947 10.1542 7 10.9807 7 12.0003C7 13.02 7.83947 13.8465 8.875 13.8465Z" 
            fill={style?.color || Color.gray5} />
            <path d="M8.875 20.0006C9.91053 20.0006 10.75 19.1741 10.75 18.1545C10.75 17.1348 9.91053 16.3083 8.875 16.3083C7.83947 16.3083 7 17.1348 7 18.1545C7 19.1741 7.83947 20.0006 8.875 20.0006Z" 
            fill={style?.color || Color.gray5} />
            <path d="M15.125 7.69235C16.1605 7.69235 17 6.86579 17 5.84617C17 4.82656 16.1605 4 15.125 4C14.0895 4 13.25 4.82656 13.25 5.84617C13.25 6.86579 14.0895 7.69235 15.125 7.69235Z" 
            fill={style?.color || Color.gray5} />
            <path d="M15.125 13.8465C16.1605 13.8465 17 13.02 17 12.0003C17 10.9807 16.1605 10.1542 15.125 10.1542C14.0895 10.1542 13.25 10.9807 13.25 12.0003C13.25 13.02 14.0895 13.8465 15.125 13.8465Z" 
            fill={style?.color || Color.gray5} />
            <path d="M15.125 20.0006C16.1605 20.0006 17 19.1741 17 18.1545C17 17.1348 16.1605 16.3083 15.125 16.3083C14.0895 16.3083 13.25 17.1348 13.25 18.1545C13.25 19.1741 14.0895 20.0006 15.125 20.0006Z" 
            fill={style?.color || Color.gray5} />
        </SvgIcon>
    );
}