import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionFieldNewIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M15 11.5C15 11.0029 14.5971 10.6 14.1 10.6H4.9C4.40294 10.6 4 11.0029 4 11.5C4 11.9971 4.40294 12.4 4.9 12.4H14.1C14.5971 12.4 15 11.9971 15 11.5ZM15 7.9C15 7.40294 14.5971 7 14.1 7H4.9C4.40294 7 4 7.40294 4 7.9C4 8.39706 4.40294 8.8 4.9 8.8H14.1C14.5971 8.8 15 8.39706 15 7.9ZM4 15.1C4 15.5971 4.40294 16 4.9 16H10.4333C10.9304 16 11.3333 15.5971 11.3333 15.1C11.3333 14.6029 10.9304 14.2 10.4333 14.2H4.9C4.40294 14.2 4 14.6029 4 15.1Z"
                fill={style?.color || Color.gray1} />
            <path
                d="M21 15C21 14.8181 20.9277 14.6437 20.7991 14.5151C20.6705 14.3865 20.4961 14.3142 20.3142 14.3142L17.6858 14.3142L17.6858 11.6858C17.6858 11.5039 17.6135 11.3295 17.4849 11.2009C17.3563 11.0723 17.1819 11 17 11C16.8181 11 16.6437 11.0723 16.5151 11.2009C16.3865 11.3295 16.3142 11.5039 16.3142 11.6858L16.3142 14.3142L13.6858 14.3142C13.5039 14.3142 13.3295 14.3865 13.2009 14.5151C13.0723 14.6437 13 14.8181 13 15C13 15.1819 13.0723 15.3563 13.2009 15.4849C13.3295 15.6135 13.5039 15.6858 13.6858 15.6858L16.3117 15.6858L16.3117 18.3142C16.3117 18.4961 16.384 18.6705 16.5126 18.7991C16.6412 18.9277 16.8156 19 16.9975 19C17.1794 19 17.3538 18.9277 17.4824 18.7991C17.611 18.6705 17.6833 18.4961 17.6833 18.3142L17.6833 15.6883L20.3142 15.6883C20.4045 15.6883 20.4939 15.6705 20.5772 15.6358C20.6606 15.6012 20.7363 15.5505 20.8 15.4865C20.8637 15.4226 20.9142 15.3467 20.9485 15.2632C20.9828 15.1797 21.0003 15.0903 21 15Z"
                fill={style?.color || Color.gray1} />
        </SvgIcon>
    );
}