import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& ::-webkit-scrollbar": {
            width: "6px",
        },
        "& ::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#EFEFEF",
            marginTop: "10px",
            marginBottom: "10px"
        },
        "& ::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: theme.colors.grey3,
        },
        "& ::-webkit-scrollbar-thumb:hover": {
            background: theme.colors.grey4
        }
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #dbdcdd",
        borderRadius: "5px",
        boxShadow: theme.shadows[5],
        width: 1000,
        height: 565,
        padding: "25px 27px 109px 45px"
    },
    headerContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        paddingBottom: 43
    },
    titleContainer: {
        display: "flex",
        flexDirection: "column"
    },
    modalNameLabel: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "normal",
        color: theme.colors.grey5
    },
    requiredFieldLabel: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "normal",
        color: theme.colors.grey4
    },
    asterix: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "normal",
        color: theme.colors.clearMain
    },
    modalManagementButtonsContainer: {
        display: "flex",
        flexDirection: "row",
        gap: 16
    },
    button: {
        width: 200,
        height: 32,
        borderRadius: "5px",
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 12,
    },
    goBack: {
        color: theme.colors.grey3,
        border: "1px solid " + theme.colors.grey3
    },
    continueButton: {
        background: theme.colors.clearGreen,
        color: "#F6F6F6",
        "&:hover": {
            background: theme.colors.clearGreen,
        },
    },
    saveDisabled: {
        color: "#F6F6F6 !important",
        opacity: "0.4",
    },
    mainContentContainer: {
        display: "flex",
        flexDirection: "row"
    },
    settingsContainer: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        maxWidth: "50%"
    },
    scrollableSettingContainer: {
        display: "flex",
        flexDirection: "column",
        maxHeight: 434,
        overflowY: "auto"
    },
    printDetailsLabelContainer: {
        display: "flex",
        flexDirection: "row"
    },
    violetHeaderLabel: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "normal",
        color: theme.colors.clear2,
        paddingBottom: 13
    },
    iconTooltipRoot: {
        display: "inline-block",
        marginLeft: 5,
    },
    previewContainer: {
        width: "50%",
        maxWidth: "50%"
    },
    documentSizeSelect: {
        color: theme.colors.clearMain + " !important",
        paddingBottom: 22
    },
    logoDisableableSection: {
        display: "flex",
        flexDirection: "column",
        paddingTop: 20
    },
    disabled: {
        pointerEvents: "none",
        opacity: 0.3,
    },
    logoConfigurationSection: {
        display: "flex",
        flexDirection: "row",
        gap: 9
    },
    logoConfigurationLeftSection: {
        display: "flex",
        flexDirection: "column",
        gap: 12
    },
    logoConfigurationRightSection: {
        display: "flex",
        columnGap: 24,
        rowGap: 19,
        flexWrap: "wrap",
        alignContent: "flex-start"
    },
    logoNotesContainer: {
        display: "flex",
        flexDirection: "column",
        paddingTop: 24
    },
    notes: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "normal",
        color: theme.colors.grey5,
    },
    notesBold: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "normal",
        color: theme.colors.grey5,
    },
    logo: {
        width: 194,
        height: 127,
        borderRadius: 15,
        backgroundRepeat: "no-repeat",
        backgroundSize: "contain",
        backgroundPosition: "center top",
    },
    logoEmpty: {
        width: 194,
        height: 127,
        backgroundColor: "#F6F6F6",
        borderRadius: 15,
        border: "1px dashed #DBDCDD;",
        cursor: "pointer",
    },
    emptyLogoStubContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
        alignItems: "center",
        marginTop: 31
    },
    iconCamera: {
        color: "#919CA5",
        fontSize: 20,
        cursor: "pointer",
        marginLeft: 10,
        marginTop: 3,
    },
    iconTrash: {
        color: "#919CA5",
        fontSize: 24,
        cursor: "pointer",
        marginLeft: 10,
    },
    addPhotoLabel: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "normal",
        color: theme.colors.grey4,
        marginTop: 8
    },
    addPhotoMinSizeLabel: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "9px",
        lineHeight: "normal",
        color: theme.colors.grey4,
    },
    logoControls: {
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        gap: 7,
        marginTop: -7
    },
    logoControlsEmptyStub: {
        marginTop: -7,
        height: 23
    },
    columnNameLabel: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "normal",
        color: theme.colors.grey4,
    },
    logoNumericInputContainer: {
        display: "flex",
        flexDirection: "column",
        gap: 12
    },
    fieldSettingsContainer: {
        display: "flex",
        flexDirection: "column",
        marginTop: 24,
        maxWidth: 435
    },
    fieldSettingsRow: {
        display: "flex",
        justifyContent: "space-between",
        marginBottom: 29
    },
    fieldSettingsGridItem: {
        "&:nth-child(1)": {
            width: 180,
            maxWidth: 180
        },
        "&:nth-child(2)": {
            marginLeft: -10,
        }
    },
    deleteConfirmationModal: {
        height: "200px !important"
    },
    deleteModalContentContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 15
    },
    deleteModalTitleText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#26292B",
        textAlign: "center"
    },
    closeConfirmationModal: {
        height: "234px + !important"
    },
    closeModalTitleText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#26292B",
        textAlign: "center"
    },
    previewSectionLabel: {
        marginLeft: 15
    }
}));

export default useStyles;