export class ClinicalHistoryCommunicationSettings {
  establishmentAccountId: string|null;
  isClinicalHistoryEnabled: boolean;
  sendFormOnConfirmationNoHistory: boolean;
  sendFormOnReminderNoHistory: boolean;
  sendFormOnConfirmationIncompleteHistory: boolean;
  sendFormOnReminderIncompleteHistory: boolean;
  isPrivacyNoticeEnabled: boolean;
  privacyNotice: string;
  isConsentLetterEnabled: boolean;
  consentLetter: string;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.establishmentAccountId = x.establishmentAccountId;
      this.isClinicalHistoryEnabled = x.isClinicalHistoryEnabled;
      this.sendFormOnConfirmationNoHistory = x.sendFormOnConfirmationNoHistory;
      this.sendFormOnReminderNoHistory = x.sendFormOnReminderNoHistory;
      this.sendFormOnConfirmationIncompleteHistory = x.sendFormOnConfirmationIncompleteHistory;
      this.sendFormOnReminderIncompleteHistory = x.sendFormOnReminderIncompleteHistory;
      this.isPrivacyNoticeEnabled = x.isPrivacyNoticeEnabled;
      this.privacyNotice = x.privacyNotice;
      this.isConsentLetterEnabled = x.isConsentLetterEnabled;
      this.consentLetter = x.consentLetter;
    } 
    else {
      this.establishmentAccountId = null;
      this.isClinicalHistoryEnabled = false;
      this.sendFormOnConfirmationNoHistory = false;
      this.sendFormOnReminderNoHistory = false;
      this.sendFormOnConfirmationIncompleteHistory =false;
      this.sendFormOnReminderIncompleteHistory = false;
      this.isPrivacyNoticeEnabled = false;
      this.privacyNotice = "";
      this.isConsentLetterEnabled = false;
      this.consentLetter = "";
    }
  }
}