import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles(theme => ({
    toolbarWrapper: {
        position: "absolute",
        top: "0",
        right: "14px",
        width: "fit-content",
        height: "100%",
        // the IconButton itself has 3px of padding, we need 8px in total
        padding: "5px 0",
        display: "flex",
        flexDirection: "row",
        // currently unused as there is only one button
        gap: "14px",
    },
}));
