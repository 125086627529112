import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  noFilesCaption: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#6462F3",
    marginTop: 34,
    textAlign: "center"
  },
  noFilesIcon: {
    position: "relative",
    top: 76,
    left: 210,
    width: 266
  },
  fileNameContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center"
  },
  grid: {
    marginRight: "auto",
    marginTop: 50,
    marginLeft: "auto",
    width: "95%",
    "& .MuiPagination-root": {
      marginTop: 30
    },
    "& td": {
      padding: "7px 0px 7px 12px"
    },
    "&>div>div, &>div>div>div":{
      height: "calc(100vh - 254px)",
    }
  },
  classRow: {
    // cursor: "pointer",
    "& div.buttonRow": {
      visibility: "hidden",
    },
    "&:hover": {
      "& div.buttonRow": {
        visibility: "visible"
      }
    }
  },
  removeModalContentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 7
  },
  closeModalContentHeaderText: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: Color.gray5
  },
  separator: {
    height: 0,
    borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    margin: "16px 0px 16px 0px",
    width: 217
  },
  closeModalContentText: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    textAlign: "center",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.gray5
  },
  closeConfirmationModal: {
    minHeight: "240px !important",
    height: "auto !important",
  },
  addButton: {
    ...theme.typography.buttons,
    color: theme.colors.clearMain,
    width: "auto",
    height: 32,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    borderRadius: 51,
    padding: "11px 16px",
    border: `1px solid ${theme.colors.clearMain}`,
    justifyContent: "space-around",
    
    position: "relative",
    top: 25,
    left: 650,
    
    "&.Mui-disabled": {
      color: theme.colors.clearGreen,
      opacity: 0.3,
    },
  },
}));

export default useStyles;
