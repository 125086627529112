import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function PatientContactIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M18.6667 6H5.33333C4.58333 6 4 6.58929 4 7.28571V7.71429H20V7.28571C20 6.58929 19.3889 6 18.6667 6ZM4 16.7143C4 17.4375 4.58333 18 5.33333 18H18.6667C19.3889 18 20 17.4375 20 16.7143V8.57143H4V16.7143ZM13.7778 10.5C13.7778 10.3929 13.8611 10.2857 14 10.2857H18C18.1111 10.2857 18.2222 10.3929 18.2222 10.5V10.9286C18.2222 11.0625 18.1111 11.1429 18 11.1429H14C13.8611 11.1429 13.7778 11.0625 13.7778 10.9286V10.5ZM13.7778 12.2143C13.7778 12.1071 13.8611 12 14 12H18C18.1111 12 18.2222 12.1071 18.2222 12.2143V12.6429C18.2222 12.7768 18.1111 12.8571 18 12.8571H14C13.8611 12.8571 13.7778 12.7768 13.7778 12.6429V12.2143ZM13.7778 13.9286C13.7778 13.8214 13.8611 13.7143 14 13.7143H18C18.1111 13.7143 18.2222 13.8214 18.2222 13.9286V14.3571C18.2222 14.4911 18.1111 14.5714 18 14.5714H14C13.8611 14.5714 13.7778 14.4911 13.7778 14.3571V13.9286ZM8.88889 10.2857C9.86111 10.2857 10.6667 11.0625 10.6667 12C10.6667 12.9643 9.86111 13.7143 8.88889 13.7143C7.88889 13.7143 7.11111 12.9643 7.11111 12C7.11111 11.0625 7.88889 10.2857 8.88889 10.2857ZM5.86111 15.7768C6.08333 15.0804 6.75 14.5714 7.55556 14.5714H7.77778C8.11111 14.7321 8.47222 14.7857 8.88889 14.7857C9.27778 14.7857 9.63889 14.7321 9.97222 14.5714H10.2222C11 14.5714 11.6667 15.0804 11.8889 15.7768C12 16.0446 11.75 16.2857 11.4722 16.2857H6.27778C6 16.2857 5.75 16.0179 5.86111 15.7768Z"
                fill={style?.color || Color.clear2}
            />
        </SvgIcon>
    );
}