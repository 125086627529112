import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionExitEditIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M17.6568 6.34314C17.3996 6.08592 17.0508 5.94142 16.687 5.94142C16.3232 5.94142 15.9744 6.08592 15.7172 6.34314L12 10.0603L8.28284 6.34314C8.02562 6.08592 7.67675 5.94141 7.31299 5.94141C6.94923 5.94141 6.60036 6.08592 6.34314 6.34314C6.08592 6.60036 5.94142 6.94922 5.94142 7.31298C5.94142 7.67675 6.08592 8.02561 6.34314 8.28283L10.0603 12L6.34315 15.7171C6.08593 15.9744 5.94142 16.3232 5.94142 16.687C5.94142 17.0508 6.08593 17.3996 6.34315 17.6568C6.60037 17.9141 6.94923 18.0586 7.31299 18.0586C7.67676 18.0586 8.02562 17.9141 8.28284 17.6568L11.9965 13.9432L15.7136 17.6604C15.9709 17.9176 16.3197 18.0621 16.6835 18.0621C17.0472 18.0621 17.3961 17.9176 17.6533 17.6604C17.9105 17.4032 18.055 17.0543 18.055 16.6905C18.055 16.3268 17.9105 15.9779 17.6533 15.7207L13.9397 12.007L17.6604 8.28636C17.788 8.1587 17.8892 8.0071 17.9582 7.84024C18.0271 7.67338 18.0624 7.49456 18.0621 7.31402C18.0618 7.13348 18.0258 6.95478 17.9563 6.78818C17.8867 6.62157 17.785 6.47033 17.6568 6.34314Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}