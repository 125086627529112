import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";
export const useStyles = makeStyles((theme) => ({
    messengerLayout: {
        width: "100vw",
        position: "relative",
        left: "50%",
        right: "50%",
        marginLeft: "-50vw",
        marginRight: "-50vw",
        marginTop: "-20px",
    },
    disableYScroll: {
        overflow: "hidden",
    },
    scrollable: {
        overflow: "scroll !important"
    },
    loader: {
        height: 80,

        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    hideScrollbars: {
        "&::-webkit-scrollbar": {
            "display": "none",
        },
        scrollbarWidth: "none",
        "&::-ms-overflow-style": "none",
    },
    buildScrollbars: {
        "&::-webkit-scrollbar": {
            "display": "none",
        },
        scrollbarWidth: "none",
        "&::-ms-overflow-style": "none",
        overflowY: "auto", 
        maxHeight: "calc(100vh - 120px)"
    },
    noConversationsContainer: {
        width: "360px",
        maxWidth: "360px",
        height: "100px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        textAlign: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray5,
    },
    noConversation: {
        width: "calc(100vw - 360px)",
        height: "100vh",
        maxHeight: "100vh",
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        backgroundColor: "#ffffff",
        textAlign: "center"
    },
    noConversationImage: {
        marginTop: "20vh",
    },
    noConversationText1: {
        marginTop: 40,
        fontFamily: 'Inter, sans-serif',
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 18,
        alignItems: "center",
        textAlign: "center",
        letterSpacing: "0.08em",
        color: "#7175FA"
    },
    noConversationText2: {
        marginTop: 20,
        fontFamily: 'Inter, sans-serif',
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        alignItems: "center",
        textAlign: "center",
        color: "#919CA5",
        margin: "auto",
        maxWidth: 280
    },
    availableCaption: {
        display: "block",
        
        fontSize: 10,
        fontFamily: "Inter",
        
        marginLeft: '19px',
        color: 'gray',
        whiteSpace: 'pre-line',
    },
    availabilityStatusContainer: {
        display: "flex",
        justifyContent: "flex-end",
        marginRight: 27
    },
    availableCircle: {
        fontSize: '12px',
        marginRight: '8px'
    },
    selected: {
        fontWeight: "bold",
        padding: "8px 0px 8px 15px",
        color: theme.colors.clearMain
    },
    focused: {
        "&:focus": {
            backgroundColor: theme.colors.grey1,
        },
        "&.MuiSelect-select": {
            "&:focus": {
                borderRadius: 5,
            },
        },
    },
    itemRoot: {
        ...theme.typography.txtBody1,
        color: theme.colors.grey5,
        height: 70,
        width: 150,
        display: "flex",
        alignItems: "center",
    },
    select: {
        ...theme.typography.txtBody2,
        height: 32,
        backgroundColor: theme.colors.grey1,
        width: 150,
        border: "solid 1px rgba(0, 0, 0, 0.1)",
        borderRadius: 5,
        "& .MuiPaper-rounded": {
            borderRadius: 10,
            marginTop: 5
        },
        "& .MuiPaper-rounded .MuiListItem-root.Mui-selected:not(:hover)": {
            backgroundColor: "white",
        },
        "& .MuiPaper-rounded .MuiListItem-root": {
            fontSize: 12,
        },
        "& .MuiPaper-rounded .MuiListItem-root:hover": {
            backgroundColor: theme.colors.grey1
        },
        "& .MuiSelect-select": {
            paddingRight: 34
        },
        position:  'relative',
    },
    toolbarContainer: {
        width: 360,
        height: 64,
        backgroundColor: Color.white,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center"
    }
}));