import React, { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import { TrashIcon } from "../../../../../assets/icons";
import GridList, { OnChangeEvent } from "../../../../common/grid/GridList";
import { TableColumn } from "../../../../common/grid/table-column";
import { Button } from "@material-ui/core";

import EntityService from "../../../../../api/settings-workareas-service";
import Workarea from "../../../../../models/workarea";
import { ListProps } from "./props";

import { Color } from "../../../../../constants/colors";
import useStyles from "./css";
import { useAppDispatch } from "../../../../../redux/hooks";
import { navbarActions } from "../../../../../redux/navbar-slice";


const WorkareasGrid = (props: ListProps) => {

    const { t } = useTranslation(["general"]);
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const { settingsSelectedEstablishmentId, isRefresh, item, openItemEditModal, openDeleteModal, setTotalRecords } = props;

    const [entityList, setEntityList] = useState<Workarea[]>([]);
    const [total, setTotal] = useState<number>(0);

    const [page, setPage] = useState<number>(1);
    const pageSize: number = 20;
    const [order, setOrder] = useState<"asc" | "desc">("asc");
    const [orderBy, setOrderBy] = useState<string>("name");

    useEffect(() => {

        (async () => {
            await loadData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, orderBy, order, isRefresh, settingsSelectedEstablishmentId]);



    useEffect(() => {

        if (item) {
            const newList = [item].concat(entityList);
            setEntityList(newList);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);


    
    const loadData = async () => {
        dispatch(navbarActions.setShowLoader(true));
        const data = await EntityService.getWorkareas(
            page,
            pageSize,
            settingsSelectedEstablishmentId,
            order,
            orderBy
        );
        dispatch(navbarActions.setShowLoader(false));
        setEntityList(data.entity || []);
        setTotal(data.total || 0);
        if (setTotalRecords) setTotalRecords(data.total || 0);
    };

    const columns: TableColumn[] = [
        {
            id: "name",
            label: t('NAME'),
            sorting: true,
            width: 200,
        },
        {
            id: "category",
            label: t('CATEGORY'),
            sorting: true,
            width: 200,
        },
        {
            id: "description",
            label: t('DESCRIPTION'),
            sorting: false,
            width: 340,
        },
        {
            id: "id",
            label: "",
            sorting: false,
            completeObject: true,
            component: (item: any) => <Button className={classes.button} onClick={(e) => { e.stopPropagation(); handleOnDeleteBtnClick(item); } } >
                <TrashIcon style={{color: Color.gray4, width: 13, height: 17, }} />
            </Button>,
            width: 50,
            headerTextAlign: "center",
            textAlign: "center",
        },
    ];

    const handleOnChangeGrid = (event: OnChangeEvent) => {
          setPage(event.page);
        if (event.sorting) {
            setOrder(event.sorting.direction);
            setOrderBy(
                event.sorting.orderBy
            );
          }
    };

    const handleOnRowClick = (item: Workarea, showModal: boolean) => {
        if (typeof openItemEditModal === "function") {
            openItemEditModal(item);
        }
    };


    const handleOnDeleteBtnClick = (item: any) => {
        if (typeof openDeleteModal === "function") {
            openDeleteModal(item);
        }
    };

    return (
        <GridList
            columns={columns}
            items={entityList}
            totalItems={total}
            pageSize={pageSize}
            onChange={handleOnChangeGrid}
            onRowClick={(e) => handleOnRowClick(e, true)}
            classRow={classes.classRow}
        />
    );
};

export default WorkareasGrid;
