import { ConversationMessageType } from "./enums/conversation-message-type";

export class ConversationPreview {
  lastMessageId: string;
  customerId?: string;
  customerName: string;
  customerMobile: string;
  lastMessageText: string | null;
  lastMessageTime?: Date;
  lastMessageType: ConversationMessageType;
  isDisabledActivity: boolean;
  isDisabledNotContactable: boolean;
  isCustomerBlocked: boolean;
  unreadMessagesCount: number;
  wasSendMore1DayAgo: boolean;
  canScheduleFollowUp: boolean;
  hasScheduledFollowUp: boolean;
  hasUnpaidAppointment: boolean;
  establishmentId: string | null;
  establishmentName: string | null;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.lastMessageId = x.lastMessageId;
      this.customerId = x.customerId;
      this.customerName = x.customerName;
      this.customerMobile = x.customerMobile;
      this.lastMessageText = x.lastMessageText;
      this.lastMessageTime = x.lastMessageTime;
      this.lastMessageType = x.lastMessageType;
      this.isDisabledActivity = x.isDisabledActivity;
      this.isDisabledNotContactable = x.isDisabledNotContactable;
      this.isCustomerBlocked = x.isCustomerBlocked;
      this.unreadMessagesCount = x.unreadMessagesCount;
      this.wasSendMore1DayAgo = x.wasSendMore1DayAgo;
      this.canScheduleFollowUp = x.canScheduleFollowUp;
      this.hasScheduledFollowUp = x.hasScheduledFollowUp;
      this.hasUnpaidAppointment = x.hasUnpaidAppointment;
      this.establishmentId = x.establishmentId;
      this.establishmentName = x.establishmentName;
    } else {
      this.lastMessageId = "";
      this.customerId = undefined;
      this.customerName = "";
      this.customerMobile = "";
      this.lastMessageText = "";
      this.lastMessageTime = undefined;
      this.lastMessageType = ConversationMessageType.Text;
      this.isDisabledActivity = false;
      this.isDisabledNotContactable = false;
      this.isCustomerBlocked = false;
      this.unreadMessagesCount = 0;
      this.wasSendMore1DayAgo = false;
      this.canScheduleFollowUp = false;
      this.hasScheduledFollowUp = false;
      this.hasUnpaidAppointment = false;
      this.establishmentId = null;
      this.establishmentName = null;
    }
  }
}
