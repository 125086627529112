import { createSlice } from "@reduxjs/toolkit";
import { UserInfoModel } from "../models/interfaces/user-info-model";


const initialState: UserInfoModel = {
  email: null,
  username: null,
  fullName: null,
  accountId: null,
  userId: null,
  isAdministrator: false,
  hasCustomerInfoVisible: false,
  hasMassiveSendings: false,
  canRegisterPayments: false,
  canOpenCloseCashRegister: false,
  canMakeCashRegisterMovements: false,
  hasAccessToReports: false,
  canSeeClinicalFile: false,
  canInteractWithEvolutionNotes: false,
  canInteractWithPatientFiles: false,
  hasAccessToWhatsAppConversationsAndNotifications: false,
  hasAccessToScheduleAllSpecialists: true,
  canPrescribeTreatment: false,
  showPrices: false
}
const initializeLocalStateDefaults = function (localState: UserInfoModel | null) {
  if (localState){
    for (const prop in initialState) {
      if (Object.prototype.hasOwnProperty.call(initialState, prop)) {
        // @ts-ignore
        if (localState[prop] === undefined) {
          // @ts-ignore
          localState[prop] = initialState[prop];
        }
      }
    }
  }
}

const localStateJson = localStorage.getItem('userInfo');
const localState: UserInfoModel | null = localStateJson ? JSON.parse(localStateJson) : null;
initializeLocalStateDefaults(localState);

export const userInfoSlice = createSlice({
  name: "userInfo",
  initialState: localState ?? initialState,
  reducers: {
    setUserInfo: (currentState, action) => {
      localStorage.setItem('userInfo', JSON.stringify(action.payload));
      return action.payload;
    },
    resetUserInfo: () => {
      localStorage.removeItem('userInfo');
      return initialState;
    }
  }
})

export const { setUserInfo, resetUserInfo } = userInfoSlice.actions;
export default userInfoSlice.reducer;