import { ApiClient } from "./api-client";
import { Response } from "../models/response";
import { GridInfoModel } from "../models/helpers/grid-info.model";
import { MassiveSendingEntity } from "../models/massive-sending-entity";

export class MassiveSendingService {

    private static baseUrl: string = "/massivesending";
    private static isoDate(date: Date): string {
        const year = date.toLocaleString("en-US", {
          year: "numeric",
        });
        const month = date.toLocaleString("en-US", {
          month: "2-digit",
        });
        const day = date.toLocaleString("en-US", {
          day: "2-digit",
        });
    
        return `${year}-${month}-${day}`;
      }
    
      private static handleTime(date: Date): Date {
        const time = date.toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          second: "2-digit",
          hour12: false,
        });
    
        return new Date(`${this.isoDate(date)}`);
      }
    static getSendings(       
        gridInfo: GridInfoModel, 
        customerFilter: number | null,
        statusFilter: number | null
    ): Promise<Response> {

        let urlEndpoint = `${this.baseUrl}/GetByAccount`;

        urlEndpoint += `?page=${gridInfo.page}&pageSize=${gridInfo.pageSize}`

        if (gridInfo.order && gridInfo.orderBy) {
            urlEndpoint += `&order=${gridInfo.order}&orderBy=${gridInfo.orderBy}`;
        }

        if (gridInfo.value && gridInfo.properties && gridInfo.properties.length > 0) {
            urlEndpoint += `&value=${gridInfo.value}`;
            for (const property of gridInfo.properties) {
                urlEndpoint += `&Properties=${property}`;
            }
        }

        if (customerFilter !== undefined)
            urlEndpoint += `&frequency=${customerFilter}`;
            
        if (statusFilter !== undefined)
            urlEndpoint += `&status=${statusFilter}`;

        return ApiClient.get(urlEndpoint, undefined).then((data) => {
            return new Response(data.massiveSending, data.total);
        });
    }
    
    static getSendingById(id: string): Promise<MassiveSendingEntity> {
        return ApiClient.get(`${this.baseUrl}/get?id=${id}`, undefined).then(
          (data) => new MassiveSendingEntity(data.massiveSending)
        );
    }
    
    static createSendingWithImage(massiveSending: MassiveSendingEntity): Promise<MassiveSendingEntity> {
        let formData = new FormData();
        if (massiveSending.dateOfSending !== null)
            massiveSending.dateOfSending = this.handleTime(massiveSending.dateOfSending as Date);
        formData.append("command", JSON.stringify(massiveSending));
        if (massiveSending.image) {
            formData.append("image", massiveSending.image, massiveSending.image.name);
        }
        return ApiClient.post(`${this.baseUrl}/createWithImage`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        }).then(
          (data) => new MassiveSendingEntity(data.massiveSending)
        );
    }

    static createSending(massiveSending: MassiveSendingEntity): Promise<MassiveSendingEntity> {
        if (massiveSending.dateOfSending !== null)
            massiveSending.dateOfSending = this.handleTime(massiveSending.dateOfSending as Date);
        return ApiClient.post(`${this.baseUrl}/create`, { massiveSending }, undefined).then(
          (data) => new MassiveSendingEntity(data.massiveSending)
        );
    }

    static updateSendingWithImage(massiveSending: MassiveSendingEntity): Promise<MassiveSendingEntity> {
        let formData = new FormData();
        if (massiveSending.dateOfSending !== null)
            massiveSending.dateOfSending = this.handleTime(massiveSending.dateOfSending as Date);
        formData.append("command", JSON.stringify(massiveSending));
        if (massiveSending.image) {
            formData.append("image", massiveSending.image, massiveSending.image.name);
        }
        return ApiClient.post(`${this.baseUrl}/updateWithImage`, formData, {
            headers: { "Content-Type": "multipart/form-data" }
        }).then(
          (data) => new MassiveSendingEntity(data.massiveSending)
        );
    }

    static updateSending(massiveSending: MassiveSendingEntity): Promise<MassiveSendingEntity> {
        if (massiveSending.dateOfSending !== null)
            massiveSending.dateOfSending = this.handleTime(massiveSending.dateOfSending as Date);
        return ApiClient.put(`${this.baseUrl}/update`, { massiveSending }, undefined).then(
          (data) => new MassiveSendingEntity(data.massiveSending)
        );
    }

    static deleteSending(sendingId: string) : Promise<any> {
        return ApiClient.remove(`${this.baseUrl}/delete?id=${sendingId}`, undefined);
    }
}