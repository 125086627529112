import React from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import moment from "moment";

import { CrossIcon } from "../../../assets/icons";

import useStyles from "./css";
import { BusyScheduleQuestionModalProps } from "./props";
import { useAppSelector } from "../../../redux/hooks";

const BusyScheduleQuestionModal = (props: BusyScheduleQuestionModalProps) => {
  const { open, appointmentBusyModalData, onClose, onContinue, appointment } = props;
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  const localeApp = useAppSelector(state => state.applicationInterface.locale);

  const date = (startDate: Date, duration: number): string => {
    const start = new Date(startDate);
    const end = moment(start).add(duration, 'm').toDate();

    let weekDay = start.toLocaleString(localeApp, {
      weekday: "long",
    });

    weekDay = `${weekDay.charAt(0).toUpperCase()}${weekDay.slice(1)}`;

    const monthDay = start.toLocaleString(localeApp, {
      day: "2-digit",
      month: "long",
    });
  
    const startTime = start.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  
    const endTime = end.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  
    return `${weekDay}, ${monthDay} \u00B7 ${startTime} - ${endTime}hrs`;
  };

  const getDuration = (duration: number): string => {
    const seconds = duration * 60;
    const hour = Math.floor(seconds / 3600);
    const minute = Math.floor((seconds % 3600) / 60);
    const hourDisplay = hour > 0 ? `${hour} ${hour === 1 ? "hr" : "hrs"} ` : "";
    const minuteDisplay = minute > 0 ? `${minute} min` : "";
    const format = hourDisplay + minuteDisplay;
    return format.trim();
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <div className={classes.modalHeader}>
            <div className={classes.modalClose}>
              <CrossIcon
                className={classes.closeButton}
                onClick={onClose}
                viewBox="0 0 14 14"
                style={{ width: 14, height: 14 }}
              />
            </div>
          </div>
          <div className={classes.modalContent}>
            <div
              className={`${classes.modalCenter} ${classes.modalTitleSpace}`}
            >
              <Typography className={classes.modalTitleText}>
                {t("There are already scheduled appointments at this time, do you want to continue?")}
              </Typography>
            </div>
            <div className={classes.modalCenter}>
              <div className={classes.separator} style={{ width: 217 }}></div>
            </div>
            <div className={classes.modalCenter}>
              <Typography className={classes.modalContentText}>
                {(appointmentBusyModalData?.customerName) + (" - ") + (appointmentBusyModalData?.serviceName)}
              </Typography>
            </div>
            <div className={classes.modalCenter}>
              <Typography className={classes.modalDateText}>
                {`${date(
                  appointmentBusyModalData?.startData ?? new Date(),
                  appointmentBusyModalData?.appointmentDuration ?? 0
                )} (${getDuration(appointmentBusyModalData?.appointmentDuration ?? 0)})`}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={onClose}
              >
                {t("Go back")}
              </Button>
              <Button
                className={`${classes.button} ${classes.deleteAppointment}`}
                onClick={() => {
                  if (onContinue) {
                    onContinue();
                  }
                }}
              >
                {t("Continue")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default BusyScheduleQuestionModal;
