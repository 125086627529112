import React from 'react';

import { useTranslation } from "react-i18next";

import { multipleBlockedScheduleActions } from "../../../redux/multiple-blocked-schedule-slice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { blockedScheduleActions } from "../../../redux/blocked-schedule-slice";

import { Backdrop, Fade, Modal } from "@material-ui/core";

import useStyles from "./css";
import { CrossIcon, DeleteIcon } from "../../../assets/icons";
import BlockSchedule from "../../../models/blocked-schedule";


export default function MultipleBlockedScheduleModal() {

  const state = useAppSelector((state) => state.multipleBlockedSchedule);
  const { isModalOpen, blockerCount, blockers } = state;

  const dispatch = useAppDispatch();

  const classes = useStyles();
  const { t } = useTranslation("general");

  const dateFormat = (date: Date): string => {
    const formatDate = date.toTimeString().split(" ")[0].split(":");
    return `${formatDate[0]}:${formatDate[1]}`;
  };

  function handleOpenSpecificBlockerModal(appointmentId: string) {
    dispatch(blockedScheduleActions.setShowModal(true));
    dispatch(blockedScheduleActions.setBlockedSheduleId(appointmentId));
  }

  function handleCloseModal() {
    dispatch(multipleBlockedScheduleActions.resetState())
  }

  function mapAppointmentBlockerToDisplayBlock(blockSchedule: BlockSchedule) {
    return (
      <div
        className={classes.blocker}
        key={blockSchedule.id}
        onClick={() => handleOpenSpecificBlockerModal(blockSchedule.id!)}
      >
        <div>
          <span style={{ display: "inline-block", marginRight: 5 }}>
            <DeleteIcon color={"rgba(50, 57, 71, 0.76);"} />
          </span>
          <span className={classes.blockerReason}>
            {blockSchedule.isBlockedReasonPredetermined ? t(blockSchedule.blockedReason!) : blockSchedule.blockedReason}
          </span>
        </div>

        <div className={classes.blockerTimeRow}>
          {
            blockSchedule.allDay
              ?
              t("All day")
              :
              dateFormat(blockSchedule.start.toJSDate()) +
              " - " +
              dateFormat(blockSchedule.end.toJSDate())
          }
        </div>
        <div className={classes.blockerSpecialist}>
          {blockSchedule.specialist}
        </div>
      </div>
    );
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isModalOpen}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={isModalOpen}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <div className={classes.modalTitle}>
              {blockerCount + " " + t("blocked schedules")}
            </div>
            <span className={classes.closeButton} onClick={handleCloseModal}>
                <CrossIcon color={"#919CA5"} />
              </span>
          </div>
          <div className={classes.content}>
            <div className={classes.blockers}>
              {blockers.map(mapAppointmentBlockerToDisplayBlock)}
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}