import * as React from "react";
import { DateTime } from 'luxon';
import AppointmentScheduler, {
    OnSchedulerScrollChangedHandler,
    SchedulerScrollController,
} from '../AppointmentsScheduler';
import {
    ContentInnerWrapperComponent,
    GetItemDataFunction,
} from '../AppointmentsScheduler/Content';
import { KeyGetterFunction } from '../AppointmentsScheduler/_util';
import moment from 'moment';
import { memo, useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from "../../../../redux/hooks";
import AppointmentItem from '../AppointmentItem';
import ColumnDecorations from '../ColumnDecorations';
import Cursor from '../Cursor';
import StopComponent from '../AppointmentsScheduler/StopComponent';
import {
    AppointmentGridItem,
    createAbsencesGridItemsForDay,
    createAppointmentsGridItems,
    groupAppointments,
} from '../_common';
import useSchedulerCommonStyles from '../css';
import AppointmentsHeaderCellComponent from './HeaderCellComponent';
import TdComponent from '../TdComponent';
import { DaySchedulerDateContext } from './_context';
import { Appointment } from "../../../../models/appointment";
import BlockSchedule from "../../../../models/blocked-schedule";
import { selectAccountSettings } from "../../../../redux/store";
import { SelectItem } from "../../Select";
import AppointmentGroupingCorner from "../AppointmentGroupingCorner";

const getAppointmentKey: KeyGetterFunction<AppointmentGridItem> = (a) => a.key;
const getAppointmentGroupKey: KeyGetterFunction<AppointmentGridItem> = (a) => a.groupKey;
const getGroupKey: KeyGetterFunction<string> = (d) => d;
const getAppointmentData: GetItemDataFunction<AppointmentGridItem> = (a) => {
    return {
        ts: a.startsAt,
        sizeInMinutes: a.duration,
    };
};

export type DaySchedulerProps = {
    appointments: Appointment[];
    stopsInterval: number;
    dateReference: Date;
    absences: BlockSchedule[];
};

const ContentInnerWrapper: ContentInnerWrapperComponent = memo(({ children }) => {
    return (
        <>
            {children}
            <Cursor />
        </>
    );
});

export default function DayScheduler({ appointments, stopsInterval, dateReference, absences }: DaySchedulerProps) {
    const accountSettings = useAppSelector(selectAccountSettings);
    const selectedEstablishments = useAppSelector(state => state.enterprise.selectedEstablishments);
    const allUsers = useAppSelector((state) => state.scheduler.allUsers);
    const selectedUsers = useAppSelector((state) => state.scheduler.selectedUsers);
    const isGrouping = useAppSelector((state) => state.scheduler.isGrouping);
    
    const isEnterprise = accountSettings.isEnterprise === true && accountSettings.establishments.length > 0;
    const appointmentGroups = useMemo(() => groupAppointments(appointments, (app) => isEnterprise ? app.establishmentId! : app.userId, isGrouping), [appointments, isGrouping, isEnterprise]);
    const [scrollController, setScrollController] = useState<SchedulerScrollController | null>(
        null
    );
    const styles = useSchedulerCommonStyles();
    const [from, to] = [0, 24 * 60];
    const scrolledRef = useRef(false);
    
    useEffect(() => {
        if (!scrollController) return;
        if (scrolledRef.current) return;
        scrolledRef.current = true;
        const now = moment();
        scrollController.scrollAtCenterInMinutes(now.hour() * 60 + now.minute() - from);
    }, [scrollController, from]);
    
    let groups: string[];
    if (isEnterprise) {
        groups = accountSettings.establishments
          .filter(es => !selectedEstablishments || selectedEstablishments.length === 0 || selectedEstablishments.includes(es.key))
          .sort(compareEstablishments)
          .map((x) => x.key)
    } else {
        groups = selectedUsers?.length ? selectedUsers : allUsers.map((x) => x.key);
    }
    
    function compareEstablishments(a: SelectItem, b: SelectItem) {
        if (a.value < b.value) {
            return -1;
        }
        if (a.value > b.value) {
            return 1;
        }
        return 0;
    }
    
    const gridItems = useMemo(() => {
        const items: AppointmentGridItem[] = [
            ...createAbsencesGridItemsForDay(absences, DateTime.fromJSDate(dateReference), groups, isEnterprise),
            ...createAppointmentsGridItems(appointmentGroups),
        ];
        return items;
    }, [absences, dateReference, appointmentGroups, groups]);

    return (
        <DaySchedulerDateContext.Provider value={dateReference.toISOString()}>
            <AppointmentScheduler<AppointmentGridItem, string>
                groups={groups}
                classes={{ root: styles.root }}
                showTimezone={false}
                items={gridItems}
                getItemKey={getAppointmentKey}
                getItemGroupKey={getAppointmentGroupKey}
                getItemData={getAppointmentData}
                getGroupKey={getGroupKey}
                components={{
                    StopComponent,
                    HeaderCellComponent: AppointmentsHeaderCellComponent,
                    ItemComponent: AppointmentItem,
                    ContentGridColumnComponent: ColumnDecorations,
                    ContentInnerWrapperComponent: ContentInnerWrapper,
                    CornerComponent: AppointmentGroupingCorner,
                    ContentGridTdComponent: TdComponent,
                }}
                range={[from, to]} // full day
                sizes={{
                    columnWidth: 150,
                    headerHeight: 50,
                    timelineWidth: 120,
                    pixelsPerMinute: (1.8 * 30) / stopsInterval,
                }}
                stopsInterval={stopsInterval}
                scrollControllerRef={setScrollController}
            />
        </DaySchedulerDateContext.Provider>
    );
}
