import React from "react";

import MuiSwitch from "@material-ui/core/Switch";

import useStyles from "./css";
import { SwitchProps } from "./props";

const Switch = (props: SwitchProps) => {
    const { value, disabled, onChange, baseClass } = props;
    const classes = useStyles();

    const handleOnChange = (event: any) => {
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <MuiSwitch
            focusVisibleClassName={classes.focusVisible}
            disableRipple
            classes={{
                root: classes.root,
                switchBase: baseClass || classes.switchBase,
                thumb: classes.thumb,
                track: classes.track,
                checked: classes.checked,
            }}
            onChange={handleOnChange}
            checked={value}
            disabled={disabled}
        />
    );
};

export default Switch;
