import React from "react";
import { useTranslation } from "react-i18next";
import { InfoCircleIcon } from "../../../assets/icons";
import { useAppSelector } from "../../../redux/hooks";

import useStyles from "./css";

const AccountOverdueMessage = () => {
  
  const classes = useStyles();
  const { t } = useTranslation("general");

  const isAccountOverdue = useAppSelector(state => state.applicationInterface.isAccountOverdue);

  return (<>
    {
    !isAccountOverdue ? <></> : <>
    <div className={classes.messageBack}>
    </div>
    <div className={classes.message}>
        <div className={classes.messageIcon}>
            <InfoCircleIcon style={{ width: 15, height: 15 }} />
        </div>
        <div className={classes.messageLabel} >
            {t("Your account will be suspended for non-payment. For more information, contact your Account Executive.")}
        </div>
    </div></>
    }
</>);
};

export default AccountOverdueMessage;
