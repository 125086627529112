import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    paddingLeft: 10,
    gap: 10,
  },
  separator: {
    paddingRight: 15,
    marginRight: 15,
    borderRightColor: theme.colors.grey2,
    borderRightWidth: 1,
    borderRightStyle: "solid",
  },
  dateNavigator: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "left",
    alignItems: "center",
    "&>svg": {
      margin: 12
    }
  },
  dateNavigatorText: {
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: theme.colors.grey4,
    whiteSpace: "nowrap"
  },
  dateNavigatorDayText: {
    textTransform: "lowercase",
    "&:first-letter":{
      textTransform: "uppercase"
    }
  },
  dateNavigatorDirectionButton: {
    padding: "12px 14px"
  },
  users: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  multiSelect: {
    ...theme.typography.txtBody2,
    height: 32,
    border: "solid 1px",
    borderColor: theme.colors.clearMain,
    borderRadius: 5,
    width: 205,
    "& .MuiSelect-select": {
      paddingRight: 34
    },
  },
  select: {
    ...theme.typography.txtBody2,
    height: 32,
    width: 96,
    border: "solid 1px",
    borderColor: theme.colors.clearMain,
    borderRadius: 5,
    "& .MuiPaper-rounded": {
      borderRadius: 10,
      marginTop: 5
    },
    "& .MuiPaper-rounded .MuiListItem-root.Mui-selected:not(:hover)": {
      backgroundColor: "white",
    },
    "& .MuiPaper-rounded .MuiListItem-root": {
      fontSize: 12,
    },
    "& .MuiPaper-rounded .MuiListItem-root:hover": {
      backgroundColor: theme.colors.grey1
    },
    "& .MuiSelect-select": {
      paddingRight: 34
    },
  },
  input: {
    height: "32px !important",
    backgroundColor: "white !important",
    borderColor: theme.colors.clearMain + " !important",
    "& input": {
      color: theme.colors.clearMain + " !important",
    },
  },
  selected: {
    fontWeight: "bold",
    padding: "8px 0px 8px 15px",
    color: theme.colors.clearMain
  },
  placeholder: {
    color: "#676767",
    opacity: "0.42",
    padding: "8px 0px 8px 15px",
  },
  focused: {
    "&:focus": {
      backgroundColor: theme.colors.grey1,
    },
    "&.MuiSelect-select": {
      "&:focus": {
        borderRadius: 5,
      },
    },
  },
  button: {
    height: 32,
    borderRadius: 5,
    padding: "0 20px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: "15px",
    whiteSpace: "nowrap"
  },
  newAppointment: {
    background: "#7175FA",
    color: "#F6F6F6",
    "&:hover": {
      background: "#6462F3",
    },
  },
  goToday: {
    marginLeft: 7,
    marginRight: 8,
    color: theme.colors.grey4,
    border: "solid 1px rgba(0, 0, 0, 0.1)",
  },
  establishmentSelectorWrapper: {
    "& .MuiInput-root": {
      borderColor: theme.colors.clearMain + " !important",
    }
    },
  viewSelect: {
    width: 100
  },
  appointmentsSelect: {
    width: 150
  },
  specialistsSelect: {
    width: 200
  },
}));

export default useStyles;
