import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { setAccountSettings, setIsCashRegisterActivated } from "../../../../../redux/account-settings-slice";

import { Backdrop, Button, Fade, FormControlLabel, Modal, Radio, RadioGroup } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import SwitchWithLabel from "../../../../common/SwitchWithLabel";
import InputWithLabel from "../../../../common/InputWithLabel";
import { SettingsPaymentService } from "../../../../../api/settings-payment-service";
import SelectWithLabel from "../../../../common/SelectWithLabel";
import { BankIcon, CashIcon, CreditCardIcon, CrossIcon, DeleteIcon, ExclamationIcon, InfoIcon, MercadoPagoIcon, OxxoIcon, PlusIcon } from "../../../../../assets/icons";
import CheckboxWithLabel from "../../../../common/CheckboxWithLabel";
import Input from "../../../../common/Input";
import { SelectItem } from "../../../../common/Select";
import IconTooltip from "../../../../common/IconTooltip";
import { BankTransferInfo } from "../../../../../models/bank-transfer-info";
import ConfirmDeleteModal from "../../../../common/ConfirmDeleteModal";
import { PaymentSettingsModel } from "../../../../../models/payment-settings-model";
import { PaymentFieldUpdate } from "../../../../../models/enums/payment-field-update";
import { BankTransferType } from "../../../../../models/enums/bank-transfer-type";
import { Currency } from "../../../../../models/currency";
import InputCurrency from "../../../../common/InputCurrency";
import { NameRegexV2 } from "../../../../../constants/validator";
import useCurrencyValueTemplate from "../../../../../hooks/useCurrencyValueTemplate";
import useStyles from "./css";
import ExclamationModal from "../../../../common/ExclamationModal";
import { navbarActions } from "../../../../../redux/navbar-slice";
import { selectAccountSettings, selectUserInfo } from "../../../../../redux/store";
import { SettingsEnterpriseProps } from "../../../../../models/interfaces/settings-enterprise-props";
import SectionNote from "../../../../establishment-settings/Sections/FormsSettingsTabs/SectionNote";
import { SectionNoteData } from "../../../../../models/enums/section-note-data";


export default function PaymentMain(props: SettingsEnterpriseProps) {

  const { settingsSelectedEstablishmentId } = props;

  const { t } = useTranslation(["general", "settings"]);
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const accountSettings = useAppSelector(selectAccountSettings);
  const [currencies, setCurrencies] = useState<SelectItem[]>([]);
  const [currentCurrency, setCurrentCurrency] = useState<Currency>(new Currency());
  const [bankTransfer, setBankTransfer] = useState<BankTransferInfo[]>([]);
  const [daysAfter, setDaysAfter] = useState<SelectItem[]>([]);
  const [transferType, setTransferType] = useState<SelectItem[]>([]);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openForbiddenSaveDialog, setOpenForbiddenSaveDialog] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<any | null>(null);
  const [entity, setEntity] = useState<PaymentSettingsModel>(new PaymentSettingsModel());
  const [isAddExtraCost, setIsAddExtraCost] = useState<string>("1");
  const [valueTemplate, setValueTemplate] = useState<string>("{0}");
  
  const [isExclamationModalOpen, setExclamationModalOpen] = useState<boolean>(false);

  const templatedValue = useCurrencyValueTemplate(valueTemplate);

  const userInfo = useAppSelector(selectUserInfo);
  const enterpriseSelected = userInfo.accountId === settingsSelectedEstablishmentId;

  useEffect(() => {

    (async () => {
      await getPaymentsSettings();
    })();

    getDaysAfter();
    getTransferType();

  }, [settingsSelectedEstablishmentId])

  useEffect(() => {
    if (entity.currencyId) {
      const newCurrency = entity.currencies.find(x => x.id === entity.currencyId)
      setCurrentCurrency(newCurrency ?? new Currency());
      dispatch(setAccountSettings({...accountSettings, currencyTemplate : newCurrency?.valueTemplate ?? "{0}"}))
    }
  }, [entity.currencyId])


  const getDaysAfter = () => {
    let listItems: SelectItem[] = [];
    listItems.push({ key: 1, value: t("1 day after") });
    listItems.push({ key: 2, value: t("2 days after") });
    listItems.push({ key: 3, value: t("3 days after") });
    setDaysAfter(listItems);
  }

  const getTransferType = () => {
    let listItems: SelectItem[] = [];
    listItems.push({ key: 1, value: t("Card number and interbank CLABE") });
    listItems.push({ key: 5, value: t("Card number, account number and interbank CLABE") });
    listItems.push({ key: 4, value: t("Account number") });
    listItems.push({ key: 2, value: t("Card number") });
    listItems.push({ key: 3, value: t("Interbank CLABE") });
    setTransferType(listItems);
  }


  const getPaymentsSettings = async () => {
    dispatch(navbarActions.setShowLoader(true));
      const info = settingsSelectedEstablishmentId 
        ? await SettingsPaymentService.getPaymentsSettings(settingsSelectedEstablishmentId)
        : await SettingsPaymentService.getPaymentsSettings();
    dispatch(navbarActions.setShowLoader(false));
    
    if (info.currencies)
      await setCurrenciesSelectItems(info.currencies);

    if (info.isPaymentBankTransfer && info.bankTransfers.length === 0) {
      handleAddInterbank();
    }
    else {
      setBankTransfer(info.bankTransfers);
    }
    setEntity(info);
    setIsAddExtraCost(info.hasExtraCostMercadoPago ? "1" : "0");

    if (info.currencyTemplate != null)
      setValueTemplate(info.currencyTemplate);
  }


  const handleChangeCurrency = async (event: React.ChangeEvent<{ value: string }>) => {
    const value = event.target.value;

    const updating: PaymentSettingsModel = {
      ...entity,
      currencyId: value,
      fieldUpd: PaymentFieldUpdate.Currency
      }
    updating.accountId = (settingsSelectedEstablishmentId && !enterpriseSelected) ? settingsSelectedEstablishmentId : "";
    const result = await SettingsPaymentService.saveCurrency(updating);

    if (result) {
      const currencySel = currencies?.find(c => { return c.key === value });
      if (currencySel)
        setEntity(updating);
    }
  };

  const savePaymentsSettings = async (updating: PaymentSettingsModel): Promise<boolean> => {
    updating.accountId = (settingsSelectedEstablishmentId && !enterpriseSelected) ? settingsSelectedEstablishmentId : "";
    const result = await SettingsPaymentService.savePaymentsSettings(updating);

    if (result.saved) {
      setEntity(updating);
    }
    else {
      if (result.forbbidenSave)
        setOpenForbiddenSaveDialog(true);
    }

    return result.saved;
  }

  const handleSetPaymentsInCash = async (value: boolean) => {
    const updating: PaymentSettingsModel = {
      ...entity,
      isPaymentCash: value,
      fieldUpd: PaymentFieldUpdate.IsPaymentCash
    }

    await savePaymentsSettings(updating);
  }


  const handleSetPaymentsCreditCard = async (value: boolean) => {
    let updating: PaymentSettingsModel;

    if (!value) {
      updating = {
        ...entity,
        isPaymentCard: false,
        isPaymentCardMasterCard: false,
        isPaymentCardVisa: false,
        isPaymentCardOther: false,
        otherPaymentCardName: "",
        fieldUpd: PaymentFieldUpdate.IsPaymentCard
      };
    }
    else {
      updating = {
        ...entity,
        isPaymentCard: value,
        fieldUpd: PaymentFieldUpdate.IsPaymentCard
      };
    }

    await savePaymentsSettings(updating);
  }


  const handleMasterCard = async (value: boolean) => {
    const updating: PaymentSettingsModel = {
      ...entity,
      isPaymentCardMasterCard: value,
      fieldUpd: PaymentFieldUpdate.IsPaymentCardMasterCard
    }

    await savePaymentsSettings(updating);
  }


  const handleVisa = async (value: boolean) => {
    const updating: PaymentSettingsModel = {
      ...entity,
      isPaymentCardVisa: value,
      fieldUpd: PaymentFieldUpdate.IsPaymentCardVisa
    }

    await savePaymentsSettings(updating);
  }


  const handleOtherPaymentSystem = async (value: boolean) => {
    if (!value) {
      const updating: PaymentSettingsModel = {
        ...entity,
        isPaymentCardOther: value,
        otherPaymentCardName: "",
        fieldUpd: PaymentFieldUpdate.IsPaymentCardOther
      }
      await savePaymentsSettings(updating);
    }
    else {
      setEntity({
        ...entity,
        isPaymentCardOther: value,
      });
    }
  }

  const handlePaymentSystemNameChange = async (event: React.ChangeEvent<{ value: string }>) => {
    const value = event.target.value.replace("  ", " ");
    if (!NameRegexV2.test(value)) return;

    const updating: PaymentSettingsModel = {
      ...entity,
      otherPaymentCardName: value,
      fieldUpd: PaymentFieldUpdate.IsPaymentCardOther
    }
    setEntity(updating);
  }

  const handlePaymentSystemNameBlur = async () => {
    const updating: PaymentSettingsModel = {
      ...entity,
      fieldUpd: PaymentFieldUpdate.IsPaymentCardOther
    }
    await savePaymentsSettings(updating);
  }

  async function handleSetPaymentsBankTransfer(value: boolean) {
    const updating: PaymentSettingsModel = {
      ...entity,
      isPaymentBankTransfer: value,
      fieldUpd: PaymentFieldUpdate.IsPaymentBankTransfer
    }

    const result = await savePaymentsSettings(updating);
    if (result) {
      setEntity(updating);

      if (!value)
        setBankTransfer([]);

      if (value && bankTransfer.length === 0)
        handleAddInterbank();
    }
  }


  const handleBankTransfer = async (item: BankTransferInfo, e: any) => {
    let transfer = bankTransfer;
    const itemIndex = transfer.findIndex(x => x.index === item.index);
    const value = e.target.value;

    if (e.target.name === "bank") {
      transfer[itemIndex].bankName = value;
      transfer[itemIndex].bankNameValid = value.toString().trim().length > 0
    }

    if (e.target.name === "cardNumber") {
      transfer[itemIndex].cardNumber = value;
      transfer[itemIndex].cardNumberValid = value.toString().trim().length > 0
    }

    if (e.target.name === "clabe") {
      transfer[itemIndex].interbankClabe = value;
      transfer[itemIndex].interbankClabeValid = value.toString().trim().length > 0
    }
    if (e.target.name === "accountNumber") {
      transfer[itemIndex].accountNumber = value;
      transfer[itemIndex].accountNumberValid = value.toString().trim().length > 0
    }

    if (e.target.name === "transferType") {
      transfer[itemIndex].bankTransferType = value;
      if (value === 2) transfer[itemIndex].interbankClabe = "";
      if (value === 3) transfer[itemIndex].cardNumber = "";
      await handleBankTransferSave(transfer[itemIndex]);
    }

    setBankTransfer([...transfer]);
  }

  const handleBankTransferSave = async (item: BankTransferInfo) => {
    let cardNumberValid = item?.cardNumber && item.cardNumber?.trim().length > 0;
    let accountNumberValid = item?.accountNumber && item.accountNumber?.trim().length > 0;
    let interbankClabeValid = item?.interbankClabe && item.interbankClabe?.trim().length > 0;

    if (item.bankName !== "" &&
      (( item.bankTransferType === BankTransferType.CardNumberClabe && cardNumberValid && interbankClabeValid) ||
        (item.bankTransferType === BankTransferType.CardNumber && cardNumberValid) ||
        (item.bankTransferType === BankTransferType.Clabe && interbankClabeValid) ||
        (item.bankTransferType === BankTransferType.AccountNumber && accountNumberValid) ||
        (item.bankTransferType === BankTransferType.CardNumberAccountNumberClabe && cardNumberValid && interbankClabeValid && accountNumberValid))
    ) {

      item.accountId = (settingsSelectedEstablishmentId && !enterpriseSelected) ? settingsSelectedEstablishmentId : null;
      const result = await SettingsPaymentService.saveBankTransfer(item);

      let transfers = bankTransfer;
      const itemIndex = transfers.findIndex(x => x.index === item.index);
      transfers[itemIndex] = result;
      transfers[itemIndex].bankNameValid = true;
      transfers[itemIndex].cardNumberValid = true;
      transfers[itemIndex].interbankClabeValid = true;
      transfers[itemIndex].accountNumberValid = true;
      setBankTransfer([...transfers]);
    }

  }


  const handleOxxoCheck = async (value: boolean) => {
    if (!value) {
      const updating: PaymentSettingsModel = {
        ...entity,
        isPaymentOxxo: value,
        oxxoCardNumber: "",
        fieldUpd: PaymentFieldUpdate.IsPaymentOxxo
      }

      const result = await savePaymentsSettings(updating);
      if (result) setEntity(updating);
    }
    else {
      setEntity({
        ...entity,
        isPaymentOxxo: value,
      });
    }
  }


  const handleOxxoChange = async (event: React.ChangeEvent<{ value: string }>) => {
    const updating: PaymentSettingsModel = {
      ...entity,
      oxxoCardNumber: event.target.value,
      fieldUpd: PaymentFieldUpdate.IsPaymentOxxo
    }
    setEntity(updating);
  }


  const handleOxxoBlur = async () => {
    if (entity.isPaymentOxxo && entity.oxxoCardNumber?.trim().length !== 0) {
      const updating: PaymentSettingsModel = {
        ...entity,
        fieldUpd: PaymentFieldUpdate.IsPaymentOxxo
      }
      await savePaymentsSettings(updating);
    }
  }


  const handleMercadoPagoCheck = async (value: boolean) => {
    if (!value) {
      const updating: PaymentSettingsModel = {
        ...entity,
        isPaymentMercadoPago: value,
        hasAcceptedMercadoPagoTerms: false,
        fieldUpd: PaymentFieldUpdate.IsPaymentMercadoPago
      }
      await savePaymentsSettings(updating);
    }
    else {
      setEntity({
        ...entity,
        isPaymentMercadoPago: value,
      });
    }
  }

  const handleMercadoPagoTerms = async (value: boolean) => {
    const updating: PaymentSettingsModel = {
      ...entity,
      hasAcceptedMercadoPagoTerms: value,
      fieldUpd: PaymentFieldUpdate.IsPaymentMercadoPago
    }
    await savePaymentsSettings(updating);
  }

  const handleExtraCostMercadoPago = async (value: string) => {
    const updating: PaymentSettingsModel = {
      ...entity,
      hasExtraCostMercadoPago: value === "1",
      fieldUpd: PaymentFieldUpdate.IsPaymentMercadoPago
    }
    const result = await savePaymentsSettings(updating);

    if (result)
      setIsAddExtraCost(value);
  }

  const handleAppointmentConfirmation = async (value: boolean) => {
    const updating: PaymentSettingsModel = {
      ...entity,
      hasWhatsAppPaymentReminderOnAppointmentConfirmation: value,
      fieldUpd: PaymentFieldUpdate.HasWhatsAppPaymentReminderOnAppointmentConfirmation
    }
    updating.accountId = (settingsSelectedEstablishmentId && !enterpriseSelected) ? settingsSelectedEstablishmentId : "";
    const result = await SettingsPaymentService.saveWhatsAppPaymentSettings(updating);
    if (result) setEntity(updating);
  }
  
  const handleIsSendReceiptByWhatsApp = async (value: boolean) => {
    const updating: PaymentSettingsModel = {
      ...entity,
      isSendReceiptByWhatsApp: value,
      fieldUpd: PaymentFieldUpdate.IsSendReceiptByWhatsApp
    }
    updating.accountId = (settingsSelectedEstablishmentId && !enterpriseSelected) ? settingsSelectedEstablishmentId : "";
    const result = await SettingsPaymentService.saveWhatsAppPaymentSettings(updating);
    if (result) setEntity(updating);
  }


  const handleAppointmentReminder = async (value: boolean) => {
    const updating: PaymentSettingsModel = {
      ...entity,
      hasWhatsAppPaymentReminderOnAppointmentReminder: value,
      fieldUpd: PaymentFieldUpdate.HasWhatsAppPaymentReminderOnAppointmentReminder
    }
    updating.accountId = (settingsSelectedEstablishmentId && !enterpriseSelected) ? settingsSelectedEstablishmentId : "";
    const result = await SettingsPaymentService.saveWhatsAppPaymentSettings(updating);
    if (result) setEntity(updating);
  }


  const handleAppointmentAfter = async (value: boolean) => {
    const updating: PaymentSettingsModel = {
      ...entity,
      hasWhatsAppPaymentReminderAfterAppointment: value,
      fieldUpd: PaymentFieldUpdate.HasWhatsAppPaymentReminderAfterAppointment
    }
    updating.accountId = (settingsSelectedEstablishmentId && !enterpriseSelected) ? settingsSelectedEstablishmentId : "";
    const result = await SettingsPaymentService.saveWhatsAppPaymentSettings(updating);
    if (result) setEntity(updating);
  }

  const handleDaysAppointmentAfter = async (e: any) => {
    const updating: PaymentSettingsModel = {
      ...entity,
      daysAfterAppointmentToSendWhatsAppPaymentReminder: e.target.value,
      fieldUpd: PaymentFieldUpdate.HasWhatsAppPaymentReminderAfterAppointment
    }
    updating.accountId = (settingsSelectedEstablishmentId && !enterpriseSelected) ? settingsSelectedEstablishmentId : "";
    const result = await SettingsPaymentService.saveWhatsAppPaymentSettings(updating);
    if (result) setEntity(updating);
  }

  const handlCashCheck = async (value: boolean) => {
    if (!value) {
      const updating: PaymentSettingsModel = {
        ...entity,
        isCashRegisterActivated: value,
        // initialCashInCashRegister: initialCashDefaultValue,
        fieldUpd: PaymentFieldUpdate.IsCashRegisterActivated
      }
      updating.accountId = (settingsSelectedEstablishmentId && !enterpriseSelected) ? settingsSelectedEstablishmentId : "";
      const result = await SettingsPaymentService.saveCashRegister(updating);
      if (result) {
        setEntity(updating);
        dispatch(setIsCashRegisterActivated(value))
      }
    }
    else {
      const updating: PaymentSettingsModel = {
        ...entity,
        isCashRegisterActivated: value,
        fieldUpd: PaymentFieldUpdate.IsCashRegisterActivated
      }
      if (entity.initialCashInCashRegister !== null && entity.initialCashInCashRegister >= 0) {
        updating.accountId = (settingsSelectedEstablishmentId && !enterpriseSelected) ? settingsSelectedEstablishmentId : "";
        const result = await SettingsPaymentService.saveCashRegister(updating);
        if (result) setEntity(updating);
      }
      else {
        setEntity(updating);
      }
    }
  }

  function setInitialCash(value: number) {
    const newModel: PaymentSettingsModel = {
      ...entity,
      initialCashInCashRegister: value,
      fieldUpd: PaymentFieldUpdate.IsCashRegisterActivated
    }
    setEntity(newModel);

  }

  const handleCashBlur = async (value?: number) => {
    if (value === undefined) return;
    if (value === entity.initialCashInCashRegister) return;

    const updating: PaymentSettingsModel = {
      ...entity,
      initialCashInCashRegister: value,
      fieldUpd: PaymentFieldUpdate.IsCashRegisterActivated
    }

    if (!entity.isCashRegisterActivated || (entity.isCashRegisterActivated && value !== null && +value >= 0)) {
      const result = await SettingsPaymentService.saveCashRegister(updating);
      if (result) setEntity(updating);
    }
  }

  const setCurrenciesSelectItems = async (currencyList: Currency[]) => {

    let listItems: SelectItem[] = [];
    if (currencyList === undefined)
      return listItems;

    currencyList.forEach(c => {
      listItems.push({
        key: c.id,
        value: t(c.name) + " (" + c.isoCode + ")"
      })
    });

    setCurrencies(listItems);
  }

  const handleAddInterbank = () => {
    if (bankTransfer.length >= 3) {
      setExclamationModalOpen(true);
      return;
    }
    const arrBankTransfer = bankTransfer;
    let newBankTransfer = new BankTransferInfo();
    newBankTransfer.index = bankTransfer.length === 0 ? 0 : Math.max(...bankTransfer.map(o => o.index)) + 1
    arrBankTransfer.push(newBankTransfer);
    setBankTransfer([...arrBankTransfer]);
  };

  const handleAskToRemoveBank = (bank: BankTransferInfo) => {
    setOpenDeleteDialog(true);
    setItemToDelete(bank);
  };

  const handleOnDelete = async (item: any) => {
    if (item?.bankTransferId === null) {
      const arrBankTransfer = bankTransfer.filter(data => data.index !== item.index);
      setBankTransfer(arrBankTransfer);
      setItemToDelete(null);
      setOpenDeleteDialog(false);
      return;
    }
    
    const result = await SettingsPaymentService.deleteBankTransfer(item);

    if (result) {
      const arrBankTransfer = bankTransfer.filter(data => data.index !== item.index);
      setBankTransfer(arrBankTransfer);
      setItemToDelete(null);
    }

    setOpenDeleteDialog(false);
  };

  const DivInfoDelete: React.FC = () => {
    return (<>
      <div className={classes.modalCenter} style={{ marginTop: 20 }}>
        <Typography className={classes.modalContentText}>
          {t("Delete info from this bank?")}
        </Typography>
      </div>
    </>);
  };

  return (
    <div>
      {settingsSelectedEstablishmentId && (
        <>
          <div className={classes.header}>
            <Typography className={classes.tabTitle}>
              {t("Payments")}
            </Typography>
          </div>
          <SectionNote 
            sectionData={SectionNoteData.Payments}
          />
        </>
      )}
      <div className={classes.block}>
        <div className={classes.title}>
          <Typography className={classes.title}>
            {t("Payment methods")}
          </Typography>
        </div>
        <div className={classes.contentArea}>
          <div className={classes.requiredFieldsHeader}>
            {t("Required fields")} <span className={classes.dot}>*</span>
          </div>
          <div className={classes.contentColumns}>
            <div className={classes.contentColumn}>
              <SelectWithLabel
                label={() => (
                  <>
                    {t("Currency")}
                  </>
                )}
                items={currencies}
                placeholder={t("Select a currency")}
                width={230}
                name="currencyid"
                onChange={handleChangeCurrency}
                value={entity.currencyId}
              />
            </div>
            <div className={classes.contentColumn} style={{ paddingLeft: 20 }}>
              <div className={classes.checkGroup}>
                <SwitchWithLabel
                  value={entity.isPaymentCash}
                  setValue={handleSetPaymentsInCash}
                  label={t("Cash")}
                  icon={() => <CashIcon style={{ color: entity.isPaymentCash ? "#6462F3" : null }} />}
                />
              </div>
              <div className={classes.checkGroup}>
                <SwitchWithLabel
                  value={entity.isPaymentCard}
                  setValue={handleSetPaymentsCreditCard}
                  label={t("Credit / debit cards")}
                  icon={() => <CreditCardIcon style={{ color: entity.isPaymentCard ? "#6462F3" : null }} />}
                />
                <IconTooltip
                  key={"cardToolTip"}
                  classNameRoot={classes.iconTooltipRoot}
                  placement={"right"}
                  arrowPlacement={"left"}
                  icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 10 }} viewBox={"0 0 12 12"} />}
                  tooltipText={t("You need to select an option")}
                />
              </div>
              {
                entity.isPaymentCard &&
                <div className={classes.creditCardTypes}>

                  <div style={{ marginRight: 30 }}>
                    <CheckboxWithLabel
                      checked={entity.isPaymentCardMasterCard}
                      setChecked={handleMasterCard}
                      label={t("MasterCard")}
                    />
                  </div>
                  <div style={{ marginRight: 20 }}>
                    <CheckboxWithLabel
                      checked={entity.isPaymentCardVisa}
                      setChecked={handleVisa}
                      label={t("Visa")}
                    />
                  </div>
                  <div className={classes.smallVerticalDivider} />
                  <div style={{ display: "flex" }}>
                    <CheckboxWithLabel
                      checked={entity.isPaymentCardOther}
                      setChecked={handleOtherPaymentSystem}
                      label={t("Other")}
                    />
                    <Input
                      inputClass={classes.otherCardTypeInput}
                      value={entity.otherPaymentCardName}
                      onChange={handlePaymentSystemNameChange}
                      onBlur={handlePaymentSystemNameBlur}
                      placeholder={t("Card type")}
                      disabled={!entity.isPaymentCardOther}
                    />
                  </div>
                </div>
              }
              <div className={classes.checkGroup} style={{ zIndex: 10 }}>
                <SwitchWithLabel
                  value={entity.isPaymentBankTransfer}
                  setValue={handleSetPaymentsBankTransfer}
                  label={t("Bank transfers")}
                  icon={() => <BankIcon style={{ color: entity.isPaymentBankTransfer ? "#6462F3" : null }} />}
                />
                <IconTooltip
                  key={"banktransfersToolTip"}
                  classNameRoot={classes.iconTooltipRoot}
                  placement={"right"}
                  arrowPlacement={"left"}
                  icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 10 }} viewBox={"0 0 12 12"} />}
                  tooltipText={t("You need to fill in the required fields")}
                />
              </div>
              {
                entity.isPaymentBankTransfer &&
                <div className={classes.bankTransfers}>
                  {bankTransfer.map((transfer, index) => (
                    <div style={{ display: "block", marginTop: -30, zIndex: 5 }}>
                      <div className={classes.deleteDiv}>
                        {index > 0 &&
                          <span className={classes.deleteButton} onClick={() => handleAskToRemoveBank(transfer)}>
                            <DeleteIcon />
                          </span>
                        }
                      </div>
                      <div style={{ display: "inline-block", margin: "10px 15px 20px 5px" }}>
                        <InputWithLabel
                          label={() => <>{t("BankLabel")} <span className={classes.dot}>*</span></>}
                          placeholder={t("Name of the bank")}
                          value={transfer.bankName}
                          width={336}
                          name="bank"
                          onChange={(e) => handleBankTransfer(transfer, e)}
                          onBlurInput={() => handleBankTransferSave(transfer)}
                          isValid={() => transfer.bankNameValid}
                        />
                      </div>
                      <div style={{ display: "inline-block", margin: "10px 0px 20px 0px" }}>
                        <SelectWithLabel
                          label={() => <>{t("Info to be sent")} <span className={classes.dot}>*</span></>}
                          items={transferType}
                          placeholder={t("Select information")}
                          width={336}
                          name="transferType"
                          onChange={(e) => handleBankTransfer(transfer, e)}
                          value={transfer.bankTransferType}
                        />
                      </div>

                      <div style={{ display: "inline-block", margin: "10px 15px 20px 5px" }}>
                        <InputWithLabel
                            label={() => <>{t("Account number")} <span className={classes.dot}>*</span></>}
                            placeholder={t("XXXXXXXXXX")}
                            value={transfer.accountNumber}
                            width={336}
                            name="accountNumber"
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            maxLength={100}
                            inputDisabled={!(transfer.bankTransferType === BankTransferType.AccountNumber || transfer.bankTransferType === BankTransferType.CardNumberAccountNumberClabe)}
                            onChange={(e) => handleBankTransfer(transfer, e)}
                            onBlurInput={() => handleBankTransferSave(transfer)}
                            isValid={() => transfer.accountNumberValid}
                        />
                      </div>
                      
                      <div style={{ display: "inline-block", margin: "10px 15px 20px 5px" }}>
                        <InputWithLabel
                          label={() => <>{t("Card number")} <span className={classes.dot}>*</span></>}
                          value={transfer.cardNumber}
                          placeholder={"XXXXXXXXXXXXXXXX"}
                          inputDisabled={!(transfer.bankTransferType === BankTransferType.CardNumberClabe || transfer.bankTransferType === BankTransferType.CardNumber || transfer.bankTransferType === BankTransferType.CardNumberAccountNumberClabe)}
                          onKeyPress={(event) => {
                            if (!/[0-9-]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={100}
                          width={336}
                          name="cardNumber"
                          onChange={(e) => handleBankTransfer(transfer, e)}
                          onBlurInput={() => handleBankTransferSave(transfer)}
                          isValid={() => transfer.cardNumberValid}
                        />
                      </div>
                      <div style={{ display: "inline-block", margin: "10px 0px 20px 0px" }}>
                        <InputWithLabel
                          label={() => <>{t("Interbank CLABE")} <span className={classes.dot}>*</span></>}
                          value={transfer.interbankClabe}
                          placeholder={"XXXXXXXXXXXXXXXXXX"}
                          inputDisabled={!(transfer.bankTransferType === BankTransferType.CardNumberClabe || transfer.bankTransferType === BankTransferType.Clabe || transfer.bankTransferType === BankTransferType.CardNumberAccountNumberClabe)}
                          onKeyPress={(event) => {
                            if (!/[0-9-]/.test(event.key)) {
                              event.preventDefault();
                            }
                          }}
                          maxLength={100}
                          width={336}
                          name="clabe"
                          onChange={(e) => handleBankTransfer(transfer, e)}
                          onBlurInput={() => handleBankTransferSave(transfer)}
                          isValid={() => transfer.interbankClabeValid}
                        />
                      </div>
                    </div>
                  ))}
                  <div style={{ display: "block" }}>
                    <Button
                      className={classes.addButton}
                      onClick={handleAddInterbank}
                    >
                      <PlusIcon />
                      {t("Add another")}
                    </Button>
                  </div>
                </div>
              }
              <div className={classes.checkGroup}>
                <SwitchWithLabel
                  value={entity.isPaymentOxxo}
                  setValue={handleOxxoCheck}
                  label={t("Payments with Oxxo")}
                  icon={() => <OxxoIcon style={{ color: entity.isPaymentOxxo ? "#6462F3" : null }} />}
                />
                <IconTooltip
                  key={"oxxoToolTip"}
                  classNameRoot={classes.iconTooltipRoot}
                  placement={"right"}
                  arrowPlacement={"left"}
                  icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 10 }} viewBox={"0 0 12 12"} />}
                  tooltipText={t("You need to fill in the required fields")}
                />
                {
                  entity.isPaymentOxxo &&
                  <div className={classes.creditCardTypes}>
                    <div style={{ marginTop: 30 }}>
                      <InputWithLabel
                        label={() => <>{t("Card number for deposits")} <span className={classes.dot}>*</span></>}
                        value={entity.oxxoCardNumber}
                        onChange={handleOxxoChange}
                        onBlurInput={handleOxxoBlur}
                        placeholder={"XXXXXXXXXXXXXXXX"}
                        disabled={!entity.isPaymentOxxo}
                        onKeyPress={(event) => {
                          if (!/[0-9-]/.test(event.key)) {
                            event.preventDefault();
                          }
                        }}
                        maxLength={100}
                        isValid={() => (true || entity.isPaymentOxxo && entity.oxxoCardNumber?.trim().length === 19)}
                      />
                    </div>
                  </div>
                }
              </div>
              {
                entity.isEnabledMercadoPagoByAdmin && <>
                  <div className={classes.checkGroup}>
                    <SwitchWithLabel
                      value={entity.isPaymentMercadoPago}
                      setValue={handleMercadoPagoCheck}
                      label={t("Payments with Mercado Pago")}
                      icon={() => <MercadoPagoIcon style={{ color: entity.isPaymentMercadoPago ? "#6462F3" : null }} />}
                    />
                    {
                      entity.isPaymentMercadoPago && <>
                        <div className={classes.creditCardTypes}>
                          <div style={{ marginTop: 30 }}>
                            <CheckboxWithLabel
                              checked={entity.hasAcceptedMercadoPagoTerms}
                              setChecked={handleMercadoPagoTerms}
                              checkboxClass={`${entity.hasAcceptedMercadoPagoTerms ? classes.checkboxMP : classes.checkboxMPRed}`}
                              label={<div className={`${classes.text} ${entity.hasAcceptedMercadoPagoTerms ? classes.textChecked : classes.textNoChecked}`}>
                                {t("You must accept the")}
                                <a
                                  href="https://www.mercadopago.com.mx/ayuda/T%C3%A9rminos-rendimientos_15581"
                                  target="_blank"
                                  className={`${entity.hasAcceptedMercadoPagoTerms ? classes.textChecked : classes.textNoChecked} ${classes.link}`}
                                  rel="noopener noreferrer">
                                  {t("terms and conditions to activate payments by WhatsApp link")}
                                </a>
                                {"."}
                              </div>
                              }
                            />
                          </div>
                        </div>
                        <div className={classes.creditCardTypes}>
                          <div>
                            <div className={classes.lineExtraCost} />
                            <div className={classes.extraCostTitle}>
                              {t("Extra cost to the total of the appointment for the use of Mercado Pago")}
                            </div>
                            <div className={classes.radioGroupItems}>
                              <RadioGroup
                                defaultValue={"1"}
                                name="addExtraCost"
                                onChange={(event) => {
                                  handleExtraCostMercadoPago(event.target.value)
                                }}
                                value={isAddExtraCost || true}
                                row
                              >
                                <div>
                                  <FormControlLabel
                                    classes={{
                                      root: classes.radioLabelRoot,
                                      label: classes.radioLabel,
                                    }}
                                    value={"0"}
                                    control={<Radio className={classes.radioRoot} />}
                                    label={t("Do not add extra cost.")}
                                  />
                                  <IconTooltip
                                    key={"mpFreeToolTip"}
                                    classNameRoot={classes.iconTooltipRoot}
                                    placement={"right"}
                                    arrowPlacement={"left"}
                                    icon={<InfoIcon style={{ width: 12, height: 12, marginLeft: 10, marginRight: 10 }} viewBox={"0 0 12 12"} />}
                                    tooltipText={t("You will pay the fee for using Mercado Pago")}
                                  />
                                </div>
                                <div>
                                  <FormControlLabel
                                    classes={{
                                      root: classes.radioLabelRoot,
                                      label: classes.radioLabel,
                                    }}
                                    value={"1"}
                                    control={<Radio className={classes.radioRoot} />}
                                    label={t("Add extra cost.")}
                                  />
                                  <IconTooltip
                                    key={"mpPayToolTip"}
                                    classNameRoot={classes.iconTooltipRoot}
                                    placement={"right"}
                                    arrowPlacement={"left"}
                                    icon={<InfoIcon style={{ width: 12, height: 12, marginLeft: 10, marginRight: 10 }} viewBox={"0 0 12 12"} />}
                                    tooltipText={t("Your customer will pay the fee for using Mercado Pago")}
                                  />
                                </div>
                              </RadioGroup>
                            </div>
                            <div className={classes.calcExtra}>
                              {t("Calculation of the extra cost per appointment:")}
                            </div>
                            <div>
                              <div className={classes.boxExtraCost}>
                                <div>
                                  <Typography className={classes.labelExtraCostMain}>
                                    {`${entity.extraPercentage || 0}%`}
                                  </Typography>
                                </div>
                                <div className={classes.labelExtraCost}>
                                  {t("Extra percentage")}
                                </div>
                              </div>
                              <div className={classes.boxExtraCostPlus}>
                                <PlusIcon />
                              </div>
                              <div className={classes.boxExtraCost}>
                                <div className={classes.labelExtraCost}>
                                  <div>
                                    <Typography className={classes.labelExtraCostMain}>
                                      {`${entity.extraAmount !== null ? templatedValue(entity.extraAmount) : templatedValue(0)}`}
                                    </Typography>
                                  </div>
                                  {t("Extra amount")}
                                </div>
                              </div>
                              <div className={classes.boxExtraCostPlus}>
                                <PlusIcon />
                              </div>
                              <div className={classes.boxExtraCost}>
                                <div>
                                  <Typography className={classes.labelExtraCostMain}>
                                    {`${entity.ivaPercentage || 0}%`}
                                  </Typography>
                                </div>
                                <div className={classes.labelExtraCost}>
                                  {t("IVA Percentage")}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>}
                  </div>
                </>
              }
            </div>
          </div>
        </div>
          </div>
          {(accountSettings.paymentsAndCollectionsEnabled || accountSettings.isEnterprise) &&
        <>
          <div className={classes.block}>
            <div className={classes.title}>
              <Typography className={classes.title}>
                {t("Communication with the customer about payments")}
              </Typography>
            </div>
            <div className={classes.contentArea}>
              <div className={classes.subtitle}>
                <Typography className={classes.title}>
                  {t("Send additional WhatsApp to make payments before the appointment")}
                </Typography>
              </div>
              <div className={classes.innerContent}>
                <div className={classes.checkGroup}>
                  <SwitchWithLabel
                    value={entity.hasWhatsAppPaymentReminderOnAppointmentConfirmation}
                    setValue={handleAppointmentConfirmation}
                    label={t("Appointment confirmation")}
                  />
                  <IconTooltip
                    key={"confirmationToolTip"}
                    classNameRoot={classes.iconTooltipRoot}
                    placement={"right"}
                    icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 5 }} viewBox={"0 0 12 12"}/>}
                    tooltipText={t("Send a message to make the payment when creating the appointment")}
                  />
                </div>
                <div className={classes.checkGroup}>
                  <SwitchWithLabel
                    value={entity.hasWhatsAppPaymentReminderOnAppointmentReminder}
                    setValue={handleAppointmentReminder}
                    label={t("Appointment reminder")}
                  />
                  <IconTooltip
                    key={"reminderToolTip"}
                    classNameRoot={classes.iconTooltipRoot}
                    placement={"right"}
                    icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 5 }} viewBox={"0 0 12 12"}/>}
                    tooltipText={t("Send a message to make the payment in the appointment reminder")}
                  />
                </div>
                <div className={classes.checkGroup}>
                  <SwitchWithLabel
                    value={entity.hasWhatsAppPaymentReminderAfterAppointment}
                    setValue={handleAppointmentAfter}
                    label={t("After appointment")}
                  />
                  <IconTooltip
                    key={"afterToolTip"}
                    classNameRoot={classes.iconTooltipRoot}
                    placement={"right"}
                    icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 5 }} viewBox={"0 0 12 12"}/>}
                    tooltipText={t("Apply for appointments with \"Customer arrived\" and \"Unpaid\" status")}
                  />
                  <div className={classes.contentColumn} style={{ paddingLeft: 45 }}>
                    <SelectWithLabel
                      label={""}
                      items={daysAfter}
                      placeholder={t("Select days")}
                      width={230}
                      name="daysAfter"
                      onChange={handleDaysAppointmentAfter}
                      value={entity.daysAfterAppointmentToSendWhatsAppPaymentReminder}
                      disabled={!entity.hasWhatsAppPaymentReminderAfterAppointment}
                      styleClass={!entity.hasWhatsAppPaymentReminderAfterAppointment ? classes.opacityTextMid : null}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.subtitle}>
                <Typography className={classes.title}>
                  {t("Send receipt by WhatsApp")}
                </Typography>
              </div>
              <div className={classes.innerContent}>
                <div className={classes.checkGroup}>
                  <SwitchWithLabel
                    value={entity.isSendReceiptByWhatsApp}
                    setValue={handleIsSendReceiptByWhatsApp}
                    label={t("Send receipt by WhatsApp")}
                  />
                  <IconTooltip
                    key={"confirmationToolTip"}
                    classNameRoot={classes.iconTooltipRoot}
                    placement={"right"}
                    icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 5 }} viewBox={"0 0 12 12"}/>}
                    tooltipText={t("When a payment is registered, the payment receipt is sent to your customer by WhatsApp.")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className={classes.block} style={{ height: 300 }}>
            <div className={classes.title}>
              <Typography className={classes.title}>
                {t("Activate cash register")}
              </Typography>
            </div>
            <div className={classes.contentArea}>
              <div className={classes.innerContent}>
                <SwitchWithLabel
                  value={entity.isCashRegisterActivated}
                  setValue={handlCashCheck}
                  label={t("ActivateCashRegisterLabel")}
                />
                <div className={classes.cashOpeningInput}>
                  <Typography className={classes.initialCash}>
                    {t("Initial cash")} <span className={classes.dot}>*</span>
                  </Typography>
                  <InputCurrency
                    value={entity.initialCashInCashRegister!}
                    setValue={setInitialCash}
                    valueTemplate={currentCurrency.valueTemplate}
                    disabled={!entity.isCashRegisterActivated}
                    onBlur={handleCashBlur}
                    isZeroAllowed={true}
                    isValid={() => {
                      return (!entity.isCashRegisterActivated || (entity.isCashRegisterActivated && entity.initialCashInCashRegister !== null && +entity.initialCashInCashRegister >= 0));
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </>}
      <ConfirmDeleteModal
        textButtonConfirm={t("Delete")}
        classModal={classes.modalDelete}
        open={openDeleteDialog}
        item={itemToDelete}
        componentInfo={<DivInfoDelete />}
        onClose={() => setOpenDeleteDialog(false)}
        onDelete={handleOnDelete}
      />
      <ExclamationModal 
        open={isExclamationModalOpen} 
        height={303}
        title={t("Sorry, you have reached the limit of banks to add")} 
        body={
          <div className={classes.exclamationModalBody}>
            {t("At the moment you can only add 3 banks")}
          </div>
        }
        okTextButton={t("I agree")}
        onClose={() => setExclamationModalOpen(false)}
      />
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openForbiddenSaveDialog}
        onClose={() => setOpenForbiddenSaveDialog(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openForbiddenSaveDialog}>
          <div className={classes.paper}>
            <div className={classes.closeButton} onClick={() => setOpenForbiddenSaveDialog(false)}>
              <CrossIcon />
            </div>
            <div className={classes.content}>
              <div className={classes.exclamationIcon}>
                <ExclamationIcon viewBox="0 0 7 16" />
              </div>
              <Typography className={classes.modalTitle}>
                {t("It is not possible to deactivate all payment methods")}
              </Typography>
              <div className={classes.modalTextBlock}>
                <Typography className={classes.modalText}>
                  {t("At least 1 active payment method is required.")}
                </Typography>
              </div>
              <div className={classes.modalFooter}>
                <Button
                  className={`${classes.button} ${classes.goBackButton}`}
                  onClick={() => setOpenForbiddenSaveDialog(false)}
                >
                  {t("OKForbiddenSavePayment")}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div >
  );
}