import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "flex-start",
    
    width: 400,
    height: 267,
    
    marginTop: -5,
    padding: 24,

    backgroundColor: theme.palette.background.paper,
    border: "1px solid #dbdcdd",
    borderRadius: 8,
    boxShadow: theme.shadows[5],
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
    justifyContent: "space-between",
    
    width: 352
  },
  headerDate: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    
    height: 40
  },
  closeIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    width: 16,
    height: 16,

    cursor: "pointer",
    color: theme.colors.grey5,
  },
  divider: {
    width: 352,
    height: 0,
    
    opacity: 0.5,
    border: "1px solid",
    borderColor: theme.colors.grey2
  },
  inputWithLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    
    marginTop: 16,
  },
  controlLabel: {
    ...theme.typography.buttons,
    fontWeight: "bold",
    display: "inline-flex",
    color: theme.colors.grey4,
    marginBottom: 8
  },
  dot: {
    ...theme.typography.buttons,
    color: theme.colors.clearMain,
    marginLeft: 5
  },
  inputLabelTooltip: {
    display: "inline-flex",
    marginLeft: 5
  },
  submitButton: {
    width: 352,
    height: 32,
    
    marginTop: 60,

    backgroundColor: theme.colors.clearGreen,
    borderRadius: 51,

    ...theme.typography.buttons,
    color: theme.colors.grey1,
    fontWeight: 700,
    
    "&:hover": {
      backgroundColor: theme.colors.clearGreen,
      color: theme.colors.grey1,
    }
  },
  noPermissionsText: {
    width: 352,
    height: 32,

    marginTop: 60,
    
    ...theme.typography.txtBody2,
    color: theme.colors.clearRed,
    fontWeight: 700
  }
  })
);

export default useStyles;