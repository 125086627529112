import React from "react";

import { useTranslation } from "react-i18next";

import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { JobPositionDeleteModalProps } from "./props";
import useStyles from "./css";
import { CrossIcon, TrashIcon } from "../../../../../assets/icons";


export default function JobPositionDeleteModal(props: JobPositionDeleteModalProps) {
  
  const { item, open, setOpen, onClose, onDelete } = props;

  const handleCloseModal = () => {
    setOpen(false);
    if (onClose) {
      onClose();
    }
  };
  
  const handleDelete = () => {
    if (onDelete) {
      onDelete(item);
    }
    handleCloseModal();
  }
  
  const classes = useStyles();
  const { t } = useTranslation("settings");
  
  const GoBackButton = () =>
    <Button
      className={`${classes.button} ${classes.goBack}`}
      onClick={handleCloseModal}
    >
      {t("Go back")}
    </Button>
  
  const DeleteButton = () =>
    <Button
      className={`${classes.button} ${classes.deleteButton}`}
      onClick={handleDelete}
    >
      {t("Delete")}
    </Button>
  
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={handleCloseModal}>
            <CrossIcon />
          </div>
          <div className={classes.content}>
            <div className={classes.mainContent}>
              <div className={classes.deleteIcon}>
                <TrashIcon />
              </div>
              <Typography className={classes.modalHeader}>{t("Delete this job title?")}</Typography>
              <div className={classes.divider} />
              <Typography className={classes.itemName}>{item.name}</Typography>
            </div>
            <div className={classes.modalFooter}>
              <GoBackButton />
              <DeleteButton />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}