import React, { useEffect, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import { useStyles } from "./css";
import { useTranslation } from 'react-i18next';
import { SelectItem } from "../../../../common/SelectWithLabel/props";
import Select from "../../../../common/Select";
import { StatusIcon } from "../../../../../assets/icons";
import { CustomerFrequencyType } from "../../../../../models/enums/customer-frequency-type";

import { CustomersClassificationEntity, CustomerClassificationType, GetCustomerClassificationLocale } from "../../../../../models/customers-classification-entity";
import { CustomerClassificationService } from "../../../../../api/customer-classification-service";
import useCustomerTerminology from "../../../../../hooks/useCustomerTerminology";
import { navbarActions } from "../../../../../redux/navbar-slice";
import { useAppDispatch } from "../../../../../redux/hooks";

import { SettingsEnterpriseProps } from "../../../../../models/interfaces/settings-enterprise-props";
import { CustomersClassifications } from "../../../../../models/customers-classifications";

const CustomerClassification = (props: SettingsEnterpriseProps) => {

    const { settingsSelectedEstablishmentId } = props;

    const { t } = useTranslation(["general"]);
    const classes = useStyles();

    const [frequencyList, setFrequencyList] = useState<SelectItem[]>([]);
    const [infrequencyList, setInfrequencyList] = useState<SelectItem[]>([]);
    const [inactiveList, setInactiveList] = useState<SelectItem[]>([]);
    const dispatch = useAppDispatch();

    const [frequents, setFrequents] = useState<SelectItem>();
    const [infrequents, setInfrequents] = useState<SelectItem>();
    const [inactives, setInactives] = useState<SelectItem>();

    const classification0appt: string[] = GetCustomerClassificationLocale(CustomerClassificationType.Inactive);
    const classification1appt: string[] = GetCustomerClassificationLocale();

    useEffect(() => {

        (async () => {
            CustomerClassificationService.getCustomerClassificationList().then(classificationList => {

                const appt1: SelectItem[] = classificationList.map(x => ({ key: x.id, value: classification1appt[(x?.months||0) - 1] }));

                setFrequencyList(appt1);
                setInfrequencyList(appt1);

                const appt0: SelectItem[] = classificationList.map(x => ({ key: x.id, value: classification0appt[(x?.months||0) - 1] }));
                setInactiveList(appt0);

                (async () => {
                    dispatch(navbarActions.setShowLoader(true));

                    let classifications: CustomersClassifications;
                    if (settingsSelectedEstablishmentId)
                        classifications = await CustomerClassificationService.getCustomerClassifications(settingsSelectedEstablishmentId);
                    else
                        classifications = await CustomerClassificationService.getCustomerClassifications();

                    dispatch(navbarActions.setShowLoader(false));
                    if (classifications.customerFrequencyClassificationFrequentId) {

                        const _frequent: SelectItem = appt1.filter(x => x.key === classifications.customerFrequencyClassificationFrequentId)[0];
                        setFrequents(_frequent);

                        const _infrequent: SelectItem = appt1.filter(x => x.key === classifications.customerFrequencyClassificationInfrequentId)[0];
                        setInfrequents(_infrequent);

                        const _inactive: SelectItem = appt0.filter(x => x.key === classifications.customerFrequencyClassificationInactiveId)[0];
                        setInactives(_inactive);

                        const disableInfraquency = appt1.map(x => x.key === _frequent.key ? { ...x, 'isGroup': true } : { ...x, 'isGroup': false });
                        setInfrequencyList(disableInfraquency);

                        const disableFraquency = appt1.map(x => x.key === _infrequent.key ? { ...x, 'isGroup': true } : { ...x, 'isGroup': false });
                        setFrequencyList(disableFraquency);
                    }
                })();

                });        

        })();


    }, [settingsSelectedEstablishmentId])

    const handleFrequency = async (e: any) => {
        let value = e.target?.value!;

        let selected = frequencyList.filter(x => x.key === value)[0];
        setFrequents(selected);

        const mappedObjectsArray = infrequencyList.map(x => x.key === value ? { ...x, 'isGroup': true } : { ...x, 'isGroup': false });
        setInfrequencyList(mappedObjectsArray);
        if (settingsSelectedEstablishmentId)
            await CustomerClassificationService.updateCustomersClassification(value, CustomerClassificationType.Frequent, settingsSelectedEstablishmentId);
        else
            await CustomerClassificationService.updateCustomersClassification(value, CustomerClassificationType.Frequent);
    }

    const handleInfrequency = async (e: any) => {
        let value = e.target?.value!;

        let selected = infrequencyList.filter(x => x.key === value)[0];
        setInfrequents(selected);

        const mappedObjectsArray = frequencyList.map(x => x.key === value ? { ...x, 'isGroup': true } : { ...x, 'isGroup': false });
        setFrequencyList(mappedObjectsArray);

        if (settingsSelectedEstablishmentId)
            await CustomerClassificationService.updateCustomersClassification(value, CustomerClassificationType.Infrequent, settingsSelectedEstablishmentId);
        else
            await CustomerClassificationService.updateCustomersClassification(value, CustomerClassificationType.Infrequent);
    }

    const handleInactive = async (e: any) => {
        let value = e.target?.value!;

        let selected = inactiveList.filter(x => x.key === value)[0];
        setInactives(selected);

        if (settingsSelectedEstablishmentId)
            await CustomerClassificationService.updateCustomersClassification(value, CustomerClassificationType.Inactive, settingsSelectedEstablishmentId);
        else
            await CustomerClassificationService.updateCustomersClassification(value, CustomerClassificationType.Inactive);
    }


    return (
        <div className={classes.section}>
            <div>
                <Typography className={classes.title}>
                    {useCustomerTerminology(t("Customer classification"), "Customer classification")}
                </Typography>
            </div>
            <Box className={classes.divArea}>

                <Grid container>
                    <Grid item xs={3}>
                        <div className={classes.root}>
                            <StatusIcon status={CustomerFrequencyType.Frequent} className={classes.iconLabel} />
                            <label className={classes.label}>
                                {t("Frequents")}
                            </label>
                            <Select key={"cmbFrequency"}
                                width={230}
                                placeholder={t("Frequents")}
                                value={frequents?.key}
                                onChange={(e) => handleFrequency(e)}
                                colorArrowIcon={"#5C6477"}
                                items={frequencyList} />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.root}>
                            <StatusIcon status={CustomerFrequencyType.Infrequent} className={classes.iconLabel} />
                            <label className={classes.label}>
                                {t("Infrequents")}
                            </label>
                            <Select key={"cmbInfrequents"}
                                width={230}
                                placeholder={t("Infrequents")}
                                value={infrequents?.key}
                                onChange={(e) => handleInfrequency(e)}
                                colorArrowIcon={"#5C6477"}
                                items={infrequencyList} />
                        </div>
                    </Grid>
                    <Grid item xs={3}>
                        <div className={classes.root}>
                            <StatusIcon status={CustomerFrequencyType.Inactive} className={classes.iconLabel} />
                            <label className={classes.label}>
                                {t("Inactives")}
                            </label>
                            <Select key={"cmbInactives"}
                                width={230}
                                placeholder={t("Inactives")}
                                value={inactives?.key}
                                onChange={(e) => handleInactive(e)}
                                colorArrowIcon={"#5C6477"}
                                items={inactiveList} />

                        </div>
                    </Grid>
                    <Grid item xs={3}>
                    </Grid>
                </Grid>
            </Box>
        </div>
    )
}

export default CustomerClassification;