import { SectionFieldType } from "./enums/section-field-type";
import { FormPhoneNumberValue } from "./form-phone-number-value";
import { FormRadioValue } from "./form-radio-value";

export class SectionFieldValueModel {
  type: SectionFieldType;
  value: string | FormPhoneNumberValue | FormRadioValue;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.type = x.type;
      this.value = x.value;
    }
    else {
      this.type = SectionFieldType.Text;
      this.value = "";
    }
  }
}