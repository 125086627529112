import { CustomRecurrenceMode } from "./enums/custom-recurrence-mode";
import { WeekDaysFlags } from "./enums/week-days-flags";


export class RecurrenceModalData {
  customRecurrenceInterval: number | null;
  customRecurrenceMode: CustomRecurrenceMode | null;
  customRecurredAppointmentsCount: number | null;
  customRecurrenceWeekDays: WeekDaysFlags | null;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.customRecurrenceInterval = x.customRecurrenceInterval;
      this.customRecurrenceMode = x.customRecurrenceMode;
      this.customRecurredAppointmentsCount = x.customRecurredAppointmentsCount;
      this.customRecurrenceWeekDays = x.customRecurrenceWeekDays;
    } else {
      this.customRecurrenceInterval = null;
      this.customRecurrenceMode = null;
      this.customRecurredAppointmentsCount = null;
      this.customRecurrenceWeekDays = null;
    }
  }
}