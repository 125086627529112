import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { SectionNewModalProps } from "./props";
import useStyles from "./css";
import { Backdrop, Button, Fade, Modal, Typography } from "@material-ui/core";
import { SectionNewIcon } from "../../../../../assets/icons";
import InputWithLabel from "../../../../common/InputWithLabel";
import ConfirmActionModal from "../../../../common/ConfirmActionModal";

export default function SectionNewModal(props: SectionNewModalProps) {
    const sectionTNamespace = "sections";
    const { t } = useTranslation(["general", sectionTNamespace]);

    const { sections, isOpen, setOpen, onCreate } = props;
    const classes = useStyles();

    const maxTextFieldLength = 200;

    const [justOpened, setJustOpened] = useState<boolean>(true);    //need to make input look valid on open
    const [dataIsValid, setDataIsValid] = useState<boolean>(false);
    const [name, setName] = useState<string>("");
    const [closeConfirmationModalIsOpen, setCloseConfirmationModalIsOpen] = useState<boolean>(false);

    const handleOnClose = () => {
        setCloseConfirmationModalIsOpen(false);
        setOpen(false);
        setDataIsValid(false);
        setName("");
        setJustOpened(true);
    };

    const handleAddButtonClick = async () => {
        if (!dataIsValid)
            return;

        await onCreate(name);
        handleOnClose();
    };

    const tryCloseModal = () => {
        if (name === "") {
            handleOnClose()
        }
        else {
            setCloseConfirmationModalIsOpen(true);
        }
    };

    const handleOnChange = (event: React.ChangeEvent<{ value: string }>) => {
        const localName = event.target.value;
        if (localName.length > maxTextFieldLength ||
            localName.trim().length === 0 ||
            (sections && !nameIsAlreadyUsed(localName))) {
            setDataIsValid(false);
            setName(localName);
            return;
        }

        if (justOpened)
            setJustOpened(false);

        setName(localName);
        setDataIsValid(true);
    };
    
    const nameIsAlreadyUsed = (localName: any) => {
        let newSectionName = localName
          .trim()
          .toLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, '');
        
        let currentSectionNames = sections!.map(s => t(s.name!, { ns: sectionTNamespace })
          .trim()
          .toLowerCase()
          .normalize('NFD')
          .replace(/\p{Diacritic}/gu, ''));
        
        return !currentSectionNames.includes(newSectionName);
    };

    const CloseModalContent: React.FC = () => {
        return (<div className={classes.closeModalContentContainer}>
            <Typography className={classes.closeModalTitleText}>
                 {t("Are you sure you want to cancel the creation of the new section?")}
            </Typography>
            <div className={classes.separator} />
            <Typography className={classes.closeModalContentText}>
                {t("The changes made will not be saved")}
            </Typography>
        </div>);
    };

    return <>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={isOpen}
            onClose={tryCloseModal}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }} >
            <Fade in={isOpen}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>
                        <div className={classes.modalTitle}>
                            <SectionNewIcon />
                            <Typography className={classes.modalTitleText}>
                                {t("New section")}
                            </Typography>
                        </div>
                        <div className={classes.modalActions}>
                            <Button
                                className={`${classes.button} ${classes.goBack}`}
                                onClick={tryCloseModal}
                            >
                                {t('Go back')}
                            </Button>
                            <Button
                                className={`${classes.button} ${classes.continueButton}`}
                                disabled={!dataIsValid}
                                classes={{ disabled: classes.createDisabled }}
                                onClick={handleAddButtonClick}
                            >
                                {t("Add")}
                            </Button>
                        </div>
                    </div>
                    <div className={classes.content}>
                        <InputWithLabel
                            label={() => <>{t("Name")} <span className={classes.requiredAsterisk}>*</span></>}
                            placeholder={t("Enter the name of the section")}
                            value={name}
                            showCounter={true}
                            width={637}
                            name="name"
                            maxLength={200}
                            onChange={(e) => handleOnChange(e)}
                            isValid={() => justOpened || dataIsValid}
                        />
                    </div>
                </div>
            </Fade>
        </Modal>
        <ConfirmActionModal
            classModal={classes.closeConfirmationModal}
            content={<CloseModalContent />}
            open={closeConfirmationModalIsOpen}
            onClose={() => setCloseConfirmationModalIsOpen(false)}
            onConfirm={handleOnClose}
        />
    </>
};