import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    borderBottom: "1px solid rgba(201, 205, 211, 0.38)",
  },
  rootTab: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey6,
    minWidth: "auto",
    padding: 0,
    margin: "0px 0px 0px 24px",
    textTransform: "none",
    [theme.breakpoints.up("sm")]: {
      minWidth: "auto",
      padding: 0,
      margin: "0px 0px 0px 24px",
    },
  },
  tabSelected: {
    fontWeight: "bold",
    color: `${theme.colors.clearMain} !important`, //"#6462F3 !important",
  },
}));

export default useStyles;
