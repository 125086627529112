export class Currency {
    id: string;
    name: string;
    isoCode: string;
    valueTemplate: string;
    
    constructor(x?: any) {
      if (typeof x === "object") {
        this.id = x.id
        this.name = x.name;
        this.isoCode = x.isoCode;
        this.valueTemplate = x.valueTemplate;
      }
      else {
        this.id = "";
        this.name = "";
        this.isoCode = "";
        this.valueTemplate = "{0}";
      }
    }
  }