export enum AppointmentRecurrenceInterval {
    OneWeek = 1,
    TwoWeeks = 2,
    ThreeWeeks = 3,
    FourWeeks = 4,
    EightWeeks = 8,
    TwelveWeeks = 12,
    CustomRecurrenceDefault = 101,
    CustomRecurrenceSaved = 100
}
