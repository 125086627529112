import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { ClinicHistoryProps } from "./props";
import useStyles from "./css";
import { Button, Typography } from "@material-ui/core";
import { InfoIcon, SectionNewIcon } from "../../../../../assets/icons";
import { SectionType } from "../../../../../models/enums/section-type";
import { SettingsPatientFormsService } from "../../../../../api/settings-patient-forms-service";
import { SectionModel } from "../../../../../models/section-model";
import SectionNewModal from "../SectionNewModal";
import SectionList from "../SectionList";
import SectionListRefs from "../SectionList/section-list-refs";
import SwitchWithLabel from "../../../../common/SwitchWithLabel";
import IconTooltip from "../../../../common/IconTooltip";
import {
  ClinicalHistoryCommunicationSettingsService
} from "../../../../../api/service-сlinical-history-communication-settings";
import {
  ClinicalHistoryCommunicationSettings
} from "../../../../../models/clinical-history-communication-settings-model";
import {
  ClinicalHistoryCommunicationSettingType
} from "../../../../../models/enums/clinical-history-communication-setting-type";
import { Editor } from "@tinymce/tinymce-react";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { navbarActions } from "../../../../../redux/navbar-slice";
import { selectAccountSettings } from "../../../../../redux/store";
import SwitchWithLabelAndTooltip from "../../../../common/SwitchWithLabelAndTooltip";


export default function ClinicHistory(props: ClinicHistoryProps) {
  
  const { t } = useTranslation(["general"]);
  const {} = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const currentTabSectionType = SectionType.ClinicHistory;
  const maxTextLength: number = 25000;
  const [sections, setSections] = useState<SectionModel[]>([]);
  const [newSectionModalIsOpen, setNewSectionModalIsOpen] = useState<boolean>(false);
  const [entity, setEntity] = useState<ClinicalHistoryCommunicationSettings>(new ClinicalHistoryCommunicationSettings());
  const [focusedPrivacyNotice, setFocusedPrivacyNotice] = useState<boolean>(false);
  const [focusedConsentLetter, setFocusedConsentLetter] = useState<boolean>(false);
  const [textLengthPrivacyNotice, setTextLengthPrivacyNotice] = useState<number>(0);
  const [textLengthConsentLetter, setTextLengthConsentLetter] = useState<number>(0);
  const [originalPrivacyNoticeValue, setOriginalPrivacyNoticeValue] = useState<string>("");
  const [originalConsentLetterValue, setOriginalConsentLetterValue] = useState<string>("");
  const accountSettings = useAppSelector(selectAccountSettings);
  
  const sectionListRef = useRef<SectionListRefs>(null);
  
  useEffect(() => {
    async function fetchData() {
      dispatch(navbarActions.setShowLoader(true));
      await loadData();
      await loadClinicalHistoryCommunicationSettings();
      dispatch(navbarActions.setShowLoader(false));
    }
    
    fetchData();
  }, []);
  
  const handleCreateSection = async (name: string) => {
    await sectionListRef?.current?.createSection(name);
  }
  
  const loadData = async () => {
    
    const sectionsData =
      await SettingsPatientFormsService.getAccountSettingsTabSections(currentTabSectionType);
    setSections(sectionsData);
  };

  const handleOpenPreview = () => {
    window.open(`https://sitiodereservas.somosclear.com/${accountSettings.siteForFinalCustomersSmbName}/patient-form?previewStep=second`, '_blank', 'noopener,noreferrer');
  }
  
  const setPrivacyNoticeTextLength = (evt: any, editor: any) => {
    setTextLengthPrivacyNotice(editor.getContent({ format: "text" }).length);
  };
  const setConsentLetterTextLength = (evt: any, editor: any) => {
    setTextLengthConsentLetter(editor.getContent({ format: "text" }).length);
  };
  const updatePrivacyNotice = async () => {
    setFocusedPrivacyNotice(false);
    if (entity.privacyNotice != originalPrivacyNoticeValue) {
      await saveClinicalHistoryCommunicationSettings(entity);
      setOriginalPrivacyNoticeValue(entity.privacyNotice);
    }
    
  };
  const updateConsentLetter = async () => {
    setFocusedConsentLetter(false);
    if (entity.consentLetter != originalConsentLetterValue) {
      await saveClinicalHistoryCommunicationSettings(entity);
      setOriginalConsentLetterValue(entity.consentLetter);
    }
  };
  
  const handleEditorPrivacyNoticeChange = async (c: string, editor: any) => {
    const contentText = editor.getContent({ format: "text" });
    const contentHtml = editor.getContent();
    
    if (contentText.length <= maxTextLength) {
      let updating: ClinicalHistoryCommunicationSettings;
      updating = {
        ...entity,
        privacyNotice: contentHtml
      };
      setEntity(updating);
      setTextLengthPrivacyNotice(contentText.length);
    }
  }
  
  const handleEditorConsentLetterChange = async (c: string, editor: any) => {
    const contentText = editor.getContent({ format: "text" });
    const contentHtml = editor.getContent();
    if (contentText.length <= maxTextLength) {
      let updating: ClinicalHistoryCommunicationSettings;
      updating = {
        ...entity,
        consentLetter: contentHtml
      };
      setEntity(updating);
      setTextLengthConsentLetter(contentText.length);
    }
  }
  
  const saveClinicalHistoryCommunicationSettings = async (updating: ClinicalHistoryCommunicationSettings) => {
    const result = await ClinicalHistoryCommunicationSettingsService.updateClinicalHistoryCommunicationSettings(updating);
    if (result) {
      setEntity(result);
    }
  }
  
  const loadClinicalHistoryCommunicationSettings = async () => {
    
    const info = await ClinicalHistoryCommunicationSettingsService.getClinicalHistoryCommunicationSetting();
    
    const regex = /(<([^>]+)>)/ig;
    
    setTextLengthPrivacyNotice(info.privacyNotice?.replace(regex, "").length);
    setTextLengthConsentLetter(info.consentLetter?.replace(regex, "").length);
    setOriginalPrivacyNoticeValue(info.privacyNotice);
    setOriginalConsentLetterValue(info.consentLetter);
    setEntity(info);
  }

  async function handleToggleClinicalHistory(value: boolean) {
    if (value) {
      await handleSetClinicalHistoryCommunicationSettings(value, ClinicalHistoryCommunicationSettingType.ClinicalHistoryEnable)
      return;
    }

    let updating = {
      ...entity,
      isClinicalHistoryEnabled: false,
      sendFormOnReminderNoHistory: false,
      sendFormOnConfirmationNoHistory: false
    }

    await saveClinicalHistoryCommunicationSettings(updating);
  }
  
  const handleSetClinicalHistoryCommunicationSettings = async (value: boolean, settingType: ClinicalHistoryCommunicationSettingType) => {
    let updating: ClinicalHistoryCommunicationSettings;
    updating = {
      ...entity
    };
    switch (settingType) {
      case ClinicalHistoryCommunicationSettingType.SendConfirmationToPatientWithoutHistory:
        updating = {
          ...entity,
          sendFormOnConfirmationNoHistory: value
        };
        break;
      case ClinicalHistoryCommunicationSettingType.SendReminderToPatientWithoutHistory:
        updating = {
          ...entity,
          sendFormOnReminderNoHistory: value
        };
        break;
      case ClinicalHistoryCommunicationSettingType.SendConfirmationToPatient:
        updating = {
          ...entity,
          sendFormOnConfirmationIncompleteHistory: value
        };
        break;
      case ClinicalHistoryCommunicationSettingType.SendReminderToPatient:
        updating = {
          ...entity,
          sendFormOnReminderIncompleteHistory: value
        };
        break;
      case ClinicalHistoryCommunicationSettingType.ConsentLetterEnable:
        updating = {
          ...entity,
          isConsentLetterEnabled: value
        };
        break;
      case ClinicalHistoryCommunicationSettingType.PrivacyNoticeEnable:
        updating = {
          ...entity,
          isPrivacyNoticeEnabled: value
        };
        break;
      case ClinicalHistoryCommunicationSettingType.ClinicalHistoryEnable:
        updating = {
          ...entity,
          isClinicalHistoryEnabled: value
        };
        break;
    }
    await saveClinicalHistoryCommunicationSettings(updating);
  }
  
  const getClinicalHistoryCommunicationSettingsContent = () => {
    return <div>
      <Typography className={classes.communicationSettingsHeader}>
        {t("Communication with the patient about the Clinical history")}
      </Typography>
      <div style={{ paddingBottom: 32 }}>
        <div className={classes.communicationSettingsContainer}>
          <div style={{ padding: 36, display: "flex", flexDirection: "column" }}>
            <div style={{ marginBottom: 14 }}>
              <Typography className={classes.communicationSettingsMainTitle}>
                {t("Patient form")}
              </Typography>
              <div className={classes.clinicalHistorySwitch}>
                <SwitchWithLabelAndTooltip
                  value={entity.isClinicalHistoryEnabled}
                  setValue={handleToggleClinicalHistory}
                  label={t("Clinical history")}
                  tooltip={t("PatientFormIncludesClinicalHistory")}
                  inactiveTooltip={t("PatientFormDoesNotIncludeClinicalHistory")}
                />
              </div>
            </div>

            <Typography className={classes.communicationSettingsMainTitle}>
              {t("Send form by WhatsApp")}
            </Typography>
            
            <Typography className={classes.communicationSettingsCheckboxGroupTitle}>
              {t("To patients without Clinical history")}
            </Typography>
            <div style={{ marginBottom: 14 }}>
              <SwitchWithLabel
                value={entity.sendFormOnConfirmationNoHistory}
                setValue={(value: boolean) => handleSetClinicalHistoryCommunicationSettings(value,
                  ClinicalHistoryCommunicationSettingType.SendConfirmationToPatientWithoutHistory)}
                label={t("Appointment confirmation")}
                disabled={!entity.isClinicalHistoryEnabled}
              />
              <IconTooltip
                key={"cardToolTip"}
                classNameRoot={classes.iconTooltipRoot}
                placement={"right"}
                arrowPlacement={"left"}
                icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 10 }} viewBox={"0 0 12 12"}/>}
                tooltipText={t("Send the patient form when creating the appointment")}
              />
            </div>
            <div style={{ marginBottom: 26 }}>
              <SwitchWithLabel
                value={entity.sendFormOnReminderNoHistory}
                setValue={(value: boolean) => handleSetClinicalHistoryCommunicationSettings(value,
                  ClinicalHistoryCommunicationSettingType.SendReminderToPatientWithoutHistory)}
                label={t("Appointment reminder")}
                disabled={!entity.isClinicalHistoryEnabled}
              />
              
              <IconTooltip
                key={"cardToolTip"}
                classNameRoot={classes.iconTooltipRoot}
                placement={"right"}
                arrowPlacement={"left"}
                icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 10 }} viewBox={"0 0 12 12"}/>}
                tooltipText={t("Send the patient form on the appointment reminder")}
              />
            </div>
            
            <Typography className={classes.communicationSettingsCheckboxGroupTitle}>
              {t(entity.isClinicalHistoryEnabled ? "To patients with Incomplete General information and/or clinical history" : "To patients with incomplete General info")}
            </Typography>
            <div style={{ marginBottom: 14 }}>
              <SwitchWithLabel
                value={entity.sendFormOnConfirmationIncompleteHistory}
                setValue={(value: boolean) => handleSetClinicalHistoryCommunicationSettings(value,
                  ClinicalHistoryCommunicationSettingType.SendConfirmationToPatient)}
                label={t("Appointment confirmation")}
              />
              
              <IconTooltip
                key={"cardToolTip"}
                classNameRoot={classes.iconTooltipRoot}
                placement={"right"}
                arrowPlacement={"left"}
                icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 10 }} viewBox={"0 0 12 12"}/>}
                tooltipText={t("Send the patient form when creating the appointment")}
              />
            </div>
            <div style={{ marginBottom: 31 }}>
              <SwitchWithLabel
                value={entity.sendFormOnReminderIncompleteHistory}
                setValue={(value: boolean) => handleSetClinicalHistoryCommunicationSettings(value,
                  ClinicalHistoryCommunicationSettingType.SendReminderToPatient)}
                label={t("Appointment reminder")}
              />
              
              <IconTooltip
                key={"cardToolTip"}
                classNameRoot={classes.iconTooltipRoot}
                placement={"right"}
                arrowPlacement={"left"}
                icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 10 }} viewBox={"0 0 12 12"}/>}
                tooltipText={t("Send the patient form on the appointment reminder")}
              />
            </div>
            
            <Typography className={classes.mainTitle} style={{ marginBottom: 13 }}>
              {t("Privacy notice")}
            </Typography>
            
            <SwitchWithLabel
              value={entity.isPrivacyNoticeEnabled}
              setValue={(value: boolean) => handleSetClinicalHistoryCommunicationSettings(value,
                ClinicalHistoryCommunicationSettingType.PrivacyNoticeEnable)}
              label={t("Include privacy notice in the form")}
            />
            <div className={`${classes.tinyMceOverrides} ${focusedPrivacyNotice ? classes.tinyMceFocused : classes.tinyMceUnfocused}`}>
              <Editor
                apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
                init={{
                  height: 160,
                  width: "auto",
                  menubar: false,
                  statusbar: false,
                  placeholder: t("Write here."),
                  plugins: 'wordcount paste lists',
                  toolbar: 'bold italic underline  | numlist bullist ',
                  content_style: "body {fontFamily: Inter, sans-serif; font-weight: 400; font-size: 14px; color: #323947 }",
                  entity_encoding: "raw",
                  formats: {
                    bold: { inline: 'b' },
                    italic: { inline: 'i' },
                    underline: { inline: 'u' }
                  },
                  paste_as_text: true,
                  extended_valid_elements: "b, i, s",
                  invalid_elements: "strong, em, span"
                }}
                value={entity.privacyNotice}
                onFocusIn={() => setFocusedPrivacyNotice(true)}
                onBlur={updatePrivacyNotice}
                onInit={setPrivacyNoticeTextLength}
                onEditorChange={handleEditorPrivacyNoticeChange}
                outputFormat="html"
                onChange={(e) => {
                  if (textLengthPrivacyNotice >= maxTextLength) {
                    e.preventDefault();
                  }
                }}
              />
              <div className={classes.counterWrapper}>
                  <span className={classes.charCounter}>{textLengthPrivacyNotice + "/" + maxTextLength}</span>
              </div>
              
            </div>
            
            <Typography className={classes.mainTitle} style={{ marginBottom: 13 }}>
              {t("Consent letter")}
            </Typography>
            
            <SwitchWithLabel
              value={entity.isConsentLetterEnabled}
              setValue={(value: boolean) => handleSetClinicalHistoryCommunicationSettings(value,
                ClinicalHistoryCommunicationSettingType.ConsentLetterEnable)}
              label={t("Include informed consent letter in the form")}
            />
            <div className={`${classes.tinyMceOverrides} ${focusedConsentLetter ? classes.tinyMceFocused : classes.tinyMceUnfocused}`}>
              <Editor
                apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
                init={{
                  height: 160,
                  width: "auto",
                  menubar: false,
                  statusbar: false,
                  placeholder: t("Write here."),
                  plugins: 'wordcount paste lists',
                  toolbar: 'bold italic underline | numlist bullist ',
                  content_style: "body {fontFamily: Inter, sans-serif; font-weight: 400; font-size: 14px; color: #323947 }",
                  entity_encoding: "raw",
                  formats: {
                    bold: { inline: 'b' },
                    italic: { inline: 'i' },
                    underline: { inline: 'u' }
                  },
                  paste_as_text: true,
                  extended_valid_elements: "b, i, s",
                  invalid_elements: "strong, em, span"
                }}
                value={entity.consentLetter}
                onFocusIn={() => setFocusedConsentLetter(true)}
                onBlur={updateConsentLetter}
                onInit={setConsentLetterTextLength}
                onEditorChange={handleEditorConsentLetterChange}
                outputFormat="html"
                onChange={(e) => {
                  if (textLengthConsentLetter >= maxTextLength) {
                    e.preventDefault();
                  }
                }}
              />
              <div className={classes.counterWrapper}>
                <span className={classes.charCounter}>{textLengthConsentLetter + "/" + maxTextLength}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  };
  
  return <>
    <div className={classes.header}>
      <Typography className={classes.tabTitle}>
        {t("Clinic history")}
      </Typography>
      {
        entity.isClinicalHistoryEnabled &&
        <Button
          className={classes.previewButton}
          onClick={handleOpenPreview}
        >
          {t("Preview")}
        </Button>
      }
      
      <Button
        className={classes.newButton}
        onClick={() => setNewSectionModalIsOpen(true)}
      >
        <SectionNewIcon style={{ paddingRight: 5 }}/>
        {t("New section")}
      </Button>
    </div>
    <SectionList
      ref={sectionListRef}
      sectionType={currentTabSectionType}
      sections={sections}
      setSections={setSections}/>
    <SectionNewModal
      sections={sections}
      isOpen={newSectionModalIsOpen}
      setOpen={setNewSectionModalIsOpen}
      onCreate={handleCreateSection}
    />
    
    {getClinicalHistoryCommunicationSettingsContent()}
  
  </>
};