import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiAccordion-root:before": {
      backgroundColor: "unset"
    },
    "& .MuiAccordionSummary-root": {
      padding: "10px 19px 10px 15px",
      background: "#FAFAFA",
      minHeight: "unset",
      height: 46,
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 0,
      margin: "unset"
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiAccordion-root.Mui-expanded": {
      margin: "unset"
    },
    overflowY: "scroll",
    minHeight: 560,
    maxHeight: "calc(100vh - 152px)",
    borderRadius: 12,
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
  addButton: {
    width: 140,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    background: "#7175FA",
    color: "#F6F6F6",
    "&:hover": {
      background: "#6462F3",
    },
    alignItems: "center",
    justifyContent: "space-around",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    position: "relative",
    top: 25,
    left: 600,
    "&.Mui-disabled": {
      color: "#F6F6F6",
      opacity: 0.3,
    },
  },
  addTreatmentButton: {
    alignSelf: "center",

    width: 20,
    height: 32,
    borderRadius: 51,
    padding: "11px 0",
    "&:hover": {
      background: "none",
    },
    alignItems: "center",
    justifyContent: "space-around",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    "&.Mui-disabled": {
      color: "#F6F6F6",
      opacity: 0.5,
    },
  },
  saveButton: {
    display: "flex",
    justifyContent: "center",

    ...theme.typography.buttons,
    color: theme.colors.clearGreen,
    width: "auto",
    height: 32,
    fontWeight: 700,
    borderRadius: 51,
    padding: "11px 16px",
    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.clearGreen,

    "&.Mui-disabled": {
      color: theme.colors.clearGreen,
      opacity: 0.3,
    },
  },
  editButton: {
    alignItems: "center",
    justifyContent: "space-around",
    color: theme.colors.clearMain,
    width: "auto",
    height: 32,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    borderRadius: 51,
    padding: "11px 16px",
    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.clearMain,
    "&.Mui-disabled": {
      color: theme.colors.clearMain,
      opacity: 0.3,
    },
  },
  cancelButton: {
    display: "flex",
    justifyContent: "center",

    ...theme.typography.buttons,
    color: theme.colors.grey4,
    width: "auto",
    height: 32,
    borderRadius: 51,
    fontWeight: 700,
    padding: "11px 16px",
    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.grey4,
    marginRight: 15,
    "&.Mui-disabled": {
      color: theme.colors.grey4,
    },
  },
  iconPlus: {
    height: 25,
    textAlign: "center",
    color: "#FAFAFA"
  },
  uploadFileCloud: {
    height: 25,
    textAlign: "center",
    color: "#FAFAFA",
    position: "relative",
    left: 115
  },
  iconRemove: {
    height: 25,
    textAlign: "center",
    color: "#F15857",
    paddingTop: 1
  },
  iconDiary: {
    position: "relative",
    top: 76,
    left: 210,
  },
  diaryCaption: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#6462F3",
    marginTop: 34,
    marginLeft: 15,
  },
  customerHistoryContent: {
    margin: "48px 0px 0px 0px",
  },
  accordion: {
    border: "none",
    boxShadow: "none"
  },
  accordionDetails: {
    padding: 0
  },
  expandIcon: {
    flexDirection: 'row-reverse',
    display: "none"
  },
  expanded: {
    "& * svg": {
      flexDirection: 'row-reverse',
      transform: 'rotate(180deg)',

    }
  },
  monthYear: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14,
    lineHight: 17,
    color: Color.clear1
  },
  notesCountInMonth: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    lineHight: 17,
    color: Color.clear1
  },
  evolutionNoteForm: {
    width: "100%",
  },
  evolutionNoteBox: {
    borderBottom: "1px solid #C9CDD3",
    background: "#FFFFFF",
    width: "100%", //680,
    marginBottom: 20,
    padding: "15px 15px 25px 15px"
  },
  counterWrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  charCounter: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "15px",
    position: "absolute",
    paddingLeft: "10px",
    marginTop: "-23px",
    right: 24,
    color: theme.colors.grey5
  },
  closeModalContentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  removeModalContentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 7
  },
  closeModalTitleText: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: "#26292B",
    textAlign: "center",
    maxWidth: "80%"
  },
  closeModalContentText: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    fontWeight: 700,
  },
  closeModalContentText2: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
  },
  closeModalContentHeaderText: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: Color.gray5
  },
  separator: {
    height: 0,
    borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    margin: "16px 0px 16px 0px",
    width: 217
  },
  editModeSeparator: {
    height: 0,
    borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    margin: "22px 16px 24px 16px",
    width: "auto"
  },
  longSeparator: {
    height: 0,
    borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    margin: "25px 0px 16px 0px",
    width: "100%"
  },
  closeConfirmationModal: {
    minHeight: "240px !important",
    height: "auto !important",
  },
  cancelEvolutionNoteConfirmationModal: {
    height: "200px !important"
  },
  tinyMceOverrides: {
    marginBottom: 26,
    "& svg": {
      fill: "#919CA5 !important"
    },
    "& button.tox-tbtn--select": {
      visibility: "hidden"
    },
    "& div.tox-edit-area": {
      marginBottom: "22px"
    },
  },
  tinyMceUnfocused: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: "#C9CDD3 !important"
    }
  },
  tinyMceFocused: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: "#6462F3 !important"
    }
  },
  tinyMceValidationError: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: "#FF0000 !important"
    }
  },
  accordionHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: 10
  },
  buttonArea: {
    display: "flex",
    width: "24%",
    justifyContent: "space-between"

  },
  editEvolutionHeader: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14,
    color: Color.gray5
  },
  editModeTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14,
    color: Color.gray5,
    marginBottom: 4
  },
  editModeSimpleText: {
    ...theme.typography.txtBody1,
    fontWeight: 400,
    color: Color.gray5
  },
  editModeBoldText: {
    ...theme.typography.txtBody1,
    fontWeight: 700,
    color: Color.gray5
  },
  editModeTreatmentList: {
    paddingLeft: 20,
  },
  editorFieldHeader: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    fontWeight: 700,

    marginBottom: 18,
  },
  treatmentFieldHeader: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    fontWeight: 700,

    marginBottom: 9,
  },
  treatmentRow: {
    display: "flex",
    gap: 15,
  },
  treatmentInputContainer: {
    display: "flex",
    flexDirection: "column",

    width: "42%",
  },
  pdfButtonsBlock: {
    display: "flex",
    gap: 16,

    marginTop: -10,
    marginBottom: 24,
  },
  pdfButton: {
    height: 32,

    padding: "11px 16px",

    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.clearMain,
    borderRadius: 5,

    ...theme.typography.buttons,
    color: theme.colors.clearMain,
    fontWeight: 700,
  },
  fileHeader: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14,
    marginBottom: 16,
    marginTop: 24,
    color: Color.gray5
  },
  treatmentNameInput: {
    backgroundColor: Color.white + " !important",
    border: "1px solid " + Color.gray2,
    borderRadius: "5px !important",
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "14px",
    marginBottom: 23,
    marginRight: 15,
    color: Color.gray5 + " !important"
  },
  inputText: {
    ...theme.typography.txtBody2,
    fontWeight: 700,
    fontSize: 12,
    color: theme.colors.grey6,
    "& ::placeholder": {
      fontWeight: "normal" ,
      fontSize: 12,
      color: theme.colors.grey5,
      opacity: 0.8 
    },
  },
  carryOutTitle: {
    borderRadius: "5px !important",
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    marginTop: -12,
    fontSize: "12px",
    lineHeight: "14px",
    marginBottom: 15,
    color: Color.gray4 + " !important"
  },
  dropZone: {
    height: "136px",
    width: "97%",
    background: "rgba(100, 98, 243, 0.1)",
    border: "0px dashed",
    borderRadius: "15px",
    borderColor: theme.colors.clearMain,
    minHeight: "100px",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%236462F3FF' stroke-width='2' stroke-dasharray='12%2c 12' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
    animation: 'none !important',

    color: theme.colors.clearMain,
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    // position: "relative",
    // top: "70px",
  },
  editModeNoteHeaderContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "0px 16px",
    marginTop: 22,
    "&>*:last-child":{
      marginLeft: 20
    }
  },
  editModeNoteHeaderContainerChild: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between"
  },
  editModeNoteFieldSmall: {
    color: theme.colors.grey4,
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12
  },
  editModeNoteFieldBlue: {
    color: theme.colors.clearMain,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14
  },
  fieldEditionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 2,
    marginBottom: 24
  },
  fieldNameLabel: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: Color.gray4
  },
  fieldValueLabel: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.gray5
  },
  phoneNumberCodeLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.clear2
  },
  phoneNumberContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 4,
    "& svg": {
      width: "20px !important",
      height: "20px !important"
    }
  },
  fieldContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 16,
    minWidth: "33%",
    maxWidth: "33%",
    gap: 2
  },
  fieldsContainer: {
    display: "flex",
    flexWrap: "wrap"
  },
  fieldNameAsterisk: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: Color.clear1
  },
  editorContent: {
    "& p": {
      display: "inline"
    },
  },
  fileNameContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start"
  },
  actionsContainer: {
    display: "flex",
    alignItems: "center"
  },
  grid: {
    marginTop: 25,
    width: "97.5%",
    "& .MuiPagination-root": {
      marginTop: 30
    },
    "& td": {
      padding: "7px 0px 7px 12px"
    },
  },
  classRow: {
    // cursor: "pointer",
    "& div.buttonRow": {
      visibility: "hidden",
    },
    "&:hover": {
      "& div.buttonRow": {
        visibility: "visible"
      }
    }
  },
  gridShowMode: {
    marginTop: 16,
    width: "95%",
    marginRight: "auto",
    marginLeft: "auto",
    "& .MuiPagination-root": {
      marginTop: 30
    },
    "& td": {
      padding: "7px 0px 7px 12px"
    },
  },
  iconTooltipRoot: {
    display: "inline-block"
  },
}));

export default useStyles;
