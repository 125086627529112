import { ApiClient } from "./api-client";
import { Appointment } from "../models/appointment";
import { AppointmentServiceModel } from "./../models/appointment-service-model";
import { AppointmentStatusModel } from "./../models/appointment-status-model";
import { AppointmentAvailableHours } from "./../models/appointment-available-hours";
import { CreateAppointmentService } from "../models/create-appointment-service";
import { CreateAppointmentModel } from "../models/create-appointment-model";
import { CreateFollowUpModel } from "../models/create-follow-up-model";
import { AppointmentBlocker } from "../models/appointment-blocker";
import {AppointmentTooltipModel} from "../models/appointment-tooltip-model";
import { AppointmentStatus } from "../models/enums/appointment-status";

export class AppointmentService {
  private static baseUrl: string = "/appointment";

  private static isoDate(date: Date): string {
    const year = date.toLocaleString("en-US", {
      year: "numeric",
    });
    const month = date.toLocaleString("en-US", {
      month: "2-digit",
    });
    const day = date.toLocaleString("en-US", {
      day: "2-digit",
    });

    return `${year}-${month}-${day}`;
  }

  private static handleTime(date: Date): string {
    const time = date.toLocaleString("en-US", {
      hourCycle: "h23",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });

    return `${this.isoDate(date)}T${time}`;
  }

  static create(appointment: CreateAppointmentModel): Promise<any> {
    return ApiClient.post(`${this.baseUrl}/create`, {
      ...appointment,
      startDate: this.handleTime(appointment.startDate as Date),
    }).then((data) => {
      return {
        appointment: data.appointment !== null ? new Appointment(data.appointment) : null,
        appointmentBlocker: data.appointmentBlocker !== null ? new AppointmentBlocker(data.appointmentBlocker) : null,
        isScheduleBusy: data.isScheduleBusy,
        appointmentScheduleBusyReason: data.appointmentScheduleBusyReason
      };
    });
  }

  static remove(appointmentId: string, allRecurringAppointments: boolean = false): Promise<boolean> {

    let endpointUrl = `${this.baseUrl}/delete?appointmentId=${appointmentId}`
    
    if (allRecurringAppointments && allRecurringAppointments !== null) 
      endpointUrl += `&allRecurringAppointments=${allRecurringAppointments}`;

    return ApiClient.remove(endpointUrl).then((data) => true);
  }

  static updateStatus(appointment: AppointmentStatusModel): Promise<boolean> {
    return ApiClient.put(`${this.baseUrl}/updatestatus`, appointment).then(
      (data) => true
    );
  }
  
  
  static updateNotes(serviceId: string, appointmentId: string, notes: string): Promise<boolean> {

    const dataNotes = {
      serviceId,
      appointmentId,
      notes
    }

    return ApiClient.put(`${this.baseUrl}/updatenotes`, dataNotes).then(
      (data) => true
    );
  }

  static update(appointment: CreateAppointmentModel): Promise<any> {
    return ApiClient.put(`${this.baseUrl}/update`, {
      ...appointment,
      startDate: this.handleTime(appointment.startDate as Date),
    }).then((data) => {
      return {
        appointment: data.appointment !== null ? new Appointment(data.appointment) : null,
        appointmentBlocker: data.appointmentBlocker !== null ? new AppointmentBlocker(data.appointmentBlocker) : null,
        isScheduleBusy: data.isScheduleBusy,
        appointmentScheduleBusyReason: data.appointmentScheduleBusyReason
      };
    });
  }
  
  static updateTime(appointmentId: string, startDate: Date, allAppointments: boolean): Promise<any> {
    return ApiClient.put(`${this.baseUrl}/updateTime`, {
      id: appointmentId,
      startDate: this.handleTime(startDate),
      allAppointments: allAppointments
    }).then((data) => {
      return {
        isUpdated: data.isUpdated,
        isScheduleBusy: data.isScheduleBusy,
        isUpdatable: data.isUpdatable,
        hasSimultaneousAppointments: data.hasSimultaneousAppointments
      };
    });
  }

  static get(appointmentId: string): Promise<CreateAppointmentModel> {
    return ApiClient.get(
      `${this.baseUrl}/getbyid?AppointmentId=${appointmentId}`
    ).then((data) => {
      return new CreateAppointmentModel(data.appointment);
    });
  }

  static getAppointmentCalendarById(appointmentId: string, userIds: string[] | undefined, statuses: AppointmentStatus[] | undefined): Promise<Appointment | null> {
    const formData = new FormData();
    formData.append("AppointmentId", appointmentId);
    if (userIds)    {
      userIds.forEach(userId => {
        formData.append("UserIds", userId);
      })
    }
    if (statuses)    {
      statuses.forEach(status => {
        formData.append("Statuses", String(status));
      })
    }
  
    return ApiClient.post(
      `${this.baseUrl}/getappointmentcalendarbyid`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then((data) => data!=null ? new Appointment(data.appointment) : null);
  }

  static getByCustomer(customerId: string): Promise<Appointment[]> {
    return ApiClient.get(
      `${this.baseUrl}/getbycustomer?customerId=${customerId}`
    ).then((data) => data.appointments.map((a: any) => new Appointment(a)));
  }

  static getByAccount(dateFrom: Date, dateTo: Date, userIds: string[] | undefined, statuses: AppointmentStatus[] | undefined): Promise<Appointment[]> {
    const formData = new FormData();
    formData.append("DateFrom", dateFrom.toISOString().replace("Z", ""));
    formData.append("DateTo", dateTo.toISOString().replace("Z", ""));
    if (userIds)    {
      userIds.forEach(userId => {
        formData.append("UserIds", userId);
      })
    }
    if (statuses)    {
      statuses.forEach(status => {
        formData.append("Statuses", String(status));
      })
    }

    return ApiClient.post(
      `${this.baseUrl}/getbyaccount`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then((data) => data.appointments.map((a: any) => new Appointment(a)));
  }

  static getSplitByServicesAppointmentsByAccount(dateFrom: Date, dateTo: Date, userIds: string[] | undefined, statuses: AppointmentStatus[] | undefined): Promise<Appointment[]> {
    const formData = new FormData();
    formData.append("DateFrom", dateFrom.toISOString().replace("Z", ""));
    formData.append("DateTo", dateTo.toISOString().replace("Z", ""));
    if (userIds)    {
      userIds.forEach(userId => {
        formData.append("UserIds", userId);
      })
    }
    if (statuses)    {
      statuses.forEach(status => {
        formData.append("Statuses", String(status));
      })
    }
    
    return ApiClient.post(
      `${this.baseUrl}/getsplitbyservicesappointmentsbyaccount`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then((data) => data.appointments.map((a: any) => new Appointment(a)));
  }
  
  static getSplitByEstablishments(dateFrom: Date, dateTo: Date, establishmentIds: string[] | undefined, userIds: string[] | undefined, statuses: AppointmentStatus[] | undefined): Appointment[] | PromiseLike<Appointment[]> {
    const formData = new FormData();
    formData.append("DateFrom", dateFrom.toISOString().replace("Z", ""));
    formData.append("DateTo", dateTo.toISOString().replace("Z", ""));
    if (establishmentIds)    {
      establishmentIds.forEach(establishmentId => {
        formData.append("EstablishmentIds", establishmentId);
      })
    }
    if (userIds)    {
      userIds.forEach(userId => {
        formData.append("UserIds", userId);
      })
    }
    if (statuses)    {
      statuses.forEach(status => {
        formData.append("Statuses", String(status));
      })
    }
    
    return ApiClient.post(
      `${this.baseUrl}/getsplitbyestablishments`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then((data) => data.appointments.map((a: any) => new Appointment(a)));
  }

  static getSplitByServicesAppointmentCalendarById(appointmentId: string, userIds: string[] | undefined, statuses: AppointmentStatus[] | undefined): Promise<Appointment[]> {
    const formData = new FormData();
    formData.append("AppointmentId", appointmentId);
    if (userIds)    {
      userIds.forEach(userId => {
        formData.append("UserIds", userId);
      })
    }
    if (statuses)    {
      statuses.forEach(status => {
        formData.append("Statuses", String(status));
      })
    }
    
    return ApiClient.post(
      `${this.baseUrl}/getsplitbyservicesappointmentcalendarbyid`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        }
      }).then((data) => data.appointments.map((a: any) => new Appointment(a)));
  }

  static getAppointmentTooltip(
    appointmentId: string
  ): Promise<AppointmentTooltipModel> {
    return ApiClient.get(
      `${this.baseUrl}/gettooltip?appointmentId=${appointmentId}`
    ).then((data) =>
      new AppointmentTooltipModel(data.data)
    );
  }

  static getServices(
    appointmentId: string
  ): Promise<AppointmentServiceModel[]> {
    return ApiClient.get(
      `${this.baseUrl}/getservices?appointmentId=${appointmentId}`
    ).then((data) =>
      data.services.map((a: any) => new AppointmentServiceModel(a))
    );
  }

  static getAvailableHours(
    startDate: Date,
    services: CreateAppointmentService[],
    scheduledSimultaneousServices: boolean,
    appointmentId?: string,
    recurrenceWeekInterval?: number | null,
    recurredAppointmentsCount?: number | null,
    customRecurrenceMode?: number | null,
    customRecurrenceInterval?: number | null,
    customRecurrenceWeekDays?: number | null
  ): Promise<AppointmentAvailableHours> {
    const date = new Date(startDate);
    date.setHours(12, 0, 0, 0);
    let endpointUrl = `${
      this.baseUrl
    }/getavailablehours?startDate=${this.isoDate(date)}`;
    if (appointmentId) {
      endpointUrl += `&appointmentId=${appointmentId}`;
    }
    
    endpointUrl += `&scheduledSimultaneousServices=${scheduledSimultaneousServices}`;

    if (recurrenceWeekInterval && recurrenceWeekInterval !== null) 
      endpointUrl += `&recurrenceWeekInterval=${recurrenceWeekInterval}`;

    if (recurredAppointmentsCount && recurredAppointmentsCount !== null) 
      endpointUrl += `&recurredAppointmentsCount=${recurredAppointmentsCount}`;
    
    if (customRecurrenceMode !== null && customRecurrenceMode !== undefined) {
      endpointUrl += `&customRecurrenceMode=${customRecurrenceMode}`;
    }
    
    if (customRecurrenceInterval && customRecurrenceInterval !== null) 
      endpointUrl += `&customRecurrenceInterval=${customRecurrenceInterval}`;

    if (customRecurrenceWeekDays && customRecurrenceWeekDays !== null) 
      endpointUrl += `&customRecurrenceWeekDays=${customRecurrenceWeekDays}`;

    for (let service of services) {
      endpointUrl += `&serviceId=${service.serviceId}`;
      endpointUrl += `&userId=${service.userId}`;
      endpointUrl += `&estimatedDuration=${service.estimatedDuration}`;
      endpointUrl += `&workAreaId=${service.workAreaId ?? ""}`;
    }
    return ApiClient.get(endpointUrl).then((data) => {
      return new AppointmentAvailableHours(data);
    });
  }

  static getAvailableDays(): Promise<number[]> {
    return ApiClient.get(`${this.baseUrl}/getavailabledays`).then(
      (data) => data.availableDays
    );
  }

  
  static createFollowUp(followup: CreateFollowUpModel): Promise<Appointment> {

    const date = this.handleTime(followup.scheduled as Date);

    const followUpDate = {
      ...followup,
      scheduled: date,
    };

    return ApiClient.post(`${this.baseUrl}/createfollowup`, followUpDate).then((data) => {
      return new Appointment(data.appointment);
    });
  }

}
