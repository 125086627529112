export class SelectWorkAreaModel {
  id: string | null;
  workArea: string | null;
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.workArea = x.workArea;
    } else {
      this.id = null;
      this.workArea = null;
    }
  }
}
