import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PDFIcon = (props: any) => {
  const { style, width, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{fontSize: '44px', width: width}}
      viewBox="0 0 32 44"
    >
      <path
        d="M30.75 8.83317L23.8333 1.83317C23.0833 1.08317 22 0.666504 21 0.666504H4C1.75 0.666504 0 2.49984 0 4.6665V39.3332C0 41.5832 1.75 43.3332 4 43.3332H28C30.1667 43.3332 32 41.5832 32 39.3332V11.6665C32 10.6665 31.5 9.58317 30.75 8.83317ZM27.6667 11.3332H21.3333V4.99984L27.6667 11.3332ZM4 39.3332V4.6665H17.3333V13.3332C17.3333 14.4998 18.1667 15.3332 19.3333 15.3332H28V39.3332H4ZM24.8333 27.4165C23.8333 26.4165 20.9167 26.6665 19.4167 26.8332C18 25.9998 17.0833 24.7498 16.4167 22.9998C16.6667 21.6665 17.25 19.5832 16.8333 18.3332C16.5 16.1665 13.6667 16.3332 13.25 17.8332C12.9167 19.1665 13.25 21.0832 13.8333 23.4165C13 25.4165 11.75 28.0832 10.9167 29.6665C9.25 30.4998 7 31.8332 6.66667 33.4998C6.41667 34.8332 8.83333 38.0832 13 30.9165C14.8333 30.2498 16.9167 29.4998 18.6667 29.1665C20.25 30.0832 22.0833 30.5832 23.3333 30.5832C25.5 30.5832 25.6667 28.2498 24.8333 27.4165ZM8.33333 33.9165C8.75 32.7498 10.3333 31.4165 10.8333 30.9998C9.25 33.4998 8.33333 33.9165 8.33333 33.9165ZM15.0833 17.9998C15.75 17.9998 15.6667 20.6665 15.25 21.4165C14.9167 20.2498 14.9167 17.9998 15.0833 17.9998ZM13.0833 29.4165C13.9167 27.9998 14.5833 26.3332 15.1667 24.8332C15.8333 26.0832 16.6667 27.0832 17.6667 27.7498C15.9167 28.1665 14.4167 28.8332 13.0833 29.4165ZM24 28.9998C24 28.9998 23.5833 29.4998 20.9167 28.3332C23.8333 28.0832 24.3333 28.7498 24 28.9998Z"
        fill={style?.color || "#ACB7C0"}
      />
    </SvgIcon>
  )
};
export default PDFIcon;
