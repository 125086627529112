import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

import { Color } from "../../constants/colors";


export default function VideoCameraFilledIcon(props: any) {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 14, height: 14, ...style }}
      viewBox={viewBox || "0 0 14 14"}
    >
      <path
        d="M9.92578 6.125L12.25 3.80078V10.1992L9.92578 7.875V9.92578C9.92578 10.0898 9.86198 10.2266 9.73437 10.3359C9.625 10.4453 9.48828 10.5 9.32422 10.5H2.32422C2.16016 10.5 2.02344 10.4453 1.91406 10.3359C1.80469 10.2266 1.75 10.0898 1.75 9.92578V4.07422C1.75 3.91016 1.80469 3.77344 1.91406 3.66406C2.02344 3.55469 2.16016 3.5 2.32422 3.5H9.32422C9.48828 3.5 9.625 3.55469 9.73437 3.66406C9.86198 3.77344 9.92578 3.91016 9.92578 4.07422V6.125Z"
        fill={style?.color || Color.gray5}
      />
    </SvgIcon>
  );
}