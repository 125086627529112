import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    root: {},
    section: {
        paddingBottom: 10
    },
    divArea: {
        backgroundColor: "#fff",
        borderRadius: 20,
        border: "1px solid #dbdcdd",
        padding: "40px",
        margin: "10px 0"
    },
    title: {
        fontSize: "14px",
        color: theme.colors.grey5,
        marginBottom: 16,
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
    },
    inputClass: {
        ...theme.typography.txtBody2,
        color: theme.colors.grey5,
        fontWeight: "bold",
    },
    label: {
        ...theme.typography.txtBody2,
        color: theme.colors.grey4,
        fontWeight: "bold",
        display: "inline-flex",
        marginBottom: "0.5rem",
    },
    iconLabel: {
        display: "inline-flex",
        fontSize: 14,
        marginRight: 5,
        marginLeft: 5
    },
    select: {
        ...theme.typography.txtBody2,
        // fontWeight: "bold",
        backgroundColor: theme.colors.grey1,
        height: 40,
        padding: "13px 0px 13px 12px",
        border: "solid 1px rgba(0, 0, 0, 0.1)",
        borderRadius: 5,
        width: 230,
    },
    selected: {
        fontWeight: "bold",
    },
    placeholder: {
        color: "#676767",
        opacity: "0.42",
    },
    focused: {
        "&:focus": {
            backgroundColor: theme.colors.grey1,
        },
    },
    menuItem: {
        color: theme.colors.grey5,
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        lineHeight: "14px",
    }
}));

