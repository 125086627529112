import React from 'react';

import { BrowserRouter, Redirect, Route } from "react-router-dom";

import { useAppSelector } from "../../../redux/hooks";
import { selectUserInfo } from "../../../redux/store";

import { Url } from "../../../constants/url";
import PredeterminedReportsPage from "../sections/PredeterminedReports/PredeterminedReportsPage";
import CustomReportsPage from "../sections/CustomReports/CustomReportsPage";


export default function ReportsRouter() {
  const userInfo = useAppSelector(selectUserInfo);
  
  return (
    <>
      <Route exact path={Url.Reports.Main}>
        {userInfo.hasAccessToReports 
          ? <PredeterminedReportsPage />
          : <Redirect to={Url.Appointments.Main} />}
      </Route>
      
      <Route exact path={Url.Reports.CustomReport}>
        {userInfo.hasAccessToReports 
          ? <CustomReportsPage /> 
          : <Redirect to={Url.Appointments.Main} />}
      </Route>
    </>
  );
}