import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ImageIcon = (props: any) => {
  const { style, width, height, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ fontSize: '44px', width: width ?? 64, height: height ?? 64 }}
      viewBox="0 0 24 24"
    >
      <path
        d="M17.5312 7.0625L14.9375 4.4375C14.6562 4.15625 14.25 4 13.875 4H7.5C6.65625 4 6 4.6875 6 5.5V18.5C6 19.3438 6.65625 20 7.5 20H16.5C17.3125 20 18 19.3438 18 18.5V8.125C18 7.75 17.8125 7.34375 17.5312 7.0625ZM16.375 8H14V5.625L16.375 8ZM7.5 18.5V5.5H12.5V8.75C12.5 9.1875 12.8125 9.5 13.25 9.5H16.5V18.5H7.5ZM8.5 17H15.5V13L14.75 12.2812C14.5938 12.125 14.375 12.125 14.2188 12.2812L11.5 15L10.25 13.7812C10.0938 13.625 9.875 13.625 9.71875 13.7812L8.5 15V17ZM10 9.5C9.15625 9.5 8.5 10.1875 8.5 11C8.5 11.8438 9.15625 12.5 10 12.5C10.8125 12.5 11.5 11.8438 11.5 11C11.5 10.1875 10.8125 9.5 10 9.5Z"
        fill={style?.color || "#ACB7C0"}
      />
    </SvgIcon>
  )
};
export default ImageIcon;
