import { ApiClient } from "./api-client";
import { EstablishmentInfo } from "./../models/establishmentinfo";
//import { EstablishmentInfoEntity } from "./../models/establishmentinfo-entity";

export class EstablishmentInfoService {

    private static baseUrl: string = "/account";

    static updateEstablishmentInfo(establishment: EstablishmentInfo): Promise<EstablishmentInfo> {
        return ApiClient.post(`${this.baseUrl}/updateestablishmentinfo`, establishment)
            .then(data => new EstablishmentInfo(data.establishmentInfo)
        );
    }
    static async getEstablishmentInfo(establishmentAccountId?: string): Promise<EstablishmentInfo> {
        let url = `${this.baseUrl}/getestablishmentinfo`;

        if (establishmentAccountId) {
            url = `${this.baseUrl}/getestablishmentinfo?AccountId=${establishmentAccountId}`;
        }

        const data = await ApiClient.get(url);
        return new EstablishmentInfo(data.establishmentInfo);
    }

}
