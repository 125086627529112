import React, { CSSProperties } from "react";
import { makeStyles } from '@material-ui/core';
import { ItemComponent } from '../AppointmentsScheduler/Content';
import { PlusIcon } from "../../../../assets/icons";
import { memo, useRef, useState } from 'react';
import { useAppSelector } from "../../../../redux/hooks";
import { AppointmentsGroup } from '../_common';
import { useScheduleAppointmentActionsMenuContext } from "../ScheduleAppointmentActionsMenu/context";
import AppointmentItemList from "./AppointmentItemList";
import AppointmentTicket from "./AppointmentTicket";
import { selectAccountSettings } from "../../../../redux/store";

const useStyles = makeStyles({
    root: {
        position: 'relative',
        overflow: 'hidden',
        height: '100%',
    },
    item: {
        boxSizing: 'border-box',
        position: 'absolute',
        left: 'calc(100% / var(--total-count) * var(--index) * var(--width-multiplier))',
        top: 'calc(var(--cmos-aps-pixels-per-minute) * var(--appointment-offset))',
        height: 'calc(var(--cmos-aps-pixels-per-minute) * var(--appointment-duration))',
    },
    additionalBlock: {
        position: 'absolute',
        height: '100%',
        left: '75%',
        width: '25%',
        cursor: 'pointer',
        zIndex: 2,
        borderRadius: 4,
        border: 'solid 1px var(--clearMain)',
        backgroundColor: 'var(--clear5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: 14,
        fontWeight: 400,
        color: 'var(--clearMain)',
        '&:hover': {
            borderWidth: 2
        },
    },
    plusBtn: {
        all: 'initial',
        backgroundColor: 'var(--clearMain)',
        display: 'block',
        zIndex: 2,
        height: 24,
        width: 24,
        borderRadius: 12,
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#fff',
        cursor: 'pointer',
        scale: 0,
        visibility: 'hidden',
        transition: 'scale .15s',
        transformOrigin: 'center',

        '&:hover': {
            backgroundColor: 'var(--clear2)',
        },
        
        '&>svg': {
            top: "1.5px",
            left: "4px",
            position: "relative"
        }
    },
    wrapper: {
        display: 'contents',
        position: 'relative',
        '&:hover $plusBtn': {
            scale: 1,
            visibility: 'visible',
        },
    },
});

const AppointmentItem: ItemComponent<AppointmentsGroup> = memo(({ value: appointmentGroup }) => {
    const styles = useStyles();
    const currentViewName = useAppSelector((state) => state.scheduler.currentViewName);
    const actionsPopupMenu = useScheduleAppointmentActionsMenuContext();
    const [openAppointmentItemList, setOpenAppointmentItemList] = useState(false);
    const accountSettings = useAppSelector(selectAccountSettings);
    
    const appointments = appointmentGroup.list;
    const firstAppointment = appointments[0];

    const isGrouped = appointmentGroup.isGrouped;

    // all appointments in the group will belong to the same service advisor in "day" mode
    const advisorId = currentViewName === 'Day' ? firstAppointment.userId : null;
    const date = firstAppointment.startDate;
    const rootRef = useRef<HTMLTableCellElement | null>(null);

    const widthMultiplier = isGrouped ? (2 / 3) : 0.8;
    
    function getWidth(index: number, totalCount: number): number {
        return 100 - ((index / totalCount) * 100) * widthMultiplier;
    }

    function getBody(): React.ReactNode {
        if (appointments.length === 1) {
            return <AppointmentTicket appointment={firstAppointment} />
        }

        if (appointments.length > 4 && isGrouped) {
            return (
              <div className={styles.root} style={{ '--total-count': 3 } as CSSProperties}>
                  {appointments.slice(0, 3).map((a, idx) => (
                    <div
                      key={a.appointmentId + a.userId}
                      //style={{ left: `${(50 / 2) * idx}%` }}
                      className={styles.item}
                      style={
                          {
                              '--index': idx,
                              '--appointment-offset': a.startDate.diff(date).as('minutes'),
                              '--appointment-duration': a.endDate.diff(a.startDate).as('minutes'),
                              '--width-multiplier': widthMultiplier,
                              width: `${getWidth(idx, 3)}%`,
                          } as CSSProperties
                      }
                    >
                        <AppointmentTicket appointment={a} />
                    </div>
                  ))}
                  <div
                    className={styles.additionalBlock}
                    onClick={() => setOpenAppointmentItemList(true)}
                  >
                      +{appointments.length - 3}
                  </div>
                  <AppointmentItemList
                    open={openAppointmentItemList}
                    appointments={appointments}
                    onClose={() => setOpenAppointmentItemList(false)}
                  />
              </div>
            )
        }

        return (
          <div className={styles.root} style={{ '--total-count': appointments.length } as CSSProperties}>
              {appointments.map((a, idx) => (
                <div
                  key={a.appointmentId + a.userId}
                  className={styles.item}
                  style={
                      {
                          '--index': idx,
                          '--appointment-offset': a.startDate.diff(date).as('minutes'),
                          '--appointment-duration': a.endDate.diff(a.startDate).as('minutes'),
                          '--width-multiplier': widthMultiplier,
                          width: `${getWidth(idx, appointments.length)}%`
                      } as CSSProperties
                  }
                >
                    <AppointmentTicket appointment={a} />
                </div>
              ))}
          </div>
        )
    }

    let body: React.ReactNode = getBody();

    return (
        <div className={styles.wrapper}>
            {body}
            {!accountSettings.isEnterprise &&
              <div
                ref={rootRef}
                className={styles.plusBtn}
                onClick={(e) => actionsPopupMenu.open(date, advisorId, e.clientX, e.clientY)}
              >
                  <PlusIcon />
              </div>
            }
        </div>
    );
});

export default AppointmentItem;
