import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #dbdcdd",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: "40px 40px 48px",
    width: 812,
    height: 488,
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
    paddingBottom: "12px",
  },
  modalTitle: {
    width: "100%",
  },
  modalTitleText: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#5C6477",
  },
  modalActions: {
    display: "flex",
    justifyContent: "center",
  },
  goBack: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  createCustomer: {
    background: "#36CE91",
    color: "#F6F6F6",
    "&:hover": {
      background: "#36CE91",
    },
  },
  createDisabled: {
    color: "#F6F6F6 !important",
    opacity: "0.4",
  },
  title: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    margin: 0,
  },

  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "24px 0px 0px 0px",
  },
  
  closeModalContentContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  commentsLabel: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontSize: 14,
    fontWeight: 400
  },

  dropZone: {
    height: "136px",
    width: "373px",
    background: "rgba(100, 98, 243, 0.1)",
    border: "0px dashed",
    borderRadius: "15px",
    borderColor: theme.colors.clearMain,
    minHeight: "100px",
    backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='15' ry='15' stroke='%236462F3FF' stroke-width='2' stroke-dasharray='12%2c 12' stroke-dashoffset='0' stroke-linecap='round'/%3e%3c/svg%3e")`,
    animation: 'none !important',
  },

  dropZoneText: {
    color: theme.colors.clearMain,
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "14px",
    lineHeight: "17px",
    position: "relative",
    top: "70px",
  },
  fileName: {
    color: theme.colors.grey5,
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
  },
  fileSize: {
    color: theme.colors.grey3,
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    paddingTop: "4px",
  },
  fileSizeBold: {
    color: theme.colors.grey3,
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
  },
  downloadTemplate: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "15px",
    color: theme.colors.grey5,
    cursor: "pointer",
  },
  notesTitle: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "14px",
    display: "flex",
    color: theme.colors.grey5,
  },
  notes: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    display: "flex",
    color: theme.colors.grey5,
  },
  divider: {
    borderTop: "1px solid",
    borderColor: theme.colors.grey2,
    marginTop: "24px",
    marginBottom: "24px",
  },
  modalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  modalTitleSpace: {
    margin: "24px 0px 8px 0px",
  },
  cancelModalButton: {
    width: 244,
    height: 39,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 14,
  },
  cancelConfirmation: {
    background: theme.colors.clearGreen,
    color: "#F6F6F6",
    "&:hover": {
      background: theme.colors.clearGreen,
    },
  },
  modalTitleTextCancel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontSize: 18,
    fontWeight: "bold"
  },
  goBackCancel: {
    marginRight: 38,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  link: {
    color: theme.colors.clearMain,
    cursor: "pointer"
  },
  label: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    fontWeight: "bold",
    marginTop: 32
  },
  inputClass: {
    ...theme.typography.body2,
    height: "70px !important",
    backgroundColor: "inherit !important",
   
    resize: "none",
    "&::placeholder": {
      ...theme.typography.txtBody2,
      color: theme.colors.grey4,
      fontWeight: "normal",
      opacity: 0.8,
    }
  }
}));

export default useStyles;
