import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: { display: "flex" },
  separator: {
    height: 0,
    borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    margin: "16px 0px 16px 0px",
  },
  customerInfoPanel: {
    height: 560,
    position: "absolute",
    width: 779,
    overflow: "hidden",
  },
  customerInfoPanelContent: {
    overflowY: "scroll",
    height: 560,
    padding: "28px 28px 0px 28px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
  customerDetails: {
    width: 779,
    margin: "0px 0px 0px 36px",
  },
  customerDetailsContent: {
    width: 779,
    height: "calc(100vh - 101px)",
    borderRadius: 12,
    border: "1px solid #DBDCDD",
    backgroundColor: "#FFF",
    boxShadow: "none",
    position: "relative"
  },
  customerInfoTitle: {
    display: "flex",
    alignItems: "center",
  },
  customerInfoContent: {
    margin: "20px 0px 0px 0px",
  },
  infoContentRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 0px 0px",
  },
  contactRow: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10
  },
  iconTitle: {
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: theme.colors.grey4,
    margin: "0px 0px 0px 10px",
  },
  caption: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    width: 228,
    margin: "0px",
  },
  captionValue: {
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: theme.colors.grey5,
    width: 228,
    margin: "0px",
  },
  captionValueTextArea: {
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: theme.colors.grey5,
    minWidth: 228,
    margin: "0px",
  },
  detailsActions: {
    position: "absolute",
    bottom: theme.spacing(4),
    right: theme.spacing(2),
    zIndex: 1002
  },
  floatButton: {
    borderRadius: 51,
    textTransform: "none",
    boxShadow: "none",
    display: "inline-block",
    padding: "0px 16px",
  },
  floatButtonTxt: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "15px",
    display: "inline-block",
  },
  editButton: {
    height: 32,
    background: "#F6F6F6",
    color: "#6462F3",
    "&:hover": {
      background: "#6462F3",
      color: "#F6F6F6",
    },
  },
  saveButton: {
    height: 32,
    background: "#F6F6F6",
    color: "#36CE91",
    "&:hover": {
      background: "#36CE91",
      color: "#F6F6F6",
    },
  },
  cancelButton: {
    marginRight: 24,
    height: 32,
    background: "#F6F6F6",
    color: "#5C6477",
    "&:hover": {
      background: "#5C6477",
      color: "#F6F6F6",
    },
  },
  lastRow: {
    marginBottom: 98,
  },
  addContactButton: {
    width: 137,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "space-around",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    border: "1px solid #6462F3",
    marginRight: 25,
    color: "#6462F3",
    textTransform: "none",
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid red",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: "24px",
    width: 464,
    minHeight: 288,
  },
  modalCancelPaper: {
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid red",
    borderRadius: "12px",
    boxShadow: theme.shadows[5],
    padding: "24px",
    width: 472,
    minHeight: 153,
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
  },
  modalClose: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  closeButton: {
    cursor: "pointer",
    color: "#919CA5",
  },
  modalTitle: {
    width: "100%",
  },
  modalTitleText: {
    fontSize: "18px",
    lineHeight: "22px",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "#26292B",
  },
  modalContent: {},
  modalCircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "1px solid #F15857",
    width: "50px",
    height: "50px",
  },
  modalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  modalTitleSpace: {
    margin: "24px 0px 8px 0px",
  },
  modalContentText: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#4A4D51",
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "24px 0px 8px 0px",
  },
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  goBack: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  deleteContact: {
    background: "#F15857",
    color: "#F6F6F6",
    "&:hover": {
      background: "#F15857",
    },
  },
  cancelConfirmation: {
    background: theme.colors.clearGreen,
    color: "#F6F6F6",
    "&:hover": {
      background: theme.colors.clearGreen,
    },
  },
  divFlag: {
    display: "inline-block",
    position: "relative",
    height: 20,
    width: 20,
    "& svg": {
        width: "20px !important", 
        height: "20px !important"
    }
  },
  divCode: {
    position: "relative",
    display: "inline-block",
    fontStyle: "normal",
    fontFamily: "Inter, sans-serif",
    fontWeight: "normal",
    width: 40,
    textAlign: "center",
    fontSize: 14
  },
  divMobile: {
    display: "inline-block",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontSize: 14,
  }
}));

export default useStyles;
