export default class Service {
    id: string | null;
    name: string;
    price?: number;
    categoryId: string;
    category: string;
    duration?: number;
    recurrenceId: string;
    recurrence: string;

    // (optional)
    workAreaId?: string | null;
    workArea?: string;
    photoName?: string;
    photoURL?: string;
    file?: File | null;
    description?: string;
    notes?: string;

    constructor(x?: Service) {
        if (typeof x === "object" && x != null) {
            this.id = x.id;
            this.name = x.name || "";
            this.price = x.price;
            this.categoryId = x.categoryId;
            this.category = x.category || "";
            this.photoName = x.photoName || "";
            this.photoURL = x.photoURL || "";
            this.duration = x.duration;
            this.recurrenceId = x.recurrenceId || "";
            this.recurrence = x.recurrence || "";
            this.description = x.description || "";
            this.notes = x.notes || "";
            this.workAreaId = x.workAreaId || null;
        }
        else {
            this.id = null;
            this.name = "";
            //this.price = null;
            //this.duration = null;
            this.photoName = "";
            this.photoURL = "";
            this.categoryId = "";
            this.category = "";
            this.recurrenceId = "";
            this.recurrence = "";
            this.description = "";
            this.notes = "";
            this.workAreaId = null;
        }
    }
}

//Form Valid
export interface ServiceValidator {
    name?: boolean;
    price?: boolean;
    categoryId?: boolean;
    duration?: boolean;
    recurrenceId?: boolean;
}

