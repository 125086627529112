import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  label: {
    ...theme.typography.txtBody2,
    fontFamily: "Inter, sans-serif",
    fontWeight: "bold",
    display: "inline-flex",
    color: theme.colors.grey4,
    margin: 0,
    marginBottom: 5
  },
  controlRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: 650,
  },
  annualControlRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-end",
    justifyContent: "space-between",
    width: 670
  },
  inputWithLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  controlLabel: {
    ...theme.typography.txtBody2,
    fontFamily: "Inter, sans-serif",
    fontWeight: "bold",
    display: "inline-flex",
    color: theme.colors.grey4,
    marginBottom: 8
  },
  dot: {
    ...theme.typography.txtBody2,
    color: theme.colors.clearMain,
    marginLeft: 5
  },
  dateText: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey5,
    "&::placeholder": {
      fontWeight: "normal",
      color: "#676767",
    },
  },
  divider: {
    width: 731,
    marginTop: 24,
    marginBottom: 24,
    height: 0,
    border: "1px solid",
    borderColor: theme.colors.grey1
  },
  customerTypes: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  customerTypeSelectors: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  customerTypeSelector: {
    marginRight: 12
  },
  sendingTypes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  sendingTypeEditText: {
    ...theme.typography.buttons,
    color: theme.colors.grey4,
    fontWeight: 700
  },
  sendingTypeName: {
    ...theme.typography.txtBody2,
    color: theme.colors.clearMain,
    fontWeight: 700,
    marginRight: 3,
    marginLeft: 3
  },
  radios: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    marginLeft: -5
  },
  radio: {
    width: 150,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  singleSendingSettings: {
    marginTop: 8,
    height: 100,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  annualSendingSettings: {
    height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
  birthdaySettings: {
    height: 200,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around",
    alignItems: "flex-start",
  },
  switchBase: {
    padding: 1,
    opacity: 1,
    height: "100%",
    border: "3px solid #323947",
    "&.Mui-checked": {
      transform: "translateX(13px)",
      border: "0px",
      color: theme.palette.common.white,
      "& + .MuiSwitch-track": {
        backgroundColor: "#36ce91",
        opacity: 1,
        border: "none",
      },
      "& * .MuiSwitch-thumb": {
        width: 15,
      },
      //
    },
    "&$focusVisible .MuiSwitch-thumb": {
      color: "#6462F3",
      border: "6px solid #fff",
    },
    "& * .MuiSwitch-thumb": {
      width: 12,
      height: 15,
      boxShadow: "none",
    },
  },
  controlRowSwitch: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 650,
    paddingTop: "20px"
  },
  switchLabel: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    fontWeight: 400,
    marginRight: 7,
    marginLeft: 9
  },
  switchLabelActive: {
    color: theme.colors.clearMain,
    fontWeight: 700,
  },
})
);

export default useStyles;