import { makeStyles } from "@material-ui/core/styles";

const useStyles = (width?: number) =>
  makeStyles((theme) => ({
    root: {},
    label: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey4,
      fontWeight: "bold",
      display: "block",
    },
    input: {
      backgroundColor: theme.colors.grey1,
      height: 40,
      padding: "0px 0px 0px 12px",
      fontSize: 14,
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      width: width || 370,
    },
    inputText: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey5,
      "&::placeholder": {
        fontWeight: "normal",
        color: "#676767",
      },
    },
    inputFocused: {
      border: "solid 1px #6462F3",
      padding: "0px 0px 0px 12px",
      width: width || 370,
    },
    inputDisabled: {
      color: "#676767",
    },
    inputError: {
      color: "#f15857",
      border: "solid 1px #f15857",
    },
  }));

export default useStyles;
