import React, { useImperativeHandle, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import useStyles from "./css";
import { UserModalProfessionalInfoProps } from "./props";
import InputWithLabel from "../../../../common/InputWithLabel";
import { Button, Typography } from "@material-ui/core";
import { FieldAndValue } from "../../../../../models/field-and-value";
import { SectionFieldValueModel } from "../../../../../models/section-field-value-model";
import { CheckIcon, PlusIcon, SectionDeleteIcon, SectionEditIcon } from "../../../../../assets/icons";
import { Color } from "../../../../../constants/colors";
import ImageDropzone from "../../../../common/ImageDropzone";
import CanvasDraw from "react-canvas-draw";
import { SignatureImageType } from "../../../../../models/enums/signature-image-type";
import UserModalProfessionalInfoRefs from "./refs";
import { SectionFieldType } from "../../../../../models/enums/section-field-type";
import ImageDropzoneRefs from "../../../../common/ImageDropzone/refs";
import ConfirmDeleteModal from "../../../../common/ConfirmDeleteModal";


const UserModalProfessionalInfo = React.forwardRef<UserModalProfessionalInfoRefs, UserModalProfessionalInfoProps>((props: UserModalProfessionalInfoProps, ref) => {

    const { userProfessionalInfo, setUserProfessionalInfo, setSignatureImage } = props;
    const sectionTNamespace = "sections";
    const { t } = useTranslation(["settings", sectionTNamespace]);
    const classes = useStyles();

    const canvasRef = useRef<CanvasDraw | null>(null);
    const imageDropzoneRef = useRef<ImageDropzoneRefs>(null);
    const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] = useState<boolean>(false);

    const brushColor = "#000000";
    const brushRadius = 2;
    const lazyRadius = 3;
    const validSignImageExtensions = ["jpg", "jpeg", "png"];
    const canvasExportImageMimeType = "image/png";
    const maxSignImageSize: number = 3 * 1024 * 1024; //3 Mb

    useImperativeHandle(ref, () => ({
        postprocessUserSignature: async () => {
            if (!canvasRef?.current ||
                userProfessionalInfo.signType !== SignatureImageType.Drawing ||
                !userProfessionalInfo.signatureReactCanvasDrawJson?.length ||
                userProfessionalInfo.signatureReactCanvasDrawJson?.length < 10)
                return;

            //  We use "any" here because of outdated TS types for the "react-canvas-draw" lib, some functions and props are not available with the explicit "CanvasDraw" type.
            //  Compare below GitHub code for more context and more APIs
            //  TS types:       https://github.com/DefinitelyTyped/DefinitelyTyped/blob/0692d01fdbf8cfff51b8754f8b7f1df0a70cbd54/types/react-canvas-draw/index.d.ts
            //  Original lib:   https://github.com/embiem/react-canvas-draw/blob/987d9fb6c27b232a5a2422a7b64b27852e7c71b2/src/index.js
            const dataUrl = (canvasRef.current as any).canvasContainer.childNodes[1].toDataURL(canvasExportImageMimeType);

            const res = await fetch(dataUrl);
            const blob = await res.blob();

            const file = new File([blob], "user_signature.png", { type: canvasExportImageMimeType });

            setSignatureImage(file);
        }
    }));


    function handleChangeFieldValue(event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>, fieldId: string) {
        let newProfessionalInfo = { ...userProfessionalInfo };

        if (!newProfessionalInfo.fieldsAndValues)
            return;

        const index = newProfessionalInfo.fieldsAndValues.findIndex(fv => fv.field.id === fieldId);
        if (index === -1)
            return;

        let fieldAndValue = { ...newProfessionalInfo.fieldsAndValues[index] } as FieldAndValue;
        fieldAndValue!.value = { ...fieldAndValue.value, type: SectionFieldType.Text, value: event.target.value, } as SectionFieldValueModel;
        newProfessionalInfo.fieldsAndValues[index] = fieldAndValue;
        setUserProfessionalInfo(newProfessionalInfo);
    }

    function handleSetUploadSignType(): void {
        setUserProfessionalInfo({ ...userProfessionalInfo, signType: SignatureImageType.Upload });
    }

    function handleSetDrawingSignType(): void {
        setUserProfessionalInfo({ ...userProfessionalInfo, signType: SignatureImageType.Drawing });
    }

    function handleCanvasOnChange(canvas: CanvasDraw) {
        setUserProfessionalInfo({ ...userProfessionalInfo, signatureReactCanvasDrawJson: canvas.getSaveData() });
    }

    function handleClearCanvas() {
        canvasRef.current?.clear();
        setUserProfessionalInfo({ ...userProfessionalInfo, signatureReactCanvasDrawJson: null });
    }

    function handleSignImageUpload(file: File): void {
        const fileIsInvalid = validateSignImage(file);
        if (!fileIsInvalid)
            return;

        setUserProfessionalInfo({ ...userProfessionalInfo, signatureImageUrl: URL.createObjectURL(file), uploadedSignatureWasDeleted: false });
        setSignatureImage(file);
    }

    function handleDeleteUploadedSignature(): void {
        setDeleteConfirmationModalIsOpen(false);
        setUserProfessionalInfo({ ...userProfessionalInfo, signatureImageUrl: null, uploadedSignatureWasDeleted: true });
        setSignatureImage(null);
        imageDropzoneRef?.current?.removeImage();
    }

    function handleEditUploadedSignature(): void {
        imageDropzoneRef?.current?.openFileExplorerDialog();
    }

    function validateSignImage(file: File) {
        if (validSignImageExtensions.indexOf(file.name.split('.').pop()!) === -1) {
            return false;
        }
        return file.size < maxSignImageSize;
    }

    function mapFieldsAndValuesToComponents() {
        return userProfessionalInfo.fieldsAndValues.map(fv =>
            <div
                className={classes.inputContainer}
                key={fv.field.id + "_input"}
            >
                <InputWithLabel
                    label={() => (<>
                        <Typography className={classes.label}>
                            {t(fv.field.name, { ns: sectionTNamespace })}
                        </Typography>
                        {fv.field.isRequired && <span className={classes.dot}>*</span>}
                    </>)}
                    placeholder={t("Enter the") + " " + t(fv.field.name, { ns: sectionTNamespace }).toLowerCase()}
                    value={fv.value?.value ?? ""}
                    onChange={(e) => handleChangeFieldValue(e, fv.field.id!)}
                    maxLength={80}
                    width={344}
                />
            </div>)
    }


    return (
        <div className={classes.root}>
            <span className={classes.requiredFieldsNote}>
                <span>
                    {t("Required fields")} <span className={classes.dot}>*</span>
                </span>
            </span>
            <div className={classes.fieldsContainer}>
                {mapFieldsAndValuesToComponents()}
            </div>
            <Typography className={classes.signSectionLabel}>
                {t("Sign")}
            </Typography>
            <div className={classes.signSectionContainer}>
                <Typography className={classes.note}>
                    {t("Upload an image or draw to create your signature in this section.")}
                </Typography>
                <div className={classes.signInteractiveSection}>
                    <div className={classes.modeButtonsBox}>
                        <Button
                            className={`${userProfessionalInfo.signType === SignatureImageType.Drawing
                                ? classes.signModeButtonEnabled
                                : classes.signModeButtonDisabled}
                                ${classes.leftSignModeButton}`}
                            onClick={handleSetDrawingSignType}>
                            {userProfessionalInfo.signType === SignatureImageType.Drawing ? <CheckIcon /> : <></>}
                            {t("Draw signature")}
                        </Button>
                        <Button
                            className={`${userProfessionalInfo.signType === SignatureImageType.Upload
                                ? classes.signModeButtonEnabled
                                : classes.signModeButtonDisabled}
                                ${classes.rightSignModeButton}`}
                            onClick={handleSetUploadSignType}>
                            {userProfessionalInfo.signType === SignatureImageType.Upload ? <CheckIcon /> : <></>}
                            {t("Upload signature")}
                        </Button>
                    </div>
                    <div className={classes.signInteractiveArea}>
                        {userProfessionalInfo.signType === SignatureImageType.Drawing
                            ? <CanvasDraw
                                className={classes.drawerBox}
                                ref={canvasDraw => (canvasRef.current = canvasDraw)}
                                canvasWidth={706}
                                canvasHeight={201}
                                brushRadius={brushRadius}
                                brushColor={brushColor}
                                lazyRadius={lazyRadius}
                                onChange={handleCanvasOnChange}
                                saveData={userProfessionalInfo.signatureReactCanvasDrawJson ?? canvasRef.current?.getSaveData()}
                                immediateLoading
                                clampLinesToDocument
                                hideInterface
                                hideGrid
                            />
                            : <div>
                                <ImageDropzone
                                    key={"sign-photo-upload"}
                                    ref={imageDropzoneRef}
                                    imageUrl={userProfessionalInfo.signatureImageUrl ?? undefined}
                                    emptyFileComponent={<>
                                        <PlusIcon
                                            style={{ height: 32, width: 32, marginLeft: 10, marginBottom: 6, color: Color.clear1 }}
                                            viewBox={"0 0 24 24"} />
                                        <Typography className={classes.addPhotoLabel}>
                                            {t("Add photo")}
                                        </Typography>
                                        <Typography className={classes.recommendedSizeLabel}>
                                            {t("Recommended size: 430px x 240px")}
                                        </Typography>
                                    </>}
                                    onChange={handleSignImageUpload}
                                    validationFunction={validateSignImage}
                                    dropzoneClass={userProfessionalInfo.signatureImageUrl
                                        ? classes.dropzoneBoxImage
                                        : classes.dropzoneBoxEmpty} />
                            </div>}
                    </div>
                </div>
                {userProfessionalInfo.signType === SignatureImageType.Upload
                    ? <div className={classes.uploadSignSectionFooter}>
                        <Typography className={classes.note}>
                            {t("Here you can upload an image with your signature. Allowed image formats: .png or .jpg.")}
                        </Typography>
                        <Button
                            key={"editUploadedSignature"}
                            onClick={handleEditUploadedSignature}
                            className={classes.editUploadedSignButton}
                        >
                            <SectionEditIcon />
                        </Button>
                        <Button
                            key={"deleteUploadedSignature"}
                            onClick={() => setDeleteConfirmationModalIsOpen(true)}
                            className={classes.deleteUploadedSignButton}
                        >
                            <SectionDeleteIcon />
                        </Button>
                    </div>
                    : <div className={classes.canvasSignSectionFooter}>
                        <Button
                            key={"clearCanvasButton"}
                            className={classes.deleteCanvasSignButton}
                            onClick={handleClearCanvas}>
                            {t("Clear")}
                        </Button>
                    </div>}
            </div>
            <ConfirmDeleteModal
                classModal={classes.deleteConfirmationModal}
                open={deleteConfirmationModalIsOpen}
                item={null}
                componentInfo={<div className={classes.deleteModalContentContainer}>
                    <Typography className={classes.deleteModalTitleText}>
                        {t("Delete this photo?")}
                    </Typography>
                </div>}
                onClose={() => setDeleteConfirmationModalIsOpen(false)}
                onDelete={handleDeleteUploadedSignature}
                textButtonConfirm={t("Delete")}
            />
        </div >
    );
})

export default UserModalProfessionalInfo;