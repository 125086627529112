import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
    sectionContainer: {
        boxSizing: "border-box",
        borderRadius: "12px",
        overflow: "hidden",
        backgroundColor: Color.gray1,
    },
    sectionLayout: {
        display: "flex",
        flexDirection: "column",
        padding: "8px 12px"
    },
    draggableClassName: {
        padding: "4px 0px"
    },
    innerFieldContentContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "baseline",
        gap: 10,
        flexWrap: "wrap"
    },
    fieldItemsContainer: {
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        gap: 13,
        flex: "1 1 100%"
    },
    fieldTypeContainer: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "baseline",
        gap: 5,
        marginRight: 9
    },
    fieldItemBox: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 7
    },
    fieldNameLabel: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray5,
        maxWidth: "300px",
        overflow: "hidden",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis"
    },
    fieldInnerComponentDivider: {
        fontSize: "21px",
        lineHeight: "23px",
        color: Color.gray4,
        padding: "0 7px"
    },
    fieldTypeNameLabel: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "15px",
        color: Color.gray4
    },
    fieldItemNameLabel: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray5,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        maxWidth: 980
    },
}));

export default useStyles;