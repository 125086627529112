import { Gender } from "./enums/gender";
export class CustomerEntity {
  id?: string;
  firstName: string;
  lastName: string;
  birthDate?: Date;
  mobileCodeIso3: string | null;
  mobileDigits: number | null;
  mobileCode: number | null;
  mobileTemplate: string | null;
  mobile: string;
  email?: string;
  gender?: Gender;
  customGender: string | null;
  contactableAppointments: boolean;
  contactableMassiveSendings: boolean;
  isMobileDuplicate: boolean;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.firstName = x.firstName;
      this.lastName = x.lastName;
      this.birthDate = x.birthDate ? new Date(x.birthDate) : undefined;
      this.mobileCodeIso3 = x.mobileCodeIso3;
      this.mobileDigits = x.mobileDigits;
      this.mobileCode = x.mobileCode;
      this.mobileTemplate = x.mobileTemplate;
      this.mobile = x.mobile;
      this.email = x.email;
      this.gender = x.gender;
      this.customGender = x.customGender;
      this.contactableAppointments = x.contactableAppointments;
      this.contactableMassiveSendings = x.contactableMassiveSendings;
      this.isMobileDuplicate = x.isMobileDuplicate;
    } else {
      this.id = undefined;
      this.firstName = "";
      this.lastName = "";
      this.birthDate = undefined;
      this.mobileCodeIso3 = null;
      this.mobileDigits = null;
      this.mobileCode = null;
      this.mobileTemplate = null;
      this.mobile = "";
      this.email = undefined;
      this.gender = undefined;
      this.customGender = null;
      this.contactableAppointments = true;
      this.contactableMassiveSendings = true;
      this.isMobileDuplicate = false;
    }
  }
}
