import { useTranslation } from "react-i18next";

import { selectAccountSettings } from "../../redux/store";
import { useAppSelector } from '../../redux/hooks';

import { TerminologyProps } from "./props";
import { TerminologyType } from "../../models/enums/terminology-type";
import { Terminology } from '../../models/terminology';
import { TerminologyForm } from "../../models/enums/terminology-form";


export default function useTerminology(props: TerminologyProps) {
  
  const {
    type,
    form,
    template,
    templateTermToLowercase
  } = props;
  
  const { t } = useTranslation(["terminologies"]);
  const accountSettings = useAppSelector(selectAccountSettings);
  
  function getDefaultTerminology(terminologyType: TerminologyType) {
    switch (terminologyType){
      case TerminologyType.Commission:
        return new Terminology({
          terminologyType: TerminologyType.Commission,
          singularForm: t('Commission'),
          pluralForm: t('Commissions'),
        })
      case TerminologyType.FiscalId:
        return new Terminology({
          terminologyType: TerminologyType.FiscalId,
          singularForm: t('Fiscal ID'),
          pluralForm: t('Fiscal ID'),
        })
      case TerminologyType.Treatment:
          return new Terminology({
            terminologyType: TerminologyType.Treatment,
            singularForm: t('Treatment'),
            pluralForm: t('Treatment'),
          })
    }
  }
  
  function getTerminology() {
    const terminology = accountSettings.terminologies.find(t => t.terminologyType === type)
      ?? getDefaultTerminology(type);
    return form === TerminologyForm.Plural ? terminology.pluralForm : terminology.singularForm
  }
  
  function formatTemplate() {
    const countInterpolationsInTargetString = ((template!).match(/\{[0-9]+}/g) || []).length;
    if (countInterpolationsInTargetString !== 1) {
      throw new Error("The number of terms does not match the number of interpolations in the target string.");
    }
    const term = !!templateTermToLowercase ? getTerminology().toLowerCase() : getTerminology();
    let reg = new RegExp("\\{\\{" + 0 + "\\}\\}", "gm");
    return template!.replace(reg, term);
  }
  
  return !!template ? formatTemplate() : getTerminology();
}