import React, { useEffect, useReducer, useState } from "react";
import { Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from "react-i18next";

import "antd/dist/antd.css";
import { TimePicker } from "antd";
import moment from "moment";

import { useAppSelector } from "../../../../../redux/hooks";
import Switch from "./../../../../common/Switch";
import Select, { SelectItem } from "../../../../common/Select";
import PopupChangeSettings from "./../WeekdaysChangeSettingsModal";
import { AlertPopupType } from "../WeekdaysChangeSettingsModal/props";
import { WeekdayService } from "../../../../../api/settings-weekdays-service";
import { AccountService } from "../../../../../api/account-service";
import { Weekday } from "../../../../../models/weekday";
import { WeekdaysEnum } from "../../../../../models/enums/weekdays";
import { TimezoneService } from "../../../../../api/settings-timezone-service";
import { ArrowDropDownIcon, InfoIcon } from "../../../../../assets/icons";

import { useStyles } from "./css";
import { useAppDispatch } from "../../../../../redux/hooks";
import { alertsActions } from "../../../../../redux/alerts-slice";
import { schedulerActions } from "../../../../../redux/scheduler-slice";
import { setAccountSettings } from "../../../../../redux/account-settings-slice";
import { AppointmentTimeIntervalGapMinute } from "../../../../../models/enums/appointment-time-interval-gap-minute";
import { AccountScheduleSectionSettingsModel } from "../../../../../models/account-schedule-section-settings-model";
import IconTooltip from "../../../../common/IconTooltip";
import { selectAccountSettings } from "../../../../../redux/store";
import { SchedulerBlockDurationMinute } from "../../../../../models/enums/scheduler-block-duration-minute";

import { SettingsEnterpriseProps } from "../../../../../models/interfaces/settings-enterprise-props";

export default (props: SettingsEnterpriseProps) => {

  const { settingsSelectedEstablishmentId } = props;

  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const accountSettings = useAppSelector(selectAccountSettings);
  

  const [popupOpen, setPopupOpen] = useState<boolean>(false);
  const [scheduleSectionSettingsModel, setScheduleSectionSettingsModel] = 
    useState<AccountScheduleSectionSettingsModel>(new AccountScheduleSectionSettingsModel());
  const [apptsAffected, setApptsAffected] = useState<number>(0);
  const [popupType, setPopupType] = useState<AlertPopupType>(
    AlertPopupType.AvailableHours
  );

  const [weekdaysState, setWeekdaysState] = useState<Weekday[]>([]);

  const [timezoneItems, setTimezoneItems] = useState<SelectItem[]>([]);
  const [timeZoneSelected, setTimeZoneSelected] = useState<string>("");
  const [, forceUpdate] = useReducer((x) => x + 1, 0);


  const daysFalse = [false, false, false, false, false, false, false];
  const [selectOpening, setSelectOpening] = useState<boolean[]>(daysFalse);
  const [selectClosing, setSelectClosing] = useState<boolean[]>(daysFalse);

  const dispatch = useAppDispatch();
  const { setWeekDays } = schedulerActions;
  const { enqueueAlert } = alertsActions;

  const weekDays = [
    t("Sunday"),
    t("Monday"),
    t("Tuesday"),
    t("Wednesday"),
    t("Thursday"),
    t("Friday"),
    t("Saturday"),
  ];
  const [weekDaysOrder, setWeekDaysOrder] = useState<number[]>([
    1, 2, 3, 4, 5, 6, 0,
  ]);
  const format = "HH:mm";

  const timeGapsSelectItems: SelectItem[] = [
    {
      key: AppointmentTimeIntervalGapMinute.Five,
      value: `5 ${t("min")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.Ten,
      value: `10 ${t("min")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.Fifteen,
      value: `15 ${t("min")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.Twenty,
      value: `20 ${t("min")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.Thirty,
      value: `30 ${t("min")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.Sixty,
      value: `1:00 ${t("hour")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.Ninety,
      value: `1:30 ${t("hours")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.HundredTwenty,
      value: `2:00 ${t("hours")}`
    }
  ];
  
  const blockDurationsSelectItems: SelectItem[] = [
    {
      key: SchedulerBlockDurationMinute.Five,
      value: `5 ${t("min")}`
    },
    {
      key: SchedulerBlockDurationMinute.Ten,
      value: `10 ${t("min")}`
    },
    {
      key: SchedulerBlockDurationMinute.Fifteen,
      value: `15 ${t("min")}`
    },
    {
      key: SchedulerBlockDurationMinute.Thirty,
      value: `30 ${t("min")}`
    },
    {
      key: SchedulerBlockDurationMinute.Sixty,
      value: `1 ${t("hour")}`
    },
    {
      key: SchedulerBlockDurationMinute.Ninety,
      value: `1.5 ${t("hours")}`
    }
  ];

  const handleLaborDay = async (event: any, dayNumber: number) => {
    var index = weekdaysState.findIndex((x) => x.dayNumber === dayNumber);

    let isOpen = event.target.checked;
    let newDay = [...weekdaysState];

    newDay[index].isOpen = isOpen;

    setWeekdaysState(newDay);
    saveWeekday(newDay[index]);
  };

  const toTimestamp = (strDate: string) => {
    const newDate = "2000-01-01 " + strDate;
    let datum = Date.parse(newDate);
    return datum / 1000;
  };

  const handleOpening = (e: any, dayNumber: number) => {
    let time = e;
    let index = weekdaysState.findIndex((x) => x.dayNumber === dayNumber);
    let newDay = [...weekdaysState];

    if (toTimestamp(time) < toTimestamp(newDay[index].closing!)) {
      newDay[index].opening = time;
      setWeekdaysState(newDay);
      saveWeekday(newDay[index]);
    } else {
      dispatch(
        enqueueAlert({
          type: "Error",
          title: t("Verify opening time"),
          description: t("It is not possible to select a opening time after closing time")
        })
      );
    }
    setSelectOpening(daysFalse);
  };

  const handleClosing = (e: any, dayNumber: number) => {
    let time = e;
    let index = weekdaysState.findIndex((x) => x.dayNumber === dayNumber);
    let newDay = [...weekdaysState];

    if (toTimestamp(time) > toTimestamp(newDay[index].opening!)) {
      newDay[index].closing = time;
      setWeekdaysState(newDay);
      saveWeekday(newDay[index]);
    } else {
      dispatch(
        enqueueAlert({
          type: "Error",
          title: t("Verify closing time"),
          description: t("It is not possible to select a closing time before opening time")
        })
      );
    }
    setSelectClosing(daysFalse);
  };

  const saveWeekday = (newDay: Weekday) => {
    (async () => {
      const total = settingsSelectedEstablishmentId
        ? await WeekdayService.updateWeekday(newDay, settingsSelectedEstablishmentId)
        : await WeekdayService.updateWeekday(newDay);
            
      if (total && +total > 0) {
        (async () => {
          await getDays();
        })();

        setPopupType(AlertPopupType.AvailableHours);
        setApptsAffected(total);
        setPopupOpen(true);
      }
    })();
  };

  useEffect(() => {
    (async () => {
      await getDays();
      await getTimezones();
      await getAccountMultipleAppointmentsSettings();
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsSelectedEstablishmentId]);

  const getAccountMultipleAppointmentsSettings = async () => {
    const response = settingsSelectedEstablishmentId
      ? await AccountService.getAccountScheduleSectionSettings(settingsSelectedEstablishmentId)
      : await AccountService.getAccountScheduleSectionSettings();
    setScheduleSectionSettingsModel(response);
  };

  const getDays = async () => {
    setWeekDaysOrder([1, 2, 3, 4, 5, 6, 0]);

    let daysTemplate = await initializeWeekdays();
    let daysDb = settingsSelectedEstablishmentId
        ? await WeekdayService.getWeekdays(settingsSelectedEstablishmentId)
        : await WeekdayService.getWeekdays();
          
    dispatch(setWeekDays(daysDb))

    daysTemplate.map((item) => {
      var idx = daysTemplate.findIndex((x) => x.dayNumber === item.dayNumber);
      let d = daysDb.filter((a) => a.dayNumber === item.dayNumber);

      if (d.length === 1 && idx >= 0) {
        daysTemplate[idx].isOpen = d[0].isOpen;
        if (d[0].opening !== "") daysTemplate[idx].opening = d[0].opening;
        if (d[0].closing !== "") daysTemplate[idx].closing = d[0].closing;
      }
    });

    setWeekdaysState(daysTemplate);
  };

  const handleTimezone = async (event: any) => {
    let value = event.target.value;
    const data = settingsSelectedEstablishmentId
        ? await TimezoneService.updateTimezone(value, settingsSelectedEstablishmentId)
        : await TimezoneService.updateTimezone(value);

    if (data && data.total && +data.total > 0) {
      setPopupType(AlertPopupType.Timezone);
      setApptsAffected(data.total);
      setPopupOpen(true);

      await getTimezones();
    } else {
      setTimeZoneSelected(value);
    }
  };

  const getTimezones = async () => {
    const timezoneDb = settingsSelectedEstablishmentId
        ? await TimezoneService.getTimezones(localeApp, settingsSelectedEstablishmentId)
        : await TimezoneService.getTimezones(localeApp);

    const items: SelectItem[] = timezoneDb.map((i) => ({
      key: i.systemId,
      value: i.displayName,
    }));
    setTimezoneItems(items);

    const itemSelected = timezoneDb.filter((x) => x.isSelected)[0]?.systemId;
    setTimeZoneSelected(itemSelected);
  };

  const initializeWeekdays = async () => {
    let days: Weekday[] = [];

    weekDaysOrder.map((i) => {
      let dayName = weekDays[i];
      let opening = null;
      let closing = null;
      let isOpen: boolean = true;

      if (i >= WeekdaysEnum.Monday && i <= WeekdaysEnum.Friday) {
        opening = "09:30";
        closing = "19:00";
      }

      if (i === WeekdaysEnum.Saturday || i === WeekdaysEnum.Sunday) {
        opening = "09:30";
        closing = "15:00";
      }

      if (i === WeekdaysEnum.Sunday) isOpen = false;

      days.push(
        new Weekday({
          accountId: settingsSelectedEstablishmentId ?? accountSettings.accountId,
          dayNumber: i,
          dayName: dayName,
          opening: opening,
          closing: closing,
          isOpen: isOpen,
        })
      );
    });

    return days;
  };

  const handleOpenTimepicker = (
    type: number,
    day: number,
    openclose: boolean
  ) => {
    if (type === 0) {
      // opening
      let dayOpen = selectOpening;
      dayOpen[day] = openclose;
      setSelectOpening(dayOpen);
    }

    if (type === 1) {
      // closing
      let dayClose = selectClosing;
      dayClose[day] = openclose;
      setSelectClosing(dayClose);
    }

    forceUpdate();
  };

  const handleChangeMultiplesAppointmentsSetting = (event: any) => {
    (async () => {
      const result = settingsSelectedEstablishmentId
      ? await AccountService.updateAccountScheduleSectionSettings(
        new AccountScheduleSectionSettingsModel({
            ...scheduleSectionSettingsModel,
          accountId: settingsSelectedEstablishmentId,
          hasMultipleAppointments: event.target.checked
        })
      )
      : await AccountService.updateAccountScheduleSectionSettings(
        new AccountScheduleSectionSettingsModel({
        ...scheduleSectionSettingsModel,
          hasMultipleAppointments: event.target.checked
        })
      );
      setScheduleSectionSettingsModel(result);
    })();
  };
  
  async function handleChangeScheduleSimultaneousServices(event: any) {
    let value = event.target.checked;
    
    const result = settingsSelectedEstablishmentId
    ? await AccountService.updateAccountScheduleSectionSettings(
      new AccountScheduleSectionSettingsModel({
        ...scheduleSectionSettingsModel,
        accountId: settingsSelectedEstablishmentId,
        scheduleSimultaneousServicesEnabled: value
      })
    )
    : await AccountService.updateAccountScheduleSectionSettings(
      new AccountScheduleSectionSettingsModel({
        ...scheduleSectionSettingsModel,
        scheduleSimultaneousServicesEnabled: value
      })
    )
    setScheduleSectionSettingsModel(result);
    if (result) {
      dispatch(setAccountSettings({ ...accountSettings, scheduleSimultaneousServicesEnabled: value }));
    }
  }

  const handleChangeScheduleTimeIntervalGapSetting = (event: any) => {
    (async () => {
      const result = settingsSelectedEstablishmentId
      ? await AccountService.updateAccountScheduleSectionSettings(
        new AccountScheduleSectionSettingsModel({
          ...scheduleSectionSettingsModel,
          accountId: settingsSelectedEstablishmentId,
          gapBetweenTimeIntervals: event.target.value
        })
      )
      : await AccountService.updateAccountScheduleSectionSettings(
        new AccountScheduleSectionSettingsModel({
          ...scheduleSectionSettingsModel,
          gapBetweenTimeIntervals: event.target.value
        })
      );
      setScheduleSectionSettingsModel(result);
    })();
  };
  
  const handleChangeBlockDurationTimeIntervalsSetting = (event: any) => {
    (async () => {
      const updatedSettings = new AccountScheduleSectionSettingsModel({
        ...scheduleSectionSettingsModel,
        schedulerBlockDurationMinutes: event.target.value,
        ...(settingsSelectedEstablishmentId && { accountId: settingsSelectedEstablishmentId })
      });

      const result = await AccountService.updateAccountScheduleSectionSettings(updatedSettings);

      setScheduleSectionSettingsModel(result);
      if (result) {
        const updatedAccountSettings = {
          ...accountSettings,
          schedulerBlockDurationMinutes: result.schedulerBlockDurationMinutes,
          ...(settingsSelectedEstablishmentId && { accountId: settingsSelectedEstablishmentId })
        };
        dispatch(setAccountSettings(updatedAccountSettings));
        }
    })();
  };

  return (
    <div className={classes.section}>
      <Typography className={classes.title}>{t("Schedule")}</Typography>

      <div className={classes.divArea}>
        <div style={{ marginBottom: 40 }}>
          <Grid container spacing={0} >
            <Grid item xs={4}>
              <Typography className={classes.titleSection}>
                {t("Time zone")}
              </Typography>
            </Grid>
            <Grid item xs={3} className={classes.titleWithTooltipContainer}>
              <Typography className={classes.titleSection}>
                {t("Default block duration")}
              </Typography>
              <IconTooltip
                key={"gapTooltip"}
                classNameRoot={classes.iconTooltipRoot}
                placement={"right"}
                arrowPlacement={"left"}
                icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 10 }} viewBox={"0 0 12 12"} />}
                tooltipText={t("Adjust the duration of the calendar blocks")}
              />
            </Grid>
            <Grid item xs={3} className={classes.titleWithTooltipContainer}>
              <Typography className={classes.titleSection}>
                {t("Time slot for scheduling an appointment")}
              </Typography>
              <IconTooltip
                  key={"gapTooltip"}
                  classNameRoot={classes.iconTooltipRoot}
                  placement={"right"}
                  arrowPlacement={"left"}
                  icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 10 }} viewBox={"0 0 12 12"} />}
                  tooltipText={t("Time interval displayed between the options to schedule an appointment")}
                />
            </Grid>
          </Grid>
          <Grid container spacing={0} >
            <Grid item xs={4}>
              <Select
                key={"cmbTimeZone"}
                width={230}
                placeholder={t("Time zone")}
                value={timeZoneSelected}
                onChange={(e) => handleTimezone(e)}
                colorArrowIcon={"#5C6477"}
                items={timezoneItems}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                key={"defaultBlockDurationClient"}
                width={254}
                value={scheduleSectionSettingsModel.schedulerBlockDurationMinutes}
                onChange={(e) => handleChangeBlockDurationTimeIntervalsSetting(e)}
                colorArrowIcon={"#5C6477"}
                items={blockDurationsSelectItems}
              />
            </Grid>
            <Grid item xs={3}>
              <Select
                key={"timeGapAccountClient"}
                width={254}
                value={scheduleSectionSettingsModel.gapBetweenTimeIntervals}
                onChange={(e) => handleChangeScheduleTimeIntervalGapSetting(e)}
                colorArrowIcon={"#5C6477"}
                items={timeGapsSelectItems}
              />
            </Grid>
          </Grid>
        </div>
        <Grid container spacing={3} className={classes.headerDays}>
          <Grid item xs={2}>
            <div className={classes.headerLabel} style={{ marginLeft: 0 }}>{t("Appointment")}</div>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={1}
          className={classes.rowWeekDays}
        >
          <Grid item  xs={4}>
            <div style={{ display: "flex", alignItems: "center", marginRight: 16, height: 36, marginLeft: 2 }}>
              <Switch
                value={scheduleSectionSettingsModel.hasMultipleAppointments}
                onChange={(e) => handleChangeMultiplesAppointmentsSetting(e)}
                baseClass={classes.switchBase}
              />
              <Typography
                className={classes.multipleAppointments}
                style={{ marginLeft: 16, display: "inline-block" }}
              >
                {t("Schedule simultaneous appointments")}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={5}>
            <div style={{ display: "flex", alignItems: "center", height: 36, marginLeft: 2 }}>
              <Switch
                value={scheduleSectionSettingsModel.scheduleSimultaneousServicesEnabled}
                onChange={(e) => handleChangeScheduleSimultaneousServices(e)}
                baseClass={classes.switchBase}
              />
              <Typography
                className={classes.multipleAppointments}
                style={{ marginLeft: 16, display: "inline-block" }}
              >
                {t("Schedule appointments with simultaneous services")}
              </Typography>
            </div>
          </Grid>
        </Grid>
        <Grid container spacing={3} className={classes.headerDays}>
          <Grid item xs={4}>
            <div className={classes.headerLabel} style={{ marginLeft: 0 }}>{t("Weekdays")}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.headerLabel}>{t("Opening time")}</div>
          </Grid>
          <Grid item xs={3}>
            <div className={classes.headerLabel}>{t("Closing time")}</div>
          </Grid>
        </Grid>

        {weekDaysOrder.map((day) => {
          const item = weekdaysState.filter((a) => a.dayNumber === day)[0];

          return (
            item && (
              <Grid
                key={item.dayNumber}
                container
                spacing={1}
                className={classes.rowWeekDays}
              >
                <Grid item xs={4}>
                  <div
                    style={{ marginRight: 20, width: 50, display: "inline", marginLeft: 0 }}
                  >
                    <Switch
                      value={item.isOpen}
                      onChange={(e) => handleLaborDay(e, item.dayNumber)}
                      baseClass={classes.switchBase}
                    />
                  </div>
                  <div className={classes.dayLabel}>{item.dayName}</div>
                </Grid>
                <Grid item xs={3}>
                  <div>
                    <TimePicker
                      key={`opening_${item.dayNumber}`}
                      onOk={(time) => {
                        handleOpening(time.format(format), item.dayNumber);
                      }}
                      className={`${classes.selectStyle} ${item.isOpen ? classes.selectHrs : classes.selectHrsClose
                        }`}
                      placeholder="Opened"
                      inputReadOnly={true}
                      showNow={false}
                      value={moment(item.opening, format)}
                      disabled={!item.isOpen}
                      onOpenChange={(e) =>
                        handleOpenTimepicker(0, item.dayNumber, e)
                      }
                      format={format}
                    />
                    <ArrowDropDownIcon
                      style={{
                        top: "inherit",
                        right: 25,
                        pointerEvents: "none",
                        color: item.isOpen ? "#5C6477" : "#CCC",
                        position: "relative",
                        transform: selectOpening[item.dayNumber]
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </div>
                </Grid>
                <Grid item xs={3}>
                  <div>
                    <TimePicker
                      key={`closing_${item.dayNumber}`}
                      className={`${classes.selectStyle} ${item.isOpen ? classes.selectHrs : classes.selectHrsClose
                        }`}
                      onOk={(time) => {
                        handleClosing(time.format(format), item.dayNumber);
                      }}
                      placeholder="Closed"
                      inputReadOnly={true}
                      showNow={false}
                      value={moment(item.closing, format)}
                      disabled={!item.isOpen}
                      onOpenChange={(e) =>
                        handleOpenTimepicker(1, item.dayNumber, e)
                      }
                      format={format}
                    />
                    <ArrowDropDownIcon
                      style={{
                        top: "inherit",
                        right: 25,
                        pointerEvents: "none",
                        color: item.isOpen ? "#5C6477" : "#CCC",
                        position: "relative",
                        transform: selectClosing[item.dayNumber]
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            )
          );
        })}
      </div>

      <PopupChangeSettings
        open={popupOpen}
        onClose={() => setPopupOpen(false)}
        count={apptsAffected}
        type={popupType}
      />
    </div>
  );
};
