
export class ReportColumnGroup {
  id: string;
  name: string;
  columns: ReportColumn[];
  order: number;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.name = x.name;
      this.columns = x.columns;
      this.order = x.order;
    }
    else {
      this.id = "";
      this.name = "";
      this.columns = [];
      this.order = 1;
    }
  }
}

export class ReportColumn {
  id: string;
  order: number;
  isPrimary: boolean;
  name: string;
  tooltip?: string;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.order = x.order;
      this.isPrimary = x.isPrimary;
      this.name = x.name;
      this.tooltip = x.tooltip;
    }
    else {
      this.id = "";
      this.order = 1;
      this.isPrimary = false;
      this.name = "";
      this.tooltip = undefined;
    }
  }
}