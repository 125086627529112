import React from "react";
import { CustomerFrequencyType } from "../../models/enums/customer-frequency-type";
import DotIcon from "./DotIcon";

interface StatusIconProps {
  status: CustomerFrequencyType;
  [x: string]: any;
}

const StatusIcon = (props: StatusIconProps) => {
  const { status, ...otherProps } = props;
  let color: string = "";
  switch (status) {
    case CustomerFrequencyType.Frequent:
      color = "#36CE91";
      break;
    case CustomerFrequencyType.Infrequent:
      color = "#FFC626";
      break;
    default:
      color = "#F15857";
      break;
  }

  return (
    <DotIcon
      color={color}
      viewBox="0 0 14 14"
      style={{ width: "14px", height: "14px", marginRight: "6px" }}
      {...otherProps}
    />
  );
};

export default StatusIcon;
