import React, { CSSProperties } from "react";
import { useTranslation } from "react-i18next";

import { FieldListProps } from "./props";
import useStyles from "./css";
import { Box, Grid, Typography } from "@material-ui/core";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { SectionFieldModel } from "../../../../../models/section-field-model";
import DraggableBase from "../../../../common/DraggableBase";
import { SectionFieldType } from "../../../../../models/enums/section-field-type";
import {
    SectionFieldCheckboxIcon,
    SectionFieldCheckboxItemIcon,
    SectionFieldDateIcon,
    SectionFieldDropdownIcon,
    SectionFieldDropdownItemIcon,
    SectionFieldEmailIcon,
    SectionFieldNumericIcon,
    SectionFieldOptionalTextFieldIcon,
    SectionFieldPhoneIcon,
    SectionFieldRadioIcon,
    SectionFieldRadioItemIcon,
    SectionFieldTextIcon
} from "../../../../../assets/icons";
import { SettingsPatientFormsService } from "../../../../../api/settings-patient-forms-service";
import { SectionFieldUpdateOrderRequest } from "../../../../../models/section-field-update-order-request";

export default function FieldList(props: FieldListProps) {
    const sectionTNamespace = "sections";
    const { t } = useTranslation(["general", sectionTNamespace]);
    const { section, onEditClick, onDeleteClick } = props;
    const classes = useStyles();

    const getReorderedFields = (fields: SectionFieldModel[], prevOrder: number, newOrder: number, movedFieldId: string) => {
        const result = Array.from(fields);

        const positionsMoved = newOrder - prevOrder;
        const isMovedDown = positionsMoved > 0;
        const affectedFieldIds = isMovedDown
            ? fields.filter(f => f.id === movedFieldId ||
                f.order! <= newOrder &&
                f.order! >= prevOrder)
                .map(f => f.id)
            : fields.filter(f => f.id === movedFieldId ||
                f.order! >= newOrder &&
                f.order! <= prevOrder)
                .map(f => f.id);

        result.forEach(f => {
            if (!affectedFieldIds.includes(f.id!))
                return;
            if (f.id === movedFieldId) {
                f.order = newOrder;
                return;
            }
            f.order = isMovedDown ? f.order! - 1 : f.order! + 1;
        });

        return result;
    };

    const dragUpdateHandler = async (update: DropResult) => {
        if (update.destination && update.destination.index < 0) {
            return;
        }
    }

    const dragEndHandler = async (result: DropResult) => {
        const { destination, source, draggableId } = result;

        //dropped not at droppable
        if (!destination)
            return;

        //dropped at other field list
        if (destination.droppableId !== source.droppableId)
            return;

        //dropped at the same spot
        if (destination.droppableId === source.droppableId && destination.index === source.index)
            return;

        if (destination.index < 0)
            return;

        const prevOrder = source.index + 1;
        const newOrder = destination.index + 1;

        section.fields = getReorderedFields(section.fields, prevOrder, newOrder, draggableId);

        await SettingsPatientFormsService.reorderSectionFields(new SectionFieldUpdateOrderRequest(
            {
                id: draggableId,
                sectionId: section.id,
                newOrder: newOrder,
                previousOrder: prevOrder
            }));
    };

    const fieldContentDividerComponent =
        <Typography className={classes.fieldInnerComponentDivider}>
            |
        </Typography>;

    const getFieldTypeNameComponent = (typeName: string) => {
        return <Typography className={classes.fieldTypeNameLabel}>
            {t(typeName)}
        </Typography>
    };

    const getFieldTypeComponent = (type: SectionFieldType) => {
        switch (type) {
            case SectionFieldType.PhoneNumber:
                return <>
                    <SectionFieldPhoneIcon style={{ position: "relative", top: 6 }} />
                    {getFieldTypeNameComponent("PhoneNumberPatientFormsSections")}
                </>
            case SectionFieldType.Email:
                return <>
                    <SectionFieldEmailIcon style={{ position: "relative", top: 6 }} />
                    {getFieldTypeNameComponent("Email")}
                </>
            case SectionFieldType.Radio:
                return <>
                    <SectionFieldRadioIcon style={{ position: "relative", top: 4 }} />
                    {getFieldTypeNameComponent("Multiple choice")}
                </>
            case SectionFieldType.Checkbox:
                return <>
                    <SectionFieldCheckboxIcon style={{ position: "relative", top: 6 }} />
                    {getFieldTypeNameComponent("Checkboxes")}
                </>
            case SectionFieldType.Dropdown:
                return <>
                    <SectionFieldDropdownIcon style={{ position: "relative", top: 6 }} />
                    {getFieldTypeNameComponent("Dropdown list")}
                </>
            case SectionFieldType.Text:
                return <>
                    <SectionFieldTextIcon style={{ position: "relative", top: 6 }} />
                    {getFieldTypeNameComponent("Free text")}
                </>
            case SectionFieldType.Numeric:
                return <>
                    <SectionFieldNumericIcon style={{ position: "relative", top: 6 }} />
                    {getFieldTypeNameComponent("Numeric field")}
                </>
            case SectionFieldType.Date:
                return <>
                    <SectionFieldDateIcon style={{ position: "relative", top: 6 }} />
                    {getFieldTypeNameComponent("Date")}
                </>
        };
    };

    const tryGetFieldItemComponents = (field: SectionFieldModel) => {
        let iconComponent: JSX.Element;

        switch (field.type) {
            case SectionFieldType.Checkbox:
                iconComponent = <SectionFieldCheckboxItemIcon />;
                break;
            case SectionFieldType.Radio:
                iconComponent = <SectionFieldRadioItemIcon />;
                break;
            case SectionFieldType.Dropdown:
                iconComponent = <SectionFieldDropdownItemIcon />;
                break;
            default:
                return <>

                </>
        }

        return <>
            {field.items.sort((i1, i2) => i1.order! - i2.order!).map(i =>
                <div className={classes.fieldItemBox}>
                    {iconComponent}
                    <Typography className={classes.fieldItemNameLabel}>
                        {t(i.name!, { ns: sectionTNamespace })}
                    </Typography>
                </div>)
            }
        </>

    };

    const getOptionalFreeTextComponent = (field: SectionFieldModel) => {
        let optionalFreeTextComponent: JSX.Element;

        if (field.isAdditionalTextareaEnabled) {
            optionalFreeTextComponent = <>
                <div>
                    {fieldContentDividerComponent}
                </div>
                <div className={classes.fieldItemBox} style={{ marginLeft: "14px", paddingTop: 6 }}>
                    <SectionFieldOptionalTextFieldIcon />
                    <Typography className={classes.fieldItemNameLabel}>
                        {t("Free text")}
                    </Typography>
                </div>
            </>
        }
        else {
            optionalFreeTextComponent = <></>
        }

        return <>
            {optionalFreeTextComponent}
        </>

    };

    const getInnerFieldContent = (field: SectionFieldModel) => {
        return <div className={classes.innerFieldContentContainer}>
            <Typography className={`${classes.fieldNameLabel} ${field.isRequired ? classes.isRequiredAsterisk : ""}`}>
                {t(field.name!, { ns: sectionTNamespace })}
            </Typography>
            <div style={{ padding: "0px 7px" }}>
                {fieldContentDividerComponent}
            </div>
            <div className={classes.fieldTypeContainer}>
                {getFieldTypeComponent(field.type!)}
            </div>
            <div style={{ display: "flex" }}>
                {getOptionalFreeTextComponent(field)}
            </div>

            <div className={classes.fieldItemsContainer}>
                {tryGetFieldItemComponents(field)}
            </div>
        </div>
    };

    const getInnerContainerStyle = (type: SectionFieldType): CSSProperties => {
        if (type === SectionFieldType.Radio) {
            return {
                minHeight: 48
            };
        }
        return {
            height: 48
        }
    };


    const mapFieldsToComponents = (fields: SectionFieldModel[]) => {
        return fields.sort((f1, f2) => f1.order! - f2.order!).map(f =>
            <DraggableBase
                key={f.id!}
                className={classes.draggableClassName}
                height={48}
                innerContainerStyle={getInnerContainerStyle(f.type)}
                draggableId={f.id!}
                index={f.order! - 1}
                isEditable={f.isEditable!}
                isEditableByButton={f.isEditable!}
                isRemovable={f.isRemovable!}
                onEditClick={() => onEditClick(f.id!)}
                onDeleteClick={() => onDeleteClick(f.id!)}
            >
                {getInnerFieldContent(f)}
            </DraggableBase>)
    };

    return <>
        <DragDropContext onDragUpdate={dragUpdateHandler} onDragEnd={dragEndHandler}>
            <Droppable key={section.id!} droppableId={section.id!}>
                {(provided) => (
                    <Box>
                        <div className={classes.sectionContainer} >
                            <Grid
                                container
                                justifyContent="center"
                                className={classes.sectionLayout}
                                ref={provided.innerRef}
                                {...provided.droppableProps}
                            >
                                {mapFieldsToComponents(section.fields)}
                                {provided.placeholder}
                            </Grid>
                        </div>
                    </Box>
                )}
            </Droppable>
        </DragDropContext>
    </>
};