export enum PaymentFieldUpdate {
    None = 0,
    Currency = 1,
    IsPaymentCash,
    IsPaymentCard,
    IsPaymentCardMasterCard,
    IsPaymentCardVisa,
    IsPaymentCardOther,
    IsPaymentBankTransfer,
    IsPaymentOxxo,  // oxxoCardNumber
    IsPaymentMercadoPago, // hasAcceptedMercadoPagoTerms
    HasWhatsAppPaymentReminderOnAppointmentConfirmation,
    HasWhatsAppPaymentReminderOnAppointmentReminder,
    HasWhatsAppPaymentReminderAfterAppointment, // DaysAfterAppointmentToSendWhatsAppPaymentReminder
    IsCashRegisterActivated, // InitialCashInCashRegister
    IsSendReceiptByWhatsApp
}
