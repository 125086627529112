import { ApiClient } from "./api-client";
import { CustomersClassification } from "../models/customers-classification";
import { CustomersClassifications } from "../models/customers-classifications";
import { CustomerClassificationType, CustomersClassificationEntity } from "../models/customers-classification-entity";

export class CustomerClassificationService {

    private static baseUrl = "/customer";

    static getCustomerClassification(): Promise<CustomersClassification> {
        return ApiClient.get(`${this.baseUrl}/getclassification`).then(
            (data) => new CustomersClassification(data.customerClassification)
        );
    }
    
    static getCustomerClassificationByEstablishments(establishmentIds: string[] | undefined): Promise<CustomersClassification> {
        let endpoint = `${this.baseUrl}/getclassificationbyestablishments?`
        
        endpoint += Object.entries({
            EstablishmentIds: establishmentIds && establishmentIds.length > 0 ? establishmentIds : [],
        }).flatMap(([key, value]) => [value].flat().map(v => [key, v]))
          .map(it => it.join("="))
          .join("&");
        
        return ApiClient.get(endpoint).then(
          (data) => new CustomersClassification(data.customerClassification)
        );
    }


    private static baseUrlCustomerClassification = "/customerclassification";

    static getCustomerClassifications(establishmentAccountId?: string): Promise<CustomersClassifications> {
        let url = `${this.baseUrlCustomerClassification}/getclassifications`;

        if (establishmentAccountId) {
            url += `?AccountId=${establishmentAccountId}`;
        }

        return ApiClient.get(url)
            .then((data) => {
                return new CustomersClassifications(data.customerClassifications);
            });
    }

    static getCustomerClassificationList(establishmentAccountId?: string): Promise<CustomersClassificationEntity[]> {
        let url = `${this.baseUrlCustomerClassification}/getclassificationlist`;

        if (establishmentAccountId) {
            url += `?AccountId=${establishmentAccountId}`;
        }

        return ApiClient.get(url)
            .then((data) => {
                return data.customerClassificationList.map((x: CustomersClassificationEntity) => new CustomersClassificationEntity(x));
            });
    }

    static updateCustomersClassification(frecuencyId: string, type: CustomerClassificationType, establishmentAccountId?: string): Promise<any> {
        return ApiClient.post(`${this.baseUrlCustomerClassification}/updatecustomersclassification`, {
            accountId: establishmentAccountId,
            customerFrequencyId: frecuencyId,
            ClassificationTypeUpd: type
        }).then((data) => {
            return (data.customerClassifications);
        });
    }

}
