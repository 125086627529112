import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  legend: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    marginTop: 24,
  },
  required: {
    color: "#6462F3",
  },
  optional: {
    fontWeight: "normal",
  },
  fieldsContainer: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& > div": {
      marginTop: 24
    }
  },
  switch: {
    display: "flex",
    alignItems: "center",
  },
  switchLabel: {
    margin: "0px 0px 0px 16px",
  },
  label: {
    display: "flex",
    margin: 0,
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey5,
  },
  description: {
    margin: 0,
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
  },
}));

export default useStyles;
