import { EmergencyContact } from "./emergency-contact";

export class CreateEmergencyContacts {
  customerId: string | null;
  emergencyContacts: EmergencyContact[] | null;
  constructor(x?: any) {
    if (typeof x === "object") {
      this.customerId = x.customerId;
      this.emergencyContacts = x.emergencyContacts;
    } else {
      this.customerId = null;
      this.emergencyContacts = null;
    }
  }
}
