import React from "react";
import { makeStyles } from '@material-ui/core';
import { StopComponent } from './TimeLine';
import moment from 'moment';
import { memo } from 'react';

const useStyles = makeStyles((theme) => ({
    stop: {
        color: "#899198",
        ...theme.typography.header6,
    },
}));

const day = moment();

const Stop: StopComponent = memo(({ ts }) => {
    const styles = useStyles();
    day.hour(0).minute(0).add(ts, 'm');
    const str = day.format('HH:mm');
    return <span className={styles.stop}>{str}</span>;
});

export default Stop;
