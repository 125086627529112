import React from "react";

import InputBase from "@material-ui/core/InputBase";

import useStyles from "./css";
import { InputProps } from "./props";

const Input = (props: InputProps) => {
  
  const {
    id,
    value,
    name,
    placeholder,
    isValid,
    width,
    inputClass,
    containerClass,
    onChange,
    onKeyPress,
    disabled,
    readOnly,
    onClick,
    onBlur,
    onKeyDown,
    onFocus,
    maxLength,
    autoFocus,
    showCounter,
    inputType,
    min,
    max,
    step
  } = props;

  const classes = useStyles(width, showCounter)();

  const isError = (): boolean => {
    if (!isValid) {
      return false;
    }

    if (isValid() === undefined) {
      return false;
    }

    return !isValid();
  };

  const handleOnChange = (event: any) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleOnKeyPress = (event: any) => {
    if (onKeyPress) {
      onKeyPress(event);
    }
  };
  
  function handleFocus(event: any) {
    if (onFocus)
      onFocus(event)
  }
  
  function handleOnKeyDown(event: any) {
    if (onKeyDown) 
      onKeyDown(event)
  }
  
  const handleOnClick = (event: any) => {
    if (onClick) {
      onClick(event);
    }
  };
  
  const handleOnBlur = (event: any) => {
    if (onBlur) {
      onBlur(event);
    }
  };
  
  return (
    <span className={`${containerClass} ${classes.container}`}>
      <InputBase
        id={id}
        className={`${inputClass} ${classes.input} ${
          isError() ? classes.inputError : ""
        }`}
        classes={{
          focused: classes.inputFocused,
          disabled: classes.inputDisabled,
          input: classes.inputText,
        }}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={handleOnChange}
        onKeyPress={handleOnKeyPress}
        onKeyDown={handleOnKeyDown}
        onFocus={handleFocus}
        disabled={disabled}
        readOnly={readOnly}
        onClick={handleOnClick}
        onBlur={handleOnBlur}
        autoFocus={autoFocus}
        inputProps={{
          maxLength: maxLength,
          min: min,
          max: max,
          step: step
        }}
        type={inputType ?? "text"}
      />
      {showCounter && <div className={classes.counter}>{`${value?.length}/${maxLength}`}</div>}
    </span>
  );
};

export default Input;