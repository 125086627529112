import { IPasswordStrengthInfo } from "./props";
import { useTranslation } from "react-i18next";

export default function usePasswordValidation(password : string, classes : any) : IPasswordStrengthInfo {
  
  const { t } = useTranslation("general");
  
  if (password === "") {
    return {
      className: classes.passwordStrengthEmpty,
      hint: t("Minimum 8 characters, you must include at least 1 special character (!, @, #, $,%, & Etc.)"),
      isGood: false
    }
  }

  const oneCapitalLetter = /([A-Z]+)/;
  if (!oneCapitalLetter.test(password)) {
    return {
      className: classes.passwordStrengthBad,
      hint: t("Must contain at least 1 capital letter"),
      isGood: false
    }
  }

  const noWhitespaces = /(\s+)/;
  if (noWhitespaces.test(password)) {
    return {
      className: classes.passwordStrengthBad,
      hint: t("Must not contain whitespaces"),
      isGood: false
    }
  }

  const oneDigit = /(\d+)/;
  if (!oneDigit.test(password)) {
    return {
      className: classes.passwordStrengthBad,
      hint: t("Must contain at least 1 digit"),
      isGood: false
    }
  }

  const oneSpecial = /[\@\$\!\%\*\&\"\#\/\(\)\=\?\-\.\,\_\[\]\*\+]+/; 
  if (!oneSpecial.test(password)) {
    return {
      className: classes.passwordStrengthMediocre,
      hint: t("Must contain at least 1 special character"),
      isGood: false
    }
  }
  
  const eightCharacters = /^\S{8,}$/;
  if (!eightCharacters.test(password)) {
    return {
      className: classes.passwordStrengthMediocre,
      hint: t("Must be at least 8 characters"),
      isGood: false
    }
  }

  return {
    className: classes.passwordStrengthGood,
    hint: t("Password secure"),
    isGood: true
  }
}