import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  label: {
    display: "block",
    color: theme.colors.grey6,
    fontFamily: "Inter, sans-serif",
    fontSize: 12,
    lineHeight: "15px",
    fontWeight: "bold",
    alignSelf: "flex-start"
  },
  required: {
    color: theme.colors.clearMain
  },
  textInput: {
    marginTop: "8px",
    width: "100%",
    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.colors.grey4
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.colors.clearMain
    }
  },
  input: {
    color: theme.colors.grey5,
    fontFamily: "Roboto, sans-serif",
    fontSize: 12,
    lineHeight: "14px",
    backgroundColor: "#F6F6F6",
    borderRadius: "5px",
    "& input": {
      height: 24,
      backgroundColor: "#F6F6F6 !important"
    }
  },
  inputNormalWeight: {
    fontWeight: "normal"
  },
  inputBoldWeight: {
    fontWeight: "bold"
  }
}));

export default useStyles;