import { AppointmentCreatedBy } from "./enums/appointment-created-by";
import { AppointmentStatus } from "./enums/appointment-status";
import { DateTime } from 'luxon';

export class Appointment {
  appointmentId: string;
  startDate: DateTime;
  endDate: DateTime;
  title?: string | undefined;
  status: AppointmentStatus;
  createdBy: AppointmentCreatedBy;
  customerName: string;
  firstServiceId: string | null;
  users: string[];
  userId: string;
  isEditable: boolean;
  establishmentId: string | null;
  establishmentName: string | null;
  services: string[];
  isDraggable: boolean;
  isRecurring: boolean;

  constructor(x?: any) {
    if (typeof x == "object") {
      this.appointmentId = x.appointmentId;
      this.startDate = DateTime.fromISO(x.startDate);
      this.endDate = DateTime.fromISO(x.endDate);
      this.status = x.status;
      this.createdBy = x.createdBy;
      this.customerName = x.customerName;
      this.firstServiceId = x.firstServiceId;
      this.users = x.users;
      this.userId = x.userId;
      this.isEditable = x.isEditable;
      this.establishmentId = x.establishmentId;
      this.establishmentName = x.establishmentName;
      this.services = x.services;
      this.isDraggable = x.isDraggable;
      this.isRecurring = x.isRecurring;
    } else {
      this.appointmentId = "";
      this.status = AppointmentStatus.NotConfirmed;
      this.createdBy = AppointmentCreatedBy.Account;
      this.startDate = DateTime.now();
      this.endDate =  DateTime.now();
      this.customerName = "";
      this.firstServiceId = null;
      this.users = [];
      this.userId = "";
      this.isEditable = true;
      this.establishmentId = null;
      this.establishmentName = null;
      this.services = [];
      this.isDraggable = false;
      this.isRecurring = false;
    }
  }
}
