import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function PatientGeneralDataIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M12 4C7.584 4 4 7.584 4 12C4 16.416 7.584 20 12 20C16.416 20 20 16.416 20 12C20 7.584 16.416 4 12 4ZM14.888 9.072C15.744 9.072 16.432 9.76 16.432 10.616C16.432 11.472 15.744 12.16 14.888 12.16C14.032 12.16 13.344 11.472 13.344 10.616C13.336 9.76 14.032 9.072 14.888 9.072ZM10.088 7.808C11.128 7.808 11.976 8.656 11.976 9.696C11.976 10.736 11.128 11.584 10.088 11.584C9.048 11.584 8.2 10.736 8.2 9.696C8.2 8.648 9.04 7.808 10.088 7.808ZM10.088 15.112V18.112C8.168 17.512 6.648 16.032 5.976 14.144C6.816 13.248 8.912 12.792 10.088 12.792C10.512 12.792 11.048 12.856 11.608 12.968C10.296 13.664 10.088 14.584 10.088 15.112ZM12 18.4C11.784 18.4 11.576 18.392 11.368 18.368V15.112C11.368 13.976 13.72 13.408 14.888 13.408C15.744 13.408 17.224 13.72 17.96 14.328C17.024 16.704 14.712 18.4 12 18.4Z"
                fill={style?.color || Color.clear2}
            />
        </SvgIcon>
    );
}