export class AppointmentBlocker {
  customerName: string | null;
  serviceName: string | null;
  workArea: string | null;
  startData: Date | null;
  appointmentDuration: number | null;

  constructor(x?: any) {
    if (typeof x == "object") {
      this.customerName = x.customerName;
      this.serviceName = x.serviceName;
      this.workArea = x.workArea;
      this.startData = x.startData;
      this.appointmentDuration = x.appointmentDuration;
    } else {
      this.customerName = null;
      this.serviceName = null;
      this.workArea = null;
      this.startData = null;
      this.appointmentDuration = null;
    }
  }
}
