import { Customer } from "./customer";
export class CustomersByIdResponse {
  customer: Customer | null;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.customer = x.customer;
    } else {
      this.customer = null;
    }
  }
}
