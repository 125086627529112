import { DateTime } from 'luxon';

export default class BlockSchedule {
    
    id?: string | null;
    specialistId?: string | null;
    specialist?: string;
    blockedReasonId: string;
    blockedReason?: string;
    isBlockedReasonPredetermined?: boolean;
    allDay: boolean;
    start: DateTime;
    end: DateTime;
    notes?: string | null;
    isEditable?: boolean;
    isBlockedMultiple: boolean;
    blockerCount: number;
    blockers: BlockSchedule[];
    establishmentId: string | null;
    establishmentName: string | null;

    constructor(x?: BlockSchedule | any) {
        if (x != null && typeof x === "object") {
            this.id = x.id;
            this.specialistId = x.userId !== null ? x.userId : "AllSpecialists";
            this.specialist = x.user;
            this.blockedReasonId = x.blockedReasonId;
            this.blockedReason = x.blockedReason;
            this.isBlockedReasonPredetermined = x.isBlockedReasonPredetermined;
            this.allDay = x.allDay;
            this.start = DateTime.fromISO(x.start);
            this.end = DateTime.fromISO(x.end);
            this.notes = x.notes;
            this.isBlockedMultiple = x.isBlockedMultiple;
            this.blockerCount = x.blockerCount;
            this.blockers = x.blockers?.map((b: any) => new BlockSchedule(b)) ?? [];
            this.establishmentId = x.establishmentId;
            this.establishmentName = x.establishmentName;
        }
        else {
            this.id = null;
            this.specialistId = null;
            this.blockedReasonId = "";
            this.blockedReason = "";
            this.allDay = false;
            this.start = DateTime.now();
            this.end = DateTime.now();
            this.notes = null;
            this.isBlockedMultiple = false;
            this.blockerCount = 0;
            this.blockers = [];
            this.establishmentId = null;
            this.establishmentName = null;
        }
    }
}

//Form Props
export interface FormProps {
    users: any;
    item: BlockSchedule;
    validator: Validator;
    onChange?: (entity: BlockSchedule) => void; //isValid: boolean,
    onModify?: () => void;
}

//Form Valid
export interface Validator {
    specialistId: boolean;
    reasonId: boolean;
    date: boolean;
    start: boolean;
    end: boolean;
}
