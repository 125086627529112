import React from "react";
import { useAppSelector } from '../../../../../redux/hooks';
import { selectAccountSettings } from "../../../../../redux/store";
import { useTranslation } from "react-i18next";

import useStyles from "./css";
import { Divider, Typography } from "@material-ui/core";
import { UserModalPermissionsProps } from "./props";
import SwitchWithLabelAndTooltip from "../../../../common/SwitchWithLabelAndTooltip";
import { AdminIcon, UserIcon, BellIcon, MoneyIcon, MedicalBookIcon } from "../../../../../assets/icons";
import useCustomerTerminology from "../../../../../hooks/useCustomerTerminology";


export default function UserModalPermissionsInfo(props: UserModalPermissionsProps) {
  const { t } = useTranslation("settings");
  const classes = useStyles();

  const { userPermissionsInfo, setUserPermissionsInfo } = props;

  const accountSettings = useAppSelector(selectAccountSettings);

  const setHasCustomerInfoVisible = (value: boolean) => {
    setUserPermissionsInfo({ ...userPermissionsInfo, hasCustomerInfoVisible: value });
  }
  const setHasAdminAccess = (value: boolean) => {
    setUserPermissionsInfo({ 
        ...userPermissionsInfo,
        hasAdminAccess: value,
        ...(value && { showPrices: true })
    });
  }

  const setHasMassiveSendings = (value: boolean) => {
    setUserPermissionsInfo({ ...userPermissionsInfo, hasMassiveSendings: value });
  }
  const setHasAccessToReports = (value: boolean) => {
    setUserPermissionsInfo({ ...userPermissionsInfo, hasAccessToReports: value });
  }
  const setHasAccessToScheduleAllSpecialists = (value: boolean) => {
    setUserPermissionsInfo({ ...userPermissionsInfo, hasAccessToScheduleAllSpecialists: value });
  }
  const setShowPrices = (value: boolean) => {
      setUserPermissionsInfo({
          ...userPermissionsInfo,
          showPrices: value,
          ...(!value && {
              canRegisterPayments: false,
              canOpenCloseCashRegister: false,
              canMakeCashRegisterMovements: false
          })
      });
  }

  const setNotifyOnCustomerRequiresAttention = (value: boolean) => {
    setUserPermissionsInfo({
      ...userPermissionsInfo,
      //TODO: remove or uncomment after clarifiaction (CR-2163)
      //hasAccessToWhatsAppConversationsAndNotifications: value === true ? true : userPermissionsInfo.hasAccessToWhatsAppConversationsAndNotifications,
      notifyOnCustomerRequiresAttention: value
    });
  }

  const setNotifyOnCashRegisterClosing = (value: boolean) => {
    setUserPermissionsInfo({ ...userPermissionsInfo, notifyOnCashRegisterClosing: value });
  }

  const setShowAllAppointments = (value: boolean) => {
    setUserPermissionsInfo({ ...userPermissionsInfo, showAllAppointments: value });
  }

  const setCanRegisterPayments = (value: boolean) => {
    setUserPermissionsInfo({ ...userPermissionsInfo, canRegisterPayments: value });
  }

  const setCanOpenCloseCashRegister = (value: boolean) => {
    setUserPermissionsInfo({ ...userPermissionsInfo, canOpenCloseCashRegister: value });
  }

  const setCanMakeCashRegisterMovements = (value: boolean) => {
    setUserPermissionsInfo({ ...userPermissionsInfo, canMakeCashRegisterMovements: value });
  }

  const setCanSeeClinicalFile = (value: boolean) => {
    setUserPermissionsInfo({ ...userPermissionsInfo, canSeeClinicalFile: value });
  }

  const setCanInteractWithEvolutionNotes = (value: boolean) => {
    setUserPermissionsInfo({ ...userPermissionsInfo, canInteractWithEvolutionNotes: value });
  }

  const setCanInteractWithPatientFiles = (value: boolean) => {
    setUserPermissionsInfo({ ...userPermissionsInfo, canInteractWithPatientFiles: value });
  }

  const setHasAccessToWhatsAppConversationsAndNotifications = (value: boolean) => {
    setUserPermissionsInfo({
      ...userPermissionsInfo,
      //TODO: remove or uncomment after clarifiaction (CR-2163)
      //notifyOnCustomerRequiresAttention: value === false ? false : userPermissionsInfo.notifyOnCustomerRequiresAttention,
      hasAccessToWhatsAppConversationsAndNotifications: value
    });
  }

  return (<>
    <div className={classes.sectionRow}>
      <AdminIcon />
      <Typography className={classes.sectionLabel}>
        {t("Administrator")}
      </Typography>
    </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.hasAdminAccess}
        setValue={setHasAdminAccess}
        label={t("Administrator access")}
        tooltip={t("This user can access or manage any settings.")}
        inactiveTooltip={t("This user cannot access or manage any settings.")}
      />
    </div>

    <Divider className={classes.divider} />

    <div className={classes.sectionRow}>
      <UserIcon />
      <Typography className={classes.sectionLabel}>
        {t("Activity")}
      </Typography>
    </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.hasCustomerInfoVisible}
        setValue={setHasCustomerInfoVisible}
        label={useCustomerTerminology(t("Show customer information"), "customer")}
        inactiveLabel={useCustomerTerminology(t("Show customer information"), "customer")}
        tooltip={useCustomerTerminology(t("This user can view customer information."), "customer")}
        inactiveTooltip={t("This user cannot view customer information.")}
      />
    </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.hasMassiveSendings}
        setValue={setHasMassiveSendings}
        label={t("Send massive sendings")}
        tooltip={t("This user can create, edit and delete massive sendings.")}
        inactiveTooltip={t("This user cannot create, edit and delete massive sendings.")}
      />
    </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.hasAccessToWhatsAppConversationsAndNotifications}
        setValue={setHasAccessToWhatsAppConversationsAndNotifications}
        label={t("Show Conversations")}
        tooltip={t("This user can view and send WhatsApp messages.")}
        inactiveTooltip={t("This user cannot view and send WhatsApp messages.")}
      />
    </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.hasAccessToReports}
        setValue={setHasAccessToReports}
        label={t("Show reports")}
        tooltip={t("This user can view, download, and create reports.")}
        inactiveTooltip={t("This user cannot view, download, and create reports.")}
      />
    </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.hasAccessToScheduleAllSpecialists}
        setValue={setHasAccessToScheduleAllSpecialists}
        label={t("Show the schedule of all specialists")}
        tooltip={t("This user can view the schedule of all specialists.")}
        inactiveTooltip={t("This user can only view their schedule.")}
      />
      </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.showPrices}
        setValue={setShowPrices}
        label={t("Show price")}
        tooltip={t("This user can see the prices of the appointment services and create services.")}
        inactiveTooltip={t("This user cannot see the prices of the appointment services and create services.")}
        disabled={userPermissionsInfo.hasAdminAccess}
      />
    </div>

    <Divider className={classes.divider} />

    <div className={classes.sectionRow}>
      <BellIcon />
      <Typography className={classes.sectionLabel}>
        {t("Notifications")}
      </Typography>
    </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.notifyOnCustomerRequiresAttention}
        setValue={setNotifyOnCustomerRequiresAttention}
        label={t("WhatsApp notifications")}
        tooltip={t("This user will be notified when a customer requires personalized attention or an estimation.")}
        inactiveTooltip={t("This user will not be notified when a customer requires personalized attention or an estimation.")}
      />
    </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.notifyOnCashRegisterClosing}
        setValue={setNotifyOnCashRegisterClosing}
        label={t("Send WhatsApp at cash closing")}
        tooltip={t("This user will be notified when the cash register is closed.")}
        inactiveTooltip={t("This user will not be notified when the cash closing is performed.")}
      />
    </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.showAllAppointments}
        setValue={setShowAllAppointments}
        label={t("Show all appointments in other calendars")}
        tooltip={t("This user can see all the appointments of all the specialists in his third calendar.")}
        inactiveTooltip={t("This user can only see his appointments on his third calendar.")}
      />
    </div>

    <Divider className={classes.divider} />

    <div className={classes.sectionRow}>
      <MoneyIcon viewBox={"0 0 14 16"} style={{ fontSize: '16px' }} />
      <Typography className={classes.sectionLabel}>
        {t("Payments")}
      </Typography>
    </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.canRegisterPayments}
        setValue={setCanRegisterPayments}
        label={t("Register payment")}
        tooltip={t("This user can perform payment registration and edition.")}
        inactiveTooltip={t("This user cannot perform payment registration and edition.")}
        disabled={!userPermissionsInfo.showPrices}
      />
    </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.canOpenCloseCashRegister}
        setValue={setCanOpenCloseCashRegister}
        label={t("Opening and closing of cash register")}
        tooltip={t("This user can open and close the cash register.")}
        inactiveTooltip={t("This user cannot open and close cash register.")}
        disabled={!userPermissionsInfo.showPrices}
      />
    </div>
    <div className={classes.switchBox}>
      <SwitchWithLabelAndTooltip
        value={userPermissionsInfo.canMakeCashRegisterMovements}
        setValue={setCanMakeCashRegisterMovements}
        label={t("New movements")}
        tooltip={t("This user can perform cash movements in the cash register.")}
        inactiveTooltip={t("This user cannot perform cash movements in the cash register.")}
        disabled={!userPermissionsInfo.showPrices}
      />
    </div>
    {accountSettings.isClinicMode
      ? <>
        <Divider className={classes.divider} />

        <div className={classes.sectionRow}>
          <MedicalBookIcon />
          <Typography className={classes.sectionLabel}>
            {t("USER_PERMISSONS Clinic history")}
          </Typography>
        </div>
        <div className={classes.switchBox}>
          <SwitchWithLabelAndTooltip
            value={userPermissionsInfo.canSeeClinicalFile}
            setValue={setCanSeeClinicalFile}
            label={t("Clinical file")}
            tooltip={t("This user can see the patient clinical file.")}
            inactiveTooltip={t("This user cannot see the patient clinical file.")}
          />
        </div>
        <div className={classes.switchBox}>
          <SwitchWithLabelAndTooltip
            value={userPermissionsInfo.canInteractWithEvolutionNotes}
            setValue={setCanInteractWithEvolutionNotes}
            label={t("Evolution notes")}
            tooltip={t("This user can view or create the patient evolution notes.")}
            inactiveTooltip={t("This user cannot view or create the patient evolution notes.")}
          />
        </div>
        <div className={classes.switchBox}>
          <SwitchWithLabelAndTooltip
            value={userPermissionsInfo.canInteractWithPatientFiles}
            setValue={setCanInteractWithPatientFiles}
            label={t("Patient files")}
            tooltip={t("This user can view or upload patient files.")}
            inactiveTooltip={t("This user cannot view or upload patient files.")}
          />
        </div>
      </>
      : <></>}

  </>);
}