import { CashRegisterMovement } from "./cash-register-movement";

export class CashRegisterMovementHistory {
  movements: CashRegisterMovement[];

  constructor(x?: any) {
    if (typeof x === "object") {
      this.movements = x.movements;
    } else {
      this.movements = [];
    }
  }
}