import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  popover: {
    borderRadius: "8px",
    width: 415,
    height: 541,
    
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
  },
  root: {
    padding: "0px 20px 20px 20px",
    overflow: "hidden",
  },
  header: {
    borderRadius: "5px 5px 0px 0px",
    width: "100%",
    height: "4px",
  },
  headerActions: {
    height: 60,
    paddingTop: 25,
  },
  headerText: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: "#899198",
    textTransform: "uppercase",
    marginLeft: 12,
    width: 103,
  },
  headerSubText: {
    color: theme.colors.grey4,
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontSize: 10,
    position: "relative",
    width: 150,
    paddingLeft: 26,
    top: -15,
    fontWeight: 400,
  },
  headerButtons: {
    cursor: "pointer",
    color: "#919CA5",
    margin: "0px auto"
  },
  headerIcon: {
    marginLeft: 10,
    display: "flex",
    width: 20,
    height: 20,
  },
  modalActionsAppt: {
    position: "absolute",
    top: 25,
    right: 0,
    background: "white",
    width: 202,
    textAlign: "center",
    border: "1px solid #DBDCDD",
    boxShadow: "0px 4px 4px rgb(166 166 166 / 25%)",
    borderRadius: 5,
    backgroundColor: "#FFFFFF",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    color: theme.colors.grey5,
  },
  modalActionFont: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    color: theme.colors.grey5,
    padding: 5,
    cursor: "pointer",
    position: "relative",
    background: "white",
    width: 200,
    textAlign: "left",
    paddingLeft: 15,
    height: 35
  },
  modalActionFirst: {
    borderTopLeftRadius: 5,
    borderTopRightRadius: 5,
    borderBottom: "1px solid #DBDCDD",
    "&:hover": {
      backgroundColor: "#E1E9FE"
    }
  },
  modalActionLast: {
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
    "&:hover": {
      backgroundColor: "#E1E9FE"
    }
  },
  arrived: {
    background: "#36CE91",
  },
  notArrived: {
    background: "#F15857",
  },
  confirmed: {
    background: "#0BCBE0",
  },
  notConfirmed: {
    background: "#919CA5",
  },
  content: {
    width: "100%",
    paddingTop: 16,
  },
  bodyContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  row: {
    display: "flex",
    width: "100%",
  },
  subsection: {
    marginTop: 8
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid red",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: "24px",
    width: 464,
    height: 288,
    "&:focus:not(:focus-visible)": {
      outline: "none",
    },
    "&:focus-visible": {
      outline: "none",
      border: "1px solid red",
    },
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
  },
  modalHeaderContent: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  modalClose: {
    display: "flex",
    justifyContent: "flex-end",
    width: "80%",
  },
  closeButton: {
    cursor: "pointer",
    color: "#919CA5",
  },
  modalTitle: {
    width: "100%",
  },
  modalTitleText: {
    fontSize: "18px",
    lineHeight: "22px",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "#26292B",
  },
  modalContent: {},
  modalCircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "1px solid #F15857",
    width: "50px",
    height: "50px",
  },
  modalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  modalTitleSpace: {
    margin: "24px 0px 8px 0px",
  },
  modalContentText: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#4A4D51",
  },
  modalDateText: {
    ...theme.typography.txtBody2,
    paddingTop: 7,
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 32,
  },
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  goBack: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  deleteAppointment: {
    background: "#F15857",
    color: "#F6F6F6",
    "&:hover": {
      background: "#F15857",
    },
  },
  separator: {
    height: 0,
    width: 317,
    borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    margin: "16px 0px 16px 0px",
  },
  caption: {
    ...theme.typography.caption2,
    color: "#6A6E72",
  },
  recurringAppointmentInfo: {
    display: "flex",
    flexDirection: "column",
    gap: 8
  },
  txtBody1: {
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: theme.colors.grey6,
  },
  txtBody2: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey6,
  },
  paymentRow: {
    justifyContent: "space-between",
    alignItems: "center"
  },
  paymentInfo: {
    display: "flex"
  },
  paymentAmount: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey5,
  },
  recurringAppointmentPaymentText: {
    ...theme.typography.caption1,
    color: theme.colors.grey4,
  },
  paymentButton: {
    justifySelf: "flex-end",
    
    width: 131,
    height: 32,
    marginRight: 30,
    
    backgroundColor: "transparent",
    borderRadius: 5,
    
    ...theme.typography.buttons,
    fontWeight: 700,
    color: theme.colors.clearMain,
    
    "&:hover": {
      backgroundColor: theme.colors.clearLilac
    }
  },
}));

export default useStyles;
