import React, { useState } from "react";

import IconButton from "@material-ui/core/IconButton";

import useStyles from "./css";
import { CashRegisterIconProps } from "./props";
import { MoneyIcon } from "../../../../assets/icons";


export default function CashRegisterIcon(props: CashRegisterIconProps) {

  const { isActive, onClick } = props;
  
  const [isHovered, setIsHovered] = useState<boolean>(false);
  
  const classes = useStyles();
  
  return (
    <div>
      <IconButton
        className={classes.iconButton}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <MoneyIcon
          viewBox={"0 0 10 16"}
          style={{
            width: 16,
            height: 16,
            marginTop: -2,
            fontSize: '16px',
          }}
          color={isHovered ? "#FFFFFF" : isActive ? "#36CE91" : "#919CA5"}
        />
      </IconButton>
    </div>
  );
}