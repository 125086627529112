import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    width: "100%",
    justifyContent: "flex-end",
  },
  header: {
    display: "flex",
    width: "100%"
  },
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  newUser: {
    background: "#7175FA",
    color: "#F6F6F6",
    "&:hover": {
      background: "#6462F3",
    }
  },
  noteHeader: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    gap: 20,
    width: "100%",
    justifyContent: "space-between",
  },
  tabTitle: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: Color.gray5
  },

}));

export default useStyles;