import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 20,
    paddingLeft: 20,
    paddingRight: 11
  },
  mainContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "end",
    
    height: 90,
    
    paddingTop: 0,
    paddingLeft: 11,
    paddingRight: 11,
    paddingBottom: 13,
    
    border: "1px solid #C9CDD3",
    borderRadius: 5,
    
    backgroundColor: theme.colors.white,
  },
  inputsBlock: {
    display: "flex",
    flexDirection: "row",
    gap: 8,
  },
  dateLabel: {
    ...theme.typography.buttons,
    fontFamily: "Inter, sans-serif",
    fontWeight: "bold",
    display: "inline-flex",
    color: Color.gray4,
    marginBottom: 8
  },
  controlWithLabel: {},
  dateInputText: {
    ...theme.typography.txtBody2,
    fontWeight: 700,
    color: theme.colors.grey5,
    opacity: 0.8,
  },
  reportTypeSelect: {
    alignSelf: "flex-end",
  },
  downloadReportButtonBlock: {},
  downloadReportButton: {
    width: 202,
    height: 40,
    
    backgroundColor: theme.colors.clearMain,
    borderRadius: 5,
    
    ...theme.typography.buttons,
    color: theme.colors.grey1,
    fontWeight: 700,
    
    "&:hover": {
      backgroundColor: theme.colors.clearMain,
      color: theme.colors.grey1,
    },
    "&:disabled": {
      backgroundColor: theme.colors.clearMain,
      opacity: 0.3,
      color: theme.colors.grey1,
    }
  },
  downloadReportButtonLabel: {
    display: "flex",
    gap: 10,
  },
}));

export default useStyles;
