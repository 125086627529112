import { SectionType } from "./enums/section-type";
import { SectionFieldModel } from "./section-field-model";

export class SectionModel {
  id: string | null;
  name: string | null;
  order: number | null;
  type: SectionType | null;
  fields: SectionFieldModel[];
  isEditable: boolean | null;
  isRemovable: boolean | null;
  isDeleted: boolean;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.name = x.name;
      this.order = x.order;
      this.type = x.type;
      this.fields = x.fields;
      this.isEditable = x.isEditable;
      this.isRemovable = x.isRemovable;
      this.isDeleted = x.isDeleted;
    } else {
      this.id = null;
      this.name = null;
      this.order = null;
      this.type = null;
      this.fields = [];
      this.isEditable = null;
      this.isRemovable = null;
      this.isDeleted = false;
    }
  }
}