import { PaymentMethodType } from "./enums/payment-method-type";

export class AppointmentPaymentPartEntity {
  paymentMethod: PaymentMethodType | null;
  amountPaid: number;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.paymentMethod = x.paymentMethod;
      this.amountPaid = x.amountPaid;
    } else {
      this.paymentMethod = null;
      this.amountPaid = 0;
    }
  }
}