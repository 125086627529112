import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAccountSettings, selectCommonHubConnection } from "../../../redux/store";
import useCurrencyValueTemplate from "../../../hooks/useCurrencyValueTemplate";
import { useTranslation } from "react-i18next";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";

import { PatientAppointmentHistoryTabProps } from "./props";
import useStyles from "./css";

import { CustomerService } from "../../../api/customer-service";
import CustomerHistoryEntity, { CustomerApptsServicesHistory } from "../../../models/customer-history";
import { AppointmentStatus } from "../../../models/enums/appointment-status";
import { AppointmentPaymentStatus } from "../../../models/enums/appointment-payment-status";

import { Accordion, AccordionDetails, AccordionSummary, Drawer, Grid, Typography } from '@material-ui/core';
import CustomerHistoryDetails from "../CustomerHistoryDetails";
import { ArrowDownIcon, CheckIcon, CrossIcon, MenuIconLogoClear, NoteIcon, PersonIcon } from "../../../assets/icons";
import { Color } from "../../../constants/colors";
import { navbarActions } from "../../../redux/navbar-slice";


export default function PatientAppointmentHistoryTab(props: PatientAppointmentHistoryTabProps) {

    const { customerId, gotoEvolutionNotesTab } = props;
    const { t } = useTranslation(["general"]);
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const [appointmentsEntity, setAppointmentsEntity] = useState<CustomerHistoryEntity[]>([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [service, setService] = useState<CustomerApptsServicesHistory>();
    const [serviceStatus, setServiceStatus] = useState<any>();
    const [servicePaymentStatus, setServicePaymentStatus] = useState<any>();
    const [appointmentId, setAppointmentId] = useState<any>();
    const [notesSaved, setNotesSaved] = useState<boolean>(true);

    const accountSettings = useAppSelector(selectAccountSettings);
    const hubConnection = useAppSelector(selectCommonHubConnection);
    const getTemplatedCurrencyValue = useCurrencyValueTemplate(accountSettings.currencyTemplate);

    const localeApp = useAppSelector(state => state.applicationInterface.locale);
    const customerDidNotArriveTerminology = useCustomerTerminology(t("Customer did not arrive"), "Customer");
    const customerArrivedTerminology = useCustomerTerminology(t("Customer arrived"), "Customer");

    useEffect(() => {

        getData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getData = () => {
        (async () => {
            dispatch(navbarActions.setShowLoader(true));
            const values = await CustomerService.getCustomerHistory(customerId, localeApp);
            dispatch(navbarActions.setShowLoader(false));
            if (values) {
                setAppointmentsEntity(values.entity!);
            }
        })();
    };
    
    // unsubscribe
    hubConnection?.off("changeAppointmentsListPaymentStatus");
    // subscribe
    hubConnection?.on("changeAppointmentsListPaymentStatus", (appointmentIds, status) => {updateAppointmentsPaymentStatus(appointmentIds, status)});
    const toggleDrawer = (s: CustomerApptsServicesHistory, apptId: string, divStatus: any, divPaymentStatus: any) => {
        if (s.notes && s.notes.trim() !== "") {
            setDrawerOpen(!drawerOpen);
            if (s)
                setService(s);
            if (divStatus)
                setServiceStatus(divStatus);
            if (divPaymentStatus)
                setServicePaymentStatus(divPaymentStatus)
            if (apptId)
                setAppointmentId(apptId);
        }
        else {
            gotoEvolutionNotesTab(apptId, s.id);
        }
    };
    
    function updateAppointmentsPaymentStatus(appointmentIds: string[], paymentStatus: AppointmentPaymentStatus) {
        setAppointmentsEntity(prevAppointmentsEntity => {
            return prevAppointmentsEntity.map(monthData => {
                const updatedAppointments = monthData.appointments.map(appointment => {
                    if (appointmentIds.includes(appointment.id)) {
                        return { ...appointment, paymentStatus: paymentStatus, };
                    }
                    return appointment;
                });
                return { ...monthData, appointments: updatedAppointments, };
            });
        });
    }

    const refreshClose = (s: CustomerApptsServicesHistory) => {
        getData();
        if (s)
            setService(s);
    };

    const changedNotesWithoutSave = (saved: boolean) => {
        setNotesSaved(saved);
    };

    const getServiceDate = (serviceDateTime: Date) => {
        const newDateObj = new Date(serviceDateTime);
        const day = newDateObj.toLocaleString(localeApp, { day: "numeric", });
        const month = newDateObj.toLocaleString(localeApp, { month: "long", });
        const year = newDateObj.toLocaleString(localeApp, { year: "numeric", });

        return localeApp === "es"
            ? `${day} de ${month[0].toUpperCase() + month.slice(1)} ${year}`
            : `${month.charAt(0).toUpperCase() + month.slice(1)} ${day} ${year}`;
    };

    const getServiceTime = (serviceStartTime: Date, serviceEndTime: Date) => {
        const newStartDateObj = new Date(serviceStartTime);
        const newEndDateObj = new Date(serviceEndTime);
        let timeStartString = newStartDateObj.toLocaleTimeString("en-US", { timeStyle: "short" })
        let timeEndString = newEndDateObj.toLocaleTimeString("en-US", { timeStyle: "short" })
        timeStartString = timeStartString.slice(0, timeStartString.length - 3) +
            timeStartString.slice(timeStartString.length - 2).toLowerCase();
        timeEndString = timeEndString.slice(0, timeEndString.length - 3) +
            timeEndString.slice(timeEndString.length - 2).toLowerCase();
        return `${timeStartString} - ${timeEndString}`
    };

    const DivStatus = (status: AppointmentStatus | number) => {
        if (status == AppointmentStatus.Arrived) {
            return (<div className={`${classes.divStatus} ${classes.statusArrived}`}>
                <CheckIcon style={{ color: "#36CE91", width: 14, height: 10, marginRight: 3 }} />
                {customerArrivedTerminology}
            </div>)
        }
        else if (status == AppointmentStatus.Confirmed) {
            return (<div className={`${classes.divStatus} ${classes.statusConfirmed}`}>
                <MenuIconLogoClear viewBox="0 0 16 16" style={{ color: "#0BCBE0", width: 16, height: 10, marginRight: 5, marginLeft: -4 }} />
                {t("Confirmed")}
            </div>)
        }
        else if (status == AppointmentStatus.NotArrived) {
            return (<span className={`${classes.divStatus} ${classes.statusNotArrived}`}>
                <PersonIcon style={{ color: "#F15857", width: 10, height: 10, marginRight: 5 }} />
                {customerDidNotArriveTerminology}
            </span>);
        }
        else if (status == AppointmentStatus.NotConfirmed) {
            return (<div className={`${classes.divStatus} ${classes.statusNotConfirmed}`}>
                <CrossIcon viewBox="0 0 16 16" style={{ color: "#F15857", width: 10, height: 10, marginRight: 5 }} />
                {t("Not Confirmed")}
            </div>);
        } else {
            return <div></div>
        }
    };

    const DivPaymentStatus = (status: AppointmentPaymentStatus | number) => {
        if (status === AppointmentPaymentStatus.Paid) {
            return (<div className={`${classes.divStatus} ${classes.statusPaid}`}>
                {t("Paid")}
            </div>)
        }
        else if (status === AppointmentPaymentStatus.Unpaid || status === AppointmentPaymentStatus.PaidPartially) {
            return (<span className={`${classes.divStatus} ${classes.statusUnpaid}`}>
                {t("Unpaid")}
            </span>);
        } else {
            return <div></div>
        }
    };

    const DivNotes = (service: CustomerApptsServicesHistory | null, apptId: string, divStatus: any, divPaymentStatus: any) => {
        if (service?.notes || service?.hasEvolutionNotes) {
            return (<div className={`${classes.divNotes} ${classes.divNotesExists}`} onClick={() => toggleDrawer(service, apptId, divStatus, divPaymentStatus)} >
                <NoteIcon style={{ color: "#6462F3", width: 14, height: 10, marginLeft: "-2px", marginTop: "-16px" }} color={"#6462F3"} />
            </div>)
        } else {
            return (<div className={`${classes.divNotes} ${classes.divNotesNone}`} onClick={() => toggleDrawer(service!, apptId, divStatus, divPaymentStatus)}>
                <NoteIcon style={{ color: "#919CA5", width: 14, height: 10, marginLeft: "-2px", marginTop: "-16px" }} />
            </div>)
        }
    };

    return <div className={classes.root}>
        <div className={classes.customerHistoryPanelContent}>
            {
                appointmentsEntity.map((history, indexHistory) =>
                (<Accordion key={indexHistory} defaultExpanded={true} className={classes.accordion}  >

                    <AccordionSummary
                        expandIcon={<ArrowDownIcon style={{ height: 15, width: 15, color: Color.clear1 }} />}>
                        <div className={classes.monthYear} >
                            {history.monthYear[0].toLocaleUpperCase(localeApp) + history.monthYear.slice(1)}
                            <span className={classes.appointmentCountInMonth}>
                                {" (" + history.appointments.length + ")"}
                            </span>
                        </div>
                    </AccordionSummary>

                    <AccordionDetails className={classes.accordionDetails} >

                        <div className={classes.appointmentsGrid}>
                            {
                                history.appointments.map((appointment, indexAppointments) =>
                                (
                                      <div key={appointment.id} className={classes.appointmentsBox}>
                                          
                                          <Grid container spacing={2} className={classes.appointmentsGrid}>
                                              <Grid item xs={3}>
                                                  <Typography className={classes.headerAppointments}>
                                                      {t("Service & specialist")}
                                                  </Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                  <Typography className={classes.headerAppointments}>
                                                      {t("Date & Time")}
                                                  </Typography>
                                              </Grid>
                                              <Grid item xs={2}>
                                                  <Typography className={classes.headerAppointments}>
                                                      {t("Price")}
                                                  </Typography>
                                              </Grid>
                                              <Grid item xs={3}>
                                                  <Typography className={classes.headerAppointments}>
                                                      {t("Status")}
                                                  </Typography>
                                              </Grid>
                                              <Grid item xs={1}>
                                                  <Typography className={classes.headerAppointments}>
                                                      {t("NotesDetails")}
                                                  </Typography>
                                              </Grid>
                                          
                                          </Grid>
                                          
                                          {
                                              appointment.services.map((service, indexService) =>
                                                (<div key={indexService}>
                                                      <Grid
                                                        key={indexService}
                                                        container
                                                        spacing={2}
                                                        className={`${classes.appointmentsGrid} ${appointment.status === AppointmentStatus.NotArrived ? classes.apptDisabledGrid : ""}`}
                                                      >
                                                          <Grid item xs={3}>
                                                              <Typography className={classes.serviceName}>
                                                                  {service.name}
                                                              </Typography>
                                                              <Typography className={classes.specialistName}>
                                                                  {service.specialist}
                                                              </Typography>
                                                          </Grid>
                                                          <Grid item xs={3}>
                                                              <Typography className={classes.serviceDate}>
                                                                  {getServiceDate(service.start)}
                                                                  <br/>
                                                                  {getServiceTime(service.start, service.end)}
                                                              </Typography>
                                                          </Grid>
                                                          <Grid item xs={2}>
                                                              <Typography className={classes.servicePrice}>
                                                                  {service.price ? getTemplatedCurrencyValue(service.price) : "--"}
                                                              </Typography>
                                                          </Grid>
                                                          <Grid item xs={3}>
                                                              {DivStatus(appointment.status)}
                                                              {DivPaymentStatus(appointment.paymentStatus)}
                                                          </Grid>
                                                          <Grid item xs={1}>
                                                              {DivNotes(service, appointment.id, DivStatus(appointment.status), DivPaymentStatus(appointment.paymentStatus))}
                                                          </Grid>
                                                      </Grid>
                                                  </div>
                                                ))
                                          }
                                          {appointment?.recurringAppointmentOrder &&
                                            <div className={classes.recurringAppointment}>
                                                {`${t("Recurring appointment")} (${appointment?.recurringAppointmentOrder}/${appointment?.recurringAppointmentCount})`}                                            </div>
                                          }
                                          {indexAppointments !== history.appointments.length - 1 &&
                                            <div className={classes.appointmentsGridLine}/>
                                          }
                                      </div>
                                ))
                            }
                        </div>
                    </AccordionDetails>
                </Accordion>))
            }
            {service && <>
                <Drawer
                    open={drawerOpen}
                    anchor="right"
                    classes={{ paper: classes.drawerHistoryDetails }}
                    onClose={() => { if (notesSaved) setDrawerOpen(false) }}
                >

                    <CustomerHistoryDetails
                        serviceHistory={service}
                        appointmentId={appointmentId}
                        serviceStatus={serviceStatus}
                        servicePaymentStatus={servicePaymentStatus}
                        onChangedWithoutSave={changedNotesWithoutSave}
                        onClose={() => setDrawerOpen(false)}
                        onRefresh={refreshClose} />

                </Drawer>
            </>}
        </div>
    </div>;
};