import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    "&:focus": {
      borderColor: theme.colors.clearMain,
    },
    "&.MuiSelect-select.MuiSelect-select": {
      padding: "6px 6px 3px 3px"
    }
  },
  menuItem: {
    display: "flex",
    justifyContent: "flex-start",
    padding: 12,
    height: 32
  },
  menuItemSelected: {
    fontWeight: "bold",
    color: theme.colors.clearMain
  },
  })
);

export default useStyles;