import React from "react";

import { useTranslation } from "react-i18next";
import { SectionNoteProps } from "./props";
import useStyles from "./css";


export default function SectionNote(props: SectionNoteProps) {
    const { t } = useTranslation(["general"]);
    const { sectionData } = props;
    const classes = useStyles();

    function capitalizeSectionTitle(str: string) {
        if (!str) return "";
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    const title = capitalizeSectionTitle(t(sectionData));
    const body = t(sectionData);
    const article = t(`${sectionData}_Article`);

    return (
        <>
            <div className={classes.sectionNotes}>
                <div className={classes.sectionNotesTitle}>
                    {t("SectionNoteTitleTemplate", { title })}
                </div>
                <div className={classes.sectionNotesDescription}>
                    {t("SectionNoteBodyTemplate", { article, body })}
                </div>
            </div>
        </>
    );
};
