import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  submitButton: {
    width: "370px",
    height: "39px",
    borderRadius: "51px",
    marginTop: "24px",

    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    display: "flex",
    alignItems: "center",
    color: "#FFFFFF"
  },
  formHeader: {
    width: "370px",
    height: "34px",
    
    display: "flex",
    alignItems: "center",
    justifyContent: "center",

    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#6A6E72",
    marginBottom: "16px",
    marginTop: 7
  },
  passwordStrengthMeter: {
    display: "block",
    width: "369px",
    height: "5px",
    backgroundColor: "#F6F6F6",
    
    marginTop: "8px",
    borderRadius: "10px"
  },
  passwordStrengthEmpty: {
    
  },
  passwordStrengthBad: {
    display: "block",
    height: "100%",
    width: "100%",
    backgroundColor: "#F15857",
    borderRadius: "10px"
  },
  passwordStrengthMediocre: {
    display: "block",
    height: "100%",
    width: "50%",
    backgroundColor: "#FFC626",
    borderRadius: "10px"
  },
  passwordStrengthGood: {
    display: "block",
    height: "100%",
    width: "100%",
    backgroundColor: "#36CE91",
    borderRadius: "10px"
  },
  passwordStrengthHint: {
    marginTop: "8px",

    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "9px",
    lineHeight: "11px",
    display: "flex",
    alignItems: "center",
    color: "#919CA5"
  },
  confirmPasswordInput: {
    marginTop: "24px",
    marginBottom: "24px"
  },
  successBlock: {
    marginTop: 40
  }
}));

export default useStyles;