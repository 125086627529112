import { UserCommissionInfo } from "./user-commission-info";

export class UserCommissionEntity {
  userId: string;
  userCommission: UserCommissionInfo;
  accountId?: string | null;

  constructor(
    userId: string,
    userCommission: UserCommissionInfo,
    accountId?: string | null,
    )
  {
    this.userId = userId;
    this.userCommission = userCommission;
    this.accountId = accountId;
  }
}