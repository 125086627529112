import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    
    width: 328,
    
    backgroundColor: theme.colors.white
  },
  signatureBlock: {
    border: "solid",
    borderColor: theme.colors.grey2,
    borderRadius: 10,
    borderWidth: 1,
  },
  controlSignatureButtonsBlock: {
    alignSelf: "flex-end",
    
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  
    width: 328,
    height: 32,

    marginTop: 12,
  },
  deleteSignatureButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  
    width: 160,
    height: 32,
  
    backgroundColor: theme.colors.white,
  
    border: "solid",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: theme.colors.grey4,
  
    ...theme.typography.buttons,
    color: theme.colors.grey4,
    fontWeight: 700,
  
    "&:disabled": {
      opacity: 0.3,
      color: theme.colors.grey4,
    },
    "&:hover": {
      backgroundColor: theme.colors.white + "!important",
      color: theme.colors.grey4 + "!important",
    }
  },
  saveSignatureButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    width: 160,
    height: 32,

    backgroundColor: theme.colors.white,

    border: "solid",
    borderWidth: 1,
    borderRadius: 5,
    borderColor: theme.colors.clearMain,

    ...theme.typography.buttons,
    color: theme.colors.clearMain,
    fontWeight: 700,

    "&:disabled": {
      opacity: 0.3,
      color: theme.colors.clearMain,
    },
    "&:hover": {
      backgroundColor: theme.colors.white + "!important",
      color: theme.colors.clearMain + "!important",
    }
  },
  hoverBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    width: 328,
    height: 118,

    backgroundColor: "#1D2027",
  },
  hoverContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",

    width: 120,
    height: 49,
  },
  hoverIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    width: 40,
    height: 40,
  },
  hoverText: {
    marginTop: 2,

    ...theme.typography.txtBody2,
    fontWeight: 700,
    color: theme.colors.grey1,
  }
}));

export default useStyles;
