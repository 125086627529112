import { Gender } from "./enums/gender";
import { PatientFormSection } from "./patient-form-section";

export class PatientGeneralInfoResponse {
    id: string;
    firstName: string;
    lastName: string;
    dateOfBirth?: Date;
    gender?: Gender;
    customGender?: string | null;
    email?: string;
    mobileCodeIso3: string;
    mobileDigits: number;
    mobileCode: number;
    mobileTemplate: string;
    mobile?: string;
    contactableAppointments: boolean;
    contactableMassiveSendings: boolean;

    notes: string;

    patientFormSections: PatientFormSection[];

    constructor(x?: any) {
      if (typeof x === "object") {
        this.id = x.id
        this.firstName = x.firstName;
        this.lastName = x.lastName;
        this.dateOfBirth = x.dateOfBirth;
        this.gender = x.gender;
        this.customGender = x.customGender;
        this.email = x.email;
        this.mobileCodeIso3 = x.mobileCodeIso3;
        this.mobileDigits = x.mobileDigits;
        this.mobileCode = x.mobileCode;
        this.mobileTemplate = x.mobileTemplate;
        this.mobile = x.mobile;
        this.contactableAppointments = x.contactableAppointments;
        this.contactableMassiveSendings = x.contactableMassiveSendings;
        this.patientFormSections = x.patientFormSections;
        this.notes = x.notes;
      }
      else {
        this.id = "";
        this.firstName = "";
        this.lastName = "";
        this.dateOfBirth = undefined;
        this.gender = undefined;
        this.customGender = undefined;
        this.email = undefined;
        this.mobileCodeIso3 = "MEX";
        this.mobileDigits = 10;
        this.mobileCode = 52;
        this.mobileTemplate = "000-000-0000";
        this.mobile = undefined;
        this.contactableAppointments = false;
        this.contactableMassiveSendings = false;
        this.patientFormSections = [];
        this.notes = "";
      }
    }
  }