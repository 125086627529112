import { makeStyles } from "@material-ui/core/styles";

const useStyles = (width?: number | string, height?: number | string) =>
  makeStyles((theme) => ({
    select: {
      ...theme.typography.txtBody2,
      backgroundColor: "theme.colors.grey1",
      height: 40,
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      width: width || 370,
    },
    paper:{
      overflow: "hidden",
      marginTop: 5,
      width: width || 370,
      borderRadius: 10
    },
    searchContainer:{
      marginBottom: 8,
      padding: "0px 10px"
    },
    menuList:{
      maxHeight: height || "auto",
      overflowY: "auto",
      padding: 0,
      "&::-webkit-scrollbar": {
        display: "none"
      },
      "-ms-overflow-style": "none",
      "scrollbar-width": "none"
    },
    selected: {
      fontWeight: "bold",
      padding: "11px 0px 12px 12px",
    },
    placeholder: {
      color: "#676767",
      padding: "13px 0px 13px 12px",
      "&.Mui-disabled": {
        opacity: "0.42",
      },
    },
    focused: {
      color: "#5C6477",
      "&:focus": {
        backgroundColor: "inherit",
      },
      "&.MuiSelect-select": {
        "&:focus": {
          borderRadius: 5,
        },
      },
    },
    itemRoot: {
      ...theme.typography.txtBody1,
      color: theme.colors.grey5,
      height: 40,
      paddingLeft: 0,
      display: "flex",
      alignItems: "center",
      "&.Mui-focusVisible": {
        backgroundColor: "inherit"
      },
    },
    itemSelected: {
      fontWeight: "bold",
      color: theme.colors.clearMain,
      backgroundColor: "inherit",
      "&.Mui-selected": {
        backgroundColor: "inherit"
      },
    },
    checkbox: {
      "&.Mui-checked":{
        color: theme.colors.clearMain,
      },
      "&:not(.Mui-checked)~ div":{
        fontWeight: "normal"
      }
    },
    inputError: {
      color: theme.colors.clearRed,
      border: `solid 1px ${theme.colors.clearRed}`,
      borderRadius: 5,
    },
    group: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey4,
      height: 40,
      display: "flex",
      alignItems: "center",
      "&.Mui-disabled": {
        opacity: 1,
      },
    },
  }));

export default useStyles;
