import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function ScreenNotificationIcon(props: any) {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 24, height: 24, ...style }}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path
        d="M17.375 6.5H6.125C5.49219 6.5 5 7.01562 5 7.625V15.125C5 15.7578 5.49219 16.25 6.125 16.25H10.625L10.25 17.375H8.5625C8.23438 17.375 8 17.6328 8 17.9375C8 18.2656 8.23438 18.5 8.5625 18.5H14.9375C15.2422 18.5 15.5 18.2656 15.5 17.9375C15.5 17.6328 15.2422 17.375 14.9375 17.375H13.25L12.875 16.25H17.375C17.9844 16.25 18.5 15.7578 18.5 15.125V7.625C18.5 7.01562 17.9844 6.5 17.375 6.5ZM17 14.75H6.5V8H17V14.75Z"
        fill={style?.color || "#1D2027"}
      />
    </SvgIcon>
  );
}