import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";


import ExclamationModal from "../../common/ExclamationModal";
import { AppointmentScheduleBusyModalProps } from "./props";
import useStyles from "./css";
import { Appointment } from "../../../models/appointment";

const AppointmentScheduleBusyModal = (props: AppointmentScheduleBusyModalProps) => {

    const { t } = useTranslation(["general"]);
    const { open, appointmentBusyModalData, appointment, onClose } = props;
    const classes = useStyles();
    
    const [appointmentSchedule, setAppointmentSchedule] = useState<Appointment>()

    useEffect(() => {

        if (appointment.startDate && appointment.services) {
            const duration = appointment.services
              .map((service: { duration: any; }) => service.duration)
              .reduce((prev: any, next: any) => (prev || 0) + (next || 0));
            const endDate = new Date(
              new Date(appointment.startDate).getTime() + (duration || 0) * 60000
            );

            setAppointmentSchedule({
                ...appointment,
                endDate: endDate
            });
        }

    }, [appointment]);

    const BusyScheduleInfo = () => <>
      <div>
        <Typography className={classes.normalText}>
            {t("It is not possible to schedule the appointment at this time because the selected specialist has a schedule block activated.")}
        </Typography>
      </div>

      <div>
        <Typography className={classes.normalText} style={{ marginTop: 10 }}>
            {t("We suggest you modify the customized schedule or select another specialist.")}
        </Typography>
      </div>
    </>


    return (<>
    
        <ExclamationModal
            open={open}
            title={`${t("Specialist not available")}`}
            body={<BusyScheduleInfo />}
            okTextButton={t("OkDeAcuerdo")}
            height={360}
            onClose={onClose}
        />

    </>)
};

export default AppointmentScheduleBusyModal;
