import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ArrowDropDownIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 7, height: 4, ...style }}
      viewBox={viewBox || "0 0 7 4"}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M0.600842 0C0.0633791 0 -0.205352 0.620896 0.18553 0.979105L3.09272 3.8209C3.31259 4.0597 3.67904 4.0597 3.92334 3.8209L6.83053 0.979105C7.19698 0.620896 6.92825 0 6.41521 0H0.600842Z" fill="#F6F6F6"/>
    </SvgIcon>
  );
};

export default ArrowDropDownIcon;
