import { makeStyles } from "@material-ui/core/styles";

import { Color } from "../../../../../constants/colors";

export const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: 10,
        paddingTop: 5,
        display: "flex",
        with: "100%"
    },
    headerLeft: {
        textAlign: "left",
        display: "inline"
    },
    headerCenter: {
        textAlign: "right",
        display: "inline",
        marginRight: 0,
        marginLeft: "auto"
    },
    headerRight: {
        textAlign: "right",
        display: "inline",
        marginRight: 0,
        marginLeft: 25
    },
    serviceImportButton: {
        border: "solid",
        borderWidth: 1,
        borderColor: theme.colors.clearMain,

        marginRight: 25,

        color: theme.colors.clearMain,
    },
    button: {
        width: 200,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
    },
    buttonNew: {
        textTransform: "none",

        background: "#7175FA",
        color: theme.colors.grey1,
        "&:hover": {
            background: theme.colors.clearMain,
        },
    },

    titles: {
        color: Color.gray4,
        fontSize: 12,
        fontWeight: "bold",
        marginBottom: 10
    },
    icons: {
        color: Color.gray5,
        fontSize: 18
    },
    buttonDel: {
        width: 32,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        textTransform: "none",
        position: "relative",
        top: "50%",
        transform: "translateY(-50%)"
    },
    iconTrash: {
        fill: Color.gray5
    },
    icon: {
        paddingTop: 5,
        height: 30,
        width: 30,
        fontWeight: "bold"
    },
    classHead: {
        color: theme.colors.grey5,
        textAlign: "left"
    },
    classRow: {
        cursor: "pointer",
        backgroundColor: "white !important",
    },
    classCell: {
        color: theme.colors.grey5,
    },
    colText: {
        ...theme.typography.body2,
        fontSize: 12,
    },
    divNotesTrash: {
        verticalAlign: "middle",
        alignItems: "left",
        display: "flex",
        cursor: "pointer",
        "& #divBackTrash, #divTrash": {
            visibility: "hidden"
        },
        "&:hover": {
            "& #divBackTrash, #divTrash": {
                visibility: "visible"
            }
        },
        position: "relative"
    },
    divNotes: {
        textAlign: "left",
        display: "inline-flex",
        minHeight: 35,
        width: "100%",
        fontSize: 12,
        fontFamily: "Roboto,sans-serif",
        whiteSpace: "pre-wrap",
        wordBreak: "break-word",
        alignItems: "center"
    },
    divTrash: {
        height: "100%",
        position: "absolute",
        right: 0,
    },
    divBackTrash: {
        background: "linear-gradient(270deg, #FFFFFF 50%, rgba(255, 255, 255, 0) 96.63%);",
        height: "100%",
        position: "absolute",
        top: 0,
        right: 0,
        width: 200,
    }
}));


export default useStyles;
