export default class Workarea {
    accountId: string | null;
    id: string | null;
    name: string;
    categoryId?: null | string;
    category: string;
    description: string | null;
    hasSimultaneousServices: boolean;

    constructor(x?: Workarea) {
        if (typeof x === "object" && x !== null && x !== undefined) {
            this.accountId = x.accountId;
            this.id = x.id;
            this.name = x.name || "";
            this.categoryId = x.categoryId || null;
            this.category = x.category || "";
            this.description = x.description || "";
            this.hasSimultaneousServices = x.hasSimultaneousServices
        }
        else {
            this.accountId = null;
            this.id = null;
            this.name = "";
            this.categoryId = null;
            this.category =  "";
            this.description = "";
            this.hasSimultaneousServices = true;
        }
    }
}

//Form Props
export interface WorkareasFormProps {
    settingsSelectedEstablishmentId?: string;
    item?: Workarea;
    onChange?: (isValid: boolean, entity: Workarea) => void;
    onModify?: () => void;
}

//Form Valid
export interface WorkareasValid {
    name?: boolean;
    categoryId?: boolean;
    description?: boolean;
}

