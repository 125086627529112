export class UpdatePatientFormDataResponse {
    isDuplicatedPhoneNumber: boolean;
    duplicatedPatientId: string | null;
    firstName: string | null;
    lastName: string | null;
    constructor(x?: any) {
      if (typeof x === "object") {
        this.isDuplicatedPhoneNumber = x.isDuplicatedPhoneNumber;
        this.duplicatedPatientId = x.duplicatedPatientId;
        this.firstName = x.firstName;
        this.lastName = x.lastName;
      } else {
        this.isDuplicatedPhoneNumber = false;
        this.duplicatedPatientId = null;
        this.firstName = null;
        this.lastName = null;
      }
    }
  }
  