import React, { useEffect, useState, useRef } from "react";

import Input from "@material-ui/core/Input";
import MuiSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import ArrowDropDownIcon from "./../../../assets/icons/ArrowDropDownIcon";

import useStyles from "./css";
import { SelectProps, SelectItem } from "./props";
import Tooltip from "@material-ui/core/Tooltip";

const Select = (props: SelectProps) => {
  const {
    value,
    placeholder,
    name,
    items,
    width,
    onChange,
    onBlur,
    disabled,
    disableScrollLock,
    styleClass,
    itemRootClass,
    classes,
    onRender,
    isValid,
    colorArrowIcon,
    showTooltip
  } = props;
  const useClasses = useStyles(width)();
  
  const selectRef = useRef<HTMLDivElement>(null);
  
  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);
  const [isSelectOpen, setIsSelectOpen] = useState<boolean>(false);
  const [tooltipText, setTooltipText] = useState<string>("");
  
  const isError = (): boolean => {
    if (!isValid) {
      return false;
    }
    
    if (isValid() === undefined) {
      return false;
    }
    
    return !isValid();
  };
  
  const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    if (onChange) {
      onChange(event);
    }
  };
  
  const ArrowIcon = (props: any) => (
    <ArrowDropDownIcon
      {...props}
      style={{ top: "inherit", right: 12, color: colorArrowIcon ?? "#6462F3" }}
    />
  );
  
  const onMouseEnterItem = () => {
    let offsetWidth = (selectRef.current?.firstChild as HTMLElement | null)?.offsetWidth ?? 0;
    let scrollWidth = (selectRef.current?.firstChild as HTMLElement | null)?.scrollWidth ?? 0;

    if (offsetWidth < scrollWidth && !isSelectOpen && showTooltip) {
      setTooltipText(selectRef.current?.innerText ?? "");
      setTooltipOpen(true);
    }
  }
  
  function onRenderHandler(key: any) {
    if (onRender) {
      let renderedValue = onRender(key);
      return renderedValue;
    }
  }
  
  return (
    <Tooltip
      title={tooltipText}
      classes={{ tooltip: useClasses.tooltipBlock }}
      open={isTooltipOpen}>
        <MuiSelect
          disableUnderline
          classes={
            classes || { 
              root: `${useClasses.focused} ${
                value ? useClasses.selected : useClasses.placeholder
              } ${isError() ? useClasses.inputError : ""}`,
            }
          }
          name={name}
          ref={selectRef}
          className={styleClass ?? useClasses.select}
          input={<Input/>}
          MenuProps={{
            classes: disableScrollLock
              ? { paper: useClasses.menuPaperDisableScroll }
              : { paper: useClasses.menuPaper },
            getContentAnchorEl: null,
            style: disableScrollLock ? { position: "absolute" } : {},
            disableScrollLock: disableScrollLock,
            //disablePortal: true,
            anchorOrigin: {
              vertical: "bottom",
              horizontal: "left",
            },
          }}
          displayEmpty
          disabled={disabled ?? false}
          onChange={handleOnChange}
          onBlur={(e) => {
            if (onBlur) onBlur(e)
          }}
          value={value || ""}
          { ...(onRender && { renderValue: (selected) => onRenderHandler(selected) }) }
          IconComponent={ArrowIcon}
          onOpen={() => setIsSelectOpen(true)}
          onClose={() => setIsSelectOpen(false)}
          onMouseEnter={() => onMouseEnterItem()}
          onMouseLeave={() => setTooltipOpen(false)}
          onClick={() => setTooltipOpen(false)}
        >
          {placeholder ? (
            <MenuItem classes={{ root: `${itemRootClass ?? useClasses.itemRoot}`, }} value="" disabled>
              {placeholder}
            </MenuItem>
          ) : null}
          {items.map((item: SelectItem | string | number, index) =>
            typeof item === "object" ? (
              item.isGroup ? (
                <MenuItem
                  key={`option-${item.key}-${index}`}
                  classes={{ root: useClasses.group }}
                  disabled
                >
                  {item.value}
                </MenuItem>
              ) : (
                <MenuItem
                  classes={{
                    root: `${itemRootClass ?? useClasses.itemRoot}`,
                    selected: useClasses.itemSelected,
                  }}
                  key={`option-${item.key}-${index}`}
                  value={item.key}
                >
                  {item.value}
                </MenuItem>
              )
            ) : (
              <MenuItem
                classes={{
                  root: `${itemRootClass ?? useClasses.itemRoot}`,
                  selected: useClasses.itemSelected,
                }}
                key={item || index}
                value={item}
              >
                {item}
              </MenuItem>
            )
          )}
        </MuiSelect>
    </Tooltip>
  );
};

export default Select;
