import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";


export default function ArrowRightIcon(props: any) {

  const { style, color, ...otherProps } = props;

  return (
    <SvgIcon
      {...otherProps}
      style={style}
      viewBox="0 0 17 14"
    >
      <path
        d="M15.9898 6.28152L10.9735 1.26517C10.7488 1.04046 10.444 0.914212 10.1262 0.914212C9.80841 0.914212 9.50363 1.04046 9.27891 1.26517C9.05419 1.48989 8.92795 1.79467 8.92795 2.11247C8.92795 2.43027 9.05419 2.73505 9.27891 2.95977L12.241 5.93935L1.28423 5.93935C0.966364 5.93935 0.661518 6.06562 0.436753 6.29039C0.211988 6.51515 0.0857151 6.82 0.0857151 7.13787C0.0857151 7.45573 0.211988 7.76058 0.436753 7.98535C0.661518 8.21011 0.966364 8.33638 1.28423 8.33638L12.241 8.33638L9.27891 11.2979C9.05419 11.5249 8.92887 11.8319 8.93051 12.1513C8.93215 12.4708 9.06062 12.7765 9.28765 13.0012C9.51469 13.2259 9.82169 13.3512 10.1411 13.3496C10.4606 13.348 10.7663 13.2195 10.991 12.9925L15.9898 7.99359C16.218 7.76688 16.3473 7.45918 16.3495 7.13755C16.3523 6.97771 16.3218 6.81904 16.2598 6.67165C16.1979 6.52426 16.106 6.39139 15.9898 6.28152V6.28152Z"
        fill={color || style?.color || "#919CA5"}
      />
    </SvgIcon>
  )
}