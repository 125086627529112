import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Button, Typography } from "@material-ui/core";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import useStyles from "./css";
import { ISetPasswordFormState, ISetPasswordData } from "./props";
import AuthenticationLayout from "../AuthenticationLayout";
import PasswordInput from "../PasswordInput";
import SuccessBlock from "../SuccessBlock";
import { Url } from "../../../constants/url";
import { AuthenticationService } from "../../../api/authentication-service";
import usePasswordValidation from "../ResetPassword/use-password-validation";
import { useTranslation } from "react-i18next";

export default function Join(props: any) {

  const classes = useStyles();
  const { t } = useTranslation("general");
  const history = useHistory();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const [resetPasswordData, setResetPasswordData] = useState<ISetPasswordData>({ token: "", email: "" });
  const [formState, setFormState] = useState<ISetPasswordFormState>({ password: "", confirmPassword: ""});
  const [completed, setCompleted] = useState<boolean>(false);

  function handlePasswordChange(e: any) {
    setFormState({...formState, password: e.target.value });
  }

  function handleConfirmPasswordChange(e: any) {
    setFormState({...formState, confirmPassword: e.target.value});
  }

  const passwordStrength = usePasswordValidation(formState.password, classes);

  function passwordsAreEqual() {
    return formState.password === formState.confirmPassword;
  }

  async function submitNewPassword() {
    if (!passwordStrength.isGood) {
      return;
    }

    if (!passwordsAreEqual()) {
      return;
    }

    if (!executeRecaptcha) {
      console.log("no recaptcha")
      return;
    }

    const captchaToken = await executeRecaptcha('reset');
    if (!captchaToken) return;

    AuthenticationService
      .resetPassword(resetPasswordData.email, resetPasswordData.token, formState.password, captchaToken)
      .then(
        () => setCompleted(true),
        () => setCompleted(true)
      );
  }

  const SubmitButton =
    <Button
      className={classes.submitButton}
      color="primary"
      variant="contained"
      onClick={submitNewPassword}
      disabled={!(passwordsAreEqual() && passwordStrength.isGood)}
    >
      {t("Sign up")}
    </Button>

  const GoToLoginButton =
    <Link to={Url.Authentication.Login}>
      <Button
        className={classes.submitButton}
        color="primary"
        variant="contained"
      >
        {t("Log in")}
      </Button>
    </Link>

  useEffect(() => {
    const token = props.match.params[0];
    const { email } = props.match.params;
    if (!token || token === "" || !email || email === "") {
      history.push(Url.Authentication.Login);
    }

    setResetPasswordData(
      {
        ...resetPasswordData,
        token: token,
        email: email,
      })
  }, [])

  return (
    <AuthenticationLayout>
      <div>

        {completed ?
          <div className={classes.successBlock}>
            <SuccessBlock
              headerMessage={t("Done!")}
              textMessage={t("Your password has been set")}
              blockHeight={180}
            />
            {GoToLoginButton}
          </div>
          :
          <>
            <Typography className={classes.formHeader}>
              {t("Sign up to join your team")}
            </Typography>
            <Typography className={classes.formHeaderText}>
              {t("We need a few details to create an account for")}
              <strong>{resetPasswordData.email}</strong>
            </Typography>

            <PasswordInput
              value={formState.password}
              label={t("Create password")}
              placeholder={t("Enter your password")}
              onChangeHandler={handlePasswordChange}
              success={passwordStrength.isGood}
              required
            />
            <div className={classes.passwordStrengthMeter}>
              <span className={passwordStrength.className} />
            </div>
            <Typography className={classes.passwordStrengthHint}>
              {passwordStrength.hint}
            </Typography>
            <div className={classes.confirmPasswordInput}>
              <PasswordInput
                value={formState.confirmPassword}
                label={t("Confirm password")}
                placeholder={t("Confirm your password")}
                onChangeHandler={handleConfirmPasswordChange}
                success={passwordsAreEqual() && passwordStrength.isGood}
                required
              />
            </div>

            {SubmitButton}
          </>
        }
      </div>
    </AuthenticationLayout>
  );
}