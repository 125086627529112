import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionFieldRadioIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 18, height: 18, ...style }}
            viewBox={viewBox || "0 0 18 18"}
        >
            <circle opacity="0.3" cx="9" cy="9" r="5.25" fill={style?.color || Color.gray5} />
            <path
                d="M9 3.75C6.0998 3.75 3.75 6.0998 3.75 9C3.75 11.9002 6.0998 14.25 9 14.25C11.9002 14.25 14.25 11.9002 14.25 9C14.25 6.0998 11.9002 3.75 9 3.75ZM9 13.2339C6.6502 13.2339 4.76613 11.3498 4.76613 9C4.76613 6.67137 6.6502 4.76613 9 4.76613C11.3286 4.76613 13.2339 6.67137 13.2339 9C13.2339 11.3498 11.3286 13.2339 9 13.2339Z"
                fill={style?.color || Color.gray5}
            />
            <circle cx="9" cy="9" r="2.25" fill={style?.color || Color.gray5} />
        </SvgIcon>
    );
}