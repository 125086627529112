import { ApiClient } from "./api-client";
import { EstablishmentPhoto } from "./../models/establishmentphoto";

export class EstablishmentPhotoService {

    private static baseUrl: string = "/account";

    static updateEstablishmentPhoto(establishment: EstablishmentPhoto): Promise<EstablishmentPhoto> {
        return ApiClient.post(`${this.baseUrl}/updateestablishmentphoto`, establishment)
            .then(data => {
                return new EstablishmentPhoto(data.establishmentPhoto)
            });
    }

    static deleteEstablishmentPhoto(establishment: EstablishmentPhoto): Promise<EstablishmentPhoto> {
        let endpoint = `${this.baseUrl}/deleteestablishmentphoto?order=${establishment.order}`;

        if (establishment.accountId) {
            endpoint += `&accountId=${establishment.accountId}`;
        }

        return ApiClient.remove(endpoint)
            .then(data => {
                return new EstablishmentPhoto(data.establishmentPhoto)
            });
    }

    static async getEstablishmentPhotos(establishmentAccountId?: string): Promise<EstablishmentPhoto[]> {
        var endpoint = `${this.baseUrl}/getestablishmentphotos`;
        if (establishmentAccountId)
            endpoint += `?AccountId=${establishmentAccountId}`;
        const data = await ApiClient.get(endpoint);
        if (data)
            return data.establishmentPhotos.map(
                (item: any) =>
                    new EstablishmentPhoto(item)
            );
        else
            return [];
    }

}
