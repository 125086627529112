export default class BlockedReason {
    id?: string | null;
    name: string;
    isPredetermined?: boolean;
    isEdit?: boolean;

    constructor(x?: BlockedReason) {
        if (typeof x === "object" && x != null) {
            this.id = x.id;
            this.name = x.name || "";
            this.isPredetermined = x.isPredetermined;
            this.isEdit = false;
        }
        else {
            this.id = null;
            this.name = "";
            this.isPredetermined = false;
            this.isEdit = false;
        }
    }
}

