import { createSlice } from "@reduxjs/toolkit";
import { AppointmentTooltip } from "../models/appointment-tooltip";
import { Weekday } from "../models/weekday";
import { SelectItem } from "../components/common/Select";
import { AppointmentStatus } from "../models/enums/appointment-status";

interface SchedulerState {
  currentDate: Date;
  currentViewName: string;
  daysOff: number[] | null;
  allUsers: SelectItem[];
  selectedUsers: string[] | null;
  selectedStatuses: AppointmentStatus[] | null;
  tooltipAppointments: AppointmentTooltip[];
  appointmentId?: string;
  appointmentBlocked?: boolean;
  startDayHours: number[];
  endDayHours: number[];
  startWeekHour: number;
  endWeekHour: number;
  weekDays: Weekday[];
  isDragging: boolean;
  isGrouping: boolean;
  appointmentDndModalState: {
    open: boolean,
    hasSimultaneousAppointments: boolean,
    appointmentId: string
  }
}

const initialSchedulerState: SchedulerState = {
  currentDate: new Date(),
  currentViewName: "Week",
  daysOff: null,
  allUsers: [],
  selectedUsers: null,
  selectedStatuses: null,
  tooltipAppointments: [],
  appointmentBlocked: false,
  startDayHours: [],
  endDayHours: [],
  startWeekHour: 0,
  endWeekHour: 24,
  weekDays: [],
  isDragging: false,
  isGrouping: true,
  appointmentDndModalState: {
    open: false,
    hasSimultaneousAppointments: false,
    appointmentId: ""
  }
};

const schedulerSlice = createSlice({
  name: "scheduler",
  initialState: initialSchedulerState,
  reducers: {
    setCurrentDate(currentState, action) {
      currentState.currentDate = action.payload;
    },
    setCurrentViewName(currentState, action) {
      currentState.currentViewName = action.payload;
    },
    setDaysOff(currentState, action) {
      currentState.daysOff = action.payload;
    },
    setAllUsers(currentState, action) {
      currentState.allUsers = action.payload;
    },
    setSelectedUsers(currentState, action) {
      currentState.selectedUsers = action.payload;
    },
    setSelectedStatuses(currentState, action) {
      currentState.selectedStatuses = action.payload;
    },
    setTooltipAppointment(currentState, action) {
      currentState.tooltipAppointments = action.payload;
    },
    setAppointmentId(currentState, action) {
      const current = currentState.tooltipAppointments.find(
        (a) => a.appointmentId === action.payload
      );
      if (current) {
        currentState.appointmentId = action.payload;
      }
      else{
        currentState.appointmentId = undefined;
      }
    },
    setAppointmentBlocked(currentState, action) {
      currentState.appointmentBlocked = action.payload;
    },
    setStartDayHours(currentState, action) {
      currentState.startDayHours = action.payload;
    },
    setEndDayHours(currentState, action) {
      currentState.endDayHours = action.payload;
    },
    setStartWeekHour(currentState, action) {
      currentState.startWeekHour = action.payload;
    },
    setEndWeekHour(currentState, action) {
      currentState.endWeekHour = action.payload;
    },
    setWeekDays(currentState, action) {
      currentState.weekDays = action.payload;
    },
    setIsDragging(currentState, action) {
      currentState.isDragging = action.payload;
    },
    setIsGrouping(currentState, action) {
      currentState.isGrouping = action.payload;
    },
    setAppointmentDndModalState(currentState, action) {
      currentState.appointmentDndModalState = action.payload;
    },
  },
});

export const schedulerActions = schedulerSlice.actions;
export default schedulerSlice.reducer;
