import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const EyeIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={style || { width: 18, height: 12 }}
      viewBox={viewBox || "0 0 18 12"}
    >
      <path d="M0.125 5.5625C1.8125 2.25 5.1875 0 9 0C12.8438 0 16.2188 2.25 17.9062 5.5625C17.9688 5.6875 18 5.875 18 6.03125C18 6.15625 17.9688 6.34375 17.9062 6.46875C16.2188 9.78125 12.8438 12 9 12C5.1875 12 1.8125 9.78125 0.125 6.46875C0.0625 6.34375 0.03125 6.15625 0.03125 6C0.03125 5.875 0.0625 5.6875 0.125 5.5625ZM9 10.5C11.5 10.5 13.5 8.5 13.5 6C13.5 3.53125 11.5 1.5 9 1.5C6.53125 1.5 4.5 3.53125 4.5 6V6.03125C4.5 8.5 6.53125 10.5 9 10.5ZM9 3C9.25 3.03125 9.59375 3.0625 9.8125 3.125C9.65625 3.34375 9.5 3.75 9.5 4.03125C9.5 4.84375 10.1875 5.5 11 5.5C11.2812 5.5 11.6875 5.375 11.9062 5.21875C11.9688 5.4375 12 5.78125 12 6C12 7.65625 10.6562 9 9 9C7.34375 9 6 7.65625 6 6C6 4.34375 7.34375 3 9 3Z" fill="#6462F3"/>
    </SvgIcon>
  );
};

export default EyeIcon;
