import React, {useEffect, useState, useRef} from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { selectAccountSettings } from "../../../redux/store";

import Paper from "@material-ui/core/Paper";
import Typography from "@material-ui/core/Typography";

import { MinusArrowIcon, StatusIcon } from "../../../assets/icons";
import { Url } from "./../../../constants/url";
import { CustomerService } from "../../../api/customer-service";
import { CustomerData } from "./../../../models/customer-data";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import useStyles from "./css";
import { CustomerDataInfoProps } from "./props";
import { CustomerFrequencyType } from "../../../models/enums/customer-frequency-type";
import { navbarActions } from "../../../redux/navbar-slice";

const CustomerDataInfo = (props: CustomerDataInfoProps) => {
  const { t } = useTranslation(["general"]);
  const { customerId, name, birthDate } = props;
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const [customer, setCustomer] = useState<CustomerData>(new CustomerData());
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const accountSettings = useAppSelector(selectAccountSettings);
  const isDataLoad = useRef<boolean>(false);

  useEffect(() => {
    if (isDataLoad.current || accountSettings.accountName === "" || accountSettings.isClinicMode) //need to prevent useless API call in clinic mode
      return;
    async function fetchData() {
      await loadData();
    }

    isDataLoad.current = true;
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSettings.accountName]);

  useEffect(() => {
    if (customer.name !== name)
    {
      setCustomer({ ...customer, name: name || null });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [name]);

  useEffect(() => {
    if (!customer.birthDate || birthDate && new Date(customer.birthDate).getTime() !== birthDate.getTime())
    {
      setCustomer({ ...customer, birthDate: birthDate });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [birthDate]);

  const loadData = async () => {
    dispatch(navbarActions.setShowLoader(true));
    const customerData = await CustomerService.getCustomerData(customerId);
    dispatch(navbarActions.setShowLoader(false));
    setCustomer(customerData);
  };

  const handleGoBackClick = () => {
    history.push(`${Url.Customers.Main}`);
  };

  const getAge = (birthDate?: Date | null): number => {
    if (!birthDate) {
      return 0;
    }

    var dob = new Date(birthDate);
    //calculate month difference from current date in time
    var month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    var age_dt = new Date(month_diff);

    //extract year from date
    var year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    return Math.abs(year - 1970);
  };

  const date = (created: Date): string => {
    var createdDate = new Date(created);
    return createdDate.toLocaleString(localeApp, {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  const customerStatus = (status: CustomerFrequencyType) => {
    switch (status) {
      case CustomerFrequencyType.Frequent:
        return t("Frequent Client");
      case CustomerFrequencyType.Infrequent:
        return t("Infrequent Client");
      default:
        return t("Inactive Client");
    }
  };

  return (
    <div className={classes.customerData}>
      <Paper className={classes.content}>
        <span className={classes.goBackButton} onClick={handleGoBackClick}>
          <MinusArrowIcon />
          <Typography className={classes.goBackButtonText}>
            {t("Go back to customers listing")}
          </Typography>
        </span>
        <div className={classes.customerStatus}>
          <StatusIcon status={customer.frequencyType} />
          <Typography className={classes.contentText}>
            {customerStatus(customer.frequencyType)}
          </Typography>
        </div>
        <div className={classes.customerName}>
          <Typography className={classes.titleText}>
            {customer?.name}
          </Typography>
        </div>
        <div className={classes.customerAge}>
          <Typography className={classes.contentText}>
            {getAge(customer.birthDate)} {t("years")}
          </Typography>
        </div>
        <div className={classes.separator}></div>
        <div className={classes.appointments}>
          <div className={classes.appointmentsContent}>
            <Typography className={classes.contentText}>
              {t("Appointments created")}
            </Typography>
            <Typography className={classes.titleText}>
              {customer?.appointments}
            </Typography>
          </div>
          <div className={classes.appointmentsContent}>
            <Typography className={classes.contentText}>
              {t("Attended")}
            </Typography>
            <Typography className={classes.titleText}>
              {customer?.attended}
            </Typography>
          </div>
        </div>
        <div className={classes.separator}></div>
        <div className={classes.appointments}>
          <div className={classes.appointmentsContentDoubleSpace}>
            <Typography className={classes.contentText}>
              {t("Services fulfilled")}
            </Typography>
            <Typography className={classes.titleText}>
              {customer?.services}
            </Typography>
          </div>
        </div>
        <div className={classes.separator}></div>
        <div>
          <Typography className={classes.contentText}>
            {t("Favorite Service")}
          </Typography>
          <div className={classes.service}>
            <Typography className={classes.titleText}>
              {customer?.serviceName || t("No Services")}
            </Typography>
            <Typography
              style={{ marginLeft: 4 }}
              className={classes.serviceText}
            >
              (${customer?.servicePrice})
            </Typography>
          </div>
          <Typography className={classes.serviceText}>
            {t("Times requested")} ({customer?.serviceTimes})
          </Typography>
        </div>
      </Paper>
      <div className={classes.dateRegistered}>
        <Typography className={classes.contentText}>
          {t("Date registered")}
        </Typography>
        <Typography
          className={`${classes.contentBoldText} ${classes.dateRegisteredText}`}
        >
          {date(customer.created)}
        </Typography>
      </div>
    </div>
  );
};

export default CustomerDataInfo;
