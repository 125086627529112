import React from "react";

import { useTranslation } from "react-i18next";
import { useStyles } from "./css";
import { UserStatisticProps } from "./props";

import CustomerClassificationItem from "../../../../customers/CustomerClassificationItem";
import { PersonIcon } from "../../../../../assets/icons";


export default function UserStatistic(props: UserStatisticProps) {

  const { totalUsers } = props;

  const { t } = useTranslation(["settings"]);
  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <CustomerClassificationItem
        className={classes.icons}
        classes={classes}
        title={t("Users")}
        value={totalUsers}
        icon={PersonIcon}
      />
      
    </div>
  );
}