import * as React from "react";
import AppointmentScheduler, {
    OnSchedulerScrollChangedHandler,
    SchedulerScrollController,
} from '../AppointmentsScheduler';
import {
    ContentInnerWrapperComponent,
    GetItemDataFunction,
} from '../AppointmentsScheduler/Content';
import { KeyGetterFunction } from '../AppointmentsScheduler/_util';
import { debounce } from 'lodash';
import moment from 'moment';
import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import AppointmentItem from '../AppointmentItem';
import ColumnDecorations from '../ColumnDecorations';
import Cursor from '../Cursor';
import StopComponent from '../AppointmentsScheduler/StopComponent';
import {
    AppointmentGridItem,
    createAbsencesGridItems,
    createAppointmentsGridItems,
    groupAppointments,
} from '../_common';
import useSchedulerCommonStyles from '../css';
import AppointmentsHeaderCellComponent from './HeaderCellComponent';
import TdComponent from '../TdComponent';
import { Appointment } from "../../../../models/appointment";
import BlockSchedule from "../../../../models/blocked-schedule";
import AppointmentGroupingCorner from "../AppointmentGroupingCorner";

const getAppointmentKey: KeyGetterFunction<AppointmentGridItem> = (a) => a.key;
const getAppointmentGroupKey: KeyGetterFunction<AppointmentGridItem> = (a) => a.groupKey;
const getGroupKey: KeyGetterFunction<string> = (d) => d;
const getAppointmentData: GetItemDataFunction<AppointmentGridItem> = (a) => {
    return {
        ts: a.startsAt,
        sizeInMinutes: a.duration,
    };
};

export type WeekSchedulerProps = {
    appointments: Appointment[];
    dateReference: Date;
    stopsInterval: number;
    absences: BlockSchedule[];
};

const ContentInnerWrapper: ContentInnerWrapperComponent = memo(({ children }) => {
    const activeGroupKey = moment().format('YYYY-MM-DD');

    return (
        <>
            {children}
            <Cursor groupKey={activeGroupKey} />
        </>
    );
});

export default function WeekScheduler({
    dateReference,
    appointments,
    stopsInterval,
    absences,
}: WeekSchedulerProps) {
    const [scrollController, setScrollController] = useState<SchedulerScrollController | null>(
        null
    );
    const styles = useSchedulerCommonStyles();
    const isGrouping = useAppSelector((state) => state.scheduler.isGrouping);

    const days = useMemo(() => {
        const monday = moment(dateReference)
            .hour(0)
            .minute(0)
            .second(0)
            .millisecond(0)
            .startOf('isoWeek');
        return Array.from({ length: 7 }).map((_, i) =>
            monday.clone().add(i, 'day').format('YYYY-MM-DD')
        );
    }, [dateReference]);
    const appointmentGroups = useMemo(() => groupAppointments(appointments, (app) => app.startDate.toFormat('yyyy-MM-dd'), isGrouping), [appointments, isGrouping]);
    const [from, to] = [0, 24 * 60];
    const scrolledRef = useRef(false);

    useEffect(() => {
        if (!scrollController) return;
        if (scrolledRef.current) return;
        scrolledRef.current = true;
        const now = moment();
        // if (uiData.scrollInMinutes !== null) { // todo: try use this for navigation from bell
        //     scrollController.scrollAtTopInMinutes(uiData.scrollInMinutes);
        // } else {
            scrollController.scrollAtCenterInMinutes(now.hour() * 60 + now.minute() - from);
        // }
    }, [scrollController, /*uiData,*/ from]);

    const dispatch = useAppDispatch();
    const onScrollChanged: OnSchedulerScrollChangedHandler = useCallback(
        (data, _event) => {
            //dispatch(setWeekSchedulerScroll(data.scrollTopMinutes));
        },
        [dispatch]
    );
    const onScrollChangedDebounced = useMemo(
        () => debounce(onScrollChanged, 1000),
        [onScrollChanged]
    );

    const gridItems = useMemo(() => {
        const items: AppointmentGridItem[] = [
            ...createAbsencesGridItems(absences, days),
            ...createAppointmentsGridItems(appointmentGroups),
        ];
        return items;
    }, [absences, days, appointmentGroups]);

    return (
        <AppointmentScheduler<AppointmentGridItem, string>
            groups={days}
            classes={{ root: styles.root }}
            items={gridItems}
            showTimezone={false}
            getItemKey={getAppointmentKey}
            getItemGroupKey={getAppointmentGroupKey}
            getItemData={getAppointmentData}
            getGroupKey={getGroupKey}
            components={{
                StopComponent,
                HeaderCellComponent: AppointmentsHeaderCellComponent,
                ItemComponent: AppointmentItem,
                ContentGridColumnComponent: ColumnDecorations,
                ContentGridTdComponent: TdComponent,
                CornerComponent: AppointmentGroupingCorner,
                ContentInnerWrapperComponent: ContentInnerWrapper
            }}
            range={[from, to]} // full day
            sizes={{
                columnWidth: 160,
                headerHeight: 50,
                timelineWidth: 120,
                pixelsPerMinute: (1.8 * 30) / stopsInterval,
            }}
            stopsInterval={stopsInterval}
            scrollControllerRef={setScrollController}
            onScrollChanged={onScrollChangedDebounced}
        />
    );
}
