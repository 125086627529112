import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { alertsActions } from "../../../../redux/alerts-slice";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectAccountSettings, selectCashRegisterState, selectUserInfo } from "../../../../redux/store";
import { setCashRegisterFullState } from "../../../../redux/cash-register-slice";

import { CashRegisterMainProps } from "./props";
import CashRegisterIcon from "../CashRegisterIcon";
import OpenCashRegisterModal from "../OpenCashRegisterModal";
import { OpenCashRegisterFormData } from "../../../../models/open-cash-register-form-data";
import CashRegisterSidebar from "../CashRegisterSidebar/cash-register-sidebar";
import { PaymentsService } from "../../../../api/payments-service";
import { OpenCashRegisterRequest } from "../../../../models/open-cash-register-request";
import { CloseCashRegisterRequest } from "../../../../models/close-cash-register-request";
import { CashRegisterMovement } from "../../../../models/cash-register-movement";
import { CashRegisterMovementHistory } from "../../../../models/cash-register-movement-history";
import { CashRegisterCurrentStateModel } from "../../../../models/interfaces/cash-register-current-state-model";
import { PaymentMethodType } from "../../../../models/enums/payment-method-type";
import { CashRegisterMovementType } from "../../../../models/enums/cash-register-movement-type";
import { CashRegisterStateModel } from "../../../../models/interfaces/cash-register-state-model";
import { RegisterCashRegisterMovementRequest } from "../../../../models/register-cash-register-movement-request";


export default function CashRegisterMain(props: CashRegisterMainProps) {

  const {commonHubConnection} = props;

  const [refresh, setRefresh] = useState<any>();
  const [isOpenCashRegisterModalOpen, setOpenCashRegisterModalOpen] = useState<boolean>(false);
  const [isMainCashRegisterSidebarOpen, setMainCashRegisterSidebarOpen] = useState<boolean>(false);
  const [openCashRegisterFormData, setOpenCashRegisterFormData] = useState<OpenCashRegisterFormData>(new OpenCashRegisterFormData());

  const cashRegisterCurrentState = useAppSelector(selectCashRegisterState);
  const isCashRegisterOpen = cashRegisterCurrentState.isOpen;
  const accountSettings = useAppSelector(selectAccountSettings);
  const currencyTemplate = accountSettings.currencyTemplate;
  const paymentMethods = accountSettings.paymentMethods.map(x => x.key);
  const userInfo = useAppSelector(selectUserInfo);

  const dispatch = useAppDispatch();
  const {enqueueAlert} = alertsActions;

  const {t} = useTranslation("general");

  useEffect(() => {
    async function fetchData() {
      await loadData();
    }

    fetchData();
  }, [refresh])

  async function loadData() {
    const cashRegisterState = await PaymentsService.getCashRegisterCurrentState(paymentMethods);
    dispatch(setCashRegisterFullState(cashRegisterState));
  }

  function setInitialCash(value: number) {
    setOpenCashRegisterFormData({...openCashRegisterFormData, initialCash: value})
  }

  async function handleCashIconButtonClick() {
    if (!isCashRegisterOpen) {
      const initialCash = await PaymentsService.getInitialCash();
      setInitialCash(initialCash)
      setOpenCashRegisterModalOpen(true);
    } else {
      await loadData();
      setMainCashRegisterSidebarOpen(true);
    }
  }

  function displaySuccessfulOpeningAlert() {
    dispatch(
      enqueueAlert({
        type: "Success",
        title: t("Cash register opened"),
        description: t("Opened successfully")
      })
    );
  }

  function displaySuccessfulCashClosingAlert() {
    dispatch(
      enqueueAlert({
        type: "Success",
        title: t("Cash register closed"),
        description: t("Cash closing was successful")
      })
    );
  }

  async function handleOpenCashRegisterSubmit() {
    await PaymentsService.openCashRegister(new OpenCashRegisterRequest({
      initialCash: openCashRegisterFormData.initialCash,
      chargingUserId: userInfo.userId,
      chargingUserName: userInfo.fullName,
    }))
    setOpenCashRegisterModalOpen(false);
    displaySuccessfulOpeningAlert()
  }

  commonHubConnection?.off("openCashRegister")
  commonHubConnection?.off("closeCashRegister")
  commonHubConnection?.off("registerMovement")
  commonHubConnection?.off("updateMovementsInfo")
  commonHubConnection?.on("openCashRegister", openCashRegister)
  commonHubConnection?.on("closeCashRegister", closeCashRegister)
  commonHubConnection?.on("registerMovement", registerMovement)
  commonHubConnection?.on("updateMovementsInfo", updateMovement)


  async function openCashRegister(initialCash: number, movement: CashRegisterMovement) {
    dispatch(setCashRegisterFullState(
      {
        isOpen: true,
        state: new CashRegisterCurrentStateModel(
          {
            initialCash: initialCash,
            cashNow: initialCash,
          }),
        history: new CashRegisterMovementHistory({movements: [movement]})
      }
    ))
  }

  async function closeCashRegister() {
    dispatch(setCashRegisterFullState(
      {
        isOpen: false,
        state: null,
        history: null
      }
    ))
  }
  
  async function registerMovement(cashRegisterMovement: CashRegisterMovement, paymentMethod: PaymentMethodType, isPayment: boolean) {
    
    let newState: CashRegisterStateModel = {...cashRegisterCurrentState };
    const movementAmount = cashRegisterMovement.amount;
    const movements = [...cashRegisterCurrentState.history!.movements]
    
    if (!isPayment) {
      switch (cashRegisterMovement.type) {
        case CashRegisterMovementType.CashOpening:
          return;
        case CashRegisterMovementType.Deposit:
          newState = {
            ...cashRegisterCurrentState,
            state: new CashRegisterCurrentStateModel({
              ...cashRegisterCurrentState.state,
              cashNow: cashRegisterCurrentState.state!.cashNow + movementAmount,
              depositByAdministrator: cashRegisterCurrentState.state!.depositByAdministrator + movementAmount
            }),
            history: new CashRegisterMovementHistory({ movements: [cashRegisterMovement].concat(cashRegisterCurrentState.history!.movements)})
          }
          break;
        case CashRegisterMovementType.Withdrawal:
          newState = {
            ...cashRegisterCurrentState,
            state: new CashRegisterCurrentStateModel({
              ...cashRegisterCurrentState.state,
              cashNow: cashRegisterCurrentState.state!.cashNow - movementAmount,
              withdrawalByAdministrator: cashRegisterCurrentState.state!.withdrawalByAdministrator + movementAmount
            }),
            history: new CashRegisterMovementHistory({ movements: [cashRegisterMovement].concat(cashRegisterCurrentState.history!.movements)})
          }
          break;
      }
      dispatch(setCashRegisterFullState(newState))
      return;
    }
    
    switch (paymentMethod) {
      case PaymentMethodType.Cash:
        newState = {
          ...cashRegisterCurrentState,
          state: new CashRegisterCurrentStateModel({
            ...cashRegisterCurrentState.state,
            paymentsReceived: cashRegisterCurrentState.state!.paymentsReceived + movementAmount,
            cashNow: cashRegisterCurrentState.state!.cashNow + movementAmount,
            cashPayments: cashRegisterCurrentState.state!.cashPayments + movementAmount
          }),
          history: new CashRegisterMovementHistory({ movements: [cashRegisterMovement].concat(cashRegisterCurrentState.history!.movements)})
        }
        break;
      case PaymentMethodType.CreditCard:
        newState = {
          ...cashRegisterCurrentState,
          state: new CashRegisterCurrentStateModel({
            ...cashRegisterCurrentState.state,
            paymentsReceived: cashRegisterCurrentState.state!.paymentsReceived + movementAmount,
            creditCardPayments: cashRegisterCurrentState.state!.creditCardPayments + movementAmount
          }),
          history: new CashRegisterMovementHistory({ movements: [cashRegisterMovement].concat(cashRegisterCurrentState.history!.movements)})
        }
        break;
      case PaymentMethodType.BankTransfer:
        newState = {
          ...cashRegisterCurrentState,
          state: new CashRegisterCurrentStateModel({
            ...cashRegisterCurrentState.state,
            paymentsReceived: cashRegisterCurrentState.state!.paymentsReceived + movementAmount,
            bankTransferPayments: cashRegisterCurrentState.state!.bankTransferPayments + movementAmount
          }),
          history: new CashRegisterMovementHistory({ movements: [cashRegisterMovement].concat(cashRegisterCurrentState.history!.movements)})
        }
        break;
      case PaymentMethodType.Oxxo:
        newState = {
          ...cashRegisterCurrentState,
          state: new CashRegisterCurrentStateModel({
            ...cashRegisterCurrentState.state,
            paymentsReceived: cashRegisterCurrentState.state!.paymentsReceived + movementAmount,
            oxxoPayments: cashRegisterCurrentState.state!.oxxoPayments + movementAmount
          }),
          history: new CashRegisterMovementHistory({ movements: [cashRegisterMovement].concat(cashRegisterCurrentState.history!.movements)})
        }
        break;
      case PaymentMethodType.MercadoPago:
        newState = {
          ...cashRegisterCurrentState,
          state: new CashRegisterCurrentStateModel({
            ...cashRegisterCurrentState.state,
            paymentsReceived: cashRegisterCurrentState.state!.paymentsReceived + movementAmount,
            mercadoPagoPayments: cashRegisterCurrentState.state!.mercadoPagoPayments + movementAmount
          }),
          history: new CashRegisterMovementHistory({ movements: [cashRegisterMovement].concat(cashRegisterCurrentState.history!.movements)})
        }
        break;
    }

    dispatch(setCashRegisterFullState(newState))
    return;
  }
  
  async function updateMovement() {
    await loadData()
  }

  async function handleCloseCashRegisterSubmit(closeCashRegisterRequest: CloseCashRegisterRequest) {

    await PaymentsService.closeCashRegister(closeCashRegisterRequest);

    displaySuccessfulCashClosingAlert();
    setMainCashRegisterSidebarOpen(false);
  }

  async function handleRegisterNewMovement(newMovementRequest: RegisterCashRegisterMovementRequest) {
    await PaymentsService.registerMovement(newMovementRequest);
  }

  const CashIcon = () =>
    <CashRegisterIcon
      onClick={handleCashIconButtonClick}
      isActive={isCashRegisterOpen}
    />

  return (
    <div>
      <CashIcon/>
      {
        !isCashRegisterOpen &&
        <OpenCashRegisterModal
          open={isOpenCashRegisterModalOpen}
          setOpen={setOpenCashRegisterModalOpen}
          anchor={CashIcon}
          initialCash={openCashRegisterFormData.initialCash}
          setInitialCash={setInitialCash}
          currencyTemplate={currencyTemplate}
          onSubmit={handleOpenCashRegisterSubmit}
        />
      }
      {
        isCashRegisterOpen && cashRegisterCurrentState.state && cashRegisterCurrentState.history &&
        <CashRegisterSidebar
          open={isMainCashRegisterSidebarOpen}
          setOpen={setMainCashRegisterSidebarOpen}
          state={cashRegisterCurrentState.state}
          history={cashRegisterCurrentState.history}
          onCashRegisterClose={handleCloseCashRegisterSubmit}
          onRegisterNewMovement={handleRegisterNewMovement}
          currencyTemplate={currencyTemplate}
        />
      }
    </div>
  );
}