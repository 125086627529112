export class SectionFieldUpdateOrderRequest {
  establishmentAccountId?: string|null;
  id: string;
  sectionId: string;
  newOrder: number;
  previousOrder: number;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.establishmentAccountId = x.establishmentAccountId;
      this.id = x.id;
      this.sectionId = x.sectionId;
      this.newOrder = x.newOrder;
      this.previousOrder = x.previousOrder;
    } else {
      this.establishmentAccountId = null;
      this.id = "";
      this.sectionId = "";
      this.newOrder = 0;
      this.previousOrder = 0;
    }
  }
}