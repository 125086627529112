import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

export const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        backgroundColor: Color.gray6,
        borderRadius: "10px",
        boxShadow: theme.shadows[5],

        position: "relative",
        height: "80vh",
        width: "65vw"
    },
    indicatorContainer: {
        backgroundColor: Color.gray1,
        borderRadius: "0px 0px 10px 10px",
        height: "48px",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "0px",
        position: "absolute",
        bottom: 0,
        width: "100%"
    },
    indicatorBulletInactive: {
        color: Color.gray2,
        "&:hover": {
            color: Color.clear3
        },
    },
    indicatorBulletActive: {
        color: Color.clear1
    },
    exitButton: {
        position: "absolute",
        top: 10,
        right: 10,
        height: 47,
        width: 47,
        borderRadius: "50%",
        backgroundColor: "black",
        filter: "brightness(120%)",
        opacity: "0.4",
        zIndex: 998,
        transition: "200ms",
        cursor: "pointer",
        "&:hover": {
            opacity: "0.6 !important"
        },
    },
    crossIcon: {
        position: "absolute",
        top: 17,
        left: 16,
        color: Color.gray1,
        zIndex: 999
    },
    carouselItem: {
        display: "block",
        marginLeft: "auto",
        marginRight: "auto",
        maxHeight: "calc(80vh - 48px)",
        maxWidth: "calc(65vw - 140px)",
        width: "auto",
        transition: "transform 0.3s ease",
        zIndex: 998,
    },
    zoomInCursor: {
        cursor: "zoom-in",
    },
    zoomedImage: {
        transform: "scale(1.6)",
        cursor: "zoom-out",
        zIndex: 999,
    },
    carouselRoot: {
        position: "relative",
        height: "80vh",
        width: "65vw",
        overflow: "unset !important"
    },
    mediaWrapper: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "relative",
        height: "calc(80vh - 48px)",
    },
    hidden: {
        visibility: "hidden"
    },
    carouselItemText: {
        ...theme.typography.txtBody1,
        display: "-webkit-box",
        WebkitLineClamp: 2,
        WebkitBoxOrient: "vertical",
        whiteSpace: "normal",
        textOverflow: "ellipsis",
        overflow: "hidden",
        maxWidth: "50%"
    }
}));