import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { Typography } from "@material-ui/core";

import useStyles from "./css";
import { SaveReportModalProps } from "./props";
import { InfoIcon } from "../../../assets/icons";
import InputWithLabel from "../../common/InputWithLabel";
import ConfirmActionModal from "../../common/ConfirmActionModal";


export default function SaveReportModal(props: SaveReportModalProps) {
  
  const { t } = useTranslation(["general"]);
  const { open, onClose, onConfirm, checkNameIsInvalid } = props;
  const classes = useStyles();
  
  const [reportName, setReportName] = useState<string>("");
  const [cancelSaveModalOpen, setCancelSaveModalOpen] = useState<boolean>(false);
  const [nameIsInvalid, setNameIsInvalid] = useState<boolean>(false);
  
  function handleConfirm() {
    onConfirm(reportName);
  }
  
  function handleClose() {
    setNameIsInvalid(false);
    setReportName("");
    setCancelSaveModalOpen(false);
    onClose();
  }
  
  function handleClickGoBack() {
    if(reportName.length < 1) {
      handleClose()
      return;
    }
    setCancelSaveModalOpen(true);
  }
  
  function handleOnInputChange(event: React.ChangeEvent<{ value: string }>) {
    let reportName = event.target.value;
    setNameIsInvalid(checkNameIsInvalid(reportName));
    setReportName(reportName);
  }
  
  const CancelSaveReportModalContent: React.FC = () => {
    return (
      <div className={classes.cancelModalContainer}>
        <Typography style={{marginBottom: 8}} className={classes.modalHeader}>
          {t("Cancel custom report creation?")}
        </Typography>
        <Typography className={classes.cancelModalContent}>
          {t("Unsaved changes will be lost.")}
        </Typography>
      </div>);
  };
  
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={`${classes.modal}`}
      open={open}
      onClose={handleClickGoBack}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={open}>
        <div className={`${classes.modalPaper}`}>
          <div style={{marginBottom: 24}} className={classes.modalHeader}>
            {t("Save custom report")}
          </div>
          <div className={classes.modalContent}>
            <InputWithLabel
              label={() => (
                <>
                  {t("Report name")}  <span style={{ color: "#6462F3" }}> *</span>
                </>
              )}
              value={reportName}
              inputClass={nameIsInvalid && classes.inputInvalid}
              maxLength={180}
              placeholder={t("Enter the name of your custom report")}
              onChange={handleOnInputChange}
              width={416}
            >
            </InputWithLabel>
            
            <div className={classes.infoContent}>
              <InfoIcon
                style={{ width: 17, height: 16, marginLeft: 5, color: "#6462F3" }}
                viewBox={"0 0 12 12"}
              />
              {nameIsInvalid 
                ? t("There is already a report with the same name. Please write another name.") 
                : t("This report will be saved for easy future reference.")}
            </div>
            <div className={classes.modalActions}>
              <Button className={`${classes.button} ${classes.goBack}`} onClick={handleClickGoBack}>
                {t("Go back")}
              </Button>
              
              <Button 
                className={`${classes.button}  ${classes.confirmButton}`} 
                disabled={reportName.length < 1 || nameIsInvalid} 
                onClick={handleConfirm}>
                {t("Save report")}
              </Button>
            </div>
          
          </div>
          <ConfirmActionModal
            open={cancelSaveModalOpen}
            content={<CancelSaveReportModalContent/>}
            onClose={() => setCancelSaveModalOpen(false)}
            onConfirm={handleClose}
            confirmButtonText={t("Yes, cancel")}
            buttonClass={classes.button}
          />
        </div>
      </Fade>
    </Modal>
  );
}