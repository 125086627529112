import { makeStyles } from "@material-ui/core/styles";


const useStyles = makeStyles((theme) => ({
    root: {},
    title: {
        fontFamily: "Roboto",
        fontSize: "12px",
        color: "#919CA5",
        margin: 0,
    },
    required: {
        color: "#6462F3",
    },
    optional: {
        fontWeight: "normal",
    },
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "24px 0px 0px 0px",
    },
    switchBox: {
        marginTop: 24,
        marginLeft: 11
    },
    label: {
        display: "flex",
        margin: 0,
        color: "#4a4d51",
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "bold",
    },
    description: {
        margin: 0,
        color: "#919CA5",
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "normal",
    },
    rowDescription: {
        position: "relative",
        zIndex: 1,
    },
    button: {
        width: 200,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #dbdcdd",
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: "40px 40px 48px",
        width: 812,
        height: 399,
    },
    modalHeader: {
        display: "flex",
        alignItems: "center",
    },
    modalTitle: {
        width: "100%",
    },
    modalTitleText: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#5C6477",
    },
    modalActions: {
        display: "flex",
        justifyContent: "flex-end",
    },
    goBack: {
        marginRight: 16,
        background: "#F6F6F6",
        color: "#ACB7C0",
    },
    createCustomer: {
        background: "#36CE91",
        color: "#F6F6F6",
        "&:hover": {
            background: "#36CE91",
        },
    },
    createDisabled: {
        color: "#F6F6F6 !important",
        opacity: "0.4",
    },
}));

export default useStyles;
