export class SectionFieldUpdateResponse {
  id: string;
  fieldItemIds: string[];

  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.fieldItemIds = x.fieldItemIds;
    } else {
      this.id = "";
      this.fieldItemIds = [];
    }
  }
}