import React from "react";

import { useTranslation } from "react-i18next";

import { CashStateHeaderProps } from "./props";
import useStyles from "./css";
import IconTooltip from "../../../common/IconTooltip";
import { InfoIcon } from "../../../../assets/icons";
import useCurrencyValueTemplate from "../../../../hooks/useCurrencyValueTemplate";


export default function CashStateHeader(props: CashStateHeaderProps) {
  
  const { state, currencyTemplate } = props;
  const { initialCash, cashNow, paymentsReceived } = state;
  
  const classes = useStyles();
  const { t } = useTranslation("general");
  const templatedValue = useCurrencyValueTemplate(currencyTemplate);
  
  return (
    <div className={classes.root}>
      <div className={classes.block}>
        <div className={classes.textWithTooltip}>
          <span className={classes.text}>{t("Initial cash")} 
            <IconTooltip
              classNameRoot={classes.iconTooltipRoot}
              icon={<InfoIcon style={{ width: 12, height: 12, marginLeft: 4 }} viewBox={"0 0 12 12"} />}
              tooltipText={t("Cash when the cash register was opened")}
            />
          </span>
        </div>
        <div className={classes.value}>{templatedValue(initialCash)}</div>
      </div>
      <div className={classes.verticalDivider} />
      <div className={`${classes.block} ${classes.centerBlock} ${classes.blockLongText}`}>
        <div className={classes.blockCashExpected} >
        <div className={classes.textWithTooltip}>
          <span className={classes.text}>{t("Cash expected at the end of the day")}</span>
        </div>
        <IconTooltip
          classNameRoot={classes.iconTooltipRoot}
          icon={<InfoIcon style={{ width: 12, height: 12, marginLeft: 4 }} viewBox={"0 0 12 12"} />}
          tooltipText={t("Cash expected at the cash register at this moment")}
        />
        </div>
        <div className={classes.value}>{templatedValue(cashNow)}</div>
      </div>
      <div className={classes.verticalDivider} />
      <div className={classes.block}>
        <div className={classes.textWithTooltip}>
          <span className={classes.text}>{t("Payments received")}</span>
          <IconTooltip
            classNameRoot={classes.iconTooltipRoot}
            icon={<InfoIcon style={{ width: 12, height: 12, marginLeft: 4 }} viewBox={"0 0 12 12"} />}
            tooltipText={t("Total sum of all payment methods")}
          />
        </div>
        <div className={classes.value}>{templatedValue(paymentsReceived)}</div>
      </div>
    </div>
  );
}