import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import { ExclamationIcon, CrossIcon } from "../../../../../assets/icons";

import { WeekdaysChangeSettingsModalProps, AlertPopupType } from "./props";
import useStyles from "./css";

const WeekdaysChangeSettingsModal = (props: WeekdaysChangeSettingsModalProps) => {

    const { t } = useTranslation(["general"]);
    const { open, count, type, onClose } = props;
    const classes = useStyles();

    const [counter, setCounter] = useState<number>(5);
    const [typeModify, setTypeModify] = useState<AlertPopupType>();

    useEffect(() => {
        setCounter(count);
        setTypeModify(type);
    });

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>

            <Fade in={open}>
                <div className={classes.modalPaper}>
                    <div className={classes.modalHeader}>
                        <div className={classes.modalHeaderContent}>
                            <div className={classes.modalCircle}>
                                <ExclamationIcon viewBox="0 0 5 16" />
                            </div>
                        </div>
                        <div className={classes.modalClose}>
                            <span className={classes.closeButton} onClick={onClose}>
                                <CrossIcon />
                            </span>
                        </div>
                    </div>

                    <div className={classes.modalContent}>

                        <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`} >
                            <Typography className={classes.modalTitleText}>
                                {typeModify === AlertPopupType.Timezone && t("It is not possible to modify your time zone")}
                                {typeModify === AlertPopupType.AvailableHours &&  t("It is not possible to modify your available hours")}
                            </Typography>
                        </div>

                        <div className={classes.modalCenter}>
                            {typeModify === AlertPopupType.Timezone && <Trans i18nKey="SettingsAlertChangeAboutTimezone" t={t} count={counter} />}
                            {typeModify === AlertPopupType.AvailableHours && <Trans i18nKey="SettingsAlertChangeAboutAvailableHours" t={t} count={counter} />}
                        </div>

                        <div className={classes.modalActions}>
                            <Button className={`${classes.button} ${classes.btnCancel}`} onClick={onClose} >
                                {t("OK!")}
                            </Button>
                        </div>

                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default WeekdaysChangeSettingsModal;
