import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";
export const useStyles = makeStyles((theme) => ({
    root: {},
    popper:{
        [`& .MuiTooltip-tooltip`]: {
            padding: "4px 8px",
            borderRadius: "5px",
            backgroundColor: Color.gray1,
            fontFamily: "Roboto, sans-serif",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "12px",
            color: theme.colors.grey5,
            boxShadow: "0px 0px 4px #00000034"
        }
    }
}));