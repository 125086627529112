import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AddCustomerIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 18, height: 15, ...style }}
      viewBox={viewBox || "0 0 18 15"}
    >
      <path d="M17.5625 6.4375H15.8125V4.6875C15.8125 4.46875 15.5938 4.25 15.375 4.25H14.5C14.2539 4.25 14.0625 4.46875 14.0625 4.6875V6.4375H12.3125C12.0664 6.4375 11.875 6.65625 11.875 6.875V7.75C11.875 7.99609 12.0664 8.1875 12.3125 8.1875H14.0625V9.9375C14.0625 10.1836 14.2539 10.375 14.5 10.375H15.375C15.5938 10.375 15.8125 10.1836 15.8125 9.9375V8.1875H17.5625C17.7812 8.1875 18 7.99609 18 7.75V6.875C18 6.65625 17.7812 6.4375 17.5625 6.4375ZM6.625 7.75C8.53906 7.75 10.125 6.19141 10.125 4.25C10.125 2.33594 8.53906 0.75 6.625 0.75C4.68359 0.75 3.125 2.33594 3.125 4.25C3.125 6.19141 4.68359 7.75 6.625 7.75ZM9.05859 8.625H8.59375C7.99219 8.92578 7.33594 9.0625 6.625 9.0625C5.91406 9.0625 5.23047 8.92578 4.62891 8.625H4.16406C2.14062 8.625 0.5 10.293 0.5 12.3164V13.4375C0.5 14.1758 1.07422 14.75 1.8125 14.75H11.4375C12.1484 14.75 12.75 14.1758 12.75 13.4375V12.3164C12.75 10.293 11.082 8.625 9.05859 8.625Z" />
    </SvgIcon>
  );
};

export default AddCustomerIcon;
