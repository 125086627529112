import { SectionType } from "../models/enums/section-type";
import { SectionCreateRequest } from "../models/section-create-request";
import { SectionDeleteRequest } from "../models/section-delete-request";
import { SectionFieldCreateRequest } from "../models/section-field-create-request";
import { SectionFieldCreateResponse } from "../models/section-field-create-response";
import { SectionFieldDeleteRequest } from "../models/section-field-delete-request";
import { SectionFieldUpdateOrderRequest } from "../models/section-field-update-order-request";
import { SectionFieldUpdateRequest } from "../models/section-field-update-request";
import { SectionFieldUpdateResponse } from "../models/section-field-update-response";
import { SectionModel } from "../models/section-model";
import { SectionUpdateRequest } from "../models/section-update-request";
import { ApiClient } from "./api-client";
import { CopyEstablishmentSettingsModel } from "../models/copy-establishment-settings-model";

export class SettingsPatientFormsService {
  private static baseUrl: string = "/account";
  private static sectionBaseUrl: string = "/section";
  private static sectionFieldBaseUrl: string = `${SettingsPatientFormsService.sectionBaseUrl}/sectionField`;

  static async getAccountSettingsTabSections(sectionType: SectionType, establishmentAccountId?: string | null): Promise<SectionModel[]> {
    const establishmentAccountIdRequestValue = establishmentAccountId === null || establishmentAccountId === undefined 
    ? "" 
    : establishmentAccountId;

      const data = await ApiClient.get(
          `${this.sectionBaseUrl}/GetAccountSettingsTabSections?TabType=${sectionType}${establishmentAccountIdRequestValue ? `&EstablishmentAccountId=${establishmentAccountIdRequestValue}` : ''}`
      );

    if (data)
      return data.sections.map(
        (item: SectionModel) => new SectionModel(item));
    else
      return [];
  }

  static async createSection(request: SectionCreateRequest): Promise<string | null> {
    const data = await ApiClient.post(`${this.sectionBaseUrl}/Create`, request);
    if (data)
      return data.id;
    else
      return null;
  }

  static async updateSection(request: SectionUpdateRequest): Promise<SectionUpdateRequest | null> {
    const data = await ApiClient.put(`${this.sectionBaseUrl}/Update`, request);
    if (data)
      return new SectionUpdateRequest(data);
    else
      return null;
  }

  static async deleteSection(request: SectionDeleteRequest): Promise<boolean> {
    const data = await ApiClient.remove(`${this.sectionBaseUrl}/Delete`, request);
    if (data)
      return true;
    else
      return false;
  }

  static async createSectionField(request: SectionFieldCreateRequest): Promise<SectionFieldCreateResponse | null> {
    const data = await ApiClient.post(this.sectionFieldBaseUrl, request);
    if (data)
      return new SectionFieldCreateResponse(data);
    else
      return null;
  }

  static async updateSectionField(request: SectionFieldUpdateRequest): Promise<SectionFieldUpdateResponse | null> {
    const data = await ApiClient.put(this.sectionFieldBaseUrl, request);
    if (data)
      return new SectionFieldUpdateResponse(data);
    else
      return null;
  }

  static async deleteSectionField(request: SectionFieldDeleteRequest): Promise<boolean> {
    const data = await ApiClient.remove(this.sectionFieldBaseUrl, request);
    if (data)
      return true;
    else
      return false;
  }

  static async reorderSectionFields(request: SectionFieldUpdateOrderRequest): Promise<boolean> {
    const data = await ApiClient.put(`${this.sectionFieldBaseUrl}/ReorderSectionFields`, request);
    return data;
  }
  
  static async copyPatientFormSetting(copyModel: CopyEstablishmentSettingsModel): Promise<boolean> {
    return await ApiClient.post(`${this.baseUrl}/copypatientformsetting`, copyModel).then(
      (data) => (data.succeeded)
    );
  }
}