import React, { useEffect } from "react";
import { ListItem, Typography } from '@material-ui/core';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { AppointmentStatus } from "../../../../../models/enums/appointment-status";
import { Appointment } from "../../../../../models/appointment";
import Tooltip from "@material-ui/core/Tooltip";
import { ComputerIcon, EstablishmentIcon } from "../../../../../assets/icons";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import { AppointmentCreatedBy } from "../../../../../models/enums/appointment-created-by";
import useStyles from "./css";
import { schedulerActions } from "../../../../../redux/scheduler-slice";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../../../redux/store";

export type AppointmentCardProps = {
    appointment: Appointment;
    enableTooltip?: boolean;
    onClick: () => void;
    anchorEl?: any;
};

const AppointmentCard = forwardRef(
    (
        { appointment, onClick, enableTooltip, anchorEl }: AppointmentCardProps,
        ref: React.ForwardedRef<HTMLDivElement>
    ) => {
        const classes = useStyles();
    
        const { setTooltipAppointment } = schedulerActions;
    
        const dispatch = useDispatch();
        const appointmentId = useSelector((state: RootState) => state.scheduler.appointmentId);
        const tooltipAppointments = useSelector((state: RootState) => state.scheduler.tooltipAppointments);
        
        useEffect(() => {
            if (appointmentId && appointmentId === appointment.appointmentId) {
               // onClick();
                anchorEl?.scrollIntoView({ block: "center" });
                const current = tooltipAppointments.find((a) => a.appointmentId === appointment.appointmentId);
                if (current) {
                    current.elementRef = anchorEl;
                    dispatch(
                      setTooltipAppointment([
                          ...tooltipAppointments.filter((a) => a.appointmentId !== current.appointmentId),
                          current,
                      ])
                    );
                }
            }
        
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [appointmentId]);
    
        const getStatus = (status: AppointmentStatus): string => {
            switch (status) {
                case AppointmentStatus.Arrived:
                    return classes.arrived;
                case AppointmentStatus.NotArrived:
                    return classes.notArrived;
                case AppointmentStatus.NotConfirmed:
                    return classes.notConfirmed;
                case AppointmentStatus.Confirmed:
                default:
                    return classes.confirmed;
            }
        };
    
        const dateFormat = (date: Date): string => {
            const formatDate = date.toTimeString().split(" ")[0].split(":");
            return `${formatDate[0]}:${formatDate[1]}`;
        };
    
        //const restProps = { ...otherProps, style, data, children };
        const isNotArrived = appointment.status === AppointmentStatus.NotArrived;
    
        return (
            <Tooltip
              classes={{
                  popper: classes.popper
              }}
              PopperProps={{
                  popperOptions: {
                      positionFixed: true
                  }
              }}
              placement="top"
              title={<div className={classes.tooltipContainer}>
                    <div className={classes.tooltipCustomerNameBox}>
                        <ComputerIcon style={{ position: "relative", left: -5, marginRight: -5 }}/> {appointment.customerName}
                    </div>
                    {appointment?.services?.length === 1
                      ? <span style={{ marginTop: 2, marginBottom: 4 }} className={classes.serviceSingleItemText}>{appointment.services[0]}</span>
                      : <List className={classes.serviceItemsTooltipList}>
                          {appointment?.services?.map((item, index) => (
                            <ListItem classes={{ root: classes.serviceListItem }} key={index}>
                                <div className={classes.dot}></div>
                                <ListItemText className={classes.serviceItemText} primary={item}/>
                            </ListItem>
                          ))}
                      </List>}
                    <div className={classes.tooltipAppointmentTime}>
                        {dateFormat(appointment.startDate.toJSDate()) +
                          " - " +
                          dateFormat(appointment.endDate.toJSDate())}
                    </div>
                    {appointment?.users?.length === 1
                      ? <div className={classes.tooltipUserName}>
                          {appointment.users[0]}
                      </div>
                      : <>
                          {appointment?.users?.map((item, index) => (
                            <div className={`${classes.tooltipUserName} ${classes.personWithDot}`}>
                                <div className="dot"></div>
                                {item}
                            </div>
                          ))}
                      </>}
                    {
                      appointment?.establishmentName &&
                      <div className={classes.establishmentRow}>
                          <EstablishmentIcon />
                          <Typography className={`${classes.tooltipAppointmentTime}`}>
                              {appointment?.establishmentName}
                          </Typography>
                      </div>
                    }
                    </div>
                }>
            
                <div className={classes.root} style={{height: "100%"}}>
                    <div ref={ref} id={appointment.appointmentId as string} onClick={onClick} style={{height: "100%"}}>
                        <div className={`${classes.header} ${getStatus(appointment.status)}`}></div>
                        <div
                          className={`${classes.content} ${
                            isNotArrived ? classes.contentNotArrive : ""
                          }`}
                        >
                            <Typography style={{ display: "inline-block" }}
                                        className={`${isNotArrived ? classes.customerNotArrived : (classes.customerTitle)}
                ${appointment.createdBy === AppointmentCreatedBy.Customer ? classes.isAppointmentCustomerSite : null}`}
                            >
                                {appointment.createdBy === AppointmentCreatedBy.Customer && <ComputerIcon style={{ position: "relative", left: -5, marginRight: -5 }}/>}
                                {appointment.customerName}
                            </Typography>
                            {appointment?.services?.length === 1
                              ? <div className={classes.serviceSingleItemText}>{appointment.services[0]}</div>
                              : <List className={classes.serviceItemsList}>
                                  {appointment?.services?.map((item, index) => (
                                    <ListItem classes={{ root: classes.serviceListItem }} key={index}>
                                        <div className={classes.dot}></div>
                                        <ListItemText className={classes.serviceItemText} primary={item}/>
                                    </ListItem>
                                  ))}
                              </List>}
                            <Typography className={classes.hoursText}>
                                {dateFormat(appointment.startDate.toJSDate()) +
                                  " - " +
                                  dateFormat(appointment.endDate.toJSDate())}
                            </Typography>
                            {appointment?.users?.length === 1
                              ? <Typography className={classes.personChargeText}>
                                  {appointment.users[0]}
                              </Typography>
                              : <>
                                  {appointment?.users?.map((item, index) => (
                                    <Typography className={`${classes.personChargeText} ${classes.personWithDot}`}>
                                        <div className="dot"></div>
                                        {item}
                                    </Typography>
                                  ))}
                              </>}
                            {
                              appointment?.establishmentName &&
                              <div className={classes.establishmentRow}>
                                  <EstablishmentIcon />
                                  <Typography className={classes.hoursText}>
                                      {appointment?.establishmentName}
                                  </Typography>
                              </div>
                            }
                        </div>
                    </div>
                </div>
            </Tooltip>
        );
    }
);

export default AppointmentCard;
