import React from "react";

import MuiSwitch from "@material-ui/core/Switch";
import { FormControlLabel } from "@material-ui/core";

import useStyles from "./css";
import { SwitchWithLabelAndTooltipProps } from "./props";
import { Color } from "../../../constants/colors";


export default function SwitchWithLabelAndTooltip(props: SwitchWithLabelAndTooltipProps) {
  
  const { value, setValue, label, inactiveLabel, tooltip, inactiveTooltip, required, disabled } = props;
  
  const classes = useStyles();
  
  function getActiveLabel() {
    if (!inactiveLabel) {
      return label;
    }
    return value ? label : inactiveLabel;
  }
  
  function getActiveTooltip() {
    if (!inactiveTooltip) {
      return tooltip;
    }
    return value ? tooltip : inactiveTooltip;
  }
  
  const tryGetRequiredAsterix = () => {
    return required
    ? <span id={"labelAsterixId"} className={classes.labelText} style={{color: Color.clear1}}> *</span>
    : <></>
  }
  const labelWithoutTooltip = (label: string) =>
    <>
      <span id={"labelId"} className={classes.labelText}>{label}{tryGetRequiredAsterix()}</span>
    </>

  const labelWithTooltip = (label: string, tooltip?: string) =>
    <span className={classes.labelWithTooltip}>
      <span id={"labelId"} className={classes.labelText}>{label}{tryGetRequiredAsterix()}</span>
      <span id={"tooltipTextId"}className={classes.tooltipText}>{tooltip}</span>
    </span>
  
  function getLabel() {
    const labelText = getActiveLabel();
    
    if (!tooltip) {
      return labelWithoutTooltip(labelText);
    }
    
    const tooltipText = getActiveTooltip();
    return labelWithTooltip(labelText, tooltipText);
  }
  
  const Switch = 
    <MuiSwitch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      onChange={() => setValue(!value)}
      checked={value}
      disabled={disabled ?? false}
    />
  
  return (
    <FormControlLabel
      classes={{
        label: classes.label
      }}
      value={value}
      control={Switch}
      labelPlacement="end"
      label={getLabel()}
    />
    
  );
}