import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BroadcastIcon = (props: any) => {
    return (
        <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M21 11.5C21 10.7812 20.5938 10.125 20 9.78125V5.03125C20 4.75 19.7812 4 19 4C18.75 4 18.5312 4.09375 18.375 4.25L15.6875 6.375C14.375 7.4375 12.6875 8 11 8H5C3.875 8 3 8.90625 3 10V13C3 14.125 3.875 15 5 15H6.03125C6 15.3438 5.96875 15.6875 5.96875 16C5.96875 17.25 6.25 18.4375 6.78125 19.4688C6.9375 19.8125 7.28125 20 7.65625 20H9.96875C10.7812 20 11.2812 19.0938 10.7812 18.4375C10.2812 17.75 9.96875 16.9375 9.96875 16C9.96875 15.6562 10.0312 15.3438 10.0938 15H11C12.6875 15 14.375 15.5938 15.6875 16.6562L18.375 18.7812C18.5 18.9062 18.7812 19 18.9688 19C19.75 19 19.9688 18.3125 19.9688 18V13.25C20.5938 12.9062 21 12.25 21 11.5ZM18 15.9375L16.9375 15.0938C15.25 13.75 13.1562 13 11 13V10C13.1562 10 15.25 9.28125 16.9375 7.9375L18 7.09375V15.9375Z" fill="#FFC626"/>
        </SvgIcon>
    )
};

export default BroadcastIcon;
