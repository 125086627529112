import { SectionType } from "./enums/section-type";

export class SectionCreateRequest {
  establishmentAccountId?: string|null;
  type: SectionType;
  name: string;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.establishmentAccountId = x.establishmentAccountId;
      this.type = x.type;
      this.name = x.name;
    } else {
      this.establishmentAccountId = null;
      this.name = "";
      this.type = SectionType.ClinicHistory;
    }
  }
}