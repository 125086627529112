export enum SectionFieldType {
    //Additional validation
    PhoneNumber = 11,
    Email = 12,

    //Controls
    Radio = 21,
    Checkbox = 22,                  //NOT IN USE
    Dropdown = 23,                  //NOT IN USE

    //Basic
    Text = 31,
    Numeric = 32,                   //NOT IN USE
    Date = 33                       //NOT IN USE
}