import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

import Input from "./../../common/Input";
import { ArrowLineDown, PlusIcon, SaveIcon, EditIcon, TrashIcon } from "./../../../assets/icons";

import BlockedReason from './../../../models/blocked-reason';
import BlockedReasonService from "./../../../api/blocked-reason-service"

import { useAppDispatch } from "../../../redux/hooks";
import { alertsActions } from "../../../redux/alerts-slice";

import { BlockedReasonSelectProps } from "./props";
import useStyles from "./css";


const BlockedReasonSelect = (props: BlockedReasonSelectProps) => {

    const { t } = useTranslation(["general"]);
    const { widthSelect, widthList, isOptional, onSelect, placeholderInput, item } = props;
    const classes = useStyles();

    const dispatch = useAppDispatch();
    const { enqueueAlert } = alertsActions;

    const [list, setList] = useState<BlockedReason[]>([]);
    const [showNew, setShowNew] = useState<boolean>(false);
    const [reasonSelected, setReasonSelected] = useState<BlockedReason>();
    const [reasonNew, setReasonNew] = useState<BlockedReason>();

    const [openModal, setOpenModal] = useState<boolean>(false);

    const handleEditItem = (i: BlockedReason) => {
        setShowNew(false);
        const newList = list.map((item) => {
            if (item.id === i.id) {
                const updatedItem = {
                    ...item,
                    isEdit: !item.isEdit,
                };
                setReasonNew(item);
                return updatedItem;
            }
            else {
                const updatedItem = {
                    ...item,
                    isEdit: false,
                };

                return updatedItem;
            }
        });

        setList(newList);
    }

    const itemSelected = (i: BlockedReason) => {
        setReasonSelected(i);
        onSelect(i);
        setOpenModal(false);
    }


    useEffect(() => {
        (async () => {
            await loadData();
        })();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    const loadData = async () => {
        const data = await BlockedReasonService.getBlockedReason();

        if (data.entity) {

            let reasonList: BlockedReason[] = [];
            const reasons = data.entity;
    
            reasons.forEach((r) => {
                reasonList.push(new BlockedReason ({
                    id:  r.id,
                    name: r.isPredetermined ? t(r.name) : r.name,
                    isPredetermined: r.isPredetermined,
                    isEdit: false
                }));

            });

            setList(reasonList);

            if (item && item.id) {
                let reason = new BlockedReason({
                  id: item.id,
                  name: item.isPredetermined ? t(item.name) : item.name,
                  isPredetermined: item.isPredetermined,
                  isEdit: false
                });
                setReasonSelected(reason);
            }
        }
    };

    const handleReasonNew = () => {
        setReasonNew(new BlockedReason());
        handleEditItem(new BlockedReason());
        setShowNew(true);
    }

    const changeReasonNew = (event: React.ChangeEvent<{ name: string, value: string }>) => {
        let value = event.target.value;
        let newCat = new BlockedReason({ name: value });
        setReasonNew(newCat);
    }

    const changeReasonEdit = (i: BlockedReason) => (event: React.ChangeEvent<{ name: string, value: string }>) => {
        setReasonNew(i);
        let value = event.target.value;

        setReasonNew({ ...i, name: value });
    }

    const saveReasonEdit = async (i: BlockedReason) => {
        if (reasonNew && reasonNew?.name !== "") {
            const data = await BlockedReasonService.updateBlockedReason(reasonNew);
            if (data) {
                setReasonNew(new BlockedReason());
                await loadData();
            }
        }
    }

    const saveBlockedReasonNew = async () => {
        if (reasonNew && reasonNew?.name !== "") {
            const data = await BlockedReasonService.createBlockedReason(reasonNew);
            if (data) {
                setShowNew(false);
                setReasonNew(new BlockedReason());
                await loadData();
            }
        }
    }

    const handleDelete = async (i: BlockedReason) => {
        if (i) {

            const data = await BlockedReasonService.deleteBlockedReason(i);
            if (data.forbiddenDelete) {
                // it wasn't deleted
                dispatch(
                  enqueueAlert({
                      type: "Error",
                      title: t("It is not possible to delete a reason linked to a block schedule"),
                      description: ""
                  })
                );
            }
            else if (data.entity) {
                dispatch(
                  enqueueAlert({
                      type: "Success",
                      title: t("Reason successfully removed"),
                      description: ""
                  })
                );
                await loadData();
            }
        }
    }


    const handleOpenModal = () => {
        setReasonNew(new BlockedReason());
        handleEditItem(new BlockedReason());
        setShowNew(false);
        setOpenModal(true);
    }


    const handleCloseModal = () => {
        setOpenModal(false);
        itemSelected(new BlockedReason());
    }

    const isValid = () => {
        return isOptional ? isOptional : !reasonSelected || reasonSelected?.id !== null
    }

    return (<div className={classes.component}>

        <div className={classes.divDropdown} style={{ width: widthSelect || 350}}  >

            <label className={classes.labelCategory}>
                <Typography className={classes.labelCategory}>

                    {t("Reason")}{" "}

                    {(isOptional ?? true) ?
                        <span className={classes.optional}>({t("Optional")})</span>
                        :
                        <span className={classes.required}>*</span>
                    }

                </Typography >
            </label>

            <div onClick={handleOpenModal}>
                <Input
                    id={"itemSelect"}
                    name={"reasonSelected"}
                    value={reasonSelected?.name || ""}
                    placeholder={placeholderInput || t("Select an option")}
                    width={widthSelect || 350}
                    inputClass={classes.inputSelectBtn}
                    isValid={isValid}
                />
            </div>
            <div className={classes.iconDropdown} onClick={handleOpenModal}>
                <ArrowLineDown />
            </div>

        </div>

        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
            disablePortal={true}
            style={{ position: "relative", top: -20 }} >

            <div className={classes.divModalSelect} style={{ width: widthList || 350}}>

                <div className={classes.divTitle}>
                    {t("Default Reasons")}
                </div>

                <div className={classes.listCategoryScroll} >

                    {list.map((item) => {
                        return <div key={item.id} className={classes.listCategory}>

                            {!item.isEdit &&
                                <div className={`${classes.elementList} ${classes.classRow}`}>
                                    <div className={classes.name} onClick={() => itemSelected(item)}>
                                        {item.name}
                                    </div>
                                    {item.id !== reasonSelected?.id ?
                                        <div className={classes.divIconsRow}>
                                            <button className={classes.btnRow} onClick={(e) => {
                                                e.stopPropagation();
                                                handleEditItem(item);
                                            }}>
                                                <EditIcon className={classes.editCategory}/>
                                            </button>
                                            <button className={classes.btnRow} onClick={(e) => {
                                                e.stopPropagation();
                                                handleDelete(item);
                                            }}>
                                                <TrashIcon className={classes.deleteCategory}
                                                           style={{color: "#919CA5"}}/>
                                            </button>
                                        </div>
                                      : null
                                    }
                                </div>
                            }
                            {item.isEdit &&
                                <div className={classes.divItemEdit} >
                                    <div className={classes.divInputEdit} >
                                        <Input
                                            id={`inputEdit${reasonNew?.id}`}
                                            name={"reasonEdit"}
                                            value={reasonNew?.name}
                                            width={widthList || 300}
                                            maxLength={50}
                                            placeholder={t("Write new reason")}
                                            inputClass={classes.input}
                                            isValid={() => reasonNew?.name != "" }
                                            autoFocus={true}
                                            onChange={changeReasonEdit(item)}
                                        />
                                    </div>
                                    <button className={classes.iconSave} onClick={() => saveReasonEdit(item)}>
                                        <SaveIcon />
                                    </button>
                                </div>
                            }
                        </div >
                    })}
                </div >

                <div className={classes.divNew} >
                    {!showNew &&
                        <div className={classes.btnInputNew}>
                            <Button className={`${classes.button} ${classes.btnNewBlockedReason}`} onClick={handleReasonNew} >
                                {t('New reason')}
                            </Button>
                        </div>
                    }
                    {showNew &&
                        <div className={classes.btnInputNew}>
                            <div  >
                                <Input
                                    id={"reason"}
                                    name={"reason"}
                                    value={reasonNew?.name}
                                    width={widthList || 300}
                                    maxLength={50}
                                    placeholder={t("Write new reason")}
                                    inputClass={classes.input}
                                    isValid={() => reasonNew?.name != ""}
                                    autoFocus={true}
                                    onChange={changeReasonNew}
                                />
                            </div>
                            <button className={classes.iconPlus} onClick={saveBlockedReasonNew}>
                                <PlusIcon style={{ color: "#6462F3", height: 15, width: 15 }} />
                            </button>
                        </div>
                    }
                </div>

            </div>
        </Modal>
    </div>);
};

export default BlockedReasonSelect;
