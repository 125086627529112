import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #dbdcdd",
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        width: 720,
        height: 210,
    },
    modalHeader: {
        display: "flex",
        alignItems: "center",
        padding: "26px 40px 22px 40px"
    },
    modalTitle: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 5,
        width: "100%",
        "& svg path": {
            fill: theme.colors.grey5
        }
    },
    modalTitleText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray5
    },
    modalActions: {
        display: "flex",
        justifyContent: "flex-end",
        gap: 16
    },
    goBack: {
        background: "#F6F6F6",
        color: "#ACB7C0",
    },
    continueButton: {
        background: "#36CE91",
        color: "#F6F6F6",
        "&:hover": {
            background: "#36CE91",
        },
    },
    createDisabled: {
        color: "#F6F6F6 !important",
        opacity: "0.4",
    },
    button: {
        width: 200,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
    },
    content: {
        backgroundColor: "#FAFAFA",
        height: "100%",
        padding: "33px 40px",
        borderRadius: "10px",
    },
    requiredAsterisk: {
        color: Color.clear1,
        fontWeight: "bold",
        fontSize: 14,
    },
    closeConfirmationModal: {
        height: "234px + !important"
    },
    closeModalContentContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
    },
    closeModalTitleText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#26292B",
        textAlign: "center",
        maxWidth: "80%"
    },
    closeModalContentText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray5
    },
    separator: {
        height: 0,
        borderTop: "1px solid rgba(201, 205, 211, 0.38)",
        margin: "16px 0px 16px 0px",
        width: 217
    },
}));

export default useStyles;