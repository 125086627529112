import clsx from 'clsx';
import React, { useContext, useEffect, useRef } from 'react';
import { AppointmentSchedulerInnerElementContext, KeyGetterFunction } from '../_util';

import styles from './css.module.css';

export type HeaderCellComponentProps<G> = {
    value: G;
};
export type HeaderCellComponent<G> = React.ComponentType<HeaderCellComponentProps<G>>;

export type AppointmentSchedulerHeaderProps<G> = {
    groups: G[];
    getGroupKey: KeyGetterFunction<G>;
    component: HeaderCellComponent<G>;
    CornerComponent?: React.ComponentType<{}>;
    classNameCorner?: string;
    classNameInner?: string;
    classNameRoot?: string;
    classNameCell?: string;
};

export default function AppointmentSchedulerHeader<G>({
    groups,
    classNameCorner,
    CornerComponent,
    classNameInner,
    classNameRoot,
    getGroupKey,
    classNameCell,
    component: Component,
}: AppointmentSchedulerHeaderProps<G>) {
    const { element: innerElement } = useContext(AppointmentSchedulerInnerElementContext);
    const headerRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        if (!innerElement) return;

        const callback = () => {
            if (!headerRef.current) return;

            headerRef.current.scroll(innerElement.scrollLeft, 0);
        };

        innerElement.addEventListener('scroll', callback);
        return () => innerElement.removeEventListener('scroll', callback);
    }, [innerElement]);

    return (
        <header ref={headerRef} className={clsx(styles.header, classNameRoot)}>
            <div className={clsx(styles.corner, classNameCorner)}>
                {CornerComponent && <CornerComponent />}
            </div>
            <div className={clsx(styles.inner, classNameInner)}>
                {groups.map((g) => {
                    return (
                        <div key={getGroupKey(g)} className={clsx(styles.cell, classNameCell)}>
                            <Component value={g} />
                        </div>
                    );
                })}
            </div>
        </header>
    );
}
