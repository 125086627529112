import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  requiredFieldsNote: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey3,
    marginTop: 13
  },
  label: {
    ...theme.typography.txtBody2,
    fontFamily: "Inter, sans-serif",
    display: "inline-flex",
    margin: 0,
    marginBottom: 5
  },
  dot: {
    ...theme.typography.txtBody2,
    color: theme.colors.clearMain,
    marginLeft: 5
  },
  inputRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 24,
    width: 706
  },
  inputContainer: {
    width: 344
  },
  note: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey3,
    marginTop: 24,
  },
  divider: {
    backgroundColor: "rgba(201, 205, 211, 0.5)"
  },
  switchBox: {
    display: "flex",
    gap: "29px",
    marginTop: 24,
    marginLeft: 11
  },
  phoneLabel: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    fontFamily: "Inter, sans-serif",
    display: "inline-flex",
    marginBottom: 6,
  },
})
);

export default useStyles;