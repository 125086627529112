import React from 'react';
import { Redirect, Route, Switch, BrowserRouter } from "react-router-dom";

import { Url } from "../../../constants/url";
import SettingsNavTabs from "../SettingsNavTabs";
import UsersMainPage from "../Sections/Users/UsersMainPage";
import CustomerCommunications from "../Sections/CustomerCommunications/CustomerCommunicationsMain";
import Workareas from "../Sections/WorkArea/WorkAreaMain";
import General from "../Sections/General/GeneralMain";
import PaymentMain from "../Sections/Payments/PaymentMain";
import Services from "../Sections/Services/ServiceMain";
import ClinicHistory from '../Sections/FormsSettingsTabs/ClinicHistory';
import EvolutionNotes from '../Sections/FormsSettingsTabs/EvolutionNotes';
import PatientForm from '../Sections/FormsSettingsTabs/PatientForm';
import Commissions from '../Sections/Commissions/CommissionsMain';
import { useAppSelector } from '../../../redux/hooks';
import { selectUserInfo } from '../../../redux/store';

export default function SettingsRouter() {

  const userInfo = useAppSelector(selectUserInfo);

  return (
    <>
      {
        userInfo.isAdministrator ?
          <BrowserRouter>
            <SettingsNavTabs />
            <Switch>
              <Route exact path={Url.Settings.Main} component={General} />
              <Route exact path={Url.Settings.CustomerCommunications} component={CustomerCommunications} />
              <Route exact path={Url.Settings.WorkAreas} component={Workareas} />
              <Route exact path={Url.Settings.Services} component={Services} />
              <Route exact path={Url.Settings.Users} component={UsersMainPage} />
              <Route exact path={Url.Settings.Payments} component={PaymentMain} />
              <Route exact path={Url.Settings.ClinicHistory} component={ClinicHistory} />
              <Route exact path={Url.Settings.EvolutionNotes} component={EvolutionNotes} />
              <Route exact path={Url.Settings.PatientForm} component={PatientForm} />
              <Route exact path={Url.Settings.Commissions} component={Commissions} />
              <Route
                exact
                path={Url.Settings.Users + "/:userId"}
                component={UsersMainPage}
              />
              <Route path={Url.Settings.Main}>
                <Redirect to={Url.Settings.Main} />
              </Route>
            </Switch>
          </BrowserRouter>
          :
          <Redirect to={Url.Appointments.Main} />
      }
    </>
  );
}
