import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ImportIcon = () => (
  <SvgIcon>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.5 2c-4.416 0-8 3.584-8 8a7.993 7.993 0 0 0 3.464 6.59l1.154-1.154a6.428 6.428 0 0 1-2.642-3.292c.84-.896 2.936-1.352 4.112-1.352.424 0 .96.064 1.52.176-1.312.696-1.52 1.616-1.52 2.144v.854l.53-.53a1.49 1.49 0 0 1 .754-.403c.105-1.083 2.375-1.625 3.516-1.625.856 0 2.336.312 3.072.92a6.46 6.46 0 0 1-2.97 3.328l1.174 1.175A7.998 7.998 0 0 0 20.5 10c0-4.416-3.584-8-8-8zm3.237 15.317.982.983c.355.355.11.93-.383.93h-2.379v2.914c0 .382-.3.656-.656.656h-2.188a.632.632 0 0 1-.656-.656V19.23H8.051a.539.539 0 0 1-.383-.93l1.18-1.18 1.203-1.203.537-.537 1.236-1.236a.546.546 0 0 1 .739 0l1.936 1.936 1.238 1.238zm-.349-10.245a1.54 1.54 0 0 1 1.544 1.544 1.54 1.54 0 0 1-1.544 1.544 1.54 1.54 0 0 1-1.544-1.544 1.535 1.535 0 0 1 1.544-1.544zm-4.8-1.264c1.04 0 1.888.848 1.888 1.888s-.848 1.888-1.888 1.888A1.891 1.891 0 0 1 8.7 7.696c0-1.048.84-1.888 1.888-1.888z"
      fill="#6462F3"
    />
  </SvgIcon>
);

export default ImportIcon;
