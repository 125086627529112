import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enGeneral from './assets/locales/en/general.json';
import esGeneral from './assets/locales/es/general.json';
import enNav from './assets/locales/en/nav.json';
import esNav from './assets/locales/es/nav.json';
import enClinicCustomer from './assets/locales/en/clinic_terminology_customer.json';
import esClinicCustomer from './assets/locales/es/clinic_terminology_customer.json';
import enMassiveSendings from './assets/locales/en/massivesendings.json';
import esMassiveSendings from './assets/locales/es/massivesendings.json';
import enClinicPatient from './assets/locales/en/clinic_terminology_patient.json';
import esClinicPatient from './assets/locales/es/clinic_terminology_patient.json';
import enSections from './assets/locales/en/sections.json';
import esSections from './assets/locales/es/sections.json';
import enSettings from './assets/locales/en/settings.json';
import esSettings from './assets/locales/es/settings.json';
import enTerminologies from './assets/locales/en/terminologies.json';
import esTerminologies from './assets/locales/es/terminologies.json';

i18n
  .use(initReactI18next)
  .init({
    lng: process.env.REACT_APP_DEFAULT_LANGUAGE ?? 'es',
    fallbackLng: process.env.REACT_APP_FALLBACK_LANGUAGE ?? 'en', // use en if detected lng is not available
    resources: {},
    react: {
      defaultTransParent: 'div', // a valid react element - required before react 16
      transEmptyNodeValue: '', // what to return for empty Trans
      transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
      transKeepBasicHtmlNodesFor: ['br', 'strong', 'i'], // don't convert to <1></1> if simple react elements
      transWrapTextNodes: ''
    }
  });

// General
i18n.addResourceBundle('en', 'general', enGeneral, true, true);
i18n.addResourceBundle('es', 'general', esGeneral, true, true);

// Navbar
i18n.addResourceBundle('en', 'nav', enNav, true, true);
i18n.addResourceBundle('es', 'nav', esNav, true, true);

// Clinic Terminology Customer
i18n.addResourceBundle('en', 'clinic_terminology_customer', enClinicCustomer, true, true);
i18n.addResourceBundle('es', 'clinic_terminology_customer', esClinicCustomer, true, true);

// Massive Sendings
i18n.addResourceBundle('en', 'massivesendings', enMassiveSendings, true, true);
i18n.addResourceBundle('es', 'massivesendings', esMassiveSendings, true, true);

// Clinic Terminology Patient
i18n.addResourceBundle('en', 'clinic_terminology_patient', enClinicPatient, true, true);
i18n.addResourceBundle('es', 'clinic_terminology_patient', esClinicPatient, true, true);

// Sections
i18n.addResourceBundle('en', 'sections', enSections, true, true);
i18n.addResourceBundle('es', 'sections', esSections, true, true);

// Settings
i18n.addResourceBundle('en', 'settings', enSettings, true, true);
i18n.addResourceBundle('es', 'settings', esSettings, true, true);

// Terminologies
i18n.addResourceBundle('en', 'terminologies', enTerminologies, true, true);
i18n.addResourceBundle('es', 'terminologies', esTerminologies, true, true);

export default i18n;