import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    
    height: "100%",
    position: "relative",
    width: 400,
    padding: 16,
    paddingTop: 0
  },
  drawer: {
    background: "#FFFFFF",
    borderTopLeftRadius: 10,
    borderBottomLeftRadius: 10,

    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
  },
  newHeader: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-start",
    
    height: 67,
    width: "100%",
    
    paddingTop: 16,
    paddingBottom: 16,
    
    position: "sticky",
  },
  tabs: {
    ...theme.typography.txtBody1,
    backgroundColor: "#FFFFFF",
    color: theme.colors.clearMain,
    
    borderBottom: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "rgba(201, 205, 211, 0.5)",
    
    "& .MuiTabs-indicator": {
      backgroundColor: "#FFFFFF"
    },
    "& .MuiButtonBase-root": {
      minWidth: 0,
      width: "50%",
    },
    "& .MuiTab-root": {
      paddingLeft: 0,
      paddingRight: 0,
      textTransform: "none",
      color: theme.colors.grey1,
      backgroundColor: theme.colors.clearMain,
      fontWeight: "bold",
    },
    "& .Mui-selected": {
      color: theme.colors.clearMain,
      backgroundColor: "#FFFFFF",
      fontWeight: "bold",
    },
  },
  tab: {
    root: {
      ...theme.typography.txtBody2,
    },
    selected: {
      fontWeight: "bold",
    }
  },
  tabPanelRoot: {
    padding: "0 !important",
    display: "flex",
    flexDirection: "column",
    height: "100%"
  },
  headerTitle: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    fontWeight: 700
  },
  headerChargingInfo: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
  },
  headerChargingUserName: {
    paddingLeft: 4,
    fontWeight: 700
  },
  headerChargingPaymentId: {
    paddingLeft: 4,
    fontWeight: 700
  },
  closeIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    width: 16,
    height: 16,
    
    position: "absolute",
    top: 16,
    right: 16,
    
    cursor: "pointer",
    color: theme.colors.grey4,
  },
  divider: {
    width: 368,
    height: 0,
    borderTop: "1px solid",
    borderColor: theme.colors.grey2,
    opacity: 0.5,
    marginTop: 5,
    marginBottom: 15
  },
  marginTopBottom: {
    marginTop: 24,
    marginBottom: 24
  },
  content: {
    overflowY: "scroll",
  },
  paymentProgressBar: {
    display: "flex",
    flexDirection: "column",
    
    marginTop: 24,
    marginBottom: 24,
  },
  paymentProgressBarLegend: {
    display: "flex",
    justifyContent: "space-between",
    
    marginTop: 8,
  },
  paymentProgressBarLegendItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  paymentProgressBarLegendIcon: {
    width: 5,
    height: 5,
    
    margin: 4,
    
    borderRadius: "50%"
  },
  paymentProgressBarLegendText: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5
  },
  paidIcon: {
    backgroundColor: theme.colors.clearGreen
  },
  unpaidIcon: {
    backgroundColor: theme.colors.clearMain
  },
  progressBarPaid: {
    borderRadius: 5,
    
    backgroundColor: theme.colors.clearGreen
  },
  progressBarUnpaid: {
    backgroundColor: theme.colors.clearMain
  },
  inputWithLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    marginTop: 16,
  },
  label: {
    ...theme.typography.buttons,
    fontWeight: "bold",
    display: "inline-flex",
    color: theme.colors.grey4,
    margin: 0,
    marginBottom: 8
  },
  controlLabel: {
    ...theme.typography.buttons,
    fontWeight: "bold",
    display: "inline-flex",
    color: theme.colors.grey4,
    marginBottom: 8
  },
  controlText: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey5,
    "&::placeholder": {
      fontWeight: "normal",
      color: "#676767",
    },
  },
  dot: {
    ...theme.typography.buttons,
    color: theme.colors.clearMain,
    marginLeft: 5
  },
  textArea: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    fontWeight: "bold",
    "&::placeholder": {
      ...theme.typography.txtBody2,
      fontWeight: "normal",
      opacity: 0.8,
    },
  },
  servicesDetail: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap"
  },
  servicesDetailRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: 368,
    
    marginBottom: 8,
  },
  servicesDetailColumn: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    "&:first-child": {
      width: 220
    }
  },
  servicesDetailServiceBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start"
  },
  servicesDetailDivider: {
    width: 368,
    height: 0,
    border: "1px solid",
    borderColor: theme.colors.grey1,
    marginBottom: 8,
  },
  servicesDetailHeader: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey4,
    marginBottom: 8
  },
  servicesDetailSubheader: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4
  },
  servicesDetailText: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5
  },
  servicesDetailValue: {
    ...theme.typography.txtBody1,
    fontWeight: 700,
    color: theme.colors.grey5
  },
  servicesDetailDiscount: {
    ...theme.typography.txtBody2,
    color: theme.colors.clearMain,
  },
  servicesDetailTotal: {
    ...theme.typography.txtBody2,
    color: theme.colors.clearGreen,
    marginTop: 8,
  },
  servicesDetailPaidWith: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    paddingLeft: 15
  },
  mainColorText: {
    color: theme.colors.clearMain,
  },
  greenText: {
    color: theme.colors.clearGreen
  },
  changeRecords: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    marginTop: 16
  },
  changeRecordBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  changeRecordRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 8
  },
  changeRecordLeftColumn: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    minWidth: 115,

    ...theme.typography.txtBody2,
    color: theme.colors.grey4
  },
  historyRightColumn: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    alignSelf: "center",

    ...theme.typography.txtBody2,
    color: theme.colors.grey5
  },
  historyRightColumnStrike: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",

    ...theme.typography.txtBody2,
    color: theme.colors.grey4
  },
  initialValuesBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  initialValuesRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginBottom: 8
  },
  initialValuesLeftColumn: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    maxWidth: 120,
    minWidth: 115,

    ...theme.typography.txtBody2,
    color: theme.colors.grey4
  },
  changeRecordDivider: {
    width: 368,
    height: 0,
    border: "1px solid",
    borderColor: theme.colors.grey2,
    opacity: 0.5,
    marginTop: 16,
    marginBottom: 16,
  },
  footer: {
    height: "auto",
    width: 368,
    
    position: "sticky",
    bottom: 0,
    paddingBottom: 16,
    backgroundColor: "#FFFFFF", 
    marginTop: "auto"
  },
  registerPaymentButton: {
    width: "100%",
    height: 32,
    
    backgroundColor: theme.colors.clearMain,
    borderRadius: 51,
    
    ...theme.typography.buttons,
    color: theme.colors.grey1,
    fontWeight: 700,
    
    "&:hover": {
      backgroundColor: theme.colors.clearMain,
      color: theme.colors.grey1,
    },
    "&:disabled": {
      backgroundColor: theme.colors.clearMain,
      opacity: 0.3,
      color: theme.colors.grey1,
    }
  },
  
  chip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    padding: "4px 8px",
    backgroundColor: "rgba(100, 98, 243, 0.2)",
    borderRadius: 5,
    marginBottom: 12,
    
    ...theme.typography.txtBody2,
    fontWeight: 700,
    color: theme.colors.clearMain
  },
  
  accordionSummary: {
    backgroundColor: "#f6f6f6",
    width: "100%"
  },
  accordionTitle: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    color: theme.colors.grey5,
    position: "relative",
    top: 5,
    left: 10
  },
  itemRoot: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    height: 40,
    fontSize: 12,
    display: "flex",
    alignItems: "center"
  },
  })
);

export default useStyles;