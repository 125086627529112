import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%"
  },
  formHeader: {
    marginBottom: "27px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#4A4D51"
  },
  formText: {
    marginTop: "27px",
    marginBottom: "48px",
    
    fontFamily: "Roboto", 
    fontStyle: "normal", 
    fontWeight: "normal", 
    fontSize: "14px", 
    lineHeight: "18px",
    alignItems: "center", 
    textAlign: "center", 
    color: "#6A6E72"
  },
  formTextLine: {
    ...theme.typography.txtBody1
  },
  formTextLineBold: {
    fontWeight: "bold"
  },
  sendButton: {
    marginTop: 22,
    width: "100%",
    borderRadius: 51,
    padding: "9px 16px"
  }
}));

export default useStyles;