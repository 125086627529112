import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import { TrashIcon, CrossIcon } from "./../../../../../assets/icons";

import useStyles from "./css";
import { ServiceDeleteModalProps } from "./props";

const ServiceDeleteModal = (props: ServiceDeleteModalProps) => {

    const { t } = useTranslation(["general"]);
    const { open, item, onClose, onDelete } = props;
    const classes = useStyles();

    const handleDelete = () => {
        if (typeof onDelete === "function") onDelete(item);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={open}>
                <div className={classes.modalPaper}>
                    <div className={classes.modalHeader}>
                        <div className={classes.modalHeaderContent}>
                            <div className={classes.modalCircle}>
                                <TrashIcon />
                            </div>
                        </div>
                        <div className={classes.modalClose}>
                            <span className={classes.closeButton} onClick={onClose}>
                                <CrossIcon />
                            </span>
                        </div>
                    </div>

                    <div className={classes.modalContent}>
                        <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`} >
                            <Typography className={classes.modalTitleText}>
                                {t("Delete this service?")}
                            </Typography>
                        </div>

                        <div className={classes.modalCenter}>
                            <div className={classes.separator} style={{ width: 217 }}></div>
                        </div>

                        <div className={classes.modalCenter}>
                            <Typography className={classes.modalContentTextTitle}>
                                {item?.name}
                            </Typography>
                        </div>

                        <div className={classes.modalActions}>
                            <Button className={`${classes.button} ${classes.goBack}`} onClick={onClose} >
                                {t("Go back")}
                            </Button>

                            <Button className={`${classes.button} ${classes.deleteBtn}`} onClick={handleDelete} >
                                {t("Delete")}
                            </Button>
                        </div>

                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default ServiceDeleteModal;
