import React from 'react';

import { useTranslation } from "react-i18next";

import { Modal, Typography, Button  } from '@material-ui/core';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { CrossIcon, ExclamationIcon } from "../../../../assets/icons";
import { SaveSignatureModalProps } from "./props";
import useStyles from "./css";


export default function SaveSignatureModal(props: SaveSignatureModalProps) {
  
  const { open, onConfirm, onClose } = props;
  
  const { t } = useTranslation(["general"]);
  const classes = useStyles();
  
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <div className={classes.modalHeader}>
            <div className={classes.modalHeaderContent}>
              <div className={classes.modalCircle}>
                <ExclamationIcon viewBox="0 0 5 16" />
              </div>
            </div>
            <div className={classes.modalClose}>
              <span className={classes.closeButton} onClick={onClose}>
                <CrossIcon />
              </span>
            </div>
          </div>
          <div className={classes.modalContent}>
            <div
              className={classes.modalTitleSpace}
            >
              <Typography className={classes.modalTitleText}>
                {t("Confirm that you want to save your signature")}
              </Typography>
            </div>
            <div className={classes.separator} />
            <div className={classes.modalMain}>
              <Typography className={classes.modalContentText}>
                {t("The signature cannot be modified after saving.")}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={onClose}
              >
                {t("Go back")}
              </Button>
              <Button
                className={`${classes.button} ${classes.editAppointment}`}
                onClick={() => {
                  if (onConfirm) {
                    onConfirm();
                  }
                }}
              >
                {t("Yes, save signature")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}