import { SelectWorkAreaModel } from "./../models/select-work-area-model";
import { ApiClient } from "./api-client";
import Workarea from "../models/workarea";
import { Response } from "./../models/response";

export default class WorkareasService {
  private static baseUrl: string = "/workarea";

  static createWorkarea(w: Workarea): Promise<Workarea> {
    return ApiClient.post(`${this.baseUrl}/createworkarea`, w).then((data) => {
      return new Workarea(data.workarea);
    });
  }

  static updateWorkarea(w: Workarea): Promise<Workarea> {
    return ApiClient.put(`${this.baseUrl}/updateworkarea`, w).then((data) => {
      return new Workarea(data.workarea);
    });
  }

  static getWorkareas(
    page: number,
    pageSize: number,
    establishmentAccountId?: string,
    order?: string,
    orderBy?: string
  ): Promise<Response> {
    let urlEndpoint = `${this.baseUrl}/getworkareas?page=${page}&pageSize=${pageSize}`;

    if (order && orderBy) {
      urlEndpoint += `&order=${order}&orderBy=${orderBy}`;
    }
    if (establishmentAccountId) {
      urlEndpoint += `&accountId=${establishmentAccountId}`;
    }
    return ApiClient.get(urlEndpoint).then((data) => {
      return new Response(data.workareas, data.total);
    });
  }

  static deleteWorkarea(w: Workarea): Promise<any> {
    return ApiClient.remove(`${this.baseUrl}/deleteworkarea?id=${w.id}`).then((data) => {
      return {
        entity: new Workarea(data.Workarea),
        forbiddenDelete: data.forbiddenDelete,
      };
    });
  }

  static getWorkAreasSelect(): Promise<SelectWorkAreaModel[]> {
    let urlEndpoint = `${this.baseUrl}/getworkareatoappt`;

    return ApiClient.get(urlEndpoint).then((data) => {
      return data.workareas.map(
        (workArea: any) =>
          new SelectWorkAreaModel({ id: workArea.id, workArea: workArea.value })
      );
    });
  }
}
