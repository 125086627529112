export class FormFieldItemValue {
  fieldItemId: string;
  value: string;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.fieldItemId = x.fieldItemId;
      this.value = x.value;
    }
    else {
      this.fieldItemId = "";
      this.value = "";
    }
  }
}