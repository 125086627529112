import * as React from "react";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";

import useStyles from "./css";
import { navbarActions } from "../../../redux/navbar-slice";

import Select from "../../common/Select";
import { LocalStorageKey } from "../../../constants/local-storage-key";
import useLocalStorageState from "../../../hooks/useLocalStorageState";
import { enterpriseActions } from "../../../redux/enterprise-slice";
import { useTranslation } from "react-i18next";

export default function SettingsEstablishmentSelector() {
  
  const classes = useStyles();
  const dispatch = useAppDispatch();

  const { t } = useTranslation(["general"]);

  const userInfo = useAppSelector(state => state.userInfo)
  const accountId = userInfo.accountId;
  const accountSettings = useAppSelector((state) => state.accountSettings);

  const settingsEstablishmentStorageKey = LocalStorageKey.SettingsEstablishment + accountId;
  const { setSettingsEstablishmentId } = enterpriseActions;
  // @ts-ignore
  const [currentEstablishmentId, setCurrentEstablishmentId] = useLocalStorageState({ itemKey: settingsEstablishmentStorageKey, dispatchFunction: setSettingsEstablishmentId });

  const enterpriseWithEstablishments = [
      ...(accountId
          ? [{ 
            key: accountId,
            value: `${accountSettings.accountName} (${t("Enterprise account")})`
          }]
          : []),
     ...accountSettings.establishments
  ];

  const handleSelectEstablishment = (event: any) => {
      if (event.target == accountId)
          setCurrentEstablishmentId(event.target);
      else
          setCurrentEstablishmentId(event.target.value);
  }

  useEffect(() => {
      if (!currentEstablishmentId && accountSettings?.isEnterprise !== false) {
          if (accountSettings?.establishments?.length) {
                setCurrentEstablishmentId(accountId)
          } else {
                setCurrentEstablishmentId(null);
          }
      }
  },[accountSettings.establishments, userInfo.accountId]);

   useEffect(() => {
      const externalContent = (
         accountSettings.isEnterprise ? (
            <div className={classes.container}>
               <Select
                items={enterpriseWithEstablishments}
                value={currentEstablishmentId}
                classes={{ root: `${classes.focused} ${classes.selected}` }}
                styleClass={classes.select}
                onChange={handleSelectEstablishment}
               />
            </div>
         ) : null
      );

      dispatch(navbarActions.setExternalContent(externalContent));
   },[accountSettings.establishments, currentEstablishmentId]);

  return null;
}
