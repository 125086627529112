import { makeStyles } from "@material-ui/core";

const useStyles = (height: number | string | undefined, width: number | undefined) =>
  makeStyles((theme) => ({
    container: {
      height: height || 605,
      width: width,
    },
    tableContainer: {
      border: "1px solid #DBDCDD",
      boxSizing: "border-box",
      borderRadius: "12px 12px 12px 12px",
      height: height || 605,
      width: width,
      overflow: "hidden",
    },
    tableHeader: {
      backgroundColor: "#F6F6F6",
    },
    popper:{
      [`& .MuiTooltip-tooltip`]: {
        padding: "4px 8px",
        borderRadius: "5px",
        backgroundColor: theme.colors.grey1,
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        color: theme.colors.grey5,
        boxShadow: "0px 0px 4px #00000034"
      }
    },
    tableBody: {
      overflowY: "scroll",
      height: height || 605,
      width: width,
      "&::-webkit-scrollbar": {
        display: "none",
      },
      scrollbarWidth: "none",
      "-ms-overflow-style": "none",
    },
    rowText: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey5,
    },
  }));

export default useStyles;
