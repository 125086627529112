import React from "react";

import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";

import { BillsIcon } from "../../../assets/icons";

import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";

import { AppointmentPaymentPartsEditProps } from "./props";
import useStyles from "./css";
import AppointmentPaymentPart from "../AppointmentPaymentPart";
import { useAppSelector } from "../../../redux/hooks";
import { selectAccountSettings } from "../../../redux/store";


export default function AppointmentPaymentPartsEdit(props: AppointmentPaymentPartsEditProps) {
  
  const { part, onChange, editionType } = props;
  
  const accountSettings = useAppSelector(selectAccountSettings);
  const paymentMethods = accountSettings.paymentMethods;
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  
  return (
      <Accordion
        className={classes.accordion}
        expanded={true}
      >
        <AccordionSummary
          className={classes.accordionSummary}
        >
          <div style={{width: 25}}>
            <span>
              <BillsIcon />
            </span>
          </div>
          <Typography className={classes.accordionTitle}>
            {t("Payment method")}
          </Typography>
        </AccordionSummary>
        <AccordionDetails
          className={classes.accordionDetails}
        >
          <AppointmentPaymentPart
            paymentPart={part}
            onChange={onChange}
            editionType={editionType}
            paymentMethods={paymentMethods}
            excludedPaymentMethodTypes={[]}
          />
        </AccordionDetails>
      </Accordion>
  );
}