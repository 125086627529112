import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "calc(100vh - 61px)",
    minWidth: "800px",
    margin: "0 -24px",
    padding: "0 0px 0 5px",
    backgroundColor: "white"
  },
}));

export default useStyles;
