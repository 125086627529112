import React, { useState } from "react";

import Input from "./../Input";
import useStyles from "./css";
import { InputWithLabelProps } from "./props";

const InputWithLabel = (props: InputWithLabelProps) => {
  const {
    id,
    label,
    labelClass,
    inputClass,
    name,
    value,
    isValid,
    placeholder,
    width,
    onChange,
    onKeyPress,
    inputDisabled,
    inputReadOnly,
    onClickInput,
    onBlurInput,
    maxLength,
    showCounter,
    inputType
  } = props;
  const classes = useStyles();

  const getId = (): string => {
    if (id) {
      return id;
    }

    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c === "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };

  const LabelComponent = label;

  const [inputId] = useState<string>(id || getId());

  const handleOnChange = (event: any) => {
    if (onChange) {
      onChange(event);
    }
  };

  const handleOnKeyPress = (event: any) => {
    if (onKeyPress) {
      onKeyPress(event);
    }
  };

  return (
    <div className={classes.root}>
      <label htmlFor={inputId} className={labelClass ?? classes.label}>
        {typeof label === "string" ? label : <LabelComponent />}
      </label>
      <Input
        id={inputId}
        name={name}
        value={value}
        placeholder={placeholder}
        width={width}
        inputClass={inputClass}
        isValid={isValid}
        onChange={handleOnChange}
        onKeyPress={handleOnKeyPress}
        disabled={inputDisabled}
        readOnly={inputReadOnly}
        onClick={onClickInput}
        onBlur={onBlurInput}
        showCounter={showCounter}
        maxLength={maxLength}
        inputType={inputType}
      />
    </div>
  );
};

export default InputWithLabel;
