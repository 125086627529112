import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function SheetIcon (props: any) {

  const { style, viewBox, ...otherProps } = props;
  
  return (
  <SvgIcon
    {...otherProps}
    style={style || {width: 12, height: 13}}
    viewBox={viewBox || "0 0 12 13"}
  >
    <path
      d="M10.2861 12.33H1.713C1.27417 12.3426 0.847454 12.2053 0.526359 11.9483C0.205263 11.6913 0.0159833 11.3355 0 10.959L0 1.3703C0.0162139 0.993951 0.205626 0.638469 0.526723 0.381763C0.84782 0.125057 1.27441 -0.0119327 1.713 0.000816457H7.06837V3.5104C7.082 3.82141 7.23854 4.11514 7.50378 4.3274C7.76902 4.53965 8.12138 4.65316 8.48385 4.64313H12V10.9582C11.9843 11.335 11.7949 11.6911 11.4736 11.9483C11.1523 12.2055 10.7252 12.3428 10.2861 12.33ZM3.24568 9.03398C3.20132 9.03198 3.15694 9.03775 3.11526 9.05095C3.07358 9.06414 3.03547 9.08447 3.00324 9.11071C2.97102 9.13696 2.94535 9.16855 2.92782 9.20358C2.91028 9.23861 2.90124 9.27633 2.90124 9.31446C2.90124 9.35258 2.91028 9.3903 2.92782 9.42533C2.94535 9.46036 2.97102 9.49196 3.00324 9.5182C3.03547 9.54444 3.07358 9.56477 3.11526 9.57797C3.15694 9.59116 3.20132 9.59693 3.24568 9.59493H8.90218C8.94654 9.59693 8.99091 9.59116 9.03259 9.57797C9.07427 9.56477 9.11239 9.54444 9.14461 9.5182C9.17684 9.49196 9.2025 9.46036 9.22004 9.42533C9.23758 9.3903 9.24662 9.35258 9.24662 9.31446C9.24662 9.27633 9.23758 9.23861 9.22004 9.20358C9.2025 9.16855 9.17684 9.13696 9.14461 9.11071C9.11239 9.08447 9.07427 9.06414 9.03259 9.05095C8.99091 9.03775 8.94654 9.03198 8.90218 9.03398H3.24568ZM3.24568 7.35114C3.15888 7.35114 3.07564 7.38073 3.01426 7.43341C2.95289 7.48608 2.91841 7.55752 2.91841 7.632C2.91841 7.70649 2.95289 7.77793 3.01426 7.8306C3.07564 7.88327 3.15888 7.91286 3.24568 7.91286H8.90218C8.98898 7.91286 9.07222 7.88327 9.1336 7.8306C9.19497 7.77793 9.22945 7.70649 9.22945 7.632C9.22945 7.55752 9.19497 7.48608 9.1336 7.43341C9.07222 7.38073 8.98898 7.35114 8.90218 7.35114H3.24568ZM11.4744 4.07754H8.48385C8.39431 4.08085 8.30491 4.06882 8.22085 4.04214C8.1368 4.01546 8.05979 3.97467 7.99431 3.92216C7.92884 3.86964 7.87622 3.80646 7.83953 3.73629C7.80284 3.66612 7.78281 3.59038 7.78062 3.5135V0.610507L11.4717 4.07367L11.4744 4.07754Z"
      fill={style?.color || "#5C6477"}
    />
  </SvgIcon>
  );
}