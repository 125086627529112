import React, { useEffect, useState } from 'react';

import MuiSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputMUI from "@material-ui/core/Input";
import { Button } from "@material-ui/core";
import ArrowDropDownIcon from "./../../../assets/icons/ArrowDropDownIcon";

import useStyles from "./css";
import { SelectKeyProps } from "./props";
import { SelectItem } from "../../../models/interfaces/select-item"
import { SectionDeleteIcon, SectionEditIcon, SectionExitEditIcon, SectionSaveIcon } from "../../../assets/icons";
import InputCustom from "../Input";
import ConfirmDeleteModal from "../ConfirmDeleteModal";


const SelectKeyEditable = (props: SelectKeyProps) => {
  const {
    value,
    setValue,
    placeholder,
    name,
    items,
    width,
    dropDownWidth,
    itemHeight,
    onChange,
    disabled,
    styleClass,
    itemRootClass,
    deleteModal,
    textButtonConfirmDelete,
    onDelete,
    setDeletableItemName,
    classes,
    isValid,
    onSave,
    arrowIcon,
    colorArrowIcon,
    menuProps
  } = props;
  const useClasses = useStyles(width, dropDownWidth, itemHeight)();
  
  const [hoveredItem, setHoveredItem] = useState<SelectItem | null>(null);
  const [editableItem, setEditableItem] = useState<SelectItem | null>(null);
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [deleteItemConfirmationModalIsOpen, setDeleteItemConfirmationModalIsOpen] = useState<boolean>(false);
  
  function handleMouseEnter(item: SelectItem) {
    setHoveredItem(item);
  }
  
  function handleMouseLeave() {
    setHoveredItem(null);
  }
  
  function onRenderValue(selectedId: any) {
    if (!value) {
      return placeholder;
    }
    return items.find(item => item.key === selectedId)?.value;
  }
  
  function isError(): boolean {
    if (!isValid) {
      return false;
    }
    
    if (isValid() === undefined) {
      return false;
    }
    
    return !isValid();
  }
  
  function handleOnChangeSelect(event: React.ChangeEvent<{ value: unknown }>) {
    let value = event.target.value;
    setValue(value);
    if (onChange) {
      onChange(value);
    }
  }
  
  function handleOnClose() {
    setIsEditMode(false);
    setEditableItem(null);
  }
  
  function handleOnChangeInput(event: React.ChangeEvent<{ value: unknown }>) {
    event.preventDefault();
    let newItem: SelectItem = {
      key: editableItem?.key,
      value: event.target.value
    };
    setEditableItem(newItem);
  }
  
  function handleOnFocusInput(event: any) {
    if (isEditMode) {
      event.preventDefault();
      event.stopPropagation();
    }
  }
  
  function handleEditButtonClick(e: any, item: SelectItem) {
    e.preventDefault();
    e.stopPropagation();
    setIsEditMode(true);
    setEditableItem(item);
  }
  
  async function handleOnSaveClick(e: any) {
    e.preventDefault();
    e.stopPropagation();
    setIsEditMode(false);
    setEditableItem(null);
    onSave(editableItem!);
  }
  
  function handleExitEdit(e: any) {
    e.preventDefault();
    e.stopPropagation();
    setIsEditMode(false);
    setEditableItem(null);
  }
  
  function handleTrashButtonOnClick(e: any, item: SelectItem) {
    e.preventDefault();
    e.stopPropagation();
    setEditableItem(item);
    setDeleteItemConfirmationModalIsOpen(true);
    if (setDeletableItemName) {
      setDeletableItemName(item.value);
    }
  }
  
  const EditButtons = ({ item } : { item: SelectItem }) => (
    <div>
      <Button
        key={"editButton"}
        onClick={(e) => handleEditButtonClick(e, item)}
        className={useClasses.headerButton}
      >
        <SectionEditIcon style={{ color: '#5C6477' }} />
      </Button>
      <Button
        onClick={(e) => handleTrashButtonOnClick(e, item)}
        className={useClasses.headerButton}
      >
        <SectionDeleteIcon style={{ color: '#5C6477' }} />
      </Button>
    </div>
  );
  
  const SaveButtons = () => (
    <div>
      <Button
        key={"saveButton"}
        onClick={async (e) => await handleOnSaveClick(e)}
        disabled={editableItem?.value.length < 1}
        className={useClasses.headerButton}
      >
        <SectionSaveIcon style={{ color: '#5C6477' }} />
      </Button>
      <Button
        onMouseUp={(e) => handleExitEdit(e)}
        className={useClasses.headerButton}
      >
        <SectionExitEditIcon style={{ color: '#5C6477' }} />
      </Button>
    </div>
  );
  
  function handleDeleteItem() {
    setDeleteItemConfirmationModalIsOpen(false);
    if (editableItem?.key === value) {
      setValue(null);
    }
    
    if (onDelete && editableItem) {
      onDelete(editableItem);
    }
  }
  
  function handleDeleteModalClose() {
    setDeleteItemConfirmationModalIsOpen(false);
    setEditableItem(null);
  }
  
  const ArrowIcon = (props: any) => (
    arrowIcon ? { ...arrowIcon, props } :
      <ArrowDropDownIcon
        {...props}
        style={{ top: "inherit", right: 12, color: colorArrowIcon ?? "#6462F3" }}
      />
  );
  
  return (
    <MuiSelect
      disableUnderline
      classes={
        classes || {
          root: `${useClasses.focused} ${useClasses.selected} ${isError() ? useClasses.inputError : ""}`,
        }
      }
      name={name}
      className={styleClass ?? useClasses.select}
      input={<InputMUI/>}
      MenuProps={menuProps ??
        {
          autoFocus: false,
          getContentAnchorEl: null,
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "left",
          },
          classes: { paper: useClasses.menuPaper },
        }}
      displayEmpty
      disabled={disabled ?? false}
      onChange={handleOnChangeSelect}
      onClose={handleOnClose}
      placeholder={placeholder}
      renderValue={onRenderValue}
      value={value === undefined || value === null ? "" : value}
      IconComponent={ArrowIcon}
    >
      {items.map((item: SelectItem) =>
        (
          <MenuItem
            classes={{
              root: `${itemRootClass ?? useClasses.itemRoot}`,
              selected: useClasses.itemSelected,
            }}
            key={item.key}
            value={item.key}
            disabled={item?.disabled ?? false}
            onMouseEnter={() => handleMouseEnter(item)}
            onMouseLeave={handleMouseLeave}
          >
            <div className={useClasses.itemContainer}>
              <div className={useClasses.itemText}>
                {isEditMode && item.key === editableItem?.key ? (
                  <InputCustom
                    inputClass={useClasses.editNameInput}
                    value={editableItem?.value}
                    isValid={() => {
                      return editableItem?.value.trim().length > 0 && editableItem?.value.length <= 200
                    }}
                    onKeyDown={(event) => event.stopPropagation()}
                    onChange={(event) => handleOnChangeInput(event)}
                    onClick={(event) => handleOnFocusInput(event)}
                    width={"100%"}
                    autoFocus
                  />
                ) : (
                  item.value
                )}
              </div>
              
              {!isEditMode && hoveredItem && hoveredItem.key === item.key && <EditButtons item={item}/>}
              {isEditMode && editableItem?.key === item.key && <SaveButtons/>}
            </div>
          </MenuItem>
        )
      )}
      <ConfirmDeleteModal
        classModal={useClasses.deleteConfirmationModal}
        open={deleteItemConfirmationModalIsOpen}
        item={editableItem}
        textButtonConfirm={textButtonConfirmDelete}
        classButton={useClasses.deleteButtonClass}
        componentInfo={deleteModal}
        onClose={handleDeleteModalClose}
        onDelete={handleDeleteItem}
      />
    </MuiSelect>
  );
};

export default SelectKeyEditable;
