import React, { useState } from "react";

import Textarea from "./../Textarea";
import useStyles from "./css";
import { TextareaWithLabelProps } from "./props";

const TextareaWithLabel = (props: TextareaWithLabelProps) => {
    const {
        id,
        label,
        labelClass,
        inputClass,
        name,
        value,
        isValid,
        placeholder,
        width,
        onChange,
        onKeyPress,
        inputDisabled,
        inputReadOnly,
        showCounter,
        onClickInput,
        onBlurInput,
        maxLength,
        minRows,
        maxRows
    } = props;
    const classes = useStyles();

    const getId = (): string => {
        if (id) {
            return id;
        }

        return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
            /[xy]/g,
            function (c) {
                var r = (Math.random() * 16) | 0,
                    v = c === "x" ? r : (r & 0x3) | 0x8;
                return v.toString(16);
            }
        );
    };

    const LabelComponent = label;

    const [inputId] = useState<string>(id || getId());

    const handleOnChange = (event: any) => {
        if (onChange) {
            onChange(event);
        }
    };

    const handleOnKeyPress = (event: any) => {
        if (onKeyPress) {
            onKeyPress(event);
        }
    };

    return (
        <div className={classes.root}>
            <label htmlFor={inputId} className={labelClass ?? classes.label}>
                {typeof label === "string" ? label : <LabelComponent />}
            </label>
            <Textarea
                id={inputId}
                name={name}
                minRows={minRows}
                maxRows={maxRows}
                showCounter={showCounter}
                value={value}
                placeholder={placeholder}
                width={width}
                inputClass={inputClass}
                isValid={isValid}
                onChange={handleOnChange}
                onKeyPress={handleOnKeyPress}
                disabled={inputDisabled}
                readOnly={inputReadOnly}
                onClick={onClickInput}
                onBlur={onBlurInput}
                maxLength={maxLength}
            />
        </div>
    );
};

export default TextareaWithLabel;
