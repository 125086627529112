import React from "react";
import { makeStyles } from '@material-ui/core';
import { useAppointmentSchedulerContext } from '../AppointmentsScheduler';
import { ItemComponentProps } from '../AppointmentsScheduler/Content';
import { CSSProperties, Fragment, useRef } from 'react';
import { useAppDispatch } from "../../../../redux/hooks";
import AbsenceBlock from './AbsenceBlock';
import { AbsenceGroup } from '../_common';
import BlockSchedule from "../../../../models/blocked-schedule";
import { blockedScheduleActions } from "../../../../redux/blocked-schedule-slice";
import { PlusIcon } from "../../../../assets/icons";
import { useScheduleAppointmentActionsMenuContext } from "../ScheduleAppointmentActionsMenu/context";
import { multipleBlockedScheduleActions } from "../../../../redux/multiple-blocked-schedule-slice";

const useStyles = makeStyles({
    root: {
        height: '100%',
        position: 'relative',
    },
    item: {
        boxSizing: 'border-box',
        position: 'absolute',
        left: 'calc(100% / var(--total-count) * var(--index) * 2 / 3)',
        top: 'calc(var(--cmos-aps-pixels-per-minute) * var(--absence-offset))',
        height: 'calc(var(--cmos-aps-pixels-per-minute) * var(--absence-duration))',
    },
    plusBtn: {
        all: 'initial',
        backgroundColor: 'var(--clearMain)',
        display: 'block',
        zIndex: 100,
        height: 24,
        width: 24,
        borderRadius: 12,
        position: 'absolute',
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)',
        color: '#fff',
        cursor: 'pointer',
        scale: 0,
        visibility: 'hidden',
        transition: 'scale .15s',
        transformOrigin: 'center',
        
        '&:hover': {
            backgroundColor: 'var(--clear2)',
        },
    
        '&>svg': {
            top: "1.5px",
            left: "4px",
            position: "relative"
        }
    },
    wrapper: {
        display: 'contents',
        position: 'relative',
        '&:hover $plusBtn': {
            scale: 1,
            visibility: 'visible',
        },
    },
});

function getWidth(index: number, totalCount: number): number {
    return 100 - ((index / totalCount) * 100 * 2) / 3;
}

export default function AbsenceItem({ value }: ItemComponentProps<AbsenceGroup>) {
    const styles = useStyles();
    const dispatch = useAppDispatch();
    const { pixelsPerMinute } = useAppointmentSchedulerContext();
    const actionsPopupMenu = useScheduleAppointmentActionsMenuContext();
    
    const firstAbsence = value.absences[0];
    const date = firstAbsence.start;
    const rootRef = useRef<HTMLTableCellElement | null>(null);

    const handleOnClick = (event: React.MouseEvent, absence: BlockSchedule) => {
        if (absence?.isBlockedMultiple) {
            dispatch(multipleBlockedScheduleActions.setState({
              isModalOpen: true,
              blockerCount: absence.blockerCount,
              blockers: absence.blockers
            }))
            return;
        }
        dispatch(blockedScheduleActions.setBlockedSheduleId(absence.id));
        dispatch(blockedScheduleActions.setShowModal(true));
    };

    const body = (<div
      ref={rootRef}
      className={styles.root}
      style={{ '--total-count': value.absences.length } as CSSProperties}
    >
        {value.absences.map((s, idx) => (
          <Fragment key={s.originalAbsence.id}>
              <div
                className={styles.item}
                style={
                    {
                        '--index': idx,
                        '--absence-offset': s.start.diff(value.start).as('seconds') / 60,
                        '--absence-duration': s.duration,
                        width: `${getWidth(idx, value.absences.length)}%`,
                    } as CSSProperties
                }
              >
                  <AbsenceBlock
                    vertical
                    pixelPerMinute={pixelsPerMinute}
                    absence={s.originalAbsence}
                    topOffset={3}
                    overrideDuration={s.duration}
                    onClick={(e) => handleOnClick(e, s.originalAbsence)}
                  />
              </div>
          </Fragment>
        ))}
    </div>);
    
    return (
      <div className={styles.wrapper}>
          {body}
          <>
              <div
                ref={rootRef}
                className={styles.plusBtn}
                onClick={(e) => actionsPopupMenu.open(date, null, e.clientX, e.clientY)}
              >
                  <PlusIcon size={24} fill="currentColor" />
              </div>
      
          </>
      </div>
    );
}
