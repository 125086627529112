import { ApiClient } from "./api-client";
import { SettingsPaymentTabInfo } from "../models/settings-payment-tab-info";
import { Currency } from "../models/currency";
import { PaymentSettingsModel } from "../models/payment-settings-model";
import { BankTransferInfo } from "../models/bank-transfer-info";

export class SettingsPaymentService {

  private static baseUrl: string = "/paymentsSettings";

  static async getCurrencyList(): Promise<Currency[]> {
    const data = await ApiClient.get(`${this.baseUrl}/GetCurrency`);
    let list: Currency[] = [];
    data.currencies.forEach((item: Currency) => {
      list.push(new Currency(item));
    });
    return list;
  }

  static async getPaymentsSettings(establishmentAccountId?: string): Promise<PaymentSettingsModel> {
    let endpoint = `${this.baseUrl}/getPaymentsSettings`;
    if (establishmentAccountId) 
        endpoint += `?AccountId=${establishmentAccountId}`;

    const data = await ApiClient.get(endpoint);
      
    return new PaymentSettingsModel(data.paymentsSettings);
  }

  static saveCurrency(settings: PaymentSettingsModel): Promise<boolean> {
    let payload: any = {
      currencyId: settings.currencyId
    };
    if (settings.accountId)
        payload.accountId = settings.accountId;
    return ApiClient.post(`${this.baseUrl}/saveCurrency`, payload)
      .then(data => data.saved);
  }
  
  static savePaymentsSettings(settings: PaymentSettingsModel): Promise<any> {
    const payload: any = {
      isPaymentCash: settings.isPaymentCash,
      isPaymentCard: settings.isPaymentCard,
      isPaymentCardMasterCard: settings.isPaymentCardMasterCard,
      isPaymentCardVisa: settings.isPaymentCardVisa,
      isPaymentCardOther: settings.isPaymentCardOther,
      otherPaymentCardName: settings.otherPaymentCardName,
      isPaymentBankTransfer: settings.isPaymentBankTransfer,
      isPaymentOxxo: settings.isPaymentOxxo,
      oxxoCardNumber: settings.oxxoCardNumber,
      isPaymentMercadoPago: settings.isPaymentMercadoPago,
      hasAcceptedMercadoPagoTerms: settings.hasAcceptedMercadoPagoTerms,
      hasExtraCostMercadoPago: settings.hasExtraCostMercadoPago
    };
    if (settings.accountId)
        payload.accountId = settings.accountId;
    return ApiClient.post(`${this.baseUrl}/savePaymentsSettings`, payload)
      .then(data => data);
  }
        
  static saveBankTransfer(bank: BankTransferInfo): Promise<BankTransferInfo> {
    return ApiClient.post(`${this.baseUrl}/saveBankTransfer`, bank)
      .then(data => data.bankTransfer);
  }
  
  static deleteBankTransfer(bank: BankTransferInfo): Promise<boolean> {
    return ApiClient.remove(`${this.baseUrl}/deleteBankTransfer?id=${bank.bankTransferId}`)
      .then(data => data.deleted);
  }
  
  static saveWhatsAppPaymentSettings(settings: PaymentSettingsModel): Promise<boolean> {
    const payload: any = {
      hasWhatsAppPaymentReminderOnAppointmentConfirmation: settings.hasWhatsAppPaymentReminderOnAppointmentConfirmation,
      hasWhatsAppPaymentReminderOnAppointmentReminder: settings.hasWhatsAppPaymentReminderOnAppointmentReminder,
      hasWhatsAppPaymentReminderAfterAppointment: settings.hasWhatsAppPaymentReminderAfterAppointment,
      daysAfterAppointmentToSendWhatsAppPaymentReminder: settings.daysAfterAppointmentToSendWhatsAppPaymentReminder,
      isSendReceiptByWhatsApp: settings.isSendReceiptByWhatsApp
    };
    if (settings.accountId)
        payload.accountId = settings.accountId;
    return ApiClient.post(`${this.baseUrl}/saveWhatsAppPaymentSettings`, payload)
      .then(data => data.saved);
  }
  
  static saveCashRegister(settings: PaymentSettingsModel): Promise<boolean> {
    const payload: any = {
      isCashRegisterActivated: settings.isCashRegisterActivated,
      initialCashInCashRegister: settings.initialCashInCashRegister
    };
    if (settings.accountId)
        payload.accountId = settings.accountId;
    return ApiClient.post(`${this.baseUrl}/saveCashRegister`, payload)
      .then(data => data.saved);
  }

}