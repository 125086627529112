import React from "react";

import { useTranslation } from "react-i18next";

import { Step, StepConnector, StepLabel, Stepper, withStyles } from "@material-ui/core";

import useStyles, { useStepIconStyles } from "./css";
import { Check } from "@material-ui/icons";
import { CustomStepperProps } from "../../../../../models/interfaces/custom-stepper-props";
import { TerminologyType } from "../../../../../models/enums/terminology-type";
import { TerminologyForm } from "../../../../../models/enums/terminology-form";
import useTerminology from "../../../../../hooks/useTerminology";
import { TabType } from "../UserModal/user-modal-tab-type";


export default function UserModalStepper(props: CustomStepperProps) {
  
  const classes = useStyles();
  const { t } = useTranslation("settings");
  const { activeStep, userHasServicesInCharge, userCanPrescribeTreatment } = props;

  const StyledStepperConnector = withStyles((theme) => ({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: theme.colors.clearGreen,
      },
    },
    completed: {
      '& $line': {
        borderColor: theme.colors.clearGreen,
      },
    },
    line: {
      borderColor: theme.colors.grey2,
      borderTopWidth: 1,
      borderRadius: 1,
    },
  }))(StepConnector);

  function StepIcon(props: any) {
    const classes = useStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={classes.root}
      >
        {completed ? <Check className={classes.completed} /> : active ? <div className={classes.activeCircle} /> : <div className={classes.circle} />}
      </div>
    );
  }
  
  const commissions = useTerminology({ type: TerminologyType.Commission, form: TerminologyForm.Plural })
  
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<StyledStepperConnector />}
    >
      <Step key={TabType.General}>
        <StepLabel
          StepIconComponent={StepIcon}
          classes={{
            alternativeLabel: classes.labelAlternativeLabel,
            completed: classes.labelCompleted
          }}
        >
          {t("General information")}
        </StepLabel>
      </Step>
      {userCanPrescribeTreatment && <Step key={TabType.ProfessionalInfo}>
        <StepLabel
          StepIconComponent={StepIcon}
          classes={{
            alternativeLabel: classes.labelAlternativeLabel,
            completed: classes.labelCompleted
          }}
        >
          {t("Professional information")}
        </StepLabel>
      </Step>}
      {userHasServicesInCharge && <Step key={TabType.Schedule}>
        <StepLabel
          StepIconComponent={StepIcon}
          classes={{
            alternativeLabel: classes.labelAlternativeLabel,
            completed: classes.labelCompleted
          }}
        >
          {t("Schedules")}
        </StepLabel>
      </Step>}
      {userHasServicesInCharge && <Step key={TabType.Services}>
        <StepLabel
          StepIconComponent={StepIcon}
          classes={{
            alternativeLabel: classes.labelAlternativeLabel,
            completed: classes.labelCompleted
          }}
        >
          {t("Services")}
        </StepLabel>
      </Step>}
      <Step key={TabType.Commissions}>
          <StepLabel
              StepIconComponent={StepIcon}
              classes={{
                alternativeLabel: classes.labelAlternativeLabel,
                completed: classes.labelCompleted
              }}
          >
            {commissions}
          </StepLabel>
      </Step>
      <Step key={TabType.Permissions}>
        <StepLabel
          StepIconComponent={StepIcon}
          classes={{
            alternativeLabel: classes.labelAlternativeLabel,
            completed: classes.labelCompleted
          }}
        >
          {t("Permissions and notifications")}
        </StepLabel>
      </Step>
    </Stepper>
  );
}