import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  tabBar: {
    width: "100%",
    height: 47,
    marginTop: -20
  },
  tabs: {
    ...theme.typography.txtBody2,
    "& .MuiTabs-indicator": {
      backgroundColor: "#7175FA"
    },
    "& .Mui-selected": {
      ...theme.typography.txtBody2,
      color: theme.colors.clearMain,
      fontWeight: "bold"
    },
    "& .MuiButtonBase-root": {
      minWidth: 0,
      alignItems: "left",
      marginRight: 25
    },
    "& .MuiTab-root": {
      paddingLeft: 0,
      paddingRight: 0,
    },
  },
  tab: {
    ...theme.typography.txtBody2,
    textTransform: "none",
    root: {
      ...theme.typography.txtBody2,
    },
    selected: {
      fontWeight: "bold",
    },
    "&:hover": {
      color: theme.colors.clearMain
    }
  },
  mainTab: {
    paddingTop: 0,
    paddingBottom: 20,
    backgroundColor: "#ffffff",
    height: 50,
    marginBottom: 30
  },
  headerBack: {
    height: 50,
    width: "100%",
    backgroundColor: "#ffffff",
    position: "absolute",
    left: 0
  }
}));

export default useStyles;
