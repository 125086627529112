import { ApiClient } from "./api-client";
import { EnterpriseInfo } from "../models/enterpriseinfo";

export class EnterpriseInfoService {

    private static baseUrl: string = "/account";

    static async updateEnterpriseInfo(enterpriseInfo: EnterpriseInfo): Promise<EnterpriseInfo> {
        return ApiClient.post(`${this.baseUrl}/updateenterpriseinfo`, enterpriseInfo)
            .then(data => new EnterpriseInfo(data.enterpriseInfoDto)
        );
    }

    static async getEnterpriseInfo(): Promise<EnterpriseInfo> {
        const data = await ApiClient.get(`${this.baseUrl}/getenterpriseinfo`);
        return new EnterpriseInfo(data.enterpriseInfoDto);
    }

}
