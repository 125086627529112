import { makeStyles } from "@material-ui/core";

const useStyles = (height: number | undefined, width: number | undefined) =>
  makeStyles((theme) => ({
    container: {
      height: height || 605,
      width: width,
      // overflowY: "scroll",
      maxHeight: 460
    },
    tableContainer: {
      border: "1px solid #DBDCDD",
      boxSizing: "border-box",
      borderRadius: "12px 12px 12px 12px",
      height: height || 605,
      maxHeight: 460,
      width: width,
      overflow: "hidden",
    },
    tableHeader: {
      backgroundColor: "#F6F6F6",
    },
    tableBody: {
      overflowY: "scroll",
      height: height || 605,
      maxHeight: 460,
      width: width,
      "&::-webkit-scrollbar": {
        display: "none",
      },
      scrollbarWidth: "none",
      "-ms-overflow-style": "none",
    },
    rowText: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey5,
    },
  }));

export default useStyles;
