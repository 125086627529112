import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import InputWithLabel from "../../../../common/InputWithLabel";

import { AlphanumericRegex } from "../../../../../constants/validator";

import EntityForm, { WorkareasFormProps, WorkareasValid } from "../../../../../models/workarea";
import useStyles from "./css";

import Category from "../../../../../models/category";
import CategorySelect from "./../../CategorySelect";
import SwitchWithLabelAndTooltip from "../../../../common/SwitchWithLabelAndTooltip";


const WorkareasForm = (props: WorkareasFormProps) => {

    const { t } = useTranslation(["general"]);
    const classes = useStyles();

    const { settingsSelectedEstablishmentId, item, onChange, onModify } = props;
    const [entityState, setEntityState] = useState<EntityForm>(item || new EntityForm());

    const runValidations = (): WorkareasValid | undefined => {
        if (item) {
            return {
                name: AlphanumericRegex.test(item.name),
                description: true
            };
        }
    };

    const [entityValid, setEntityValid] = useState<WorkareasValid | undefined>(runValidations());

    useEffect(() => {

        if (item && JSON.stringify(item) !== JSON.stringify(entityState)) {
            setEntityState(item);
        }

        runValidations();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [item]);


    useEffect(() => {
        if (
            onChange
            && entityValid
            && entityValid.name
        ) {
            onChange(true, entityState);
        } else if (onChange) {
            onChange(false, entityState);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entityState]);

    const handleChange = (event: any) => {
        let value = event.target.value;
        setEntityValid({
            ...entityValid,
            [event.target.name]: (value !== undefined && value !== null) ? AlphanumericRegex.test(value) : false
        });
        setEntityState({
            ...entityState,
            [event.target.name]: value
        });
        if (onModify) onModify();
    };

    const handleSelectCategory = (category: Category) => {
        setEntityState({
            ...entityState,
            categoryId: category.id,
            category: category.name
        });
        if (onModify) onModify();
    }

    const handleChangeSimultaneousServices = (value: boolean) => {
        setEntityState({
            ...entityState,
            hasSimultaneousServices: value,
        });
        if (onModify) onModify();
    }

    return (
        <div className={classes.root}>

            <div className={classes.rowContainer}>
                <label className={classes.title}>
                    {t("Required fields")} <span className={classes.required}>*</span>
                </label>
            </div>

            <div className={classes.rowContainer}>

                <InputWithLabel
                    label={() => (
                        <>
                            {t("Work area name")} <span className={classes.required}>*</span>
                        </>
                    )}
                    value={entityState?.name}
                    placeholder={t("Enter the work area name")}
                    width={350}
                    maxLength={60}
                    isValid={() => entityValid?.name}
                    name="name"
                    onChange={handleChange}
                />

                <CategorySelect
                    settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
                    onSelect={handleSelectCategory}
                    item={item}
                    isOptional={true}
                />

            </div>

            <div className={classes.rowDescription}>
                <InputWithLabel
                    label={() => (
                        <>
                            {t("Description")}{" "}
                            <span className={classes.optional}>({t("Optional")})</span>
                        </>
                    )}
                    placeholder={t("Enter a short description for the work area (Maximum 180 characters).")}
                    width={730}
                    showCounter={true}
                    maxLength={180}
                    name="description"
                    onChange={handleChange}
                    value={entityState?.description || ""}
                />
            </div>

            <div className={classes.switchBox}>
                <SwitchWithLabelAndTooltip
                  value={entityState?.hasSimultaneousServices}
                  setValue={handleChangeSimultaneousServices}
                  label={t("Simultaneous services")}
                  tooltip={t("simultaneous-services-tooltip")}
                  inactiveTooltip={t("simultaneous-services-inactive-tooltip")}
                />
            </div>

        </div>
    );
};

export default WorkareasForm;
