import { CustomerFrequencyType } from "./enums/customer-frequency-type";

export class CustomerData {
  name: string | null;
  birthDate: Date | null;
  frequencyType: CustomerFrequencyType;
  appointments: number | null;
  attended: number | null;
  services: number | null;
  serviceName: string | null;
  servicePrice: number | null;
  serviceTimes: number | null;
  created: Date;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.name = x.name;
      this.birthDate = x.birthDate;
      this.frequencyType = x.frequencyType;
      this.appointments = x.appointments;
      this.attended = x.attended;
      this.services = x.services;
      this.serviceName = x.serviceName;
      this.servicePrice = x.servicePrice;
      this.serviceTimes = x.serviceTimes;
      this.created = x.created;
    } else {
      this.name = null;
      this.birthDate = null;
      this.frequencyType = CustomerFrequencyType.Inactive;
      this.appointments = null;
      this.attended = null;
      this.services = null;
      this.serviceName = null;
      this.servicePrice = null;
      this.serviceTimes = null;
      this.created = new Date();
    }
  }
}
