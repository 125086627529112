import { SectionType } from "./enums/section-type";

export class SectionDeleteRequest {
    establishmentAccountId?: string|null;
    id: string | null;
    type: SectionType;
    order: number;

    constructor(x?: any) {
        if (typeof x === "object") {
            this.establishmentAccountId = x.establishmentAccountId;
            this.id = x.id;
            this.type = x.type;
            this.order = x.order;
        } else {
            this.establishmentAccountId = null;
            this.id = null;
            this.type = SectionType.ClinicHistory;
            this.order = 0;
        }
    }
}