export class EstablishmentPhoto {
    accountId?: string | null;
    photoId?: string;
    photoURL?: string;
    order: number;


    constructor(x?: EstablishmentPhoto) {
        if (typeof x === "object" && x !== null) {
            this.accountId = x.accountId;
            this.photoId = x.photoId || "";
            this.photoURL = x.photoURL || "";
            this.order = x.order || 0;
        }
        else {
            this.accountId = null;
            this.photoId = "";
            this.photoURL = "";
            this.order = 0;
        }
    }
}
