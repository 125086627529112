import React, { forwardRef, useEffect, useImperativeHandle } from "react";
import { useAppDispatch } from "../../../../../redux/hooks";
import { alertsActions } from "../../../../../redux/alerts-slice";
import { useTranslation } from "react-i18next";

import { SectionListProps } from "./props";
import SectionListRefs from "./section-list-refs";
import useStyles from "./css";

import Section from "../Section";

import { SettingsPatientFormsService } from "../../../../../api/settings-patient-forms-service";
import { SectionModel } from "../../../../../models/section-model";
import { SectionUpdateRequest } from "../../../../../models/section-update-request";
import { SectionDeleteRequest } from "../../../../../models/section-delete-request";
import { SectionCreateRequest } from "../../../../../models/section-create-request";
import { navbarActions } from "../../../../../redux/navbar-slice";


const SectionList = forwardRef<SectionListRefs, any>((props: SectionListProps, ref) => {
    const { sectionType, sections, setSections } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const { enqueueAlert } = alertsActions;
    const { t } = useTranslation(["general"]);

    //to make the "createSection" function callable from the parent component
    useImperativeHandle(ref, () => ({
        createSection: async (name: string) => {
            const resultId = await SettingsPatientFormsService.createSection(new SectionCreateRequest({
                type: sectionType,
                name: name,
            }));

            const newSections = [...sections];
            newSections.forEach(s => {
                s.order = s.order! + 1;
            });

            newSections.push(new SectionModel({
                id: resultId,
                name: name,
                order: 1,
                type: sectionType,
                fields: [],
                isEditable: true,
                isRemovable: true
            }));

            setSections(newSections);

            dispatch(
                enqueueAlert({
                    type: "Success",
                    title: t("Section created"),
                    description: name
                })
              );
        }
    }));

    useEffect(() => {
        async function fetchData() {
            dispatch(navbarActions.setShowLoader(true));
            await loadData();
            dispatch(navbarActions.setShowLoader(false));
        }
        fetchData();
    }, []);

    const handleEditSection = async (section: SectionModel) => {
        await SettingsPatientFormsService.updateSection(new SectionUpdateRequest({
            id: section.id,
            type: section.type,
            newName: section.name,
            newOrder: section.order,
            previousOrder: null
        }))
        .then(() => {
            setSection(section)
        })
    };

    const handleDeleteSection = async (section: SectionModel) => {
        const sectionOrder = section.order;
        await SettingsPatientFormsService.deleteSection(new SectionDeleteRequest({
            id: section.id,
            type: sectionType,
            order: sectionOrder
        }));

        const updatedSections = sections.filter(s => s.id !== section.id);

        updatedSections.forEach(s => {
            if (s.order! < sectionOrder!)
                return;
            s.order = s.order! - 1;
        });

        setSections(updatedSections);
    };

    const handleMove = async (sectionId: string, isUp: boolean) => {
        const step = isUp ? -1 : 1;
        const targetSection = sections.find(s => s.id === sectionId);
        const targetSectionIndex = sections.findIndex(s => s.id === sectionId);
        const pairedSectionIndex = sections.findIndex(s => s.order === targetSection!.order! + step);

        const newSections = [...sections];
        newSections[pairedSectionIndex].order = targetSection!.order!;
        newSections[targetSectionIndex].order = targetSection!.order! + step;
        setSections(newSections);

        await SettingsPatientFormsService.updateSection(new SectionUpdateRequest({
            id: targetSection!.id!,
            type: sectionType,
            newName: targetSection!.name!,
            newOrder: targetSection?.order,
            previousOrder: targetSection!.order! - step
        }));
    };

    const setSection = (section: SectionModel) => {
        const targetSection = sections.find(s => s.id === section.id);
        if (!targetSection)
            return;

        const targetSectionIndex = sections.findIndex(s => s.id === section.id);
        const updatedSections = [...sections];

        updatedSections[targetSectionIndex].fields = [...section.fields];

        setSections(updatedSections);
    };

    const loadData = async () => {
        const sectionsData =
            await SettingsPatientFormsService.getAccountSettingsTabSections(sectionType);
        setSections(sectionsData);
    };

    const mapSections = () => {
        return sections.sort((s1, s2) => s1.order! - s2.order!).map(s =>
            <Section
                key={s.id!}
                section={s}
                sections={sections}
                setSection={(updatedSection) => setSection(updatedSection)}
                onEdit={async () => { await handleEditSection(s) }}
                onDelete={async () => { await handleDeleteSection(s) }}
                onMoveDown={async () => { await handleMove(s.id!, false) }}
                onMoveUp={async () => { await handleMove(s.id!, true) }} />)
    };

    return <div className={classes.sectionsContainer}>
        {mapSections()}
    </div>
});

export default SectionList;