export class MassiveSendingEntity {
  id: string | null;
  name: string;
  type: number;
  customerFrequency: number;
  customerGender: number;
  dateOfSending: Date | null;
  timeOfSending: string;
  terminationDate: Date | null;
  birthdaySendingType: number | null;
  status: number;
  contentType: number;
  text: string;
  image: File | undefined;
  imageUrl: string | undefined;
  sendImmediately: boolean;
  
  constructor(x?: any) {
    const newDate = new Date();
    const currentYear = newDate.getFullYear();
    const currentMonth = newDate.getMonth();
    const nextDay = newDate.getDate() + 1;
    const tomorrowDate = new Date(currentYear, currentMonth, nextDay);
    if (typeof x === "object") {
      this.id = x.id;
      this.name = x.name;
      this.type = x.type;
      this.customerFrequency = x.customerFrequency;
      this.customerGender = x.customerGender;
      this.dateOfSending = x.dateOfSending !== null ? new Date(x.dateOfSending) : null;
      this.timeOfSending = x.timeOfSending;
      this.terminationDate = x.terminationDate !== null ? new Date(x.terminationDate) : null;
      this.birthdaySendingType = x.birthdaySendingType;
      this.status = x.status;
      this.contentType = x.contentType;
      this.text = x.text;
      this.image = x.image;
      this.imageUrl = x.imageUrl;
      this.sendImmediately = x.sendImmediately;
    } else {
      this.id = null;
      this.name = "";
      this.type = 0;
      this.customerFrequency = 0;
      this.customerGender = 0;
      this.dateOfSending = tomorrowDate;
      this.timeOfSending = "12:00";
      this.terminationDate = null;
      this.birthdaySendingType = 0;
      this.status = 0;
      this.contentType = 0;
      this.text = "";
      this.image = undefined;
      this.imageUrl = undefined;
      this.sendImmediately = false;
    }
  }
}