import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from 'react-i18next';

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import EntityService from "../../../../../api/settings-workareas-service";
import WorkareaForm from './../WorkAreaForm'
import Workarea from "../../../../../models/workarea";

import useStyles from "./css";
import Cloner from "../../../../../utils/cloner";
import stringify from "safe-stable-stringify";


const WorkareaModal = (props: any) => {
    const { settingsSelectedEstablishmentId, open, onClose, onCreate, item } = props;

    const { t } = useTranslation(["general"]);
    const classes = useStyles();

    const [entityState, setEntityState] = useState<Workarea>(new Workarea());
    const [initialState, setInitialState] = useState<string | null>(null);
    const [openModal, setOpenModal] = useState<boolean>(false);
    const [cancelModal, setCancelModal] = useState<boolean>(false);
    const [isValid, _setIsValid] = useState<boolean>(false);
    const [isChangesMade, setIsChangesMade] = useState<boolean>(false);
    const isValidRef = useRef(isValid);

    useEffect(() => {
        setOpenModal(open);
        setIsChangesMade(false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const setIsValid = (data: boolean) => {
        isValidRef.current = data;
        _setIsValid(data);
    };

    const handleOnCreateClick = async () => {
        if (isValid) {
            setIsValid(false);
            let entity: Workarea;
            entityState.accountId = settingsSelectedEstablishmentId || null;
            if (!entityState.id) {
                entity = await EntityService.createWorkarea(entityState);
            }
            else {
                entity = await EntityService.updateWorkarea(entityState);
            }

            const newEntity: Workarea = { ...entityState, id: entity.id };
            setEntityState(newEntity);

            if (onCreate) {
                let edited = item !== null;
                onCreate(newEntity, edited);
                setInitialState(null);
                onClose();
            }
        }
    };

    const handleFormChange = (isValid: boolean, entity: Workarea) => {
        setIsValid(isValid);
        setEntityState(entity);
        if (initialState === null) {
            setInitialState(stringify(Cloner.deepCopy<Workarea>(entity)));
        }
    };

    const handleModifying = () => {
        setIsChangesMade(true);
    };

    return (<>
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={openModal}
            onClose={(event, reason) => {
                if (reason === 'backdropClick') {
                    if (isChangesMade)
                        setCancelModal(true);
                    else {
                        setInitialState(null);
                        onClose();
                    }
                }
            }}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }} >
            <Fade in={openModal}>
                <div className={classes.paper}>
                    <div className={classes.modalHeader}>

                        <div className={classes.modalTitle}>
                            <Typography className={classes.modalTitleText} variant="h1">
                                {item ? t('Work area detail') : t('New work area')}
                            </Typography>
                        </div>

                        <div className={classes.modalActions}>
                            <Button
                                className={`${classes.button} ${classes.goBack}`}
                                onClick={() => {
                                    if (isChangesMade)
                                        setCancelModal(true);
                                    else {
                                        setInitialState(null);
                                        onClose();
                                    }
                                }} >
                                {t('Go back')}
                            </Button>
                            <Button
                                className={`${classes.button} ${classes.createCustomer}`}
                                disabled={!isValid || initialState == stringify(entityState)}
                                classes={{ disabled: classes.createDisabled }}
                                onClick={handleOnCreateClick} >
                                {
                                    item ? t('Save changes') : t('Create work area')
                                }
                            </Button>
                        </div>

                    </div>

                    <WorkareaForm
                        settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
                        item={item}
                        onChange={handleFormChange}
                        onModify={handleModifying}
                    />

                </div>
            </Fade>
        </Modal>

        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modal}
            open={cancelModal}
            onClose={() => setCancelModal(false)}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}
        >
            <Fade in={cancelModal}>
                <div className={classes.modalCancelPaper}>
                    <div className={classes.modalContent}>
                        <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`} >
                            <Typography className={classes.modalTitleTextCancel}>
                                {item ? t("Cancel work area edition?") : t("Cancel work area registration?")}
                            </Typography>
                        </div>
                        <div className={classes.modalActions}>
                            <Button className={`${classes.button} ${classes.goBack}`} onClick={() => setCancelModal(false)}>
                                {t("Go back")}
                            </Button>
                            <Button
                                className={`${classes.button} ${classes.cancelConfirmation}`}
                                onClick={() => {
                                    setInitialState(null);
                                    setCancelModal(false);
                                    onClose();
                                }}
                            >
                                {t("WorkAreaModalCancelEditConfirm")}
                            </Button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    </>);
};

export default WorkareaModal;
