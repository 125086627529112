import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

export const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    menuButton: {
    },
    title: {
        fontSize: 14,
        fontWeight: 700,
        fontFamily: "Inter, sans-serif",
        color: theme.colors.grey4,
    },
    externalContentPlaceholder: {
        flexGrow: 1,
    },
    verticalDivider: {
        height: 40,
        
        paddingRight: 15,
        marginRight: 15,
        borderRightColor: theme.colors.grey2,
        borderRightWidth: 1,
        borderRightStyle: "solid",
    },
    establishmentSelector: {
    },
    paper: {
        color: "#fff",
        background: "#1D2027",
        borderTopRightRadius: "20px",
        borderBottomRightRadius: "20px",
        width: "250px",
    },
    logoClear: {
        color: "#F6F6F6",
        fontSize: "42px",
        marginTop: "30px"
    },
    iconLogoClear: {
        margin: "15px 5px 0 0",
        fontSize: "48px",
        color: "#F6F6F6"
    },
    enterpriseLabel: {
        ...theme.typography.txtBody1,
        fontWeight: 700,
        color: theme.colors.grey2,
    },
    versionLabel: {
        color: "#5C6477",
        fontSize: "10px" 
    },
    drawerListItems: {
        overflow: "hidden"
    },
    tablecss: {
        height: "100%",
        borderCollapse: "collapse"
    },
    userLabel: {
        color: "#F6F6F6",
        fontSize: "1rem",
        margin: "40px 0 0 0"
    },
    emailLabel: {
        color: "#919CA5",
        fontSize: "12px",
        overflow: "hidden",
        margin: "0 0 40px 0",
        overflowWrap: "break-word",
        width: 200
    },
    linkMenu: {
        backgroundColor: "inherit",
        color: "#ACB7C0",
        "&:hover": {
            color: "#FFFFFF",
            textDecoration: "none",
            "& * svg": {
                color: "#FFFFFF"
            }
        },
        "& * svg": {
            color: "#ACB7C0",
        },
    },
    
    linkMenuParent: {
        backgroundColor: "inherit",
        color: "#ACB7C0",
        "& * svg": {
            color: "#ACB7C0",
        },
    },
    listItem: {
        borderLeft: "5px solid transparent",
    },
    listItemActive: {
        color: "#FFFFFF",
        borderLeft: "5px solid #6361f2",
        backgroundColor: "#2e3138",
        "& * svg": {
            color: "#FFF",
        },
        "&:hover": {
            backgroundColor: "#2e3138",
        }
    },
    childItemActive: {
        color: "#FFFFFF",
        backgroundColor: "#2e3138",
        "& * svg": {
            color: "#FFF",
        },
        "&:hover": {
            backgroundColor: "#2e3138",
        }
    },
    listSubItemActive: {
        paddingLeft: 30,
        color: "#FFFFFF",
        borderLeft: "5px solid #6361f2",
        backgroundColor: "#2e3138",
        "& * svg": {
            color: "#FFF",
        },
        overflow: "hidden",
        "&:hover": {
            backgroundColor: "#2e3138",
        }
    },
    listItemIcon: {
        height: 25,
        width: 30,
        minWidth: 30,
        margin: "auto",
        paddingTop: 5,
    },
    menuIcon: {
        margin: "auto",
        fontSize: "24px",
    },
    listItemText: {
        marginLeft: "10px",
        fontSize: "14px",
        width: "100%"
    },
    menuHorizontalTop: {
        padding: "3px 15px",
        backgroundColor: "#fff",
        color: "#919CA5", // gray4
        fontWeight: "bold",
        borderBottom: "1px solid #F6F6F6",
        // Centering AppBar
        position: "fixed",
        left: 0,
        right: 0,
        margin: "auto",
        overflow: "hidden",
    },
    listSubItem: {
        marginLeft: 20,
        width: "auto",
        "&:hover": {
            color: "#FFFFFF",
            textDecoration: "none",
            "& * svg": {
                color: "#FFFFFF"
            }
        },
        "& * svg": {
            color: "#ACB7C0",
        },
    },
    iconsBlock: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: 85,
    },
    referFriend: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: "#344153",
        borderRadius: 10,
        color: Color.clilac,
        padding: "8px 14px 14px",
        margin: "14px"
    },
    referFriendTitle: {
        fontFamily: "Inter, sans-serif",
        fontSize: 14,
        fontWeight: 700,
        marginBottom: 8
    },
    referFriendDescription: {
        fontFamily: "Inter, sans-serif",
        fontSize: 11,
        fontWeight: 400,
        marginBottom: 15
    },
    referFriendLink: {
        fontFamily: "Inter, sans-serif",
        display: "inline-block",
        width: "100%",
        height: 22,
        textAlign: "center",
        fontSize: 12,
        fontWeight: 700,
        border: "1px solid " + Color.clilac,
        borderRadius: 11,
        color: Color.clilac,
        "&:hover":{
            color: Color.clilac,
        }
    },
    popper:{
        [`& .MuiTooltip-tooltip`]: {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: 12
        }
    }
}));
