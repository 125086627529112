import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
    deleteConfirmationModal: {
        height: "271px !important"
    },
    deleteModalContentContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: 15
    },
    deleteModalTitleText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#26292B",
        textAlign: "center"
    },
    deleteModalContentText: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "12px",
        lineHeight: "17px",
        color: Color.gray5,
        textAlign: "center"
    },
    deleteModalContentBoldText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray5
    },
    separator: {
        height: 0,
        borderTop: "1px solid rgba(201, 205, 211, 0.38)",
        margin: "16px 0px 16px 0px",
        width: 217
    },
}));

export default useStyles;
