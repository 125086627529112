import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function PatientMaleIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 16, height: 16, ...style }}
            viewBox={viewBox || "0 0 16 16"}
        >
            <path
                d="M9.14077 2.66699V4.15217H10.7718L8.62187 6.27446C8.01861 5.8865 7.29952 5.66137 6.52744 5.66137C4.39549 5.66137 2.66699 7.37872 2.66699 9.49734C2.66698 11.616 4.39549 13.3337 6.52744 13.3337C8.6594 13.3337 10.3876 11.616 10.3876 9.49734C10.3876 8.69204 10.138 7.94472 9.71132 7.32765L11.8252 5.22702V6.83366H13.3197C13.3197 5.53166 13.3339 3.96561 13.3337 2.66699H9.14077ZM6.52744 7.20544C7.80129 7.20544 8.83377 8.23145 8.83377 9.49734C8.83377 10.7632 7.80129 11.7896 6.52744 11.7896C5.25359 11.7896 4.22077 10.7632 4.22077 9.49734C4.22077 8.23145 5.25358 7.20544 6.52744 7.20544Z"
                fill={style?.color || Color.blue}
            />
        </SvgIcon>
    );
}