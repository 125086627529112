import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import { Button, Typography } from "@material-ui/core";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import useStyles from "./css";
import { IResetPasswordFormState, IResetPasswordData } from "./props";
import AuthenticationLayout from "../AuthenticationLayout";
import PasswordInput from "../PasswordInput";
import SuccessBlock from "../SuccessBlock";
import { Url } from "../../../constants/url";
import { AuthenticationService } from "../../../api/authentication-service";
import usePasswordValidation from "./use-password-validation";
import { useTranslation } from "react-i18next";
import { alertsActions } from "../../../redux/alerts-slice";
import { useAppDispatch } from "../../../redux/hooks";

export default function ResetPassword(props: any) {
  
  const classes = useStyles();
  const { t } = useTranslation("general");
  const history = useHistory();

  const { executeRecaptcha } = useGoogleReCaptcha();
  
  const [resetPasswordData, setResetPasswordData] = useState<IResetPasswordData>({ token: "", email: "" });
  const [formState, setFormState] = useState<IResetPasswordFormState>({ newPassword: "", confirmPassword: ""});
  const [completed, setCompleted] = useState<boolean>(false);
  
  const { enqueueAlert } = alertsActions;
  const dispatch = useAppDispatch();
  
  function handleNewPasswordChange(e: any) {
    setFormState({...formState, newPassword: e.target.value });
  }

  function handleConfirmPasswordChange(e: any) {
    setFormState({...formState, confirmPassword: e.target.value});
  }

  function displayAlert(message: string) {
    dispatch(
      enqueueAlert({
        type: "Error",
        title: t("Error"),
        description: message
      })
    );
  }
  
  const passwordStrength = usePasswordValidation(formState.newPassword, classes);
  
  function passwordsAreEqual() {
    return formState.newPassword === formState.confirmPassword;
  }
  
  async function submitNewPassword() {
    if (!passwordStrength.isGood) {
      return;
    }
    
    if (!passwordsAreEqual()) {
      return;
    }

    if (!executeRecaptcha) {
      console.log("no recaptcha")
      return;
    }
    
    const captchaToken = await executeRecaptcha('reset');
    if (!captchaToken) return;
    
    AuthenticationService
      .resetPassword(resetPasswordData.email, resetPasswordData.token, formState.newPassword, captchaToken)
      .then(
        () => setCompleted(true),
        () => setCompleted(true)
        );
  }

  const SubmitButton =
    <Button
      className={classes.submitButton}
      color="primary"
      variant="contained"
      onClick={submitNewPassword}
      disabled={!(passwordsAreEqual() && passwordStrength.isGood)}
    >
      {t("Reset password")}
    </Button>
  
  const GoToLoginButton =
    <Link to={Url.Authentication.Login}>
      <Button
        className={classes.submitButton}
        color="primary"
        variant="contained"
      >
        {t("Log in")}
      </Button>
    </Link>
  
  useEffect(() => {
    const token = props.match.params[0];
    const { email } = props.match.params;
    if (!token || token === "" || !email || email === "") {
      history.push(Url.Authentication.Login);
    }
    
    setResetPasswordData(
      {
        ...resetPasswordData,
        token: token,
        email: email,
      })
  }, [])
  
  return (
    <AuthenticationLayout>
      <div>

        {completed ? 
          <div className={classes.successBlock}>
            <SuccessBlock 
              headerMessage={t("Done!")}
              textMessage={t("Your password has been restored")}
              blockHeight={180}
            />
            {GoToLoginButton}
          </div>
          : 
          <>
            <Typography className={classes.formHeader}>
              {t("Reset password")}
            </Typography>
  
            <PasswordInput
              value={formState.newPassword}
              label={t("New password")}
              placeholder={t("Enter new password")}
              onChangeHandler={handleNewPasswordChange}
              success={passwordStrength.isGood}
            />
            <div className={classes.passwordStrengthMeter}>
              <span className={passwordStrength.className} />
            </div>
            <Typography className={classes.passwordStrengthHint}>
              {passwordStrength.hint}
            </Typography>
            <div className={classes.confirmPasswordInput}>
              <PasswordInput
                value={formState.confirmPassword}
                label={t("Confirm your new password")}
                placeholder={t("Confirm new password")}
                onChangeHandler={handleConfirmPasswordChange}
                success={passwordsAreEqual() && passwordStrength.isGood}
              />
            </div>
  
            {SubmitButton}
          </>
        }
      </div>
    </AuthenticationLayout>
  );
}