import React from "react";

import { Select } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

import useStyles from "./css";
import { ChipSelectProps } from "./props";
import Chip from "../Chip";
import { SelectItem } from "../../../models/interfaces/select-item";


export default function ChipSelect(props: ChipSelectProps) {
  
  const { items, item, setItem } = props;
  
  const classes = useStyles();

  const handleOnChange = (event: any) => {
    setItem(event.target.value);
  };
  
  return (
    <Select
      disableUnderline
      IconComponent={() => null}
      classes={{ root: classes.root }}
      onChange={handleOnChange}
      value={item || 0}
      input={<Chip />}
      inputProps={{ style: { padding: '0!important'}}}
      MenuProps={{
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left"
        },
        transformOrigin: {
          vertical: "top",
          horizontal: 17
        },
        getContentAnchorEl: null
      }}
    >
      {items.map((item: SelectItem) =>
        <MenuItem
          key={item.key}
          value={item.key}
          classes={{
            root: classes.menuItem,
            selected: classes.menuItemSelected
          }}
        >
          {item.value}
        </MenuItem>
      )}
    </Select>
  );
}