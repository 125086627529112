import React from "react";
import { Box, IconButton, Popover } from '@material-ui/core';
import { CloseIcon } from "../../../../../assets/icons";
import groupBy from 'lodash/groupBy';
import { useTranslation } from 'react-i18next';
import { Appointment } from "../../../../../models/appointment";
import AppointmentTicket from '../AppointmentTicket';
import useAppointmentItemListStyles from './css';

export type AppointmentItemListProps = {
    appointments: Appointment[];
    open: boolean;
    onClose: () => void;
};

export default function AppointmentItemList({
    appointments,
    open,
    onClose,
}: AppointmentItemListProps) {
    const { t } = useTranslation(['general']);
    const styles = useAppointmentItemListStyles();

    const groupedAppointments = [...appointments].sort((a, b) => a.startDate > b.startDate ? 1 : -1)

    const body = (
        <ul className={styles.list}>
            {groupedAppointments.map(a =>
                <li key={a.appointmentId} className={styles.item}>
                    <AppointmentTicket appointment={a} />
                </li>
            )}
        </ul>
    );

    return (
        <>
            <Popover
                disableScrollLock
                elevation={3}
                marginThreshold={0}
                classes={{ paper: styles.root }}
                anchorOrigin={{ horizontal: 'right', vertical: 'center' }}
                transformOrigin={{ horizontal: 'right', vertical: 'center' }}
                onClose={onClose}
                open={open}
                style={{ zIndex: 4 }}
            >
                <Box className={styles.header} marginTop={1} display="flex" alignItems="center">
                    <span className={styles.title}>
                        {appointments.length} {t('appointments')}
                    </span>
                    <IconButton style={{ width: 13, height: 13 }} onClick={() => onClose()}>
                        <CloseIcon fill={'#fff'} />
                    </IconButton>
                </Box>
                <Box className={styles.wrapper}>{body}</Box>
            </Popover>
        </>
    );
}
