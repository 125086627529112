import React, { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";

import { EvolutionNotesProps } from "./props";
import useStyles from "./css";
import { Button, Typography } from "@material-ui/core";
import SectionList from "../SectionList";
import { SectionType } from "../../../../../models/enums/section-type";
import { SectionModel } from "../../../../../models/section-model";
import { SettingsPatientFormsService } from "../../../../../api/settings-patient-forms-service";
import { navbarActions } from "../../../../../redux/navbar-slice";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import Section from "../Section";
import { SectionFieldModel } from "../../../../../models/section-field-model";
import { SectionFieldType } from "../../../../../models/enums/section-field-type";
import useTerminology from "../../../../../hooks/useTerminology";
import { TerminologyType } from "../../../../../models/enums/terminology-type";
import { TerminologyForm } from "../../../../../models/enums/terminology-form";
import { AccountService } from "../../../../../api/account-service";
import { Terminology } from "../../../../../models/terminology";
import { setAccountSettings } from "../../../../../redux/account-settings-slice";
import { selectAccountSettings } from "../../../../../redux/store";
import { SectionGearIcon } from "../../../../../assets/icons";
import TreatmentDocumentConfigurationModal from "./TreatmentDocumentConfigurationModal";


const EvolutionNotes = (props: EvolutionNotesProps) => {
    const sectionTNamespace = "sections";
    const { t } = useTranslation(["general", sectionTNamespace]);
    const treatmentTerm = useTerminology({ type: TerminologyType.Treatment, form: TerminologyForm.Singular });
    const accountSettings = useAppSelector(selectAccountSettings);

    const { } = props;
    const classes = useStyles();
    const dispatch = useAppDispatch();

    const [evolutionNoteSections, setEvolutionNoteSections] = useState<SectionModel[]>([]);
    const [userTreatmentSection, setUserTreatmentSection] = useState<SectionModel>(new SectionModel());
    const [openTreatmentPdfConfigurationModal, setOpenTreatmentPdfConfigurationModal] = useState<boolean>(false);

    const userTreatmentStaticFields = [new SectionFieldModel({
        id: "specialist",
        name: t("default_field_name_Specialist", { ns: sectionTNamespace }),
        order: -1,
        type: SectionFieldType.Text,
        isRequired: true,
        isAdditionalTextareaEnabled: false,
        isEditable: false,
        isRemovable: false
    }),
    new SectionFieldModel({
        id: "mobile",
        name: t("default_field_name_Mobile", { ns: sectionTNamespace }),
        order: 0,
        type: SectionFieldType.PhoneNumber,
        isRequired: true,
        isAdditionalTextareaEnabled: false,
        isEditable: false,
        isRemovable: false
    })];

    useEffect(() => {
        async function fetchData() {
            dispatch(navbarActions.setShowLoader(true));
            await loadData();
            dispatch(navbarActions.setShowLoader(false));
        }
        fetchData();
    }, []);

    const loadData = async () => {
        const evolutionNoteSections = await SettingsPatientFormsService.getAccountSettingsTabSections(SectionType.EvolutionNoteForm);
        const userTreatmentSections = await SettingsPatientFormsService.getAccountSettingsTabSections(SectionType.UserTreatment);
        let userTreatmentSection = userTreatmentSections[0];
        userTreatmentSection = {...userTreatmentSection, name: treatmentTerm};
        userTreatmentSection.fields.unshift(...userTreatmentStaticFields);
        setEvolutionNoteSections(evolutionNoteSections);
        setUserTreatmentSection(userTreatmentSection);
    };

    const handleChangeTreatmentTerminology = async (section: SectionModel) => {
        const newTerminology = new Terminology({
            terminologyType: TerminologyType.Treatment,
            singularForm: section.name,
            pluralForm: section.name
        });
        await AccountService.updateAccountTerminology(newTerminology);
        const newTerms = accountSettings.terminologies.map(t => t.terminologyType === TerminologyType.Treatment
            ? newTerminology
            : t);
        dispatch(setAccountSettings({ ...accountSettings, terminologies: newTerms }));
    }


    return <>
        <div className={classes.header}>
            <Typography className={classes.tabTitle}>
                {t("Evolution notes")}
            </Typography>
        </div>
        <SectionList
            sectionType={SectionType.EvolutionNoteForm}
            sections={evolutionNoteSections}
            setSections={setEvolutionNoteSections} />
        <Section
            key={userTreatmentSection.id}
            sections={[userTreatmentSection]}
            section={userTreatmentSection}
            customNote={
                <div className={classes.treatmentNoteBox}>
                    <Trans 
                        className={classes.treatmentNoteLabel}
                        i18nKey="The Treatment contains the indications for medications or care prescribed for the patient. It also includes information about the specialist and the patient."
                        t={t}
                        values={{ "treatment": treatmentTerm }} />
                </div>}
            customHeaderButton={<Button
                onClick={() => setOpenTreatmentPdfConfigurationModal(true)}
                className={classes.headerButton}
            >
                <SectionGearIcon />
            </Button>}
            setSection={(updatedSection) => setUserTreatmentSection(updatedSection)}
            onEdit={async () => { await handleChangeTreatmentTerminology(userTreatmentSection) }}
        />
        <TreatmentDocumentConfigurationModal 
            isOpen={openTreatmentPdfConfigurationModal}
            closeModal={() => setOpenTreatmentPdfConfigurationModal(false)}
        />
    </>
};

export default EvolutionNotes;
