import React from "react";

import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";

import { InputSearchProps } from "./props";
import useStyles from "./css";
import { CrossIcon, SearchIcon } from "../../../assets/icons";

const InputSearch = (props: InputSearchProps) => {
  
  const classes = useStyles();
  const { placeholder, noBorder, onSearch, onReset, inputSearchClass, rootClass, value, ...params } = props;

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter" && onSearch) {
      onSearch();
    }
  };

  const getEndAdornmentComponent = () => {
    if (onSearch && onReset && value && value.length > 0) {
      return <IconButton onClick={() => onReset()}>
        <CrossIcon
          viewBox="0 0 14 14"
          style={{ width: 14, height: 14 }}
        />
      </IconButton>
    }

    return (
      <IconButton 
        className={classes.searchIcon}
        onClick={() => {
        if (onSearch)
          onSearch()
      }}
      >
        <SearchIcon />
      </IconButton>
    )
  }

  return (
    <TextField
      {...params}
      onKeyPress={handleKeyPress}
      className={inputSearchClass ?? classes.inputSearchClass}
      placeholder={placeholder}
      value={value ?? undefined}
      InputProps={{
        ...params.InputProps,
        disableUnderline: true,
        className: `${noBorder ? classes.noBorder : "" } ${rootClass ? rootClass : classes.root}`,
        classes: { focused: !noBorder ? classes.inputFocused : undefined },
        type: "text",
        endAdornment: (
          <InputAdornment position="end">
            {getEndAdornmentComponent()}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default InputSearch;
