import { ClinicalHistoryFieldAndValue } from "./clinical-history-field-and-value";

export class UpdatePatientClinicalHistoryFieldValueRequest {
    customerId: string;
    fieldAndValue: ClinicalHistoryFieldAndValue;

    constructor(x?: any) {
        if (typeof x === "object") {
            this.customerId = x.customerId;
            this.fieldAndValue = x.fieldAndValue;
        } else {
            this.customerId = "";
            this.fieldAndValue = new ClinicalHistoryFieldAndValue();
        }
    }
}