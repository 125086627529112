import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const AdminIcon = (props: any) => {
  const { style, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{fontSize: '16px'}}
      viewBox="0 0 16 16"
    >
      <path
        d="M7 8C9.1875 8 11 6.21875 11 4C11 1.8125 9.1875 0 7 0C4.78125 0 3 1.8125 3 4C3 6.21875 4.78125 8 7 8ZM9.96875 9.03125L8.5 15L7.5 10.75L8.5 9H5.5L6.5 10.75L5.5 15L4 9.03125C1.75 9.125 0 10.9688 0 13.2188V14.5C0 15.3438 0.65625 16 1.5 16H12.5C13.3125 16 14 15.3438 14 14.5V13.2188C14 10.9688 12.2188 9.125 9.96875 9.03125Z"
        fill={style?.color || "#919CA5"}
      />
    </SvgIcon>
  )
};
export default AdminIcon;
