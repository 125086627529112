export enum NotificationType {
  UnreadMessages = 0,
  CustomerRequiresAttention,
  NewAppointment,
  AppointmentConfirmedByCustomer,
  ProofOfPayment,
  AppointmentModifiedByCustomer,
  AppointmentCancelledByCustomer,
  CustomerRequiresQuote,
  ModifiedAppointment
}