import React, { useState } from "react";

import { useTranslation } from "react-i18next";
import moment from "moment";

import { useAppSelector } from "../../../../redux/hooks";
import { selectAccountSettings, selectUserInfo } from "../../../../redux/store";

import { Backdrop, Button, Drawer, Fade, Modal, Tab, Tabs, Typography } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { ArrowBackIos } from "@material-ui/icons";
import RadioGroup from "@material-ui/core/RadioGroup";

import { CashRegisterSidebarProps } from "./props";
import useStyles from "./css";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  BankIcon,
  CashIcon,
  CashRegisterIcon,
  CreditCardIcon,
  CrossIcon,
  MercadoPagoIcon,
  OxxoIcon
} from "../../../../assets/icons";
import CashStateHeader from "../CashStateHeader/cash-state-header";
import useCurrencyValueTemplate from "../../../../hooks/useCurrencyValueTemplate";
import { CashRegisterMovement } from "../../../../models/cash-register-movement";
import InputCurrency from "../../../common/InputCurrency";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "../../../common/Radio";
import Textarea from "../../../common/Textarea";
import { CloseCashRegisterRequest } from "../../../../models/close-cash-register-request";
import { CashRegisterMovementType } from "../../../../models/enums/cash-register-movement-type";
import { RegisterCashRegisterMovementRequest } from "../../../../models/register-cash-register-movement-request";
import { PaymentMethodType } from "../../../../models/enums/payment-method-type";


export default function CashRegisterSidebar(props: CashRegisterSidebarProps) {

  const { open, setOpen, onCashRegisterClose, onRegisterNewMovement, state, history, onClose } = props;

  const [mode, setMode] = useState<"main" | "newMovement" | "cashClosing">("main");
  const [activeTab, setActiveTab] = useState<"cash" | "history">("cash");
  const [goBackModalIsOpen, setGoBackModalIsOpen] = useState<boolean>(false);
  const [cancelCashClosureModalIsOpen, setCancelCashClosureModalIsOpen] = useState<boolean>(false);

  const [newMovementType, setNewMovementType] = useState<CashRegisterMovementType>(CashRegisterMovementType.Deposit);
  const [newMovementAmount, setNewMovementAmount] = useState<number>(0);
  const [newMovementComments, setNewMovementComments] = useState<string>("");

  const [cashAtTheEndOfDay, setCashAtTheEndOfDay] = useState<number>(state.cashNow);
  const [withdrawalAmount, setWithdrawalAmount] = useState<number>(0);
  const [closingCashComments, setClosingCashComments] = useState<string>("");

  const userInfo = useAppSelector(selectUserInfo);
  const isUserAbleToOpenAndCloseCash = userInfo.canOpenCloseCashRegister;
  const isUserAbleToRegisterMovements = userInfo.canMakeCashRegisterMovements;

  const accountSettings = useAppSelector(selectAccountSettings)
  const currencyTemplate = accountSettings.currencyTemplate;
  const templatedCurrency = useCurrencyValueTemplate(currencyTemplate);

  const paymentMethods = accountSettings.paymentMethods.map(x => x.key);

  function paymentMethodsContains(paymentMethod: PaymentMethodType): boolean {
    return paymentMethods.findIndex(x => x === paymentMethod) !== -1
  }

  const [hasClosingCashCommentsBeenFocused, setClosingCashCommentsBeenFocused] = useState<boolean>(false);
  const [hasNewMovementCommentsBeenFocused, setNewMovementCommentsBeenFocused] = useState<boolean>(false);
  const [hasNewMovementAmountBeenFocused, setNewMovementAmountBeenFocused] = useState<boolean>(false);

  const { t } = useTranslation("general");
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const classes = useStyles();

  const dateTimeFormat = localeApp === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY";
  const timeFormat = "HH:mm";

  const handleTabChange = (event: any, newValue: "cash" | "history") => {
    setActiveTab(newValue);
  };

  function handleCloseModal() {
    handleOnClose();
  }

  function handleOnClose() {
    if (mode === "newMovement" && (newMovementAmount !== 0 || newMovementComments !== "")) {
      setGoBackModalIsOpen(true);
      return;
    }
    if (mode === "cashClosing" && (withdrawalAmount !== 0 || closingCashComments !== "")) {
      setCancelCashClosureModalIsOpen(true);
      return;
    }
    if (onClose) onClose();
    setOpen(false);
    setMode("main");
    resetAllInputs();
  }

  function handleRegisterMovementClick() {
    resetNewMovementInputs()
    setMode("newMovement");
  }

  function handleCloseCashRegisterClick() {
    resetCashClosingInputs()
    setMode("cashClosing");
  }

  function handleGoBackClick() {
    if (mode === "newMovement" && (newMovementAmount !== 0 || newMovementComments !== "")) {
      setGoBackModalIsOpen(true);
      return;
    }
    if (mode === "cashClosing" && (withdrawalAmount !== 0 || closingCashComments !== "")) {
      setCancelCashClosureModalIsOpen(true);
      return;
    }
    resetComponent();
  }

  function resetComponent() {
    setMode("main");
    resetAllInputs();
  }

  async function handleRegisterMovementButtonClick() {
    await onRegisterNewMovement(new RegisterCashRegisterMovementRequest(
      {
        type: newMovementType,
        amount: newMovementAmount,
        time: new Date(),
        chargingUserId: userInfo.userId,
        chargingUserName: userInfo.fullName,
        notes: newMovementComments
      }
    ));
    resetComponent()
  }

  async function handleCloseCashButtonClick() {
    await onCashRegisterClose(new CloseCashRegisterRequest(
      {
        cashAtTheEndOfTheDay: cashAtTheEndOfDay,
        chargingUserId: userInfo.userId,
        chargingUserName: userInfo.fullName,
        withdrawalByAdministrator: withdrawalAmount,
        notes: closingCashComments
      }));
  }

  function isRegisterMovementButtonDisabled(): boolean {
    return newMovementAmount === 0 || newMovementComments === "";
  }

  function isCloseCashButtonDisabled(): boolean {
    return withdrawalAmount > cashAtTheEndOfDay || closingCashComments === "";
  }

  function resetAllInputs() {
    resetNewMovementInputs();
    resetCashClosingInputs();
  }

  function resetNewMovementInputs() {
    setNewMovementType(CashRegisterMovementType.Deposit);
    setNewMovementAmount(0);
    setNewMovementComments("");
    setNewMovementCommentsBeenFocused(false);
    setNewMovementAmountBeenFocused(false)
  }

  function resetCashClosingInputs() {
    setCashAtTheEndOfDay(state.cashNow);
    setWithdrawalAmount(0)
    setClosingCashComments("");
    setClosingCashCommentsBeenFocused(false);
  }

  function addedCurrencyValue(value: number) {
    if (value > 0) return `+${templatedCurrency(value)}`
    return templatedCurrency(value)
  }

  function subtractedCurrencyValue(value: number) {
    if (value > 0) return `-${templatedCurrency(value)}`
    return templatedCurrency(value)
  }

  function getCurrencyFormattedNegativeString(actualCash: number, inputValue: number) {
    const difference = actualCash - inputValue;
    return difference < 0 ? `-${templatedCurrency(Math.abs(difference))}` : templatedCurrency(difference);
  }

  function countNextDayCash(actualCash: number, withdrawalAmount: number) {
    return actualCash - withdrawalAmount;
  }
  const nextCashOpening = countNextDayCash(cashAtTheEndOfDay, withdrawalAmount);

  function getChipByMovementType(type: CashRegisterMovementType) {
    switch (type) {
      case CashRegisterMovementType.CashOpening: return <div className={`${classes.historyChip} ${classes.historyCashOpeningChip}`}>{t("Open cash register")}</div>
      case CashRegisterMovementType.Deposit: return <div className={`${classes.historyChip} ${classes.historyDepositChip}`}>{t("Deposit")}</div>
      case CashRegisterMovementType.Withdrawal: return <div className={`${classes.historyChip} ${classes.historyWithdrawalChip}`}>{t("Withdrawal")}</div>
    }
  }

  function getTemplateByMovementType(type: CashRegisterMovementType, amount: number) {
    switch (type) {
      case CashRegisterMovementType.CashOpening: return templatedCurrency(amount)
      case CashRegisterMovementType.Deposit: return addedCurrencyValue(amount)
      case CashRegisterMovementType.Withdrawal: return subtractedCurrencyValue(amount)
    }
  }

  function handleNewMovementCommentsChange(event: any) {
    setNewMovementComments(event.target.value)
  }

  function handleClosingCashCommentsChange(event: any) {
    setClosingCashComments(event.target.value)
  }

  function getOrderedMovements() {
    const movements = [...history.movements];
    return movements.sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime());
  }

  const CrossCloseButton = () =>
    <div className={classes.closeIcon}>
      <CrossIcon
        style={{
          width: 16,
          height: 16
        }}
        viewBox="0 0 16 16"
        onClick={handleCloseModal}
      />
    </div>

  const GoBackHeader = () =>
    <div className={classes.goBackHeader}>
      <div
        className={classes.goBackButton}
        onClick={handleGoBackClick}
      >
        <Typography className={classes.backButtonText}><ArrowBackIos className={classes.backButtonIcon} /> {t("Go back")}</Typography>
      </div>

    </div>

  const DateHeader = () =>
    <>
      <div className={classes.headerDate}>
        <div className={classes.headerDateText}>{t("Date") + ":"}<b className={classes.headerDateDate}>{moment(new Date()).format(dateTimeFormat)}</b></div>
        <CrossCloseButton />
      </div>
      <hr className={classes.divider} />
    </>


  const TabsHeader = () =>
    <TabContext
      value={activeTab}
    >
      <Tabs
        className={classes.tabs}
        value={activeTab}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
      >
        <Tab
          className={classes.tab}
          label={t("CashRegister")}
          value="cash"
        />
        <Tab
          className={classes.tab}
          label={t("Movements history")}
          value="history"
        />
      </Tabs>
    </TabContext>

  const CashDataHeader = () =>
    <>
      <CashStateHeader
        state={state}
        currencyTemplate={currencyTemplate}
      />
      <hr className={classes.divider} />
    </>

  const CashDetailsBlock = () =>
    <div className={classes.cashDetailsBlock}>
      <div className={classes.cashDetailsHeader}>{t("Details") + ":"}</div>
      <div className={classes.cashDetailsRow}>
        <div className={classes.cashDetailsRowName}><CashRegisterIcon className={classes.cashDetailsIcon} color={"#5C6477"} style={{ width: 24, height: 14 }} /> {t("Initial cash")}</div>
        <div className={`${classes.cashDetailsRowValue} ${classes.mainColorText}`}>{templatedCurrency(state.initialCash)}</div>
      </div>
      {
        paymentMethodsContains(PaymentMethodType.Cash) &&
        <div className={classes.cashDetailsRow}>
          <div className={classes.cashDetailsRowName}><CashIcon className={classes.cashDetailsIcon} color={"#5C6477"} /> {t("Cash payments")}</div>
          <div className={classes.cashDetailsRowValue}>{addedCurrencyValue(state.cashPayments)}</div>
        </div>
      }
      {
        paymentMethodsContains(PaymentMethodType.CreditCard) &&
        <div className={classes.cashDetailsRow}>
          <div className={classes.cashDetailsRowName}><CreditCardIcon className={classes.cashDetailsIcon} color={"#5C6477"} /> {t("Credit/debit card payments")}</div>
          <div className={classes.cashDetailsRowValue}>{addedCurrencyValue(state.creditCardPayments)}</div>
        </div>
      }
      {
        paymentMethodsContains(PaymentMethodType.BankTransfer) &&
        <div className={classes.cashDetailsRow}>
          <div className={classes.cashDetailsRowName}><BankIcon className={classes.cashDetailsIcon} color={"#5C6477"} /> {t("Bank transfers")}</div>
          <div className={classes.cashDetailsRowValue}>{addedCurrencyValue(state.bankTransferPayments)}</div>
        </div>
      }
      {
        paymentMethodsContains(PaymentMethodType.Oxxo) &&
        <div className={classes.cashDetailsRow}>
          <div className={classes.cashDetailsRowName}><OxxoIcon className={classes.cashDetailsIcon} color={"#5C6477"} /> {t("Payments with Oxxo")}</div>
          <div className={classes.cashDetailsRowValue}>{addedCurrencyValue(state.oxxoPayments)}</div>
        </div>
      }
      {
        paymentMethodsContains(PaymentMethodType.MercadoPago) &&
        <div className={classes.cashDetailsRow}>
          <div className={classes.cashDetailsRowName}><MercadoPagoIcon className={classes.cashDetailsIcon} color={"#5C6477"} /> {t("Payments with Mercado pago")}</div>
          <div className={classes.cashDetailsRowValue}>{addedCurrencyValue(state.mercadoPagoPayments)}</div>
        </div>
      }
      <div className={classes.cashDetailsRow}>
        <div className={classes.cashDetailsRowName}><ArrowRightIcon className={classes.cashDetailsIcon} color={"#5C6477"} style={{ width: 24, height: 14 }} /> {t("Deposit by administrator")}</div>
        <div className={classes.cashDetailsRowValue}>{addedCurrencyValue(state.depositByAdministrator)}</div>
      </div>
      <div className={classes.cashDetailsRow}>
        <div className={classes.cashDetailsRowName}><ArrowLeftIcon className={classes.cashDetailsIcon} color={"#5C6477"} style={{ width: 24, height: 14 }} /> {t("Withdrawal by administrator")}</div>
        <div className={classes.cashDetailsRowValue}>{subtractedCurrencyValue(state.withdrawalByAdministrator)}</div>
      </div>
    </div>

  const HistoryMovementBlock = (movement: CashRegisterMovement) =>
    <div className={classes.historyMovementBlock}>
      <div className={classes.historyMovementRow}>
        <div className={classes.historyMovementTitle}>{t("Movement") + ":"}</div>
        {getChipByMovementType(movement.type)}
      </div>
      <div className={classes.historyMovementRow}>
        <div className={classes.historyMovementTitle}>{t("Amount") + ":"}</div>
        <div className={classes.currencyValue}>{getTemplateByMovementType(movement.type, movement.amount)}</div>
      </div>
      <div className={classes.historyMovementRow}>
        <div className={classes.historyMovementTitle}>{t("User") + ":"}</div>
        <div className={classes.historyMovementValue}>{movement.userName}</div>
      </div>
      <div className={classes.historyMovementRow}>
        <div className={classes.historyMovementTitle}>{t("Time") + ":"}</div>
        <div className={classes.historyMovementValue}>{moment(movement.time).format(timeFormat)}</div>
      </div>
      <div className={classes.historyMovementRow}>
        <div className={classes.historyMovementTitle}>{t("Notes") + ":"}</div>
        <div className={classes.historyMovementValue}>{movement.notes ?? "--"}</div>
      </div>
      <div className={classes.historyDivider} />
    </div>

  const RequiredFields = () =>
    <div className={classes.requiredFields}>{t("Required fields")}<span className={classes.dot}>*</span></div>

  const InputLabel = ({ label }: any) =>
    <div className={classes.inputLabel}>{label}<span className={classes.dot}>*</span></div>

  const NumberValue = ({ number }: any) =>
    <div className={classes.numberValue}>{number}</div>

  const CloseCashButton = () =>
    <div className={classes.singleButtonFooter}>
      <Button
        className={`${classes.button} ${classes.redButton}`}
        onClick={async () => { await handleCloseCashButtonClick() }}
        disabled={isCloseCashButtonDisabled()}
        fullWidth
      >
        {t("Close cash register")}
      </Button>
    </div>

  const RegisterMovementButton = () =>
    <div className={classes.singleButtonFooter}>
      <Button
        className={`${classes.button} ${classes.mainButton}`}
        onClick={async () => { await handleRegisterMovementButtonClick() }}
        disabled={isRegisterMovementButtonDisabled()}
        fullWidth
      >
        {t("Register movement")}
      </Button>
    </div>

  const CashContent = () =>
    <div className={classes.content}>
      <CashDataHeader />
      <CashDetailsBlock />
      <ButtonsFooter />
    </div>

  const HistoryContent = () =>
    <div className={classes.content}>
      <div className={classes.historyBlock}>
        {
          getOrderedMovements().map(x =>
            <HistoryMovementBlock
              id={x.id}
              amount={x.amount}
              type={x.type}
              time={x.time}
              userName={x.userName}
              notes={x.notes}
            />
          )
        }
      </div>
    </div>

  const ButtonsFooter = () =>
    <>
      {(isUserAbleToOpenAndCloseCash || isUserAbleToRegisterMovements) &&
        <div className={classes.footer}>
          {
            isUserAbleToRegisterMovements &&
            <Button
              className={`${classes.button} ${classes.mainButton}`}
              onClick={handleRegisterMovementClick}
              fullWidth={!isUserAbleToOpenAndCloseCash}
            >
              {t("New movement")}
            </Button>
          }
          {
            isUserAbleToOpenAndCloseCash &&
            <Button
              className={`${classes.button} ${classes.redButton}`}
              onClick={handleCloseCashRegisterClick}
              fullWidth={!isUserAbleToRegisterMovements}
            >
              {t("Close cash register")}
            </Button>
          }
        </div>
      }
    </>

  const GoBackModal = () =>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={goBackModalIsOpen}
      onClose={() => setGoBackModalIsOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={goBackModalIsOpen}>
        <div className={classes.cancelModalPaper}>
          <div>
            <div
              className={`${classes.cancelModalCenter} ${classes.cancelModalTitleSpace}`}
            >
              <Typography className={classes.modalTitleText}>
                {t("Cancel new movement registration?")}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={() => setGoBackModalIsOpen(false)}
              >
                {t("Go back")}
              </Button>
              <Button
                className={`${classes.button} ${classes.cancelConfirmation}`}
                onClick={() => {
                  resetComponent();
                  setGoBackModalIsOpen(false);
                }}
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>

  const CancelCashClosureModal = () =>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={cancelCashClosureModalIsOpen}
      onClose={() => setCancelCashClosureModalIsOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={cancelCashClosureModalIsOpen}>
        <div className={classes.cancelModalPaper}>
          <div>
            <div
              className={`${classes.cancelModalCenter} ${classes.cancelModalTitleSpace}`}
            >
              <Typography className={classes.modalTitleText}>
                {t("Cancel cash register closure?")}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={() => setCancelCashClosureModalIsOpen(false)}
              >
                {t("Go back")}
              </Button>
              <Button
                className={`${classes.button} ${classes.cancelConfirmation}`}
                onClick={() => {
                  resetComponent();
                  setCancelCashClosureModalIsOpen(false);
                }}
              >
                {t("Cancel")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>

  return (
    <Drawer
      open={open}
      anchor="right"
      classes={{ paper: classes.drawer }}
      onClose={handleOnClose}
    >
      <div className={classes.root}>
        {
          mode === "main"
            ? <TabsHeader />
            : <GoBackHeader />
        }
        <DateHeader />
        {
          mode === "main" &&
          <TabContext
            value={activeTab}
          >
            <TabPanel
              classes={{
                root: classes.tabPanelRoot
              }}
              value="cash"
            >
              {CashContent()}
            </TabPanel>
            <TabPanel
              value="history"
              classes={{
                root: classes.tabPanelRoot
              }}
            >
              {HistoryContent()}
            </TabPanel>
          </TabContext>
        }
        {
          mode === "newMovement" &&
          <div className={classes.content}>
            <div className={classes.newMovementBlock}>
              <div className={classes.newMovementHeader}>{t("New movement")}</div>
              <InputLabel label={t("Type of movement")} />
              <RadioGroup
                defaultValue={CashRegisterMovementType.Deposit}
                aria-label="movementType"
                name="movementType"
                onChange={(event) => setNewMovementType(+event.target.value)}
                value={newMovementType}
                className={classes.typeOfMovementRadios}
              >
                <FormControlLabel
                  classes={{
                    root: classes.radioLabelRoot,
                    label: classes.radioLabel,
                  }}
                  value={CashRegisterMovementType.Deposit}
                  control={
                    <Radio
                      className={classes.radioGreen}
                      style={{
                        color:
                          newMovementType === CashRegisterMovementType.Deposit
                            ? "#36CE91"
                            : "#5C6477",
                      }}
                    />
                  }
                  label={t("Deposit")}
                />

                <FormControlLabel
                  classes={{
                    root: classes.radioLabelRoot,
                    label: classes.radioLabel,
                  }}
                  value={CashRegisterMovementType.Withdrawal}
                  control={
                    <Radio
                      className={classes.radioRed}
                      style={{
                        color:
                          newMovementType === CashRegisterMovementType.Withdrawal
                            ? "#F15857"
                            : "#5C6477",
                      }}
                    />
                  }
                  label={t("Withdrawal")}
                />
              </RadioGroup>
              <InputLabel label={t("Amount")} />
              <InputCurrency
                value={newMovementAmount}
                setValue={setNewMovementAmount}
                valueTemplate={currencyTemplate}
                onBlur={() => setNewMovementAmountBeenFocused(true)}
                isValid={() => !hasNewMovementAmountBeenFocused || newMovementAmount > 0}
                isZeroAllowed
              />
              <div className={classes.inputSpacing} />
              <InputLabel label={t("Comments")} />
              <Textarea
                value={newMovementComments}
                onChange={handleNewMovementCommentsChange}
                width={452}
                maxLength={180}
                placeholder={t("Write your comments here.")}
                onBlur={() => setNewMovementCommentsBeenFocused(true)}
                isValid={() => !hasNewMovementCommentsBeenFocused || newMovementComments.length > 0}
              />
            </div>
            <RegisterMovementButton />
            <GoBackModal />
          </div>
        }
        {
          mode === "cashClosing" &&
          <div className={classes.content}>
            <CashDataHeader />
            <div className={classes.cashClosingBlock}>
              <RequiredFields />
              <div className={classes.cashClosingInputAndNumberBlock}>
                <div className={classes.cashClosingInputRow}>
                  <div className={classes.cashClosingInputColumn}>
                    <InputLabel label={t("Real cash at the end of the day")} />
                  </div>
                  <div className={classes.cashClosingNumberColumn}>
                    <div className={classes.numberLabel}>{t("Difference")}</div>
                  </div>
                </div>
                <div className={classes.cashClosingInputRow}>
                  <div className={classes.cashClosingInputColumn}>
                    <InputCurrency
                      value={cashAtTheEndOfDay}
                      setValue={setCashAtTheEndOfDay}
                      valueTemplate={currencyTemplate}
                      width={293}
                    />
                  </div>
                  <div className={classes.cashClosingNumberColumn}>
                    <NumberValue number={getCurrencyFormattedNegativeString(state.cashNow, cashAtTheEndOfDay)} />
                  </div>
                </div>
                <div className={classes.cashClosingDivider} />
                <div className={classes.cashClosingInputRow}>
                  <div className={classes.cashClosingInputColumn}>
                    <InputLabel label={t("Withdrawal by administrator")} />
                  </div>
                  <div className={classes.cashClosingNumberColumn}>
                    <div className={classes.numberLabel}>{t("Next cash opening")}</div>
                  </div>
                </div>
                <div className={classes.cashClosingInputRow}>
                  <div className={classes.cashClosingInputColumn}>
                    <InputCurrency
                      value={withdrawalAmount}
                      setValue={setWithdrawalAmount}
                      valueTemplate={currencyTemplate}
                      width={293}
                      isValid={() => withdrawalAmount <= cashAtTheEndOfDay}
                      isZeroAllowed
                    />
                  </div>
                  <div className={classes.cashClosingNumberColumn}>
                    <NumberValue number={getCurrencyFormattedNegativeString(cashAtTheEndOfDay, withdrawalAmount)} />
                  </div>
                </div>
                <div className={classes.commentsLabel}>
                  <InputLabel label={t("Comments to cash closing")} />
                </div>
                <Textarea
                  value={closingCashComments}
                  onChange={handleClosingCashCommentsChange}
                  width={452}
                  maxLength={180}
                  placeholder={t("Write your comments here. Eg: There is $500.00 missing for the payment of electricity.")}
                  onBlur={() => setClosingCashCommentsBeenFocused(true)}
                  isValid={() => !hasClosingCashCommentsBeenFocused || closingCashComments.length > 0}
                />
              </div>
            </div>
            <CloseCashButton />
            <CancelCashClosureModal />
          </div>
        }
      </div>
    </Drawer>
  );
}