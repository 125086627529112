import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  label: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    fontWeight: "bold",
    display: "block",
    marginBottom: "0.5rem",
  },
  disabled: {
    opacity: "0.42",
  },
}));

export default useStyles;
