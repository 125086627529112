import { makeStyles } from "@material-ui/core/styles";

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      backgroundColor: theme.colors.white,
      position: "relative"
    },
    input: {
      backgroundColor: theme.colors.white,
      height: 65,
      padding: "13px 0px 13px 12px",
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      width: "100%",
      fontFamily: "Roboto, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "14px",
      color: theme.colors.grey6,
      "&::placeholder": {
        fontWeight: "normal",
        color: "#676767",
      },
      marginTop: 10
    },
    inputFocused: {
      border: "solid 1px #6462F3",
      padding: "14px 0px 14px 12px",
      width: "100%",
    },
    inputDisabled: {
      color: "#676767",
    },
    counter: {
      position: "absolute",
      bottom: 7,
      right: 18,
      fontSize: 12,
      color: "#B5B9C1",
      padding: "5px",
    },
    inputError: {
      color: theme.colors.clearRed,
      border: "solid",
      borderWidth: 1,
      borderColor: theme.colors.clearRed
    },
  }));

export default useStyles;
