import React from "react";

import { useTranslation } from "react-i18next";

import { GroupIcon, StatusIcon } from "../../../assets/icons";
import CustomerClassificationItem from "./../CustomerClassificationItem";
import { useStyles } from "./css";
import { CustomerClassificationProps } from "./props";


export default function CustomerClassification(props: CustomerClassificationProps) {
  
  const { classification } = props;
  
  const { t } = useTranslation(["general"]);

  const classes = useStyles();
  
  return (
    <div className={classes.root}>
      <CustomerClassificationItem
        title={t("Registered")}
        value={classification.total}
        icon={GroupIcon}
      />
      <div className={classes.line} />
      <CustomerClassificationItem
        title={t("Frequent")}
        value={classification.frequent}
        icon={() => <StatusIcon status={2} />}
      />
      <CustomerClassificationItem
        title={t("Infrequent")}
        value={classification.infrequent}
        icon={() => <StatusIcon status={3} />}
      />
      <CustomerClassificationItem
        title={t("Inactive")}
        value={classification.inactive}
        icon={() => <StatusIcon status={1} />}
      />
    </div>
  );
}