import { ApiClient } from "./api-client";
import { ClinicalHistoryCommunicationSettings } from "../models/clinical-history-communication-settings-model";
import { CopyEstablishmentSettingsModel } from "../models/copy-establishment-settings-model";

export class ClinicalHistoryCommunicationSettingsService {
  
  private static baseUrl: string = "/account";
  
  static async updateClinicalHistoryCommunicationSettings(communicationSettingsModel: ClinicalHistoryCommunicationSettings): Promise<ClinicalHistoryCommunicationSettings> {
    const data = await ApiClient.post(`${this.baseUrl}/updateclinicalhistorycommunicationsettings`, communicationSettingsModel);
    return new ClinicalHistoryCommunicationSettings(data.clinicalHistoryCommunicationSettings);
  }
  
  static async getClinicalHistoryCommunicationSetting(establishmentAccountId?: string | null | undefined): Promise<ClinicalHistoryCommunicationSettings> {
    const establishmentAccountIdRequestValue = establishmentAccountId === null || establishmentAccountId === undefined 
    ? "" 
    : establishmentAccountId;
    const data = await ApiClient.get(`${this.baseUrl}/getclinicalhistorycommunicationsettings?EstablishmentAccountId=${establishmentAccountIdRequestValue}`);
    return new ClinicalHistoryCommunicationSettings(data.clinicalHistoryCommunicationSettings);
  }
  
  static async copyClinicalHistorySetting(copyModel: CopyEstablishmentSettingsModel): Promise<boolean> {
    return await ApiClient.post(`${this.baseUrl}/copyclinicalhistorysetting`, copyModel).then(
      (data) => (data.succeeded)
    );
  }
}
