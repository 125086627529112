import React from 'react';

import SvgIcon from "@material-ui/core/SvgIcon";


export default function DownloadCloudIcon(props: any) {

  const { style, viewBox, color, ...otherProps } = props;

  return (
    <SvgIcon
      {...otherProps}
      style={style || { width: 24, height: 24 }}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path
        d="M17.6836 10.957C17.793 10.6562 17.875 10.3555 17.875 10C17.875 8.55078 16.6992 7.375 15.25 7.375C14.7031 7.375 14.1836 7.53906 13.7734 7.83984C13.0352 6.52734 11.6133 5.625 10 5.625C7.56641 5.625 5.625 7.59375 5.625 10C5.625 10.082 5.625 10.1641 5.625 10.2461C4.09375 10.7656 3 12.2422 3 13.9375C3 16.125 4.75 17.875 6.9375 17.875H17C18.9141 17.875 20.5 16.3164 20.5 14.375C20.5 12.707 19.2969 11.2852 17.6836 10.957ZM14.0469 13.3906L11.1758 16.2617C11.0117 16.4258 10.7109 16.4258 10.5469 16.2617L7.67578 13.3906C7.40234 13.1172 7.59375 12.625 7.97656 12.625H9.78125V9.5625C9.78125 9.34375 9.97266 9.125 10.2188 9.125H11.5312C11.75 9.125 11.9688 9.34375 11.9688 9.5625V12.625H13.7461C14.1289 12.625 14.3203 13.1172 14.0469 13.3906Z"
        fill={color || style?.color || "#F6F6F6"}
      />
    </SvgIcon>
  )
}