import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DoorIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M11.7857 0C12.0699 0 12.3424 0.112381 12.5433 0.312419C12.7443 0.512458 12.8571 0.783769 12.8571 1.06667V14.9333H14.4643C14.6064 14.9333 14.7426 14.9895 14.8431 15.0895C14.9436 15.1896 15 15.3252 15 15.4667C15 15.6081 14.9436 15.7438 14.8431 15.8438C14.7426 15.9438 14.6064 16 14.4643 16H0.535714C0.393634 16 0.257373 15.9438 0.156907 15.8438C0.0564411 15.7438 0 15.6081 0 15.4667C0 15.3252 0.0564411 15.1896 0.156907 15.0895C0.257373 14.9895 0.393634 14.9333 0.535714 14.9333H2.14286V1.06667C2.14286 0.783769 2.25574 0.512458 2.45667 0.312419C2.6576 0.112381 2.93012 0 3.21429 0H11.7857ZM9.64286 9.6C9.92702 9.6 10.1995 9.48762 10.4005 9.28758C10.6014 9.08754 10.7143 8.81623 10.7143 8.53333C10.7143 8.25044 10.6014 7.97913 10.4005 7.77909C10.1995 7.57905 9.92702 7.46667 9.64286 7.46667C9.3587 7.46667 9.08617 7.57905 8.88524 7.77909C8.68431 7.97913 8.57143 8.25044 8.57143 8.53333C8.57143 8.81623 8.68431 9.08754 8.88524 9.28758C9.08617 9.48762 9.3587 9.6 9.64286 9.6Z" />
        </SvgIcon>
    );
};

export default DoorIcon;
