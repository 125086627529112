import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ConversationIcon = (props: any) => {
    return (
        <SvgIcon {...props} viewBox={"0 0 16 16"}>
            <path d="M10.2665 0.666992H1.73317C1.1465 0.666992 0.671837 1.14699 0.671837 1.73366L0.666504 11.3337L2.79984 9.20033H10.2665C10.8532 9.20033 11.3332 8.72033 11.3332 8.13366V1.73366C11.3332 1.14699 10.8532 0.666992 10.2665 0.666992ZM2.79984 4.40033H9.19984V5.46699H2.79984V4.40033ZM7.0665 7.06699H2.79984V6.00033H7.0665V7.06699ZM9.19984 3.86699H2.79984V2.80033H9.19984V3.86699Z" />
        </SvgIcon>
    )
};

export default ConversationIcon;
