export class CloseCashRegisterRequest {
  cashAtTheEndOfTheDay: number;
  withdrawalByAdministrator: number;
  chargingUserId: string;
  chargingUserName: string;
  notes: string;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.cashAtTheEndOfTheDay = x.cashAtTheEndOfTheDay;
      this.chargingUserId = x.chargingUserId;
      this.chargingUserName = x.chargingUserName;
      this.withdrawalByAdministrator = x.withdrawalByAdministrator;
      this.notes = x.notes;
    } else {
      this.cashAtTheEndOfTheDay = 0.0;
      this.chargingUserId = "";
      this.chargingUserName = "";
      this.withdrawalByAdministrator = 0.0;
      this.notes = "";
    }
  }
}