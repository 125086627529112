import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #dbdcdd",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: "40px 40px 48px",
    width: 812
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
  },
  modalTitle: {
    width: "100%",
  },
  modalTitleText: {
    fontSize: "14px",
    fontWeight: "bold",
    color: "#5C6477",
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  goBack: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  cancelConfirmation: {
    background: theme.colors.clearGreen,
    color: "#F6F6F6",
    "&:hover": {
      background: theme.colors.clearGreen,
    },
  },
  createCustomer: {
    background: "#36CE91",
    color: "#F6F6F6",
    "&:hover": {
      background: "#36CE91",
    },
  },
  createDisabled: {
    color: "#F6F6F6 !important",
    opacity: "0.4",
  },
  cancelModalPaper: {
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid red",
    borderRadius: "12px",
    boxShadow: theme.shadows[5],
    padding: "24px",
    width: 472,
    minHeight: 153,
  },
  cancelModalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  cancelModalTitleSpace: {
    margin: "24px 0px 8px 0px",
  },
}));

export default useStyles;
