import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

import { Color } from "../../constants/colors";


export default function VideoCameraEmptyIcon(props: any) {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 16, height: 16, ...style }}
      viewBox={viewBox || "0 0 16 16"}
    >
      <path
        d="M14.6345 3.08853L12.401 5.48149V3.82031C12.401 3.45464 12.2638 3.1299 11.998 2.87913C11.757 2.62819 11.4462 2.5 11.099 2.5H1.76562C1.41197 2.5 1.0961 2.63298 0.853222 2.89322C0.612304 3.15134 0.5 3.4726 0.5 3.82031V12.1797C0.5 12.5274 0.612304 12.8487 0.853222 13.1068C1.0961 13.367 1.41197 13.5 1.76562 13.5H11.099C11.4462 13.5 11.757 13.3718 11.998 13.1209C12.2638 12.8701 12.401 12.5454 12.401 12.1797V10.5185L14.6345 12.9115L15.5 13.8388V12.5703V3.42969V2.16118L14.6345 3.08853Z"
        fill="#FFFFFF"
        stroke={style?.color || Color.gray5}
      />
    </SvgIcon>
  );
}