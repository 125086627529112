import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";


export default function ArrowLeftIcon(props: any) {
  
  const { style, color, ...otherProps } = props;
  
  return (
    <SvgIcon
      {...otherProps}
      style={style}
      viewBox="0 0 17 14"
    >
      <path
        d="M0.445549 7.98216L5.46189 12.9985C5.68661 13.2232 5.99139 13.3495 6.30919 13.3495C6.62699 13.3495 6.93177 13.2232 7.15648 12.9985C7.3812 12.7738 7.50745 12.469 7.50745 12.1512C7.50745 11.8334 7.3812 11.5286 7.15648 11.3039L4.19438 8.32432H15.1512C15.469 8.32432 15.7739 8.19805 15.9986 7.97328C16.2234 7.74852 16.3497 7.44367 16.3497 7.12581C16.3497 6.80794 16.2234 6.50309 15.9986 6.27833C15.7739 6.05356 15.469 5.92729 15.1512 5.92729L4.19438 5.92729L7.15648 2.96581C7.3812 2.73878 7.50652 2.43177 7.50489 2.11234C7.50325 1.7929 7.37478 1.4872 7.14774 1.26248C6.92071 1.03776 6.6137 0.912439 6.29427 0.914078C5.97483 0.915718 5.66913 1.04419 5.44441 1.27122L0.445549 6.27008C0.217413 6.4968 0.0881381 6.8045 0.0859008 7.12612C0.0830977 7.28596 0.113631 7.44463 0.175553 7.59202C0.237475 7.73941 0.329428 7.87228 0.445549 7.98216V7.98216Z"
        fill={color || style?.color || "#919CA5"}
      />
    </SvgIcon>
  )
}