import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    verticalAlign: "middle",
    display: "inline-flex",
    margin: "0px 0px 24px 0px",
  },
  titles: {
    color: "#919CA5",
  },
  icons: {
    display: "flex",
    alignItems: "center",
    margin: "7px 0px 0px 0px"
  },
  icon: {
    marginRight: 6,
  },
  classification: {
    width: 104,
    margin: "0px 25px 0px 0px",
  }
}));