export interface ICashRegisterCurrentStateModel {
  initialCash: number;
  cashNow: number;
  paymentsReceived: number;
  cashPayments: number;
  creditCardPayments: number;
  bankTransferPayments: number;
  oxxoPayments: number;
  mercadoPagoPayments: number;
  depositByAdministrator: number;
  withdrawalByAdministrator: number;
}

export class CashRegisterCurrentStateModel implements ICashRegisterCurrentStateModel {
  
  initialCash: number;
  cashNow: number;
  paymentsReceived: number;
  cashPayments: number;
  creditCardPayments: number;
  bankTransferPayments: number;
  oxxoPayments: number;
  mercadoPagoPayments: number;
  depositByAdministrator: number;
  withdrawalByAdministrator: number;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.initialCash = x.initialCash;
      this.cashNow = x.cashNow;
      this.paymentsReceived = x.paymentsReceived ?? 0.0;
      this.cashPayments = x.cashPayments ?? 0.0;
      this.creditCardPayments = x.creditCardPayments ?? 0.0;
      this.bankTransferPayments = x.bankTransferPayments ?? 0.0;
      this.oxxoPayments = x.oxxoPayments ?? 0.0;
      this.mercadoPagoPayments = x.mercadoPagoPayments ?? 0.0;
      this.depositByAdministrator = x.depositByAdministrator ?? 0.0;
      this.withdrawalByAdministrator = x.withdrawalByAdministrator ?? 0.0;
    }
    else {
      this.initialCash = 0.0;
      this.cashNow = 0.0;
      this.paymentsReceived = 0.0;
      this.cashPayments = 0.0;
      this.creditCardPayments = 0.0;
      this.bankTransferPayments = 0.0;
      this.oxxoPayments = 0.0;
      this.mercadoPagoPayments = 0.0;
      this.depositByAdministrator = 0.0;
      this.withdrawalByAdministrator = 0.0;
    }
  }
}