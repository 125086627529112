import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  message: {
    height: 32,
    background: "#fff4d4",
    textAlign: "center",
    paddingTop: 5,
    position: "fixed",
    width: "100%",
    zIndex: 9
  },
  messageBack: {
    height: 32,
    background: "rgba(255, 198, 38, 0.2)",
    textAlign: "center",
    paddingTop: 5,
  },
  messageIcon: {
    color: "#B49A48", 
    display: "inline-block",
    marginRight: 10
  },
  messageLabel: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    alignItems: "center",
    color: "#B49A48",
    display: "inline-block"
  }
})
);

export default useStyles;