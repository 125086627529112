import React from "react";

import UserScheduleItem from "../UserScheduleItem";
import { UserModalSchedulesProps } from "./props";
import { UserScheduleDay } from "../../../../../models/user-schedule-info";

export default function UserModalSchedules(props: UserModalSchedulesProps) {
  
  const { schedule, setSchedule } = props;
    
  const renderScheduleItems = (day: UserScheduleDay, index: number) => {

    const setDay = (day: UserScheduleDay) => {
      const newSchedule = [...schedule.schedule];
      newSchedule[index] = day;
      setSchedule({...schedule, schedule: newSchedule });
    }

    const copyToAll = (day: UserScheduleDay) => {
      const newSchedule = [...schedule.schedule].map(item => {
        if (!item.isActive) return item;
        return new UserScheduleDay(item.dayOfWeek, day.windows, true);
      });
      setSchedule({...schedule, schedule: newSchedule });
    }

    return (
      <UserScheduleItem
        key={`user_schedule_item_${index}`}
        day={day}
        setDay={setDay}
        copyToAll={copyToAll}
      />
    );
  }
  
  return (
    <>
      {schedule.schedule.map(renderScheduleItems)}
    </>
  );
}