import React from "react";

import { FormControlLabel, Radio } from "@material-ui/core";

import useStyles from "./css";
import { RadioWithTextProps } from "./props";


export default function RadioWithText(props: RadioWithTextProps) {
  
  const { value, checked, onCheck, text, disabled } = props;
  const classes = useStyles();
  
  function handleChecking() {
    onCheck();
  }
  
  return (
    <div className={classes.root}>
      <Radio
        checked={checked}
        onChange={handleChecking}
        color={checked ? "primary" : "default"}
        classes={{
          root: classes.radio,
          checked: classes.checked
        }}
        disabled={disabled}
      />
      {typeof text === "string" 
        ? <div className={`${classes.text} ${checked ? classes.checked : ''}`}>{text}</div>
        : text ?? null
      }
    </div>
  );
}