import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function PatientServicesIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M6 20L12 16.5L18 20V5.5C18 4.6875 17.3125 4 16.5 4H7.5C6.65625 4 6 4.6875 6 5.5V20Z"
                fill={style?.color || Color.clear2}
            />
        </SvgIcon>
    );
}