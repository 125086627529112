import React from 'react';

import { InputBase } from "@material-ui/core";

import { PatientFormFreeTextInputProps } from "./props";
import useStyles from "./css";


export default function PatientFormFreeTextInput(props: PatientFormFreeTextInputProps) {
  
  const { value, setValue, placeholder, width, disabled, maxLength } = props;
    
  const classes = useStyles(width)();
  
  function handleOnChange(event: any) {
    setValue(event.target.value);
  }
  
  return (
    <div className={classes.root}>
      <InputBase
        className={classes.input}
        placeholder={placeholder}
        value={value}
        onChange={handleOnChange}
        disabled={disabled}
        inputProps={{
          className: classes.inputWidth,
          maxLength: maxLength
        }}
      />
      <div className={classes.counter}>{`${value?.length}/${maxLength}`}</div>
    </div>
  );
}