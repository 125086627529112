import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import MaskedInput, { conformToMask } from "react-text-mask";

import { SearchIcon, SortDownIcon } from "./../../../assets/icons";
import CountryFlagIcon from "./../../../assets/flags";
import Country from "../../../models/country";
import CountryService from "../../../api/country-service";
import { GetMobileMaskByTemp } from "../../../constants/mask";
import { useAppSelector } from "../../../redux/hooks";

import { InputMobileCodeProps } from "./props";
import useStyles from "./css";
import { selectAccountSettings } from "../../../redux/store";
import Input from "../Input";


const InputMobileCode = (props: InputMobileCodeProps) => {

    const dialTemplate: string = "000-000-0000";
    const { t } = useTranslation(["general"]);
    const accountSettings = useAppSelector(selectAccountSettings);

    const { label, widthSelect, widthList, heigthList, isOptional,
        labelClass, requiredDotClass,
        codeIso3, mobile,
        isValid, onChangeItem, onChangeMobile, onChangeComplete, onBlur, setSmbPhoneNumber
    } = props;
    const classes = useStyles();

    const [openModal, setOpenModal] = useState<boolean>(false);

    const [countries, setCountries] = useState<Country[]>([]);
    const [countrySelected, setCountrySelected] = useState<Country>(new Country({
        iso3: "MEX", dialCode: 52, dialDigits: 10, dialTemplate: dialTemplate, name: "México"
    }));
    const [mobileNumber, setMobileNumber] = useState<string>(mobile!);
    const [searchValue, setSearchValue] = useState<string | null>(null);
    const [masking, setMasking] = useState<any>(GetMobileMaskByTemp(dialTemplate));
    const [isValidNumber, setIsValidNumber] = useState<boolean>(true);

    const itemSelected = (item: Country) => {
        setSearchValue(null);
        let country = new Country({
            iso3: item.iso3,
            name: item.name,
            dialCode: item.dialCode,
            dialDigits: item.dialDigits,
            dialTemplate: item.dialTemplate
        });

        // setIso3(country.iso3);
        setCountrySelected(country);
        setOpenModal(false);

        let newNumber: string;
        if (mobileNumber) {
            newNumber = mobileNumber.substring(0, country.dialDigits || 10);
            setMobileNumber(newNumber);
        } else {
            newNumber = mobileNumber;
        }

        // setCodeNumber(item.dialCode.toString());
        const m = GetMobileMaskByTemp(country.dialTemplate || dialTemplate);
        setMasking(m);

        if (onChangeItem)
            onChangeItem(country);

        const valid = validate(newNumber, country.dialDigits);

        if (onChangeComplete)
            onChangeComplete(country.iso3, newNumber, valid);
    }

    const handleMobileChange = (event: React.ChangeEvent<{ value: string }>) => {
        const mobile = event.target.value.replace(/[^0-9]/g, "");

        setMobileNumber(mobile);
        if (onChangeMobile) onChangeMobile(mobile);

        const valid = validate(mobile, countrySelected?.dialDigits);

        if (onChangeComplete)
            onChangeComplete(countrySelected.iso3, mobile, valid);
    };

    const validate = (mobileComplete: string, digits: number): boolean => {
        let validDig = false;

        if (mobileComplete) {
            const mobile = mobileComplete.replace(/[^0-9]/g, "");
            validDig = (digits === 7 && /^\d{7}$/.test(mobile!)) ||
                (digits === 8 && /^\d{8}$/.test(mobile!)) ||
                (digits === 9 && /^\d{9}$/.test(mobile!)) ||
                (digits === 10 && /^\d{10}$/.test(mobile!)) ||
                (digits === 11 && /^\d{11}$/.test(mobile!)) ||
                (digits === 12 && /^\d{12}$/.test(mobile!));

            setIsValidNumber(validDig);
            if (isValid)
                isValid(validDig);
        }
        if (!isOptional && !mobileComplete) {
            if (isValid)
                isValid(false);
            setIsValidNumber(false);
        }

        return validDig;
    }

    useEffect(() => {

        (async () => {
            const listCountries: Country[] = accountSettings.countries;
            const countryInSettings: Country = accountSettings.countryInSettings;

            setCountries(listCountries || []);
            let iso = codeIso3 || (countryInSettings ? countryInSettings.iso3 : null) || "MEX";

            const countryByIso = listCountries.filter(x => x.iso3 === iso);

            if (countryByIso.length > 0) {
                const country = countryByIso[0];
                setCountrySelected(country);
                const m = GetMobileMaskByTemp(country.dialTemplate || dialTemplate);
                setMasking(m);
            }

        })();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [codeIso3]);


    useEffect(() => {

        if (mobile) {
            setMobileNumber(mobile);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mobile]);

    useEffect(() => {
        if (setSmbPhoneNumber)
            setSmbPhoneNumber("+" + countrySelected.dialCode + mobile);
    }, [mobile, countrySelected]);

    const handleOpenModal = () => {
        setOpenModal(true);
    }
    
    function handleSearch(searchValue: string) {
        setSearchValue(searchValue);
    }
    
    function filterCountries (item: Country) {
        const lowercaseSearchValue = searchValue!.toLowerCase();
        let isSearchByDialCode = /^\+?\d+$/.test(searchValue!);
        
        if (searchValue!.trim().length < 2 && !isSearchByDialCode) {
            return true;
        }
        
        return (
          item.name.toLowerCase().includes(lowercaseSearchValue) ||
          item.dialCode.toString().startsWith(lowercaseSearchValue.replace("+", ""))
        );
    }

    const handleCloseModal = () => {
        setSearchValue(null);
        setOpenModal(false);
    }

    return (<div className={classes.component}>

        <div className={classes.divDropdown} style={{ width: widthSelect || 350 }}  >

            <label className={labelClass ?? classes.labelCountry}>
                <Typography className={labelClass ?? classes.labelCountry}>
                    {label || t("Mobile") + " "}
                    {(isOptional ?? true) ?
                        <span className={classes.optional}>({t("Optional")})</span>
                        :
                        <span className={requiredDotClass ?? classes.required}>*</span>
                    }
                </Typography >
            </label>

            <div className={`${classes.inputGroup} ${!isValidNumber ? classes.inputError : ""}`} >
                <MaskedInput
                    className={`${classes.inputClassic} ${classes.inputSelectBtn} `}
                    placeholder={countrySelected?.dialTemplate || dialTemplate}
                    mask={masking}
                    placeholderChar=" "
                    onChange={handleMobileChange}
                    onBlur={() => { if (onBlur) onBlur(validate(mobileNumber, countrySelected?.dialDigits)) }}
                    value={mobileNumber}
                />

                <div className={classes.divCodes} onClick={handleOpenModal} >
                    <div className={classes.inputFlag}>
                        <CountryFlagIcon iso={countrySelected?.iso3 || "MEX"} />
                    </div>
                    <div className={classes.iconDropdown} >
                        <SortDownIcon />
                    </div>
                    <div className={classes.divDialCode}>
                        {countrySelected.dialCode !== undefined ? `+${countrySelected.dialCode}` : "+52"}
                    </div>
                </div>

            </div>
        </div>

        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
            disablePortal={true}
            style={{ position: "relative", top: -20 }} >

            <div className={classes.divModalSelect} style={{ width: widthList || 350, maxHeight: heigthList ? heigthList + 10 : 310 }}>
                <div className={classes.titleCountries} >{t("Countries")}</div>
                <div className={classes.searchInputContainer}>
                    <Input
                      placeholder={t("Search country code")}
                      width={"-webkit-fill-available"}
                      onChange={(event) => handleSearch(event.target.value)}
                    />
                    <div className={classes.searchIconContainer}><SearchIcon
                      style={{ fontSize: 17, color: "#919CA5" }}/></div>
                </div>
                <div className={classes.listCountryScroll} style={{ maxHeight: heigthList ? heigthList - 10 : 214 }}>
                    {(searchValue === null 
                          ? countries 
                          : countries.filter((item) => filterCountries(item)))
                      .map((item) => (
                      <div key={item.iso3} className={classes.listCountry}>
                          <div className={`${classes.elementList} ${classes.classRow}`}
                               onClick={() => itemSelected(item)}>
                                <span style={{ marginRight: 10 }}>
                                    <CountryFlagIcon iso={item.iso3}/>
                                </span>
                              <div className={classes.countryName}>
                                  {item.name} (+{item.dialCode})
                              </div>
                          </div>
                      </div>
                    ))}
                </div>

            </div>
        </Modal>



    </div>);
};

export default InputMobileCode;
