import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    margin: 0,
  },
  required: {
    color: "#6462F3",
  },
  optional: {
    fontWeight: "normal",
  },
  rowContainer: {
    display: "flex",
    justifyContent: "space-between",
    margin: "24px 0px 0px 0px",
  },
  switch: {
    display: "flex",
    alignItems: "center",
  },
  switchLabel: {
    margin: "0px 0px 0px 16px",
  },
  label: {
    display: "flex",
    margin: 0,
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey5,
  },
  description: {
    margin: 0,
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
  },
  infoContentRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 0px 0px",
  },
  caption: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    width: 228,
    margin: "0px",
  },
  captionValue: {
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: theme.colors.grey5,
    width: 228,
    margin: "0px",
  },
}));

export default useStyles;
