import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ScheduleAppointmentActionsMenuContextProvider } from '../../common/Scheduler/ScheduleAppointmentActionsMenu';
import WeekScheduler from '../../common/Scheduler/WeekScheduler';
import DayScheduler from '../../common/Scheduler/DayScheduler';
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { AppointmentService } from "../../../api/appointment-service";
import BlockedScheduleService from "../../../api/blocked-schedule-service";
import { UserService } from "../../../api/user-service";
import { useSelector } from "react-redux";
import { RootState, selectAccountSettings } from "../../../redux/store";
import { connect } from "../../../redux/common-hub-slice";
import { Appointment } from "../../../models/appointment";
import AppointmentListToolbar from "./../AppointmentListToolbar";
import { SelectItem } from "../../common/Select";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { navbarActions } from '../../../redux/navbar-slice';
import { schedulerActions } from "../../../redux/scheduler-slice";
import { applicationInterfaceActions } from "../../../redux/application-interface-slice";
import { startOfDay, startOfWeek } from "date-fns";
import { endOfDay, endOfWeek } from "date-fns/esm";
import BlockScheduleModal from "../BlockScheduleModal";
import useStyles from "./css";
import BlockSchedule from "../../../models/blocked-schedule";
import { AppointmentStatus } from "../../../models/enums/appointment-status";
import { LocalStorageKey } from "../../../constants/local-storage-key";
import { WeekdayService } from "../../../api/settings-weekdays-service";
import { WeekdaysEnum } from "../../../models/enums/weekdays";
import MultipleBlockedScheduleModal from "../MultipleBlockedScheduleModal";
import AppointmentDragAndDropScheduleBusyModal from "../AppointmentDragAndDropScheduleBusyModal";
import { Url } from "../../../constants/url";
import { useTranslation } from "react-i18next";

interface LocationState {
  redirectedAppointmentId: string | undefined;
  redirectedAppointmentDate: Date | undefined;
}


export default function AppointmentList() {

  const classes = useStyles();
  const { t } = useTranslation(["general"]);

  const AllSpecialists = "AllSpecialists";

  const location = useLocation<LocationState | undefined>();
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const { setDaysOff, setAllUsers, setSelectedUsers, setSelectedStatuses, setTooltipAppointment, setStartDayHours, setEndDayHours, setCurrentViewName, setStartWeekHour, setEndWeekHour, setWeekDays, setAppointmentDndModalState } = schedulerActions;
  const daysOff = useAppSelector((state) => state.scheduler.daysOff);
  const { updateIsWideLayout } = applicationInterfaceActions;
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [absences, setAbsences] = useState<BlockSchedule[]>([]);
  const currentDate = useAppSelector((state) => state.scheduler.currentDate);
  const currentViewName = useAppSelector((state) => state.scheduler.currentViewName);
  const [lastCurrentViewName, setLastCurrentViewName] = useState<string>(currentViewName);
  const [appointmentsLoadedForDate, setAppointmentsLoadedForDate] = useState<Date | null>(null);

  const userId = useAppSelector((state) => state.userInfo.userId);
  const hasAccessToScheduleAllSpecialists = useAppSelector((state) => state.userInfo.hasAccessToScheduleAllSpecialists);
  const accountSettings = useAppSelector(selectAccountSettings);
  const allUsers = useAppSelector((state) => state.scheduler.allUsers);
  const selectedUsers = useAppSelector((state) => state.scheduler.selectedUsers);
  const selectedStatuses = useAppSelector((state) => state.scheduler.selectedStatuses);
  const startDayHours = useAppSelector((state) => state.scheduler.startDayHours);
  const appointmentDndModalState = useAppSelector(state => state.scheduler.appointmentDndModalState);
  const selectedEstablishments = useAppSelector(state => state.enterprise.selectedEstablishments);
  
  const commonHubConnection = useSelector(
    (state: RootState) => state.commonHub.connection
  );

  // unsubscribe
  commonHubConnection?.off("updateAppointmentList");
  commonHubConnection?.off("changeAppointmentStatus");
  commonHubConnection?.off("updateAppointmentData");
  commonHubConnection?.off("updateAppointmentsBlockedSchedule");
  commonHubConnection?.off("updateUserHasServicesInCharge");
  // subscribe
  commonHubConnection?.on("updateAppointmentList", () => {
    void loadData(false, true);
  });
  commonHubConnection?.on("changeAppointmentStatus",
    (appointmentId, status) => handleStatusChange(appointmentId, status)
  );
  commonHubConnection?.on("updateAppointmentData", (appointmentId) => handleUpdateAppointmentData(appointmentId));
  commonHubConnection?.on("updateAppointmentsBlockedSchedule", () => updateBlockedSchedule());
  commonHubConnection?.on("updateUserHasServicesInCharge",
      (userId, fullName, hasServicesInCharge) => handleUserHasServicesInChargeChange(userId, fullName, hasServicesInCharge)
  );
  dispatch(connect());

  const handleStatusChange = (appointmentId: string, status: string) => {
    let appointmentToUpdateIndex = appointments.findIndex(a => a.appointmentId === appointmentId);
    if (appointmentToUpdateIndex !== -1) {
      const newAppointmentStatus: AppointmentStatus = parseInt(status);
      let newAppointments = [...appointments];
      newAppointments[appointmentToUpdateIndex] = {
        ...newAppointments[appointmentToUpdateIndex],
        status: newAppointmentStatus
      };
      setAppointments(newAppointments);
    }
  };

  const handleUserHasServicesInChargeChange = (userId: string, fullName: string, hasServicesInCharge: boolean) => {
    let newUsers = [...allUsers];

    if (hasServicesInCharge) {      
      newUsers.push({ key: userId, value: fullName });
    } else {
      newUsers = newUsers.filter(user => user.key !== userId);
    }
    dispatch(setAllUsers(newUsers));
  };


  const handleUpdateAppointmentData = (appointmentId: string) => {
    async function fetchData(appointmentId: string) {

      if (currentViewName === "Week") {
        const appointment: Appointment | null =
          await AppointmentService.getAppointmentCalendarById(appointmentId, !selectedUsers?.length ? undefined : selectedUsers, !selectedStatuses?.length ? undefined : selectedStatuses);
        if (appointment) {
          const appointmentToUpdateIndex = appointments.findIndex(a => a.appointmentId === appointmentId);
          if (appointmentToUpdateIndex !== -1) {
            let newAppointments = [...appointments];
            newAppointments[appointmentToUpdateIndex] = appointment;
            setAppointments(newAppointments);
          }
        }
      } else {
        const apps: Appointment[] =
          await AppointmentService.getSplitByServicesAppointmentCalendarById(appointmentId, !selectedUsers?.length ? undefined : selectedUsers, !selectedStatuses?.length ? undefined : selectedStatuses);
        let newAppointments = [...appointments];
        apps.forEach(app => {
          const appointmentToUpdateIndex = appointments.findIndex(a => a.appointmentId === app.appointmentId && a.firstServiceId === app.firstServiceId);
          if (appointmentToUpdateIndex !== -1) {
            newAppointments[appointmentToUpdateIndex] = app;
          }
        });
        setAppointments(newAppointments);
      }
    }

    const appointmentToUpdateIndex = appointments.findIndex(a => a.appointmentId === appointmentId);
    if (appointmentToUpdateIndex !== -1) {
      fetchData(appointmentId);
    }
  };

  const _setTooltipAppointments = (appointments: any[]) => {
    dispatch(setTooltipAppointment(appointments));
  };

  useEffect(() => {
    dispatch(updateIsWideLayout(true));
  });
  
  useEffect(() => {
    // restore selected statuses from localStorage
    if (selectedStatuses === null) {
      const storageKey = accountSettings.isEnterprise ? LocalStorageKey.SchedulerSelectedStatusesEnterprise : LocalStorageKey.SchedulerSelectedStatuses
      const json = localStorage.getItem(storageKey + userId);
      if (json) {
        const selectedStatuses = JSON.parse(json);
        if (selectedStatuses.constructor === Array) {
          dispatch(setSelectedStatuses(selectedStatuses));
        }
      }
    }
  }, []);

  useEffect(() => {
    async function fetchData() {
      dispatch(navbarActions.setShowLoader(true));
      if (accountSettings.isEnterprise === null) return;

      if (!Array.isArray(daysOff)) {
        await loadDayOff();
      }
      if (!startDayHours.length) {
        await loadWeekDays();
      }
      
      if (hasAccessToScheduleAllSpecialists){
        await loadUsers();
      } else {
        dispatch(setSelectedUsers([]));
      }

      if (location?.state?.redirectedAppointmentDate !== undefined) {
        const loadAppointments = async () => {
          await loadData(false, true);
        };
        loadAppointments();
      }

      setIsLoaded(true);
      dispatch(navbarActions.setShowLoader(false));
    }

    if (userId) {
      const viewName = localStorage.getItem(LocalStorageKey.SchedulerViewName + userId);
      if (viewName) {
        dispatch(setCurrentViewName(viewName));
      }
    }

    fetchData();
  },
      [accountSettings.isEnterprise, selectedEstablishments]
  );
  
  useEffect(
    () => {
      async function fetchData() {
        await loadData(false, true);
      }
      
      if (accountSettings.isEnterprise === null) return;
      if (allUsers && selectedUsers) {
        fetchData();
      }
      
      dispatch(navbarActions.setExternalContent((
        <AppointmentListToolbar
          allUsers={allUsers}
          selectedUsers={selectedUsers ?? []}
          selectedStatuses={selectedStatuses ?? []}
          saveSelectedUsers={saveSelectedUsers}
          saveSelectedStatuses={saveSelectedStatuses}
        />)));
    },
    [selectedUsers, selectedStatuses, allUsers]
  );
  
  useEffect(
    () => {
      async function fetchData() {
        await loadData(true, true);  //Forcing appointments array cleanup to fix phantom blocked schedules
      }
      
      if (currentDate.getTime() !== appointmentsLoadedForDate?.getTime() || currentViewName !== lastCurrentViewName) {
        void fetchData();
        setLastCurrentViewName(currentViewName);
      }
    },
    [currentDate, currentViewName]
  );
  
  useEffect(() => {
    if (location?.state?.redirectedAppointmentId && location?.state?.redirectedAppointmentDate?.getTime() === appointmentsLoadedForDate?.getTime()) {
      dispatch(schedulerActions.setAppointmentId(location.state.redirectedAppointmentId));
      history.replace(Url.Appointments.Main, { redirectedAppointmentDate: undefined, redirectedAppointmentId: undefined });
    }
  }, [location?.state?.redirectedAppointmentId, appointmentsLoadedForDate]);

  async function loadBlockedSchedule(showLoader: boolean, dateFrom: Date, dateTo: Date) {
    if (showLoader) dispatch(navbarActions.setShowLoader(true));
    const blocked: BlockSchedule[] = accountSettings.isEnterprise
      ? await BlockedScheduleService.getBlockedScheduleByEstablishments(dateFrom, dateTo, selectedEstablishments)
      : await BlockedScheduleService.getBlockedScheduleByAccount(dateFrom, dateTo);
    if (showLoader) dispatch(navbarActions.setShowLoader(false));

    const mappedBlockers = blocked.flatMap(mapBlockerToSingleOrMultipleAbsence);

    return mappedBlockers;
  }

  function mapBlockerToSingleOrMultipleAbsence(blocker: BlockSchedule): BlockSchedule[] {
    if (blocker.isBlockedMultiple) {
      if (currentViewName === "Week") {
        const newBlocked = { ...blocker, specialistId: blocker.specialistId !== AllSpecialists ? blocker.specialistId : t(AllSpecialists) };
        return [newBlocked];
      }
      return blocker.blockers;
    }
    if (currentViewName === "Week" || blocker.specialistId !== AllSpecialists) {
      const newBlocker = { ...blocker, specialistId: blocker.specialistId !== AllSpecialists ? blocker.specialistId : t(AllSpecialists) };
      return [newBlocker];
    } else {
      const allUsersAbsences = allUsers.map(specialist => ({
        ...blocker,
        specialistId: specialist.key,
        specialist: t(AllSpecialists)
      }));
      return allUsersAbsences;
    }
  }

  async function loadData(forceAppointmentsClean: boolean, showLoader: boolean) {
    if (!selectedUsers)
      return;
    if (forceAppointmentsClean) {
      setAppointments([]);
      _setTooltipAppointments([]);
    }
    if (showLoader) dispatch(navbarActions.setShowLoader(true));
    if (accountSettings.isEnterprise === null) return;

    const dateFrom = currentViewName === "Week" ? startOfWeek(currentDate, { weekStartsOn: 1 }) : startOfDay(currentDate);
    const dateTo = currentViewName === "Week" ? endOfWeek(currentDate, { weekStartsOn: 1 }) : endOfDay(currentDate);
    let appointments: Appointment[] =
      accountSettings.isEnterprise
        ? await AppointmentService.getSplitByEstablishments(dateFrom, dateTo, selectedEstablishments, !selectedUsers ? undefined : selectedUsers, !selectedStatuses?.length ? undefined : selectedStatuses)
        : currentViewName === "Week"
          ? await AppointmentService.getByAccount(dateFrom, dateTo, !selectedUsers ? undefined : selectedUsers, !selectedStatuses?.length ? undefined : selectedStatuses)
          : await AppointmentService.getSplitByServicesAppointmentsByAccount(dateFrom, dateTo, !selectedUsers ? undefined : selectedUsers, !selectedStatuses?.length ? undefined : selectedStatuses);

    const blocked = await loadBlockedSchedule(showLoader, dateFrom, dateTo);

    if (showLoader) dispatch(navbarActions.setShowLoader(false));

    setAbsences(blocked)
    setAppointments(appointments);
    _setTooltipAppointments(appointments);
    setAppointmentsLoadedForDate(currentDate);
  }

  const updateBlockedSchedule = async () => {
    const dateFrom = currentViewName === "Week" ? startOfWeek(currentDate, { weekStartsOn: 1 }) : startOfDay(currentDate);
    const dateTo = currentViewName === "Week" ? endOfWeek(currentDate, { weekStartsOn: 1 }) : endOfDay(currentDate);

    const blocked = await loadBlockedSchedule(false, dateFrom, dateTo);

    setAbsences(blocked);
  };

  const loadUsers = async () => {
    const usersOnlyWithServices = true;
    const users = accountSettings.isEnterprise
      ? await UserService.getUsersBaseInfoByEstablishments(selectedEstablishments)
      : await UserService.getUsersBaseInfoByAccount(usersOnlyWithServices);

    const selectItems = users.map((user) => {
      const select: SelectItem = {
        key: user.id,
        value: `${user.firstName} ${user.lastName}`,
      };
      return select;
    });
    dispatch(setAllUsers(selectItems));

    let isSelectedUsersSet = false;
    if (userId) {
      const storageKey = accountSettings.isEnterprise ? LocalStorageKey.SchedulerSelectedUsersEnterprise : LocalStorageKey.SchedulerSelectedUsers
      const json = localStorage.getItem(storageKey + userId);
      if (json) {
        const selectedUsers = JSON.parse(json);
        if (selectedUsers.constructor === Array) {
          if (selectItems && selectedUsers) {
            const userIds = selectItems.map(x => x.key);
            const removeUsers = selectedUsers.filter(x => !userIds.includes(x))
            
            if (removeUsers.length > 0) {
              const newUsers = [...selectedUsers.filter(x => !removeUsers.includes(x))]
              saveSelectedUsers(newUsers)
            }
            else {
              dispatch(setSelectedUsers(selectedUsers));
            }
  
            isSelectedUsersSet = true;
          }
        }
      }
    }
    
    if (!isSelectedUsersSet) {
      dispatch(setSelectedUsers([]));
    }
  };

  const loadDayOff = async () => {
    const days = await AppointmentService.getAvailableDays();
    dispatch(setDaysOff([...days]));
  };

  const loadWeekDays = async () => {
    const weekdays = accountSettings.isEnterprise 
      ? await WeekdayService.getWeekdaysForEstablishments(selectedEstablishments)
      : await WeekdayService.getWeekdays();

    dispatch(setWeekDays(weekdays))
    
    let startDayHours = [], endDayHours = [], startWeekHour = 24, endWeekHour = 0;
    for (let i = 0; i < 7; i++) {
      let weekday = weekdays.find(x => x.dayNumber == i);
      if (weekday && weekday.isOpen) {
        if (weekday.opening) {
          const spl = weekday.opening.split(':')
          const hour = parseInt(spl[0]);
          const minutes = parseInt(spl[1]) - 1;
          const hourDecimal = +(hour + (minutes / 60)).toFixed(2);
          const roundedHourDecimal = Math.floor(hourDecimal * 2) / 2;
          startDayHours.push(roundedHourDecimal);
          startWeekHour = Math.min(startWeekHour, roundedHourDecimal);
        } else {
          startDayHours.push(9);
        }
        if (weekday.closing) {
          const spl = weekday.closing.split(':');
          const hour = parseInt(spl[0]);
          const minutes = parseInt(spl[1]) + 1;
          const hourDecimal = +(hour + (minutes / 60)).toFixed(2);
          const roundedHourDecimal = Math.ceil(hourDecimal * 2) / 2;
          endDayHours.push(roundedHourDecimal);
          endWeekHour = Math.max(endWeekHour, roundedHourDecimal);
        } else {
          if (i === WeekdaysEnum.Saturday || i === WeekdaysEnum.Sunday) {
            endDayHours.push(15);
          }
          endDayHours.push(19);
        }
      }
    }
    dispatch(setStartDayHours(startDayHours));
    dispatch(setEndDayHours(endDayHours));
    dispatch(setStartWeekHour(startWeekHour));
    dispatch(setEndWeekHour(endWeekHour));
  };

  const saveSelectedUsers = (users: string[]) => {
    dispatch(setSelectedUsers(users));
    if (userId) {
      const storageKey = accountSettings.isEnterprise ? LocalStorageKey.SchedulerSelectedUsersEnterprise : LocalStorageKey.SchedulerSelectedUsers
      localStorage.setItem(storageKey + userId, JSON.stringify(users));
    }
  };
  
  const saveSelectedStatuses = (statuses: AppointmentStatus[]) => {
    dispatch(setSelectedStatuses(statuses));
    if (userId) {
      const storageKey = accountSettings.isEnterprise ? LocalStorageKey.SchedulerSelectedStatusesEnterprise : LocalStorageKey.SchedulerSelectedStatuses
      localStorage.setItem(storageKey + userId, JSON.stringify(statuses));
    }
  };
  
  function handleEditAppointmentModalClick() {
    history.push(`${Url.Appointments.Main}/${appointmentDndModalState.appointmentId}`)
  }
  
  function handleDndModalOnClose() {
    dispatch(setAppointmentDndModalState({ ...appointmentDndModalState, open: false }));
  }
  
  return (
    <>
      {isLoaded && <>
        <div className={classes.container}>
          <DndProvider backend={HTML5Backend}>
            <ScheduleAppointmentActionsMenuContextProvider>
              {(currentViewName === 'Week'
                ? (<WeekScheduler
                    stopsInterval={accountSettings.schedulerBlockDurationMinutes}
                    appointments={appointments}
                    dateReference={currentDate}
                    absences={absences}
                  />)
                : (<>
                  <DayScheduler
                    stopsInterval={accountSettings.schedulerBlockDurationMinutes}
                    appointments={appointments}
                    dateReference={currentDate}
                    absences={absences}
                  />
                  </>))}
            </ScheduleAppointmentActionsMenuContextProvider>
          </DndProvider>
          <MultipleBlockedScheduleModal />
          <BlockScheduleModal refresh={() => loadData(true, true)} />
          <AppointmentDragAndDropScheduleBusyModal
            state={appointmentDndModalState}
            onContinue={handleEditAppointmentModalClick}
            onClose={handleDndModalOnClose}
          />
        </div>
      </>}
    </>
  );
}
