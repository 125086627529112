import { FirstRemindBefore } from "./enums/first-remind-before";
import { SecondRemindBefore } from "./enums/second-remind-before";
import {WhatsappFollowupSendTime} from "./enums/whatsapp-followup-send-time";

export class CustomerCommunication{
    accountId: string | null;
    notifyOnAppointmentCreated: boolean | null;
    notifyOnAppointmentModified: boolean | null;
    notifyOnAppointmentCanceled: boolean | null;
    firstReminderEnabled: boolean | null;
    secondReminderEnabled: boolean | null;
    firstRemindBefore: FirstRemindBefore | null;
    secondRemindBefore: SecondRemindBefore | null;
    customContactUsMessageEnabled: boolean | null;
    customContactUsMessage: string | null;
    showServicePricesInWhatsApp: boolean | null;
    scheduleWhatsAppFollowUpMessageEnabled: number | null;
    whatsAppFollowUpMessage: string | null;
    whatsAppFollowUpSendTime: WhatsappFollowupSendTime | null;
    whatsAppRequestQuotesEnabled: boolean | null;
    whatsAppRequestQuotesMessage: string | null;
    whatsAppRequestQuotesDelay: string | null;
    showCancellationPolicies: boolean;
    hasWhatsAppCancellationPoliciesOnAppointmentConfirmation: boolean;
    hasWhatsAppCancellationPoliciesOnAppointmentReminder: boolean;
    hasWhatsAppCancellationPoliciesOnAppointmentCancel: boolean;
    smbName: string;
    smbPhone: string | null;
    
    constructor(x?: any) {
        if (typeof x === "object") {
            this.accountId = x.accountId;
            this.firstReminderEnabled = x.firstReminderEnabled;
            this.secondReminderEnabled = x.secondReminderEnabled;
            this.firstRemindBefore = x.firstRemindBefore;
            this.secondRemindBefore = x.secondRemindBefore;
            this.notifyOnAppointmentCreated = x.notifyOnAppointmentCreated;
            this.notifyOnAppointmentModified = x.notifyOnAppointmentModified;
            this.notifyOnAppointmentCanceled = x.notifyOnAppointmentCanceled;
            this.customContactUsMessageEnabled = x.customContactUsMessageEnabled;
            this.customContactUsMessage = x.customContactUsMessage;
            this.showServicePricesInWhatsApp = x.showServicePricesInWhatsApp;
            this.scheduleWhatsAppFollowUpMessageEnabled = x.scheduleWhatsAppFollowUpMessageEnabled;
            this.whatsAppFollowUpMessage = x.whatsAppFollowUpMessage;
            this.whatsAppFollowUpSendTime = x.whatsAppFollowUpSendTime;
            this.whatsAppRequestQuotesEnabled = x.whatsAppRequestQuotesEnabled;
            this.whatsAppRequestQuotesMessage = x.whatsAppRequestQuotesMessage;
            this.whatsAppRequestQuotesDelay = x.whatsAppRequestQuotesDelay;
            this.showCancellationPolicies = x.showCancellationPolicies;
            this.hasWhatsAppCancellationPoliciesOnAppointmentConfirmation = x.hasWhatsAppCancellationPoliciesOnAppointmentConfirmation;
            this.hasWhatsAppCancellationPoliciesOnAppointmentReminder = x.hasWhatsAppCancellationPoliciesOnAppointmentReminder;
            this.hasWhatsAppCancellationPoliciesOnAppointmentCancel = x.hasWhatsAppCancellationPoliciesOnAppointmentCancel;
            this.smbName = x.smbName;
            this.smbPhone = x.smbPhone;
        }
        else {
            this.accountId = null;
            this.firstReminderEnabled = null;
            this.secondReminderEnabled = null;
            this.firstRemindBefore = null;
            this.secondRemindBefore = null;
            this.notifyOnAppointmentCreated = null;
            this.notifyOnAppointmentModified = null;
            this.notifyOnAppointmentCanceled = null;
            this.customContactUsMessageEnabled = null;
            this.customContactUsMessage = null;
            this.showServicePricesInWhatsApp = null;
            this.scheduleWhatsAppFollowUpMessageEnabled = null;
            this.whatsAppFollowUpMessage = null;
            this.whatsAppFollowUpSendTime = null;
            this.whatsAppRequestQuotesEnabled = null;
            this.whatsAppRequestQuotesMessage = null;
            this.whatsAppRequestQuotesDelay = null;
            this.showCancellationPolicies = false;
            this.hasWhatsAppCancellationPoliciesOnAppointmentConfirmation = false;
            this.hasWhatsAppCancellationPoliciesOnAppointmentReminder = false;
            this.hasWhatsAppCancellationPoliciesOnAppointmentCancel = false;
            this.smbName = "";
            this.smbPhone = null;
        }
    }
}