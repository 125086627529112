import React from "react";

import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";

import useStyles from "./css";
import { useTranslation, Trans } from "react-i18next";
import { UserModalHasUpcomingAppointmentProps } from "./props";
import { CrossIcon, ExclamationIcon } from "../../../../../assets/icons";


export default function UserModalHasUpcomingAppointment(props: UserModalHasUpcomingAppointmentProps) {
  
  const { open, onCancelClick, onSaveClick } = props;

  const classes = useStyles();
  const { t } = useTranslation("settings");

  const handleCloseModal = () => {
    onCancelClick();
  };

  const handleSubmit = () => {
    onSaveClick();
    handleCloseModal();
  }

  const GoBackButton = () =>
    <Button
      className={`${classes.button} ${classes.goBackButton}`}
      onClick={handleCloseModal}
    >
      {t("Go back")}
    </Button>

  const SubmitButton = () =>
    <Button
      className={`${classes.button} ${classes.submitButton}`}
      onClick={handleSubmit}
    >
      {t("Save changes")}
    </Button>

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.closeButton} onClick={handleCloseModal}>
            <CrossIcon />
          </div>
          <div className={classes.content}>
            <div className={classes.exclamationIcon}>
              <ExclamationIcon viewBox="0 0 7 16" />
            </div>
            <Typography className={classes.modalTitle}>
              {t("Continue with the new configuration?")}
            </Typography>
            <div className={classes.modalTextBlock}>
              <Typography className={classes.modalText}>
                {t("This user has upcoming appointments to attend. If the user is no longer with services in charge, scheduled appointments may be affected.")}
              </Typography>
              <Typography className={classes.modalText}>
                {t("We suggest you assign the appointments to another specialist. Don't forget to notify your customers about this change.")}
              </Typography>
            </div>
            <div className={classes.modalFooter}>
              <GoBackButton />
              <SubmitButton />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}