import React from "react";
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { HeaderCellComponent } from '../AppointmentsScheduler/Header';
import { memo, useMemo } from 'react';
import { useAppSelector } from "../../../../redux/hooks";
import { selectAccountSettings } from "../../../../redux/store";

const useStyles = makeStyles((theme) => ({
    root: {
        ...theme.typography.header5,
        color: "#6A6E72",
        textAlign: 'center',
    },
}));

const AppointmentsHeaderCellComponent: HeaderCellComponent<string> = memo(({ value }) => {
    const styles = useStyles();
    const accountSettings = useAppSelector(selectAccountSettings);
    const allUsers = useAppSelector((state) => state.scheduler.allUsers);
    const isEnterprise = accountSettings.isEnterprise === true && accountSettings.establishments.length > 0;
    let title;
    if (isEnterprise) {
        const est = useMemo(() => accountSettings.establishments.find((x) => x.key === value), [accountSettings.establishments, value]);
        title = est?.value ?? value;
    } else {
        const user = useMemo(() => (allUsers ?? []).find((x) => x.key === value), [allUsers, value]);
        title = user?.value ?? value;
    }

    return <div className={clsx(styles.root)}>{title}</div>;
});

export default AppointmentsHeaderCellComponent;
