import React, { useEffect, useState } from 'react';

import { Redirect } from "react-router-dom";

import { useAppSelector } from "../../../redux/hooks";

import InterweaveComponent from "../../common/InterweaveComponent";
import { Url } from "../../../constants/url";
import { CustomerService } from "../../../api/customer-service";


export default function InformedConsentLetterPage() {
  
  const [consentLetterEnabled, setConsentLetterEnabled] = useState<boolean>(true);
  const [consentLetter, setConsentLetter] = useState<string>("");
  
  const userInfo = useAppSelector((state) => state.userInfo);
  
  useEffect(() => {
    (async () => loadPrivacyNotice()
    )();
  }, [userInfo.accountId])
  
  async function loadPrivacyNotice() {
    if (userInfo && userInfo.accountId) {
      const response = await CustomerService.getInformedConsentLetter();
      setConsentLetter(response.consentLetter);
      setConsentLetterEnabled(response.consentLetterEnabled);
    }
  }
  
  
  return (
    <>
      {
        consentLetterEnabled
          ?
          <InterweaveComponent
            text={consentLetter}
          />
          :
          <Redirect to={Url.Appointments.Main} />
      }
    </>
  );
}