import React from "react";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";

import EnhancedTableHead from "./../EnhancedTableHead";
import { TableColumn } from "../table-column";

import useStyles from "./css";
import { GridListWithoutPaginationProps } from "./props";

const GridListWithoutPagination = (props: GridListWithoutPaginationProps) => {
  const {
    columns,
    items,
    height,
    width,
    onRowClick,
    classRow
  } = props;
  
  const customClasses = useStyles(height, width)();
  
  const handleRequestSort = (event: any, property: string) => {
  };
  
  const handleRowClick = <T extends unknown>(item: T) => {
    if (onRowClick) {
      onRowClick(item);
    }
  };
  
  return (
    <Box>
      <div className={customClasses.container}>
        <TableContainer
          component={Paper}
          className={customClasses.tableContainer}
        >
          <div className={customClasses.tableBody}>
            <Table stickyHeader aria-label="sticky table">
              <EnhancedTableHead
                columns={columns}
                order={"asc"}
                orderBy={""}
                onRequestSort={handleRequestSort}
              />
              <TableBody>
                {items.map((item: any, index: number) => (
                  <TableRow
                    key={index}
                    id={`$GridList-${index}`}
                    className={classRow}
                    onClick={() => handleRowClick(item)}
                    hover
                  >
                    {columns.map((column: TableColumn, colIndex: number) => (
                      (column.visible! ?? true) && <>
                          <TableCell
                              key={colIndex}
                              className={`${customClasses.rowText} ${
                                column.rowClassName || ""
                              }`.trim()}
                              style={{
                                width: column.width,
                                textAlign: column.textAlign
                                  ? column.textAlign
                                  : "left",
                                ...column.rowStyle,
                              }}
                          >
                            {!column.component ? (
                              <Typography
                                className={`${customClasses.rowText} ${
                                  column.rowClassName || ""
                                }`.trim()}
                                style={column.rowStyle}
                              >
                                {item[column.id]}
                              </Typography>
                            ) : (
                              column.component(
                                column.completeObject ? item : item[column.id]
                              )
                            )}
                          </TableCell>
                      </>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
      </div>
    </Box>
  );
};

export default GridListWithoutPagination;
