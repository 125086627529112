import { CreateAppointmentService } from "./create-appointment-service";
import { AppointmentPaymentStatus } from "./enums/appointment-payment-status";
import { AppointmentRecurrenceInterval } from "./enums/appointment-recurrence-interval";
import { AppointmentStatus } from "./enums/appointment-status";
import { CustomRecurrenceMode } from "./enums/custom-recurrence-mode";
import { WeekDaysFlags } from "./enums/week-days-flags";

export class CreateAppointmentModel {
  id: string | null;
  number: number | null;
  userId: string | null;
  user: string | null;
  customerId: string | null;
  firstName: string | null;
  lastName: string | null;
  mobile: string | null;
  mobileCodeIso3: string | null;
  mobileDigits: number | null;
  mobileCode: number | null;
  mobileTemplate: string | null;
  email: string | null;
  startDate: Date | null;
  endDate: Date | null;
  services: CreateAppointmentService[];
  notes: string | null;
  isRecurring: boolean;
  isCustomSchedule: boolean;
  scheduledSimultaneousServices: boolean;
  recurrenceWeekInterval: AppointmentRecurrenceInterval | null;
  recurredAppointmentsCount: number | null;
  totalPriceForRecurringAppointments: number | null;
  updateAllRecurringAppointments?: boolean | null;
  recurringAppointmentOrder?: number | null;
  isIgnoreScheduleBusy: boolean;
  customRecurrenceInterval: number | null;
  customRecurrenceMode: CustomRecurrenceMode | null;
  customRecurrenceWeekDays: WeekDaysFlags | null;

  constructor(x?: any) {
    if (x !== null && typeof x === "object") {
      this.id = x.id;
      this.number = x.number;
      this.userId = x.userId;
      this.user = x.user;
      this.customerId = x.customerId;
      this.firstName = x.firstName;
      this.lastName = x.lastName;
      this.mobileCodeIso3 = x.mobileCodeIso3;
      this.mobileDigits = x.mobileDigits;
      this.mobileCode = x.mobileCode;
      this.mobileTemplate = x.mobileTemplate;
      this.mobile = x.mobile;
      this.email = x.email;
      this.startDate = x.startDate;
      this.endDate = x.endDate;
      this.services = x.services;
      this.notes = x.notes;
      this.isRecurring = x.isRecurring;
      this.isCustomSchedule = x.isCustomSchedule;
      this.scheduledSimultaneousServices = x.scheduledSimultaneousServices;
      this.recurrenceWeekInterval = x.recurrenceWeekInterval;
      this.recurredAppointmentsCount = x.recurredAppointmentsCount;
      this.totalPriceForRecurringAppointments = x.totalPriceForRecurringAppointments;
      this.recurringAppointmentOrder = x.recurringAppointmentOrder;
      this.isIgnoreScheduleBusy = x.isIgnoreScheduleBusy;
      this.customRecurrenceInterval = x.customRecurrenceInterval;
      this.customRecurrenceMode = x.customRecurrenceMode;
      this.customRecurrenceWeekDays = x.customRecurrenceWeekDays;
     
    } else {
      this.id = null;
      this.number = null;
      this.userId = null;
      this.user = null;
      this.customerId = null;
      this.firstName = null;
      this.lastName = null;
      this.mobileCodeIso3 = null;
      this.mobileDigits = null;
      this.mobileCode = null;
      this.mobileTemplate = null;
      this.mobile = null;
      this.email = null;
      this.startDate = null;
      this.endDate = null;
      this.services = [];
      this.notes = null;
      this.isRecurring = false;
      this.isCustomSchedule = false;
      this.scheduledSimultaneousServices = false;
      this.recurrenceWeekInterval = null;
      this.recurredAppointmentsCount = null;
      this.totalPriceForRecurringAppointments = null;
      this.recurringAppointmentOrder = null;
      this.isIgnoreScheduleBusy = false;
      this.customRecurrenceInterval = null;
      this.customRecurrenceMode = null;
      this.customRecurrenceWeekDays = null;
    }
  }
}
