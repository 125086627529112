import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: false
};

export const authenticationSlice = createSlice({
  name: "authenticated",
  initialState: initialState,
  reducers: {
    authenticate: (state) => {
      state.value = true;
    },
    deauthenticate: (state) => {
      state.value = false;
    }
  }
});

export const { authenticate, deauthenticate } = authenticationSlice.actions;

export default authenticationSlice.reducer;