import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../constants/colors";

export const useStyles = makeStyles((theme) => ({
    buttonRow: {
        width: 32,
        height: 32,
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        textTransform: "none",
        borderRadius: 20,
        padding: 10,
        display: "inline",
        cursor: "pointer",
        "&:hover": {
            background: "#ebebeb"
        }
    },
    iconTrash: {
        fill: Color.gray5
    },
    icon: {
        paddingTop: 5,
        height: 30,
        width: 30,
        fontWeight: "bold"
    },
    classRow: {
        // cursor: "pointer",
        "& div.buttonRow": {
            visibility: "hidden",
        },
        "&:hover": {
            "& div.buttonRow": {
                visibility: "visible"
            }
        }
    },
    classHead: {
        color: theme.colors.grey5,
        textAlign: "left"
    },
    classCell: {
        color: theme.colors.grey5,
    },
    divStatus: {
        borderRadius: 5,
        color: "#FFFFFF",
        padding: "5px 10px",
        width: "fit-content",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12
    },
    statusActive: {
        color: "#6462F3",
        backgroundColor: "rgba(100, 98, 243, 0.2)"
    },
    statusDone: {
        marginLeft: -2,
        color: "#36CE91",
        backgroundColor: "rgba(54, 206, 145, 0.2)"
    },
    modalCenter: {
        display: "flex",
        justifyContent: "center",
    },
    modalTitleSpace: {
        margin: "24px 0px 8px 0px",
    },
    modalContentText: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "17px",
        textAlign: "center",
        color: "#4A4D51",
    },
    modalTitleText: {
        fontSize: "18px",
        lineHeight: "22px",
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: "bold",
        color: "#26292B",
    },
    modalDelete: {
        height: 280
    },
    separator: {
        height: 0,
        borderTop: "1px solid rgba(201, 205, 211, 0.38)",
        margin: "16px 0px 16px 0px",
    },
    impactedCustomersContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between"
    }
}));


export default useStyles;
