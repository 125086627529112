import { BankTransferInfo } from "./bank-transfer-info";
import { Currency } from "./currency";
import { PaymentFieldUpdate } from "./enums/payment-field-update";

export class PaymentSettingsModel {
  accountId: string | "";
  currencies: Currency[];
  currencyId: string | null;
  isPaymentCash: boolean;
  isPaymentCard: boolean;
  isPaymentCardMasterCard: boolean;
  isPaymentCardVisa: boolean;
  isPaymentCardOther: boolean;
  otherPaymentCardName: string | null;
  isPaymentBankTransfer: boolean;
  bankTransfers: BankTransferInfo[];
  isPaymentOxxo: boolean;
  oxxoCardNumber: string | null;
  isEnabledMercadoPagoByAdmin: boolean;
  isPaymentMercadoPago: boolean;
  hasAcceptedMercadoPagoTerms: boolean;
  mercadoPagoEnabledByAdmin: boolean;
  hasExtraCostMercadoPago: boolean;
  extraPercentage: number | null;
  extraAmount: number | null;
  ivaPercentage: number | null;
  currencyTemplate: string | null;
  hasWhatsAppPaymentReminderOnAppointmentConfirmation: boolean;
  hasWhatsAppPaymentReminderOnAppointmentReminder: boolean;
  hasWhatsAppPaymentReminderAfterAppointment: boolean;
  daysAfterAppointmentToSendWhatsAppPaymentReminder: number | null;
  isCashRegisterActivated: boolean;
  initialCashInCashRegister: number | null;
  fieldUpd: PaymentFieldUpdate;
  isSendReceiptByWhatsApp: boolean;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.accountId = x.accountId;
      this.currencies = x.currencies;
      this.currencyId = x.currencyId;
      this.isPaymentCash = x.isPaymentCash;
      this.isPaymentCard = x.isPaymentCard;
      this.isPaymentCardMasterCard = x.isPaymentCardMasterCard;
      this.isPaymentCardVisa = x.isPaymentCardVisa;
      this.isPaymentCardOther = x.isPaymentCardOther;
      this.otherPaymentCardName = x.otherPaymentCardName;
      this.isPaymentBankTransfer = x.isPaymentBankTransfer;
      this.bankTransfers = x.bankTransfers;
      this.isPaymentOxxo = x.isPaymentOxxo;
      this.oxxoCardNumber = x.oxxoCardNumber;
      this.isEnabledMercadoPagoByAdmin = x.isEnabledMercadoPagoByAdmin;
      this.isPaymentMercadoPago = x.isPaymentMercadoPago;
      this.hasAcceptedMercadoPagoTerms = x.hasAcceptedMercadoPagoTerms;
      this.mercadoPagoEnabledByAdmin = x.mercadoPagoEnabledByAdmin;
      this.hasExtraCostMercadoPago = x.hasExtraCostMercadoPago;
      this.extraPercentage = x.extraPercentage;
      this.extraAmount = x.extraAmount;
      this.ivaPercentage = x.ivaPercentage;
      this.currencyTemplate = x.currencyTemplate;
      this.hasWhatsAppPaymentReminderOnAppointmentConfirmation = x.hasWhatsAppPaymentReminderOnAppointmentConfirmation;
      this.hasWhatsAppPaymentReminderOnAppointmentReminder = x.hasWhatsAppPaymentReminderOnAppointmentReminder;
      this.hasWhatsAppPaymentReminderAfterAppointment = x.hasWhatsAppPaymentReminderAfterAppointment;
      this.daysAfterAppointmentToSendWhatsAppPaymentReminder = x.daysAfterAppointmentToSendWhatsAppPaymentReminder || 2;
      this.isCashRegisterActivated = x.isCashRegisterActivated;
      this.initialCashInCashRegister = x.initialCashInCashRegister;
      this.fieldUpd = x.fieldUpd;
      this.isSendReceiptByWhatsApp = x.isSendReceiptByWhatsApp;
    }
    else {
      this.accountId = "";
      this.currencies = [];
      this.currencyId = null;
      this.isPaymentCash = false;
      this.isPaymentCard = false;
      this.isPaymentCardMasterCard = false;
      this.isPaymentCardVisa = false;
      this.isPaymentCardOther = false;
      this.otherPaymentCardName = "";;
      this.isPaymentBankTransfer = false;
      this.bankTransfers = [];
      this.isEnabledMercadoPagoByAdmin = false;
      this.isPaymentOxxo = false;
      this.oxxoCardNumber = "";
      this.isPaymentMercadoPago = false;
      this.hasAcceptedMercadoPagoTerms = false;
      this.mercadoPagoEnabledByAdmin = false;
      this.hasExtraCostMercadoPago = true;
      this.extraPercentage = null;
      this.extraAmount = null;
      this.ivaPercentage = null;
      this.currencyTemplate = null;
      this.hasWhatsAppPaymentReminderOnAppointmentConfirmation = false;
      this.hasWhatsAppPaymentReminderOnAppointmentReminder = false;
      this.hasWhatsAppPaymentReminderAfterAppointment = false;
      this.daysAfterAppointmentToSendWhatsAppPaymentReminder = 2;
      this.isCashRegisterActivated = false;
      this.initialCashInCashRegister = null;
      this.fieldUpd = PaymentFieldUpdate.None;
      this.isSendReceiptByWhatsApp = true;
    }
  }
}


  