export class AppointmentServiceModel {
  id: string | null;
  name: string | null;
  duration: number | null;
  price: number | null;
  user: string | null;
  workArea: string | null;
  startDate: Date | null;
  endDate: Date | null;

  constructor(x?: any) {
    if (typeof x == "object") {
      this.id = x.id;
      this.name = x.name;
      this.duration = x.duration;
      this.price = x.price;
      this.user = x.user;
      this.workArea = x.workArea;
      this.startDate = x.startDate;
      this.endDate = x.endDate;
    } else {
      this.id = null;
      this.name = null;
      this.duration = null;
      this.price = null;
      this.user = null;
      this.workArea = null;
      this.startDate = null;
      this.endDate = null;
    }
  }
}
