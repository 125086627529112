import React from 'react';

import { useTranslation } from "react-i18next";

import { schedulerActions } from "../../../../redux/scheduler-slice";
import { useAppSelector } from "../../../../redux/hooks";
import { selectAccountSettings, selectUserInfo } from "../../../../redux/store";

import useLocalStorageState from "../../../../hooks/useLocalStorageState";
import { LocalStorageKey } from "../../../../constants/local-storage-key";

import { IconButton, Tooltip } from "@material-ui/core";

import useStyles from "./css";
import { GroupAppointmentsIcon } from "../../../../assets/icons";
import { Color } from "../../../../constants/colors";


export default function AppointmentGroupingCorner() {

  const classes = useStyles();
  const { t } = useTranslation("general");

  const isEnterprise = useAppSelector(selectAccountSettings).isEnterprise;
  const userId = useAppSelector(selectUserInfo).userId;
  const { setIsGrouping } = schedulerActions;

  const groupingStorageKey = `${isEnterprise ? LocalStorageKey.SchedulerGroupingEnterprise : LocalStorageKey.SchedulerGrouping}${userId}`;
  //@ts-ignore
  const [schedulerGrouping, setSchedulerGrouping] = useLocalStorageState({ itemKey: groupingStorageKey, dispatchFunction: setIsGrouping });

  function invertGrouping() {
    setSchedulerGrouping(!schedulerGrouping)
  }

  return (
    <div className={classes.root}>
      <Tooltip
        classes={{
          popper: classes.popper,
        }}
        arrow
        title={`${schedulerGrouping ? t("Ungroup appointments") : t("Group appointments")}`}
        placement="right"
      >
        <IconButton
          onClick={() => invertGrouping()}
        >
          <GroupAppointmentsIcon
            color={schedulerGrouping ? Color.clear1 : Color.gray5}
          />
        </IconButton>
      </Tooltip>
    </div>
  );
}