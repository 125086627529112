export class CustomerClinicalFile {
  id: string;
  customerId: string;
  s3Key: string;
  fileName: string;
  comments: string | null;
  uploadedBy: string;
  created: Date;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.customerId = x.customerId;
      this.s3Key = x.s3Key;
      this.fileName = x.fileName;
      this.comments = x.comments;
      this.uploadedBy = x.uploadedBy;
      this.created = x.created;
    } else {
      this.id = "";
      this.customerId = "";
      this.s3Key = "";
      this.fileName = "";
      this.comments = null;
      this.uploadedBy = "";
      this.created = new Date();
    }
  }
}
