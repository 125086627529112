import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import jwtDecode from "jwt-decode";
import store from "../redux/store";

import { Url } from "../constants/url"
import { LocalStorageKey } from "../constants/local-storage-key";
import { UserInfo } from "../models/user-info";
import { setUserInfo } from "../redux/user-info-slice";

export class ApiClient {
    static baseUrl = process.env.REACT_APP_SERVER_URL;
    static authorizationHeader = "Authorization";
    private static refreshTokenPromise: null | Promise<any>;

    static async initialize() {
        axios.interceptors.response.use((response) => {
            return response;
        }, ApiClient.handleRejectedRequest.bind(ApiClient));

        const accessToken = localStorage.getItem(LocalStorageKey.AccessToken);
        if (accessToken) {
            axios.defaults.headers.common[this.authorizationHeader] = `Bearer ${accessToken}`;
            return true;
        } else {
            axios.defaults.headers.common[this.authorizationHeader] = null;
            return false;
        }
    }

    static async handleRejectedRequest(error: any) {
        //Manual cancellation [19.12.23, CR-2225, Alex.B]
        if (axios.isCancel(error)) return Promise.reject('Cancelled');

        //"423" means that permissions were changed and we need to update access token
        if (error.response.status === 423) {
            await axios.post(`${this.baseUrl}/Refresh`, {}, { withCredentials: true })
                .then((resp) => {
                    ApiClient.configureAuthorization(resp.data.accessToken);
                    store.dispatch(setUserInfo(ApiClient.getUserInfo()));
                    return resp;
                }).catch((resp) => {
                    ApiClient.removeAuthorization();
                    window.location.href = Url.Authentication.Logout;
                    return Promise.reject(resp);
                });
            window.location.reload();
            return;
        }
        else if (error.response.status === 401) {
            const originalRequest = error.config;

            if (!ApiClient.refreshTokenPromise) { // check for an existing in-progress request
                // if nothing is in-progress, start a new refresh token request
                ApiClient.refreshTokenPromise =
                    axios.post(`${this.baseUrl}/Refresh`, {}, { withCredentials: true })
                        .then((resp) => {
                            ApiClient.refreshTokenPromise = null;
                            ApiClient.configureAuthorization(resp.data.accessToken);
                            return resp;
                        }).catch((resp) => {
                            ApiClient.removeAuthorization();
                            window.location.href = Url.Authentication.Logout;
                            return Promise.reject(resp);
                        });
            }

            return ApiClient.refreshTokenPromise
                .then(resp => {
                    originalRequest.headers[this.authorizationHeader] = `Bearer ${resp.data.accessToken}`;
                    return axios(originalRequest);
                });
        }
        else if (error.response.status === 403) { // forbidden
            window.location.href = "/";
        }
        else {
            return Promise.reject(error);
        }
    }

    static async post(url: string, formData?: any, config?: AxiosRequestConfig) {
        return axios.post(this.baseUrl + url, formData, config)
            .then((resp) => {
                if (!resp.data) {
                    return Promise.reject(resp);
                }
                return resp.data;
            })
            .catch((resp) => {
                if (resp.response !== undefined && resp.response.status === 401) {
                    ApiClient.removeAuthorization();
                    window.location.href = Url.Authentication.Logout;
                }
                return Promise.reject(resp);
            });
    }

    static async put(url: string, formData?: any, config?: AxiosRequestConfig) {
        return axios.put(this.baseUrl + url, formData, config)
            .then((resp) => {
                if (!resp.data) {
                    return Promise.reject(resp);
                }
                return resp.data;
            })
            .catch((resp) => {
                if (resp.response !== undefined && resp.response.status === 401) {
                    ApiClient.removeAuthorization();
                    window.location.href = Url.Authentication.Logout;
                }
                return Promise.reject(resp);
            });
    }

    static async remove(url: string, content?: any) {
        return axios.delete(this.baseUrl + url, {
            data: content
        })
            .then((resp) => {
                return resp.data;
            })
            .catch((resp) => {
                if (resp.response !== undefined && resp.response.status === 401) {
                    ApiClient.removeAuthorization();
                    window.location.href = Url.Authentication.Logout;
                }
                return Promise.reject(resp);
            });
    }

    static async get(url: string, config?: AxiosRequestConfig) {
        return axios.get(this.baseUrl + url, config)
            .then((resp) => {
                return resp.data;
            })
            .catch((resp) => {
                if (resp.response !== undefined && resp.response.status === 401) {
                    ApiClient.removeAuthorization();
                    window.location.href = Url.Authentication.Logout;
                }
                return Promise.reject(resp);
            });
    }

    static async all(values: (AxiosResponse<any> | Promise<AxiosResponse<any>> | Promise<any>)[]): Promise<any[]> {
        return axios.all(values)
            .then((resp) => {
                return resp;
            })
            .catch((resp) => {
                if (resp.response !== undefined && resp.response.status === 401) {
                    ApiClient.removeAuthorization();
                    window.location.href = Url.Authentication.Logout;
                }
                return Promise.reject(resp);
            });
    }

    static configureAuthorization(accessToken: string) {
        axios.defaults.headers.common[this.authorizationHeader] = `Bearer ${accessToken}`;

        localStorage.setItem(LocalStorageKey.AccessToken, accessToken);
    }

    static removeAuthorization() {
        axios.defaults.headers.common[this.authorizationHeader] = null;

        localStorage.removeItem(LocalStorageKey.AccessToken);
    }

    static getUserInfo() {
        const token = localStorage.getItem(LocalStorageKey.AccessToken);
        if (token) {
            const decodedToken = jwtDecode(token);
            return new UserInfo(decodedToken);
        }
        return new UserInfo();
    }

    static toServerDate(date: Date, includeTime: boolean = false) {
        const serverDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
        if (includeTime) {
            serverDate.setUTCHours(date.getHours(), date.getMinutes(), 0); // TODO check UTC
        }
        return serverDate;
    }

    static toDate(date: any) {
        return new Date(date);
    }
}
