import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { CommissionViewProps } from "./props";
import useStyles from "./css";
import { useAppSelector } from "../../../../../redux/hooks";
import { selectAccountSettings } from "../../../../../redux/store";
import useCurrencyValueTemplate from "../../../../../hooks/useCurrencyValueTemplate";
import { UserCommissionType } from "../../../../../models/enums/user-commission-type";
import { CommissionType } from "../../../../../models/enums/commission-type";
import { CommissionByServiceEntity } from "../../../../../models/commission-by-service-entity";
import InputCurrency from "../../../../common/InputCurrency";
import Select from "../../../../common/Select";
import ConfirmationModal from "../ConfirmationModal";
import { TerminologyType } from "../../../../../models/enums/terminology-type";
import useCustomerTerminology from "../../../../../hooks/useCustomerTerminology";
import { TerminologyForm } from "../../../../../models/enums/terminology-form";
import useTerminology from "../../../../../hooks/useTerminology";


export default function CommissionView(props: CommissionViewProps) {
  const { t } = useTranslation("settings");
  const classes = useStyles();
  
  const {
    userCommissionsInfo,
    setUserCommissionsInfo,
    isUserCommissionTypeChangeConfirmed,
    setIsUserCommissionTypeChangeConfirmed
  } = props;
  
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState<boolean>(false);
  
  const accountSettings = useAppSelector(selectAccountSettings);
  const percentageTemplate = "{0}%";
  const getTemplatedCurrencyValue = useCurrencyValueTemplate(accountSettings.currencyTemplate);
  
  const needConfirmUserCommissionTypeChange = () => {
    return userCommissionsInfo.initialUserCommissionType !== userCommissionsInfo.userCommissionType && !isUserCommissionTypeChangeConfirmed;
  }
  
  const getCurrencySign = () => {
    return accountSettings.currencyTemplate.replace("{0}", "");
  }
  
  const commissionTypeToDisplayable = (type: CommissionType | null) => {
    if (type === CommissionType.Absolute) {
      return getCurrencySign();
    } else if (type === CommissionType.Percentage) {
      return "%";
    }
    return null;
  }
  
  const displayableToCommissionType = (type: string) => {
    if (type === "%") {
      return CommissionType.Percentage;
    }
    return CommissionType.Absolute;
  }
  
  const getPercentageValueByAbsolute = (service: CommissionByServiceEntity): number => {
    let newVal = service.commissionValue / service.servicePrice * 100;
    newVal = Math.round((newVal + Number.EPSILON) * 100) / 100;
    return newVal > 100 ? 100 : newVal;
  }
  
  const getAbsoluteValueByPercentage = (service: CommissionByServiceEntity): number => {
    const newVal = service.servicePrice * (service.commissionValue / 100);
    return Math.round((newVal + Number.EPSILON) * 100) / 100;
  }
  
  const getServiceAbsoluteTemplate = (service: CommissionByServiceEntity): string => {
    return accountSettings.currencyTemplate + ` (${percentageTemplate.replace("{0}", getPercentageValueByAbsolute(service) + "")})`
  }
  
  const getServicePercentageTemplate = (service: CommissionByServiceEntity): string => {
    return percentageTemplate + ` (${getTemplatedCurrencyValue(getAbsoluteValueByPercentage(service))})`
  }
  
  const displayableCommissionTypes: string[] = [
    commissionTypeToDisplayable(CommissionType.Absolute)!,
    commissionTypeToDisplayable(CommissionType.Percentage)!,
  ];
  
  const setServiceCommissionValue = (service: CommissionByServiceEntity, value: number) => {
    service.commissionValue = value;
    setUserCommissionsInfo({
      ...userCommissionsInfo,
      commissionsByServices: userCommissionsInfo.commissionsByServices
    });
    if (needConfirmUserCommissionTypeChange() && userCommissionsInfo.commissionValue !== value) {
      setConfirmationModalOpen(true);
    }
  }
  
  const setServiceCommissionType = (service: CommissionByServiceEntity, type: string) => {
    service.commissionType = displayableToCommissionType(type);
    if (service.commissionType === CommissionType.Percentage) {
      service.commissionValue = getPercentageValueByAbsolute(service);
    } else if (service.commissionType === CommissionType.Absolute) {
      service.commissionValue = getAbsoluteValueByPercentage(service);
    }
    setUserCommissionsInfo({
      ...userCommissionsInfo,
      commissionsByServices: userCommissionsInfo.commissionsByServices
    });
    if (needConfirmUserCommissionTypeChange() && userCommissionsInfo.commissionType !== service.commissionType) {
      setConfirmationModalOpen(true);
    }
  }
  
  const setCommissionValue = (value: number) => {
    setUserCommissionsInfo({ ...userCommissionsInfo, commissionValue: value });
    if (needConfirmUserCommissionTypeChange()) {
      setConfirmationModalOpen(true);
    }
  }
  
  const setCommissionType = (displayableType: string) => {
    const type = displayableToCommissionType(displayableType);
    if (type === CommissionType.Percentage) {
      let value: number | null = null;
      if (userCommissionsInfo.commissionValue && userCommissionsInfo.commissionValue > 100) {
        value = 100;
      }
      if (value !== null) {
        setUserCommissionsInfo({ ...userCommissionsInfo, commissionType: type, commissionValue: value });
        return;
      }
    }
    setUserCommissionsInfo({ ...userCommissionsInfo, commissionType: type });
    if (needConfirmUserCommissionTypeChange()) {
      setConfirmationModalOpen(true);
    }
  }
  
  const confirmUserCommissionTypeChange = () => {
    setIsUserCommissionTypeChangeConfirmed(true);
    setConfirmationModalOpen(false);
  }
  
  const rejectUserCommissionTypeChange = () => {
    setConfirmationModalOpen(false);
  }
  
  const getTerminology = (form: TerminologyForm): string => {
    const terminology = accountSettings.terminologies.find(t => t.terminologyType === TerminologyType.Commission);
    return (form === TerminologyForm.Plural ? terminology?.pluralForm ?? t('commissions') : terminology?.singularForm ?? t('commission'))!.toLowerCase();
  }
  
  const renderServiceItem = (service: CommissionByServiceEntity) => {
    return <div key={service.serviceId} className={classes.commissionByServiceItem}>
      <div className={classes.serviceName}>{service.serviceName} {getTemplatedCurrencyValue(service.servicePrice)}</div>
      <div className={classes.commissionByServiceValues}>
        <InputCurrency
          value={service.commissionValue}
          setValue={(value: number) => setServiceCommissionValue(service, value)}
          valueTemplate={service.commissionType === CommissionType.Absolute
            ? getServiceAbsoluteTemplate(service)
            : getServicePercentageTemplate(service)}
          isZeroAllowed={true}
          isValid={() => {
            return true;
          }}
          maxValue={service.commissionType === CommissionType.Percentage ? 100 : undefined}
        />
        <Select
          key={service.serviceId}
          width={100}
          value={commissionTypeToDisplayable(service.commissionType)}
          onChange={(event) => setServiceCommissionType(service, event.target.value)}
          colorArrowIcon={"#5C6477"}
          items={displayableCommissionTypes}/>
      </div>
    </div>
  }
  
  const defaultCommission = useTerminology({ type: TerminologyType.Commission, template: t("Default commission") });
  
  return (
    <>
      {userCommissionsInfo.userCommissionType === UserCommissionType.ByService &&
        <>
          <div className={classes.commissionsByServices}>
            {userCommissionsInfo.commissionsByServices.map(renderServiceItem)}
          </div>
        </>
      }
      {userCommissionsInfo.userCommissionType === UserCommissionType.Default &&
        <>
          <div className={classes.contentHeader}>
            {defaultCommission}
            <span className={classes.dot}>*</span>
          </div>
          <div className={classes.defaultCommissionValues}>
            <InputCurrency
              value={userCommissionsInfo.commissionValue}
              setValue={(value: number) => setCommissionValue(value)}
              valueTemplate={userCommissionsInfo.commissionType === CommissionType.Absolute
                ? accountSettings.currencyTemplate
                : userCommissionsInfo.commissionType === CommissionType.Percentage ? percentageTemplate : "{0}"}
              placeholder={"--"}
              isZeroAllowed={true}
              isValid={() => {
                return true;
              }}
              maxValue={userCommissionsInfo.commissionType === CommissionType.Percentage ? 100 : undefined}
            />
            <Select
              key={""}
              width={100}
              value={commissionTypeToDisplayable(userCommissionsInfo.commissionType)}
              placeholder={"--"}
              onChange={(event) => setCommissionType(event.target.value)}
              colorArrowIcon={"#5C6477"}
              items={displayableCommissionTypes}/>
          </div>
        </>
      }
      <ConfirmationModal
        open={isConfirmationModalOpen}
        title={t("Do you want to continue?")}
        body={
          <>
            <div style={{display: userCommissionsInfo.userCommissionType === UserCommissionType.ByService ? "none" : ""}}>
              {useCustomerTerminology(t("If you modify this field, this user can no longer have different commissions for each service and instead will have a default commission for all services."), getTerminology(TerminologyForm.Singular), getTerminology(TerminologyForm.Plural))}
            </div>
            <div style={{display: userCommissionsInfo.userCommissionType === UserCommissionType.Default ? "none" : ""}}>
              {useCustomerTerminology(t("If you modify this field, this user will no longer have a default commission for all services and instead can have different commissions for each service."), getTerminology(TerminologyForm.Singular), getTerminology(TerminologyForm.Plural))}
            </div>
          </>
        }
        okTextButton={t("Yes, continue")}
        cancelTextButton={t("Go back")}
        onConfirm={confirmUserCommissionTypeChange}
        onCancel={rejectUserCommissionTypeChange}
        onClose={confirmUserCommissionTypeChange}
      />
    </>);
};