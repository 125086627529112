import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: 26,
  },
  disabled: {
    opacity: 0.5,
  },
  header: {
    display: "flex",
  },
  subHeader: {
    marginTop: 28,
    display: "flex",
    width: "100%",
  },
  subTitle: {
    display: "flex",
    width: "50%",
  },
  stepTitle: {
    ...theme.typography.header2,
    color: theme.colors.clearMain,
    fontWeight: "bold",
  },
  separator: {
    height: 0,
    borderTop: `1px solid ${theme.colors.grey2}`,
    margin: "24px 0px 24px 0px",
  },
  selectStyle: {
    color: theme.colors.grey5,
    fontWeight: "bold",
    fontSize: 12,
    height: 40,
    padding: "13px 0px 13px 12px",
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
    width: 91,
    paddingRight: 10,
    backgroundColor: "#F6F6F6",
    cursor: "pointer",
    "& * .ant-picker-suffix, .ant-picker-clear": {
      display: "none",
    },
    "& * input": {
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: 12,
      textAlign: "center",
      fontStyle: "normal",
      color: theme.colors.grey5,
      cursor: "pointer",
    },
  },
  dateTimeSubTitle: {
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: theme.colors.grey5
  },
  switchSubTitle: {
    ...theme.typography.txtBody2,
    fontWeight: 700,
    color: theme.colors.grey4
  },
  calendarIcon: {
    marginLeft: 7,
    marginRight: 13,
    color: theme.colors.grey5,
  },
  dateTimeContent: {
    display: "flex",
    width: "48%",
    justifyContent: "end"
  },
  dateTimeTxt: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.clearMain,
  },
  bodyContent: {
    marginTop: 11,
  },
  requiredField: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey4,
  },
  calendarScheduler: {
    display: "flex",
    marginTop: 18,
  },
  rootCalendar: {
    border: `1px solid ${theme.colors.grey1}`,
    boxSizing: "border-box",
    borderRadius: 8,
    boxShadow: "0px 3px 20px rgba(229, 231, 234, 0.56)",
  },
  calendar: {},
  schedule: {
    width: "100%",
    height: "auto",
    marginLeft: 32,
    marginTop: 18
  },
  scheduleContent: {
    marginTop: 15,
  },
  dateRoot: {
    width: 36,
    height: 36,
    margin: "0 2px",
  },
  datePicker: {
    marginTop: 16,
  },
  date: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey7,
    width: 36,
    height: 36,
    margin: "0 2px",
  },
  currentDate: {
    fontWeight: "bold",
    color: theme.colors.clearMain,
  },
  selectedDate: {
    fontWeight: "bold",
    background: theme.colors.clearMain,
    color: "#FFFFFF",
    "&:hover": {
      background: theme.colors.clearMain,
    },
  },
  ulSchedule: {
    flexWrap: "wrap",
    display: "flex",
    listStyle: "none",
    padding: 0
  },
  liSchedule: {
    width: 116,
    height: 32,
    marginRight: 16,
    marginBottom: 16,
    borderRadius: 51,
    cursor: "pointer",
    display: "flex",
    flex: "0 0 30%",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    boxSizing: "border-box",
    ...theme.typography.buttons,
    fontWeight: "bold",
    color: theme.colors.clearMain,
    border: `1px solid ${theme.colors.clearMain}`,
    "&:nth-child(3n + 3)": {
      marginRight: 0,
    },
    "&:hover": {
      background: theme.colors.clearMain,
      color: theme.colors.grey1,
    },
  },
  liScheduleActive: {
    background: theme.colors.clearMain,
    color: theme.colors.grey1,
  },
  scheduleInvalid: {
    ...theme.typography.txtBody2,
    fontSize: 14,
    lineHeight: "18px",
    fontFamily: "Roboto",
    fontWeight: 400
  },
  calendarNoTime: {
    textAlign: "center",
    paddingTop: 25
  },
  calendarTitle: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14,
    textAlign: "center",
    color: theme.colors.grey4,
    marginTop: 20,
    marginBottom: 15,
    padding: "0 60px"
  },
  calendarSubTitle: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontSize: 12,
    textAlign: "center",
    color: theme.colors.grey4,
    padding: "0 60px"
  },
  
  required: {
    color: "#6462F3",
  },
  rowContainer: {
    margin: "24px 0px 0px 0px",
  },
  switch: {
    display: "flex",
    alignItems: "center",
  },
  switchLabel: {
      margin: "0px 0px 0px 7px",
      paddingTop: 6
  },
  switchBase: {
    padding: 1,
    opacity: 1,
    height: "100%",
    border: "3px solid #323947",
    "&.Mui-checked": {
        transform: "translateX(13px)",
        border: "0px",
        color: "#FFFFFF",
        "& + .MuiSwitch-track": {
            backgroundColor: theme.colors.clearGreen,
            opacity: 1,
            border: "none",
        },
        "& * .MuiSwitch-thumb": {
            width: 15,
        },
    },
    "& * .MuiSwitch-thumb": {
        width: 12,
        height: 15,
        boxShadow: "none",
    },
  },
  inputSelect: {
    ...theme.typography.txtBody2,
    backgroundColor: theme.colors.grey1,
    height: 40,
    width: 312,
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
        "&::placeholder": {
            fontFamily: "Roboto",
            fontStyle: "normal",
            fontSize: 12,
            color: "#5C6477",
        },
  },
  radioLabelRoot: {
    margin: 0,
  },
  radioLabel: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey4,
  },
  radioOption: {
    "&.Mui-checked": {
      "&, & + .MuiFormControlLabel-label": {
        color: theme.colors.clearMain,
      },
    },
  },
  label: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    fontWeight: "bold",
    display: "block",
    marginBottom: "0.5rem",
  },
  labelAppointment: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 12,
    color: theme.colors.grey4,
  },
  inputDisabled: {
    opacity: "0.5"
  },
  scheduleDisabled: {
    pointerEvents: "none",
    opacity: "0.5"
  },
  itemRoot: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    height: 40,
    display: "flex",
    fontSize: 12,
    alignItems: "center"
  },
}));

export default useStyles;
