import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const EditIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={style || { width: 16, height: 16 }}
      viewBox={viewBox || "0 0 16 16"}
    >
      <path d="M10.0625 2.9375L1.375 11.625L1 15.1875C0.9375 15.6562 1.34375 16.0625 1.8125 16L5.375 15.625L14.0625 6.9375L10.0625 2.9375ZM16.5312 2.34375L14.6562 0.46875C14.0938 -0.125 13.125 -0.125 12.5312 0.46875L10.7812 2.21875L14.7812 6.21875L16.5312 4.46875C17.125 3.875 17.125 2.90625 16.5312 2.34375Z" />
    </SvgIcon>
  );
};

export default EditIcon;
