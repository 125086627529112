export class ImportExcelResponse {
    isFileProcessed: boolean | null;
    processedRecordsCount: number | null;
    totalRecordsCount: number | null;
    invalidRecordsFile: Uint8Array | null;

    constructor(x?: any) {
        if (typeof x === "object") {
            this.isFileProcessed = x.isFileProcessed;
            this.processedRecordsCount = x.processedRecordsCount;
            this.totalRecordsCount = x.totalRecordsCount;
            this.invalidRecordsFile = x.invalidRecordsFile ? Buffer.from(x.invalidRecordsFile, 'base64') : null;
        } else {
            this.isFileProcessed = null;
            this.processedRecordsCount = null;
            this.totalRecordsCount = null;
            this.invalidRecordsFile = null;
        }
    }
}
