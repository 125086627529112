import React, { useEffect, useState, useRef } from "react";

import Box from "@material-ui/core/Box";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Tooltip from "@material-ui/core/Tooltip";

import EnhancedTableHead from "./../EnhancedTableHead";
import { TableColumn } from "../table-column";

import Pagination from "./../../Pagination";

import useStyles from "./css";
import { GridListProps } from "./props";

export default function GridList(props: GridListProps) {
  const {
    columns,
    items,
    totalItems,
    pageSize,
    height,
    width,
    onChange,
    onRowClick,
    classRow,
    classTableBody,
    classTableContainer,
    showPaginationAlways,
    tooltipIsVisible
  } = props;

  const customClasses = useStyles(height, width)();
  const [page, setPage] = useState<number>(1);
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("");
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const tableContainerRef = useRef<HTMLDivElement>(null);
  const prevColumnsLength = useRef(columns.length);
  const elementsRefs = useRef<(HTMLSpanElement | null)[]>([]);

  useEffect(() => {
    if (onChange) {
      onChange({
        page: page,
        sorting:
          order && orderBy
            ? {
                direction: order,
                orderBy: orderBy,
              }
            : undefined,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, order, orderBy]);
  
  useEffect(() => {
    if (columns.length > prevColumnsLength.current ) {
      tableContainerRef.current!.scrollLeft += 9999;
    }
    prevColumnsLength.current = columns.length;
  }, [columns.length]);
  
  function onMouseEnterItem(rowIndex: number, colIndex: number) {
    let offsetWidth = elementsRefs.current[getUniqueIndexForCell(rowIndex, colIndex)]?.offsetWidth ?? 0;
    let scrollWidth = elementsRefs.current[getUniqueIndexForCell(rowIndex, colIndex)]?.scrollWidth ?? 0;
    
    if (offsetWidth < scrollWidth) {
      setShowTooltip(true);
    }
  }
  function onMouseLeaveItem() {
    setShowTooltip(false);
  }
  
  function getUniqueIndexForCell(rowIndex: number, colIndex: number) {
     return rowIndex * 100000 + colIndex;
  }
  
  const handleRequestSort = (event: any, property: string) => {
    const isAsc = orderBy === property && order === "asc";
    setPage(1);
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handlePage = (event: React.ChangeEvent<unknown>, page: number) => {
    setPage(page);
  };

  const handleRowClick = <T extends unknown>(item: T) => {
    if (onRowClick) {
      onRowClick(item);
    }
  };

  return (
    <Box style={{ height: height }}>
      <div className={customClasses.container}>
        <TableContainer
          component={Paper}
          className={classTableContainer ?? customClasses.tableContainer}
        >
          <div ref={tableContainerRef} className={classTableBody ?? customClasses.tableBody}>
            <Table stickyHeader aria-label="sticky table">
              <EnhancedTableHead
                columns={columns}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
              />
              
              <TableBody>
                {items.map((item: any, rowIndex: number) => (
                  <TableRow
                    key={rowIndex}
                    id={`$GridList-${rowIndex}`}
                    className={classRow}
                    onClick={() => handleRowClick(item)}
                    hover
                  >
                    {columns.map((column: TableColumn, colIndex: number) => (
                      (column.visible! ?? true) && <>
                      <TableCell
                        key={colIndex}
                        className={`${customClasses.rowText} ${
                          column.rowClassName || ""
                        }`.trim()}
                        style={{
                          width: column.width,
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                          textAlign: column.textAlign
                            ? column.textAlign
                            : "left",
                          ...column.cellStyle,
                        }}
                      >
                        {!column.component ? (
                          <Tooltip
                            title={tooltipIsVisible && showTooltip ? item[column.id] : ""}
                            placement="bottom"
                            classes={{ popper: customClasses.popper }}>
                            <Typography
                              onMouseEnter={() => onMouseEnterItem(rowIndex, colIndex)} 
                              onMouseLeave={onMouseLeaveItem}
                              className={`${customClasses.rowText} ${column.rowClassName || ""}`.trim()}
                              style={column.rowStyle}
                              ref={(ref) => elementsRefs.current[getUniqueIndexForCell(rowIndex, colIndex)] = ref}
                            >
                              {item[column.id]}
                            </Typography>
                          </Tooltip>
                        ) : (
                          column.component(
                            column.completeObject ? item : item[column.id]
                          )
                        )}
                      </TableCell>
                       </>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </TableContainer>
        <Pagination
          count={Math.ceil(totalItems / (pageSize || 20))}
          page={page || 1}
          onPageChange={handlePage}
          showPaginationAlways={showPaginationAlways}
        />
      </div>
    </Box>
  );
}