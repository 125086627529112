import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

import { Url } from "./../../../constants/url";
import CustomerMainPage from "./../CustomerMainPage";
import CustomerDetails from "./../CustomerDetails";

const CustomerRouter = () => {
  return (
    <Switch>
      <Route exact path={Url.Customers.Main} component={CustomerMainPage} />
      <Route
        exact
        path={Url.Customers.Main + "/:customerId"}
        component={CustomerDetails}
      />
      <Route path={Url.Customers.Main}>
        <Redirect to={Url.Customers.Main} />
      </Route>
    </Switch>
  );
};

export default CustomerRouter;
