import { ApiClient } from "./api-client";
import { Message } from "../models/message";
import { ConversationPreviewsList } from "../models/conversation-previews-list";
import { ConversationPreview } from "../models/conversation-preview";
import { WhatsAppTemplate } from "../models/whatsapp-template";
import { WhatsAppTemplateType } from "../models/enums/whatsapp-template-type";
import { AvailabilityStatus } from "../models/enums/availability-status";
import { CustomerCommunicationWa } from "../models/customer-communication-wa";
import { GetSpecificConversationMessagesResponse } from "../models/get-specific-conversation-messages-response";


export class WhatsAppService {
  private static baseUrl: string = "/whatsapp/api/Conversation";
  private static accountUrl: string = "/whatsapp/api/Account";

  static getConversationsPreview(page: number, pagesize: number): Promise<ConversationPreviewsList> {
    return ApiClient.get(`${this.baseUrl}/GetConversationsPreviews?Page=${page}&Pagesize=${pagesize}`);
  }

  static getConversationsPreviewByEstablishments(page: number, pagesize: number, establishmentIds: string[] | undefined): Promise<ConversationPreviewsList> {
    let endpoint = `${this.baseUrl}/GetConversationsPreviewsForEstablishments?`;

    endpoint += Object.entries({
      Page: page,
      PageSize: pagesize,
      EstablishmentIds: establishmentIds && establishmentIds.length > 0 ? establishmentIds : [],
    }).flatMap(([key, value]) => [value].flat().map(v => [key, v]))
      .map(it => it.join("="))
      .join("&")

    return ApiClient.get(endpoint);
  }

  static async getSearch(searchQuery: string, page: number, pagesize: number): Promise<ConversationPreview[]> {
    return await ApiClient
      .get(`${this.baseUrl}/GetSearch?SearchQueryString=${searchQuery}&Page=${page}&PageSize=${pagesize}`)
      .then((data) => data.conversationPreviews);
  }

  static async getSearchByEstablishments(searchQuery: string, page: number, pagesize: number, establishmentIds: string[] | undefined): Promise<ConversationPreview[]> {
    let endpoint = `${this.baseUrl}/GetSearchForEstablishments?`;

    endpoint += Object.entries({
      SearchQueryString: searchQuery,
      Page: page,
      PageSize: pagesize,
      EstablishmentIds: establishmentIds && establishmentIds.length > 0 ? establishmentIds : [],
    }).flatMap(([key, value]) => [value].flat().map(v => [key, v]))
      .map(it => it.join("="))
      .join("&")

    return await ApiClient
      .get(endpoint)
      .then((data) => data.conversationPreviews);
  }

  static async getSpecificConversationMessages(
    customerMobile: string,
    messagesOffset: number,
    establishmentId: string | null, 
    pageNumber: number | null,
    targetMessageId: string | null): Promise<GetSpecificConversationMessagesResponse> {
    return await ApiClient.get(`${this.baseUrl}/GetSpecificConversationMessages?CustomerMobile=${customerMobile}&MessagesOffset=${messagesOffset}&establishmentId=${establishmentId ?? ""}&PageNumber=${pageNumber ?? ""}&TargetMessageId=${targetMessageId ?? ""}`)
      .then(
        (data) => new GetSpecificConversationMessagesResponse(data)
      );
  }

  static updateAvailabilityStatus(availabilityStatus: AvailabilityStatus): Promise<boolean> {
    return ApiClient.post(`${this.baseUrl}/updateAvailabilityStatus`, { availabilityStatus })
      .then(
        (data) => data.IsAvailabilityStatusUpdated
      );
  }

  static markConversationAsRead(customerMobile: string): Promise<boolean> {
    return ApiClient.put(`${this.baseUrl}/MarkConversationAsRead?CustomerMobile=${customerMobile}`)
      .then(
        (data) => data.isMessagesUpdated
      );
  }

  static markConversationAsReadAsEstablishment(establishmentId: string, customerMobile: string): Promise<boolean> {
    return ApiClient.put(`${this.baseUrl}/MarkConversationAsReadAsEstablishment?CustomerMobile=${customerMobile}&EstablishmentId=${establishmentId}`)
      .then(
        (data) => data.isMessagesUpdated
      );
  }

  static sendTextMessage(customerId: string, customerMobile: string, text: string): Promise<boolean> {
    return ApiClient.post(`${this.baseUrl}/SendTextMessage`, { customerId, customerMobile, text })
      .then(
        (data) => data.isSent
      );
  }

  static sendTextMessageAsEstablishment(customerId: string, establishmentId: string, customerMobile: string, text: string): Promise<boolean> {
    return ApiClient.post(`${this.baseUrl}/SendTextMessageAsEstablishment`, { customerId, establishmentId, customerMobile, text })
      .then(
        (data) => data.isSent
      );
  }

  static scheduleFollowUp(customerId: string, customerMobile: string): Promise<boolean> {
    return ApiClient.post(`${this.baseUrl}/ScheduleFollowUp`, { customerId, customerMobile })
      .then(
        (data) => data.isScheduled
      );
  }

  static sendMercadoPagoLink(customerId: string, customerName: string, customerMobile: string): Promise<boolean> {
    return ApiClient.post(`${this.baseUrl}/SendMercadoPagoLink`, { customerId, customerName, customerMobile })
      .then(
        (data) => data.isSent
      );
  }

  static sendTemplate(customerId: string, customerName: string, customerMobile: string, templateType: WhatsAppTemplateType): Promise<boolean> {
    return ApiClient.post(`${this.baseUrl}/SendTemplate`, { customerId, customerName, customerMobile, templateType })
      .then(
        (data) => data.isSent
      );
  }

  static async getCustomerCommunication(establishmentAccountId?: string): Promise<CustomerCommunicationWa> {
    let endpoint = `${this.accountUrl}/getcustomercommunications`;
    if (establishmentAccountId)
        endpoint += `?AccountId=${establishmentAccountId}`;
    const data = await ApiClient.get(endpoint);
    return new CustomerCommunicationWa(data.customerCommunications);
  }

  static async updateCustomerCommunication(model: CustomerCommunicationWa): Promise<CustomerCommunicationWa> {
    return ApiClient.post(`${this.accountUrl}/updatecustomercommunications`, model)
      .then(data => new CustomerCommunicationWa(data.customerCommunications)
      );
  }

  static getAvailableTemplates(customerId?: string): Promise<WhatsAppTemplate[]> {
    let url = `${this.baseUrl}/GetAvailableTemplates`;

    if (customerId) {
      url += `?CustomerId=${customerId}`;
    }

    return ApiClient
      .get(url)
      .then(
        (data) => data.availableTemplates
      );
  }
  
  static blockCustomer(customerId: string, customerMobile: string, isBlocked: boolean): Promise<boolean> {
    return ApiClient.post(`${this.baseUrl}/blockCustomer`, { customerId, customerMobile, isBlocked })
      .then(
        (data) => data.isBlocked
      );
  }
}