export enum MassiveSendingBirthdayType
{
    SameDay = 0,
    OneDayBefore,
    OneWeekBefore,
    TwoWeeksBefore,
    OneMonthBefore
}


export const GetSendingBirthdayStringEnum = (num: MassiveSendingBirthdayType | number): string => {
    
    const birthdaySendingRules: { [key: number] : string } = {
        0: "Same day of the birthday",
        1: "One day before the birthday",
        2: "One week before the birthday",
        3: "Two weeks before the birthday",
        4: "One month before the birthday",
    };

    return birthdaySendingRules[num] || "";
}

