import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionFieldPhoneIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                fillRule="evenodd" 
                clipRule="evenodd"
                d="M9.09192 5.8853C8.95248 5.44289 8.81192 5.08953 8.34768 5.04529L7.464 5.00105C6.5344 4.95737 5 6.28347 5 8.49439C5.04648 12.3417 11.0911 18.6216 15.0901 18.9755C17.4617 19.1967 18.9496 17.8701 18.9961 16.941V16.1452C19.0426 15.7028 18.6707 15.5259 18.2054 15.3495C17.32 15.0375 16.4386 14.6416 15.5554 14.3319C15.3937 14.2964 15.2261 14.2979 15.0651 14.3362C14.904 14.3745 14.7537 14.4486 14.6253 14.5531C14.3253 14.7794 14.0453 15.0308 13.7881 15.3047C13.5557 15.4816 13.3233 15.5701 13.0903 15.3489C11.1065 14.3903 9.5254 12.7612 8.62656 10.7495C8.58593 10.699 8.55703 10.64 8.54194 10.577C8.52685 10.5139 8.52594 10.4482 8.53928 10.3847C8.55262 10.3213 8.57987 10.2615 8.61907 10.2099C8.65828 10.1582 8.70846 10.1159 8.766 10.0859C9.09136 9.82049 9.326 9.59929 9.6032 9.3344C9.72677 9.23558 9.82197 9.1058 9.87911 8.95826C9.93626 8.81071 9.95332 8.65066 9.92856 8.49439C9.648 7.65437 9.3708 6.72532 9.09192 5.8853Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}