import clsx from 'clsx';
import React, { CSSProperties, memo } from 'react';
import { KeyGetterFunction, getGroupKeyOffsetVariable } from '../_util';
import ContentGrid, { ContentGridColumnComponent, ContentGridTdComponent } from './ContentGrid';
import styles from './css.module.css';

export type AppointmentSchedulerContentComponents<G> = {
    ContentInnerWrapperComponent?: ContentInnerWrapperComponent;
    ContentGridTdComponent?: ContentGridTdComponent<G>;
    ContentGridColumnComponent?: ContentGridColumnComponent<G>;
};

export type AppointmentSchedulerContentProps<I, G> = ItemsProps<I> &
    AppointmentSchedulerContentComponents<G> & {
        groups: G[];
        intervalInMinutes: number;
        classNameRoot?: string;
        contentGridRootRef?: React.RefCallback<HTMLElement>;
        getGroupKey: KeyGetterFunction<G>;
    };

export type ContentInnerWrapperComponent = React.ComponentType<React.PropsWithChildren<{}>>;
const PassThroughWrapper: ContentInnerWrapperComponent = memo(({ children }) => <>{children}</>);

export default class AppointmentSchedulerContent<I, G> extends React.PureComponent<
    AppointmentSchedulerContentProps<I, G>
> {
    render() {
        const { classNameRoot } = this.props;

        return (
            <main style={{ width: 'fit-content' }} className={clsx(styles.root, classNameRoot)}>
                {this.renderContentGrid()}
            </main>
        );
    }
    renderContentGrid(): React.ReactNode {
        const {
            groups,
            from,
            to,
            pixelsPerMinute,
            intervalInMinutes,
            ContentGridTdComponent,
            ContentInnerWrapperComponent = PassThroughWrapper,
            contentGridRootRef,
        } = this.props;
        return (
            <ContentGrid<G>
                pixelsPerMinute={pixelsPerMinute}
                intervalInMinutes={intervalInMinutes}
                from={from}
                to={to}
                groups={groups}
                ContentGridTdComponent={ContentGridTdComponent}
                contentGridRootRef={contentGridRootRef}
            >
                <ContentInnerWrapperComponent>
                    {this.renderColumns()}
                    {this.renderItems()}
                </ContentInnerWrapperComponent>
            </ContentGrid>
        );
    }

    private renderItems() {
        const {
            getItemGroupKey,
            getItemData,
            getItemKey,
            items,
            component,
            pixelsPerMinute,
            from,
            to,
        } = this.props;
        return (
            <Items
                from={from}
                to={to}
                pixelsPerMinute={pixelsPerMinute}
                component={component}
                getItemData={getItemData}
                getItemGroupKey={getItemGroupKey}
                getItemKey={getItemKey}
                items={items}
            />
        );
    }

    private renderColumns() {
        const { ContentGridColumnComponent, groups, getGroupKey } = this.props;

        if (!ContentGridColumnComponent) {
            return;
        }

        return groups.map((group, i) => (
            <div className={styles.column} style={{ '--index': i } as CSSProperties}>
                <ContentGridColumnComponent key={getGroupKey(group)} group={group} />
            </div>
        ));
    }
}

export type ItemComponentProps<I> = {
    value: I;
};
export type GetItemDataFunction<I> = (item: I) => { ts: number; sizeInMinutes: number };
export type ItemComponent<I> = React.ComponentType<ItemComponentProps<I>>;
type ItemsProps<I> = {
    items: I[];
    getItemKey: KeyGetterFunction<I>;
    getItemGroupKey: KeyGetterFunction<I>;
    getItemData: GetItemDataFunction<I>;
    component: ItemComponent<I>;
    from: number;
    to: number;
    pixelsPerMinute: number;
};

class Items<I> extends React.PureComponent<ItemsProps<I>> {
    render() {
        const { items } = this.props;

        return <>{items.map((i) => this._renderItem(i))}</>;
    }

    private _renderItem(i: I) {
        const {
            getItemData,
            getItemGroupKey,
            getItemKey,
            component: Component,
            from,
            pixelsPerMinute,
        } = this.props;
        const { ts, sizeInMinutes } = getItemData(i);
        const groupKey = getItemGroupKey(i);

        const offsetFromStartInMinutes = ts - from;
        const positionInPixels = offsetFromStartInMinutes * pixelsPerMinute;
        const sizeInPixels = sizeInMinutes * pixelsPerMinute;

        return (
            <div
                data-group={groupKey}
                key={getItemKey(i)}
                data-ts={ts}
                className={styles.cell}
                style={
                    {
                        '--pos': `${positionInPixels}px`,
                        '--size': `${sizeInPixels}px`,
                        '--offset': `var(${getGroupKeyOffsetVariable(groupKey)})`,
                    } as CSSProperties
                }
            >
                <Component value={i} />
            </div>
        );
    }
}
