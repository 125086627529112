import React from "react";

import { useTranslation } from "react-i18next";
import { conformToMask } from "react-text-mask";
import moment from "moment";

import { useAppSelector } from "../../../../../../../redux/hooks";
import { selectAccountSettings, selectUserInfo } from "../../../../../../../redux/store";

import { TreatmentDocumentPreviewProps } from "./props";
import useTerminology from "../../../../../../../hooks/useTerminology";
import { TerminologyType } from "../../../../../../../models/enums/terminology-type";
import { TerminologyForm } from "../../../../../../../models/enums/terminology-form";
import useStyles from "./css";
import { TreatmentDocumentSize } from "../../../../../../../models/enums/treatment-document-size";
import { StaticTreatmentDocumentFieldType } from "../../../../../../../models/enums/static-treatment-document-field-type";
import SignatureExampleImage from "../../../../../../../assets/images/treatment-preview-signature.png"
import { GetMobileMaskByTemp } from "../../../../../../../constants/mask";


export default function TreatmentDocumentPreview(props: TreatmentDocumentPreviewProps) {

  const { settings } = props;
  const sectionTNamespace = "sections";
  const { t } = useTranslation(["settings", sectionTNamespace]);
  const treatmentTerm = useTerminology({ type: TerminologyType.Treatment, form: TerminologyForm.Singular });
  const classes = useStyles();
  const accountSettings = useAppSelector(selectAccountSettings);
  const userInfo = useAppSelector(selectUserInfo);

  const locale = useAppSelector(state => state.applicationInterface.locale);
  const dateFormat = locale === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY";
  const displayedDate = moment('2023-10-23').format(dateFormat);

  function getFormattedPhoneNumber(isoCode: string, body: string): string {
    const country = accountSettings.countries?.find(c => c.iso3 === isoCode);

    const dialTemplate = country?.dialTemplate ?? "";
    const dialCode = country?.dialCode ?? "";
    const maskedPnoneNumberBody = conformToMask(body, GetMobileMaskByTemp(dialTemplate)).conformedValue;

    return `+${dialCode} ${maskedPnoneNumberBody}`;
  }

  function mapDynamicFieldsToComponents(): JSX.Element[] {
    return settings.fieldSettings
    .filter(fs => fs.field !== null)
    .sort((fs1, fs2) => fs1.field!.order - fs2.field!.order)
    .map(fs => (
      <span
        className={classes.dynamicFieldValueLabel}
        style={getBoolStyle(fs.isEnabled)}
      >
        {`${t("Here your")} ${t(fs.field!.name, { ns: sectionTNamespace }).toLowerCase()} ${(t("will be displayed."))}`}
      </span>
    ));
  }

  function getPageClass(): string {
    switch (settings.documentSize) {
      // https://clearmechanic.atlassian.net/browse/CR-2437?focusedCommentId=151047
      // case TreatmentDocumentSize.HalfLetter:
      //     return classes.halfLetterPage;
      case TreatmentDocumentSize.Letter:
        return classes.letterPage;
      case TreatmentDocumentSize.Legal:
        return classes.legalPage;
      default:
        return "";
    }
  }

  function getDividerClass(): string {
    switch (settings.documentSize) {
      // https://clearmechanic.atlassian.net/browse/CR-2437?focusedCommentId=151047
      // case TreatmentDocumentSize.HalfLetter:
      //     return classes.halfLetterPage;
      case TreatmentDocumentSize.Letter:
        return classes.dividerLetter;
      case TreatmentDocumentSize.Legal:
        return classes.dividerLegal;
      default:
        return "";
    }
  }

  function getStyle(fieldType: StaticTreatmentDocumentFieldType): React.CSSProperties {
    const isEnabled = settings.fieldSettings.find(fs => fs.staticTreatmentDocumentFieldType === fieldType)?.isEnabled ?? true;

    return isEnabled
      ? { visibility: "visible" }
      : { display: "none" }
  }

  function getBoolStyle(isEnabled: boolean): React.CSSProperties {

    return isEnabled
      ? { visibility: "visible" }
      : { display: "none" }
  }

  const Header = () =>
    <div className={classes.pageHeader}>
      <div className={classes.headerSpecialistRow}>
        <div className={classes.logoSpecialistBlock}>
          <img
            className={classes.logo}
            src={settings.logoUrl ?? undefined}
            style={{
              ...getBoolStyle(settings.logoIsEnabled && (settings.logoUrl !== null))
            }}
          />

          <div className={classes.specialistInfoBlock}>
            <div className={classes.flexColumn} style={getStyle(StaticTreatmentDocumentFieldType.SpecialistFullName)}>
              <span className={classes.specialistNameLabel}>
                {userInfo.fullName}
              </span>
              <span className={classes.dynamicFieldValueLabel}>
                {t(settings.specialistJobPositionName)}
              </span>
            </div>

            {mapDynamicFieldsToComponents()}
          </div>
        </div>


        <div style={getStyle(StaticTreatmentDocumentFieldType.AppointmentDate)}>
          <span className={classes.staticFieldNameLabel}>
            {`${t("Date")}: `}
          </span>
          <span className={classes.staticFieldValueLabel}>
            {displayedDate}
          </span>
        </div>
      </div>

      <div className={classes.flexDualColumn}>
        <div className={classes.dualLeftColumn}>
          <div style={getStyle(StaticTreatmentDocumentFieldType.PatientFullName)}>
            <span className={classes.staticFieldNameLabel}>
              {`${t("Patient")}: `}
            </span>
            <span className={classes.staticFieldValueLabel}>
              {t("John Doe")}
            </span>
          </div>
        </div>

        <div className={`${classes.dualRightColumn} ${classes.patientInfoBlock}`}>
          <div style={getStyle(StaticTreatmentDocumentFieldType.PatientAge)}>
            <span className={classes.staticFieldNameLabel}>
              {`${t("Age")}: `}
            </span>
            <span className={classes.staticFieldValueLabel}>
              {t("31 years old")}
            </span>
          </div>

          <div style={getStyle(StaticTreatmentDocumentFieldType.PatientGender)}>
            <span className={classes.staticFieldNameLabel}>
              {`${t("Gender")}: `}
            </span>
            <span className={classes.staticFieldValueLabel}>
              {t("Male")}
            </span>
          </div>
        </div>
      </div>
    </div>

  const Footer = () =>
    <div className={classes.pageFooter}>
      <div className={classes.signatureContainer} style={getStyle(StaticTreatmentDocumentFieldType.SpecialistSign)}>
        <img src={SignatureExampleImage} style={{ width: "4.29cm" }} />
        <hr className={`${classes.dividerCommon} ${classes.dividerSignature}`} />
        <span className={classes.staticFieldValueLabel}>
            {t("Signature")}
          </span>
      </div>

      <div className={classes.flexRow} style={getStyle(StaticTreatmentDocumentFieldType.SpecialistPhoneNumber)}>
        <div>
            <span className={classes.staticFieldNameLabel}>
              {`${t("Appointments")}: `}
            </span>
          <span className={classes.staticFieldValueLabel}>
              {getFormattedPhoneNumber(settings.accountPhoneNumberIso, settings.accountPhoneNumberBody)}
            </span>
        </div>

        <div>
            <span className={classes.staticFieldNameLabel}>
              {`${t("Mobile")}: `}
            </span>
          <span className={classes.staticFieldValueLabel}>
              {getFormattedPhoneNumber(settings.specialistPhoneNumberIso, settings.specialistPhoneNumberBody)}
            </span>
        </div>

      </div>

      <div className={classes.flexColumn}>
          <span className={classes.staticFieldValueLabel}>
            {settings.accountAddress}
          </span>
      </div>
    </div>

  return (
    <div className={classes.previewContainer}>
      <div className={`${classes.commonPage} ${getPageClass()}`}>

        <div className={classes.pageHeaderWithContent}>
          <Header />

          <hr className={`${classes.dividerCommon} ${classes.dividerHeader} ${getDividerClass()}`} />

          <div className={classes.pageContent}>
            <div className={classes.flexDualColumn}>
              <div className={classes.dualLeftColumn}>
                <div style={getStyle(StaticTreatmentDocumentFieldType.Diagnosis)}>
                  <span className={classes.staticFieldNameLabel}>
                    {`${t("Diagnosis")}: `}
                  </span>
                  <span className={classes.staticFieldValueLabel}>
                    {t("Here the diagnosis that you prescribe in the patient evolution note will be displayed.")}
                  </span>
                </div>
              </div>
              <div className={classes.dualRightColumn}>
                <span className={classes.dynamicFieldValueLabel} style={getStyle(StaticTreatmentDocumentFieldType.VitalSigns)}>
                  {t("Here the patient vital signs that you registered in the patient evolution note will be displayed.")}
                </span>
              </div>
            </div>

            <div className={classes.flexColumn} style={getStyle(StaticTreatmentDocumentFieldType.Treatment)}>
              <span className={classes.staticFieldNameLabel}>
                {`${treatmentTerm}: `}
              </span>

              <span className={classes.staticFieldValueLabel}>
                {`${t("Here the")} ${treatmentTerm} ${t("that you prescribe in the patient evolution note will be displayed.")}`}
              </span>
            </div>

            <div className={classes.flexColumn} style={{ top: "16.5cm", left: "1cm" }}>
              <span className={classes.staticFieldNameLabel}>
                {`${t("Additional specialist instructions")}: `}
              </span>
              <span className={classes.staticFieldValueLabel}>
                {t("Here the additional specialist instructions that you prescribe in the patient evolution note will be displayed.")}
              </span>
            </div>
          </div>

        </div>

        <Footer />

      </div>
    </div>
  );
}