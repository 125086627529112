import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { DeleteIcon, CrossIcon, TrashIcon, ArrowDownIcon, BillsIcon, PlusIcon } from "../../../assets/icons";

import { Accordion, AccordionDetails, AccordionSummary } from "@material-ui/core";

import { AppointmentPaymentPartsProps } from "./props";
import useStyles from "./css";
import { AppointmentPaymentPartEntity } from "../../../models/appointment-payment-part-entity";
import AppointmentPaymentPart from "../AppointmentPaymentPart";
import { useAppSelector } from "../../../redux/hooks";
import { selectAccountSettings } from "../../../redux/store";


export default function AppointmentPaymentParts(props: AppointmentPaymentPartsProps) {
  
  const { parts, onDelete, onChange, onAdd } = props;
  
  const accountSettings = useAppSelector(selectAccountSettings);
  const paymentMethods = accountSettings.paymentMethods;
  const excludedPaymentMethodTypes = parts.filter(p => p.paymentMethod !== null).map(p => p.paymentMethod!);
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [part, setPart] = useState<AppointmentPaymentPartEntity>();
  const [indexPart, setIndexPart] = useState<number>(0);
  const [expandedAccordionIndex, setExpandedAccordionIndex] = useState<number>(0);

  const handleDeletePart = (e: any, index: number) => {
    e.preventDefault();
    e.stopPropagation();
    const part = parts.find((part, idx) => idx === index);
    setPart(part);
    setIndexPart(index);
    setOpenModal(true);
  };

  const removePart = () => {
    onDelete(indexPart);
    setOpenModal(false);
  };

  const handleOnChange =
    (index: number) => (part: AppointmentPaymentPartEntity) => {
      setExpandedAccordionIndex(index);
      onChange(index, part);
    };
  
  function handleAccordionChange(index: number) {
    const newIndex = expandedAccordionIndex === index ? -1 : index;
    setExpandedAccordionIndex(newIndex);
  }

  return (
    <div className={classes.root}>
      {parts.map((part, index) => (
        <Accordion
          className={classes.accordion}
          expanded={expandedAccordionIndex === index}
          onChange={() => handleAccordionChange(index)}
        >
          <AccordionSummary
            className={classes.accordionSummary}
            expandIcon={<ArrowDownIcon style={{height: 15, width: 15, color: "#5c6477"}} />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <div style={{width: 25}}>
              {
                index > 0 ?
                  <span  onClick={(e) => handleDeletePart(e, index)}>
                      <DeleteIcon />
                    </span>
                  :
                  <span>
                      <BillsIcon />
                  </span>
              }
            </div>
            <Typography className={classes.accordionTitle}>
              {`${t("Payment method")} ${index+1}` }
            </Typography>
          </AccordionSummary>
          <AccordionDetails
            className={classes.accordionDetails}
          >
            <AppointmentPaymentPart
              paymentPart={part}
              onChange={handleOnChange(index)}
              editionType={null}
              paymentMethods={paymentMethods}
              excludedPaymentMethodTypes={excludedPaymentMethodTypes}
            />
          </AccordionDetails>
        </Accordion>
      ))}
  
      {paymentMethods.length > excludedPaymentMethodTypes.length &&
        <Button
          className={classes.addButton}
          onClick={onAdd}
        >
          <PlusIcon/>
          {t("Add another method")}
        </Button>
      }
      <div className={classes.separatorWithoutLine}></div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={() => {
          setOpenModal(false);
        }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div className={classes.modalPaper}>
            <div className={classes.modalHeader}>
              <div className={classes.modalClose}>
                <span
                  className={classes.closeButton}
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  <CrossIcon />
                </span>
              </div>
            </div>
            <div className={classes.modalContent}>
              <div className={classes.modalCenter}>
                <div className={classes.modalCircle}>
                  <TrashIcon />
                </div>
              </div>
              <div
                className={`${classes.modalCenter} ${classes.modalTitleSpace}`}
              >
                <Typography className={classes.modalTitleText}>
                  {t("Do you want to remove this payment method?")}
                </Typography>
              </div>
              <div className={classes.modalCenter}>
                <div className={classes.separator} style={{ width: 217 }}></div>
              </div>
              <div className={classes.modalCenter}>
                <Typography className={classes.modalContentText}>
                  {/*{part?.name || "--"}*/}
                </Typography>
              </div>
              <div className={classes.modalActions}>
                <Button
                  className={`${classes.button} ${classes.goBack}`}
                  onClick={() => {
                    setOpenModal(false);
                  }}
                >
                  {t("Go back")}
                </Button>
                <Button
                  className={`${classes.button} ${classes.deleteContact}`}
                  onClick={removePart}
                >
                  {t("Remove")}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}