import React, { useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";

import { Tooltip } from "@material-ui/core";

import { EstablishmentSelectorProps } from "./props";
import MultiSelect from "../../common/MultiSelect";
import useStyles from './css';
import { MultiselectOverflowRenderType } from "../../common/MultiSelect";


export default function EstablishmentSelector(props: EstablishmentSelectorProps) {
  
  const { items, establishments, setEstablishments } = props;
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  
  const [isTooltipOpen, setTooltipOpen] = useState<boolean>(false);
  
  useEffect(() => {
    //If establishment has been removed from enterprise, and it was selected before, remove it.
    if (items && establishments) {
      const itemIds = items.map(x => x.key);
      const removedEstablishments = establishments.filter(x => !itemIds.includes(x))
      if (removedEstablishments.length > 0) {
        const newEstablishments = [...establishments.filter(x => !removedEstablishments.includes(x))]
        setEstablishments(newEstablishments)
      }
    }
  }, [establishments])
  
  function handleSelectEstablishments(newEstablishments: any[]) {
    setEstablishments(newEstablishments)
  }
  
  function resetEstablishments() {
    setEstablishments([]);
  }
  
  const establishmentNames = items && establishments && establishments.length > 1 
    ? items
      .filter(i => establishments.includes(i.key))
      .map(i => i.value)
      .join(', ')
    : ""
  
  function handleOpenTooltip() {
    setTooltipOpen(true)
  }
  
  function handleCloseTooltip() {
    setTooltipOpen(false)
  }
  
  return (
    <Tooltip
      title={establishmentNames}
      open={isTooltipOpen}
      onOpen={handleOpenTooltip}
      onClose={handleCloseTooltip}
      disableHoverListener
      disableTouchListener
      disableFocusListener
    >
      <div
        onMouseEnter={handleOpenTooltip}
        onMouseLeave={handleCloseTooltip}
      >
        <MultiSelect
          allItemsPlaceholder={t("All")}
          filterPlaceholder={t("Search for establishments")}
          items={items}
          sort={true}
          values={establishments}
          classes={{ root: `${classes.focused} ${classes.selected}` }}
          styleClass={classes.multiSelect}
          width={205}
          height={372}
          onChange={handleSelectEstablishments}
          onReset={resetEstablishments}
          onOpen={handleCloseTooltip}
          overflowRenderType={MultiselectOverflowRenderType.Dots}
          showSearch={true}
        />
      </div>
    </Tooltip>
  );
}