import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const WordIcon = (props: any) => {
  const { style, width, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{fontSize: '44px', width: width ?? 64, height: 64}}
      viewBox="0 0 24 24"
    >
      <path
        d="M17.5312 7.0625L14.9375 4.4375C14.6562 4.15625 14.25 4 13.875 4H7.5C6.65625 4 6 4.6875 6 5.5V18.5C6 19.3438 6.65625 20 7.5 20H16.5C17.3125 20 18 19.3438 18 18.5V8.125C18 7.75 17.8125 7.34375 17.5312 7.0625ZM16.375 8H14V5.625L16.375 8ZM7.5 18.5V5.5H12.5V8.75C12.5 9.1875 12.8125 9.5 13.25 9.5H16.5V18.5H7.5ZM14.375 12C14.1875 12 14.0312 12.125 14 12.3125C13.3438 15.375 13.375 15.2812 13.3438 15.5312C13.3438 15.5 13.3438 15.4688 13.3125 15.4062C13.2812 15.25 13.3438 15.4062 12.5938 12.3125C12.5312 12.125 12.4062 12 12.2188 12H11.8125C11.625 12 11.4688 12.125 11.4375 12.3125C10.6875 15.4062 10.6875 15.3125 10.6562 15.5312C10.6562 15.5 10.6562 15.4688 10.6562 15.4062C10.625 15.25 10.2188 13.125 10.0625 12.3125C10 12.125 9.875 12 9.6875 12H9.15625C8.90625 12 8.71875 12.25 8.78125 12.4688C9.03125 13.5 9.625 15.9062 9.8125 16.7188C9.875 16.9062 10.0312 17 10.1875 17H10.9688C11.1562 17 11.3125 16.9062 11.3438 16.7188L11.9062 14.5C11.9375 14.3125 11.9688 14.125 12 13.9375L12.0938 14.5C12.0938 14.5 12.4688 16.0625 12.6562 16.7188C12.6875 16.875 12.8438 17 13 17H13.7812C13.9375 17 14.0938 16.9062 14.1562 16.7188C14.7812 14.1562 15.0938 13 15.2188 12.4688C15.2812 12.25 15.0938 12 14.8438 12H14.375Z"
        fill={style?.color || "#ACB7C0"}
      />
    </SvgIcon>
  )
};
export default WordIcon;
