import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    component: {
        zIndex: 2,
        position: "relative",
        left: 0
    },
    labelItem: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        color: "#919CA5"
    },
    label: {
        ...theme.typography.txtBody2,
        color: theme.colors.grey4,
        fontWeight: "bold",
        display: "block",
        marginBottom: "0.5rem",
    },
    required: {
        color: "#6462F3",
        fontFamily: "Roboto, sans-serif"
    },
    optional: {
        fontWeight: "normal",
    },
    divDropdown: {
        width: 350,
        display: "block",
    },
    inputSelectBtn: {
        width: 300,
        pointerEvents: "none",
        paddingRight: "30px !important",
        "& input": {
            textOverflow: "ellipsis"
        }
    },
    iconDropdown: {
        position: "relative",
        top: -32,
        right: 12,
        textAlign: "right",
    },
    divModalSelect: {
        backgroundColor: "#FFFFFF",
        border: "1px solid #DBDCDD",
        borderRadius: 5,
        boxShadow: "0px 4px 4px rgba(166, 166, 166, 0.25)",
        width: 350,
        textAlign: "center"
    },
    listItemScroll: {
        maxHeight: 300,
        overflowY: "auto",
        minHeight: 40
    },
    listItem: {
        height: 40,
        "&:first-child": {
            paddingTop: 0,
        },
        marginBottom: 0,
    },
    itemValue: {
        textAlign: "left",
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        // width: "75%",
        overflow: "hidden",
        display: "inline-block",
        paddingTop: "10px",
        height: 40
    },
    button: {
        width: 300,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        color: "#786EFF",
        border: "1px solid #786EFF",

    },
    divInputEdit: {
        margin: "auto",
        "& .MuiInputBase-root": {
            height: 32
        }
    },
    input: {
        marginLeft: "auto",
        "& input": {
            width: 250,
        }
    },
    iconSearch: {
        border: "none",
        backgroundColor: "transparent",
        position: "absolute",
        top: 20,
        right: 17,
        textAlign: "center",
        verticalAlign: "middle",
        padding: 0,
        color: "#6462F3",
        "&:focus": {
            outline: "none"
        }
    },
    btnInputNew: {
        height: 32,
        "& .MuiInputBase-root": {
            height: 32
        }
    },
    elementList: {
        height: 40,
        padding: "0px 5px 10px 15px",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        display: "flex",
    },
    itemSelected: {
        fontWeight: "bold",
        color: theme.colors.clearMain,
        backgroundColor: "#ebebeb",
    },
    itemNoSelected: {
        fontWeight: "normal",
        color: theme.colors.grey5,
    },
    classRow: {
        cursor: "pointer",
        "& button": {
            visibility: "hidden",
        },
        "&:hover": {
            backgroundColor: "#f5f5f5",
            "& button": {
                visibility: "visible"
            }
        }
    },
    classRowDisabled: {
        fontSize: 12,
        fontFamily: "Roboto, sans-serif",
        cursor: "cursor",
        color: theme.colors.grey4,
        fontWeight: "bold",
        height: 40,
        display: "flex",
        "&.Mui-disabled": {
            opacity: 1,
        },
    },
    divIconsRow: {
        right: 0,
        textAlign: "center",
        marginLeft: "auto",
        float: "right",
        paddingTop: 0

    },
    divSearch: {
        marginTop: 15,
        marginBottom: 10
    },
    menuPlacementTop: {
        "&>div[role=presentation]": {
            inset: "auto !important",
            bottom: "65px !important"
        }
    }
}));

export default useStyles;
