import { makeStyles } from '@material-ui/core';
import { scrollbarStyle } from './styles/ScrollbarStyles';

const useSchedulerCommonStyles = makeStyles({
    root: {
        ...scrollbarStyle(),
    },
});

export default useSchedulerCommonStyles;
