import { makeStyles } from "@material-ui/core/styles";

const useStyles = (width?: number) =>
  makeStyles((theme) => ({
    timePickerInput: {
      color: theme.colors.grey5,
      fontWeight: "bold",
      fontSize: 12,
      height: 40,
      width: width || 300,
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#F6F6F6",
      borderRadius: 5,
      paddingRight: 10,
      "& * .ant-picker-suffix, .ant-picker-clear": {
        display: "none"
      },
      "& * input": {
        color: theme.colors.grey5,
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "bold",
        borderRadius: "5px",
        height: 24,
        transition: "0.0s !important",
      },
      "& div:after": {
        content: "'hrs'",
        marginLeft: 33,
        marginBottom: 1,
        position: "absolute",
        color: theme.colors.grey5,
      },
    },
    timePickerInputDisabled: {
      color: theme.colors.grey4,
      fontWeight: "bold",
      fontSize: 12,
      height: 40,
      width: width || 300,
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      backgroundColor: "#F6F6F6",
      borderRadius: 5,
      paddingRight: 10,
      cursor: "default !important",
      "& * .ant-picker-suffix, .ant-picker-clear": {
        display: "none"
      },
      "& * input": {
        color: theme.colors.grey4 + " !important",
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "bold",
        borderRadius: "5px",
        height: 24,
        transition: "0.0s !important",
        cursor: "default !important",
      },
      "& div:after": {
        content: "'hrs'",
        marginLeft: 33,
        marginBottom: 1,
        position: "absolute",
        color: theme.colors.grey4,
        cursor: "default !important",
      },
    },
    timePickerPopup: {
      zIndex: 2500
    },
  }),
  );

export default useStyles;