import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MinusArrowIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 6, height: 10, ...style }}
      viewBox={viewBox || "0 0 6 10"}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.189874 5.4318L4.86219 9.81987C4.98849 9.93534 5.15812 10 5.33476 10C5.51141 10 5.68104 9.93534 5.80734 9.81987C5.93056 9.70183 5.99953 9.54342 5.99953 9.37848C5.99953 9.21353 5.93056 9.05513 5.80734 8.93708L1.59806 4.99978L5.80734 1.06338C5.93085 0.945291 6 0.786693 6 0.621534C6 0.456374 5.93085 0.297777 5.80734 0.179692C5.68109 0.0644877 5.51166 5.82341e-09 5.33524 7.92717e-09C5.15882 1.00309e-08 4.9894 0.0644877 4.86314 0.179692L0.189874 4.55348C0.0680786 4.67131 5.75825e-08 4.82877 5.95366e-08 4.99264C6.14907e-08 5.15651 0.0680786 5.31397 0.189874 5.4318Z"
        fill="#6462F3"
      />
    </SvgIcon>
  );
};

export default MinusArrowIcon;
