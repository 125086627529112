import { SectionFieldType } from "./enums/section-field-type";
import { SectionFieldItemModel } from "./section-field-item-model";

export class SectionFieldModel {
  id: string | null;
  name: string;
  order: number;
  type: SectionFieldType;
  items: SectionFieldItemModel[];
  isRequired: boolean;
  isAdditionalTextareaEnabled: boolean;
  isEditable: boolean;
  isRemovable: boolean;
  isDeleted: boolean;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.name = x.name;
      this.order = x.order;
      this.type = x.type;
      this.items = x.items;
      this.isRequired = x.isRequired;
      this.isAdditionalTextareaEnabled = x.isAdditionalTextareaEnabled;
      this.isEditable = x.isEditable;
      this.isRemovable = x.isRemovable;
      this.isDeleted = x.isDeleted;
    } else {
      this.id = null;
      this.name = "";
      this.order = 0;
      this.type = 31;
      this.items = [];
      this.isRequired = false;
      this.isAdditionalTextareaEnabled = false;
      this.isEditable = true;
      this.isRemovable = true;
      this.isDeleted = false;
    }
  }
}