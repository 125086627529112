import React, { useEffect, useState } from "react";

import { navbarActions } from '../../../../redux/navbar-slice'

import NotificationsIcon from "@material-ui/icons/Notifications";
import IconButton from "@material-ui/core/IconButton";
import { ClickAwayListener, Menu } from "@material-ui/core";

import useStyles from "./css";
import NotificationHeader from "../NotificationHeader";
import NotificationFooter from "../NotificationFooter";
import NotificationItem from "../NotificationItem";
import NotificationEntity from "../../../../models/notification-entity";
import { NotificationsService } from "../../../../api/notifications-service";
import ConversationsCounterModel from "../../../../models/conversations-counter-model";
import { NotificationBlockProps } from "./props";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { selectUserInfo } from "../../../../redux/store";
import { NotificationType } from "../../../../models/enums/notification-type";
import { StackableNotificationTypes } from "../../../../constants/business";

export default function NotificationBlock(props: NotificationBlockProps) {

  const { commonHubConnection } = props;

  const dispatch = useAppDispatch();
  const userInfo = useAppSelector(selectUserInfo);
  const [anchorElement, setAnchorElement] = useState<any>(null);
  const [notifications, setNotifications] = useState<NotificationEntity[]>([]);
  const [coversationsCounterModel, setConversationsCounterModel] = useState<ConversationsCounterModel>(new ConversationsCounterModel());
  const isMenuOpen = Boolean(anchorElement)

  const classes = useStyles();

  const handleMenuOpen = async (event: any) => {
    setAnchorElement(event.currentTarget);
    fetchConversationCounter();

    if (notifications.some(n => !n.wasShown)) {
      let shownStatusIsUpdated = await NotificationsService.markNotificationsAsWasShown();
      if (shownStatusIsUpdated) {
        const newNotifications = notifications.map(n => {
          n.wasShown = true
          return n;
        });
        setNotifications(newNotifications);
      }
    }
  }

  const handleMenuClose = () => {
    setAnchorElement(null);
  }

  const unshownNotifications = notifications.filter(n => !n.wasShown);

  async function fetchNotifications() {
    const receivedNotifications = await NotificationsService.getNotifications();
    setNotifications(receivedNotifications ?? []);
  }

  async function fetchConversationCounter() {
    const remainingConversationsModel = await NotificationsService.getAvailableConversationsCount();
    setConversationsCounterModel(remainingConversationsModel ?? new ConversationsCounterModel());
    dispatch(navbarActions.setAvailableConversationsCount(remainingConversationsModel.availableConversationsCount));
  }

  async function markNotificationAsRead(notification: string) {
    await NotificationsService.markNotificationAsRead(notification);
  }

  function updateNotification(notificationIds: string[], notification: NotificationEntity): NotificationEntity {
    if (Boolean(notificationIds.find(x => x === notification.id))) {
      return new NotificationEntity({ ...notification, read: true })
    }
    return notification;
  }

  function handleNotificationReading(notificationId: string) {
    handleNotificationsReading([notificationId]);
  }

  function handleNotificationsReading(notificationIds: string[]) {
    const newNotifications = notifications.map(x => updateNotification(notificationIds, x));
    setNotifications(newNotifications);
  }

  function removeWhatsAppNotificationsFilter(n: NotificationEntity) {
    if (userInfo.hasAccessToWhatsAppConversationsAndNotifications)
      return n;

    return n.type !== NotificationType.CustomerRequiresAttention &&
      n.type !== NotificationType.CustomerRequiresQuote &&
      n.type !== NotificationType.ProofOfPayment &&
      n.type !== NotificationType.UnreadMessages;
  }

  useEffect(() => {
    fetchNotifications()
    fetchConversationCounter();
  }, [])

  //unsubscribe first so subscriptions does not duplicate
  commonHubConnection?.off("receiveNotification")
  commonHubConnection?.off("markNotificationRead")

  commonHubConnection?.on("receiveNotification", (receivedNotification) => receiveNotification(new NotificationEntity(receivedNotification)))
  commonHubConnection?.on("markNotificationRead", (notificationId) => handleNotificationReading(notificationId))

  const receiveNotification = (receivedNotification: NotificationEntity) => {
    var isStackable = StackableNotificationTypes.some(t => t === receivedNotification.type);

    if (notifications.some(n => n.id === receivedNotification.id) && !isStackable)
      return;

    const updatedNotifications = [...notifications];

    //Removing stackable notification before adding the updated one at the beginning 
    if (isStackable) {
      const notificationToFloatUpIndex = updatedNotifications.findIndex(n => n.id === receivedNotification.id);
      updatedNotifications.splice(notificationToFloatUpIndex, 1);
    }

    updatedNotifications.unshift(receivedNotification);
    setNotifications(updatedNotifications);
  };

  return (
    <div>
      <IconButton
        className={classes.iconNotificationButton}
        onClick={handleMenuOpen}
      >
        <NotificationsIcon />
        {unshownNotifications.length > 0 &&
          <div className={classes.notificationDot}>{unshownNotifications.length > 9 ? "9+" : unshownNotifications.length}</div>}
      </IconButton>

      <Menu
        anchorEl={anchorElement}
        open={isMenuOpen}
        classes={{
          paper: classes.paper,
          list: classes.list
        }}
      >
        <ClickAwayListener onClickAway={handleMenuClose}>
          <div>
            <NotificationHeader
              handleClose={handleMenuClose}
            />
            {notifications
              .filter(removeWhatsAppNotificationsFilter)
              .map(not =>
                <NotificationItem
                  key={not.id}
                  notification={not}
                  markAsRead={markNotificationAsRead}
                />
              )}
            <NotificationFooter
              conversationsLimit={coversationsCounterModel.monthlyConversationsLimit}
              availableConversationsCount={coversationsCounterModel.availableConversationsCount}
            />
          </div>
        </ClickAwayListener>
      </Menu>
    </div>
  );
}