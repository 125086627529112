import * as React from "react"
import { useEffect } from "react";
import { matchPath, useHistory } from "react-router-dom";

import { useDispatch } from 'react-redux';
import { authenticate, deauthenticate } from '../../../redux/authentication-slice';

import { ApiClient } from "../../../api/api-client";
import { Url } from "../../../constants/url";

export function AuthenticationProvider() {

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    ApiClient.initialize().then((result) => result ? authenticateUser() : redirectToLogin());
  }, []);
  
  function authenticateUser() {
    dispatch(authenticate());
  }
  
  function redirectToLogin() {
    dispatch(deauthenticate());
    const location = window.location.pathname;
    const isViableRoute = matchPath(
      location, 
      {
        path: [Url.Authentication.Login, Url.Authentication.Recovery, Url.Authentication.ResetPassword, Url.Authentication.Join]
      })?.isExact
    
    if (!isViableRoute) {
      history.push(Url.Authentication.Login);
    }
  }

  return null;
}