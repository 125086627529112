import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    gap: 20,
    width: "100%",
    justifyContent: "space-between",
  },
  tabTitle: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: Color.gray5
  },
  newButton: {
    marginLeft: "auto",
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "space-between",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    background: Color.clear2,
    color: Color.gray1,
    "&:hover": {
      background: Color.clear1,
    },
  },
  sectionsContainer: {
    border: "1px solid",
    borderColor: "#DBDCDD",
    boxSizing: "border-box",
    borderRadius: "12px 12px 12px 12px",
    height: "auto",
    overflow: "hidden"
  },
  communicationSettingsContainer: {
    border: "1px solid",
    borderColor: "#DBDCDD",
    boxSizing: "border-box",
    borderRadius: "12px 12px 12px 12px",
    height: "auto",
    backgroundColor: "#FFFFFF",
    overflow: "hidden"
  },
  mainTitle: {
    fontWeight: 700,
    fontSize: 14,
    color: "#919CA5"
  },
  iconTooltipRoot: {
    display: "inline-block"
  },
  checkboxTitle: {
    fontWeight: 400,
    fontSize: 14,
    color: "#919CA5"
  },
  tinyMceOverrides: {
    "& svg": {
      fill: "#919CA5 !important"
    },
    "& button.tox-tbtn--select": {
      visibility: "hidden"
    },
    position: "relative",
    padding: "15px 0px 23px 47px"
  },
  tinyMceUnfocused: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: "#C9CDD3 !important"
    }
  },
  tinyMceFocused: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: "#6462F3 !important"
    }
  },
  charCounter: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "12px",
    position: "absolute",
    paddingRight: 15,
    marginTop: "-18px",
    marginRight: "15px",
    color: theme.colors.grey5
  },
  communicationSettingsMainTitle: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "14px",
    marginBottom: "13px",
    color: "#919CA5"
  },
  communicationSettingsCheckboxGroupTitle: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    marginBottom: "20px",
    color: "#919CA5"
  },
  communicationSettingsHeader: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    marginBottom: "16px",
    color: "#5C6477"
  },
  previewButton: {
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    border: "1px solid " + Color.clear1,
    color: Color.clear1,
  },
  counterWrapper: {
    display: "flex",
    justifyContent: "flex-end"
  },
  clinicalHistorySwitch: {
    marginLeft: 11,
  },
}));

export default useStyles;