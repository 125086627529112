import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { CustomerService } from "../../../api/customer-service";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import useStyles from "./css";
import { CustomerImportModalProps } from "./props";
import { Box, Grid } from "@material-ui/core";

import {
  ExcelIcon,
  UploadFileCloud,
  DownloadFileCloud,
} from "../../../assets/icons";
import { useAppSelector } from "../../../redux/hooks";
import FileService from "../../../api/file-service";
import { alertsActions } from "../../../redux/alerts-slice";
import { useAppDispatch } from "../../../redux/hooks";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";
import { selectAccountSettings } from "../../../redux/store";


export default function CustomerImportModal(props: CustomerImportModalProps) {

  const { t } = useTranslation(["general"]);
  const { open, onClose, refreshCustomers } = props;
  const classes = useStyles();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [cancelModalShouldAppear, setCancelModalShouldAppear] = useState<boolean>(false);

  const templateFileName = useRef<string>("");
  const [file, setFile] = useState<FileObject[]>([]);
  const [fileSize, setFileSize] = useState<number>(0);
  const [fileName, setFileName] = useState<string>(t("No file."));
  const [isValid, setIsValid] = useState<boolean>(false);
  const maxFileSize: number = 1 * 1024 * 1024;

  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const accountSettings = useAppSelector(selectAccountSettings);
  const customerListUpdatedTitle = useCustomerTerminology(t("Customer list updated"), "Customer list updated")
  const customersNotImportedDescription = useCustomerTerminology(t("Customers were not imported"), "Customers were not imported")

  const { enqueueAlert } = alertsActions;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (localeApp && accountSettings) {
      const templateFileNameCustomersES: string = "Lista_de_clientes_2024.xlsx";
      const templateFileNameCustomersEN: string = "List_of_customers_2024.xlsx";
      const templateFileNamePatientsES: string = "Lista_de_pacientes_2024.xlsx";
      const templateFileNamePatientsEN: string = "List_of_patients_2024.xlsx";

      if (localeApp === 'en'){
        if (accountSettings.isClinicMode) {
          templateFileName.current = templateFileNamePatientsEN;
        } else {
          templateFileName.current = templateFileNameCustomersEN;
        }
      } else {
        if (accountSettings.isClinicMode) {
          templateFileName.current = templateFileNamePatientsES;
        } else {
          templateFileName.current = templateFileNameCustomersES;
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localeApp, accountSettings]);

  useEffect(() => {
    setOpenModal(open);
    if (open === true) {
      cleanupModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  const handleTryCloseModal = () => {
    if (cancelModalShouldAppear) {
      setOpenCancelModal(true);
    } else {
      handleCloseModal();
    }
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    if (onClose) {
      onClose();
    }
  };

  async function handleOnUploadClick() {

    if (isValid) {
      setIsValid(false);
      const uploadResponse = await CustomerService.createCustomersFromFile(
        file[0].file
      );

      if (!uploadResponse.isFileProcessed) {
        dispatch(
          enqueueAlert({
            type: "Error",
            title: t("File could not be uploaded"),
            description: customersNotImportedDescription
          })
        );
        
        handleCloseModal();
        return;
      }
      
      const processed: string = uploadResponse.processedRecordsCount!+"";
      const total: string = uploadResponse.totalRecordsCount!+"";

      if (uploadResponse.processedRecordsCount == uploadResponse.totalRecordsCount) {
        dispatch(
          enqueueAlert({
            type: "Success",
            title: customerListUpdatedTitle,
            description: t("{n} of {m} records were uploaded").replace("{n}", processed).replace("{m}", total)
          })
        );

        handleCloseModal();
        refreshCustomers();
        return;
      }

      if (uploadResponse.processedRecordsCount! < uploadResponse.totalRecordsCount!) {
        dispatch(
          enqueueAlert({
            type: "Success",
            title: customerListUpdatedTitle,
            description:
              <>
                {t("{n} of {m} records were uploaded").replace("{n}", processed).replace("{m}", total)}
                <div
                  className={classes.link}
                  onClick={async () => await handleOnDownloadClick(uploadResponse.invalidRecordsFile)}
                >
                  {t("View records that were not loaded")}
                </div>
              </>
          })
        );
        
        handleCloseModal();
        refreshCustomers();
        return;
      }
    }

    cleanupModal();
  }

  async function handleOnDownloadClick(responseFile: Uint8Array | null) {

    if (responseFile) {
      const fileUrl = URL.createObjectURL(new Blob([responseFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' }));
      const link = document.createElement("a");
      link.setAttribute("href", fileUrl);
      link.setAttribute("download", templateFileName.current);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    }
  }

  const handleOnAddFile = (files: FileObject[]) => {
    setFile(files);
    setFileName(files[0].file.name);
    let size = files[0].file.size / (1024 * 1024);
    setFileSize(Math.round((size + Number.EPSILON) * 100) / 100); // in megabytes
    setIsValid(validateFile(files[0].file));
    setCancelModalShouldAppear(true);
  };

  const handleDropRejected = (file: File[]) => {
    if (file[0].name.split(".").pop() !== "xlsx") {
      dispatch(
        enqueueAlert({
          type: "Error",
          title: t("Error"),
          description: t("Use the indicated .xlsx format")
        })
      );
    }
  };

  const validateFile = (file: File) => {
    if (file.size > maxFileSize)
      //1 megabyte
      return false;
    return file.name.split(".").pop() === "xlsx";
  };

  function cleanupModal() {
    setFile([]);
    setFileName(t("No file."));
    setFileSize(0);
    setIsValid(false);
    setCancelModalShouldAppear(false);
    setOpenCancelModal(false);
  }

  async function downloadTemplateFile() {
    const fileUrl = await FileService.getTemplateFileUrl(templateFileName.current, localeApp!);

    const link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("download", templateFileName.current);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleTryCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div>
            <div
              className={classes.paper}
              style={openCancelModal ? { display: "none" } : {}}
            >
              <div className={classes.modalHeader}>
                <div className={classes.modalTitle}>
                  <Typography className={classes.modalTitleText} variant="h1">
                    {useCustomerTerminology(t("Import customers"), "customers")}
                  </Typography>
                </div>
                <div className={classes.modalActions}>
                  <Button
                    className={`${classes.button} ${classes.goBack}`}
                    onClick={handleTryCloseModal}
                  >
                    {t("Go back")}
                  </Button>
                  <Button
                    className={`${classes.button} ${classes.createCustomer}`}
                    disabled={!isValid}
                    classes={{ disabled: classes.createDisabled }}
                    onClick={handleOnUploadClick}
                  >
                    {t("Upload file")}
                  </Button>
                </div>
              </div>
              <Box sx={{ width: "100%" }} pt={1}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <DropzoneAreaBase
                      dropzoneClass={classes.dropZone}
                      dropzoneParagraphClass={classes.dropZoneText}
                      fileObjects={file}
                      dropzoneText={t(
                        "Drag or click here to upload your .xlsx file"
                      )}
                      onAdd={handleOnAddFile}
                      showPreviewsInDropzone={false}
                      showAlerts={false}
                      filesLimit={1}
                      acceptedFiles={[
                        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                      ]}
                      maxFileSize={maxFileSize}
                      onDropRejected={handleDropRejected}
                      // @ts-ignore: type negotiation problem
                      Icon={UploadFileCloud} // works and builds but https://github.com/Yuvaleros/material-ui-dropzone/issues/246
                    />
                  </Grid>
                  <Grid item xs={1} style={{ paddingLeft: "38px" }}>
                    <ExcelIcon
                      style={isValid ? { color: "#6462F3" } : {}}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={5}
                    style={{ paddingLeft: "48px", paddingTop: "30px" }}
                  >
                    <Typography className={classes.fileName}>
                      {fileName}
                    </Typography>
                    <Typography className={classes.fileSize}>
                      {t("Size: ")}
                      <b>{fileSize} MB</b>
                    </Typography>
                  </Grid>
                </Grid>
                <hr className={classes.divider} />
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography className={classes.notesTitle}>
                      {t("NOTES: ")}
                    </Typography>
                    <Typography className={classes.notes}>
                      {t("Accepted format: xlsx")}
                    </Typography>
                    <Typography className={classes.notes}>
                      {t("Maximum allowed document size: 1 Mb")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                      <Typography
                        className={classes.downloadTemplate}
                        onClick={downloadTemplateFile}
                      >
                        <DownloadFileCloud
                          style={{ fontSize: 18, margin: "0px 4px 2px 0px" }}
                        />
                        {t("Download template")}
                      </Typography>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div
              className={classes.cancelModalPaper}
              style={openCancelModal ? {} : { display: "none" }}
            >
              <div
                className={`${classes.modalCenter} ${classes.modalTitleSpace}`}
              >
                <Typography className={classes.modalTitleTextCancel}>
                  {t("Cancel the upload of the .xlsx file?")}
                </Typography>
              </div>
              <div className={classes.modalActions}>
                <Button
                  className={`${classes.cancelModalButton} ${classes.goBackCancel}`}
                  onClick={() => setOpenCancelModal(false)}
                >
                  {t("Go back")}
                </Button>
                <Button
                  className={`${classes.cancelModalButton} ${classes.cancelConfirmation}`}
                  onClick={handleCloseModal}
                >
                  {t("Yes, cancel it")}
                </Button>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}