import EvolutionNotesGrouped from "./evolution-notes-grouped";
import { SectionFieldModel } from "./section-field-model";

export class EvolutionNoteResponse {
  evolutionNotesGrouped: EvolutionNotesGrouped[];
  sectionFields: SectionFieldModel[];
  vitalSignSectionName: string;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.evolutionNotesGrouped = x.evolutionNotesGrouped;
      this.sectionFields = x.sectionFields;
      this.vitalSignSectionName = x.vitalSignSectionName;
    } else {
      this.evolutionNotesGrouped = [];
      this.sectionFields = [];
      this.vitalSignSectionName = "";
    }
  }
}