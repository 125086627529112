import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customerSection: {
    width: 228,
    marginRight: 34,
  },
  txtBody1Bold: {
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: theme.colors.grey5,
  },
  personInCharge: {
    display: "flex",
    width: 228,
  },
  txtBody2: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
  },
  txtBody2Bold: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey4,
  },
  lineSeparator: {
    borderTop: "1px solid #E5E7EA",
    height: 0,
  },
  customerInfoTitle: {
    display: "flex",
    alignItems: "center",
    marginBottom: 28,
  },
  label1: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey4,
    fontWeight: "bold",
  },
  label2: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
  },
  radioRoot: {
    "&.Mui-checked": {
      "&, & + .MuiFormControlLabel-label": {
        color: theme.colors.grey5,
      },
    },
  },
  radioConfirmed: {
    "&.Mui-checked": {
      "&, & + .MuiFormControlLabel-label": {
        color: theme.colors.clearBlue,
      },
    },
  },
  radioArrived: {
    "&.Mui-checked": {
      "&, & + .MuiFormControlLabel-label": {
        color: theme.colors.clearGreen,
      },
    },
  },
  radioNotArrived: {
    "&.Mui-checked": {
      "&, & + .MuiFormControlLabel-label": {
        color: theme.colors.clearRed,
      },
    },
  },
  radioPaid: {
    "&.Mui-checked": {
      "&, & + .MuiFormControlLabel-label": {
        color: theme.colors.clearGreen,
      },
    },
  },
  radioUnpaid: {
    "&.Mui-checked": {
      "&, & + .MuiFormControlLabel-label": {
        color: theme.colors.clearRed,
      },
    },
  },
  radioLabelRoot: {
    margin: 0,
  },
  radioLabel: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey4,
  },
  customerListOverrides: {
    "& .MuiSvgIcon-root": {
      height: "18px",
      width: "18px"
    }
  },
  customerList: {
    ...theme.typography.txtBody2,
    lineHeight: "15px",
    color: theme.colors.grey5,
  },
  customerListSelected: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    lineHeight: "15px",
    color: theme.colors.clearMain,
  },
  buttonNewCustomer: {
    border: `1px solid ${theme.colors.clearMain}`,
    borderRadius: 50,
    width: 196,
    height: 32,
    ...theme.typography.buttons,
    fontWeight: "bold",
    color: "#786EFF",
    "&:hover": {
      color: theme.colors.grey1,
      background: "#786EFF",
    },
  },
  infoIcon: {
    color: theme.colors.grey3,
    marginLeft: 5,
    width: 8,
    height: 8,
    minWidth: 8,
    cursor: "pointer",
  },
}));

export default useStyles;
