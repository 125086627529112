import { makeStyles } from "@material-ui/core/styles";

const useStyles = (width?: number) =>
  makeStyles((theme) => ({
    root: {},
    label: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey4,
      fontWeight: "bold",
      display: "block",
    },
    select: {
      ...theme.typography.txtBody2,
      color: theme.colors.grey5,
      fontWeight: "bold",
      backgroundColor: theme.colors.grey1,
      height: 40,
      padding: "13px 0px 13px 12px",
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      width: width || 370,
    },
    focused: {
      "&:focus": {
        backgroundColor: theme.colors.grey1,
      },
    },
    menuItem: {
      display: "flex",
      justifyContent: "space-between",
      height: 40
    },
    icons: {
      width: "80px",
      display: "flex",
      justifyContent: "space-evenly",
      '&:hover': {
      }
    },
    editIcon: {
      margin: "1px",
      color: theme.colors.grey4,
    },
    menuPaper: {
      maxHeight: 210,
      marginTop: 6
    },
    addButton: {
      borderColor: theme.colors.clearMain,
      borderRadius: "51px",
      width: 200,
      height: 32,
      fontFamily: "Inter",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "12px",
      lineHeight: "15px",
    },
    input: {
      color: "#4a4d51",
      width: 200,
      height: 40,
      fontFamily: "Roboto",
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: "bold",
      backgroundColor: "#F6F6F6",
      borderRadius: "5px",
      "& input": {
        backgroundColor: "#F6F6F6 !important"
      }
    }
}));

export default useStyles;