import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { Editor } from "@tinymce/tinymce-react";
import Typography from "@material-ui/core/Typography";

import { MassiveSendingMessageSettingsProps } from "./props";
import useStyles from "./css";
import RadioWithText from "../../../common/RadioWithText";
import { MassiveSendingContentType } from "../../../../models/enums/massive-sending-content-type";
import { ExportIcon, PlusIcon, WarningIcon } from "../../../../assets/icons";
import ImageDropzone from "../../../common/ImageDropzone";
import { MassiveSendingUrlRegex } from "../../../../constants/validator";
import IconTooltip from "../../../common/IconTooltip";
import useCustomerTerminology from "../../../../hooks/useCustomerTerminology";


export default function MassiveSendingMessageSettings(props: MassiveSendingMessageSettingsProps) {

  const classes = useStyles();
  const { t } = useTranslation("massivesendings");

  const validMimeTypes = ["jpg", "jpeg", "png"];
  const massiveSendingTemplates = [t("single_sending_template"), t("annual_sending_template"), t("birthday_sending_template")]
  const maxFileSize: number = 800 * 1024; //800KB
  const greetingMessage = <span>{t("Hello")} <b>{useCustomerTerminology(t("[Customer name]"), "[Customer name]")}</b>,</span>;
  const scheduleAppointments = t("To schedule your appointment on our Site for appointments click on the following button.");
  const goToSite = t("GO TO APPT SITE");

  const { massiveSending, setMassiveSending, textLength, setTextLength, maxTextLength, textContainsUrl, setTextContainsUrl } = props;
  const [focused, setFocused] = useState<boolean>(false);

  function getTextInitialValue(): string {
    if (massiveSending.text === "" || massiveSendingTemplates.indexOf(massiveSending.text) !== -1) {
      return massiveSendingTemplates[massiveSending.type];
    }
    return massiveSending.text;
  }

  useEffect(() => {
    setMassiveSendingText(""); //need to initialize text length of default template message
    setMassiveSendingText(getTextInitialValue())
  }, [])

  function setMassiveSendingText(text: string) {
    setMassiveSending({ ...massiveSending, text });
  }

  function setMassiveSendingContentType(contentType: MassiveSendingContentType) {
    setMassiveSending({ ...massiveSending, contentType });
  }

  function setMassiveSendingImage(image: File, imageUrl: string) {
    setMassiveSending({ ...massiveSending, image, imageUrl });
  }

  function handleTextTypeCheck() {
    setMassiveSendingContentType(MassiveSendingContentType.Text)
  }

  function handleImageAndTextTypeCheck() {
    setMassiveSendingContentType(MassiveSendingContentType.ImageAndText);
  }

  const validateFile = (file: File) => {
    if (validMimeTypes.indexOf(file.name.split('.').pop()!) === -1) {
      return false;
    }
    return file.size < maxFileSize;
  }

  const handleFileUpload = (file: File) => {
    const fileIsInvalid = validateFile(file);
    if (!fileIsInvalid) return;

    const imageUrl = URL.createObjectURL(file);
    setMassiveSendingImage(file, imageUrl);
  }

  const setLength = (evt: any, editor: any) => {
    setTextLength(editor.getContent({ format: "text" }).length);
  };

  const handleEditorChange = (c: string, editor: any) => {
    const contentText = editor.getContent({ format: "text" });
    const contentHtml = editor.getContent();

    let newText: string;
    newText = contentText.replace(/\r?\n|\r/g, " "); //Removing line breaks
    newText = newText.replace(/\s{3,}/g, " "); //Removing 3+ whitespaces
    newText = newText.replace(/[•\t]/g, ""); //Removing bullet points

    let newHtml: string;
    newHtml = contentHtml.replace(/\r?\n|\r/g, " "); //Removing line breaks
    newHtml = newHtml.replace(/\s{3,}/g, " "); //Removing 3+ whitespaces
    newHtml = newHtml.replace(/[•\t]/g, ""); //Removing bullet points

    setMassiveSendingText(newHtml)
    setTextLength(newText.length);

    setTextContainsUrl(newText.search(MassiveSendingUrlRegex) !== -1)
  }

  const EmptyImageComponent: React.FC = () => {
    return (
      <>
        <div className={classes.plusIcon}>
          <PlusIcon style={{ color: "#6462F3" }} />
        </div>
        <div className={classes.addImageLabel}>
          {t("Add image")}
        </div>
        <div className={classes.labelAddPhotoIns}>
          {t("Recommended size") + ": 430px x 240px"}
        </div>
      </>
    );
  };

  return (
    <div className={classes.root}>
      <div className={classes.radios}>
        <div>
          <RadioWithText
            value={MassiveSendingContentType.Text}
            checked={massiveSending.contentType === MassiveSendingContentType.Text}
            onCheck={handleTextTypeCheck}
            text={t("Text")}
          />
        </div>
        <div>
          <RadioWithText
            value={MassiveSendingContentType.ImageAndText}
            checked={massiveSending.contentType === MassiveSendingContentType.ImageAndText}
            onCheck={handleImageAndTextTypeCheck}
            text={t("Image and text")}
          />
        </div>
      </div>
      <div className={classes.divider} />
      {massiveSending.contentType === MassiveSendingContentType.ImageAndText &&
        <div>
          <div>
            <Typography className={classes.controlLabel}>
              {t("Template image")}
            </Typography>
            <span className={classes.dot}>*</span>
          </div>
          <div className={classes.imageBlock}>
            <ImageDropzone
              key={"massive-sending-image"}
              imageUrl={massiveSending.imageUrl}
              emptyFileComponent={<EmptyImageComponent />}
              onChange={handleFileUpload}
              validationFunction={validateFile}
              dropzoneClass={massiveSending.imageUrl ? classes.uploadedImage : classes.emptyImage}
            />
            <div className={classes.imageBlockNotes}>
              <span><b>{t("Notes") + ":"}</b></span>
              <span>{t("This image will be sent to the customer by WhatsApp message. Allowed formats for the image: ")}<b>{t(".png or .jpg")}</b></span>
            </div>
          </div>
          <div className={classes.divider} />
        </div>
      }
      <div>
        <Typography className={classes.controlLabel}>
          {t("Template text")}
        </Typography>
        <span className={classes.dot}>*</span>
      </div>
      <div className={classes.textBlock}>
        <span>{greetingMessage}</span>
        <div className={`
        ${classes.tinyMceOverrides} 
        ${textContainsUrl || textLength > maxTextLength
            ? classes.tinyMceInvalid
            : (focused
              ? classes.tinyMceFocused
              : classes.tinyMceUnfocused)}`}>
          <Editor
            apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
            init={{
              height: 160,
              width: 680,
              menubar: false,
              statusbar: false,
              plugins: 'wordcount paste',
              toolbar: 'bold italic strikethrough | styleselect ',
              content_style: "body {fontFamily: Inter, sans-serif; font-weight: 400; font-size: 14px; color: #323947 }",
              entity_encoding: "raw",
              formats: {
                bold: { inline: 'b' },
                italic: { inline: 'i' },
                strikethrough: { inline: 's' }
              },
              paste_as_text: true,
              paste_preprocess: (plugin: any, args: any) => {
                let newText: string;
                newText = args.content.replace(/(<|&lt;)\s*br\s*\/*(>|&gt;)/g, " "); //Removing br tags
                newText = newText.replace(/(<|&lt;)\s*\/?\s*p[^>]*(>|&gt;)/g, ""); //Removing p tags
                newText = newText.replace(/\r?\n|\r/g, " "); //Removing other line breaks
                newText = newText.replace(/\s{3,}/g, " "); //Removing 3+ whitespaces
                newText = newText.replace(/[•\t]/g, ""); //Removing bullet points and tabs
                args.content = newText;
              },
              extended_valid_elements: "b, i, s",
              invalid_elements: "strong, em, span",
              remove_linebreaks: true,
              setup: (editor) => {
                editor.on("init", (e) => {
                  editor.focus();
                  editor.selection.select(editor.getBody(), true);
                  editor.selection.collapse(false);
                });
                editor.on("keydown", (e) => {
                  if (e.key === "Enter" || e.key === "Tab") {
                    e.preventDefault();
                    e.stopPropagation();
                    return false;
                  }
                  //without the next block, the cursor will lose its position immediately after the text becomes invalid
                  if (textContainsUrl) {
                    editor.focus();
                    editor.selection.select(editor.getBody(), true);
                    editor.selection.collapse(false);
                  }
                });
              }
            }}
            value={massiveSending.text}
            onFocusIn={() => setFocused(true)}
            onFocusOut={() => setFocused(false)}
            onInit={setLength}
            onEditorChange={handleEditorChange}
            outputFormat="html"
            onChange={(e) => {
              if (textLength >= maxTextLength) {
                e.preventDefault();
              }
            }}
          />
          {textContainsUrl
            ? <div className={classes.urlWarning}>
              <IconTooltip
                key={"urlWarningTooltip"}
                placement={"right"}
                icon={<WarningIcon />}
                tooltipText={t("The message must not contain links. We suggest you modify it.")}
                arrowPlacement={"right"}
              />
            </div>
            : <></>}
          <span className={`${classes.charCounter} ${textLength > maxTextLength ? classes.charCounterInvalid : ""}`}>{textLength + "/" + maxTextLength}</span>
        </div>
        <span>{scheduleAppointments}</span>
        <div className={classes.goToSiteButton}>
          <div className={classes.goToSiteButtonInnerContainer}>
            <div className={classes.goToSiteIcon}>
              <ExportIcon />
            </div>
            {goToSite}
          </div>
        </div>
      </div>
    </div>
  );
}