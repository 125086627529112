import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  title: {
    fontFamily: "Inter",
    fontSize: "14px",
    color: Color.gray5,
    marginBottom: 10,
    fontStyle: "normal",
    fontWeight: "bold",
  },
  divArea: {
    backgroundColor: "#fff",
    borderRadius: 20,
    border: "1px solid #dbdcdd",
    padding: "35px 40px 75px 40px",
    margin: "10px 0"
  },
  enterpriseInformationContainer: {
    display: "flex"
  },
  enterpriseLeftContainer: {
    flexBasis: "23%"
  },
  enterpriseRightContainer: {
    flexBasis: "77%"
  },
  copyLinkButtonContainer: {
    width: "32%"
  },
  required: {
    color: "#6462F3",
    marginLeft: 5,
    fontWeight: "bold",
    fontSize: 14,
  },
  copyLinkButton: {
    height: 32,
    width: "100%",
    
    marginTop: 43,
    
    borderRadius: 51,
    padding: "16px 16px",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    border: "1px solid " + Color.clear1,
    color: Color.clear1,
  },
  titleSection: {
    fontFamily: "Inter",
    fontSize: "14px",
    color: Color.gray4,
    marginBottom: 20,
    fontStyle: "normal",
    fontWeight: "normal",
  },
  inputClass: {
    ...theme.typography.body2,
    color: theme.colors.grey5,
    fontWeight: "bold",
    "&::placeholder": {
      ...theme.typography.txtBody2,
      color: theme.colors.grey5,
      fontWeight: "normal",
      opacity: 0.4,
    }
  },
  label: {
    fontFamily: "Inter",
    display: "inline-flex",
    margin: 0,
    color: "#919CA5",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    marginBottom: 5,
  },
  labelSecondary: {
    fontFamily: "Roboto,sans serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    color: "#919CA5"
  },
  logoSection: {
    width: "100%",
    paddingTop: 10,
    height: 150
  },
  divLogo: {
    width: 120,
    height: 120,
    borderRadius: "50%",
    backgroundColor: "#6462F3",
    marginLeft: 30,
    position: "relative",
    cursor: "pointer"
  },
  divLogoIcon: {
    display: "flex",
    alignItems: "center",
    zIndex: 2,
    position: "absolute",
    height: "100%"
  },
  logoIconClear: {
    width: "70%",
    height: "100%",
    margin: "auto",
    fontSize: 70,
    color: "#F6F6F6",
  },
  iconCam: {
    marginTop: 40,
    height: 25,
    textAlign: "center",
    color: "#FFFFFF",
  },
  labelChangePhoto: {
    marginTop: 5,
    fontWeight: "bold",
    fontSize: 12,
    textAlign: "center",
    color: "#FFFFFF",
    opacity: 1
  },
  optional: {
    marginLeft: 3,
    fontFamily: "Inter",
    display: "inline-flex",
    margin: 0,
    color: "#919CA5",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: "normal",
    fontStyle: "normal",
    marginBottom: 5,
  },
  divLogoIconHover: {
    zIndex: 3,
    position: "absolute",
    width: 120,
    height: 120,
    borderRadius: "50%",
    margin: "auto",
    opacity: 0,
    "&:hover": {
      background: "rgba(29, 32, 39, 0.7)",
      display: "flow-root",
      opacity: 0.7
    }
  },
}));

export default useStyles;