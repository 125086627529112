import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
  title: {
    fontSize: 14,
    fontWeight: 700,
    fontFamily: "Inter, sans-serif",
    color: theme.colors.grey4,
  },
}));
