import { createSlice } from "@reduxjs/toolkit";
import { ScheduleAppointmentTimeStateModel } from "../models/interfaces/schedule-appointment-time-state-model";


const initialState: ScheduleAppointmentTimeStateModel = {
  appointmentTime: new Date(),
  newAppointmentViaScheduler: false
};


export const scheduleAppointmentTimeSlice = createSlice({
  name: "scheduleAppointmentTimeState",
  initialState: initialState,
  reducers: {
    setAppointmentTime: (currentState, action) => {
      currentState.appointmentTime = action.payload;
    },
    setClickNewAppointmentOnScheduler: (currentState, action) => {
      currentState.newAppointmentViaScheduler = action.payload;
    },
  }
})

export const scheduleAppointmentTimeActions = scheduleAppointmentTimeSlice.actions;
export default scheduleAppointmentTimeSlice.reducer;