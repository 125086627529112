import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

interface DotIconProps {
  color: string;
  [x: string]: any;
}

const DotIcon = (props: DotIconProps) => {
  const { color, ...otherProps } = props;
  return (
    <SvgIcon {...otherProps}>
      <path
        d="M7 0C3.13306 0 0 3.13306 0 7C0 10.8669 3.13306 14 7 14C10.8669 14 14 10.8669 14 7C14 3.13306 10.8669 0 7 0Z"
        fill={color}
      />
    </SvgIcon>
  );
};

export default DotIcon;
