import { makeStyles } from "@material-ui/core/styles";

const useStyles = (width?: number | string) =>
    makeStyles((theme) => ({
        input: {
            backgroundColor: "#F6F6F6",
            height: 40,
            padding: "13px 0px 13px 12px",
            //fontSize: 14,
            border: "solid 1px rgba(0, 0, 0, 0.1)",
            borderRadius: 5,
            width: width || 370,
            "&:focus": {
                outline: "none",
                boxShadow: 0,
                border: "solid 1px #6462F3",
            },
            "&::placeholder": {
                color: "#B5B9C1",
            },
        },
        inputError: {
            color: "#f15857",
            border: "solid 1px #f15857",
        },
        counter: {
            position: "absolute",
            bottom: 7,
            right: 15,
            fontSize: 12,
            color: "#B5B9C1",
            padding: "5px",
        },
        container: {
            position: "relative",
        },
    }));

export default useStyles;
