import { makeStyles } from "@material-ui/core/styles";

const useStyles = (blockHeight: number) => makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "center",
    width: "100%",
    minHeight: blockHeight,
    border: "1px solid #36CE91",
    borderRadius: 5
  },
  icon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50px",
    height: "50px",
    border: "1px solid #36CE91",
    borderRadius: "25px",
    color: "#36CE91"
  },
  header: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "19px",
    textAlign: "center",
    color: "#26292B"
  },
  text: {
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
    lineHeight: "14px",
    color: "#676767",
    margin: "0 34px 0 34px"
  }
}));

export default useStyles;