import React, { useEffect, useState } from "react";
import {  Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../redux/hooks";

import "antd/dist/antd.css";
import { TimePicker } from "antd";
import moment from "moment";

import SelectWithLabel from "../../../components/common/SelectWithLabel";
import Textarea from "../../../components/common/Textarea";

import EntityForm, {FormProps, Validator} from "./../../../models/blocked-schedule";
import Switch from "../../common/Switch";
import BlockedReasonSelect from "../BlockedReasonSelect";
import DateInput from "../../common/DateInput";
import BlockSchedule from "./../../../models/blocked-schedule";
import useStyles from "./css";
import { ClockIcon } from "../../../assets/icons";
import BlockedReason from "../../../models/blocked-reason";
import InputWithLabel from "../../common/InputWithLabel";
import { DateTime } from 'luxon';


const BlockScheduleForm = (props: FormProps) => {

    const {
        users,
        item, 
        validator,
        onChange,
    } = props;


    const { t } = useTranslation(["general"]);
    const classes = useStyles();
    const dateFromSchedule = useAppSelector(state => state.blockedSchedule.date);
    const userIdFromSchedule = useAppSelector(state => state.blockedSchedule.userId);
    const userInfo = useAppSelector(state => state.userInfo);
    const [entityState, setEntityState] = useState<EntityForm>(item || new EntityForm());
    const [notes, setNotes] = useState<string>("");

    const format = "HH:mm";
    const formatDate = "YYYY-MM-DD";

    const changing = (blocked: BlockSchedule) => { if (onChange) onChange(blocked); }

    useEffect(() => {
        if (item && (item.id || dateFromSchedule || userIdFromSchedule)) {
            let newEntity: EntityForm;
            if (item.id) {
                newEntity = {
                    ...item,
                    start: item.start!,
                    end: item.end!,
                };

                if (item.notes)
                    setNotes(item.notes)
            }
            else {
                newEntity = {...item}
                if (dateFromSchedule){
                    let added30Min: Date = new Date(dateFromSchedule!);
                    added30Min.setMinutes(added30Min.getMinutes() + 30);

                    newEntity = {
                        ...newEntity,
                        start: DateTime.fromJSDate(dateFromSchedule!),
                        end: DateTime.fromJSDate(added30Min),
                        notes: ""
                    };
                }
                if (userIdFromSchedule){
                    newEntity = {
                        ...newEntity,
                        specialistId: userIdFromSchedule
                    };
                }
            }

            setEntityState(newEntity);
        }
    }, [item.id])

    const handleStartTime = (time: any) => {
        let newValue: BlockSchedule = entityState;
        const d = toDateTime(moment(entityState.start.toJSDate()).format(formatDate).toString(), time);
        newValue.start = d;
        changing(newValue);
    };

    const handleEndTime = (time: any) => {
        let newValue: BlockSchedule = entityState;
        const d = toDateTime(moment(entityState.start.toJSDate()).format(formatDate).toString(), time);
        newValue.end = d;
        changing(newValue);
    };

    const toDateTime = (date: string, hhmm: string): DateTime => {
        const newDate = new Date(`${date} ${hhmm}:00`);
        return DateTime.fromJSDate(newDate);
    };

    const handleNotes = (event: React.ChangeEvent<{ name: string, value: string }>) => {
        setNotes(event.target.value);
    };
    
    const handleNotesBlur = (event: React.ChangeEvent<{ name: string, value: string }>) => {
        let newValue: BlockSchedule = entityState;
        newValue.notes = event.target.value
        changing(newValue);
    };
    
    const handleAllDay = (event: any) => {

        const checked = event.target.checked;
        let newValue: BlockSchedule = entityState;

        newValue.allDay = checked;

        if (checked) {
            newValue.start = toDateTime(moment(entityState.start).format(formatDate).toString(), "00:00");
            newValue.end = toDateTime(moment(entityState.end).format(formatDate).toString(), "23:59");
        
        } 
        else {
            newValue.end = toDateTime(moment(entityState.start).format(formatDate).toString(), "23:59");
        }

        changing(newValue);
    };

    const handleDate = (date: Date) => {
        const d = toDateTime(moment(date).format(formatDate).toString(), moment(entityState.start).format(format).toString());
        const df = toDateTime(moment(date).format(formatDate).toString(), moment(entityState.end).format(format).toString());
        let newValue = entityState;
        newValue.start = d;
        newValue.end = df;
        changing(newValue);
    }

    const handleStartDate = (date: Date) => {
        let newValue: BlockSchedule = entityState;
        const d = toDateTime(moment(date).format(formatDate).toString(), "00:00");

        if (entityState.allDay) {
            newValue.start = d;
        }
        else {
            newValue.start = d;
        }

        changing(newValue);
    }

    const handleEndDate = (date: Date) => {
        let newValue: BlockSchedule = entityState;
        const d = toDateTime(moment(date).format(formatDate).toString(), "23:59");
        newValue.end = d;
        changing(newValue);
    }

    const handleSpecialist = (e: any) => {
        let newValue = entityState;
        newValue.specialistId = e.target.value;
        changing(newValue);
    }

    const handleReasonSelected = (e: any) => {
        let newValue = entityState;
        newValue.blockedReasonId = e.id;
        newValue.blockedReason = e.name;
        changing(newValue);
    }


    return (<>
    <div className={classes.root}>

        <div className={classes.rowContainer}>
            <label className={classes.title}>
                {t("Required fields")} <span className={classes.required}>*</span>
            </label>
        </div>

        <div className={classes.rowContainer}>
            <div style={{position: "relative"}}>
                {userInfo.hasAccessToScheduleAllSpecialists &&
                    <SelectWithLabel
                        label={() => (<>
                                {t("Choose the specialist")} <span className={classes.required}>*</span>
                            </>)}
                        value={entityState?.specialistId}
                        placeholder={t("Name of the specialist")}
                        styleClass={classes.inputSpecialist}
                        width={354}
                        isValid={() => true}
                        name="specialist"
                        onChange={handleSpecialist}
                        items={users || []}
                    />
                }
                {!userInfo.hasAccessToScheduleAllSpecialists &&
                  <InputWithLabel
                    label={() => (<>
                        {t("Specialist")} <span className={classes.required}>*</span>
                    </>)}
                    value={userInfo.fullName}
                    inputClass={classes.input}
                    inputDisabled={true}
                    inputReadOnly={true}
                    width={354}
                  />
                }
            </div>
            <div style={{position: "relative"}}>
                <BlockedReasonSelect 
                    placeholderInput={t("Select a reason")}
                    isOptional={false}
                    item={item ? new BlockedReason({
                        id: item.blockedReasonId,
                        name: item.blockedReason!,
                        isPredetermined: item.isBlockedReasonPredetermined,
                        isEdit: false,
                    }) : null }
                    key={item.blockedReasonId}
                    onSelect={handleReasonSelected} 
                />
            </div>
        </div>

        
        <div className={classes.rowContainer} style={{marginTop: 0}}>
            <div className={classes.switch}>
                <Switch
                    baseClass={classes.switchBase}
                    onChange={handleAllDay}
                    value={entityState.allDay}
                />
                <div className={classes.switchLabel}>
                    <label className={classes.labelH2}>
                        {t("All day")}
                    </label>
                </div>
            </div>
        </div>

        {entityState.allDay ?

            <div className={classes.rowContainer}>
            <div style={{marginRight: 26}}>
                    <div style={{width: 354}}>
                        <Typography className={classes.controlLabel}>
                            {t("Start date")}
                        </Typography>
                        <span className={classes.dot}>*</span>
                    </div>
                    <div style={{position: "relative"}}>
                    <DateInput
                        placeholder={t("dd/mm/yy")}
                        format={"dd/MMMM/yyyy"}
                        value={entityState.start}
                        onChange={handleStartDate}
                        inputTextClass={classes.dateText}
                        iconColor={"#919CA5"}
                        width={354}
                    />
                    </div>
                </div>

                <div style={{width: 354}}>
                    <div>
                        <Typography className={classes.controlLabel}>
                            {t("End date")}
                        </Typography>
                        <span className={classes.dot}>*</span>
                    </div>
                    <div style={{position: "relative"}}>
                    <DateInput
                        placeholder={t("dd/mm/yy")}
                        format={"dd/MMMM/yyyy"}
                        value={entityState.end.toJSDate()}
                        onChange={handleEndDate}
                        inputTextClass={classes.dateText}
                        iconColor={"#919CA5"}
                        width={354}
                    />
                    </div>
                </div>
            </div>
        : 
            <div className={classes.rowContainer}>
                <div style={{marginRight: 26}}>
                    <div>
                        <Typography className={classes.controlLabel}>
                            {t("Date")}
                        </Typography>
                        <span className={classes.dot}>*</span>
                    </div>
                    <div style={{position: "relative"}}>
                    <DateInput
                        placeholder={t("dd/mm/yy")}
                        format={"dd/MMMM/yyyy"}
                        value={entityState.start.toJSDate()}
                        onChange={handleDate}
                        inputTextClass={classes.dateText}
                        iconColor={"#919CA5"}
                        width={354}
                        isValid={() => validator?.date}
                        />
                    </div>
                </div>

                <div style={{marginRight: 22}}>
                    <Typography className={classes.controlLabel}>
                        {t("Start time")}
                    </Typography>
                    <span className={classes.dot}>*</span>
                    
                    <div style={{position: "relative"}}>
                    <TimePicker
                        onOk={(time) => {
                            handleStartTime(time.format(format));
                        }}
                        className={`${classes.selectStyle} ${classes.selectHrs} ${validator?.start ? "" : classes.selectHrsNotValid}`}
                        placeholder="HH:mm"
                        inputReadOnly={true}
                        showNow={false}
                        value={moment(entityState.start.toJSDate(), format)}
                        disabledHours={() => []}
                        format={format}
                    />
                    <div className={classes.clockPosition}>
                        <ClockIcon />
                    </div>
                    </div>
                </div>
                
                <div>
                    <Typography className={classes.controlLabel}>
                        {t("End time")}
                    </Typography>
                    <span className={classes.dot}>*</span>
                    <div style={{position: "relative"}}>
                    <TimePicker
                        onOk={(time) => {
                            handleEndTime(time.format(format));
                        }}
                        className={`${classes.selectStyle} ${classes.selectHrs} ${validator?.end ? "" : classes.selectHrsNotValid}`}
                        placeholder="HH:mm"
                        inputReadOnly={true}
                        showNow={false}
                        value={moment(entityState.end.toJSDate(), format)}
                        disabledHours={() => []}
                        format={format}
                    />
                    <div className={classes.clockPosition}>
                        <ClockIcon />
                    </div>
                    </div>
                </div>
            </div>
        }


        <div className={classes.rowContainer}>
            <div className={classes.colNotes}>

                <label htmlFor={"lblNotes"} className={classes.label}>
                    {t("Notes")}  <span className={classes.optional}> {" "} ({t("Optional")})</span>
                </label>

                <Textarea
                    inputClass={`${classes.inputTextareaNotes}`}
                    id={"notes"}
                    name={"notes"}
                    maxLength={180}
                    value={notes ?? ''}
                    onChange={handleNotes}
                    placeholder={t("Enter here notes of why this schedule will be blocked.")}
                    width={732} styles={{ height: 90 }}
                    onBlur={handleNotesBlur}
                />
            </div>
        </div>
    </div >
  </>);

};

export default BlockScheduleForm;