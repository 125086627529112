import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  controlLabel: {
    ...theme.typography.buttons,
    fontWeight: "bold",
    display: "inline-flex",
    color: theme.colors.grey4,
    marginBottom: 8
  },
  inputWithLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    "&:not(:first-child)": {
      marginTop: 16
    }
  },
  dot: {
    ...theme.typography.buttons,
    color: theme.colors.clearMain
  },
  itemRoot: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    height: 40,
    display: "flex",
    alignItems: "center",
  },
}));

export default useStyles;
