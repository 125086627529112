import React from "react";

import { SortIcon, SortUpIcon, SortDownIcon } from "./../../../../assets/icons";

import { ArrowDropProps } from "./props";

const ArrowDrop = (props: ArrowDropProps) => {
  const { classes, propertyName, hover, active, direction, ...otherProps } =
    props;
  return (
    <div {...otherProps} className={classes.dropdown}>
      {direction === "asc" && active ? (
        <SortUpIcon />
      ) : direction === "desc" && active ? (
        <SortDownIcon />
      ) : (
        <SortIcon />
      )}
    </div>
  );
};

export default ArrowDrop;
