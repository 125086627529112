import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const NoFilesIcon = () => (
  <SvgIcon style={{ width: 266, height: 191 }}
           viewBox={"0 0 266 196"}>
    <path
      d="M0.771601 55.4022C-4.31468 95.4126 16.2093 136.431 48.6351 160.419C81.0609 184.408 123.731 192.326 163.658 186.578C192.16 182.494 220.43 171.319 240.518 150.694C260.607 130.068 271.197 99.0798 263.106 71.4481C260.047 61.0132 254.042 50.9002 244.513 45.6589C229.296 37.311 210.525 43.4288 194.319 49.7017C178.112 55.9746 159.37 62.1521 144.118 53.8757C132.401 47.5014 126.378 34.3773 117.792 24.169C103.189 6.81121 79.4628 -2.40729 56.9711 0.544297C54.1549 0.91488 51.3662 1.47063 48.6232 2.20792C24.6944 8.62987 6.60323 28.2893 1.37981 52.5102L0.771601 55.4022Z"
      fill="rgb(225, 233, 254)"
    />
    <path
      d="M89.2121 72.0544L68.1037 180.458L65.3608 194.65L206.083 193.97C212.404 193.94 218.009 188.329 220.03 179.993L244.991 77.6535C246.535 71.327 243.148 64.744 238.354 64.75L95.8904 64.8991C92.7898 64.8931 90.0111 67.8268 89.2121 72.0544Z"
      fill="#FF725E"
    />
    <path
      d="M89.2121 72.0544L68.1037 180.458L65.3608 194.65L206.083 193.97C212.404 193.94 218.009 188.329 220.03 179.993L244.991 77.6535C246.535 71.327 243.148 64.744 238.354 64.75L95.8904 64.8991C92.7898 64.8931 90.0111 67.8268 89.2121 72.0544Z"
      fill="#6462F3"
    />
    <path
      d="M192.601 173.1L177.694 53.0033C177.098 48.084 173.169 44.4109 168.535 44.4288L135.859 44.5301C134.645 44.5438 133.446 44.8047 132.336 45.2967C131.226 45.7888 130.227 46.5018 129.402 47.3923L113.898 63.8914L23.025 64.3803C17.4736 64.4102 13.2042 69.6694 13.9376 75.5725L27.7773 186.57C28.3736 191.484 32.3031 195.151 36.9243 195.145L195.326 194.829C212.964 195.193 214.652 189.987 214.652 189.987C194.402 194.465 192.601 173.13 192.601 173.1Z"
      fill="#9FA9E6"
    />
    <circle xmlns="http://www.w3.org/2000/svg" cx="58.9628" cy="128.963" r="5.96281" fill="#323947"/>
    <circle xmlns="http://www.w3.org/2000/svg" cx="141.25" cy="128.963" r="5.96281" fill="#323947"/>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.243 137.306C112.126 137.422 111.937 137.421 111.819 137.307C105.24 130.952 94.7978 130.952 88.2195 137.307C88.1011 137.421 87.9117 137.422 87.7953 137.306V137.306C87.6789 137.189 87.6795 137 87.7979 136.885C94.6091 130.297 105.429 130.297 112.24 136.885C112.359 137 112.359 137.189 112.243 137.306V137.306Z"
      stroke="#323947"
      strokeWidth={3}
    />
  </SvgIcon>
);

export default NoFilesIcon;
