import React from 'react';

import { Link, useRouteMatch } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { TerminologyType } from "../../../models/enums/terminology-type";
import { TerminologyForm } from "../../../models/enums/terminology-form";

import { useAppSelector } from '../../../redux/hooks';
import { selectAccountSettings } from '../../../redux/store';

import { Tab, Tabs } from "@material-ui/core";

import { Url } from "../../../constants/url";
import useStyles from "./css";
import useTerminology from "../../../hooks/useTerminology";


export default function SettingsNavTabs() {
  
  const routeMatch = useRouteMatch({
    path: [
      Url.Settings.Main,
      Url.Settings.CustomerCommunications,
      Url.Settings.WorkAreas,
      Url.Settings.Services,
      Url.Settings.Users,
      Url.Settings.Payments,
      Url.Settings.ClinicHistory,
      Url.Settings.EvolutionNotes,
      Url.Settings.PatientForm,
      Url.Settings.Commissions
    ],
    exact: true
  });
  
  const currentTab = routeMatch?.path;
  
  const { t } = useTranslation(["settings"]);
  
  const classes = useStyles();
  
  const accountSettings = useAppSelector(selectAccountSettings);
  
  const commissions = useTerminology({ type: TerminologyType.Commission, form: TerminologyForm.Plural });
  
  return (
    <>
      <div className={classes.headerBack}/>
      <div className={classes.mainTab}>
        <div className={classes.tabBar}>
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
          >
            <Tab
              className={classes.tab}
              label={t("General")}
              value={Url.Settings.Main}
              to={Url.Settings.Main}
              component={Link}/>
            <Tab
              className={classes.tab}
              label={t("Communication with the customer")}
              value={Url.Settings.CustomerCommunications}
              to={Url.Settings.CustomerCommunications}
              component={Link}/>
            <Tab
              className={classes.tab}
              label={t("Work areas")}
              value={Url.Settings.WorkAreas}
              to={Url.Settings.WorkAreas}
              component={Link}/>
            <Tab
              className={classes.tab}
              label={t("Services")}
              value={Url.Settings.Services}
              to={Url.Settings.Services}
              component={Link}/>
            <Tab
              className={classes.tab}
              label={t("Users")}
              value={Url.Settings.Users}
              to={Url.Settings.Users}
              component={Link}/>
            <Tab
              className={classes.tab}
              label={t("Payments")}
              value={Url.Settings.Payments}
              to={Url.Settings.Payments}
              component={Link}/>
            {
              accountSettings.isClinicMode ?
                <Tab
                  className={classes.tab}
                  label={t("Clinic history")}
                  value={Url.Settings.ClinicHistory}
                  to={Url.Settings.ClinicHistory}
                  component={Link}
                /> :
                <div/>
            }
            {
              accountSettings.isClinicMode ?
                <Tab
                  className={classes.tab}
                  label={t("Evolution notes")}
                  value={Url.Settings.EvolutionNotes}
                  to={Url.Settings.EvolutionNotes}
                  component={Link}
                /> :
                <div/>
            }
            {
              accountSettings.isClinicMode ?
                <Tab
                  className={classes.tab}
                  label={t("Patient Form")}
                  value={Url.Settings.PatientForm}
                  to={Url.Settings.PatientForm}
                  component={Link}
                /> :
                <div/>
            }
            <Tab
              className={classes.tab}
              label={commissions}
              value={Url.Settings.Commissions}
              to={Url.Settings.Commissions}
              component={Link}/>
          </Tabs>
        </div>
      </div>
    </>);
}