import * as React from "react";
import { Redirect } from "react-router-dom";
import { Url } from "../../../constants/url";
import { useParams } from "react-router-dom";

const WhatsAppConversationRouter = (props:any) =>{
    const history = useParams<any>();
    return (
        <Redirect to={{pathname: Url.WhatsApp.Main, state: {mobile: history.mobile}}} />
    )
}

export default WhatsAppConversationRouter;
