import React, { useState, useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import Cropper from "react-easy-crop";
import { Area, Point } from "react-easy-crop/types";
import "antd/dist/antd.css";
import { TimePicker } from "antd";
import moment from "moment";

import { useAppDispatch } from "../../../../../redux/hooks";
import { alertsActions } from "../../../../../redux/alerts-slice";

import Button from "@material-ui/core/Button";
import { Backdrop, Fade, Modal, Slider, Typography } from "@material-ui/core";
import Tooltip from "@material-ui/core/Tooltip";

import InputWithLabel from "../../../../common/InputWithLabel";
import SelectWithLabel from "../../../../common/SelectWithLabel";
import Textarea from "../../../../common/Textarea";
import { SelectItem } from "../../../../common/Select";
import { PlusIcon, InfoIcon, MoneyIcon, CameraIcon, ArrowDropDownIcon, TrashIcon, EditIcon, CrossIcon, FlatImageIcon } from "../../../../../assets/icons";
import { AlphanumericRegex, CurrencyRegex } from "../../../../../constants/validator";
import Service, { ServiceValidator } from "../../../../../models/service";
import Category from "../../../../../models/category";
import CategorySelect from "./../../CategorySelect";
import ServicesService from "./../../../../../api/settings-services-service"
import WorkareasService from "./../../../../../api/settings-workareas-service"
import useStyles from "./css";
import { ServiceFormProps } from "./props";
import IconTooltip from "../../../../common/IconTooltip";
import ConfirmDeleteModal from "../../../../common/ConfirmDeleteModal";

export default function ServiceForm(props: ServiceFormProps) {

  const { settingsSelectedEstablishmentId, item, onChange, onModify } = props;

  const { t } = useTranslation(["general"]);
  const classes = useStyles();

  const imageRef = useRef<HTMLInputElement>(null);

  const [entityState, setEntityState] = useState<Service>(item || new Service());
  
  const [openDeleteImageModal, setOpenDeleteImageModal] = useState<boolean>(false);

  const [recurrenceList, setRecurrenceList] = useState<SelectItem[]>([]);
  const [workareaList, setWorkareaList] = useState<SelectItem[]>([]);
  
  const [showCropperModal, setShowCropperModal] = useState(false);
  const [cropImage, setCropImage] = useState<string | undefined>("");
  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 });
  const [cropZoom, setCropZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area | null>(null);
  
  const [timePickerIsOpen, setTimePickerIsOpen] = useState<boolean>(false);
  const [durationTime, setDurationTime] = useState<moment.Moment>(moment().utcOffset(0).set({
    hour: 1,
    minute: 0,
    second: 0,
    millisecond: 0
  }));

  const format = "HH:mm";
  const maxFileSize = 800 * 1024;
  const initialCropZoom = 0.815;

  const dispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;

  function runValidations(): ServiceValidator | undefined {
    if (item) {

      let priceValidator = item.price !== null && isNumber(item.price) ? CurrencyRegex.test(item.price!.toString()) : false;

      return {
        name: AlphanumericRegex.test(item.name),
        price: priceValidator,
        categoryId: item.categoryId !== "",
        duration: item.duration! > 0,
        recurrenceId: item.recurrenceId !== "",
      };
    }
  }

  function isNumber(n: any) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  const [entityValid, setEntityValid] = useState<ServiceValidator | undefined>(runValidations());

  const [selectedFile, setSelectedFile] = useState<any>();

  function showOpenFileDialog() {
    if (imageRef && imageRef.current) {
      imageRef.current.click();
    }
  }

  // Clean up the selection to avoid memory leak
  useEffect(() => {
    if (selectedFile) {
      const objectURL = URL.createObjectURL(selectedFile);
      return () => URL.revokeObjectURL(objectURL);
    }
  }, [selectedFile]);

  async function handleChangeFile(event: React.FormEvent<HTMLInputElement>) {

    event.stopPropagation();
    event.preventDefault();
    const files = imageRef.current?.files;

    if (files && files.length > 0) {
      if (imageRef && imageRef.current) {

        const validate = await isFileNotValid(files[0]);
        if (validate) return;

        setSelectedFile(files[0]);

        setEntityState({
          ...entityState,
          photoURL: URL.createObjectURL(files[0]),
          photoName: "",
          file: files[0]
        });
        runValidations();
        if (onModify) onModify();
      }
    }
  }
  
  function handleCropComplete(croppedArea: Area, croppedAreaPixels: Area) {
    setCroppedAreaPixels(croppedAreaPixels);
  }
  
  function handleZoomSliderChange(e: any, newValue: any) {
    setCropZoom(newValue);
  }
  
  function handleCropChange(crop: Point) {
    setCrop(crop);
  }
  
  function handleZoomChange(zoom: number) {
    setCropZoom(zoom);
  }
  
  function handleGoBackCropModalClick(){
    setShowCropperModal(false);
  }
  
  async function handleCropImage() {
    try {
      const croppedImageBlob = await getCroppedImg(cropImage, croppedAreaPixels);
      const croppedImageURL = URL.createObjectURL(croppedImageBlob);
      setSelectedFile(croppedImageBlob);
      const file = new File([croppedImageBlob], 'croppedImage.jpg', { type: croppedImageBlob.type });
      setEntityState({
        ...entityState,
        photoURL: croppedImageURL,
        photoName: "",
        file: file
      });
      runValidations();
      if (onModify) onModify();
      setShowCropperModal(false);
    } catch (error) {
      console.error('Error cropping image:', error);
    }
  }
  
  async function getCroppedImg(imageSrc: any, crop: any): Promise<Blob> {
    return new Promise((resolve, reject) => {
      fetch(
        imageSrc,
        {
          method: 'GET',
          headers: { "Cache-Control": 'no-cache' },
        }
      )
        .then(r => r.blob())
        .then(blob => {
          const imageUrl = URL.createObjectURL(blob);
          const image = new Image();
          
          image.onload = function() {
            const canvas = document.createElement('canvas');
            const ctx = canvas.getContext('2d');
            
            canvas.width = crop.width;
            canvas.height = crop.height;
            
            ctx!.drawImage(
              image,
              crop.x,
              crop.y,
              crop.width,
              crop.height,
              0,
              0,
              crop.width,
              crop.height
            );
            
            canvas.toBlob(
              (blob) => {
                if (!blob) {
                  return;
                }
                resolve(blob);
              }, 'image/jpeg', 1);
          };
          
          image.crossOrigin = 'anonymous';
          image.src = imageUrl;
        });
    });
  }

  function handleOnClickDeleteServicePhoto() {
    if (entityState?.photoURL) {
      setOpenDeleteImageModal(true);
    }
  }
  
  async function handleDeleteServicePhoto(service: Service) {
    setEntityState({
      ...entityState,
      photoName: "",
      photoURL: "",
      file: null
    });
    if (imageRef && imageRef.current) {
      imageRef.current.files = new DataTransfer().files; // assign empty file list for input
    }
    if (onModify) onModify();
    setOpenDeleteImageModal(false);
  }
  
  function handleOnClickEditServicePhoto() {
    setCropZoom(initialCropZoom);
    setCropImage(entityState.photoURL);
    setShowCropperModal(true);
  }
  
  function handleCrossIconCropImageClick() {
    setEntityState({
      ...entityState,
      photoName: "",
      photoURL: "",
      file: null
    });
    if (imageRef && imageRef.current) {
      imageRef.current.files = new DataTransfer().files; // assign empty file list for input
    }
    setShowCropperModal(false);
    if (onModify) onModify();
  }
  
  const DivInfoDeleteImage: React.FC = () => {
    return (<>
      <div className={classes.deleteImageModalCenter} style={{ marginTop: 20 }}>
        <Typography className={classes.deleteImageModalContentText}>
          {t("Delete this photo?")}
        </Typography>
      </div>
    </>);
  };

  useEffect(() => {
    (async () => {
      await getRecurrences();
    })();

    (async () => {
      await getWorkareas();
    })();

    if (item && item.duration) {
      setDurationTime(moment().utcOffset(0).set({ hour: 0, minute: item.duration, second: 0, millisecond: 0 }));
    }
    if (item && JSON.stringify(item) !== JSON.stringify(entityState)) {
      setEntityState(item);
    }

    (async () => {
      await getImage(item?.photoName);
    })();

    runValidations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item]);

  useEffect(() => {

    if (
      onChange
      && entityValid
      && entityValid.name
      && entityValid.price
      && entityValid.duration
      && entityValid.recurrenceId
      && entityValid.categoryId
    ) {
      onChange(true, entityState);
    } else if (onChange) {
      onChange(false, entityState);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityState]);

  async function getRecurrences() {
    const data = await ServicesService.getRecurrences();

    let listItems: SelectItem[] = [];

    data.entity?.forEach(i => {
      listItems.push({
        key: i.id,
        value: t(i.name)
        .replace("weeks", t("weeks"))
        .replace("week", t("week"))
        .replace("months", t("months"))
        .replace("month", t("month"))
      })
    });

    setRecurrenceList(listItems || []);
  }

  async function getWorkareas() {
    const data = await WorkareasService.getWorkareas(1, 1000, settingsSelectedEstablishmentId);

    let listItems: SelectItem[] = [];

    data.entity?.forEach(i => {
      listItems.push({
        key: i.id,
        value: i.name
      })
    });

    setWorkareaList(listItems || []);
  }

  async function getImage(fileName: string | null = "") {

    let fileId = fileName || entityState.photoName;
    if (!fileId) return;

    const data = await ServicesService.getImage(fileId);

    setEntityState({
      ...entityState,
      photoURL: data.fileURL,
    });

  }

  function getTimePickerDurationPostfixClass() {
    const postfix = durationTime.hours() > 1 ? "hrs" : (durationTime.hours() === 1 ? "hr" : "min");
    switch (postfix) {
      case "hrs":
        return classes.selectHrs;
      case "hr":
        return classes.selectHr;
      case "min":
        return classes.selectMin;
    }
  }

  function handleChange(event: React.ChangeEvent<{ name: string, value: string }>) {
    let value = event.target.value;

    switch (event.target.name) {
      case "name":
        setEntityValid({
          ...entityValid,
          [event.target.name]: AlphanumericRegex.test(value)
        });
        break;
      case "price":
        setEntityValid({
          ...entityValid,
          [event.target.name]: CurrencyRegex.test(value)
        });
        break;
    }

    setEntityState({
      ...entityState,
      [event.target.name]: value
    });

    if (onModify) onModify();
  }

  function handleChangeRecurrence(event: React.ChangeEvent<{ value: string }>) {
    let value = event.target.value;

    setEntityValid({
      ...entityValid,
      recurrenceId: value !== ""
    });

    const recurrence: string = recurrenceList.filter(x => x.key == value)[0].value;

    setEntityState({
      ...entityState,
      recurrence: recurrence,
      recurrenceId: value
    });

    if (onModify) onModify();
  }

  function handleChangeWorkArea(event: React.ChangeEvent<{ value: string }>) {
    let value = event.target.value;

    setEntityState({
      ...entityState,
      workAreaId: value
    });

    if (onModify) onModify();
  }

  function handleSelectCategory(item: Category) {

    if (item) {

      setEntityValid({
        ...entityValid,
        categoryId: item.name !== ""
      });

      setEntityState({
        ...entityState,
        categoryId: item.id || "",
        category: item.name
      });

      if (onModify) onModify();
    }
  }

  function onDragOver(e: any) {
    e.preventDefault();
  }

  function onDragEnter(e: any) {
    e.preventDefault();
  }

  function onDragLeave(e: any) {
    e.preventDefault();
  }

  async function onFileDrop(e: any) {
    e.preventDefault();
    const files = e.dataTransfer.files;

    if (!(imageRef && imageRef.current)) {
      return;
    }

    imageRef.current.files = files;

    if (files && files.length > 0) {
      const validate = await isFileNotValid(files[0]);
      if (validate) return;

      setSelectedFile(files[0]);

      setEntityState({
        ...entityState,
        photoURL: URL.createObjectURL(files[0]),
        photoName: "",
        file: files[0]
      });

      if (onModify) onModify();
    }
  }

  async function isFileNotValid(file: any) {
    const validTypes = ['image/jpeg', 'image/jpg', 'image/png'];
    if (validTypes.indexOf(file.type) === -1) {
      return true;
    }

    return file.size > maxFileSize;
  }


  function handleDurationTime(time: moment.Moment) {

    const minutes = (time.hour() * 60) + time.minute();

    if (minutes < 1) {
      dispatch(
        enqueueAlert({
          type: "Error",
          title: t("Invalid duration"),
        })
      );
      return;
    }

    setDurationTime(time);

    setEntityValid({
      ...entityValid,
      duration: +minutes > 0
    });

    setEntityState({
      ...entityState,
      duration: minutes
    });

    if (onModify) onModify();

    setTimePickerIsOpen(false);
  }


  function handleOpenTimepicker(isOpen: boolean) {
    setTimePickerIsOpen(isOpen);
  }

  return (
    <div className={classes.root}>

      <div className={classes.rowContainer}>

        <InputWithLabel
          label={() => (
            <>
              {t("Service name")} <span className={classes.required}>*</span>
            </>
          )}
          value={entityState?.name}
          placeholder={t("Enter the service name")}
          width={255}
          isValid={() => entityValid?.name}
          name="name"
          maxLength={60}
          onChange={handleChange}
        />

        <div className={classes.divPrice}>
          <InputWithLabel
            label={() => (
              <>
                {t("Price")} <span className={classes.required}>*</span>
              </>
            )}
            value={entityState?.price}
            placeholder={t("Enter the price")}
            width={255}
            isValid={() => entityValid?.price}
            name="price"
            inputType="number"
            onChange={handleChange}
          />

          <div className={classes.moneyIcon}>
            <MoneyIcon style={{ color: "#5C6477" }}/>
          </div>
        </div>
        <div className={classes.divCategory}>
          <CategorySelect
            onSelect={handleSelectCategory}
            item={item}
            widthSelect={255}
            isOptional={false}
            settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
          />
        </div>
      </div>

      <div className={classes.rowContainer}>

        <div>
          <label className={classes.label}>
            {t("Estimated duration")}{" "}<span className={classes.required}>*</span>
          </label>

          <TimePicker
            onOk={(time) => {
              handleDurationTime(time);
            }}
            className={`${classes.selectStyle} ${getTimePickerDurationPostfixClass()}`}
            placeholder="HH:mm"
            inputReadOnly={true}
            showNow={false}
            value={durationTime}
            disabledHours={() => [21, 22, 23]}
            onOpenChange={(e) =>
              handleOpenTimepicker(e)
            }
            format={format}
          />

          <ArrowDropDownIcon
            style={{
              top: "inherit",
              right: 25,
              pointerEvents: "none",
              color: "#5C6477",
              position: "relative",
              transform: timePickerIsOpen
                ? "rotate(180deg)"
                : "rotate(0deg)",
            }}
          />
        </div>

        <SelectWithLabel
          label={() => (
            <>
              {t("Recurrence")}{" "}<span className={classes.required}>*</span>
            </>
          )}
          isValid={() => entityValid?.recurrenceId}
          items={recurrenceList}
          placeholder={t("Select an option")}
          width={255}
          name="recurrenceid"
          onChange={handleChangeRecurrence}
          value={entityState?.recurrenceId}
        />

        <SelectWithLabel
          label={() => (
            <>
              {t("Work area")}{" "}
              <span className={classes.optional}>({t("Optional")})</span>
            </>
          )}
          items={workareaList}
          placeholder={t("Select one of your list")}
          width={255}
          name="workareaId"
          onChange={handleChangeWorkArea}
          value={entityState?.workAreaId}
        />
      </div>

      <div className={classes.rowContainer}>
        <div className={classes.colPhoto}>

          <label htmlFor={"lblPhoto"} className={classes.label}>
            {t("Service photo")} <span className={classes.optional}>({t("Optional")})</span>
          </label>

          <input
            ref={imageRef!}
            type="file"
            style={{ display: 'none' }}
            accept="image/jpeg, image/jpg, image/png"
            onChange={handleChangeFile}
          />
          <div
            className={`${classes.divUploadPhoto}`}
            onClick={showOpenFileDialog}
            style={{ backgroundImage: `url(${entityState.photoURL})` }}
            onDragOver={onDragOver}
            onDragEnter={onDragEnter}
            onDragLeave={onDragLeave}
            onDrop={onFileDrop}
          >
            {
              entityState.photoURL && <div className={classes.divPhotoExistHover}>
                <div className={classes.iconCam}>
                  <CameraIcon/>
                </div>
                <div className={classes.labelChangePhoto}>
                  {t("Change photo")}
                </div>
              </div>
            }
            {
              !entityState.photoURL && <>
                <div className={classes.iconPlus}>
                  <PlusIcon style={{ color: "#6462F3" }}/>
                </div>
                <div className={classes.labelAddPhoto}>
                  {t("Add photo")}
                </div>
                <div className={classes.labelAddPhotoIns}>
                  {t("Recommended size") + ": 430px x 240px"}
                </div>
              </>
            }
          </div>
          {entityState.photoURL && <div className={classes.servicePhotoButtonContainer}>
              <Tooltip
                  title={`${t("Edit photograph")}`}
                  placement="bottom-start"
                  classes={{ popper: classes.servicePhotoTooltipBlock }}>
                  <div className={classes.servicePhotoHeaderIcon}>
                    <EditIcon
                        className={classes.servicePhotoHeaderButtons}
                        style={{ width: 16, height: 16, }}
                        onClick={handleOnClickEditServicePhoto}
                    />
                  </div>
              </Tooltip>
            <span className={classes.servicePhotoHeaderIcon}>
              <TrashIcon
                className={classes.servicePhotoHeaderButtons}
                style={{ width: 13, height: 17, color: "#919CA5", }}
                onClick={handleOnClickDeleteServicePhoto}
              />
            </span>
          </div>}

        </div>
        <div className={classes.colNotes}>
          <label htmlFor={"lblDescription"} className={classes.label}>
            {t("Service description")} <span className={classes.optional}> {" "} ({t("Optional")})</span>
          </label>

          <Textarea
            inputClass={`${classes.inputTextareaDescription}`}
            id={"description"}
            name={"description"}
            showCounter={true}
            maxLength={224}
            value={entityState?.description}
            onChange={handleChange}
            placeholder={t("Write a short description of the service here.")}
            width={557}
            styles={{ height: 127 }}
          />
        </div>
      </div>
      <div className={classes.rowContainer}>
        <div>
          <label htmlFor={"lblNotes"} className={classes.label}>
            {t("Notes prior to the service")}
            <span className={classes.optional}> {" "} ({t("Optional")})</span>
            <IconTooltip
              classNameRoot={classes.iconTooltipRoot}
              icon={<InfoIcon style={{ width: 12, height: 12, marginLeft: 4 }} viewBox={"0 0 12 12"} />}
              tooltipText={t("Notes will be provided to the customer prior to their appointment and by WhatsApp.")}
            />
          </label>

          <Textarea
            inputClass={`${classes.inputTextareaNotes}`}
            id={"notes"}
            name={"notes"}
            maxLength={600}
            showCounter={true}
            value={entityState?.notes}
            onChange={handleChange}
            placeholder={t("Write your pre-service notes here.")}
            width={813}
            styles={{ height: 61 }}
          />
        </div>
      </div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={showCropperModal}
        onClose={() => setShowCropperModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{ timeout: 500, }}
      >
        <Fade in={showCropperModal}>
          <div className={classes.modalCropPaper}>
              <Typography className={classes.modalHeader}>
                {t("Crop photo")}
              </Typography>
              <div className={`${classes.modalCropContainer}`}>
                  <div className={classes.cropContainer}>
                        <div className={classes.crossIconContainer} onClick={handleCrossIconCropImageClick}>
                            <CrossIcon color={"white"}/>
                        </div>
                        <Cropper
                          image={entityState.photoURL}
                          maxZoom={10}
                          minZoom={initialCropZoom}
                          classes={{
                            cropAreaClassName: classes.cropArea,
                            containerClassName: classes.cropInternalContainer,
                          }}
                          objectFit={"cover"}
                          crop={crop}
                          zoom={cropZoom}
                          showGrid={false}
                          cropSize={{width: 522, height: 294}}
                          onCropChange={handleCropChange}
                          onCropComplete={handleCropComplete}
                          onZoomChange={handleZoomChange}
                      />
                  
                  </div>
                <div>
                  <div className={classes.cropSliderContainer}>
                    <FlatImageIcon color={"#ACB7C0"} style={{ width: 25, height: 25 }}/>
                    <Slider
                      min={initialCropZoom}
                      max={10}
                      className={classes.cropSlider}
                      step={0.001}
                      value={cropZoom}
                      onChange={handleZoomSliderChange}
                    />
                    <FlatImageIcon color={"#ACB7C0"}/>
                  </div>
                  
                  <div className={classes.cropModalButtonContainer}>
                    <Button
                      className={`${classes.goBackButton}`}
                      onClick={handleGoBackCropModalClick}>
                      {t("Go back")}
                    </Button>
                    <Button
                      className={`${classes.saveCropButton}`}
                      onClick={handleCropImage}>
                      {t("Save")}
                    </Button>
                  </div>
                </div>
              </div>
          </div>
        </Fade>
      </Modal>
      <ConfirmDeleteModal
        classModal={classes.modalDeleteImage}
        open={openDeleteImageModal}
        item={item}
        componentInfo={<DivInfoDeleteImage />}
        onClose={() => setOpenDeleteImageModal(false)}
        onDelete={handleDeleteServicePhoto}
      />
    </div>
  );
}