import React, { useEffect } from "react";
import { useSnackbar } from "notistack";

import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import {
  SuccessIcon,
  ErrorIcon,
  ExclamationIcon, CrossIcon,
} from "./../../../../assets/icons";
import { useAppDispatch, useAppSelector } from "./../../../../redux/hooks";
import { alertsActions } from "../../../../redux/alerts-slice";

import { AlertProps } from "./props";
import useStyles from "./css";

const Alert = (props: AlertProps) => {
  const { closeSnackbar } = useSnackbar();
  const { key, title, description, link, type } = props;
  const classes = useStyles();
  return (
    <div className={link ? classes.notificationLink : classes.notification}>
      <div
        className={`${classes.notificationType} ${
          type === "Error"
            ? classes.notificationError
            : type === "Success"
            ? classes.notificationSuccess
            : classes.notificationWarning
        }`}
      />
      <div className={classes.notificationBody}>
          <div className={classes.notificationIconContainer}>
            <div className={classes.notificationIcon}>
              {type === "Error" ? (
                <ErrorIcon />
              ) : type === "Success" ? (
                <SuccessIcon />
              ) : (
                <ExclamationIcon viewBox="0 0 1 20" />
              )}
            </div>
          </div>
        <div className={classes.notificationContent}>
          <div className={classes.notificationText}>
            <Typography className={classes.notificationTitle}>
              {title}
            </Typography>
            <Typography className={classes.notificationDescription}>
              {description}
            </Typography>
            {link && link.title &&
              <a
                href={link?.url || "#"}
                onClick={link?.onClick}
                className={classes.link}
              >
                {link?.title}
              </a>
            }
          </div>
        </div>
      </div>
      <div className={classes.notificationClose}>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => {
            closeSnackbar(key);
          }}
          className={classes.closeIconButton}
        >
          <div className={classes.notificationCloseIcon}>
            <CrossIcon style={{ width: 16, height: 16 }} viewBox="0 0 16 16" />
          </div>
        </IconButton>
      </div>
    </div>
  );
};

let displayed: any[] = [];

const useAlert = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dispatch = useAppDispatch();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const alerts = useAppSelector((state) => state.alerts.alerts);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { removeAlert } = alertsActions;

  const storeDisplayed = (id: any) => {
    displayed = [...displayed, id];
  };

  const removeDisplayed = (id: any) => {
    displayed = [...displayed.filter((key) => id !== key)];
  };

  const classes = useStyles();

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    alerts.forEach((alert) => {
      const { key, title, description, link, type } = alert;

      if (alert.dismissed) {
        closeSnackbar(key);
        return;
      }

      // do nothing if snackbar is already displayed
      if (displayed.includes(key)) return;

      // display snackbar using notistack
      enqueueSnackbar(alert.title, {
        key: key,
        content: (key: any, message: any) => (
          <div className={link ? classes.notificationLink : classes.notification}>
            <div
              className={`${classes.notificationType} ${
                type === "Error"
                  ? classes.notificationError
                  : type === "Success"
                    ? classes.notificationSuccess
                    : classes.notificationWarning
              }`}
            />
            <div className={classes.notificationBody}>
              <div className={classes.notificationIconContainer}>
                <div className={classes.notificationIcon}>
                  {type === "Error" ? (
                    <ErrorIcon />
                  ) : type === "Success" ? (
                    <SuccessIcon />
                  ) : (
                    <ExclamationIcon viewBox="0 0 1 20" />
                  )}
                </div>
              </div>
              <div className={classes.notificationContent}>
                <div className={classes.notificationText}>
                  <Typography className={classes.notificationTitle}>
                    {title}
                  </Typography>
                  <Typography className={classes.notificationDescription}>
                    {description}
                  </Typography>
                  {link && link.title &&
                  <a
                    href={link?.url || "#"}
                    onClick={link?.onClick}
                    className={classes.link}
                  >
                    {link?.title}
                  </a>
                  }
                </div>
              </div>
            </div>
            <div className={classes.notificationClose}>
              <IconButton
                size="small"
                aria-label="close"
                color="inherit"
                onClick={() => {
                  closeSnackbar(key);
                }}
                className={classes.closeIconButton}
              >
                <div className={classes.notificationCloseIcon}>
                  <CrossIcon style={{ width: 16, height: 16 }} viewBox="0 0 16 16" />
                </div>
              </IconButton>
            </div>
          </div>
        ),
        // onClose: (event, reason, myKey) => {
        //   if (options.onClose) {
        //     options.onClose(event, reason, myKey);
        //   }
        // },
        onExited: (event, myKey) => {
          // remove this snackbar from redux store
          dispatch(removeAlert(myKey));
          removeDisplayed(myKey);
        },
      });

      // keep track of snackbars that we've displayed
      storeDisplayed(key);
    });
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [alerts]);
};

export default useAlert;
