import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { conformToMask } from "react-text-mask";
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary, Button } from "@material-ui/core";

import { useDispatch } from 'react-redux';

import { PatientEvolutionNotesProps } from "./props";
import useStyles from "./css";
import DiaryIcon from "../../../assets/icons/DiaryIcon";
import {
  ArrowDownIcon,
  DeleteIcon,
  DownloadFileCloud,
  ImageIcon,
  InfoIcon,
  PDFIcon,
  PlusIcon,
  TrashIcon,
  UploadFileCloud,
  WordIcon
} from "../../../assets/icons";
import EvolutionNotesGrouped, {
  EvolutionNote,
  EvolutionNoteClinicalFile,
  EvolutionNoteDynamicInput,
  EvolutionNoteTreatmentInput
} from "../../../models/evolution-notes-grouped";
import { GetMonthStringEnum } from "../../../models/enums/months";
import { Editor } from "@tinymce/tinymce-react";
import IconButton from "@material-ui/core/IconButton";
import ConfirmActionModal from "../../common/ConfirmActionModal";
import { alertsActions } from "../../../redux/alerts-slice";
import Input from "../../common/Input";
import ConfirmDeleteModal from "../../common/ConfirmDeleteModal";
import { CustomerService } from "../../../api/customer-service";
import { useAppSelector } from "../../../redux/hooks";
import PatientImportEvolutionNoteClinicalFileModal from "../PatientImportEvolutionNoteClinicalFileModal";
import { TableColumn } from "../../common/grid/table-column";
import GridListWithoutPagination from "../../common/grid/GridListWithoutPagination";
import { PatientFormFieldAndValue } from "../../../models/patient-form-field-and-value";
import { SectionFieldType } from "../../../models/enums/section-field-type";
import { FormPhoneNumberValue } from "../../../models/form-phone-number-value";
import { FormRadioValue } from "../../../models/form-radio-value";
import { SectionFieldModel } from "../../../models/section-field-model";
import PatientFormTextInput from "../../common/PatientFormTextInput";
import PatientFormMobileInput from "../../common/PatientFormMobileInput";
import { SectionFieldItemModel } from "../../../models/section-field-item-model";
import { SectionFieldValueModel } from "../../../models/section-field-value-model";
import { FormFieldItemValue } from "../../../models/form-field-item-value";
import { EmailRegex } from "../../../constants/validator";
import { selectAccountSettings } from "../../../redux/store";
import PatientFormRadioGroup from "../../common/PatientFormRadioGroup";
import { EvolutionNoteFieldAndValue } from "../../../models/evolution-note-field-and-value";
import CountryFlagIcon from "../../../assets/flags";
import { GetMobileMaskByTemp } from "../../../constants/mask";
import { Color } from "../../../constants/colors";
import { navbarActions } from "../../../redux/navbar-slice";
import CircularProgress from "@material-ui/core/CircularProgress";
import IconTooltip from "../../common/IconTooltip";
import useTerminology from "../../../hooks/useTerminology";
import { TerminologyType } from "../../../models/enums/terminology-type";
import { TerminologyForm } from "../../../models/enums/terminology-form";
import { Url } from "../../../constants/url";
import { Customer } from "../../../models/customer";


const PatientEvolutionNotes = (props: PatientEvolutionNotesProps) => {

  const { customerName, customerMobile, appointmentId, serviceId, onUpdate } = props;
  const sectionTNamespace = "sections";
  const { t } = useTranslation(["general", sectionTNamespace]);
  const classes = useStyles();
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const showLoaderIsActive = useAppSelector(state => state.navbar.showLoader);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueAlert } = alertsActions;
  const accountSettings = useAppSelector(selectAccountSettings);
  const { customerId } = useParams<any>();

  const treatmentTerm = useTerminology({ type: TerminologyType.Treatment, form: TerminologyForm.Singular });

  const [evolutionNotesGrouped, setEvolutionNotesGrouped] = useState<EvolutionNotesGrouped[]>([]);
  const [vitalSignsFields, setVitalSignsFields] = useState<SectionFieldModel[]>([]);
  const [treatmentForRemove, setTreatmentForRemove] = useState<EvolutionNoteTreatmentInput>();
  const [studyForRemove, setStudyForRemove] = useState<EvolutionNoteDynamicInput>();
  const [editNoteMode, setEditNoteMode] = useState<boolean>();
  const [cancelEvolutionNoteModalIsOpen, setCancelEvolutionNoteModalIsOpen] = useState<boolean>(false);
  const [evolutionNoteIsInvalid, setEvolutionNoteIsInvalid] = useState<boolean>(true);
  const [removeTreatmentModalIsOpen, setRemoveTreatmentModalIsOpen] = useState<boolean>(false);
  const [removeStudyModalIsOpen, setRemoveStudyModalIsOpen] = useState<boolean>(false);
  const [focusedEvolutionNoteInput, setFocusedEvolutionNoteInput] = useState<boolean>(false);
  const [focusedDiagnosisInput, setFocusedDiagnosisInput] = useState<boolean>(false);
  const [focusedAdditionalInstructionsInput, setFocusedAdditionalInstructionsInput] = useState<boolean>(false);
  const [evolutionNoteInputErrorHighlight, setEvolutionNoteInputErrorHighlight] = useState<boolean>(false);
  const [editableEvolutionNoteBeforeChanges, setEditableEvolutionNoteBeforeChanges] = useState<EvolutionNote>();
  const [editableEvolutionNote, setEditableEvolutionNote] = useState<EvolutionNote>();
  const [deleteFileModalIsOpen, setDeleteFileModalIsOpen] = useState<boolean>(false);
  const [deletableFile, setDeletableFile] = useState<EvolutionNoteClinicalFile | undefined>();
  const [diagnosisTextLength, setDiagnosisTextLength] = useState<number>(0);
  const [additionalInstructionsTextLength, setAdditionalInstructionsTextLength] = useState<number>(0);
  const [evolutionNoteTextLength, setEvolutionNoteTextLength] = useState<number>(0);
  const [vitalSignSectionName, setVitalSignSectionName] = useState<string>("");
  const maxEditorTxtLength = 3485;

  const [importModalIsOpen, setImportModalIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const getData = async () => {
      dispatch(navbarActions.setShowLoader(true));
      const data = await CustomerService.getPatientEvolutionNotes(customerId, appointmentId, serviceId);
      dispatch(navbarActions.setShowLoader(false));
      if (data.evolutionNotesGrouped) {
        setEvolutionNotesGrouped(data.evolutionNotesGrouped);
      }
      if (data.sectionFields) {
        setVitalSignsFields(data.sectionFields);
      }

      if (data.vitalSignSectionName) {
        setVitalSignSectionName(data.vitalSignSectionName);
      }
    };

    getData()
      .then(() => setIsLoading(false));
  }, []);

  useEffect(() => {
    [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined) {
          if ((evolutionNote.evolutionNoteText === undefined
            || evolutionNote.evolutionNoteText.length < 1)
            || evolutionNoteTextLength > maxEditorTxtLength
            || diagnosisTextLength > maxEditorTxtLength
            || evolutionNote.vitalSigns.filter(x => x.field?.isRequired && !x.field?.isDeleted).some(x => x.value === null)) {
            setEvolutionNoteIsInvalid(true);
          } else {
            setEvolutionNoteIsInvalid(false);
          }
        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

  }, [evolutionNotesGrouped]);

  const handleAddNoteButton = () => {
    setEditNoteMode(true);
    let dateTimeNow = new Date();
    let yearIndex = dateTimeNow.getFullYear();
    let monthIndex = dateTimeNow.getMonth() + 1;
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped];

    let result = newEvolutionNotesGrouped?.find(item => item.month === monthIndex && item.year === yearIndex);

    let treatments: Array<EvolutionNoteTreatmentInput> = [];
    let studies: Array<EvolutionNoteDynamicInput> = [];
    let vitalSigns: Array<EvolutionNoteFieldAndValue> = [];
    [...vitalSignsFields].forEach(field => {
      vitalSigns.push(new EvolutionNoteFieldAndValue({ field: field, value: null }));
    });

    let clinicalFiles: EvolutionNoteClinicalFile[] = [];
    if (result) {
      newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
        if (evolutionNoteGrouped.month === monthIndex && evolutionNoteGrouped.year === yearIndex) {
          treatments.push(new EvolutionNoteTreatmentInput({ id: null, order: 1, medication: "", prescription: "" }));
          studies.push(new EvolutionNoteDynamicInput({ id: null, order: 1, name: "" }));
          let evolutionNote = new EvolutionNote({ editMode: true, vitalSigns: vitalSigns, treatments: treatments, studies: studies, clinicalFiles: clinicalFiles });
          evolutionNoteGrouped.evolutionNotes.push(evolutionNote);
          setEditableEvolutionNote(evolutionNote);
        }
        return evolutionNoteGrouped;
      });

    } else {
      treatments.push(new EvolutionNoteTreatmentInput({ id: null, order: 1, medication: "", prescription: "" }));
      studies.push(new EvolutionNoteDynamicInput({ id: null, order: 1, name: "" }));
      let evolutionNotes: Array<EvolutionNote> = [];
      let evolutionNote = new EvolutionNote({ editMode: true, vitalSigns: vitalSigns, treatments: treatments, studies: studies, clinicalFiles: clinicalFiles });
      evolutionNotes.push(evolutionNote);
      newEvolutionNotesGrouped.push(new EvolutionNotesGrouped({ evolutionNotes: evolutionNotes, month: dateTimeNow.getMonth() + 1, year: dateTimeNow.getFullYear() }));
      setEditableEvolutionNote(evolutionNote);
    }
    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
  };

  const handleEvolutionNoteEditorOnBlur = (c: any, editor: any) => {
    const contentText = editor.getContent({ format: "text" });
    if (contentText.length < 1 || contentText.length > maxEditorTxtLength) {
      setEvolutionNoteInputErrorHighlight(true);
    } else {
      setEvolutionNoteInputErrorHighlight(false);
    }
    setFocusedEvolutionNoteInput(false)
  }

  async function handleSaveNoteButton(evolutionNote: EvolutionNote) {
    evolutionNote.customerId = customerId;
    evolutionNote.appointmentId = appointmentId;
    evolutionNote.serviceId = serviceId;
    evolutionNote.treatments = evolutionNote.treatments.filter(x => x.medication !== "" && x.medication !== undefined);
    evolutionNote.studies = evolutionNote.studies.filter(x => x.name !== "" && x.name !== undefined);
    dispatch(navbarActions.setShowLoader(true));
    const data = await CustomerService.createEvolutionNote(evolutionNote);
    dispatch(navbarActions.setShowLoader(false));
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === undefined) {
          if (data) {
            evolutionNote.id = data.id;
            evolutionNote.diagnosis = data.diagnosis;
            evolutionNote.evolutionNoteText = data.evolutionNoteText;
            evolutionNote.user = data.user;
            evolutionNote.dateCreated = data.dateCreated;
            evolutionNote.appointmentDate = data.appointmentDate;
            evolutionNote.editMode = false;
            evolutionNote.treatments = data.treatments.map((item: any) => new EvolutionNoteTreatmentInput(item));
            evolutionNote.studies = data.studies.map((item: any) => new EvolutionNoteDynamicInput(item));
            evolutionNote.clinicalFiles = data.clinicalFiles.map((item: any) => new EvolutionNoteClinicalFile(item));
          }
        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

    setEditNoteMode(false);
    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
    dispatch(
      enqueueAlert({
        type: "Success",
        title: t("Evolution note successfully created"),
      }));

    onUpdate();
  }

  async function handleUpdateNoteButton(evolutionNote: EvolutionNote) {
    evolutionNote.treatments = evolutionNote.treatments.filter(x => x.medication !== "" && x.medication !== undefined);
    evolutionNote.studies = evolutionNote.studies.filter(x => x.name !== "" && x.name !== undefined);
    dispatch(navbarActions.setShowLoader(true));
    const data = await CustomerService.updateEvolutionNote(evolutionNote);
    dispatch(navbarActions.setShowLoader(false));
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === data.id) {
          evolutionNote.diagnosis = data.diagnosis;
          evolutionNote.evolutionNoteText = data.evolutionNoteText;
          evolutionNote.editMode = false;
          evolutionNote.treatments = data.treatments.map((item: any) => new EvolutionNoteTreatmentInput(item));
          evolutionNote.studies = data.studies.map((item: any) => new EvolutionNoteDynamicInput(item));
          evolutionNote.clinicalFiles = data.clinicalFiles.map((item: any) => new EvolutionNoteClinicalFile(item));
        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

    setEditNoteMode(false);
    setEvolutionNotesGrouped(newEvolutionNotesGrouped);

    onUpdate();

  }

  const handleEditNoteButton = async (editableEvolutionNote: EvolutionNote) => {
    setEditNoteMode(true);
    setEditableEvolutionNoteBeforeChanges({
      ...editableEvolutionNote,
      studies: structuredClone(editableEvolutionNote.studies),
      treatments: structuredClone(editableEvolutionNote.treatments),
      clinicalFiles: structuredClone(editableEvolutionNote.clinicalFiles)
    });

    setEditableEvolutionNote(editableEvolutionNote);
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === editableEvolutionNote.id) {
          if (evolutionNote.treatments.length === 0) {
            let treatments: Array<EvolutionNoteTreatmentInput> = [];
            treatments.push(new EvolutionNoteTreatmentInput({ id: null, order: 1, medication: "", prescription: "" }));
            evolutionNote.treatments = treatments;
          }
          if (evolutionNote.studies.length === 0) {
            let studies: Array<EvolutionNoteDynamicInput> = [];
            studies.push(new EvolutionNoteDynamicInput({ id: null, order: 1, name: "" }));
            evolutionNote.studies = studies;
          }

          evolutionNote.editMode = true;
        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
  };

  const handleCancelButtonEvolutionNoteModal = (canceledEvolutionNote: EvolutionNote) => {
    if (canceledEvolutionNote.id) {
      let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
        evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {

          if (evolutionNote.id === canceledEvolutionNote.id) {
            evolutionNote.evolutionNoteText = editableEvolutionNoteBeforeChanges!.evolutionNoteText;
            evolutionNote.diagnosis = editableEvolutionNoteBeforeChanges!.diagnosis!;
            evolutionNote.treatments = editableEvolutionNoteBeforeChanges!.treatments!;
            evolutionNote.studies = editableEvolutionNoteBeforeChanges!.studies;
            evolutionNote.clinicalFiles = editableEvolutionNoteBeforeChanges!.clinicalFiles;
            evolutionNote.editMode = false;
          }
          return evolutionNote;
        })
        return evolutionNoteGrouped;
      });
      setEditableEvolutionNoteBeforeChanges(undefined);
      setEvolutionNotesGrouped(newEvolutionNotesGrouped);
      setEvolutionNoteInputErrorHighlight(false);
      setEditNoteMode(false);
    } else {
      [...evolutionNotesGrouped].filter(evolutionNoteGrouped => {
        let editableEvolutionNoteEntity = evolutionNoteGrouped.evolutionNotes.find(evolutionNote => evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined);
        if (editableEvolutionNoteEntity) {
          if ((editableEvolutionNoteEntity.evolutionNoteText === undefined || editableEvolutionNoteEntity.evolutionNoteText?.length === 0)
            && (editableEvolutionNoteEntity.diagnosis === undefined || editableEvolutionNoteEntity.diagnosis?.length === 0)
            && (editableEvolutionNoteEntity.treatments.length > 1 || editableEvolutionNoteEntity.treatments[0].medication.length === 0)
            && (editableEvolutionNoteEntity.studies.length > 1 || editableEvolutionNoteEntity.studies[0].name.length === 0)) {
            handleOnConfirmCancelEvolutionNote();
          } else {
            setCancelEvolutionNoteModalIsOpen(true);
          }
        }
      });
    }
  };

  const handleOnConfirmCancelEvolutionNote = () => {
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].filter(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes = evolutionNoteGrouped.evolutionNotes.filter(note => note.id != undefined);
      if (evolutionNoteGrouped.evolutionNotes.length > 0) {
        return evolutionNoteGrouped;
      }
    });

    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
    setEditNoteMode(false);
    setCancelEvolutionNoteModalIsOpen(false);
    setEvolutionNoteInputErrorHighlight(false);
  };
  const handleOnRemoveTreatmentConfirm = () => {
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined) {
          let index = evolutionNote.treatments.map(x => x.order).indexOf(treatmentForRemove!.order);
          if (index !== -1) {
            evolutionNote.treatments.splice(index, 1);
          }
          evolutionNote.treatments.forEach(s => {
            if (s.order! <= index)
              return;
            s.order = s.order! - 1;
          });
        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
    setRemoveTreatmentModalIsOpen(false);
  };

  const handleOnRemoveStudyConfirm = () => {
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined) {
          let index = evolutionNote.studies.map(x => x.order).indexOf(studyForRemove!.order);
          if (index !== -1) {
            evolutionNote.studies.splice(index, 1);
          }
          evolutionNote.studies.forEach(s => {
            if (s.order! <= index)
              return;
            s.order = s.order! - 1;
          });
        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
    setRemoveStudyModalIsOpen(false);
  };

  const handleAddTreatmentClick = () => {
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined) {
          evolutionNote.treatments.push(new EvolutionNoteTreatmentInput({ id: null, order: evolutionNote.treatments.length + 1, medication: "", prescription: "" }));
        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
  };

  const handleAddStudyClick = () => {
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined) {
          evolutionNote.studies.push(new EvolutionNoteDynamicInput({ id: null, order: evolutionNote.studies.length + 1, name: "" }));
        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
  };

  const handleEditorEvolutionNoteTextChange = async (text: string, editor: any) => {
    const editorLength = editor.getContent({ format: "text" }).length;
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined) {
          evolutionNote.evolutionNoteText = text;
        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

    setEvolutionNoteTextLength(editorLength);
    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
  };

  const handleEditorDiagnosisChange = async (text: string, editor: any) => {
    const editorLength = editor.getContent({ format: "text" }).length;
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined) {
          evolutionNote.diagnosis = text;
        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });
    setDiagnosisTextLength(editorLength);
    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
  };

  const handleEditorAdditionalInstructionsChange = async (text: string, editor: any) => {
    const editorLength = editor.getContent({ format: "text" }).length;
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined) {
          evolutionNote.additionalInstructions = text;
        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });
    setAdditionalInstructionsTextLength(editorLength);
    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
  };

  const fileDate = (appointmentDate: Date): string => {
    const date = new Date(appointmentDate);

    const day = date.toLocaleString(localeApp, {
      day: "2-digit",
    });

    const month = date.toLocaleString(localeApp, {
      month: "2-digit",
    });

    const year = date.toLocaleString(localeApp, {
      year: "numeric",
    });

    if (localeApp === "es")
      return `${day}-${month}-${year}`;
    else
      return `${month}-${day}-${year}`;
  }

  const date = (startDate: Date | null): string => {
    if (startDate === null) {
      return "--";
    }

    const date = new Date(startDate);

    const day = date.toLocaleString(localeApp, {
      day: "2-digit",
    });

    const month = date.toLocaleString(localeApp, {
      month: "short",
    }).toLowerCase();

    const year = date.toLocaleString(localeApp, {
      year: "numeric",
    });

    const time = date.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    const capitalizeFirstLetter = function (str: string) {
      return str.charAt(0).toUpperCase() + str.slice(1);
    }

    if (localeApp === "es")
      return capitalizeFirstLetter(`${day}/${month}/${year} - ${time}hrs`);
    else
      return capitalizeFirstLetter(`${month}/${day}/${year} - ${time}hrs`);
  };

  const filesColumns: TableColumn[] = [
    {
      id: "fileName",
      label: t("NAME"),
      sorting: false,
      completeObject: true,
      width: 130,
      component: (item: any) => <>
        <div className={classes.fileNameContainer}>
          <div style={{ fontWeight: 700 }}>
            {getExtenstionImage(item.fileName)}
          </div>
          <div style={{ wordBreak: "break-word" }}>
            {t(item.fileName)}
          </div>
        </div>
      </>,
    },
    {
      id: "uploadedBy",
      label: t("UPLOADED"),
      width: 80,
      completeObject: true,
      component: (item: any) => <>
        <div style={{ marginLeft: 3 }}>
          {item.uploadedBy}
        </div>
      </>,
    },
    {
      id: "created",
      label: t("DATE AND TIME"),
      width: 20,
      completeObject: true,
      component: (item: any) => <>
        <div style={{ whiteSpace: "pre-line", marginLeft: 3 }}>
          {getUploadDate(item.created)}
        </div>
      </>,
    },
    {
      id: "fileComment",
      label: t("COMMENTS"),
      width: 130,
      completeObject: true,
      component: (item: any) => <>
        {item.fileComment
          ? item.fileComment
          : <div style={{ color: "#919CA5", marginLeft: 3 }}>
            {t("No comments")}
          </div>}
      </>,
    },
    {
      id: "s3Key",
      label: t("ACTIONS"),
      width: 60,
      completeObject: true,
      component: (item: any) => <>
        <div className={classes.actionsContainer}>
          <IconButton
            onClick={() => handleDownloadFile(item)}>
            <DownloadFileCloud />
          </IconButton>
          <IconButton
            onClick={() => handleOnClickDeleteFile(item)}>
            <TrashIcon style={{ color: "#5C6477" }} />
          </IconButton>
        </div>
      </>,
    },
  ];

  const getUploadDate = (startDate: Date): string => {
    const date = new Date(startDate);

    const day = date.toLocaleString(localeApp, {
      day: "2-digit",
    });

    const month = date.toLocaleString(localeApp, {
      month: "long",
    }).toLowerCase();

    const year = date.toLocaleString(localeApp, {
      year: "numeric",
    });

    const time = date.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    if (localeApp === "es")
      return `${day}/${month}/${year} \n ${time} hrs`;
    else
      return `${month}/${day}/${year} \n ${time} hrs`;
  };

  const getExtenstionImage = (name: string) => {
    if (name) {
      let fileExtension = name.split(".").pop();
      switch (fileExtension) {
        case "pdf":
          return <div style={{ padding: 8 }}><PDFIcon width={16} style={{ color: "#5C6477" }}></PDFIcon></div>
        case "docx":
          return <WordIcon width={32} style={{ color: "#5C6477" }}></WordIcon>
        case "jpg":
          return <ImageIcon width={32} style={{ color: "#5C6477" }}></ImageIcon>
        case "png":
          return <ImageIcon width={32} style={{ color: "#5C6477" }}></ImageIcon>
        default:
          return <PDFIcon width={16} style={{ color: "#5C6477" }}></PDFIcon>
      }
    }
  };

  const handleDownloadFile = (file: EvolutionNoteClinicalFile) => {
    if (file.s3Key) {
      dispatch(navbarActions.setShowLoader(true));
      (async () => {
        const data = await CustomerService.getPatientClinicalFileUrl(file.s3Key);
        dispatch(navbarActions.setShowLoader(false));
        if (data.entity) {
          let fileUrl = data.entity.toString();
          const link = document.createElement("a");
          link.setAttribute("href", fileUrl);
          link.setAttribute("target", "_blank");
          document.body.appendChild(link);
          link.click();
          link.parentNode?.removeChild(link);
        }
      })();
    } else {
      const link = document.createElement("a");
      link.setAttribute("href", file.fileURL);
      link.setAttribute("target", "_blank");
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    }
  };

  const handleDeleteFile = () => {
    if (deletableFile?.s3Key) {
      dispatch(navbarActions.setShowLoader(true));
      (async () => {
        await CustomerService.deletePatientClinicalFile(deletableFile.s3Key);
        dispatch(navbarActions.setShowLoader(false));
      })();
    }
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        evolutionNote.clinicalFiles.map((file, index) => {
          if ((file.s3Key && file.s3Key === deletableFile?.s3Key) || (file.fileURL && file.fileURL === deletableFile?.fileURL)) {
            evolutionNote.clinicalFiles.splice(index, 1);
          }
        })
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
    dispatch(
      enqueueAlert({
        type: "Success",
        title: t("File successfully deleted"),
        description: deletableFile?.fileName,
      }));
    setDeletableFile(undefined);
    setDeleteFileModalIsOpen(false);
  };

  const handleOnClickDeleteFile = (file: EvolutionNoteClinicalFile) => {
    setDeletableFile(file);
    setDeleteFileModalIsOpen(true);
  };

  const CancelNewEvolutionNoteModalContent: React.FC = () => {
    return (<div className={classes.closeModalContentContainer}>
      <Typography className={classes.closeModalTitleText}>
        {t("Cancel new evolution note?")}
      </Typography>
      <div className={classes.separator} />
      <Typography className={classes.closeModalContentText}>
        {t("Changes made will not be saved")}
      </Typography>
    </div>);
  };

  const RemoveTreatmentModalContent: React.FC = () => {
    return (<div className={classes.removeModalContentContainer}>
      <Typography className={classes.closeModalContentHeaderText}>
        {t("Delete this treatment?", { treatment: treatmentTerm.toLowerCase() })}
      </Typography>
      <div className={classes.separator} />
      <Typography className={classes.closeModalContentText}>
        {treatmentForRemove?.medication}
      </Typography>
      <Typography className={classes.closeModalContentText2}>
        {treatmentForRemove?.prescription}
      </Typography>
    </div>);
  };

  const RemoveStudyModalContent: React.FC = () => {
    return (<div className={classes.removeModalContentContainer}>
      <Typography className={classes.closeModalContentHeaderText}>
        {t("Delete this study?")}
      </Typography>
      <div className={classes.separator} />
      <Typography className={classes.closeModalContentText}>
        {studyForRemove?.name}
      </Typography>
    </div>);
  };

  const RemoveFileModalContent: React.FC = () => {
    return (<div className={classes.removeModalContentContainer}>
      <Typography className={classes.closeModalContentHeaderText}>
        {t("Are you sure to delete the file?")}
      </Typography>
      <div className={classes.separator} />
      <Typography className={classes.closeModalContentText}>
        {deletableFile?.fileName}
      </Typography>
    </div>);
  };

  const onHandleChangeTreatment = (event: React.ChangeEvent<{ name: string, value: string }>, treatment: EvolutionNoteTreatmentInput, changed: "medication" | "prescription") => {
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined) {
          let editableTreatment = evolutionNote.treatments.find(x => x.order === treatment.order);
          if (editableTreatment) {
            if (changed === "medication") {
              editableTreatment.medication = event.target.value;
            } else {
              editableTreatment.prescription = event.target.value;
            }
          }

        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
  };

  async function handleSendPdfByWhatsAppClick(evolutionNoteId: string) {
    const response = await CustomerService.sendTreatmentPdfByWhatsApp(customerId, evolutionNoteId);

    if (response) {
      dispatch(enqueueAlert({
        type: "Success",
        title: t("Treatment successfully sent", { treatment: treatmentTerm }),
        description: customerName,
        link: {
          onClick: (e: any) => {
            e.preventDefault();
            history.push(`${Url.WhatsApp.Main}/${customerMobile}`);
          },
          title: t("Go to the conversation"),
        }
      }));
    }
  }

  async function handlePrintPdfClick(evolutionNoteId: string, appointmentDate: string) {
    const blob = await CustomerService.getTreatmentPdf(customerId, evolutionNoteId);
    const url = window.URL.createObjectURL(new Blob([blob], {
      type: "application/pdf"
    }));
    const link = document.createElement('a');
    link.href = url;
    const fileName = `${treatmentTerm}-${appointmentDate}.pdf`
    link.setAttribute('download', `${fileName}`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  const onHandleChangeStudy = (event: React.ChangeEvent<{ name: string, value: string }>, study: EvolutionNoteDynamicInput) => {
    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined) {
          let editableTreatment = evolutionNote.studies.find(x => x.order === study.order);
          if (editableTreatment) {
            editableTreatment.name = event.target.value;
          }

        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

    setEvolutionNotesGrouped(newEvolutionNotesGrouped);
  };

  const getFieldValueComponent = (valueModel: SectionFieldValueModel | null | undefined) => {
    if (valueModel?.value === null || valueModel?.value == undefined || valueModel?.value === "")
      return <Typography className={classes.fieldValueLabel}>
        {"--"}
      </Typography>
    switch (valueModel.type) {
      case SectionFieldType.Text:
        return <Typography className={classes.fieldValueLabel}>
          {valueModel.value as string}
        </Typography>
      case SectionFieldType.Email:
        return <Typography className={classes.fieldValueLabel}>
          {valueModel.value as string}
        </Typography>
      case SectionFieldType.Radio:
        return <Typography className={classes.fieldValueLabel}>
          {t((valueModel.value as FormRadioValue).item.value, { ns: sectionTNamespace })}
          {!!(valueModel.value as FormRadioValue).additionalFreeTextValue &&
            <> / {(valueModel.value as FormRadioValue).additionalFreeTextValue}</>
          }
        </Typography>
      case SectionFieldType.PhoneNumber: {
        const phoneNumberValue = valueModel.value as FormPhoneNumberValue;
        return <div className={classes.phoneNumberContainer}>
          <CountryFlagIcon iso={phoneNumberValue.countryIso} />
          <Typography className={classes.phoneNumberCodeLabel}>
            {"+" + accountSettings.countries.find(c => c.iso3 === phoneNumberValue.countryIso)!.dialCode}
          </Typography>
          <Typography className={classes.fieldValueLabel}>
            {conformToMask(phoneNumberValue.body,
              GetMobileMaskByTemp(
                accountSettings.countries.find(c => c.iso3 === phoneNumberValue.countryIso)!.dialTemplate)).conformedValue}
          </Typography>
        </div>
      }
    }
  };

  const getInputForEditMode = (fieldAndValueModel: PatientFormFieldAndValue) => {
    const sectionField = fieldAndValueModel.field;
    const valueField = fieldAndValueModel.value;
    if (!valueField?.value) {
      //return empty input for sectionField type
      switch (sectionField.type) {
        case SectionFieldType.Text:
          return getTextInput(sectionField);
        case SectionFieldType.Email:
          return getEmailInput(sectionField);
        case SectionFieldType.PhoneNumber:
          return getPhoneNumberInput(sectionField);
        case SectionFieldType.Radio:
          return getRadioInput(sectionField);
        default:
          return;
      }
    }

    if (sectionField.type !== valueField.type) {
      switch (sectionField.type) {
        case SectionFieldType.Text:
          switch (valueField.type) {
            case SectionFieldType.Email:
              //return TextInput with valueField.value
              return getTextInput(sectionField, valueField.value as string);
            case SectionFieldType.PhoneNumber:
              //return TextInput with PhoneNumber like "+52123456789"
              const phoneObj = valueField.value as FormPhoneNumberValue;
              const dialCode = accountSettings.countries.find(c => c.iso3 === phoneObj.countryIso)!.dialCode;
              const phoneAsString = `+${dialCode}${phoneObj.body}`
              return getTextInput(sectionField, phoneAsString);
            case SectionFieldType.Radio:
              //return TextInput with valueField.value.item.value
              const radioItemObj = valueField.value as FormRadioValue;
              return getTextInput(sectionField, radioItemObj.item.value);
          }
        case SectionFieldType.Email:
          //return empty input for Email type
          return getEmailInput(sectionField);
        case SectionFieldType.PhoneNumber:
          //return empty input for PhoneNumber type
          return getPhoneNumberInput(sectionField);
        case SectionFieldType.Radio:
          //return empty input for Radio type
          return getRadioInput(sectionField);
      }
    }

    //dealing with filled RadioInput
    if (sectionField.type === SectionFieldType.Radio && valueField.type === SectionFieldType.Radio) {
      if (sectionField.items
        .filter(i => !i.isDeleted)
        .map(i => i.id)
        .some(i => i === (valueField.value as FormRadioValue).item.fieldItemId)) {
        //return RadioInput with valueField.value.item checked
        return getRadioInput(sectionField, valueField.value as FormRadioValue);
      } else {
        //return RadioInput with FAKE valueField.value.item checked
        return getRadioInput(sectionField, valueField.value as FormRadioValue, true);
      }
    }

    //dealing with other filled field types
    switch (sectionField.type) {
      case SectionFieldType.Text:
        //return filled TextInput
        return getTextInput(sectionField, valueField.value as string);
      case SectionFieldType.Email:
        //return filled EmailInput
        return getEmailInput(sectionField, valueField.value as string);
      case SectionFieldType.PhoneNumber:
        //return filled PhoneNumberInput
        return getPhoneNumberInput(sectionField, valueField.value as FormPhoneNumberValue);
    }
  };

  const validatePhoneNumber = (iso3Code: string, phoneNumber: string) => {
    const numberOfDigits = accountSettings.countries?.find(c => c.iso3 === iso3Code)!.dialDigits;
    if (!numberOfDigits)
      return false;
    return (numberOfDigits === 7 && /^\d{7}$/.test(phoneNumber)) ||
      (numberOfDigits === 8 && /^\d{8}$/.test(phoneNumber)) ||
      (numberOfDigits === 9 && /^\d{9}$/.test(phoneNumber)) ||
      (numberOfDigits === 10 && /^\d{10}$/.test(phoneNumber)) ||
      (numberOfDigits === 11 && /^\d{11}$/.test(phoneNumber)) ||
      (numberOfDigits === 12 && /^\d{12}$/.test(phoneNumber));
  };

  const validateEmailField = (field: SectionFieldModel, value: string): boolean => {
    if (field.isRequired) {
      return EmailRegex.test(value) && value !== ""
    }
    return EmailRegex.test(value) || value === ""
  };

  const validateTextField = (field: SectionFieldModel, value: string): boolean => {
    const isValid = !(field.isRequired && value === "");
    return isValid;
  };

  const validateFieldsOnChange = (fieldsAndValues: PatientFormFieldAndValue[]) => {
    const isValid = !fieldsAndValues.some(fv => {
      if (fv.field.isRequired) {
        if (!fv.value?.value || fv.value?.value === "") {
          if (!fv.field.isDeleted) {
            return true;
          } else {
            return false;
          }
        }
        switch (fv.field.type) {
          case SectionFieldType.Text:
            if (!fv.value?.value || fv.value.value === "") {
              return true;
            }
            break;
          case SectionFieldType.Email:
            if (!fv.value?.value || fv.value.value === "" || !EmailRegex.test(fv.value!.value as string)) {
              return true;
            }
            break;
          case SectionFieldType.Radio:
            if (!fv.field.isDeleted && (fv.value?.value as FormRadioValue).item === null) {
              return true;
            }
            break;
          case SectionFieldType.PhoneNumber:
            const phoneNumber = (fv.value?.value as FormPhoneNumberValue);
            if (!fv.field.isDeleted && (phoneNumber.body === "" || !validatePhoneNumber(phoneNumber.countryIso, phoneNumber.body))) {
              return true;
            }
            break;
        }
      } else {
        switch (fv.field.type) {
          case SectionFieldType.Email:
            if (fv.value?.value && !EmailRegex.test(fv.value.value as string)) {
              return true;
            }
            break;
          case SectionFieldType.PhoneNumber:
            const phoneNumber = (fv.value?.value as FormPhoneNumberValue);
            if (phoneNumber && phoneNumber.body !== "" && !validatePhoneNumber(phoneNumber.countryIso, phoneNumber.body)) {
              return true;
            }
            break;
        }
      }

      return false;
    });

    setEvolutionNoteIsInvalid(!isValid);
  };

  const setFieldValue = (fieldId: string, value: string | FormPhoneNumberValue | FormRadioValue | null, additionalTextValue: string | null = null) => {
    if (!editableEvolutionNote?.vitalSigns)
      return;

    const fieldsAndValues = [...editableEvolutionNote.vitalSigns];
    const fieldAndValue = fieldsAndValues.find(f => f.field.id === fieldId);

    if (!fieldAndValue)
      return;

    //make value null if field is empty (need for compairing with old patient version and for proper storing)
    if (typeof value === "string" && value === "") {
      fieldAndValue.value = null;
      validateFieldsOnChange(fieldsAndValues);
      let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
        evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
          if (evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined) {
            evolutionNote.vitalSigns = fieldsAndValues;
          }
          return evolutionNote;
        })
        return evolutionNoteGrouped;
      });

      setEvolutionNotesGrouped(newEvolutionNotesGrouped);
      //setEditableEvolutionNote({ ...editableEvolutionNote, vitalSigns: fieldsAndValues })
      return;
    }

    if (!additionalTextValue) {
      if (!fieldAndValue.value) {
        fieldAndValue.value = new SectionFieldValueModel({ type: fieldAndValue.field.type, value: value })
      } else {
        fieldAndValue.value.type = fieldAndValue.field.type;
        fieldAndValue.value.value = value!;
      }
    }
    //if field is Radio and contain additional text value
    else if (additionalTextValue && fieldAndValue.field.type === SectionFieldType.Radio) {
      let newValue: FormRadioValue;
      //if this field value has already selected field item - just setting the argument value
      if (value && (value as FormRadioValue).item) {
        newValue = value as FormRadioValue;
      }
      //if not - we're selecting first item automatically
      else {
        const firstSelectItem = fieldAndValue.field.items.sort((i1, i2) => i1.order - i2.order)[0];
        const autoSelectedFirstItemValue = new FormFieldItemValue({ fieldItemId: firstSelectItem.id, value: firstSelectItem.name })
        newValue = new FormRadioValue();
        newValue.item = autoSelectedFirstItemValue;
        newValue.additionalFreeTextValue = additionalTextValue;
      }
      if (!fieldAndValue.value) {
        fieldAndValue.value = new SectionFieldValueModel({ type: fieldAndValue.field.type, value: newValue })
      } else {
        fieldAndValue.value.value = newValue!;
      }
    }

    let newEvolutionNotesGrouped = [...evolutionNotesGrouped].map(evolutionNoteGrouped => {
      evolutionNoteGrouped.evolutionNotes.map(evolutionNote => {
        if (evolutionNote.id === editableEvolutionNote?.id || evolutionNote.id === undefined) {
          evolutionNote.vitalSigns = fieldsAndValues;
        }
        return evolutionNote;
      })
      return evolutionNoteGrouped;
    });

    validateFieldsOnChange(fieldsAndValues);
    setEvolutionNotesGrouped(newEvolutionNotesGrouped);

    //setEditableEvolutionNote({ ...editableEvolutionNote, vitalSigns: fieldsAndValues })
  };

  const getTextInput = (field: SectionFieldModel, value: string | null = null) => {
    return <>
      <PatientFormTextInput
        key={field.id!}
        inputStyle={classes.inputText}
        value={value ? t(value, { ns: sectionTNamespace }) : ""}
        setValue={(v) => setFieldValue(field.id!, v)}
        label={t(field.name, { ns: sectionTNamespace })}
        isValid={(v) => validateTextField(field, v)}
        required={field.isRequired}
        placeholder={t(field.name, { ns: sectionTNamespace })}
        maxLength={100} />
    </>
  };

  const getEmailInput = (field: SectionFieldModel, value: string | null = null) => {
    return <>
      <PatientFormTextInput
        key={field.id!}
        value={value ? value : ""}
        setValue={(v) => setFieldValue(field.id!, v)}
        label={t(field.name, { ns: sectionTNamespace })}
        isValid={(v) => validateEmailField(field, v)}
        required={field.isRequired}
        placeholder={t(field.name, { ns: sectionTNamespace })}
        maxLength={100} />
    </>
  };

  const getPhoneNumberInput = (field: SectionFieldModel, value: FormPhoneNumberValue | null = null) => {
    return <>
      <PatientFormMobileInput
        key={field.id!}
        mobileNumber={value ?? new FormPhoneNumberValue({ countryIso: accountSettings.countryInSettings!.iso3, body: "" })}
        label={t(field.name, { ns: sectionTNamespace })}
        setMobileNumber={(v) => setFieldValue(field.id!, v)}
        required={field.isRequired}
        countryInSettings={accountSettings.countryInSettings!}
        countries={accountSettings.countries!} />
    </>
  };

  function setEvolutionNotesTextLengthOnInit(evt: any, editor: any) {
    setEvolutionNoteTextLength(editor.getContent({ format: "text" }).length);
  }
  function setDiagnosisTextLengthOnInit(evt: any, editor: any) {
    setDiagnosisTextLength(editor.getContent({ format: "text" }).length);
  }
  function setAdditionalInstructionsTextLengthOnInit(evt: any, editor: any) {
    setAdditionalInstructionsTextLength(editor.getContent({ format: "text" }).length);
  }

  const getRadioInput = (field: SectionFieldModel, value: FormRadioValue | null = null, isFakeRadioItem: boolean = false) => {
    const getSelectedItem = () => {
      if (!value && !isFakeRadioItem) {
        //Possibly QA team will ask for this (TODO: fix lack of real value in request)
        // if (field.isRequired) {
        //   //return first selected item
        //   return field.items.filter(i => !i.isDeleted).sort((i1, i2) => i1.order - i2.order)[0]
        // }
        // else {
        //   //return no selected item
        //   return null;
        // }
        return null;
      }
      ;
      if (value && !isFakeRadioItem)
        //return genuine selected item
        return field.items.filter(i => i.id === value.item.fieldItemId)[0];
      if (value && isFakeRadioItem)
        //return "fake"/old selected item
        return new SectionFieldItemModel({ id: value.item.fieldItemId, name: value.item.value, order: 0 });

      return null;
    };

    const setRadioValue = (fieldId: string, i: SectionFieldItemModel) => {
      const radioValue = new FormFieldItemValue();
      radioValue.fieldItemId = i.id!;
      radioValue.value = i.name;

      const itemModel = new FormRadioValue();
      itemModel.item = radioValue;
      itemModel.additionalFreeTextValue = value ? value.additionalFreeTextValue : ""
      setFieldValue(fieldId, itemModel);
    };

    return <>
      <PatientFormRadioGroup
        key={field.id!}
        field={field}
        value={value}
        selectedItem={getSelectedItem()}
        setSectionFieldItem={(i) => setRadioValue(field.id!, i)}
        setFreeTextValue={
          field.isAdditionalTextareaEnabled
            ? (av) => {
              setFieldValue(field.id!, new FormRadioValue({ item: value?.item, additionalFreeTextValue: av }), av)
            }
            : undefined}
      />
    </>
  };

  function renderTreatment(treatment: EvolutionNoteTreatmentInput) {
    return (
      <li className={classes.editModeBoldText}>
        <Typography className={classes.editModeBoldText}>
          {treatment.medication}
        </Typography>
        <Typography className={classes.editModeSimpleText}>
          {treatment.prescription}
        </Typography>
      </li>
    )
  }

  function renderTreatments(treatments: EvolutionNoteTreatmentInput[]) {
    return (
      <ol className={classes.editModeTreatmentList}>
        {treatments.sort((t1, t2) => t1.order! - t2.order!).map(renderTreatment)}
      </ol>
    );
  }

  return (
    <div className={classes.root}>
      <Button
        className={`${classes.addButton}`}
        onClick={handleAddNoteButton}
        disabled={editNoteMode}
      >
        <div className={classes.iconPlus}>
          <PlusIcon />
        </div>
        {t("Add note")}
      </Button>

      {!isLoading && evolutionNotesGrouped.length === 0 && <>
        <div className={classes.iconDiary}>
          <DiaryIcon />
          <Typography className={classes.diaryCaption}>
            {t("There are no evolution notes")}
          </Typography>
        </div>
      </>}

      {!isLoading && (evolutionNotesGrouped.length > 1 || evolutionNotesGrouped[0]?.evolutionNotes?.length > 0) && <>
        <div className={classes.customerHistoryContent}>
          {evolutionNotesGrouped?.sort((e1, e2) => e2.year! - e1.year! || e2.month! - e1.month!).map((evolutionNoteGrouped, indexEvolutionNote) =>
          (<Accordion key={indexEvolutionNote} defaultExpanded={true} className={classes.accordion}>
            <AccordionSummary
              expandIcon={<ArrowDownIcon style={{ height: 15, width: 15, color: Color.clear1 }} />}>
              <div className={classes.monthYear}>
                {t(GetMonthStringEnum(evolutionNoteGrouped.month - 1))} {evolutionNoteGrouped.year}
                <span className={classes.notesCountInMonth}>{" (" + evolutionNoteGrouped.evolutionNotes.length + ")"}</span>
              </div>
            </AccordionSummary>
            <AccordionDetails className={classes.accordionDetails}>
              <div className={classes.evolutionNoteForm}>
                {
                  evolutionNoteGrouped.evolutionNotes.sort(function (e1, e2) {
                    let noteDate1 = isNaN((new Date(e1.dateCreated).getTime()))
                      ? Number.MAX_VALUE
                      : new Date(e1.dateCreated).getTime();
                    let noteDate2 = new Date(e2.dateCreated).getTime();
                    return noteDate2 - noteDate1
                  }).map((evolutionNote, indexEvolutionNote) =>
                  (
                    evolutionNote.editMode
                      ?
                      <div key={evolutionNote.id} className={classes.evolutionNoteBox}>
                        <div className={classes.accordionHeader}>
                          <Typography className={classes.editEvolutionHeader}>
                            {t("New evolution note")}
                          </Typography>

                          <div className={classes.buttonArea}>
                            <Button
                              className={`${classes.cancelButton}`}
                              onClick={() => handleCancelButtonEvolutionNoteModal(evolutionNote)}>
                              {t("Cancel")}
                            </Button>

                            {evolutionNote.id
                              ? <Button
                                className={`${classes.saveButton}`}
                                startIcon={showLoaderIsActive ? <CircularProgress size="1rem" /> : undefined}
                                disabled={evolutionNoteIsInvalid || showLoaderIsActive!}
                                onDoubleClick={() => {}}
                                onClick={() => {handleUpdateNoteButton(evolutionNote)}}>
                                {t("Update")}
                              </Button>
                              : <Button
                                className={`${classes.saveButton}`}
                                startIcon={showLoaderIsActive ? <CircularProgress size="1rem" /> : undefined}
                                disabled={evolutionNoteIsInvalid || showLoaderIsActive!}
                                onDoubleClick={() => {}}
                                onClick={() => {handleSaveNoteButton(evolutionNote)}}>
                                {t("Save")}
                              </Button>}

                          </div>
                        </div>
                        <Typography className={classes.editorFieldHeader}>
                          {t("Evolution note *")}
                        </Typography>
                        <div className={`${classes.tinyMceOverrides} 
                                        ${evolutionNoteTextLength > maxEditorTxtLength || evolutionNoteInputErrorHighlight ? classes.tinyMceValidationError : <></>} 
                                        ${focusedEvolutionNoteInput ? classes.tinyMceFocused : classes.tinyMceUnfocused}`}>
                          <Editor
                            apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
                            init={{
                              height: 160,
                              width: "auto",
                              menubar: false,
                              statusbar: false,
                              placeholder: t("No notes"),
                              plugins: 'wordcount paste  lists',
                              toolbar: 'bold italic underline | numlist bullist ',
                              content_style: "body {fontFamily: Inter, sans-serif; font-weight: 400; font-size: 14px; color: #323947 }",
                              entity_encoding: "raw",
                              formats: {
                                bold: { inline: 'b' },
                                italic: { inline: 'i' },
                                underline: { inline: 'u' }
                              },
                              extended_valid_elements: "b, i, s",
                              invalid_elements: "strong, em, span"
                            }}
                            value={evolutionNote.evolutionNoteText ?? ""}
                            onInit={setEvolutionNotesTextLengthOnInit}
                            onFocusIn={() => {
                              setFocusedEvolutionNoteInput(true)
                            }}
                            onBlur={(ev, cont) => {
                              handleEvolutionNoteEditorOnBlur(ev, cont)
                            }}
                            onKeyDown={(e) => {
                                setEvolutionNoteInputErrorHighlight(false);
                            }}
                            onEditorChange={handleEditorEvolutionNoteTextChange}
                            outputFormat="html"
                          />
                          <div className={classes.counterWrapper}>
                            <span className={classes.charCounter}>{evolutionNoteTextLength + "/" + maxEditorTxtLength}</span>
                          </div>
                        </div>
                        <Typography className={classes.editorFieldHeader}>
                          {t("Diagnosis")}
                        </Typography>
                        <div className={`${classes.tinyMceOverrides} 
                                        ${diagnosisTextLength > maxEditorTxtLength ? classes.tinyMceValidationError : <></>} 
                                        ${focusedDiagnosisInput ? classes.tinyMceFocused : classes.tinyMceUnfocused}`}>
                          <Editor
                            apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
                            init={{
                              height: 160,
                              width: "auto",
                              menubar: false,
                              statusbar: false,
                              placeholder: t("No notes"),
                              plugins: 'wordcount paste  lists',
                              toolbar: 'bold italic underline | numlist bullist ',
                              content_style: "body {fontFamily: Inter, sans-serif; font-weight: 400; font-size: 14px; color: #323947 }",
                              entity_encoding: "raw",
                              formats: {
                                bold: { inline: 'b' },
                                italic: { inline: 'i' },
                                underline: { inline: 'u' }
                              },
                              extended_valid_elements: "b, i, s",
                              invalid_elements: "strong, em, span",
                            }}

                            value={evolutionNote.diagnosis ?? ""}
                            onFocusIn={() => {
                              setFocusedDiagnosisInput(true)
                            }}
                            onBlur={() => {
                              setFocusedDiagnosisInput(false)
                            }}
                            onInit={setDiagnosisTextLengthOnInit}
                            onEditorChange={handleEditorDiagnosisChange}
                            outputFormat="html"
                          />
                          <div className={classes.counterWrapper}>
                            <span className={classes.charCounter}>{diagnosisTextLength + "/" + maxEditorTxtLength}</span>
                          </div>
                        </div>

                        <Typography className={classes.editorFieldHeader}>
                          {treatmentTerm}
                        </Typography>
                        {evolutionNote.treatments.sort((t1, t2) => t1.order! - t2.order!).map((treatment, index, treatments) => {
                          return (
                            <div className={classes.treatmentRow}>
                              <div className={classes.treatmentInputContainer}>
                                <Typography className={classes.treatmentFieldHeader}>
                                  {t("Medication")}
                                </Typography>
                                <Input
                                  inputClass={classes.treatmentNameInput}
                                  value={treatment.medication}
                                  maxLength={500}
                                  width={"100%"}
                                  onChange={(e) => onHandleChangeTreatment(e, treatment, "medication")}
                                />
                              </div>
                              <div className={classes.treatmentInputContainer}>
                                <Typography className={classes.treatmentFieldHeader}>
                                  {t("Prescription")}
                                </Typography>
                                <Input
                                  inputClass={classes.treatmentNameInput}
                                  value={treatment.prescription}
                                  maxLength={500}
                                  width={"100%"}
                                  onChange={(e) => onHandleChangeTreatment(e, treatment, "prescription")}
                                />
                              </div>
                              {index + 1 === treatments.length &&
                                <IconButton
                                  disableRipple
                                  className={`${classes.addTreatmentButton}`}
                                  onClick={handleAddTreatmentClick}
                                >
                                  <div className={classes.iconPlus}>
                                    <PlusIcon style={{ color: "#36CE91", height: 20, width: 20 }} />
                                  </div>
                                </IconButton>}
                              {treatments.length > 1 &&
                                <IconButton
                                  disableRipple
                                  className={`${classes.addTreatmentButton}`}
                                  onClick={() => {
                                    setTreatmentForRemove(treatment)
                                    setRemoveTreatmentModalIsOpen(true)
                                  }}
                                >
                                  <div className={classes.iconRemove}>
                                    <DeleteIcon style={{ color: "#36CE91" }} />
                                  </div>
                                </IconButton>}
                              <ConfirmDeleteModal
                                textButtonConfirm={t("Delete")}
                                classModal={classes.closeConfirmationModal}
                                open={removeTreatmentModalIsOpen}
                                item={treatment}
                                componentInfo={<RemoveTreatmentModalContent />}
                                onClose={() => {
                                  setTreatmentForRemove(undefined)
                                  setRemoveTreatmentModalIsOpen(false)
                                }}
                                onDelete={handleOnRemoveTreatmentConfirm}
                              />
                            </div>
                          )
                        })}

                        <Typography className={classes.editorFieldHeader}>
                          {t("Additional specialist instructions")}
                        </Typography>
                        <div className={`${classes.tinyMceOverrides} 
                                        ${additionalInstructionsTextLength > maxEditorTxtLength ? classes.tinyMceValidationError : <></>} 
                                        ${focusedAdditionalInstructionsInput ? classes.tinyMceFocused : classes.tinyMceUnfocused}`}>
                          <Editor
                            apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
                            init={{
                              height: 160,
                              width: "auto",
                              menubar: false,
                              statusbar: false,
                              placeholder: t("Enter any additional instructions here"),
                              plugins: 'wordcount paste  lists',
                              toolbar: 'bold italic underline | numlist bullist ',
                              content_style: "body {fontFamily: Inter, sans-serif; font-weight: 400; font-size: 14px; color: #323947 }",
                              entity_encoding: "raw",
                              formats: {
                                bold: { inline: 'b' },
                                italic: { inline: 'i' },
                                underline: { inline: 'u' }
                              },
                              extended_valid_elements: "b, i, s",
                              invalid_elements: "strong, em, span",
                            }}

                            value={evolutionNote.additionalInstructions ?? ""}
                            onFocusIn={() => {
                              setFocusedAdditionalInstructionsInput(true)
                            }}
                            onBlur={() => {
                              setFocusedAdditionalInstructionsInput(false)
                            }}
                            onInit={setAdditionalInstructionsTextLengthOnInit}
                            onEditorChange={handleEditorAdditionalInstructionsChange}
                            outputFormat="html"
                          />
                          <div className={classes.counterWrapper}>
                            <span className={classes.charCounter}>{additionalInstructionsTextLength + "/" + maxEditorTxtLength}</span>
                          </div>
                        </div>

                        <Typography className={classes.editorFieldHeader}>
                          {t("Studies")}
                        </Typography>
                        <Typography className={classes.carryOutTitle}>
                          {t("Carry out:")}
                        </Typography>
                        {evolutionNote.studies.sort((t1, t2) => t1.order! - t2.order!).map((study, index, studies) => {
                          return <div>
                            <Input
                              inputClass={classes.treatmentNameInput}
                              maxLength={600}
                              value={study.name}
                              width={"87%"}
                              onChange={(e) => onHandleChangeStudy(e, study)}
                            />
                            {index + 1 === studies.length &&
                              <IconButton
                                disableRipple
                                className={`${classes.addTreatmentButton}`}
                                onClick={handleAddStudyClick}
                              >
                                <div className={classes.iconPlus}>
                                  <PlusIcon style={{ color: "#36CE91", height: 20, width: 20 }} />
                                </div>
                              </IconButton>}
                            {studies.length > 1 &&
                              <IconButton
                                disableRipple
                                className={`${classes.addTreatmentButton}`}
                                onClick={() => {
                                  setStudyForRemove(study)
                                  setRemoveStudyModalIsOpen(true)
                                }}
                              >
                                <div className={classes.iconRemove}>
                                  <DeleteIcon style={{ color: "#36CE91" }} />
                                </div>
                              </IconButton>}
                            <ConfirmDeleteModal
                              textButtonConfirm={t("Yes, delete")}
                              classModal={classes.closeConfirmationModal}
                              open={removeStudyModalIsOpen}
                              item={study}
                              componentInfo={<RemoveStudyModalContent />}
                              onClose={() => {
                                setStudyForRemove(undefined)
                                setRemoveStudyModalIsOpen(false)
                              }}
                              onDelete={handleOnRemoveStudyConfirm}
                            />
                          </div>
                        })}
                        <div className={classes.longSeparator} />
                        <Typography className={classes.editEvolutionHeader} style={{ marginBottom: 32 }}>
                          {t(vitalSignSectionName, { ns: sectionTNamespace })}
                        </Typography>
                        {evolutionNote.vitalSigns
                          .sort((f1, f2) => f1.field.order - f2.field.order)
                          .map(f => {
                            if (f.field.isDeleted && f.value === null)
                              return;
                            else {
                              const fieldAndValue = editableEvolutionNote?.vitalSigns.find(fv => fv.field.id === f.field.id);
                              return <div className={classes.fieldEditionContainer}>
                                {getInputForEditMode(fieldAndValue!)}
                              </div>
                            }
                          })}

                        <div className={classes.longSeparator} />
                        <Typography className={classes.fileHeader}>
                          {t("Files")}
                        </Typography>

                        <Button
                          className={`${classes.dropZone}`}
                          onClick={() => setImportModalIsOpen(true)}
                        >
                          <div className={classes.uploadFileCloud}>
                            <UploadFileCloud />
                          </div>
                          <div style={{ marginTop: 39 }}>
                            {t("Click here to upload your file")}
                          </div>
                        </Button>


                        {evolutionNote.clinicalFiles.length > 0 &&
                          <div className={classes.grid}>
                            <GridListWithoutPagination
                              columns={filesColumns}
                              items={evolutionNote.clinicalFiles.sort(function (f1, f2) {
                                let fileDate1 = isNaN((new Date(f1.created).getTime()))
                                  ? Number.MAX_VALUE
                                  : new Date(f1.created).getTime();
                                let fileDate2 = new Date(f2.created).getTime();
                                return fileDate2 - fileDate1
                              })}
                              height={"auto"}
                              classRow={classes.classRow}
                            />
                          </div>
                        }


                      </div>
                      :
                      <div>
                        <div className={classes.editModeNoteHeaderContainer}>
                          <div className={classes.editModeNoteHeaderContainerChild}>
                            <div>
                              <Typography className={classes.editModeNoteFieldSmall}>
                                {t("User")}
                              </Typography>
                              <Typography className={classes.editModeNoteFieldBlue}>
                                {evolutionNote.user}
                              </Typography>
                            </div>
                            <div>
                              <Typography className={classes.editModeNoteFieldSmall}>
                                {t("Date and time of the appointment")}
                              </Typography>
                              <Typography className={classes.editModeNoteFieldBlue}>
                                {date(evolutionNote.appointmentDate)}
                              </Typography>
                            </div>
                            <div>
                              <Typography className={classes.editModeNoteFieldSmall}>
                                {t("Date and time of the note")}
                                <IconTooltip
                                  classNameRoot={classes.iconTooltipRoot}
                                  icon={<InfoIcon style={{ width: 11, height: 11, marginLeft: 4 }} viewBox={"0 0 11 11"} />}
                                  tooltipText={t("Date and time of the creation of the evolution note.")}
                                />
                              </Typography>
                              <Typography className={classes.editModeNoteFieldBlue}>
                                {date(evolutionNote.dateCreated)}
                              </Typography>
                            </div>
                          </div>
                          <div>
                            <Button
                              className={`${classes.editButton}`}
                              disabled={editNoteMode}
                              onClick={() => handleEditNoteButton(evolutionNote)}>
                              {t("Edit note")}
                            </Button>
                          </div>
                        </div>
                        <div className={classes.editModeSeparator} />
                        <div style={{ padding: "0px 16px" }}>
                          <div style={{ marginBottom: 24 }}>
                            <Typography className={classes.editModeTitle}>
                              {t("Evolution note")}
                            </Typography>
                            <Typography className={classes.editModeSimpleText}>
                              <div className={classes.editorContent} dangerouslySetInnerHTML={{ __html: evolutionNote.evolutionNoteText }} />
                            </Typography>
                          </div>
                          {evolutionNote.diagnosis && <div style={{ marginBottom: 24 }}>
                            <Typography className={classes.editModeTitle}>
                              {t("Diagnosis")}
                            </Typography>
                            <Typography className={classes.editModeSimpleText}>
                              <div className={classes.editorContent} dangerouslySetInnerHTML={{ __html: evolutionNote.diagnosis }} />
                            </Typography>
                          </div>}
                          {evolutionNote.treatments.length > 0 && <div style={{ marginBottom: 24 }}>
                            <Typography className={classes.editModeTitle}>
                              {treatmentTerm}
                            </Typography>
                            {renderTreatments(evolutionNote.treatments)}
                          </div>}
                          {evolutionNote.additionalInstructions && <div style={{ marginBottom: 24 }}>
                            <Typography className={classes.editModeTitle}>
                              {t("Additional specialist instructions")}
                            </Typography>
                            <Typography className={classes.editModeSimpleText}>
                              <div className={classes.editorContent} dangerouslySetInnerHTML={{ __html: evolutionNote.additionalInstructions }} />
                            </Typography>
                          </div>}
                          {evolutionNote.treatments.length > 0 &&
                            <div className={classes.pdfButtonsBlock}>
                              <Button
                                className={classes.pdfButton}
                                onClick={() => handleSendPdfByWhatsAppClick(evolutionNote.id)}
                              >
                                {t("Send PDF by WhatsApp")}
                              </Button>
                              <Button
                                className={classes.pdfButton}
                                onClick={() => handlePrintPdfClick(evolutionNote.id, fileDate(evolutionNote.appointmentDate ?? evolutionNote.dateCreated))}
                              >
                                {t("Print PDF")}
                              </Button>
                            </div>
                          }
                          {evolutionNote.studies.length > 0 &&
                            <div style={{ marginBottom: 24 }}>
                              <Typography className={classes.editModeTitle}>
                                {t("Studies")}
                              </Typography>
                              {evolutionNote.studies.sort((t1, t2) => t1.order! - t2.order!).map((study) => {
                                return <div>
                                  <Typography className={classes.editModeSimpleText}>
                                    {study.name}
                                  </Typography>
                                </div>
                              })}
                            </div>
                          }
                        </div>
                        {!evolutionNote.vitalSigns.filter(x => x.value).every(x => x.value === null) && <>
                          <div className={classes.editModeSeparator} />
                          <div style={{ padding: "0px 16px" }}>
                            <Typography className={classes.editModeTitle} style={{ marginBottom: 6 }}>
                              {t(vitalSignSectionName, { ns: sectionTNamespace })}
                            </Typography>
                            <div className={classes.fieldsContainer}>
                              {evolutionNote.vitalSigns
                                .sort((f1, f2) => f1.field.order - f2.field.order)
                                .map((f, index) => {
                                  if (f.field.isDeleted && !f.value?.value)
                                    return;
                                  else
                                    return <div className={classes.fieldContainer}>
                                      <Typography className={classes.fieldNameLabel}>
                                        {t(f.field.name, { ns: sectionTNamespace })} <span className={classes.fieldNameAsterisk}>{f.field.isRequired ? "*" : ""}</span>
                                      </Typography>
                                      {getFieldValueComponent(f.value)}

                                    </div>
                                })}
                            </div>
                          </div>
                        </>}

                        {evolutionNote.clinicalFiles.length > 0 && <>
                          <div className={classes.editModeSeparator} />
                          <div style={{ padding: "0px 16px" }}>
                            <Typography className={classes.editModeTitle}>
                              {t("Files")}
                            </Typography>
                          </div>

                          <div className={classes.gridShowMode}>
                            <GridListWithoutPagination
                              columns={filesColumns}
                              items={evolutionNote.clinicalFiles.sort(function (f1, f2) {
                                let fileDate1 = isNaN((new Date(f1.created).getTime()))
                                  ? Number.MAX_VALUE
                                  : new Date(f1.created).getTime();
                                let fileDate2 = new Date(f2.created).getTime();
                                return fileDate2 - fileDate1
                              })}
                              height={"auto"}
                              classRow={classes.classRow}
                            />
                          </div>
                        </>
                        }
                        {indexEvolutionNote === evolutionNoteGrouped.evolutionNotes.length - 1
                          ? <></>
                          : <div className={classes.longSeparator} />}
                      </div>))
                }
              </div>
            </AccordionDetails>
          </Accordion>)
          )}
        </div>
      </>}
      <ConfirmActionModal
        classModal={classes.cancelEvolutionNoteConfirmationModal}
        content={<CancelNewEvolutionNoteModalContent />}
        open={cancelEvolutionNoteModalIsOpen}
        onClose={() => setCancelEvolutionNoteModalIsOpen(false)}
        onConfirm={handleOnConfirmCancelEvolutionNote}
      />
      <PatientImportEvolutionNoteClinicalFileModal
        open={importModalIsOpen}
        evolutionNote={editableEvolutionNote}
        onClose={() => setImportModalIsOpen(false)}
      ></PatientImportEvolutionNoteClinicalFileModal>
      <ConfirmDeleteModal
        textButtonConfirm={t("Delete")}
        classModal={classes.closeConfirmationModal}
        open={deleteFileModalIsOpen}
        item={deletableFile}
        componentInfo={<RemoveFileModalContent />}
        onClose={() => {
          setDeleteFileModalIsOpen(false)
          setDeletableFile(undefined);
        }}
        onDelete={handleDeleteFile}
      />
    </div>
  );
};

export default PatientEvolutionNotes;