import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../../../redux/hooks";
import { alertsActions } from "../../../../../redux/alerts-slice";

import { SectionProps } from "./props";
import useStyles from "./css";

import { SettingsPatientFormsService } from "../../../../../api/settings-patient-forms-service";
import { SectionFieldCreateRequest } from "../../../../../models/section-field-create-request";
import { SectionFieldType } from "../../../../../models/enums/section-field-type";
import { SectionFieldItemModel } from "../../../../../models/section-field-item-model";
import { SectionFieldUpdateRequest } from "../../../../../models/section-field-update-request";
import { SectionModel } from "../../../../../models/section-model";
import { SectionType } from "../../../../../models/enums/section-type";

import { Box, Button, Grid, Typography } from "@material-ui/core";
import { SectionArrowIcon, SectionDeleteIcon, SectionEditIcon, SectionExitEditIcon, SectionFieldNewIcon, SectionSaveIcon } from "../../../../../assets/icons";
import FieldList from "../FieldList";
import ConfirmDeleteModal from "../../../../common/ConfirmDeleteModal";
import SectionFieldModal from "../SectionFieldModal";
import { SectionFieldModel } from "../../../../../models/section-field-model";
import Input from "../../../../common/Input";
import { SectionFieldDeleteRequest } from "../../../../../models/section-field-delete-request";


export default function Section(props: SectionProps) {
    const sectionTNamespace = "sections";
    const maxTextFieldLength = 200;
    const { t } = useTranslation(["general", sectionTNamespace]);
    const { accountId, section, sections, setSection, onEdit, onDelete, onMoveDown, onMoveUp } = props;
    const classes = useStyles();

    const dispatch = useAppDispatch();
    const { enqueueAlert } = alertsActions;

    const sectionInputRef = useRef<HTMLInputElement>();

    const [deleteSectionConfirmationModalIsOpen, setDeleteSectionConfirmationModalIsOpen] = useState<boolean>(false);
    const [sectionFieldModalIsOpen, setSectionFieldModalIsOpen] = useState<boolean>(false);
    const [headerHover, setHeaderHover] = useState<boolean>(false);
    const [deleteFieldConfirmationModalIsOpen, setDeleteFieldConfirmationModalIsOpen] = useState<boolean>(false);

    const [fieldMode, setFieldMode] = useState<"new" | "edit">("new");
    const [isSectionEditMode, setSectionEditMode] = useState<boolean>(false);

    const [modalField, setModalField] = useState<SectionFieldModel | undefined>(undefined);
    const [deleteModalField, setDeleteModalField] = useState<SectionFieldModel | undefined>(undefined);

    const [name, setName] = useState<string>(section.name!);

    useEffect(() => {
        if (section.name)
            setName(section.name);
    }, [section]);

    useEffect(() => {
        if (isSectionEditMode)
            sectionInputRef?.current?.focus();
    }, [isSectionEditMode]);

    const handleEditButtonClick = () => {
        setSectionEditMode(true);
    }

    const handleDeleteSection = (section: SectionModel) => {
        setDeleteSectionConfirmationModalIsOpen(false);
        if (onDelete)
            onDelete(section);
    }

    const handleNewFieldButtonClick = () => {
        setFieldMode("new");
        setModalField(undefined);
        setSectionFieldModalIsOpen(true);
    };

    const handleEditFieldClick = (fieldId: string) => {
        const selectedField = section.fields.filter(f => f.id === fieldId)[0];
        setFieldMode("edit");
        setModalField(selectedField);
        setSectionFieldModalIsOpen(true);
    };

    const handleDeleteFieldClick = (fieldId: string) => {
        const fieldToDelete = section.fields.filter(f => f.id === fieldId)[0];
        setDeleteModalField(fieldToDelete);
        setDeleteFieldConfirmationModalIsOpen(true);
    }

    const handleOnChange = (event: React.ChangeEvent<{ value: string }>) => {
        const name = event.target.value;
        if (name.length > maxTextFieldLength) {
            return;
        }
        setName(name);
    };

    const handleEditSection = async () => {
        if (!onEdit)
            return;
        setSectionEditMode(false);
        if (section.name === name.trim() || name.trim().length === 0) {
            setName(section.name!);
            return;
        }
        section.name = name;
        await onEdit(section);
    };

    const handleSectionFieldSave = async (newField: SectionFieldModel, sectionIdToSave: string) => {
        if (!sections || !setSection)
            return;

        const sectionToSave = sections.filter(s => s.id === sectionIdToSave)[0];
        
        if (!sectionToSave)
            return;

        let sectionFields = [...sectionToSave.fields];

        if (fieldMode === "new") {
            const response = await SettingsPatientFormsService.createSectionField(new SectionFieldCreateRequest({
                establishmentAccountId: accountId,
                sectionId: sectionToSave.id,
                name: newField.name,
                type: newField.type,
                isRequired: newField.isRequired,
                isAdditionalTextareaEnabled: newField.type === SectionFieldType.Radio
                    ? newField.isAdditionalTextareaEnabled
                    : false,
                itemsNames: newField.items.sort((i1, i2) => i1.order - i2.order).map(i => i.name)
            }));

            if (!response)
                return;

            const afterResponseField = { ...newField }
            afterResponseField.id = response.id
            afterResponseField.order = sectionToSave.fields.length + 1;
            afterResponseField.isAdditionalTextareaEnabled = afterResponseField.type === SectionFieldType.Radio
                ? afterResponseField.isAdditionalTextareaEnabled
                : false;

            for (let i = 0; i < response.fieldItemIds.length; i++) {
                afterResponseField.items[i].id = response.fieldItemIds[i];
            }

            sectionFields.push(afterResponseField);
        }
        else {
            const response = await SettingsPatientFormsService.updateSectionField(new SectionFieldUpdateRequest({
                establishmentAccountId: accountId,
                id: newField.id,
                sectionId: sectionToSave.id,
                name: newField.name,
                type: newField.type,
                isRequired: newField.isRequired,
                isAdditionalTextareaEnabled: newField.type === SectionFieldType.Radio
                    ? newField.isAdditionalTextareaEnabled
                    : false,
                items: newField.items.sort((i1, i2) => i1.order - i2.order).map(i => new SectionFieldItemModel({
                    id: i.id?.length === 36 ? i.id : null, //is guid?
                    name: i.name,
                    order: i.order
                }))
            }));

            if (!response)
                return;

            for (let i = 0; i < response.fieldItemIds.length; i++) {
                newField.items[i].id = response.fieldItemIds[i];
            }

            newField.isAdditionalTextareaEnabled = newField.type === SectionFieldType.Radio
                ? newField.isAdditionalTextareaEnabled
                : false;
            const fieldIndexToUpdate = sectionFields.findIndex(i => i.id === newField.id);
            sectionFields[fieldIndexToUpdate] = newField;
        }

        dispatch(
            enqueueAlert({
                type: "Success",
                title: fieldMode === "new" ? t("Item created") : t("Item edited"),
                description: t(newField.name, { ns: sectionTNamespace })
            })
        );

        sectionFields = sectionFields.sort((f1, f2) => f1.order - f2.order)
        setSection({ ...sectionToSave, fields: sectionFields })
    }

    const handleSectionFieldDelete = async (field: SectionFieldModel) => {
        if (!setSection)
            return;
        
        const sectionFields = [...section.fields];
        const fieldIndexToDelete = sectionFields.findIndex(i => i.id === field.id);

        if (fieldIndexToDelete === -1)
            return;

        const isSuccessful = await SettingsPatientFormsService.deleteSectionField(new SectionFieldDeleteRequest({
            establishmentAccountId: accountId,
            id: field.id,
            sectionId: section.id,
            order: field.order
        }));

        if (!isSuccessful)
            return;

        dispatch(
            enqueueAlert({
                type: "Success",
                title: t("Item deleted"),
                description: t(field.name, { ns: sectionTNamespace })
            })
        );

        let updatedSectionFields = sectionFields.filter((_, i) => i !== fieldIndexToDelete);
        updatedSectionFields = updatedSectionFields.sort((f1, f2) => f1.order - f2.order)
        setSection({ ...section, fields: updatedSectionFields })

        setDeleteModalField(undefined);
        setDeleteFieldConfirmationModalIsOpen(false);
    }

    const handleExitEdit = () => {
        setName(section.name!);
        setSectionEditMode(false);
    }

    const getTabName = (sectionType: SectionType) => {
        switch (sectionType) {
            case SectionType.ClinicHistory:
                return t("of the Clinic history");
            case SectionType.EvolutionNoteForm:
                return t("of the Evolution notes");
            case SectionType.PatientForm:
                return t("of the Patient form");
        }
    }

    const getEditButton = (section: SectionModel) => {
        if (section.isEditable)
            return (<Button
                key={"editButton"}
                onClick={() => handleEditButtonClick()}
                className={classes.headerButton}
            >
                <SectionEditIcon />
            </Button>);
        return <></>;
    };

    const getSaveButton = (section: SectionModel) => {
        if (section.isEditable && isSectionEditMode)
            return (<Button
                key={"saveButton"}
                onClick={async () => await handleEditSection()}
                className={classes.headerButton}
            >
                <SectionSaveIcon />
            </Button>);
        return <></>;
    };

    const getExitEditButton = (section: SectionModel) => {
        if (section.isEditable && isSectionEditMode)
            return (<Button
                onMouseDown={() => handleExitEdit()}
                className={classes.headerButton}
            >
                <SectionExitEditIcon />
            </Button>);
        return <></>;
    };

    const getDeleteSectionButton = (section: SectionModel) => {
        if (section.isRemovable && section.type !== SectionType.EvolutionNoteForm)
            return (<Button
                onClick={() => setDeleteSectionConfirmationModalIsOpen(true)}
                className={classes.headerButton}
            >
                <SectionDeleteIcon />
            </Button>);
        return <></>;
    };

    const getMoveDownButton = (section: SectionModel) => {
        if (onMoveDown && section.type !== SectionType.EvolutionNoteForm) {
            const isDisabled = section.order === sections?.length ?? 0;
            return (<Button
                onClick={() => onMoveDown && onMoveDown()}
                disabled={section.order === sections?.length ?? 0}
                className={isDisabled ? classes.headerButtonDisabled : classes.headerButton}
            >
                <SectionArrowIcon />
            </Button>);
        }
        return <></>;
    };

    const getMoveUpButton = (section: SectionModel) => {
        if (onMoveUp && section.type !== SectionType.EvolutionNoteForm) {
            const isDisabled = section.order === 1;
            return (<Button
                onClick={() => onMoveUp && onMoveUp()}
                disabled={isDisabled}
                className={isDisabled ? classes.headerButtonDisabled : classes.headerButton}
            >
                <SectionArrowIcon style={{ transform: "rotate(180deg)" }} />
            </Button>);
        }
        return <></>;
    };

    const getHeaderButtons = (section: SectionModel) => {
        if (isSectionEditMode) {
            return (
                <>
                    {getSaveButton(section)}
                    {getExitEditButton(section)}
                </>
            );
        }
        return (
            <>
                {getEditButton(section)}
                {getDeleteSectionButton(section)}
                {getMoveDownButton(section)}
                {getMoveUpButton(section)}
            </>
        );
    };

    const getSectionFooterComponent = (section: SectionModel) => {
        if (section.isEditable) {
            return <Grid item className={classes.footerContainer}>
            <Button
                className={`${classes.newButton}`}
                onClick={() => { handleNewFieldButtonClick() }}
            >
                <SectionFieldNewIcon style={{ paddingRight: 5 }} />
                {t('New item')}
            </Button>
        </Grid>
        }

        return <></>
    }
    
    const getSectionContent = (section: SectionModel) => {
        if (section.fields.length === 0)
            return (
                <div className={classes.noItemsBox}>
                    <Typography className={classes.noItemsLabel}>
                        {t("Section without items")}
                    </Typography>
                </div>);
        else
            return (<FieldList
                accountId={accountId}
                section={section}
                onEditClick={handleEditFieldClick}
                onDeleteClick={handleDeleteFieldClick}
            />)
    };

    const DeleteSectionModalContent: React.FC = () => {
        return (<div className={classes.deleteModalContentContainer}>
            <Typography className={classes.deleteModalTitleText}>
                {t("Delete this section") + " " + getTabName(section.type!) + "?"}
            </Typography>
            <div className={classes.separator} />
            <Typography className={classes.deleteModalContentBoldText}>
                {t(section.name!, { ns: sectionTNamespace })}
            </Typography>
        </div>);
    };

    const DeleteFieldModalContent: React.FC = () => {
        return (<div className={classes.deleteModalContentContainer}>
            <Typography className={classes.deleteModalTitleText}>
                {t("Delete this item?")}
            </Typography>
            <div className={classes.separator} />
            <Typography className={classes.deleteModalContentText}>
                {t("Delete the") + " "}
                <span className={classes.deleteModalContentBoldText}>
                    {"\"" + t(deleteModalField?.name ?? "", { ns: sectionTNamespace }) + "\""}
                </span>
                {" " + t("item from the") + " "}
                <span className={classes.deleteModalContentBoldText}>
                    {"\"" + t(section.name!, { ns: sectionTNamespace }) + "\"" + "?"}
                </span>
            </Typography>
        </div>);
    };

    return <>
        <div className={classes.root}>
            <Box>
                <Grid container justifyContent="center" className={classes.sectionContainer}>
                    <Grid item
                        className={classes.headerContainer}
                        onMouseEnter={() => { setHeaderHover(true) }}
                        onMouseLeave={() => { setHeaderHover(false) }} >
                        {!isSectionEditMode
                            ? <Typography className={classes.headerTitle}>
                                {t(section.name!, { ns: sectionTNamespace })}
                            </Typography>

                            : <Input
                                inputClass={classes.sectionEditNameInput}
                                value={t(name, { ns: sectionTNamespace })}
                                isValid={() => { return name.trim().length > 0 && name.length <= 200 }}
                                onChange={(event) => handleOnChange(event)}
                                onBlur={async () => await handleEditSection()}
                                disabled={!isSectionEditMode || !section.isEditable}
                                maxLength={maxTextFieldLength}
                                width={"91%"}
                                ref={sectionInputRef}
                                autoFocus
                            />}
                        {headerHover || isSectionEditMode
                            ? <div className={classes.headerButtonsContainer}>
                                {getHeaderButtons(section)}
                            </div>
                            : <></>}
                    </Grid>
                    <div className={classes.innerLayout}>
                        <Grid item className={classes.fieldsContainer}>
                            {getSectionContent(section)}
                        </Grid>
                        {getSectionFooterComponent(section)}
                    </div>
                </Grid>
            </Box>
        </div>
        <ConfirmDeleteModal
            classModal={classes.deleteConfirmationModal}
            open={deleteSectionConfirmationModalIsOpen}
            item={section}
            componentInfo={<DeleteSectionModalContent />}
            onClose={() => setDeleteSectionConfirmationModalIsOpen(false)}
            onDelete={() => handleDeleteSection(section)}
        />
        <ConfirmDeleteModal
            classModal={classes.deleteConfirmationModal}
            open={deleteFieldConfirmationModalIsOpen}
            item={deleteModalField}
            componentInfo={<DeleteFieldModalContent />}
            onClose={() => setDeleteFieldConfirmationModalIsOpen(false)}
            onDelete={async () => await handleSectionFieldDelete(deleteModalField!)}
        />
        <SectionFieldModal
            isOpen={sectionFieldModalIsOpen}
            setOpen={setSectionFieldModalIsOpen}
            mode={fieldMode}
            field={modalField}
            section={section}
            sections={sections ?? []}
            onSave={async (newField, sectionIdToSave) => await handleSectionFieldSave(newField, sectionIdToSave)} />
    </>

};