import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ListIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={style || { width: 21, height: 17 }}
      viewBox={viewBox || "0 0 21 17"}
    >
      <path d="M5.96094 5.23077C5.96094 4.69952 5.39697 4.25 4.73047 4.25C4.03833 4.25 3.5 4.69952 3.5 5.23077C3.5 5.78245 4.03833 6.21154 4.73047 6.21154C5.39697 6.21154 5.96094 5.78245 5.96094 5.23077ZM4.73047 7.51923C4.03833 7.51923 3.5 7.96875 3.5 8.5C3.5 9.05168 4.03833 9.48077 4.73047 9.48077C5.39697 9.48077 5.96094 9.05168 5.96094 8.5C5.96094 7.96875 5.39697 7.51923 4.73047 7.51923ZM4.73047 10.7885C4.03833 10.7885 3.5 11.238 3.5 11.7692C3.5 12.3209 4.03833 12.75 4.73047 12.75C5.39697 12.75 5.96094 12.3209 5.96094 11.7692C5.96094 11.238 5.39697 10.7885 4.73047 10.7885ZM7.19141 5.96635H16.2148C16.4199 5.96635 16.625 5.82332 16.625 5.63942V4.82212C16.625 4.65865 16.4199 4.49519 16.2148 4.49519H7.19141C6.96069 4.49519 6.78125 4.65865 6.78125 4.82212V5.63942C6.78125 5.82332 6.96069 5.96635 7.19141 5.96635ZM7.19141 9.23558H16.2148C16.4199 9.23558 16.625 9.09255 16.625 8.90865V8.09135C16.625 7.92788 16.4199 7.76442 16.2148 7.76442H7.19141C6.96069 7.76442 6.78125 7.92788 6.78125 8.09135V8.90865C6.78125 9.09255 6.96069 9.23558 7.19141 9.23558ZM7.19141 12.5048H16.2148C16.4199 12.5048 16.625 12.3618 16.625 12.1779V11.3606C16.625 11.1971 16.4199 11.0337 16.2148 11.0337H7.19141C6.96069 11.0337 6.78125 11.1971 6.78125 11.3606V12.1779C6.78125 12.3618 6.96069 12.5048 7.19141 12.5048Z" fill="#C9CDD3"/>
    </SvgIcon>
  );
};

export default ListIcon;
