import React from "react";
import { styled } from '@material-ui/core';
import { ContentGridColumnComponent } from '../AppointmentsScheduler/Content/ContentGrid';
import moment from 'moment';
import { memo, useMemo } from 'react';

const ActiveColumnOverlay = styled('div')({
    backgroundColor: '#6462F31A',
    height: '100%',
});

const ColumnDecorations: ContentGridColumnComponent<string> = memo(({ group }) => {
    const isActive = useMemo(() => moment().format('YYYY-MM-DD') === group, [group]);

    return <>{isActive && <ActiveColumnOverlay />}</>;
});

export default ColumnDecorations;
