import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MegaphoneIcon = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path d="M18 7.5C18 6.78125 17.5938 6.125 17 5.78125V1.03125C17 0.75 16.7812 0 16 0C15.75 0 15.5312 0.09375 15.375 0.25L12.6875 2.375C11.375 3.4375 9.6875 4 8 4H2C0.875 4 0 4.90625 0 6V9C0 10.125 0.875 11 2 11H3.03125C3 11.3438 2.96875 11.6875 2.96875 12C2.96875 13.25 3.25 14.4375 3.78125 15.4688C3.9375 15.8125 4.28125 16 4.65625 16H6.96875C7.78125 16 8.28125 15.0938 7.78125 14.4375C7.28125 13.75 6.96875 12.9375 6.96875 12C6.96875 11.6562 7.03125 11.3438 7.09375 11H8C9.6875 11 11.375 11.5938 12.6875 12.6562L15.375 14.7812C15.5 14.9062 15.7812 15 15.9688 15C16.75 15 16.9688 14.3125 16.9688 14V9.25C17.5938 8.90625 18 8.25 18 7.5ZM15 11.9375L13.9375 11.0938C12.25 9.75 10.1562 9 8 9V6C10.1562 6 12.25 5.28125 13.9375 3.9375L15 3.09375V11.9375Z" />
        </SvgIcon>
    )
};

export default MegaphoneIcon;
