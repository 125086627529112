import { makeStyles } from "@material-ui/core/styles";

const useStyles = (color?: string) =>
  makeStyles((theme) => ({
    root: {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
    icon: {
      borderRadius: "50%",
      width: 16,
      height: 16,
      boxShadow: `inset 0 0 0 1px ${
        color || theme.colors.grey5
      }, inset 0 -1px 0 ${color || theme.colors.grey5}`,
      backgroundColor: "#f5f8fa",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
      "$root.Mui-focusVisible &": {
        outline: "2px auto rgba(19,124,189,.6)",
        outlineOffset: 2,
      },
      "input:hover ~ &": {
        backgroundColor: "#ebf1f5",
      },
      "input:disabled ~ &": {
        boxShadow: "none",
        background: "rgba(206,217,224,.5)",
      },
    },
    checkedIcon: {
      backgroundColor: "#f5f8fa",
      backgroundImage:
        "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
      "&:before": {
        display: "block",
        width: 16,
        height: 16,
        backgroundImage: `radial-gradient(${color || theme.colors.grey5},${
          color || theme.colors.grey5
        } 28%,transparent 32%)`,
        content: '""',
      },
      "input:hover ~ &": {
        backgroundColor: "#f5f8fa",
      },
    },
  }));

export default useStyles;
