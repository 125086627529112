export class IsUserEmailDuplicatedModel {
  
    isDuplicated: boolean;
    userId: string | null;
    userFullName: string | null;

    constructor(x?: any) {
      if (typeof x === "object") {
        this.isDuplicated = x.isDuplicated;
        this.userId = x.userId;
        this.userFullName = x.userFullName;
      } else {
        this.isDuplicated = false;
        this.userId = null;
        this.userFullName = null;
      }
    }
  }