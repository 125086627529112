import { ApiClient } from "./api-client";

export default class CountryService {

    private static baseUrl: string = "/country";
    
    static getCountries(locale?: string): Promise<any> {
        let urlEndpoint = `${this.baseUrl}/getlist?locale=${locale ?? "es"}`;
        return ApiClient.get(urlEndpoint)
            .then((data) => {
                return ({country: data.country, countryInSettings: data.countryInSettings });
            });
    }

}
