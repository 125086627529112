import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";


export default function MenuIconReports(props: any) {
    return (
        <SvgIcon {...props}>
          <path d="M5 7H18.8311"  stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path d="M5 11.7422H13.813" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
          <path d="M5 16.4922H8.21094" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
        </SvgIcon>
    )
}