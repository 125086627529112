import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  block: {
    paddingBottom: 30
  },
  title: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    fontWeight: "bold",
    
    marginBottom: 10,
  },
  contentArea: {
    backgroundColor: "#fff",
    borderRadius: 20,
    border: "1px solid #dbdcdd",
    padding: "40px 40px 30px 40px",
    margin: "10px 0"
  },
  requiredFieldsHeader: {
    ...theme.typography.buttons,
    color: theme.colors.grey4,
    marginBottom: 20
  },
  dot: {
    ...theme.typography.txtBody2,
    color: theme.colors.clearMain,
    marginLeft: 5
  },
  contentColumns: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start"
  },
  contentColumn: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginRight: 16
  },
  innerContent: {
    padding: 24
  },
  cashOpeningInput: {
    marginLeft: 48
  },
  creditCardTypes: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: 42,
    marginBottom: 20,
    marginTop: -15
  },
  bankTransfers: {
    marginLeft: 42,
    marginBottom: 20,
    marginTop: -20
  },
  smallVerticalDivider: {
    width: 0,
    height: 16,
    marginLeft: 24,
    marginRight: 15,
    border: "1px solid",
    borderColor: theme.colors.grey4
  },
  otherCardTypeInput: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    marginLeft: 9
  },
  cardNumberOxxoInput: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    marginLeft: 9
  },
  checkGroup: {
    "&:not(:last-child)": {
      marginBottom: 20
    }
  },
  subtitle: {
    fonFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14,
    color: "#5C6477",
    opacity: 0.5
  },
  iconTooltipRoot: {
    display: "inline-block"
  },
  infoContentRow: {
    display: "flex",
    justifyContent: "space-between",
    margin: "20px 0px 0px 0px",
  },
  addButton: {
    width: 137,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "space-around",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    border: "1px solid #6462F3",
    marginRight: 25,
    color: "#6462F3",
    textTransform: "none",
  },
  deleteDiv: {
    paddingTop: 25,
    position: "relative",
    top: 60,
    left: -35
  },
  deleteButton: {
    "&:hover": {
      cursor: "pointer",
    },
  },
  modalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  modalTitleSpace: {
      margin: "24px 0px 8px 0px",
  },
  modalContentText: {
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: "14px",
      lineHeight: "17px",
      textAlign: "center",
      color: "#4A4D51",
  },
  modalTitleText: {
      fontSize: "18px",
      lineHeight: "22px",
      textAlign: "center",
      fontStyle: "normal",
      fontWeight: "bold",
      color: "#26292B",
  },
  modalDelete: {
      height: 210
  },
  exclamationModalBody: {
    display: "flex",
    justifyContent: "center"
  },
  separator: {
      height: 0,
      borderTop: "1px solid rgba(201, 205, 211, 0.38)",
      margin: "16px 0px 16px 0px",
  },
  text: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
  },
  textChecked: {
    color: theme.colors.grey5,
    textDecoration: "none",
    fontSize: 12
  },
  textNoChecked: {
    color: theme.colors.clearRed,
    textDecoration: "none",
    fontSize: 12
  },
  link: {
    marginLeft: 4,
    textDecoration: "underline",
    fontWeight: 700,
  },
  checkboxMP: {
    color: theme.colors.clearMain,
    '&.Mui-checked': {
      color: theme.colors.clearMain,
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  checkboxMPRed: {
    color: theme.colors.clearRed,
    '&.Mui-checked': {
      color: theme.colors.clearRed,
      "&:hover": {
        backgroundColor: "transparent"
      }
    },
    "&:hover": {
      backgroundColor: "transparent"
    }
  },
  opacityMid: {
    opacity: "0.5"
  },
  opacityTextMid: {
    ...theme.typography.txtBody2,
    opacity: "0.5",
    backgroundColor: theme.colors.grey1,
    height: 40,
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
    width: 230 
  },


  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  goBackButton: {
    marginRight: 16,
    background: theme.colors.grey1,
    color: theme.colors.grey3,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid",
    borderColor: theme.colors.clearYellow,
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    width: 534,
    height: 260,
    padding: "16px 24px",
  },
  closeButton: {
    position: "absolute",
    width: 24,
    height: 24,
    top: 18,
    right: 28,
    color: theme.colors.grey4
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  exclamationIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: 10,
    marginBottom: 18,
    width: 50,
    height: 50,
    borderRadius: 25,
    borderColor: theme.colors.clearYellow,
    color: theme.colors.clearYellow,
    border: "1px solid"
  },
  modalTitle: {
    ...theme.typography.h4,
    fontFamily: "Inter",
    fontWeight: "bold",
    color: theme.colors.grey7,
    justifySelf: "center",
    marginBottom: 24
  },
  modalText: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
  },
  modalTextBlock: {
    marginBottom: 24,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    textAlign: "center"
  },
  modalFooter: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  initialCash: {
    fontFamily: 'Inter',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    color: theme.colors.grey4,
    marginBottom: 10
  },

  extraCostTitle: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 14,
    color: theme.colors.grey5
  },
  calcExtra: {
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    color: theme.colors.grey5,
    marginBottom: 15
  },
  lineExtraCost: {
    width: 330,
    marginTop: 10,
    marginBottom: 10,
    borderTop: "1px solid #C9CDD3",
    height: 15,
  },
  boxExtraCost: {
    height: 70,
    display: "inline-block",
    textAlign: "left"
  },
  boxExtraCostPlus: {
    width: 50,
    display: "inline-block",
    textAlign: "center",
    color: theme.colors.grey4,
    position: "relative",
    top: -10
  },
  inputExtraCost: {
    width: 100,
    padding: "0 !important",
    "& input": {
      textAlign: "center",
      padding: "0 !important",
      color: theme.colors.grey4,
    }
  },
  labelExtraCostMain: {
    height: 22,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 18,
    color: "#919CA5"
  },
  labelExtraCost: {
    fontFamily: "Roboto",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 12,
    color: theme.colors.grey4
  },
  infoIcon: {
    color: theme.colors.grey5,
    marginLeft: 5,
    width: 8,
    height: 8,
    minWidth: 8,
    cursor: "pointer",
  },
  radioLabelRoot: {
    margin: 0,
  },
  radioLabel: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey4,
  },
  radioRoot: {
    "&.Mui-checked": {
      color: theme.colors.clearMain,
      "&, & + .MuiFormControlLabel-label": {
        color: theme.colors.clearMain,
      },
    },
  },
  radioGroupItems: {
    marginBottom: 15
  },
  header: {
      display: "flex",
      alignItems: "center",
      marginBottom: 15,
      gap: 20,
      width: "100%",
      justifyContent: "space-between",
  },
  tabTitle: {
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: 700,
      fontSize: "18px",
      lineHeight: "22px",
      color: Color.gray5
  },
  })
);

export default useStyles;