import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
const DownloadFileCloud = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{
        width: 18,
        height: 13,
        color: "#5C6477",
        ...style,
      }}
      viewBox={viewBox || "0 0 18 13"}
    >
      <path d="M14.6836 5.95703C14.793 5.65625 14.875 5.35547 14.875 5C14.875 3.55078 13.6992 2.375 12.25 2.375C11.7031 2.375 11.1836 2.53906 10.7734 2.83984C10.0352 1.52734 8.61328 0.625 7 0.625C4.56641 0.625 2.625 2.59375 2.625 5C2.625 5.08203 2.625 5.16406 2.625 5.24609C1.09375 5.76562 0 7.24219 0 8.9375C0 11.125 1.75 12.875 3.9375 12.875H14C15.9141 12.875 17.5 11.3164 17.5 9.375C17.5 7.70703 16.2969 6.28516 14.6836 5.95703ZM11.0469 8.39062L8.17578 11.2617C8.01172 11.4258 7.71094 11.4258 7.54688 11.2617L4.67578 8.39062C4.40234 8.11719 4.59375 7.625 4.97656 7.625H6.78125V4.5625C6.78125 4.34375 6.97266 4.125 7.21875 4.125H8.53125C8.75 4.125 8.96875 4.34375 8.96875 4.5625V7.625H10.7461C11.1289 7.625 11.3203 8.11719 11.0469 8.39062Z" />
    </SvgIcon>
  );
};
export default DownloadFileCloud;
