import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  label: {
    ...theme.typography.txtBody2,
    fontFamily: "Inter, sans-serif",
    fontWeight: "bold",
    display: "inline-flex",
    color: theme.colors.grey4,
    margin: 0,
    marginBottom: 5
  },
  inputWithLabel: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  controlLabel: {
    ...theme.typography.txtBody2,
    fontFamily: "Inter, sans-serif",
    fontWeight: "bold",
    display: "inline-flex",
    color: theme.colors.grey4,
    marginBottom: 8
  },
  dot: {
    ...theme.typography.txtBody2,
    color: theme.colors.clearMain,
    marginLeft: 5
  },
  divider: {
    width: 731,
    marginTop: 24,
    marginBottom: 24,
    height: 0,
    border: "1px solid",
    borderColor: theme.colors.grey1
  },
  radios: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start"
  },
  radio: {
    width: 150,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center"
  },
  imageBlock: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-start"
  },
  imageBlockNotes: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    marginLeft: 24,

    ...theme.typography.txtBody2,
    color: theme.colors.grey5
  },
  uploadedImage: {
    width: 250,
    height: 120,
    backgroundColor: "#FFF",
    borderRadius: 15,
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundPosition: "center top",
  },
  emptyImage: {
    width: 250,
    height: 120,
    backgroundColor: "rgba(100, 98, 243, 0.1)",
    borderRadius: 15,
    border: "1px dashed",
    borderColor: theme.colors.clearMain,
    cursor: "pointer",
  },
  plusIcon: {
    marginTop: 40,
    height: 25,
    textAlign: "center",
    color: theme.colors.clearMain
  },
  addImageLabel: {
    marginTop: 3,
    fontSize: 12,
    textAlign: "center",
    ...theme.typography.txtBody2,
    color: theme.colors.clearMain,
    fontWeight: 700
  },
  labelAddPhotoIns: {
    marginTop: 3,
    ...theme.typography.caption2,
    color: theme.colors.clearMain,
    textAlign: "center",
  },
  textBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly",
    alignItems: "flex-start",

    height: 304,
    padding: "16px 29px 16px 16px",
    backgroundColor: theme.colors.grey1,

    border: "1px solid",
    borderColor: theme.colors.grey2,
    borderRadius: 14,

    ...theme.typography.txtBody1,
    color: theme.colors.grey3,
  },
  goToSiteButton: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    paddingTop: 7
  },
  goToSiteButtonInnerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",

    color: theme.colors.grey2,
    fontWeight: 700,

    width: 680,
    height: 32,
    border: "1px solid",
    borderColor: theme.colors.grey2,
    borderRadius: 5,
  },
  goToSiteIcon: {
    marginRight: 4
  },
  tinyMceOverrides: {
    "& svg": {
      fill: "#919CA5 !important"
    },
    "& button.tox-tbtn--select": {
      visibility: "hidden"
    },
    position: "relative",
    padding: "15px 0px 15px 0px"
  },
  tinyMceUnfocused: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: "#C9CDD3 !important"
    }
  },
  tinyMceFocused: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: "#6462F3 !important"
    }
  },
  tinyMceInvalid: {
    "& div.tox-tinymce": {
      borderRadius: 5,
      borderColor: Color.red +" !important"
    }
  },
  charCounter: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "10px",
    lineHeight: "12px",
    position: "absolute",
    paddingLeft: "629px",
    marginTop: "-18px",
    color: theme.colors.grey5
  },
  charCounterInvalid: {
    color: theme.colors.clearRed
  },
  urlWarning: {
    position: "absolute",
    paddingLeft: "656px",
    marginTop: "-198px",
  }
})
);

export default useStyles;