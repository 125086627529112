import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionArrowIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M13.0681 19.9038L18.0844 14.8875C18.3092 14.6628 18.4354 14.358 18.4354 14.0402C18.4354 13.7224 18.3092 13.4176 18.0844 13.1929C17.8597 12.9682 17.5549 12.842 17.2371 12.842C16.9193 12.842 16.6146 12.9682 16.3898 13.1929L13.4103 16.155L13.4103 5.19823C13.4103 4.88037 13.284 4.57552 13.0592 4.35075C12.8345 4.12599 12.5296 3.99972 12.2117 3.99972C11.8939 3.99972 11.589 4.12599 11.3643 4.35075C11.1395 4.57552 11.0132 4.88037 11.0132 5.19823L11.0132 16.155L8.05175 13.1929C7.82472 12.9682 7.51771 12.8429 7.19827 12.8445C6.87884 12.8461 6.57313 12.9746 6.34842 13.2017C6.1237 13.4287 5.99838 13.7357 6.00002 14.0551C6.00166 14.3746 6.13012 14.6803 6.35716 14.905L11.356 19.9038C11.5827 20.132 11.8904 20.2613 12.2121 20.2635C12.3719 20.2663 12.5306 20.2358 12.678 20.1738C12.8253 20.1119 12.9582 20.02 13.0681 19.9038Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}