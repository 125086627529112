import React, { useEffect, useState } from "react";

import { useAppDispatch } from "../../../redux/hooks";
import { alertsActions } from "../../../redux/alerts-slice";

import { InputEditableWithSelectItemProps } from "./props";
import useStyles from "./css";
import { EditIcon, SaveIcon } from "../../../assets/icons";
import { ClickAwayListener } from "@material-ui/core";
import InputBase from "@material-ui/core/InputBase";
import useCurrencyValueTemplate from "../../../hooks/useCurrencyValueTemplate";
import Select from "../Select";
import { DiscountType } from "../../../models/enums/discount-type";

enum InputState {
  Idle,
  Edit
}


export default function InputEditableWithSelectItem(props: InputEditableWithSelectItemProps) {
  
  const { value, selectValue, setValue, valueTemplate, disabled, maxLength, maxValue, color, selectItems, subTotal } = props;
  
  const actualMaxLength = maxLength ?? 10;
  const maxDecimals = 2;
  
  const classes = useStyles(color)();
  const dispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;
  
  const templatedValue = useCurrencyValueTemplate(valueTemplate);
  
  useEffect(() => {
    if (subTotal) {
      let currentValue = value;
      if (displaySelectValue === DiscountType.Percentage) {
        currentValue = (currentValue / 100) * subTotal;
      }
      setDisplayValue(templatedValue(currentValue));
    }
  }, [subTotal])
  
  
  const [isError, setError] = useState<boolean>(false);
  const [inputState, setInputState] = useState<InputState>(InputState.Idle);
  
  function getAmountDiscountByDiscountType() {
    return selectValue === DiscountType.Percentage ? (value / 100) * subTotal : value;
  }
  
  const [displayValue, setDisplayValue] = useState<string>(templatedValue(getAmountDiscountByDiscountType()));
  const [displaySelectValue, setDisplaySelectValue] = useState<DiscountType>(selectValue);
  
  function handleEditButtonClick() {
    setDisplayValue(value.toString());
    setInputState(InputState.Edit);
  }
  
  function handleEditClickAway() {
    let parsedValue = parseFloat(displayValue);
    let selectValue = displaySelectValue;
    if (Number.isNaN(parsedValue)) parsedValue = 0;
    setValue(parsedValue, selectValue);
    setError(false);
    
    if ((maxValue && parsedValue > maxValue.maxValue) && displaySelectValue === DiscountType.Value) {
      setError(true);
    }
    if ((maxValue && parsedValue > 100) && displaySelectValue === DiscountType.Percentage) {
      setError(true);
    }
    
    let calculateValue = parsedValue;
    if (displaySelectValue === DiscountType.Percentage) {
      calculateValue = (calculateValue / 100) * subTotal;
    }
    setDisplayValue(templatedValue(calculateValue));
    
    setInputState(InputState.Idle);
  }
  
  function handleEditOnChange(event: any) {
    event.preventDefault();
    
    if (Number.isNaN(+event.target.value)) return;
    
    const value = event.target.value + "";
    const actualValue = value.replace(",", ".").replace(/[^\d.]/g, "");
    
    // verify decimals
    const pointIndex = value.indexOf(".");
    const hasDecimals = pointIndex > -1;
    
    if (hasDecimals) {
      if ((value.length - pointIndex - 1) > maxDecimals) return;
    } else {
      if (value.length > actualMaxLength) return;
    }
    
    setDisplayValue((actualValue) + "");
  }
  
  function handleSelectEditOnChange(event: any) {
    event.preventDefault();
    const value = event.target.value;
    setDisplaySelectValue(value);
  }
  
  
  return (
    <div>
      {
        inputState === InputState.Idle
          ?
          <div className={classes.idleRoot}>
            <div className={classes.idleValue}>{displayValue}</div>
            <div>
              {disabled
                ? ""
                :
                <EditIcon
                  style={{ width: 11, height: 11, color: "#919CA5" }}
                  viewBox={"0 0 16 16"}
                  onClick={handleEditButtonClick}
                />
              }
            </div>
          </div>
          :
          
          <ClickAwayListener mouseEvent="onMouseDown" onClickAway={handleEditClickAway}>
            
            <div className={classes.editRoot}>
              <InputBase
                className={`${classes.input} ${isError ? classes.inputError : ""}`}
                classes={{
                  focused: classes.inputFocused,
                  disabled: classes.inputDisabled,
                  input: classes.inputText,
                }}
                value={displayValue}
                placeholder={templatedValue(0.00)}
                onChange={handleEditOnChange}
                disabled={disabled ?? false}
                inputProps={{
                  maxLength: maxLength ?? 10,
                }}
                type="text"
              />
              <Select
                key={"inputEditableWithSelectItem"}
                width={51}
                classes={{
                  root: `${classes.focused} ${classes.selected}`,
                }}
                styleClass={classes.select}
                value={displaySelectValue}
                onChange={handleSelectEditOnChange}
                colorArrowIcon={"#5C6477"}
                items={selectItems}
                disabled={false}/>
            
            </div>
          </ClickAwayListener>
      }
    
    </div>
  );
}