import React from "react";

import { Trans, useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";

import useStyles from "./css";
import { UserModalServicesProps } from "./props";
import { ServiceCategoryEntity } from "../../../../../models/service-category-entity";
import UserServiceCategory from "../UserServiceCategory";


export default function UserModalServices(props: UserModalServicesProps) {
  
  const { userServiceInfo, setUserServiceInfo, mode } = props;
  
  const classes = useStyles();
  const { t } = useTranslation("settings");
  
  const renderServiceCategories = (item: ServiceCategoryEntity, index: number) => {
    
    const setItem = (value: ServiceCategoryEntity) => {
      const newServiceCategories = userServiceInfo.serviceCategories;
      newServiceCategories[index] = value;
      setUserServiceInfo({...userServiceInfo, serviceCategories: newServiceCategories });
    }
    
    return (
      <UserServiceCategory 
        key={`service_category_${index}`}
        category={item} 
        setCategory={setItem}
      />
    );
  }
  
  return (
    <div>
      {mode === "new" &&
      <Typography className={classes.header}><Trans i18nKey="SelectAtLeastOneService" t={t} /></Typography>
      }
      {userServiceInfo.serviceCategories.map(renderServiceCategories)}
    </div>
  );
  
}