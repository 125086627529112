import { makeStyles } from "@material-ui/core/styles";
import { Clear } from "@material-ui/icons";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
    header: {
        display: "flex",
        alignItems: "center",
        marginBottom: 24,
        width: "100%",
        gap: 20
    },
    tabTitle: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: Color.gray5
    },
    newButton: {
        marginLeft: "auto",
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "space-between",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        background: Color.clear2,
        color: Color.gray1,
        "&:hover": {
            background: Color.clear1,
        },
    },
    footerContainer: {
        paddingTop: 10,
        paddingBottom: 80
    },
    footerNoteLabel: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "14px",
        color: Color.gray5,
    },
    footerNoteLabelBold: {
        fontWeight: 700,
    },
    footerNoteLabelLink: {
        textDecoration: "underline",
        fontWeight: 700,
        color: Color.clear1,
        "&:hover": {
            color: Color.blue
        }
    },
    previewButton: {
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 12,
        border: "1px solid " + Color.clear1,
        color: Color.clear1,
    },
    copyNotes:{
        backgroundColor: theme.colors.clearLilac,
        borderRadius: 5,
        padding: "20px 30px",
        marginBottom: 32
    },
    copyNotesTitle: {
        color: theme.colors.clearMain,
        fontSize: 12,
        fontFamily: "Roboto, sans-serif",
        fontWeight: 700,
        wordWrap: "break-word"
    },
    copyNotesDescription: {
        color: theme.colors.grey5,
        fontSize: 12,
        fontFamily: "Roboto, sans-serif",
        fontWeight: 400,
        wordWrap: "break-word"
    },
    copyControls:{
        display: "flex",
    },
    copyButton:{
        marginLeft: 16,
        height: 32,
        borderRadius: 5,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "space-between",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        background: Color.clear2,
        color: Color.gray1,
        "&:hover": {
            background: Color.clear1,
        },
    },
    cancelButton:{
        marginLeft: 16,
        height: 32,
        borderRadius: 5,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "space-between",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        background: Color.gray1,
        color: Color.clear2,
        borderColor: Color.clear2,
        borderWidth: 1,
        borderStyle: "solid",
        "&:hover": {
            background: Color.white,
        },
    },
    focused: {
        "&:focus": {
            backgroundColor: theme.colors.grey1,
        },
        "&.MuiSelect-select": {
            "&:focus": {
                borderRadius: 5,
            },
        },
    },
    select: {
        ...theme.typography.txtBody2,
        height: 32,
        width: 205,
        border: "solid 1px rgba(0, 0, 0, 0.1)",
        borderRadius: 5,
        "& .MuiPaper-rounded": {
            borderRadius: 10,
            marginTop: 8
        },
        "& .MuiPaper-rounded .MuiListItem-root.Mui-selected:not(:hover)": {
            backgroundColor: "white",
        },
        "& .MuiPaper-rounded .MuiListItem-root": {
            fontSize: 12,
        },
        "& .MuiPaper-rounded .MuiListItem-root:hover": {
            backgroundColor: theme.colors.grey1
        },
        "& .MuiSelect-select": {
            paddingRight: 34
        },
    },
    selected: {
        fontWeight: "bold",
        padding: "8px 0px 8px 15px",
        color: theme.colors.clearMain
    },
    multiSelect: {
        ...theme.typography.txtBody2,
        height: 32,
        border: "solid 1px rgba(0, 0, 0, 0.1)",
        borderRadius: 5,
        width: 250,
        marginLeft: 16,
        "& .MuiSelect-select": {
            paddingRight: 34
        },
        "& .MuiMenu-list": {
            borderRadius: "0 0 10px 10px",
            marginTop: 8
        },
    },
    newSectionButtonContainer: {
        display: "flex",
        marginBottom: 24,
    },
}));

export default useStyles;