import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BellIcon = (props: any) => {
  const { style, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{fontSize: '16px'}}
      viewBox="0 0 16 16"
    >
      <path
        d="M13.8422 10.0927L12.3846 8.59917V6.62069C12.3829 5.25343 11.8866 3.93536 10.9914 2.92137C10.0963 1.90737 8.866 1.26948 7.53846 1.13103V0.538461C7.53846 0.241077 7.29738 0 7 0C6.70262 0 6.46154 0.241077 6.46154 0.538462V1.13103C5.134 1.26948 3.90372 1.90737 3.00858 2.92137C2.11345 3.93536 1.61705 5.25343 1.61538 6.62069V8.59917L0.157769 10.0927C0.0567811 10.1961 3.04971e-05 10.3364 0 10.4828V12.1379C0 12.2843 0.0567306 12.4246 0.157712 12.5281C0.258693 12.6315 0.395653 12.6897 0.538462 12.6897H4.30769V13.2414C4.30769 13.973 4.59135 14.6747 5.09625 15.192C5.60116 15.7094 6.28596 16 7 16C7.71404 16 8.39884 15.7094 8.90375 15.192C9.40865 14.6747 9.69231 13.973 9.69231 13.2414V12.6897H13.4615C13.6043 12.6897 13.7413 12.6315 13.8423 12.5281C13.9433 12.4246 14 12.2843 14 12.1379V10.4828C14 10.3364 13.9432 10.1961 13.8422 10.0927ZM8.61538 13.2414C8.61538 13.6804 8.44519 14.1014 8.14225 14.4118C7.83931 14.7222 7.42843 14.8966 7 14.8966C6.57157 14.8966 6.16069 14.7222 5.85775 14.4118C5.55481 14.1014 5.38462 13.6804 5.38462 13.2414V12.6897H8.61538V13.2414Z"
        fill={style?.color || "#919CA5"}
      />
    </SvgIcon>
  )
};
export default BellIcon;