import { makeStyles } from "@material-ui/core/styles";

const useStyles = (width?: number) =>
  makeStyles((theme) => ({
    root: {},
    label: {
      display: "block",
      color: "#4a4d51",
      fontFamily: "Roboto",
      fontSize: 12,
      lineHeight: "14px",
      fontWeight: "bold",
      marginBottom: "0.5rem",
    },
    date: {
      display: "flex",
      justifyContent: "space-between",
      width: width || 370,
    },
    controlWithLabel: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start"
    },
    controlLabel: {
      ...theme.typography.txtBody2,
      fontFamily: "Inter, sans-serif",
      fontWeight: "bold",
      display: "inline-flex",
      color: theme.colors.grey4,
      marginBottom: 8
    },
    dot: {
      ...theme.typography.txtBody2,
      color: theme.colors.clearMain,
      marginLeft: 5
    },
    dateText: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey5,
      "&::placeholder": {
        fontWeight: "normal",
        color: "#676767",
      },
    }
  }));

export default useStyles;