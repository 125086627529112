import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    alignItems: "center",
    justifyContent: "end",
    },
    focused: {
        "&:focus": {
            backgroundColor: theme.colors.grey1,
        },
        "&.MuiSelect-select": {
            "&:focus": {
                borderRadius: 5,
            },
        },
    },
    select: {
        ...theme.typography.txtBody2,
        height: 32,
        width: 205,
        border: "solid 1px rgba(0, 0, 0, 0.1)",
        borderRadius: 5,
        "& .MuiPaper-rounded": {
            borderRadius: 10,
            marginTop: 8
        },
        "& .MuiPaper-rounded .MuiListItem-root.Mui-selected:not(:hover)": {
            backgroundColor: "white",
        },
        "& .MuiPaper-rounded .MuiListItem-root": {
            fontSize: 12,
        },
        "& .MuiPaper-rounded .MuiListItem-root:hover": {
            backgroundColor: theme.colors.grey1
        },
        "& .MuiSelect-select": {
            paddingRight: 34
        },
    },
    selected: {
        fontWeight: "bold",
        padding: "8px 0px 8px 15px",
        color: theme.colors.clearMain
    },
}));

export default useStyles;
