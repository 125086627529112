export enum SettingsFieldUpdate {
    None = -1,
    All = 0,
    Name = 1,
    PhoneNumber = 2,
    Address = 3,
    AddressReferences = 4,
    CancellationPolicies = 5,
    ShowPrices = 6,
    Logo = 7,
    IsPaymentCash = 8,
    IsPaymentCard = 9,
    IsPaymentBankTransfer = 10,
    Currency = 11,
    allowCustomerToCancelFromSite = 12,
    allowCustomerToModifyFromSite = 13,
    minuteLimitToModifyAppointmentFromSite = 14,
    requireSelectSpecialistFromSite = 15,
    showAnySpecialistFromSite = 16,
    gapBetweenTimeIntervalsFromSite = 17,
    AllowCustomerScheduleSimultaneousServices = 18,
    FiscalId = 19,
    AllowCustomerToScheduleTodayFromSite = 20,
    ShowCancellationPolicies = 21,
    ShowSuggestedServices = 22,
}

export const getEnum = (field: string) => {

    let e: { [key: string]: SettingsFieldUpdate } = {};

    e["name"] = SettingsFieldUpdate.Name;
    e["fiscalid"] = SettingsFieldUpdate.FiscalId;
    e["phonenumber"] = SettingsFieldUpdate.PhoneNumber;
    e["address"] = SettingsFieldUpdate.Address;
    e["addressreferences"] = SettingsFieldUpdate.AddressReferences;
    e["cancellationpolicies"] = SettingsFieldUpdate.CancellationPolicies;
    e["showprices"] = SettingsFieldUpdate.ShowPrices;
    e["allowcustomertocancelfromsite"] = SettingsFieldUpdate.allowCustomerToCancelFromSite;
    e["allowcustomertomodifyfromsite"] = SettingsFieldUpdate.allowCustomerToModifyFromSite;
    e["ispaymentcash"] = SettingsFieldUpdate.IsPaymentCash;
    e["ispaymentcard"] = SettingsFieldUpdate.IsPaymentCard;
    e["ispaymentbanktransfer"] = SettingsFieldUpdate.IsPaymentBankTransfer;
    e["currency"] = SettingsFieldUpdate.Currency;
    e["logo"] = SettingsFieldUpdate.Logo;
    e["minutelimittomodifyappointmentfromsite"] = SettingsFieldUpdate.minuteLimitToModifyAppointmentFromSite;
    e["gapbetweentimeintervalsfromsite"] = SettingsFieldUpdate.gapBetweenTimeIntervalsFromSite;
    e["allowcustomerschedulesimultaneousservices"] = SettingsFieldUpdate.AllowCustomerScheduleSimultaneousServices;
    e["allowcustomertoscheduletodayfromsite"] = SettingsFieldUpdate.AllowCustomerScheduleSimultaneousServices;
    e["showcancellationpolicies"] = SettingsFieldUpdate.ShowCancellationPolicies;
    e["showsuggestedservices"] = SettingsFieldUpdate.ShowSuggestedServices;

    return e[field.toLowerCase()];

}