import axios from "axios";

import { ApiClient } from "./api-client"
import { Url } from "../constants/url";
import store from "../redux/store";
import { setUserInfo } from "../redux/user-info-slice";

export class AuthenticationService {
    private static baseUrl = process.env.REACT_APP_SERVER_URL;

    static async login(email: string, password: string, captchaToken: string) {
        const formData = {
            email: email,
            password: password,
            captchaToken: captchaToken
        };

        return axios.post(`${this.baseUrl}/Login`, formData, { withCredentials: true })
          .then((resp) => {
                const { accessToken, succeeded } = resp.data;
                if (succeeded && accessToken && accessToken !== "") {
                    ApiClient.configureAuthorization(accessToken);
                    store.dispatch(setUserInfo(ApiClient.getUserInfo()));
                    return;
                }
                return Promise.reject("authentication failed");
            })
            .catch((error) => {
                return Promise.reject(error?.response?.data || error);
            });
    }
    
    static async loginEnterprise(email: string, password: string, captchaToken: string) {
        const formData = {
            email: email,
            password: password,
            captchaToken: captchaToken
        };
        
        return axios.post(`${this.baseUrl}/enterprise/Login`, formData, { withCredentials: true })
          .then((resp) => {
              const { accessToken, succeeded } = resp.data;
              if (succeeded && accessToken && accessToken !== "") {
                  ApiClient.configureAuthorization(accessToken);
                  store.dispatch(setUserInfo(ApiClient.getUserInfo()));
                  return;
              }
              return Promise.reject("authentication failed");
          })
          .catch((error) => {
              return Promise.reject(error?.response?.data || error);
          });
    }

    static signup(email: string, name: string, password: string, timeZoneId: string) {
        const formData = {
            email: email,
            name: name,
            password: password,
            timeZoneId: timeZoneId
        };

        return axios.post(`${this.baseUrl}/signup`, formData)
            .then((resp) => {
                window.location.href = Url.Authentication.Login;
            })
            .catch((data) => {
                throw new Error(data);
            });
    }

    static recoverPassword(email: string, captchaToken: string) {
        const formData = {
            email,
            captchaToken
        }

        return axios.post(`${this.baseUrl}/RecoverPassword`, formData);
    }
    
    static recoverPasswordEnterprise(email: string, captchaToken: string) {
        const formData = {
            email,
            captchaToken
        }
        
        return axios.post(`${this.baseUrl}/enterprise/RecoverPassword`, formData);
    }
    
    static resetPassword(email: string, token: string, newPassword: string, captchaToken: string) {
        const formData = {
            email,
            token,
            newPassword,
            captchaToken
        }
        return axios.post(`${this.baseUrl}/ResetPassword`, formData);
    }

    static async logout() {
        try {
            await axios.post(`${this.baseUrl}/logout`, {}, { withCredentials: true });
        } finally {
            ApiClient.removeAuthorization();
            window.location.href = Url.Authentication.Login;
        }
    }
}