import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const FlagIcon = (props: any) => {
  return (
    <SvgIcon {...props}>
      <path d="M0 16L6 12.5L12 16V1.5C12 0.6875 11.3125 0 10.5 0H1.5C0.65625 0 0 0.6875 0 1.5V16Z" />
    </SvgIcon>
  );
};

export default FlagIcon;
