import React from "react";

import MuiTabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";

import useStyles from "./css";
import { TabsProps } from "./props";

const Tabs = (props: TabsProps) => {
  const { value, tabs, onChange } = props;
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (onChange) {
      onChange(newValue);
    }
  };

  const a11yProps = (index: any) => {
    return {
      id: `tab-${index}`,
      "aria-controls": `tabpanel-${index}`,
    };
  };

  return (
    <Box>
      <MuiTabs
        className={classes.root}
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        aria-label="tabs"
      >
        {tabs.map((tab) => (
          <Tab
            classes={{ root: classes.rootTab, selected: classes.tabSelected }}
            key={tab.index}
            label={tab.label}
            {...a11yProps(tab.index)}
          />
        ))}
      </MuiTabs>
    </Box>
  );
};

export default Tabs;
