import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
const UploadFileCloud = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{
        width: 41,
        height: 29,
        color: "#9FA9E6",
        position: "relative",
        top: "-29px",
        ...style,
      }}
      viewBox={viewBox || "0 0 41 29"}
    >
      <path d="M34.2292 12.5208C34.4792 11.8333 34.6667 11.1458 34.6667 10.3333C34.6667 7.02075 31.9792 4.33325 28.6667 4.33325C27.4167 4.33325 26.2292 4.70825 25.2917 5.39575C23.6042 2.39575 20.3542 0.333252 16.6667 0.333252C11.1042 0.333252 6.66669 4.83325 6.66669 10.3333C6.66669 10.5208 6.66669 10.7083 6.66669 10.8958C3.16669 12.0833 0.666687 15.4583 0.666687 19.3333C0.666687 24.3333 4.66669 28.3333 9.66669 28.3333H32.6667C37.0417 28.3333 40.6667 24.7708 40.6667 20.3333C40.6667 16.5208 37.9167 13.2708 34.2292 12.5208ZM25.2292 16.3333H21.1667V23.3333C21.1667 23.8958 20.6667 24.3333 20.1667 24.3333H17.1667C16.6042 24.3333 16.1667 23.8958 16.1667 23.3333V16.3333H12.0417C11.1667 16.3333 10.7292 15.2708 11.3542 14.6458L17.9167 8.08325C18.2917 7.70825 18.9792 7.70825 19.3542 8.08325L25.9167 14.6458C26.5417 15.2708 26.1042 16.3333 25.2292 16.3333Z" />
    </SvgIcon>
  );
};
export default UploadFileCloud;
