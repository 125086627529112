import { ConversationMessageType } from "./enums/conversation-message-type";
import { NotificationType } from "./enums/notification-type";

export default class NotificationEntity {
  id: string;
  appointmentId: string | null;
  type: NotificationType;
  senderId: string;
  senderMobile: string;
  sender: string;
  text: string | null;
  fromStartDate: Date | null;
  fromEndDate: Date | null;
  toStartDate: Date | null;
  toEndDate: Date | null;
  date: string;
  read: boolean;
  wasShown: boolean;
  messageCount: number;
  whatsAppInboundMessageType: ConversationMessageType | null;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.appointmentId = x.appointmentId;
      this.type = x.type;
      this.senderId = x.senderId;
      this.senderMobile = x.senderMobile;
      this.sender = x.sender;
      this.text = x.text;
      this.fromStartDate = new Date(x.fromStartDate);
      this.fromEndDate = new Date(x.fromEndDate);
      this.toStartDate = new Date(x.toStartDate);
      this.toEndDate = new Date(x.toEndDate);
      this.date = x.date;
      this.read = x.read;
      this.wasShown = x.wasShown;
      this.messageCount = x.messageCount;
      this.whatsAppInboundMessageType = x.whatsAppInboundMessageType;
    } else {
      this.id = "";
      this.appointmentId = null;
      this.type = 0;
      this.senderId = "";
      this.senderMobile = "";
      this.sender = "";
      this.text = "";
      this.fromStartDate = null;
      this.fromEndDate = null;
      this.toStartDate = null;
      this.toEndDate = null;
      this.date = "";
      this.read = true;
      this.wasShown = true;
      this.messageCount = 0;
      this.whatsAppInboundMessageType = null;
    }
  }
}