import React from "react";

import { useSelector } from "react-redux";
import { useAppSelector } from "../../../../../redux/hooks";

import GeneralEnterprise from "./Enterprise/general";
import GeneralEstablishment from "../../../../settings/Sections/General/GeneralMain";
import { RootState } from "../../../../../redux/store";

export default function GeneralPage() {
    const settingsSelectedAccountId = useSelector((state: RootState) => state.enterprise.settingsEstablishmentId);
    const userInfo = useAppSelector(state => state.userInfo)
    const enterpriseSelected = userInfo.accountId === settingsSelectedAccountId;

  return (
    <>
       {
         enterpriseSelected
         ? <GeneralEnterprise />
         : <GeneralEstablishment
             settingsSelectedEstablishmentId={settingsSelectedAccountId}
           />
       }
    </>
  )
};