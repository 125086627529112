import React, { useState } from 'react'

import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";

import { ArrowDropDownIcon, DotIcon } from "../../../assets/icons";
import { AppointmentStatusSelectorProps } from "./props";
import { AppointmentStatus } from "../../../models/enums/appointment-status";
import useStyles from "./css";
import { ClickAwayListener, ListItem } from "@material-ui/core";


export default function AppointmentStatusSelector(props: AppointmentStatusSelectorProps) {
  
  const { appointmentStatus, handleAppointmentStatusChange, disabled } = props;
  
  const classes = useStyles();
  const { t } = useTranslation("general");
  
  const statuses = [AppointmentStatus.NotConfirmed, AppointmentStatus.Confirmed, AppointmentStatus.Arrived, AppointmentStatus.NotArrived];
  
  const [open, setOpen] = useState<boolean>(false);
  
  const getDotStatus = (status: AppointmentStatus | undefined): string => {
    switch (status) {
      case AppointmentStatus.Arrived:
        return "#36CE91";
      case AppointmentStatus.NotArrived:
        return "#F15857";
      case AppointmentStatus.NotConfirmed:
        return "#919CA5";
      case AppointmentStatus.Confirmed:
      default:
        return "#0BCBE0";
    }
  };
  
  const getTxtStatus = (status: AppointmentStatus | undefined): string => { //TODO probably cleanup
    switch (status) {
      case AppointmentStatus.Arrived:
        return t("Arrived");
      case AppointmentStatus.NotArrived:
        return t("Not Arrived");
      case AppointmentStatus.NotConfirmed:
        return t("Not Confirmed");
      case AppointmentStatus.Confirmed:
      default:
        return t("Confirmed");
    }
  };
  
  function handleDropdown() {
    setOpen(!open)
  }
  
  function handleCloseList() {
    setOpen(false)
  }
  
  function handleListItemClick(item: AppointmentStatus) {
    handleAppointmentStatusChange(item);
    handleCloseList();
  }
  
  function renderStatusAsListItem(item: AppointmentStatus) {
    const selected = item === appointmentStatus;
    return (
      <ListItem 
        className={classes.item}
        onClick={() => handleListItemClick(item)}
      >
        <div className={classes.itemDot}>
          <DotIcon
            color={getDotStatus(item)}
            style={{ width: "10.5px", height: "10.5px" }}
            viewBox="0 0 14 14"
          />
        </div>
        <Typography className={`${classes.itemText} ${selected ? classes.selectedItemText : ""}`}>
          {getTxtStatus(item).toUpperCase()}
        </Typography>
      </ListItem>
    );
  }
  
  return (
    <div className={classes.root}>
      <ListItem className={classes.mainItem}>
        <DotIcon
          color={getDotStatus(appointmentStatus)}
          style={{ width: 14, height: 14, marginRight: 11.75, }}
          viewBox="0 0 14 14"
        />
        <Typography className={classes.mainItemText}>
          {getTxtStatus(appointmentStatus).toUpperCase()}
        </Typography>
        {
          disabled 
            ? null
            : 
            <ArrowDropDownIcon
              style={{ color: "#919CA5", marginLeft: 5, transform: open ? "rotate(180deg)" : "rotate(0deg)", cursor: "pointer" }}
              onClick={handleDropdown}
            />
        }
      </ListItem>
      {
        open &&
          <ClickAwayListener mouseEvent="onClick" onClickAway={() => setOpen(false)}>
              <div className={classes.list}>
                {statuses.map(renderStatusAsListItem)}
              </div>
          </ClickAwayListener>
      }
    </div>
  );
}