import { SectionType } from "./enums/section-type";

export class SectionUpdateRequest {
  establishmentAccountId?: string|null;
  id: string;
  type: SectionType;
  newName: string;
  newOrder: number;
  previousOrder: number | null;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.establishmentAccountId = x.establishmentAccountId;
      this.id = x.id;
      this.type = x.type;
      this.newName = x.newName;
      this.newOrder = x.newOrder;
      this.previousOrder = x.previousOrder;
    } else {
      this.establishmentAccountId = null;
      this.id = "";
      this.newName = "";
      this.type = SectionType.ClinicHistory;
      this.newOrder = 0;
      this.previousOrder = null;
    }
  }
}