import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function WhatsAppNotificationIcon(props: any) {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 24, height: 24, ...style }}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path 
        d="M11.8977 5.60087C13.4555 5.57519 14.9693 6.11915 16.1547 7.13066C17.3402 8.14218 18.1159 9.55171 18.3363 11.0947C18.5567 12.6378 18.2066 14.2082 17.3517 15.5114C16.4968 16.8145 15.196 17.7608 13.6932 18.1726C12.1905 18.5844 10.5892 18.4334 9.1898 17.748C9.0373 17.6733 8.865 17.6491 8.69784 17.6789L6.12694 18.1373L6.85272 16.4651C6.9638 16.2092 6.93357 15.9139 6.77295 15.6858C5.87564 14.4115 5.47402 12.8534 5.64347 11.304C5.81291 9.75452 6.54178 8.32018 7.69328 7.27014C8.84478 6.22011 10.3398 5.62655 11.8977 5.60087ZM17.1926 5.91333C15.7108 4.64893 13.8187 3.96899 11.8713 4.00109C9.92393 4.03319 8.05521 4.77513 6.61584 6.08768C5.17646 7.40023 4.26538 9.19315 4.05357 11.13C3.85838 12.9148 4.2694 14.7088 5.2129 16.2259L4.06617 18.8679C3.94882 19.1382 3.98959 19.4512 4.17227 19.6825C4.35495 19.9138 4.64994 20.0258 4.94001 19.9741L8.72864 19.2986C10.419 20.0576 12.3235 20.2069 14.1158 19.7157C15.9942 19.201 17.6203 18.0182 18.6889 16.3892C19.7575 14.7603 20.1951 12.7972 19.9196 10.8684C19.6441 8.93964 18.6745 7.17772 17.1926 5.91333ZM9.90669 8.41406C9.58391 8.41406 9.27435 8.54228 9.04611 8.77052C8.81787 8.99876 8.68965 9.30832 8.68965 9.6311V10.4425C8.68965 11.7336 9.20254 12.9718 10.1155 13.8848C11.0285 14.7977 12.2667 15.3106 13.5578 15.3106H14.3692C14.6919 15.3106 15.0015 15.1824 15.2297 14.9542C15.458 14.7259 15.5862 14.4164 15.5862 14.0936C15.5862 13.7708 15.458 13.4612 15.2297 13.233C15.0015 13.0048 14.6919 12.8765 14.3692 12.8765H13.5578C13.235 12.8765 12.9255 13.0048 12.6972 13.233C12.6152 13.3151 12.546 13.4077 12.4911 13.5076C12.0332 13.3482 11.6123 13.0867 11.2629 12.7373C10.9135 12.3879 10.652 11.9671 10.4927 11.5091C10.5926 11.4542 10.6852 11.3851 10.7673 11.303C10.9955 11.0748 11.1237 10.7652 11.1237 10.4425V9.6311C11.1237 9.30832 10.9955 8.99876 10.7673 8.77052C10.539 8.54228 10.2295 8.41406 9.90669 8.41406Z" 
        fill={style?.color || "#1D2027"}
      />
    </SvgIcon>
  );
}