import axios, { CancelToken } from "axios";

import { PredeterminedReportType } from "../models/enums/predetermined-report-type";
import { ApiClient } from "./api-client";
import { Report } from "../models/report";
import { ReportColumnGroup, ReportColumn } from "../models/report-column";
import { CustomReportPreviewRequest } from "../models/custom-report-preview-request";
import { PreviewCustomReport } from "../models/preview-custom-report";
import OrderedReportProperty from "../models/ordered-report-property";
import { ColumnGroupNameId, ColumnNameId } from "../models/enums/report-column-id";
import { EnterpriseCustomReportPreviewRequest } from "../models/enterprise-custom-report-preview-request";


export class ReportsService {
  private static baseUrl: string = "/reports";
  
  private static isoDate(date: Date): string {
    const year = date.toLocaleString("en-US", {
      year: "numeric",
    });
    const month = date.toLocaleString("en-US", {
      month: "2-digit",
    });
    const day = date.toLocaleString("en-US", {
      day: "2-digit",
    });
    
    return `${year}-${month}-${day}`;
  }
  
  public static handleTime(date: Date): string {
    const time = date.toLocaleString("en-US", {
      hourCycle: "h23",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit"
    });
    
    return `${this.isoDate(date)}T${time}`;
  }
  
  static getPredeterminedReport(dateFrom: Date, dateTo: Date, type: PredeterminedReportType): Promise<any> {
    let endpoint = `${this.baseUrl}/getPredeterminedReport?`
  
    endpoint += Object.entries({
      DateFrom: this.handleTime(dateFrom),
      DateTo: this.handleTime(dateTo),
      ReportType: type
    }).flatMap(([key, value]) => [value].flat().map(v => [key, v]))
      .map(it => it.join("="))
      .join("&")
    
    return ApiClient.get(endpoint, {
      responseType: 'arraybuffer',
      headers:
        {
          'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml'
        }
    }).then((data) => {
      return data;
    });
  }

    static downloadCustomReport = (
      dateFrom: Date,
      dateTo: Date,
      primaryPropertyId: string,
      properties: OrderedReportProperty[],
      reportName: string | null,
    ) =>
      async (setPercentage: (num: number) => void, cancelToken: CancelToken): Promise<Blob> =>
      {
        let endpoint = `${this.baseUrl}/downloadCustomReport`

        const request = {
          dateFrom: this.handleTime(dateFrom),
          dateTo: this.handleTime(dateTo),
          primaryPropertyId,
          properties,
          reportName: reportName
        }
    
        return ApiClient.post(endpoint, request, {
          responseType: 'blob',
          cancelToken: cancelToken
        }).then((data) => {
          return data;
        });
  }
  
    static downloadEnterpriseCustomReport = (
      dateFrom: Date,
      dateTo: Date,
      primaryPropertyId: string,
      properties: OrderedReportProperty[],
      reportName: string | null,
      establishmentIds: string[]
    ) =>
      async (setPercentage: (num: number) => void, cancelToken: CancelToken): Promise<Blob> =>
      {
        let endpoint = `${this.baseUrl}/downloadEnterpriseCustomReport`

        const request = {
          dateFrom: this.handleTime(dateFrom),
          dateTo: this.handleTime(dateTo),
          primaryPropertyId,
          properties,
          establishmentIds,
          reportName: reportName
        }
    
        return ApiClient.post(endpoint, request, {
          responseType: 'blob',
          cancelToken: cancelToken
        }).then((data) => {
          return data;
        });
  }
  
  static async createCustomReport(
      primaryPropertyId: string,
      properties: OrderedReportProperty[],
      reportName: string): Promise<Report> {
    let endpoint = `${this.baseUrl}/createCustomReport`

    const request = {
      primaryPropertyId,
      properties,
      reportName
    }

    return ApiClient.post(endpoint, request).then((data) => data);
  }
  
  static async deleteCustomReport(reportId: string): Promise<boolean> {
    let endpoint = `${this.baseUrl}/deleteCustomReport?id=${reportId}`
    await ApiClient.remove(endpoint);
    return true;
  }
  
  static async updateCustomReport(
      primaryPropertyId: string,
      properties: OrderedReportProperty[],
      reportId: string): Promise<Report> {
    let endpoint = `${this.baseUrl}/updateCustomReport`

    const request = {
      primaryPropertyId,
      properties,
      reportId
    }

    return ApiClient.post(endpoint, request).then((data) => data);
  }
  
  static async renameCustomReport(
      reportId: string,
      name: string): Promise<Report> {
    let endpoint = `${this.baseUrl}/renameCustomReport`

    const request = {
      reportId,
      name
    }

    return ApiClient.post(endpoint, request).then((data) => data);
  }
  
  static async getCustomReportsList(): Promise<Report[]> {
    let endpoint = `${this.baseUrl}/getCustomReportsList`;
    return ApiClient.get(endpoint).then((data) => {
      return data.reports.map((reportData: any) => new Report(reportData));
    });
  }
  
  static async getCustomReportPreview(requestData: CustomReportPreviewRequest): Promise<PreviewCustomReport> {
    let endpoint = `${this.baseUrl}/getCustomReportPreview`;
    return ApiClient.post(endpoint, requestData).then((data) => {
        return new PreviewCustomReport({
          properties: data.properties,
          resultNumbers: data.resultNumbers,
          dataTable: data.dataTable,
        });
    });
  }
  
  static async getEnterpriseCustomReportPreview(requestData: EnterpriseCustomReportPreviewRequest): Promise<PreviewCustomReport> {
    let endpoint = `${this.baseUrl}/getEnterpriseCustomReportPreview`;
    return ApiClient.post(endpoint, requestData).then((data) => {
        return new PreviewCustomReport({
          properties: data.properties,
          resultNumbers: data.resultNumbers,
          dataTable: data.dataTable,
        });
    });
  }
  
  static async getCustomReportsColumnList(): Promise<ReportColumnGroup[]> {
    let endpoint = `${this.baseUrl}/getcustomreportcolumns`;

    const tooltippedColumns: { [id: string]: string } = {
      [ColumnNameId.TotalToPayRecurringAppt]: "It's the sum of all recurring appointments",
      [ColumnNameId.AmountPaidRecurringAppt]: "It's the sum of all recurring appointments",
      [ColumnNameId.AmountDueRecurringAppt]: "It's the sum of all recurring appointments",
    }
    
    const orderGroup: { [id: string]: number } = {
      [ColumnGroupNameId.Appointments]: 1,
      [ColumnGroupNameId.Customer]: 2,
      [ColumnGroupNameId.Specialist]: 3,
      [ColumnGroupNameId.Commissions]: 4,
      [ColumnGroupNameId.Payments]: 5,
      [ColumnGroupNameId.Services]: 6,
      [ColumnGroupNameId.GeneralInformation]: 7,
    };
    
    const orderColumns: { [id: string]: number } = {
      // appointments
      [ColumnNameId.Appointment]: 1,
      [ColumnNameId.RecurringAppointment]: 2,
      [ColumnNameId.AppointmentStatus]: 3,
      [ColumnNameId.PaymentStatus]: 4,
      [ColumnNameId.AppointmentDate]: 5,
      [ColumnNameId.AppointmentTime]: 6,
      [ColumnNameId.AppointmentCreatedBy]: 7,

      // customer
      [ColumnNameId.Customer]: 1,
      [ColumnNameId.CustomerMobile]: 2,
      [ColumnNameId.CustomerGender]: 3,
      [ColumnNameId.CustomerContactableForAppointments]: 4,
      [ColumnNameId.CustomerContactableForMassiveSendings]: 5,
      
      // specialist
      [ColumnNameId.Specialist]: 1,
      [ColumnNameId.SpecialistID]: 2,
      [ColumnNameId.SpecialistEmail]: 3,
      [ColumnNameId.SpecialistJobTitle]: 4,
      
      // commissions
      [ColumnNameId.CommissionPerService]: 1,
      [ColumnNameId.CommissionAmount]: 2,
      
      // payments
      [ColumnNameId.PaymentID]: 1,
      [ColumnNameId.UserWhoRegisteredThePayment]: 2,
      [ColumnNameId.PaymentDate]: 3,
      [ColumnNameId.PaymentTime]: 4,
      [ColumnNameId.PaymentMethod]: 5,
      [ColumnNameId.TotalToPay]: 6,
      [ColumnNameId.AmountPaid]: 7,
      [ColumnNameId.AmountDue]: 8,
      [ColumnNameId.TotalToPayRecurringAppt]: 9,
      [ColumnNameId.AmountPaidRecurringAppt]: 10,
      [ColumnNameId.AmountDueRecurringAppt]: 11,

      // services
      [ColumnNameId.ServiceName]: 1,
      [ColumnNameId.ServicePrice]: 2,
      [ColumnNameId.ServiceCategory]: 3,
      [ColumnNameId.ServiceDuration]: 4,
      [ColumnNameId.ServiceRecurrence]: 5,
      [ColumnNameId.ServiceWorkArea]: 6,
      
      // general information
      [ColumnNameId.Establishment]: 1,
    };
    
    return ApiClient.get(endpoint).then((data) => {
      return data.propertyGroups.map((group: ReportColumnGroup) => {
        const columns = group.columns.map((col: ReportColumn) => {
          return new ReportColumn({ ...col,
            order: orderColumns[col.id],
            tooltip: tooltippedColumns[col.id]
          });
        });
        return new ReportColumnGroup({ ...group,
          order: orderGroup[group.id],
          columns });
      });
    });
  }
}