import React, { useEffect, useRef, useState } from "react";

import moment from "moment";

import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Dialog, DialogContent, DialogTitle, Tab, Tabs } from "@material-ui/core";

import useStyles from "./css";
import { useTranslation } from "react-i18next";
import { UserModalProps } from "./props";
import { UserEntity } from "../../../../../models/user-entity";
import UserModalGeneralInfo from "../UserModalGeneralInfo/user-modal-general-info";
import UserModalSchedules from "../UserModalSchedules";
import UserModalServices from "../UserModalServices";
import UserModalPermissions from "../UserModalPermissions";
import UserModalCommissions from "../UserModalCommissions";
import { TabContext, TabPanel } from "@material-ui/lab";
import UserCancelModal from "../UserCancelModal";
import UserModalStepper from "../UserModalStepper";
import UserContinueEditModal from "../UserContinueEditModal";
import { EmailRegex, NameRegexV2 } from "../../../../../constants/validator";
import { UserService } from "../../../../../api/user-service";
import { UserCommissionType } from "../../../../../models/enums/user-commission-type";
import { TerminologyType } from "../../../../../models/enums/terminology-type";
import { TerminologyForm } from "../../../../../models/enums/terminology-form";
import useTerminology from "../../../../../hooks/useTerminology";
import { selectAccountSettings } from "../../../../../redux/store";
import { useAppSelector } from "../../../../../redux/hooks";
import UserModalProfessionalInfo from "../UserModalProfessionalInfo";
import { UserGeneralInfo } from "../../../../../models/user-general-info";
import { UserPermissionsInfo } from "../../../../../models/user-permissions-info";
import { UserProfessionalInfo } from "../../../../../models/user-professional-info";
import { UserScheduleInfo } from "../../../../../models/user-schedule-info";
import { UserServicesInfo } from "../../../../../models/user-services-info";
import { UserCommissionInfo } from "../../../../../models/user-commission-info";
import UserModalProfessionalInfoRefs from "../UserModalProfessionalInfo/refs";
import { TabType } from "./user-modal-tab-type";
import { SignatureImageType } from "../../../../../models/enums/signature-image-type";
import { UserScheduleDay, UserScheduleWindow } from "../../../../../models/user-schedule-info";


interface IContinueModalState {
  open: boolean;
  appointmentCount: number;
}


export default function UserModal(props: UserModalProps) {
  
  const { settingsSelectedEstablishmentId, user, setUser, oldUser, open, setOpen, onClose, onCreate, mode, customUserSchedule } = props;
  
  const { t } = useTranslation("settings");

  const [activeStep, setActiveStep] = useState<number>(0);
  const [activeTab, setActiveTab] = useState<TabType>(TabType.General);
  const [cancelModalOpen, setCancelModalOpen] = useState<boolean>(false);
  const [continueModalState, setContinueModalState] = useState<IContinueModalState>({
    open: false,
    appointmentCount: 0
  });
  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState<boolean>(false);
  const [emailIsValid, setEmailIsValid] = useState<boolean>(false);
  const [previousJobPositionName, setPreviousJobPositionName] = useState<string>();
  const accountSettings = useAppSelector(selectAccountSettings);


  const timeFormat = 'HH:mm';
  
  const [isRefresh, setRefresh] = useState<any>();

  const professionalInfoTabRefs = useRef<UserModalProfessionalInfoRefs>(null);
  const signatureImageFileRef = useRef<File | null>(null);

  const classes = useStyles();

  const getContinueButtonTitle = mode === "edit"
    ? t("Save changes")
    : activeStep === getFinishStepNumber()
      ? t("Send invite")
      : t("Continue");
  const commissions = useTerminology({ type: TerminologyType.Commission, form: TerminologyForm.Plural });

  useEffect(() => {
    const jobPositionName = user?.general?.jobPosition?.name;

    if (previousJobPositionName !== "jp_Administrator" && jobPositionName !== "jp_Administrator")
      return;

    if (jobPositionName && jobPositionName !== previousJobPositionName) {
      const newPermissions = { 
          ...user.permissions,
          hasAdminAccess: jobPositionName === "jp_Administrator",
          showPrices: jobPositionName === "jp_Administrator"
      };
      const newUser = { ...user, permissions: newPermissions };
      setUser(newUser);
    }
    setPreviousJobPositionName(jobPositionName!);
  }, [user.general.jobPosition]);

  useEffect(() => {
    if (user.general.hasServicesInCharge)
      if (user.schedule.schedule.length === 0) {
        setUserScheduleInfo(customUserSchedule!);
      }
    if (mode == "new" && user.general.canPrescribeTreatment) {
      setUser({ ...user, professionalInformation: { ...user.professionalInformation, signType: SignatureImageType.Drawing } });
    }
  }, [user.general.hasServicesInCharge, user.general.canPrescribeTreatment, open]);


  function setUserGeneralInfo(value: UserGeneralInfo): void {
    setUser({ ...user, general: value });
  };

  function setUserProfessionalInfo(value: UserProfessionalInfo): void {
    setUser({ ...user, professionalInformation: value });
  };

  function setUserScheduleInfo(value: UserScheduleInfo): void {
    setUser({ ...user, schedule: value });
  };

  function setUserServiceInfo(value: UserServicesInfo): void {
    setUser({ ...user, services: value });
  };

  function setUserCommissionsInfo(value: UserCommissionInfo): void {
    setUser({ ...user, commission: value });
  };

  function setUserPermissionsInfo(value: UserPermissionsInfo): void {
    setUser({ ...user, permissions: value });
  };

  function setSignatureImage(image: File | null): void {
    signatureImageFileRef.current = image;
  }

  function handleCloseModal(): void {
    setOpen(false);
    resetForm();
    if (onClose) {
      onClose();
    }
  }

  function validateTab(tab: TabType): boolean {
    switch (tab) {
      case TabType.General:
        return validateGeneralInfo();
      case TabType.ProfessionalInfo:
        return validateProfessionalInfo();
      case TabType.Schedule:
        return validateSchedule();
      case TabType.Services:
        return validateServices();
      case TabType.Commissions:
        return validateCommissions();
      case TabType.Permissions:
        return true;
    }
  }

  function getFinishStepNumber(): number {
    return getActiveTabs().length - 1;
  }


  function getActiveTabs(): TabType[] {
    const noServices = userHasNoServicesInCharge();
    const noProfInfo = userCannotPrescribeTreatment();

    if (noServices && noProfInfo)
      return [TabType.General, TabType.Commissions, TabType.Permissions];

    if (noServices && !noProfInfo)
      return [TabType.General, TabType.ProfessionalInfo, TabType.Commissions, TabType.Permissions];

    if (!noServices && noProfInfo)
      return [TabType.General, TabType.Schedule, TabType.Services, TabType.Commissions, TabType.Permissions];

    if (!noServices && !noProfInfo)
      return [TabType.General, TabType.ProfessionalInfo, TabType.Schedule, TabType.Services, TabType.Commissions, TabType.Permissions];

    return [];
  }

  function getContentForTab(tab: TabType): JSX.Element {
    switch (tab) {
      case TabType.General:
        return GeneralInfoContent();
      case TabType.ProfessionalInfo:
        return ProfessionalInfoContent();
      case TabType.Schedule:
        return SchedulesContent();
      case TabType.Services:
        return ServicesContent();
      case TabType.Commissions:
        return CommissionsContent();
      case TabType.Permissions:
        return PermissionsContent();
    }
  }

  function getContentForCurrentStep(): JSX.Element {
    const currentTab = getCurrentTab()
    return getContentForTab(currentTab);
  }

  function getCurrentTab(): TabType {
    const activeTabs = getActiveTabs();
    return activeTabs[activeStep];
  }

  function resetForm(): void {
    setUser(new UserEntity());
    setActiveStep(0);
    setActiveTab(TabType.General);
    setPhoneNumberIsValid(false);
    setEmailIsValid(false);
  }

  function generalInfoIsEmpty(): boolean {
    return (user.general.name?.trim() === "" || user.general.name === null) &&
      (user.general.lastName?.trim() === "" || user.general.lastName === null) &&
      (user.general.email?.trim() === "" || user.general.email === null);
  }

  function validateName(name: string | null): boolean {
    if (name === null || name?.trim() === "")
      return false;
    return NameRegexV2.test(name);
  }

  function validateGeneralInfo(): boolean {
    if (!validateName(user.general.name))
      return false;
    if (!validateName(user.general.lastName))
      return false;
    if (user.general.jobPosition === null)
      return false;
    if (!user.general.email)
      return false;
    if (!phoneNumberIsValid)
      return false;
    if (!emailIsValid)
      return false;
    return EmailRegex.test(user.general.email);
  }

  function validateProfessionalInfo(): boolean {
    return !user.general.canPrescribeTreatment ||
      user.professionalInformation.fieldsAndValues
        .filter(fv => fv.field.isRequired)
        .every(fv => fv.value && fv.value.value && fv.value.value.toString().length > 0);
  }

  function validateSchedule(): boolean {
    return user.schedule.schedule.length === 7 &&
      !user.schedule.schedule.every(x => !x.isActive) &&
      validateScheduleWindows();
  }

  function validateScheduleWindows(): boolean {
    return user.schedule.schedule.every(validateScheduleDay);
  }

  function validateScheduleDay(day: UserScheduleDay): boolean {
    return day.windows.every((window, index) => {
      const newWindowsExceptCurrent = [...day.windows].filter(x => x !== day.windows[index])
      return validateScheduleWindow(window, index, newWindowsExceptCurrent);
    })
  }

  function validateScheduleWindow(window: UserScheduleWindow, index: number, otherWindows: UserScheduleWindow[]): boolean {
    const currentStartTime = moment(window.timeStart, timeFormat);
    const currentClosingTime = moment(window.timeClosing, timeFormat);
    return !otherWindows.some(x =>
      (currentStartTime >= moment(x.timeStart, timeFormat) &&
      currentStartTime <= moment(x.timeClosing, timeFormat)) ||
      (currentClosingTime >= moment(x.timeStart, timeFormat) &&
      currentClosingTime <= moment(x.timeClosing, timeFormat)))
  }

  function validateServices(): boolean {
    return !user.services.serviceCategories.every(x => x.services.every(x => !x.value));
  }

  function validateCommissions(): boolean {
    return (user.commission.userCommissionType === UserCommissionType.Default || !user.general.hasServicesInCharge) && user.commission.isCommissionEnabled
      ? !!user.commission.commissionValue && user.commission.commissionValue > 0 && user.commission.commissionType !== null
      : true;
  }

  function userHasNoServicesInCharge() {
    return !user.general.hasServicesInCharge;
  }

  function userCannotPrescribeTreatment() {
    return !user.general.canPrescribeTreatment || !accountSettings.isClinicMode;
  }

  function isFormValid() {
    return getActiveTabs().every(t => validateTab(t));
  }

  function userHasNotBeenEdited() {
    return JSON.stringify(oldUser) === JSON.stringify(user);
  }

  function isContinueButtonDisabled() {
    if (mode === "new") {
      return !validateTab(getCurrentTab());
    }
    else {
      return !isFormValid() || userHasNotBeenEdited();
    }
  }

  function executeSubmitAndCloseModal() {
    if (onCreate) {
      onCreate(user, signatureImageFileRef.current);
    }
    handleCloseModal();
  }

  async function handleFormSubmit(): Promise<void> {
    if (!isFormValid())
      return;
    if (mode === "new") {
      executeSubmitAndCloseModal();
      return;
    }

    if (!user.general.id)
      return;

    let newUserSchedule = JSON.stringify(user.schedule);
    let oldUserSchedule = JSON.stringify(oldUser.schedule);
    let newUserServices = JSON.stringify(user.services);
    let oldUserService = JSON.stringify(oldUser.services);
    let userAppointmentCount = 0;


    if (newUserServices !== oldUserService) {
      let disabledServiceIds = user.services.serviceCategories
        .flatMap(category => (user.general.hasServicesInCharge
          ? category.services.filter(service => !service.value)
          : category.services))
        .map(service => service.id);

      userAppointmentCount = await UserService.getAllUserAppointmentCount(user.general.id, disabledServiceIds, settingsSelectedEstablishmentId);
    } else if (newUserSchedule !== oldUserSchedule)
      userAppointmentCount = await UserService.getUserAppointmentCount(user.general.id, user.schedule);

    if (userAppointmentCount === 0) {
      executeSubmitAndCloseModal();
    }
    else {
      setContinueModalState({ open: true, appointmentCount: userAppointmentCount });
    }
  }

  async function handleTabChange(_: any, newTab: TabType): Promise<void> {
    const currentTab = getCurrentTab();
    const nextStep = getActiveTabs().indexOf(newTab);
    setActiveStep(nextStep);
    if (mode === "edit" && currentTab === TabType.ProfessionalInfo) {
      await professionalInfoTabRefs?.current?.postprocessUserSignature();
    }
    setActiveTab(newTab);
  }

  function handleGoBackButtonClick(): void {
    if (mode === "edit") {
      if (userHasNotBeenEdited()) {
        handleCloseModal();
      }
      else {
        setCancelModalOpen(true);
      }
    }
    else {
      if (activeStep === 0) {
        if (generalInfoIsEmpty()) {
          handleCloseModal();
        }
        else {
          setCancelModalOpen(true);
        }
      }
      else {
        setActiveStep((prev) => prev - 1);
      }
    }
  }

  function onCloseHandler(_: any, reason: any): void {
    if (reason === "backdropClick") {
      if (mode === "edit") {
        if (userHasNotBeenEdited()) {
          handleCloseModal();
        }
        else {
          setCancelModalOpen(true);
        }
      }
      else {
        if (generalInfoIsEmpty()) {
          handleCloseModal();
        }
        else {
          setCancelModalOpen(true);
        }
      }
    }
  }

  function handleEditModalClose(): void {
    setContinueModalState({ ...continueModalState, open: false });
  }

  function handleEditModalSubmit(): void {
    handleEditModalClose();
    executeSubmitAndCloseModal();
  }

  async function handleContinueButtonClick(): Promise<void> {
    if (getCurrentTab() === TabType.ProfessionalInfo) {
      await professionalInfoTabRefs?.current?.postprocessUserSignature();
    }
    if (mode === "edit" || activeStep === getFinishStepNumber()) {
      handleFormSubmit();
    }
    else {
      setActiveStep((prev) => prev + 1);
    }
  }

  const GeneralInfoContent = () =>
    <UserModalGeneralInfo
      settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
      userServices={user.services}
      userGeneralInfo={user.general}
      setUserGeneralInfo={setUserGeneralInfo}
      isRefresh={isRefresh}
      setRefresh={setRefresh}
      emailIsValid={emailIsValid}
      setPhoneNumberIsValid={setPhoneNumberIsValid}
      setEmailIsValid={setEmailIsValid} />;

  const ProfessionalInfoContent = () =>
    <UserModalProfessionalInfo
      ref={professionalInfoTabRefs}
      userProfessionalInfo={user.professionalInformation}
      setUserProfessionalInfo={setUserProfessionalInfo}
      setSignatureImage={setSignatureImage}
    />;

  const SchedulesContent = () =>
    <UserModalSchedules
      schedule={user.schedule}
      setSchedule={setUserScheduleInfo}
    />;

  const ServicesContent = () =>
    <UserModalServices
      userServiceInfo={user.services}
      setUserServiceInfo={setUserServiceInfo}
      mode={mode}
    />;

  const CommissionsContent = () =>
    <UserModalCommissions
      userCommissionsInfo={user.commission}
      setUserCommissionsInfo={setUserCommissionsInfo}
      selectedServiceIds={user.services.serviceCategories.flatMap(sc => sc.services.filter(x => x.value).map(s => s.id))}
      hasServicesInCharge={user.general.hasServicesInCharge}
      mode={mode}
    />;

  const PermissionsContent = () =>
    <UserModalPermissions
      userPermissionsInfo={user.permissions}
      setUserPermissionsInfo={setUserPermissionsInfo}
      mode={mode}
    />;


  return (
    <Dialog
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      classes={{
        root: classes.modal,
        container: classes.container
      }}
      open={open}
      onClose={onCloseHandler}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      PaperProps={{
        className: classes.paper,
        elevation: 0,
        square: false
      }}
      maxWidth={false}
      scroll="paper"
    >
      <DialogTitle
        className={classes.dialogHeader}
      >
        <div className={classes.modalHeader}>
          <div className={classes.modalTitle}>
            <Typography className={classes.modalTitleText} variant="h1">
              {mode === "new"
                ? t('New user')
                : t('User detail')
              }
            </Typography>
            {
              mode === "edit" &&
              <Typography className={classes.modalTitleUserId} variant="h1">
                {`ID:${user.general.friendlyId}`}
              </Typography>
            }
          </div>
          <div className={classes.modalActions}>
            <Button
              className={`${classes.button} ${classes.goBack}`}
              onClick={handleGoBackButtonClick}
            >
              {t('Go back')}
            </Button>
            <Button
              className={`${classes.button} ${classes.continueButton}`}
              disabled={isContinueButtonDisabled()}
              classes={{ disabled: classes.createDisabled }}
              onClick={handleContinueButtonClick}
            >
              {getContinueButtonTitle}
            </Button>
          </div>
        </div>
        {
          mode === "new"
            ? <UserModalStepper
              activeStep={activeStep}
              userHasServicesInCharge={user.general.hasServicesInCharge}
              userCanPrescribeTreatment={!userCannotPrescribeTreatment()}
            />
            :
            <TabContext
              value={activeTab.toString()}
            >
              <Tabs
                className={classes.tabs}
                value={activeTab}
                onChange={handleTabChange}
                indicatorColor="primary"
                textColor="primary"
              >
                <Tab
                  className={classes.tab}
                  label={t("General information")}
                  value={TabType.General}
                />
                {!userCannotPrescribeTreatment() && <Tab
                  className={classes.tab}
                  label={t("Professional information")}
                  value={TabType.ProfessionalInfo}
                />}
                {!userHasNoServicesInCharge() && <Tab
                  className={classes.tab}
                  label={t("Schedules")}
                  value={TabType.Schedule}
                />}
                {!userHasNoServicesInCharge() && <Tab
                  className={classes.tab}
                  label={t("UserModalServices")}
                  value={TabType.Services}
                />}
                <Tab
                  className={classes.tab}
                  label={commissions}
                  value={TabType.Commissions}
                />
                <Tab
                  className={classes.tab}
                  label={t("Permissions and notifications")}
                  value={TabType.Permissions}
                />
              </Tabs>
            </TabContext>
        }
      </DialogTitle>
      <DialogContent
        className={classes.dialogContent}
      >
        <Fade in={open}>
          <div>
            {
              mode === "new"
                ?
                <>
                  {getContentForCurrentStep()}
                </>
                :
                <>
                  <TabContext
                    value={activeTab.toString()}
                  >
                    <TabPanel
                      classes={{
                        root: classes.tabPanelRoot
                      }}
                      value={TabType.General.toString()}
                    >
                      {GeneralInfoContent()}
                    </TabPanel>
                    {!userCannotPrescribeTreatment() && <TabPanel
                      classes={{
                        root: classes.tabPanelRoot
                      }}
                      value={TabType.ProfessionalInfo.toString()}
                    >
                      {ProfessionalInfoContent()}
                    </TabPanel>}
                    {!userHasNoServicesInCharge() && <TabPanel
                      value={TabType.Schedule.toString()}
                      classes={{
                        root: classes.tabPanelRoot
                      }}
                    >
                      {SchedulesContent()}
                    </TabPanel>}
                    {!userHasNoServicesInCharge() && <TabPanel
                      value={TabType.Services.toString()}
                      classes={{
                        root: classes.tabPanelRoot
                      }}
                    >
                      {ServicesContent()}
                    </TabPanel>}
                    <TabPanel
                      value={TabType.Commissions.toString()}
                      classes={{
                        root: classes.tabPanelRoot
                      }}
                    >
                      {CommissionsContent()}
                    </TabPanel>
                    <TabPanel
                      value={TabType.Permissions.toString()}
                      classes={{
                        root: classes.tabPanelRoot
                      }}
                    >
                      {PermissionsContent()}
                    </TabPanel>
                  </TabContext>
                </>
            }
            <UserCancelModal
              open={cancelModalOpen}
              setOpen={setCancelModalOpen}
              title={mode === "new" ? t("Cancel user invitation?") : t("Cancel user edition?")}
              onSubmit={handleCloseModal}
            />
            <UserContinueEditModal
              open={continueModalState.open}
              setOpen={handleEditModalClose}
              appointmentCount={continueModalState.appointmentCount}
              onSubmit={handleEditModalSubmit}
            />
          </div>
        </Fade>
      </DialogContent>
    </Dialog>
  );
}