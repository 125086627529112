import React, { useState, useEffect, useCallback, useRef } from "react";

import { useTranslation } from "react-i18next";

import InfiniteScroll from 'react-infinite-scroller';

import { useDispatch, useSelector } from "react-redux";
import { RootState, selectAccountSettings } from "../../../redux/store";
import { connect } from "../../../redux/whatsapp-hub-slice";
import { useAppSelector } from "../../../redux/hooks";
import { navbarActions } from "../../../redux/navbar-slice";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Grid, Box } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";

import { useStyles } from "./css";
import { WhatsAppService } from "../../../api/whatsapp-service"
import { ConversationPreview } from "../../../models/conversation-preview";
import ConversationWindow from "./../ConversationWindow"
import ConversationsListItem from "./../ConversationsListItem";
import { Message } from "../../../models/message";
import { MessagesByCustomer } from "../../../models/messages-by-customer";
import { ReactComponent as NoConversationsImage } from "../../../assets/images/no-conversations.svg";
import InputSearch from "../../common/InputSearch/input-search";
import useDebounce from "../../../hooks/useDebounce";
import MessengerRefs from "./messenger-refs";
import EstablishmentsToolbar from "../../common/EstablishmentsToolbar";
import { SelectedConversation } from "../../../models/interfaces/selected-conversation";
import Select, { SelectItem } from "../../common/Select";
import { AvailabilityStatus } from "../../../models/enums/availability-status";


export default function Messenger(props: any) {
    const { t } = useTranslation(["general"]);
    const classes = useStyles();
    const firstPageSize = 10;
    const pageSize = 10;

    const [refresh, setRefresh] = useState<any>();
    const [nextPageNumber, setNextPageNumber] = useState<number>(2);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [hasMore, setHasMore] = useState<boolean>(false);
    const [conversations, setConversations] = useState<ConversationPreview[]>([]);
    const [currentChat, setCurrentChat] = useState<ConversationPreview>();
    const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
    const [searchQuery, setSearchQuery] = useState<string>("");
    const [selectedConversation, setSelectedConversation] = useState<SelectedConversation>({ customerPhoneNumber: null, establishmentId: null })
    const [searchWasRun, setSearchWasRun] = useState<boolean>(false);
    
    const [availabilityStatus, setAvailabilityStatus] = useState<AvailabilityStatus>(AvailabilityStatus.Away);

    const debouncedSearchQuery = useDebounce(searchQuery, 400);

    const messengerRef = useRef<MessengerRefs>(null);
    
    const accountSettings = useAppSelector(selectAccountSettings);
    const selectedEstablishments = useAppSelector(state => state.enterprise.selectedEstablishments);
    
    const availabilityStatuses: SelectItem[] = [
        {
            key: AvailabilityStatus.Available,
            value:
              <div style={{ whiteSpace: "pre-line" }}>
                  <FiberManualRecordIcon className={classes.availableCircle} style={{color: '#36CE91'}}/>
                  {t("Available")}
                  <br/>
                  <span className={classes.availableCaption}>
                    {t("You can respond to your customers")}
                   </span>
              </div>
        } as SelectItem,
        {
            key: AvailabilityStatus.Away,
            value:
              <div style={{ whiteSpace: "pre-line" }}>
                  <FiberManualRecordIcon  className={classes.availableCircle} style={{color: '#F15857'}}/>
                  {t("Away")}
                  <br/>
                  <span className={classes.availableCaption}>
                    {t("The chatbot will respond to your customers")}
                  </span>
              </div>
        } as SelectItem
    ];

    async function fetchSearchData() {
        if (debouncedSearchQuery.length < 3)
            return;

        setIsLoading(true);
        dispatch(navbarActions.setShowLoader(true));
    
        if (accountSettings.isEnterprise === null) return;
        const searchPreviews = accountSettings.isEnterprise
          ? await WhatsAppService.getSearchByEstablishments(debouncedSearchQuery, 1, firstPageSize, selectedEstablishments)
          : await WhatsAppService.getSearch(debouncedSearchQuery, 1, firstPageSize);        
        
        if (searchPreviews.length < firstPageSize)
            setHasMore(false);
        else
            setHasMore(true)

        dispatch(navbarActions.setShowLoader(false));

        setConversations(searchPreviews);
        const curConv = getCurrentConversation(searchPreviews);

        if (curConv)
            setCurrentChat(new ConversationPreview(curConv));
        else if (searchPreviews.length > 0) {
            setCurrentChat(new ConversationPreview(searchPreviews[0]));
            setSelectedConversation({ customerPhoneNumber: searchPreviews[0].customerMobile, establishmentId: searchPreviews[0].establishmentId })
        }
        else {
            setCurrentChat(undefined);
            setSelectedConversation({ customerPhoneNumber: null, establishmentId: null })
        }

        setIsLoading(false);
    }

    const getCurrentConversation = (conversations: ConversationPreview[]) => {
        const mobile = props.location?.state?.mobile;
        if (mobile === undefined || mobile === null) return;
        const curConv = conversations.find(c => c.customerMobile.includes(mobile));
        if (curConv === undefined) return;
        return curConv;
    }

    async function fetchData() {
        setIsLoading(true);
        dispatch(navbarActions.setShowLoader(true));
        
        if (accountSettings.isEnterprise === null) return;
        const conversationsPreviewList = accountSettings.isEnterprise 
          ? await WhatsAppService.getConversationsPreviewByEstablishments(1, firstPageSize, selectedEstablishments)
          : await WhatsAppService.getConversationsPreview(1, firstPageSize);
        
        dispatch(navbarActions.setShowLoader(false));
        
        const conversationsPreviews = conversationsPreviewList.conversationPreviews;
        setHasMore(conversationsPreviewList.hasMore);
        setAvailabilityStatus(conversationsPreviewList.availabilityStatus);
        setConversations(conversationsPreviews);

        if (currentChat === undefined || searchWasRun) {
            if (searchWasRun)
                setSearchWasRun(false);
            //if page is just loading, load chat from routing or first chat in list
            const curConv = getCurrentConversation(conversationsPreviews);

            if (curConv) {
                setCurrentChat(new ConversationPreview(curConv));
                setSelectedConversation({ customerPhoneNumber: curConv.customerMobile, establishmentId: curConv.establishmentId })
            }
            else if (conversationsPreviews.length > 0) {
                setCurrentChat(new ConversationPreview(conversationsPreviews[0]));
                setSelectedConversation({ customerPhoneNumber: conversationsPreviews[0].customerMobile, establishmentId: conversationsPreviews[0].establishmentId })
            }

        } else if (currentChat && selectedEstablishments && currentChat.establishmentId) {
            if (!selectedEstablishments.includes(currentChat.establishmentId)) {
                if (conversationsPreviews[0]) {
                    setCurrentChat(new ConversationPreview(conversationsPreviews[0]));
                    setSelectedConversation({ customerPhoneNumber: conversationsPreviews[0].customerMobile, establishmentId: conversationsPreviews[0].establishmentId })
                } else {
                    setCurrentChat(undefined);
                    setSelectedConversation({ customerPhoneNumber: null, establishmentId: null })
                }
            }
        } else if (currentChat)
        //if we received new chat list and current chat is not in the list, add it to list
        {
            if (!isDialogPresentInNewList(currentChat, conversationsPreviews)) {
                setConversations([...conversationsPreviews, currentChat])
            }
        }
        setIsLoading(false);
    }

    async function fetchMoreData() {
        if (isLoading) return;
        if (!hasMore) return;
        setIsLoading(true);

        dispatch(navbarActions.setShowLoader(true));
        
        if (accountSettings.isEnterprise === null) return;
        const conversationsPreviewsList = accountSettings.isEnterprise
          ? await WhatsAppService.getConversationsPreviewByEstablishments(nextPageNumber, pageSize, selectedEstablishments)
          : await WhatsAppService.getConversationsPreview(nextPageNumber, pageSize);
        
        dispatch(navbarActions.setShowLoader(false));
        
        setConversations([...conversations, ...conversationsPreviewsList.conversationPreviews])
        setHasMore(conversationsPreviewsList.hasMore);
        setNextPageNumber((prev) => prev + 1);
        
        setIsLoading(false);
    }
    async function fetchMoreSearchData() {
        if (isLoading) return;
        if (!hasMore) return;
        setIsLoading(true);

        dispatch(navbarActions.setShowLoader(true));

        if (accountSettings.isEnterprise === null) return;
        const searchPreviews = accountSettings.isEnterprise
          ? await WhatsAppService.getSearchByEstablishments(debouncedSearchQuery, nextPageNumber, firstPageSize, selectedEstablishments)
          : await WhatsAppService.getSearch(debouncedSearchQuery, nextPageNumber, firstPageSize);
        
        dispatch(navbarActions.setShowLoader(false));
        
        if (searchPreviews.length < firstPageSize)
            setHasMore(false);
        else
            setHasMore(true)
        setConversations([...conversations, ...searchPreviews])
        setNextPageNumber((prev) => prev + 1);

        setIsLoading(false);
    }
    
    async function handleSelectAvailabilityStatus(event: any) {
        let value = event.target.value;
        setAvailabilityStatus(value);
        await WhatsAppService.updateAvailabilityStatus(value);
    }
    
    function handleOnSelectAvailabilityRender(key: any) {
        return (
          <div>
              <FiberManualRecordIcon
                className={classes.availableCircle}
                style={{ marginBottom: 1, color: availabilityStatus === AvailabilityStatus.Available ? '#36CE91' : '#F15857' }}/>
              {t(AvailabilityStatus[key])}
          </div>
        );
    }
    
    async function onBlockCustomer(customerId: string, customerMobile: string, block: boolean) {
        await WhatsAppService.blockCustomer(customerId, customerMobile, block);
        
        const conversationIndex = conversations.findIndex(c => c.customerMobile === customerMobile);
        if (conversationIndex === -1) return;
        const newConversations = [...conversations];
        newConversations[conversationIndex].isCustomerBlocked = block;
        setConversations(newConversations);
    
        if (currentChat?.customerMobile === customerMobile) {
            const updatedChat = new ConversationPreview(currentChat);
            updatedChat.isCustomerBlocked = block;
            setCurrentChat(updatedChat);
        }
    };
    
    function onAvailabilityStatusUpdate(availabilityStatus: AvailabilityStatus) {
        setAvailabilityStatus(availabilityStatus);
    }

    useEffect(() => {
        document.body.classList.add(classes.hideScrollbars);
        let element = document.getElementById('root');
        element?.classList.add(classes.disableYScroll);
        return () => {
            document.body.classList.remove(classes.hideScrollbars);
            element?.classList.remove(classes.disableYScroll);
        };
    }, []);

    useEffect(() => {
        if (debouncedSearchQuery !== "") {
            return;
        }
        if (refresh) {
            setNextPageNumber(2)
        }
        fetchData();
    }, [refresh, debouncedSearchQuery, accountSettings.isEnterprise, selectedEstablishments]);

    useEffect(() => {
        setHasMore(false);
        setNextPageNumber(2);
        setConversations([]);
        if (debouncedSearchQuery.trim().length > 2) {
            setIsSearchMode(true);
            setSearchWasRun(true);
            fetchSearchData();
        }
        else {
            setIsSearchMode(false);
        }
    }, [debouncedSearchQuery]);
    
    useEffect(() => {
        if (accountSettings.isEnterprise === false) {
            dispatch(navbarActions.setExternalContent(<AvailabilityStatusSelect />));
        }
    }, [accountSettings.isEnterprise, availabilityStatus]);
    
    const AvailabilityStatusSelect = () => {
        return (
          <div className={classes.availabilityStatusContainer}>
              <Select
                items={availabilityStatuses}
                value={availabilityStatus}
                classes={{ root: `${classes.focused} ${classes.selected}` }}
                styleClass={classes.select}
                itemRootClass={classes.itemRoot}
                width={400}
                onRender={handleOnSelectAvailabilityRender}
                onChange={handleSelectAvailabilityStatus}
              />
          </div>
        );
    }

    const receiveMessages = async (messagesByCustomers: MessagesByCustomer[] | undefined) => {
        if (messagesByCustomers !== undefined) {
            if (currentChat) {
                //if all updated dialogs are present in current list, just update their order and last message
                if (messagesByCustomers.every(x => isDialogPresentInInboundMessageList(x, conversations))) {
                    let newConversations = [...conversations];

                    for (let i = 0; i < messagesByCustomers.length; i++) {
                        const conversationIndex = newConversations.findIndex(c => c.customerMobile == messagesByCustomers[i].customerMobile && c.establishmentId === messagesByCustomers[i].establishmentId);
                        const lastMessageInList = findLastMessage(messagesByCustomers[i]);
                        newConversations[conversationIndex].lastMessageTime = lastMessageInList.time;
                        newConversations[conversationIndex].lastMessageText = lastMessageInList.text;
                        newConversations[conversationIndex].lastMessageType = lastMessageInList.type
                        if (lastMessageInList.isInbound) {
                            newConversations[conversationIndex].isDisabledActivity = false;
                            newConversations[conversationIndex].unreadMessagesCount += messagesByCustomers[i].messages.filter(x => x.isInbound).length;
                        }
                    }

                    newConversations = newConversations.sort(sortConversationsByLastMessageTimeDesc);
                    setConversations(newConversations);

                }
                else { //download new list of dialogues
                    setRefresh(messagesByCustomers);
                }

                //if messages for current chat are present, add them to conversation
                const currentChatIndexInNewMessages = messagesByCustomers.findIndex(x => x.customerMobile === currentChat.customerMobile);

                await tryAddNewMessages(currentChatIndexInNewMessages, messagesByCustomers);
            }
        }
    }

    const tryAddNewMessages = async (currentChatIndexInNewMessages: number, messagesByCustomers: MessagesByCustomer[]) => {
        if (currentChatIndexInNewMessages !== -1) {
            messengerRef.current?.addNewMessages(messagesByCustomers[currentChatIndexInNewMessages].messages.sort(sortMessagesByTime));
            if (messagesByCustomers[currentChatIndexInNewMessages].messages.some(i => i.isInbound)) {
                const updatedChat = new ConversationPreview(currentChat);
                updatedChat.isDisabledActivity = false;
                setCurrentChat(updatedChat);
            }
            await WhatsAppService.markConversationAsRead(currentChat!.customerMobile);
        }
    }

    const onMarkConversationAsRead = (customerMobile: string) => {
        const conversationIndex = conversations.findIndex(c => c.customerMobile === customerMobile);
        if (conversationIndex === -1) return;
        const newConversations = [...conversations];
        newConversations[conversationIndex].unreadMessagesCount = 0;
        setConversations(newConversations);
    }

    const onChangeScheduledFollowUpState = (customerMobile: string, hasScheduledFollowUp: boolean) => {
        const conversationIndex = conversations.findIndex(c => c.customerMobile === customerMobile);
        if (conversationIndex === -1) return;
        const newConversations = [...conversations];
        newConversations[conversationIndex].hasScheduledFollowUp = hasScheduledFollowUp;
        setConversations(newConversations);

        if (currentChat?.customerMobile === customerMobile) {
            const updatedChat = new ConversationPreview(currentChat);
            updatedChat.hasScheduledFollowUp = hasScheduledFollowUp;
            setCurrentChat(updatedChat);
        }
    }

    const isDialogPresentInInboundMessageList = (dialog: MessagesByCustomer, array: ConversationPreview[]) => {
        return array.findIndex(e => e.customerMobile === dialog.customerMobile && e.establishmentId === dialog.establishmentId) !== -1;
    }

    const isDialogPresentInNewList = (dialog: ConversationPreview, array: ConversationPreview[]) => {
        return array.findIndex(e => e.customerMobile === dialog.customerMobile && e.establishmentId === dialog.establishmentId) !== -1;
    }

    const sortConversationsByLastMessageTimeDesc = (firstEl: ConversationPreview, secondEl: ConversationPreview) => {
        if (firstEl.lastMessageTime! > secondEl.lastMessageTime!) return -1;
        if (firstEl.lastMessageTime! < secondEl.lastMessageTime!) return 1;
        return 0;
    }

    const sortMessagesByTime = (firstEl: Message, secondEl: Message) => {
        if (firstEl.time! > secondEl.time!) return -1;
        if (firstEl.time! < secondEl.time!) return 1;
        return 0;
    }

    const findLastMessage = (msg: MessagesByCustomer) => {
        return msg.messages.reduce((a, b) => a.time! > b.time! ? a : b);
    }

    const markConversationAsRead = useCallback(async (customerMobile: string) => {
        const i = conversations.findIndex(c => c.customerMobile === customerMobile);
        if (conversations[i]?.unreadMessagesCount > 0) {
            currentChat?.establishmentId 
              ? await WhatsAppService.markConversationAsReadAsEstablishment(currentChat?.establishmentId, customerMobile)
              : await WhatsAppService.markConversationAsRead(customerMobile);
        }
    }, [currentChat?.customerMobile]);

    const dispatch = useDispatch();
    const whatsAppHubConnection = useSelector(
        (state: RootState) => state.whatsAppHub.connection
    );
    dispatch(connect());

    //unsubscribe first so subscriptions does not duplicate
    whatsAppHubConnection?.off("receiveMessages")
    whatsAppHubConnection?.off("markConversationsAsRead")
    whatsAppHubConnection?.off("updateAvailabilityStatus")
    whatsAppHubConnection?.off("changeScheduledFollowUpState")

    whatsAppHubConnection?.on("receiveMessages", (messages) => receiveMessages(messages))
    whatsAppHubConnection?.on("markConversationsAsRead", (customerMobile) => onMarkConversationAsRead(customerMobile))
    whatsAppHubConnection?.on("updateAvailabilityStatus", (availabilityStatus) => onAvailabilityStatusUpdate(availabilityStatus))
    whatsAppHubConnection?.on("changeScheduledFollowUpState", (customerMobile, hasScheduledFollowUp) => onChangeScheduledFollowUpState(customerMobile, hasScheduledFollowUp))


    return (
      <>
        <EstablishmentsToolbar />
        <div className={classes.messengerLayout}>
            <Grid container>
                <div>
                    <div className={classes.toolbarContainer}>
                        <InputSearch
                            style={{ width: 280, marginTop: 7 }}
                            placeholder={t("Search conversation")}
                            onSearch={fetchSearchData}
                            value={searchQuery}
                            onReset={() => setSearchQuery("")}
                            onChange={(e: any) => setSearchQuery(e.target.value)}
                        />
                    </div>
                    <Grid item className={classes.buildScrollbars} style={{ overflow: "auto" }}>
                        <InfiniteScroll
                            pageStart={0}
                            loadMore={isSearchMode ? fetchMoreSearchData : fetchMoreData}
                            hasMore={hasMore}
                            useWindow={false}
                            loader={<Box className={classes.loader}><CircularProgress /></Box>}
                        >
                            {
                                conversations.length === 0 && !isLoading
                                    ? <div className={classes.noConversationsContainer}>
                                        {isSearchMode ? t("No chats, messages or customers found") : ""}
                                    </div>
                                    :
                                    conversations.map((conversation) =>
                                        <ConversationsListItem
                                            key={conversation.lastMessageId}
                                            customerName={conversation.customerName}
                                            lastMessageText={conversation.lastMessageText}
                                            lastMessageTime={conversation.lastMessageTime}
                                            lastMessageType={conversation.lastMessageType}
                                            wasSendMore1DayAgo={conversation.wasSendMore1DayAgo}
                                            isDisabledActivity={conversation.isDisabledActivity}
                                            isDisabledNotContactable={conversation.isDisabledNotContactable}
                                            isSelected={currentChat && currentChat.lastMessageId === conversation.lastMessageId}
                                            unreadMessagesCount={conversation.unreadMessagesCount}
                                            hasScheduledFollowUp={conversation.hasScheduledFollowUp}
                                            onSelect={() => {
                                                setSelectedConversation({ customerPhoneNumber: conversation.customerMobile, establishmentId: conversation.establishmentId });
                                                setCurrentChat(new ConversationPreview(conversation));
                                            }}
                                            establishmentName={conversation.establishmentName}
                                        />)
                            }
                        </InfiniteScroll>
                    </Grid>
                </div>
                <Grid item>
                    {currentChat?.customerMobile === undefined || currentChat.customerMobile === ""
                        ? isLoading
                            ? <div></div>
                            : <div className={classes.noConversation}>
                                <div className={classes.noConversationImage}>
                                    <NoConversationsImage />
                                </div>
                                <div className={classes.noConversationText1}>
                                    {t("Ooops! There's nothing here")}
                                </div>
                                {isSearchMode
                                    ? <></>
                                    : <div className={classes.noConversationText2} >
                                        {t("At the moment, there are no conversations to show.")}
                                    </div>}
                            </div>
                        : <ConversationWindow
                            ref={messengerRef}
                            lastMessageId={currentChat?.lastMessageId}
                            customerId={currentChat?.customerId}
                            customerName={currentChat?.customerName}
                            customerMobile={currentChat.customerMobile}
                            wasSendMore1DayAgo={currentChat?.wasSendMore1DayAgo}
                            isDisabledActivity={currentChat?.isDisabledActivity}
                            isDisabledNotContactable={currentChat?.isDisabledNotContactable}
                            isCustomerBlocked={currentChat?.isCustomerBlocked}
                            unreadMessagesCount={currentChat.unreadMessagesCount}
                            canScheduleFollowUp={currentChat?.canScheduleFollowUp}
                            hasScheduledFollowUp={currentChat?.hasScheduledFollowUp}
                            hasUnpaidAppointment={currentChat?.hasUnpaidAppointment}
                            markConversationAsRead={markConversationAsRead}
                            isSearch={isSearchMode}
                            selectedConversation={selectedConversation}
                            foundMessageText={currentChat?.lastMessageText}
                            establishmentId={currentChat?.establishmentId}
                            establishmentName={currentChat?.establishmentName}
                            onBlockCustomer={onBlockCustomer}
                        />}
                </Grid>
            </Grid>
        </div>
      </>
    );
}