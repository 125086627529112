import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    label: {
        fontFamily: "Inter",
        display: "inline-flex",
        margin: 0,
        color: "#4a4d51",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "bold",
        fontStyle: "normal",
        marginBottom: 5,
    },
    labelSecondary: {
        fontFamily: "Roboto,sans serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        color: "#919CA5"
    },
    optional: {
        marginLeft: 3,
        fontFamily: "Inter",
        display: "inline-flex",
        margin: 0,
        color: "#4a4d51",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "normal",
        fontStyle: "normal",
        marginBottom: 5,
    },
    photosEstablishmentRow: {
        marginTop: 10,
        display: "table",
        width: "100%",
        tableLayout: "fixed",
        textAlign: "center"
    },
    photosEstablishmentCol: {
        display: "inline-grid",
        "&:first-child": {
            float: "left"
        },
        "&:last-child": {
            float: "right"
        }
    },
    photoEstablishment: {
        width: 230,
        height: 120,
        backgroundColor: "#FFF",
        borderRadius: 12,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
    },
    photoEstablishmentEmpty: {
        width: 230,
        height: 120,
        backgroundColor: "#F6F6F6",
        borderRadius: 12,
        border: "1px dashed #DBDCDD;",
        cursor: "pointer",
    },
    iconPlus: {
        marginTop: 40,
        height: 25,
        textAlign: "center",
        color: theme.colors.clearMain
    },
    labelAddPhoto: {
        marginTop: 0,
        fontWeight: "bold",
        fontSize: 12,
        textAlign: "center",
        color: "#919CA5"
    },
    labelAddPhotoIns: {
        marginTop: -5,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        color: "#919CA5",
        textAlign: "center",
    },
    photosEstablishmentControls: {
        height: 24,
        textAlign: "right",
        paddingTop: 5,
        paddingRight: 5
    },
    iconCamera: {
        color: "#919CA5",
        fontSize: 20,
        cursor: "pointer",
        marginLeft: 10,
        marginTop: 3,

    },
    iconTrash: {
        color: "#919CA5",
        fontSize: 24,
        cursor: "pointer",
        marginLeft: 10,
    },
    modalDelete: {
        height: 220
    },
    modalCenter: {
        display: "flex",
        justifyContent: "center",
    },
    modalTitleSpace: {
        margin: "24px 0px 8px 0px",
    },
    modalTitleText: {
        fontSize: "18px",
        lineHeight: "22px",
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: "bold",
        color: theme.colors.grey7
    },
}));

