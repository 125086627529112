import OrderedReportProperty from "./ordered-report-property";

export class EnterpriseCustomReportPreviewRequest {
  dateFrom: Date;
  dateTo: Date;
  primaryPropertyId: string;
  properties: OrderedReportProperty[];
  establishmentIds: string[];
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.dateFrom = x.dateFrom;
      this.dateTo = x.dateTo;
      this.primaryPropertyId = x.primaryPropertyId;
      this.properties = x.properties;
      this.establishmentIds = x.establishmentIds;
    } else {
      this.dateFrom = new Date();
      this.dateTo = new Date();
      this.primaryPropertyId = "";
      this.properties = [];
      this.establishmentIds = [];
    }
  }
}