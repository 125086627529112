import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    pageContent: {
      "&>nav": {
        marginTop: 120
      }
    },
    usersServices: {
      display: "flex",
      flexWrap: "wrap",
      gap: 16,
      overflowY: "auto",
      maxHeight: 333
    },
    userServices: {
      width: "calc(33% - 8px)",
      backgroundColor: "white",
      padding: 16,
      borderRadius: 12,
      fontFamily: "Inter, sans-serif",
    },
    userName: {
      color: theme.colors.clearMain,
      fontWeight: 700,
      fontSize: "18px"
    },
    servicesCount: {
      fontFamily: "Roboto, sans-serif",
      fontWeight: 700,
      fontSize: "14px",
      marginTop: 20
    },
    buttons: {
      display: "flex",
      gap: 8,
      marginTop: 20
    },
    button: {
      minWidth: 120,
      height: 32,
      borderRadius: 51,
      padding: "8.5px 16px",
      alignItems: "center",
      justifyContent: "center",
      fontFamily: "Inter, sans-serif",
      fontStyle: "normal",
      fontWeight: "bold",
      fontSize: 12,
      background: "#F6F6F6",
      color: theme.colors.grey5,
    }
  })
);

export default useStyles;