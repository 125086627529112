import { ApiClient } from "./api-client";
import BlockedReason from "../models/blocked-reason";
import { Response } from "../models/response";


export default class BlockedReasonService {

    private static baseUrl: string = "/blockedschedule";

  static getBlockedReason(): Promise<Response> {
    let urlEndpoint = `${this.baseUrl}/GetBlockedReason`;
    return ApiClient.get(urlEndpoint)
      .then((data) => {
        return new Response(data.blockedReasons, 0);
      });
  }

    static createBlockedReason(blockedreason: BlockedReason): Promise<BlockedReason> {
        return ApiClient.post(`${this.baseUrl}/createBlockedReason`, blockedreason)
            .then(data => {
                return new BlockedReason(data.blockedreason);
            });
    }

    static updateBlockedReason(blockedreason: BlockedReason): Promise<BlockedReason> {
        return ApiClient.put(`${this.baseUrl}/updateBlockedReason`, blockedreason)
            .then(data => {
                return new BlockedReason(data.blockedreason);
            });
    }

    static deleteBlockedReason(w: BlockedReason): Promise<any> {
        return ApiClient.remove(`${this.baseUrl}/deleteBlockedReason?id=${w.id}`)
            .then(data => {
                return {
                    entity: new BlockedReason(data.blockedreason),
                    forbiddenDelete: data.forbiddenDelete
                };
            });
    }

}
