export class Weekday {
    accountId: string | null;
    dayNumber: number;
    dayName: string;
    opening: string | null;
    closing: string | null;
    isOpen: boolean;

    constructor(x?: Weekday) {
        if (typeof x === "object" && x != null) {
            this.accountId = x.accountId ?? null;
            this.dayNumber = x.dayNumber || 0;
            this.dayName = x.dayName || "";
            this.opening = x.opening || "";
            this.closing = x.closing || "";
            this.isOpen = x.isOpen || false;
        }
        else {
            this.accountId = x!.accountId ?? null;
            this.dayNumber = x!.dayNumber ?? 0;
            this.dayName = x!.dayName || "";
            this.opening = x!.opening || "";
            this.closing = x!.closing || "";
            this.isOpen = x!.isOpen || false;
        }
    }
}

