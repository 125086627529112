export enum EnterpriseSettingsFieldUpdate {
    None = -1,
    All = 0,
    Name = 1,
    Logo = 2

}

export const getEnum = (field: string) => {
    let e: { [key: string]: EnterpriseSettingsFieldUpdate } = {};
    e["name"] = EnterpriseSettingsFieldUpdate.Name;
    e["logo"] = EnterpriseSettingsFieldUpdate.Logo;
    return e[field.toLowerCase()];
}