export const MobileMask = [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/];

export const CardNumberMask = [/\d/, /\d/, /\d/, /\d/, "-", 
                               /\d/, /\d/, /\d/, /\d/, "-",
                               /\d/, /\d/, /\d/, /\d/, "-",
                               /\d/, /\d/, /\d/, /\d/];

export const ClabeMask = [/\d/, /\d/, /\d/, /\d/, "-", 
                          /\d/, /\d/, /\d/, /\d/, "-",
                          /\d/, /\d/, /\d/, /\d/, "-",
                          /\d/, /\d/, /\d/, /\d/, "-",
                          /\d/, /\d/];

export function GetMobileMask(digits: number): any {
    
    const mask: { [key: string] : any } = {
        "7":  [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
        "8":  [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
        "9":  [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
        "10": [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
        "11": [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
        "12": [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/]
    };
    
    return mask[digits] || mask["10"];
}


export function GetMobileMaskByTemp(template: string): any {
    
    const mask: { [key: string] : any } = {
        "000-0000": [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
        "0000-0000": [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
        "0-000-0000": [/\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
        "00-000-000": [/\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
        "0000-00-00": [/\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, "-", /\d/, /\d/],
        "00-0000-000": [/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
        "000-000-000": [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
        "000 000-000": [/\d/, /\d/, /\d/, " ", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/],
        "000-000-0000": [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
        "00000-00000": [/\d/, /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/],
        "000-0000-0000": [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
        "000-000-000000": [/\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
        "0-000-000-0000": [/\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/],
        "00-0000-00000": [/\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, "-", /\d/, /\d/, /\d/, /\d/, /\d/]
    };
    
    return mask[template] || mask["000-000-0000"];
}