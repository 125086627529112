import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: 2,
  },
  mainItem: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    
    width: 171,
    padding: 0,
  },
  mainItemText: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey4,
    fontWeight: 700
  },
  list: {
    marginTop: 5,
    borderRadius: 5,
    boxShadow: "0px 4px 4px rgba(166, 166, 166, 0.25)",
  
    "& > :first-child": {
      borderTopLeftRadius: 5,
      borderTopRightRadius: 5,
    },
    "& > :last-child": {
      borderBottomLeftRadius: 5,
      borderBottomRightRadius: 5,
    },
  },
  itemDot: {
    paddingLeft: 17.75,
    paddingRight: 11.75,
  },
  item: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    
    width: 171,
    height: 40,
    padding: 0,
    
    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.grey1,
    
    backgroundColor: theme.colors.white,
    
    cursor: "pointer",
    
    "&:hover": {
      backgroundColor: theme.colors.grey1
    }
  },
  itemText: {
    ...theme.typography.buttons,
    color: theme.colors.grey5,
    fontWeight: 400,
  },
  selectedItemText: {
    color: theme.colors.clearMain,
    fontWeight: 700,
  },
}));

export default useStyles;
