import React from "react";

import { useTranslation } from "react-i18next";
import { Step, StepConnector, StepLabel, Stepper, withStyles } from "@material-ui/core";
import { Check } from "@material-ui/icons";

import { CustomStepperProps } from "../../../../models/interfaces/custom-stepper-props";
import useStyles, { useStepIconStyles } from "./css";

export default function MassiveSendingStepper(props: CustomStepperProps) {

  const classes = useStyles();
  const { t } = useTranslation("massivesendings");
  const { activeStep } = props;

  const StyledStepperConnector = withStyles((theme) => ({
    alternativeLabel: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    active: {
      '& $line': {
        borderColor: theme.colors.clearGreen,
      },
    },
    completed: {
      '& $line': {
        borderColor: theme.colors.clearGreen,
      },
    },
    line: {
      borderColor: theme.colors.grey2,
      borderTopWidth: 1,
      borderRadius: 1,
    },
  }))(StepConnector);

  function StepIcon(props: any) {
    const classes = useStepIconStyles();
    const { active, completed } = props;

    return (
      <div
        className={classes.root}
      >
        {completed ? <Check className={classes.completed} /> : active ? <div className={classes.activeCircle} /> : <div className={classes.circle} />}
      </div>
    );
  }
  
  return (
    <Stepper
      alternativeLabel
      activeStep={activeStep}
      connector={<StyledStepperConnector />}
    >
      <Step key={0}>
        <StepLabel
          StepIconComponent={StepIcon}
          classes={{
            alternativeLabel: classes.labelAlternativeLabel,
            completed: classes.labelCompleted
          }}
        >
          {t("Sending rules")}
        </StepLabel>
      </Step>
      <Step key={1}>
        <StepLabel
          StepIconComponent={StepIcon}
          classes={{
            alternativeLabel: classes.labelAlternativeLabel,
            completed: classes.labelCompleted
          }}
        >
          {t("WhatsApp template")}
        </StepLabel>
      </Step>
      <Step key={2}>
        <StepLabel
          StepIconComponent={StepIcon}
          classes={{
            alternativeLabel: classes.labelAlternativeLabel,
            completed: classes.labelCompleted
          }}
        >
          {t("Preview")}
        </StepLabel>
      </Step>
    </Stepper>
  );
}