import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    
    width: 40,
    height: 40
  },
  ring: {
    width: 40,
    height: 40,
    position: "absolute"
  },
  svg: {
    transform: "rotate(-90deg)"
  },
  circle: {
    fill: "transparent",
    strokeWidth: 2,
    strokeLinecap: "round",
  }
  })
);

export default useStyles;