import React from 'react';

import { Link, useRouteMatch } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Tab, Tabs } from "@material-ui/core";

import { Url } from "../../../constants/url";
import useStyles from './css';
import { useAppSelector } from "../../../redux/hooks";
import { selectAccountSettings } from "../../../redux/store";


export default function ReportsNavTabs() {
  
  const accountSettings = useAppSelector(selectAccountSettings);
  
  const routeMatch = useRouteMatch({
    path: [
      Url.Reports.Main,
      Url.Reports.CustomReport
    ],
    exact: true
  });
  
  const currentTab = routeMatch?.path;
  
  const { t } = useTranslation(["general"]);
  
  const classes = useStyles();
  
  return (
    <>
      <div className={classes.headerBack} />
      <div className={classes.mainTab}>
        <div className={classes.tabBar}>
          <Tabs
            value={currentTab}
            indicatorColor="primary"
            textColor="primary"
            className={classes.tabs}
          >
            {!accountSettings.isEnterprise && (<Tab
              className={classes.tab}
              label={t("Predefined reports")}
              value={Url.Reports.Main}
              to={Url.Reports.Main}
              component={Link}
            />)}
            <Tab
              className={classes.tab}
              label={t("Customizable reports")}
              value={Url.Reports.CustomReport}
              to={Url.Reports.CustomReport}
              component={Link} 
            />
          </Tabs>
        </div>
      </div>
    </>
  );
}