import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";
import useStyles from "./css";
import { PatientImportEvolutionNoteClinicalFileModalProps } from "./props";
import { Box, Grid } from "@material-ui/core";
import moment from "moment";

import { PDFIcon, UploadFileCloud, WordIcon, ImageIcon, } from "../../../assets/icons";
import { alertsActions } from "../../../redux/alerts-slice";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import TextareaWithLabel from "../../common/TextareaWithLabel";
import { EvolutionNoteClinicalFile } from "../../../models/evolution-notes-grouped";
import { ApiClient } from "../../../api/api-client";
import ConfirmActionModal from "../../common/ConfirmActionModal";
import { selectAccountSettings } from "../../../redux/store";

const PatientImportEvolutionNoteClinicalFileModal = (props: PatientImportEvolutionNoteClinicalFileModalProps) => {
  const { t } = useTranslation(["general"]);
  const { open, onClose, evolutionNote } = props;
  const classes = useStyles();
  const accountSettings = useAppSelector(selectAccountSettings)
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);
  const [cancelModalShouldAppear, setCancelModalShouldAppear] = useState<boolean>(false);
  const username = ApiClient.getUserInfo().fullName;
  const [file, setFile] = useState<FileObject[]>([]);
  const [fileSize, setFileSize] = useState<number>(0);
  const [fileName, setFileName] = useState<string>(t("No file attached."));
  const [fileComment, setFileComment] = useState<string>("");
  const [fileExtension, setFileExtension] = useState<string>("pdf");
  const [isValid, setIsValid] = useState<boolean>(false);
  const maxFileSize: number = 1 * 1024 * 10240;
  
  const { enqueueAlert } = alertsActions;
  const dispatch = useAppDispatch();
  
  useEffect(() => {
    setOpenModal(open);
    if (open === true) {
      cleanupModal();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  
  const handleTryCloseModal = () => {
    if (cancelModalShouldAppear) {
      setOpenCancelModal(true);
    } else {
      handleCloseModal();
    }
  };
  
  const handleCloseModal = () => {
    setOpenModal(false);
    if (onClose) {
      setFileExtension("pdf");
      onClose();
    }
  };
  
  const handleOnUploadClick = async () => {
    handleCloseModal();
    if (isValid) {
      setIsValid(false);
      let fileExtension = file[0].file.name.split(".").pop();
      let fileType = "multipart/form-data";
      switch (fileExtension) {
        case "pdf":
          fileType = 'application/pdf';
          break;
        case "docx":
          fileType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
          break;
        case "jpg":
          fileType = 'image/jpg';
          break;
        case "png":
          fileType = 'image/png';
          break;
      }
      let url = window.URL.createObjectURL(new Blob([file[0].file], { type: fileType }));

      evolutionNote?.clinicalFiles.push(new EvolutionNoteClinicalFile({
        fileName: file[0].file.name,
        fileComment: fileComment,
        fileURL: url,
        fileBlob: new Blob([file[0].file], { type: fileType }),
        created: new Date(moment().utc().tz(accountSettings.accountIanaTimezone).format("YYYY-MM-DD HH:mm")),
        uploadedBy: username,
      }));
    }
  };
  
  const handleOnAddFile = (files: FileObject[]) => {
    setFile(files);
    setFileName(files[0].file.name);
    let size = files[0].file.size / (1024 * 1024);
    setFileSize(Math.round((size + Number.EPSILON) * 100) / 100); // in megabytes
    setIsValid(validateFile(files[0].file));
    
    setCancelModalShouldAppear(true);
  };
  
  const handleDropRejected = (file: File[]) => {
    let fileExtension = file[0].name.split(".").pop();
    let allowedExtension = ["png", "jpg", "docx", "pdf"];

    if (allowedExtension.indexOf(fileExtension!) == -1) {
      dispatch(
        enqueueAlert({
          type: "Error",
          title: t("Error"),
          description: t("Format not allowed")
        })
      );
      return;
    } else {
      setFileExtension(fileExtension ?? 'pdf');
    }
    
    if (file[0].size > maxFileSize) {
      dispatch(
        enqueueAlert({
          type: "Error",
          title: t("Error"),
          description: t("The file exceeds the maximum size allowed")
        })
      );
    }
  };
  
  const validateFile = (file: File) => {
    if (file.size > maxFileSize)
      //10 megabyte
      return false;
    let fileExtension = file.name.split(".").pop();
    let allowedExtension = ["png", "jpg", "docx", "pdf"];
    let isValid = allowedExtension.indexOf(fileExtension!) > -1;
    if (isValid) {
      setFileExtension(fileExtension ?? "pdf");
    }
    return (allowedExtension.indexOf(fileExtension!) > -1);
  };
  
  const cleanupModal = () => {
    setFile([]);
    setFileName(t("No file attached."));
    setFileSize(0);
    setFileComment("");
    setIsValid(false);
    setCancelModalShouldAppear(false);
    setOpenCancelModal(false);
  };
  
  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setFileComment(value);
  };
  
  const CancelNewEvolutionNoteModalContent: React.FC = () => {
    return (<div className={classes.closeModalContentContainer}>
      <Typography className={classes.modalTitleTextCancel}>
        {t("Cancel file upload?")}
      </Typography>
    </div>);
  };
  
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={handleTryCloseModal}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={openModal}>
          <div>
            <div
              className={classes.paper}
            >
              <div className={classes.modalHeader}>
                <div className={classes.modalTitle}>
                  <Typography className={classes.modalTitleText} variant="h1">
                    {t("Upload files")}
                  </Typography>
                </div>
                <div className={classes.modalActions}>
                  <Button
                    className={`${classes.button} ${classes.goBack}`}
                    onClick={handleTryCloseModal}
                  >
                    {t("Cancel")}
                  </Button>
                  <Button
                    className={`${classes.button} ${classes.createCustomer}`}
                    disabled={!isValid}
                    classes={{ disabled: classes.createDisabled }}
                    onClick={handleOnUploadClick}
                  >
                    {t("Upload file")}
                  </Button>
                </div>
              </div>
              <Box sx={{ width: "100%" }} pt={1}>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <DropzoneAreaBase
                      dropzoneClass={classes.dropZone}
                      dropzoneParagraphClass={classes.dropZoneText}
                      fileObjects={file}
                      dropzoneText={t(
                        "Drag or click here to upload your file"
                      )}
                      onAdd={handleOnAddFile}
                      showPreviewsInDropzone={false}
                      showAlerts={false}
                      filesLimit={1}
                      acceptedFiles={[
                        "application/pdf,.docx,.jpg,.png",
                      ]}
                      maxFileSize={maxFileSize}
                      onDropRejected={handleDropRejected}
                      // @ts-ignore: type negotiation problem
                      Icon={UploadFileCloud} // works and builds but https://github.com/Yuvaleros/material-ui-dropzone/issues/246
                    />
                  </Grid>
                  {fileExtension === "pdf" && <Grid item xs={1} style={{ paddingLeft: "48px", paddingTop: 25 }}>
                      <PDFIcon
                          style={isValid ? { color: "#6462F3" } : {}}
                      />
                  </Grid>}
                  {fileExtension === "docx" && <Grid item xs={1} style={{ paddingLeft: "38px", paddingTop: 16 }}>
                      <WordIcon
                          style={isValid ? { color: "#6462F3" } : {}}
                      />
                  </Grid>}
                  {(fileExtension === "jpg" || fileExtension === "png") && <Grid item xs={1} style={{ paddingLeft: "38px", paddingTop: 16 }}>
                      <ImageIcon
                          style={isValid ? { color: "#6462F3" } : {}}
                      />
                  </Grid>}
                  <Grid
                    item
                    xs={5}
                    style={{ paddingLeft: "48px", paddingTop: "30px", wordWrap: "break-word" }}
                  >
                    <Typography className={classes.fileName}>
                      {fileName}
                    </Typography>
                    <Typography className={classes.fileSize}>
                      {t("Size: ")}
                      <b>{fileSize} MB</b>
                    </Typography>
                  </Grid>
                </Grid>
                
                <TextareaWithLabel
                  name="comments"
                  value={fileComment}
                  onChange={handleInputChange}
                  width="100%"
                  inputClass={classes.inputClass}
                  showCounter={true}
                  label={() => (<>
                    <Typography className={classes.commentsLabel}>
                      {t("Comments")}
                    </Typography>
                  </>)}
                  labelClass={classes.label}
                  placeholder={t("Write your comments here")}
                  maxLength={180}
                />
                
                <hr className={classes.divider}/>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <Typography className={classes.notesTitle}>
                      {t("NOTES: ")}
                    </Typography>
                    <Typography className={classes.notes}>
                      {t("Accepted formats .jpg . png .docx .pdf")}
                    </Typography>
                    <Typography className={classes.notes}>
                      {t("Maximum allowed file size: 10MB")}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    container
                    direction="row"
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div>
              <ConfirmActionModal
                onClose={() => setOpenCancelModal(false)}
                open={openCancelModal}
                onConfirm={handleCloseModal}
                content={<CancelNewEvolutionNoteModalContent/>}
              />
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
};

export default PatientImportEvolutionNoteClinicalFileModal;
