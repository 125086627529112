import React, { useEffect, useState } from 'react';

import { Trans, useTranslation } from "react-i18next";

import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";

import { useDispatch } from "react-redux";
import { useAppSelector } from "../../../../../redux/hooks";
import { selectAccountSettings } from "../../../../../redux/store";
import { applicationInterfaceActions } from "../../../../../redux/application-interface-slice";
import { alertsActions } from "../../../../../redux/alerts-slice";
import { navbarActions } from "../../../../../redux/navbar-slice";
import { fileDownloadActions } from "../../../../../redux/file-downloader-slice";

import { Box, Button, CircularProgress, FormControlLabel, Grid, RadioGroup, Typography } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";

import useStyles from "./css";
import useControlLabel from "../../../../../hooks/useControlLabel";
import DateInput from "../../../../common/DateInput";
import { SelectItem } from "../../../../../models/interfaces/select-item";
import { Report } from "../../../../../models/report";
import SelectKeyEditable from "../../../../common/SelectKeyEditable";
import DotsVerticalIcon from "../../../../../assets/icons/DotsVerticalIcon";
import ReportsNavTabs from "../../../ReportsNavTabs";
import { ReportsService } from "../../../../../api/reports-service";
import { ReportColumn, ReportColumnGroup } from "../../../../../models/report-column";
import CheckboxWithLabel from "../../../../common/CheckboxWithLabel";
import Radio from "../../../../common/Radio";
import IconTooltip from "../../../../common/IconTooltip";
import { CloseIcon, InfoIcon, ReportPersonIcon, TrashIcon } from "../../../../../assets/icons";
import DraggableBase from "../../../../common/DraggableBase";
import InputSearch from "../../../../common/InputSearch";
import { CustomReportPreviewRequest } from '../../../../../models/custom-report-preview-request';
import moment from "moment/moment";
import OrderedReportProperty from '../../../../../models/ordered-report-property';
import SaveReportModal from "../../../SaveReportModal";
import ConfirmActionModal from "../../../../common/ConfirmActionModal";
import GridList from "../../../../common/grid/GridList";
import { TableColumn } from "../../../../common/grid/table-column";
import { ColumnGroupNameId } from "../../../../../models/enums/report-column-id";
import SearchIcon from "../../../../../assets/icons/SearchIcon";
import EstablishmentsToolbar from "../../../../common/EstablishmentsToolbar";
import { EnterpriseCustomReportPreviewRequest } from "../../../../../models/enterprise-custom-report-preview-request";


export default function CustomReportsPage() {
  
  const classes = useStyles();
  const { t } = useTranslation("general");
  
  const dispatch = useDispatch();

  const { enqueueAlert } = alertsActions;
  const { startDownload } = fileDownloadActions;
  const { updateIsWideLayout } = applicationInterfaceActions;
  
  const todayDate = new Date();
  const lastMonthDate = addMonths(new Date(), -1);
  const nextDayOfLastMonthDate = new Date();
  
  const [openModalActionDots, setOpenModalActionDots] = useState<boolean>(false);
  const [saveReportModalIsOpen, setSaveReportModalIsOpen] = useState<boolean>(false);
  const [cancelEditReportModalIsOpen, setCancelEditReportModalIsOpen] = useState<boolean>(false);
  
  const [startDate, setStartDate] = useState<Date>(lastMonthDate);
  const [endDate, setEndDate] = useState<Date>(nextDayOfLastMonthDate);
  
  const [reports, setReports] = useState<Report[]>([]);
  const [selectedReport, setSelectedReport] = useState<Report | null>(null);
  const [deletableReportName, setDeletableReportName] = useState<string>("");
  
  const [columnGroups, setColumnGroups] = useState<ReportColumnGroup[]>([]);
  const [selectedColumns, setSelectedColumns] = useState<ReportColumn[]>([]);
  const [primaryColumn, setPrimaryColumn] = useState<ReportColumn | null>(null);
  const [previewGridData, setPreviewGridData] = useState<{ [key: string]: string }[]>();
  
  const [columnSearchQuery, setColumnSearchQuery] = useState<string>("");
  
  const selectedEstablishments = useAppSelector(state => state.enterprise.selectedEstablishments); 
  const noSavedReports = new Report({
    id: "NoSavedReports",
    name: t("No saved reports")
  });
  
  const allColumns = columnGroups.flatMap(group => group.columns);
  
  const selectItems: SelectItem[] = reports.map(report => ({
    key: report.id,
    value: report.name,
    disabled: report.id === noSavedReports.id
  }));
  
  const isDownloading = useAppSelector(state => state.fileDownloader.isDownloading);
  
  const locale = useAppSelector(state => state.applicationInterface.locale);
  const accountSettings = useAppSelector(selectAccountSettings);
  const accountName = accountSettings.accountName;
  
  const dateFormatPlaceholder = locale === "en" ? "mm/dd/yy" : "dd/mm/yy";
  const dateFormat = locale === "en" ? "MM/dd/yy" : "dd/MM/yy";
  const fileDateFormat = locale === "en" ? "MM-DD-YYYY" : "DD-MM-YYYY";
  
  const StartDateLabel = useControlLabel({ label: t("Start date"), required: true, labelClass: classes.dateLabel, })
  const EndDateLabel = useControlLabel({ label: t("End date"), required: true, labelClass: classes.dateLabel, })
  
  function addDays(date: Date, days: number): Date {
    let result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  
  function addMonths(date: Date, months: number): Date {
    let result = new Date(date);
    result.setMonth(result.getMonth() + months);
    return result;
  }
  
  useEffect(() => {
    dispatch(updateIsWideLayout(true));
  });
  
  useEffect(() => {
    (async () => {
      await loadReports();
      await loadColumns();
    })();
  }, []);
  
  useEffect(() => {
    (async () => {
      if (primaryColumn) {
        await loadPreview();
      }
    })();
  }, [selectedColumns.length, primaryColumn, startDate, endDate, selectedEstablishments]);
  
  
  async function loadPreview() {
    dispatch(navbarActions.setShowLoader(true));
    const requestData = {
      dateFrom: ReportsService.handleTime(startDate),
      dateTo: ReportsService.handleTime(endDate),
      primaryPropertyId: primaryColumn?.id!,
      properties: selectedColumns.map(c => new OrderedReportProperty(c.order, c.id))
    };
    const establishmentIds = selectedEstablishments || [];
    
    const reportsData = !accountSettings.isEnterprise
      ? await ReportsService.getCustomReportPreview(new CustomReportPreviewRequest(requestData))
      : await ReportsService.getEnterpriseCustomReportPreview(new EnterpriseCustomReportPreviewRequest({ ...requestData, establishmentIds }));
    dispatch(navbarActions.setShowLoader(false));
    
    const properties = reportsData.properties.sort((s1, s2) => s1.order! - s2.order!);
    const transformedData = reportsData.dataTable.map(row => transformPreviewRowData(row, properties));
    setPreviewGridData(transformedData);
  }
  
  function transformPreviewRowData(row: Record<string, any>[], properties: OrderedReportProperty[]) {
    const rowData: { [key: string]: any } = {};
    row.forEach((cell, index) => {
      const propertyId = properties[index].id;
      rowData[propertyId] = cell.value;
    });
    return rowData;
  }
  
  function getResultString(numberOfResults: number | undefined) {
    if (!numberOfResults) return '0';
    if (numberOfResults < 20) return `${numberOfResults}`;
    return `20+`;
  }
  
  function createPreviewGridColumn(reportColumn: ReportColumn): TableColumn {
    const countResults = (id: string) => previewGridData?.filter((entry: Record<string, any>) => entry[id] !== null);
    const keyExists = (id: string) => previewGridData?.some(obj => obj.hasOwnProperty(id));
    
    const numberOfResults = countResults(reportColumn.id);
    const shouldShowResults = numberOfResults !== undefined && keyExists(reportColumn.id);
    
    return {
      id: reportColumn.id,
      label: (
        <div>
          {reportColumn.name.toUpperCase()}
          <br />
          {shouldShowResults && (
            <span className={classes.totalResultText}>
            {`${getResultString(numberOfResults?.length)} ${t('results')}`}
          </span>
          )}
        </div>
      ),
      sorting: false,
      width: 180,
      maxWidth: 180,
      minWidth: 180,
      headerTextAlign: 'center',
      rowStyle: { whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' },
      cellStyle: { borderRight: '1px solid rgba(224, 224, 224, 1)' },
      cellHeaderStyle: { borderRight: '1px solid rgba(224, 224, 224, 1)' },
    };
  }
  
  function getPreviewGridColumns(): TableColumn[] {
    const updatedColumns: TableColumn[] = selectedColumns
      .sort((a, b) => a.order - b.order)
      .map(createPreviewGridColumn);
    
    if (primaryColumn) {
      updatedColumns.unshift(createPreviewGridColumn(primaryColumn));
    }
    return updatedColumns;
  }
  
  const previewGridColumns: TableColumn[] = getPreviewGridColumns();
  
  async function loadReports() {
    dispatch(navbarActions.setShowLoader(true));
    let reportsData = await ReportsService.getCustomReportsList();
    if (reportsData.length === 0) {
      reportsData.push(noSavedReports);
    } else {
      reportsData.forEach(report => {
        report.properties.forEach(property => {
          property.name = t(property.name);
        });
      });
    }
    
    dispatch(navbarActions.setShowLoader(false));
    setReports(reportsData);
  }
  
  async function loadColumns() {
    dispatch(navbarActions.setShowLoader(true));
    let columnsData = await ReportsService.getCustomReportsColumnList();
    if (!accountSettings.isEnterprise) {
      columnsData = columnsData.filter(item => item.id !== ColumnGroupNameId.GeneralInformation);
    }
    let translatedColumnGroups = translateNames(columnsData);
    setColumnGroups(translatedColumnGroups);
    dispatch(navbarActions.setShowLoader(false));
  }
  
  function translateNames(columnsData: ReportColumnGroup[]): ReportColumnGroup[] {
    return columnsData.map((grouped) => {
      grouped.name = t(grouped.name);
      grouped.columns = grouped.columns.map((column) => {
        column.name = t(column.name);
        return column;
      });
      return grouped;
    });
  }
  
  async function onSaveReportName(item: SelectItem) {
    dispatch(navbarActions.setShowLoader(true));
    let updatedReport = await ReportsService.renameCustomReport(item.key, item.value);
    dispatch(navbarActions.setShowLoader(false));
    if (updatedReport) {
      if (selectedReport?.id === item.key) {
        const updatedReport = { ...selectedReport, name: item.value } as Report;
        setSelectedReport(updatedReport);
      }
      const updatedReports = reports.map(report => {
        if (report.id === updatedReport.id) {
          return { ...report, name: updatedReport.name };
        }
        return report;
      });
      setReports(updatedReports);
    }
  }
  
  async function onDeleteReport(item: SelectItem) {
    dispatch(navbarActions.setShowLoader(true));
    let data = await ReportsService.deleteCustomReport(item.key);
    dispatch(navbarActions.setShowLoader(false));
    if (data) {
      const updatedReports = reports.filter(report => report.id !== item.key);
      
      if (updatedReports.length === 0) {
        updatedReports.push(noSavedReports);
      }
      
      if (item.key === selectedReport?.id) {
        setPrimaryColumn(null);
        setSelectedColumns([]);
      }
      
      dispatch(
        enqueueAlert({
          type: "Success",
          title: t("Report deleted"),
          description: deletableReportName,
        })
      );
      
      setReports(updatedReports);
    }
  }
  
  const DeleteModalContent = () => {
    return (
      <div className={classes.deleteModalContentContainer}>
        <Typography className={classes.deleteModalTitleText}>
          {t("Delete custom report?")}
        </Typography>
        <div className={classes.separator}/>
        <Typography className={classes.deleteModalContentReportName}>
          {deletableReportName}
        </Typography>
        <Typography className={classes.deleteModalContentText}>
          {t("This report will be permanently deleted.")}
        </Typography>
      </div>
    );
  }

  function getReportFileName(startDate: Date, endDate: Date, reportName?: string) {
    const startDateFormatted = moment(startDate).format(fileDateFormat)
    const endDateFormatted = moment(endDate).format(fileDateFormat)
    const reportNameString = reportName ? ` ${reportName} -` : ""
    return `${t("Custom report")} -${reportNameString} ${startDateFormatted} - ${endDateFormatted}`;
  }
  
  async function handleDownloadReportClick() {
    const reportName = selectedReport?.name;
    const fileName = `${getReportFileName(startDate, endDate, reportName)}.xlsx`;
    const mimeType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet.main+xml";
    
    const downloadFunction = accountSettings.isEnterprise
      ? ReportsService.downloadEnterpriseCustomReport(startDate, endDate, primaryColumn!.id, selectedColumns.map(c => new OrderedReportProperty(c.order, c.id)), reportName ?? null, selectedEstablishments || [])
      : ReportsService.downloadCustomReport(startDate, endDate, primaryColumn!.id, selectedColumns.map(c => new OrderedReportProperty(c.order, c.id)), reportName ?? null);
    
    dispatch(startDownload({ fileName, mimeType, callback: downloadFunction }));
  }
  
  function handleCheckColumn(column: ReportColumn) {
    let updatedSelectedColumns = [...selectedColumns];
    
    let columnCurrentIndex = updatedSelectedColumns.findIndex(col => col.id === column.id);
    if (columnCurrentIndex !== -1) {
      updatedSelectedColumns.splice(columnCurrentIndex, 1);
    } else {
      const maxOrder = Math.max(...updatedSelectedColumns.map(c => c.order))
      updatedSelectedColumns.push(new ReportColumn({
        id: column.id,
        name: column.name,
        order: maxOrder + 1
      }));
    }
    
    updatedSelectedColumns.sort((a, b) => a.order - b.order);
    updatedSelectedColumns.forEach((column, index) => {
      column.order = index + 1;
    });
    
    setSelectedColumns(updatedSelectedColumns);
  }
  
  function dragEndHandler(result: DropResult) {
    const { destination, source, draggableId } = result;
    if (!destination)
      return;
    if (destination.droppableId !== source.droppableId)
      return;
    if (destination.droppableId === source.droppableId && destination.index === source.index)
      return;
    const prevOrder = source.index + 1;
    const newOrder = destination.index + 1;
    
    let reorderedColumns = getReorderedColumns(selectedColumns, prevOrder, newOrder, draggableId);
    
    setSelectedColumns(reorderedColumns);
  }
  
  function getReorderedColumns(columns: ReportColumn[], prevOrder: number, newOrder: number, movedColumnId: string) {
    const result = Array.from(columns);
    const positionsMoved = newOrder - prevOrder;
    const isMovedDown = positionsMoved > 0;
    const affectedFieldIds = isMovedDown
      ? columns.filter(f => f.id === movedColumnId ||
        f.order! <= newOrder &&
        f.order! >= prevOrder)
        .map(f => f.id)
      : columns.filter(f => f.id === movedColumnId ||
        f.order! >= newOrder &&
        f.order! <= prevOrder)
        .map(f => f.id);
    
    result.forEach(f => {
      if (!affectedFieldIds.includes(f.id!))
        return;
      if (f.id === movedColumnId) {
        f.order = newOrder;
        return;
      }
      f.order = isMovedDown ? f.order! - 1 : f.order! + 1;
    });
    
    return result;
  }
  
  function handlePrimaryColumnCheck(key: string) {
    let updatedSelectedColumns = [...selectedColumns];
    const selectedColumn = allColumns.find(column => column.id === key)!;

    const columnCurrentIndex = updatedSelectedColumns.findIndex(col => col.id === key);
    
    if (columnCurrentIndex !== -1) {
      updatedSelectedColumns.splice(columnCurrentIndex, 1);
    }
    
    updatedSelectedColumns.sort((a, b) => a.order - b.order);
    updatedSelectedColumns.forEach((column, index) => {
      column.order = index + 1;
    });
    
    setSelectedColumns(updatedSelectedColumns);
    selectedColumn.order = 0;
    setPrimaryColumn(selectedColumn);
  }
  
  async function handleSaveReportButtonClick() {
    if (selectedReport) {
      await updateCustomReport();
    }
    else {
      setSaveReportModalIsOpen(true);
    }
  }
  
  function nameAlreadyExist(reportName: string) {
    return reports.flatMap(x => x.name.toLowerCase().trim()).includes(reportName.toLowerCase().trim());
  }
  
  function onDeleteClick(id: string) {
    const columnIndex = selectedColumns.findIndex(column => column.id === id);
    
    if (columnIndex !== -1) {
      const updatedSelectedColumns = [...selectedColumns];
      updatedSelectedColumns.splice(columnIndex, 1);
      updatedSelectedColumns.sort((a, b) => a.order - b.order);
      updatedSelectedColumns.forEach((column, index) => {
        column.order = index + 1;
      });
      
      setSelectedColumns(updatedSelectedColumns);
    }
  }
  
  async function updateCustomReport() {
    dispatch(navbarActions.setShowLoader(true));
    let updatedReport = await ReportsService.updateCustomReport(
            primaryColumn!.id,
            selectedColumns.map(c => new OrderedReportProperty(c.order, c.id)),
            selectedReport?.id!);
    dispatch(navbarActions.setShowLoader(false));
    if (updatedReport) {
      let updatedReports = reports.map(report => {
        if (report.id === updatedReport.id) {
          return {
            ...report,
            name: updatedReport.name,
            properties: updatedReport.properties.map(prop => {return { ...prop, name: t(prop.name), };}),
          };
        }
        return report;
      });
      setReports(updatedReports);
      setSaveReportModalIsOpen(false);
      setOpenModalActionDots(false);
      dispatch(
        enqueueAlert({
          type: "Success",
          title: t("Updated report"),
          description: updatedReport.name,
        })
      );
    }
  }
  
  async function saveCustomReport(reportName: string) {
    let newReport = await ReportsService.createCustomReport(
            primaryColumn!.id,
            selectedColumns.map(c => new OrderedReportProperty(c.order, c.id)),
            reportName);
    if (newReport) {
      let updatedReports = [...reports.filter(item => item.id !== noSavedReports.id)];
      newReport.properties.forEach(prop => {
        prop.name = t(prop.name)
      });
      updatedReports.push(newReport);
      setReports(updatedReports);
      setSelectedReport(newReport);
      setSaveReportModalIsOpen(false);
      setOpenModalActionDots(false);
      dispatch(
        enqueueAlert({
          type: "Success",
          title: t("Saved report"),
          description: newReport.name,
        })
      );
    }
  }
  
  function handleCancelButtonClick() {
    setCancelEditReportModalIsOpen(true);
  }
  
  function handleNewReportButtonClick() {
    setPrimaryColumn(null);
    setSelectedColumns([]);
    setSelectedReport(null);
    setOpenModalActionDots(false);
  }
  
  function searchInputResultNotFound(): boolean {
    let primaryColumnsResultNotFound = columnGroups
      .filter(group => group.columns.filter(x => x.isPrimary)
        .some(column => column.name.toLowerCase()
          .includes(columnSearchQuery.toLowerCase()))).length === 0;
    let columnsResultNotFound = columnGroups
      .filter(group => group.columns
        .some(column => column.name.toLowerCase()
          .includes(columnSearchQuery.toLowerCase()))).length === 0;
    return columnSearchQuery !== "" && primaryColumnsResultNotFound && columnsResultNotFound;
  }
  
  function handleCancel() {
    if (selectedReport) {
      const initialStateColumns = reports.find(report => report.id === selectedReport?.id)?.properties;
      setSelectedColumns(initialStateColumns?.filter(col => col.order !== 0)!);
      setPrimaryColumn(initialStateColumns?.find(col => col.order === 0)!);
    }
    else {
      setPrimaryColumn(null);
      setSelectedColumns([]);
    }
    setCancelEditReportModalIsOpen(false);
    setOpenModalActionDots(false);
  }
  
  function handleSetSelectedReport(selectedReportKey: string) {
    let report = reports.find(x => x.id === selectedReportKey);
    setSelectedReport(report!);
  }
  
  function handleOnChangeSelectedReport(selectedReportKey: string) {
    let primaryColumnInfo = reports.find(x => x.id === selectedReportKey)?.properties.find(x => x.isPrimary);
    let primaryColumn = new ReportColumn({
      id: primaryColumnInfo?.id,
      isPrimary: true,
      order: primaryColumnInfo?.order,
      name: t(primaryColumnInfo!.name),
    });
    setPrimaryColumn(primaryColumn);
    
    let selectedColumnInfo = reports.find(x => x.id === selectedReportKey)?.properties.filter(x => !x.isPrimary);
    let selectedColumns = selectedColumnInfo?.map(column => new ReportColumn({
      id: column?.id,
      isPrimary: false,
      order: column?.order,
      name: t(column!.name),
    }));
    setSelectedColumns(selectedColumns ?? []);
  }
  
  function isColumnStateChanged(): boolean {
    const initialStateColumns = reports
            .find(report => report.id === selectedReport?.id)?.properties
            .filter(item => item.order !== 0)
            .map(({ isPrimary, name, ...rest }) => rest);
    
    const currentStateColumns = selectedColumns.map(({ isPrimary, name, ...rest }) => rest);
    
    if (!initialStateColumns || !currentStateColumns) {
      return true;
    }
    
    const initialPrimaryColumn = reports
            .find(report => report.id === selectedReport?.id)?.properties
            .find(item => item.order === 0);
    
    if (initialPrimaryColumn && initialPrimaryColumn.id !== primaryColumn?.id) {
      return true;
    }
    
    if (initialStateColumns.length !== currentStateColumns.length) {
      return true;
    }
    
    initialStateColumns.sort((a, b) => a.order - b.order);
    currentStateColumns.sort((a, b) => a.order - b.order);
    
    return initialStateColumns.some((col, index) => JSON.stringify(col) !== JSON.stringify(currentStateColumns[index]));
  }
  
  const CancelEditModalContent: React.FC = () => {
    return (
        <div className={classes.cancelModalContainer}>
          <Typography style={{marginBottom: 8}} className={classes.modalHeader}>
            {selectedReport ? t("Cancel custom report editing?") : t("Cancel custom report creation?")}
          </Typography>
          <Typography className={classes.cancelModalContent}>
            {t("Unsaved changes will be lost.")}
          </Typography>
        </div>);
  };
  
  const ColumnNotFoundContent: React.FC = () => {
    return (
        <div className={classes.columnNotFoundContainer}>
          <SearchIcon style={{ color: "#919CA5", height: 50, width: 50 }}/>
          <div className={classes.columnNotFoundCaptionBold}>{t("Column not found...")}</div>
          <div className={classes.columnNotFoundCaption}>{t("Try again with a different column")}</div>
        </div>);
  };
  
  const mapItemsToComponents = (items: any[]) => {
    return items.sort((i1, i2) => i1.order! - i2.order!).map(i =>
      <div>
        <DraggableBase
          key={i.id.toString()}
          className={classes.draggableClassName}
          innerContainerClass={classes.draggableInnerContainer}
          height={48}
          isRemovable={true}
          mainBoxClass={classes.mainBoxClass}
          draggableId={i.id.toString()}
          index={i.order - 1}
          onDeleteClick={() => onDeleteClick(i.id!)}
          isEditable={true}
          isShowActionOnMouseHover={true}
          closeIcon={<CloseIcon style={{ color: "#5C6477" }} viewBox={"0 0 14 5"}/>}
        >
          {i.name}
        </DraggableBase>
      </div>
    )
  }

  const getColumnLabel = (column: ReportColumn) => {
    if (!column.tooltip) return column.name;

    const checked = selectedColumns.some(selectedColumn => selectedColumn.id === column.id) || column.id === primaryColumn?.id;

    return (
      <div className={classes.columnWithTooltipLabel}>
        <span>{column.name}</span>
        <IconTooltip
          placement={"right"}
          arrowPlacement={"right"}
          icon={<InfoIcon style={{ width: 12, height: 12, marginLeft: 2, color: "#6462F3" }} viewBox={"0 0 12 12"} />}
          tooltipText={t(column.tooltip)}
        />
      </div>
    )
  }
  
  return (
    <div className={classes.root}>
      <ReportsNavTabs/>
      <EstablishmentsToolbar />
      <div className={classes.mainContainer}>
        <div className={classes.inputsBlock}>
          <div className={classes.controlWithLabel}>
            <StartDateLabel/>
            <DateInput
              width={158}
              placeholder={t(dateFormatPlaceholder)}
              format={dateFormat}
              value={startDate}
              onChange={setStartDate}
              maxDate={todayDate}
              inputTextClass={classes.dateInputText}
              iconColor={"#ACB7C0"}
            />
          </div>
          <div className={classes.controlWithLabel}>
            <EndDateLabel/>
            <DateInput
              width={158}
              placeholder={t(dateFormatPlaceholder)}
              format={dateFormat}
              value={endDate}
              onChange={setEndDate}
              minDate={startDate}
              maxDate={todayDate}
              inputTextClass={classes.dateInputText}
              iconColor={"#ACB7C0"}
            />
          </div>
          <div className={classes.reportTypeSelect}>
            <SelectKeyEditable
              key={selectedReport?.id}
              value={selectedReport?.id}
              onSave={onSaveReportName}
              deleteModal={<DeleteModalContent/>}
              onDelete={onDeleteReport}
              setValue={handleSetSelectedReport}
              itemRootClass={classes.itemRootClass}
              onChange={handleOnChangeSelectedReport}
              setDeletableItemName={setDeletableReportName}
              items={selectItems}
              width={348}
              dropDownWidth={348}
              placeholder={t("Select a report")}
              textButtonConfirmDelete={t("Delete report")}
            />
          </div>
        
        </div>
        <div className={classes.downloadReportButtonBlock}>
          <Button
            className={classes.downloadReportButton}
            disabled={isDownloading || !primaryColumn}
            onClick={handleDownloadReportClick}
          >
            {
              <span className={classes.downloadReportButtonLabel}>
              {isDownloading
                ? <CircularProgress size="1rem" style={{ color: "#FFFFFF" }}/>
                : null
              }
                {t("Download report")}
            </span>
            }
          </Button>
          <span>
          <span >
            <DotsVerticalIcon
              color="#5C6477"
              style={{ cursor: primaryColumn ? "pointer" : "auto" }}
              onClick={() => {
                if (!primaryColumn) return
                setOpenModalActionDots(true)
              }}
            />
          <Modal
            open={openModalActionDots}
            onClose={() => setOpenModalActionDots(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            BackdropProps={{ style: { backgroundColor: "transparent" } }}
            disablePortal={true}
            style={{ position: "relative", top: 0 }}>
              <div className={classes.modalActionsAppt}>
                <div className={`${classes.modalActionFont} ${!isColumnStateChanged() && classes.disabledButton}`} 
                     onClick={isColumnStateChanged() ? handleSaveReportButtonClick : undefined}>
                  {selectedReport ? t("Save changes") : t("Save report")} 
                </div>
                <div className={`${classes.modalActionFont} ${selectedColumns.length === 0 && !primaryColumn && classes.disabledButton}`} 
                     onClick={handleNewReportButtonClick}>
                  {t("New report")}
                </div>
                <div className={`${classes.modalActionFont} ${!isColumnStateChanged() && classes.disabledButton}`} 
                     onClick={isColumnStateChanged() ? handleCancelButtonClick : undefined}>
                  {t("Cancel")}
                </div>
              </div>
          </Modal>
          </span>
        </span>
        </div>
      </div>
      <div className={classes.bottomRoot}>
        <div className={classes.columnsContainer}>
          <div className={classes.columnsHeader}>
            {t("COLUMNS")}
          </div>
          <div className={classes.inputSearchContainer}>
            <InputSearch
              value={columnSearchQuery}
              inputSearchClass={classes.inputSearchClass}
              placeholder={t("Search column")}
              rootClass={classes.inputSearchRoot}
              onReset={() => setColumnSearchQuery("")}
              onChange={(e: any) => setColumnSearchQuery(e.target.value)}
            />
          </div>
          {searchInputResultNotFound() && <ColumnNotFoundContent/>}
          <div className={classes.scrollColumnsArea}>
            {!searchInputResultNotFound() && <div className={classes.selectedColumnsBox}>
              {primaryColumn &&
                  <div style={{ marginTop: 10 }} className={classes.groupHeader}>
                      <div style={{ width: "max-content" }}>{t("Selected columns")}</div>
                      <IconTooltip
                          key={"selectedColumnsTooltip"}
                          classNameRoot={classes.iconTooltipRoot}
                          placement={"bottom"}
                          arrowPlacement={"top"}
                          icon={<InfoIcon style={{ width: 17, height: 16, marginLeft: 5, color: "#6462F3" }}
                                          viewBox={"0 0 12 12"}/>}
                          tooltipText={t("These columns will appear in the personalized report you create")}
                      />
                  </div>
              }
              <DragDropContext onDragEnd={dragEndHandler}>
                <Droppable key={"drop_selected_columns_id"} droppableId={"drop_selected_columns_id"}>
                  {(provided) => (
                    <Box style={{ width: "100%" }}>
                      <div className={classes.sectionContainer}>
                        <Grid
                          container
                          justifyContent="center"
                          className={classes.sectionLayout}
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                        >
                          {primaryColumn?.name && (
                            <span style={{ marginLeft: 6, marginBottom: 5 }}> {primaryColumn?.name}{' '}
                              <span style={{ color: '#6462F3' }}> {"(" + t("Primary") + ")"}
                            </span>
                          </span>
                          )}
                          {mapItemsToComponents(selectedColumns)}
                          {provided.placeholder}
                        </Grid>
                      </div>
                    </Box>
                  )}
                </Droppable>
              </DragDropContext>
            </div>}
            
            
            <div>
              {columnGroups.filter(group => group.columns.filter(x => x.isPrimary)
                  .some(column => column.name.toLowerCase().includes(columnSearchQuery.toLowerCase()))
                ).length > 0 &&
                <div className={classes.groupHeader}>
                  <div style={{ width: "max-content" }}>{t("Primary column")}</div>
                  <IconTooltip
                    key={"primaryColumnTooltip"}
                    classNameRoot={classes.iconTooltipRoot}
                    placement={"bottom"}
                    arrowPlacement={"top"}
                    icon={<InfoIcon style={{ width: 17, height: 16, marginLeft: 5, color: "#6462F3" }}
                                    viewBox={"0 0 12 12"}/>}
                    tooltipText={t("You must select a primary column")}
                  />
                </div>
              }
              <RadioGroup
                className={classes.radioGroup}
                defaultValue={-1}
                value={primaryColumn?.id ?? -1}
                aria-label={"Primary column"}
                name={"Primary column"}
                onChange={(event) => handlePrimaryColumnCheck(event.target.value)}
              >
                {columnGroups
                  .filter(group => group.columns.some(column => column.isPrimary))
                  .filter(group => group.columns.filter(x => x.isPrimary).some(column => column.name.toLowerCase().includes(columnSearchQuery.toLowerCase())))
                  .sort((a, b) => a.order - b.order)
                  .map((group) => {
                    const primaryColumn = group.columns.find((column) => column.isPrimary);
                    
                    return (
                      <FormControlLabel
                        classes={{
                          root: classes.radioLabelRoot,
                          label: classes.radioLabel,
                        }}
                        key={primaryColumn?.id}
                        value={primaryColumn?.id}
                        control={
                          <Radio
                            className={classes.radioOption}
                          />
                        }
                        label={primaryColumn?.name}
                      />
                    );
                  })
                }
              </RadioGroup>
            </div>
            
            
            {columnGroups
              .filter(group => group.columns.some(column => column.name.toLowerCase().includes(columnSearchQuery.toLowerCase())))
              .sort((a, b) => a.order - b.order)
              .map((group) => (
                <div className={classes.columnsContent}>
                  <div className={classes.groupHeader}>{group.name}</div>
                  {group.columns
                    .filter(column => column.name.toLowerCase().includes(columnSearchQuery.toLowerCase()))
                    .sort((a, b) => a.order - b.order)
                    .map((column) => (
                      <CheckboxWithLabel
                        checkboxClass={classes.columnCheckbox}
                        labelClass={classes.checkboxLabel}
                        disableRippleEffect={true}
                        key={column.id}
                        disabled={!primaryColumn || column.id === primaryColumn?.id}
                        checked={selectedColumns.some(selectedColumn => selectedColumn.id === column.id) || column.id === primaryColumn?.id}
                        label={getColumnLabel(column)}
                        setChecked={() => handleCheckColumn(column)}
                      />
                    ))}
                </div>
              ))
            }
          </div>
        </div>
        <div className={classes.previewRoot}>
          <div className={classes.informativeBoxContainer}>
            <Trans
              i18nKey="ReportInformativeBox"
              t={t}
            />
          </div>
          <div className={classes.previewContainer}>
            {!primaryColumn
              ? <div className={classes.emptyPreviewBox}>
                <div><ReportPersonIcon/></div>
                <div className={classes.emptyPreviewBoxTitle}>{t("Create a report!")}</div>
                <div className={classes.emptyPreviewBoxCaption}>{t("Please select columns.")}</div>
              </div> 
              : <GridList
                columns={previewGridColumns}
                items={previewGridData ?? []}
                classTableContainer={classes.classTableContainer}
                classTableBody={classes.classTableBody}
                height={"100%"}
                tooltipIsVisible={true}
                totalItems={20}
                pageSize={20}
              />
            }
          
          </div>
        </div>
      </div>
      <SaveReportModal 
        open={saveReportModalIsOpen}
        checkNameIsInvalid={(stringName: string) => nameAlreadyExist(stringName)}
        onClose={() => {
          setOpenModalActionDots(false);
          setSaveReportModalIsOpen(false);
        }}
        onConfirm={saveCustomReport}
      />
      <ConfirmActionModal
          open={cancelEditReportModalIsOpen}
          content={<CancelEditModalContent/>}
          onClose={() => {
            setCancelEditReportModalIsOpen(false);
            setOpenModalActionDots(false);
          }}
          onConfirm={handleCancel}
          confirmButtonText={t("Yes, cancel")}
          buttonClass={classes.cancelButton}
      />
    </div>
  );
}