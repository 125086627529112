import React, { useEffect, useState } from "react";

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";

import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  selectAccountSettings,
  selectCommonHubConnection,
  selectCashRegisterOpen,
  selectUserInfo
} from "../../../redux/store";
import { schedulerActions } from "../../../redux/scheduler-slice";
import { useAppSelector } from "../../../redux/hooks";
import { blockedScheduleActions } from "../../../redux/blocked-schedule-slice";
import { multipleBlockedScheduleActions } from "../../../redux/multiple-blocked-schedule-slice";

import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Modal from "@material-ui/core/Modal";
import CircularProgress from "@material-ui/core/CircularProgress";

import { Url } from "../../../constants/url";
import { AppointmentService } from "../../../api/appointment-service";
import { Appointment } from "../../../models/appointment";
import { AppointmentStatus } from "../../../models/enums/appointment-status";
import {
  CrossIcon,
  DotsVerticalIcon,
  EditIcon,
  FlagIcon,
  GroupIcon,
  MoneyIcon,
  NotesIcon,
  TrashIcon,
} from "../../../assets/icons";
import AppointmentDeleteModal from "./../AppointmentDeleteModal";
import AppointmentFollowUp from "./../AppointmentFollowUp";
import useStyles from "./css";
import { GetMobileMaskByTemp } from "../../../constants/mask";
import { conformToMask } from "react-text-mask";
import { AppointmentCreatedBy } from "../../../models/enums/appointment-created-by";
import AppointmentPaymentSidebar from "../AppointmentPaymentSidebar";
import { AppointmentPaymentStatus } from "../../../models/enums/appointment-payment-status";
import { EditableItemProps } from "../../../models/interfaces/editable-item-props";
import { AppointmentPaymentEntity } from "../../../models/appointment-payment-entity";
import { AppointmentPaymentFormData } from "../../../models/appointment-payment-form-data";
import { AppointmentPaymentService } from "../../../api/appointment-payment-service";
import useCurrencyValueTemplate from "../../../hooks/useCurrencyValueTemplate";
import { AppointmentPaymentChangeRecord } from "../../../models/appointment-payment-change-record";
import RecurringAppointmentDeleteModal from "../RecurringAppointmentDeleteModal";
import { PaymentsService } from "../../../api/payments-service";
import { AppointmentPaymentValues } from "../../../models/appointment-payment-values";
import AppointmentStatusSelector from "../AppointmentStatusSelector";
import { AppointmentTooltipModel } from "../../../models/appointment-tooltip-model";
import { CustomRecurrenceMode } from "../../../models/enums/custom-recurrence-mode";
import { WeekDaysFlags } from "../../../models/enums/week-days-flags";
import { alertsActions } from "../../../redux/alerts-slice";


export default function AppointmentItemTooltip(props: any) {

  const {
    anchorEl,
    appointmentMeta,
    visible,
    onHide,
    onDeleteButtonClick,
    ...otherProps
  } = props;
  
  const classes = useStyles();
  const history = useHistory();
  
  const dispatch = useDispatch();
  const accountSettings = useAppSelector(selectAccountSettings);
  const currencyTemplate: string = accountSettings.currencyTemplate;
  const isEnterprise = accountSettings.isEnterprise;
  const isCashRegisterActivated: boolean = accountSettings.isCashRegisterActivated;
  const isCashRegisterOpen: boolean = useAppSelector(selectCashRegisterOpen);
  const hubConnection = useAppSelector(selectCommonHubConnection);
  const { enqueueAlert } = alertsActions;

  const { t } = useTranslation(["general"]);
  const [target, setTarget] = useState<any>();
  const [appointment, setAppointment] = useState<Appointment>(new Appointment());
  const [appointmentDetails, setAppointmentDetails] = useState<AppointmentTooltipModel>(new AppointmentTooltipModel());
  const [appointmentPayment, setAppointmentPayment] = useState<AppointmentPaymentEntity>(new AppointmentPaymentEntity());
  const [oldAppointmentPayment, setOldAppointmentPayment] = useState<AppointmentPaymentEntity>(new AppointmentPaymentEntity());
  const [appointmentPaymentFormData, setAppointmentPaymentFormData] = useState<AppointmentPaymentFormData>(new AppointmentPaymentFormData());
  const [firstService, setFirstService] = useState<string>();
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [openModalFollowUp, setOpenModalFollowUp] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [isPaymentsSidebarOpen, setPaymentsSidebarOpen] = useState<boolean>(false);
  const [paymentsSidebarMode, setPaymentsSidebarMode] = useState<EditableItemProps>({ mode: "new", itemId: null });
  //const [services, setServices] = useState<AppointmentServiceModel[]>([]);
  const [openModalActionDots, setOpenModalActionDots] = useState<boolean>(false);
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const [showRecurringAppointmentDeleteModal, setShowRecurringAppointmentDeleteModal] = useState<boolean>(false);
  const [paymentButtonDisable , setPaymentButtonDisable] = useState<boolean>(false);
  const mobileTemplate: string = "000-000-0000";
  const templatedValue = useCurrencyValueTemplate(currencyTemplate);
  
  const userInfo = useAppSelector(selectUserInfo);
  const hasCustomerInfoVisible = userInfo.hasCustomerInfoVisible;
  const isUserAbleToRegisterPayments = userInfo.canRegisterPayments;
  const isPaymentSidebarEnabled =
    isEnterprise === false &&
    (isCashRegisterActivated &&
    (isCashRegisterOpen && isUserAbleToRegisterPayments && appointmentDetails?.paymentStatus === AppointmentPaymentStatus.Unpaid) ||
    appointmentDetails?.paymentStatus !== AppointmentPaymentStatus.Unpaid)
  const canMakePayment = isCashRegisterActivated && isCashRegisterOpen && isUserAbleToRegisterPayments;
  const oldAppointmentPaymentAmountPaid = oldAppointmentPayment.parts?.map(x => x.amountPaid).reduce((sum, x) => sum + x) || 0

  const frequencyWeek: any[] = [
    { key: "1", value: `${t("Every week, on the")} DAY` },
    { key: "2", value: `${t("Every 2 weeks, on the")} DAY` },
    { key: "3", value: `${t("Every 3 weeks, on the")} DAY` },
    { key: "4", value: `${t("Every 4 weeks, on the")} DAY` },
    { key: "8", value: `${t("Every 8 weeks, on the")} DAY` },
    { key: "12", value: `${t("Every 12 weeks, on the")} DAY` }
  ];

  const appointmentId = useSelector(
    (state: RootState) => state.scheduler.appointmentId
  );
  const tooltipAppointments = useSelector(
    (state: RootState) => state.scheduler.tooltipAppointments
  );

  useEffect(() => {
    setAppointment(appointmentMeta);
    setTarget(anchorEl);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentMeta, anchorEl]);

  useEffect(() => {
    if (appointmentId && appointmentId === appointment.appointmentId) {
      const currentAppointment = tooltipAppointments.find(
        (a) => a.appointmentId === appointmentId
      );

      if (currentAppointment && currentAppointment.elementRef) {
        setAppointment(new Appointment(currentAppointment));
        setTarget(currentAppointment.elementRef);
        setOpen(true);
      }
    } else {
      setOpen(false);
      onHide();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointmentId]);

  useEffect(() => {
    async function fetchData() {
      await loadData();
    }

    if (open && appointment && appointment.appointmentId) {
      void fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);
  
  useEffect(() => {
    async function fetchData() {
      await loadData();
      await loadPaymentData();
    }

    if (appointment && appointment.appointmentId) {
      fetchData();
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCashRegisterOpen]);

  useEffect(() => {
      setOpen(!!visible);
  }, [visible]);
  
  async function loadData() {
    const data = await AppointmentService.getAppointmentTooltip(appointment.appointmentId as string);
    const appointmentDate = appointment.startDate.toJSDate();
    
    if (data.services.length > 0) {
      let duration = 0;
      const services = data.services.map((service) => {
        const startDate = data.scheduledSimultaneousServices ? appointmentDate : new Date(appointmentDate.getTime() + duration * 60000);
        const endDate = new Date(startDate.getTime() + service.duration! * 60000);
        duration += service.duration!;
        return { ...service, startDate, endDate };
      });
      setFirstService(services[0].name || undefined);
      data.services = services;
    }
    setAppointmentDetails(data);
  }

  async function loadPaymentData() {
    if (!appointment.appointmentId) return;

    setPaymentButtonDisable(true);

    try {
      const result = appointmentDetails.isRecurring
        ? await PaymentsService.getRecurringAppointmentPaymentFormData(appointment.appointmentId,
          appointmentDetails.recurredAppointmentsIds,
          appointmentDetails.recurringApptTotalToPaid)
        : await PaymentsService.getPaymentFormData(appointment.appointmentId);

      setPaymentButtonDisable(false);

      if (!result || !result.payment) return;
      setOldAppointmentPayment(result.payment);
      setAppointmentPaymentFormData(result);

      const isNew = result.payment.id === null;
      if (isNew){
        result.payment.services = await AppointmentPaymentService.getAppointmentPricedServices(appointment.appointmentId);
      }

      const somePaymentsExist = result.payment.parts.some(x => x.amountPaid > 0);

      const mode = appointmentDetails.isRecurring 
        ? (somePaymentsExist ? "edit" : "new") 
        : (isNew ? "new" : "edit");

      setPaymentsSidebarMode({ mode: mode, itemId: appointmentPayment.id });
      setAppointmentPayment({ ...result.payment });
    } catch (error) {
      setPaymentButtonDisable(false);
    }
  }
  
  async function updatePaymentInfo(appointmentId: string) {
    if (!(appointment && appointment.appointmentId)) return;
    if (appointment.appointmentId == appointmentId) {
      await loadPaymentData()
    }
  }
  
  function updateAppointmentsPaymentStatus(appointmentId: string, paymentStatus: AppointmentPaymentStatus, paidAmount: number) {
    if (!(appointmentDetails && appointmentDetails.appointmentId)) return;
    if (appointmentDetails.appointmentId == appointmentId) {
      setAppointmentDetails({...appointmentDetails, paymentStatus, paidAmount });
    }
  }

  const handleUpdateAppointmentData = (appointmentId: string) => {
    async function fetchData() {
      await loadData();
    }
    if (!(appointmentDetails && appointmentDetails.appointmentId)) return;
    if (appointmentDetails.appointmentId === appointmentId) {
      void fetchData();
    }
  };

  const handleStatusChange = (appointmentId: string, status: string) => {
    if (!(appointment && appointment.appointmentId)) return;
    const newAppointmentStatus: AppointmentStatus = parseInt(status);

    if (appointment.appointmentId == appointmentId) {
      setAppointment({ ...appointment, status: newAppointmentStatus });
    }
  };

  // unsubscribe
  hubConnection?.off("updatePaymentInfo");
  hubConnection?.off("changeAppointmentPaymentStatus");
  hubConnection?.off("updateAppointmentTooltipData");
  hubConnection?.off("changeAppointmentStatusOnTooltip");
  // subscribe
  hubConnection?.on("updatePaymentInfo", async (appointmentId) => await updatePaymentInfo(appointmentId));
  hubConnection?.on("changeAppointmentPaymentStatus", (appointmentId, status, paidAmount) => updateAppointmentsPaymentStatus(appointmentId, status, paidAmount));
  hubConnection?.on("updateAppointmentTooltipData", (appointmentId) => handleUpdateAppointmentData(appointmentId));
  hubConnection?.on("changeAppointmentStatusOnTooltip", (appointmentId, status) => handleStatusChange(appointmentId, status));

  function isAppointmentPaymentUnchanged() {
    return JSON.stringify(appointmentPayment) === JSON.stringify(oldAppointmentPayment);
  }

  const getStatus = (status: AppointmentStatus | undefined): string => {
    switch (status) {
      case AppointmentStatus.Arrived:
        return classes.arrived;
      case AppointmentStatus.NotArrived:
        return classes.notArrived;
      case AppointmentStatus.NotConfirmed:
        return classes.notConfirmed;
      case AppointmentStatus.Confirmed:
      default:
        return classes.confirmed;
    }
  };
  
  async function handleAppointmentStatusSelect(status: AppointmentStatus) {
    await AppointmentService.updateStatus({
      id: appointment.appointmentId as string,
      status
    });
    setAppointment({ ...appointment, status });
  }
  
  function getTxtPaymentStatus(status: AppointmentPaymentStatus | undefined): string {
    switch (status) {
      case AppointmentPaymentStatus.Unpaid:
        return t("Unpaid");
      case AppointmentPaymentStatus.Paid:
        return t("Paid");
      case AppointmentPaymentStatus.PaidPartially:
        return t("Unpaid");
      default:
        return t("Unpaid");
    }
  }

  function getPaymentStatusColor(status: AppointmentPaymentStatus | undefined): string {
    switch (status) {
      case AppointmentPaymentStatus.Unpaid:
        return "#F15857";
      case AppointmentPaymentStatus.Paid:
        return "#36CE91";
      case AppointmentPaymentStatus.PaidPartially:
        return "#F15857";
      default:
        return "#F15857";
    }
  }
  
  let appointmentTotalPrice = appointmentDetails.services
    .map(x => x.price)
    .reduce((sum: number, currentServicePrice) => sum += currentServicePrice ?? 0, 0);
  
  function getAppointmentPriceByPaymentStatus(status: AppointmentPaymentStatus | undefined) : number {
    const totalPrice = appointmentDetails.isRecurring
      ? appointmentDetails.totalRecurringApptPriceExcludingSelected + appointmentTotalPrice
      : appointmentTotalPrice;

    switch (status) {
      case AppointmentPaymentStatus.Unpaid:
        return totalPrice;
      case AppointmentPaymentStatus.Paid:
        return appointmentDetails.paidAmount;
      case AppointmentPaymentStatus.PaidPartially:
        return appointmentDetails.paidAmount;
      default:
        return appointmentDetails.services.map(x => x.price).reduce((sum : number, currentServicePrice) => sum += currentServicePrice ?? 0, 0);
    }
  }

  const onClose = (event: any, reason: any) => {
    setOpen(false);
    onHide(event, reason);

    //need to be able to open same appointment tooltip from notifications more than once
    dispatch(schedulerActions.setAppointmentId(undefined));
    dispatch(blockedScheduleActions.setBlockedSheduleId(undefined));
    
  };

  const handleCloseModal = () => {
    setOpenModalDelete(false);
  };

  const onEdit = () => {
    history.push(`${Url.Appointments.Main}/${appointment?.appointmentId}`);
  };

  const handleDelete = (allRecurringAppointments: boolean) => {
    if (appointment.appointmentId) {
      AppointmentService.remove(appointment.appointmentId as string, allRecurringAppointments).then(() => {
        setShowRecurringAppointmentDeleteModal(false);
        setOpenModalDelete(false);
        dispatch(
          enqueueAlert({
            type: "Success",
            title: t("Appointment deleted"),
            description: t("Appointment has been successfully deleted."),
          })
        );
      });
    }
  };

  const handleDeleteModal = () => {
    if (appointmentDetails.isRecurring) {
      setShowRecurringAppointmentDeleteModal(true);
    } else {
      setOpenModalDelete(true);
    }
  };

  async function handlePaymentSidebarOpen() {
    if (!appointment.appointmentId || !appointmentPayment) return;
    resetAppointmentPaymentForm();
    await loadPaymentData().then(() => {
      setPaymentsSidebarOpen(true);
    });

  }

  const getStartEndTime = (startDate: Date, endDate: Date): string => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    const startTime = start.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    const endTime = end.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    return `${startTime} - ${endTime}hrs`;
  };

  const isEditable = (): boolean => {
    if (isEnterprise || isEnterprise === null) return false;
    return appointment ? appointment.isEditable : true;
  };

  const handleCreateNewAppointment = () => {
    history.push(`${Url.Appointments.Main}/new/${appointmentDetails?.customerId}`);
  };

  const handleCloseModalFollowUp = (isCreated: boolean) => {
    if (isCreated) {
      onHide();
      setOpen(false);
    }
    
    setOpenModalActionDots(false);
    setOpenModalFollowUp(false);
    
  };

  const handleCreateFollowUp = () => {
    setOpenModalActionDots(false);
    setOpenModalFollowUp(true);
  };
  
  function setAppointmentPaymentValues(paymentValues: AppointmentPaymentValues[]) {
    setAppointmentPaymentFormData({...appointmentPaymentFormData, paymentValues });
  }
  
  function setAppointmentPaymentChangeRecords(changeRecords: AppointmentPaymentChangeRecord[]) {
    setAppointmentPaymentFormData({...appointmentPaymentFormData, changeRecords });
  }
  
  async function handlePaymentSave(totalToBePaid: number): Promise<void> {
    if (!appointment.appointmentId) return;
    if (!canMakePayment) {
      setPaymentsSidebarOpen(false);
      return;
    }
    if (paymentsSidebarMode.mode === "new" || !appointmentPayment.id) {
      const createdPayment = await AppointmentPaymentService
        .create(appointment.appointmentId, appointmentPayment, totalToBePaid);
       
      if (createdPayment.paymentValues && createdPayment.payment) {
        if (!appointmentDetails.isRecurring) setAppointmentPaymentValues(createdPayment.paymentValues);
        setEditModeOnPaymentCreation(createdPayment.payment.id);
        setOldAppointmentPayment(createdPayment.payment);
        setAppointmentPayment(createdPayment.payment);
      }
      return;
    }
    
    const editedPayment = await AppointmentPaymentService
      .update(appointment.appointmentId, appointmentPayment, oldAppointmentPaymentAmountPaid, totalToBePaid);

    if (editedPayment.changeRecords && editedPayment.payment) {
      setAppointmentPaymentChangeRecords(editedPayment.changeRecords);
      setAppointmentPayment(editedPayment.payment);
      setOldAppointmentPayment(editedPayment.payment);
    }
  }
  
  function setEditModeOnPaymentCreation(itemId: string | null) {
    setPaymentsSidebarMode({ mode: "edit", itemId: itemId });
  }

  function resetAppointmentPaymentForm() {
    setPaymentsSidebarMode({ mode: "new", itemId: null });
  }
  
  const mobileFormat = (mobile: string, template: any): string => {
    return conformToMask(mobile, GetMobileMaskByTemp(template || mobileTemplate)).conformedValue;
  };

  const formatDay = (): string => {
    const date = appointment?.startDate.toJSDate();
    let longDay = date.toLocaleDateString(localeApp, { weekday: "long" });
    return longDay.charAt(0).toLowerCase() + longDay.slice(1);
  };

  const getRecurringWeekByDay = () => {

    if (appointmentDetails?.appointmentRecurrenceWeekInterval) {
      let filtered = frequencyWeek.filter(x => x.key == appointmentDetails?.appointmentRecurrenceWeekInterval);
      const day = " " + formatDay();
      if (filtered.length === 1) {
        return filtered[0].value.replace("DAY", day);
      }
    }
    
    if (appointmentDetails?.customRecurrenceInterval) {
      const modeString = t(CustomRecurrenceMode[appointmentDetails?.customRecurrenceMode!].toLowerCase());
      const modeStringEnding = localeApp == 'es' && appointmentDetails?.customRecurrenceMode === CustomRecurrenceMode.Month ? "es" : "s";
      const daysOfWeek = appointmentDetails?.customRecurrenceWeekDays && appointmentDetails?.customRecurrenceWeekDays > 0 
        ? ` ${t("_the")} ${getChosenDaysOfWeek(appointmentDetails?.customRecurrenceWeekDays)}` 
        : "";
      const recurrenceString = `${t("Every")} ${appointmentDetails.customRecurrenceInterval} ${modeString}${modeStringEnding}${daysOfWeek}`;
      return recurrenceString;
    }

    return "";
  };
  
  function getChosenDaysOfWeek(weekDaysFlags: WeekDaysFlags): string {
    const chosenDays = [];
    for (const day in WeekDaysFlags) {
      if (weekDaysFlags & parseInt(day)) {
        chosenDays.push(t(WeekDaysFlags[day]));
      }
    }
    
    return chosenDays.join(", ");
  }

  return (
    <>
      <Popover
        open={(!appointmentMeta?.isBlocked && open) || false}
        anchorEl={target}
        onClose={onClose}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        PaperProps={{
          className: classes.popover,
        }}
        {...otherProps}
      >
        <div className={`${classes.header} ${getStatus(appointment?.status)}`}/>
        <div className={classes.root}>
          <div className={`${classes.headerActions} ${classes.row}`}>
            <AppointmentStatusSelector 
              appointmentStatus={appointment?.status}
              handleAppointmentStatusChange={handleAppointmentStatusSelect}
              disabled={isEnterprise !== false}
            />
            <div
              style={{ display: "flex", justifyContent: "end", width: "100%" }}
            >
              {isEditable() ? (
                <>
                  <span className={classes.headerIcon}>
                    <TrashIcon
                      className={classes.headerButtons}
                      style={{
                        width: 13,
                        height: 17,
                        color: "#919CA5",
                      }}
                      onClick={handleDeleteModal}
                    />
                  </span>
                  <span className={classes.headerIcon}>
                    <EditIcon
                      className={classes.headerButtons}
                      style={{
                        width: 16,
                        height: 16,
                      }}
                      onClick={onEdit}
                    />
                  </span>
                </>
              ) : null}
              <span
                className={classes.headerIcon}
                style={{ marginTop: -2, position: "relative" }}
              >
              {hasCustomerInfoVisible && (isEnterprise === false) &&
                <span className={classes.headerIcon} style={{marginTop: -2, position: "relative", left: -10}}>
                  <DotsVerticalIcon
                    className={classes.headerButtons}
                    style={{
                      width: 20,
                      height: 20,
                      marginTop: "3px"
                    }}
                    color="#919CA5"
                    viewBox="0 0 25 25"
                    onClick={() => setOpenModalActionDots(true)}
                  />

                  <Modal
                    open={openModalActionDots}
                    onClose={() => setOpenModalActionDots(false)}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                    BackdropProps={{ style: { backgroundColor: "transparent" } }}
                    disablePortal={true}
                    style={{ position: "relative", top: 0 }} >
                    <div className={classes.modalActionsAppt} >
                      <div className={`${classes.modalActionFont} ${classes.modalActionFirst}`} onClick={handleCreateNewAppointment}>{t("Create new appointment")} </div>
                      <div className={`${classes.modalActionFont} ${classes.modalActionLast}`} onClick={handleCreateFollowUp}>{t("NewFollowUpCreateFollowUp")} </div>
                    </div>
                  </Modal>
                </span> 
              }
              </span>

              <span className={classes.headerIcon}>
                <CrossIcon
                  className={classes.headerButtons}
                  style={{
                    width: 20,
                    height: 20,
                  }}
                  viewBox="0 0 16 16"
                  onClick={onClose}
                />
              </span>
            </div>
          </div>

          {
            appointment?.createdBy === AppointmentCreatedBy.Customer &&
            <div className={classes.headerSubText}>
              {t("Scheduled from site for appointments")}
            </div>
          }

          <div className={classes.content}>
            <div className={classes.bodyContent}>
              <div className={`${classes.row} ${classes.paymentRow}`}>
                <div className={classes.paymentInfo}>
                  <MoneyIcon
                    viewBox={"0 0 10 16"}
                    style={{
                      width: 16,
                      height: 16,
                      marginTop: 5,
                      marginRight: 12,
                      fontSize: '16px'
                    }}
                  />
                  <div>
                    <div>
                      <span className={classes.txtBody2}
                            style={{ color: getPaymentStatusColor(appointmentDetails?.paymentStatus) }}>
                      {getTxtPaymentStatus(appointmentDetails?.paymentStatus)}
                      </span>
                      {" "}
                      {userInfo.showPrices &&
                      <span className={classes.paymentAmount}>
                      {templatedValue(getAppointmentPriceByPaymentStatus(appointmentDetails?.paymentStatus))}
                      </span>
                      }
                    </div>
                    {appointmentDetails.isRecurring && <div className={classes.recurringAppointmentPaymentText}>
                      {t("Includes {{count}} recurring appointments", {
                        count: appointmentDetails.recurredAppointmentsCount ?? 0,
                      })}
                    </div>}
                  </div>
                
                </div>
                {
                  isPaymentSidebarEnabled &&
                  <Button
                    className={classes.paymentButton}
                    startIcon={paymentButtonDisable ? <CircularProgress size="1rem" /> : undefined}
                    disabled={paymentButtonDisable}
                    onClick={() => handlePaymentSidebarOpen()}
                    fullWidth
                  >
                    {paymentButtonDisable
                      ? ""
                      : appointmentDetails?.paymentStatus === AppointmentPaymentStatus.Unpaid
                        ? t("Register payment")
                        : t("Payment detail")}
                  </Button>
                }
              </div>
              <div
                className={classes.separator}
              />
              
              {appointmentDetails?.isRecurring &&
                <>
                  <div className={classes.row} >
                    <div
                      style={{
                        width: 12,
                        height: 16,
                        marginTop: 4,
                        marginRight: 14,
                      }}
                    />
                    <div  className={classes.recurringAppointmentInfo} >
                      <div>
                        <Typography className={classes.caption}>
                          {t("Recurring appointment")}
                        </Typography>
                        <Typography className={classes.txtBody2}>
                          {`(${appointmentDetails?.recurringAppointmentOrder}/${appointmentDetails?.recurredAppointmentsCount}) ${getRecurringWeekByDay()}`}
                        </Typography>
                      </div>
                      {userInfo.showPrices && 
                        <>
                          <div>
                            <Typography className={classes.caption}>
                              {t("Total of {{count}} appointments:", {
                                count: appointmentDetails.recurredAppointmentsCount ?? 0,
                              })}
                            </Typography>
                            <Typography className={classes.txtBody2}>
                              {templatedValue(appointmentDetails.totalRecurringApptPrice)}
                            </Typography>
                          </div>
                          <div>
                            <Typography className={classes.caption}>
                              {t("Total per appointment:")}
                            </Typography>
                            <Typography className={classes.txtBody2}>
                              {templatedValue((appointmentDetails.totalRecurringApptPrice) 
                                / (appointmentDetails.recurredAppointmentsCount ?? 1))}
                            </Typography>
                          </div>
                       </>
                    }
                    </div>
                  </div>
                  <div
                    className={classes.separator}
                  />
                </>}

              
              <div className={classes.row}>
                <GroupIcon
                  style={{
                    width: 16,
                    height: 16,
                    marginTop: 5,
                    marginRight: 12,
                  }}
                />
                <div>
                  <div>
                    <Typography className={classes.caption}>
                      {useCustomerTerminology(t("Customer name"), "Customer name")}
                    </Typography>
                    <Typography className={classes.txtBody1}>
                      {appointment?.customerName}
                    </Typography>
                  </div>
                  {!hasCustomerInfoVisible ? <></> : <>
                  <div className={classes.subsection}>
                    <Typography className={classes.caption}>
                      {t("Email")}
                    </Typography>
                    <Typography className={classes.txtBody2}>
                      {appointmentDetails?.customerEmail || "--"}
                    </Typography>
                  </div>
                  <div className={classes.subsection}>
                    <Typography className={classes.caption}>
                      {t("Mobile number")}
                    </Typography>
                    <Typography className={classes.txtBody2}>
                      {appointmentDetails?.customerMobile ? mobileFormat(appointmentDetails?.customerMobile || "", appointmentDetails.customerMobileTemplate) : ""}
                    </Typography>
                  </div>
                  </>}
                </div>
              </div>
              <div 
                className={classes.separator}
              />
              {appointmentDetails.services.map((service, index) => (
                <>
                  <div className={classes.row}>
                    <FlagIcon
                      style={{
                        color: "#919CA5",
                        width: 12,
                        height: 16,
                        marginTop: 4,
                        marginRight: 14,
                      }}
                      viewBox="0 0 12 16"
                    />
                    <div>
                      <div>
                        <Typography className={classes.caption}>
                          {t("Service")}
                        </Typography>
                        <Typography className={classes.txtBody2}>
                          {service.name}
                        </Typography>
                      </div>
                      {userInfo.showPrices &&
                        <div className={classes.subsection}>
                          <Typography className={classes.caption}>
                            {t("Price")}
                          </Typography>
                          <Typography className={classes.txtBody2}>
                            {templatedValue(service.price ?? 0)}
                          </Typography>
                        </div>
                      }
                      <div className={classes.subsection}>
                        <Typography className={classes.caption}>
                          {t("Specialist")}
                        </Typography>
                        <Typography className={classes.txtBody2}>
                          {service.user}
                        </Typography>
                      </div>
                      {
                        appointment?.establishmentName &&
                          <div className={classes.subsection}>
                            <Typography className={classes.caption}>
                              {t("Establishment")}
                            </Typography>
                            <Typography className={classes.txtBody2}>
                              {appointment.establishmentName}
                            </Typography>
                          </div>
                      }
                      <div className={classes.subsection}>
                        <Typography className={classes.caption}>
                          {t("Work area")}
                        </Typography>
                        <Typography className={classes.txtBody2}>
                          {service.workArea || "--"}
                        </Typography>
                      </div>
                      <div className={classes.subsection}>
                        <Typography className={classes.caption}>
                          {t("Duration")}
                        </Typography>
                        <Typography className={classes.txtBody2}>
                          {`${getStartEndTime(
                            service.startDate || new Date(),
                            service.endDate || new Date()
                          )} (${service.duration} min)`}
                        </Typography>
                      </div>
                    </div>
                  </div>
                  <div className={classes.separator} />
                </>
              ))}

              <div className={classes.row}>
                <NotesIcon
                  style={{
                    width: 14,
                    height: 14,
                    color: "#919CA5",
                    marginTop: 6,
                    marginRight: 14,
                  }}
                  viewBox="0 0 14 14"
                />
                <div>
                  <div>
                    <Typography className={classes.caption}>
                      {t("Comments")}
                    </Typography>
                    <Typography className={classes.txtBody2}>
                      {appointmentDetails?.notes || t("No comments")}
                    </Typography>
                  </div>
                </div>
               
              </div>
            </div>
          </div>
        </div>
      </Popover>

      <AppointmentDeleteModal
        open={openModalDelete}
        appointment={appointment}
        service={firstService}
        onClose={handleCloseModal}
        onDelete={() => handleDelete(false)}
      />
      
      <RecurringAppointmentDeleteModal
        open={showRecurringAppointmentDeleteModal}
        appointment={appointment}
        service={firstService}
        onClose={() => {
          setShowRecurringAppointmentDeleteModal(false);
        }}
        onDelete={handleDelete}
      />

      <AppointmentFollowUp
        open={openModalFollowUp} 
        appointment={appointment}
        appointmentDetails={appointmentDetails}
        onClose={handleCloseModalFollowUp}
      />

      {
        isPaymentSidebarEnabled &&
          <AppointmentPaymentSidebar
            mode={paymentsSidebarMode.mode}
            itemId={paymentsSidebarMode.itemId}
            isOpen={isPaymentsSidebarOpen}
            setOpen={setPaymentsSidebarOpen}
            userName={userInfo.fullName!}
            paymentEntity={appointmentPayment}
            setPaymentEntity={setAppointmentPayment}
            formData={appointmentPaymentFormData}
            onSave={handlePaymentSave}
            onClose={resetAppointmentPaymentForm}
            isUnchanged={isAppointmentPaymentUnchanged}
            paidPreviously={oldAppointmentPaymentAmountPaid}
            recurredAppointmentOrder={appointmentDetails.recurringAppointmentOrder}
            recurredAppointmentsCount={appointmentDetails.recurredAppointmentsCount}
            totalRecurringApptPriceExcludingSelected={appointmentDetails.totalRecurringApptPriceExcludingSelected}
          />
      }
    </>
  );
}