import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function CaptionDocumentMediaIcon(props: any) {
    const { style, height, width, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: width || 15, height: height || 14, ...style }}
            viewBox={viewBox || "0 0 15 14"}
        >
            <path 
            d="M11.4141 6.78125C11.4141 6.61719 11.1953 6.45312 10.9766 6.45312H4.85156C4.59635 6.45312 4.41406 6.61719 4.41406 6.78125V7.54687C4.41406 7.73828 4.59635 7.875 4.85156 7.875H10.9766C11.1953 7.875 11.4141 7.73828 11.4141 7.54687V6.78125ZM10.9766 8.75H4.85156C4.59635 8.75 4.41406 8.91406 4.41406 9.07812V9.84375C4.41406 10.0352 4.59635 10.1719 4.85156 10.1719H10.9766C11.1953 10.1719 11.4141 10.0352 11.4141 9.84375V9.07812C11.4141 8.91406 11.1953 8.75 10.9766 8.75ZM14.9141 3.60937C14.9141 3.28125 14.6953 2.92578 14.3672 2.67969L11.3411 0.410156C11.013 0.164062 10.5391 0 10.1016 0H2.66406C1.67969 0 0.914062 0.601562 0.914062 1.3125V12.6875C0.914062 13.4258 1.67969 14 2.66406 14H13.1641C14.112 14 14.9141 13.4258 14.9141 12.6875V3.60937ZM10.2474 1.42188L13.0182 3.5H10.2474V1.42188ZM13.1641 12.6875H2.66406V1.3125H8.4974V4.15625C8.4974 4.53906 8.86198 4.8125 9.3724 4.8125H13.1641V12.6875Z" 
            fill={style?.color || Color.gray5}/>
        </SvgIcon>
    );
}