import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container: {
    width: 812,
    height: 550,
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #dbdcdd",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    display: "flex",
    alignItems: "flex-start"
  },
  dialogHeader: {
    padding: "0 0 12px",
  },
  dialogContent: {
    padding: 0,
    overflowX: "hidden",
  },
  paper: {
    margin: 0,
    height: "100%",
    width: 812,
    maxWidth: 812,
    maxHeight: 550,
    padding: "40px 40px 18px",
    overflowY: "auto",
    "& ::-webkit-scrollbar": {
      width: "6px",
    },
    "& ::-webkit-scrollbar-track": {
      borderRadius: "10px",
      background: "#EFEFEF",
      marginTop: "33px",
      marginBottom: "33px"
    },
    "& ::-webkit-scrollbar-thumb": {
      borderRadius: "10px",
      background: theme.colors.grey3,
    },
    "& ::-webkit-scrollbar-thumb:hover": {
      background: theme.colors.grey4
    },
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
  },
  modalTitle: {
    width: "100%",
  },
  modalTitleText: {
    fontSize: "14px",
    fontWeight: "bold",
    color: theme.colors.grey5,
  },
  modalTitleUserId: {
    fontSize: "14px",
    fontWeight: "bold",
    color: theme.colors.grey4,
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
  },
  goBack: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  continueButton: {
    background: "#36CE91",
    color: "#F6F6F6",
    "&:hover": {
      background: "#36CE91",
    },
  },
  createDisabled: {
    color: "#F6F6F6 !important",
    opacity: "0.4",
  },
  tabs: {
    "& .MuiTabs-indicator": {
      backgroundColor: "#7175FA"
    },
    "& .Mui-selected": {
      fontWeight: "700 !important",
      color: theme.colors.clearMain + " !important",
    },
    "& .MuiButtonBase-root": {
      minWidth: 0,
      alignItems: "left",
      marginRight: 25
    },
    "& .MuiTab-root": {
      fontFamily: "Roboto, sans-serif",
      fontStyle: "normal",
      fontWeight: 400,
      fontSize: "12px",
      lineHeight: "normal",
      color: theme.colors.grey5,
      paddingLeft: 0,
      paddingRight: 0,
      textTransform: "none"
    },
  },
  tab: {
    root: {
      ...theme.typography.txtBody2,
    },
    selected: {
      fontWeight: "bold",
    }
  },
  tabPanelRoot: {
    padding: "0 !important"
  }
}));

export default useStyles;