import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionFieldRadioItemIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M12 5C8.13306 5 5 8.13306 5 12C5 15.8669 8.13306 19 12 19C15.8669 19 19 15.8669 19 12C19 8.13306 15.8669 5 12 5ZM12 17.6452C8.86694 17.6452 6.35484 15.1331 6.35484 12C6.35484 8.89516 8.86694 6.35484 12 6.35484C15.1048 6.35484 17.6452 8.89516 17.6452 12C17.6452 15.1331 15.1048 17.6452 12 17.6452Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}