import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const DotsVerticalIcon = (props: any) => {
  return (
    <SvgIcon  {...props} color={"inherit"} viewBox={props.viewBox || "0 0 22 22"} >
        <circle cx="12" cy="5"  r="2" fill={`${props.color || "#1D2027"}`} />
        <circle cx="12" cy="12" r="2" fill={`${props.color || "#1D2027"}`} />
        <circle cx="12" cy="19" r="2" fill={`${props.color || "#1D2027"}`} />
    </SvgIcon>
  );
};

export default DotsVerticalIcon;
