import clsx from 'clsx';
import { GetOffsetLabel } from '../../DateTimeHelpers';
import { DateTime } from 'luxon';
import React, { CSSProperties, useContext, useEffect, useRef } from 'react';
import {
    AppointmentSchedulerInnerElementContext,
    generateStops,
    useAppointmentSchedulerContext,
} from '../_util';
import styles from './css.module.css';

export type TimelineStopProps = {
    ts: number;
};
export type StopComponent = React.ComponentType<TimelineStopProps>;

export type AppointmentSchedulerTimelineProps = {
    from: number;
    to: number;
    intervalInMinutes: number;
    pixelsPerMinute: number;
    component: StopComponent;
    classNameRoot?: string;
    showTimezone: boolean;
};

export default function AppointmentSchedulerTimeline({
    from,
    to,
    pixelsPerMinute,
    intervalInMinutes,
    component: Component,
    showTimezone,
    classNameRoot,
}: AppointmentSchedulerTimelineProps) {
    const stops = generateStops(from, to, intervalInMinutes);

    const { innerHeight } = useAppointmentSchedulerContext();
    const { element: innerElement } = useContext(AppointmentSchedulerInnerElementContext);
    const asideRef = useRef<HTMLElement | null>(null);

    useEffect(() => {
        if (!innerElement) return;

        const callback = () => {
            if (!asideRef.current) return;

            asideRef.current.scroll(0, innerElement.scrollTop);
        };

        innerElement.addEventListener('scroll', callback);
        return () => innerElement.removeEventListener('scroll', callback);
    }, [innerElement]);

    return (
        <aside
            ref={asideRef}
            style={{ height: innerHeight }}
            className={clsx(styles.container, classNameRoot)}
        >
            {showTimezone ? (
                <div className={clsx(styles.timezone, classNameRoot)}>
                    {GetOffsetLabel(DateTime.now().zoneName)}
                </div>
            ) : undefined}
            {stops.map((minutes) => {
                return (
                    <div
                        className={styles.mark}
                        data-ts={minutes}
                        key={minutes}
                        style={
                            {
                                '--pos': `${pixelsPerMinute * (minutes - from)}px`,
                                visibility:
                                    Math.abs(minutes - from) < 2 || Math.abs(minutes - to) < 2
                                        ? 'hidden'
                                        : undefined,
                            } as CSSProperties
                        }
                    >
                        <Component ts={minutes} />
                    </div>
                );
            })}
            {/* another "fake" mark to make sure that after last mark (23:30 for example) there is additional space for last grid row */}
            <div
                data-ts={stops[stops.length - 1] + intervalInMinutes}
                className={styles.mark}
                key="last"
                style={
                    {
                        '--pos': `${
                            pixelsPerMinute * (stops[stops.length - 1] + intervalInMinutes - 1)
                        }px`,
                        visibility: 'hidden',
                    } as CSSProperties
                }
            />
        </aside>
    );
}
