import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import InputWithLabel from "./../../common/InputWithLabel";
import DeleteIcon from "./../../../assets/icons/DeleteIcon";

import { EmergencyContact } from "../../../models/emergency-contact";
import { NameRegex } from "../../../constants/validator";
import InputMobileCode from "../../common/InputMobileCode";

import { CustomerEmergencyContactsProps } from "./props";
import useStyles from "./css";
import Country from "../../../models/country";

const CustomerEmergencyContacts = (props: CustomerEmergencyContactsProps) => {
  const { t } = useTranslation(["general"]);
  const classes = useStyles();
  const { zIndex, value, onChange, onDelete } = props;
  const [contact, setContact] = useState<EmergencyContact>(
    value || new EmergencyContact()
  );
  const [contactValid, setContactValid] =
    useState<{ name?: boolean; relationship?: boolean; mobile?: boolean }>();

  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(contact)) {
      setContact(value);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (onChange) {
      onChange(contact);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact, contactValid]);

  const handleNameChange = (event: any) => {
    setContactValid({
      ...contactValid,
      name: NameRegex.test(event.target.value),
    });
    setContact({ ...contact, name: event.target.value });
  };

  const handleRelationshipChange = (event: any) => {
    setContactValid({
      ...contactValid,
      relationship: NameRegex.test(event.target.value),
    });
    setContact({ ...contact, relationship: event.target.value });
  };

  const handleMobileChange = (mobile: any) => {
    setContact({
      ...contact,
      mobile
    });
  };

  const handlerOnDelete = () => {
    if (onDelete) {
      onDelete(contact);
    }
  };
  
  const handleMobileCodeChange = (item: Country) => {
    setContact({
      ...contact,
      mobileCodeIso3: item.iso3,
      mobileDigits: item.dialDigits,
      mobileCode: item.dialCode
    });
  };

  const handleValidMobile = (valid: boolean) => {
    setContactValid({
      ...contactValid,
      mobile: valid
    });
  }

  return (
    <div className={classes.root} >
      <div className={classes.spaceBetween}>
        <InputWithLabel
          label={() => (
            <>
              {t("Name")} <span className={classes.required}>*</span>
            </>
          )}
          placeholder={t("Enter the contact name")}
          value={contact?.name || ""}
          width={200}
          isValid={() => contactValid?.name}
          onChange={handleNameChange}
        />
      </div>
      <div className={classes.spaceBetween}>
        <InputWithLabel
          label={() => (
            <>
              {t("Relationship")} <span className={classes.required}>*</span>
            </>
          )}
          placeholder={t("Enter the relationship of the contact")}
          value={contact?.relationship || ""}
          width={200}
          isValid={() => contactValid?.relationship}
          onChange={handleRelationshipChange}
        />
      </div>
      <div className={classes.spaceBetween} style={{zIndex: 1000-zIndex!}}>
      
      <InputMobileCode 
            isOptional={false} 
            widthSelect={228} 
            widthList={280}
            isValid={handleValidMobile}
            label={t("Mobile Number")}
            onChangeItem={handleMobileCodeChange}
            onChangeMobile={handleMobileChange}
            codeIso3={contact?.mobileCodeIso3!}
            mobile={contact?.mobile!} />
            
      </div>
      <div className={classes.deleteContactDiv}>
        <span className={classes.deleteContactButton} onClick={handlerOnDelete}>
          <DeleteIcon />
        </span>
      </div>
    </div>
  );
};

export default CustomerEmergencyContacts;
