import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import IconButton from "@material-ui/core/IconButton";
import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import { Grid, MenuItem } from "@material-ui/core";
import Select from "@material-ui/core/Select";
import { conformToMask } from "react-text-mask";
import "antd/dist/antd.css";
import { TimePicker } from "antd";
import moment from "moment";

import { AppointmentService } from "./../../../api/appointment-service";
import { ClockIcon } from "../../../assets/icons";
import { AppointmentFollowUpProps } from "./props";
import TextareaWithLabel from "../../common/TextareaWithLabel";
import { ServiceGroupModel } from "../../../models/service-group-model";
import ServicesService from "../../../api/settings-services-service";
import { SelectItem } from "../../common/Select";
import { CreateFollowUpModel } from "../../../models/create-follow-up-model";
import { useAppDispatch, useAppSelector } from "./../../../redux/hooks";
import { alertsActions } from "./../../../redux/alerts-slice";
import { GetMobileMaskByTemp } from "./../../../constants/mask";

import useStyles from "./css";
import CountryFlagIcon from "../../../assets/flags";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";


const AppointmentFollowUp = (props: AppointmentFollowUpProps) => {
  
  const dispatch = useAppDispatch();
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const { enqueueAlert } = alertsActions;
  const history = useHistory();
  const { open, appointment, appointmentDetails, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  const format = "HH:mm";
  const mobileTemplate: string = "000-000-0000";
  
  const [isValid, setIsValid] = useState<Boolean>(false);
  const [timePickerValue, setTimePickerValue] = useState<string | null>(null);
  const [dateValue, setDateValue] = useState<Date>(new Date());
  const [availableDays, setAvailableDays] = useState<number[]>([]);
  const [services, setServices] = useState<ServiceGroupModel[]>([]);
  const [groupServices, setGroupServices] = useState<SelectItem[]>([]);
  const [serviceId, setServiceId] = useState<string>("");
  const [notes, setNotes] = useState<string>("");


  const handleGoBackClick = () => {
    onClose(false);
  }

  const handleOnCreateClick = () => {
    
    if (!timePickerValue) return;

    const hour = timePickerValue.split(":")[0];
    const min = timePickerValue.split(":")[1];

    const date1 = new Date(dateValue);
    const scheduled = new Date(date1.getFullYear(), date1.getMonth(), date1.getDate(), (+hour), (+min), 0);

    const followUp = new CreateFollowUpModel({
      appointmentId: appointment?.appointmentId,
      customerId: appointmentDetails?.customerId,
      serviceId: serviceId,
      scheduled: scheduled,
      notes: notes
    });

    (async() => {
      const result = await AppointmentService.createFollowUp(followUp);

      if (result) {

        onClose(true);

        dispatch(
          enqueueAlert({
            type: "Success",
            title: t("Follow-up created successfully"),
            description: null,
            // link: {
            //   onClick: (e: any) => {
            //     e.preventDefault();
            //     history.push(`${Url.FollowUps.Main}`);
            //   },
            //   title: t("Go to my follow-ups"),
            // }
          })
        );

      }
    })();
  }
  

  const handleTime = (e: any) => {
      setTimePickerValue(e);
  };


  const getLocale = (): any => {
    switch (localeApp) {
      case "es":
        if (esLocale && esLocale.options) esLocale.options = { ...esLocale.options, weekStartsOn: 1 };
        return esLocale;

      default:
        if (enLocale && enLocale.options) enLocale.options = { ...enLocale.options, weekStartsOn: 1 };
        return enLocale;
    }
  };


  const renderWrappedDay = (
    date: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean
  ) => {
    const day = new Date(date as Date);
    const selected = new Date(selectedDate as Date);
    const current = new Date();
    current.setHours(0, 0, 0, 0);
    const dayOff =
      availableDays.findIndex((dayOff) => dayOff === day.getDay()) !== -1;
    const disabled = day < current || dayOff;
    const isCurrentDate =
      day.getDate() === current.getDate() &&
      day.getMonth() === current.getMonth();
    const isSelected =
      selected.getDate() === day.getDate() &&
      selected.getMonth() === day.getMonth();

    return (
      <div className={classes.dateRoot}>
        {dayInCurrentMonth ? (
          <IconButton
            className={`${classes.date} ${
              isCurrentDate ? classes.currentDate : ""
            } ${isSelected ? classes.selectedDate : ""}`}
            disabled={disabled}
          >
            {day.getDate()}
          </IconButton>
        ) : null}
      </div>
    );
  };


  const handleDisabledDay = (date: MaterialUiPickersDate): boolean => {
    const day = new Date(date as Date);
    const current = new Date();
    current.setHours(0, 0, 0, 0);
    const dayOff =
      availableDays.findIndex((dayOff) => dayOff === day.getDay()) !== -1;
    return day < current || dayOff;
  };


  const handleChangeDate = (date: MaterialUiPickersDate) => {
    setDateValue(date as Date);
  };
  

  const numberWithCommas = (x: any) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  

  const loadServices = async () => {
    if (!open)
      return;
    const services = await ServicesService.getServicesGroupByCategory();

    setServices(services);
    const selectArray: SelectItem[] = [];

    const showPrices = true;

    services.map((category, index) => {
      selectArray.push({
        key: `${category.category}-${index}`,
        value: `${category.category} (${category.services?.length})`,
        isGroup: true,
      });
      category.services?.map((service) =>
        selectArray.push({
          key: service.id,
          value:
            `${service.name}` +
            (showPrices ? ` ($${numberWithCommas(service.price)})` : ""),
        })
      );

      return null;
    });
    setGroupServices(selectArray);
  };


  const getServiceName = (id: any): string => {
    if (!id) {
      return t("Select one of your services");
    }

    const category = services.find(
      (service) => service.services?.findIndex((svc) => svc.id === id) !== -1
    );

    const categoryService = category?.services?.find(
      (service) => service.id === id
    );

    return `${category?.category} \u00B7 ${categoryService?.name}`;
  };


  const handleServiceChange = (event: React.ChangeEvent<{ value: any }>) => {
    const id = event.target.value;
    setServiceId(id);
  };


  const handleNotesChange = (e: any) => {
    const { value } = e.target;
    setNotes(value);
  };


  class OverriddenDateFnsUtils extends DateFnsUtils {
    getWeekdays() {
      if (localeApp === "es")
        return ["Lun", "Mar", "Mié", "Jue", "Vie", "Sáb", "Dom"];
      else
        return ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]

    }
  }
  

  useEffect(() => {

   if (timePickerValue
      && dateValue
      && serviceId
      && notes) {
      setIsValid(true);
    } else {
      setIsValid(false);
    }

  }, [timePickerValue, dateValue, serviceId, notes])


  useEffect(() => {

    (async () => {
      setDateValue(new Date());
      setTimePickerValue(null);
      setServiceId(appointment?.firstServiceId ?? "");
      setNotes("");
      setIsValid(false);
      await loadServices();
      await loadAvailableDays();
    })();

  }, [open])

  
  const loadAvailableDays = async () => {
    if (!open)
      return;
    const days = await AppointmentService.getAvailableDays();
    setAvailableDays(days);
  };


  return (<>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }} >

      <Fade in={open}>
        <div className={classes.modalPaper}>
            
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.modalTitleText} variant="h1">
                  {t("New follow-up")}
              </Typography>
            </Grid>
            <Grid item xs={8} style={{textAlign: "right"}}>
            <Button
                  className={`${classes.button} ${classes.goBack}`}
                  onClick={handleGoBackClick}
                >
                  {t("Go back")}
                </Button>
            
            <Button
                  className={`${classes.button} ${classes.create}`}
                  disabled={!isValid}
                  classes={{ disabled: classes.createDisabled }}
                  onClick={handleOnCreateClick}
                >
                  {t("Create follow-up")}
                </Button>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{marginTop: 30}} >
            <Grid item xs={12}>
              <Typography className={classes.requiredText}>
                {t("Required fields")}
                <span className={classes.requiredSymbol}>{" *"}</span>
              </Typography>
            </Grid>
          </Grid>

          <Grid container spacing={2} style={{marginTop: 10}} >
            <Grid item xs={6}>
              <Typography className={classes.labelText} style={{marginBottom: 10}}>
                {t("Select the date")}
                <span className={classes.requiredSymbol}>{" *"}</span>
              </Typography>

              <MuiPickersUtilsProvider utils={OverriddenDateFnsUtils} locale={getLocale()} >
                <DatePicker
                  autoOk
                  disableToolbar
                  fullWidth
                  variant="static"
                  openTo="date"
                  value={dateValue}
                  onChange={handleChangeDate}
                  renderDay={renderWrappedDay}
                  shouldDisableDate={handleDisabledDay}
                  disableFuture={false}
                  style={{
                    alignItems: "center",
                    alignContent: "center",
                    justifyContent: "center",
                  }}
                />
              </MuiPickersUtilsProvider>

            </Grid>
            <Grid item xs={6}>

              <Typography className={classes.labelText} style={{marginBottom: 10}}>
                {t("Time")}
                <span className={classes.requiredSymbol}>{" *"}</span>
              </Typography> 

              <div style={{marginBottom: 20}}>
                <TimePicker
                  key={`timePicker`}
                  className={`${classes.selectStyle}`}
                  placeholder={t("Time the follow-up will be sent")}
                  inputReadOnly={true}
                  showNow={false}
                  value={timePickerValue !== null ? moment(timePickerValue, format) : timePickerValue}
                  onOk={(timePicker) => { handleTime(timePicker.format(format)); }}
                  format={format}
                />
                <div className={classes.divClock}>
                  <ClockIcon />
                </div>
              </div>
             
              <div style={{marginBottom: 20}}>

                <Typography className={classes.labelText} style={{marginBottom: 10}}>
                  {t("Service")}
                  <span className={classes.requiredSymbol}>{" *"}</span>
                </Typography> 

                <Select
                  disableUnderline
                  displayEmpty
                  placeholder={t("Select one of your services")}
                  id="service-id"
                  name="service"
                  className={classes.selectService}
                  classes={{
                    root: `${classes.selectRoot} ${
                      serviceId ? classes.selected : classes.placeholder
                    }`,
                  }}
                  style={{ width: "100%" }}
                  value={groupServices.length ? serviceId! : ""}
                  onChange={handleServiceChange}
                  renderValue={getServiceName}
                  inputProps={{ style: { padding: 0 } }}
                  MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "left",
                    },
                  }}>
                  <MenuItem classes={{ root: classes.itemRoot }} value={""} disabled>
                    {t("Select one of your services")}
                  </MenuItem>
                  {groupServices && groupServices.length
                    ? groupServices.map((category, index) => {
                        if (category.isGroup) {
                          return (
                            <MenuItem
                              key={category.key}
                              classes={{ root: classes.group }}
                              disabled
                            >
                              {category.value}
                            </MenuItem>
                          );
                        }

                        return (
                          <MenuItem
                            key={category.key}
                            classes={{
                              root: classes.itemRoot,
                              selected: classes.itemSelected,
                            }}
                            value={category.key}
                          >
                            {category.value}
                          </MenuItem>
                        );
                      })
                    : null}
                </Select>
              </div>

              <div style={{marginBottom: 10}}>
                <TextareaWithLabel
                  value={notes}
                  width="100%"
                  maxRows={3}
                  showCounter={true}
                  inputClass={classes.inputTextareaClass}
                  label={() => (<>
                      <Typography className={classes.labelText}>
                        {t("Notes for the customer")}
                        <span className={classes.requiredSymbol}>{" *"}</span>
                      </Typography> 
                  </>)}
                  placeholder={t("Write relevant notes for follow-up")}
                  maxLength={700}
                  onChange={handleNotesChange}
                />
              </div>

              <div style={{marginBottom: 5}}>

                <Typography className={classes.labelNotes}>
                  {t("The notes will be sent to the customer via WhatsApp.")}
                </Typography> 

                <Typography className={classes.labelNotes}>
                  {t("Example: “This is a reminder that you should schedule an appointment to review the progress of your hair treatment.”")}
                </Typography> 
              </div>

            </Grid>
          </Grid>

          <hr />
          
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <Typography className={classes.labelNotes}>
                {useCustomerTerminology(t("Customer name"), "Customer name")}
              </Typography> 
              <Typography className={classes.labelText}>
                {appointment?.customerName}
              </Typography> 
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.labelNotes}>
                {t("Email")}
              </Typography> 
              <Typography className={classes.labelText}>
                {appointmentDetails?.customerEmail ?? "- -"}
              </Typography> 
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.labelNotes}>
                {t("PhoneNumberCellular")}
              </Typography> 
              <div className={classes.labelText}>
                <div className={classes.divFlag}>
                    <CountryFlagIcon iso={appointmentDetails?.customerMobileCodeIso3 || "MEX"} />
                </div>
                <div className={classes.divCode}> 
                    {appointmentDetails?.customerMobileCode !== undefined ? `+${appointmentDetails?.customerMobileCode}` : "+52"}
                </div>
                <div className={classes.divMobile}>
                  {appointmentDetails?.customerMobile ? conformToMask(appointmentDetails?.customerMobile, GetMobileMaskByTemp(appointmentDetails.customerMobileTemplate || mobileTemplate)).conformedValue : ""}
                </div>
              </div> 
            </Grid>
          </Grid>

        </div>
      </Fade>

    </Modal>

  </>);
};

export default AppointmentFollowUp;
