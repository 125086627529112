import { SignatureImageType } from "./enums/signature-image-type";
import { FieldAndValue } from "./field-and-value";

export class UserProfessionalInfo {
  fieldsAndValues: FieldAndValue[];
  signatureImageUrl: string | null;
  signatureReactCanvasDrawJson: string | null
  signType: SignatureImageType | null;
  uploadedSignatureWasDeleted: boolean;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.fieldsAndValues = x.fieldsAndValues;
      this.signatureImageUrl = x.signatureImageUrl;
      this.signType = x.signType;
      this.signatureReactCanvasDrawJson = x.signatureReactCanvasDrawJson;
      this.uploadedSignatureWasDeleted = x.uploadedSignatureWasDeleted;
    }
    else {
      this.fieldsAndValues = [];
      this.signatureImageUrl = null;
      this.signType = null;
      this.signatureReactCanvasDrawJson = null;
      this.uploadedSignatureWasDeleted = false;
    }
  }
}