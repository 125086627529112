import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BillIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      width="24" height="24" viewBox="0 0 24 24" fill="none" 
    >
      <path fill-rule="evenodd" clip-rule="evenodd" d="M3.59798 6H20.4085C20.9242 6 21.3424 6.41969 21.3424 6.93721V16.3093C21.3424 16.8268 20.9242 17.2465 20.4085 17.2465H3.59798C3.08228 17.2465 2.66406 16.8268 2.66406 16.3093V6.93721C2.66406 6.41969 3.08228 6 3.59798 6ZM18.0737 15.8407C18.0737 14.8053 18.9099 13.9663 19.9415 13.9663V9.28022C18.9099 9.28022 18.0737 8.44113 18.0737 7.40581H5.93278C5.93278 8.44113 5.09663 9.28022 4.06494 9.28022V13.9663C5.09663 13.9663 5.93278 14.8053 5.93278 15.8407H18.0737ZM11.9375 13.7609C13.1362 13.7609 14.1079 12.7891 14.1079 11.5905C14.1079 10.3918 13.1362 9.42011 11.9375 9.42011C10.7388 9.42011 9.76711 10.3918 9.76711 11.5905C9.76711 12.7891 10.7388 13.7609 11.9375 13.7609Z" fill="#5C6477"/>
    </SvgIcon>
);
};

export default BillIcon;
