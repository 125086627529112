import React, { ReactNode, forwardRef, useCallback, useEffect, useImperativeHandle, useRef, useState } from "react";
import { Button, Grid, Tooltip, TextareaAutosize, Typography, Menu, MenuItem, ListItemText, ListItemIcon } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import { useDispatch } from 'react-redux';
import { alertsActions } from "../../../redux/alerts-slice";
import { useStyles } from "./css";
import { WhatsAppService } from "../../../api/whatsapp-service"

import { Message as MessageModel } from "../../../models/message";

import Message from "./../Message";
import ArrowDropDownTriangleIcon from "../../../assets/icons/ArrowDropDownTriangleIcon";
import ScheduledSendIcon from "../../../assets/icons/ScheduledSendIcon";
import MercadoPagoLinkIcon from "../../../assets/icons/MercadoPagoLinkIcon";
import ScheduledSendIconWithTooltip from "../common/ScheduledSendIconWithTooltip"
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment-timezone";
import ImageCarousel from "../ImageCarousel";

import SnackbarNotification, { SnackbarNotificationKind } from "../../common/notifications/SnackbarNotification";
import SelectWhatsappTemplate from "../SelectWhatsAppTemplate";
import ChatToolbar from "../ChatToolbar";
import { Markup } from "interweave";
import MessengerRefs from "../Messenger/messenger-refs";
import { SelectItem } from "../../../models/interfaces/select-item";
import { WhatsAppTemplateType } from "../../../models/enums/whatsapp-template-type";
import { WhatsAppTemplate } from "../../../models/whatsapp-template";
import { ConversationMessageType } from "../../../models/enums/conversation-message-type";
import { GetSpecificConversationMessagesResponse } from "../../../models/get-specific-conversation-messages-response";
import BlockCustomerIcon from "../BlockCustomerIcon/block-customer-icon";
import BlockCustomerModal from "../BlockCustomerModal/block-customer-modal";
import UnblockCustomerModal from "../UnblockCustomerModal";

const ConversationWindowMemo = forwardRef<MessengerRefs, any>((props: any, ref) => {
    const classes = useStyles();
    const { t } = useTranslation(["general"]);
    const {
        selectedConversation,
        lastMessageId,
        customerId,
        customerName,
        customerMobile,
        isDisabledActivity,
        isDisabledNotContactable,
        isCustomerBlocked,
        unreadMessagesCount,
        canScheduleFollowUp,
        hasScheduledFollowUp,
        hasUnpaidAppointment,
        markConversationAsRead,
        isSearch,
        foundMessageText,
        establishmentId,
        establishmentName,
        onBlockCustomer
    } = props;
    const isCustomerRegistered= isNaN(+customerName);
    
    const dispatch = useDispatch();
    const { enqueueAlert } = alertsActions;
    const [outMessage, setOutMessage] = useState<string>("");
    const [carouselIsOpen, setCarouselIsOpen] = useState<boolean>(false);
    const [clickedMessageId, setClickedMessageId] = useState<string>("");
    const [anchorSendDropdown, setAnchorSendDropdown] = useState<null | HTMLElement>(null);
    const openSendDropdown = Boolean(anchorSendDropdown);
    const [linkCopiedSnackbarShown, setLinkCopiedSnackbarShown] = useState<boolean>(false);
    const [currentChatMessages, setCurrentChatMessages] = useState<MessageModel[]>([]);
    const [availableTemplates, setAvailableTemplates] = useState<WhatsAppTemplate[]>([]);
    const [templateType, setTemplateType] = useState<WhatsAppTemplateType | null>(null);
    const [openBlockCustomerModal, setOpenBlockCustomerModal] = useState<boolean>(false);

    const domObserver = useRef<MutationObserver | null>(null);
    const textInput = useRef<HTMLTextAreaElement>(null);
    const chatWindowRef = useRef<HTMLDivElement>(null);
    const isLoadingRef = useRef<boolean>(false);
    const currentPageNumberRef = useRef<number>(1);
    const messagesOffsetRef = useRef<number>(0);
    const hasMoreRef = useRef<boolean>(true);

    const pageSize = 50;
    const templateTypes: SelectItem[] =
        [
            { key: WhatsAppTemplateType.AfterGivingAQuote, value: t("Follow-up after giving a quote") },
            { key: WhatsAppTemplateType.AfterAppointmentNoShow, value: t("Follow-up after appointment no show") },
            { key: WhatsAppTemplateType.SiteForAppointmentsLink, value: t("Site for appointments link") },
            { key: WhatsAppTemplateType.AfterNotAnsweringCustomerQuestion, value: t("Follow-up after not answering a customer's question") },
            { key: WhatsAppTemplateType.OnPendingPaymentWithMercadoPagoLink, value: t("Follow-up on pending payment with Mercado Pago link") },
        ]

    const availableTemplateTypes = templateTypes
        .filter(({ key }) => availableTemplates.some(template => template.templateType === key) || key === WhatsAppTemplateType.SiteForAppointmentsLink)
        .sort((a, b) => a.value.localeCompare(b.value));

    useImperativeHandle(ref, () => ({
        addNewMessages: (messages) => {
            messagesOffsetRef.current += messages.length;
            setCurrentChatMessages([...currentChatMessages, ...messages]);
        }
    }));

    useEffect(() => {
        if (isSearch) {
            const messagesWithHighlightedOne = getMessagesWithHighlightedOne(currentChatMessages);
            setCurrentChatMessages(messagesWithHighlightedOne);
        }
    }, [lastMessageId]);

    useEffect(() => {
        resetChatWindowState();
        if (!selectedConversation.customerPhoneNumber)
            return;

        (async () => {
            if (customerMobile && customerMobile !== "") {
                const response = await fetchFirstPageMessages();
                if (response.messages.length < pageSize)
                    hasMoreRef.current = false;
                if (isSearch) {
                    const messagesWithHighlightedOne = getMessagesWithHighlightedOne(response.messages);
                    currentPageNumberRef.current = response.currentPageNumber;
                    setCurrentChatMessages(messagesWithHighlightedOne);
                }
                else {
                    setCurrentChatMessages(response.messages);
                }
            }
        })().then(() => {
            if (isSearch) {
                scrollToMessage();
            }
            else {
                scrollToBottom();
            }
        });
    }, [selectedConversation]);

    useEffect(() => {
        (async () => {
            if (unreadMessagesCount > 0) {
                await markConversationAsRead(customerMobile);
            }
        })();
    }, [unreadMessagesCount]);

    useEffect(() => {
        scrollToMessage();
    }, [lastMessageId]);

    useEffect(() => {
        const chatWindowElement = chatWindowRef.current;
        chatWindowElement?.addEventListener("scroll", handleScrollReachTop);

        return () => {
            chatWindowElement?.removeEventListener("scroll", handleScrollReachTop);
        };
    }, [customerMobile]);

    async function handleScrollReachTop() {
        if (isLoadingRef.current)
            return;

        const chatWindow = chatWindowRef.current!;
        const maxScrollTop = 0 - (chatWindow.scrollHeight - chatWindow.clientHeight);
        const hasScrollTrackAtVisualTop = chatWindow.scrollTop - 1 <= maxScrollTop;
        const hasScroll = maxScrollTop !== 0;
        if (hasScroll && hasScrollTrackAtVisualTop) {
            await loadMoreMessages();
        }

    };

    async function fetchFirstPageMessages(): Promise<GetSpecificConversationMessagesResponse> {
        switch (true) {
            case (!isSearch && !establishmentId):
                return await WhatsAppService.getSpecificConversationMessages(
                    customerMobile,
                    0,
                    null,
                    1,
                    null);
            case (isSearch && !establishmentId):
                return await WhatsAppService.getSpecificConversationMessages(
                    customerMobile,
                    0,
                    null,
                    null,
                    lastMessageId);
            case (!isSearch && !!establishmentId):
                return await WhatsAppService.getSpecificConversationMessages(
                    customerMobile,
                    0,
                    establishmentId,
                    1,
                    null);
            case (isSearch && establishmentId):
                return await WhatsAppService.getSpecificConversationMessages(
                    customerMobile,
                    0,
                    establishmentId,
                    null,
                    lastMessageId);
        }

        return Promise.resolve(new GetSpecificConversationMessagesResponse());
    }

    async function loadMoreMessages() {
        if (isLoadingRef.current || !hasMoreRef.current)
            return;

        isLoadingRef.current = true;

        // Need to prevent search result message following on paginating while search
        if (isSearch)
            removeDomObserver();

        await WhatsAppService.getSpecificConversationMessages(
            customerMobile,
            messagesOffsetRef.current,
            establishmentId,
            currentPageNumberRef.current + 1,
            null)
            .then(response => {
                if (response.messages.length < pageSize)
                    hasMoreRef.current = false;
                setCurrentChatMessages(prevMessages => response.messages.concat(prevMessages));
            });

        currentPageNumberRef.current++;;
        isLoadingRef.current = false;
    };

    function scrollToBottom() {
        domObserver.current?.disconnect();
        domObserver.current = null;
        chatWindowRef.current!.scrollTo(0, chatWindowRef.current!.scrollHeight);
    }

    function scrollToMessage() {
        if (!isSearch || !lastMessageId)
            return;

        const messageElement = document?.getElementById(`target${lastMessageId}`);
        if (!messageElement)
            return;

        messageElement.scrollIntoView({ behavior: "auto", block: 'nearest', inline: 'end' });
        messageElement.focus({ preventScroll: true });
        const messageRect = messageElement.getBoundingClientRect();

        // Set up a MutationObserver to detect changes to the DOM
        domObserver.current?.disconnect();
        domObserver.current = null;
        domObserver.current = new MutationObserver(() => {
            // Calculate the new position of the message element relative to the viewport
            const newRect = messageElement!.getBoundingClientRect();
            const scrollPosition = chatWindowRef.current!.scrollTop + newRect.top - messageRect.top;

            // Adjust the scroll position to keep the message element fixed in place
            chatWindowRef.current!.scrollTop = scrollPosition;
        });

        // Start observing changes to the DOM within the scrollable container
        domObserver.current.observe(chatWindowRef.current!, { childList: true, subtree: true });
    }

    function removeDomObserver() {
        if (domObserver.current) {
            domObserver.current.disconnect();
            domObserver.current = null;
        }
    }

    function resetChatWindowState() {
        currentPageNumberRef.current = 1;
        messagesOffsetRef.current = 0;
        isLoadingRef.current = false;
        hasMoreRef.current = true;
    }

    function getMessagesWithHighlightedOne(messages: MessageModel[]): MessageModel[] {
        const messagesWithHighlightedOne = messages = messages.map(m => {
            if (m.id === lastMessageId) {
                return {
                    ...m, text: foundMessageText
                        .replace("color: #7175FA", "background-color: #7175FA; color: white")
                };
            }
            return m;
        });

        return messagesWithHighlightedOne;
    }

    async function sendTemplate() {
        const cleanedCustomerName = customerName.replace(/<\/?[^>]+(>|$)/g, ''); // remove HTML tags
        await WhatsAppService.sendTemplate(customerId, cleanedCustomerName, customerMobile, templateType!);
        setTemplateType(null);
        handleSendDropdownClose();
    }

    async function handleOnSelectTemplateOpen() {
        const availableTemplatesData = await WhatsAppService.getAvailableTemplates(customerId);
        setAvailableTemplates(availableTemplatesData);
    }

    const openCarousel = useCallback(() => {
        setCarouselIsOpen(true);
    }, []);

    const setClickedMessageIdCallback = useCallback((messageId: string) => {
        setClickedMessageId(messageId);
    }, []);

    const setOutboundMessageText = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setOutMessage(event.target.value);
    }, []);

    const handleKeyDown = useCallback((event: React.KeyboardEvent<{ value: unknown }>) => {
        const keyCode = event.key;
        if (keyCode === "Enter" && !event.shiftKey) {
            event.preventDefault();
            event.stopPropagation();
            sendMessage();
        }
    }, [outMessage]);

    const mapMessagesToComponents = (messages: MessageModel[]) => {
        let daysByMessages: Map<string, boolean> = new Map<string, boolean>();
        const timeNow = moment().utc();
        const messagesComponents: ReactNode[] = messages.map((m) => {
            const messageMoment = moment(m.time);
            const dayKey = messageMoment.format("DDMMYYYY")
            let dayLabel: string;
            if (daysByMessages.get(dayKey) === true) {
                return <Message
                    key={m.id}
                    message={m}
                    openCarousel={openCarousel}
                    setClickedMessageId={setClickedMessageIdCallback}
                    isCustomerRegistered={isCustomerRegistered}
                />
            }
            else {
                daysByMessages.set(dayKey, true);
                switch (true) {
                    case moment(m.time).isSame(timeNow, "day"): {
                        dayLabel = t("Today").toUpperCase();
                        break;
                    }
                    case moment(m.time).add(1, "days").isSame(timeNow, "day"): {
                        dayLabel = t("Yesterday").toUpperCase();
                        break;
                    }
                    case moment(m.time).add(2, "days").isSame(timeNow, "day"): {
                        dayLabel = t(messageMoment.format("dddd")).toUpperCase();
                        break;
                    }
                    case moment(m.time).add(3, "days").isSame(timeNow, "day"): {
                        dayLabel = t(messageMoment.format("dddd")).toUpperCase();
                        break;
                    }
                    case moment(m.time).add(4, "days").isSame(timeNow, "day"): {
                        dayLabel = t(messageMoment.format("dddd")).toUpperCase();
                        break;
                    }
                    case moment(m.time).add(5, "days").isSame(timeNow, "day"): {
                        dayLabel = t(messageMoment.format("dddd")).toUpperCase();
                        break;
                    }
                    case moment(m.time).add(6, "days").isSame(timeNow, "day"): {
                        dayLabel = t(messageMoment.format("dddd")).toUpperCase();
                        break;
                    }
                    default: {
                        dayLabel = messageMoment.format("DD/MM/yyyy");
                        break;
                    }
                }
                return <div key={`dateLabel${m.id}`}>
                    <div className={classes.dayLabel} key={`dateLabelInner${m.id}`}>
                        <div className={classes.dayLabelBox}>
                            <Typography className={classes.dayLabelText}>
                                {dayLabel}
                            </Typography>
                        </div>
                    </div>
                    <Message
                        key={m.id}
                        message={m}
                        openCarousel={openCarousel}
                        setClickedMessageId={setClickedMessageIdCallback}
                        isCustomerRegistered={isCustomerRegistered}
                    />
                </div>
            }

        })
        return messagesComponents;
    };

    const handleSendDropdownClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorSendDropdown(event.currentTarget);
    };

    const handleSendDropdownClose = () => {
        setAnchorSendDropdown(null);
    };

    const sendMessage = () => {
        (async () => {
            establishmentId
                ? await WhatsAppService.sendTextMessageAsEstablishment(customerId, establishmentId, customerMobile, outMessage)
                : await WhatsAppService.sendTextMessage(customerId, customerMobile, outMessage)
        })();
        setOutMessage("");
    };

    const scheduleFollowUp = () => {
        (async () => {
            await WhatsAppService.scheduleFollowUp(customerId, customerMobile)
        })();
        handleSendDropdownClose();
    };

    const sendMercadoPagoLink = () => {
        (async () => {
            await WhatsAppService.sendMercadoPagoLink(customerId, customerName, customerMobile)
        })();
        handleSendDropdownClose();
    };

    const formatCustomerNameWithPlusSign = (customerName: string) => {
        if (!isNaN(+customerName)) {
            customerName = "+" + customerName;
        }
        return customerName;
    };

    const handleCopyLinkClick = () => {
        // actual copying is done by CopyToClipboard inside the toolbar
        setLinkCopiedSnackbarShown(true);
        // re-focus the input
        textInput.current?.focus();
    };

    const getActiveCarouselIndex = () => {
        const mappedObjs = currentChatMessages
        .filter((m: MessageModel) =>
          m.originalFileName &&
          m.previewFileUrl &&
          (m.type === ConversationMessageType.Image || m.type === ConversationMessageType.Video))
        .map((m: MessageModel) => ({ id: m.id, time: m.time, type: m.type, url: m.fileUrl! }))
        .sort((o: { time: any; }) => o.time);

        const openedItemIndex = mappedObjs.findIndex((o: { id: any; }) => o.id === clickedMessageId);

        return openedItemIndex;
    }

    const getCarouselItemsCount = (messages: MessageModel[]) => {
        return messages
            .filter((m: MessageModel) =>
                m.originalFileName &&
                (m.originalFileName.endsWith(".jpeg") ||
                  m.originalFileName.endsWith(".jpg") ||
                  m.originalFileName.endsWith(".png") ||
                  m.originalFileName.endsWith(".mp4")
                )).length;
    }
    
    const handleBlockCustomerModalClick = async (block: boolean) => {
        setOpenBlockCustomerModal(false);
        await onBlockCustomer(customerId, customerMobile, block);
        if (block) {
            dispatch(
              enqueueAlert({
                  type: "Error",
                  title: formatCustomerNameWithPlusSign(customerName),
                  description: t(isCustomerRegistered ? "The customer has been blocked" : "The number has been blocked"),
              }));
        } else {
            dispatch(
              enqueueAlert({
                  type: "Success",
                  title: formatCustomerNameWithPlusSign(customerName),
                  description: t(isCustomerRegistered ? "The customer has been unblocked" : "The number has been unblocked"),
              }));
        }
    }
    
    const BlockIcon = () =>
      <BlockCustomerIcon
        onClick={() => setOpenBlockCustomerModal(true)}
        isBlocked={isCustomerBlocked}
        isRegistered={isCustomerRegistered}
      />

    return (
         <>
            <div className={classes.windowContainer}>
                <div className={classes.header} id="header">
                    <>
                        {
                            establishmentName
                                ?
                                <Typography className={classes.headerTitleWithEstablishmentName}>
                                    <Markup content={formatCustomerNameWithPlusSign(customerName)} />
                                    <Typography className={classes.establishmentName}>{establishmentName}</Typography>
                                </Typography>
                                :
                                <Typography className={classes.headerTitle}>
                                    {hasScheduledFollowUp ? <ScheduledSendIconWithTooltip title={t("Send scheduled message")} /> : <></>}
                                    <Markup content={formatCustomerNameWithPlusSign(customerName)} />
                                </Typography>
                        }
                        <div className={classes.headerActions}>
                            <BlockIcon />
                        </div>
                    </>
                </div>
                <div
                    ref={chatWindowRef}
                    className={`${classes.buildScrollbars} ${classes.messageWindow}`}
                >
                    <div>
                        {mapMessagesToComponents(currentChatMessages)}
                    </div>
                </div>
                <div className={classes.inputLayout} id="input">
                    <div className={classes.inputItemsLayout}>
                        {isDisabledActivity || isDisabledNotContactable
                            ?
                            <Grid className={classes.disabledInputLayout}>
                                {templateType == null && (
                                    <div className={`${classes.disabledInput} ${classes.inputText}`}>
                                        {t("Unable to send a free text")}
                                    </div>
                                )}
                                {templateType == null && (
                                    <div>
                                        <SelectWhatsappTemplate
                                            value={templateType}
                                            onOpen={handleOnSelectTemplateOpen}
                                            setValue={setTemplateType}
                                            items={availableTemplateTypes}
                                            availableTemplates={availableTemplates}
                                            disabled={isCustomerBlocked}
                                        />
                                        <SnackbarNotification
                                            isOpen={linkCopiedSnackbarShown}
                                            onClose={() => setLinkCopiedSnackbarShown(false)}
                                            message={t("Link copied")}
                                            notificationKind={SnackbarNotificationKind.whatsappChatWindowLinkCopied}
                                        />
                                    </div>
                                )}
    
                                {templateType !== null && (
                                    <div className={classes.templateWindowArea}>
                                        <Tooltip placement={"top"}
                                            title={`${t("Unable to send a free tooltip")}`}
                                            classes={{ tooltip: classes.tooltipBlock }}>
                                            <div className={classes.templateTextAreaContainer}>
                                                <div className={classes.closeWindowButton}>
                                                    <CloseIcon onClick={() => setTemplateType(null)} className={classes.closeIcon} />
                                                </div>
                                                <div className={classes.templateTextContainer}
                                                    dangerouslySetInnerHTML={{ __html: availableTemplates.find(x => x.templateType === templateType)!.templateText }}>
                                                </div>
                                            </div>
                                        </Tooltip>
                                        <div className={classes.buttonContainer}>
                                            <Button
                                                className={`${classes.enabledSendButton}`}
                                                onClick={sendTemplate}
                                            >
                                                {t("SendWhatsappTemplate")}
                                            </Button>
                                        </div>
                                    </div>
    
                                )}
                            </Grid>
                            :
                            <div className={classes.inputAreaContainer}>
                                <div className={classes.messageInputArea}>
                                    <TextareaAutosize
                                        className={`${classes.inputArea} ${classes.inputText}`}
                                        placeholder={t("Type a message...")}
                                        maxLength={2048}
                                        maxRows={1}
                                        value={outMessage}
                                        onKeyDown={handleKeyDown}
                                        onChange={setOutboundMessageText}
                                        disabled={isCustomerBlocked}
                                    />
                                    <ChatToolbar onCopyLinkClick={handleCopyLinkClick} />
                                </div>
                                <div className={classes.sendMessageButton}>
                                    <Button
                                        disabled={outMessage.length === 0 || isCustomerBlocked}
                                        className={`${classes.enabledSendButton} ${canScheduleFollowUp || hasUnpaidAppointment ? classes.enabledSendButtonWithDropdown : undefined}`}
                                        onClick={sendMessage}
                                    >
                                        {t("Send ")}
                                    </Button>
                                    <SnackbarNotification
                                        isOpen={linkCopiedSnackbarShown}
                                        onClose={() => setLinkCopiedSnackbarShown(false)}
                                        message={t("Link copied")}
                                        notificationKind={SnackbarNotificationKind.whatsappChatWindowLinkCopied}
                                    />
                                    {canScheduleFollowUp || hasUnpaidAppointment
                                        ? <>
                                            <Button
                                                id="send-dropdown-button"
                                                className={`${classes.enabledSendButton} ${classes.enabledSendDropdownButton}`}
                                                aria-controls={openSendDropdown ? 'send-dropdown-menu' : undefined}
                                                aria-haspopup="true"
                                                aria-expanded={openSendDropdown ? 'true' : undefined}
                                                onClick={handleSendDropdownClick}
                                                disabled={isCustomerBlocked}
                                            >
                                                <ArrowDropDownTriangleIcon />
                                            </Button>
                                            <Menu
                                                id="send-dropdown-menu"
                                                anchorEl={anchorSendDropdown}
                                                open={openSendDropdown}
                                                onClose={handleSendDropdownClose}
                                                MenuListProps={{
                                                    'aria-labelledby': 'send-dropdown-button',
                                                }}
                                                anchorOrigin={{
                                                    vertical: 'top',
                                                    horizontal: 'right',
                                                }}
                                                transformOrigin={{
                                                    vertical: 'bottom',
                                                    horizontal: 'right',
                                                }}
                                            >
                                                {hasUnpaidAppointment &&
                                                    <MenuItem
                                                        className={classes.menuItem}
                                                        onClick={sendMercadoPagoLink}>
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <MercadoPagoLinkIcon />
                                                        </ListItemIcon>
                                                        <ListItemText className={classes.listItemText}>
                                                            {t("Mercado Pago link")}
                                                        </ListItemText>
                                                    </MenuItem>
                                                }
                                                {canScheduleFollowUp &&
                                                    <MenuItem
                                                        className={classes.menuItem}
                                                        onClick={scheduleFollowUp}>
                                                        <ListItemIcon className={classes.listItemIcon}>
                                                            <ScheduledSendIcon />
                                                        </ListItemIcon>
                                                        <ListItemText className={classes.listItemText}>
                                                            {t("Schedule follow-up")}
                                                        </ListItemText>
                                                    </MenuItem>
                                                }
                                            </Menu>
                                        </>
                                        : <></>}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                {carouselIsOpen
                  ?
                  <ImageCarousel
                    carouselIsOpen={carouselIsOpen}
                    closeCarousel={() => setCarouselIsOpen(false)}
                    messages={currentChatMessages}
                    activeImageIndex={getActiveCarouselIndex()}
                    imagesCount={getCarouselItemsCount(currentChatMessages)}
                  />
                  :
                  <></>
                }
    
            </div>
        <BlockCustomerModal
            open={openBlockCustomerModal && !isCustomerBlocked}
            customerName={customerName}
            onContinue={() => handleBlockCustomerModalClick(true)}
            onClose={() => setOpenBlockCustomerModal(false)}/>
         <UnblockCustomerModal
            open={openBlockCustomerModal && isCustomerBlocked}
            customerName={customerName}
            onContinue={() => handleBlockCustomerModalClick(false)}
            onClose={() => setOpenBlockCustomerModal(false)}/>
      </>
    );
});

const ConversationWindow = React.memo(ConversationWindowMemo);
export default ConversationWindow;