import React from "react";
import MuiPagination from "@material-ui/lab/Pagination";

import useStyles from "./css";
import { PaginationProps } from "./props";

const Pagination = (props: PaginationProps) => {
  const classes = useStyles();
  const { count, page, onPageChange, showPaginationAlways } = props;

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    page: number
  ) => {
    onPageChange(event, page);
  };

  if (count <= (showPaginationAlways ? 0 : 1)) {
    return null;
  }

  return (
    <MuiPagination
      className={classes.pagination}
      count={count}
      page={page}
      onChange={handlePageChange}
      boundaryCount={2}
      siblingCount={0}
    />
  );
};

export default Pagination;
