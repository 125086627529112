import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
    marginBottom: 20
  },
  servicesContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-evenly"
  },
  serviceItem: {
    margin: 10
  },
  divider: {
    marginTop: 30,
    marginBottom: 24,
    width: 772,
    height: 0,
    border: "1px solid #F6F6F6"
  }
  })
);

export default useStyles;