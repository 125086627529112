import React, { useEffect, useState } from 'react';

import { Redirect } from "react-router-dom";

import { useAppSelector } from "../../../redux/hooks";

import InterweaveComponent from "../../common/InterweaveComponent";
import { Url } from "../../../constants/url";
import { CustomerService } from "../../../api/customer-service";


export default function PrivacyNoticePage() {
  
  const [privacyNoticeEnabled, setPrivacyNoticeEnabled] = useState<boolean>(true);
  const [privacyNotice, setPrivacyNotice] = useState<string>("");

  const userInfo = useAppSelector((state) => state.userInfo);
  
  useEffect(() => {
    (async () => loadPrivacyNotice()
    )();
  }, [userInfo.accountId])
  
  async function loadPrivacyNotice() {
    if (userInfo && userInfo.accountId) {
      const response = await CustomerService.getPrivacyNotice();
      setPrivacyNotice(response.privacyNotice);
      setPrivacyNoticeEnabled(response.privacyNoticeEnabled);
    }
  }
  
  return (
    <>
      {
        privacyNoticeEnabled
        ? 
          <InterweaveComponent
            text={privacyNotice}
          /> 
        :
          <Redirect to={Url.Appointments.Main} />
      }
    </>
  );
}