import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  header: {
    marginLeft: 26,
  },
  stepTitle: {
    ...theme.typography.header2,
    color: theme.colors.clearMain,
    fontWeight: "bold",
  },
  servicesHeader: {
    display: "flex",
    marginTop: 24,
  },
  servicesTitle: {
    ...theme.typography.txtBody1,
    fontWeight: "bold",
    color: theme.colors.grey5,
  },
  flagIcon: {
    color: theme.colors.grey5,
  },
  addButton: {
    ...theme.typography.buttons,
    fontWeight: "bold",
    color: theme.colors.clearMain,
    width: 137,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    border: `1px solid ${theme.colors.clearMain}`,
    justifyContent: "space-around",
    marginTop: 23,
    marginLeft: 26,
    "&.Mui-disabled": {
      color: theme.colors.clearMain,
    },
  },
  separatorWithoutLine: {
    marginBottom: 30,
  },
  separator: {
    height: 0,
    borderTop: `1px solid ${theme.colors.grey2}`,
    margin: "24px 0px 24px 26px",
  },
  inputTextareaClass: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
    fontWeight: "bold",
    "&::placeholder": {
      ...theme.typography.txtBody2,
      fontWeight: "normal",
      opacity: 0.8,
    },
  },
  label: {
    fontFamily: "Inter",
    display: "inline-flex",
    margin: 0,
    color: theme.colors.grey4,
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: "bold",
    fontStyle: "normal",
    marginBottom: 5,
  },
  notes: {
    marginLeft: 26,
  },
  disabled: {
    opacity: 0.5,
  },
}));

export default useStyles;
