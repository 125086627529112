import React, { useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";

import { useAppSelector } from "../../../redux/hooks";

import Modal from "@material-ui/core/Modal";

import MaskedInput from "react-text-mask";

import useStyles from "./css";

import CountryFlagIcon from "../../../assets/flags";
import { SearchIcon, SortDownIcon } from "../../../assets/icons";
import { PatientFormMobileInputProps } from "./props";
import useControlLabel from "../../../hooks/useControlLabel";
import Country from '../../../models/country';
import { FormPhoneNumberValue } from '../../../models/form-phone-number-value';
import { GetMobileMaskByTemp } from '../../../constants/mask';
import Input from "../Input";


export default function PatientFormMobileInput(props: PatientFormMobileInputProps) {
  
  const defaultDialTemplate: string = "000-000-0000";
  const defaultCountry = new Country({ iso3: "MEX", dialCode: 52, dialDigits: 10, dialTemplate: defaultDialTemplate, name: "México" });
  
  const { t } = useTranslation(["general"]);
  
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  
  const {
    mobileNumber,
    setMobileNumber,
    countryInSettings,
    countries,
    required,
    label,
    isValid,
    onBlur 
  } = props;
  
  const classes = useStyles()();
  
  const [openModal, setOpenModal] = useState<boolean>(false);
  
  const [countrySelected, setCountrySelected] = useState<Country>(defaultCountry);
  const [masking, setMasking] = useState<any>(GetMobileMaskByTemp(defaultDialTemplate));
  const [isValidNumber, setIsValidNumber] = useState<boolean>(true);
  const [searchValue, setSearchValue] = useState<string | null>(null);
    
  function setMobile(mobile: string) {
    setMobileNumber(new FormPhoneNumberValue({ countryIso: mobileNumber.countryIso, body: mobile }))
  }
  
  function handleCountrySelected(country: Country) {
    setSearchValue(null);
    setCountrySelected(country);
    setOpenModal(false);
    
    let newNumber: string;
    if (mobileNumber.body) {
      newNumber = mobileNumber.body.substring(0, country.dialDigits || 10);
    } else {
      newNumber = mobileNumber.body;
    }
    
    const m = GetMobileMaskByTemp(country.dialTemplate || defaultDialTemplate);
    setMasking(m);
    
    setMobileNumber(new FormPhoneNumberValue({ countryIso: country.iso3, body: newNumber }))
  
    const valid = validate(newNumber, country.dialDigits); //TODO check and probably remove
  }
  
  const handleMobileChange = (event: React.ChangeEvent<{ value: string }>) => {
    const mobile = event.target.value.replace(/[^0-9]/g, "");
    
    setMobile(mobile);
    
    const valid = validate(mobile, countrySelected?.dialDigits); //TODO check and probably remove
  };
  
  const validate = (mobileComplete: string, digits: number): boolean => {
    let validDig = false;

    if (mobileComplete.length === 0 && !required) {
      setIsValidNumber(true);
      return true;
    }

    if (mobileComplete) {
      const mobile = mobileComplete.replace(/[^0-9]/g, "");
      validDig = (digits === 7 && /^\d{7}$/.test(mobile!)) ||
        (digits === 8 && /^\d{8}$/.test(mobile!)) ||
        (digits === 9 && /^\d{9}$/.test(mobile!)) ||
        (digits === 10 && /^\d{10}$/.test(mobile!)) ||
        (digits === 11 && /^\d{11}$/.test(mobile!)) ||
        (digits === 12 && /^\d{12}$/.test(mobile!));
      
      setIsValidNumber(validDig);
      if (isValid) isValid(validDig);
    }
    
    return validDig;
  }
  
  useEffect(() => {
    
    (async () => {
      
      let iso = mobileNumber.countryIso || (countryInSettings ? countryInSettings.iso3 : null) || "MEX";
      
      const countryByIso = countries.filter(x => x.iso3 === iso);
      
      if (countryByIso.length > 0) {
        const country = countryByIso[0];
        setCountrySelected(country);
        const m = GetMobileMaskByTemp(country.dialTemplate || defaultDialTemplate);
        setMasking(m);
      }
    })();
  }, [countries, mobileNumber.countryIso]);
  
  function handleSearch(searchValue: string) {
    setSearchValue(searchValue);
  }
  
  function filterCountries (item: Country) {
    const lowercaseSearchValue = searchValue!.toLowerCase();
    let isSearchByDialCode = /^\+?\d+$/.test(searchValue!);
    
    if (searchValue!.trim().length < 2 && !isSearchByDialCode) {
      return true;
    }
    
    return (
      item.name.toLowerCase().includes(lowercaseSearchValue) ||
      item.dialCode.toString().startsWith(lowercaseSearchValue.replace("+", ""))
    );
  }
  
  useEffect(() => {
    
    if (mobileNumber.body) {
      const m = GetMobileMaskByTemp(countrySelected.dialTemplate);
      setMasking(m);
    }
    
  }, [countrySelected]);
  
  const handleOpenModal = () => {
    setOpenModal(true);
  }
  
  const handleCloseModal = () => {
    setSearchValue(null);
    setOpenModal(false);
  }
  
  const inputLabel = label || t("Mobile") + " ";
  const ControlLabel = useControlLabel({ label: inputLabel, required })
  
  function countryAsListItem(country: Country) {
    return (
      <div key={country.iso3} className={classes.listCountry}>
        <div className={`${classes.elementList} ${classes.classRow}`} onClick={() => handleCountrySelected(country)}>
          <span className={classes.countryIcon}>
            <CountryFlagIcon iso={country.iso3} />
          </span>
          <div className={classes.countryName}>
            {country.name} (+{country.dialCode})
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.divDropdown}>
        
        <ControlLabel />
        
        <div className={`${classes.inputGroup} ${!isValidNumber ? classes.inputError : ""}`}>
          <MaskedInput
            className={`${classes.inputClassic} ${classes.inputSelectBtn} `}
            placeholder={countrySelected?.dialTemplate || defaultDialTemplate}
            mask={masking}
            placeholderChar=" "
            onChange={handleMobileChange}
            onBlur={() => {if (onBlur) onBlur(validate(mobileNumber.body, countrySelected?.dialDigits))}}
            value={mobileNumber.body}
          />
          
          <div className={classes.divCodes} onClick={handleOpenModal}>
            <div className={classes.inputFlag}>
              <CountryFlagIcon iso={countrySelected?.iso3 || "MEX"}/>
            </div>
            <div className={classes.iconDropdown}>
              <SortDownIcon/>
            </div>
            <div className={classes.divDialCode}>
              {countrySelected.dialCode !== undefined ? `+${countrySelected.dialCode}` : "+52"}
            </div>
          </div>
        
        </div>
      </div>
      
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        BackdropProps={{ style: { backgroundColor: "transparent" } }}
        disablePortal={true}
        className={classes.modal}
        style={{ position: "relative" }}
      >
        <div className={classes.divModalSelect}>
          <div className={classes.titleCountries} >{t("Countries")}</div>
          <div className={classes.searchInputContainer}>
            <Input
              placeholder={t("Search country code")}
              width={"-webkit-fill-available"}
              onChange={(event) => handleSearch(event.target.value)}
            />
            <div className={classes.searchIconContainer}><SearchIcon style={{ fontSize: 17, color: "#919CA5" }}/></div>
          </div>
          <div className={classes.listCountryScroll}>
            {(searchValue === null
              ? countries
              : countries.filter((item) => filterCountries(item))).map(countryAsListItem)}
          </div>
        </div>
      </Modal>
    </div>
  );
}