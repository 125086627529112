export enum LocalStorageKey {
    AccessToken = "access_token",
    SchedulerSelectedUsers = "scheduler_selected_users-",
    SchedulerSelectedUsersEnterprise = "scheduler_selected_users_enterprise-",
    SchedulerViewName = "scheduler_view-name-",
    SchedulerViewNameEnterprise = "scheduler_view-name_enterprise-",
    Establishments = "establishments-",
    SchedulerSelectedStatuses = "scheduler_selected_statuses-",
    SchedulerSelectedStatusesEnterprise = "scheduler_selected_statuses_enterprise-",
    SettingsEstablishment = "settings_selected_establishment-",
    SchedulerGrouping = "scheduler_grouping-",
    SchedulerGroupingEnterprise = "scheduler_grouping_enterprise-",
}