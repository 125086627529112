import { useTranslation } from "react-i18next";


export class CustomersClassificationEntity {
    id: string | null;
    description: string | null;
    months: number | null;
    classificationType?: CustomerClassificationType | null;

    constructor(x?: any) {
        if (typeof x === "object" && x != null) {
            this.id = x.id;
            this.description = x.description;
            this.months = x.months;
            this.classificationType = null;
        } else {
            this.id = null;
            this.description = null;
            this.months = null;
            this.classificationType = null;
        }
    }
}


export enum CustomerClassificationType {
    Frequent = 0,
    Infrequent,
    Inactive
}


export const GetCustomerClassificationLocale = (type: CustomerClassificationType = CustomerClassificationType.Frequent): string[] => {

    const { t } = useTranslation(["general"]);
    let items: string[] = [];

    if (type === CustomerClassificationType.Inactive) {

        items.push(t('0 appointments in the last month'))

        for (let m: number = 2; m <= 12; m++) {
            items.push(t('0 appointments in the last N months', { numMonth: m }))
        }
    } else {
        items.push(t('1 appointment in the last month'))

        for (let m: number = 2; m <= 12; m++) {
            items.push(t('1 appointment in the last N months', { numMonth: m }))
        }
    }


    return items;
}


