import React from "react";

import Input from "@material-ui/core/Input";
import MuiSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import ArrowDropDownIcon from "./../../../assets/icons/ArrowDropDownIcon";

import useStyles from "./css";
import { SelectKeyProps } from "./props";
import { SelectItem } from "../../../models/interfaces/select-item"

const SelectKey = (props: SelectKeyProps) => {
  const {
    value,
    setValue,
    placeholder,
    name,
    items,
    width,
    itemHeight,
    onChange,
    onBlur,
    disabled,
    styleClass,
    itemRootClass,
    classes,
    isValid,
    arrowIcon,
    colorArrowIcon,
    menuProps
  } = props;
  const useClasses = useStyles(width, itemHeight)();

  const isError = (): boolean => {
    if (!isValid) {
      return false;
    }

    if (isValid() === undefined) {
      return false;
    }

    return !isValid();
  };

  const handleOnChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setValue(event.target.value);
    if (onChange) {
      onChange();
    }
  };
  
  function handleOnBlur(event: React.ChangeEvent<{ value: unknown }>) {
    if (onBlur) {
      onBlur();
    }
  }

  const ArrowIcon = (props: any) => (
    arrowIcon ? {...arrowIcon, props} :
    <ArrowDropDownIcon
      {...props}
      style={{ top: "inherit", right: 12, color: colorArrowIcon ?? "#6462F3" }}
    />
  );

  return (
    <MuiSelect
      disableUnderline
      classes={
        classes || {
          root: `${useClasses.focused} ${useClasses.selected} ${isError() ? useClasses.inputError : ""}`,
        }
      }
      name={name}
      className={styleClass ?? useClasses.select}
      input={<Input />}
      MenuProps={menuProps ??
        {
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        classes: { paper: useClasses.menuPaper },
      }}
      displayEmpty
      disabled={disabled ?? false}
      onChange={handleOnChange}
      onBlur={handleOnBlur}
      placeholder={placeholder}
      value={value === undefined || value === null ? "" : value}
      IconComponent={ArrowIcon}
    >
      {placeholder ? (
        <MenuItem classes={{ root: `${itemRootClass ?? useClasses.itemRoot}`, }} value="" disabled>
          {placeholder}
        </MenuItem>
      ) : null}
      {items.map((item: SelectItem) =>
        (
          <MenuItem
            classes={{
              root: `${itemRootClass ?? useClasses.itemRoot}`,
              selected: useClasses.itemSelected,
            }}
            key={item.key}
            value={item.key}
            disabled={item?.disabled ?? false}
          >
            {item.value}
          </MenuItem>
        )
      )}
    </MuiSelect>
  );
};

export default SelectKey;
