import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";

import { Color } from "../../constants/colors";


export default function WhatsAppPlayVideoIcon(props: any) {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 100, height: 101, ...style }}
      viewBox={viewBox || "0 0 100 101"}
      fill="#FFFFFF"
    >
      <g opacity="0.75">
        <path
          opacity="0.6"
          d="M49.9974 20.9297C34.3887 20.9297 21.7422 33.5762 21.7422 49.1849C21.7422 64.7936 34.3887 77.4401 49.9974 77.4401C65.6061 77.4401 78.2526 64.7936 78.2526 49.1849C78.2526 33.5762 65.6061 20.9297 49.9974 20.9297ZM63.0996 51.9193L43.0475 63.4264C41.2246 64.4518 39.0599 63.1986 39.0599 61.0339V37.3359C39.0599 35.2852 41.2246 34.0319 43.0475 34.9434L63.0996 47.1341C65.0365 48.2734 65.0365 50.8939 63.0996 51.9193Z"
          fill={style?.color || Color.gray7}
        />
      </g>
    </SvgIcon>
  );
}