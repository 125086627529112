import React from "react";

import { useTranslation } from "react-i18next";

import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";

import useStyles from "./css";
import { CancelModalProps } from "./props";


export default function CancelModal(props: CancelModalProps) {

  const { open, setOpen, onSubmit, title } = props;

  const classes = useStyles();
  const { t } = useTranslation("settings");

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    onSubmit();
    handleCloseModal();
  }

  const GoBackButton = () =>
    <Button
      className={`${classes.button} ${classes.goBackButton}`}
      onClick={handleCloseModal}
    >
      {t("Go back")}
    </Button>

  const CancelButton = () =>
    <Button
      className={`${classes.button} ${classes.cancelButton}`}
      onClick={handleSubmit}
    >
      {t("Cancel")}
    </Button>

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={handleCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.paper}>
          <div className={classes.content}>
            <Typography className={classes.modalTitle}>
              {title}
            </Typography>
            <div className={classes.modalFooter}>
              <GoBackButton />
              <CancelButton />
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}