import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BlockIcon = (props: any) => {
  const { style, color, ...otherProps } = props;
  return (
    <SvgIcon style={style} {...otherProps}>
      <path
        d="M8 0C3.58065 0 0 3.6129 0 8C0 12.4194 3.58065 16 8 16C12.3871 16 16 12.4194 16 8C16 3.6129 12.3871 0 8 0ZM12.1935 3.80645C14.2903 5.93548 14.4516 9.16129 12.8387 11.4194L4.58065 3.16129C6.83871 1.54839 10.0645 1.70968 12.1935 3.80645ZM3.77419 12.2258C1.67742 10.0968 1.51613 6.87097 3.12903 4.6129L11.3871 12.871C9.12903 14.4839 5.90323 14.3226 3.77419 12.2258Z"
        fill={color || style?.color || "#919CA5"}
      />
    </SvgIcon>
  );
};

export default BlockIcon;
