import React from "react";
import { ItemComponentProps } from '../AppointmentsScheduler/Content';
import { AppointmentGridItem } from '../_common';
import AbsenceItem from '../AbsenceItem/AbsenceItem';
import AppointmentItem from './AppointmentItem';

function AppointmentGridItemComponent({ value }: ItemComponentProps<AppointmentGridItem>) {
    if (value.type === 'appointments') return <AppointmentItem value={value.group} />;

    return <AbsenceItem value={value.absenceGroup} />;
}

export default AppointmentGridItemComponent;
