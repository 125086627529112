export class UpdatePatientObservationsRequest {
    customerId: string;
    observations: string | null | undefined;

    constructor(x?: any) {
        if (typeof x === "object") {
            this.customerId = x.customerId;
            this.observations = x.observations;
        } else {
            this.customerId = "";
            this.observations = null;
        }
    }
}