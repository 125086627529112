import React from "react"
import ScheduledSendIcon from "../../../assets/icons/ScheduledSendIcon";
import {Tooltip} from "@material-ui/core";
import {useStyles} from "./css";

const ScheduledSendIconWithTooltip = (props: any) => {
    const { title, ...otherProps } = props;
    const classes = useStyles();
    const style = {
        display: "flex"
    }
    return (
        <Tooltip title={title} classes={{popper: classes.popper}}>
            <span style={style}>
                <ScheduledSendIcon {...otherProps} />
            </span>
        </Tooltip>
    )
};
export default ScheduledSendIconWithTooltip;