import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MenuIconPower = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M16.5 5.71875C16.125 5.4375 15.625 5.5625 15.4063 5.9375L14.9063 6.8125C14.7188 7.15625 14.8125 7.5625 15.0938 7.78125C16.4688 8.8125 17.2188 10.375 17.25 11.9688C17.25 14.9375 14.8438 17.25 12 17.25C9.06251 17.25 6.71876 14.9062 6.75001 12C6.75001 10.2812 7.56251 8.75 8.87501 7.78125C9.15626 7.5625 9.25001 7.15625 9.06251 6.8125L8.56251 5.9375C8.34376 5.5625 7.84376 5.4375 7.46876 5.71875C5.50001 7.125 4.21876 9.4375 4.25001 12.0312C4.25001 16.2812 7.75001 19.7812 12 19.75C16.2813 19.75 19.75 16.2812 19.75 12C19.75 9.40625 18.4688 7.125 16.5 5.71875ZM13.25 12.25V4.75C13.25 4.34375 12.9063 4 12.5 4H11.5C11.0625 4 10.75 4.34375 10.75 4.75V12.25C10.75 12.6875 11.0625 13 11.5 13H12.5C12.9063 13 13.25 12.6875 13.25 12.25Z" 
            />
        </SvgIcon>
    )
};

export default MenuIconPower;
