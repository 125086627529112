import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { DateTime } from 'luxon';
import React from 'react';
import { Appointment } from "../../../models/appointment";
import BlockSchedule from "../../../models/blocked-schedule";

export type AppointmentsGroup = {
    list: Appointment[];
    groupKey: string;
    key: string;
    isGrouped: boolean;
};

//namespace absenceGridItem {
    export type Slice = {
        originalAbsence: BlockSchedule;
        start: DateTime;
        end: DateTime;
        duration: number;
    };

    function getSlice(originalAbsence: BlockSchedule, startOfDay: DateTime): Slice | null {
        startOfDay = startOfDay.startOf('day');
        const endOfDay = startOfDay.plus({ day: 1 });
        const startsAt = originalAbsence.start;
        const endsAt = originalAbsence.end;

        if (startsAt >= endOfDay || endsAt <= startOfDay) return null;

        const blockStart = startsAt > startOfDay ? startsAt : startOfDay;
        const blockEnd = endsAt > endOfDay ? endOfDay : endsAt;

        return {
            originalAbsence,
            start: blockStart,
            end: blockEnd,
            duration: blockEnd.diff(blockStart).as('minutes'),
        };
    }

    export function getGroupKey(group: Group): string {
        return `g/${group.start.toMillis()}-${group.end.toMillis()}/${
            group.parentGroupKey
        }/${group.absences.map((x) => x.originalAbsence.id).join('-')}`;
    }

    /**
     * group of absence slices that overlap with each other
     */
    export type Group = {
        absences: Slice[];
        start: DateTime;
        end: DateTime;
        parentGroupKey: string;
    };

    function intersectsWithGroup(a: Slice, g: Group): boolean {
        return a.start < g.end && a.end > g.start;
    }

    function tryAddToGroup(a: Slice, g: Group): boolean {
        if (intersectsWithGroup(a, g)) {
            g.absences.push(a);
            g.start = g.start < a.start ? g.start : a.start;
            g.end = g.end > a.end ? g.end : a.end;
            return true;
        }

        return false;
    }

    function createEmptyGroup(slice: Slice, parentGroupKey: string): Group {
        return {
            absences: [slice],
            start: slice.start,
            end: slice.end,
            parentGroupKey,
        };
    }

    /**
     *
     * @param absences list of absences
     * @param days list of days in the format yyyy-MM-dd
     * @returns mapping of day -> list of appointment group
     */
    function groupIntersectingAbsencesByDays(
        absences: BlockSchedule[],
        days: string[]
    ): Record<string, Group[]> {
        const dayGroups: Record<string, Group[]> = {};
        const absencesSorted = sortBy(absences, (x) => x.start.toMillis());

        for (const dayStr of days) {
            const groups: Group[] = [];
            dayGroups[dayStr] = groups;
            const day = DateTime.fromFormat(dayStr, 'yyyy-MM-dd');

            for (const absence of absencesSorted) {
                const slice = getSlice(absence, day);
                if (slice === null) {
                    // slice is empty meaning this absence does not overlap with the current day
                    continue;
                }

                if (groups.length === 0) {
                    groups.push({
                        start: slice.start,
                        end: slice.end,
                        absences: [slice],
                        parentGroupKey: dayStr,
                    });
                } else {
                    const lastGroup = groups[groups.length - 1];
                    if (!tryAddToGroup(slice, lastGroup)) {
                        groups.push(createEmptyGroup(slice, dayStr));
                    }
                }
            }
        }

        return dayGroups;
    }

    function groupIntersectingAbsencesByUser(
        groupIds: string[],
        absences: BlockSchedule[],
        dayFilter: DateTime,
        isEnterprise: boolean
    ) {
        const groups: Record<string, Group[]> = {};
        for (const groupId of groupIds)
            groups[groupId] = [];

        for (const absence of absences) {
            const groupId = isEnterprise ? absence.establishmentId : absence.specialistId
            if (groupId && !groupIds.includes(groupId)) continue;
            const slice = getSlice(absence, dayFilter);
            if (slice === null) continue;

            const applicableGroupIds = groupId ? [groupId] : groupIds;

            for (const applicableGroupId of applicableGroupIds) {
                const list = groups[applicableGroupId];
                if (list.length === 0) {
                    list.push(createEmptyGroup(slice, applicableGroupId));
                } else {
                    const lastGroup = list[list.length - 1];
                    if (!tryAddToGroup(slice, lastGroup)) {
                        list.push(createEmptyGroup(slice, applicableGroupId));
                    }
                }
            }
        }

        return groups;
    }

    export function createAbsencesGridItems(
        absences: BlockSchedule[],
        days: string[]
    ): AppointmentGridItem[] {
        const dayGroups = groupIntersectingAbsencesByDays(absences, days);
        const items: AppointmentGridItem[] = [];

        for (const groupKey in dayGroups) {
            const groups = dayGroups[groupKey];

            for (const group of groups) {
                items.push({
                    groupKey,
                    absenceGroup: group,
                    duration: group.end.diff(group.start).as('minutes'),
                    startsAt: group.start.hour * 60 + group.start.minute,
                    key: getGroupKey(group),
                    type: 'absence',
                });
            }
        }

        return items;
    }

    export function createAbsencesGridItemsForDay(
        absences: BlockSchedule[],
        day: DateTime,
        groupIds: string[],
        isEnterprise: boolean
    ): AppointmentGridItem[] {
        const groupsMapping = groupIntersectingAbsencesByUser(groupIds, absences, day, isEnterprise);
        const items: AppointmentGridItem[] = [];

        for (const groupKey in groupsMapping) {
            const groups = groupsMapping[groupKey];
            for (const group of groups) {
                items.push({
                    groupKey,
                    absenceGroup: group,
                    duration: group.end.diff(group.start).as('minutes'),
                    startsAt: group.start.hour * 60 + group.start.minute,
                    key: getGroupKey(group),
                    type: 'absence',
                });
            }
        }

        return items;
    }
//}

export type AbsenceGroup = Group;

export type AppointmentGridItem = {
    key: string;
    groupKey: string;
    startsAt: number;
    duration: number;
} & (
    | {
          type: 'appointments';
          group: AppointmentsGroup;
      }
    | {
          type: 'absence';
          absenceGroup: AbsenceGroup;
      }
);

export function createAppointmentsGridItems(
    appointmentGroups: AppointmentsGroup[]
): AppointmentGridItem[] {
        return appointmentGroups.map((x) => {
        const startsAt = DateTime.min(...x.list.map(d => d.startDate));
        const endsAt = DateTime.max(...x.list.map(d => d.endDate));

        const item: AppointmentGridItem = {
            type: 'appointments',
            group: x,
            groupKey: x.groupKey,
            duration: endsAt.diff(startsAt).as('minutes'),
            startsAt: startsAt.hour * 60 + startsAt.minute,
            key: x.key,
        };

        return item;
    });
}

export function groupAppointments(appointments: Appointment[], getGroupKey: (appointment: Appointment) => string, isGrouped: boolean): AppointmentsGroup[] {
    if (!appointments.length)
        return [];
    
    const sortedAppointments = [...appointments].sort((a,b) => a.startDate.diff(b.startDate).as('minutes'));

    function convertAppointmentListToGroups(list: Appointment[]): Appointment[][] {
        const innerGroups = [[list[0]]];
        let j = 0;
        let end = list[0].endDate;

        for (let i = 1; i < list.length; i++) {
            if (list[i].startDate < end) {
                if (list[i].endDate > end) {
                    end = list[i].endDate;
                }
                innerGroups[j].push(list[i]);
            } else {
                innerGroups.push([list[i]]);
                j++;
                end = list[i].endDate;
            }
        }

        return innerGroups
    }

    const groups: Appointment[][] = Object.values(groupBy(sortedAppointments, getGroupKey)).flatMap(convertAppointmentListToGroups)
    
    function getKey(appointment: Appointment) {
        const groupBy = getGroupKey(appointment);
        const dateTime = +appointment.startDate.toJSDate() / 60000;
        return `${groupBy}_${dateTime}`;
    }

    return groups.map((list) => ({
        list,
        groupKey: getGroupKey(list[0]),
        key: getKey(list[0]),
        isGrouped: isGrouped
    }));
}