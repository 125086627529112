import React from "react";
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import { Box } from "@material-ui/core";
// import MessageSentIcon from "../../../assets/icons/MessageSentIcon";
// import MessageDeliveredIcon from "../../../assets/icons/MessageDeliveredIcon";
// import { MessageStatus } from "../../../models/enums/message-status";
import moment from "moment";
import "moment-timezone";
import { useStyles } from "./css";

import ScheduledSendIconWithTooltip from "../common/ScheduledSendIconWithTooltip"
import { useTranslation } from "react-i18next";
import { Markup } from "interweave";
import { ConversationMessageType } from "../../../models/enums/conversation-message-type";
import { CaptionDocumentMediaIcon, CaptionImageMediaIcon, VideoCameraFilledIcon } from "../../../assets/icons";


const ConversationsListItem = (props: any) => {

    const {
        customerName,
        lastMessageText,
        lastMessageFileName,
        lastMessageTime,
        lastMessageType,
        isDisabledActivity,
        isDisabledNotContactable,
        isSelected,
        wasSendMore1DayAgo,
        unreadMessagesCount,
        hasScheduledFollowUp,
        onSelect,
        establishmentName,
        ...otherProps
    } = props;

    const classes = useStyles();
    const { t } = useTranslation(["general"]);

    const getText = (text: string | null, messageType: ConversationMessageType): string => {
        if (text)
            return formatText(text)

        switch (messageType) {
            case ConversationMessageType.Image:
                return t("Photo");
            case ConversationMessageType.Video:
                return t("Video");
            case ConversationMessageType.Document:
            case ConversationMessageType.Pdf:
                return t("Document");
            default:
                return formatText(text ?? "");
        }
    }
    
    const formatText = (text: string) =>
        text.replaceAll("\n", " ").replaceAll("<br>", " ").replaceAll(new RegExp('<(?!span|/span).*?\>', 'g'), '');

    const getFormattedTime = (messageTime: Date, wasSendMore1DayAgo: boolean) => {
        const messageMoment = moment(messageTime);
        const datetimeString = wasSendMore1DayAgo ?
            messageMoment.format("DD/MM/yyyy") :
            messageMoment.format("HH:mm[hrs]")
        return datetimeString;
    }
    const getChatPreviewIndicator = (unreadMessagesCount: number) => {
        if (unreadMessagesCount > 0) {
            return (<Badge
                classes={{ badge: classes.messageCounter }}
                style={{ paddingRight: "10px" }}
                badgeContent={unreadMessagesCount}
                max={99}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}> </Badge>);
        }
        // if (!lastMessage.isInbound && lastMessage.messageStatus === MessageStatus.Sent){
        //     return (<MessageSentIcon className={classes.messageSentIcon}/>)
        // }
        // if (!lastMessage.isInbound && lastMessage.messageStatus === MessageStatus.Delivered){
        //     return (<MessageDeliveredIcon className={classes.messageDeliveredIcon}/>)
        // }
        // if (!lastMessage.isInbound && lastMessage.messageStatus === MessageStatus.Read){
        //     return (<MessageDeliveredIcon className={classes.messageReadIcon}/>)
        // }
    }

    const getMessageMediaContentIcon = (type: ConversationMessageType) => {
        switch (type) {
            case ConversationMessageType.Image:
                return <CaptionImageMediaIcon width={14} viewBox={"0 0 14 11"} style={{ marginRight: "6px", marginBottom: "2px" }} />;
            case ConversationMessageType.Video:
                return <VideoCameraFilledIcon style={{ marginRight: "6px", marginBottom: "2px" }} />;
            case ConversationMessageType.Document:
            case ConversationMessageType.Pdf:
                return <CaptionDocumentMediaIcon style={{ marginRight: "6px", marginBottom: "2px" }} />
            default:
                return <></>;
        }
    }

    const formatCustomerNameWithPlusSign = (customerName: string) => {
        if (!isNaN(+customerName)) {
            customerName = "+" + customerName;
        }
        return customerName;
    }
    return (
        <div
            className={
                `${classes.conversationBox} ${isSelected ? classes.conversationSelected : classes.conversationNotSelected} ${isDisabledActivity ? classes.conversationDisabled : classes.conversationEnabled}`
            }
            onClick={onSelect}>
            <Grid container>
                <Grid item xs={9}>
                    <Typography noWrap className={classes.customerContactName}>
                        {hasScheduledFollowUp ? <ScheduledSendIconWithTooltip title={t("Send scheduled message")} width="14px" height="13px" /> : <></>}
                        <Markup content={formatCustomerNameWithPlusSign(customerName)} />
                    </Typography>
                </Grid>
                <Grid item xs={3} container justifyContent="flex-end">
                    <Typography className={classes.dateTime}>{getFormattedTime(lastMessageTime, wasSendMore1DayAgo)}</Typography>
                </Grid>
                <Box width="100%" />
                {
                    establishmentName
                        ?
                        <Grid item xs={10} zeroMinWidth>
                            <Typography className={classes.establishmentName}>
                                {establishmentName}
                            </Typography>
                        </Grid>
                        : null
                }
                <Grid item xs={10} zeroMinWidth>
                    <Typography noWrap className={classes.messagePreview}>
                        {getMessageMediaContentIcon(lastMessageType)}
                        <Markup content={getText(lastMessageText, lastMessageType)} />
                    </Typography>
                </Grid>
                <Grid item xs={2} container justifyContent="flex-end" style={{ paddingBottom: 10 }}>
                    {getChatPreviewIndicator(unreadMessagesCount)}
                </Grid>
            </Grid>
        </div>
    );
};

export default ConversationsListItem;
