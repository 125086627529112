import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionFieldDropdownIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M9.60084 10C9.06338 10 8.79465 10.6209 9.18553 10.9791L12.0927 13.8209C12.3126 14.0597 12.679 14.0597 12.9233 13.8209L15.8305 10.9791C16.197 10.6209 15.9282 10 15.4152 10H9.60084Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}