import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { CreateAppointmentModel } from "../../../models/create-appointment-model";
import { CreateAppointmentService } from "../../../models/create-appointment-service";
import { FlagIcon, PlusIcon } from "../../../assets/icons";
import AppointmentServices from "./../AppointmentServices";
import TextareaWithLabel from "./../../common/TextareaWithLabel";

import { AppointmentStepOneProps } from "./props";
import useStyles from "./css";
import {useAppDispatch, useAppSelector} from "../../../redux/hooks";
import {blockedScheduleActions} from "../../../redux/blocked-schedule-slice";

export default function AppointmentStepOne(props: AppointmentStepOneProps) {
  
  const { appointment, onChange } = props;
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  const dispatch = useAppDispatch();
  const [desiredUserId] = useState(useAppSelector(state => state.blockedSchedule.userId));
  
  function setAppointment(appointment: CreateAppointmentModel) {
    const isValid = appointment.services.every(service => service.serviceId && service.userId && service.price > 0);
    onChange(appointment, isValid)
  }
  
  function setServices(services: CreateAppointmentService[]) {
    setAppointment(new CreateAppointmentModel({ ...appointment, services }))
  }

  useEffect(() => {
    if (desiredUserId) {
      dispatch(blockedScheduleActions.setUserId(null));
    }
  }, []);

  useEffect(() => {
    if (!appointment){
      setAppointment(new CreateAppointmentModel());
    }
    if (appointment.services.length === 0) {
      setServices([...appointment.services, new CreateAppointmentService()]);
    }
  }, [appointment])

  const handleAddService = (event: any) => {
    setServices([...appointment?.services ?? [], new CreateAppointmentService()]);
  };

  const handleDeleteService = (index: number) => {
    const arrServices = appointment!.services!.filter((service, idx) => idx !== index);
    setServices(arrServices);
  };

  const handleOnChangeService = (
    index: number,
    service: CreateAppointmentService
  ) => {
    let arrServices = [...appointment!.services!];
    arrServices[index] = service;
    setServices(arrServices)
  };

  return (
    <div className={!appointment?.customerId ? classes.disabled : ""}>
      <div className={classes.header}>
        <Typography className={classes.stepTitle}>{t("STEP 1")}</Typography>
        <div className={classes.servicesHeader}>
          <FlagIcon
            className={classes.flagIcon}
            viewBox="0 0 12 16"
            style={{ width: 12, height: 16, marginLeft: 7 }}
          />
          <div style={{ marginLeft: 14 }}>
            <Typography className={classes.servicesTitle}>
              {t("Services")}
            </Typography>
          </div>
        </div>
      </div>
      <AppointmentServices
        services={appointment!.services}
        desiredUserId={desiredUserId}
        onDelete={handleDeleteService}
        onChange={handleOnChangeService}
        disabled={!appointment?.customerId}
      />
      <Button
        className={`${classes.addButton} ${
          !appointment?.services || appointment?.services.length < 2 ? classes.separatorWithoutLine : ""
        }`}
        onClick={handleAddService}
        disabled={!appointment?.customerId}
      >
        <PlusIcon />
        {t("Add another")}
      </Button>
      {appointment?.services && appointment?.services.length > 1 ? (
        <div className={classes.separator} style={{ width: 480 }}></div>
      ) : null}
      <div className={classes.notes}>
        <TextareaWithLabel
          name="notes"
          width="100%"
          inputClass={classes.inputTextareaClass}
          label={() => (
            <>
              <Typography className={classes.label}>{t("Comments")}</Typography>
            </>
          )}
          labelClass={classes.label}
          placeholder={t("Enter here any appointment comments.")}
          maxLength={180}
          inputDisabled={!appointment?.customerId}
          showCounter={true}
          onChange={(event) => {
            setAppointment(
              new CreateAppointmentModel({
                ...appointment,
                notes: event.target.value,
              })
            );
          }}
          value={appointment?.notes || ""}
        />
      </div>
      <div className={classes.separator} style={{ width: 704 }}></div>
    </div>
  );
};