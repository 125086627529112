import React from "react";
import { createSlice } from '@reduxjs/toolkit';

interface InitialNavbarState{
    externalContent: React.Component | null,
    availableConversationsCount: number | null,
    showLoader: boolean | null
}

const initialNavbarState: InitialNavbarState = {
    externalContent: null,
    availableConversationsCount: null,
    showLoader: null
};

const navbarSlice = createSlice({
    name: 'navbar',
    initialState: initialNavbarState,
    reducers: {
        setExternalContent(currentState, action) {
            currentState.externalContent = action.payload;
        },
        setAvailableConversationsCount(currentState, action) {
            currentState.availableConversationsCount = action.payload;
        },
        setShowLoader(currentState, action) {
            currentState.showLoader = action.payload;
        }
    }
});

export const navbarActions = navbarSlice.actions;
export default navbarSlice.reducer;