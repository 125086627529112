import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  accordion: {
    boxShadow: "none",
    borderWidth: 1,
    borderStyle: "solid",
    borderColor: theme.colors.grey2,
    borderRadius: 4
  },
  accordionSummary: {
    backgroundColor: "#f6f6f6",
    width: "100%",
    minHeight: "46px !important",
    "& >div:first-child": {
      margin: 0
    }
  },
  accordionTitle: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    color: theme.colors.grey5,
    position: "relative",
    top: 5,
    left: 10
  },
  accordionDetails: {
    display: "block",
    padding: "10px 15px 15px"
  }
}));

export default useStyles;
