import { ApiClient } from "./api-client";
import Category from "../models/category";
import { Response } from "./../models/response";


export default class CategoryService {

    private static baseUrl: string = "/category";

    static getCategory(establishmentAccountId?: string): Promise<Response> {

        let urlEndpoint = `${this.baseUrl}/getcategories`;

        if (establishmentAccountId)
            urlEndpoint += `?AccountId=${establishmentAccountId}`;

        return ApiClient.get(urlEndpoint)
            .then((data) => {
                return new Response(data.category, data.total);
            });
    }

    static createCategory(category: Category): Promise<Category> {
        return ApiClient.post(`${this.baseUrl}/createcategory`, category)
            .then(data => {
              return new Category(data.category);
            });
    }

    static updateCategory(category: Category): Promise<Category> {
        return ApiClient.put(`${this.baseUrl}/updatecategory`, category)
            .then(data => {
              return new Category(data.category);
            });
    }

    static deleteCategory(w: Category): Promise<any> {
        let endpoint = `${this.baseUrl}/deletecategory?id=${w.id}`;

        if (w.accountId)
            endpoint += `&AccountId=${w.accountId}`;

        return ApiClient.remove(endpoint)
            .then(data => {
                return {
                    entity: new Category(data.category),
                    forbiddenDelete: data.forbiddenDelete
                };
            });
    }

}
