export default class Country {
    iso3: string;
    name: string;
    dialCode: number;
    dialDigits: number;
    dialTemplate: string;

    constructor(x?: Country) {
        if (typeof x === "object" && x != null) {
            this.iso3 = x.iso3 || "";;
            this.name = x.name || "";
            this.dialCode = x.dialCode || 0;
            this.dialDigits = x.dialDigits || 0;
            this.dialTemplate = x.dialTemplate || "";
        }
        else {
            this.iso3 = "";
            this.name = "";
            this.dialCode = 0;
            this.dialDigits = 0;
            this.dialTemplate = "";
        }
    }
}

