import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useAppDispatch } from "../../../../../redux/hooks";

import { CommissionModalProps } from "./props";
import useStyles from "./css";
import { Backdrop, Button, Fade, Modal, Typography } from "@material-ui/core";
import { UserCommissionType } from "../../../../../models/enums/user-commission-type";
import CommissionView from "../CommissionView";
import { CommissionService } from "../../../../../api/commission-service";
import { TerminologyType } from "../../../../../models/enums/terminology-type";
import { alertsActions } from "../../../../../redux/alerts-slice";
import { UserCommissionInfo } from "../../../../../models/user-commission-info";
import useTerminology from "../../../../../hooks/useTerminology";


export default function CommissionModal(props: CommissionModalProps) {

  const { settingsSelectedEstablishmentId } = props;

  const { t } = useTranslation("settings");
  const dispatch = useAppDispatch();
  
  const { isOpen, setOpen, userCommission, setUserCommission, userId, userName, openedCommissionType } = props;
  const classes = useStyles();
  const { enqueueAlert } = alertsActions;
  
  const [isUserCommissionTypeChangeConfirmed, setIsUserCommissionTypeChangeConfirmed] = useState<boolean>(false);
  const [initialUserCommission, setInitialUserCommission] = useState<string>("");
  
  useEffect(() => {
    if (isOpen) {
      setInitialUserCommission(JSON.stringify(userCommission));
      if (userCommission.initialUserCommissionType !== openedCommissionType) {
        if (openedCommissionType === UserCommissionType.Default) {
          setUserCommission({
            ...userCommission,
            commissionType: null,
            commissionValue: null,
            userCommissionType: openedCommissionType
          });
        } else if (openedCommissionType === UserCommissionType.ByService) {
          userCommission.commissionsByServices.forEach(service => {
            service.commissionValue = userCommission.commissionValue!;
            service.commissionType = userCommission.commissionType!;
          });
          setUserCommission({
            ...userCommission,
            commissionsByServices: userCommission.commissionsByServices,
            userCommissionType: openedCommissionType
          });
        }
      } else {
        setUserCommission({ ...userCommission, userCommissionType: openedCommissionType });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsSelectedEstablishmentId, isOpen]);
  
  const handleOnClose = () => {
    setOpen(false);
    setIsUserCommissionTypeChangeConfirmed(false);
  };
  
  const handleSaveButtonClick = async () => {
    await CommissionService.updateUserCommission(userId, userCommission, settingsSelectedEstablishmentId);
    setOpen(false);
    setIsUserCommissionTypeChangeConfirmed(false);
    dispatch(
      enqueueAlert({
        type: "Success",
        title: t("Information updated successfully")
      })
    );
  };
  
  const validateCommissions = () => {
    return userCommission.userCommissionType === UserCommissionType.Default
      ? !!userCommission.commissionValue && userCommission.commissionValue > 0 && userCommission.commissionType !== null
      : true;
  }
  
  const canSave = () => {
    const userCommissionJson = JSON.stringify(userCommission);
    if (!!initialUserCommission && initialUserCommission !== userCommissionJson){
      const initUserCommission: UserCommissionInfo = JSON.parse(initialUserCommission);
      // extra comparison logic for Daimara
      if (initUserCommission.userCommissionType != userCommission.userCommissionType){
        if (userCommission.userCommissionType === UserCommissionType.ByService) {
          initUserCommission.commissionsByServices.forEach(service => {
            service.commissionValue = userCommission.commissionValue!;
            service.commissionType = userCommission.commissionType!;
          });
        }
        initUserCommission.userCommissionType = userCommission.userCommissionType;
        return isUserCommissionTypeChangeConfirmed && JSON.stringify(initUserCommission) !== userCommissionJson && validateCommissions()
      }
      return JSON.stringify(initUserCommission) !== userCommissionJson && validateCommissions()
    }
    return false;
  }
  
  const editCommissionByDefaultFor = useTerminology({ type: TerminologyType.Commission, template: t("Edit commission by default for"), templateTermToLowercase: true });
  const editCommissionByServiceFor = useTerminology({ type: TerminologyType.Commission, template: t("Edit commission by service for"), templateTermToLowercase: true });
  
  return <>
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={isOpen}
      onClose={handleOnClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}>
      <Fade in={isOpen}>
        <div className={classes.paper}>
          <div className={classes.modalHeader}>
            <div className={classes.modalTitle}>
              <Typography className={classes.modalTitleText}>
                {openedCommissionType === UserCommissionType.Default
                  ? editCommissionByDefaultFor
                  : editCommissionByServiceFor
                }&nbsp;
                {userName}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={handleOnClose}
              >
                {t('Cancel')}
              </Button>
              <Button
                className={`${classes.button} ${classes.continueButton}`}
                onClick={handleSaveButtonClick}
                disabled={!canSave()}
              >
                {t("Save")}
              </Button>
            </div>
          </div>
          <div className={classes.content}>
            <CommissionView
              userCommissionsInfo={userCommission}
              setUserCommissionsInfo={setUserCommission}
              isUserCommissionTypeChangeConfirmed={isUserCommissionTypeChangeConfirmed}
              setIsUserCommissionTypeChangeConfirmed={setIsUserCommissionTypeChangeConfirmed}/>
          </div>
        </div>
      </Fade>
    </Modal>
  </>
};