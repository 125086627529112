import React from "react";

import { Check } from "@material-ui/icons";
import { Typography } from "@material-ui/core";

import useStyles from "./css";
import { SuccessBlockProps } from "./props";


export default function SuccessBlock(props: SuccessBlockProps) {

  const { headerMessage, textMessage, blockHeight } = props;
  const classes = useStyles(blockHeight)();
  
  return (
    <div className={classes.root}>
      <div className={classes.icon}>
        <Check />
      </div>
      <Typography className={classes.header}>{headerMessage}</Typography>
      <Typography className={classes.text}>{textMessage}</Typography>
    </div>
  );
}