import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionSaveIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M18.5312 8.0625L15.9375 5.46875C15.6875 5.21875 15.2188 5 14.875 5H6.5C5.65625 5 5 5.6875 5 6.5V17.5C5 18.3438 5.65625 19 6.5 19H17.5C18.3125 19 19 18.3438 19 17.5V9.125C19 8.78125 18.7812 8.3125 18.5312 8.0625ZM12 17C10.875 17 10 16.125 10 15C10 13.9062 10.875 13 12 13C13.0938 13 14 13.9062 14 15C14 16.125 13.0938 17 12 17ZM15 7.5V10.625C15 10.8438 14.8125 11 14.625 11H7.375C7.15625 11 7 10.8438 7 10.625V7.375C7 7.1875 7.15625 7 7.375 7H14.5C14.5938 7 14.6875 7.0625 14.7812 7.125L14.875 7.21875C14.9375 7.28125 15 7.40625 15 7.5Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}