import React from "react";

import Typography from "@material-ui/core/Typography";

import useStyles from "./css";
import { UserServiceCategoryProps } from "./props";
import { SwitchItemModel } from "../../../../../models/interfaces/switch-item-model";
import SwitchWithLabel from "../../../../common/SwitchWithLabel";


export default function UserServiceCategory(props: UserServiceCategoryProps) {
  
  const { category, setCategory } = props;
  
  const classes = useStyles();
  
  const renderServiceItems = (item: SwitchItemModel, index: number) => {
    
    const setItemValue = (value: boolean) => {
      const newServices = category.services;
      newServices[index].value = value;
      setCategory({...category, services: newServices });
    }
    
    return (
      <div className={classes.serviceItem} key={item.id}>
        <SwitchWithLabel 
          value={item.value} 
          setValue={setItemValue} 
          label={item.name} 
        />
      </div>
    );
  }
  
  return (
    <div key={category.id}>
      <Typography className={classes.header}>{category.name}</Typography>
      <div className={classes.servicesContainer}>
        {category.services.map(renderServiceItems)}
      </div>
      <div className={classes.divider} />
    </div>
  );
}