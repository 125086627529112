import React from 'react';

import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { Modal, Typography, Button  } from '@material-ui/core';
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";

import { CrossIcon, ExclamationIcon } from "../../../assets/icons";
import { AppointmentDragAndDropScheduleBusyModalProps } from "./props";
import useStyles from "./css";
import { Url } from "../../../constants/url";


export default function AppointmentDragAndDropScheduleBusyModal(props: AppointmentDragAndDropScheduleBusyModalProps) {
  
  const { state, onContinue, onClose } = props;
  const { open, appointmentId, hasSimultaneousAppointments } = state;
  
  const { t } = useTranslation(["general"]);
  const classes = useStyles();
  
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <div className={classes.modalHeader}>
            <div className={classes.modalHeaderContent}>
              <div className={classes.modalCircle}>
                <ExclamationIcon viewBox="0 0 5 16" />
              </div>
            </div>
            <div className={classes.modalClose}>
              <span className={classes.closeButton} onClick={onClose}>
                <CrossIcon />
              </span>
            </div>
          </div>
          <div className={classes.modalContent}>
            <div
              className={classes.modalTitleSpace}
            >
              <Typography className={classes.modalTitleText}>
                {t("Are you sure to move the appointment?")}
              </Typography>
            </div>
            <div className={classes.separator} />
            <div className={classes.modalMain}>
              <Typography className={classes.modalContentText}>
                {hasSimultaneousAppointments 
                  ? t("It is possible that the new schedule is outside business hours.")
                  : t("Existing appointments may be scheduled at this time or the new schedule is outside business hours.")
                }
              </Typography>
              <Typography className={classes.modalContentText}>
                <span>{t("We suggest you go to the") + " \""}</span>
                <Link className={classes.editAppointmentLink} to={`${Url.Appointments.Main}/${appointmentId}`}>{`${t("Edit appointment")}`}</Link>
                <span>{"\" " + t("screen to review available dates and times.")}</span>
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={onClose}
              >
                {t("Go back")}
              </Button>
              <Button
                className={`${classes.button} ${classes.editAppointment}`}
                onClick={() => {
                  if (onContinue) {
                    onContinue();
                  }
                }}
              >
                {t("Continue")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}