import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";

const MenuIcon = (props: any) => {
  return (
    <SvgIcon {...props} viewBox={props.viewBox ?? "0 0 12 12"} style={{ width: 12, height: 12, ...props.style }}>
      <path 
        fillRule="evenodd" 
        clipRule="evenodd" 
        d="M2 3.5C2 3.22386 2.22386 3 2.5 3H9.5C9.77614 3 10 3.22386 10 3.5C10 3.77614 9.77614 4 9.5 4H2.5C2.22386 4 2 3.77614 2 3.5ZM2 6.25C2 5.97386 2.22386 5.75 2.5 5.75H9.5C9.77614 5.75 10 5.97386 10 6.25C10 6.52614 9.77614 6.75 9.5 6.75H2.5C2.22386 6.75 2 6.52614 2 6.25ZM2 9C2 8.72386 2.22386 8.5 2.5 8.5H9.5C9.77614 8.5 10 8.72386 10 9C10 9.27614 9.77614 9.5 9.5 9.5H2.5C2.22386 9.5 2 9.27614 2 9Z" 
        fill={props.color ?? "#6462F3"}
      />
    </SvgIcon>      
  )
};

export default MenuIcon;