export enum Color {
    clear1 = "#6462F3",
    clear2 = "#7175FA",
    clear3 = "#9FA9E6",
    clear4 = "#BDC7F8",
    clear5 = "#E1E9FE",
    clear6 = "#E9F1FE",

    gray1 = "#F6F6F6",
    gray2 = "#C9CDD3",
    gray3 = "#ACB7C0",
    gray4 = "#919CA5",
    gray5 = "#5C6477",
    gray6 = "#323947",
    gray7 = "#1D2027",

    white = "#FFFFFF",
    green = "#36CE91",
    red = "#F15857",
    yellow = "#FFC626",
    blue = "#0BCBE0",
    clilac = "#EAE9FF",
}

