import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    verticalAlign: "middle",
    display: "inline-flex",
    margin: "0px 0px 24px 0px",
  },
  icon: {
    marginRight: 6,
  },
  line: {
    width: 1,
    height: 41,
    borderLeft: "1px solid #C9CDD3",
    margin: "7px 24px 0px 0px",
  },
}));
