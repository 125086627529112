import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const BillsIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      width="24" height="24" viewBox="0 0 24 24" fill="none" 
    >
      <path d="M13.5 13.5C12.6667 13.5 11.9583 13.2083 11.375 12.625C10.7917 12.0417 10.5 11.3333 10.5 10.5C10.5 9.66667 10.7917 8.95833 11.375 8.375C11.9583 7.79167 12.6667 7.5 13.5 7.5C14.3333 7.5 15.0417 7.79167 15.625 8.375C16.2083 8.95833 16.5 9.66667 16.5 10.5C16.5 11.3333 16.2083 12.0417 15.625 12.625C15.0417 13.2083 14.3333 13.5 13.5 13.5ZM5.5 17C5.0875 17 4.73438 16.8531 4.44062 16.5594C4.14688 16.2656 4 15.9125 4 15.5V5.5C4 5.0875 4.14688 4.73438 4.44062 4.44063C4.73438 4.14688 5.0875 4 5.5 4H21.5C21.9125 4 22.2656 4.14688 22.5594 4.44063C22.8531 4.73438 23 5.0875 23 5.5V15.5C23 15.9125 22.8531 16.2656 22.5594 16.5594C22.2656 16.8531 21.9125 17 21.5 17H5.5ZM8 15.5H19C19 14.8 19.2417 14.2083 19.725 13.725C20.2083 13.2417 20.8 13 21.5 13V8C20.8 8 20.2083 7.75833 19.725 7.275C19.2417 6.79167 19 6.2 19 5.5H8C8 6.2 7.75833 6.79167 7.275 7.275C6.79167 7.75833 6.2 8 5.5 8V13C6.2 13 6.79167 13.2417 7.275 13.725C7.75833 14.2083 8 14.8 8 15.5ZM20 20H2.5C2.0875 20 1.73438 19.8531 1.44063 19.5594C1.14688 19.2656 1 18.9125 1 18.5V7H2.5V18.5H20V20Z" fill="#5C6477"/>
    </SvgIcon>
);
};

export default BillsIcon;
