import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionFieldTextIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M18.5 17H17.6563L13.5938 5.34375C13.5312 5.15625 13.3438 5 13.125 5H10.8438C10.625 5 10.4375 5.15625 10.375 5.34375L6.3125 17H5.5C5.21875 17 5 17.25 5 17.5V18.5C5 18.7813 5.21875 19 5.5 19H9.75C10 19 10.25 18.7813 10.25 18.5V17.5C10.25 17.25 10 17 9.75 17H9L9.8125 14.5H14.125L14.9688 17H14.25C13.9688 17 13.75 17.25 13.75 17.5V18.5C13.75 18.7813 13.9688 19 14.25 19H18.5C18.75 19 19 18.7813 19 18.5V17.5C19 17.25 18.75 17 18.5 17ZM10.4375 12.4063L11.75 8.53125C11.875 8.03125 11.9688 7.625 12 7.4375C12 7.65625 12.0625 8.0625 12.2188 8.53125L13.5312 12.4063H10.4375Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}