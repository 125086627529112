import * as React from "react";
import { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useLocation } from 'react-router-dom';

import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { deauthenticate } from "../../../redux/authentication-slice";
import { navbarActions } from '../../../redux/navbar-slice'
import { selectAccountSettings, selectCommonHubConnection, selectUserInfo } from "../../../redux/store";
import { resetUserInfo } from "../../../redux/user-info-slice";

import Typography from "@material-ui/core/Typography";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Tooltip from "@material-ui/core/Tooltip";

import { MenuIconSettings, MenuIconReports, MenuIconCustomer, MenuIconLogoClear, MenuIconPower, MenuIconWhatsapp, MegaphoneIcon, ConversationIcon, PlusIcon } from "../../../assets/icons";
import { Url } from "../../../constants/url";
import { useStyles } from "./css";
import { AuthenticationService } from "../../../api/authentication-service";
import NotificationBlock from "../notifications/NotificationBlock";
import AccountOverdueMessage from "../../common/AccountOverdueMessage";
import CashRegisterMain from "../cash-register/CashRegisterMain";
import { useSelector } from "react-redux";
import { connect } from "../../../redux/common-hub-slice";
import ExternalLink from "../../../assets/icons/ExternalLink";
import Title from "../Title";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";


export interface NavBarMenuItem {
  id: number;
  icon: JSX.Element;
  label: string;
  url: string;
  isDisplayed: boolean;
  isParent?: boolean;
  parentId?: number;
}


export default function NavBar() {
  
  const { t, i18n } = useTranslation(["general", "nav", "sections"]);
  const location = useLocation();
  
  const [isLocationInitialized, setIsLocationInitialized] = useState<boolean>(false);
  const [isExternalLingHover, setIsExternalLinkHover] = useState<boolean>(false);
  const [hoverItemId, seHoverItemId] = useState<number>(-1);
  
  const titleNav = useAppSelector(state => state.applicationInterface.title);
  const externalContent = useAppSelector(state => state.navbar.externalContent);
  const availableConversationsCount = useAppSelector(state => state.navbar.availableConversationsCount);
  const userInfo = useAppSelector(selectUserInfo);
  const accountSettings = useAppSelector(selectAccountSettings);
  
  const dispatch = useAppDispatch();

  const classes = useStyles();
  const history = useHistory();
  
  const [drawerOpen, setDrawerOpen] = useState(false);
  
  const version = "1.12.1.3";
  
  const toggleDrawer =
    (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      setDrawerOpen(open);
    };
  
  const accountNavBarMenu = [
    { id: 1, icon: <MenuIconLogoClear className={classes.menuIcon}/>, label: t('Appointments'), url: Url.Appointments.Main, isDisplayed: true },
    { id: 2, icon: <MenuIconCustomer className={classes.menuIcon}/>, label: useCustomerTerminology(t("Customers"), "Customers"), url: Url.Customers.Main, isDisplayed: userInfo.hasCustomerInfoVisible },
    { id: 3, isParent: true, icon: <MenuIconWhatsapp className={classes.menuIcon}/>, label: "WhatsApp", url: "", isDisplayed: true },
    { id: 3.1, parentId: 3, icon: <ConversationIcon className={classes.menuIcon}/>, label: t("Conversations"), url: Url.WhatsApp.Main, isDisplayed: userInfo.hasAccessToWhatsAppConversationsAndNotifications },
    { id: 3.2, parentId: 3, icon: <MegaphoneIcon className={classes.menuIcon}/>, label: t("Massive sendings"), url: Url.WhatsApp.MassiveSendings, isDisplayed: true },
    { id: 4, icon: <MenuIconReports className={classes.menuIcon} style={{ marginTop: -5 }}/>, label: t('Reports'), url: Url.Reports.Main, isDisplayed: userInfo.hasAccessToReports },
    { id: 5, icon: <MenuIconSettings className={classes.menuIcon}/>, label: t('Settings'), url: Url.Settings.Main, isDisplayed: userInfo.isAdministrator }
  ];
  
  const enterpriseNavBarMenu = [
    { id: 1, icon: <MenuIconLogoClear className={classes.menuIcon}/>, label: t('Appointments'), url: Url.Appointments.Main, isDisplayed: true },
    { id: 2, icon: <MenuIconCustomer className={classes.menuIcon}/>, label: useCustomerTerminology(t("Customers"), "Customers"), url: Url.Customers.Main, isDisplayed: userInfo.hasCustomerInfoVisible },
    { id: 3, isParent: true, icon: <MenuIconWhatsapp className={classes.menuIcon}/>, label: "WhatsApp", url: "", isDisplayed: true },
    { id: 3.1, parentId: 3, icon: <ConversationIcon className={classes.menuIcon}/>, label: t("Conversations"), url: Url.WhatsApp.Main, isDisplayed: true },
    { id: 4, icon: <MenuIconReports className={classes.menuIcon} style={{ marginTop: -5 }}/>, label: t('Reports'), url: Url.Reports.CustomReport, isDisplayed: userInfo.hasAccessToReports },
    { id: 5, icon: <MenuIconSettings className={classes.menuIcon}/>, label: t('Settings'), url: Url.EstablishmentSettings.General, isDisplayed: userInfo.isAdministrator }
  ];
  
  const navBarMenuItems: NavBarMenuItem[] = 
    accountSettings.isEnterprise 
      ? enterpriseNavBarMenu 
      : accountNavBarMenu
  
  const clickMenuItem = (e: any, item: NavBarMenuItem) => {
    if (item.isParent) {
      e.preventDefault();
    } else {
      setDrawerOpen(false);
      history.push(item.url);
    }
  };
  const onClickHandleExternalLink = (e: any, item: NavBarMenuItem) => {
    e.stopPropagation();
    window.open(item.url, "_blank");
  };
  
  const onMouseHoverItem = (item: NavBarMenuItem) => {
    seHoverItemId(item.id);
  };
  const onMouseLeaveItem = () => {
    seHoverItemId(-1);
  };
  
  async function logOut() {
    await dispatch(resetUserInfo());
    await dispatch(deauthenticate());
    await AuthenticationService.logout();
  }
  
  useEffect(() => {
    if (isLocationInitialized) {
      dispatch(navbarActions.setExternalContent(null));
    } else {
      setIsLocationInitialized(true);
    }
  }, [location.pathname]);
  
  const commonHubConnection = useSelector(selectCommonHubConnection);
  dispatch(connect());
  
  return (
    <AppBar position="fixed" className={classes.menuHorizontalTop}>
      
      <AccountOverdueMessage/>
      <Toolbar variant="dense">
        
        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={toggleDrawer(true)}>
          <MenuIcon/>
        </IconButton>
        
        <Drawer open={drawerOpen} onClose={toggleDrawer(false)} classes={{ paper: classes.paper }}>
          
          <table className={classes.tablecss}>
            <tbody>
            <tr>
              <td valign="top">
                
                <div style={{ paddingLeft: 25 }}>
                  <div className={classes.logoClear}>
                    <MenuIconLogoClear className={classes.iconLogoClear}/>
                    clear
                  </div>
                  {
                    accountSettings.isEnterprise &&
                    <div className={classes.enterpriseLabel}>
                      {t("Enterprise account")}
                    </div>
                  }
                  <div className={classes.versionLabel}>
                    {t("Version") + " " + version}
                  </div>
                  <div className={classes.userLabel}>
                    {userInfo.fullName}
                  </div>
                  <div className={classes.emailLabel}>
                    {userInfo.email}
                  </div>
                </div>
                
                <List className={classes.drawerListItems}>
                  {navBarMenuItems.filter(x => x.isDisplayed).map((item: NavBarMenuItem, index: number) => (
                    
                    <div key={index} className={item.isParent ? classes.linkMenuParent : classes.linkMenu}>
                      
                      <ListItem button
                                key={item.label}
                                classes={{
                                  root: `
                                  ${item.isParent && navBarMenuItems.filter(x => x.parentId === item.id).some(x => x.url === history.location.pathname)
                                    ? classes.childItemActive
                                    : null} 
                                  ${history.location.pathname === item.url
                                    ? item.parentId ? classes.listSubItemActive : classes.listItemActive
                                    : item.parentId ? classes.listSubItem : classes.listItem}`,
                                }}
                                onMouseEnter={() => onMouseHoverItem(item)}
                                onMouseLeave={() => onMouseLeaveItem()}
                                onClick={(e) => clickMenuItem(e, item)}>
                        
                        <ListItemIcon className={classes.listItemIcon}>
                          {item.icon}
                        </ListItemIcon>
                        
                        <Typography className={classes.listItemText}>
                          {item.label}
                        </Typography>
                        
                        {!item.isParent && hoverItemId === item.id &&
                            <div onMouseEnter={() => setIsExternalLinkHover(true)}
                                 onMouseLeave={() => setIsExternalLinkHover(false)}
                                 onClick={(e) => onClickHandleExternalLink(e, item)}>
                                <Tooltip placement="bottom" title={<>{t("Open in new tab")} </>} classes={{popper: classes.popper}}>
                                    <div>
                                        <ExternalLink viewBox="0 -3 24 24" color={isExternalLingHover ? "inherit" : "#ACB7C0"} style={{ width: 24, height: 24 }}/>
                                    </div>
                                </Tooltip>
                            </div>
                        }
                      </ListItem>
                    </div>
                  ))}
                </List>
              </td>
            </tr>
            {availableConversationsCount !== null && availableConversationsCount <= 500 && accountSettings.isEnterprise === false &&
                <tr>
                    <td valign="bottom">
                        <div className={classes.referFriend}>
                            <div className={classes.referFriendTitle}>{t("NavbarReferFriendTitle")}</div>
                            <div className={classes.referFriendDescription}>{t("NavbarReferFriendDescription")}</div>
                            <div>
                                <a className={classes.referFriendLink} href="https://somosclear.com/referidos-de-clientes/" target="_blank">{t("NavbarReferFriendLink")}</a>
                            </div>
                        </div>
                    </td>
                </tr>
            }
            </tbody>
            <tfoot>
            <tr>
              <td valign="bottom">
                <div style={{ width: "90%" }}>
                  <List>
                    <Link to={"/logout"} className={classes.linkMenu}>
                      <ListItem button key={"Sign off"} classes={{ root: classes.listItem }} onClick={logOut}>
                        <ListItemIcon className={classes.listItemIcon} style={{ paddingTop: 0 }}>
                          <MenuIconPower className={classes.menuIcon}/>
                        </ListItemIcon>
                        <ListItemText primary={t('Sign off')} className={classes.listItemText}/>
                      </ListItem>
                    </Link>
                  </List>
                </div>
              
              </td>
            </tr>
            </tfoot>
          </table>
        </Drawer>
        <Title/>
        
        <div className={classes.externalContentPlaceholder}>
          {externalContent}
        </div>
  
        {
          accountSettings.isEnterprise === false
            ? <div className={classes.iconsBlock}>
              {
                accountSettings.isCashRegisterActivated && accountSettings.paymentsAndCollectionsEnabled &&
                <CashRegisterMain commonHubConnection={commonHubConnection}/>
              }
              <NotificationBlock commonHubConnection={commonHubConnection}/>
            </div> 
            : null
        }
      </Toolbar>
    </AppBar>
  );
  
};

