import React from "react";
import {Trans, useTranslation} from "react-i18next";

import Typography from "@material-ui/core/Typography";
import moment from "moment";

import ExclamationModal from "../../common/ExclamationModal";

import useStyles from "./css";
import { AppointmentWorkAreaBusyModalProps } from "./props";
import { useAppSelector } from "../../../redux/hooks";

const AppointmentWorkAreaBusyModal = (props: AppointmentWorkAreaBusyModalProps) => {
  const { open, appointmentBusyModalData, onClose } = props;
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  const localeApp = useAppSelector(state => state.applicationInterface.locale);

  const date = (startDate: Date, duration: number): string => {
    const start = new Date(startDate);
    const end = moment(start).add(duration, 'm').toDate();

    let weekDay = start.toLocaleString(localeApp, {
      weekday: "long",
    });

    weekDay = `${weekDay.charAt(0).toUpperCase()}${weekDay.slice(1)}`;

    const monthDay = start.toLocaleString(localeApp, {
      day: "2-digit",
      month: "long",
    });
  
    const startTime = start.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  
    const endTime = end.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
  
    return `${weekDay}, ${monthDay} \u00B7 ${startTime} - ${endTime}hrs`;
  };

  const getDuration = (duration: number): string => {
    const seconds = duration * 60;
    const hour = Math.floor(seconds / 3600);
    const minute = Math.floor((seconds % 3600) / 60);
    const hourDisplay = hour > 0 ? `${hour} ${hour === 1 ? "hr" : "hrs"} ` : "";
    const minuteDisplay = minute > 0 ? `${minute} min` : "";
    const format = hourDisplay + minuteDisplay;
    return format.trim();
  };

  const BusyScheduleInfo = () => <>
    <div>
      <Typography className={classes.normalText}>
        <Trans i18nKey="TheWorkAreaIsBusy" t={t} values={{ workArea: appointmentBusyModalData?.workArea || "" }}/>
      </Typography>
    </div>

    <div className={classes.modalDateText}>
      <Typography className={classes.scheduleText}>
        {`${date(
          appointmentBusyModalData?.startData ?? new Date(),
          appointmentBusyModalData?.appointmentDuration ?? 0
        )} (${getDuration(appointmentBusyModalData?.appointmentDuration ?? 0)})`}
      </Typography>
    </div>

    <div>
      <Typography className={classes.normalText}>
        {t("Please select another work area.")}
      </Typography>
    </div>
  </>

  return (
    <ExclamationModal
      open={open}
      title={t("Busy work area")}
      body={<BusyScheduleInfo />}
      okTextButton={t("OkUnderstand")}
      height={330}
      onClose={onClose}
    />
  );
};

export default AppointmentWorkAreaBusyModal;
