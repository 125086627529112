import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import { SelectItem } from "../../../models/interfaces/select-item";

export interface SelectProps {
  styleClass?: any;
  classes?: Partial<ClassNameMap<string>>;
  values: Array<SelectItem | string | number>;
  maxValues?: number | null;
  allItemsPlaceholder: string;
  name?: string;
  items: Array<SelectItem | string | number>;
  width?: number | string;
  disabled?: boolean;
  isValid?: () => boolean | undefined;
  onChange?: (users: Array<SelectItem | string | number>) => void;
  onReset: () => void;
  onBlur?: (event: any) => void;
  onOpen?: (event: any) => void;
  colorArrowIcon?: string;
  sort?: boolean;
  filterPlaceholder: string;
  height?: number | string;
  overflowRenderCap?: number;
  overflowRenderType?: MultiselectOverflowRenderType;
  showSearch?: boolean;
}

export enum MultiselectOverflowRenderType {
  Plus,
  Dots
}
