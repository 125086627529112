import { makeStyles } from "@material-ui/core/styles";

const useStyles = () => makeStyles((theme) => ({
  root: {
    zIndex: 2,
    position: "relative",
    left: 0,
    backgroundColor: theme.colors.white
  },
  modal: {
    display: "flex",
    alignItems: "center",
  },
  optional: {
    fontWeight: "normal",
  },
  searchInputContainer: {
    position: "relative",
    padding: "0 15px 10px 15px",
    width: "-webkit-fill-available"
  },
  searchIconContainer: {
    position: "absolute",
    top: 7,
    right: 21
  },
  divDropdown: {
    width: "100%",
    display: "block",
    marginTop: -7,
    height: 70,
    position: "relative"
  },
  inputSelectBtn: {
    width: 300,
    paddingLeft: 100,
    "& input": {
      marginLeft: 100,
      width: 140,
    }
  },
  titleCountries: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey4,
    textAlign: "left",
    margin: "15px auto 10px 15px",
  },
  divDialCode: {
    position: "relative",
    top: -1,
    left: 18,
    display: "inline",
    fontSize: 12,
    fontStyle: "normal",
    fontFamily: "Roboto, sans-serif",
    fontWeight: "bold",
    color: theme.colors.clearMain,
  },
  iconDropdown: {
    cursor: "pointer",
    display: "inline",
    textAlign: "left",
    position: "relative",
    top: -1,
    left: 15,
    "& svg": {
      width: 24,
      height: 24,
    }
  },
  inputFlag: {
    display: "inline",
    position: "relative",
    top: -2,
    left: 12,
    height: 20,
    width: 20,
    "& svg": {
      width: "20px !important",
      height: "20px !important"
    }
  },
  divCodes: {
    cursor: "pointer",
    position: "relative",
    top: -30,
    left: 0,
    width: 90,
  },
  divModalSelect: {
    backgroundColor: theme.colors.white,
  
    position: "absolute",
    top: 0,
  
    width: 288,
    maxHeight: 382,
    padding: 0,
    
    border: "solid",
    borderWidth: 1,
    borderColor: "#DBDCDD",
    borderRadius: 5,
    boxShadow: "0px 4px 4px rgba(166, 166, 166, 0.25)",
    
    textAlign: "center",
  },
  listCountryScroll: {
    maxHeight: 290,
    
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      backgroundColor: theme.colors.white,
      width: 16,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.colors.white,
    },
    "&::-webkit-scrollbar-track:hover": {
      backgroundColor: "#F4F4F4",
      width: 16,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      
      border: "solid",
      borderRadius: 16,
      borderWidth: 5,
      borderColor: theme.colors.white,
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a0a0a5",
      
      border: "solid",
      borderWidth: 4,
      borderColor: theme.colors.white,
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  },
  listCountry: {
    padding: "5px 0",
    "&:first-child": {
      paddingTop: 10,
    },
    borderBottom: "1px solid #F6F6F6",
    marginBottom: 0,
  },
  countryName: {
    textAlign: "left",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    width: "75%",
    overflow: "hidden",
    display: "inline-block",
    paddingTop: "1px",
    height: 40
  },
  countryIcon: {
    marginRight: 10,
  },
  button: {
    width: 300,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    color: "#786EFF",
    border: "1px solid #786EFF",
    
  },
  elementList: {
    height: 35,
    padding: "5px 5px 10px 10px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 14,
    display: "flex",
    color: theme.colors.grey5
  },
  divInputEdit: {
    margin: "auto",
    "& .MuiInputBase-root": {
      height: 32
    }
  },
  input: {
    marginLeft: "auto",
    "& input": {
      width: 250,
    }
  },
  inputClassic: {
    marginLeft: 85,
    border: "none",
    width: "55%",
    height: 38,
    backgroundColor: theme.colors.white,
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey5,
    "&::placeholder": {
      fontWeight: "normal",
      color: "#676767",
      opacity: "0.42",
    },
    padding: "13px 0px 13px 12px",
    borderRadius: 5,
    "&:focus": {
      border: "none",
      padding: "14px 0px 14px 12px",
      "&$inputError": {
        color: theme.colors.clearRed,
        border: "none",
      },
    },
    "&:focus:not(:focus-visible)": {
      outline: "none",
    },
    "&:focus-visible": {
      outline: "none",
      border: "none",
      padding: "14px 0px 14px 12px",
      "&$inputError": {
        color: theme.colors.clearRed,
        border: "none",
      },
    },
  },
  iconPlus: {
    border: "none",
    backgroundColor: "transparent",
    position: "relative",
    top: -30,
    left: 130,
    textAlign: "center",
    verticalAlign: "middle",
    padding: 0,
    color: "#6462F3",
    "&:focus": {
      outline: "none"
    }
  },
  inputError: {
    color: `${theme.colors.clearRed} !important`,
    border: `solid 1px ${theme.colors.clearRed} !important`,
    "& input": {
      color: `${theme.colors.clearRed} !important`,
    },
  },
  iconSave: {
    border: "none",
    backgroundColor: "transparent",
    position: "relative",
    top: -30,
    left: 130,
    textAlign: "center",
    verticalAlign: "middle",
    padding: 0,
    color: "#6462F3",
    "&:focus": {
      outline: "none"
    }
  },
  divItemEdit: {
    height: 32,
    display: "block"
  },
  btnInputNew: {
    height: 32,
    "& .MuiInputBase-root": {
      height: 32
    }
  },
  classRow: {
    cursor: "pointer",
    "& button": {
      visibility: "hidden",
    },
    "&:hover": {
      backgroundColor: "#e1e9fe",
      "& button": {
        visibility: "visible"
      }
    }
  },
  btnRow: {
    width: 32,
    height: 32,
    borderRadius: 51,
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    textTransform: "none",
    border: "none",
    padding: 0,
    margin: 0,
    backgroundColor: "transparent",
    "&:focus": {
      outline: "none"
    }
  },
  divIconsRow: {
    right: 0,
    textAlign: "center",
    marginLeft: "auto",
    float: "right",
    paddingTop: 0
    
  },
  inputGroup: {
    height: 40,
    backgroundColor: theme.colors.white,
    borderRadius: 5,
    border: "1px solid #dddddd"
  }
}));

export default useStyles;
