import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: 24,
    width: "100%",
    justifyContent: "space-between",
  },
  tabTitle: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: Color.gray5
  },
  sectionsContainer: {
    display: "flex",
    flexDirection: "column",
  },
  treatmentNoteBox: {
    width: "100%",
    height: "37px",
    display: "flex",
    alignItems: "center",
    borderRadius: "5px",
    background: Color.clilac,
    marginBottom: 14
  },
  treatmentNoteLabel: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "normal",
    color: Color.gray5,
    margin: "0px 14px 0px 14px"
  },
  headerButton: {
    "&.MuiButton-root": {
        minWidth: 0
    },
},

}));

export default useStyles;