import { Customer } from "./customer";
export class CustomersResponse {
  customers: Customer[] | null;
  totalCustomers: number | null;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.customers = x.customers;
      this.totalCustomers = x.totalCustomers;
    } else {
      this.customers = null;
      this.totalCustomers = null;
    }
  }
}
