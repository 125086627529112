// export const nameRegex = new RegExp("^([a-zA-Z'-.À-ÿ\u00f1\u00d1]+((?: [a-zA-Z'-.À-ÿ\u00f1\u00d1]+)?)+(?: [a-zA-Z'-.À-ÿ\u00f1\u00d1]+)?)$");
export const NameRegex = /^([a-zA-Z'-.À-ÿ\u00f1\u00d1]+((?: [a-zA-Z'-.À-ÿ\u00f1\u00d1]+)?)+(?: [a-zA-Z'-.À-ÿ\u00f1\u00d1]+)?)$/;
export const MobileRegex = /^\d{10}$/;
export const EmailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const AlphanumericRegex = /^(?!.*[ ]{2})[a-zA-Z ¡!¿?)(.,;:áéíóúÁÉÍÓÚäëïöüÄËÏÖÜÑñ1234567890]{2,80}$/; // Alphanumeric
export const AlphanumericExtendedRegex = /^(?!.*[ ]{2})[a-zA-Z ¡!¿?@#$%^&*)(.,;:áéíóúÁÉÍÓÚäëïöüÄËÏÖÜ1234567890]{2,80}$/; // Alphanumeric with some symbols
export const CurrencyRegex = /^(\d+(\.\d{0,2})?|\.?\d{1,2})$/;
export const NameRegexV2 = /^[^\d!@#$%^&*<>?`]*$/;
export const LetterSpaceRegex = /^[a-zA-Z\s]*$/;
export const MultipleSpacesNameRegex = /^\s*([a-zA-Z'-.À-ÿ\u00f1\u00d1]+((?:\s{0,}[a-zA-Z'-.À-ÿ\u00f1\u00d1]+)?)+(?:\s{0,}[a-zA-Z'-.À-ÿ\u00f1\u00d1]+)?)( *)$/;
export const URLRegex = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
export const MassiveSendingUrlRegex = /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/ig;

