import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    width: "100%",
    height: 75,
    padding: "8px 24px",
  },
  block: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "inherit",
    
    width: 159,
    height: 59,
    
    padding: "4px 0"
  },
  blockCashExpected: {
    display: "flex",
  },
  centerBlock: {
    width: 134
  },
  textWithTooltip: {
     lineHeight: "15px",
  },
  blockLongText: {
     paddingTop: "0",
  },
  text: {
    ...theme.typography.buttons,
    color: theme.colors.grey5
  },
  value: {
    ...theme.typography.txtBody1,
    fontSize: 12,
    fontWeight: 700,
    color: theme.colors.grey5
  },
  verticalDivider: {
    height: 59,
    width: 0,

    opacity: 0.5,

    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.grey2,
    
    margin: 8
  },
  iconTooltipRoot: {
    display: "inline-block"
  }
  })
);

export default useStyles;