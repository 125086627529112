import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function WhatsAppDocumentIcon(props: any) {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 25, height: 26, ...style }}
      viewBox={viewBox || "0 0 25 26"}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M20.3464 10.2268L13.0547 2.64351C12.9012 2.50671 12.7047 2.43344 12.5026 2.43767H9.3776C6.64495 2.43767 4.42969 4.74154 4.42969 7.58351V18.4168C4.42969 21.2588 6.64495 23.5627 9.3776 23.5627H15.6276C18.3603 23.5627 20.5755 21.2588 20.5755 18.4168V10.8335C20.5791 10.6078 20.4967 10.3897 20.3464 10.2268ZM13.2839 5.18934L17.9297 10.021H14.5859C13.8668 10.021 13.2839 9.41473 13.2839 8.66684V5.18934ZM5.99219 18.4168C5.99791 20.3589 7.51026 21.9317 9.3776 21.9377H15.6276C17.4949 21.9317 19.0073 20.3589 19.013 18.4168V11.646H14.5859C13.0039 11.646 11.7214 10.3122 11.7214 8.66684V4.06267H9.3776C7.51026 4.06862 5.99791 5.64147 5.99219 7.58351V18.4168Z"
        fill={Color.gray5} />
    </SvgIcon>
  );
}