import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionFieldOptionalTextFieldIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 16, height: 16, ...style }}
            viewBox={viewBox || "0 0 16 16"}
        >
            <path
                d="M10.3333 7.8125C10.3333 7.67188 10.1875 7.53125 10.0416 7.53125H5.95831C5.78817 7.53125 5.66665 7.67188 5.66665 7.8125V8.46875C5.66665 8.63281 5.78817 8.75 5.95831 8.75H10.0416C10.1875 8.75 10.3333 8.63281 10.3333 8.46875V7.8125ZM10.0416 9.5H5.95831C5.78817 9.5 5.66665 9.64062 5.66665 9.78125V10.4375C5.66665 10.6016 5.78817 10.7188 5.95831 10.7188H10.0416C10.1875 10.7188 10.3333 10.6016 10.3333 10.4375V9.78125C10.3333 9.64062 10.1875 9.5 10.0416 9.5ZM12.6666 5.09375C12.6666 4.8125 12.5208 4.50781 12.3021 4.29688L10.2847 2.35156C10.066 2.14062 9.74998 2 9.45831 2H4.49998C3.84373 2 3.33331 2.51562 3.33331 3.125V12.875C3.33331 13.5078 3.84373 14 4.49998 14H11.5C12.1319 14 12.6666 13.5078 12.6666 12.875V5.09375ZM9.55554 3.21875L11.4028 5H9.55554V3.21875ZM11.5 12.875H4.49998V3.125H8.38887V5.5625C8.38887 5.89062 8.63192 6.125 8.9722 6.125H11.5V12.875Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}