import React, { useState } from "react";

import { useTranslation } from "react-i18next";

import Input from "@material-ui/core/Input";
import MuiSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Tooltip from "@material-ui/core/Tooltip";

import ArrowDropDownIcon from "./../../../assets/icons/ArrowDropDownIcon";
import SheetIcon from "../../../assets/icons/SheetIcon";
import { SelectWhatsAppTemplateProps } from "./props";
import { SelectItem } from "../../../models/interfaces/select-item"
import { WhatsAppTemplateType } from "../../../models/enums/whatsapp-template-type";
import useStyles from "./css";

export default function SelectWhatsappTemplate(props: SelectWhatsAppTemplateProps) {
  const {
    value,
    setValue,
    name,
    items,
    onOpen,
    styleClass,
    classes,
    availableTemplates,
    disabled
  } = props;
  const useClasses = useStyles();
  const { t } = useTranslation(["general"]);
  
  const [isOpen, setIsOpen] = useState<boolean>(false);
  
  function handleOnChange(key: WhatsAppTemplateType) {
    setValue(key);
  };
  
  function handleOnOpen() {
    setIsOpen(true);
    onOpen();
  }
  
  function getTitleByKey(key: any) {
    const availableTemplate = availableTemplates.find(template => template.templateType === key);
    return availableTemplate ? availableTemplate.templateText : "";
  }

  const ArrowIcon = (props: any) => (
    <ArrowDropDownIcon
      {...props}
      style={{ right: 12, top: 6, height: "22px!important", color: "#F6F6F6" }}
    />
  );

  return (
    <MuiSelect
      disabled={disabled === true}
      disableUnderline
      classes={
        classes || {
          root: `${useClasses.focused} ${useClasses.selected}`,
        }
      }
      name={name}
      className={styleClass ?? useClasses.select}
      input={<Input  />}
      MenuProps={
        { 
          style: { position: "absolute", top: -47 },
          classes: { paper: useClasses.menuPaper },
        }}
      displayEmpty
      renderValue={(selected) => (
        <span>
          {t("Send WhatsApp template")}
        </span>
      )}
      onOpen={handleOnOpen}
      onClose={() => setIsOpen(false)}
      value={value === undefined || value === null ? "" : value}
      IconComponent={ArrowIcon}
      
    >
        <MenuItem classes={{ root: `${useClasses.itemRoot}`, }} value="" disabled>
          <SheetIcon/> {t("WhatsApp templates")}
        </MenuItem>
      {items.map((item: SelectItem) => (
          <MenuItem
            classes={{
              root: `${ useClasses.itemRoot}`,
              selected: useClasses.itemSelected,
            }}
            value={item.key}
            onClick={() => handleOnChange(item.key)}
            disabled={item?.disabled ?? false}
          >
            <Tooltip
              key={item.key}
              title={isOpen
                ? (<div dangerouslySetInnerHTML={{ __html: getTitleByKey(item.key) }}/>)
                : ""}
              classes={{ tooltip: useClasses.tooltipBlock }}
            >
              <div className={useClasses.tooltipContainer} key={item.key}>
                {item.value}
              </div>
            </Tooltip>
          </MenuItem>
      ))}
    </MuiSelect>
  );
};