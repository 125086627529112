import React, { useState, useEffect } from "react";

import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { conformToMask } from "react-text-mask";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { alertsActions } from "../../../redux/alerts-slice";

import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";

import { Url } from "../../../constants/url";
import Autocomplete from "../../common/Autocomplete";
import CustomerModal from "./../../customers/CustomerModal";
import { CustomerService } from "../../../api/customer-service";
import { CustomerSearch } from "../../../models/customer-search";
import {
  GroupIcon,
  AddCustomerIcon,
} from "./../../../assets/icons";
import { LocalStorageKey } from "../../../constants/local-storage-key";
import { CreateAppointmentModel } from "../../../models/create-appointment-model";

import { AppointmentCustomerSectionProps } from "./props";
import useStyles from "./css";
import { GetMobileMaskByTemp } from "../../../constants/mask";
import { AppointmentPaymentStatus } from "../../../models/enums/appointment-payment-status";
import CountryFlagIcon from "../../../assets/flags";
import { navbarActions } from "../../../redux/navbar-slice";
import { selectAccountSettings } from "../../../redux/store";
import { ApiClient } from "../../../api/api-client";


export default function AppointmentCustomerSection(props: AppointmentCustomerSectionProps) {

  const { appointment, isEdition, customerId, onChange } = props;

  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation(["general"]);
  
  const hasCustomerInfoVisible = ApiClient.getUserInfo().hasCustomerInfoVisible;
  
  const [search, setSearch] = useState<string>();
  const [customer, setCustomer] = useState<CustomerSearch>();
  const [customerModal, setCustomerModal] = useState<boolean>(false);
  const [customersSearch, setCustomersSearch] = useState<CustomerSearch[]>([]);

  const dispatch = useAppDispatch();
  const terminologyCreateNewCustomer = useCustomerTerminology(t("Create new customer"), "customer");
  const terminologyFindCustomer = useCustomerTerminology(t("Find & select customer"), "customer");
  const accountSettings = useAppSelector(selectAccountSettings);
  const { enqueueAlert } = alertsActions;

  const mobileTemplate: string = "000-000-0000";

  useEffect(() => {
    if (customerId) {
      (async () => {
        dispatch(navbarActions.setShowLoader(true));
        const customer = await CustomerService.getCustomerInfoDetails(customerId);
        dispatch(navbarActions.setShowLoader(false));

        const countries = accountSettings.countries;
        let mobileCode = customer.mobileCodeIso3 ?? "MEX";
        customer.mobileDigits = countries.find(x => x.iso3 === mobileCode)!.dialDigits;
        customer.mobileCode = countries.find(x => x.iso3 === mobileCode)!.dialCode;
        customer.mobileTemplate = countries.find(x => x.iso3 === mobileCode)!.dialTemplate.toString();
        
        setCustomer(new CustomerSearch(customer));
        onChange(
          new CreateAppointmentModel({
            ...appointment,
            customerId: customerId,
            firstName: customer.firstName,
            lastName: customer.lastName,
          })
        );
      })();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customerId]);

  useEffect(() => {
    async function fetchData() {
      await loadSearchData();
    }

    if (search) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);

  const loadSearchData = async () => {
    const customers = await CustomerService.getSearch(search);

    const countries = accountSettings.countries;

    [...customers].map(customer => {
      let mobileCode = customer.mobileCodeIso3 ?? "MEX";
      customer.mobileDigits = countries.find(x => x.iso3 === mobileCode)!.dialDigits.toString();
      customer.mobileCode = countries.find(x => x.iso3 === mobileCode)!.dialCode.toString();
      customer.mobileTemplate = countries.find(x => x.iso3 === mobileCode)!.dialTemplate.toString();
      return customer;
    })
    
    setCustomersSearch(customers || []);
  };

  const getUser = (): string => {
    const accessToken = localStorage.getItem(LocalStorageKey.AccessToken) || "";
    const jsonPayload = JSON.parse(
      decodeURIComponent(
        atob(accessToken.split(".")[1].replace(/-/g, "+").replace(/_/g, "/"))
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      )
    );
    return jsonPayload.fullname;
  };

  const handleCustomerCreated = (customer: any) => {

    const customerSearched = new CustomerSearch(customer);
    setCustomer(customerSearched);
    onChange(
      new CreateAppointmentModel({
        ...appointment,
        customerId: customer.id,
        firstName: customer.firstName,
        lastName: customer.lastName,
      })
    );
    setCustomerModal(false);
    dispatch(
      enqueueAlert({
        type: "Success",
        title: t("Customer Created"),
        description: `${customer.firstName} ${customer.lastName}`
      })
    );
  };

  const handleCustomerDuplicated = (customer: any) => {
    dispatch(
      enqueueAlert({
        type: "Error",
        title: t("Invalid number"),
        description: t("This number already belongs to customer"),
        link: {
          onClick: (e: any) => {
            e.preventDefault();
            history.push(`${Url.Customers.Main}/${customer.id}`);
          },
          title: `${customer.firstName} ${customer.lastName}`,
        }
      })
    );
  };

  const mobileFormat = (mobile: string, template: any): string => {
    return conformToMask(mobile, GetMobileMaskByTemp(template || mobileTemplate)).conformedValue;
  };

  return (
    <div className={classes.customerSection}>
      <Typography className={classes.txtBody1Bold}>
        {isEdition ? t("Edit appointment") : t("New appointment")}
      </Typography>
      <div className={classes.personInCharge}>
        <Typography className={classes.txtBody2}>
          {isEdition ? t("PersonInChargeEdition") : t("Person in charge:")}
        </Typography>
        <Typography style={{ marginLeft: 3 }} className={classes.txtBody2Bold}>
          {isEdition ? appointment?.user : getUser()}
        </Typography>
      </div>
      {isEdition ? (
        <div className={classes.personInCharge}>
          <Typography className={classes.txtBody2}>
            {t("Appointment number:")}
          </Typography>
          <Typography
            style={{ marginLeft: 3 }}
            className={classes.txtBody2Bold}
          >
            {appointment?.number}
          </Typography>
        </div>
      ) : null}
      <div
        className={classes.lineSeparator}
        style={{ width: 227, marginTop: 16, marginBottom: 28 }}
      />
      <div className={classes.customerInfoTitle}>
        <GroupIcon style={{ marginRight: 12 }} />
        <Typography className={classes.txtBody1Bold}>
          {useCustomerTerminology(t("Customer information"), "Customer information")}
        </Typography>
      </div>
      {!isEdition ? (
        <Autocomplete
          style={{ width: 228, marginBottom: 24, height: 32 }}
          items={customersSearch}
          placeholder={terminologyFindCustomer}
          noBorder
          renderOption={(option: CustomerSearch) => {
            if (option.firstName) {
              return (
                <Typography
                  className={`${classes.customerListOverrides} ${customer && customer.id === option.id
                    ? classes.customerListSelected
                    : classes.customerList
                    }`}
                >
                  {`${option.firstName} ${option.lastName}`}
                  {hasCustomerInfoVisible &&
                    <>
                      { " / " }
                      <CountryFlagIcon iso={option.mobileCodeIso3!} />
                      {" +" + option.mobileCode + "  "}
                      {mobileFormat(option.mobile || "", option.mobileTemplate)}
                    </>
                  }
                </Typography>
              );
            }
            return option;
          }}
          getOptionSelected={(option, value) =>
            `${option.firstName} ${option.lastName}` ===
            `${value.firstName} ${value.lastName}`
          }
          getOptionLabel={() => ""}
          filterOptions={(options) => {
            const result = [...options];
            result.push(
              <Button
                className={classes.buttonNewCustomer}
                onClick={() => {
                  setCustomerModal(true);
                }}
              >
                <AddCustomerIcon style={{ marginRight: 11 }} />
                {terminologyCreateNewCustomer}
              </Button>
            );
            return result;
          }}
          onChange={(value: any, reason) => {
            if (reason === "input" && value.length > 2) {
              setSearch(value);
            } else {
              setCustomersSearch([]);
            }
          }}
          onSelected={(option: CustomerSearch) => {
            if (option && option.firstName) {
              setCustomer(option);
              onChange(
                new CreateAppointmentModel({
                  ...appointment,
                  customerId: option.id,
                  firstName: option.firstName,
                  lastName: option.lastName,
                })
              );
            }
          }}
        />
      ) : null}
      <Typography className={classes.label2} style={{ width: 220, height: 14 }}>
        {useCustomerTerminology(t("Customer name"), "Customer name")}
      </Typography>
      <Typography className={classes.label1} style={{ width: 220, height: 14 }}>
        {isEdition
          ? `${appointment?.firstName} ${appointment?.lastName}`
          : customer
            ? `${customer.firstName} ${customer.lastName}`
            : "--"}
      </Typography>
      {hasCustomerInfoVisible &&
        <>
          <Typography
            className={classes.label2}
            style={{ width: 220, height: 14, marginTop: 16 }}
          >
            {t("Mobile number")}
          </Typography>
          <Typography className={classes.label1} style={{ width: 220, height: 14 }}>
            {isEdition
              ? mobileFormat(appointment?.mobile || "", appointment?.mobileTemplate || mobileTemplate)
              : customer
                ? mobileFormat(customer.mobile || "", customer.mobileTemplate)
                : "--"}
          </Typography>
          <Typography
            className={classes.label2}
            style={{ width: 220, height: 14, marginTop: 24 }}
          >
            {t("Email")}
          </Typography>
          <Typography className={classes.label1} style={{ width: 220, height: 14 }}>
            {isEdition
              ? appointment?.email || "--"
              : customer
                ? customer.email
                  ? customer.email
                  : "--"
                : "--"}
          </Typography>
        </>
      }
      <CustomerModal
        open={customerModal}
        onClose={() => {
          setCustomerModal(false);
        }}
        onDuplicate={handleCustomerDuplicated}
        onCreate={handleCustomerCreated}
      />
    </div>
  );
}