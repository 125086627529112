import React, { useState, useEffect, useRef, useImperativeHandle } from "react";
import useStyles from "./css";
import { DropImageProps } from "./props";
import DropImageRefs from "./refs";


const DropImage = React.forwardRef<DropImageRefs, DropImageProps>((props: DropImageProps, ref) => {
    const {
        classDivUploadImage,
        componentShowEmpty,
        componentShowEmptyHover,
        imageURL,
        disableChange,
        disableBlob,
        onChange,
        funcToValid
    } = props;

    const classes = useStyles();
    const imageRef = useRef<HTMLInputElement>(null);

    const [picturl, setPicturl] = useState<any>();

    useImperativeHandle(ref, () => ({
        openFileExplorerDialog: showOpenFileDialog,
    }));

    const showOpenFileDialog = () => {
        if (disableChange) return;
        if (imageRef && imageRef != null && imageRef != undefined && imageRef.current != undefined) {
            imageRef.current.click();
        }
    };


    useEffect(() => {
        setPicturl(imageURL);
    }, [imageURL]);

    const dragOver = (e: any) => {
        e.preventDefault();
    }

    const dragEnter = (e: any) => {
        e.preventDefault();
    }

    const dragLeave = (e: any) => {
        e.preventDefault();
    }

    const fileDrop = async (e: any) => {
        e.stopPropagation();
        e.preventDefault();
        if (disableChange) return;

        if (imageRef.current) {
            const files = e.dataTransfer.files;
            imageRef.current.files = files;
            await handleFileSelected(files);
        }
    }

    const handleChangeFile = async (e: React.FormEvent<HTMLInputElement>) => {
        e.stopPropagation();
        e.preventDefault();
        if (disableChange) return;

        const files = imageRef.current?.files;
        await handleFileSelected(files);
    };

    const handleFileSelected = async (files: any) => {

        if (files && files.length > 0) {
            if (typeof funcToValid === "function" && funcToValid) {
                const validate = await funcToValid(files[0]);
                if (validate) return;
            }

            if (!disableBlob)
                setPicturl(URL.createObjectURL(files[0]));

            if (onChange) onChange(files);
        }
    }


    return (<>

        <input
            ref={imageRef!}
            type="file"
            style={{ display: 'none' }}
            accept="image/jpeg, image/jpg, image/png"
            onChange={handleChangeFile}
        />

        <div className={`${classes.bkgPic} ${classDivUploadImage}`}
            onClick={showOpenFileDialog}
            style={{ backgroundImage: `url(${picturl})` }}
            onDragOver={dragOver}
            onDragEnter={dragEnter}
            onDragLeave={dragLeave}
            onDrop={fileDrop}  >

            {
                componentShowEmptyHover
            }
            {
                !picturl && componentShowEmpty
            }

        </div>

    </>);
});

export default DropImage;
