import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import InputMobileCode from "../../common/InputMobileCode";
import InputWithLabel from "../../common/InputWithLabel";
import InputDateWithLabel from "../../common/InputDateWithLabel";
import SelectWithLabel from "../../common/SelectWithLabel";
import { CustomerEntity } from "../../../models/customer-entity";

import useStyles from "./css";
import { CustomerGeneralProps, CustomerValid } from "./props";

import { useAppDispatch } from "../../../redux/hooks";
import { applicationInterfaceActions } from "../../../redux/application-interface-slice";
import { EmailRegex, MultipleSpacesNameRegex } from "../../../constants/validator";
import Country from "../../../models/country";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";
import CustomerNotifications from "../CustomerNotifications";
import { Gender } from "../../../models/enums/gender";


const CustomerGeneral = (props: CustomerGeneralProps) => {
  const appDispatch = useAppDispatch();
  const { t } = useTranslation(["general"]);
  const { value, isEdition, mobilePhoneDuplicated, isModal, onChange } = props;
  const [isDuplicated, setIsDuplicated] = useState<boolean>(false);
  const [customer, setCustomer] = useState<CustomerEntity>(
    value || new CustomerEntity()
  );

  const minBirthDate = new Date(1900, 0);
  const yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));

  const runValidations = (): CustomerValid | undefined => {
    if (value) {
      return {
        firstName: MultipleSpacesNameRegex.test(value.firstName),
        lastName: MultipleSpacesNameRegex.test(value.lastName),
        email: !value.email ? undefined : EmailRegex.test(value.email),
        customGender: value.gender !== Gender.Custom || !!value.customGender && MultipleSpacesNameRegex.test(value.customGender)
      };
    }

    return undefined;
  };
  const [customerValid, setCustomerValid] = useState<CustomerValid | undefined>(
    runValidations()
  );

  useEffect(() => {
    if (mobilePhoneDuplicated) setIsDuplicated(mobilePhoneDuplicated)
  }, [mobilePhoneDuplicated]);


  useEffect(() => {
    if (value && JSON.stringify(value) !== JSON.stringify(customer)) {
      setCustomer(value);
    }

    runValidations();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  useEffect(() => {
    if (onChange && customerValid) {
      onChange(customer, true);
    } else if (onChange) {
      onChange(customer, false);
    }
    setIsDuplicated(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer]);

  const handleFirstNameChange = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setCustomerValid({
      ...customerValid,
      firstName: MultipleSpacesNameRegex.test(event.target.value),
    });
    setCustomer(
      new CustomerEntity({ ...customer, firstName: event.target.value })
    );
    updateCustomerNameHeaderTitleNav(event.target.value, "");
  };

  const handleLastNameChange = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setCustomerValid({
      ...customerValid,
      lastName: MultipleSpacesNameRegex.test(event.target.value),
    });
    setCustomer(
      new CustomerEntity({ ...customer, lastName: event.target.value })
    );
    updateCustomerNameHeaderTitleNav("", event.target.value);
  };

  const updateCustomerNameHeaderTitleNav = (
    firstName: string,
    lastName: string
  ) => {
    if (isEdition) {
      if (!firstName) firstName = customer.firstName;
      if (!lastName) lastName = customer.lastName;
      let titleNav = t("Customer detail") + " / " + firstName + " " + lastName;
      appDispatch(applicationInterfaceActions.updateTitle(titleNav));
    }
  };

  const handleBirthdayChange = (date: Date) => {
    if (date) {
      const birthdate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0);
      setCustomer(
        new CustomerEntity({
          ...customer,
          birthDate: birthdate,
        })
      );
    }
  };

  const handleGenderChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setCustomer(
      new CustomerEntity({ ...customer, gender: event.target.value })
    );
  };
  
  const handleCustomGenderChange = (event: React.ChangeEvent<{ value: string | null }>) => {
    setCustomerValid({
      ...customerValid,
      customGender: customer.gender !== Gender.Custom || MultipleSpacesNameRegex.test(event.target.value || ""),
    });
    setCustomer(
      new CustomerEntity({ ...customer, customGender: event.target.value })
    );
  };

  const handleEmailChange = (event: React.ChangeEvent<{ value: string }>) => {
    const email = event.target.value ? event.target.value : undefined;
    setCustomerValid({
      ...customerValid,
      email: email ? EmailRegex.test(email.toLowerCase()) : undefined,
    });
    setCustomer(new CustomerEntity({ ...customer, email: email }));
  };

  const handleMobileChange = (num: any) => {
    setCustomer(
      new CustomerEntity({
        ...customer,
        mobile: num,
      })
    );
  };
  
  const handleMobileCodeChange = (c: Country) => {
    setCustomer(
      new CustomerEntity({
        ...customer,
        mobileCodeIso3: c.iso3,
        mobileCode: c.dialCode,
        mobileDigits: c.dialDigits,
        mobileTemplate: c.dialTemplate
      })
    );
    setIsDuplicated(false);
  };

  const handlerCustomerNotificationsChange = (customerEntity: CustomerEntity) => {
    setCustomer(customerEntity);
  };

  const handleValidMobile = (valid: boolean) => {
    setCustomerValid({
      ...customerValid,
      mobilePhone: valid && !isDuplicated
    });
  }

  const classes = useStyles();
  return (
    <div className={classes.root}>
      <label className={classes.legend}>
        {t("Required fields")} <span className={classes.required}>*</span>
      </label>
      <div className={classes.fieldsContainer}>
        <InputWithLabel
          label={() => (
            <>
              {t("Name")} <span className={classes.required}>*</span>
            </>
          )}
          value={customer?.firstName}
          placeholder={useCustomerTerminology(t("Enter the names of the customer"), "customer")}
          width={228}
          isValid={() => customerValid?.firstName}
          onChange={handleFirstNameChange}
        />
        <InputWithLabel
          label={() => (
            <>
              {t("Last names")} <span className={classes.required}>*</span>
            </>
          )}
          placeholder={useCustomerTerminology(t("Enter the last names of the customer"), "customer")}
          value={customer?.lastName}
          width={228}
          isValid={() => customerValid?.lastName}
          onChange={handleLastNameChange}
        />

        {isModal ? 
          <div style={{zIndex: 1001}} >
            <InputMobileCode 
                isOptional={false} 
                widthSelect={228} 
                widthList={isModal ? 260 : 250}
                isValid={handleValidMobile}
                onChangeItem={handleMobileCodeChange}
                onChangeMobile={handleMobileChange}
                codeIso3={customer.mobileCodeIso3!}
                mobile={customer.mobile!} />
          </div>
        : 
          <InputDateWithLabel
            label={() => (
              <>
                {t("Birth date")}{" "}
                <span className={classes.optional}>({t("Optional")})</span>
              </>
            )}
            width={228}
            placeholder={t("dd/mm/yy")}
            value={customer.birthDate}
            onChange={handleBirthdayChange}
            minDate={minBirthDate}
            maxDate={yesterdayDate}
          />
        }
        <SelectWithLabel
          label={() => (
            <>
              {t("Gender")}{" "}
              <span className={classes.optional}>({t("Optional")})</span>
            </>
          )}
          items={[
            { key: 1, value: t("Male") },
            { key: 2, value: t("Female") },
            { key: 3, value: t("I prefer not to say") },
            { key: 4, value: t("gender_custom") },
          ]}
          placeholder={t("Select the gender")}
          width={228}
          onChange={handleGenderChange}
          value={customer?.gender}
        />
        {customer.gender === Gender.Custom &&
          <InputWithLabel
            label={() => (
              <>
                {t("Custom gender")} <span className={classes.required}>*</span>
              </>
            )}
            placeholder={t("Enter custom gender")}
            width={228}
            isValid={() => customerValid?.customGender}
            onChange={handleCustomGenderChange}
            value={customer?.customGender || ""}
            maxLength={60}
          />
        }
        <InputWithLabel
          label={() => (
            <>
              {t("Email")}{" "}
              <span className={classes.optional}>({t("Optional")})</span>
            </>
          )}
          placeholder={useCustomerTerminology(t("Enter the email of the customer"), "customer")}
          width={228}
          isValid={() => customerValid?.email}
          onChange={handleEmailChange}
          value={customer?.email || ""}
        />
        {isModal ? 
          <InputDateWithLabel
            label={() => (
              <>
                {t("Birth date")}{" "}
                <span className={classes.optional}>({t("Optional")})</span>
              </>
            )}
            width={228}
            placeholder={t("dd/mm/yy")}
            value={customer.birthDate}
            onChange={handleBirthdayChange}
            minDate={minBirthDate}
            maxDate={yesterdayDate}
          />
        :
          <div style={{zIndex: 1001}} >
            <InputMobileCode 
                isOptional={false} 
                widthSelect={228} 
                widthList={isModal ? 260 : 250}
                isValid={handleValidMobile}
                onChangeItem={handleMobileCodeChange}
                onChangeMobile={handleMobileChange}
                codeIso3={customer.mobileCodeIso3!}
                mobile={customer.mobile!} />
          </div>
        }

      </div>
      {isModal ?
        <CustomerNotifications
          onChange={handlerCustomerNotificationsChange}
          value={new CustomerEntity(customer)}
          isEdition={true}
        />
      : <></>}
    </div>
  );
};

export default CustomerGeneral;
