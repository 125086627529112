import { configureStore } from "@reduxjs/toolkit";

import authenticationReducer from "./authentication-slice";
import applicationInterfaceReducer from "./application-interface-slice";
import navbarReducer from "./navbar-slice";
import blockedScheduleReducer from "./blocked-schedule-slice";
import multipleBlockedScheduleReducer from "./multiple-blocked-schedule-slice";
import schedulerReducer from "./scheduler-slice";
import alertsReducer from "./alerts-slice";
import commonHubReducer from "./common-hub-slice";
import whatsAppHubReducer from "./whatsapp-hub-slice";
import accountSettingsReducer from "./account-settings-slice";
import userInfoReducer from "./user-info-slice";
import cashRegisterReducer from "./cash-register-slice";
import scheduleAppointmentTimeReducer from "./schedule-appointment-time-slice";
import enterpriseReducer from "./enterprise-slice";
import fileDownloadReducer from "./file-downloader-slice";


const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    applicationInterface: applicationInterfaceReducer,
    navbar: navbarReducer,
    blockedSchedule: blockedScheduleReducer,
    multipleBlockedSchedule: multipleBlockedScheduleReducer,
    scheduler: schedulerReducer,
    alerts: alertsReducer,
    commonHub: commonHubReducer,
    whatsAppHub: whatsAppHubReducer,
    accountSettings: accountSettingsReducer,
    userInfo: userInfoReducer,
    cashRegister: cashRegisterReducer,
    scheduleAppointmentTime: scheduleAppointmentTimeReducer,
    enterprise: enterpriseReducer,
    fileDownloader: fileDownloadReducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;

export const selectAccountSettings = (state: RootState) => state.accountSettings;
export const selectUserInfo = (state: RootState) => state.userInfo;
export const selectCommonHubConnection = (state: RootState) => state.commonHub.connection;
export const selectCashRegisterState = (state: RootState) => state.cashRegister;
export const selectCashRegisterOpen = (state: RootState) => state.cashRegister.isOpen;

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
