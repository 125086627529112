import React from "react";

import { IProgressRingWithIconProps } from "./props";
import useStyles from "./css";

export default function ProgressRingWithIcon(props: IProgressRingWithIconProps) {
  
  const classes = useStyles();
  const { icon, fillPercent, color, ...otherProps } = props;
  
  const radius = 18;
  const circumference = radius * 2 * Math.PI;
  const offset = circumference - fillPercent / 100 * circumference;
  
  return (
    <div {...otherProps}>
      <div className={classes.ring}>
        <svg width={40} height={40} className={classes.svg}>
          <circle
            className={`${classes.circle}`}
            cx={20}
            cy={20}
            r={radius}
            stroke={"#F6F6F6"}
          />
          <circle
            className={`${classes.circle}`}
            cx={20} 
            cy={20} 
            r={radius}
            strokeDashoffset={offset}
            strokeDasharray={`${circumference} ${circumference}`}
            stroke={color}
          />
        </svg>
      </div>
      <div className={classes.iconContainer}>
        {icon}
      </div>
    </div>
  );
}