import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";


const OxxoIcon = (props: any) => {

  const { style, color, ...otherProps } = props;
  
    return (
        <SvgIcon 
          style={style}
          {...otherProps}
        >
            <path 
              fillRule="evenodd" 
              clipRule="evenodd" 
              d="M2 7.64699C2 7.2911 2.2911 7 2.6471 7H22.1327C22.4886 7 22.7798 7.2911 22.7798 7.64699V15.9302C22.7798 16.2862 22.4886 16.5773 22.1327 16.5773H2.6471C2.29121 16.5773 2 16.2862 2 15.9302V7.64699ZM2 7.94141H22.7797V15.638H2V7.94141ZM19.6101 9.93945C18.6594 9.93945 17.8844 10.7146 17.8844 11.6653C17.8844 12.6156 18.6594 13.3909 19.6101 13.3909C20.5606 13.3909 21.3357 12.6156 21.3357 11.6653C21.3357 10.7146 20.5606 9.93945 19.6101 9.93945ZM5.38626 9.93945C4.43541 9.93945 3.66037 10.7146 3.66037 11.6653C3.66037 12.6156 4.43552 13.3909 5.38626 13.3909C6.33664 13.3909 7.11179 12.6156 7.11179 11.6653C7.11179 10.7146 6.33664 9.93945 5.38626 9.93945ZM19.4749 8.41991C17.8773 8.38313 17.1264 9.40765 16.0361 10.6711L15.0081 11.8628L16.6363 13.812C17.024 14.4109 16.2837 15.0054 15.8244 14.4574L14.319 12.6616L12.8522 14.3622C12.3849 14.9035 11.653 14.2988 12.0492 13.7054L13.6417 11.8536L12.5984 10.609L13.2695 9.77784L14.3307 11.0524L15.2454 9.98842C15.6941 9.46676 16.1518 8.77556 16.7451 8.42002H2V15.1566H5.4474C7.04512 15.1566 7.77038 14.2013 8.83096 12.9131L9.83158 11.6976L8.15843 9.78659C7.75714 9.19671 8.48346 8.58555 8.95546 9.12282L10.5017 10.8836L11.9296 9.14954C12.3839 8.59738 13.1295 9.18536 12.7474 9.78766L11.1976 11.6759L12.2694 12.8961L11.594 13.6958L10.5272 12.4925L9.63744 13.5771C9.20068 14.1089 8.7586 14.7873 8.17368 15.1564H22.7796V8.41991H19.4749ZM8.11255 11.6645C8.11255 13.1662 6.88799 14.3909 5.38627 14.3909C3.88421 14.3909 2.65964 13.1664 2.65964 11.6645C2.65964 10.1625 3.88409 8.9379 5.38627 8.9379C6.88799 8.9379 8.11255 10.1625 8.11255 11.6645ZM19.6102 14.3909C18.1084 14.3909 16.8835 13.1664 16.8835 11.6645C16.8835 10.1625 18.1083 8.9379 19.6102 8.9379C21.1121 8.9379 22.3366 10.1625 22.3366 11.6645C22.3366 13.1662 21.1121 14.3909 19.6102 14.3909Z" 
              fill={color || style?.color || "#919CA5"} 
            />
        </SvgIcon>
    )
};

export default OxxoIcon;
