export class EmergencyContact {
  id?: string;
  name: string | null;
  relationship: string | null;
  mobileCodeIso3: string | null;
  mobileCode: number | null;
  mobileDigits: number | null;
  mobileTemplate: string | null;
  mobile: string | null;
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.name = x.name;
      this.relationship = x.relationship;
      this.mobileCodeIso3 = x.mobileCodeIso3;
      this.mobileDigits = x.mobileDigits;
      this.mobileCode = x.mobileCode;
      this.mobileTemplate = x.mobileTemplate;
      this.mobile = x.mobile;
    } else {
      this.name = null;
      this.relationship = null;
      this.mobileCodeIso3 = null; //"MEX";
      this.mobileDigits = 10;
      this.mobileCode = 52;
      this.mobileTemplate = "000-000-0000";
      this.mobile = null;
    }
  }
}
