import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PlusIcon = (props: any) => {

  const { style, viewBox, color, disabled, ...otherProps } = props;
  
  return (
  <SvgIcon
    {...otherProps}
    style={style || { width: 16, height: 16, opacity: disabled ? 0.3 : 1 }}
    viewBox={viewBox || "0 0 16 16"}
  >
    <path
      d="M16 8C16 7.63624 15.8555 7.28737 15.5983 7.03015C15.341 6.77293 14.9922 6.62843 14.6284 6.62843L9.37157 6.62843L9.37157 1.37157C9.37157 1.00781 9.22706 0.658943 8.96984 0.401724C8.71262 0.144504 8.36376 -3.33791e-07 8 -3.49691e-07C7.63623 -3.65592e-07 7.28737 0.144504 7.03015 0.401723C6.77293 0.658943 6.62843 1.00781 6.62843 1.37157L6.62843 6.62843L1.37157 6.62843C1.00781 6.62843 0.658944 6.77293 0.401725 7.03015C0.144505 7.28737 6.19884e-07 7.63624 6.03983e-07 8C5.88083e-07 8.36376 0.144505 8.71263 0.401724 8.96985C0.658944 9.22707 1.00781 9.37157 1.37157 9.37157L6.62344 9.37157L6.62344 14.6284C6.62344 14.9922 6.76794 15.3411 7.02516 15.5983C7.28238 15.8555 7.63124 16 7.99501 16C8.35877 16 8.70764 15.8555 8.96485 15.5983C9.22207 15.3411 9.36658 14.9922 9.36658 14.6284L9.36658 9.37656L14.6284 9.37656C14.809 9.37656 14.9877 9.34092 15.1545 9.27168C15.3212 9.20244 15.4726 9.10096 15.6 8.97307C15.7275 8.84518 15.8284 8.69339 15.897 8.52641C15.9657 8.35943 16.0006 8.18054 16 8Z"
      fill={color ?? style?.color}
    />
  </SvgIcon>
  );
}

export default PlusIcon;
