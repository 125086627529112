import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center"
  },
  page: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center"
  },
  wrapper: {
    position: "relative",
    width: "100%",
    maxWidth: 996,
    minWidth: 812,
    height: 556,
    minHeight: 315,
    display: "flex",
    justifyContent: "center",
    backgroundColor: "#fff",
    boxShadow: "15px 30px 80px #F2F3F7"
  },
  formWrapper: {
    display: "flex",
    flexDirection: "column",
    width: "370px",
    justifyContent: "center"
  },
  logo: {
    display: "flex",
    alignSelf: "center",
    width: 186,
    height: 80,
    justifyContent: "center",
    alignItems: "center"
  },
  ornamentLeft: {
    display: "block",
    position: "absolute",
    bottom: 0,
    left: 0
  },
  ornamentRight: {
    display: "block",
    position: "absolute",
    bottom: 0,
    right: 0
  },
  formFooter: {
    position: "absolute",
    bottom: 37,
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "11px",
    lineHeight: "13px",
    color: theme.colors.grey4,
    "& a": {
      color: theme.colors.grey4,
      fontWeight: "bold"
    }
  },
  backButton: {
    position: "absolute",
    left: 40,
    top: 20,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    width: 89,
    height: 40,
    color: theme.colors.clearMain,
    zIndex: 1,
    "&:focus": {
      textDecoration: "none",
      color: theme.colors.clearMain
    },
    "&:hover": {
      textDecoration: "none",
      color: theme.colors.clearMain
    }, 
    "&:visited": {
      textDecoration: "none",
      color: theme.colors.clearMain
    },
     "&:link" : {
       textDecoration: "none",
       color: theme.colors.clearMain
     }, 
    "&:active": {
      textDecoration: "none",
      color: theme.colors.clearMain
    }
  },
  backButtonText: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
    lineHeight: 15,
  },
  backButtonIcon: {
    fontSize: 12,
    lineHeight: 15,
  }
}));

export default useStyles;