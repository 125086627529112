import React, { useRef, useState } from "react";

import { Popper } from "@material-ui/core";

import { IconTooltipProps } from "./props";
import useStyles from "./css";

export default function IconTooltip(props: IconTooltipProps) {
  
  const [open, setOpen] = useState<boolean>(false);
  const anchorEl = useRef<any>(null);
  
  const { icon, tooltipText, classNameRoot, placement, arrowPlacement } = props;
  
  const classes = useStyles();
  
  const tooltipComponent = 
    <div className={classes.box}>
      {tooltipText}
    </div>
  
  function handleOpenPopper(event: any) {
    anchorEl.current = event.currentTarget;
    setOpen(true);
  }
  
  function handleClosePopper(event: any) {
    anchorEl.current = null;
    setOpen(false);
  }

  function getArrowPlacementClass(arrowPlacement: string) {
    switch (arrowPlacement) {
      case "bottom":
        return classes.arrowBottom;
      case "left":
        return classes.arrowLeft;
      case "right":
        return classes.arrowRight;
      default:
        return classes.arrowBottom;
    }
  }
  
  return (
    <div className={`${classes.root} ${classNameRoot ?? ""}`}>
      <div
        className={classes.icon}
        onMouseEnter={handleOpenPopper}
        onMouseLeave={handleClosePopper}
      >
        {icon}
      </div>
      <Popper 
        open={open}
        anchorEl={anchorEl.current}
        placement={placement || "top"}
        className={classes.box}
        disablePortal
      >
        <div className={getArrowPlacementClass(arrowPlacement || "bottom")} ref={anchorEl} />
        <div>
          {tooltipText}
        </div>
      </Popper>
    </div>
  );
}