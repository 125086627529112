import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #dbdcdd",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    
    width: 415,
    maxHeight: 500,
  
    overflowY: "auto",
    
    "&::-webkit-scrollbar": {
      backgroundColor: theme.colors.white,
      width: 16,
    },
    "&::-webkit-scrollbar-track": {
      backgroundColor: theme.colors.white,
    },
    "&::-webkit-scrollbar-track:hover": {
      backgroundColor: "#F4F4F4",
      width: 16,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#babac0",
      
      border: "solid",
      borderRadius: 16,
      borderWidth: 5,
      borderColor: theme.colors.white,      
    },
    "&::-webkit-scrollbar-thumb:hover": {
      backgroundColor: "#a0a0a5",
    
      border: "solid",
      borderWidth: 4,
      borderColor: theme.colors.white,
    },
    "&::-webkit-scrollbar-button": {
      display: "none",
    },
  },
  content: {
    display: "flex",
    flexDirection: "column",
  
    paddingTop: 2,
    paddingLeft: 15,
    paddingRight: 10,
    paddingBottom: 16,
  },
  modalHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    
    position: "sticky",
    top: 0,
    
    width: "100%",
    height: 71,
  
    paddingLeft: 15,
    paddingTop: 23,
    paddingRight: 10,
    paddingBottom: 22,
  
    backgroundColor: theme.palette.background.paper,
    
    borderTop: "solid",
    borderTopWidth: 1,
    borderTopColor: "#dbdcdd",
    borderTopRadius: "10px",
  },
  modalTitle: {
    ...theme.typography.txtBody1,
    fontWeight: 700,
    color: theme.colors.grey5,
  },
  closeButton: {
    cursor: "pointer",
    color: "#919CA5",
  },
  blockers: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  blocker: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    width: 374,
    height: 68,
    
    paddingTop: 9,
    paddingLeft: 8,
    
    backgroundColor: theme.colors.grey2,
    borderRadius: 4,
    boxShadow: "0px 0px 5px rgba(0, 0, 0, 0.25)",
    
    cursor: "pointer",
  },
  blockerReason: {
    ...theme.typography.caption1,
    fontWeight: 700,
    color: "rgba(50, 57, 71, 0.76)"
  },
  blockerTimeRow: {
    marginTop: 2,
    
    ...theme.typography.caption2,
    color: theme.colors.grey5,
  },
  blockerSpecialist: {
    marginTop: 4,
    
    ...theme.typography.caption2,
    fontWeight: 700,
    color: "rgba(50, 57, 71, 0.76)",
  },
}));

export default useStyles;
