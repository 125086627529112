import React from "react";
import { BanIcon, CalendarIcon } from "../../../../assets/icons";
import SMenu from '../mui/SMenu';
import { SMenuItem2 } from '../mui/SMenuItem';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useHistory } from 'react-router-dom';
import {
    IScheduleAppointmentActionsMenuContext,
    ScheduleAppointmentActionsMenuContext,
} from './context';
import { Url } from "../../../../constants/url";
import { blockedScheduleActions } from "../../../../redux/blocked-schedule-slice";
import { useAppDispatch } from "../../../../redux/hooks";
import { scheduleAppointmentTimeActions } from "../../../../redux/schedule-appointment-time-slice";

type ScheduleAppointmentActionsMenuProps = {
    anchorEl: HTMLElement | null;
    onClose: () => void;
    addAbsenceClick: React.MouseEventHandler;
};

export default function ScheduleAppointmentActionsMenu({
    anchorEl,
    addAbsenceClick,
    onClose,
}: ScheduleAppointmentActionsMenuProps) {
    const { t } = useTranslation(['general']);
    const history = useHistory();
    const dispatch = useAppDispatch();
    
    const handleNewAppointment = () => {
        dispatch(blockedScheduleActions.setShowMenu(false));
        history.push(`${Url.Appointments.Main}/new`);
    }
    
    return (
        <SMenu
            borders
            anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
            transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            id="scheduler-add-work-menu-keke"
            anchorEl={anchorEl}
            onClose={onClose}
            transitionDuration={150}
            open={!!anchorEl}
            keepMounted
        >
            <SMenuItem2 onClick={handleNewAppointment}>
                <span style={{marginRight: 10, paddingLeft: 2}}>
                  <CalendarIcon />
                </span>
                {t('Create new appointment')}
            </SMenuItem2>
            <SMenuItem2 onClick={addAbsenceClick}>
                <span style={{marginRight: 10}}>
                  <BanIcon />
                </span>
                {t('Block schedule')}
            </SMenuItem2>
        </SMenu>
    );
}

export function ScheduleAppointmentActionsMenuContextProvider({
    children,
}: React.PropsWithChildren<{}>) {
    
    const [state, setState] = useState<{anchorEl: HTMLElement;} | null>(null);
    useEffect(() => {
        return () => state?.anchorEl.remove();
    }, [state?.anchorEl]);
    
    const dispatch = useAppDispatch();

    const addAbsence = useCallback(() => {
        if (!state) return;
        dispatch(blockedScheduleActions.setShowModal(true));
        // close the menu after 200ms to give the schedule absence popup some time to position iteself in the right place before
        // removing anchor element
        setTimeout(() => {
            setState(null);
        }, 200);
    }, [/*scheduleAbsencePopup, */state]);

    const ctx: IScheduleAppointmentActionsMenuContext = useMemo(
        () => ({
            open: (dt, userId, x, y) => {
                // create temporary anchor element to avoid menu acting weird if original anchor disappears
                const el = document.createElement('div');
                el.style.position = 'fixed';
                el.style.left = `${x}px`;
                el.style.top = `${y}px`;
                el.style.width = el.style.height = '1px';
                document.body.appendChild(el);

                setState({
                    anchorEl: el,
                });
    
                dispatch(scheduleAppointmentTimeActions.setAppointmentTime(dt.toJSDate()));
                dispatch(scheduleAppointmentTimeActions.setClickNewAppointmentOnScheduler(true));
                dispatch(blockedScheduleActions.setDate(dt.toJSDate()));
                dispatch(blockedScheduleActions.setUserId(userId));
            },
        }),
        []
    );

    return (
        <>
            <ScheduleAppointmentActionsMenuContext.Provider value={ctx}>
                {children}
            </ScheduleAppointmentActionsMenuContext.Provider>
            <ScheduleAppointmentActionsMenu
                anchorEl={state ? state.anchorEl : null}
                addAbsenceClick={addAbsence}
                onClose={() => setState(null)}
            />
        </>
    );
}
