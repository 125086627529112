import { makeStyles } from "@material-ui/core/styles";

import { Color } from "../../../../../constants/colors";

export const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: 10,
        paddingTop: 5
    },
    headerRight: {
        textAlign: "right"
    },
    button: {
        width: 200,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        textTransform: "none"
    },
    btnNewWorkArea: {
        background: "#7175FA",
        color: "#F6F6F6",
        "&:hover": {
            background: "#6462F3",
        },
    },
    titles: {
        color: Color.gray4,
        fontSize: 12,
        fontWeight: "bold",
        marginBottom: 10
    },
    icons: {
        color: Color.gray5,
        fontSize: 18
    },
    icon: {
        paddingTop: 5,
        height: 30,
        width: 30,
        fontWeight: "bold"
    },
    noteHeader: {
        display: "flex",
        alignItems: "center",
        marginBottom: 15,
        gap: 20,
        width: "100%",
        justifyContent: "space-between",
    },
    tabTitle: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: Color.gray5
    },
}));


export default useStyles;
