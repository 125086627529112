import React, { useEffect } from "react";

import { CheckboxWithLabelProps } from "./props";
import useStyles from "./css";
import { Checkbox } from "@material-ui/core";


export default function CheckboxWithLabel(props: CheckboxWithLabelProps) {
  
  const { checked, setChecked, labelClass, disableRippleEffect, label, disabled, checkboxClass } = props;
  const classes = useStyles();
  
  function handleChecking() {
    setChecked(!checked);
  }
  
  return (
    <div className={`${classes.root} ${disabled ? classes.disabledCheckbox : ""}`}>
      <Checkbox
        disableRipple={disableRippleEffect ?? false}
        checked={checked}
        disabled={disabled}
        onChange={handleChecking}
        className={checkboxClass ?? classes.checkbox}
        size={"small"}
      />
      {typeof label === "string"
        ? <div className={`${labelClass ?? classes.text} ${checked ? classes.textChecked : ""}`}>{label}</div>
        : label ?? null
      }
    </div>
  );
}