import { AppointmentPricedService } from "./appointment-priced-service";
import { PaymentEditionType } from "./enums/payment-edition-type";
import { AppointmentPaymentPartEntity } from "./appointment-payment-part-entity";

export class AppointmentPaymentEntity {
  id: string | null;
  friendlyId: number | null;
  date: Date;
  parts: AppointmentPaymentPartEntity[];
  editingPart: AppointmentPaymentPartEntity | null;
  notes: string;
  services: AppointmentPricedService[];
  isEditable: boolean;
  editionType: PaymentEditionType | null;
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.friendlyId = x.friendlyId;
      this.date = x.date;
      this.parts = x.parts;
      this.editingPart = x.editingPart;
      this.notes = x.notes;
      this.services = x.services;
      this.isEditable = x.isEditable;
      this.editionType = null;
    } else {
      this.id = null;
      this.friendlyId = null;
      this.date = new Date();
      this.parts = [new AppointmentPaymentPartEntity()]
      this.editingPart = null
      this.notes = "";
      this.services = [];
      this.isEditable = true;
      this.editionType = null;
    }
  }
}