import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    button: {
        width: 200,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #dbdcdd",
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: "40px 40px 48px",
        width: 812,
        height: 399,
    },
    modalHeader: {
        display: "flex",
        alignItems: "center",
    },
    modalTitle: {
        width: "100%",
    },
    modalTitleText: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#5C6477",
        
    },
    modalTitleTextCancel: {
        marginTop: 0,
        marginBottom: 20,
        fontSize: 18,
        fontWeight: "bold"
    },
    modalActions: {
        display: "flex",
        justifyContent: "flex-end",
    },
    goBack: {
        marginRight: 16,
        background: "#F6F6F6",
        color: "#ACB7C0",
    },
    createCustomer: {
        background: "#36CE91",
        color: "#F6F6F6",
        "&:hover": {
            background: "#36CE91",
        },
    },
    createDisabled: {
        color: "#F6F6F6 !important",
        opacity: "0.4",
    },
    modalPaper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid red",
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: "24px",
        width: 464,
        minHeight: 288,
    },
    modalCancelPaper: {
        backgroundColor: theme.palette.background.paper,
        // border: "1px solid red",
        borderRadius: "12px",
        boxShadow: theme.shadows[5],
        padding: "24px",
        width: 472,
        minHeight: 153,
    },
    modalClose: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
    },
    closeButton: {
        cursor: "pointer",
        color: "#919CA5",
    },
    modalContent: {},
    modalCircle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        border: "1px solid #F15857",
        width: "50px",
        height: "50px",
    },
    modalCenter: {
        display: "flex",
        justifyContent: "center",
    },
    modalTitleSpace: {
        margin: "24px 0px 8px 0px",
    },
    modalContentText: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "17px",
        textAlign: "center",
        color: "#4A4D51",
    },
    cancelConfirmation: {
        background: theme.colors.clearGreen,
        color: "#F6F6F6",
        "&:hover": {
            background: theme.colors.clearGreen,
        },
    }
}));

export default useStyles;
