import React, { useState } from "react";

import { InputAdornment, TextField, Typography } from "@material-ui/core";
import IconButton from "@material-ui/core/IconButton";
import { Visibility, VisibilityOff } from "@material-ui/icons";

import useStyles from "./css";
import { PasswordInputProps } from "./props";


export default function PasswordInput(props: PasswordInputProps) {
  
  const classes = useStyles();

  const [isPasswordVisible, setPasswordVisibility] = useState<boolean>(false);

  function handlePasswordVisibility() {
    setPasswordVisibility(!isPasswordVisible);
  }

  function handleMouseDownPassword(event: any) {
    event.preventDefault()
  }
  
  const TogglePasswordVisibilityIcon =
    <IconButton
      aria-label="toggle password visibility"
      onClick={handlePasswordVisibility}
      onMouseDown={handleMouseDownPassword}
      edge="end"
    >
      {isPasswordVisible ? <Visibility/> : <VisibilityOff/>}
    </IconButton>
  
  return (
    <>
      <Typography className={classes.label}>
        {props.label} {props.required && <span className={classes.required}>*</span>}
      </Typography>
      <TextField
        className={classes.textInput}
        variant="outlined"
        value={props.value}
        onChange={props.onChangeHandler}
        placeholder={props.placeholder}
        type={isPasswordVisible ? "text" : "password"}
        InputProps={{
          classes: {
            root: props.success ? classes.inputSuccess : `${classes.input} ${props.value.length > 0 ? classes.inputBoldWeight : classes.inputNormalWeight}`,
            notchedOutline: props.success ? classes.outlineSuccess : classes.outline
          },
          endAdornment: (
            <InputAdornment position="end">
              {TogglePasswordVisibilityIcon}
            </InputAdornment>
          )
        }}
      />
    </>
  );
}