import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  customerData: {
    width: 256,
  },
  content: {
    border: "1px solid #DBDCDD",
    borderRadius: 12,
    boxSizing: "border-box",
    padding: 16,
    height: 417,
    boxShadow: "none",
  },
  goBackButton: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    height: 32,
  },
  goBackButtonText: {
    margin: "0px 0px 0px 17px",
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: 12,
    lineHeight: "15px",
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    color: "#786EFF",
  },
  customerStatus: {
    display: "flex",
    alignItems: "center",
    margin: "24px 0px 0px 0px",
  },
  titleText: {
    ...theme.typography.header4,
    fontWeight: "bold",
    color: theme.colors.grey5,
  },
  contentText: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
  },
  contentBoldText: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey4,
  },
  customerName: {
    margin: "9px 0px 0px 0px",
  },
  customerAge: {
    margin: "4px 0px 0px 0px",
  },
  separator: {
    height: 0,
    borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    margin: "16px 0px 16px 0px",
  },
  appointments: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  appointmentsContent: {
    width: 104,
  },
  appointmentsContentDoubleSpace: {
    width: 208,
  },
  service: {
    display: "flex",
    alignItems: "center",
    marginTop: 4,
    marginBottom: 4,
  },
  serviceText: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
  },
  dateRegistered: {
    display: "flex",
    alignItems: "center",
    margin: "16px 0px 0px 0px",
  },
  dateRegisteredText: {
    margin: "0px 0px 0px 9px",
  },
}));

export default useStyles;
