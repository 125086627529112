import { SectionFieldType } from "./enums/section-field-type";
import { SectionFieldItemModel } from "./section-field-item-model";

export class SectionFieldUpdateRequest {
  establishmentAccountId?: string|null;
  id: string;
  sectionId: string;
  name: string;
  type: SectionFieldType;
  isRequired: boolean;
  isAdditionalTextareaEnabled: boolean;
  items: SectionFieldItemModel[];

  constructor(x?: any) {
    if (typeof x === "object") {
      this.establishmentAccountId = x.establishmentAccountId;
      this.id = x.id;
      this.sectionId = x.sectionId;
      this.name = x.name;
      this.type = x.type;
      this.isRequired = x.isRequired;
      this.isAdditionalTextareaEnabled = x.isAdditionalTextareaEnabled;
      this.items = x.items;
    } else {
      this.establishmentAccountId = null;
      this.id = "";
      this.sectionId = "";
      this.name = "";
      this.type = 11;
      this.isRequired = false;
      this.isAdditionalTextareaEnabled = false;
      this.items = [];
    }
  }
}