import React from "react";
import { MenuItem, styled } from '@material-ui/core';
import { rgba } from '../styles/ColorHelpers';

const SMenuItem = styled(MenuItem)(({ theme }) => ({
    ...theme.typography.header6,
    flexWrap: 'wrap',
    fontWeight: 'normal',
    padding: '8px 14px',
}));

export const SMenuItem2 = styled(SMenuItem)(({ theme }) => ({
    minHeight: 32,
    fontFamily: "Inter",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: 14,
    color: theme.colors.grey5,
    padding: 5,
    cursor: "pointer",
    position: "relative",
    width: 240,
    textAlign: "left",
    paddingLeft: 15,
    height: 35,
    '&:not(:hover)': {
        backgroundColor: "white",
    },
    '&:hover': {
        backgroundColor: "#E1E9FE",
    },
}));

export default SMenuItem;
