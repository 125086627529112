import React from "react";
import { useTranslation, Trans } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import { BroadcastIcon, CrossIcon } from "../../../assets/icons";

import { TimeIsBusyAnotherAppointmentCanNotContinueModalProps } from "./props";
import useStyles from "./css";


const TimeIsBusyAnotherAppointmentCanNotContinueModal = (props: TimeIsBusyAnotherAppointmentCanNotContinueModalProps) => {

    const { t } = useTranslation(["general"]);
    const { open, onClose } = props;
    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modalYellow}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>

            <Fade in={open}>
                <div className={classes.modalPaperYellow}>
                    <div className={classes.modalHeader}>
                        <div className={classes.modalHeaderContent}>
                            <div className={classes.modalCircle}>
                                <BroadcastIcon  />
                            </div>
                        </div>
                        <div className={classes.modalClose}>
                            <span className={classes.closeButton} onClick={onClose}>
                                <CrossIcon />
                            </span>
                        </div>
                    </div>

                    <div className={classes.modalContent}>

                        <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`} >
                            <Typography className={classes.modalTitleText}>
                                {t("There's another appointment scheduled at the same time")}
                            </Typography>
                        </div>
                        
                        <div className={classes.separator}></div>

                        <div className={classes.bodyContent}>
                            <div>
                                {t("The selected specialist is not available because they already have another appointment scheduled at this time.")}
                            </div>
                            <br/>
                            <div>
                                <Trans ns={["general"]} i18nKey="We recommend activating the Schedule simultaneous appointments switch button so that your specialists can attend appointments at the same time.">
                                    We recommend activating the "<a href='/settings'>Schedule simultaneous appointments</a>" switch button so that your specialists can attend appointments at the same time.
                                </Trans>
                            </div>
                        </div>

                        <div className={classes.modalActions}>
                            <Button className={`${classes.button} ${classes.btnOk}`} onClick={onClose} >
                                {t("Understood")}
                            </Button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default TimeIsBusyAnotherAppointmentCanNotContinueModal;
