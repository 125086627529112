export class CustomersClassification {
  total: number | null;
  frequent: number | null;
  infrequent: number | null;
  inactive: number | null;

  constructor(x?: any) {
    if (typeof x === "object" && x != null) {
      this.total = x.total;
      this.frequent = x.frequent;
      this.infrequent = x.infrequent;
      this.inactive = x.inactive;
    } else {
      this.total = null;
      this.frequent = null;
      this.infrequent = null;
      this.inactive = null;
    }
  }
}
