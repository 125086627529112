import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    margin: "7px 0px 0px 0px",
  },
  classification: {
    width: 104,
    margin: "0px 25px 0px 0px",
  },
  titles: {
    ...theme.typography.txtBody2,
    fontWeight: "bold",
    color: theme.colors.grey4,
  },
  icons: {
    ...theme.typography.header4,
    color: theme.colors.grey5,
    display: "flex",
    alignItems: "center",
    margin: "7px 0px 0px 0px",
  },
}));

export default useStyles;
