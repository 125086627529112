import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    width: '100%',
    gap: 2,
    color: theme.colors.grey5,
    justifySelf: 'stretch',
  },
  popper: {
    [`& .MuiTooltip-tooltip`]: {
      padding: 8,
      borderRadius: 5,
      backgroundColor: theme.colors.grey1,
      ...theme.typography.txtBody2,
      color: theme.colors.grey5,
      boxShadow: "0px 4px 4px 0px rgba(166, 166, 166, 0.25)",
      marginLeft: 4,
    },
    [`& .MuiTooltip-arrow`]: {
      color: theme.colors.grey1,
    },
  }
}));

export default useStyles;
