import { ApiClient } from "./api-client";
import { AccountSettingsModel } from "../models/interfaces/account-settings-model";
import { TerminologyType } from "../models/enums/terminology-type";
import { Terminology } from "../models/terminology";
import { AccountScheduleSectionSettingsModel } from "../models/account-schedule-section-settings-model";
import { TreatmentDocumentFieldSettingsModel } from "../models/treatment-document-field-settings-model";

export class AccountService {
  private static baseUrl: string = "/account";

  static getAccountSettings(): Promise<AccountSettingsModel> {
    return ApiClient.get(`${this.baseUrl}/getAccountSettings`).then(
      (data) => data.accountSettings
    );
  }

   static async getAccountScheduleSectionSettings(establishmentAccountId?: string): Promise<AccountScheduleSectionSettingsModel> {
    var endpoint = `${this.baseUrl}/GetAccountScheduleSectionSettings`;

    if (establishmentAccountId)
        endpoint += `?AccountId=${establishmentAccountId}`;

    return await ApiClient.get(endpoint)
      .then((data) => new AccountScheduleSectionSettingsModel(data));
  }

  static async updateAccountScheduleSectionSettings(request: AccountScheduleSectionSettingsModel): Promise<AccountScheduleSectionSettingsModel> {
    return await ApiClient.put(`${this.baseUrl}/UpdateAccountScheduleSectionSettings`, request)
      .then((data) => new AccountScheduleSectionSettingsModel(data));
  }

  static getAccountCountryCode(establishmentAccountId?: string): Promise<string> {
    let endpoint = `${this.baseUrl}/getcountrycode`;
    if (establishmentAccountId) {
        endpoint += `?accountId=${establishmentAccountId}`;
    }
    return ApiClient.get(endpoint,)
      .then((data) => data.countryCode);
  }

  static getAccountOverdue(): Promise<string> {
    return ApiClient.get(`${this.baseUrl}/getaccountoverdue`,)
      .then((data) => data.isAccountOverdue);
  }

  static getAccountSubdomain(establishmentAccountId?: string): Promise<string> {
    let urlEndpoint = !!establishmentAccountId
      ? `${this.baseUrl}/getBusinessNameUrl?EstablishmentAccountId=${establishmentAccountId}`
      : `${this.baseUrl}/getBusinessNameUrl`;

    return ApiClient.get(urlEndpoint).then(
      (data) => {
        return data.businessNameUrl;
      });
  }

  static getAccountLanguage(): Promise<string> {
    return ApiClient.get(`${this.baseUrl}/getaccountlanguage`,)
      .then((data) => data.languageCode);
  }

  static getAccountTerminology(type: TerminologyType, establishmentAccountId?: string): Promise<Terminology> {
    let endpoint = `${this.baseUrl}/getaccountterminology?TerminologyType=${type}`;
    if (establishmentAccountId) {
        endpoint += `&accountId=${establishmentAccountId}`;
    }
    return ApiClient.get(endpoint,)
      .then((data) => new Terminology(data.terminology));
  }

    static updateAccountTerminology(terminology: Terminology, establishmentAccountId?: string): Promise<Terminology> {
    const request = { 
        accountId: establishmentAccountId,
        terminologyType: terminology.terminologyType,
        singularForm: terminology.singularForm,
        pluralForm: terminology.pluralForm
    }; 
    return ApiClient.post(`${this.baseUrl}/updateaccountterminology`, request)
      .then((data) => new Terminology(data.terminology));
  }

  static async getTreatmentDocumentFieldSettings(): Promise<TreatmentDocumentFieldSettingsModel> {
    return ApiClient.get(`${this.baseUrl}/GetTreatmentDocumentFieldSettings`)
      .then((data) => new TreatmentDocumentFieldSettingsModel(data));
  }

  static async updateTreatmentDocumentFieldSettings(request: TreatmentDocumentFieldSettingsModel): Promise<boolean> {
    const formData = new FormData();

    formData.append('DocumentSize', request.documentSize.toString());
    formData.append('LogoIsEnabled', request.logoIsEnabled ? 'true' : 'false');
    formData.append('ShouldRemoveLogo', request.shouldRemoveLogo ? 'true' : 'false');

    if (request.logoFile) {
      formData.append('LogoFile', request.logoFile, request.logoFile.name);
    }

    formData.append("TreatmentDocumentFieldSettingsJsonString", JSON.stringify(request.fieldSettings));

    return ApiClient.put(`${this.baseUrl}/UpdateTreatmentDocumentFieldSettings`, formData)
      .then((data) => data.succeeded);
  }
}
