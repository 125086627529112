import React, { useEffect, useState } from 'react';

import { ClinicalHistoryRadioGroupProps } from "./props";
import useStyles from "./css";
import PatientFormRadio from "../ClinicalHistoryRadio";
import useControlLabel from "../../../../hooks/useControlLabel";
import { useTranslation } from 'react-i18next';
import { SectionFieldItemModel } from '../../../../models/section-field-item-model';
import ClinicalHistoryFreeTextInput from '../ClinicalHistoryFreeTextInput';


export default function ClinicalHistoryRadioGroup(props: ClinicalHistoryRadioGroupProps) {

  const { field, value, selectedItem, setSectionFieldItem, setFreeTextValue, onTextAreaBlur } = props;
  const sectionTNamespace = "sections";
  const { t } = useTranslation(["general", sectionTNamespace]);
  const classes = useStyles();

  const ControlLabel = useControlLabel({ label: t(field.name, { ns: sectionTNamespace }), required: field.isRequired });

  const [initialTextAreaValue, setInitialTextAreaValue] = useState<string | null>(null);
  const [items, setItems] = useState<SectionFieldItemModel[]>(field.items.filter((i: SectionFieldItemModel) => {
    if (field.isDeleted)
      return true;
    else {
      return !i.isDeleted
    }
  }));

  useEffect(() => {
    if (!value || items.some(i => i.id === value.item.fieldItemId)) {
      return;
    }

    const itemsWithOldValue = [...items];
    itemsWithOldValue.unshift(new SectionFieldItemModel({ id: value.item.fieldItemId, name: value.item.value, order: 0 }))
    setItems(itemsWithOldValue);
  }, []);

  useEffect(() => {
    if (initialTextAreaValue !== null && value?.additionalFreeTextValue)
      return;
    setInitialTextAreaValue(value?.additionalFreeTextValue ?? null) 
  }, []);

  function itemAsRenderItem(item: SectionFieldItemModel) {
    return (
      <div>
        <PatientFormRadio
          key={item.name}
          value={t(item.name, { ns: sectionTNamespace })}
          checked={selectedItem !== null && selectedItem.id === item.id}
          onCheck={() => setSectionFieldItem(item)}
        />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <ControlLabel />
      <div className={classes.radios}>
        {
          items.sort((i1, i2) => i1.order - i2.order).map(itemAsRenderItem)
        }
      </div>
      {
        (value?.additionalFreeTextValue !== "" || field.isAdditionalTextareaEnabled) && setFreeTextValue
          ?
          <div className={classes.freeTextAreaContainer}>
            <ClinicalHistoryFreeTextInput
              key={field.id! + "freeTextInput"}
              value={value?.additionalFreeTextValue ?? ""}
              setValue={setFreeTextValue}
              onBlur={(textAreaValue) => {onTextAreaBlur && onTextAreaBlur(textAreaValue, initialTextAreaValue)}}
              placeholder={`${t("Write here your notes for")} ${t(field.name, { ns: sectionTNamespace })}`}
              maxLength={1500}
            />
          </div>
          :
          null
      }
    </div>
  );
}