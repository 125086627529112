import React from "react";
import clsx from 'clsx';
import moment from 'moment';
import { CSSProperties, useEffect, useState } from 'react';
import { useAppSelector } from "../../../../redux/hooks";
import {
    getGroupKeyOffsetVariable,
    useAppointmentSchedulerContext,
} from '../AppointmentsScheduler/_util';
import styles from './css.module.css';

type CursorProps = {
    groupKey?: string;
};

const now = () => {
    const m = moment();
    return m.hour() * 60 + m.minute() + m.second() / 60;
};

const noop = () => {};

export default function Cursor({ groupKey }: CursorProps) {
    const date =  useAppSelector((state) => state.scheduler.currentDate);
    const mode = useAppSelector((state) => state.scheduler.currentViewName);
    const { from, to, pixelsPerMinute } = useAppointmentSchedulerContext();
    const [element, setElement] = useState<HTMLDivElement | null>(null);

    useEffect(() => {
        if (!element) return noop;

        if (!moment().isSame(moment(date), mode === 'Week' ? 'w' : 'd')) {
            element.style.display = 'none';
            return noop;
        }

        const millisecondsPerPixel = (1 / pixelsPerMinute) * 60 * 1000;
        const intervalTime = Math.max(500, millisecondsPerPixel);
        const update = () => {
            const value = now();

            if (value < from || value > to) {
                element.style.display = 'none';
            } else {
                if (element.style.display === 'none') element.style.display = 'block';
                const pos = (value - from) * pixelsPerMinute;
                element.style.setProperty('--pos', `${pos}px`);
            }
        };
        update();
        const interval = setInterval(update, intervalTime);
        return () => clearInterval(interval);
    }, [element, from, to, pixelsPerMinute, date, mode]);

    return (
        <div
            className={clsx(styles.cursor, !groupKey && styles.full)}
            style={
                groupKey
                    ? ({
                          '--offset': `var(${getGroupKeyOffsetVariable(groupKey)})`,
                      } as CSSProperties)
                    : undefined
            }
            ref={setElement}
        />
    );
}
