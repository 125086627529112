import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function CalendarCheckmarkIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={style || { width: 12, height: 12 }}
            viewBox={viewBox || "0 0 12 12"}
        >
            <path d="M9.3125 4.5C9.40625 4.5 9.5 4.42188 9.5 4.3125V3.75C9.5 3.34375 9.15625 3 8.75 3H8V2.1875C8 2.09375 7.90625 2 7.8125 2H7.1875C7.07813 2 7 2.09375 7 2.1875V3H5V2.1875C5 2.09375 4.90625 2 4.8125 2H4.1875C4.07813 2 4 2.09375 4 2.1875V3H3.25C2.82813 3 2.5 3.34375 2.5 3.75V4.3125C2.5 4.42188 2.57813 4.5 2.6875 4.5H9.3125ZM2.6875 5C2.57813 5 2.5 5.09375 2.5 5.1875V9.25C2.5 9.67188 2.82813 10 3.25 10H8.75C9.15625 10 9.5 9.67188 9.5 9.25V5.1875C9.5 5.09375 9.40625 5 9.3125 5H2.6875ZM7.89063 6.5C7.95313 6.57813 7.95313 6.70313 7.89063 6.76563L5.65625 8.98438C5.57813 9.0625 5.45313 9.0625 5.39063 8.98438L4.09375 7.6875C4.03125 7.60938 4.03125 7.5 4.09375 7.42188L4.54688 6.98438C4.60938 6.90625 4.73438 6.90625 4.8125 6.98438L5.53125 7.70313L7.1875 6.0625C7.25 5.98438 7.375 5.98438 7.45313 6.0625L7.89063 6.5Z"
                fill={style?.color || "#7175FA"} />
        </SvgIcon>
    );
};