import React from "react";
import { Link } from "react-router-dom";

import { Box, Typography } from "@material-ui/core";
import { ArrowBackIos } from "@material-ui/icons";

import { useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from "../../../assets/images/logo.svg";
import { ReactComponent as OrnamentLeft } from "../../../assets/images/ornament-left.svg";
import { ReactComponent as OrnamentRight } from "../../../assets/images/ornament-right.svg";
import useStyles from "./css";
import { AuthenticationLayoutProps } from "./props";

import { Url } from "../../../constants/url";

export default function AuthenticationLayout(props: AuthenticationLayoutProps) {

  const { t } = useTranslation(["general"]);
  const classes = useStyles();

  const getCurrentYear = () =>{
    return new Date().getFullYear();
  }

  return (
    <div className={classes.container}>
      <div className={classes.page}>
        <div className={classes.wrapper}>
          {props.backButton &&
          <Link to={Url.Authentication.Login} className={classes.backButton}>
            <Typography className={classes.backButtonText}><ArrowBackIos className={classes.backButtonIcon} /> {t("Back")}</Typography>
          </Link>
          }
          <div className={classes.formWrapper}>
            {!props.hiddenLogo &&
              <div className={classes.logo}>
                <Logo />
              </div>
            }
            <div>
              {props.children}
            </div>
          </div>
          <div className={classes.ornamentLeft}>
            <OrnamentLeft />
          </div>
          <div className={classes.ornamentRight}>
            <OrnamentRight />
          </div>
        </div>
          <div className={classes.formFooter}>
            © {getCurrentYear()} {t('ClearCheck and its affiliate')}. | {t('Visit us at')}: <a href="https://www.somosclear.com" target="_blank">somosclear.com</a>
          </div>
        </div>
      </div>
  )
}