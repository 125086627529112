import React, { useEffect, useRef, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { TimePicker } from "antd";
import moment from "moment";

import DateFnsUtils from "@date-io/date-fns";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import Switch from "../../common/Switch";
import Input from "../../common/Input";
import { CalendarIcon } from "./../../../assets/icons";
import { AppointmentService } from "./../../../api/appointment-service";
import { CreateAppointmentModel } from "../../../models/create-appointment-model";
import { CreateAppointmentService } from "../../../models/create-appointment-service";
import { ReactComponent as CalendarNoTime } from "./../../../assets/images/calendar-notime.svg";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { blockedScheduleActions } from "../../../redux/blocked-schedule-slice";

import { AppointmentStepTwoProps } from "./props";
import useStyles from "./css";
import SelectWithLabel, { SelectItem } from "../../common/SelectWithLabel";
import { AppointmentRecurrenceInterval } from "../../../models/enums/appointment-recurrence-interval";
import { scheduleAppointmentTimeActions } from "../../../redux/schedule-appointment-time-slice";
import { schedulerActions } from "../../../redux/scheduler-slice";
import CustomRecurrenceModal from "../CustomRecurrenceModal";
import { CustomRecurrenceMode } from "../../../models/enums/custom-recurrence-mode";
import { RecurrenceModalData } from "../../../models/recurrence-modal-data";
import { WeekDaysFlags } from "../../../models/enums/week-days-flags";
import { selectAccountSettings } from "../../../redux/store";

const AppointmentStepTwo = (props: AppointmentStepTwoProps) => {
  const { appointment, step1Valid, isEdition, refreshData, onChange, onRecurringChange } = props;
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  const [date, setDate] = useState<Date>(new Date());
  const [availableHours, setAvailableHours] = useState<string[]>([]);
  const [isAvailableHoursLoad, setIsAvailableHoursLoad] = useState<boolean>(false);
  const [schedule, setSchedule] = useState<string>();
  const [services, setServices] = useState<CreateAppointmentService[]>();
  const dispatch = useAppDispatch();
  const { setDaysOff } = schedulerActions;
  const daysOff = useAppSelector((state) => state.scheduler.daysOff);
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const scheduleAppointmentTime = useAppSelector(state => state.scheduleAppointmentTime.appointmentTime);
  const isNewAppointmentViaScheduler = useAppSelector(state => state.scheduleAppointmentTime.newAppointmentViaScheduler);
  const [desiredDate, setDesiredDate] = useState<Date | null>(useAppSelector(state => state.blockedSchedule.date));
  const accountSettings = useAppSelector(selectAccountSettings);

  const [isRecurringAppointment, setIsRecurringAppointment] = useState<boolean>(false);
  const [customRecurrenceModalIsOpen, setCustomRecurrenceModalIsOpen] = useState<boolean>(false);
  const [isCustomRecurrence, setIsCustomRecurrence] = useState<boolean>(false);
  const [customRecurrenceInterval, setCustomRecurrenceInterval] = useState<number | null>(null);
  const [customRecurrenceMode, setCustomRecurrenceMode] = useState<CustomRecurrenceMode | null>(null);
  const [customRecurrenceAfterAppointments, setCustomRecurrenceAfterAppointments] = useState<number | null>(null);
  const [customRecurrenceWeekDays, setCustomRecurrenceWeekDays] = useState<WeekDaysFlags | null>(null);
  const [isCustomSchedule, setIsCustomSchedule] = useState<boolean>(false);
  const [scheduledSimultaneousServices, setScheduledSimultaneousServices] = useState<boolean>(false);
  const [recurrenceItems, setRecurrenceItems] = useState<SelectItem[]>([]);
  const [recurrenceItemId, setRecurrenceItemId] = useState<AppointmentRecurrenceInterval | null>(null);
  const [recurrenceAfterAppointments, setRecurrenceAfterAppointments] = useState<number | null>(null);
  const [customStartTime, setCustomStartTime] = useState<moment.Moment>(moment().utcOffset(0).set({ hour: 9, minute: 30}));

  const [isValidRecurrence, setIsValidRecurrence] = useState<boolean>(true);
  const [isValidEndRecurrence, setIsValidEndRecurrence] = useState<boolean>(true);

  const availableHoursDelayRef = useRef<any>(null);
  const loadAvailableTimeObservableValuesRef = useRef<string|null>(null);
  const loadAvailableTimePrevDateRef = useRef<number|null>(null);
  const isDaysOffLoadRef = useRef<boolean>(false);

  const frequencyItems: SelectItem[] = [
    {key: "1", value: `${t("Every week, on the")} DAY`} as SelectItem,
    {key: "2", value: `${t("Every 2 weeks, on the")} DAY`} as SelectItem,
    {key: "3", value: `${t("Every 3 weeks, on the")} DAY`} as SelectItem,
    {key: "4", value: `${t("Every 4 weeks, on the")} DAY`} as SelectItem,
    {key: "8", value: `${t("Every 8 weeks, on the")} DAY`} as SelectItem,
    {key: "12", value: `${t("Every 12 weeks, on the")} DAY`} as SelectItem,
    {key: "101", value: `${t("Custom")}`} as SelectItem,
  ];
  const format = "HH:mm";
  
  const servicesHaveRepeatedUsers = appointment?.services.some((user, index) => {
    return appointment?.services.slice(0, index).some((prevUser) => {
      return prevUser.userId === user.userId;
    });
  });

  useEffect(() => {
    if (desiredDate) {
      dispatch(blockedScheduleActions.setDate(null));
    }
  }, []);

  useEffect(() => {
    const observableValues = {
      customerId: appointment?.customerId,
      services: appointment?.services.map(x => { return { serviceId:x.serviceId, userId:x.userId, workAreaId:x.workAreaId, estimatedDuration: x.estimatedDuration }}),
      startDate: appointment?.startDate,
      isRecurringAppointment: isRecurringAppointment,
      recurrenceItemId: recurrenceItemId,
      customRecurrenceInterval: customRecurrenceInterval,
      customRecurrenceWeekDays: customRecurrenceWeekDays,
      customRecurrenceAfterAppointments: customRecurrenceAfterAppointments, 
      customRecurrenceMode: customRecurrenceMode,
      recurrenceAfterAppointments: recurrenceAfterAppointments,
      scheduledSimultaneousServices: scheduledSimultaneousServices,
      refreshData: refreshData
    }
    const observableValuesString = JSON.stringify(observableValues);
    if (observableValuesString === loadAvailableTimeObservableValuesRef.current){
      return;
    }
    const prevObservableValues = loadAvailableTimeObservableValuesRef.current ? JSON.parse(loadAvailableTimeObservableValuesRef.current) : null;
    loadAvailableTimeObservableValuesRef.current = observableValuesString;
    if (prevObservableValues &&
      (prevObservableValues.isRecurringAppointment && !observableValues.isRecurringAppointment ||
        !observableValues.isRecurringAppointment &&
        (prevObservableValues.recurrenceItemId !== observableValues.recurrenceItemId ||
        prevObservableValues.recurrenceAfterAppointments !== observableValues.recurrenceAfterAppointments ||
        prevObservableValues.customRecurrenceInterval !== observableValues.customRecurrenceInterval ||
        prevObservableValues.customRecurrenceAfterAppointments !== observableValues.customRecurrenceAfterAppointments ||
        prevObservableValues.customRecurrenceWeekDays !== observableValues.customRecurrenceWeekDays ||
        prevObservableValues.customRecurrenceMode !== observableValues.customRecurrenceMode ||
        prevObservableValues.scheduledSimultaneousServices !== observableValues.scheduledSimultaneousServices))) {
      return;
    }
    if (prevObservableValues?.startDate && observableValues?.startDate && new Date(prevObservableValues.startDate).getTime() !== new Date(observableValues.startDate).getTime()) {
      let prevStartDate = new Date(prevObservableValues.startDate);
      let currentStartDate = new Date(prevObservableValues.startDate);
      prevStartDate.setHours(0, 0, 0, 0);
      currentStartDate.setHours(0, 0, 0, 0);
      if (prevStartDate.getTime() === currentStartDate.getTime()){
        return;
      }
    }

    async function fetchData() {
      await loadAvailableHoursWithDelay();
      await loadAvailableDaysIfNeed();
    }

    if (step1Valid && !desiredDate) {
      fetchData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment?.customerId,
    appointment?.services,
    appointment?.startDate,
    isRecurringAppointment,
    scheduledSimultaneousServices,
    recurrenceItemId,
    recurrenceAfterAppointments,
    customRecurrenceInterval,
    customRecurrenceAfterAppointments,
    customRecurrenceWeekDays,
    customRecurrenceMode,
    refreshData]);
  
  useEffect(() => {
    if (appointment?.startDate) {
      let appointmentStartDate = new Date(appointment?.startDate);
      let currentStateStartDate = new Date(date);
  
      if (appointmentStartDate.getTime() === currentStateStartDate?.getTime() && isCustomSchedule) {
        setIsAvailableHoursLoad(true);
        return;
      }

      currentStateStartDate.setHours(0, 0, 0, 0);
      if (loadAvailableTimePrevDateRef.current && loadAvailableTimePrevDateRef.current === currentStateStartDate?.getTime() && !isCustomSchedule) {
        setIsAvailableHoursLoad(true);
        return;
      }
      loadAvailableTimePrevDateRef.current = currentStateStartDate.getTime();
    }
    
    async function fetchData() {
      await loadAvailableHoursWithDelay();
      await loadAvailableDaysIfNeed();
    }

    if (step1Valid) {
      fetchData().then(() => {
        if (isCustomSchedule) {
          setSchedule(undefined);
        }
      });
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    
    if (
      appointment &&
      appointment.services &&
      JSON.stringify(appointment.services) !== JSON.stringify(services)
    ) {
      setServices(appointment.services);
      setSchedule(undefined);
    }

    if (
      appointment &&
      appointment.startDate &&
      appointment.startDate !== date
    ) {
      setDate(new Date(appointment.startDate));
      if (appointment.services) {
        const duration = getEstimatedDurations() ?? 0;
        const fromDate = new Date(appointment.startDate).toLocaleString(
          undefined,
          {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }
        );

        const appointmentDate = new Date(appointment.startDate);
        const toDate = new Date(
          appointmentDate.getTime() + duration * 60000
        ).toLocaleString(undefined, {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });

        setSchedule(`${fromDate} - ${toDate}`);
      }

    
    if (appointment) {
        setIsCustomRecurrence(appointment.customRecurrenceInterval != null);
        setIsRecurringAppointment(appointment.isRecurring);
        let recurrenceItemId = (isCustomRecurrence || appointment.customRecurrenceInterval != null)
          ? AppointmentRecurrenceInterval.CustomRecurrenceSaved
          : appointment.recurrenceWeekInterval;
        setRecurrenceItemId(recurrenceItemId);
        setRecurrenceAfterAppointments(appointment.recurredAppointmentsCount);
        onRecurringChange?.(appointment.recurredAppointmentsCount ?? 0, isRecurringAppointment);
        if (appointment.id) {
          setCustomRecurrenceAfterAppointments(appointment.recurredAppointmentsCount);
          setCustomRecurrenceInterval(appointment.customRecurrenceInterval);
          setCustomRecurrenceMode(appointment.customRecurrenceMode);
          setCustomRecurrenceWeekDays(appointment.customRecurrenceWeekDays);
        }
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment]);
  
  useEffect(() => {
    if (servicesHaveRepeatedUsers && !accountSettings.hasMultipleAppointments) {
      setScheduledSimultaneousServices(false);
    }
  }, [servicesHaveRepeatedUsers]);
  
  useEffect(() => {
    if (appointment?.id) {
      setScheduledSimultaneousServices(appointment.scheduledSimultaneousServices);
    }
  }, [appointment?.id]);
  
  useEffect(() => {
    async function fetchData() {
      await loadAvailableHoursWithDelay();
      await loadAvailableDaysIfNeed();
    }
    if (step1Valid) {
      fetchData().then(() => {
        if (isCustomSchedule) {
          setSchedule(undefined);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scheduledSimultaneousServices]);

  useEffect(() => {
    if (step1Valid && desiredDate) {
      const startDate = new Date(desiredDate);
      setIsAvailableHoursLoad(false);
      setDate(startDate);
    }
  }, [step1Valid]);

  useEffect(() => {
    if (desiredDate && step1Valid && Array.isArray(daysOff) && isAvailableHoursLoad && appointment && appointment.services) {
      const isDayOff = daysOff.findIndex((dayOff) => dayOff === desiredDate.getDay()) !== -1;
      if (!isDayOff){
        const fromDate = new Date(desiredDate).toLocaleString(
          undefined,
          {
            hour: "2-digit",
            minute: "2-digit",
            hour12: false,
          }
        );
        const duration = getDurations();
        const toDate = new Date(
          desiredDate.getTime() + duration * 60000
        ).toLocaleString(undefined, {
          hour: "2-digit",
          minute: "2-digit",
          hour12: false,
        });
        const desiredSchedule = `${fromDate} - ${toDate}`;
        if (availableHours.findIndex((h) => h === desiredSchedule) >= 0){
          setSchedule(desiredSchedule);
        }
      }
      setDesiredDate(null);
    }
  }, [daysOff, isAvailableHoursLoad]);

  useEffect(() => {
    if (onChange) {
      const validateRecurring = !isRecurringAppointment
        || (isRecurringAppointment
          && recurrenceItemId !== null
          && (!isCustomRecurrence && recurrenceAfterAppointments! > 1 && recurrenceAfterAppointments! <= 50)
          || (isCustomRecurrence && customRecurrenceAfterAppointments! > 1 && customRecurrenceAfterAppointments! <= 50));
      
      const customRecurrenceWeekDayValid = !isCustomRecurrenceWeekDaysModeChoose || 
        customRecurrenceWeekDays !== null && (customRecurrenceWeekDays & (1 << date.getDay())) !== 0;
      
      if (customRecurrenceWeekDayValid && validateRecurring && (schedule || isCustomSchedule)) {
        let startDate = new Date(date);

        if (isCustomSchedule) {
          startDate.setHours(
            +customStartTime.hours(),
            +customStartTime.minutes(),
            0,
            0
          );
        }
        else if (schedule) {
          const startTime = schedule.split("-")[0];
          startDate.setHours(
            +startTime.split(":")[0],
            +startTime.split(":")[1],
            0,
            0
          );
        }

        const newStateAppointment = new CreateAppointmentModel({ ...appointment, 
          startDate: startDate,
          isRecurring: isRecurringAppointment,
          scheduledSimultaneousServices: scheduledSimultaneousServices,
          recurrenceWeekInterval: isCustomRecurrence ? null : recurrenceItemId,
          recurredAppointmentsCount: isCustomRecurrence ?  +customRecurrenceAfterAppointments! : +recurrenceAfterAppointments!,
          customRecurrenceInterval: isCustomRecurrence ?  +customRecurrenceInterval! : null,
          customRecurrenceMode: isCustomRecurrence ? customRecurrenceMode : null,
          customRecurrenceWeekDays: isCustomRecurrence && isCustomRecurrenceWeekDaysModeChoose ? customRecurrenceWeekDays : null,
          isCustomSchedule: isCustomSchedule
        });
        
        onChange(newStateAppointment, true);
      } 
      else {
        onChange(null, false);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    schedule,
    scheduledSimultaneousServices,
    isCustomSchedule,
    recurrenceAfterAppointments,
    recurrenceItemId]);
  
  useEffect(() => {
    if (isCustomSchedule && !appointment?.id) {
      if (isNewAppointmentViaScheduler) {
        setDate(scheduleAppointmentTime);
        setCustomStartTime(moment(scheduleAppointmentTime, 'HH:mm'));
        
        if (onChange) {
          const newStateAppointment = new CreateAppointmentModel({ ...appointment,
            startDate: scheduleAppointmentTime,
            isRecurring: isRecurringAppointment,
            scheduledSimultaneousServices: scheduledSimultaneousServices,
            recurrenceWeekInterval: isCustomRecurrence ? null : recurrenceItemId,
            recurredAppointmentsCount: isCustomRecurrence ?  +customRecurrenceAfterAppointments! : +recurrenceAfterAppointments!,
            customRecurrenceInterval: isCustomRecurrence ?  +customRecurrenceInterval! : null,
            customRecurrenceMode: isCustomRecurrence ? customRecurrenceMode : null,
            customRecurrenceWeekDays: isCustomRecurrence && isCustomRecurrenceWeekDaysModeChoose ? customRecurrenceWeekDays : null,
            isCustomSchedule: isCustomSchedule
          });

          onChange(newStateAppointment, true);
          setSchedule("---");
        }
        dispatch(scheduleAppointmentTimeActions.setClickNewAppointmentOnScheduler(false));
      }
      else {
        if (!appointment?.id) {
          const firstAvailableHour = availableHours[0]?.substring(0,  availableHours[0]?.indexOf(' '));
          setCustomStartTime(moment(firstAvailableHour ?? moment().utcOffset(0).set({ hour: 9, minute: 30, }), 'HH:mm'));
        }
        else {
          if (appointment?.startDate) {
            let appointmentStartDate = new Date(appointment?.startDate);
            setCustomStartTime(moment(moment().utcOffset(0).set({ hour: appointmentStartDate?.getHours(), minute:  appointmentStartDate?.getMinutes(), }), 'HH:mm'));
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomSchedule]);
  
  useEffect(() => {
    // Refresh day name in SelectItems 
    const day = " " + formatDay();
    let selectItems: SelectItem[] = [];

    frequencyItems.forEach(item => {
      const newValue = item.value.replace("DAY", day);
      selectItems.push({key: item.key, value: newValue} as SelectItem);
    });
    
    if (customRecurrenceInterval !== null && customRecurrenceMode !== null && customRecurrenceAfterAppointments !== null) {
      const modeString = t(CustomRecurrenceMode[customRecurrenceMode].toLowerCase());
      const modeStringEnding = localeApp == 'es' && customRecurrenceMode === CustomRecurrenceMode.Month ? "es" : "s";

      const daysOfWeek = customRecurrenceWeekDays && customRecurrenceWeekDays > 0 
        ? ` ${t("_the")} ${getChosenDaysOfWeek(customRecurrenceWeekDays)}` 
        : "";
      const afterAppointments = `${t("after")} ${customRecurrenceAfterAppointments} ${t("appointments")}`;
      const recurrenceString = `${t("Every")} ${customRecurrenceInterval} ${modeString}${modeStringEnding}${daysOfWeek}, ${afterAppointments}`;

      selectItems.push({ key: AppointmentRecurrenceInterval.CustomRecurrenceSaved, value: recurrenceString } as SelectItem);
    }
    setRecurrenceItems(selectItems);
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);
  
  useEffect(() => {
    setIsCustomSchedule(appointment?.isCustomSchedule ?? false);
    if (appointment?.isCustomSchedule && appointment?.id && appointment?.startDate) {
      let appointmentStartDate = new Date(appointment?.startDate);
      setCustomStartTime(moment().utcOffset(0).set({ hour: appointmentStartDate?.getHours(), minute:appointmentStartDate?.getMinutes()}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appointment?.isCustomSchedule]);
  
  const loadAvailableHoursWithDelay = async () => {
    if (appointment && appointment.services && appointment.services.length) {
      if (availableHoursDelayRef.current){
        clearTimeout(availableHoursDelayRef.current!);
        availableHoursDelayRef.current = null;
      }
      availableHoursDelayRef.current = setTimeout(async function () {
        if (appointment.services.some(service => service.userId === undefined)) {
          return;
        } 

        const availableSchedule = await AppointmentService.getAvailableHours(
          date,
          appointment.services,
          scheduledSimultaneousServices,
          appointment.id || undefined,
          isCustomRecurrence ? null : recurrenceItemId,
          isCustomRecurrence ? customRecurrenceAfterAppointments : recurrenceAfterAppointments,
          isCustomRecurrence ? customRecurrenceMode : null,
          isCustomRecurrence ? customRecurrenceInterval : null,
          isCustomRecurrence && isCustomRecurrenceWeekDaysModeChoose ? customRecurrenceWeekDays : null,
        );
        const values = availableSchedule.availableHours || [];
        setAvailableHours(values);

        if (!isCustomSchedule && schedule && values.findIndex((h) => h === schedule) < 0){
          setSchedule(undefined);
        }
        setIsAvailableHoursLoad(true);

        clearTimeout(availableHoursDelayRef.current);
        availableHoursDelayRef.current = null;
      }, 10);
    }
  };

  const loadAvailableDaysIfNeed = async () => {
    if (!isDaysOffLoadRef.current && !Array.isArray(daysOff)){
      isDaysOffLoadRef.current = true;
      const days = await AppointmentService.getAvailableDays();
      dispatch(setDaysOff([...days]));
    }
  };
  
  const isCustomRecurrenceWeekDaysModeChoose = recurrenceItemId === AppointmentRecurrenceInterval.CustomRecurrenceSaved
  && customRecurrenceWeekDays !== null
  && customRecurrenceWeekDays !== 0;

  const getLocale = (): any => {
    switch (localeApp) {
      case "es":
        return esLocale;

      default:
        return enLocale;
    }
  };

  const formatDate = (): string => {
    let longDay = date.toLocaleDateString(localeApp, { weekday: "long" });
    longDay = longDay.charAt(0).toUpperCase() + longDay.slice(1);
    let month = date.toLocaleDateString(localeApp, { month: "long" });
    month = month.charAt(0).toUpperCase() + month.slice(1);
    const day = date.toLocaleDateString(localeApp, { day: "numeric" });
    const year = date.toLocaleDateString(localeApp, { year: "numeric" });

    switch (localeApp) {
      case "es":
        return `${longDay}, ${day} de ${month} ${year}`;

      default:
        return `${longDay}, ${month} ${day}, ${year}`;
    }
  };
  
  const formatDay = (): string => {
    let longDay = date.toLocaleDateString(localeApp, { weekday: "long" });
    return longDay.charAt(0).toLowerCase() + longDay.slice(1);
  };

  const renderWrappedDay = (
    date: MaterialUiPickersDate,
    selectedDate: MaterialUiPickersDate,
    dayInCurrentMonth: boolean
  ) => {
    const day = new Date(date as Date);
    const selected = new Date(selectedDate as Date);
    const current = new Date();
    current.setHours(0, 0, 0, 0);
    const dayOff = Array.isArray(daysOff) && daysOff.findIndex((dayOff) => dayOff === day.getDay()) !== -1;
    const isCustomRecurrenceDay =
      customRecurrenceWeekDays !== null
      && (customRecurrenceWeekDays & (1 << (day.getDay()))) !== 0;
    
    let disabled = day < current || !appointment?.customerId || dayOff || (isCustomRecurrenceWeekDaysModeChoose && !isCustomRecurrenceDay);
    const isCurrentDate =
      day.getDate() === current.getDate() &&
      day.getMonth() === current.getMonth();
    const isSelected =
      selected.getDate() === day.getDate() &&
      selected.getMonth() === day.getMonth();

    return (
      <div className={classes.dateRoot}>
        {dayInCurrentMonth ? (
          <IconButton
            className={`${classes.date} ${
              isCurrentDate ? classes.currentDate : ""
            } ${isSelected ? classes.selectedDate : ""}`}
            disabled={disabled}
          >
            {day.getDate()}
          </IconButton>
        ) : null}
      </div>
    );
  };

  const handleDisabledDay = (date: MaterialUiPickersDate): boolean => {
    const day = new Date(date as Date);
    const current = new Date();
    current.setHours(0, 0, 0, 0);
    const dayOff = Array.isArray(daysOff) && daysOff.findIndex((dayOff) => dayOff === day.getDay()) !== -1;
    const isCustomRecurrenceDay =
      customRecurrenceWeekDays !== null
      && (customRecurrenceWeekDays & (1 << (day.getDay()))) !== 0;
    return day < current || !appointment?.customerId || dayOff || (isCustomRecurrenceWeekDaysModeChoose && !isCustomRecurrenceDay);
  };

  const handleChangeDate = (date: MaterialUiPickersDate) => {
    setDate(date as Date);
    setSchedule(undefined);
  };

  const handleRecurringAppointment = (event: any) => {
    const checked = event.target.checked;
    setIsRecurringAppointment(checked);
    onRecurringChange?.(0, checked);
    if (!checked) {
      setRecurrenceItemId(null);
      setRecurrenceAfterAppointments(null);
      setIsValidRecurrence(true);
    }
    
    setSchedule(undefined);
  }
  
  const handleCustomSchedule = (event: any) => {
    const checked = event.target.checked;
    setIsCustomSchedule(checked);
    setSchedule(undefined);
  }
  
  function handleChangeScheduleSimultaneousServices(event: any) {
    const checked = event.target.checked;
    setScheduledSimultaneousServices(checked);
    setSchedule(undefined);
  }

  const handleRecurrenceSelect = (event: any) => {
    setIsValidRecurrence(true);
    setSchedule(undefined);
    const value = +event.target.value;
    if (value == AppointmentRecurrenceInterval.CustomRecurrenceDefault) {
      setIsValidEndRecurrence(true);
      setCustomRecurrenceModalIsOpen(true);
      return;
    }
    
    if (value == AppointmentRecurrenceInterval.CustomRecurrenceSaved) {
      setIsValidEndRecurrence(true);
      setIsCustomRecurrence(true);
      onRecurringChange?.(customRecurrenceAfterAppointments ?? 0, isRecurringAppointment);
    }
    else {
      setIsCustomRecurrence(false);
      onRecurringChange?.(recurrenceAfterAppointments ?? 0, isRecurringAppointment);
    }

    setRecurrenceItemId(value);
  }

  const handleBlurRecurrenceSelect = (event: any) => {
    if (customRecurrenceModalIsOpen) {
      return;
    }
    
    const value = event.target.value;
    const isNotValid = value === "" || +value < 0;
    if (!isNotValid) {
      setRecurrenceItemId(value);
    }
    setIsValidRecurrence(!isNotValid);
    setSchedule(undefined);
  }
  
  const handleCustomStartTimeChange = (time: moment.Moment) => {
    setCustomStartTime(time);
    setSchedule(undefined);
  };

  const handleRecurrenceAfterAppointments = (event: any) => {
    const value = event.target.value.replace(/\D/g, "");
    setRecurrenceAfterAppointments(value);
  }

  const handleBlurRecurrencesAfterAppointments = (event: any) => {
    let value = event.target.value;
    const isNotValid = (value === "" || +value < 0) || !(value >= 2 && value <= 50);
    if (isNotValid) {
      setRecurrenceAfterAppointments(null);
    }
    setIsValidEndRecurrence(!isNotValid);
    onRecurringChange?.(value, isRecurringAppointment);
    setSchedule(undefined);
  }
  
  const getChosenDaysOfWeek = (weekDaysFlags: WeekDaysFlags): string => {
    const chosenDays = [];
    for (const day in WeekDaysFlags) {
      if (weekDaysFlags & parseInt(day)) {
        chosenDays.push(t(WeekDaysFlags[day]));
      }
    }

    return chosenDays.join(", ");
  };
  
  const handleCustomRecurrenceModalOnSave = (modalData: RecurrenceModalData) => {
    onRecurringChange?.(modalData.customRecurredAppointmentsCount!, isRecurringAppointment);
    setCustomRecurrenceInterval(modalData.customRecurrenceInterval);
    setCustomRecurrenceMode(modalData.customRecurrenceMode);
    setCustomRecurrenceAfterAppointments(modalData.customRecurredAppointmentsCount);
    setCustomRecurrenceWeekDays(modalData.customRecurrenceWeekDays);
    setIsCustomRecurrence(true);
    if (modalData.customRecurrenceWeekDays && modalData.customRecurrenceWeekDays > 0) {
      const currentDayIndex = date.getDay();
      for (let i = 1; i <= 7; i++) {
        const nextAvailableDayIndex = (currentDayIndex + i) % 7; // Cast to day of the week
        const nextAvailableDayFlag = 1 << nextAvailableDayIndex;
        
        if (modalData.customRecurrenceWeekDays & nextAvailableDayFlag) {
          const nextAvailableDate = new Date(date);
          nextAvailableDate.setDate(date.getDate() + i);
          setDate(nextAvailableDate);
          break;
        }
      }
    }

    const updatedRecurrenceItems = recurrenceItems.filter(item => item.key !== AppointmentRecurrenceInterval.CustomRecurrenceSaved);
    const modeString = t(CustomRecurrenceMode[modalData.customRecurrenceMode!].toLowerCase());
    const modeStringEnding = localeApp == 'es' && modalData.customRecurrenceMode === CustomRecurrenceMode.Month ? "es" : "s";
    
    const daysOfWeek = modalData.customRecurrenceWeekDays && modalData.customRecurrenceWeekDays > 0 
      ? ` ${t("_the")} ${getChosenDaysOfWeek(modalData.customRecurrenceWeekDays)}` 
      : "";
    const afterAppointments = `${t("after")} ${modalData.customRecurredAppointmentsCount} ${t("appointments")}`;
    const recurrenceString = `${t("Every")} ${modalData.customRecurrenceInterval} ${modeString}${modeStringEnding}${daysOfWeek}, ${afterAppointments}`;

    updatedRecurrenceItems.push({
      key: AppointmentRecurrenceInterval.CustomRecurrenceSaved,
      value: recurrenceString
    } as SelectItem);
    
    setRecurrenceItems(updatedRecurrenceItems);
    setRecurrenceItemId(AppointmentRecurrenceInterval.CustomRecurrenceSaved);
    setCustomRecurrenceModalIsOpen(false);
  }
  
  function getEstimatedDurations() {
    if (scheduledSimultaneousServices) {
      const services = appointment?.services || []; 
      const durations = services.map(service => service?.estimatedDuration ?? 0);
      return Math.max(...durations);
    }
    return ( appointment?.services?.map((service) => service.estimatedDuration).reduce((prev, next) => (prev ? prev : 0) + (next ? next : 0)) || 0 ) ?? 0;
  }
  
  function getDurations() {
    if (scheduledSimultaneousServices) {
      const services = appointment?.services || []; 
      const durations = services.map(service => service?.duration ?? 0);
      return Math.max(...durations);
    }
    return appointment?.services.map((service) => service.duration).reduce((prev, next) => (prev ? prev : 0) + (next ? next : 0)) || 0;
  }
  function getCustomEndDate() {
    return moment().utcOffset(0).set({hour:customStartTime.hours(),minute:customStartTime.minutes() + getEstimatedDurations(),second:0,millisecond:0});
  }
  
  return (
    <div
      className={`${classes.root} ${
        !appointment?.customerId || !step1Valid ? classes.disabled : ""
      }`}
    >
      <div className={classes.header}>
        <Typography className={classes.stepTitle}>{t("STEP 2")}</Typography>
      </div>

        
      <div className={classes.subHeader}>
        <div className={classes.subTitle}>
          <CalendarIcon className={classes.calendarIcon} />
          <Typography className={classes.dateTimeSubTitle}>
            {t("Select the date and time of your appointment")}
          </Typography>
        </div>
        <div className={classes.dateTimeContent}>
          <Typography className={classes.dateTimeTxt}>
            {!appointment?.customerId ? "--" : formatDate()} /{" "}
            {schedule || "--"}
          </Typography>
        </div>
      </div>
      <div className={classes.bodyContent}>
        <div className={classes.calendarScheduler}>
          <div className={classes.calendar}>
            <div className={classes.datePicker}>
              <MuiPickersUtilsProvider
                utils={DateFnsUtils}
                locale={getLocale()}
              >
                <DatePicker
                  autoOk
                  disableToolbar
                  variant="static"
                  openTo="date"
                  value={date}
                  onChange={handleChangeDate}
                  disablePast
                  renderDay={renderWrappedDay}
                  shouldDisableDate={handleDisabledDay}
                  disableFuture={!appointment?.customerId}
                />
              </MuiPickersUtilsProvider>
            </div>
          </div>
          <div className={classes.schedule}>
            <Typography className={classes.requiredField}>
              {t("Schedules available")}
            </Typography>
            <div className={`${classes.scheduleContent} ${
               isCustomSchedule ? classes.scheduleDisabled : ""
            }`}>
              {!appointment?.customerId || !step1Valid ? (
                <Trans
                  className={classes.scheduleInvalid}
                  i18nKey="AppointmentScheduleNotValidMessage"
                  t={t}
                />
              ) : (
                <ul className={classes.ulSchedule}>
                  {(availableHours && availableHours.length) ||
                  (Array.isArray(daysOff) &&
                    daysOff.length &&
                    daysOff.findIndex(
                      (dayOff) => dayOff === date.getDay()
                    ) !== -1)
                    ? availableHours.map((availableHour, index) => (
                        <li
                          key={index}
                          
                          className={`${classes.liSchedule} ${
                            availableHour === schedule
                              ? isCustomSchedule ? "" : classes.liScheduleActive
                              : ""
                          }`}
                          onClick={() => {
                            setSchedule(availableHour);
                          }}
                        >
                          {availableHour}
                        </li>
                      ))
                    : <>
                    <div className={classes.calendarNoTime} >
                      <CalendarNoTime />
                      <Typography className={classes.calendarTitle}>
                        {t("There are no available times on the selected date.")}
                      </Typography>
                      <Typography className={classes.calendarSubTitle}>
                        {t("Please select another date or specialist.")}
                      </Typography>
                    </div>
                    </>}
                </ul>
              )}
            </div>
            {accountSettings.scheduleSimultaneousServicesEnabled && services && services?.length > 1  && <div className={classes.switch}>
              <Switch
                baseClass={classes.switchBase}
                onChange={handleChangeScheduleSimultaneousServices}
                value={scheduledSimultaneousServices}
                disabled={!step1Valid || (servicesHaveRepeatedUsers && !accountSettings.hasMultipleAppointments)}
              />
              <div className={classes.switchLabel}>
                <label className={classes.switchSubTitle}>
                  {t("Services performed simultaneously")}
                </label>
              </div>
            </div>}
            <div className={classes.separator} >
            </div>

            <div className={classes.switch}>
              <Switch
                baseClass={classes.switchBase}
                onChange={handleCustomSchedule}
                value={isCustomSchedule}
                disabled={!step1Valid}
              />
              <div className={classes.switchLabel}>
                <label className={classes.switchSubTitle}>
                  {t("Custom schedule")}
                </label>
              </div>
            </div>
            {
              isCustomSchedule && <div style={{height: 45, marginTop: 20, display: "flex"}}>
                    <div >
                        <TimePicker
                            onOk={(time) => {
                              handleCustomStartTimeChange(time);
                            }}
                            className={classes.selectStyle}
                            placeholder="HH:mm"
                            inputReadOnly={false}
                            showNow={false}
                            minuteStep={5}
                            value={customStartTime}
                            disabled={!step1Valid}
                            format={format}
                        />
                    </div>
                    <div style={{marginLeft: 8, marginRight: 8, paddingTop: 6, color: "#cfc9c4"}}>
                        - 
                    </div>
                    <div >
                        <TimePicker
                            className={classes.selectStyle}
                            placeholder="HH:mm"
                            inputReadOnly={true}
                            disabled={true}
                            value={getCustomEndDate()}
                            format={format}
                        />
                    </div>
                </div>
            }
            <div className={classes.separator} >   </div>
            {!isEdition && <>
                <div className={classes.rowContainer} style={{marginTop: 20}}>

                    <div className={classes.switch}>
                        <Switch
                            baseClass={classes.switchBase}
                            onChange={handleRecurringAppointment}
                            value={isRecurringAppointment}
                            disabled={!step1Valid}
                        />
                        <div className={classes.switchLabel}>
                            <label className={classes.switchSubTitle}>
                              {t("Recurring appointment")}
                            </label>
                        </div>
                    </div>
        
                  {
                    isRecurringAppointment && step1Valid && <div style={{height: 85, marginTop: 20}}>
                          <div style={{display: "inline-block", marginRight: 32}}>
                              <SelectWithLabel
                                  label={() => (<>
                                    {t("Recurrence")} <span className={classes.required}>*</span>
                                  </>)}
                                  value={recurrenceItemId}
                                  placeholder={t("Select the recurrence")}
                                  styleClass={classes.inputSelect}
                                  width={312}
                                  itemRootClass={classes.itemRoot}
                                  isValid={() => isValidRecurrence}
                                  name="recurrenceId"
                                  showTooltip={true}
                                  onChange={handleRecurrenceSelect}
                                  onBlur={handleBlurRecurrenceSelect}
                                  items={recurrenceItems || []}
                              />
                          </div>
                      <div style={{display: "inline-block", opacity: isCustomRecurrence ? 0.5 : 1}}>
                              <div style={{position: "relative", top: 0}} >

                                  <div className={classes.label} style={{paddingTop: 10}}>
                                    {t("Recurrence end")} <span className={classes.required}>*</span>
                                  </div>

                                  <div style={{display: "inline-block"}}>
                    <span className={classes.labelAppointment}>
                      {t("After")} 
                    </span>
                                  </div>

                                  <div style={{display: "inline-block", position: "relative", top: 0, left: 20}}>
                                      <Input
                                          type={"text"}
                                          disabled={!isRecurringAppointment || isCustomRecurrence}
                                          value={isCustomRecurrence ? "" : recurrenceAfterAppointments}
                                          isValid={() => isValidEndRecurrence}
                                          width={60}
                                          onChange={handleRecurrenceAfterAppointments}
                                          onBlur={handleBlurRecurrencesAfterAppointments}
                                      />
                                      <span className={classes.labelAppointment} style={{marginLeft: 10}}>
                      {t("appointments")}
                    </span>
                                  </div>

                              </div>
                          </div>

                      </div>
                  }
                </div>
            </>}
            <br></br>
          </div>
        </div>
      </div>
      <CustomRecurrenceModal
      isOpen={customRecurrenceModalIsOpen}
      handleClose={() => setCustomRecurrenceModalIsOpen(false)}
      handleConfirm={handleCustomRecurrenceModalOnSave}
      />
    </div>
   
  );
};

export default AppointmentStepTwo;
