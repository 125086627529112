import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        "& ::-webkit-scrollbar": {
            width: "6px",
        },
        "& ::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#EFEFEF",
            marginTop: "33px",
            marginBottom: "33px"
        },
        "& ::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: "#ACB7C0",
        },
        "& ::-webkit-scrollbar-thumb:hover": {
            background: Color.gray4
        },
    },
    paper: {
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #dbdcdd",
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        width: 812,
        minHeight: 500,
        maxHeight: 500,
        overflow: "auto"
    },
    modalHeader: {
        display: "flex",
        alignItems: "center",
        padding: "24px 40px 24px 40px"
    },
    modalTitle: {
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 5,
        width: "100%",
        "& svg path": {
            fill: theme.colors.grey5
        }
    },
    modalTitleText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray5
    },
    modalActions: {
        display: "flex",
        justifyContent: "flex-end",
        gap: 16
    },
    goBack: {
        width: 120,
        background: "#F6F6F6",
        color: "#ACB7C0",
    },
    continueButton: {
        width: 164,
        background: "#36CE91",
        color: "#F6F6F6",
        "&:hover": {
            background: "#36CE91",
        },
        "&.Mui-disabled": {
            background: "rgba(54, 206, 145, 0.5)",
            color: "white"
        }
    },
    button: {
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
    },
    content: {
        height: "100%",
        padding: "24px 40px",
        borderRadius: "10px",
    },
}));

export default useStyles;