import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ArrowDropDownIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 12, height: 7, ...style }}
      viewBox={viewBox || "0 0 12 7"}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.51817 6.77848L11.7838 1.32745C11.9224 1.1801 12 0.982199 12 0.776109C12 0.570018 11.9224 0.372117 11.7838 0.224768C11.6422 0.0810133 11.4521 0.000544049 11.2542 0.000544049C11.0562 0.000544049 10.8662 0.0810133 10.7245 0.224768L5.99974 5.1356L1.27605 0.224768C1.13435 0.0806795 0.944032 0 0.74584 0C0.547649 0 0.357332 0.0806795 0.21563 0.224768C0.0773852 0.372066 0 0.569731 0 0.775552C0 0.981372 0.0773852 1.17904 0.21563 1.32634L5.46417 6.77848C5.60558 6.92057 5.79453 7 5.99117 7C6.18781 7 6.37676 6.92057 6.51817 6.77848Z"
      />
    </SvgIcon>
  );
};

export default ArrowDropDownIcon;
