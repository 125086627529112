import { styled } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import clsx from 'clsx';
import React, { memo, useContext, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { FixedSizeGrid, GridChildComponentProps } from 'react-window';
import {
    AppointmentSchedulerInnerElementContext,
    generateStops,
    useAppointmentSchedulerContext,
} from '../../_util';
import styles from './css.module.css';

export type ContentGridProps<G> = {
    groups: G[];
    from: number;
    to: number;
    intervalInMinutes: number;
    pixelsPerMinute: number;
    children?: React.ReactNode;
    ContentGridTdComponent?: ContentGridTdComponent<G>;
    contentGridRootRef?: React.RefCallback<HTMLElement>;
};

export type ContentGridTdProps<G> = {
    startTimestamp: number;
    stopTimestamp: number;
    group: G;
};
export type ContentGridTdComponent<G> = React.ComponentType<ContentGridTdProps<G>>;
class DefaultContentGridTdComponent<G> extends React.Component<ContentGridTdProps<G>> {
    render(): React.ReactNode {
        return <div />;
    }
    shouldComponentUpdate(): boolean {
        return false;
    }
}
export type ContentGridColumnProps<G> = {
    group: G;
};
export type ContentGridColumnComponent<G> = React.ComponentType<ContentGridColumnProps<G>>;

type CellData<G> = {
    stops: number[];
    stopsInterval: number;
    groups: G[];
    Component: ContentGridTdComponent<G>;
};

function ContentGrid<G>({
    from,
    to,
    groups,
    children,
    ContentGridTdComponent,
    intervalInMinutes,
    contentGridRootRef,
}: ContentGridProps<G>) {
    const stops = generateStops(from, to, intervalInMinutes);
    const TdComponent = ContentGridTdComponent ?? DefaultContentGridTdComponent;

    const { rowHeight, columnWidth, innerHeight, innerWidth } = useAppointmentSchedulerContext();

    const cellData: CellData<G> = useMemo(
        () => ({
            Component: TdComponent,
            stops,
            stopsInterval: intervalInMinutes,
            groups,
        }),
        [stops, intervalInMinutes, groups, TdComponent]
    );

    const [gridInnerRef, setGridInnerRef] = useState<HTMLElement | null>(null);
    const [gridOuterRef, setGridOuterRef] = useState<HTMLElement | null>(null);
    const { setInnerElement } = useContext(AppointmentSchedulerInnerElementContext);

    useEffect(() => {
        if (contentGridRootRef) {
            contentGridRootRef(gridOuterRef);
        }
    }, [gridOuterRef, contentGridRootRef]);

    // we use react-window because react-virtuoso does not support virtual grid with horizontal scrolling

    return (
        <>
            <FixedSizeGrid<CellData<G>>
                columnCount={groups.length}
                rowCount={stops.length}
                innerRef={setGridInnerRef}
                outerRef={(outer) => {
                    setGridOuterRef(outer);
                    setInnerElement(outer); // from AppointmentScheduler perspective this "outer" element is "inner"
                }}
                height={innerHeight}
                width={innerWidth}
                rowHeight={rowHeight}
                itemData={cellData}
                columnWidth={columnWidth}
                className={clsx(styles.grid, 'custom-scrollbar', 'data-scroll-parent')}
                innerElementType={Inner}
                style={
                    {
                        // this should hurt performance a little, but 1) I don't notice it 2) without this, absence reason will move out of sync with the scheduler
                        // try creating a long absence and then scrolling past that absence with/without this property
                        willChange: 'initial',

                        '--cmos-aps-cell-width': `${columnWidth}px`,
                        '--cmos-aps-cell-height': `${rowHeight}px`,
                    } as CSSProperties
                }
            >
                {Cell}
            </FixedSizeGrid>
            {/* ugly hack that I hate, but it works so uh... yeah  */}
            {gridInnerRef &&
                ReactDOM.createPortal(
                    <>
                        <div className={styles.gridChildren}>{children}</div>
                    </>,
                    gridInnerRef
                )}
        </>
    );
}

export default memo(ContentGrid) as typeof ContentGrid;

function Cell<G>({ columnIndex, rowIndex, data, style }: GridChildComponentProps<CellData<G>>) {
    const group = data.groups[columnIndex];
    const ts = data.stops[rowIndex];
    const Component = data.Component;
    return (
        <div className={styles.item} style={style}>
            <Component startTimestamp={ts} stopTimestamp={ts + data.stopsInterval} group={group} />
        </div>
    );
}

const Inner = styled('div')({
    display: 'flex',
    flexWrap: 'wrap',
});
