import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import { TrashIcon, CrossIcon } from "./../../../assets/icons";

import useStyles from "./css";
import { ConfirmDeleteModalProps } from "./props";

const ConfirmDeleteModal = (props: ConfirmDeleteModalProps) => {

    const { t } = useTranslation(["general"]);
    const { open, item, componentInfo, onClose, onDelete, classModal, classButton, textButtonConfirm } = props;
    const classes = useStyles();

    const handleDelete = () => {
        if (onDelete) onDelete(item);
    };

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={`${classes.modal}`}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>
            <Fade in={open}>
                <div className={`${classes.modalPaper} ${classModal}`}>
                    <div className={classes.modalHeader}>
                        <div className={classes.modalHeaderContent}>
                            <div className={classes.modalCircle}>
                                <TrashIcon />
                            </div>
                        </div>
                        <div className={classes.modalClose}>
                            <span className={classes.closeButton} onClick={onClose}>
                                <CrossIcon />
                            </span>
                        </div>
                    </div>

                    <div className={classes.modalContent}>

                        {
                            componentInfo
                        }

                        <div className={classes.modalActions}>
                            <Button className={`${classButton ?? classes.button} ${classes.goBack}`} onClick={onClose} >
                                {t("Go back")}
                            </Button>

                            <Button className={`${classButton ?? classes.button} ${classes.deleteBtn}`} onClick={handleDelete} >
                                {textButtonConfirm ?? t("Delete")}
                            </Button>
                        </div>

                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default ConfirmDeleteModal;
