import { DayOfWeek } from "./enums/day-of-week";

export class UserScheduleInfo {
  schedule : UserScheduleDay[];
  
  constructor(days: UserScheduleDay[]) {
    this.schedule = days ? JSON.parse(JSON.stringify(days))  : [];
  }
}

export class UserScheduleDay {
  dayOfWeek: DayOfWeek;
  isActive: boolean;
  windows: UserScheduleWindow[];
  
  constructor(dayOfWeek: DayOfWeek, windows: UserScheduleWindow[], isActive?: boolean) {
    this.dayOfWeek = dayOfWeek;
    this.windows = JSON.parse(JSON.stringify(windows));
    this.isActive = isActive ?? false;
  }
}

export class UserScheduleWindow {
  timeStart: string;
  timeClosing: string;
  
  constructor(timeStart : string, timeClosing : string) {
    this.timeStart = timeStart;
    this.timeClosing = timeClosing;
  }
}