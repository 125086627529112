import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Paper from "@material-ui/core/Paper";

import Tabs from "./../../common/tabs/Tabs";
import TabPanel from "./../../common/tabs/TabPanel";
import { TabsModel } from "../../common/tabs/tabs-model";

import CustomerDataInfo from "./../CustomerDataInfo";
import { CustomerInfoDetails } from "../../../models/customer-info-details";
import CustomerHistory from "./../CustomerHistory";

import { useAppSelector } from "../../../redux/hooks";
import { selectAccountSettings, selectUserInfo } from "../../../redux/store";

import useStyles from "./css";
import PatientEvolutionNotes from "../PatientEvolutionNotes";
import PatientData from "../PatientData";
import CustomerInformationTab from "../CustomerInformationTab";
import PatientGeneralInformationTab from "../PatientGeneralInformationTab";
import CustomerClinicalFiles from "../CustomerClinicalFiles";
import PatientClinicalHistoryTab from "../PatientClinicalHistoryTab";
import CustomerDetailsRefs from "./customer-details-refs";
import PatientAppointmentHistoryTab from "../PatientAppointmentHistoryTab";

enum CustomerDetailsTab {
  General,
  ClinicalHistory,
  EvolutionNotes,
  AppointmentHistory,
  Files
}


const CustomerDetails = () => {
  const { t } = useTranslation(["general"]);
  const { customerId } = useParams<any>();
  const classes = useStyles();
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [tabs, setTabs] = useState<CustomerDetailsTab[]>([])
  const [appointmentId, setAppointmentId] = useState<string | undefined>();
  const [serviceId, setServiceId] = useState<string | undefined>();

  const accountSettings = useAppSelector(selectAccountSettings);
  const userInfo = useAppSelector(selectUserInfo);

  const canSeeClinicalFile = userInfo.canSeeClinicalFile;
  const canInteractWithEvolutionNotes = userInfo.canInteractWithEvolutionNotes;
  const canInteractWithPatientFiles = userInfo.canInteractWithPatientFiles;
  const customerDetailsListRef = useRef<CustomerDetailsRefs>(null);

  useEffect(() => {
    const tabs: CustomerDetailsTab[] = [];
    tabs.push(CustomerDetailsTab.General);
    if (accountSettings.isClinicMode && canSeeClinicalFile)
      tabs.push(CustomerDetailsTab.ClinicalHistory);
    if (accountSettings.isClinicMode && canInteractWithEvolutionNotes)
      tabs.push(CustomerDetailsTab.EvolutionNotes);
    tabs.push(CustomerDetailsTab.AppointmentHistory);
    if ((canInteractWithPatientFiles && accountSettings.isClinicMode) || !accountSettings.isClinicMode)
      tabs.push(CustomerDetailsTab.Files);
    setTabs(tabs);
  }, [accountSettings]);

  const [customer, _setCustomer] = useState<CustomerInfoDetails>(
    new CustomerInfoDetails()
  );
  
  const handleUpdateEvolutionNotes = async () => {
    await customerDetailsListRef?.current?.updateVitalSigns();
  }

  const getTabModels = (): TabsModel[] => {
    const tabsModels: TabsModel[] = [];
    for (let i = 0; i < tabs.length; i++) {
      switch (tabs[i]) {
        case CustomerDetailsTab.General:
          tabsModels.push({
            index: i, label: accountSettings.isClinicMode
              ? t("General information")
              : t("Customer info")
          });
          break;
        case CustomerDetailsTab.ClinicalHistory:
          tabsModels.push({ index: i, label: t("Clinical history") });
          break;
        case CustomerDetailsTab.EvolutionNotes:
          tabsModels.push({ index: i, label: t("Evolution notes") });
          break;
        case CustomerDetailsTab.AppointmentHistory:
          tabsModels.push({ index: i, label: t("Appointments history") });
          break;
        case CustomerDetailsTab.Files:
          tabsModels.push({ index: i, label: t("Files") });
          break;
      }
    }
    return tabsModels;
  };

  const tryGetTabIndex = (tabType: CustomerDetailsTab) => {
    const tabIndex = tabs.findIndex(tt => tt === tabType);
    if (tabIndex === -1)
      return null;
    return tabIndex;
  };

  const onChangeTab = (index: number) => {
    setAppointmentId(undefined);
    setServiceId(undefined);
    setCurrentTab(index);
  };


  return (
    <div className={classes.root}>
      {accountSettings.isClinicMode
        ? <PatientData customerId={customerId} ref={customerDetailsListRef}/>
        : <CustomerDataInfo
          customerId={customerId}
          name={`${customer.firstName} ${customer.lastName}`}
          birthDate={customer.birthDate || null}
        />
      }
      <div className={classes.customerDetails}>
        <Paper className={classes.customerDetailsContent}>
          <Tabs value={currentTab} tabs={getTabModels()} onChange={onChangeTab} />
          <TabPanel value={currentTab} index={tryGetTabIndex(CustomerDetailsTab.General)}>
            {accountSettings.isClinicMode
              ?
              <PatientGeneralInformationTab
                customerId={customerId}
                setCustomer={_setCustomer}
              />
              :
              <CustomerInformationTab
                customerId={customerId}
                customer={customer}
                currentTabIndex={currentTab}
                setCustomer={_setCustomer}
              />
            }
          </TabPanel>
          {tryGetTabIndex(CustomerDetailsTab.ClinicalHistory) && <>
            <TabPanel value={currentTab} index={tryGetTabIndex(CustomerDetailsTab.ClinicalHistory)}>
              <PatientClinicalHistoryTab customerId={customerId} />
            </TabPanel></>}
          {tryGetTabIndex(CustomerDetailsTab.EvolutionNotes) && <>
            <TabPanel value={currentTab} index={tryGetTabIndex(CustomerDetailsTab.EvolutionNotes)}>
              <PatientEvolutionNotes
                customerName={`${customer.firstName} ${customer.lastName}`}
                customerMobile={customer.mobile}
                appointmentId={appointmentId}
                serviceId={serviceId}
                onUpdate={handleUpdateEvolutionNotes}
              />
            </TabPanel></>}
          {tryGetTabIndex(CustomerDetailsTab.AppointmentHistory) && <>
            <TabPanel value={currentTab} index={tryGetTabIndex(CustomerDetailsTab.AppointmentHistory)}>
              {accountSettings.isClinicMode
                ? <PatientAppointmentHistoryTab
                  customerId={customerId}
                  gotoEvolutionNotesTab={(appointmentId, serviceId) => {
                    setAppointmentId(appointmentId);
                    setServiceId(serviceId);
                    setCurrentTab(CustomerDetailsTab.EvolutionNotes);
                  }} />
                : <CustomerHistory
                  customer={customer} />}
            </TabPanel></>}
          {tryGetTabIndex(CustomerDetailsTab.Files) && <>
            <TabPanel value={currentTab} index={tryGetTabIndex(CustomerDetailsTab.Files)}>
              <CustomerClinicalFiles />
            </TabPanel></>}
        </Paper>
      </div>
    </div>
  );
};

export default CustomerDetails;