import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import useStyles from "./css";
import Button from "@material-ui/core/Button";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { useHistory } from "react-router-dom";
import { alertsActions } from "../../../redux/alerts-slice";
import { selectAccountSettings } from "../../../redux/store";
import { applicationInterfaceActions } from "../../../redux/application-interface-slice";

import { conformToMask } from "react-text-mask";
import Interweave from "interweave";

import { PatientGeneralInformationTabProps } from "./props";
import { GetMobileMaskByTemp } from "../../../constants/mask";
import { EmailRegex, MultipleSpacesNameRegex } from "../../../constants/validator";
import { Url } from "../../../constants/url";

import { CustomerService } from "../../../api/customer-service";
import { ApiClient } from "../../../api/api-client";

import Typography from "@material-ui/core/Typography";
import { Editor } from "@tinymce/tinymce-react";
import PatientFormTextInput from "../../common/PatientFormTextInput";
import PatientFormMobileInput from "../../common/PatientFormMobileInput";
import PatientFormRadioGroup from "../../common/PatientFormRadioGroup";
import { SectionFieldItemModel } from "../../../models/section-field-item-model";
import ConfirmActionModal from "../../common/ConfirmActionModal";
import PatientFormDateInput from "./PatientFormDateInput";
import Cloner from "../../../utils/cloner";

import { CrossIcon, EditIcon, InternalNotesIcon, SaveIcon } from "../../../assets/icons";
import CountryFlagIcon from "../../../assets/flags";

import { PatientGeneralInfoResponse } from "../../../models/patient-general-info-response";
import { Gender } from "../../../models/enums/gender";
import { SectionFieldType } from "../../../models/enums/section-field-type";
import { FormRadioValue } from "../../../models/form-radio-value";
import { SectionFieldValueModel } from "../../../models/section-field-value-model";
import { FormPhoneNumberValue } from "../../../models/form-phone-number-value";
import { PatientFormFieldAndValue } from "../../../models/patient-form-field-and-value";
import { FormFieldItemValue } from "../../../models/form-field-item-value";
import { UpdatePatientFormDataRequest } from "../../../models/update-patient-form-data-request";
import { SectionFieldModel } from "../../../models/section-field-model";
import { navbarActions } from "../../../redux/navbar-slice";
import Switch from "../../common/Switch";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";
import { CustomerInfoDetails } from "../../../models/customer-info-details";


export default function PatientGeneralInformationTab(props: PatientGeneralInformationTabProps) {
  const { customerId, setCustomer } = props;
  const sectionTNamespace = "sections";
  const maxNotesTextLength: number = 1500;
  const stringify = require('safe-stable-stringify')
  const { t } = useTranslation(["general", sectionTNamespace]);
  const classes = useStyles();
  const history = useHistory();
  const appDispatch = useAppDispatch();
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const accountSettings = useAppSelector(selectAccountSettings);
  const { enqueueAlert } = alertsActions;
  const hasCustomerInfoVisible = ApiClient.getUserInfo().hasCustomerInfoVisible;

  const [patient, setPatient] = useState<PatientGeneralInfoResponse>(new PatientGeneralInfoResponse());
  const [isEdition, setIsEdition] = useState<boolean>(false);
  const [patientForEdit, setPatientForEdit] = useState<UpdatePatientFormDataRequest | null>(null);
  const [isFormValid, setIsFormValid] = useState<boolean>(false);
  const [cancelEditModalIsOpen, setCancelEditModalIsOpen] = useState<boolean>(false);
  const [focusedNotes, setFocusedNotes] = useState<boolean>(false);
  const [notesTextLength, setNotesTextLength] = useState<number>(0);

  const maleGender = new FormFieldItemValue({ fieldItemId: Gender.Male.toString(), value: t("default_field_item_name_Male", { ns: sectionTNamespace }) });
  const femaleGender = new FormFieldItemValue({ fieldItemId: Gender.Female.toString(), value: t("default_field_item_name_Female", { ns: sectionTNamespace }) });
  const secretGender = new FormFieldItemValue({ fieldItemId: Gender.PreferNotToSay.toString(), value: t("default_field_item_name_PreferNotToSay", { ns: sectionTNamespace }) });
  const customGender = new FormFieldItemValue({ fieldItemId: Gender.Custom.toString(), value: t("default_field_item_name_Custom", { ns: sectionTNamespace }) });

  const appointmentDescription = useCustomerTerminology(t("The customer will receive automatic notifications such as: Appointments, reminders, etc."), "customer");
  const massiveSendingsDescription = useCustomerTerminology(t("The customer will receive automated Massive sending messages."), "customer");

  const yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1));
  const year1900Date = new Date(1900, 0);

  useEffect(() => {
    async function fetchData() {
      await loadData();
    }

    fetchData();
  }, []);

  useEffect(() => {
    if (!patientForEdit)
      return;
    validateFieldsOnChange(patientForEdit.patientFormSectionFields);
  }, [
    patientForEdit?.firstName,
    patientForEdit?.lastName,
    patientForEdit?.email,
    patientForEdit?.mobile,
    patientForEdit?.mobileCodeIso3,
    patientForEdit?.gender,
    patientForEdit?.notes,
    patientForEdit?.dateOfBirth,
    patientForEdit?.contactableAppointments,
    patientForEdit?.contactableMassiveSendings]);

  const loadData = async () => {
    appDispatch(navbarActions.setShowLoader(true));
    const patientResponse = await CustomerService.getPatientGeneralInfo(customerId);
    appDispatch(navbarActions.setShowLoader(false));
    
    const countries = accountSettings.countries;
    let mobileCode = patientResponse.mobileCodeIso3 ?? "MEX";
    patientResponse.mobileDigits = countries.find(x => x.iso3 === mobileCode)!.dialDigits;
    patientResponse.mobileCode = countries.find(x => x.iso3 === mobileCode)!.dialCode;
    patientResponse.mobileTemplate = countries.find(x => x.iso3 === mobileCode)!.dialTemplate.toString();
    
    setPatient(patientResponse);
    setCustomer(patientResponse);

    const titleNav = t("Patient clinical file") + " / " + patientResponse.firstName + " " + patientResponse.lastName;
    appDispatch(applicationInterfaceActions.updateTitle(titleNav));
  };

  const handleEditButtonClick = async () => {
    const clone = Cloner.deepCopy<PatientGeneralInfoResponse>(patient);
    const patientForEditModel = new UpdatePatientFormDataRequest(clone);
    setPatientForEdit(patientForEditModel);
    setIsEdition(true);
  };

  const handleCloseEdit = () => {
    setCancelEditModalIsOpen(false);
    setIsEdition(false);
    setIsFormValid(false);
  };

  const handleCancelEditButtonClick = async () => {
    if (stringify(new UpdatePatientFormDataRequest(patient)) !== stringify(patientForEdit)) {
      setCancelEditModalIsOpen(true);
    }
    else {
      handleCloseEdit();
    }
  };

  const handleSaveChangesButtonClick = async () => {
    if (!patientForEdit)
      return;

    const response = await CustomerService.updatePatientFormData(patientForEdit);
    if (response.isDuplicatedPhoneNumber) {
      appDispatch(
        enqueueAlert({
          type: "Error",
          title: t("Invalid number"),
          description: t("This number already belongs to patient"),
          link: {
            onClick: (e: any) => {
              e.preventDefault();
              const location = `${Url.Customers.Main}/${response.duplicatedPatientId!}`;
              history.push(location);
              history.replace(location);
              history.go(0);
            },
            title: `${response.firstName} ${response.lastName}`,
          }
        })
      );
      return;
    }

    await loadData();
    handleCloseEdit();
    appDispatch(
      enqueueAlert({
        type: "Success",
        description: t("Information updated")
      }));
  };

  const setFirstName = (v: string) => {
    setPatientForEdit(prev => ({ ...prev!, firstName: v }));
  };

  const setLastName = (v: string) => {
    setPatientForEdit(prev => ({ ...prev!, lastName: v }));
  };
  
  const setPhoneNumber = (v: FormPhoneNumberValue) => {
    setPatientForEdit(prev => ({ ...prev!, mobileCodeIso3: v.countryIso, mobile: v.body }));
  };

  const setEmail = (v: string) => {
    setPatientForEdit(prev => ({ ...prev!, email: v }));
  };
  
  const setCustomGender = (v: string) => {
    setPatientForEdit(prev => ({ ...prev!, customGender: v }));
  };
  
  const setDob = (v: Date) => {
    setPatientForEdit(prev => ({ ...prev!, dateOfBirth: v }));
  };
  
  function setContactableAppointments(event: any) {
    setPatientForEdit(prev => ({ ...prev!, contactableAppointments: event.target.checked }));
  }
  
  function setContactableMassiveSendings(event: any) {
    setPatientForEdit(prev => ({ ...prev!, contactableMassiveSendings: event.target.checked }));
  }

  const setNotesTextLengthOnInit = (evt: any, editor: any) => {
    setNotesTextLength(editor.getContent({ format: "text" }).length);
  };

  const setNotes = (c: string, editor: any) => {
    const contentText = editor.getContent({ format: "text" });
    let contentHtml: string = editor.getContent();
    if (contentText.length <= maxNotesTextLength) {
      setPatientForEdit(prev => ({ ...prev!, notes: contentHtml }));
      setNotesTextLength(contentText.length);
    }
  };

  const setFieldValue = (fieldId: string, value: string | FormPhoneNumberValue | FormRadioValue | null, additionalTextValue: string | null = null) => {
    if (!patientForEdit?.patientFormSectionFields)
      return;

    const fieldsAndValues = [...patientForEdit.patientFormSectionFields];
    const fieldAndValue = fieldsAndValues.find(f => f.field.id === fieldId);

    if (!fieldAndValue)
      return;

    //make value null if field is empty (need for compairing with old patient version and for proper storing)
    if (typeof value === "string" && value === "") {
      fieldAndValue.value = null;
      validateFieldsOnChange(fieldsAndValues);
      setPatientForEdit({ ...patientForEdit, patientFormSectionFields: fieldsAndValues })
      return;
    }

    if (!additionalTextValue) {
      if (!fieldAndValue.value) {
        fieldAndValue.value = new SectionFieldValueModel({ type: fieldAndValue.field.type, value: value })
      }
      else {
        fieldAndValue.value.type = fieldAndValue.field.type;
        fieldAndValue.value.value = value!;
      }
    }
    //if field is Radio and contain additional text value
    else if (additionalTextValue && fieldAndValue.field.type === SectionFieldType.Radio) {
      let newValue: FormRadioValue;
      //if this field value has already selected field item - just setting the argument value
      if (value && (value as FormRadioValue).item) {
        newValue = value as FormRadioValue;
      }
      //if not - we're selecting first item automatically
      else {
        const firstSelectItem = fieldAndValue.field.items.sort((i1, i2) => i1.order - i2.order)[0];
        const autoSelectedFirstItemValue = new FormFieldItemValue({ fieldItemId: firstSelectItem.id, value: firstSelectItem.name })
        newValue = new FormRadioValue();
        newValue.item = autoSelectedFirstItemValue;
        newValue.additionalFreeTextValue = additionalTextValue;
      }
      if (!fieldAndValue.value) {
        fieldAndValue.value = new SectionFieldValueModel({ type: fieldAndValue.field.type, value: newValue })
      }
      else {
        fieldAndValue.value.value = newValue!;
      }

    };

    validateFieldsOnChange(fieldsAndValues);
    setPatientForEdit({ ...patientForEdit, patientFormSectionFields: fieldsAndValues })
  };

  const validateFieldsOnChange = (fieldsAndValues: PatientFormFieldAndValue[]) => {
    //if any of the fields is invalid - returning true 
    const firstNameIsValid = MultipleSpacesNameRegex.test(patientForEdit!.firstName) && patientForEdit!.firstName.trim() !== "";
    const lastNameIsValid = MultipleSpacesNameRegex.test(patientForEdit!.lastName) && patientForEdit!.lastName.trim() !== "";
    const emailIsValid = EmailRegex.test(patientForEdit!.email ?? "") || !patientForEdit!.email || patientForEdit!.email === "";
    const mobileIsValid = validatePhoneNumber(patientForEdit!.mobileCodeIso3, patientForEdit!.mobile);
    const dobIsValid = validateDobField(patientForEdit!.dateOfBirth);

    if (!(firstNameIsValid && lastNameIsValid && emailIsValid && mobileIsValid)) {
      setIsFormValid(false);
      return;
    }

    const isValid = !fieldsAndValues.some(fv => {
      if (fv.field.isRequired) {
        if (!fv.value?.value || fv.value?.value === "") {
          if (!fv.field.isDeleted) {
            return true;
          }
          else {
            return false;
          }
        }
        switch (fv.field.type) {
          case SectionFieldType.Text:
            if (!fv.value?.value || fv.value.value === "") {
              return true;
            }
            break;
          case SectionFieldType.Email:
            if (!fv.value?.value || fv.value.value === "" || !EmailRegex.test(fv.value!.value as string)) {
              return true;
            }
            break;
          case SectionFieldType.Radio:
            if (!fv.field.isDeleted && (fv.value?.value as FormRadioValue).item === null) {
              return true;
            }
            break;
          case SectionFieldType.PhoneNumber:
            const phoneNumber = (fv.value?.value as FormPhoneNumberValue);
            if (!fv.field.isDeleted && (phoneNumber.body === "" || !validatePhoneNumber(phoneNumber.countryIso, phoneNumber.body))) {
              return true;
            }
            break;
        }
      }
      else {
        switch (fv.field.type) {
          case SectionFieldType.Email:
            if (fv.value?.value && !EmailRegex.test(fv.value.value as string)) {
              return true;
            }
            break;
          case SectionFieldType.PhoneNumber:
            const phoneNumber = (fv.value?.value as FormPhoneNumberValue);
            if (phoneNumber && phoneNumber.body !== "" && !validatePhoneNumber(phoneNumber.countryIso, phoneNumber.body)) {
              return true;
            }
            break;
        }
      }

      return false;
    });

    const isAllValid = isValid && firstNameIsValid && lastNameIsValid && emailIsValid && mobileIsValid && dobIsValid;
    setIsFormValid(isAllValid);
  }

  const validateTextField = (field: SectionFieldModel, value: string): boolean => {
    const isValid = !(field.isRequired && value === "");
    return isValid;
  };

  const validateEmailField = (field: SectionFieldModel, value: string): boolean => {
    if (field.isRequired) {
      return EmailRegex.test(value) && value !== ""
    }
    return EmailRegex.test(value) || value === ""
  };

  const validatePhoneNumber = (iso3Code: string, phoneNumber: string) => {
    const numberOfDigits = accountSettings.countries?.find(c => c.iso3 === iso3Code)!.dialDigits;
    if (!numberOfDigits)
      return false;
    return (numberOfDigits === 7 && /^\d{7}$/.test(phoneNumber)) ||
      (numberOfDigits === 8 && /^\d{8}$/.test(phoneNumber)) ||
      (numberOfDigits === 9 && /^\d{9}$/.test(phoneNumber)) ||
      (numberOfDigits === 10 && /^\d{10}$/.test(phoneNumber)) ||
      (numberOfDigits === 11 && /^\d{11}$/.test(phoneNumber)) ||
      (numberOfDigits === 12 && /^\d{12}$/.test(phoneNumber));
  }

  const validateDobField = (date: Date | undefined | null): boolean => {
    if (!date)
      return true;

    const newDateObj = new Date(date);

    return newDateObj instanceof Date &&
      !isNaN(newDateObj.valueOf()) &&
      newDateObj <= yesterdayDate &&
      newDateObj >= year1900Date
  };

  const mapSectionsToComponents = () => {
    if (!isEdition)
      return patient.patientFormSections
        .sort((s1, s2) => s1.section.order! - s2.section.order!)
        .map(s => {
          if (!s.patientFormSectionFields ||
            s.patientFormSectionFields.length === 0 ||
            s.patientFormSectionFields.every(f => f.field.isDeleted && f.value === null) ||
            (s.section.isDeleted && (s.patientFormSectionFields.length === 0 || s.patientFormSectionFields.every(f => f.value === null))))
            return <></>
          return <>
            <div className={classes.sectionContainer}>
              <Typography className={classes.sectionLabel}>
                {t(s.section.name!, { ns: sectionTNamespace })}
              </Typography>
              <div className={classes.fieldsContainer}>
                {s.patientFormSectionFields
                  .sort((f1, f2) => f1.field.order - f2.field.order)
                  .map(f => {
                    if (f.field.isDeleted && !f.value?.value)
                      return;
                    else
                      return <div className={classes.fieldContainer}>
                        <Typography className={classes.fieldNameLabel}>
                          {t(f.field.name, { ns: sectionTNamespace })} <span className={classes.fieldNameAsterisk}>{f.field.isRequired ? "*" : ""}</span>
                        </Typography>
                        {getFieldValueComponent(f.value)}
                      </div>
                  })}
              </div>
            </div>
            <div className={classes.divider}></div>
          </>
        });
    else
      return patient.patientFormSections
        .sort((s1, s2) => s1.section.order! - s2.section.order!)
        .map(s => {
          if (!s.patientFormSectionFields ||
            s.patientFormSectionFields.length === 0 ||
            s.patientFormSectionFields.every(f => f.field.isDeleted && f.value === null) ||
            (s.section.isDeleted && (s.patientFormSectionFields.length === 0 || s.patientFormSectionFields.every(f => f.value === null))))
            return <></>
          return <>
            <div className={classes.sectionContainer}>
              <Typography className={classes.sectionLabel}>
                {t(s.section.name!, { ns: sectionTNamespace })}
              </Typography>
              <Typography className={classes.requiredFieldsNoteLabel}>
                {t("Required fields")} <span className={classes.fieldNameAsterisk}>*</span>
              </Typography>
              <div className={classes.fieldsEditionContainer}>
                {s.patientFormSectionFields
                  .sort((f1, f2) => f1.field.order - f2.field.order)
                  .map(f => {
                    if (f.field.isDeleted && f.value === null)
                      return;
                    else {
                      const fieldAndValue = patientForEdit?.patientFormSectionFields.find(fv => fv.field.id === f.field.id);
                      return <div className={classes.fieldEditionContainer}>
                        {getInputForEditMode(fieldAndValue!)}
                      </div>
                    }
                  })}
              </div>
            </div>
            <div className={classes.editDivider}></div>
          </>
        });
  };

  const getFieldValueComponent = (valueModel: SectionFieldValueModel | null | undefined) => {
    if (valueModel?.value === null || valueModel?.value == undefined || valueModel?.value === "")
      return <Typography className={classes.fieldValueLabel}>
        {"--"}
      </Typography>

    switch (valueModel.type) {
      case SectionFieldType.Text:
        return <Typography className={classes.fieldValueLabel}>
          {valueModel.value as string}
        </Typography>
      case SectionFieldType.Email:
        return <Typography className={classes.fieldValueLabel}>
          {valueModel.value as string}
        </Typography>
      case SectionFieldType.Radio:
        return <Typography className={classes.fieldValueLabel}>
          {t((valueModel.value as FormRadioValue).item.value, { ns: sectionTNamespace })}
          {!!(valueModel.value as FormRadioValue).additionalFreeTextValue &&
            <> / {(valueModel.value as FormRadioValue).additionalFreeTextValue}</>
          }
        </Typography>
      case SectionFieldType.PhoneNumber: {
        const phoneNumberValue = valueModel.value as FormPhoneNumberValue;
        return <div className={classes.phoneNumberContainer}>
          <CountryFlagIcon iso={phoneNumberValue.countryIso} />
          <Typography className={classes.phoneNumberCodeLabel}>
            {"+" + accountSettings.countries.find(c => c.iso3 === phoneNumberValue.countryIso)!.dialCode}
          </Typography>
          <Typography className={classes.fieldValueLabel}>
            {conformToMask(phoneNumberValue.body, GetMobileMaskByTemp(accountSettings.countries.find(c => c.iso3 === phoneNumberValue.countryIso)!.dialTemplate)).conformedValue}
          </Typography>
        </div>
      }
    }
  };

  const getInputForEditMode = (fieldAndValueModel: PatientFormFieldAndValue) => {
    const sectionField = fieldAndValueModel.field;
    const valueField = fieldAndValueModel.value;
    if (!valueField?.value) {
      //return empty input for sectionField type
      switch (sectionField.type) {
        case SectionFieldType.Text:
          return getTextInput(sectionField);
        case SectionFieldType.Email:
          return getEmailInput(sectionField);
        case SectionFieldType.PhoneNumber:
          return getPhoneNumberInput(sectionField);
        case SectionFieldType.Radio:
          return getRadioInput(sectionField);
        default:
          return;
      }
    }

    if (sectionField.type !== valueField.type) {
      switch (sectionField.type) {
        case SectionFieldType.Text:
          switch (valueField.type) {
            case SectionFieldType.Email:
              //return TextInput with valueField.value
              return getTextInput(sectionField, valueField.value as string);
            case SectionFieldType.PhoneNumber:
              //return TextInput with PhoneNumber like "+52123456789"
              const phoneObj = valueField.value as FormPhoneNumberValue;
              const dialCode = accountSettings.countries.find(c => c.iso3 === phoneObj.countryIso)!.dialCode;
              const phoneAsString = `+${dialCode}${phoneObj.body}`
              return getTextInput(sectionField, phoneAsString);
            case SectionFieldType.Radio:
              //return TextInput with valueField.value.item.value
              const radioItemObj = valueField.value as FormRadioValue;
              return getTextInput(sectionField, radioItemObj.item.value);
          }
        case SectionFieldType.Email:
          //return empty input for Email type
          return getEmailInput(sectionField);
        case SectionFieldType.PhoneNumber:
          //return empty input for PhoneNumber type
          return getPhoneNumberInput(sectionField);
        case SectionFieldType.Radio:
          //return empty input for Radio type
          return getRadioInput(sectionField);
      }
    }

    //dealing with filled RadioInput
    if (sectionField.type === SectionFieldType.Radio && valueField.type === SectionFieldType.Radio) {
      if (sectionField.items
        .filter(i => !i.isDeleted)
        .map(i => i.id)
        .some(i => i === (valueField.value as FormRadioValue).item.fieldItemId)) {
        //return RadioInput with valueField.value.item checked
        return getRadioInput(sectionField, valueField.value as FormRadioValue);
      }
      else {
        //return RadioInput with FAKE valueField.value.item checked
        return getRadioInput(sectionField, valueField.value as FormRadioValue, true);
      }
    }

    //dealing with other filled field types
    switch (sectionField.type) {
      case SectionFieldType.Text:
        //return filled TextInput
        return getTextInput(sectionField, valueField.value as string);
      case SectionFieldType.Email:
        //return filled EmailInput
        return getEmailInput(sectionField, valueField.value as string);
      case SectionFieldType.PhoneNumber:
        //return filled PhoneNumberInput
        return getPhoneNumberInput(sectionField, valueField.value as FormPhoneNumberValue);
    }
  };

  const getTextInput = (field: SectionFieldModel, value: string | null = null) => {
    return <>
      <PatientFormTextInput
        key={field.id!}
        value={value ? t(value, { ns: sectionTNamespace }) : ""}
        setValue={(v) => setFieldValue(field.id!, v)}
        label={t(field.name, { ns: sectionTNamespace })}
        isValid={(v) => validateTextField(field, v)}
        required={field.isRequired}
        placeholder={t(field.name, { ns: sectionTNamespace })}
        maxLength={100} />
    </>
  };

  const getEmailInput = (field: SectionFieldModel, value: string | null = null) => {
    return <>
      <PatientFormTextInput
        key={field.id!}
        value={value ? value : ""}
        setValue={(v) => setFieldValue(field.id!, v)}
        label={t(field.name, { ns: sectionTNamespace })}
        isValid={(v) => validateEmailField(field, v)}
        required={field.isRequired}
        placeholder={t(field.name, { ns: sectionTNamespace })}
        maxLength={100} />
    </>
  };

  const getPhoneNumberInput = (field: SectionFieldModel, value: FormPhoneNumberValue | null = null) => {
    return <>
      <PatientFormMobileInput
        key={field.id!}
        mobileNumber={value ?? new FormPhoneNumberValue({ countryIso: accountSettings.countryInSettings!.iso3, body: "" })}
        label={t(field.name, { ns: sectionTNamespace })}
        setMobileNumber={(v) => setFieldValue(field.id!, v)}
        required={field.isRequired}
        countryInSettings={accountSettings.countryInSettings!}
        countries={accountSettings.countries!} />
    </>
  };

  const getRadioInput = (field: SectionFieldModel, value: FormRadioValue | null = null, isFakeRadioItem: boolean = false) => {
    const getSelectedItem = () => {
      if (!value && !isFakeRadioItem) {
        //Possibly QA team will ask for this (TODO: fix lack of real value in request)
        // if (field.isRequired) {
        //   //return first selected item
        //   return field.items.filter(i => !i.isDeleted).sort((i1, i2) => i1.order - i2.order)[0]
        // }
        // else {
        //   //return no selected item
        //   return null;
        // }
        return null;
      };
      if (value && !isFakeRadioItem)
        //return genuine selected item
        return field.items.filter(i => i.id === value.item.fieldItemId)[0];
      if (value && isFakeRadioItem)
        //return "fake"/old selected item
        return new SectionFieldItemModel({ id: value.item.fieldItemId, name: value.item.value, order: 0 });

      return null;
    };

    const setRadioValue = (fieldId: string, i: SectionFieldItemModel) => {
      const radioValue = new FormFieldItemValue();
      radioValue.fieldItemId = i.id!;
      radioValue.value = i.name;

      const itemModel = new FormRadioValue();
      itemModel.item = radioValue;
      itemModel.additionalFreeTextValue = value ? value.additionalFreeTextValue : ""
      setFieldValue(fieldId, itemModel);
    };

    return <>
      <PatientFormRadioGroup
        key={field.id!}
        field={field}
        value={value}
        selectedItem={getSelectedItem()}
        setSectionFieldItem={(i) => setRadioValue(field.id!, i)}
        setFreeTextValue={
          field.isAdditionalTextareaEnabled
            ? (av) => { setFieldValue(field.id!, new FormRadioValue({ item: value?.item, additionalFreeTextValue: av }), av) }
            : undefined}
      />
    </>
  };

  const getGenderString = (patient: PatientGeneralInfoResponse): string => {
    switch (patient.gender) {
      case Gender.Female:
        return t("Female");
      case Gender.Male:
        return t("Male");
      case Gender.PreferNotToSay:
        return t("I prefer not to say");
      case Gender.Custom:
        return t("gender_custom");
      default:
        return "--";
    }
  };

  const getGenderAsRadioValue = (gender: Gender | null) => {
    if (gender === null) return null;
    if (gender === Gender.Male) return new FormRadioValue({ item: maleGender });
    if (gender === Gender.Female) return new FormRadioValue({ item: femaleGender });
    if (gender === Gender.PreferNotToSay) return new FormRadioValue({ item: secretGender });
    return new FormRadioValue({ item: customGender });
  };

  const getGenderItemModels = () => {
    return [
      new SectionFieldItemModel({ id: maleGender.fieldItemId, name: maleGender.value }),
      new SectionFieldItemModel({ id: femaleGender.fieldItemId, name: femaleGender.value }),
      new SectionFieldItemModel({ id: secretGender.fieldItemId, name: secretGender.value }),
      new SectionFieldItemModel({ id: customGender.fieldItemId, name: customGender.value })];
  };

  const getDateOfBirthString = (date: Date | undefined) => {
    if (!date)
      return "--";

    var newDateObj = new Date(date);
    const day = newDateObj.toLocaleString(
      localeApp,
      {
        day: "numeric",
      }
    );
    let month = newDateObj.toLocaleString(
      localeApp,
      {
        month: "long",
      }
    );

    const year = newDateObj.toLocaleString(
      localeApp,
      {
        year: "numeric",
      }
    );

    return localeApp === "es"
      ? `${day}/${month}/${year}`
      : `${month.charAt(0).toUpperCase() + month.slice(1)}/${day}/${year}`;
  };

  const StaticBasicInformationSection: React.FC = () => {
    return <>
      <div className={classes.sectionContainer}>
        <Typography className={classes.sectionLabel}>
          {t("default_section_name_Basic patient information", { ns: sectionTNamespace })}
        </Typography>
        <div className={classes.fieldsContainer}>
          <div className={classes.fieldContainer}>
            <Typography className={classes.fieldNameLabel}>
              {t("default_field_name_First name(s)", { ns: sectionTNamespace }) + " "} <span className={classes.fieldNameAsterisk}>*</span>
            </Typography>
            <Typography className={classes.fieldValueLabel}>
              {t(patient.firstName)}
            </Typography>
          </div>
          <div className={classes.fieldContainer}>
            <Typography className={classes.fieldNameLabel}>
              {t("default_field_name_Last name", { ns: sectionTNamespace }) + " "} <span className={classes.fieldNameAsterisk}>*</span>
            </Typography>
            <Typography className={classes.fieldValueLabel}>
              {t(patient.lastName)}
            </Typography>
          </div>
          <div className={classes.fieldContainer}>
            <Typography className={classes.fieldNameLabel}>
              {t("default_field_name_Mobile", { ns: sectionTNamespace }) + " "} <span className={classes.fieldNameAsterisk}>*</span>
            </Typography>
            {patient.mobile && hasCustomerInfoVisible
              ? <div className={classes.phoneNumberContainer}>
                <CountryFlagIcon iso={patient.mobileCodeIso3} />
                <Typography className={classes.phoneNumberCodeLabel}>
                  {"+" + patient.mobileCode}
                </Typography>
                <Typography className={classes.fieldValueLabel}>
                  {patient.mobile ? conformToMask(patient.mobile, GetMobileMaskByTemp(patient.mobileTemplate)).conformedValue : "--"}
                </Typography>
              </div>
              : <Typography className={classes.fieldValueLabel}>
                {"--"}
              </Typography>}
          </div>
          <div className={classes.fieldContainer}>
            <Typography className={classes.fieldNameLabel}>
              {t("default_field_name_Email", { ns: sectionTNamespace })}
            </Typography>
            <Typography className={classes.fieldValueLabel}>
              {patient.email ? t(patient.email) : "--"}
            </Typography>
          </div>
          <div className={classes.fieldContainer}>
            <Typography className={classes.fieldNameLabel}>
              {t("default_field_name_Gender", { ns: sectionTNamespace })}
            </Typography>
            <Typography className={classes.fieldValueLabel}>
              {getGenderString(patient)}
            </Typography>
          </div>
          {patient.gender === Gender.Custom &&
            <div className={classes.fieldContainer}>
              <Typography className={classes.fieldNameLabel}>
                {t("default_field_name_Custom gender", { ns: sectionTNamespace })}
              </Typography>
              <Typography className={classes.fieldValueLabel}>
                {patient.customGender}
              </Typography>
            </div>
          }
          <div className={classes.fieldContainer}>
            <Typography className={classes.fieldNameLabel}>
              {t("default_field_name_Date of Birth", { ns: sectionTNamespace })}
            </Typography>
            <Typography className={classes.fieldValueLabel}>
              {getDateOfBirthString(patient.dateOfBirth)}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.sectionContainer}>
        <Typography className={classes.sectionLabel}>
          {t("default_section_name_Notifications", { ns: sectionTNamespace })}
        </Typography>
        <div className={classes.fieldsContainer}>
          <div className={classes.fieldContainer}>
            <Typography className={classes.fieldNameLabel}>
              {t("default_field_name_Appointment", { ns: sectionTNamespace }) + " "}
            </Typography>
            <Typography className={classes.fieldValueLabel}>
              {patient.contactableAppointments ? t("Yes") : t("No")}
            </Typography>
          </div>
          <div className={classes.fieldContainer}>
            <Typography className={classes.fieldNameLabel}>
              {t("default_field_name_Massive sendings", { ns: sectionTNamespace }) + " "}
            </Typography>
            <Typography className={classes.fieldValueLabel}>
              {patient.contactableMassiveSendings ? t("Yes") : t("No")}
            </Typography>
          </div>
        </div>
      </div>
      <div className={classes.divider}></div>
    </>
  };

  const NotesSection: React.FC = () => {
    return <div className={classes.sectionContainer}>
      <Typography className={classes.sectionLabel}>
        {t("Internal notes")}
      </Typography>
      <div className={classes.fieldsContainer}>
        <div className={classes.fieldContainer}>
          <Typography className={patient.notes ? classes.notes : classes.noNotes}>
            {patient.notes ? <Interweave content={patient.notes} /> : t("No notes.")}
          </Typography>
        </div>
      </div>
    </div>
  };

  const EditButton: React.FC = () => {
    return hasCustomerInfoVisible ? <div className={classes.editButtonContainer}>
      <Button
        className={classes.editButton}
        onClick={handleEditButtonClick}
      >
        <EditIcon style={{ width: 16, height: 16, marginRight: "12px" }} />
        <Typography className={classes.editButtonLabel}>
          {t("Edit info")}
        </Typography>
      </Button>
    </div>
      : <></>
  };

  const EditModeButtonsGroup: React.FC = () => {
    return <div className={classes.editModeButtonsGroupContainer}>
      <Button
        className={classes.cancelButton}
        onClick={handleCancelEditButtonClick}
      >
        <CrossIcon style={{ width: 16, height: 16, marginRight: "12px" }} viewBox="0 0 16 16" />
        <Typography className={classes.cancelButtonLabel}>
          {t("Cancel")}
        </Typography>
      </Button>
      <Button
        className={classes.saveButton}
        onClick={handleSaveChangesButtonClick}
        disabled={stringify(new UpdatePatientFormDataRequest(patient)) === stringify(patientForEdit) || !isFormValid}
      >
        <SaveIcon style={{ width: 16, height: 16, marginRight: "12px" }} />
        <Typography className={classes.saveButtonLabel}>
          {t("Save changes")}
        </Typography>
      </Button>
    </div>
  };

  const CloseModalContent: React.FC = () => {
    return (<div className={classes.closeModalContentContainer}>
      <Typography className={classes.closeModalTitleText}>
        {t("Are you sure you want to cancel the editing of the data?")}
      </Typography>
      <div className={classes.separator} />
      <Typography className={classes.closeModalContentText}>
        {t("The changes made will not be saved")}
      </Typography>
    </div>);
  };


  return <>
    <div className={classes.root}>
      <div className={classes.sectionsListContainer}>
        {!isEdition
          ? <StaticBasicInformationSection />
          : <>
            <div className={classes.sectionContainer}>
              <Typography className={classes.sectionLabel}>
                {t("default_section_name_Basic patient information", { ns: sectionTNamespace })}
              </Typography>
              <Typography className={classes.requiredFieldsNoteLabel}>
                {t("Required fields")} <span className={classes.fieldNameAsterisk}>*</span>
              </Typography>
              <div className={classes.fieldsEditionContainer}>
                <div className={classes.fieldEditionContainer}>
                  <PatientFormTextInput
                    key={"firstName"}
                    value={patientForEdit?.firstName ?? ""}
                    setValue={setFirstName}
                    label={t("default_field_name_First name(s)", { ns: sectionTNamespace })}
                    isValid={(v) => MultipleSpacesNameRegex.test(v) && v.trim() !== ""}
                    required={true}
                    placeholder={t("default_field_name_First name(s)", { ns: sectionTNamespace })}
                    maxLength={100} />
                </div>
                <div className={classes.fieldEditionContainer}>
                  <PatientFormTextInput
                    key={"lastName"}
                    value={patientForEdit?.lastName ?? ""}
                    setValue={setLastName}
                    label={t("default_field_name_Last name", { ns: sectionTNamespace })}
                    isValid={(v) => MultipleSpacesNameRegex.test(v) && v.trim() !== ""}
                    required={true}
                    placeholder={t("default_field_name_Last name", { ns: sectionTNamespace })}
                    maxLength={100} />
                </div>
                <div className={classes.fieldEditionContainer}>
                  <PatientFormMobileInput
                    key={"phoneNumber"}
                    mobileNumber={patientForEdit
                      ? new FormPhoneNumberValue({
                        countryIso: patientForEdit.mobileCodeIso3,
                        body: patientForEdit.mobile
                      })
                      : new FormPhoneNumberValue({
                        countryIso: accountSettings.countryInSettings!.iso3,
                        body: ""
                      })}
                    label={t("default_field_name_Mobile", { ns: sectionTNamespace })}
                    setMobileNumber={setPhoneNumber}
                    required={true}
                    countryInSettings={accountSettings.countryInSettings!}
                    countries={accountSettings.countries!} />
                </div>
                <div className={classes.fieldEditionContainer}>
                  <PatientFormTextInput
                    key={"email"}
                    value={patientForEdit?.email ?? ""}
                    setValue={setEmail}
                    label={t("default_field_name_Email", { ns: sectionTNamespace })}
                    isValid={(v) => EmailRegex.test(v) || v === ""}
                    required={false}
                    placeholder={t("default_field_name_Email", { ns: sectionTNamespace })}
                    maxLength={100} />
                </div>
                <div className={classes.fieldEditionContainer}>
                  <PatientFormRadioGroup
                    key={"gender"}
                    field={new SectionFieldModel({
                      name: t("default_field_name_Gender", { ns: sectionTNamespace }),
                      items: getGenderItemModels(),
                      isRequired: false,
                      isDeleted: false,
                      isAdditionalTextareaEnabled: false
                    })}
                    value={getGenderAsRadioValue(patientForEdit?.gender ?? null)}
                    selectedItem={patientForEdit?.gender
                      ? new SectionFieldItemModel({ id: getGenderAsRadioValue(patientForEdit.gender!)?.item.fieldItemId })
                      : null}
                    setSectionFieldItem={(i) => setPatientForEdit(prev => ({ ...prev!, gender: +i.id! }))}
                  />
                  {patientForEdit?.gender === Gender.Custom &&
                    <div style={{marginTop: 10}}>
                      <PatientFormTextInput
                        key={"customGender"}
                        value={patientForEdit?.customGender ?? ""}
                        setValue={setCustomGender}
                        label={""}
                        isValid={(v) => MultipleSpacesNameRegex.test(v) && v.trim() !== ""}
                        required={true}
                        placeholder={t("default_field_name_Custom gender", { ns: sectionTNamespace })}
                        maxLength={100}/>
                    </div>
                  }
                </div>
                <div className={classes.fieldEditionContainer}>
                  <PatientFormDateInput
                    key={"dob"}
                    value={patientForEdit?.dateOfBirth ?? null}
                    setValue={setDob}
                    label={t("default_field_name_Date of Birth", { ns: sectionTNamespace })}
                    required={false}
                    isValid={(v) => validateDobField(v)} 
                    minDate={year1900Date} 
                    maxDate={yesterdayDate} />
                </div>
              </div>
            </div>
            <div className={classes.editDivider}></div>
            <div className={classes.sectionContainer}>
              <Typography className={classes.sectionLabel}>
                {t("default_section_name_Notifications", { ns: sectionTNamespace })}
              </Typography>
              <div className={classes.fieldsEditionContainer}>
                <div className={classes.fieldEditionContainer}>
                  <div className={classes.switch}>
                    <Switch
                      onChange={setContactableAppointments}
                      value={patientForEdit?.contactableAppointments ?? false}
                    />
                    <div className={classes.switchLabel}>
                      <label className={classes.label}>
                        {t("default_field_name_Appointment", { ns: sectionTNamespace })}
                      </label>
                      <p className={classes.description}>
                        {appointmentDescription}
                      </p>
                    </div>
                  </div>
                </div>
                <div className={classes.fieldEditionContainer}>
                  <div className={classes.switch}>
                    <Switch
                      onChange={setContactableMassiveSendings}
                      value={patientForEdit?.contactableMassiveSendings ?? false}
                    />
                    <div className={classes.switchLabel}>
                      <label className={classes.label}>
                        {t("default_field_name_Massive sendings", { ns: sectionTNamespace })}
                      </label>
                      <p className={classes.description}>
                        {massiveSendingsDescription}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.editDivider}></div>
          </>}
        {mapSectionsToComponents()}
        {!isEdition
          ? <NotesSection />
          : <div className={classes.sectionContainer}>
            <div className={classes.notesEditLabelContainer}>
              <InternalNotesIcon />
              <Typography className={classes.sectionLabel}>
                {t("Internal notes")}
              </Typography>
            </div>
            <div className={classes.fieldsContainer}>
              <div className={classes.tinyMceContainer}>
                <div className={`${classes.tinyMceOverrides} ${focusedNotes ? classes.tinyMceFocused : classes.tinyMceUnfocused}`}>
                  <Editor
                    apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
                    init={{
                      height: 160,
                      width: "auto",
                      menubar: false,
                      statusbar: false,
                      placeholder: t("No notes."),
                      plugins: 'wordcount paste lists',
                      toolbar: 'bold italic underline | numlist bullist ',
                      content_style: "body {fontFamily: Inter, sans-serif; font-weight: 400; font-size: 14px; color: #323947 }",
                      entity_encoding: "raw",
                      formats: {
                        bold: { inline: 'b' },
                        italic: { inline: 'i' },
                        underline: { inline: 'u' }
                      },
                      paste_as_text: true,
                      extended_valid_elements: "b, i, s",
                      invalid_elements: "strong, em, span",
                      forced_root_block: ""
                    }}
                    value={patientForEdit?.notes}
                    onFocusIn={() => setFocusedNotes(true)}
                    onBlur={() => setFocusedNotes(false)}
                    onInit={setNotesTextLengthOnInit}
                    onEditorChange={setNotes}
                    outputFormat="html"
                    onChange={(e) => {
                      if (notesTextLength >= maxNotesTextLength) {
                        e.preventDefault();
                      }
                    }}
                  />
                  <div className={classes.counterWrapper}>
                    <span className={classes.charCounter}>{notesTextLength + "/" + maxNotesTextLength}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>}
        {!isEdition
          ? <EditButton />
          : <EditModeButtonsGroup />}
      </div>
    </div>
    <ConfirmActionModal
      classModal={classes.closeConfirmationModal}
      content={<CloseModalContent />}
      open={cancelEditModalIsOpen}
      onClose={() => setCancelEditModalIsOpen(false)}
      onConfirm={handleCloseEdit}
    />
  </>
};