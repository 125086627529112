import { makeStyles } from "@material-ui/core/styles";

const useStyles = () =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      backgroundColor: theme.colors.white,
      position: "relative"
    },
    input: {
      backgroundColor: theme.colors.white,
      height: 40,
      padding: "13px 0px 13px 12px",
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      width: "100%"
    },
    inputWidth: {
      width: "90%"
    },
    counter: {
      position: "absolute",
      bottom: 7,
      right: 7,
      fontSize: 12,
      color: "#B5B9C1",
      padding: "5px",
    },
    inputText: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey5,
      "&::placeholder": {
        fontWeight: "normal",
        color: theme.colors.grey5,
        opacity: 0.8
      },
    },
    inputFocused: {
      border: "solid 1px #6462F3",
      padding: "14px 0px 14px 12px",
      width: "100%",
    },
    inputDisabled: {
      color: "#676767",
    },
    inputError: {
      color: theme.colors.clearRed,
      border: "solid",
      borderWidth: 1,
      borderColor: theme.colors.clearRed
    },
  }));

export default useStyles;
