import * as React from "react";
import { useEffect, useState } from "react";
import clsx from 'clsx';

import { useHistory } from "react-router-dom";

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { schedulerActions } from "../../../redux/scheduler-slice";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";

import Select, { SelectItem } from "../../common/Select";

import { Url } from "../../../constants/url";
import useStyles from "./css";
import { AppointmentListToolbarProps } from "./props";
import { MinusArrowIcon } from "../../../assets/icons";
import Typography from "@material-ui/core/Typography";
import MultiSelect from "../../common/MultiSelect";
import DateIcon from "../../common/DateIcon/date-icon";
import { LocalStorageKey } from "../../../constants/local-storage-key";
import EstablishmentSelector from "../../navbar/EstablishmentSelector";
import useLocalStorageState from "../../../hooks/useLocalStorageState";
import { enterpriseActions } from "../../../redux/enterprise-slice";
import Input from "../../common/Input";
import { AppointmentStatus } from "../../../models/enums/appointment-status";


export default function AppointmentListToolbar(props: AppointmentListToolbarProps) {
  
  const { allUsers, selectedUsers, selectedStatuses, saveSelectedUsers, saveSelectedStatuses } = props;
  
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  const history = useHistory();
  
  const dispatch = useAppDispatch();
  
  const userInfo = useAppSelector(state => state.userInfo);
  const currentUserAuthId = userInfo.userId;
  const hasAccessToScheduleAllSpecialists = userInfo.hasAccessToScheduleAllSpecialists;
  
  const accountSettings = useAppSelector((state) => state.accountSettings);
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const currentDate = useAppSelector((state) => state.scheduler.currentDate);
  const currentViewName = useAppSelector((state) => state.scheduler.currentViewName);
  const { setCurrentDate, setCurrentViewName } = schedulerActions;
  
  const establishmentsStorageKey = LocalStorageKey.Establishments + userInfo.userId;
  const { setSelectedEstablishments } = enterpriseActions;
  // @ts-ignore
  const [accountEstablishments, setAccountEstablishments] = useLocalStorageState({ itemKey: establishmentsStorageKey, dispatchFunction: setSelectedEstablishments });
  
  const views: SelectItem[] = [
    { key: "Week", value: t("Week") } as SelectItem,
    { key: "Day", value: t("Day") } as SelectItem
  ];
  
  const statuses: SelectItem[] = [
    { key: AppointmentStatus.NotConfirmed, value: t("Not Confirmed") } as SelectItem,
    { key: AppointmentStatus.Confirmed, value: t("Confirmed") } as SelectItem,
    { key: AppointmentStatus.NotArrived, value: t("Not Arrived") } as SelectItem,
    { key: AppointmentStatus.Arrived, value: t("Arrived") } as SelectItem,
  ];
  
  useEffect(() => {
      if (currentUserAuthId) {
        const viewName = localStorage.getItem(LocalStorageKey.SchedulerViewName + currentUserAuthId);
        if (viewName) {
          dispatch(setCurrentViewName(viewName));
        }
      }
    }, []);
  
  function changeCurrentDate(date: Date) {
    dispatch(setCurrentDate(date));
  }
  
  function handleSelectUsers(users: any) {
    saveSelectedUsers(users);
  }
  
  function handleResetUsers() {
    saveSelectedUsers([]);
  }
  
  function handleSelectStatuses(users: any) {
    saveSelectedStatuses(users);
  }
  
  function handleResetStatuses() {
    saveSelectedStatuses([]);
  }
  
  function handleSelectView(event: any) {
    changeCurrentDate(new Date());
    dispatch(setCurrentViewName(event.target.value));
    if (currentUserAuthId) {
      localStorage.setItem(LocalStorageKey.SchedulerViewName + currentUserAuthId, event.target.value);
    }
  }
  
  const formattedDate = (): string => {
    if (currentViewName === "Week") {
      const startDate = new Date(currentDate);
      const parsedCurrentDate = new Date(currentDate);
      startDate.setDate(
        parsedCurrentDate.getDate() -
        ((parsedCurrentDate.getDay() !== 0 ? parsedCurrentDate.getDay() : 7) - 1)
      );
      const endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 6);
      return `${startDate.toLocaleDateString(
        localeApp,
        {
          month: "short",
          day: "numeric",
        }
      )} - ${endDate.toLocaleDateString(localeApp, {
        month: "short",
        day: "numeric",
      })}`;
    } else { // Day
      return currentDate.toLocaleDateString(localeApp,
        {
          weekday: "long",
          month: "long",
          day: "numeric",
        }).replace(',', '');
    }
  };
  
  function handleNavigate(direction: string) {
    let date = new Date(currentDate);
    const step = currentViewName === "Week" ? 7 : 1;
    if (direction === "forward") {
      date.setDate(date.getDate() + step);
      changeCurrentDate(date);
    } else if (direction === "back") {
      date.setDate(date.getDate() - step);
      changeCurrentDate(date);
    }
  }
  
  function handleDateChange(date: MaterialUiPickersDate) {
    changeCurrentDate(date as Date)
  }
  
  function handleGoToday() {
    changeCurrentDate(new Date());
  }
  
  return (
    <div className={clsx(classes.container, {[classes.separator]: !accountSettings.isEnterprise})} >
      <div className={classes.dateNavigator}>
        <Button
          className={`${classes.button} ${classes.goToday}`}
          onClick={handleGoToday}
        >
          {t("Today")}
        </Button>
        <DateIcon value={currentDate} onChange={handleDateChange} />
        <Typography
          className={`${classes.dateNavigatorText} ${currentViewName === "Day" ? classes.dateNavigatorDayText : ""}`}>
          {formattedDate()}
        </Typography>
        <IconButton
          className={classes.dateNavigatorDirectionButton}
          onClick={() => {
            handleNavigate("back");
          }}
        >
          <MinusArrowIcon/>
        </IconButton>
        <IconButton
          className={classes.dateNavigatorDirectionButton}
          onClick={() => {
            handleNavigate("forward");
          }}
        >
          <MinusArrowIcon style={{ transform: "rotate(180deg)" }}/>
        </IconButton>
      </div>
      <Select
        items={views}
        value={currentViewName}
        classes={{ root: `${classes.focused} ${classes.selected}` }}
        styleClass={`${classes.select} ${classes.viewSelect}`}
        onChange={handleSelectView}
      />
  
      {
        accountSettings.isEnterprise ?
          <>
            <div className={classes.establishmentSelectorWrapper}>
              <EstablishmentSelector
                establishments={accountEstablishments ?? []}
                setEstablishments={setAccountEstablishments}
                items={accountSettings?.establishments ?? []}
              />
            </div>
          </>
          : null
      }
      <MultiSelect
        allItemsPlaceholder={t("All Appts")}
        filterPlaceholder={""}
        items={statuses}
        values={selectedStatuses}
        classes={{ root: `${classes.focused} ${classes.selected}` }}
        styleClass={`${classes.multiSelect} ${classes.appointmentsSelect}`}
        width={205}
        height={372}
        onChange={handleSelectStatuses}
        onReset={handleResetStatuses}
      />
      {hasAccessToScheduleAllSpecialists &&
        <MultiSelect
          allItemsPlaceholder={t("All specialists")}
          filterPlaceholder={t("Search a specialist")}
          items={allUsers}
          sort={true}
          showSearch={true}
          values={selectedUsers}
          classes={{ root: `${classes.focused} ${classes.selected}` }}
          styleClass={`${classes.multiSelect} ${classes.specialistsSelect}`}
          width={205}
          height={372}
          onChange={handleSelectUsers}
          onReset={handleResetUsers}
        />
      }
      {!hasAccessToScheduleAllSpecialists &&
        <Input
          value={userInfo.fullName}
          width={205}
          inputClass={classes.input}
          disabled={true}
          readOnly={true}
        />
      }
      
      {accountSettings?.isEnterprise ? <></> :
        <Button
          className={`${classes.button} ${classes.newAppointment}`}
          onClick={() => {
            history.push(`${Url.Appointments.Main}/new`);
          }}>
          {t("Create appt")}
        </Button>
      }
    </div>
  );
}
