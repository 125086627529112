import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column"
  },
  divider: {
    backgroundColor: "rgba(201, 205, 211, 0.5) !important",
    marginTop: "12px !important",
    marginBottom: "18px !important"
  },
  switchBox: {
    marginTop: 20,
    marginLeft: 36
  },
  sectionRow: {
    display: "flex",
    alignItems: "center"
  },
  sectionLabel: {
    color: theme.colors.grey4,
    fontFamily: "Inter, sans-serif",
    fontWeight: 700,
    fontSize: "14px",
    marginLeft: 10
  },
})
);

export default useStyles;