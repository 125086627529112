import { Timezone } from "../models/timezone";
import { ApiClient } from "./api-client";
import { Response } from "./../models/response";
import { useAppSelector } from "../redux/hooks";

export class TimezoneService {

    private static baseUrl: string = "/timezone";

    static updateTimezone(timezoneId: number, establishmentAccountId?: string): Promise<Response> {
        const request: any = { Timezone: timezoneId };

        if (establishmentAccountId) {
            request.AccountId = establishmentAccountId;
        }

        return ApiClient.post(`${this.baseUrl}/updatetimezone`, request)
            .then(data => {
                return new Response(data.timezone, data.appointmentsAffected);
            });
    }


    static async getTimezones(locale?: string, establishmentAccountId?: string): Promise<Timezone[]> {
        let endpoint = `${this.baseUrl}/gettimezones?locale=${locale ?? 'es'}`;

        if (establishmentAccountId) {
            endpoint += `&accountId=${establishmentAccountId}`;
        }

        const data = await ApiClient.get(endpoint);

        return data.timezones;
    }

}
