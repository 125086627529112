import React, { useState } from "react";

import { DataAccordionProps } from "./props";
import useStyles from "./css";

import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from "@material-ui/core";
import { ArrowDownIcon} from "../../../assets/icons";
import { Color } from "../../../constants/colors";


export default function DataAccordion(props: DataAccordionProps) {
    const {
        headerIcon,
        title,
        firstItemComponent,
        firstItemComponentHeight,
        bodyItems,
        isExpandedByDefault,
        ...otherProps
    } = props;

    const classes = useStyles(firstItemComponentHeight)();

    const [expanded, setExpanded] = useState<boolean>(isExpandedByDefault ?? false);

    const toggleAccordion = () => {
        setExpanded((prev) => !prev)
    }

    const getBodyItems = () => {
        return bodyItems
            ? <>
                <div className={classes.divider}></div>
                {bodyItems}
            </>
            : <></>
    }

    return <>
        <div className={classes.root}>
            <Accordion
                expanded={bodyItems ? expanded : false}
            >
                <AccordionSummary
                    expandIcon={bodyItems ? <ArrowDownIcon style={{ height: 15, width: 15, color: Color.clear1 }} /> : <></>}
                    IconButtonProps={{
                        onClick: toggleAccordion,
                        disabled: !bodyItems
                    }}
                >
                        <div className={classes.headerTitleContainer}>
                            {headerIcon}
                            <Typography className={classes.headerLabel}>
                                {title}
                            </Typography>
                        </div>
                        <div className={classes.headerItemContainer}>
                            {firstItemComponent}
                        </div>
                </AccordionSummary>
                <AccordionDetails className={classes.allItemsContainer}>
                    {getBodyItems()}
                </AccordionDetails>
            </Accordion>
        </div>
    </>
};