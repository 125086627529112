import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: 360,
    height: 72,
    position: "sticky",
    bottom:"0px",
    backgroundColor:"#ffffff",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  messagesIconContainer: {
    width: 40,
    height: 40,
    marginRight: 12
  },
  messageRing: {
    width: 40,
    height: 40,
  },
  manyMessagesRemain: {
    color: theme.colors.clearGreen
  },
  halfMessagesRemain: {
    color: theme.colors.clearYellow
  },
  lowMessagesRemain: {
    color: theme.colors.clearRed
  },
  noMessagesRemain: {
    color: theme.colors.grey1
  },
  messagesText: {
    width: 260,
    height: 34,
    
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    borderRadius: 17,
    boxShadow: "0px 1px 6px #E5E7EA",
    
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    fontWeight: 700
  }
  })
);

export default useStyles;