import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
    "@global": {
        ".ant-picker-time-panel-cell-disabled": {
            display: "none"
        },
    },
    root: {},
    title: {
        fontFamily: "Roboto",
        fontSize: "12px",
        color: "#919CA5",
        margin: 0,
    },
    required: {
        color: "#6462F3",
    },
    optional: {
        fontWeight: "normal",
    },
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "24px 0px 0px 0px",
    },
    switch: {
        display: "flex",
        alignItems: "center",
    },
    switchLabel: {
        margin: "0px 0px 0px 16px",
    },
    label: {
        ...theme.typography.txtBody2,
        margin: 0,
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "bold",
        color: theme.colors.grey4,
        display: "block",
        marginBottom: "0.5rem",
    },
    description: {
        margin: 0,
        color: "#919CA5",
        fontFamily: "Roboto",
        fontSize: 12,
        lineHeight: "14px",
        fontWeight: "normal",
    },
    inputSpecialist: {
        ...theme.typography.txtBody2,
        backgroundColor: theme.colors.grey1,
        height: 40,
        border: "solid 1px rgba(0, 0, 0, 0.1)",
        borderRadius: 5,
        width: 354,
            "&::placeholder": {
                fontFamily: "Roboto",
                fontStyle: "normal",
                fontSize: 12,
                color: "#5C6477",
            },
    },
    button: {
        width: 200,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
    },
    modal: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: "1px solid #dbdcdd",
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: "40px 40px 48px",
        width: 812,
        height: 399,
    },
    modalHeader: {
        display: "flex",
        alignItems: "center",
    },
    modalTitle: {
        width: "100%",
    },
    modalTitleText: {
        fontSize: "14px",
        fontWeight: "bold",
        color: "#5C6477",
    },
    modalActions: {
        display: "flex",
        justifyContent: "flex-end",
    },
    goBack: {
        marginRight: 16,
        background: "#F6F6F6",
        color: "#ACB7C0",
    },
    createCustomer: {
        background: "#36CE91",
        color: "#F6F6F6",
        "&:hover": {
            background: "#36CE91",
        },
    },
    createDisabled: {
        color: "#F6F6F6 !important",
        opacity: "0.4",
    },
    divUploadPhoto: {
        width: 228,
        height: 127,
        backgroundColor: "#F6F6F6",
        border: "1px dashed #DBDCDD",
        borderRadius: 5,
        cursor: "pointer",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        backgroundPosition: "center top",
        display: "grid"
    },
    colPhoto: {
        width: 228
    },
    colNotes: {
        width: 479,
        "& input": {
            height: 190,
            backgroundColor: "#F6F6F6",
            boxSizing: "border-box",
            borderRadius: 5,
            padding: "13px 0px 13px 12px",
            border: "solid 1px rgba(0, 0, 0, 0.1)",
            width: 480,
            "&:focus": {
                outline: "none",
                boxShadow: 0,
                border: "solid 1px #6462F3",
            },
            "&::placeholder": {
                color: "#B5B9C1",
            },
        }
    },
    inputTextareaNotes: {
        height: 90,
        backgroundColor: "#F6F6F6",
        boxSizing: "border-box",
        borderRadius: 5,
        padding: "13px 0px 13px 12px",
        border: "solid 1px rgba(0, 0, 0, 0.1)",
        width: 480,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        color: "rgba(38, 41, 43, 0.8)", //"#919CA5",
        "&:focus": {
            outline: "none",
            boxShadow: 0,
            border: "solid 1px #6462F3",
        },
        "&::placeholder": {
            color: "#B5B9C1",
        },
    },
    notesInstructions: {
        display: "block",
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        color: "#919CA5"
    },
    iconPlus: {
        marginTop: 40,
        height: 25,
        textAlign: "center",
        color: theme.colors.clearMain
    },
    labelAddPhoto: {
        marginTop: 0,
        fontWeight: "bold",
        fontSize: 12,
        textAlign: "center",
        color: "#919CA5"
    },
    labelAddPhotoIns: {
        marginTop: -5,
        fontFamily: "Roboto",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 9,
        color: "#919CA5",
        textAlign: "center",
    },
    divPhotoInfo: {
        width: "100%",
        display: "inline",
    },
    divPhotoInfoIcon: {
        display: "inline",
        width: 20,
        height: 20,
        textAlign: "center",
        position: "relative",
        top: 4,
        left: 2
    },
    divPhotoInfoLabel: {
        display: "inline",
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 11,
        color: "#919CA5",
        opacity: "0.8",
        position: "relative",
        left: -5
    },
    divPrice: {
    },
    moneyIcon: {
        position: "relative",
        top: -30,
        right: -205,
        color: "#5C6477",
        width: 22,
        backgroundColor: "#F6F6F6"
    },
    divCategory: {
        width: 228,
    },
    divPhotoExistHover: {
        width: 228,
        height: 127,
        borderRadius: 5,
        opacity: 0,
        top: 0,
        position: "relative",
        "&:hover": {
            background: "rgba(29, 32, 39, 0.7)",
            display: "flow-root",
            opacity: 0.7
        }
    },
    iconCam: {
        marginTop: 40,
        height: 25,
        textAlign: "center",
        color: "#FFFFFF",
    },
    labelChangePhoto: {
        marginTop: 5,
        fontWeight: "bold",
        fontSize: 12,
        textAlign: "center",
        color: "#FFFFFF",
        opacity: 1
    },
    selectStyle: {
        color: theme.colors.grey5,
        fontWeight: "bold",
        fontSize: 12,
        height: 40,
        padding: "13px 0px 13px 12px",
        border: "solid 1px rgba(0, 0, 0, 0.1)",
        borderRadius: 5,
        width: 165,
        paddingRight: 10,
        backgroundColor: "#F6F6F6",
        cursor: "pointer",
        "& * .ant-picker-suffix, .ant-picker-clear": {
        display: "none",
        },
        "& * input": {
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 12,
        fontStyle: "normal",
        color: theme.colors.grey5,
        cursor: "pointer",
        },
    },
    selectHrsNotValid:{
        border: "1px solid red"
    },
    selectHrs: {
        "& div:after": {
        content: "'hrs'",
        marginLeft: "35px",
        marginBottom: "2px",
        position: "absolute",
        color: theme.colors.grey5,
        },
    },
    selectStyleHrs: {
        position: "relative",
        top: 1,
        left: -150,
        fontFamily: "Roboto",
        fontWeight: "bold",
        fontSize: 12,
        fontStyle: "normal",
    },
    labelH2: {
        fontFamily: "Inter, sans-serif",
        display: "flex",
        paddingTop: 7,
        color: "#5C6477",
        fontSize: "14px",
        lineHeight: "14px",
        fontWeight: "bold",
        fontStyle: "normal",
        marginBottom: 5,
    },
    switchBase: {
        padding: 1,
        opacity: 1,
        height: "100%",
        border: "3px solid #323947",
        "&.Mui-checked": {
            transform: "translateX(13px)",
            border: "0px",
            color: "#FFFFFF",
            "& + .MuiSwitch-track": {
                backgroundColor: theme.colors.clearMain,
                opacity: 1,
                border: "none",
            },
            "& * .MuiSwitch-thumb": {
                width: 15,
            },
            //
        },
        // "&$focusVisible .MuiSwitch-thumb": {
        //     color: "#6462F3",
        //     border: "6px solid #fff",
        // },
        "& * .MuiSwitch-thumb": {
            width: 12,
            height: 15,
            boxShadow: "none",
        },
    },
    controlLabel: {
      ...theme.typography.txtBody2,
      fontFamily: "Inter, sans-serif",
      fontWeight: "bold",
      display: "inline-flex",
      color: theme.colors.grey4,
      marginBottom: 8
    },
    dot: {
      ...theme.typography.txtBody2,
      color: theme.colors.clearMain,
      marginLeft: 5
    },
    dateText: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey5,
      "&::placeholder": {
        fontWeight: "normal",
        color: "#676767",
      },
    },
    clockPosition: {
        position: "absolute", 
        top: 10, 
        right: 10
    },
    input: {
        width: "320px !important",
        margin: "0 0 22px"
    }
}));

export default useStyles;
