import React from "react";

import { useTranslation } from "react-i18next";

import { TextField, Typography } from "@material-ui/core";

import useStyles from "./css";
import { EmailInputProps } from "./props";


export default function EmailInput(props: EmailInputProps) {
  
  const classes = useStyles();
  const { t } = useTranslation("general");
  
  const { value, onChangeHandler, placeholder, error, required } = props;
  return (
    <>
      <Typography className={classes.label}>
        {t("Email")} {required && <span className={classes.required}>*</span>}
      </Typography>
      <TextField
        className={classes.textInput}
        variant="outlined"
        value={value}
        onChange={onChangeHandler}
        placeholder={placeholder}
        InputProps={{
          className: `${classes.input} ${value.length > 0 ? classes.inputBoldWeight : classes.inputNormalWeight}`,
        }}
        error={error?.isError}
        helperText={error?.message}
      />
    </>
  );
}