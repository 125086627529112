import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionGearIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M19.2188 13.8749L17.875 13.1249C18.0313 12.3749 18.0313 11.6562 17.875 10.9062L19.2188 10.1562C19.375 10.0624 19.4375 9.87493 19.375 9.71868C19.0313 8.59368 18.4375 7.59368 17.6875 6.74993C17.5625 6.62493 17.375 6.59368 17.2188 6.68743L15.875 7.43743C15.3125 6.96868 14.6875 6.59368 14 6.34368V4.81243C14 4.65618 13.875 4.49993 13.6875 4.46868C12.5313 4.18743 11.375 4.21868 10.2813 4.46868C10.0938 4.49993 10 4.65618 10 4.81243V6.34368C9.28127 6.59368 8.65627 6.96868 8.09377 7.46868L6.75002 6.68743C6.59377 6.59368 6.40627 6.62493 6.28127 6.74993C5.53127 7.59368 4.93752 8.59368 4.59377 9.71868C4.53127 9.87493 4.59377 10.0624 4.75002 10.1562L6.09377 10.9062C5.96877 11.6562 5.96877 12.3749 6.09377 13.1249L4.75002 13.8749C4.59377 13.9687 4.53127 14.1562 4.59377 14.3124C4.93752 15.4374 5.53127 16.4374 6.28127 17.2812C6.40627 17.4062 6.59377 17.4374 6.75002 17.3437L8.09377 16.5937C8.65627 17.0624 9.28127 17.4374 10 17.6874V19.2187C10 19.3749 10.125 19.5312 10.2813 19.5937C11.4375 19.8437 12.5938 19.8124 13.6875 19.5937C13.875 19.5312 14 19.3749 14 19.2187V17.6874C14.6875 17.4374 15.3125 17.0624 15.875 16.5937L17.2188 17.3437C17.375 17.4374 17.5625 17.4062 17.6875 17.2812C18.4688 16.4374 19.0313 15.4374 19.4063 14.3124C19.4375 14.1562 19.375 13.9687 19.2188 13.8749ZM12 14.4999C10.5938 14.4999 9.50002 13.4062 9.50002 11.9999C9.50002 10.6249 10.5938 9.49993 12 9.49993C13.375 9.49993 14.5 10.6249 14.5 11.9999C14.5 13.4062 13.375 14.4999 12 14.4999Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}