import React, { useEffect, useState } from 'react';

import { useAppDispatch } from "../../redux/hooks";
import { Dispatch } from "redux";


export default function useLocalStorageState(props: { itemKey: string, dispatchFunction: Dispatch }) {
  
  const { itemKey, dispatchFunction } = props;
  
  const dispatch = useAppDispatch();
  
  const [item, _setItem] = useState<any>();
  
  function setItem(newItem: any) {
    localStorage.setItem(itemKey, JSON.stringify(newItem))
    _setItem(newItem)
  }
  
  useEffect(() => {
    if (!item) {
      const localStorageItem = localStorage.getItem(itemKey)
      if (localStorageItem !== null) {
        const parsedItem = JSON.parse(localStorageItem);
        if (parsedItem) {
          _setItem(parsedItem)
        }
      }
    }
  }, [localStorage.getItem(itemKey)])
  
  useEffect(() => {
    dispatch(dispatchFunction(item))
  }, [item])
  
  return [item, setItem];
}