import React, { useState } from "react";

import { useTranslation } from 'react-i18next';

import { useAppDispatch } from "../../../../../redux/hooks";
import { alertsActions } from "../../../../../redux/alerts-slice";

import EntityService from "../../../../../api/settings-services-service";
import Service from "../../../../../models/service";
import ServiceGrid from "../ServiceGrid";
import ServiceModal from '../ServiceModal';
import ServiceDeleteModal from './../ServiceDeleteModal';
import ServiceImportModal from "../ServiceImportModal";
import { SettingsEnterpriseProps } from "../../../../../models/interfaces/settings-enterprise-props";
import { Typography } from "@material-ui/core";
import SectionNote from "../../../../establishment-settings/Sections/FormsSettingsTabs/SectionNote";
import useStyles from "./css";
import { SectionNoteData } from "../../../../../models/enums/section-note-data";


export default function ServiceMain(props: SettingsEnterpriseProps) {

  const { settingsSelectedEstablishmentId } = props;

  const { t } = useTranslation(["general"]);

  const classes = useStyles();

  const [isRefresh, setIsRefresh] = useState<any>();

  const [openItemEditModal, setOpenItemEditModal] = useState<boolean>(false);
  const [openItemDeleteModal, setOpenItemDeleteModal] = useState<boolean>(false);

  const [itemModal, setItemModal] = useState<Service | null>(null);

  const [newItem, setNewItem] = useState<Service | null>(null);

  const [openServiceImportModal, setOpenServiceImportModal] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;

  function handleCreate(entity: any) {
    setItemModal(null);
    setOpenItemEditModal(true);
    setIsRefresh(entity);
  }

  function handleRowClick(item: Service) {
    setOpenItemEditModal(true);
    setItemModal(item);
  }

  function handleDelete(item: any) {
    setItemModal(item);
    setOpenItemDeleteModal(true);
  }

  function handleCreated(item: Service, isNew: boolean) {
    setOpenItemEditModal(false);

    let alertString = isNew ? "New service created successfully" : "Service has been updated";

    let func = isNew ? setNewItem : setIsRefresh;

    let alertTitle: string = t(alertString);

    func(item);
    displayAlert(alertTitle, item.name)
  }

  function displayAlert(title: string, description: string) {
    dispatch(
      enqueueAlert({
        type: "Success",
        title: title,
        description: description
      })
    );
  }

  function handleOpenImportModal() {
    setOpenServiceImportModal(true)
  }

  async function handleDeleted(item: any) {

    const deletedEntity = await EntityService.deleteService(item);

    if (deletedEntity.forbiddenDelete) {
      setOpenItemDeleteModal(false);

      dispatch(
        enqueueAlert({
          type: "Warning",
          title: t("Service cannot be deleted"),
          description: `${item.name} ${t("cannot be deleted because it has one or more future appointments associate")}`
        })
      );
    } else if (deletedEntity) {
      setOpenItemEditModal(false);
      setOpenItemDeleteModal(false);
      setIsRefresh(item);
      dispatch(
        enqueueAlert({
          type: "Success",
          title: t("Service has been deleted"),
          description: `${item.name} ${t("has been deleted")}`
        })
      );
    }
  }

  return (
    <>
      {settingsSelectedEstablishmentId && (
        <>
          <div className={classes.noteHeader}>
            <Typography className={classes.tabTitle}>
              {t("Services")}
            </Typography>
          </div>
          <SectionNote 
            sectionData={SectionNoteData.Services}
          />
        </>
      )}

      <ServiceGrid
        isRefresh={isRefresh}
        item={newItem}
        openImportModal={handleOpenImportModal}
        openItemEditModal={handleRowClick}
        openDeleteModal={handleDelete}
        handleCreate={handleCreate}
        settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
      />

      <ServiceModal
        open={openItemEditModal}
        item={itemModal}
        onClose={() => setOpenItemEditModal(false)}
        onCreate={handleCreated}
        onDelete={handleDelete}
        settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
      />

      <ServiceImportModal
        open={openServiceImportModal}
        onClose={() => setOpenServiceImportModal(false)}
        refreshServices={() => {
          setIsRefresh(Math.random())
        }}
        settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
      />

      <ServiceDeleteModal
        open={openItemDeleteModal}
        item={itemModal}
        onClose={() => setOpenItemDeleteModal(false)}
        onDelete={handleDeleted}
      />
    </>
  );
};