import { makeStyles } from "@material-ui/core/styles";

export const useStyles = makeStyles((theme) => ({
    header: {
        marginBottom: 10,
        paddingTop: 5
    },
    headerRight: {
        textAlign: "right"
    },
    button: {
        width: 200,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        textTransform: "none"
    },
    btnNewWorkArea: {
        background: "#7175FA",
        color: "#F6F6F6",
        "&:hover": {
            background: "#6462F3",
        },
    },
    titles: {
        color: theme.colors.grey4,
        fontSize: 12,
        fontWeight: "bold",
        marginBottom: 10
    },
    icons: {
        color: theme.colors.grey5,
        fontSize: 18
    },
    icon: {
        paddingTop: 5,
        height: 30,
        width: 30,
        fontWeight: "bold"
    }
}));


export default useStyles;
