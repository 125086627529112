import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function WhatsAppVideoIcon(props: any) {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 40, height: 40, ...style }}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path
        d="M15.1445 11.2578L10.332 8.33203C9.89453 8.11328 9.375 8.41406 9.375 8.90625V14.5938C9.375 15.1133 9.89453 15.4141 10.332 15.168L15.1445 12.4062C15.6094 12.1602 15.6094 11.5312 15.1445 11.2578ZM18.7812 11.75C18.7812 8.00391 15.7461 4.96875 12 4.96875C8.25391 4.96875 5.21875 8.00391 5.21875 11.75C5.21875 15.4961 8.25391 18.5312 12 18.5312C15.7461 18.5312 18.7812 15.4961 18.7812 11.75ZM6.53125 11.75C6.53125 8.74219 8.96484 6.28125 12 6.28125C15.0078 6.28125 17.4688 8.74219 17.4688 11.75C17.4688 14.7852 15.0078 17.2188 12 17.2188C8.96484 17.2188 6.53125 14.7852 6.53125 11.75Z"
        fill={style?.color || Color.gray5}
      />
    </SvgIcon>
  );
}