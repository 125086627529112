export class AppointmentAvailableHours {
  availableHours: string[] | null;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.availableHours = x.availableHours;
    } else {
      this.availableHours = null;
    }
  }
}
