import { makeStyles } from '@material-ui/core/styles';

const useAppointmentCardStyles = makeStyles((theme) => ({
    root: {
        overflow: "hidden",
        border: "1px solid #C9CDD3",
        boxSizing: "border-box",
        borderRadius: "4px",
        background: "#FFFFFF",
        zIndex: 99,
        "&:hover": {
            border: "1px solid #C9CDD3",
            boxSizing: "border-box",
            borderRadius: "4px",
            background: "#FFFFFF",
        },
    },
    header: {
        // background: "#0BCBE0",
        borderRadius: "5px 5px 0px 0px",
        width: "100%",
        height: "4px",
    },
    arrived: {
        background: theme.colors.clearGreen,
    },
    notArrived: {
        background: theme.colors.clearRed,
    },
    confirmed: {
        background: theme.colors.clearBlue,
    },
    notConfirmed: {
        background: theme.colors.grey4,
    },
    content: {
        padding: "9px 8px 9px 8px",
        height: "100%",
    },
    contentNotArrive: {
        background: theme.colors.grey1,
    },
    customerTitle: {
        ...theme.typography.caption1,
        fontWeight: "bold",
        color: theme.colors.grey7,
    },
    customerTitleBlocked: {
        ...theme.typography.caption1,
        fontWeight: "bold",
        color: "rgba(50, 57, 71, 0.76);",
    },
    customerNotArrived: {
        ...theme.typography.caption1,
        fontWeight: "bold",
        color: theme.colors.grey5,
        textDecoration: "line-through",
    },
    hoursText: {
        ...theme.typography.caption2,
        color: theme.colors.grey5,
        marginTop: 2,
    },
    hoursTextBlocked: {
        ...theme.typography.caption2,
        color: theme.colors.grey5,
        marginTop: 2,
    },
    personChargeText: {
        ...theme.typography.caption2,
        fontWeight: "bold",
        color: theme.colors.grey5,
        marginTop: 4,
    },
    isAppointmentCustomerSite: {
        color: theme.colors.clearMain,
    },
    personChargeTextBlocked: {
        ...theme.typography.caption2,
        fontWeight: "bold",
        color: "rgba(50, 57, 71, 0.76)",
        marginTop: 8,
    },
    tooltipContainer: {
        display: "flex",
        flexDirection: "column"
    },
    tooltipCustomerNameBox: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 10,
        color: theme.colors.grey5,
    },
    tooltipAppointmentTime: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 9,
        color: theme.colors.grey5,
    },
    tooltipUserName: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 9,
        color: theme.colors.grey5,
    },
    personWithDot: {
        display: "flex",
        alignItems: "center",
        "& .dot": {
            width: 4,
            height: 4,
            display: "block",
            backgroundColor: theme.colors.grey4,
            borderRadius: "50%",
            marginRight: 7
        }
    },
    dot: {
        width: 4,
        height: 4,
        display: "block",
        position: "absolute",
        left: 2,
        top: 4,
        backgroundColor: theme.colors.grey4,
        borderRadius: "50%",
    },
    serviceItemText: {
        marginTop: 0,
        marginBottom: 4,
        "& .MuiTypography-root": {
            fontFamily: "Inter",
            fontStyle: "normal",
            fontWeight: 400,
            fontSize: "9px",
            lineHeight: "11px",
            color: theme.colors.grey4,
        }
    },
    serviceItemsList: {
        padding: "0",
    },
    serviceItemsTooltipList: {
        padding: "0px 0px 3px 0px",
    },
    serviceSingleItemText: {
        fontFamily: "Inter",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "9px",
        lineHeight: "11px",
        color: theme.colors.grey4,
        marginBottom: 4
    },
    serviceListItem: {
        padding: "0px 0px 0px 12px"
    },
    popper:{
        [`& .MuiTooltip-tooltip`]: {
            padding: "4px 8px",
            borderRadius: "5px",
            backgroundColor: theme.colors.grey1,
            fontFamily: "Roboto, sans-serif",
            fontStyle: "normal",
            fontWeight: "normal",
            fontSize: "12px",
            color: theme.colors.grey5,
            boxShadow: "0px 0px 4px #00000034",
            position: "absolute",
            height: "auto",
            width: "max-content",
            top: 35,
            left: -55
        },
    },
    establishmentRow: {
        display: "flex",
        alignItems: "center",
        gap: 4,
    }
}));

export default useAppointmentCardStyles;
