import React from "react";
import Box from "@material-ui/core/Box";
import LinearProgress from '@material-ui/core/LinearProgress';

import { useStyles } from "./css";

const LoaderLine = () => {
  const classes = useStyles();
  
  return (
    <Box sx={{ width: '100%', position: 'fixed', top: 61, zIndex: 999}} className={classes.root}>
      <LinearProgress classes={{root: classes.root, colorPrimary: classes.colorPrimary, barColorPrimary: classes.barColorPrimary}} />
    </Box>
  );
};

export default LoaderLine;
