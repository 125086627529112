import React from 'react';

import { useTranslation } from "react-i18next";

import { InputBase } from "@material-ui/core";

import { PatientFormTextInputProps } from "./props";
import useStyles from "./css";

import useControlLabel from "../../../hooks/useControlLabel";
import useInputFirstTouch from "../../../hooks/useInputFirstTouch";


export default function PatientFormTextInput(props: PatientFormTextInputProps) {
  
  const { value, setValue, label, required, placeholder, isValid, disabled, inputStyle, maxLength, key } = props;
  
  const classes = useStyles()();
  
  const { t } = useTranslation("general");
  
  function handleOnChange(event: any) {
    setValue(event.target.value);
  }
  
  function isError(): boolean {
    if (!isValid || isValid(value) === undefined) {
      return false;
    }
    return !isValid(value);
  }
  
  const ControlLabel = useControlLabel({ label, required });
  const { hasBeenTouched, handleFirstTouch } = useInputFirstTouch();
  
  return (
    <div className={classes.root}>
      {!!label &&
        <ControlLabel/>
      }
      <InputBase
        key={key}
        className={`${classes.input} ${inputStyle} ${hasBeenTouched && isError() ? classes.inputError : ""}`}
        placeholder={`${t("Write here your answer for")} ${placeholder}`}
        value={value}
        onChange={handleOnChange}
        onBlur={handleFirstTouch}
        disabled={disabled}
        inputProps={{
          className: classes.inputWidth,
          maxLength: maxLength
        }}
      />
      <div className={classes.counter}>{`${value?.length}/${maxLength}`}</div>
    </div>
  );
}