import React from "react";

import { useTranslation } from "react-i18next";
import moment from "moment";

import { useAppSelector } from "../../../../redux/hooks";
import { selectUserInfo } from "../../../../redux/store";

import { Button, Popover } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";

import { OpenCashRegisterModalProps } from "./props";
import useStyles from "./css";
import { CrossIcon, InfoIcon } from "../../../../assets/icons";
import InputCurrency from "../../../common/InputCurrency";
import IconTooltip from "../../../common/IconTooltip";


export default function OpenCashRegisterModal(props: OpenCashRegisterModalProps) {
  
  const { open, setOpen, initialCash, setInitialCash, currencyTemplate, onSubmit, onClose, anchor } = props;
  
  const { t } = useTranslation("general");
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const classes = useStyles();
  const dtFormattingRule = localeApp === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY"
  
  const isUserAbleToOpenCashRegister = useAppSelector(selectUserInfo).canOpenCloseCashRegister

  function handleCloseModal() {
    handleOnClose();
  }
  
  function handleOnClose() {
    if (onClose) onClose();
    setOpen(false);
  }
  
  const SubmitButton = () =>
    <Button
      className={classes.submitButton}
      onClick={async () => {await onSubmit()}}
    >
      {t("Open cash register")}
    </Button>
  
  const NoPermissionText = () =>
    <div className={classes.noPermissionsText}>
      {t("This user does not have permission to open cash register")}
    </div>
    

  const CrossCloseButton = () =>
    <div className={classes.closeIcon}>
      <CrossIcon
        style={{
          width: 16,
          height: 16
        }}
        viewBox="0 0 16 16"
        onClick={handleCloseModal}
      />
    </div>
  
  return (
    <div>
      <Popover
        open={open}
        anchorEl={anchor}
        onClose={handleOnClose}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          className: classes.popover,
        }}
      >
        <div className={classes.header}>
          <div className={classes.headerDate}>{t("Date") + ": "}<b>{moment(new Date()).format(dtFormattingRule)}</b></div>
          <CrossCloseButton />
        </div>
        <div className={classes.divider} />
        <div className={classes.inputWithLabel}>
          <div>
            <Typography className={classes.controlLabel}>
              {t("Initial cash")}
            </Typography>
            <span className={classes.dot}>*</span>
            <IconTooltip
              classNameRoot={classes.inputLabelTooltip}
              placement={"top"}
              icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 2 }} viewBox={"0 0 12 12"} />}
              tooltipText={t("Expected cash on hand")}
            />
          </div>
          <InputCurrency 
            value={initialCash} 
            setValue={setInitialCash} 
            valueTemplate={currencyTemplate}
            width={352}
            disabled={!isUserAbleToOpenCashRegister}
          />
          {
            isUserAbleToOpenCashRegister
            ? <SubmitButton /> 
            : <NoPermissionText />
          }
          
        </div>
      </Popover>
    </div>
  );
}