import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";
export const useStyles = makeStyles((theme) => ({
    windowContainer: {
        width: "calc(100vw - 360px)",
        height: "100vh",
        maxHeight: "100vh",
        
        display: "flex",
        flexDirection: "column",
        alignItems: "stretch",
        
        backgroundColor: "#FFFFFF"
    },
    header: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: theme.colors.grey1,
        height: "48px",
        paddingRight: 39,
        zIndex: 2
    },
    headerTitle: {
        display: "flex",
        alignItems: "center",
    
        padding: "15px 16px 16px 40px",
    
        ...theme.typography.txtBody1,
        fontWeight: "bold",
        color: theme.colors.grey5,
        
        "& svg": {
            marginRight: 10
        }
    },
    headerActions: {
    
    },
    headerTitleWithEstablishmentName: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 2,
    
        padding: "8px 16px 8px 40px",
        
        ...theme.typography.txtBody1,
        fontWeight: "bold",
        color: theme.colors.grey5,
    },
    establishmentName: {
        ...theme.typography.txtBody3,
        color: theme.colors.grey5,
    },
    buildScrollbars: {
        overflow: "auto",
        overflowAnchor: "none",
        overflowX: "hidden"
    },
    messageWindow: {
        height: "calc(100vh - 175px) !important",
        marginTop: "48px",
        display: "flex", 
        flexDirection: "column-reverse",
        position: "sticky",
        bottom: "300px",
    },
    dayLabel: {
        display: "flex", 
        justifyContent: "center"
    },
    inputLayout: {
        width: "100%",
        
        bottom: "0%",
        position: "sticky",
        
        display: "flex",
        justifySelf: "flex-end",

        backgroundColor: "#FFFFFF",
    },
    inputItemsLayout: {
        width: "100%",
        minHeight: "72px",
        
        margin: "0px !important",
        padding: "12px 5%"
    },
    inputAreaContainer: {
        height: 40,
        
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    messageInputArea: {
        width: "75%",
        height: 40,
        marginRight: "2%",
        // does not change this element's position,
        // used to absolutely position toolbarWrapper within it
        position: "relative",
    },
    sendMessageButton: {
        display: "flex",
        width: "120px",
        height: 40
    },
    inputArea: {
        backgroundColor: theme.colors.grey1,
        border: "0px",
        borderRadius: "6px !important",
        
        width: "100% !important",
        height: "100% !important",
        
        resize: "none",
        "&::-webkit-scrollbar": {
            "display": "none",
        },
        scrollbarWidth: "none",
        "&::-ms-overflow-style": "none",
        "&:focus": {
            outline: "none",
            boxShadow: 0,
            border: "solid 1px #6462F3",
        },
        "&::placeholder": {
            color: "#B5B9C1",
        },
    },
    inputText: {
        fontFamily: "Inter, sans-serif !important",
        fontStyle: "normal !important",
        fontWeight: "bold",
        fontSize: "14px !important",
        color: theme.colors.grey4 + " !important",
        // padding-right accommodates the copy link button,
        // please remember to update after adding new buttons
        padding: "11px 40px 12px 16px !important"
    },
    disabledInputLayout: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center"
    },
    disabledInput: {
        display: "flex",
        justifyContent: "center"
    },
    enabledSendButton: {
        backgroundColor: theme.colors.clearGreen,
        width: "100%",
        height: "100%",
        borderRadius: "6px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        color: "#FFFFFF",
        transition: "color 0.5s, background-color 0.5s",
        "&:hover": {
            color: theme.colors.clearGreen
        },
        "&.Mui-disabled": {
            color: "#FFFFFF",
            opacity: 0.3,
          },
    },
    enabledSendButtonWithDropdown: {
        width: "60%",
        borderTopRightRadius: "0",
        borderBottomRightRadius: "0"
    },
    enabledSendDropdownButton: {
        width: "40%",
        minWidth: "auto",
        borderTopLeftRadius: "0",
        borderBottomLeftRadius: "0",
        marginLeft: "1px"
    },
    menuItem:{
        padding: "0 20px 0 10px",
    },
    listItemIcon: {
        minWidth: "25px",
    },
    listItemText: {
        '& span': {fontSize: "14px"}
    },
    alternativeNotificationSendButton: {
        backgroundColor: theme.colors.clearGreen,
        width: "100%",
        maxWidth: "200px",
        height: "100%",
        borderRadius: "50px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
        color: "#FFFFFF",
        transition: "color 0.5s, background-color 0.5s",
        "&:hover": {
            color: theme.colors.clearGreen
        },
        "&.Mui-disabled": {
            color: "#FFFFFF",
            opacity: 0.3,
        },
    },
    dayLabelBox: {
        background: "rgba(11, 203, 224, 0.1)",
        padding: "4px 8px",
        borderRadius: "5px",
        // display: "flex",
        // justifyContent: "center",
        whiteSpace: "break-spaces",
        margin: "10px 40px 10px 40px",
    },
    dayLabelText: {
        color: Color.blue,
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "12px",
    },
    templateTextAreaContainer: {
        backgroundColor: theme.colors.grey1,
        borderRadius: 10,
        overflowY: "auto",
        maxHeight: 130,
        width: "593px",
        paddingLeft: "10px",
        position: "relative",
        "&::-webkit-scrollbar": {
            width: "6px",
        },
        "&::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#EFEFEF",
            marginTop: "10px",
            marginBottom: "10px",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: "#ACB7C0",
        },
        "&::-webkit-scrollbar-thumb:hover": {
            background: Color.gray4
        },
    },
    closeWindowButton: {
        position: "sticky",
        top: 0,
        right: 0,
        padding: "5px",
        cursor: "pointer",
        marginLeft: 542,
        textAlign: "right",
        zIndex: 1,
    },
    closeIcon: {
        color: theme.colors.grey4
    },
    templateWindowArea: {
        display: "flex",
        gap: 12
    },
    textArea: {
        ...theme.typography.txtBody2,
        color: theme.colors.grey5,
        fontWeight: "bold",
        "&::placeholder": {
            ...theme.typography.txtBody2,
            fontWeight: "normal",
            opacity: 0.8,
        },
    },
    buttonContainer: {
        display: "flex",
        alignSelf: "end",
        height: 40,
        width: 103,
        marginTop: "10px", 
    },
    templateTextContainer: {
        marginTop: -40,
        padding: 17,
        paddingRight: 25,
        color: theme.colors.grey3
    },
    tooltipBlock: {
        backgroundColor: theme.colors.grey1,
        ...theme.typography.txtBody2,
        color: theme.colors.grey4,
        borderRadius: 5,
        boxShadow: "0px 4px 4px 0px",
    },
    infiniteScrollOverrides: {
        "overflow-x": "hidden !important",
        "& ::-webkit-scrollbar": {
            width: "6px",
        },
        "& ::-webkit-scrollbar-track": {
            borderRadius: "10px",
            background: "#EFEFEF",
            marginTop: "33px",
            marginBottom: "33px"
        },
        "& ::-webkit-scrollbar-thumb": {
            borderRadius: "10px",
            background: "#ACB7C0",
        },
        "& ::-webkit-scrollbar-thumb:hover": {
            background: Color.gray4
        },
    }
}));