import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
    modalYellow: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#FFC626",
    },
    modalPaperYellow: {
        backgroundColor: theme.palette.background.paper,
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#FFC626",
        borderRadius: "10px",
        boxShadow: theme.shadows[5],
        padding: "24px",
        width: 464,
        "&:focus:not(:focus-visible)": {
            outline: "none",
        },
        "&:focus-visible": {
            outline: "none",
            border: "1px solid #FFC626",
        },
    },
    modalHeader: {
        display: "flex",
    },
    modalHeaderContent: {
        display: "flex",
        justifyContent: "flex-end",
        width: "100%",
    },
    modalClose: {
        display: "flex",
        justifyContent: "flex-end",
        width: "73%",
    },
    closeButton: {
        cursor: "pointer",
        color: "#919CA5",
    },
    modalTitle: {
        width: "100%",
    },
    modalTitleText: {
        fontSize: "18px",
        lineHeight: "22px",
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: "bold",
        color: "#26292B",
    },
    modalContent: {
        fontFamily: "Inter, sans-serif",
    },
    bodyContent: {
        color: theme.colors.grey5,
        textAlign: "center"
    },
    modalCircle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: "50%",
        borderWidth: 1,
        borderStyle: "solid",
        borderColor: "#FFC626",
        width: "60px",
        height: "60px",
    },
    modalCenter: {
        display: "flex",
        justifyContent: "center",
        textAlign: "center"
    },
    modalTitleSpace: {
        margin: "30px 0px 20px 0px",
    },
    modalContentTextTitle: {
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: "14px",
        lineHeight: "17px",
        textAlign: "center",
        color: "#4A4D51",
    },
    modalContentText: {
        fontStyle: "normal",
        fontSize: "14px",
        lineHeight: "17px",
        textAlign: "center",
        color: "#4A4D51",
    },
    modalDateText: {
        ...theme.typography.txtBody2,
        paddingTop: 7,
    },
    modalActions: {
        display: "flex",
        marginTop: 32,
        justifyContent: "center",
        textAlign: "center"
    },
    button: {
        width: 196,
        height: 32,
        borderRadius: 51,
        padding: "11px 16px",
        alignItems: "center",
        justifyContent: "center",
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        margin: "auto"
    },
    btnCancel: {
        marginRight: 16,
        background: "#F6F6F6",
        color: "#ACB7C0",
    },
    btnOk: {
        background: "#36CE91",
        color: "#F6F6F6",
        "&:hover": {
            background: "#36CE91",
        },
        "&.Mui-disabled": {
            background: "rgba(54, 206, 145, 0.5)",
            color: "white"
        }
    },
    separator: {
        height: 0,
        margin: "0px auto 20px auto",
        borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    },
    
    termContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        height: 57,
        width: "100%",
    },
    termTitle: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray7
    },
    termEditNameInput: {
        backgroundColor: Color.white + " !important",
        border: "1px solid " + Color.gray2,
        borderRadius: "5px !important" ,
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "12px",
        lineHeight: "14px",
        color: Color.gray5 + " !important"
    },
    termButtonsContainer: {
        display: "flex"
    },
    termButton: {
        "&.MuiButton-root": {
            minWidth: 0
        },
    },
    closeConfirmationModal: {
        height: "234px + !important",
        width: 660
    },
    closeModalContentContainer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column"
    },
    closeModalTitleText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "18px",
        lineHeight: "22px",
        color: "#26292B",
        textAlign: "center",
        maxWidth: "80%"
    },
    closeModalContentText: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "14px",
        lineHeight: "17px",
        color: Color.gray5
    },
}));

export default useStyles;