import React, { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useTranslation } from "react-i18next";
import { conformToMask } from "react-text-mask";
import { format } from 'date-fns';

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAccountSettings, selectUserInfo } from "../../../redux/store";
import useCustomerTerminology from "../../../hooks/useCustomerTerminology";
import useStyles from "./css";
import { PatientDataProps } from "./props";

import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

import { StatusIcon, PatientGeneralDataIcon, PatientMaleIcon, PatientFemaleIcon, PatientContactIcon, PatientAppointmentsIcon, PatientServicesIcon, PatientStethoscopeIcon, ExportFileIcon } from "../../../assets/icons";
import CountryFlagIcon from "../../../assets/flags";

import { CustomerService } from "../../../api/customer-service";
import { Gender } from "../../../models/enums/gender";
import { PatientDataResponse } from "../../../models/patient-data-response";
import { EvolutionNoteFieldAndValue } from "../../../models/evolution-note-field-and-value";
import { SectionFieldType } from "../../../models/enums/section-field-type";
import { FormPhoneNumberValue } from "../../../models/form-phone-number-value";
import { FormRadioValue } from "../../../models/form-radio-value";

import DataAccordion from "../../common/DataAccordion";
import { GetMobileMaskByTemp } from "../../../constants/mask";
import CustomerDetailsRefs from "../CustomerDetails/customer-details-refs";
import { navbarActions } from "../../../redux/navbar-slice";
import { CircularProgress } from "@material-ui/core";
import esLocale from "date-fns/locale/es";
import enLocale from "date-fns/locale/en-US";
import { Color } from "../../../constants/colors";


const PatientData = forwardRef<CustomerDetailsRefs, any>((props: PatientDataProps, ref) => {
  const sectionTNamespace = "sections";
  const { t } = useTranslation(["general", sectionTNamespace]);
  const { customerId } = props;
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const [patientData, setPatientData] = useState<PatientDataResponse>(new PatientDataResponse());
  const [isPdfDownloading, setIsPdfDownloading] = useState<boolean>(false);
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const accountSettings = useAppSelector(selectAccountSettings);

  const userInfo = useAppSelector(selectUserInfo);
  const hasCustomerInfoVisible = userInfo.hasCustomerInfoVisible;
  const canSeeClinicalFile = userInfo.canSeeClinicalFile;
  
  //to make the "updateVitalSigns" function callable from the parent component
  useImperativeHandle(ref, () => ({
    updateVitalSigns: async () => {
      await loadData();
    }
  }));

  useEffect(() => {
    async function fetchData() {
      await loadData();
    }

    fetchData();
  }, []);

  const loadData = async () => {
    dispatch(navbarActions.setShowLoader(true));
    const response = await CustomerService.getPatientData(customerId);

    const countries = accountSettings.countries;
    let mobileCode = response.mobileCodeIso3 ?? "MEX";
    response.mobileDigits = countries.find(x => x.iso3 === mobileCode)!.dialDigits;
    response.mobileCode = countries.find(x => x.iso3 === mobileCode)!.dialCode;
    response.mobileTemplate = countries.find(x => x.iso3 === mobileCode)!.dialTemplate.toString();

    dispatch(navbarActions.setShowLoader(false));
    setPatientData(response);
  };
  
  async function handleDownloadPdfClick() {
    if (isPdfDownloading)
      return;
    
    setIsPdfDownloading(true);
    
    try {
      const blob = await CustomerService.getPatientClinicalFilePdf(customerId);
      const url = window.URL.createObjectURL(new Blob([blob], {
        type: "application/pdf"
      }));
      const link = document.createElement('a');
      const name = `${t("Patient clinical file")} - ${patientData.firstName} ${patientData.lastName} - ${format(new Date(), t("clinical_file_date_format"), {locale:getLocale()})}`;
      link.href = url;
      link.setAttribute('download', `${name}.pdf`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
    finally {
      setIsPdfDownloading(false);
    }
  }
  
  const tryGetGenderIcon = () => {
    if (!patientData.gender)
      return <></>
    if (patientData.gender === Gender.Male)
      return <PatientMaleIcon />
    if (patientData.gender === Gender.Female)
      return <PatientFemaleIcon />
  };

  const tryGetAgeLabel = () => {
    if (!patientData.dateOfBirth)
      return <></>

    return <Typography className={classes.ageLabel}>
      {getAge(patientData.dateOfBirth) + " " + t("years")}
    </Typography>
  };

  const getAge = (birthDate: Date): number => {
    const dob = new Date(birthDate);
    //calculate month difference from current date in time
    const month_diff = Date.now() - dob.getTime();

    //convert the calculated difference in date format
    const age_dt = new Date(month_diff);

    //extract year from date
    const year = age_dt.getUTCFullYear();

    //now calculate the age of the user
    return Math.abs(year - 1970);
  };

  const getDateOfBirthString = () => {
    const newDateObj = new Date(patientData.dateOfBirth!);
    const day = newDateObj.toLocaleString(localeApp, { day: "numeric" });
    const month = newDateObj.toLocaleString(localeApp, { month: "long", });
    const year = newDateObj.toLocaleString(localeApp, { year: "numeric", });

    return localeApp === "es"
      ? `${day}/${month}/${year}`
      : `${month.charAt(0).toUpperCase() + month.slice(1)}/${day}/${year}`;
  };

  const getEvolutionNoteLastUpdatedString = () => {
    if (!patientData.evolutionNoteFieldsLastUpdated)
      return "--";

    const newDateObj = new Date(patientData.evolutionNoteFieldsLastUpdated);
    const time = newDateObj.toLocaleTimeString("es", { timeStyle: "short" })
    const day = newDateObj.toLocaleString(localeApp, { day: "2-digit" });
    const month = newDateObj.toLocaleString(localeApp, { month: "long", });
    const year = newDateObj.toLocaleString(localeApp, { year: "numeric", });

    return localeApp === "es"
      ? `${day}/${month}/${year} - ${time}hrs`
      : `${month.charAt(0).toUpperCase() + month.slice(1)}/${day}/${year} - ${time}hrs`;
  };
  
  function getLocale(): any {
    switch (localeApp) {
      case "es":
        return esLocale;
      
      default:
        return enLocale;
    }
  }
  
  const getFieldValueComponent = (fv: EvolutionNoteFieldAndValue) => {
    if (!fv?.value?.value)
      return <Typography className={classes.fieldValueLabel}>
        {"--"}
      </Typography>;

    switch (fv.value.type) {
      case SectionFieldType.Text:
        return <Typography className={classes.fieldValueLabel}>
          {t(fv.value.value as string, { ns: sectionTNamespace })}
        </Typography>;
      case SectionFieldType.Email:
        return <Typography className={classes.fieldValueLabel}>
          {t(fv.value.value as string, { ns: sectionTNamespace })}
        </Typography>;
      case SectionFieldType.PhoneNumber:
        const phoneValue = fv.value.value as FormPhoneNumberValue;
        const country = accountSettings.countries.find(c => c.iso3 === phoneValue.countryIso);
        return <div className={classes.phoneNumberContainer}>
          <CountryFlagIcon iso={phoneValue.countryIso} />
          <Typography className={classes.phoneNumberCodeLabel}>
            {"+" + country!.dialCode}
          </Typography>
          <Typography className={classes.fieldValueLabel}>
            {conformToMask(phoneValue.body, GetMobileMaskByTemp(country!.dialTemplate)).conformedValue}
          </Typography>
        </div>
      case SectionFieldType.Radio:
        return <Typography className={classes.fieldValueLabel}>
          {t((fv.value.value as FormRadioValue)?.item?.value ?? "--", { ns: sectionTNamespace })}
          {!!(fv.value.value as FormRadioValue).additionalFreeTextValue &&
            <> / {(fv.value.value as FormRadioValue).additionalFreeTextValue}</>
          }
        </Typography>;
    };
  }

  const PatientFirstItem: React.FC = () => {
    return <div className={classes.itemContainer}>
      <Typography className={classes.fieldNameLabel}>
        {t("Name")}
      </Typography>
      <div className={classes.nameValueContainer}>
        {tryGetGenderIcon()}
        <Typography className={`${classes.fieldValueLabel} ${classes.fixedHeightLabelWrapper}`}>
          {patientData.firstName + " " + patientData.lastName}
        </Typography>
        <div className={classes.statusIconContainer}>
          <StatusIcon status={patientData.customerFrequency} />
        </div>
      </div>
      {tryGetAgeLabel()}
    </div>
  };

  const PatientBody: React.FC = () => {
    return (
      <div className={classes.bodyItemsContainer}>
      {!!patientData.dateOfBirth &&
        <>
          <div className={classes.itemContainer}>
            <Typography className={classes.fieldNameLabel}>
              {t("Date of birth")}
            </Typography>
            <Typography className={classes.fieldValueLabel}>
              {getDateOfBirthString()}
            </Typography>
          </div>
        </>
      }
    </div>
    )
  };

  const ContactFirstItem: React.FC = () => {
    return <div className={classes.itemContainer}>
      <Typography className={classes.fieldNameLabel}>
        {t("Mobile")}
      </Typography>
      {patientData.mobile && hasCustomerInfoVisible
        ? <div className={classes.phoneNumberContainer}>
          <CountryFlagIcon iso={patientData.mobileCodeIso3} />
          <Typography className={classes.phoneNumberCodeLabel}>
            {"+" + patientData.mobileCode}
          </Typography>
          <Typography className={classes.fieldValueLabel}>
            {patientData.mobile ? conformToMask(patientData.mobile, GetMobileMaskByTemp(patientData.mobileTemplate)).conformedValue : "--"}
          </Typography>
        </div>
        : <Typography className={classes.fieldValueLabel}>
          {"--"}
        </Typography>}
    </div>
  };

  const ContactBody: React.FC = () => {
    return <div className={classes.bodyItemsContainer}>
      {patientData.email && hasCustomerInfoVisible
        ? <div className={classes.itemContainer}>
          <Typography className={classes.fieldNameLabel}>
            {t("Email")}
          </Typography>
          <Typography className={classes.fieldValueLabel}>
            {patientData.email}
          </Typography>
        </div>
        : <></>}
      <div className={classes.itemContainer}>
        <Typography className={classes.fieldNameLabel}>
          {t("Contactable")}
        </Typography>
        <Typography className={classes.fieldValueLabel}>
          {patientData.contactable ? t("Yes") : t("No")}
        </Typography>
      </div>
    </div>
  };

  const AppointmentsFirstItem: React.FC = () => {
    return <div className={classes.appointmentsFirstItemContainer}>
      <div className={classes.appointmentsFirstItemContainerRow}>
        <Typography className={`${classes.fieldNameLabel} ${classes.appointmentsFirstItemContainerItem}`}>
          {useCustomerTerminology(t("Customer arrived"), "Customer")}
        </Typography>
        <Typography className={`${classes.fieldNameLabel} ${classes.appointmentsFirstItemContainerItem}`}>
          {t("Created")}
        </Typography>
      </div>
      <div className={classes.appointmentsFirstItemContainerRow}>
        <Typography className={`${classes.appointmentsValueLabel} ${classes.appointmentsFirstItemContainerItem}`}>
          {patientData.arrivedAppointmentsCount}
        </Typography>
        <Typography className={`${classes.appointmentsValueLabel} ${classes.appointmentsFirstItemContainerItem}`}>
          {patientData.totalAppointmentsCount}
        </Typography>
      </div>
    </div>
  };

  const ServicesFirstItem: React.FC = () => {
    return <div className={classes.itemContainer}>
      <Typography className={classes.fieldNameLabel}>
        {t("Favorite")}
      </Typography>
      <Typography className={`${classes.fieldValueLabel} ${classes.fixedHeightLabelWrapper}`}>
        {patientData.favoriteServiceName ? patientData.favoriteServiceName : "--"}
      </Typography>
    </div>
  };

  const ServicesBody: React.FC = () => {
    return <div className={classes.bodyItemsContainer}>
      <div className={classes.itemContainer}>
        <Typography className={classes.fieldNameLabel}>
          {t("Times requested")}
        </Typography>
        <Typography className={classes.fieldValueLabel}>
          {patientData.favoriteServiceRequestedCount}
        </Typography>
      </div>
      <div className={classes.itemContainer}>
        <Typography className={classes.fieldNameLabel}>
          {t("Total services performed")}
        </Typography>
        <Typography className={classes.fieldValueLabel}>
          {patientData.totalServicesCount}
        </Typography>
      </div>
    </div>
  };

  const EvolutionNoteFieldsAccordion: React.FC = () => {
    if (!patientData.evolutionNoteSectionName)
      return <></>
    return <DataAccordion
      headerIcon={<PatientStethoscopeIcon />}
      title={t(patientData.evolutionNoteSectionName, { ns: sectionTNamespace })}
      firstItemComponent={<EvolutionNoteFirstItem />}
      firstItemComponentHeight={65}
      bodyItems={<EvolutionNoteBody />} />
  };

  const EvolutionNoteFirstItem: React.FC = () => {
    return <div className={classes.itemContainer}>
      <Typography className={classes.fieldNameLabel}>
        {t("Last update")}
      </Typography>
      <Typography className={`${classes.fieldValueLabel} ${classes.fixedHeightLabelWrapper}`}>
        {getEvolutionNoteLastUpdatedString()}
      </Typography>
    </div>
  };

  const EvolutionNoteBody: React.FC = () => {
    return <div className={classes.bodyItemsContainer}>
      {patientData.evolutionNoteFieldsValues!
        .sort((fv1, fv2) => fv1.field.order - fv2.field.order)
        .map(fv => <div className={classes.itemContainer}>
          <Typography className={classes.fieldNameLabel}>
            {t(fv.field.name, { ns: sectionTNamespace })}
          </Typography>
          {getFieldValueComponent(fv)}
        </div>)}
    </div>
  };


  return (
    <div className={classes.root}>
      <DataAccordion
        headerIcon={<PatientGeneralDataIcon />}
        title={t("Patient")}
        firstItemComponent={<PatientFirstItem />}
        firstItemComponentHeight={patientData.dateOfBirth ? 90 : 70}
        bodyItems={<PatientBody />}
      />
      <DataAccordion
        headerIcon={<PatientContactIcon />}
        title={t("Contact")}
        firstItemComponent={<ContactFirstItem />}
        firstItemComponentHeight={68}
        bodyItems={<ContactBody />}
      />
      <EvolutionNoteFieldsAccordion />
      <DataAccordion
        headerIcon={<PatientAppointmentsIcon />}
        title={t("Appointments")}
        firstItemComponent={<AppointmentsFirstItem />}
        firstItemComponentHeight={65}
      />
      <DataAccordion
        headerIcon={<PatientServicesIcon />}
        title={t("Services")}
        firstItemComponent={<ServicesFirstItem />}
        firstItemComponentHeight={65}
        bodyItems={<ServicesBody />}
      />
      {
        canSeeClinicalFile &&
        <Button
          className={classes.exportToPdfButton}
          onClick={handleDownloadPdfClick}
        >
          <div className={classes.exportToPdfIcon}>
            {isPdfDownloading
              ? <CircularProgress size="12px" color="inherit" />
              : <ExportFileIcon color={Color.gray1} />
            }
          </div>
          {t("Export clinical file to PDF")}
        </Button>
      }
    </div>
  );
});

export default PatientData;