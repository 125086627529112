import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  paper: {
    position: "relative",
    backgroundColor: theme.palette.background.paper,
    border: "1px solid #dbdcdd",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    width: 472,
    height: 153,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  modalTitle: {
    ...theme.typography.h4,
    fontFamily: "Inter",
    fontWeight: "bold",
    color: "#26292B",
    marginTop: 40,
    marginBottom: 40,
    justifySelf: "center"
  },
  modalFooter: {
    display: "flex",
    justifyContent: "space-evenly",
  },
  goBackButton: {
    marginRight: 16,
    background: theme.colors.grey1,
    color: theme.colors.grey3,
  },
  cancelButton: {
    background: theme.colors.clearGreen,
    color: "#FFFFFF",
    "&:hover": {
      background: theme.colors.clearGreen,
    },
  }
  
  })
);

export default useStyles;