import React from "react";
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import { useDrop } from 'react-dnd'
import { ContentGridTdComponent } from './AppointmentsScheduler/Content/ContentGrid';
import { rgba } from './styles/ColorHelpers';
import { DateTime } from 'luxon';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useScheduleAppointmentActionsMenuContext } from './ScheduleAppointmentActionsMenu/context';
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { AppointmentService } from "../../../api/appointment-service";
import { alertsActions } from "../../../redux/alerts-slice";
import { Appointment } from "../../../models/appointment";
import { schedulerActions } from "../../../redux/scheduler-slice";
import { selectAccountSettings } from "../../../redux/store";
import DragNDropConfirmationModal from "./DragNDropConfirmationModal";

const useStyles = makeStyles((theme) => ({
    td: {
        position: 'relative',
        '&:hover $actionOverlay': {
            opacity: 1,
        },
    },

    disabled: {
        backgroundColor: '#FAFAFA',
        // '& > $actionOverlay': {
        //     display: 'none',
        // },
    },

    actionOverlay: {
        userSelect: 'none',
        textDecoration: 'none',
        cursor: 'pointer',
        backgroundColor: rgba(theme.colors.clearMain, 0.1),
        ...theme.typography.header4,
        borderRadius: 4,
        position: 'absolute',
        left: 6,
        right: 6,
        top: 3,
        bottom: 3,
        color: theme.colors.clearMain,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        border: `1px dashed ${theme.colors.clearMain}`,
        fontSize: 11,
    },
}));

const TdComponent: ContentGridTdComponent<string> = memo(({ group, startTimestamp }) => {
    const styles = useStyles();
    const { t } = useTranslation(['general']);
    const dispatch = useAppDispatch();
    const actionsPopupMenu = useScheduleAppointmentActionsMenuContext();
    const { enqueueAlert } = alertsActions;
    const { setAppointmentDndModalState } = schedulerActions;
    const accountSettings = useAppSelector(selectAccountSettings);
    const mode = useAppSelector((state) => state.scheduler.currentViewName);
    const currentDate =  useAppSelector((state) => state.scheduler.currentDate);
    const weekDays = useAppSelector((state) => state.scheduler.weekDays);
    const daysOff = useAppSelector((state) => state.scheduler.daysOff);
    const isDragging = useAppSelector((state) => state.scheduler.isDragging);
    const localeApp = useAppSelector(state => state.applicationInterface.locale);

    let dragZone: string;
    let date: DateTime;
    let userId: string | null = null;
    if (mode === "Day"){
        dragZone = `Appointment-${group}`;
        date = DateTime.fromJSDate(currentDate).startOf("day").plus({minutes: startTimestamp});
        if (!(accountSettings.isEnterprise)) {
            userId = group;
        }
    } else {
        dragZone = "Appointment";
        date = DateTime.fromFormat(group, 'yyyy-MM-dd').plus({minutes: startTimestamp});
    }
    
    const isActive = isCellOpenedInSchedule();

    const [hovered, setHovered] = useState<boolean>(false);
    const [droppedAppointment, setDroppedAppointment] = useState<{appointment: Appointment, newDate: Date} | null>(null)
    const [{ canDrop, isOver }, drop] = useDrop(
      () => ({
          accept: dragZone,
          canDrop: () => !accountSettings.isEnterprise && date.toMillis() >= Date.now(),
          drop: async (data: {appointment: Appointment}) => {
              if (date.diff(data.appointment.startDate).toMillis() !== 0){
                  setDroppedAppointment({appointment: data.appointment, newDate: date.toJSDate()})
              }
          },
          collect: (monitor: any) => ({
              isOver: monitor.isOver(),
              canDrop: monitor.canDrop(),
          }),
      }), [date]
    )
    
    function isCellOpenedInSchedule(): boolean {
        // if it's in the past, then it's outside of schedule
        //if (date.toMillis() < Date.now()) return false;
        
        const weekDay = weekDays.find(wd => wd.dayNumber == date.weekday || wd.dayNumber === 0 && date.weekday === 7)!;
        
        const openingSplitted = weekDay.opening!.split(':')
        const openingHour = parseInt(openingSplitted[0]);
        const openingMinutes = parseInt(openingSplitted[1]);
        const openingHourDecimal = +(openingHour + (openingMinutes / 60)).toFixed(2);
        const roundedOpeningHourDecimal = Math.ceil(openingHourDecimal * 2) / 2;
        
        const closingSplitted = weekDay.closing!.split(':')
        const closingHour = parseInt(closingSplitted[0]);
        const closingMinutes = parseInt(closingSplitted[1]);
        const closingHourDecimal = +(closingHour + (closingMinutes / 60)).toFixed(2);
        const roundedClosingHourDecimal = Math.floor(closingHourDecimal * 2) / 2;
        
        const cellStartHour = +(date.hour + date.minute/ 60).toFixed(2);
        
        if (roundedOpeningHourDecimal > cellStartHour
          || roundedClosingHourDecimal <= cellStartHour
          || daysOff && daysOff.findIndex((day) => day === date.weekday || day === 0 && date.weekday === 7) !== -1)
            return false;
        
        return true;
    }
    
    async function handleMoveAppointment(appointment: Appointment, startDate: Date, allAppointments: boolean) {
        setDroppedAppointment(null);
        
        const response = await AppointmentService.updateTime(appointment.appointmentId as string, startDate, allAppointments)
        
        if (response.isUpdated) {
            dispatch(
              enqueueAlert({
                  type: "Success",
                  title: t("Appointment successfully modified"),
                  description: `${appointment.customerName} - ${formatDate(startDate)}`
              })
            );
            return;
        }
        
        if (!response.isUpdatable) {
            dispatch(
              enqueueAlert({
                  type: "Error",
                  title: t("Please try again."),
                  description: t("It is not possible to move appointments with past dates or times"),
              })
            );
            return;
        }
        
        if (response.isScheduleBusy === true) {
            dispatch(setAppointmentDndModalState({ open: true, hasSimultaneousAppointments: response.hasSimultaneousAppointments, appointmentId: appointment.appointmentId }))
            return;
        }
    }
    
    function formatDate(date: Date | null): string {
        const day = date?.toLocaleString(localeApp, {
            day: "2-digit",
        });
        const month = date?.toLocaleString(localeApp, {
            month: "long",
        });
        const year = date?.toLocaleString(localeApp, {
            year: "numeric",
        });
        
        return `${day}/${month}/${year}`;
    }

    return (
        <>
            <div
                ref={drop}
                aria-hidden={!isActive}
                className={clsx(styles.td, !isActive && styles.disabled)}
                onMouseOver={() => setHovered(true)}
                onMouseLeave={() => setHovered(false)}
                style={{zIndex: isDragging ? 1 : 'initial'}}
            >
                {!accountSettings.isEnterprise && hovered && !isDragging && (
                    <div
                        className={styles.actionOverlay}
                        onClick={(e) => actionsPopupMenu.open(date, userId, e.clientX, e.clientY)}
                    >
                        {t('scheduleNewAppointmentOrBlock')}
                    </div>
                )}
                {isOver && canDrop && (
                  <div className={styles.actionOverlay}>
                      {t('Drop')}
                  </div>
                )}
            </div>
            {!!droppedAppointment && // render only if needed
                <DragNDropConfirmationModal
                    open={!!droppedAppointment}
                    appointment={droppedAppointment.appointment}
                    toDateTime={droppedAppointment.newDate}
                    onClose={() => setDroppedAppointment(null)}
                    onContinue={(allAppointments) => handleMoveAppointment(droppedAppointment.appointment, droppedAppointment.newDate, allAppointments)}
                />
            }
      </>
    );
});

export default TdComponent;
