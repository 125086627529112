import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const PhoneIcon = () => (
  <SvgIcon style={{ width: 16, height: 16 }} viewBox="0 0 16 16">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.09192 0.885302C3.95248 0.442894 3.81192 0.0895282 3.34768 0.0452874L2.464 0.00104667C1.5344 -0.0426341 0 1.28347 0 3.49439C0.04648 7.34165 6.09112 13.6216 10.0901 13.9755C12.4617 14.1967 13.9496 12.8701 13.9961 11.941V11.1452C14.0426 10.7028 13.6707 10.5259 13.2054 10.3495C12.32 10.0375 11.4386 9.64161 10.5554 9.33193C10.3937 9.29642 10.2261 9.29787 10.0651 9.33617C9.904 9.37447 9.75367 9.44863 9.62527 9.55313C9.32535 9.77939 9.04527 10.0308 8.78807 10.3047C8.55567 10.4816 8.32327 10.5701 8.09031 10.3489C6.10647 9.3903 4.5254 7.7612 3.62656 5.74955C3.58593 5.69898 3.55703 5.64003 3.54194 5.57695C3.52685 5.51387 3.52594 5.44823 3.53928 5.38475C3.55262 5.32127 3.57987 5.26155 3.61907 5.20988C3.65828 5.1582 3.70846 5.11587 3.766 5.08594C4.09136 4.82049 4.326 4.59929 4.6032 4.3344C4.72677 4.23558 4.82197 4.1058 4.87911 3.95826C4.93626 3.81071 4.95332 3.65066 4.92856 3.49439C4.648 2.65437 4.3708 1.72532 4.09192 0.885302Z"
      fill="#919CA5"
    />
  </SvgIcon>
);

export default PhoneIcon;
