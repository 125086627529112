import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "row",
    gap: "3px"
  },
  count: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: theme.colors.clearMain
  }
})
);

export default useStyles;