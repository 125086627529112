export class GridInfoModel {

    page: number | null;
    pageSize: number;
    order: string | null;
    orderBy: string | null;
    value: any | null;  // searchValue
    properties?: string[] | null;
    total: number | null;

    constructor(x?: any) {
        if (typeof x === "object" && x !== null && x !== undefined) {
            this.page = x.page;
            this.pageSize = x.pageSize;
            this.order = x.order;
            this.orderBy = x.orderBy;
            this.value = x.value;
            this.total = x.total;
            this.properties = x.properties;
        } else {
            this.page = 0;
            this.pageSize = 20;
            this.order = "asc";
            this.orderBy = "";
            this.value = "";
            this.total = 0;
            this.properties = null;
        }
    }

}



