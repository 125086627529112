import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  commissionsHeaderBlock: {
    display: "flex",
    padding: "12px 24px",
    color: theme.colors.grey7,
    fontFamily: "Inter, sans-serif",
    fontWeight: 700,
    fontSize: "14px",
    backgroundColor: "white",
    marginBottom: 32,
    alignItems: "center"
  },
  actionButton: {
    "&.MuiButton-root": {
      minWidth: 0,
      marginLeft: 10
    },
  },
  title: {
    marginRight: 10
  },
  iconTooltipRoot: {
    "&>div": {
      maxWidth: 220
    }
  },
  header: {
    display: "flex",
    alignItems: "center",
    marginBottom: 15,
    gap: 20,
    width: "100%",
    justifyContent: "space-between",
  },
  tabTitle: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: Color.gray5
  },
})
);

export default useStyles;