import React from "react";

import { FormControlLabel, Radio } from "@material-ui/core";

import useStyles from "./css";
import { RadioWithContentProps } from "./props";


export default function RadioWithContent(props: RadioWithContentProps) {
  
  const { value, checked, onCheck, content, contentClass, disabled } = props;
  const classes = useStyles();
  
  function handleChecking() {
    onCheck();
  }
  
  return (
    <div className={classes.root}>
      <Radio
        checked={checked}
        onChange={handleChecking}
        color={checked ? "primary" : "default"}
        classes={{
          root: classes.radio,
          checked: classes.checked
        }}
        disabled={disabled}
      />
      <div className={`${classes.text} ${checked ? classes.checked : ''} ${!!contentClass ? contentClass : ''}`}>{content}</div>
    </div>
  );
}