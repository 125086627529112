import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  column: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
  },
  selectHrs: {
    "& div:after": {
      content: "'hrs'",
      marginLeft: "35px",
      marginBottom: "2px",
      position: "absolute",
      color: theme.colors.grey5,
    },
  },
  selectHr: {
    "& div:after": {
      content: "'hr'",
      marginLeft: "35px",
      marginBottom: "2px",
      position: "absolute",
      color: theme.colors.grey5,
    },
  },
  selectMin: {
    "& div:after": {
      content: "'min'",
      marginLeft: "35px",
      marginBottom: "2px",
      position: "absolute",
      color: theme.colors.grey5,
    },
  },
  selectStyle: {
    color: theme.colors.grey5,
    fontWeight: "bold",
    fontSize: 12,
    height: 40,
    padding: "13px 0px 13px 12px",
    border: "solid 1px rgba(0, 0, 0, 0.1)",
    borderRadius: 5,
    width: 320,
    paddingRight: 10,
    backgroundColor: "#F6F6F6",
    cursor: "pointer",
    "& * .ant-picker-suffix, .ant-picker-clear": {
      display: "none",
    },
    "& * input": {
      fontFamily: "Roboto",
      fontWeight: "bold",
      fontSize: 12,
      fontStyle: "normal",
      color: theme.colors.grey5,
      cursor: "pointer",
      "&:disabled": {
       color: "#5C6477",
      },
    },
  },
  form: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-between",
      width: "100%",
  },
  formItem: {
  },
  required: {
    color: theme.colors.clearMain,
    fontWeight: "bold",
  },
  optional: {
    fontWeight: "normal",
  },
  controlLabel: {
    ...theme.typography.buttons,
    fontWeight: "bold",
    color: theme.colors.grey4,
    marginBottom: 7
  },
  dot: {
    ...theme.typography.buttons,
    color: theme.colors.clearMain
  },
  itemRootClass: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    height: 40,
    display: "flex",
    fontSize: 12,
    alignItems: "center"
  },
  input: {
    width: "320px !important",
    margin: "0 0 22px"
  }
}));

export default useStyles;
