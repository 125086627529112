import React from "react";
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import { BroadcastIcon, CrossIcon } from "../../../assets/icons";

import { OutsideBusinessHoursModalProps } from "./props";
import useStyles from "./css";


const OutsideBusinessHoursModal = (props: OutsideBusinessHoursModalProps) => {

    const { t } = useTranslation(["general"]);
    const { open, onClose, onCancel, onConfirm } = props;
    const classes = useStyles();

    return (
        <Modal
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            className={classes.modalYellow}
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
                timeout: 500,
            }}>

            <Fade in={open}>
                <div className={classes.modalPaperYellow}>
                    <div className={classes.modalHeader}>
                        <div className={classes.modalHeaderContent}>
                            <div className={classes.modalCircle}>
                                <BroadcastIcon  />
                            </div>
                        </div>
                        <div className={classes.modalClose}>
                            <span className={classes.closeButton} onClick={onClose}>
                                <CrossIcon />
                            </span>
                        </div>
                    </div>

                    <div className={classes.modalContent}>

                        <div className={`${classes.modalCenter} ${classes.modalTitleSpace}`} >
                            <Typography className={classes.modalTitleText}>
                                {t("Appointment outside business hours")}
                            </Typography>
                        </div>
                        
                        <div className={classes.separator}></div>

                        <div className={classes.bodyContent}>
                            <div>
                                {t("The customized schedule is outside the establishment's business hours.")}
                            </div>
                            <br/>
                            <div>
                                {t("Do you want to continue?")}
                            </div>
                        </div>

                        <div className={classes.modalActions}>
                            <Button className={`${classes.button} ${classes.btnCancel}`} onClick={onCancel} >
                                {t("Go back")}
                            </Button>
                            <Button className={`${classes.button} ${classes.btnOk}`} onClick={onConfirm} >
                                {t("Yes, continue")}
                            </Button>
                        </div>
                    </div>
                </div>
            </Fade>
        </Modal>
    );
};

export default OutsideBusinessHoursModal;
