import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";


const PenIcon = (props: any) => {

  const { style, color, viewBox, ...otherProps } = props;

  return (
    <SvgIcon
      style={{ width: style?.width ?? 24, height: style?.width ?? 24, ...style }}
      viewBox={viewBox ?? "0 0 24 24"}
      {...otherProps}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.1736 15.0336L21.9336 20.3136C21.9884 20.7201 21.8648 21.1305 21.5946 21.4391C21.3243 21.7477 20.9338 21.9244 20.5236 21.9236H20.3136L15.0336 21.1636C14.3179 21.0649 13.6541 20.7348 13.1436 20.2236L3.40356 10.4736C1.5087 8.51167 1.5358 5.39314 3.46447 3.46447C5.39314 1.5358 8.51167 1.5087 10.4736 3.40356L20.2336 13.1436C20.7448 13.6541 21.0749 14.3179 21.1736 15.0336ZM14.2536 19.1636C14.5301 19.4292 14.8773 19.6097 15.2536 19.6836L20.4636 20.3736L19.7336 15.2036C19.6597 14.8273 19.4792 14.4801 19.2136 14.2036L9.46356 4.46356C8.80662 3.78913 7.90506 3.40874 6.96356 3.40874C6.02206 3.40874 5.1205 3.78913 4.46356 4.46356C3.80464 5.11864 3.43414 6.00942 3.43414 6.93856C3.43414 7.8677 3.80464 8.75848 4.46356 9.41356L14.2536 19.1636Z"
        fill={color || style?.color || "#F6F6F6"}
      />
      <path
        d="M7.43356 6.37356C7.13805 6.0982 6.67755 6.10633 6.39194 6.39194C6.10633 6.67755 6.0982 7.13805 6.37356 7.43356L10.5836 11.6436C10.8764 11.936 11.3507 11.936 11.6436 11.6436C11.936 11.3507 11.936 10.8764 11.6436 10.5836L7.43356 6.37356Z"
        fill={color || style?.color || "#F6F6F6"}
      />
    </SvgIcon>
  )
};

export default PenIcon;