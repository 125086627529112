import React, { useState } from "react";

import { IconCounterProps } from "./props";
import useStyles from "./css";

export default function IconCounter(props: IconCounterProps) {

  const { count, icon } = props;

  const classes = useStyles();

  return (
    <div className={classes.container}>
      {icon}
      <div className={classes.count}>
        {count}
      </div>
    </div>
  );
}