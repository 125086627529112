import React, { useEffect, useMemo, useState } from "react";
import { Backdrop, Button, Fade, Modal, Typography } from "@material-ui/core";
import { Trans, useTranslation } from "react-i18next";
import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import Draggable, { ControlPosition } from 'react-draggable';
import useStyles from "./css";
import { blockedScheduleActions } from "../../../redux/blocked-schedule-slice";
import { ExclamationIcon, TrashIcon } from "../../../assets/icons";
import { alertsActions } from "../../../redux/alerts-slice";
import BlockedScheduleForm from "../BlockedScheduleForm";
import { UserService } from "../../../api/user-service";
import BlockedScheduleService from "../../../api/blocked-schedule-service";

import { SelectItem } from "../../common/Select";
import { ApiClient } from "../../../api/api-client";
import BlockSchedule, { Validator } from "../../../models/blocked-schedule";
import BlockedSchedule from "../../../models/blocked-schedule";

const BlockScheduleModal = (props: any) => {

  const { refresh } = props;
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  
  const dispatch = useAppDispatch();
  const showModal = useAppSelector(state => state.blockedSchedule.showModal);
  const blockedSheduleId = useAppSelector((state) => state.blockedSchedule.blockedSheduleId);
  const userInfo = useAppSelector(state => state.userInfo);
  const { enqueueAlert } = alertsActions;
  
  const [position, setPosition] = useState<ControlPosition>({ x: 0, y: 0 });
  const draggableId = useMemo(
    () => `draggable-schedule-popup-handle-${Math.random().toString(36).substring(2)}`,
    []
  );
  
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [confirmBlockModal, setConfirmBlockModal] = useState<boolean>(false);
  const [counterAppointments, setCounterAppointments] = useState<number>(0);
  const [isValid, setIsValid] = useState<boolean>(false);
  const [isChanged, setIsChanged] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [users, setUsers] = useState<SelectItem[]>([]);
  const [userName, setUserName] = useState<string>("");
  const [entity, setEntity] = useState<BlockSchedule>(new BlockSchedule());
  const [entityInit, setEntityInit] = useState<BlockSchedule>(new BlockSchedule());
  const [entityValid, setEntityValid] = useState<Validator>({
    specialistId: true,
    reasonId: true,
    date: true,
    start: true,
    end: true
  });
  const AllSpecialists = "AllSpecialists";

  const loadUsers = async () => {
    const usersOnlyWithServices = false;
    const usersDb = await UserService.getUsersBaseInfoByAccount(usersOnlyWithServices);

    let selectItems:SelectItem[] = [];
    
    selectItems.push({
      key: AllSpecialists,
      value: t(AllSpecialists),
    } as SelectItem);

    usersDb.map((u) => {
        const select: SelectItem = {
            key: u.id,
            value: `${u.firstName} ${u.lastName}`,
        };
        return selectItems.push(select);
    });

    setUsers(selectItems);

    const currentUserAuthId = ApiClient.getUserInfo().userId;
    const currentUser = usersDb.filter(u => u.authenticationId == currentUserAuthId);

    if (currentUser.length > 0) {
        const fullName = currentUser[0].firstName + " " + currentUser[0].lastName;
        setUserName(fullName);
    }
  };

  useEffect(() => {



  }, [])

  const getBlockedSchedule = async () => {
    const schedule: BlockSchedule = await BlockedScheduleService.getBlockedScheduleById(blockedSheduleId);
    setEntity(schedule);
    setIsEdit(true);
  }

  useEffect(() => {
    if (blockedSheduleId) {
      (async () => {
        await getBlockedSchedule();
      })();
    }
  }, [blockedSheduleId])

  useEffect(() => {
    if (!showModal) return;

    if (!users.length){
      (async () => {
        await loadUsers();
      })();
    }

    setEntity(new BlockSchedule());
    const v: Validator = {
      specialistId: true,
      reasonId: true,
      date: true,
      start: true,
      end: true
    };
    setEntityValid(v);
    
  }, [showModal])

  const handleCloseModal = () => {
    setEntity(new BlockSchedule());
    setIsEdit(false);
    setIsChanged(false);
    dispatch(blockedScheduleActions.setShowModal(false));
    dispatch(blockedScheduleActions.setBlockedSheduleId(undefined));
  };
  
  const handleGoBackClick = () => {
    if (isChanged) {
      setCancelModal(true);
    }
    else {
      handleCloseModal();
    }
  }

  const handleOnCreateClick = async () => {

    const entityToSave = {...entity, 
      specialistId: entity.specialistId !== AllSpecialists ? entity.specialistId : null 
    };

    let response;
    if (!entityToSave.id){
      response = await BlockedScheduleService.createBlockedSchedule(entityToSave);
    } else {
      response = await BlockedScheduleService.updateBlockedSchedule(entityToSave);
    }

    if (response) {
      if (!response.forbiddenToBlock) {
        handleCloseModal();
        dispatch(
          enqueueAlert({
            type: "Success",
            title: t("Blocked schedule"),
            description:  isEdit ? 
                          `${t("The block schedule has been updated")}` : 
                          `${t("Schedule blocked by")} ${entity.isBlockedReasonPredetermined && entity.blockedReason ? t(entity.blockedReason) : entity.blockedReason}`,
          })
        );
        if (refresh) refresh();
      }
      else {
        setConfirmBlockModal(true);
        setCounterAppointments(response.appointmentsNotServed);
      }
    }
  }

  const handleOnDeleteClick = async () => {
    
    const entityToSave = {...entity, 
      specialistId: entity.specialistId !== AllSpecialists ? entity.specialistId : null 
    };

    const deleting = await BlockedScheduleService.deleteBlockedSchedule(entityToSave);

    if (deleting){
      handleCloseModal();
      dispatch(
          enqueueAlert({
            type: "Success",
            title: t("Block schedule removed"),
            description: t("The blocked schedule has been successfully deleted"),
          })
        );
      if (refresh) refresh();
    }
  }

  
  const handleFormChange = (e: BlockSchedule) => {
    setEntity(e);
    runValidations(e);

    const isSameObj = JSON.stringify(entityInit) !== JSON.stringify(e);
    setIsChanged(isSameObj);
  };

  
  const runValidations = (item: BlockSchedule) => {
    const v: Validator = {
      specialistId: !userInfo.hasAccessToScheduleAllSpecialists || Boolean(item.specialistId) && item.specialistId !== "",
      reasonId: item.blockedReasonId !== "",
      date: true,
      start: (item.start && item.end) ? item.start <= item.end : true,
      end: (item.start && item.end) ? item.start <= item.end : true
    };
    
    const isV: boolean = v.specialistId && v.reasonId && v.date && v.start && v.end;
    setIsValid(isV);
    setEntityValid(v);

  };

  const handleForceSave = async () => {
    
    const entityToSave = {...entity, 
      specialistId: entity.specialistId !== AllSpecialists ? entity.specialistId : null 
    };

    let response;
    if (!entityToSave.id){
      response = await BlockedScheduleService.createBlockedSchedule(entityToSave, true);
    } else {
      response = await BlockedScheduleService.updateBlockedSchedule(entityToSave, true);
    }
    if (response) {
        handleCloseModal();
        setConfirmBlockModal(false);
        dispatch(
          enqueueAlert({
            type: "Success",
            title: t("Blocked schedule"),
            description: isEdit ? 
                          `${t("The block schedule has been updated")}` : 
                          `${t("Schedule blocked by")} ${entity.isBlockedReasonPredetermined && entity.blockedReason ? t(entity.blockedReason) : entity.blockedReason}`,
          })
        );
        if (refresh) refresh();
    }
  }

  return (<>
   
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={showModal}
      onClose={handleGoBackClick}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={showModal}>
        <Draggable
          position={position}
          enableUserSelectHack={false}
          onDrag={(_, { x, y }) => setPosition({ x, y })}
          bounds="body"
          handle={'#' + draggableId}
        >
          <div className={classes.paper}>
            <div className={classes.draggableModalHeader} id={draggableId}></div>
            <div className={classes.modalHeader}>
              <div className={classes.modalTitle}>
                <Typography className={classes.modalTitleText} variant="h1">
                  {isEdit
                  ?
                  t("Block schedule detail")
                  :
                  t("Block schedule")
                  }
                  
                </Typography>
                
                <div className={classes.modalSubTitleText}>{t("Person blocking schedule by:") + " " + userName}</div>
                </div>
             
                <div className={classes.modalActions}>
                {isEdit &&
                    <Button className={`${classes.iconTrash}`} onClick={handleOnDeleteClick} >
                        <TrashIcon style={{ color: "#919CA5", fontSize: 14 }} />
                    </Button>
                }
                
                <Button
                  className={`${classes.button} ${classes.goBack}`}
                  onClick={handleGoBackClick}
                >
                  {t("Go back")}
                </Button>
                <Button
                  className={`${classes.button} ${classes.createCustomer}`}
                  disabled={!(isValid && isChanged)}
                  classes={{ disabled: classes.createDisabled }}
                  onClick={handleOnCreateClick}
                >
                  {isEdit
                  ?
                  t("Save changes")
                  :
                  t("Block schedule")
                  }
                </Button>
              </div>
            </div>
  
            <BlockedScheduleForm
              users={users}
              item={entity}
              validator={entityValid}
              onChange={handleFormChange}
            />
            
          </div>
        </Draggable>
      </Fade>
    </Modal>
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={cancelModal}
        onClose={() => setCancelModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        >
        <Fade in={cancelModal}>
            <div className={classes.cancelModalPaper}>
            <div>
                <div
                className={`${classes.cancelModalCenter} ${classes.cancelModalTitleSpace}`}
                >
                <Typography className={classes.modalTitleText}>

                    {isEdit 
                      ?
                      t("Cancel block schedule edition?")
                      :
                      t("Cancel block schedule registration?")
                    }
                </Typography>
                </div>
                <div className={classes.modalActions}>
                <Button
                    className={`${classes.button} ${classes.goBack}`}
                    onClick={() => setCancelModal(false)}
                >
                    {t("Go back")}
                </Button>
                <Button
                    className={`${classes.button} ${classes.cancelConfirmation}`}
                    onClick={() => {
                    handleCloseModal();
                    setCancelModal(false);
                    }}
                >
                    {t("Cancel")}
                </Button>
                </div>
            </div>
            </div>
        </Fade>
    </Modal>

    
    <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modalConfirm}
        open={confirmBlockModal}
        onClose={() => setConfirmBlockModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
            timeout: 500,
        }}
        >
        <Fade in={confirmBlockModal}>
            <div className={classes.confirmModalPaper}>
            <div>
                <div className={classes.modalHeaderContent}>
                    <div className={classes.modalCircle}>
                        <ExclamationIcon viewBox="0 0 5 16" />
                    </div>
                </div>
                <div className={`${classes.cancelModalCenter} ${classes.cancelModalTitleSpace}`} >
                  <Typography className={classes.modalConfirmTitleText}>
                      {t("Continue with the block schedule?")}
                  </Typography>
                </div>
                
                <div className={`${classes.cancelModalCenter} ${classes.cancelModalTitleSpace}`} >
                  <Typography className={classes.modalConfirmSubTitleText}>
                      <Trans i18nKey="ThereAreAppointmentsToBlockSchedule" t={t} count={counterAppointments} />
                  </Typography>
                </div>
                
                <div className={`${classes.cancelModalCenter} ${classes.cancelModalTitleSpace}`} >
                  <Typography className={classes.modalConfirmSubTitleText}>
                      <Trans i18nKey="ThereAreAppointmentsToBlockScheduleSuggest" t={t} />
                  </Typography>
                </div>

                <div className={classes.modalActionsConfirm}>
                <Button
                    className={`${classes.button} ${classes.goBack}`}
                    onClick={() => setConfirmBlockModal(false)}
                >
                    {t("Go back")}
                </Button>
                <Button
                    className={`${classes.button} ${classes.cancelConfirmation}`}
                    onClick={handleForceSave}
                >
                    {t("Save changes")}
                </Button>
                </div>
            </div>
            </div>
        </Fade>
    </Modal>
  </>);
};

export default BlockScheduleModal;