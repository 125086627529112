import { makeStyles } from "@material-ui/core/styles";

const useStyles = (color?: string) => makeStyles((theme) => ({
    idleRoot: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      height: 24
    },
    idleValue: {
      ...theme.typography.txtBody1,
      fontWeight: 700,
      color: color ?? theme.colors.grey5,
      marginRight: 6
    },
    editRoot: {
      position: "relative"
    },
    input: {
      backgroundColor: theme.colors.grey1,
      height: 24,
      padding: "13px 0px 13px 12px",
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      width: 148,
    },
    inputText: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey5,
      "&::placeholder": {
        fontWeight: "normal",
        color: "#676767",
      },
    },
    inputFocused: {
      border: "solid 1px #6462F3",
      padding: "14px 0px 14px 12px",
      width: 160,
    },
    inputDisabled: {
      color: "#676767",
    },
    inputError: {
      color: theme.colors.clearRed,
      border: `solid 1px ${theme.colors.clearRed}`,
    },
    editSaveButton: {
      cursor: "pointer",
      marginRight: 7
    },
    editSaveIcon: {
      position: "absolute",
      right: 2,
      top: 8,

      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      cursor: "pointer",
    }
  })
);

export default useStyles;