import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import { useAppDispatch } from "../../../../../redux/hooks";
import { navbarActions } from "../../../../../redux/navbar-slice";

import { Button } from "@material-ui/core";

import { CommissionService } from "../../../../../api/commission-service";
import { UserCommissions } from "../../../../../models/user-commissions";
import { CommissionListProps } from "./props";
import Pagination from "../../../../common/Pagination";
import useStyles from "./css";
import CommissionModal from "../CommissionModal";
import { UserCommissionInfo } from "../../../../../models/user-commission-info";
import { UserCommissionType } from "../../../../../models/enums/user-commission-type";


export default function CommissionList(props: CommissionListProps) {

  const { settingsSelectedEstablishmentId } = props;

  const { t } = useTranslation(["settings"]);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  
  const [usersCommissions, setUsersCommissions] = useState<UserCommissions[]>([]);
  const [totalUserCommissions, setTotalUserCommissions] = useState<number>(0);
  
  const [isCommissionModalOpened, setIsCommissionModalOpened] = useState<boolean>(false);
  const [currentUserCommission, setCurrentUserCommission] = useState<UserCommissionInfo>(new UserCommissionInfo());
  const [currentUserId, setCurrentUserId] = useState<string>("");
  const [currentUserName, setCurrentUserName] = useState<string>("");
  const [modalOpenedAs, setModalOpenedAs] = useState<UserCommissionType>(UserCommissionType.Default);
  
  const [page, setPage] = useState<number>(1);
  const pageSize: number = 12;
  
  
  useEffect(() => {
    async function fetchData() {
      await loadData();
    }
    
    void fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsSelectedEstablishmentId, page]);
  
  async function loadData() {
    dispatch(navbarActions.setShowLoader(true));
    const usersCommissions = await CommissionService.getUsersCommissions(
      page,
      pageSize,
      settingsSelectedEstablishmentId
    );
    dispatch(navbarActions.setShowLoader(false));
    setUsersCommissions(usersCommissions.usersCommissions || []);
    setTotalUserCommissions(usersCommissions.totalUserCommissions || 0);
  }
  
  function handlePage(event: React.ChangeEvent<unknown>, page: number) {
    setPage(page);
  }
  
  async function openEditByService(user: UserCommissions) {
    setModalOpenedAs(UserCommissionType.ByService);
    await openCommissionModal(user);
  }
  
  async function openEditByDefault(user: UserCommissions) {
    setModalOpenedAs(UserCommissionType.Default);
    await openCommissionModal(user);
  }
  
  async function openCommissionModal(user: UserCommissions) {
    dispatch(navbarActions.setShowLoader(true));
    const userCommission = await CommissionService.getUserCommissionsById(user.id!, settingsSelectedEstablishmentId);
    dispatch(navbarActions.setShowLoader(false));
    
    setCurrentUserCommission(userCommission);
    setCurrentUserId(user.id!);
    setCurrentUserName(user.name!);
    setIsCommissionModalOpened(true);
  }
  
  function closeCommissionModal() {
    setIsCommissionModalOpened(false);
  }
  
  function renderAsCommissionCard(user: UserCommissions) {
    return (
      <div className={classes.userServices}>
        <div className={classes.userName}>{user.name}</div>
        <div className={classes.servicesCount}>{t("Number of services")}: {user.servicesCount}</div>
        <div className={classes.buttons}>
          <Button
            className={`${classes.button}`}
            onClick={() => openEditByService(user)}
            disabled={!user.hasServicesInCharge}
          >
            {t('Edit by service')}
          </Button>
          <Button
            className={`${classes.button}`}
            onClick={() => openEditByDefault(user)}
          >
            {t('Edit by default')}
          </Button>
        </div>
      </div>
    )
  }
  
  return (
    <div className={classes.pageContent}>
      <div className={classes.usersServices}>
        {usersCommissions.map(renderAsCommissionCard)}
      </div>
      <Pagination
        count={Math.ceil(totalUserCommissions / pageSize)}
        page={page || 1}
        onPageChange={handlePage}
        showPaginationAlways={false}
      />
      <CommissionModal
        isOpen={isCommissionModalOpened}
        setOpen={closeCommissionModal}
        userCommission={currentUserCommission}
        setUserCommission={setCurrentUserCommission}
        userId={currentUserId}
        userName={currentUserName}
        openedCommissionType={modalOpenedAs}
        settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
      />
    </div>
  );
}