import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    maxHeight: "calc(100vh - 152px)",
    overflowY: "scroll",
    borderRadius: "0px 0px 12px 12px",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    scrollbarWidth: "none",
    "-ms-overflow-style": "none",
    "& .MuiAccordion-root": {
    },
    "& .MuiAccordionSummary-root": {
      background: "#FAFAFA",
      minHeight: "unset",
      height: "46px"
    },
    "& .MuiAccordionSummary-root.Mui-expanded": {
      minHeight: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
    },
    "& .MuiPaper-elevation1": {
      boxShadow: "none"
    },
    "& .MuiAccordionDetails-root": {
      padding: "40px 16px 24px 16px"
    },
  },
  sectionsListContainer: {
    display: "flex",
    flexDirection: "column",
    paddingBottom: "24px",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "column",
  },
  fieldsEditionContainer: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
    width: "100%",
    gap: 24
  },
  fieldEditionContainer: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    gap: 2
  },
  sectionLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.clear2
  },
  signatureBlock: {
    marginBottom: 15,
    fontSize: 12
  },
  signatureBlockTitle: {
    fontWeight: 700,
    marginBottom: 5,
    fontSize: 12,
    color: "#676767",
  },
  signatureBlockCheckbox: {
    display: "flex",
    alignItems: "center",
    "& > *:first-child": {
      marginRight: 5
    },
    "& > div": {
      display: "flex"
    }
  },
  asterisk: {
    color: "#6462F3"
  },
  signatureSectionContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    gap: 10
  },
  signatureSectionTitle: {
    fontWeight: 700,
    marginBottom: 5,
    fontSize: 12,
    color: "#676767",
  },
  signatureContainer: {
    flexDirection: "column"
  },
  emptySignatureBox: {
    width: 329,
    height: 118,
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "5px"
  },
  signaturePlaceholder: {
    padding: 12,
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: "#676767",
    opacity: "0.8",
  },
  metaInfoContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 9
  },
  metaInfoFieldContainer: {
    display: "flex",
    flexDirection: "column",
  },
  metaInfoFieldTitle: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: Color.gray4,
  },
  metaInfoFieldValue: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "17px",
    color: Color.gray5,
  },
  signature: {
    border: "1px solid rgba(0, 0, 0, 0.1)",
    borderRadius: "5px"
  },
  checkBoxText: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    fontWeight: 400
  },
  link: {
    fontWeight: 700,
    "&:focus": {
      textDecoration: "none",
      color: theme.colors.clearMain
    },
    "&:hover": {
      textDecoration: "none",
      color: theme.colors.clearMain
    },
    "&:visited": {
      textDecoration: "none",
      color: theme.colors.clearMain
    },
    "&:link" : {
      textDecoration: "none",
      color: theme.colors.clearMain
    },
    "&:active": {
      textDecoration: "none",
      color: theme.colors.clearMain
    }
  }
}));

export default useStyles;
