import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function MedicalBookIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M19 15.2188V4.8125C19 4.3125 18.6875 4 18.1875 4H8C6.28125 4 5 5.3125 5 7V17C5 18.7188 6.28125 20 8 20H18.1875C18.5938 20 19 19.7188 19 19.2188V18.7188C19 18.5 18.875 18.3125 18.6875 18.125C18.5938 17.625 18.5938 16.2188 18.6875 15.8125C18.875 15.7188 19 15.5 19 15.2188ZM9.5 9.25C9.5 9.125 9.59375 9 9.75 9H11.5V7.25C11.5 7.125 11.5938 7 11.75 7H13.25C13.375 7 13.5 7.125 13.5 7.25V9H15.25C15.375 9 15.5 9.125 15.5 9.25V10.75C15.5 10.9063 15.375 11 15.25 11H13.5V12.75C13.5 12.9063 13.375 13 13.25 13H11.75C11.5938 13 11.5 12.9063 11.5 12.75V11H9.75C9.59375 11 9.5 10.9063 9.5 10.75V9.25ZM16.875 18H8C7.375 18 7 17.625 7 17C7 16.4063 7.5 16 8 16H16.875V18Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}
