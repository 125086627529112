import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";


const BankIcon = (props: any) => {
  
  const { color, style, ...otherProps } = props;
  
  return (
    <SvgIcon
      {...otherProps}
      style={{ ...style, fontSize: '24px' }}
      viewBox="0 0 24 24"
    >
      <path
        d="M18.6992 7.54297L12.3008 5.08203C12.2187 5.02734 12.082 5 12 5C11.8906 5 11.7539 5.02734 11.6719 5.08203L5.27344 7.54297C5.10937 7.59766 5 7.76172 5 7.95312V8.9375C5 9.18359 5.19141 9.375 5.4375 9.375H18.5625C18.7812 9.375 19 9.18359 19 8.9375V7.95312C19 7.76172 18.8633 7.59766 18.6992 7.54297ZM6.75 10.25V14.625H6.3125C6.06641 14.625 5.875 14.8437 5.875 15.0625V16.375H18.125V15.0625C18.125 14.8437 17.9062 14.625 17.6875 14.625H17.25V10.25H15.5V14.625H12.875V10.25H11.125V14.625H8.5V10.25H6.75ZM18.5625 17.25H5.4375C5.19141 17.25 5 17.4687 5 17.6875V18.5625C5 18.8086 5.19141 19 5.4375 19H18.5625C18.7812 19 19 18.8086 19 18.5625V17.6875C19 17.4687 18.7812 17.25 18.5625 17.25Z"
        fill={color || style?.color || "#919CA5"}
      />
    </SvgIcon>
  )
};
export default BankIcon;