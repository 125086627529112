import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ExportFileIcon = (props: any) => {
  const { style, viewBox, color, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={style || { width: 12, height: 12 }}
      viewBox={viewBox || "0 0 12 12"}
    >
        <g clip-path="url(#clip0_2953_19762)">
          <path d="M5.1155 0.618405L2.6405 3.09341L3.3495 3.79991L5.4995 1.64991V8.69141H6.4995V1.64991L8.6495 3.79991L9.3565 3.09291L6.8815 0.617906C6.76543 0.501798 6.62761 0.409693 6.47593 0.346854C6.32426 0.284015 6.16168 0.251673 5.9975 0.251673C5.83332 0.251673 5.67075 0.284015 5.51907 0.346854C5.36739 0.409693 5.22958 0.501798 5.1135 0.617906L5.1155 0.618405Z"
                fill={color ?? "#5C6477"}
          />
          <path d="M10.5 6.762C10.6326 6.762 10.7598 6.81468 10.8536 6.90845C10.9473 7.00221 11 7.12939 11 7.262V10.5C11 10.6326 10.9473 10.7598 10.8536 10.8536C10.7598 10.9473 10.6326 11 10.5 11H1.5C1.36739 11 1.24021 10.9473 1.14645 10.8536C1.05268 10.7598 1 10.6326 1 10.5L1 7.262C1 7.12939 1.05268 7.00221 1.14645 6.90845C1.24021 6.81468 1.36739 6.762 1.5 6.762H4.1665V5.762H1.5C1.10218 5.762 0.720644 5.92004 0.43934 6.20134C0.158035 6.48264 0 6.86418 0 7.262L0 10.5C0 10.8978 0.158035 11.2794 0.43934 11.5607C0.720644 11.842 1.10218 12 1.5 12H10.5C10.8978 12 11.2794 11.842 11.5607 11.5607C11.842 11.2794 12 10.8978 12 10.5V7.262C12 6.86418 11.842 6.48264 11.5607 6.20134C11.2794 5.92004 10.8978 5.762 10.5 5.762H7.8335V6.762H10.5Z"
                fill={color ?? "#5C6477"}
          />
        </g>
        <defs>
          <clipPath id="clip0_2953_19762">
            <rect width="12" height="12" fill="white" transform="matrix(0 -1 1 0 0 12)"/>
          </clipPath>
        </defs>

    </SvgIcon>
  );
};

export default ExportFileIcon;
