import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const ExternalLink = (props: any) => {
  
  const { color, style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon {...props}
             viewBox={viewBox}
    >
      <path
        d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H9V2H2V16H16V9H18V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM6.7 12.7L5.3 11.3L14.6 2H11V0H18V7H16V3.4L6.7 12.7Z"
        fill={color || style?.color || "#000000"}
      />
    </SvgIcon>
  )
};

export default ExternalLink;
