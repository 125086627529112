import React, { useEffect, useState } from 'react';

import { useTranslation } from "react-i18next";

import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { alertsActions } from "../../../../../redux/alerts-slice";

import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import { Box, Grid } from "@material-ui/core";
import { DropzoneAreaBase, FileObject } from "material-ui-dropzone";

import { ServiceImportModalProps } from "./props";
import useStyles from "./css";
import ServicesService from "../../../../../api/settings-services-service";
import FileService from "../../../../../api/file-service";
import { DownloadFileCloud, ExcelIcon, UploadFileCloud } from "../../../../../assets/icons";


export default function ServiceImportModal(props: ServiceImportModalProps) {

  const { t } = useTranslation(["general"]);
  const { settingsSelectedEstablishmentId, open, onClose, refreshServices } = props;
  const classes = useStyles();

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openCancelModal, setOpenCancelModal] = useState<boolean>(false);

  const [cancelModalShouldAppear, setCancelModalShouldAppear] = useState<boolean>(false);

  const [templateFileName, setTemplateFileName] = useState<string>("");

  const [file, setFile] = useState<FileObject[]>([]);
  const [fileSize, setFileSize] = useState<number>(0);
  const [fileName, setFileName] = useState<string>(t("No file."));
  const [isValid, setIsValid] = useState<boolean>(false);

  const maxFileSize: number = 1 * 1024 * 1024; //1 megabyte
  const localeApp = useAppSelector(state => state.applicationInterface.locale);

  const { enqueueAlert } = alertsActions;
  const dispatch = useAppDispatch();

  const servicesImportTitle = t("Import of services")
  const servicesAddedDescription = t("Services were added successfully")
  const servicesNotImportedDescription = t("Services were not imported")

  const templateFileNameEN = "List_of_services.xlsx";
  const templateFileNameES = "Lista_de_servicios.xlsx";

  useEffect(() => {
    setTemplateFileName(localeApp === 'es' ? templateFileNameES : templateFileNameEN)
  }, [localeApp]);

  useEffect(() => {
    setOpenModal(open);
    if (open) {
      cleanupModal();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open]);

  function handleTryCloseModal() {
    if (cancelModalShouldAppear) {
      setOpenCancelModal(true);
    } else {
      handleCloseModal();
    }
  }

  function handleCloseModal() {
    setOpenModal(false);
    if (onClose) {
      onClose();
    }
  }

  async function handleOnUploadClick() {
    if (isValid) {
      setIsValid(false);
      let uploadResponse = await ServicesService.createServicesFromFile(
        file[0].file,
        settingsSelectedEstablishmentId
      );

      if (!uploadResponse.isFileProcessed) {
        dispatch(
          enqueueAlert({
            type: "Error",
            title: t("File could not be uploaded"),
            description: servicesNotImportedDescription
          })
        );

        handleCloseModal();
        return;
      }

      const processed: string = uploadResponse.processedRecordsCount! + "";
      const total: string = uploadResponse.totalRecordsCount! + "";

      if (uploadResponse.processedRecordsCount == uploadResponse.totalRecordsCount) {
        dispatch(
          enqueueAlert({
            type: "Success",
            title: servicesImportTitle,
            description: servicesAddedDescription
          })
        );

        handleCloseModal();
        refreshServices();
        return;
      }

      if (uploadResponse.processedRecordsCount! < uploadResponse.totalRecordsCount!) {
        dispatch(
          enqueueAlert({
            type: "Warning",
            title: servicesImportTitle,
            description:
              <>
                {t("{n} of {m} records were uploaded").replace("{n}", processed).replace("{m}", total)}
              <div
                className={classes.link}
                onClick={async () => await handleOnDownloadClick(uploadResponse.invalidRecordsFile)}
                >
                {t("View records that were not loaded")}
              </div>
            </>
          })
        );

        handleCloseModal();
        refreshServices();
        return;
      }
    }

    cleanupModal();
  }

  async function handleOnDownloadClick(responseFile: Uint8Array | null) {
    if (responseFile) {
      const fileUrl = URL.createObjectURL(new Blob([responseFile], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}));
      const link = document.createElement("a");
      link.setAttribute("href", fileUrl);
      link.setAttribute("download", templateFileName);
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
    }
  }

  function handleOnAddFile(files: FileObject[]) {
    setFile(files);
    setFileName(files[0].file.name);
    let size = files[0].file.size / (1024 * 1024);
    setFileSize(Math.round((size + Number.EPSILON) * 100) / 100); // in megabytes
    setIsValid(validateFile(files[0].file));
    setCancelModalShouldAppear(true);
  }

  function handleDropRejected(file: File[]) {
    if (file[0].name.split(".").pop() !== "xlsx") {
      dispatch(
        enqueueAlert({
          type: "Error",
          title: t("Error"),
          description: t("Use the indicated .xlsx format")
        })
      );
    }
  }

  function validateFile(file: File) {
    if (file.size > maxFileSize)
      return false;

    return file.name.split(".").pop() === "xlsx";
  }

  function cleanupModal() {
    setFile([]);
    setFileName(t("No file."));
    setFileSize(0);
    setIsValid(false);
    setCancelModalShouldAppear(false);
    setOpenCancelModal(false);
  }

  async function downloadTemplateFile() {
    const fileUrl = await FileService.getTemplateFileUrl(templateFileName, localeApp!);

    const link = document.createElement("a");
    link.setAttribute("href", fileUrl);
    link.setAttribute("download", templateFileName);
    document.body.appendChild(link);
    link.click();
    link.parentNode?.removeChild(link);
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={openModal}
      onClose={handleTryCloseModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={openModal}>
        <div>
          <div
            className={classes.paper}
            style={openCancelModal ? { display: "none" } : {}}
          >
            <div className={classes.modalHeader}>
              <div className={classes.modalTitle}>
                <Typography className={classes.modalTitleText} variant="h1">
                  {t("Import services")}
                </Typography>
              </div>
              <div className={classes.modalActions}>
                <Button
                  className={`${classes.button} ${classes.goBack}`}
                  onClick={handleTryCloseModal}
                >
                  {t("Go back")}
                </Button>
                <Button
                  className={`${classes.button} ${classes.uploadButton}`}
                  disabled={!isValid}
                  classes={{ disabled: classes.createDisabled }}
                  onClick={handleOnUploadClick}
                >
                  {t("Upload file")}
                </Button>
              </div>
            </div>
            <Box sx={{ width: "100%" }} pt={1}>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <DropzoneAreaBase
                    dropzoneClass={classes.dropZone}
                    dropzoneParagraphClass={classes.dropZoneText}
                    fileObjects={file}
                    dropzoneText={t(
                      "Drag or click here to upload your .xlsx file"
                    )}
                    onAdd={handleOnAddFile}
                    showPreviewsInDropzone={false}
                    showAlerts={false}
                    filesLimit={1}
                    acceptedFiles={[
                      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
                    ]}
                    maxFileSize={maxFileSize}
                    onDropRejected={handleDropRejected}
                    // @ts-ignore: type negotiation problem
                    Icon={UploadFileCloud} // works and builds but https://github.com/Yuvaleros/material-ui-dropzone/issues/246
                  />
                </Grid>
                <Grid item xs={1} style={{ paddingLeft: "38px" }}>
                  <ExcelIcon
                    style={isValid ? { color: "#6462F3" } : {}}
                  />
                </Grid>
                <Grid
                  item
                  xs={5}
                  style={{ paddingLeft: "48px", paddingTop: "30px" }}
                >
                  <Typography className={classes.fileName}>
                    {fileName}
                  </Typography>
                  <Typography className={classes.fileSize}>
                    {t("Size: ")}
                    <b>{fileSize} MB</b>
                  </Typography>
                </Grid>
              </Grid>
              <hr className={classes.divider}/>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <Typography className={classes.notesTitle}>
                    {t("NOTES: ")}
                  </Typography>
                  <Typography className={classes.notes}>
                    {t("Accepted format: xlsx")}
                  </Typography>
                  <Typography className={classes.notes}>
                    {t("Maximum allowed document size: 1 Mb")}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={6}
                  container
                  direction="row"
                  alignItems="center"
                  justifyContent="flex-end"
                >
                  <Typography
                    className={classes.downloadTemplate}
                    onClick={downloadTemplateFile}
                  >
                    <DownloadFileCloud
                      style={{ fontSize: 18, margin: "0px 4px 2px 0px" }}
                    />
                    {t("Download template")}
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          </div>
          <div
            className={classes.cancelModalPaper}
            style={openCancelModal ? {} : { display: "none" }}
          >
            <div
              className={`${classes.modalCenter} ${classes.modalTitleSpace}`}
            >
              <Typography className={classes.modalTitleTextCancel}>
                {t("Cancel the upload of the .xlsx file?")}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.cancelModalButton} ${classes.goBackCancel}`}
                onClick={() => setOpenCancelModal(false)}
              >
                {t("Go back")}
              </Button>
              <Button
                className={`${classes.cancelModalButton} ${classes.cancelConfirmation}`}
                onClick={handleCloseModal}
              >
                {t("Yes, cancel it")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}