import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

const useStyles = makeStyles((height: number) => ({
    mainBox: {
        border: "1px solid",
        borderColor: Color.gray2,
        boxSizing: "border-box",
        borderRadius: "5px",
        width: "100%",
        overflow: "hidden",
    },
    innerContainer: {
        display: "flex",
        alignItems: "center",
        gap: 10,
        backgroundColor: Color.white,
        padding: 12,
        width: "100%",
    },
    justifyEnd: {
        marginLeft: "auto",
        minWidth: "fit-content",
        alignSelf: "baseline",
        marginTop: -6
    },
    actionButton: {
        "&.MuiButton-root": {
            minWidth: 0
        },
    },
}));

export default useStyles;