import React, { useState } from "react";

import IconButton from "@material-ui/core/IconButton";

import useStyles from "./css";
import { BlockCustomerIconProps } from "./props";
import { BlockIcon } from "../../../assets/icons";
import CloseIcon from "@material-ui/icons/Close";
import { Tooltip } from "@material-ui/core";
import { useTranslation } from "react-i18next";


export default function BlockCustomerIcon(props: BlockCustomerIconProps) {

  const { isBlocked, isRegistered, onClick } = props;
  
  const { t } = useTranslation(["general"]);
  
  const [isHovered, setIsHovered] = useState<boolean>(false);
  
  const classes = useStyles();
  
  return (
    <Tooltip placement={"bottom"}
             title={`${isBlocked
               ? isRegistered
                 ? t("Unblock customer")
                 : t("Unblock number")
               : isRegistered
                 ? t("Block customer")
                 : t("Block number")}`}
             classes={{ tooltip: classes.tooltipBlock }}>
      <IconButton
        className={`${classes.iconButton} ${isBlocked ? 'blocked' : ''}`}
        onClick={onClick}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <BlockIcon
          viewBox={"0 0 16 16"}
          style={{
            width: 16,
            height: 16,
            marginLeft: 1,
            fontSize: '16px',
          }}
          color={isHovered ? "#FFFFFF" : "#919CA5"}
        />
      </IconButton>
    </Tooltip>
  );
}