import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const MenuIconSettings = (props: any) => {
    return (
        <SvgIcon {...props}>
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M15.2188 9.87517L13.875 9.12517C14.0313 8.37517 14.0313 7.65642 13.875 6.90642L15.2188 6.15642C15.375 6.06267 15.4375 5.87517 15.375 5.71892C15.0313 4.59392 14.4375 3.59392 13.6875 2.75017C13.5625 2.62517 13.375 2.59392 13.2188 2.68767L11.875 3.43767C11.3125 2.96892 10.6875 2.59392 10 2.34392V0.812672C10 0.656422 9.87502 0.500172 9.68752 0.468922C8.53127 0.187672 7.37502 0.218922 6.28127 0.468922C6.09377 0.500172 6.00002 0.656422 6.00002 0.812672V2.34392C5.28127 2.59392 4.65627 2.96892 4.09377 3.46892L2.75002 2.68767C2.59377 2.59392 2.40627 2.62517 2.28127 2.75017C1.53127 3.59392 0.937524 4.59392 0.593774 5.71892C0.531274 5.87517 0.593774 6.06267 0.750024 6.15642L2.09377 6.90642C1.96877 7.65642 1.96877 8.37517 2.09377 9.12517L0.750024 9.87517C0.593774 9.96892 0.531274 10.1564 0.593774 10.3127C0.937524 11.4377 1.53127 12.4377 2.28127 13.2814C2.40627 13.4064 2.59377 13.4377 2.75002 13.3439L4.09377 12.5939C4.65627 13.0627 5.28127 13.4377 6.00002 13.6877V15.2189C6.00002 15.3752 6.12502 15.5314 6.28127 15.5939C7.43752 15.8439 8.59377 15.8127 9.68752 15.5939C9.87502 15.5314 10 15.3752 10 15.2189V13.6877C10.6875 13.4377 11.3125 13.0627 11.875 12.5939L13.2188 13.3439C13.375 13.4377 13.5625 13.4064 13.6875 13.2814C14.4688 12.4377 15.0313 11.4377 15.4063 10.3127C15.4375 10.1564 15.375 9.96892 15.2188 9.87517ZM8.00002 10.5002C6.59377 10.5002 5.50002 9.40642 5.50002 8.00017C5.50002 6.62517 6.59377 5.50017 8.00002 5.50017C9.37502 5.50017 10.5 6.62517 10.5 8.00017C10.5 9.40642 9.37502 10.5002 8.00002 10.5002Z" 
            />
        </SvgIcon>
    )
};

export default MenuIconSettings;
