import { AppointmentPaymentValues } from "./appointment-payment-values";
import { AppointmentPaymentEntity } from "./appointment-payment-entity";
import { AppointmentPaymentChangeRecord } from "./appointment-payment-change-record";

export class AppointmentPaymentFormData {
  payment: AppointmentPaymentEntity;
  paymentValues: AppointmentPaymentValues[];
  changeRecords: AppointmentPaymentChangeRecord[];
  
  constructor(x?: any) {
    if (typeof x === "object") {
      this.payment = x.payment;
      this.paymentValues = x.paymentValues;
      this.changeRecords = x.changeRecords;
    }
    else {
      this.payment = new AppointmentPaymentEntity();
      this.paymentValues = [];
      this.changeRecords = [];
    }
  }
}