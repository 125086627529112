export class CustomerSearch {
  id: string | null;
  firstName: string | null;
  lastName: string | null;
  mobile: string | null;
  email: string | null;
  mobileCodeIso3: string | null;
  mobileDigits: string | null;
  mobileCode: string | null;
  mobileTemplate: string | null;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.id = x.id;
      this.firstName = x.firstName;
      this.lastName = x.lastName;
      this.mobile = x.mobile;
      this.email = x.email;
      this.mobileCodeIso3 = x.mobileCodeIso3;
      this.mobileDigits = x.mobileDigits;
      this.mobileCode = x.mobileCode;
      this.mobileTemplate = x.mobileTemplate;
    } else {
      this.id = null;
      this.firstName = null;
      this.lastName = null;
      this.mobile = null;
      this.email = null;
      this.mobileCodeIso3 = null;
      this.mobileDigits = null;
      this.mobileCode = null;
      this.mobileTemplate = null;
    }
  }
}
