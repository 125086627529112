import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CameraIcon = (props: any) => {

  const { style, viewBox, ...otherProps } = props;
  
  return (
  <SvgIcon {...otherProps} >
    <path
      d="M21.6666 5.33366C21.6666 4.25033 20.7499 3.33366 19.6666 3.33366H15.9999L15.4583 2.00033C15.1666 1.20866 14.4166 0.666992 13.5833 0.666992H8.37492C7.54158 0.666992 6.79158 1.20866 6.49992 2.00033L5.99992 3.33366H2.33325C1.20825 3.33366 0.333252 4.25033 0.333252 5.33366V17.3337C0.333252 18.4587 1.20825 19.3337 2.33325 19.3337H19.6666C20.7499 19.3337 21.6666 18.4587 21.6666 17.3337V5.33366ZM15.9999 11.3337C15.9999 14.1253 13.7499 16.3337 10.9999 16.3337C8.20825 16.3337 5.99992 14.1253 5.99992 11.3337C5.99992 8.58366 8.20825 6.33366 10.9999 6.33366C13.7499 6.33366 15.9999 8.58366 15.9999 11.3337ZM14.6666 11.3337C14.6666 9.33366 12.9999 7.66699 10.9999 7.66699C8.95825 7.66699 7.33325 9.33366 7.33325 11.3337C7.33325 13.3753 8.95825 15.0003 10.9999 15.0003C12.9999 15.0003 14.6666 13.3753 14.6666 11.3337Z"
    />
  </SvgIcon>
  );
}

export default CameraIcon;
