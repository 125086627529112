import React, { useState, useEffect } from "react";
import { Editor } from '@tinymce/tinymce-react';

import { Button } from '@material-ui/core';
import Typography from "@material-ui/core/Typography";

import { useTranslation } from "react-i18next";
import moment from 'moment';

import { useAppSelector } from "../../../redux/hooks";
import { CustomerApptsServicesHistory } from "../../../models/customer-history";
import { CrossIcon, SaveIcon } from "../../../assets/icons";
import { AppointmentService } from "../../../api/appointment-service";
import useStyles from "./css";

 import { CustomerHistoryDetailsProps } from "./props";
  
const CustomerHistoryDetails = (props: CustomerHistoryDetailsProps) => {

    const { appointmentId, serviceHistory, serviceStatus, servicePaymentStatus, onChangedWithoutSave, onClose, onRefresh } = props;
    const { t } = useTranslation(["general"]);
    const classes = useStyles();
    const [isDisabledBtnSave, setIsDisabledBtnSave] = useState(true);
    const [showBtnSave, setShowBtnSave] = useState(false);

    const [service, setService] = useState<CustomerApptsServicesHistory>(serviceHistory);
    const [editorTxt, setEditorTxt] = useState<string>(serviceHistory.notes);
    const [editorTxtLength, setEditorTxtLength] = useState<number>(0);
    const maxEditorText = 1954;
    const [warningSave, setWarningSave] = useState(false);
    const [editorTextDisabled, setEditorTextDisabled] = useState<boolean>(false);
    const localeApp = useAppSelector(state => state.applicationInterface.locale);

    useEffect(() => {
        setService(serviceHistory);
        // setEditorTxtLength(serviceHistory.notes?.replace(/<[^>]*>/g, '').length ?? 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceHistory]);
    
    
    const handleEditorChange = (content: any, editor: any) => {
        let editorLength = editor.getContent({ format: 'text' }).length;
        setEditorTxt(content);
        setWarningSave(false);
        setEditorTxtLength(editorLength);
        if(content !== "" && serviceHistory.notes !== content ) {
            setIsDisabledBtnSave(false);
            if (onChangedWithoutSave) onChangedWithoutSave(false);
        }
        else {
            setIsDisabledBtnSave(true);
            if (onChangedWithoutSave) onChangedWithoutSave(true);
        }

      };

    const handleSaveNotes = async () => {
        const result = await AppointmentService.updateNotes(serviceHistory.id, appointmentId, editorTxt);
        
        if (result && onRefresh) onRefresh(service);
        setIsDisabledBtnSave(true);
        setWarningSave(false);
        if (onChangedWithoutSave) onChangedWithoutSave(true);
    };

    const onFocus = () => {
        setShowBtnSave(true);
    };
    
    const onFocusOut = () => {

        if(serviceHistory.notes !== editorTxt && editorTxt !== "")
            setWarningSave(true);
        else
            setWarningSave(false);
    };

    
    useEffect(() => {

        setEditorTxt(serviceHistory.notes);
        
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [serviceHistory]);

    return (<div className={classes.customerHistoryDetailsPanel}>
                <div className={classes.closeDrawer} onClick={onClose} >
                    <div className={classes.closeDrawerIcon}>
                    <CrossIcon />
                    </div>
                    <div className={classes.closeDrawerText}>
                        {t("Close window")}
                    </div>
                </div>

                <div className={classes.divGroup} >
                    <div className={classes.title} >
                        {t("Service & specialist")}
                    </div>
                    <div className={classes.service} >
                        {serviceHistory.name}
                    </div>
                    <div className={classes.specialist} >
                        {serviceHistory.specialist}
                    </div>
                </div>

                <div className={classes.divGroup} >
                    <div className={classes.title} >
                        {t("Date & Time")}
                    </div>
                    <div className={classes.dateTimeInfo} >
                        {
                        new Date(serviceHistory.start).toLocaleDateString(
                            localeApp,
                            {
                                month: "long",
                                day: "numeric",
                            })
                        + " " + new Date(serviceHistory.start).getFullYear()
                        }
                        ·
                        {moment(serviceHistory.start).format("HH:mm") + " - " + moment(serviceHistory.end).format("HH:mm")}
                    </div>
                
                </div>

                <div className={classes.divGroup} >
                    <div className={classes.title} >
                        {t("Status")}
                    </div>
                    <div className={classes.statusLabel} >
                        {serviceStatus}
                        {servicePaymentStatus}
                    </div>
                </div>

                <div className={classes.divGroup} >
                    <div className={classes.title} >
                        {t("Notes after service")}
                    </div>
                    <div className={`
                    ${classes.divTiny} ${showBtnSave ? classes.divTinyFocus : ""} 
                    ${warningSave || editorTxtLength > maxEditorText ? classes.divTinyWarning : ""}` } >
                        <Editor
                            apiKey="k8euxnsm83b9ddwer6ard3pp5tb1h1sh4dq0ciqs1q60mo1k"
                            initialValue={serviceHistory.notes}
                            value={editorTxt ?? ""}
                            onFocusIn={onFocus}
                            onFocusOut={onFocusOut}
                            onInit={(e: any, editor: any) => {
                                const initialContentLength = editor.getContent({ format: 'text' }).length;
                                setEditorTxtLength(initialContentLength);
                            }}
                            init={{
                                placeholder: `${t("No notes")}.`,
                                height: 190,
                                menubar: false,
                                plugins: 'wordcount paste  lists',
                                toolbar: 'bold italic underline | bullist numlist',
                                lists_indent_on_tab: false,
                                formats: {
                                    bold: { inline: 'b' },
                                    italic: { inline: 'i' },
                                    underline: { inline: 'u' }
                                },
                                extended_valid_elements: "b, i, s",
                                entity_encoding: "raw",
                                invalid_elements: "strong, em, span",
                                content_style: "body {font-style: normal; font-weight: 400; font-size: 14px; color: #323947 }"
                            }}
                            onEditorChange={handleEditorChange}
                            outputFormat="html"
                        />
                      <div className={classes.counter}>{`${editorTxtLength}/${maxEditorText}`}</div>
                    </div>
                        
                    <div className={`${classes.divGroup} ${showBtnSave ? classes.divGroup : ""}`} >
                        
                        {showBtnSave && <>
                        <Button
                            aria-label={t("Save")}
                            className={`${classes.floatButton} ${classes.saveButton}`}
                            onClick={handleSaveNotes}
                            disabled={isDisabledBtnSave || editorTxtLength > maxEditorText}
                            >
                            <SaveIcon
                                style={{ width: 16, height: 16, marginRight: "12px" }}
                                viewBox="0 0 16 16"
                            />
                            <Typography className={classes.floatButtonTxt}>
                                {t("Save")}
                            </Typography>
                        </Button>
                        </>}
                    </div>

                </div>
        </div>);
};

export default CustomerHistoryDetails;
