import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionFieldDropdownItemIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M7.85835 9C7.09054 9 6.70664 9.93134 7.26504 10.4687L11.4182 14.7313C11.7323 15.0896 12.2558 15.0896 12.6048 14.7313L16.7579 10.4687C17.2814 9.93134 16.8975 9 16.1646 9H7.85835Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}