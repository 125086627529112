import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionEditIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M13.0625 6.9375L4.37503 15.625L4.00003 19.1875C3.93753 19.6562 4.34378 20.0625 4.81253 20L8.37503 19.625L17.0625 10.9375L13.0625 6.9375ZM19.5313 6.34375L17.6563 4.46875C17.0938 3.875 16.125 3.875 15.5313 4.46875L13.7813 6.21875L17.7813 10.2188L19.5313 8.46875C20.125 7.875 20.125 6.90625 19.5313 6.34375Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}