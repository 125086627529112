import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function RoundCheckmarkIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 12, height: 12, ...style }}
            viewBox={viewBox || "0 0 12 12"}
        >
            <circle cx="6" cy="6" r="6" fill={style?.color || Color.green}/>
            <path
                d="M8.91382 3.67305C8.79558 3.68923 8.68628 3.74696 8.60414 3.83661L5.11122 7.45831L3.73672 6.32078C3.68323 6.27638 3.62182 6.24334 3.55601 6.22355C3.4902 6.20377 3.42128 6.19763 3.35318 6.20546C3.28507 6.21329 3.21912 6.23496 3.1591 6.26922C3.09907 6.30347 3.04613 6.34966 3.00332 6.40512C2.9605 6.46059 2.92864 6.52425 2.90956 6.59248C2.89048 6.66072 2.88454 6.7322 2.8921 6.80281C2.89965 6.87343 2.92054 6.9418 2.95358 7.00404C2.98662 7.06628 3.03116 7.12116 3.08466 7.16556L4.82305 8.60607C4.92411 8.68862 5.05102 8.72952 5.17941 8.72094C5.30779 8.71236 5.42859 8.6549 5.51859 8.55959L9.3427 4.59449C9.42091 4.51536 9.47317 4.41276 9.49212 4.30117C9.51107 4.18959 9.49575 4.07465 9.44831 3.97257C9.40087 3.8705 9.32372 3.78646 9.22773 3.73229C9.13175 3.67813 9.02179 3.6566 8.91337 3.67072L8.91382 3.67305Z"
                fill={Color.gray1}
            />
        </SvgIcon>
    );
}