import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Typography from "@material-ui/core/Typography";
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core//Link';

import { Url } from "./../../../constants/url";
import { Appointment } from "../../../models/appointment";
import { CreateAppointmentModel } from "../../../models/create-appointment-model";
import { AppointmentService } from "./../../../api/appointment-service";
import { TrashIcon } from "../../../assets/icons";
import AppointmentStepOne from "./../AppointmentStepOne";
import AppointmentStepTwo from "./../AppointmentStepTwo";
import AppointmentDeleteModal from "./../AppointmentDeleteModal";
import AppointmentCustomerSection from "./../AppointmentCustomerSection";
import { useAppDispatch, useAppSelector } from "./../../../redux/hooks";
import { alertsActions } from "./../../../redux/alerts-slice";
import { ApiClient } from "../../../api/api-client";
import AppointmentScheduleBusyModal from "../AppointmentScheduleBusyModal";
import RecurringAppointmentUpdateModal from "../RecurringAppointmentUpdateModal";
import RecurringAppointmentDeleteModal from "../RecurringAppointmentDeleteModal";
import useStyles from "./css";
import BusyScheduleQuestionModal from "../../common/BusyScheduleQuestion/busy-schedule-question";
import { CustomerFrequencyType } from "../../../models/enums/customer-frequency-type";
import { AppointmentScheduleBusyReason } from "../../../models/enums/appointment-schedule-busy-reason";
import { AppointmentBusyModalDataModel } from "../../../models/appointment-busy-modal-data-model";
import { AppointmentBlocker } from "../../../models/appointment-blocker";
import AppointmentWorkAreaBusyModal from "../../common/AppointmentWorkAreaBusyModal";
import { navbarActions } from "../../../redux/navbar-slice";
import { applicationInterfaceActions } from "../../../redux/application-interface-slice";
import { selectAccountSettings } from "../../../redux/store";
import OutsideBusinessHoursModal from "../../common/OutsideBusinessHoursModal";
import AppointmentOutsideScheduleModal from "../AppointmentOutsideScheduleModal";
import useCurrencyValueTemplate from "../../../hooks/useCurrencyValueTemplate";
import TimeIsBusyAnotherAppointmentCanNotContinueModal
  from "../../common/TimeIsBusyAnotherAppointmentCanNotContinueModal";

export default function AppointmentDetails() {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;
  const { appointmentId } = useParams<any>();
  const { t } = useTranslation(["general"]);
  const [appointment, setAppointment] = useState<CreateAppointmentModel>(
    new CreateAppointmentModel()
  );
  const [deleteAppointment, setDeleteAppointment] = useState<Appointment>(
    new Appointment()
  );
  const [updateRecurringAppointment, setUpdateRecurringAppointment] = useState<Appointment>(
    new Appointment()
  );
  const [step1Valid, setStep1Valid] = useState<boolean>(false);
  const [step2Valid, setStep2Valid] = useState<boolean>(false);
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [firstService, setFirstService] = useState<string>();
  const [showRecurringAppointmentUpdateModal, setShowRecurringAppointmentUpdateModal] = useState<boolean>(false);
  const [showRecurringAppointmentDeleteModal, setShowRecurringAppointmentDeleteModal] = useState<boolean>(false);
  const [openExclamationScheduleBusyModal, setOpenExclamationScheduleBusyModal] = useState<boolean>(false);
  const [openOutsideScheduleModal, setOpenOutsideScheduleModal] = useState<boolean>(false);
  const [openQuestionScheduleBusyModal, setOpenQuestionScheduleBusyModal] = useState<boolean>(false);
  const [openAppointmentWorkAreaBusyModal, setOpenAppointmentWorkAreaBusyModal] = useState<boolean>(false);
  const [openOutsideBusinessHoursModal, setOpenOutsideBusinessHoursModal] = useState<boolean>(false);
  const [openTimeIsBusyAnotherAppointmentCanNotContinueModal, setOpenTimeIsBusyAnotherAppointmentCanNotContinueModal] = useState<boolean>(false);
  const [appointmentBusyModalData, setAppointmentBusyModalData] = useState<AppointmentBusyModalDataModel>();
  const [refreshAvailableHours, setRefreshAvailableHours] = useState<number>(0);
  const accountSettings = useAppSelector(selectAccountSettings);
  const getTemplatedCurrencyValue = useCurrencyValueTemplate(accountSettings.currencyTemplate);
  const localeApp = useAppSelector(state => state.applicationInterface.locale);
  const userInfo = useAppSelector(state => state.userInfo);

  useEffect(() => {
    dispatch(applicationInterfaceActions.updateTitle(t("Edit appointment")));

    async function fetchData() {
      await loadAppointmentDetail();
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (accountSettings.countries.length) {
      let newAppointment = appointment;
      const countries = accountSettings.countries;
      let mobileCode = newAppointment.mobileCodeIso3 ?? "MEX";
      newAppointment.mobileDigits = countries.find(x => x.iso3 === mobileCode)!.dialDigits;
      newAppointment.mobileCode = countries.find(x => x.iso3 === mobileCode)!.dialCode;
      newAppointment.mobileTemplate = countries.find(x => x.iso3 === mobileCode)!.dialTemplate.toString();
      setAppointment(newAppointment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accountSettings.countries]);

  const loadAppointmentDetail = async () => {
    dispatch(navbarActions.setShowLoader(true));
    const appointment = await AppointmentService.get(appointmentId);
    dispatch(navbarActions.setShowLoader(false));
    setAppointment(appointment);
    if (appointment.startDate && appointment.services) {
      const duration = appointment.services
        .map((service) => service.duration)
        .reduce((prev, next) => (prev || 0) + (next || 0));
      const endDate = new Date(
        new Date(appointment.startDate).getTime() + (duration || 0) * 60000
      );
      setFirstService(appointment.services[0].name || undefined);
      setDeleteAppointment(
        new Appointment({
          ...appointment,
          customerName: `${appointment.firstName} ${appointment.lastName}`,
          customerEmail: appointment.email,
          customerMobile: appointment.mobile,
          endDate: endDate,
        })
      );
    }
  };

  const formatDate = (date: Date | null): string => {
    const day = date?.toLocaleString(localeApp, {
      day: "2-digit",
    });
    const month = date?.toLocaleString(localeApp, {
      month: "long",
    });
    const year = date?.toLocaleString(localeApp, {
      year: "numeric",
    });

    return `${day}/${month}/${year}`;
  };
  
  const totalServicesPrice = (appointment?.totalPriceForRecurringAppointments ?? 0) 
    + appointment.services.reduce((total, service) => total + service.price, 0);
  
  const handleSaveButton = (event: any) => {
    event.preventDefault();

    if (appointment.isRecurring) {

      if (appointment.startDate && appointment.services) {
        const duration = appointment.services
          .map((service) => service.duration)
          .reduce((prev, next) => (prev || 0) + (next || 0));
        const endDate = new Date(
          new Date(appointment.startDate).getTime() + (duration || 0) * 60000
        );
        setFirstService(appointment.services[0].name || undefined);
        setUpdateRecurringAppointment(
          new Appointment({
            ...appointment,
            customerName: `${appointment.firstName} ${appointment.lastName}`,
            customerEmail: appointment.email,
            customerMobile: appointment.mobile,
            endDate: endDate,
          }));
      }

      setShowRecurringAppointmentUpdateModal(true);
    } else {
      updateAppointment();
    }
  };

  const handleSaveRecurringAppointmentButton = (allRecurringAppointments: boolean) => {
    const newAppointment = {
      ...appointment,
      updateAllRecurringAppointments: allRecurringAppointments
    };
    setShowRecurringAppointmentUpdateModal(false);
    updateAppointment(newAppointment);
  };

  function setAppointmentBusyData(appointmentBlocker: AppointmentBlocker, appointmentScheduleBusyReason: AppointmentScheduleBusyReason) {
    setAppointmentBusyModalData(
      new AppointmentBusyModalDataModel({
        customerName: appointmentBlocker?.customerName,
        serviceName: appointmentBlocker?.serviceName,
        workArea: appointmentBlocker?.workArea,
        startData: appointmentBlocker?.startData,
        appointmentDuration: appointmentBlocker?.appointmentDuration,
        appointmentScheduleBusyReason: appointmentScheduleBusyReason,
      }));
    openScheduleBusyModal(appointmentScheduleBusyReason);
  }

  function openScheduleBusyModal(data: AppointmentScheduleBusyReason) {
    switch (data) {
      case AppointmentScheduleBusyReason.TimeIsBusyAnotherAppointment:
        setOpenQuestionScheduleBusyModal(true);
        break;
      case AppointmentScheduleBusyReason.BlockedScheduleTime:
        setOpenExclamationScheduleBusyModal(true);
        break;
      case AppointmentScheduleBusyReason.OutsideUserScheduleTime:
        setOpenOutsideScheduleModal(true);
        break;
      case AppointmentScheduleBusyReason.OutsideUserScheduleTimeAndOutsideBusinessHours:
        setOpenOutsideScheduleModal(true);
        break;
      case AppointmentScheduleBusyReason.WorkAreaIsBusyAnotherAppointment:
        setOpenAppointmentWorkAreaBusyModal(true);
        break;
      case AppointmentScheduleBusyReason.OutsideBusinessHours:
        setOpenOutsideBusinessHoursModal(true);
        break;
      case AppointmentScheduleBusyReason.TimeIsBusyAnotherAppointmentCanNotContinue:
        setOpenTimeIsBusyAnotherAppointmentCanNotContinueModal(true);
        break;
      default:
        setOpenExclamationScheduleBusyModal(true);
        break;
    }
  }

  const updateAppointment = (newAppointment?: CreateAppointmentModel) => {
    const appointmentUpdate = newAppointment ?? appointment;
    dispatch(navbarActions.setShowLoader(true));
    AppointmentService.update(appointmentUpdate).then((data) => {
      dispatch(navbarActions.setShowLoader(false));
      if (data.isScheduleBusy) {
        setAppointmentBusyData(data.appointmentBlocker, data.appointmentScheduleBusyReason);
        setRefreshAvailableHours(refreshAvailableHours + 1);
        setStep2Valid(false);
        return;
      } else {
        dispatch(applicationInterfaceActions.updateTitle(t("Appointments")));
        dispatch(
          enqueueAlert({
            type: "Success",
            title: t("Appointment updated"),
            description: `${appointment.firstName} ${appointment.lastName
            } - ${formatDate(appointment.startDate)}`,
          })
        );
        history.push(`${Url.Appointments.Main}`);
      }
    });
  };

  const handleDelete = (allRecurringAppointments: boolean) => {
    if (appointment.id) {
      AppointmentService.remove(appointment.id as string, allRecurringAppointments).then(() => {
        dispatch(applicationInterfaceActions.updateTitle(t("Appointments")));
        dispatch(
          enqueueAlert({
            type: "Success",
            title: t("Appointment deleted"),
            description: t("Appointment has been successfully deleted."),
          })
        );
        history.push(`${Url.Appointments.Main}`);
      });
    }
  };

  const handleDeleteModal = () => {
    if (appointment.isRecurring) {
      setShowRecurringAppointmentDeleteModal(true);
    } else {
      setDeleteModal(true);
    }
  };

  const handleContinueScheduleBusyModal = () => {
    const newAppointment = {
      ...appointment,
      isIgnoreScheduleBusy: true
    };
    updateAppointment(newAppointment);
  };

  return (<>

    <div style={{ paddingBottom: 75 }}>
      <div className={classes.breadcrumbContainer}>
        <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
          <Link
            underline="hover"
            color="inherit"
            href="/appointments"
          >
            {t("Appointments")}
          </Link>
          <div className={classes.boldFont}>
            <Typography> {t("Edit appointment")}</Typography>
          </div>
        </Breadcrumbs>
      </div>
      <div className={classes.root}>
        <AppointmentCustomerSection
          appointment={appointment}
          onChange={(appointment) => {
            setAppointment(appointment);
          }}
          isEdition
        />
        <div className={classes.appointmentSection}>
          <AppointmentStepOne
            appointment={appointment}
            onChange={(appointment: any, isValid: any) => {
              setAppointment(appointment);
              setStep1Valid(isValid);
            }}
          />
          <AppointmentStepTwo
            appointment={appointment}
            step1Valid={step1Valid}
            isEdition
            refreshData={refreshAvailableHours}
            onChange={(appointment: any, isValid: any) => {
              if (isValid) {
                setAppointment(appointment);
              }

              setStep2Valid(isValid);
            }}
          />
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={cancelModal}
          onClose={() => setCancelModal(false)}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}
        >
          <Fade in={cancelModal}>
            <div className={classes.modalCancelPaper}>
              <div>
                <div
                  className={`${classes.modalCenter} ${classes.modalTitleSpace}`}
                >
                  <Typography className={classes.modalTitleText}>
                    {t("Cancel appointment edition?")}
                  </Typography>
                </div>
                <div className={classes.modalActions}>
                  <Button
                    className={`${classes.button} ${classes.goBackCancel}`}
                    onClick={() => setCancelModal(false)}
                  >
                    {t("Go back")}
                  </Button>
                  <Button
                    className={`${classes.button} ${classes.cancelConfirmation}`}
                    onClick={() => {
                      setCancelModal(false);
                      history.push(`${Url.Appointments.Main}`);
                    }}
                  >
                    {t("Yes, cancel")}
                  </Button>
                </div>
              </div>
            </div>
          </Fade>
        </Modal>

        <AppointmentDeleteModal
          open={deleteModal}
          appointment={deleteAppointment}
          service={firstService}
          onClose={() => {
            setDeleteModal(false);
          }}
          onDelete={() => handleDelete(false)}
        />

        <RecurringAppointmentUpdateModal
          open={showRecurringAppointmentUpdateModal}
          appointment={updateRecurringAppointment}
          service={firstService}
          onClose={() => {
            setShowRecurringAppointmentUpdateModal(false);
          }}
          onUpdate={handleSaveRecurringAppointmentButton}
        />

        <RecurringAppointmentDeleteModal
          open={showRecurringAppointmentDeleteModal}
          appointment={deleteAppointment}
          service={firstService}
          onClose={() => {
            setShowRecurringAppointmentDeleteModal(false);
          }}
          onDelete={handleDelete}
        />
        <BusyScheduleQuestionModal
          open={openQuestionScheduleBusyModal}
          appointment={appointment}
          appointmentBusyModalData={appointmentBusyModalData}
          onClose={() => setOpenQuestionScheduleBusyModal(false)}
          onContinue={handleContinueScheduleBusyModal}
        />
        <AppointmentWorkAreaBusyModal
          open={openAppointmentWorkAreaBusyModal}
          appointment={appointment}
          appointmentBusyModalData={appointmentBusyModalData}
          onClose={() => setOpenAppointmentWorkAreaBusyModal(false)}
        />
        <AppointmentScheduleBusyModal
          open={openExclamationScheduleBusyModal}
          appointment={appointment}
          appointmentBusyModalData={appointmentBusyModalData}
          onClose={() => setOpenExclamationScheduleBusyModal(false)}
        />
        <AppointmentOutsideScheduleModal
          open={openOutsideScheduleModal}
          appointment={appointment}
          appointmentBusyModalData={appointmentBusyModalData}
          onClose={() => setOpenOutsideScheduleModal(false)}
          onContinue={handleContinueScheduleBusyModal}
        />
        <OutsideBusinessHoursModal
          open={openOutsideBusinessHoursModal}
          onClose={() => setOpenOutsideBusinessHoursModal(false)}
          onCancel={() => setOpenOutsideBusinessHoursModal(false)}
          onConfirm={handleContinueScheduleBusyModal}
        />
        <TimeIsBusyAnotherAppointmentCanNotContinueModal
          open={openTimeIsBusyAnotherAppointmentCanNotContinueModal}
          onClose={() => setOpenTimeIsBusyAnotherAppointmentCanNotContinueModal(false)}
        />
      </div>
      
      <div className={classes.actions}>
        <div>
          {(appointment.isRecurring && userInfo.showPrices) && <div>
            <div className={classes.totalPriceBoldText}>
              {t("Total for the {{count}} appointments: {{total}}", {
                count: appointment.recurredAppointmentsCount ?? 0,
                total: getTemplatedCurrencyValue((totalServicesPrice) ?? 0),
              })}
            </div>
            <div className={classes.totalPriceText}>
              {t("Total per appointment: {{price}}", {
                price: getTemplatedCurrencyValue(totalServicesPrice / (appointment.recurredAppointmentsCount ?? 1)),
              })}
            </div>
          </div>}
        </div>
        <div>
          <IconButton
          className={classes.deleteButton}
          onClick={handleDeleteModal}
        >
          <TrashIcon style={{ color: "#ACB7C0" }}/>
        </IconButton>
          <Button
            className={`${classes.button} ${classes.goBack}`}
            onClick={() => {
              setCancelModal(true);
            }}
          >
            {t("Go back")}
          </Button>
          <Button
            className={`${classes.button} ${classes.newAppointment}`}
            disabled={!appointment.customerId || !step1Valid || !step2Valid}
            onClick={handleSaveButton}
          >
            {t("Save changes")}
          </Button>
        </div>
      </div>
    </div>
  </>);
}
