import React, { useEffect, useState } from "react";

import { useTranslation } from 'react-i18next';
import { Grid, Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";

import { useAppDispatch } from "../../../../redux/hooks";
import { alertsActions } from "../../../../redux/alerts-slice";

import { MegaphoneIcon } from "../../../../assets/icons";
import MassiveSendingList from "../MassiveSendingsList";
import { useStyles } from "./css";
import MassiveSendingModal from "../MassiveSendingModal";
import { MassiveSendingEntity } from "../../../../models/massive-sending-entity";
import { EditableItemProps } from "../../../../models/interfaces/editable-item-props";
import { MassiveSendingService } from "../../../../api/massive-sending-service";
import { ApiClient } from "../../../../api/api-client";


const MassiveSengingsMain = (props: any) => {

    const { t } = useTranslation(["massivesendings"]);
    const classes = useStyles();
    const dispatch = useAppDispatch();
    const hasMassiveSendings = ApiClient.getUserInfo().hasMassiveSendings;

    const { enqueueAlert } = alertsActions;
        
    const [massiveSending, setMassiveSending] = useState<MassiveSendingEntity>(new MassiveSendingEntity());
    const [oldMassiveSending, setOldMassiveSending] = useState<MassiveSendingEntity>(new MassiveSendingEntity());
    const [modalMode, setModalMode] = useState<EditableItemProps>({ mode: "new", itemId: null });
    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [isRefresh, setRefresh] = useState<any>();
    const [total, setTotal] = useState<number>(0);
    
    async function handleListRowClick(itemId: string) {
        await handleOpenModal(itemId);
    }
    
    const handleConfirmDelete = async (item: any) => {
        const result = await MassiveSendingService.deleteSending(item.id);
        if (result && result.isDeleted) {
            setRefresh(result)
        }
    }

    const handleTotal = (total: number) => {
        setTotal(total);
    }

    async function handleOpenModal(itemId?: string | null) {
        if (itemId) {
            const ms = await MassiveSendingService.getSendingById(itemId)
            setMassiveSending(ms);
            setOldMassiveSending(ms);
            setModalMode({ mode: "edit", itemId });
        } else {
            setModalMode({ mode: "new", itemId: null });
        }
        
        setModalOpen(true);
    }
    
    function displaySuccessfulCreationAlert(massiveSendingName: string) {
        dispatch(
          enqueueAlert({
              type: "Success",
              title: t("Massive sending successfully created"),
              description: `${massiveSendingName}`
          })
        )
    }
    
    function displaySuccessfulEditionAlert(massiveSendingName: string) {
        dispatch(
          enqueueAlert({
              type: "Success",
              title: t("Massive sending modified"),
              description: `${massiveSendingName}`
          })
        )
    }
    
    async function createNewSending(massiveSending: MassiveSendingEntity) {
        return massiveSending.image
          ? await MassiveSendingService.createSendingWithImage(massiveSending)
            .then(() => displaySuccessfulCreationAlert(massiveSending.name))
            .catch((e) => {
                console.log(e)
            })
          : await MassiveSendingService.createSending(massiveSending)
            .then(() => displaySuccessfulCreationAlert(massiveSending.name))
            .catch((e) => {
                console.log(e)
            });
    }
    
    async function updateSending(massiveSending: MassiveSendingEntity) {
        return massiveSending.image 
          ? await MassiveSendingService.updateSendingWithImage(massiveSending)
            .then(() => displaySuccessfulEditionAlert(massiveSending.name))
            .catch((e) => { console.log(e)})
          : await MassiveSendingService.updateSending(massiveSending)
            .then(() => displaySuccessfulEditionAlert(massiveSending.name))
            .catch((e) => { console.log(e)})
    }
    
    async function handleCompleteModal(massiveSending: MassiveSendingEntity) {
        const response = modalMode.mode === "new"
          ? await createNewSending(massiveSending)
          : await updateSending(massiveSending)

        setRefresh({ massiveSending });
    }
    
    async function handleCloseModal() {
        setModalMode({ mode: "new", itemId: null });
        setModalOpen(false);
    }

    useEffect(() => {
        if (props.match.params?.sendingId) {
        handleOpenModal(props.match.params?.sendingId)
        }
    }, [])


    return (<>
        <div>
            <div className={classes.header}>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <div>
                            <Typography className={classes.titles} variant="subtitle1">
                                {t('Massive sendings lists')}
                            </Typography>
                            <Typography className={classes.icons} variant="h4">
                                <MegaphoneIcon className={classes.icon} />
                                {total}
                            </Typography>
                        </div>
                    </Grid>
                    <Grid item xs={6}>
                    {
                        hasMassiveSendings && 
                        <div className={classes.headerRight}>
                            <Button className={`${classes.button} ${classes.btnNewWorkArea}`} 
                                onClick={() => handleOpenModal()}
                                >
                                {t('New massive sending')}
                            </Button>
                        </div>
                    }
                    </Grid>
                </Grid>
            </div>
            
            <MassiveSendingList 
                isRefresh={isRefresh} 
                onRowClick={handleListRowClick} 
                onDelete={handleConfirmDelete}
                setTotalRecords={handleTotal} 
            />

            <MassiveSendingModal 
                massiveSending={massiveSending} 
                setMassiveSending={setMassiveSending} 
                oldMassiveSending={oldMassiveSending}
                mode={modalMode.mode}
                itemId={modalMode.itemId}
                open={modalOpen}
                setOpen={setModalOpen}
                onCreate={handleCompleteModal}
                onClose={handleCloseModal}
            />
            
        </div>
    </>);
}

export default MassiveSengingsMain;