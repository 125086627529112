// import './wdyr';

import "bootstrap/dist/css/bootstrap.css";
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import {unregister} from "./registerServiceWorker";

import { ThemeProvider } from "@material-ui/core/styles";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as customTypes from "./material-ui";

import store from "./redux/store";
import { Provider } from "react-redux";

import App from "./App";
import { AuthenticationProvider } from "./components/authentication/AuthenticationProvider";
import AlertProvider from "./components/common/notifications/AlertProvider";
import { ThemeStyles } from "./constants/styles";

import "./i18n";

const baseUrl = document.getElementsByTagName("base")[0].getAttribute("href");
const rootElement = document.getElementById("root");

ReactDOM.render(
  <BrowserRouter basename={baseUrl || "/"}>
    <Provider store={store}>
      <AuthenticationProvider />
      <ThemeProvider theme={ThemeStyles}>
        <Suspense fallback="">
          <AlertProvider>
            <App />
          </AlertProvider>
        </Suspense>
      </ThemeProvider>
    </Provider>
  </BrowserRouter>,
  rootElement
);

unregister();
