export class CreateAppointmentService {
  appointmentId: string | null;
  serviceId: string | null;
  name: string | null;
  duration: number | null;
  estimatedDuration: number;
  userId: string | null;
  user: string | null;
  workAreaId: string | null;
  workArea: string | null;
  price: number;

  constructor(x?: any) {
    if (typeof x == "object") {
      this.appointmentId = x.appointmentId;
      this.serviceId = x.serviceId;
      this.name = x.name;
      this.duration = x.duration;
      this.userId = x.userId;
      this.user = x.user;
      this.estimatedDuration = x.estimatedDuration;
      this.workAreaId = x.workAreaId;
      this.workArea = x.workArea;
      this.price = x.price;
      
    } else {
      this.appointmentId = null;
      this.serviceId = null;
      this.name = null;
      this.duration = null;
      this.userId = null;
      this.user = null;
      this.estimatedDuration = 0;
      this.workAreaId = null;
      this.workArea = null;
      this.price = 0;
    }
  }
}
