import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",

    height: "100%",
    width: 500
  },
  drawer: {
    background: theme.colors.white,
    borderTopLeftRadius: 5,
    borderBottomLeftRadius: 5,

    display: "flex",
    flexDirection: "column",
    overflowX: "hidden",
  },
  tabs: {
    ...theme.typography.txtBody1,
    backgroundColor: theme.colors.white,
    color: theme.colors.clearMain,

    borderBottom: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "rgba(201, 205, 211, 0.5)",

    "& .MuiTabs-indicator": {
      backgroundColor: theme.palette.common.white
    },
    "& .MuiButtonBase-root": {
      minWidth: 0,
      width: "50%",
    },
    "& .MuiTab-root": {
      paddingLeft: 0,
      paddingRight: 0,
      textTransform: "none",
      color: theme.colors.grey1,
      backgroundColor: theme.colors.clearMain,
      fontWeight: "bold",
    },
    "& .Mui-selected": {
      color: theme.colors.clearMain,
      backgroundColor: theme.palette.common.white,
      fontWeight: "bold",
    },
  },
  tab: {
    root: {
      ...theme.typography.txtBody2,
    },
    selected: {
      fontWeight: "bold",
    }
  },
  tabPanelRoot: {
    padding: "0 !important",
    height: "100%"
  },
  divider: {
    width: 452,
    height: 0,
    
    marginLeft: 24,
    marginRight: 24,
    marginTop: 0,
    marginBottom: 0,
    
    color: theme.colors.grey2,
  },
  headerDate: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",

    width: "100%",
    padding: "16px 24px",
  },
  headerDateText: {
    display: "flex",
    alignItems: "center",
    
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,

    height: 40
  },
  headerDateDate: {
    marginLeft: 8,
  },
  goBackHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",

    width: "100%",
    padding: "16px 24px",
  },
  goBackButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    
    width: 89,
    height: 16,
    
    color: theme.colors.clearMain,
    
    cursor: "pointer",
  },
  backButtonText: {
    ...theme.typography.buttons,
    fontWeight: 700,
  },
  backButtonIcon: {
    fontSize: 12,
    lineHeight: 15,
    marginRight: 5,
  },
  closeIcon: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    width: 16,
    height: 16,

    cursor: "pointer",
    color: theme.colors.grey5,
  },
  content: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    position: "relative",
    height: "100%"
  },
  cashDetailsBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    width: 452,
    height: 367,
    
    marginTop: 16,
    marginLeft: 15,
    marginRight: 33,
    marginBottom: 13,
    
    padding: 8,
  },
  cashDetailsHeader: {
    justifySelf: "flex-start",
    alignSelf: "flex-start",
    
    marginBottom: 18,

    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    fontWeight: 700,
  },
  cashDetailsRow: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    
    height: 18,
    width: "100%",
    
    marginBottom: 18
  },
  cashDetailsIcon: {
    marginRight: 14
  },
  cashDetailsRowName: {
    display: "inline-flex",
    alignItems: "center",
    
    ...theme.typography.txtBody2,
    color: theme.colors.grey5
  },
  cashDetailsRowValue: {
    ...theme.typography.txtBody1,
    fontWeight: 700,
    fontSize: 12,
    
    color: theme.colors.grey5
  },
  historyBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  historyMovementBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    marginTop: 20,
    marginLeft: 24,
    marginRight: 24,
  },
  historyMovementRow: {
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    
    marginBottom: 8,
  },
  historyMovementTitle: {
    width: 82,
    
    ...theme.typography.txtBody2,
    color: theme.colors.grey4
  },
  historyMovementValue: {
    //display: "flex",
    width: 340,
    wordBreak: "break-all",
    
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
  },
  historyChip: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    padding: "4px 8px",
    
    borderRadius: 5,
    
    ...theme.typography.txtBody2,
    fontWeight: 700,
  },
  historyDepositChip: {
    color: theme.colors.clearGreen,
    backgroundColor: "rgba(54, 206, 145, 0.2)"
  },
  historyWithdrawalChip: {
    color: theme.colors.clearRed,
    backgroundColor: "rgba(241, 88, 87, 0.2)"
  },
  historyCashOpeningChip: {
    color: theme.colors.clearMain,
    backgroundColor: "rgba(100, 98, 243, 0.2)"
  },
  historyDivider: {
    width: 452,
    height: 0,
    
    marginTop: 8,
    
    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.grey1,
  },
  currencyValue: {
    ...theme.typography.txtBody1,
    fontWeight: 700,
    color: theme.colors.grey5
  },
  cashClosingBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    marginTop: 17,
    marginLeft: 24,
  },
  cashClosingInputAndNumberBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    
    marginBottom: 24,
    marginTop: 18,
  },
  cashClosingInputRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
  cashClosingInputsFirstRow: {
    marginTop: 18,
  },
  cashClosingInputColumn: {
    width: 300,
  },
  cashClosingNumberColumn: {
    marginLeft: 20,
    alignSelf: "center"
  },
  inputLabel: {
    ...theme.typography.buttons,
    color: theme.colors.grey4,
    fontWeight: 700,
    marginBottom: 8
  },
  numberLabel: {
    ...theme.typography.buttons,
    color: theme.colors.grey5,
  },
  numberValue: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    fontWeight: 700,
  },
  cashClosingDivider: {
    width: 225,
    height: 0,

    marginTop: 24,
    marginBottom: 24,

    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.grey2,
  },
  commentsLabel: {
    marginTop: 24
  },
  singleButtonFooter: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    
    height: 80,
    width: 500,

    position: "sticky",
    bottom: 0,
    padding: 24,
    backgroundColor: "#FFFFFF"
  },
  newMovementBlock: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",

    marginTop: 16,
    marginLeft: 24,
  },
  newMovementHeader: {
    ...theme.typography.txtBody1,
    color: theme.colors.grey5,
    fontWeight: 700,
    marginBottom: 16,
  },
  typeOfMovementRadios: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    
    width: 200,
    
    marginBottom: 16,
  },
  radioGreen: {
    color: theme.colors.grey5,
    "&.Mui-checked": {
      color: theme.colors.clearGreen,
      "&, & + .MuiFormControlLabel-label": {
        color: theme.colors.clearGreen,
      },
    },
    "&:hover .MuiIconButton-label span":{
      boxShadow: `inset 0 0 0 1px #36CE91, inset 0 -1px 0 #36CE91`
    }
  },
  radioRed: {
    color: theme.colors.grey5,
    "&.Mui-checked": {
      color: theme.colors.clearGreen,
      "&, & + .MuiFormControlLabel-label": {
        color: theme.colors.clearRed,
      },
    },
    "&:hover .MuiIconButton-label span":{
      boxShadow: `inset 0 0 0 1px #F15857, inset 0 -1px 0 #F15857`
    }
  },
  radioLabelRoot: {
    margin: 0,
  },
  radioLabel: {
    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
  },
  inputSpacing: {
    height: 0,
    marginBottom: 16,
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    
    width: "100%",
    height: 80,

    position: "sticky",
    bottom: 0,
    marginTop: "auto",
    
    padding: 24,
  },
  button: {
    minWidth: 214,
    height: 32,
    
    borderRadius: 51,
    
    ...theme.typography.buttons,
    fontWeight: 700,
  },
  mainButton: {
    color: theme.colors.clearMain,
    backgroundColor: theme.colors.white,
    
    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.clear2,
    
    "&:hover": {
      backgroundColor: theme.colors.clear2,
      color: theme.colors.grey1,
    },

    "&:disabled": {
      backgroundColor: theme.colors.white,
      color: theme.colors.clearMain,
      borderColor: theme.colors.clearMain,
      opacity: 0.3
    }
  },
  redButton: {
    color: theme.colors.clearRed,
    backgroundColor: theme.colors.white,

    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.clearRed,

    "&:hover": {
      backgroundColor: theme.colors.clearRed,
      color: theme.colors.grey1,
    },

    "&:disabled": {
      backgroundColor: theme.colors.white,
      color: theme.colors.clearRed,
      borderColor: theme.colors.clearRed,
      opacity: 0.3
    }
  },
  mainColorText: {
    color: theme.colors.clearMain
  },
  requiredFields: {    
    ...theme.typography.txtBody2,
    color: theme.colors.grey4,
  },
  dot: {
    marginLeft: 3,
    color: theme.colors.clearMain
  },
  cancelModalPaper: {
    backgroundColor: theme.palette.background.paper,
    borderRadius: "12px",
    boxShadow: theme.shadows[5],
    padding: "24px",
    width: 472,
    minHeight: 153,
  },
  cancelModalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  cancelModalTitleSpace: {
    margin: "24px 0px 24px 0px",
  },
  modalTitleText: {
    fontSize: "18px",
    fontFamily: 'Inter, sans-serif',
    fontStyle: "normal",
    fontWeight: 700,
    color: "#26292B",
  },
  modalSubTitleText: {
    fontFamily: 'Inter, sans-serif',
    fontSize: 12,
    color: theme.colors.grey1,
    fontStyle: "normal",
  },
  modalActions: {
    display: "flex",
    justifyContent: "space-around",
  },
  goBack: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  cancelConfirmation: {
    background: theme.colors.clearGreen,
    color: "#F6F6F6",
    "&:hover": {
      background: theme.colors.clearGreen,
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }
  })
);

export default useStyles;