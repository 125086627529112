import React, { useEffect, useState } from "react";

import Box from "@material-ui/core/Box";
import Input from "@material-ui/core/Input";
import MuiSelect from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

import ArrowDropDownIcon from "./../../../assets/icons/ArrowDropDownIcon";
import useStyles from "./css";
import { MultiselectOverflowRenderType, SelectProps } from "./props";
import InputSearch from "./../InputSearch";
import CheckboxWithLabel from "../CheckboxWithLabel";
import { SelectItem } from "../../../models/interfaces/select-item";


export default function MultiSelect(props: SelectProps) {
  const {
    values,
    maxValues,
    allItemsPlaceholder,
    filterPlaceholder,
    name,
    items,
    width,
    onChange,
    onReset,
    onBlur,
    onOpen,
    disabled,
    styleClass,
    classes,
    isValid,
    colorArrowIcon,
    sort,
    height,
    overflowRenderCap,
    overflowRenderType,
    showSearch
  } = props;
  
  const overflowCap = overflowRenderCap ?? 25;
  
  const useClasses = useStyles(width, height)();
  const [filter, setFilter] = useState<string>("");
  const restrictValuesCount = !!maxValues && items.length > maxValues && values.length === 0;
  const trimValuesSelection = !!maxValues && values.length > maxValues;
  const restrictValuesSelection = !!maxValues && values.length >= maxValues;
  
  const displayItems = (items: Array<SelectItem | string | number>): Array<SelectItem | string | number> => {
    if (sort) {
      const compare = (a: SelectItem | string | number, b: SelectItem | string | number) => {
        if (typeof a === "object" && typeof b === "object") {
          if (a.value < b.value) {
            return -1;
          }
          if (a.value > b.value) {
            return 1;
          }
          return 0;
        } else {
          return a < b ? -1 : a > b ? 1 : 0;
        }
      }
      return [...items].sort(compare);
    } else {
      return items;
    }
  }
  
  if (onChange && maxValues && (restrictValuesCount || trimValuesSelection)){
    const keys = displayItems(items).map((item: SelectItem | string | number, index) => typeof item === "object" ? item.key : item);
    onChange(keys.slice(0, maxValues))
  }
  
  const isError = (): boolean => {
    if (!isValid) {
      return false;
    }

    if (isValid() === undefined) {
      return false;
    }

    return !isValid();
  };

  const handleOnChange = (value: string | number) => {
    if (value === ""){
      if (onChange && !!maxValues && items.length > maxValues){
        const keys = displayItems(items).map((item: SelectItem | string | number, index) => typeof item === "object" ? item.key : item);
        onChange(keys.slice(0, maxValues))
      } else {
        onReset();
      }
    } else {
      if (!values.includes(value)) {
        const v = [...values, value]
        onChange && onChange(v);
      } else {
        const v = values.filter(e => e !== value);
        onChange && onChange(v);
      }
    }
  };

  const handleOnFilter = (event: React.ChangeEvent<{ value: string }>) => {
    setFilter(event.target.value);
  };

  const handleOnClose = (event: React.ChangeEvent<{ }>) => {
    setFilter("");
  };

  const ArrowIcon = (props: any) => (
    <ArrowDropDownIcon
      {...props}
      style={{ top: "inherit", right: 12, color: colorArrowIcon ?? "#6462F3" }}
    />
  );

  const isHidden = (item: SelectItem | string | number): boolean => {
    if (filter) {
      const flt = filter.toUpperCase();
      if (typeof item === "object") {
        return !(item as SelectItem).value.toUpperCase().includes(flt);
      } else if (typeof item === "string") {
        return !item.toUpperCase().includes(flt);
      } else {
        return String(item) !== flt;
      }
    }
    return false;
  }
  
  function renderValue(selected: unknown) {
    if ((selected as Array<any>).length === 0)
      return allItemsPlaceholder;
    
    let names = (selected as Array<string | number>).map((item: string | number) =>
      ((items as Array<SelectItem>).find(v => v.key === item) || { value: item }).value);
    if (sort) {
      names = names.sort();
    }
    
    if (overflowRenderType === MultiselectOverflowRenderType.Dots) {
      const namesString = names.join(', ');
      if (namesString.length > overflowCap) {
        return namesString.substring(0, overflowCap) + " + ..."
      } else {
        return namesString;
      }
    }
    
    if (names.length > 2) {
      names = names.map(n => n.split(' ')[0]);
      return names.slice(0, 2).join(', ') + `, +${names.length - 2}`
    } else {
      return names.join(', ');
    }
  }

  return (
    <MuiSelect
      multiple
      renderValue={renderValue}
      disableUnderline
      classes={
        classes || {
          root: `${useClasses.focused} ${values && values.length ? useClasses.selected : useClasses.placeholder
            } ${isError() ? useClasses.inputError : ""}`,
        }
      }
      name={name}
      className={styleClass ?? useClasses.select}
      input={<Input />}
      MenuProps={{
        getContentAnchorEl: null,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "left",
        },
        PaperProps: {
          className: useClasses.paper,
        }
      }}
      displayEmpty
      disabled={disabled ?? false}
      onBlur={(e) => { if (onBlur) onBlur(e) }}
      onClose={handleOnClose}
      value={values || []}
      IconComponent={ArrowIcon}
      onOpen={(e) => { if (onOpen) onOpen(e) }}
    >
      {showSearch &&
        <Box key={`option-filter`} className={useClasses.searchContainer} onKeyDown={(e) => {e.stopPropagation();}}>
          <InputSearch placeholder={filterPlaceholder} onChange={handleOnFilter} value={filter}/>
        </Box>
      }
      <ul className={useClasses.menuList}>
        <MenuItem
          key={`option-all-items`}
          classes={{ root: useClasses.itemRoot }}
          onClick={() => handleOnChange("")}
          disabled={restrictValuesCount}
        >
          <CheckboxWithLabel
            checked={!values.length}
            setChecked={() => { }}
            label={allItemsPlaceholder}
            checkboxClass={useClasses.checkbox}
          />
        </MenuItem>
        {displayItems(items).map((item: SelectItem | string | number, index) =>
          typeof item === "object" ? (
            <MenuItem
              classes={{
                root: useClasses.itemRoot,
                selected: useClasses.itemSelected,
              }}
              key={`option-${item.key}-${index}`}
              onClick={() => handleOnChange(item.key)}
              hidden={isHidden(item)}
              disabled={restrictValuesSelection && values.indexOf(item.key) < 0}
            >
              <CheckboxWithLabel
                checked={values.indexOf(item.key) > -1}
                setChecked={() => { }}
                label={item.value}
                checkboxClass={useClasses.checkbox}
              />
            </MenuItem>
          ) : (
            <MenuItem
              classes={{
                root: useClasses.itemRoot,
                selected: useClasses.itemSelected,
              }}
              key={item || index}
              onClick={() => handleOnChange(item)}
              hidden={isHidden(item)}
            >
              <CheckboxWithLabel
                checked={values.indexOf(item) > -1}
                setChecked={() => { }}
                label={item}
                checkboxClass={useClasses.checkbox}
              />
            </MenuItem>
          )
        )}
      </ul>
    </MuiSelect>
  );
}