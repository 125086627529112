import React from "react";

import { useTranslation } from "react-i18next";

import 'antd/dist/antd.css';
import { TimePicker } from "antd";
import moment, { Moment } from "moment";

import Button from "@material-ui/core/Button";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import Tooltip from "@material-ui/core/Tooltip";

import SwitchWithLabelAndTooltip from "../../../../common/SwitchWithLabel";
import { CopyIcon, DeleteIcon, PlusIcon } from "../../../../../assets/icons";
import { UserScheduleWindow } from "../../../../../models/user-schedule-info";
import { UserScheduleItemProps } from "./props";
import { DayOfWeek } from "../../../../../models/enums/day-of-week";
import useStyles from "./css";


export default function UserScheduleItem(props: UserScheduleItemProps) {
  
  const { day, setDay, copyToAll } = props;

  const classes = useStyles();
  const { t } = useTranslation("settings");
  const format = 'HH:mm';
  
  function setIsAvailable(value: boolean) {
    setDay({...day, isActive: value });
  }

  function setWindows(value: UserScheduleWindow[]) {
    setDay({...day, windows: value });
  }

  function handleCopyToAll() {
    copyToAll(day)
  }
  
  function renderWindows(window: UserScheduleWindow, index: number) {
    
    const setTimeStart = (value: Moment) => {
      const newWindows = [...day.windows];
      newWindows[index].timeStart = value.format(format);
      setWindows(newWindows);
    }

    const setTimeClosing = (value: Moment) => {
      const newWindows = [...day.windows];
      newWindows[index].timeClosing = value.format(format);
      setWindows(newWindows);
    }

    const isInvalidStartTime = () => {
      const newWindowsExceptCurrent = [...day.windows].filter(x => x !== day.windows[index])
      const currentStartTime = moment(day.windows[index].timeStart, format);
      return newWindowsExceptCurrent.some(x => currentStartTime >= moment(x.timeStart, format) && currentStartTime <= moment(x.timeClosing, format))
    }

    const IsInvalidEndTime = () => {
      const newWindowsExceptCurrent = [...day.windows].filter(x => x !== day.windows[index])
      const currentClosingTime = moment(day.windows[index].timeClosing, format);
      return newWindowsExceptCurrent.some(x => currentClosingTime >= moment(x.timeStart, format) && currentClosingTime <= moment(x.timeClosing, format))
    }

    return (
      <div className={classes.datePickers} key={`day_${day.dayOfWeek}_${index}`}>

        <div className={classes.timePickersContainer}>
          <div className={classes.timePickerContainer}>
            {index === 0 && day.dayOfWeek === DayOfWeek.Monday &&
              <Typography className={classes.header}>
                {t('Start')}
              </Typography>
            }
            <TimePicker
              key={`timeStart_${day.dayOfWeek}_${index}`}
              className={`${classes.timePickerInput} ${isInvalidStartTime() ? classes.timePickerInvalid : null}`}
              popupClassName={classes.timePickerPopup}
              onOk={(time) => setTimeStart(time)}
              placeholder={t("Start")}
              inputReadOnly={true}
              showNow={false}
              value={moment(day.windows[index].timeStart, format)}
              defaultValue={moment(day.windows[index].timeStart, format)}
              disabled={!day?.isActive}
              format={format}
            />
          </div>
          <div className={classes.timePickerContainer}>
            {index === 0 && day.dayOfWeek === DayOfWeek.Monday &&
              <Typography className={classes.header}>
                {t('Closing')}
              </Typography>
            }
            <TimePicker
              key={`timeClosing_${day.dayOfWeek}_${index}`}
              className={`${classes.timePickerInput} ${IsInvalidEndTime() ? classes.timePickerInvalid : null}`}
              popupClassName={classes.timePickerPopup}
              onOk={(time) => setTimeClosing(time)}
              placeholder={t("Closing")}
              inputReadOnly={true}
              showNow={false}
              value={moment(day.windows[index].timeClosing, format)}
              defaultValue={moment(day.windows[index].timeClosing, format)}
              disabled={!day?.isActive}
              format={format}
            />
          </div>
        </div>

        {day?.isActive &&
          <div
            className={`${classes.addWindowButtonContainer} ${index === 0 && day.dayOfWeek === DayOfWeek.Monday ? classes.topRowMargin : null}`}
          >
            {
              day.windows.length - 1 === index &&
              <Tooltip
                title={`${t("Add another work schedule period to this day")}`}
                classes={{
                  tooltip: classes.tooltip
                }}
              >
                <IconButton
                  className={classes.addWindowButton}
                  onClick={handleAddWindow}
                  disabled={day.windows.length >= 5}
                >
                  <PlusIcon
                    color={"#6462F3"}
                    disabled={day.windows.length >= 5}
                  />
                </IconButton>
              </Tooltip>
            }
          </div>
        }

        {day.isActive && index !== 0 &&
          <div
            className={`${classes.removeWindowButtonContainer} ${index === 0 && day.dayOfWeek === DayOfWeek.Monday ? classes.topRowMargin : null}`}
          >
            <Tooltip
              title={`${t("Remove this work schedule period")}`}
              classes={{
                tooltip: classes.tooltip
              }}
            >
              <IconButton className={classes.removeWindowButton} onClick={() => handleRemoveWindow(window)}>
                <DeleteIcon />
              </IconButton>
            </Tooltip>

          </div>
        }
      </div>
    );
  }
  
  function handleAddWindow() {
    const lastWindowClosing = day.windows[day.windows.length - 1].timeClosing;
    const newWindowOpen = moment(lastWindowClosing, format).add(1, "hour").format(format);
    const newWindowClosing = moment(newWindowOpen, format).add(1, "hour").format(format)
    const newWindow = new UserScheduleWindow(newWindowOpen,newWindowClosing);
    setWindows([...day.windows, newWindow]);
  }
  
  function handleRemoveWindow(item: UserScheduleWindow) {
    const newWindows = day.windows.filter((x: UserScheduleWindow) => x !== item);
    setWindows(newWindows);
  }
  
  return (
    <div className={classes.root}>
      <div className={classes.switchContainer}>
        {day.dayOfWeek === 1 &&
          <Typography className={classes.header}>
            {t('Availability')}
          </Typography>
        }
        <div className={classes.switch}>
          <SwitchWithLabelAndTooltip
            value={day?.isActive}
            setValue={setIsAvailable}
            label={t(DayOfWeek[day.dayOfWeek])}
          />
        </div>
      </div>
      <div className={classes.windows}>
        {day.windows.map(renderWindows)}
        {day.isActive && day.dayOfWeek === DayOfWeek.Monday &&
          <Button
            variant="text"
            className={classes.copyToAllButton}
            onClick={handleCopyToAll}
          >
            <CopyIcon
              color={"#FAFAFA"}
              border={"#6462F3"}
              style={{ width: 22, height: 22 }}
            />
            {t("Copy to all")}
          </Button>
        }
        <div className={classes.divider} />
      </div>
      
    </div>
  );
}