import { SelectServiceModel } from "./select-service-model";

export class SelectGroupServiceModel {
  category: string | null;
  services: SelectServiceModel[] | null;
  constructor(x?: any) {
    if (typeof x === "object") {
      this.category = x.category;
      this.services = x.services;
    } else {
      this.category = null;
      this.services = null;
    }
  }
}
