import { makeStyles } from "@material-ui/core/styles";

const useStyles = (progress: number) => makeStyles((theme) => ({
  root: {
    display: "flex",

    zIndex: 3000,

    position: "absolute",
    left: 20,
    bottom: 14,

    width: 282,
    height: 117,

    borderRadius: 10,

    boxShadow: "0px 4px 4px rgba(0,0,0,0.1)",
  },
  side: {
    alignSelf: "flex-start",

    height: "100%",
    width: 5,

    backgroundColor: theme.colors.clearMain,

    borderBottomLeftRadius: 10,
    borderTopLeftRadius: 10,
  },
  container: {
    display: "flex",
    flexDirection: "column",

    width: 277,
  },
  header: {
    alignSelf: "flex-start",

    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",

    height: 17,
    width: "100%",

    backgroundColor: theme.colors.grey1,

    borderTopRightRadius: 10,
  },
  dndIconContainer: {
    marginLeft: 3,
  },
  closeIconContainer: {
    marginRight: 8,
  },
  closeIconButton: {
    alignSelf: "flex-start",
  },
  closeIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  contentContainer: {
    display: "flex",

    width: 277,
    height: 100,

    backgroundColor: theme.colors.white,

    borderBottomRightRadius: 10,
  },
  mainIconContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    paddingLeft: 14,
    paddingRight: 14,

    height: "100%",
  },
  mainIconBackground: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",

    width: 28,
    height: 28,

    borderRadius: 14,
    backgroundColor: theme.colors.clearMain
  },
  content: {
    display: "flex",
    flexDirection: "column",

    width: 221,

    paddingTop: 8,
    paddingBottom: 12,
    paddingRight: 16,
  },
  title: {
    marginBottom: 4,

    ...theme.typography.txtBody2,
    fontWeight: 700,
    color: theme.colors.grey5,
  },
  text: {
    marginBottom: 13,

    ...theme.typography.txtBody2,
    color: theme.colors.grey5,
  },
  downloadProgressBar: {
    display: "block",
    width: 200,
    height: 6,
    backgroundColor: theme.colors.grey1,

    borderRadius: 10
  },
  downloadProgressBarEmpty: {
    height: "100%",
  },
  downloadProgressBarProgress: {
    display: "block",

    height: "100%",
    width: `${progress}%`,

    backgroundColor: theme.colors.clearGreen,
    borderRadius: 10
  }
}));

export default useStyles;