import React from "react";

import SvgIcon from "@material-ui/core/SvgIcon";


const CreditCardIcon = (props: any) => {
  
  const { color, style, ...otherProps } = props;
  
  return (
    <SvgIcon
      {...otherProps}
      style={{ ...style, fontSize: '24px' }}
      viewBox="0 0 24 24"
    >
      <path
        d="M19.4688 5H4.5C3.65625 5 3 5.6875 3 6.5V17.5C3 18.3438 3.65625 19 4.5 19H19.4688C20.3125 19 21 18.3438 21 17.5V6.5C21 5.6875 20.3125 5 19.4688 5ZM4.6875 6.5H19.2812C19.4062 6.5 19.4688 6.59375 19.4688 6.6875V8H4.5V6.6875C4.5 6.59375 4.5625 6.5 4.6875 6.5ZM19.2812 17.5H4.6875C4.5625 17.5 4.5 17.4375 4.5 17.3125V12H19.4688V17.3125C19.4688 17.4375 19.4062 17.5 19.2812 17.5ZM9 14.375C9 14.1875 8.8125 14 8.625 14H6.375C6.15625 14 6 14.1875 6 14.375V15.625C6 15.8438 6.15625 16 6.375 16H8.625C8.8125 16 9 15.8438 9 15.625V14.375ZM15 14.375C15 14.1875 14.8125 14 14.625 14H10.375C10.1562 14 10 14.1875 10 14.375V15.625C10 15.8438 10.1562 16 10.375 16H14.625C14.8125 16 15 15.8438 15 15.625V14.375Z"
        fill={color || style?.color || "#919CA5"}
      />
    </SvgIcon>
  )
};
export default CreditCardIcon;