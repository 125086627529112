import React from "react";
import clsx from 'clsx';
import { CSSProperties, useEffect, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import BlockSchedule from "../../../../../models/blocked-schedule";
import { DeleteIcon, EstablishmentIcon } from "../../../../../assets/icons";
import Typography from "@material-ui/core/Typography";
import useStyles from "./css";

type AbsenceBlockProps = {
    onClick?: React.MouseEventHandler;
    topOffset?: number;
    leftOffset?: number;
    absence: BlockSchedule;
    vertical?: boolean;

    /**
     * if set, block's size will be determined based on the given ratio of
     * pixels per minute, if vertical is set to true, this will determine the height of the block,
     * otherwise width
     */
    pixelPerMinute?: number;
    overrideDuration?: number;
};

export default function AbsenceBlock({
    onClick,
    absence,
    leftOffset = 10,
    topOffset = 10,
    vertical,
    pixelPerMinute,
    overrideDuration,
}: AbsenceBlockProps) {
    const classes = useStyles();
    const { t } = useTranslation("general");

    const rootElRef = useRef<HTMLDivElement | null>(null);

    const duration = useMemo(() => {
        if (overrideDuration) {
            return overrideDuration;
        }

        const startDt = absence.start;
        const endDt = absence.end;
        return endDt.diff(startDt).as('minutes');
    }, [overrideDuration, absence.start, absence.end]);

    const style = useMemo(() => {
        const s: CSSProperties = {};

        if (pixelPerMinute) {
            const size = pixelPerMinute * duration;
            s[vertical ? 'height' : 'width'] = `${size}px`;
        }

        return s;
    }, [pixelPerMinute, vertical, duration]);

    useEffect(() => {
        // I hate this, but it works
        if (!rootElRef.current) throw new Error();
        const rootEl = rootElRef.current;
        rootEl.style.setProperty('--absence-block-offset-y', `0px`);
        rootEl.style.setProperty('--absence-block-offset-x', `0px`);
        const scrollbarParent = getScrollableParent(rootEl);
        if (!scrollbarParent) return;
        const parentRect = scrollbarParent.getBoundingClientRect();

        const callback = () => {
            const rootRect = rootEl.getBoundingClientRect();

            if (!vertical) {
                const rootElementOffset = rootRect.left;
                const diff = parentRect.left - rootElementOffset;

                if (diff > -leftOffset) {
                    rootEl.style.setProperty('--absence-block-offset-x', `${diff + leftOffset}px`);
                } else {
                    rootEl.style.setProperty('--absence-block-offset-x', `0px`);
                }
            } else {
                const rootElementOffset = rootRect.top;
                const diff = parentRect.top - rootElementOffset;

                if (diff > -topOffset) {
                    rootEl.style.setProperty('--absence-block-offset-y', `${diff + topOffset}px`);
                } else {
                    rootEl.style.setProperty('--absence-block-offset-y', `0px`);
                }
            }
        };
        callback();

        scrollbarParent.addEventListener('scroll', callback);
        return () => scrollbarParent.removeEventListener('scroll', callback);
    }, [rootElRef, leftOffset, topOffset, vertical]);
    
    const dateFormat = (date: Date): string => {
        const formatDate = date.toTimeString().split(" ")[0].split(":");
        return `${formatDate[0]}:${formatDate[1]}`;
    };

    return (<>
        <div
            style={style}
            ref={rootElRef}
            role="button"
            className={clsx(classes.root, vertical && classes.vertical, absence.isBlockedMultiple ? classes.rootMultipleBlocked : classes.rootBlocked)}
            onClick={onClick}
        >
            <div className={classes.inner}>
                <div key={absence.id} data-absence-id={absence.id}>
                    {
                        absence.isBlockedMultiple ?
                          <>
                              <div className={classes.multipleBlockerTitle}>
                                  {absence.blockerCount + " " + t("blocked schedules")}
                              </div>
                              <div className={classes.multipleBlockerDate}>
                                  {
                                      absence.allDay
                                        ?
                                        t("All day")
                                        :
                                        dateFormat(absence.start.toJSDate()) +
                                        " - " +
                                        dateFormat(absence.end.toJSDate())
                                  }
                              </div>
                          </>
                          :
                          <>
                              <div className={`${classes.header} ${classes.blocked}`}></div>
                              <div className={classes.content}>
                            <span style={{ display: "inline-block", marginRight: 5 }}>
                                <DeleteIcon color={"rgba(50, 57, 71, 0.76);"}/>
                            </span>
                                  <Typography style={{ display: "inline-block" }}
                                              className={classes.customerTitleBlocked}>
                                      {
                                          absence.isBlockedReasonPredetermined ? t(absence.blockedReason!) : absence.blockedReason
                                      }
                                  </Typography>
                                  <Typography className={classes.hoursTextBlocked}>
                                      {dateFormat(absence.start.toJSDate()) +
                                        " - " +
                                        dateFormat(absence.end.toJSDate())}
                                  </Typography>
                                  <Typography className={classes.personChargeTextBlocked}>
                                      {t(absence.specialist!)}
                                  </Typography>
                                  {
                                    absence.establishmentName &&
                                    <div className={classes.establishmentRow}>
                                        <EstablishmentIcon/>
                                        <Typography className={classes.hoursTextBlocked}>
                                            {absence.establishmentName}
                                        </Typography>
                                    </div>
                                  }
                              </div>
                          </>
                    }
                </div>
            </div>
        </div>
      
      
        
      </>
    );
}

function getScrollableParent(element: HTMLElement): HTMLElement | null {
    let node = element.parentElement;

    while (node !== null) {
        if (node.dataset.scrollParent || node.classList.contains('data-scroll-parent')) {
            return node;
        }

        node = node.parentElement;
    }

    return null;
}
