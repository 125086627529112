import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  labelAlternativeLabel: {
    ...theme.typography.txtBody2,
    color: `${theme.colors.grey4} !important`,
    "& .MuiStepLabel-active": {
      color: `${theme.colors.clearMain} !important`
    }
  },
  labelCompleted: {
    color: `${theme.colors.clearGreen} !important`
  },
  })
);

export const useStepIconStyles = makeStyles((theme) => ({
  root: {
    color: theme.colors.grey2,
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  circle: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.grey2}`
  },
  activeCircle: {
    width: 14,
    height: 14,
    borderRadius: '50%',
    border: `1px solid ${theme.colors.clearMain}`,
    backgroundColor: theme.colors.clearMain
  },
  completed: {
    color: theme.colors.clearGreen,
    zIndex: 1,
    fontSize: 22,
    fontWeight: "bold"
  }})
);

export default useStyles;