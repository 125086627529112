import React from "react";

import useStyles from "./css";
import { useTranslation } from "react-i18next";
import ProgressRingWithIcon from "../../../common/ProgressRingWithIcon";
import WhatsAppNotificationIcon from "../../../../assets/icons/WhatsAppNotificationIcon";
import { Color } from "../../../../constants/colors";


export default function NotificationFooter(props: any) {
  
  const { conversationsLimit, availableConversationsCount } = props;
  
  const classes = useStyles();
  const { t } = useTranslation("general");
  
  function getProgressRingFillPercent() {
    if (availableConversationsCount <= 0) return 0;
    const remainingPart = availableConversationsCount / conversationsLimit;
    return Math.round(remainingPart * 100);
  }
  
  const fillPercent = getProgressRingFillPercent();
  
  function getColorByFillPercent() {
    if (fillPercent <= 0) return Color.gray1;
    if (fillPercent > 50) return Color.green;
    if (fillPercent <= 25) return Color.red;
    return Color.yellow;
  }
  
  const iconColor = getColorByFillPercent();
  
  const WhatsAppIcon =
    <WhatsAppNotificationIcon
      style={{ color: iconColor, width: 32, height: 32 }}
      viewBox={"0 0 24 24"}
    />
  
  return (
    <div className={classes.root}>
      <div className={classes.messagesIconContainer}>
        <ProgressRingWithIcon 
          icon={WhatsAppIcon}
          fillPercent={fillPercent}
          color={iconColor}
          className={classes.messageRing}
        />
      </div>
      <div className={classes.messagesText}>
        {availableConversationsCount + " " + t("available conversations")}
      </div>
    </div>
  );
}