import React, { useState, useEffect } from "react";

import { useTranslation } from "react-i18next";
import { conformToMask } from "react-text-mask";

import useStyles from "./css";
import { PatientClinicalHistoryTabProps } from "./props";

import { useAppDispatch, useAppSelector } from "../../../redux/hooks";
import { selectAccountSettings } from "../../../redux/store";

import Cloner from "../../../utils/cloner";

import { GetMobileMaskByTemp } from "../../../constants/mask";
import { Color } from "../../../constants/colors";
import {
  ArrowDownIcon,
  RoundCheckmarkIcon,
  CheckboxCheckedIcon,
  CheckboxUncheckedIcon
} from "../../../assets/icons";

import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@material-ui/core";
import ClinicalHistoryRadioGroup from "./ClinicalHistoryRadioGroup";
import ClinicalHistoryTextInput from "./ClinicalHistoryTextInput";

import { CustomerService } from "../../../api/customer-service";
import { SectionFieldItemModel } from "../../../models/section-field-item-model";
import { SectionFieldType } from "../../../models/enums/section-field-type";
import { FormRadioValue } from "../../../models/form-radio-value";
import { SectionFieldValueModel } from "../../../models/section-field-value-model";
import { FormPhoneNumberValue } from "../../../models/form-phone-number-value";
import { FormFieldItemValue } from "../../../models/form-field-item-value";
import { SectionFieldModel } from "../../../models/section-field-model";
import { PatientClinicalHistoryTabDataResponse } from "../../../models/patient-clinical-history-tab-data-response";
import { ClinicalHistoryFieldAndValue } from "../../../models/clinical-history-field-and-value";
import { UpdatePatientObservationsRequest } from "../../../models/update-patient-observations-request";
import { UpdatePatientClinicalHistoryFieldValueRequest } from "../../../models/update-patient-clinical-history-field-value-request";
import { navbarActions } from "../../../redux/navbar-slice";
import PatientSignature from "./PatientSignature";
import { Link } from "react-router-dom";
import { Url } from "../../../constants/url";
import SaveSignatureModal from "./SaveSignatureModal";


export default function PatientClinicalHistoryTab(props: PatientClinicalHistoryTabProps) {
  const { customerId } = props;
  const stringify = require('safe-stable-stringify');
  const sectionTNamespace = "sections";
  const { t } = useTranslation(["general", sectionTNamespace]);
  const classes = useStyles();
  const dispatch = useAppDispatch();
  const accountSettings = useAppSelector(selectAccountSettings);
  const localeApp = useAppSelector(state => state.applicationInterface.locale);

  const [tabDataModel, setTabDataModel] = useState<PatientClinicalHistoryTabDataResponse>(new PatientClinicalHistoryTabDataResponse());
  const [tabDataModelInitialState, setTabDataModelInitialState] = useState<PatientClinicalHistoryTabDataResponse>(new PatientClinicalHistoryTabDataResponse());
  const [saveSignatureModalOpen, setSaveSignatureModalOpen] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      await loadData();
    }

    fetchData();
  }, []);

  const loadData = async () => {
    dispatch(navbarActions.setShowLoader(true));
    const response = await CustomerService.getPatientClinicalHistoryTabData(customerId);
    dispatch(navbarActions.setShowLoader(false));
    const responseClone = Cloner.deepCopy<PatientClinicalHistoryTabDataResponse>(response);
    setTabDataModel(response);
    setTabDataModelInitialState(responseClone);
  };

  const updateFieldValue = async (fieldId: string) => {
    const fieldsAndValues = [...tabDataModel.clinicalHistorySections
      .find(s => s.fieldsAndValues
        .some(fv => fv.field.id === fieldId))!
      .fieldsAndValues];
    const fieldAndValue = fieldsAndValues.find(f => f.field.id === fieldId);

    if (!validateFieldValue(fieldAndValue!))
      return;

    const initialFieldsAndValues = [...tabDataModelInitialState.clinicalHistorySections
      .find(s => s.fieldsAndValues
        .some(fv => fv.field.id === fieldId))!
      .fieldsAndValues];
    const initialValue = initialFieldsAndValues.find(f => f.field.id === fieldId)?.value;

    //Prevent API call if data remains the same
    if (stringify(initialValue) === stringify(fieldAndValue?.value)) {
      return;
    }

    const isUpdated = await CustomerService.updatePatientClinicalHistoryFieldValue(new UpdatePatientClinicalHistoryFieldValueRequest({customerId: customerId, fieldAndValue: fieldAndValue}))

    if (isUpdated) {
      const newDataClone = Cloner.deepCopy<PatientClinicalHistoryTabDataResponse>(tabDataModel);
      setTabDataModelInitialState(newDataClone);
    }
  };

  function updateSignature(value: string | null) {
    setTabDataModel({ ...tabDataModel, signature: value });
  }

  async function saveSignature() {
    const response = await CustomerService.updatePatientSignature({
        customerId: customerId,
        signature: tabDataModel.signature!
      });

    if (response.isUpdated) {
      setTabDataModel({...tabDataModel,
        isFormSignedAtEstablishment: true,
        ip: response.ip,
        formSignedAt: response.date
      })
      const newDataClone = Cloner.deepCopy<PatientClinicalHistoryTabDataResponse>(tabDataModel);
      setTabDataModelInitialState(newDataClone);
    }
    else
    {
      setTabDataModel({...tabDataModel, signature: tabDataModelInitialState.signature })
    }
    setSaveSignatureModalOpen(false);
  }

  const updateObservations = async () => {
    if (tabDataModel.observations?.trim() === tabDataModelInitialState.observations?.trim() ||
      !tabDataModelInitialState.observations && tabDataModel.observations?.trim() === "") {
      return;
    }
    
    const isUpdated = await CustomerService.updatePatientObservations(
      new UpdatePatientObservationsRequest({
        customerId: customerId,
        observations: tabDataModel.observations?.trim()
      }));

    if (isUpdated) {
      setTabDataModelInitialState({ 
        ...tabDataModelInitialState, 
        observations: tabDataModel.observations?.trim().slice() })
    }
  };

  const handleRadioTextAreaOnBlur = async (fieldId: string, radioValue: FormRadioValue | null, textAreaValue: string | undefined, initialTextAreaValue: string | null) => {
    if (!radioValue)
      return;
    if (initialTextAreaValue?.trim() === textAreaValue?.trim())
      return;
    await updateFieldValue(fieldId);
  };

  const setFieldValue = (fieldId: string, value: string | FormPhoneNumberValue | FormRadioValue | null, additionalTextValue: string | null = null) => {
    if (!tabDataModel.clinicalHistorySections)
      return;

    const fieldsAndValues = [...tabDataModel.clinicalHistorySections
      .find(s => s.fieldsAndValues
        .some(fv => fv.field.id === fieldId))!
      .fieldsAndValues];
    const fieldAndValue = fieldsAndValues.find(f => f.field.id === fieldId);

    if (!fieldAndValue)
      return;

    //make value null if field is empty (need for compairing with old patient version and for proper storing)
    if (typeof value === "string" && value === "") {
      fieldAndValue.value = null;
      const clone = Cloner.deepCopy<PatientClinicalHistoryTabDataResponse>(tabDataModel);
      clone.clinicalHistorySections.find(s => s.fieldsAndValues.some(fv => fv.field.id === fieldId))!.fieldsAndValues = fieldsAndValues;
      setTabDataModel(clone);
      return;
    }

    if (!additionalTextValue) {
      if (!fieldAndValue.value) {
        fieldAndValue.value = new SectionFieldValueModel({ type: fieldAndValue.field.type, value: value })
      }
      else {
        fieldAndValue.value.type = fieldAndValue.field.type;
        fieldAndValue.value.value = value!;
      }
    }
    //if field is Radio and contain additional text value
    else if (additionalTextValue && fieldAndValue.field.type === SectionFieldType.Radio) {
      let newValue: FormRadioValue;
      //if this field value has already selected field item - just setting the argument value
      if (value && (value as FormRadioValue).item) {
        newValue = value as FormRadioValue;
      }
      //if not - we're selecting first item automatically
      else {
        const firstSelectItem = fieldAndValue.field.items.sort((i1, i2) => i1.order - i2.order)[0];
        const autoSelectedFirstItemValue = new FormFieldItemValue({ fieldItemId: firstSelectItem.id, value: firstSelectItem.name })
        newValue = new FormRadioValue();
        newValue.item = autoSelectedFirstItemValue;
        newValue.additionalFreeTextValue = additionalTextValue;
      }
      if (!fieldAndValue.value) {
        fieldAndValue.value = new SectionFieldValueModel({ type: fieldAndValue.field.type, value: newValue })
      }
      else {
        fieldAndValue.value.value = newValue!;
      }
    };

    const clone = Cloner.deepCopy<PatientClinicalHistoryTabDataResponse>(tabDataModel);
    clone.clinicalHistorySections.find(s => s.fieldsAndValues.some(fv => fv.field.id === fieldId))!.fieldsAndValues = fieldsAndValues;
    setTabDataModel(clone);
  };

  const validateFieldValue = (fv: ClinicalHistoryFieldAndValue): boolean => {
    if (fv.field.isRequired) {
      if (!fv.value?.value || fv.value?.value === "") {
        if (!fv.field.isDeleted) {
          return false;
        }
        else {
          return true;
        }
      }
      switch (fv.field.type) {
        case SectionFieldType.Text:
          if (!fv.value?.value || fv.value.value === "") {
            return false;
          }
          break;
        case SectionFieldType.Radio:
          if (!fv.field.isDeleted && (fv.value?.value as FormRadioValue).item === null) {
            return false;
          }
          break;
      }
    }

    return true;
  };

  const validateTextField = (field: SectionFieldModel, value: string): boolean => {
    const isValid = !(field.isRequired && value === "");
    return isValid;
  };

  const mapSectionsToComponents = () => {
    return tabDataModel.clinicalHistorySections
      .sort((s1, s2) => s1.section.order! - s2.section.order!)
      .map(s => {
        if (!s.fieldsAndValues ||
          s.fieldsAndValues.length === 0 ||
          s.fieldsAndValues.every(f => f.field.isDeleted && f.value === null) ||
          (s.section.isDeleted && (s.fieldsAndValues.length === 0 || s.fieldsAndValues.every(f => f.value === null))))
          return <></>
        return <>
          <div className={classes.sectionContainer}>
            <Accordion defaultExpanded={true}>
              <AccordionSummary
                expandIcon={<ArrowDownIcon style={{ height: 15, width: 15, color: Color.clear1 }}
                />}>
                <Typography className={classes.sectionLabel}>
                  {t(s.section.name!, { ns: sectionTNamespace })}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <div className={classes.fieldsEditionContainer}>
                  {s.fieldsAndValues
                    .sort((f1, f2) => f1.field.order - f2.field.order)
                    .map(f => {
                      if (f.field.isDeleted && f.value === null)
                        return;
                      else {
                        const fieldAndValue = tabDataModel.clinicalHistorySections
                          .flatMap(s => s.fieldsAndValues)
                          .find(fv => fv.field.id === f.field.id);
                        return <div className={classes.fieldEditionContainer}>
                          {getInput(fieldAndValue!)}
                        </div>
                      }
                    })}
                </div>
              </AccordionDetails>
            </Accordion>
          </div>
        </>
      });
  };

  const getInput = (fieldAndValueModel: ClinicalHistoryFieldAndValue) => {
    const sectionField = fieldAndValueModel.field;
    const valueField = fieldAndValueModel.value;
    if (!valueField?.value) {
      //return empty input for sectionField type
      switch (sectionField.type) {
        case SectionFieldType.Text:
          return getTextInput(sectionField);
        case SectionFieldType.Radio:
          return getRadioInput(sectionField);
        default:
          return;
      }
    }

    if (sectionField.type !== valueField.type) {
      switch (sectionField.type) {
        case SectionFieldType.Text:
          switch (valueField.type) {
            case SectionFieldType.Radio:
              //return TextInput with valueField.value.item.value
              const radioItemObj = valueField.value as FormRadioValue;
              return getTextInput(sectionField, radioItemObj.item.value);
          }
        case SectionFieldType.Radio:
          //return empty input for Radio type
          return getRadioInput(sectionField);
      }
    }

    //dealing with filled RadioInput
    if (sectionField.type === SectionFieldType.Radio && valueField.type === SectionFieldType.Radio) {
      if (sectionField.items
        .filter(i => !i.isDeleted)
        .map(i => i.id)
        .some(i => i === (valueField.value as FormRadioValue).item.fieldItemId)) {
        //return RadioInput with valueField.value.item checked
        return getRadioInput(sectionField, valueField.value as FormRadioValue);
      }
      else {
        //return RadioInput with FAKE valueField.value.item checked
        return getRadioInput(sectionField, valueField.value as FormRadioValue, true);
      }
    }

    //dealing with other filled field types
    switch (sectionField.type) {
      case SectionFieldType.Text:
        //return filled TextInput
        return getTextInput(sectionField, valueField.value as string);
    }
  };

  const getTextInput = (field: SectionFieldModel, value: string | null = null) => {
    return <>
      <ClinicalHistoryTextInput
        key={field.id!}
        value={value ? t(value, { ns: sectionTNamespace }) : ""}
        setValue={(v) => setFieldValue(field.id!, v)}
        onBlur={async () => { await updateFieldValue(field.id!) }}
        label={t(field.name, { ns: sectionTNamespace })}
        isValid={(v) => validateTextField(field, v)}
        required={field.isRequired}
        placeholder={t(field.name, { ns: sectionTNamespace })}
        maxLength={1500} />
    </>
  };

  const getRadioInput = (field: SectionFieldModel, value: FormRadioValue | null = null, isFakeRadioItem: boolean = false) => {
    const getSelectedItem = () => {
      if (!value && !isFakeRadioItem) {
        return null;
      };
      if (value && !isFakeRadioItem)
        //return genuine selected item
        return field.items.filter(i => i.id === value.item.fieldItemId)[0];
      if (value && isFakeRadioItem)
        //return "fake"/old selected item
        return new SectionFieldItemModel({ id: value.item.fieldItemId, name: value.item.value, order: 0 });

      return null;
    };

    const setRadioValue = async (fieldId: string, i: SectionFieldItemModel) => {
      const radioValue = new FormFieldItemValue();
      radioValue.fieldItemId = i.id!;
      radioValue.value = i.name;

      const itemModel = new FormRadioValue();
      itemModel.item = radioValue;
      itemModel.additionalFreeTextValue = value ? value.additionalFreeTextValue : ""
      setFieldValue(fieldId, itemModel);
      await updateFieldValue(fieldId);
    };

    return <>
      <ClinicalHistoryRadioGroup
        key={field.id!}
        field={field}
        value={value}
        selectedItem={getSelectedItem()}
        setSectionFieldItem={async (i) => await setRadioValue(field.id!, i)}
        onTextAreaBlur={async (freeTextValue, initialTextAreaValue) => { await handleRadioTextAreaOnBlur(field.id!, value, freeTextValue, initialTextAreaValue) }}
        setFreeTextValue={
          field.isAdditionalTextareaEnabled
            ? (av) => { setFieldValue(field.id!, new FormRadioValue({ item: value?.item, additionalFreeTextValue: av }), av) }
            : undefined}
      />
    </>
  };

  const getSignatureDateTime = () => {
    var newDateObj = new Date(tabDataModel.formSignedAt!);
    const time = newDateObj.toLocaleTimeString("es", { timeStyle: "short" })
    const day = newDateObj.toLocaleString(localeApp, { day: "numeric" });
    let month = newDateObj.toLocaleString(localeApp, { month: "long", });
    const year = newDateObj.toLocaleString(localeApp, { year: "numeric", });

    let result = localeApp === "es"
      ? `${day} de ${month}, ${year} ${time}hrs`
      : `${month.charAt(0).toUpperCase() + month.slice(1)} ${day}, ${year} ${time}hrs`;

    return result;
  };

  const getSignaturePhoneNumber = () => {
    if (tabDataModel.isFormSignedAtEstablishment) {
      return t("Signed at the establishment");
    }
    const template = accountSettings.countries?.find(c => c.iso3 === tabDataModel.phoneNumberIso3Code)!.dialTemplate;
    return conformToMask(tabDataModel.phoneNumberBody, GetMobileMaskByTemp(template)).conformedValue;
  };

  function checkBoxDisplayText(textKey: string, linkKey: string, linkTo: string) {
    return (
      <span
        className={classes.checkBoxText}
      >
        {t(textKey)} <Link className={classes.link} to={linkTo} target="_blank">{t(linkKey)}</Link>
      </span>
    );
  }

  return <>
    <div className={classes.root}>
      <div className={classes.sectionsListContainer}>
        {mapSectionsToComponents()}
        <div className={classes.sectionContainer}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ArrowDownIcon style={{ height: 15, width: 15, color: Color.clear1 }}
              />}>
              <Typography className={classes.sectionLabel}>
                {t("Patient's signature")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.signatureContainer}>
              {tabDataModel.canAcceptPrivacyNotice &&
                <div className={classes.signatureBlock}>
                  <div className={classes.signatureBlockTitle}>{t("Privacy notice")} <span className={classes.asterisk}>*</span></div>
                  <div className={classes.signatureBlockCheckbox}>
                    {tabDataModel.hasAcceptedPrivacyNotice
                      ? <CheckboxCheckedIcon />
                      : <CheckboxUncheckedIcon />
                    }

                    <div style={{width: 250}}>{checkBoxDisplayText("PrivacyNoticeAcceptance", "privacy notice", `${Url.PrivacyNotice}`)}</div>
                  </div>
                </div>
              }
              {tabDataModel.canReadConsentLetter &&
                <div className={classes.signatureBlock}>
                  <div className={classes.signatureBlockTitle}>{t("Informed consent letter")} <span className={classes.asterisk}>*</span></div>
                  <div className={classes.signatureBlockCheckbox}>
                    {tabDataModel.hasReadConsentLetter
                      ? <CheckboxCheckedIcon />
                      : <CheckboxUncheckedIcon />
                    }

                    <div style={{width: 250}}>{checkBoxDisplayText("ConsentLetterAcceptance", "informed consent letter", `${Url.InformedConsentLetter}`)}</div>
                  </div>
                </div>
              }
              <div className={classes.signatureSectionTitle}>{t("Patient's signature")}</div>
              <div className={classes.signatureSectionContainer}>
                <div>
                  <PatientSignature
                    signature={tabDataModel.signature}
                    setSignature={updateSignature}
                    onSave={() => setSaveSignatureModalOpen(true)}
                    enabled={!tabDataModel.isFormSignedAtEstablishment && tabDataModel.hasReadConsentLetter && tabDataModel.hasAcceptedPrivacyNotice}
                  />
                </div>
                <div className={classes.metaInfoContainer}>
                  <div className={classes.metaInfoFieldContainer}>
                    <Typography className={classes.metaInfoFieldTitle}>
                      {t("Signed IP address")}
                    </Typography>
                    <Typography className={classes.metaInfoFieldValue}>
                      {!tabDataModel?.signature || tabDataModel.signature === ""
                        ? "--"
                        : <>
                          {tabDataModel.ip}
                          <RoundCheckmarkIcon style={{ margin: "0px 0px 3px 5px" }} />
                        </>}
                    </Typography>
                  </div>
                  <div className={classes.metaInfoFieldContainer}>
                    <Typography className={classes.metaInfoFieldTitle}>
                      {t("Signature date")}
                    </Typography>
                    <Typography className={classes.metaInfoFieldValue}>
                      {!tabDataModel?.signature || tabDataModel.signature === ""
                        ? "--"
                        : <>
                          {getSignatureDateTime()}
                          <RoundCheckmarkIcon style={{ margin: "0px 0px 3px 5px" }} />
                        </>}
                    </Typography>
                  </div>
                  <div className={classes.metaInfoFieldContainer}>
                    <Typography className={classes.metaInfoFieldTitle}>
                      {t("Mobile")}
                    </Typography>
                    <Typography className={classes.metaInfoFieldValue}>
                      {!tabDataModel?.signature || tabDataModel.signature === ""
                        ? "--"
                        : <>
                          {getSignaturePhoneNumber()}
                          <RoundCheckmarkIcon style={{ margin: "0px 0px 3px 5px" }} />
                        </>}
                    </Typography>
                  </div>
                </div>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className={classes.sectionContainer}>
          <Accordion defaultExpanded={true}>
            <AccordionSummary
              expandIcon={<ArrowDownIcon style={{ height: 15, width: 15, color: Color.clear1 }}
              />}>
              <Typography className={classes.sectionLabel}>
                {t("Observations")}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <div className={classes.fieldsEditionContainer} style={{marginTop: "-14px"}}>
                  <ClinicalHistoryTextInput
                    key={"observations"}
                    value={tabDataModel?.observations ?? ""}
                    setValue={(v) => setTabDataModel({ ...tabDataModel, observations: v })}
                    onBlur={async () => { await updateObservations() }}
                    label={t("Patient's general observations") + ":"}
                    isValid={(_) => true}
                    required={false}
                    placeholder={t("Patient's general observations")}
                    maxLength={1500} />
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
      </div>
      <SaveSignatureModal
        open={saveSignatureModalOpen}
        onConfirm={saveSignature}
        onClose={() => setSaveSignatureModalOpen(false)}
      />
    </div>
  </>
};