export class CustomersClassifications {
  customerFrequencyClassificationFrequentId: string | null;
  customerFrequencyClassificationInfrequentId: string | null;
  customerFrequencyClassificationInactiveId: string | null;

  constructor(x?: any) {
    if (typeof x === "object" && x != null) {
      this.customerFrequencyClassificationFrequentId =
        x.customerFrequencyClassificationFrequentId;
      this.customerFrequencyClassificationInfrequentId =
        x.customerFrequencyClassificationInfrequentId;
      this.customerFrequencyClassificationInactiveId =
        x.customerFrequencyClassificationInactiveId;
    } else {
      this.customerFrequencyClassificationFrequentId = null;
      this.customerFrequencyClassificationInfrequentId = null;
      this.customerFrequencyClassificationInactiveId = null;
    }
  }
}
