import React from 'react';

import { useTranslation } from "react-i18next";

import { InputBase } from "@material-ui/core";

import { ClinicalHistoryTextInputProps } from "./props";
import useStyles from "./css";

import useControlLabel from "../../../../hooks/useControlLabel";
import useInputFirstTouch from "../../../../hooks/useInputFirstTouch";


export default function ClinicalHistoryTextInput(props: ClinicalHistoryTextInputProps) {
  
  const { value, setValue, label, required, placeholder, isValid, disabled, maxLength, key, onBlur } = props;
  
  const classes = useStyles()();
  
  const { t } = useTranslation("general");
  
  function handleOnChange(event: any) {
    setValue(event.target.value);
  }
  
  function isError(): boolean {
    if (!isValid || isValid(value) === undefined) {
      return false;
    }
    return !isValid(value);
  }
  
  const ControlLabel = useControlLabel({ label, required });
  const { hasBeenTouched, handleFirstTouch } = useInputFirstTouch();
  
  return (
    <div className={classes.root}>
      <ControlLabel />
      <InputBase
        key={key}
        className={`${classes.input} ${hasBeenTouched && isError() ? classes.inputError : ""}`}
        placeholder={`${t("Write here your notes for")} ${placeholder}`}
        value={value}
        onChange={handleOnChange}
        onBlur={() => {handleFirstTouch(); onBlur();}}
        disabled={disabled}
        multiline
        rows={3}
        inputProps={{
          maxLength: maxLength
        }}
      />
      <div className={classes.counter}>{`${value?.length}/${maxLength}`}</div>
    </div>
  );
}