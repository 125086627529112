import React from 'react';

import SvgIcon from "@material-ui/core/SvgIcon";


export default function GroupAppointmentsIcon(props: any) {

  const { style, viewBox, color, ...otherProps } = props;

  return (
    <SvgIcon
      {...otherProps}
      style={style || { width: 25, height: 25 }}
      viewBox={viewBox || "0 0 25 25"}
    >
      <path
        d="M13.6018 21.4399V11.4399H21.6018V21.4399H13.6018ZM3.60181 13.4399V3.43994H11.6018V13.4399H3.60181ZM9.60181 11.4399V5.43994H5.60181V11.4399H9.60181ZM3.60181 21.4399V15.4399H11.6018V21.4399H3.60181ZM5.60181 19.4399H9.60181V17.4399H5.60181V19.4399ZM15.6018 19.4399H19.6018V13.4399H15.6018V19.4399ZM13.6018 3.43994H21.6018V9.43994H13.6018V3.43994ZM15.6018 5.43994V7.43994H19.6018V5.43994H15.6018Z"
        fill={color || style?.color || "#899198"}
      />
    </SvgIcon>
  )
}