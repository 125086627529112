import React, { useEffect, useState } from "react";

import { Button, Grid } from "@material-ui/core";
import { TimePicker } from "antd";
import moment from "moment";
import Typography from "@material-ui/core/Typography";
import { useTranslation } from 'react-i18next';

import Switch from "../../../../common/Switch";
import InputWithLabel from "../../../../common/InputWithLabel";
import TextareaWithLabel from "../../../../common/TextareaWithLabel";
import {
  MoneyIcon,
  SuggestedServicesIcon,
  LogoIcon,
  CameraIcon,
  CalendarCancelIcon,
  ArrowDropDownIcon,
  EditIcon,
  ListMarkIcon,
  EyeIcon,
  InfoIcon,
  SectionEditIcon,
  WarningIcon, DocumentIcon,
} from "../../../../../assets/icons";
import ServicesService from "../../../../../api/settings-services-service";
import { EstablishmentInfoService } from "../../../../../api/settings-establishmentinfo-service";
import { EstablishmentInfo, ControlInput, MobileCountry } from "../../../../../models/establishmentinfo";
import { SettingsFieldUpdate, getEnum } from "../../../../../models/enums/settings-field-update";
import Weekdays from '../Weekdays'
import DropImage from "../../../../common/DropImage";
import EstablishmentPhotos from "../EstablishmentPhotos";
import CustomerClassification from "../CustomerClassification";

import InputMobileCode from "../../../../common/InputMobileCode";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { alertsActions } from "../../../../../redux/alerts-slice";
import { useStyles } from "./css";
import useCustomerTerminology from "../../../../../hooks/useCustomerTerminology";
import { navbarActions } from "../../../../../redux/navbar-slice";
import { selectAccountSettings } from "../../../../../redux/store";
import IconTooltip from "../../../../common/IconTooltip";
import Select, { SelectItem } from "../../../../common/Select";
import { AppointmentTimeIntervalGapMinute } from "../../../../../models/enums/appointment-time-interval-gap-minute";
import TimeLimitIcon from "../../../../../assets/icons/TimeLimitIcon";
import { TerminologyType } from "../../../../../models/enums/terminology-type";
import EditTerminologyModal from "../../../../common/EditTerminologyModal";
import useTerminology from "../../../../../hooks/useTerminology";
import HtmlTextArea from "../../../../common/HtmlTextArea";

import { SettingsEnterpriseProps } from "../../../../../models/interfaces/settings-enterprise-props";
import SectionNote from "../../../../establishment-settings/Sections/FormsSettingsTabs/SectionNote";
import { SectionNoteData } from "../../../../../models/enums/section-note-data";

export default (props: SettingsEnterpriseProps) => {

  const { settingsSelectedEstablishmentId } = props;
  const dispatch = useAppDispatch();
  const { enqueueAlert } = alertsActions;

  const { t } = useTranslation(["general"]);
  const classes = useStyles();
  const [establishmentInfo, setEstablishmentInfo] = useState<EstablishmentInfo>(new EstablishmentInfo());
  const [establishmentInfoShadow, setEstablishmentInfoShadow] = useState<EstablishmentInfo>(new EstablishmentInfo());
  const [readOnlyControl, setReadOnlyControl] = useState<ControlInput>(new ControlInput(true));
  const [timeLimitToModifyAppointment, setTimeLimitToModifyAppointment] = useState<moment.Moment>(moment().utcOffset(0).set({
    hour: 1,
    minute: 0,
    second: 0,
    millisecond: 0
  }));
  const [timeLimitPickerIsOpen, setTimeLimitPickerIsOpen] = useState<boolean>(false);
  const [logoURL, setLogoURL] = useState<string>("");
  const [loadComplete, setLoadComplete] = useState<boolean>(false);
  const [smbPhoneNumber, setSmbPhoneNumber] = useState<string>("");
  const [isFiscalIdTerminologyModalOpen, setIsFiscalIdTerminologyModalOpen] = useState<boolean>(false);
  const accountSettings = useAppSelector(selectAccountSettings);

  const terminologyAllowCustomerToCancelAppointment = useCustomerTerminology(t("Allow the customer to cancel appointment"), "customer");
  const terminologyAllowCustomerToScheduleAppointmentToday = useCustomerTerminology(t("Allow the customer to schedule appointments on the current day"), "customer");
  const terminologyAllowCustomerToModifyAppointment = useCustomerTerminology(t("Allow the customer to modify appointment"), "customer");
  const fiscalIdTerminology = useTerminology({ type: TerminologyType.FiscalId });

  const cancellationPoliciesMaxLength: number = 2000;

  const ALPHA_NUMERIC_REGEX = /^[a-zA-Z0-9]+$/;
  const timeGapsSelectItems: SelectItem[] = [
    {
      key: AppointmentTimeIntervalGapMinute.Five,
      value: `5 ${t("min")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.Ten,
      value: `10 ${t("min")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.Fifteen,
      value: `15 ${t("min")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.Twenty,
      value: `20 ${t("min")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.Thirty,
      value: `30 ${t("min")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.Sixty,
      value: `1:00 ${t("hour")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.Ninety,
      value: `1:30 ${t("hours")}`
    },
    {
      key: AppointmentTimeIntervalGapMinute.HundredTwenty,
      value: `2:00 ${t("hours")}`
    }
  ];

  const handleShowPrice = (event: any) => {

    let check = event.target.checked;

    setEstablishmentInfo({
      ...establishmentInfo,
      showPrices: check
    });

    let newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      showPrices: check,
      fieldUpd: SettingsFieldUpdate.ShowPrices
    };

    saveByEnter(newInfo);
    };

    const handleShowSuggestedServices = (event: any) => {

    let check = event.target.checked;

    setEstablishmentInfo({
      ...establishmentInfo,
      showSuggestedServices: check
    });

    let newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      showSuggestedServices: check,
      fieldUpd: SettingsFieldUpdate.ShowSuggestedServices
    };

    saveByEnter(newInfo);
  };

  const handleAllowCancel = (event: any) => {

    const check = event.target.checked;

    setEstablishmentInfo({
      ...establishmentInfo,
      allowCustomerToCancelFromSite: check
    });

    const newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      allowCustomerToCancelFromSite: check,
      fieldUpd: SettingsFieldUpdate.allowCustomerToCancelFromSite
    };

    saveByEnter(newInfo);
  };

  const handleAllowScheduleToday = (event: any) => {

    const check = event.target.checked;

    setEstablishmentInfo({
      ...establishmentInfo,
      allowCustomerToScheduleTodayFromSite: check
    });

    const newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      allowCustomerToScheduleTodayFromSite: check,
      fieldUpd: SettingsFieldUpdate.AllowCustomerToScheduleTodayFromSite
    };

    saveByEnter(newInfo);
  };

  const handleAllowModify = (event: any) => {
    const check = event.target.checked;

    setEstablishmentInfo({
      ...establishmentInfo,
      allowCustomerToModifyFromSite: check
    });

    const newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      allowCustomerToModifyFromSite: check,
      fieldUpd: SettingsFieldUpdate.allowCustomerToModifyFromSite
    };

    saveByEnter(newInfo);
  };

  const handleRequireSelectSpecialistFromSite = (event: any) => {
    const check = event.target.checked;

    setEstablishmentInfo({
      ...establishmentInfo,
      requireSelectSpecialistFromSite: check
    });

    const newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      requireSelectSpecialistFromSite: check,
      fieldUpd: SettingsFieldUpdate.requireSelectSpecialistFromSite
    };

    saveByEnter(newInfo);
  };

  const handleShowAnySpecialistFromSite = (event: any) => {
    const check = event.target.checked;

    setEstablishmentInfo({
      ...establishmentInfo,
      showAnySpecialistFromSite: check
    });

    const newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      showAnySpecialistFromSite: check,
      fieldUpd: SettingsFieldUpdate.showAnySpecialistFromSite
    };

    saveByEnter(newInfo);
  };

  function handleAllowCustomerScheduleSimultaneousServicesChange(event: any) {
    const check = event.target.checked;

    setEstablishmentInfo({
      ...establishmentInfo,
      allowCustomerScheduleSimultaneousServices: check
    });

    const newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      allowCustomerScheduleSimultaneousServices: check,
      fieldUpd: SettingsFieldUpdate.AllowCustomerScheduleSimultaneousServices
    };

    saveByEnter(newInfo);
  }

  const handleChangeScheduleTimeIntervalGapSetting = (event: any) => {
    const newValue = event.target.value;

    setEstablishmentInfo({
      ...establishmentInfo,
      gapBetweenTimeIntervalsFromSite: newValue
    });

    const newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      gapBetweenTimeIntervalsFromSite: newValue,
      fieldUpd: SettingsFieldUpdate.gapBetweenTimeIntervalsFromSite
    };

    saveByEnter(newInfo);
  }

  const clickTextbox = (e: any) => {
    const { name } = e.target;

    setReadOnlyControl({
      ...readOnlyControl,
      [name]: false
    });
  };

  function updateOnBlur(e: any) {
    const { name, value } = e.target;

    setReadOnlyControl({
      ...readOnlyControl,
      [name]: true
    });

    let newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      fieldUpd: getEnum(name)
    };

    return newInfo;
  }

  function onBlur(e: any) {
    const { name, value } = e.target;

    const newInfo = updateOnBlur(e);

    const canSave = handleEmptyRequired(name, value);

    if (canSave)
      saveByEnter(newInfo);
  }

  function onBlurNotRequired(e: any) {
    const newInfo = updateOnBlur(e);

    saveByEnter(newInfo);
  }

  const onKeyPress = (e: any) => {
    if (e.which === 13) {
      e.target.blur();
    }
  };

  const saveByEnter = (newInfo: EstablishmentInfo) => {
    (async () => {
       if (settingsSelectedEstablishmentId)
           newInfo.accountId = settingsSelectedEstablishmentId;
       await EstablishmentInfoService.updateEstablishmentInfo(newInfo);
    })();
  };

  const handleInputChange = (e: any) => {
    const { name, value } = e.target;
    setEstablishmentInfo({
      ...establishmentInfo,
      [name]: value
    });
  };

  function handleCancellationPoliciesSet(newValue: string) {
    setEstablishmentInfo({
      ...establishmentInfo, cancellationPolicies: newValue
    });
  }

  function handleCancellationPoliciesBlur() {
    if (establishmentInfo.cancellationPolicies &&
      establishmentInfo.cancellationPolicies.trim() !== "" &&
      establishmentInfo.cancellationPolicies !== establishmentInfoShadow.cancellationPolicies) {
      const newInfo = { ...establishmentInfo, fieldUpd: SettingsFieldUpdate.CancellationPolicies }
      saveByEnter(newInfo)
    }
  }

  const handleInputChangeAlphaNumeric = (e: any) => {
    const { name, value } = e.target;
    if (value && !ALPHA_NUMERIC_REGEX.test(value)) {
      e.preventDefault();
    } else {
      handleInputChange(e);
    }
  };

  const handleEmptyRequired = (name: string, value: any): boolean => {
    switch (name) {
      case "name": {
        if (value === "") {
          setEstablishmentInfo({
            ...establishmentInfo,
            [name]: establishmentInfoShadow.name
          });
          dispatch(
            enqueueAlert({
              type: "Error",
              title: t("Error"),
              description: t("Enter establishment name is required")
            })
          );
          return false;
        } else if (value === establishmentInfoShadow.name)
          return false;

        return true;
      }
      case "phoneNumber": {
        if (value === "") {
          setEstablishmentInfo({
            ...establishmentInfo,
            mobileCodeIso3: establishmentInfoShadow.mobileCodeIso3,
            phoneNumber: establishmentInfoShadow.phoneNumber
          });
          dispatch(
            enqueueAlert({
              type: "Error",
              title: t("Error"),
              description: t("Capture phone number is required")
            })
          );
          return false;
        } else if (value === establishmentInfoShadow.phoneNumber)
          return false;

        return true;
      }
      case "address": {
        if (value === "") {
          setEstablishmentInfo({
            ...establishmentInfo,
            [name]: establishmentInfoShadow.address
          });
          dispatch(
            enqueueAlert({
              type: "Error",
              title: t("Error"),
              description: t("Capture address is required")
            })
          );
          return false;
        } else if (value === establishmentInfoShadow.address)
          return false;

        return true;
      }
      case "addressReferences": {
        setEstablishmentInfoShadow({
          ...establishmentInfo,
          [name]: value
        });
        if (value === "" && value !== establishmentInfoShadow.addressReferences)
          return true;
        else if (value === establishmentInfoShadow.addressReferences)
          return false;

        return true;
      }
      case "cancellationPolicies": {
        setEstablishmentInfoShadow({
          ...establishmentInfo,
          [name]: value
        });
        if (value === "" && value !== establishmentInfoShadow.cancellationPolicies)
          return true;

        return value !== establishmentInfoShadow.cancellationPolicies;
      }
      default: {
        return false;
      }
    }
  }

  const handleMobileComplete = (codeIso3: string, number: string, valid: boolean) => {

    let newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      fieldUpd: getEnum("phonenumber"),
      mobileCodeIso3: codeIso3,
      mobileCountry: new MobileCountry({ iso3: codeIso3 }),
      phoneNumber: number.toString(),
    };

    setEstablishmentInfo(newInfo);

    if (valid && codeIso3 !== establishmentInfo.mobileCodeIso3)
      saveByEnter(newInfo);
  }

  const handleTimeLimitOnOk = (time: moment.Moment) => {

    const minutes = (time.hour() * 60) + time.minute();

    setTimeLimitToModifyAppointment(time);

    setEstablishmentInfo({
      ...establishmentInfo,
      minuteLimitToModifyAppointmentFromSite: minutes
    });

    let newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      minuteLimitToModifyAppointmentFromSite: minutes,
      fieldUpd: SettingsFieldUpdate.minuteLimitToModifyAppointmentFromSite
    };

    saveByEnter(newInfo);

    setTimeLimitPickerIsOpen(false);
  };

  const getTimePickerTimeLimitPostfixClass = () => {
    const postfix = timeLimitToModifyAppointment.hours() > 1 ? "hrs" : (timeLimitToModifyAppointment.hours() === 1 ? "hr" : "min");
    switch (postfix) {
      case "hrs":
        return classes.selectHrs;
      case "hr":
        return classes.selectHr;
      case "min":
        return classes.selectMin;
    }
  }

  const handleOpenTimepicker = (openclose: boolean) => {
    setTimeLimitPickerIsOpen(openclose);
  };

  const handleBlurMobile = (valid: boolean) => {
    let canSave = true;

    if (!valid) {
      dispatch(
        enqueueAlert({
          type: "Error",
          title: t("Error"),
          description: t("Capture phone number is required")
        })
      );
    } else if (establishmentInfo.phoneNumber === establishmentInfoShadow.phoneNumber)
      canSave = false;

    if (canSave) {
      let newInfo: EstablishmentInfo = {
        ...establishmentInfo,
        fieldUpd: getEnum("phonenumber")
      };
      saveByEnter(newInfo);
    }
  }

  const handleLogoUpload = async (files: any) => {

    if (files && files.length > 0) {

      let data: any = await ServicesService.saveImage(files[0]);
      if (data) {
        let newInfo: EstablishmentInfo = {
          ...establishmentInfo,
          logo: data.fileName,
          fieldUpd: getEnum("logo")
        };

        saveByEnter(newInfo);
      }
    }

  }

  function handleShowCancellationPolicies(event: any) {

    let check = event.target.checked;

    setEstablishmentInfo({
      ...establishmentInfo,
      showCancellationPolicies: check
    });

    let newInfo: EstablishmentInfo = {
      ...establishmentInfo,
      showCancellationPolicies: check,
      fieldUpd: SettingsFieldUpdate.ShowCancellationPolicies
    };

    saveByEnter(newInfo);
  }

  useEffect(() => {
    if (accountSettings.countries.length) {
      let newEstablishmentInfo = establishmentInfo;
      if (newEstablishmentInfo.mobileCountry) {
        const countries = accountSettings.countries;
        let mobileCode = newEstablishmentInfo.mobileCountry?.iso3 ?? "MEX";
        newEstablishmentInfo.mobileCountry.dialDigits = countries.find(x => x.iso3 === mobileCode)!.dialDigits;
        newEstablishmentInfo.mobileCountry.dialCode = countries.find(x => x.iso3 === mobileCode)!.dialCode;
        newEstablishmentInfo.mobileCountry.dialTemplate = countries.find(x => x.iso3 === mobileCode)!.dialTemplate.toString();
      }

      setEstablishmentInfo(newEstablishmentInfo);
    }

  }, [accountSettings.countries])

  useEffect(() => {
    if (!accountSettings.scheduleSimultaneousServicesEnabled) {
      setEstablishmentInfo({
        ...establishmentInfo,
        allowCustomerScheduleSimultaneousServices: false
      });

      const newInfo: EstablishmentInfo = {
        ...establishmentInfo,
        allowCustomerScheduleSimultaneousServices: false,
        fieldUpd: SettingsFieldUpdate.AllowCustomerScheduleSimultaneousServices
      };

      saveByEnter(newInfo);
    }

  }, [accountSettings.scheduleSimultaneousServicesEnabled])

  useEffect(() => {

    (async () => {
      dispatch(navbarActions.setShowLoader(true));
 
      let establishment: EstablishmentInfo = settingsSelectedEstablishmentId
      ? await EstablishmentInfoService.getEstablishmentInfo(settingsSelectedEstablishmentId)
      : await EstablishmentInfoService.getEstablishmentInfo();
      
      const countries = accountSettings.countries;
      let mobileCode = establishment.mobileCountry?.iso3 ?? "MEX";
      if (establishment.mobileCountry && countries.length) {
        establishment.mobileCountry.dialDigits = countries.find(x => x.iso3 === mobileCode)!.dialDigits;
        establishment.mobileCountry.dialCode = countries.find(x => x.iso3 === mobileCode)!.dialCode;
        establishment.mobileCountry.dialTemplate = countries.find(x => x.iso3 === mobileCode)!.dialTemplate.toString();
      }

      setEstablishmentInfoShadow(establishment);
      setTimeLimitToModifyAppointment(moment().utcOffset(0).set({
        hour: 0,
        minute: establishment?.minuteLimitToModifyAppointmentFromSite ?? 0,
        second: 0,
        millisecond: 0
      }))
      setEstablishmentInfo(establishment);
      await getImage(establishment.logo);
      dispatch(navbarActions.setShowLoader(false));
      setLoadComplete(true);
    })();

  }, [settingsSelectedEstablishmentId])

  const getImage = async (fileId: string | null = "") => {

    if (!fileId) {
          setLogoURL("");
          return;
    }

    const data = await ServicesService.getImage(fileId);

    if (data)
      setLogoURL(data.fileURL);
  }

    const DivLogoEmpty: React.FC = () => {
    return (
      <div className={classes.divLogoIcon}>
        <LogoIcon className={classes.logoIconClear} />
      </div>);
    };

  const DivLogoEmptyHover: React.FC = () => {
    return (
      <>
        <div className={classes.divLogoIconHover}>
          <div className={classes.iconCam}>
            <CameraIcon />
          </div>
          <div className={classes.labelChangePhoto}>
            {t("upload photo")}
          </div>
        </div>
      </>);
  };

  const handleCopyLink = () => {
    navigator.clipboard.writeText(`https://sitiodereservas.somosclear.com/${accountSettings.siteForFinalCustomersSmbName}`);
  }

  return (!loadComplete ? <></> :
    <>
        <div>
        {settingsSelectedEstablishmentId && (
        <>
          <div className={classes.header}>
            <Typography className={classes.tabTitle}>
              {t("General")}
            </Typography>
          </div>
          <SectionNote 
            sectionData={SectionNoteData.General}
          />
        </>
        )}
        <div className={classes.title}>
          <Typography className={classes.title}>
            {t("General Settings")}
          </Typography>
        </div>
        <div className={classes.divArea}>
          <div className={classes.titleSection}>
            <Typography className={classes.titleSection}>
              {t("Establishment information")}
            </Typography>
          </div>

          <Grid container spacing={5}>

            <Grid item xs={4}>
              <Typography className={classes.label}>
                {t("Establishment logo")}
              </Typography>

              <span className={classes.optional}>({t("Optional")})</span>
              <br />

              <div className={classes.logoSection}>

                <DropImage
                  classDivUploadImage={classes.divLogo}
                  onChange={handleLogoUpload}
                  imageURL={logoURL}
                  componentShowEmptyHover={<DivLogoEmptyHover />}
                  componentShowEmpty={<DivLogoEmpty />}
                />

                <div style={{ marginTop: 10 }} className={classes.labelSecondary}>
                  {t("Max size")}: <b>800px x 800px</b>
                </div>

              </div>
            </Grid>

            <Grid item xs={8}>
              <EstablishmentPhotos settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}/>
            </Grid>

          </Grid>

          <Grid container spacing={2} direction={"column"} style={{ marginTop: 24 }}>

            <Grid item container spacing={4}>
              <Grid item xs={9}>
                <InputWithLabel
                  name="name" value={establishmentInfo.name}
                  onChange={handleInputChange}
                  onKeyPress={onKeyPress}
                  inputReadOnly={readOnlyControl.name}
                  onClickInput={clickTextbox}
                  onBlurInput={onBlur}
                  width="100%"
                  inputClass={classes.inputClass}
                  label={() => (
                    <>
                      <Typography className={classes.label}>
                        {t("Establishment name")}
                      </Typography>
                      <span className={classes.required}>*</span>
                    </>)}
                  labelClass={classes.label}
                  placeholder={t("Enter the name of the establishment")}
                  maxLength={60}
                />
              </Grid>
              <Grid item xs={3} style={{ alignSelf: "flex-end" }}>
                <InputMobileCode
                  isOptional={false}
                  requiredDotClass={classes.required}
                  widthSelect={"100%"}
                  widthList={250}
                  label={t("WhatsApp")}
                  onChangeComplete={handleMobileComplete}
                  codeIso3={establishmentInfo.mobileCountry?.iso3!}
                  mobile={establishmentInfo.phoneNumber!}
                  onBlur={handleBlurMobile}
                  setSmbPhoneNumber={setSmbPhoneNumber}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={4}>
              <Grid item xs={9}>
                <InputWithLabel
                  name="address" value={establishmentInfo.address}
                  onChange={handleInputChange}
                  onKeyPress={onKeyPress}
                  inputReadOnly={readOnlyControl.address}
                  onClickInput={clickTextbox}
                  onBlurInput={onBlur}
                  width="100%"
                  inputClass={classes.inputClass}
                  label={() => (<>
                    <Typography className={classes.label}>
                      {t("Address")}
                    </Typography>
                    <span className={classes.required}>*</span>
                  </>)}
                  labelClass={classes.label}
                  placeholder={t("Ex. Hidalgo Av. #123")}
                  maxLength={180}
                />
              </Grid>
              <Grid item xs={3}>
                <InputWithLabel
                  name="fiscalId"
                  value={establishmentInfo.fiscalId}
                  onChange={handleInputChangeAlphaNumeric}
                  onKeyPress={onKeyPress}
                  inputReadOnly={readOnlyControl.fiscalId}
                  onClickInput={clickTextbox}
                  onBlurInput={onBlurNotRequired}
                  width="100%"
                  inputClass={classes.inputClass}
                  label={() => (<>
                    <Typography className={classes.label}>
                      {fiscalIdTerminology}
                    </Typography>
                    <IconTooltip
                      icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 1 }} viewBox={"0 0 12 12"} />}
                      tooltipText={t("It is the identification code of the tax service.")}
                      placement={"right"}
                      arrowPlacement={"left"}
                      classNameRoot={classes.iconTooltipRoot}
                    />
                    <Button
                      onClick={(e) => {
                        setIsFiscalIdTerminologyModalOpen(true);
                        e.preventDefault();
                      }}
                      className={classes.terminologyButton}
                    >
                      <SectionEditIcon />
                    </Button>
                    <span className={classes.optionalLabel}>({t("Optional")})</span>
                    <EditTerminologyModal
                      isOpen={isFiscalIdTerminologyModalOpen}
                      setOpen={setIsFiscalIdTerminologyModalOpen}
                      type={TerminologyType.FiscalId}
                      isSingleFieldOnly={true}
                      settingsSelectedEstablishmentId={settingsSelectedEstablishmentId}
                    />
                  </>)}
                  labelClass={classes.label}
                  maxLength={500}
                />
              </Grid>
            </Grid>

            <Grid item container spacing={4}>
              <Grid item xs={9}>
                <TextareaWithLabel
                  name="addressReferences" value={establishmentInfo.addressReferences}
                  onChange={handleInputChange}
                  onKeyPress={onKeyPress}
                  inputReadOnly={readOnlyControl.addressReferences}
                  onClickInput={clickTextbox}
                  onBlurInput={onBlur}
                  width="100%"
                  showCounter={true}
                  inputClass={classes.inputTextareaClass}
                  label={() => (<>
                    <Typography className={classes.label}>
                      {t("Establishment references")}
                    </Typography>
                    <span className={classes.optional}>({t("Optional")})</span>
                  </>)}
                  labelClass={classes.label}
                  placeholder={t("E.g. Gray color premises, located between Avenue A and Avenue B")}
                  maxLength={180}
                />
              </Grid>
              <Grid item xs={3}>
                <Button
                  className={classes.copyLinkButton}
                  onClick={handleCopyLink}
                >
                  {t("Copy Site for appts link")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>

        <div className={classes.title} style={{ marginTop: 30 }}>
          <Typography className={classes.title}>
            {t("Site for appointments")}
          </Typography>
        </div>
        <div className={classes.divArea} style={{ padding: "22px 2px 38px 40px" }}>
          <div className={classes.flexContainer}>
            <div>
              <div className={classes.rowContainer} style={{ gap: 50 }}>
                <div className={classes.selectContainer} style={{ gap: 0 }}>
                  <div className={classes.titleWithTooltipContainer}>
                    <label className={classes.label}>
                      {t("Time slot for scheduling an appointment")}
                    </label>
                    <IconTooltip
                      key={"gapFromSiteTooltip"}
                      classNameRoot={classes.iconTooltipRoot}
                      placement={"right"}
                      arrowPlacement={"left"}
                      icon={<InfoIcon style={{ width: 12, height: 12, marginBottom: 10 }} viewBox={"0 0 12 12"} />}
                      tooltipText={t("Time interval displayed between the options to schedule an appointment")}
                    />
                  </div>
                  <Select
                    key={"gapFromSiteSelect"}
                    width={254}
                    value={establishmentInfo.gapBetweenTimeIntervalsFromSite}
                    onChange={(e) => handleChangeScheduleTimeIntervalGapSetting(e)}
                    colorArrowIcon={"#5C6477"}
                    items={timeGapsSelectItems}
                  />
                </div>
                <div className={classes.selectContainer}>
                  <label className={classes.label}>
                    {t("Time limit to modify appointments before they start")}{" "}
                  </label>
                  <div>
                    <TimePicker
                      onOk={(time) => {
                        handleTimeLimitOnOk(time);
                      }}
                      className={`${classes.selectStyle} ${getTimePickerTimeLimitPostfixClass()}`}
                      placeholder="HH:mm"
                      inputReadOnly={true}
                      showNow={false}
                      disabledHours={() => [23]}
                      value={timeLimitToModifyAppointment}
                      onOpenChange={(e) =>
                        handleOpenTimepicker(e)
                      }
                      format={"HH:mm"}
                    />
                    <ArrowDropDownIcon
                      style={{
                        top: "inherit",
                        right: 25,
                        pointerEvents: "none",
                        color: "#5C6477",
                        position: "relative",
                        transform: timeLimitPickerIsOpen
                          ? "rotate(180deg)"
                          : "rotate(0deg)",
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className={classes.rowContainer}>
                <div className={classes.switch}>
                  <Switch
                    baseClass={classes.switchBase}
                    onChange={handleShowPrice}
                    value={establishmentInfo.showPrices}
                  />
                  <div className={classes.switchLabel}>
                    <div className={classes.iconInCheck}>
                      <MoneyIcon className={classes.iconMoney} style={{ color: "#6462F3" }} viewBox="-5 0 18 18" />
                    </div>
                    <label className={classes.labelH2}>
                      {t("Show prices of my services")}
                    </label>
                  </div>
                </div>
              </div>
              
              <div className={classes.rowContainer}>
                <div className={classes.switch}>
                  <Switch
                    baseClass={classes.switchBase}
                    onChange={handleShowSuggestedServices}
                    value={establishmentInfo.showSuggestedServices}
                  />
                  <div className={classes.switchLabel}>
                    <div className={classes.iconInCheck}>
                      <SuggestedServicesIcon className={classes.iconSuggestedServices} style={{ color: "#6462F3" }} viewBox="-5 0 18 18" />
                    </div>
                    <label className={classes.labelH2}>
                      {t("Show Suggested Services")}
                    </label>
                  </div>
                </div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.switch}>
                  <Switch
                    baseClass={classes.switchBase}
                    onChange={handleAllowCancel}
                    value={establishmentInfo.allowCustomerToCancelFromSite}
                  />
                  <div className={classes.switchLabel}>
                    <div className={classes.iconInCheck}>
                      <CalendarCancelIcon className={classes.iconCalendarCancel} />
                    </div>
                    <Typography className={classes.labelH2}>
                      {terminologyAllowCustomerToCancelAppointment}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.switch}>
                  <Switch
                    baseClass={classes.switchBase}
                    onChange={handleAllowScheduleToday}
                    value={establishmentInfo.allowCustomerToScheduleTodayFromSite}
                  />
                  <div className={classes.switchLabel}>
                    <div className={classes.iconInCheck}>
                      <CalendarCancelIcon className={classes.iconCalendarCancel} />
                    </div>
                    <Typography className={classes.labelH2}>
                      {terminologyAllowCustomerToScheduleAppointmentToday}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.switch}>
                  <Switch
                    baseClass={classes.switchBase}
                    onChange={handleAllowModify}
                    value={establishmentInfo.allowCustomerToModifyFromSite}
                  />
                  <div className={classes.switchLabel}>
                    <div className={classes.iconInCheck}>
                      <EditIcon viewBox={"0 0 24 24"} className={classes.iconEdit} />
                    </div>
                    <Typography className={classes.labelH2}>
                      {terminologyAllowCustomerToModifyAppointment}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.switch}>
                  <Switch
                    baseClass={classes.switchBase}
                    onChange={handleRequireSelectSpecialistFromSite}
                    value={establishmentInfo.requireSelectSpecialistFromSite}
                    disabled={!establishmentInfo.showAnySpecialistFromSite}
                  />
                  <div className={classes.switchLabel}>
                    <div className={classes.iconInCheck}>
                      <ListMarkIcon viewBox={"0 0 24 24"} className={classes.iconListMark} />
                    </div>
                    <Typography className={classes.labelH2}>
                      {t("Make it required to select a Specialist")}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.switch}>
                  <Switch
                    baseClass={classes.switchBase}
                    onChange={handleShowAnySpecialistFromSite}
                    value={establishmentInfo.showAnySpecialistFromSite}
                    disabled={!establishmentInfo.requireSelectSpecialistFromSite}
                  />
                  <div className={classes.switchLabel}>
                    <div className={classes.iconInCheck}>
                      <EyeIcon viewBox={"0 0 24 24"} className={classes.iconEye} />
                    </div>
                    <Typography className={classes.labelH2}>
                      {t("Show Any specialist option")}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.switch}>
                  <Switch
                    baseClass={classes.switchBase}
                    onChange={handleAllowCustomerScheduleSimultaneousServicesChange}
                    disabled={!accountSettings.scheduleSimultaneousServicesEnabled}
                    value={establishmentInfo.allowCustomerScheduleSimultaneousServices}
                  />
                  <div className={classes.switchLabel}>
                    <div className={classes.iconInCheck}>
                      <TimeLimitIcon viewBox={"0 0 24 24"} />
                    </div>
                    <Typography className={classes.labelH2}>
                      {t("Allow the customer to schedule simultaneous services")}
                    </Typography>
                  </div>
                </div>
              </div>

              <div className={classes.rowContainer}>
                <div className={classes.switch}>
                  <Switch
                    baseClass={classes.switchBase}
                    onChange={handleShowCancellationPolicies}
                    value={establishmentInfo.showCancellationPolicies}
                  />
                  <div className={classes.switchLabel}>
                    <div className={classes.iconInCheck}>
                      <DocumentIcon />
                    </div>
                    <label className={classes.labelH2}>
                      {t("Show cancellation policies")}
                    </label>
                  </div>
                </div>
              </div>

              {
                establishmentInfo.showCancellationPolicies &&
                <HtmlTextArea
                  text={establishmentInfo.cancellationPolicies}
                  setText={handleCancellationPoliciesSet}
                  maxLength={cancellationPoliciesMaxLength}
                  onBlur={handleCancellationPoliciesBlur}
                />
              }


            </div>
          </div>
        </div>


        <Weekdays settingsSelectedEstablishmentId={settingsSelectedEstablishmentId} />

        <CustomerClassification settingsSelectedEstablishmentId={settingsSelectedEstablishmentId} />

      </div>

    </>);
};