import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 24,
  },
  required: {
    color: theme.colors.clearMain,
    fontWeight: "bold",
  },
  form: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
  firstElement: {
    marginLeft: 26,
  },
  element: {
    marginTop: 25,
  },
  row: {
    display: "flex",
    alignItems: "center",
    width: "100%",
  },
  deleteButton: {
      marginRight: 10,
      marginTop: 0,
    "&:hover": {
      cursor: "pointer",
    },
  },
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    border: "1px solid red",
    borderRadius: "10px",
    boxShadow: theme.shadows[5],
    padding: "24px",
    width: 464,
    minHeight: 288,
  },
  modalCancelPaper: {
    backgroundColor: theme.palette.background.paper,
    // border: "1px solid red",
    borderRadius: "12px",
    boxShadow: theme.shadows[5],
    padding: "24px",
    width: 472,
    minHeight: 153,
  },
  modalHeader: {
    display: "flex",
    alignItems: "center",
  },
  modalClose: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  closeButton: {
    cursor: "pointer",
    color: "#919CA5",
  },
  modalTitle: {
    width: "100%",
  },
  modalTitleText: {
    fontSize: "18px",
    lineHeight: "22px",
    textAlign: "center",
    fontStyle: "normal",
    fontWeight: "bold",
    color: "#26292B",
  },
  modalContent: {},
  modalCircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    border: "1px solid #F15857",
    width: "50px",
    height: "50px",
  },
  modalCenter: {
    display: "flex",
    justifyContent: "center",
  },
  modalTitleSpace: {
    margin: "24px 0px 8px 0px",
  },
  modalContentText: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "17px",
    textAlign: "center",
    color: "#4A4D51",
  },
  modalActions: {
    display: "flex",
    justifyContent: "flex-end",
    margin: "24px 0px 8px 0px",
  },
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  goBack: {
    marginRight: 16,
    background: "#F6F6F6",
    color: "#ACB7C0",
  },
  deleteContact: {
    background: "#F15857",
    color: "#F6F6F6",
    "&:hover": {
      background: "#F15857",
    },
  },
  separator: {
    height: 0,
    borderTop: "1px solid rgba(201, 205, 211, 0.38)",
    margin: "16px 0px 16px 0px",
  },
  accordionSummary: {
    backgroundColor: "#f6f6f6",
    width: "100%"
  },
  accordionTitle: {
    fontFamily: 'Roboto',
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: 12,
    color: theme.colors.grey5,
    position: "relative",
    top: 5,
    left: 10
  },
}));

export default useStyles;
