import React, { useState } from "react";
import { useHistory } from 'react-router-dom';
import { useTranslation } from "react-i18next";
import Typography from "@material-ui/core/Typography";
import { NotificationType } from "../../../../models/enums/notification-type";
import {
  ArrowDropDownIcon,
  CalendarNotificationIcon,
  CaptionDocumentMediaIcon,
  CaptionImageMediaIcon,
  DollarSignNotificationIcon,
  ScreenNotificationIcon,
  VideoCameraEmptyIcon,
  WhatsAppNotificationIcon
} from "../../../../assets/icons";
import { Url } from "../../../../constants/url";
import { INotificationItemProps } from "./props";
import useStyles from "./css";
import { useAppDispatch, useAppSelector } from "../../../../redux/hooks";
import { schedulerActions } from "../../../../redux/scheduler-slice";
import { AppointmentService } from "../../../../api/appointment-service";
import CustomerRequiresQuoteNotificationIcon from "../../../../assets/icons/CustomerRequiresQuoteNotificationIcon";
import { selectUserInfo } from "../../../../redux/store";
import { ConversationMessageType } from "../../../../models/enums/conversation-message-type";


export default function NotificationItem(props: INotificationItemProps) {

  interface INotificationParams {
    type: NotificationType;
    icon: any;
    headerText: string;
    actionType: string;
    hasServices: boolean;
    hasDropDown: boolean;
    hasFromToDateTime: boolean;
    onClick: () => void;
  }

  const { notification, markAsRead } = props;
  const { id, type, senderId, senderMobile, sender, appointmentId, text,
    fromStartDate, fromEndDate, toStartDate, toEndDate,
    date, read, messageCount, whatsAppInboundMessageType } = notification;

  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  const dispatch = useAppDispatch();
  const { setCurrentDate, setSelectedUsers } = schedulerActions;
  const selectedUsers = useAppSelector((state) => state.scheduler.selectedUsers);
  const userInfo = useAppSelector(selectUserInfo);
  const localeApp = useAppSelector(state => state.applicationInterface.locale);

  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation("general");

  const WhatsAppIcon = <WhatsAppNotificationIcon style={{ color: "#5C6477" }} />
  const CustomerRequiresQuoteIcon = <CustomerRequiresQuoteNotificationIcon style={{ color: "#5C6477" }} />
  const CalendarIcon = <CalendarNotificationIcon style={{ color: "#5C6477" }} />
  const ScreenIcon = <ScreenNotificationIcon style={{ color: "#5C6477" }} />
  const DollarIcon = <DollarSignNotificationIcon style={{ color: "#5C6477" }} />
  const ArrowIcon = <ArrowDropDownIcon style={{ color: "#F6F6F6", width: 7, height: 5 }} />

  const textLengthThreshold = 40;
  const textWithIconLengthThreshold = 36;

  function markRead() {
    if (!read) {
      markAsRead(id);
    }
  }

  async function redirectToAppointmentPage() {
    if (appointmentId === null) return;
    const appointment = await AppointmentService.get(appointmentId);
    const appointmentDate = new Date(appointment.startDate ?? new Date());
    if (selectedUsers && !appointment.services?.map(s => s.userId).some(u => u && selectedUsers.includes(u)))
      dispatch(setSelectedUsers([]));
    dispatch(setCurrentDate(appointmentDate));
    history.push(Url.Appointments.Main, { redirectedAppointmentDate: appointmentDate, redirectedAppointmentId: appointmentId });
  }

  function markReadAndRedirectToWhatsApp() {
    if (!userInfo.hasAccessToWhatsAppConversationsAndNotifications)
      return;
    markRead();
    history.push(Url.WhatsApp.Main + "/" + senderMobile);
  }

  function markReadAndRedirectToAppointments() {
    markRead();
    redirectToAppointmentPage();
  }

  const getParametersByNotificationType = (type: NotificationType): INotificationParams => {
    switch (type) {
      case NotificationType.UnreadMessages:
        return {
          type: type,
          icon: WhatsAppIcon,
          headerText: t("Unread messages"),
          actionType: "",
          hasServices: false,
          hasDropDown: false,
          hasFromToDateTime: false,
          onClick: markReadAndRedirectToWhatsApp
        }
      case NotificationType.CustomerRequiresAttention:
        return {
          type: type,
          icon: WhatsAppIcon,
          headerText: t("Customer requires attention"),
          actionType: "",
          hasServices: false,
          hasDropDown: true,
          hasFromToDateTime: false,
          onClick: markReadAndRedirectToWhatsApp
        }
      case NotificationType.NewAppointment:
        return {
          type: type,
          icon: ScreenIcon,
          headerText: t("New appointment"),
          actionType: t("has made an appointment"),
          hasServices: true,
          hasDropDown: true,
          hasFromToDateTime: false,
          onClick: markReadAndRedirectToAppointments
        }
      case NotificationType.ModifiedAppointment:
        return {
          type: type,
          icon: CalendarIcon,
          headerText: t("Modified appointment"),
          actionType: t("has modified the appointment"),
          hasServices: true,
          hasDropDown: true,
          hasFromToDateTime: true,
          onClick: markReadAndRedirectToAppointments
        }
      case NotificationType.AppointmentConfirmedByCustomer:
        return {
          type: type,
          icon: CalendarIcon,
          headerText: t("Appointment confirmed by customer"),
          actionType: t("has confirmed an appointment"),
          hasServices: true,
          hasDropDown: true,
          hasFromToDateTime: false,
          onClick: markReadAndRedirectToAppointments
        }
      case NotificationType.ProofOfPayment:
        return {
          type: type,
          icon: DollarIcon,
          headerText: t("Proof of payment received"),
          actionType: t("has made a payment"),
          hasServices: true,
          hasDropDown: true,
          hasFromToDateTime: false,
          onClick: markReadAndRedirectToWhatsApp
        }
      case NotificationType.AppointmentModifiedByCustomer:
        return {
          type: type,
          icon: ScreenIcon,
          headerText: t("Appointment modified"),
          actionType: t("has modified an appointment"),
          hasServices: true,
          hasDropDown: true,
          hasFromToDateTime: false,
          onClick: markReadAndRedirectToAppointments
        }
      case NotificationType.AppointmentCancelledByCustomer:
        return {
          type: type,
          icon: ScreenIcon,
          headerText: t("Appointment cancelled"),
          actionType: t("has cancelled an appointment"),
          hasServices: true,
          hasDropDown: true,
          hasFromToDateTime: false,
          onClick: markReadAndRedirectToAppointments
        }
      case NotificationType.CustomerRequiresQuote:
        return {
          type: type,
          icon: CustomerRequiresQuoteIcon,
          headerText: messageCount == 1 ? t("Customer requests estimation") : t("Estimation pending to respond"),
          actionType: "",
          hasServices: false,
          hasDropDown: true,
          hasFromToDateTime: false,
          onClick: markReadAndRedirectToWhatsApp
        }
      default:
        return {
          type: type,
          icon: "",
          headerText: "",
          actionType: "",
          hasServices: false,
          hasDropDown: false,
          hasFromToDateTime: false,
          onClick: () => { }
        }
    }
  }

  const getCaptionImage = (type: ConversationMessageType | null) => {
    switch (type) {
      case ConversationMessageType.Image:
        return <CaptionImageMediaIcon style={{ marginRight: "7px", marginBottom: "2px" }} />;
      case ConversationMessageType.Video:
        return <VideoCameraEmptyIcon style={{ marginRight: "7px", marginBottom: "2px" }} />;
      case ConversationMessageType.Document:
      case ConversationMessageType.Pdf:
        return <CaptionDocumentMediaIcon style={{ marginRight: "7px", marginBottom: "2px" }} />;
      default:
        return <></>;
    }
  }

  const getText = (type: NotificationType): string => {
    let notificationText = text;
    if (whatsAppInboundMessageType) {
      if (whatsAppInboundMessageType === ConversationMessageType.Video) {
        return t("Video")
      }
      else if (whatsAppInboundMessageType === ConversationMessageType.Image) {
        if (notificationText === null) return t("Photo")
        else return tryTrimText(notificationText, textWithIconLengthThreshold);
      }
      if (whatsAppInboundMessageType === ConversationMessageType.Pdf || whatsAppInboundMessageType === ConversationMessageType.Document) {
        if (notificationText === null) return t("Document")
        else return tryTrimText(notificationText, textWithIconLengthThreshold);
      }
      // TODO 1.10.0.1
    }
    if (notificationText === null) return "";
    if (type === NotificationType.ProofOfPayment) return t(notificationText);
    return tryTrimText(notificationText, textLengthThreshold);
  }

  const tryTrimText = (text: string | undefined, maxTextLength: number): string => {
    if (!text) return "";
    if (isExpanded) return text;
    if (text.length <= maxTextLength) return text;
    return text.substring(0, maxTextLength - 5) + "...";
  }

  const notificationParameters = getParametersByNotificationType(notification.type);

  const formatSenderWithPlusSign = (sender: string) => {
    if (!isNaN(+sender)) {
      sender = "+" + sender;
    }
    return sender;
  }

  function handleExpanderClick(e: any) {
    e.stopPropagation();
    setIsExpanded(!isExpanded);
  }
  
  const formatDate = (startDate: Date, endDate: Date): string => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    
    let weekDay = start.toLocaleString(localeApp, {
      weekday: "long",
    });
    
    weekDay = `${weekDay.charAt(0).toUpperCase()}${weekDay.slice(1)}`;
    
    const monthDay = start.toLocaleString(localeApp, {
      day: "2-digit",
      month: "long",
    });
    
    const startTime = start.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    
    const endTime = end.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });
    
    return `${weekDay}, ${monthDay} \u00B7 ${startTime} - ${endTime}hrs`;
  };
  
  const timeDifference = (today: Date, endDate: Date): string => {
    const hours = parseInt(
      `${
        (Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60 * 60)) % 24
      }`
    );
    const minutes = parseInt(
      `${(Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60)) % 60}`
    );
    if (hours > 0 && minutes < 1) {
      return `${hours}hr`;
    } else if (hours < 1 && minutes > 0) {
      return `${minutes}min`;
    } else {
      return `${hours}hr ${minutes}min`;
    }
  };
  
  return (
    <div className={`${classes.root}`} onClick={notificationParameters.onClick}>
      {!read && <div className={classes.unreadDot}></div>}
      <div className={classes.headerRow}>
        <div className={classes.header}>
          <div className={classes.headerIcon}>
            {notificationParameters.icon}
          </div>
          <Typography className={classes.headerText}>{notificationParameters.headerText}</Typography>
        </div>
        <div className={classes.timeContainer}>
          {formatDate}
        </div>
      </div>

      <div className={classes.senderRow}>
        {
          notificationParameters.hasServices
            ? <span className={classes.mainText}>{formatSenderWithPlusSign(sender)}<span className={classes.strongText}> {notificationParameters.actionType}</span></span>
            : <span className={`${classes.mainText} ${classes.strongText}`}>{formatSenderWithPlusSign(sender)}</span>
        }
      </div>
      <div className={classes.textRow}>
        <div className={`${classes.additionalText} ${notificationParameters.hasServices && notificationParameters.type !== NotificationType.ProofOfPayment ? classes.strongText : ""}`}>
          {getCaptionImage(notification.whatsAppInboundMessageType)}{getText(notification.type)}
          {notificationParameters.hasFromToDateTime && isExpanded &&
            <>
              <Typography className={classes.fromToDateTimeRow}>
                <span className={classes.fromToLabel}>{t("From")}:</span>&nbsp;
                {`${formatDate(
                  fromStartDate!,
                  fromEndDate!
                )} (${timeDifference(
                  fromStartDate!,
                  fromEndDate!
                )})`}
              </Typography>
              <Typography className={classes.fromToDateTimeRow}>
                <span className={classes.fromToLabel}>{t("ToA")}:</span>&nbsp;
                {`${formatDate(
                  toStartDate!,
                  toEndDate!
                )} (${timeDifference(
                  toStartDate!,
                  toEndDate!
                )})`}
              </Typography>
            </>
          }
        </div>
        {messageCount > 0 && !read && <div className={classes.messageCounter}>
          {messageCount}
        </div>}
        {
          notificationParameters.hasDropDown &&
          (notificationParameters.hasFromToDateTime ||
          (text?.length ?? false) &&
          (text?.length ?? 0) >
          (whatsAppInboundMessageType && whatsAppInboundMessageType !== ConversationMessageType.Text
            ? textLengthThreshold
            : textWithIconLengthThreshold)) &&
          <div
            className={classes.expander}
            onClick={handleExpanderClick}
          >
            <div
              className={`${isExpanded ? classes.upsideDown : ''}`}
            >
              {ArrowIcon}
            </div>
          </div>
        }
      </div>
    </div>
  );
}