import React, { useEffect, useState } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";

import { AccountService } from "../../../api/account-service";
import { LinkIcon } from "../../../assets/icons";
import { useStyles } from "./css";
import { ChatToolbarProps } from "./props";

const ChatToolbar = (props: ChatToolbarProps) => {
    const classes = useStyles();
    const { t } = useTranslation(["general"]);
    const {
        onCopyLinkClick
    } = props;

    const [siteLink, setSiteLink] = useState<string>("");

    useEffect(() => {
        AccountService.getAccountSubdomain().then(businessNameUrl => {
            setSiteLink("https://sitiodereservas.somosclear.com/" + businessNameUrl);
        });
    }, [siteLink]);

    const CopyToClipboardMemo = React.memo(() => {
        return <CopyToClipboard text={siteLink}>
        <IconButton
            size={"small"}
            disabled={!siteLink}
            onClick={onCopyLinkClick}
        >
            <LinkIcon />
        </IconButton>
    </CopyToClipboard>
    });

    return (
        <div className={classes.toolbarWrapper}>
            <Tooltip arrow={true} placement={"top"} title={`${t("Copy link from my Site for appointments")}`}>
                <CopyToClipboardMemo/>
            </Tooltip>
        </div>
    );
}

export default ChatToolbar;
