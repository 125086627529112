import { ClinicalHistorySection } from "./clinical-history-section";

export class PatientClinicalHistoryTabDataResponse {
  
  canAcceptPrivacyNotice: boolean;
  canReadConsentLetter: boolean;
  hasAcceptedPrivacyNotice: boolean;
  hasReadConsentLetter: boolean;
  signature: string | null;
  ip?: string;
  formSignedAt?: Date;
  isFormSignedAtEstablishment: boolean;
  observations?: string;
  phoneNumberIso3Code: string;
  phoneNumberBody: string
  clinicalHistorySections: ClinicalHistorySection[];

    constructor(x?: any) {
      if (typeof x === "object") {
        this.canAcceptPrivacyNotice = x.canAcceptPrivacyNotice;
        this.canReadConsentLetter = x.canReadConsentLetter;
        this.hasAcceptedPrivacyNotice = x.hasAcceptedPrivacyNotice;
        this.hasReadConsentLetter = x.hasReadConsentLetter;
        this.signature = x.signature;
        this.ip = x.ip;
        this.formSignedAt = x.formSignedAt;
        this.isFormSignedAtEstablishment = x.isFormSignedAtEstablishment;
        this.observations = x.observations;
        this.phoneNumberIso3Code = x.phoneNumberIso3Code;
        this.phoneNumberBody = x.phoneNumberBody;
        this.clinicalHistorySections = x.clinicalHistorySections;
      }
      else {
        this.canAcceptPrivacyNotice = false;
        this.canReadConsentLetter = false;
        this.hasAcceptedPrivacyNotice = false;
        this.hasReadConsentLetter = false;
        this.signature = null;
        this.ip = undefined;
        this.formSignedAt = undefined;
        this.isFormSignedAtEstablishment = false;
        this.observations = undefined;
        this.phoneNumberIso3Code = "";
        this.phoneNumberBody = "";
        this.clinicalHistorySections = [];
      }
    }
  }