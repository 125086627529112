import React, { useEffect, useState } from "react";

import GridList, { OnChangeEvent } from "../../../../common/grid/GridList";
import { TableColumn } from "../../../../common/grid/table-column";
import { useTranslation } from "react-i18next";
import { User } from "../../../../../models/user";
import { UserService } from "../../../../../api/user-service";
import { UserListProps } from "./props";
import { useAppDispatch, useAppSelector } from "../../../../../redux/hooks";
import { navbarActions } from "../../../../../redux/navbar-slice";
import { Button, Typography } from "@material-ui/core";
import { TrashIcon } from "../../../../../assets/icons";
import { Color } from "../../../../../constants/colors";
import ConfirmDeleteModal from "../../../../common/ConfirmDeleteModal";
import useStyles from "./css";
import { alertsActions } from "../../../../../redux/alerts-slice";
import { selectUserInfo } from "../../../../../redux/store";


export default function UserList(props: UserListProps) {
  const classes = useStyles();
  const { t } = useTranslation(["settings"]);
  const dispatch = useAppDispatch();
  const { settingsSelectedEstablishmentId, onRowClick, setTotalUsers, isRefresh } = props;
  const { enqueueAlert } = alertsActions;
  const userInfo = useAppSelector(selectUserInfo);

  const [users, setUsers] = useState<User[]>([]);
  const [totalUsers, setLocalTotalUsers] = useState<number>(0);

  const [page, setPage] = useState<number>(1);
  const pageSize: number = 20;
  const [order, setOrder] = useState<"asc" | "desc">("asc");
  const [orderBy, setOrderBy] = useState<string>("friendlyId");

  const [userToDelete, setUserToDelete] = useState<User | null>(null);
  const [userToDeleteUpcomingAppointmentsCount, setUserToDeleteUpcomingAppointmentsCount] = useState<number>(0);
  const [deleteConfirmationModalIsOpen, setDeleteConfirmationModalIsOpen] = useState<boolean>(false);

  useEffect(() => {
    async function fetchData() {
      await loadData();
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [settingsSelectedEstablishmentId, page, orderBy, order, isRefresh]);

  const loadData = async () => {
    dispatch(navbarActions.setShowLoader(true));
    const userList = await UserService.getUsers(
      page,
      pageSize,
      order,
      orderBy,
      settingsSelectedEstablishmentId
    );
    dispatch(navbarActions.setShowLoader(false));
    setUsers(userList.users || []);
    setLocalTotalUsers(userList.totalUsers || 0);
    setTotalUsers(userList.totalUsers || 0);
  };

  function lpad(value: number, padding: number) {
    var zeroes = new Array(padding + 1).join("0");
    return (zeroes + value).slice(-padding);
  }

  function displayFriendlyId(friendlyId: any) {
    return friendlyId < 1000 ? lpad(friendlyId, 3) : friendlyId
  }

  const columns: TableColumn[] = [
    {
      id: "friendlyId",
      label: t("ID"),
      sorting: true,
      width: 115,
      component: displayFriendlyId,
      headerTextAlign: "center",
      textAlign: "center",
    },
    {
      id: "name",
      label: t("NAME"),
      sorting: true,
      width: 240,
    },
    {
      id: "email",
      label: t("EMAIL"),
      sorting: true,
      width: 240,
    },
    {
      id: "jobPosition",
      label: t("JOBPOSITION"),
      sorting: true,
      width: 160,
      component: (item: any) => <>{t(item)}</>
    },
    {
      id: "actions",
      label: t("ACTIONS"),
      sorting: false,
      completeObject: true,
      component: (user: User) => <Button onClick={async (e) => { e.stopPropagation(); await handleDeleteUserClick(user) }}>
        <TrashIcon style={{ color: Color.gray5, width: 13, height: 17, }} />
      </Button>,
      width: 50,
      visible: userInfo.isAdministrator
    },
  ];

  const handleOnChangeGrid = (event: OnChangeEvent) => {
    setPage(event.page);
    if (event.sorting) {
      setOrder(event.sorting.direction);
      setOrderBy(
        event.sorting.orderBy === "name" ? "firstName" : event.sorting.orderBy
      );
    }
  };

  const handleOnRowClick = (item: User) => {
    onRowClick(item.id);
  };

  async function handleDeleteUserClick(user: User) {
    var userUpcomingAppointmentsCount = await UserService.getUserUpcomingAppointmentsCount(user!.id!, settingsSelectedEstablishmentId);
    setUserToDeleteUpcomingAppointmentsCount(userUpcomingAppointmentsCount)
    setUserToDelete(user);
    setDeleteConfirmationModalIsOpen(true);
  };

  async function handleDeleteUserConfirmClick() {
    const isDeleted = await UserService.deleteUser(userToDelete!.id!, settingsSelectedEstablishmentId)

    if (isDeleted) {
      const usersWithoutDeletedCustomer = [...users].filter(u => u.id !== userToDelete!.id);
      setUsers(usersWithoutDeletedCustomer);
      // refreshCustomers();
      dispatch(
        enqueueAlert({
          type: "Success",
          title: t("Deleted user"),
          description: `${userToDelete?.name} (${userToDelete?.jobPosition})`
        })
      );
    }

    setUserToDelete(null);
    setDeleteConfirmationModalIsOpen(false);
  };

  const DeleteUserModalContent: React.FC = () => {
    return (<div className={classes.deleteModalContentContainer}>
      <Typography className={classes.deleteModalTitleText}>
        {t("Are you sure you want to delete this user?")}
      </Typography>
      <div className={classes.separator} />
      <Typography className={classes.deleteModalContentBoldText}>
        {userToDelete?.name}
      </Typography>
      <Typography className={classes.deleteModalContentText}>
        {t("All information for this user will be deleted.")}
      </Typography>
    </div>);
  };

  const DeleteUserWithAppointmentsModalContent: React.FC = () => {
    return (<div className={classes.deleteModalContentContainer}>
      <Typography className={classes.deleteModalTitleText}>
        {t("There are") + " " + userToDeleteUpcomingAppointmentsCount + " "
          + t("existing appointments that cannot be served if you delete this user.")}
      </Typography>
      <div className={classes.separator} />
      <Typography className={classes.deleteModalContentText}>
        {t("We suggest you change the specialist of these future appointments")}
      </Typography>
    </div>);
  };

  return <>
    <GridList
      columns={columns}
      items={users}
      totalItems={totalUsers}
      pageSize={20}
      onChange={handleOnChangeGrid}
      onRowClick={handleOnRowClick}
    />
    {userToDelete
      ? <ConfirmDeleteModal
        classModal={classes.deleteConfirmationModal}
        open={deleteConfirmationModalIsOpen}
        item={userToDelete}
        componentInfo={userToDeleteUpcomingAppointmentsCount > 0
          ? <DeleteUserWithAppointmentsModalContent />
          : <DeleteUserModalContent />}
        onClose={() => setDeleteConfirmationModalIsOpen(false)}
        onDelete={async () => await handleDeleteUserConfirmClick()}
        textButtonConfirm={t("Delete user")} />
      : <></>}
  </>
}