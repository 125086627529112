import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: 320,
    display: "flex",
    flexDirection: "column",
    gap: 25,
    paddingRight: 25,
    marginBottom: 30
  },
  bodyItemsContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 16,
  },
  itemContainer: {
    display: "flex",
    flexDirection: "column",
    gap: 4
  },
  nameValueContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 6
  },
  statusIconContainer: {
    marginLeft: "auto"
  },
  phoneNumberContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    gap: 4,
    "& svg": {
      width: "20px !important", 
      height: "20px !important"
    }
  },
  appointmentsFirstItemContainer: {
    display: "flex",
    flexDirection: "column",
  },
  appointmentsFirstItemContainerRow: {
    display: "flex",
    flexWrap: "wrap",
    textAlign: "start"
  },
  appointmentsFirstItemContainerItem: {
    width: "50%"
  },
  fieldNameLabel: {
    fontFamily: "Roboto, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "14px",
    color: Color.gray4
  },
  fieldValueLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.gray5
  },
  fieldValueLink: {
    display: "flex",
    alignItems: "center",
    gap: 5
  },
  ageLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.gray5
  },
  phoneNumberCodeLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "14px",
    lineHeight: "17px",
    color: Color.clear2
  },
  appointmentsValueLabel: {
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "22px",
    color: Color.gray5
  },
  fixedHeightLabelWrapper: {
    overflow: "hidden",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis"
  },
  divider: {
    height: 0,
    borderTop: "1px solid " + Color.gray2,
    opacity: "0.5",
  },
  exportToPdfButton: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    gap: 5,

    width: 320,
    height: 32,

    borderRadius: 5,

    backgroundColor: theme.colors.clearMain,
    ...theme.typography.txtBody1,
    fontWeight: 700,
    color: theme.colors.grey1,

    cursor: "pointer",

    "&:hover": {
      background: theme.colors.clearMain,
    },
  },
  exportToPdfIcon: {
    marginRight: 5,
  },
}));

export default useStyles;
