import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

export default function PersonIcon(props: any) {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={style || { width: 24, height: 24, marginRight: 5 }}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path
        d="M12 12c2.21 0 4-1.79 4-4s-1.79-4-4-4-4 1.79-4 4 1.79 4 4 4zm0 2c-2.67 0-8 1.34-8 4v2h16v-2c0-2.66-5.33-4-8-4z"
        fill={style?.color || "#919CA5"}
      />
    </SvgIcon>
  );
};