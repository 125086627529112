import { makeStyles } from "@material-ui/core/styles";
import { TreatmentDocumentFieldSettingsModel } from "../../../../../../../models/treatment-document-field-settings-model";

const useStyles = makeStyles((theme) => ({
    previewContainer: {
        backgroundColor: theme.colors.grey2,
        height: 425,
        width: 446,
        marginLeft: 15,
    },
    commonPage: {
        backgroundColor: theme.colors.white,

        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",

        padding: "1cm",
    },
    halfLetterPage: {
        width: "14cm",
        height: "21.6cm",
        transform: 'scale(0.5)',
        transformOrigin: '34% 2%'
    },
    letterPage: {
        width: "21.6cm",
        height: "27.9cm",
        transform: 'scale(0.39)',
        transformOrigin: '13% 1%'
    },
    legalPage: {
        width: "21.6cm",
        height: "33cm",
        transform: 'scale(0.33)',
        transformOrigin: '16% 1%'
    },
    dividerCommon: {
        borderBottom: "solid",
        borderBottomWidth: 1,
        borderBottomColor: theme.colors.grey2,
    },
    dividerHalfLetter: {
        width: "12cm"
    },
    dividerLetter: {
        width: "19.6cm"
    },
    dividerLegal: {
        width: "19.6cm"
    },
    dividerHeader: {
        marginTop: 1,
        marginBottom: 5,
    },
    specialistNameLabel: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "0.42cm",
        lineHeight: "normal",
        color: theme.colors.grey7,
    },
    dynamicFieldValueLabel: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "0.38cm",
        lineHeight: "normal",
        color: theme.colors.grey7,
    },
    staticFieldNameLabel: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: "0.40cm",
        lineHeight: "normal",
        color: theme.colors.grey7,
    },
    staticFieldValueLabel: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: "0.40cm",
        lineHeight: "normal",
        color: theme.colors.grey7,
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column",
        gap: 5
    },
    flexRow: {
        display: "flex",
        flexDirection: "row",
        gap: 5
    },
    signatureContainer: {
        width: "8.33cm",

        display: "flex",
        flexDirection: "column",
        alignItems: "center",

        alignSelf: "flex-end"
    },
    dividerSignature: {
        width: "8.33cm"
    },
    pageHeader: {
        alignSelf: "flex-start",

        display: "flex",
        flexDirection: "column",
        gap: 5,

        width: "100%",
    },
    headerSpecialistRow: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
    },
    logo: {
        alignSelf: "flex-start",
        objectFit: "contain",
        width: 90,
        height: 90,
    },
    logoSpecialistBlock: {
        display: "flex",
        flexDirection: "row",
        gap: 5,
    },
    specialistInfoBlock: {
        display: "flex",
        flexDirection: "column",
    },
    patientInfoBlock: {
        display: "flex",
        flexDirection: "row",

        justifyContent: "space-between",
    },
    flexDualColumn: {
        display: "flex",
        flexDirection: "row",
        gap: 5
    },
    dualLeftColumn: {
        display: "flex",
        flex: "66%",
        gap: 5,
    },
    dualRightColumn: {
        display: "flex",
        flex: "33%",
        gap: 5,
    },
    pageContent: {
        display: "flex",
        flexDirection: "column",
        gap: 5,
    },
    pageHeaderWithContent: {
        display: "flex",
        flexDirection: "column",
    },
    pageFooter: {
        display: "flex",
        flexDirection: "column",
    }
}));

export default useStyles;