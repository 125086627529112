import { createSlice } from "@reduxjs/toolkit";
import {
  HubConnectionBuilder,
  LogLevel,
  IHttpConnectionOptions,
  HttpTransportType,
  HubConnection,
  HubConnectionState
} from '@microsoft/signalr';
import { ApiClient } from "../api/api-client";
import { LocalStorageKey } from "../constants/local-storage-key";
import { Url } from "../constants/url";

const startSignalRConnection = async (connection: HubConnection) => {
  try {
    await connection.start();
  } catch (err) {
    setTimeout(() => startSignalRConnection(connection), 5000);
  }
};

const initializeConnection = () => {
  const userInfo = ApiClient.getUserInfo();
  const accessToken = localStorage.getItem(LocalStorageKey.AccessToken)

  if (accessToken === null || 
    userInfo.hasAccessToWhatsAppConversationsAndNotifications === null || 
    !userInfo.hasAccessToWhatsAppConversationsAndNotifications) {
    return;
  }

  const connectionOptions: IHttpConnectionOptions = {
    accessTokenFactory: () => {
      return accessToken;
    },
    transport: HttpTransportType.WebSockets,
    skipNegotiation: true,
  }

  const connection = new HubConnectionBuilder()
    .withUrl(process.env.REACT_APP_SERVER_URL +
      Url.SignalRHubs.WhatsAppHub, connectionOptions)
    .withAutomaticReconnect()
    .configureLogging(LogLevel.Information)
    .build();

  connection.serverTimeoutInMilliseconds = 60000;

  startSignalRConnection(connection);
  return connection;
}

const initialState = {
  connection: undefined as undefined | HubConnection
};

export const whatsAppHubSlice = createSlice({
  name: "whatsAppHub",
  initialState: initialState,
  reducers: {
    connect: (state) => {
      if (state.connection === undefined || state.connection.state == HubConnectionState.Disconnected) {
        state.connection = initializeConnection();
      }
    },
    disconnect: (state) => {
      if (state.connection?.state == HubConnectionState.Connected) {
        state.connection.stop();
      }
    }
  }
});

export const { connect, disconnect } = whatsAppHubSlice.actions;

export default whatsAppHubSlice.reducer;