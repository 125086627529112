import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const CheckboxUncheckedIcon = (props: any) => {
  const { viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      viewBox={viewBox || "0 0 24 24"}
    >
      <path d="M17.5 5H6.5C5.65625 5 5 5.6875 5 6.5V17.5C5 18.3438 5.65625 19 6.5 19H17.5C18.3125 19 19 18.3438 19 17.5V6.5C19 5.6875 18.3125 5 17.5 5ZM17.3125 17.5H6.6875C6.5625 17.5 6.5 17.4375 6.5 17.3125V6.6875C6.5 6.59375 6.5625 6.5 6.6875 6.5H17.3125C17.4063 6.5 17.5 6.59375 17.5 6.6875V17.3125C17.5 17.4375 17.4063 17.5 17.3125 17.5Z" fill="#919CA5"/>
    </SvgIcon>
  );
};

export default CheckboxUncheckedIcon;
