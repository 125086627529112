export class IsUserPhoneDuplicatedModel {
  
    isDuplicated: boolean;
    userId: string | null;
    userFirstName: string | null;
    userLastName: string | null;

    constructor(x?: any) {
      if (typeof x === "object") {
        this.isDuplicated = x.isDuplicated;
        this.userId = x.userId;
        this.userFirstName = x.userFirstName;
        this.userLastName = x.userLastName;
      } else {
        this.isDuplicated = false;
        this.userId = null;
        this.userFirstName = null;
        this.userLastName = null;
      }
    }
  }