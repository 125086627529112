import { SectionFieldModel } from "./section-field-model";
import { SectionFieldValueModel } from "./section-field-value-model";

export class FieldAndValue {
  field: SectionFieldModel;
  value: SectionFieldValueModel | null;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.field = x.field;
      this.value = x.value;
    }
    else {
      this.field = new SectionFieldModel();
      this.value = new SectionFieldValueModel();
    }
  }
}