export const Url = {
  Default: "/",
  Authentication: {
    Login: "/login",
    Recovery: "/recovery",
    ResetPassword: "/resetpassword/:email/*",
    Logout: "/logout",
    Join: "/join/:email/*"
  },
  Appointments: {
    Main: "/appointments"
  },
  Customers: {
    Main: "/customers"
  },
  FollowUps: {
    Main: "/followups"
  },
  WhatsApp: {
    Main: "/whatsapp",
    MassiveSendings: "/massive-sendings"
  },
  Reports: {
    Main: "/reports",
    CustomReport: "/custom-report"
  },
  Settings: {
    Main: "/settings",
    CustomerCommunications: "/customer-communications",
    WorkAreas: "/work-areas",
    Services: "/services",
    Users: "/users",
    Payments: "/payments",
    ClinicHistory: "/clinic-history",
    EvolutionNotes: "/evolution-notes",
    PatientForm: "/patient-form",
    Commissions: "/commissions"
  },
  SignalRHubs: {
    CommonHub: "/commonHub",
    WhatsAppHub: "/whatsAppHub"
  },
  EstablishmentSettings: {
    General: "/general",
    ClinicHistory: "/clinic-history",
    EvolutionNotes: "/evolution-notes",
    PatientForm: "/patient-form",
    CustomerCommunications: "/customer-communications",
    WorkAreas: "/work-areas",
    Services: "/services",
    Users: "/users",
    Payments: "/payments",
    Commissions: "/commissions"
  },
  PrivacyNotice: "/privacy-notice",
  InformedConsentLetter: "/informed-consent-letter"
};