import { DateTime, IANAZone } from 'luxon';

export const GetOffsetLabel = (ianaName: string | undefined) => {
    const timeZone = new IANAZone(ianaName ?? '');
    if (!timeZone.isValid) {
        return '(INVALID TIMEZONE)';
    } else {
        const now = DateTime.now();
        const hrsOffset = timeZone.formatOffset(now.toMillis(), 'narrow');

        const timezoneShortName = timeZone.offsetName(now.toMillis(), {
            format: 'short',
            locale: 'en',
        });
        if (timezoneShortName!.startsWith('GMT')) {
            return `(GMT${hrsOffset})`;
        } else {
            return `(GMT${hrsOffset} / ${timezoneShortName})`;
        }
    }
};
