import { makeStyles } from "@material-ui/core/styles";
import { Color } from "../../../constants/colors";

const useStyles = makeStyles((theme) => ({
    root: {
        "& .MuiAccordion-root:before": {
            backgroundColor: "unset"
        },
        "& .MuiAccordionSummary-root": {
            padding: "10px 19px 10px 15px",
            background: "#FAFAFA",
            minHeight: "unset",
            height: 46,
          },
          "& .MuiAccordionSummary-root.Mui-expanded": {
            minHeight: 0,
            margin: "unset"
          },
          "& .MuiAccordionSummary-content": {
            margin: 0,
          },
          "& .MuiAccordion-root.Mui-expanded": {
            margin: "unset"
          }
    },
    customerHistoryPanelContent: {
        overflowY: "scroll",
        maxHeight: "calc(100vh - 152px)",
        borderRadius: "0px 0px 12px 12px",
        "&::-webkit-scrollbar": {
            display: "none",
        },
        scrollbarWidth: "none",
        "-ms-overflow-style": "none",
    },
    customerDetails: {
        width: 779,
        height: 610,
        margin: "0px 0px 0px 36px",
    },
    recurringAppointment: {
        ...theme.typography.txtBody1,
        fontWeight: "bold",
        color: theme.colors.grey5,
        height: 40,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTop: "1px solid #C9CDD361",
        paddingTop: 7,
        marginBottom: 10,
        marginTop: 10,
        width: "90%",
        marginLeft: "auto",
        marginRight: "auto"
    },
    customerDetailsContent: {
        width: 779,
        height: 610,
        borderRadius: "0px 0px 12px 12px",
        border: "1px solid #DBDCDD",
        backgroundColor: "#FFF",
        boxShadow: "none",
    },
    customerHistoryTitle: {
        display: "flex",
        alignItems: "center",
    },
    infoContentRow: {
        display: "flex",
        justifyContent: "space-between",
        margin: "20px 0px 0px 0px",
    },
    contactRow: {
        display: "flex",
        justifyContent: "space-between",
    },
    iconTitle: {
        ...theme.typography.txtBody1,
        fontWeight: "bold",
        color: theme.colors.grey4,
        margin: "0px 0px 0px 10px",
    },
    title: {
        ...theme.typography.txtBody2,
        color: theme.colors.grey4,
        margin: 0,
    },
    required: {
        color: "#6462F3",
    },
    optional: {
        fontWeight: "normal",
    },
    rowContainer: {
        display: "flex",
        justifyContent: "space-between",
        margin: "24px 0px 0px 0px",
    },
    switch: {
        display: "flex",
        alignItems: "center",
    },
    switchLabel: {
        margin: "0px 0px 0px 16px",
    },
    label: {
        display: "flex",
        margin: 0,
        ...theme.typography.txtBody2,
        fontWeight: "bold",
        color: theme.colors.grey5,
    },
    description: {
        margin: 0,
        ...theme.typography.txtBody2,
        color: theme.colors.grey5,
    },
    monthYear: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 14,
        lineHight: 17,
        color: Color.clear1
    },
    appointmentCountInMonth: {
        fontFamily: "Inter, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 14,
        lineHight: 17,
        color: Color.clear1
    },
    accordion: {
        border: "none",
        boxShadow: "none"
    },
    accordionSummary: {
        padding: 0
    },
    accordionDetails: {
        padding: 0
    },
    appointmentsBox: {
        background: "#FFFFFF",
        width: "100%",
        padding: "15px 15px 0px 15px"
    },
    appointmentsGrid: {
        width: "100%",
    },
    appointmentsGridLine: {
        borderBottom: "1px solid #C9CDD3",
        marginTop: 10,
        alignContent: "center",
        opacity: 0.5
    },
    headerAppointments: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 12,
        lineHight: 14,
        display: "flex",
        alignItems: "center",
        color: Color.gray4
    },
    serviceName: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 12,
        lineHeight: "14px",
        display: "flex",
        alignItems: "center",
        color: Color.gray5
    },
    serviceDate: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 12,
        lineHeight: "14px",
        display: "flex",
        alignItems: "center",
        color: Color.gray5
    },
    servicePrice: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 12,
        lineHeight: "14px",
        display: "flex",
        alignItems: "center",
        color: Color.gray5
    },
    serviceStatus: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: "bold",
        fontSize: 12,
        display: "flex",
        alignItems: "center",
        color: "#5C6477",
    },
    ArrowIcon: {
        color: "#6462F3",
        fontSize: 24,
        width: 18,
        // marginLeft: 10,
        height: 15
    },
    divStatus: {
        borderRadius: 5,
        color: "#FFFFFF",
        padding: "5px 10px",
        width: "fit-content",
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 700,
        fontSize: 12,
        lineHeight: "14px",
        marginBottom: 10,
        marginRight: 5,
        display: "inline-block",
        maxHeight: 22
    },
    statusConfirmed: {
        color: "#0BCBE0",
        backgroundColor: "rgba(11, 203, 224, 0.2)"
    },
    statusArrived: {
        color: "#36CE91",
        backgroundColor: "rgba(54, 206, 145, 0.2)"
    },
    statusNotConfirmed: {
        color: "#F15857",
        backgroundColor: "rgba(241, 88, 87, 0.2)",
    },
    statusNotArrived: {
        color: "#F15857",
        backgroundColor: "rgba(241, 88, 87, 0.2)",
    },
    statusCanceled: {
        color: "#F15857",
        backgroundColor: "rgba(241, 88, 87, 0.2)"
    },
    apptDisabledGrid: {
        "& * p": {
            opacity: 0.5
        }
    },
    divNotes: {
        borderRadius: 5,
        color: "#FFFFFF",
        padding: "5px 8px 5px 10px",
        width: 28,
        height: 20,
        cursor: "pointer"
    },
    divNotesNone: {
        color: "#36CE91",
        backgroundColor: "rgba(145, 156, 165, 0.2)"
    },
    divNotesExists: {
        color: "#36CE91",
        backgroundColor: "rgba(100, 98, 243, 0.2)"
    },
    drawerHistoryDetails: {
        color: "#000",
        background: "#fff",
        borderTopLeftRadius: 20,
        borderBottomLeftRadius: 20,
        width: "250px",
    },
    statusPaid: {
        color: "#36CE91",
        backgroundColor: "rgba(54, 206, 145, 0.2)"
    },
    statusUnpaid: {
        color: "#F15857",
        backgroundColor: "rgba(241, 88, 87, 0.2)",
    },
    specialistName: {
        fontFamily: "Roboto, sans-serif",
        fontStyle: "normal",
        fontWeight: 400,
        fontSize: 12,
        lineHeight: "14px",
        display: "flex",
        alignItems: "center",
        color: Color.gray5
    }
}));

export default useStyles;
