import React from 'react';
import SvgIcon from "@material-ui/core/SvgIcon";


export default function DocumentIcon(props: any) {

  const { style, viewBox, color, ...otherProps } = props;

  return (
    <SvgIcon
      {...otherProps}
      style={{ width: 25, height: 25, color: color ?? style?.color ?? "#6462F3", ...style }}
      viewBox={viewBox || "0 0 25 25"}
      fill="none"
    >
      <path
        d="M8.34938 9.55344H15.0494C15.4223 9.50986 15.7035 9.19391 15.7035 8.81844C15.7035 8.44297 15.4223 8.12701 15.0494 8.08344H8.34938C8.06632 8.05036 7.78942 8.18278 7.63746 8.42388C7.48551 8.66497 7.48551 8.9719 7.63746 9.213C7.78942 9.4541 8.06632 9.58651 8.34938 9.55344Z"
        fill={color ?? style?.color ?? "#6462F3"}
      />
      <path
        d="M8.34938 17.5534H13.1294C13.4124 17.5865 13.6893 17.4541 13.8413 17.213C13.9932 16.9719 13.9932 16.665 13.8413 16.4239C13.6893 16.1828 13.4124 16.0504 13.1294 16.0834H8.34938C8.06632 16.0504 7.78942 16.1828 7.63746 16.4239C7.48551 16.665 7.48551 16.9719 7.63746 17.213C7.78942 17.4541 8.06632 17.5865 8.34938 17.5534Z"
        fill={color ?? style?.color ?? "#6462F3"}
      />
      <path
        d="M16.9594 13.5534H8.34938C8.06632 13.5865 7.78942 13.4541 7.63746 13.213C7.48551 12.9719 7.48551 12.665 7.63746 12.4239C7.78942 12.1828 8.06632 12.0504 8.34938 12.0834H16.9594C17.3323 12.127 17.6135 12.443 17.6135 12.8184C17.6135 13.1939 17.3323 13.5099 16.9594 13.5534Z"
        fill={color ?? style?.color ?? "#6462F3"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.1494 22.6134H9.14937C6.50231 22.6079 4.35937 20.4605 4.35938 17.8134V6.81344C4.36488 4.17028 6.50621 2.02894 9.14937 2.02344H16.1494C18.7964 2.02343 20.9439 4.16638 20.9494 6.81344V17.8134C20.9494 19.0865 20.4437 20.3074 19.5435 21.2076C18.6433 22.1077 17.4224 22.6134 16.1494 22.6134ZM9.14937 3.61344C7.38434 3.61893 5.95486 5.04841 5.94937 6.81344V17.8134C5.94937 19.5824 7.38045 21.0179 9.14937 21.0234H16.1494C17.9222 21.0234 19.3594 19.5863 19.3594 17.8134V6.81344C19.3539 5.04451 17.9183 3.61343 16.1494 3.61344H9.14937Z"
        fill={color ?? style?.color ?? "#6462F3"}
      />
    </SvgIcon>
  );
}