import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  modalPaper: {
    backgroundColor: theme.palette.background.paper,
    
    minWidth: 320,
    width: 464,
    
    border: "solid",
    borderColor: theme.colors.clearYellow,
    borderWidth: 1,
    borderRadius: 10,
    
    boxShadow: theme.shadows[5],
    padding: 24,
    
    "&:focus:not(:focus-visible)": {
      outline: "none",
    },
    "&:focus-visible": {
      outline: "none",
      border: "solid",
      borderColor: theme.colors.clearYellow,
      borderWidth: 1,
    },
  },
  modalHeader: {
    display: "flex",
    alignItems: "flex-start",
  },
  modalHeaderContent: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  modalClose: {
    display: "flex",
    justifyContent: "flex-end",
    width: "73%",
  },
  closeButton: {
    cursor: "pointer",
    color: theme.colors.grey4,
    height: 14,
  },
  modalTitle: {
    width: "100%",
  },
  modalTitleText: {
    ...theme.typography.header4,
    fontWeight: 700,
    textAlign: "center",
    
    color: "#26292B",
  },
  modalContent: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  modalCircle: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  
    width: 50,
    height: 50,
    
    borderRadius: "50%",
    border: "solid",
    borderWidth: 1,
    borderColor: theme.colors.clearYellow,
  },
  modalMain: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    
    gap: 3,
  },
  modalTitleSpace: {
    marginTop: 24,
  },
  modalContentText: {
    marginTop: 3,
    
    ...theme.typography.txtBody1,
    fontSize: 12,
    fontWeight: 300,
    
    textAlign: "center",
    
    color: "#4A4D51",
  },
  editAppointmentLink: {
    color: theme.colors.clearMain,
    textDecoration: "underline",
    textDecorationColor: theme.colors.clearMain,
    
    "&:hover": {
      color: theme.colors.clearMain,
      textDecoration: "underline",
      textDecorationColor: theme.colors.clearMain,
    }
  },
  modalActions: {
    display: "flex",
    justifyContent: "center",
    marginTop: 32,
  },
  button: {
    width: 200,
    height: 32,
    borderRadius: 51,
    padding: "11px 16px",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: "Inter, sans-serif",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: 12,
  },
  goBack: {
    marginRight: 16,
    background: theme.colors.grey1,
    color: "#ACB7C0",
  },
  editAppointment: {
    background: theme.colors.clearYellow,
    color: theme.colors.grey1,
    "&:hover": {
      background: theme.colors.clearYellow,
    },
  },
  separator: {
    
    width: 217,
    height: 0,
    
    borderTop: "solid",
    borderTopWidth: 1,
    borderTopColor: "rgba(201, 205, 211, 0.38)",
    margin: "16px 0px 16px 0px",
    
  },
}));

export default useStyles;
