import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";
import { Color } from "../../constants/colors";

export default function SectionDeleteIcon(props: any) {
    const { style, viewBox, ...otherProps } = props;
    return (
        <SvgIcon
            {...otherProps}
            style={{ width: 24, height: 24, ...style }}
            viewBox={viewBox || "0 0 24 24"}
        >
            <path
                d="M6.92857 19.1111C6.92857 20.15 7.76429 21 8.78571 21H16.2143C17.2357 21 18.0714 20.15 18.0714 19.1111V7.77778H6.92857V19.1111ZM19 4.94444H15.75L14.8214 4H10.1786L9.25 4.94444H6V6.83333H19V4.94444Z"
                fill={style?.color || Color.gray4}
            />
        </SvgIcon>
    );
}