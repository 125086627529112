import {UserCommissions} from "./user-commissions";

export class UsersCommissionsResponse {
  usersCommissions: UserCommissions[] | null;
  totalUserCommissions: number | null;

  constructor(x?: any) {
    if (typeof x === "object") {
      this.usersCommissions = x.usersCommissions;
      this.totalUserCommissions = x.totalUserCommissions;
    } else {
      this.usersCommissions = null;
      this.totalUserCommissions = null;
    }
  }
}