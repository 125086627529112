import React, { useEffect, useState } from 'react';

import { PatientFormRadioGroupProps } from "./props";
import useStyles from "./css";
import PatientFormRadio from "../PatientFormRadio";
import useControlLabel from "../../../hooks/useControlLabel";
import PatientFormFreeTextInput from '../PatientFormFreeTextInput';
import { useTranslation } from 'react-i18next';
import { SectionFieldItemModel } from '../../../models/section-field-item-model';


export default function PatientFormRadioGroup(props: PatientFormRadioGroupProps) {

  const { field, value, selectedItem, setSectionFieldItem, setFreeTextValue } = props;
  const sectionTNamespace = "sections";
  const { t } = useTranslation(["general", sectionTNamespace]);
  const classes = useStyles();

  const ControlLabel = useControlLabel({ label: t(field.name, { ns: sectionTNamespace }), required: field.isRequired });

  const [items, setItems] = useState<SectionFieldItemModel[]>(field.items.filter((i: SectionFieldItemModel) => {
    if (field.isDeleted)
      return true;
    else {
      return !i.isDeleted
    }
  }));

  useEffect(() => {
    if (!value || items.some(i => i.id === value.item.fieldItemId)) {
      return;
    }

    const itemsWithOldValue = [...items];
    itemsWithOldValue.unshift(new SectionFieldItemModel({ id: value.item.fieldItemId, name: value.item.value, order: 0 }))
    setItems(itemsWithOldValue);
  }, []);

  function itemAsRenderItem(item: SectionFieldItemModel) {
    return (
      <div>
        <PatientFormRadio
          key={item.name}
          value={t(item.name, { ns: sectionTNamespace })}
          checked={selectedItem !== null && selectedItem.id === item.id}
          onCheck={() => setSectionFieldItem(item)}
        />
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <ControlLabel />
      <div className={classes.radios}>
        {
          items.sort((i1, i2) => i1.order - i2.order).map(itemAsRenderItem)
        }
      </div>
      {
        (value?.additionalFreeTextValue !== "" || field.isAdditionalTextareaEnabled) && setFreeTextValue
          ?
          <div className={classes.freeTextAreaContainer}>
            <PatientFormFreeTextInput
              key={field.id! + "freeTextInput"}
              value={value?.additionalFreeTextValue ?? ""}
              setValue={setFreeTextValue}
              placeholder={`${t("Write here your answer for")} ${t(field.name, { ns: sectionTNamespace })}`}
              maxLength={100}
            />
          </div>
          :
          null
      }
    </div>
  );
}