import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {},
  label: {
    display: "block",
    color: "#4a4d51",
    fontFamily: "Roboto",
    fontSize: 12,
    lineHeight: "14px",
    fontWeight: "bold",
  },
}));

export default useStyles;
