import { makeStyles } from "@material-ui/core/styles";

const useStyles = () =>
makeStyles((theme) => ({
    root:{},
    dateIcon: {
      padding: 0
    },
    datePicker: {
    "& .MuiPopover-paper": {
      width: 202,
      height: 233,
    },
    "& .MuiPickersDay-hidden": {
      opacity: 0.25
    },
    "& .MuiPaper-rounded": {
      borderRadius: 10
    },
    "& .MuiPickersCalendarHeader-switchHeader::after": {
      content: '""',
      display: 'block',
      width: 176,
      borderBottomWidth: 1,
      borderBottom: 'solid',
      borderBottomColor: '#EFEFEF',
      position: 'absolute',
      marginLeft : 10,
      bottom: 0
    },
    "& .MuiPickersCalendarHeader-switchHeader": {
      width: 202,
      height: 40,
      position: 'relative',
    },
    "& .MuiPickersCalendar - transitionContainer": {
      width: 177,
      height: 137,
    },
    "& .MuiPickersCalendarHeader-daysHeader": {
        width: 177,
        margin: 'auto',
        marginBottom: 7
    },
    "& .MuiPickersDay-day": {
      width: 22,
      height: 22,
      margin: 2
    },
    "& .MuiPickersCalendar-transitionContainer": {
      width: 177,
      margin: 'auto'
    },
    "& .MuiPickersStaticWrapper-staticWrapperRoot": {
      width: 202,
      height: 233,
      minWidth: 202
    },
    "& .MuiPickersBasePicker-pickerView": {
      minWidth: 202,
      minHeight: 233
    },
    "& .MuiPickersCalendarHeader-iconButton": {
      backgroundColor: 'initial'
    }
  }
}));

export default useStyles;