import React from "react";
import SvgIcon from "@material-ui/core/SvgIcon";

const SaveIcon = (props: any) => {
  const { style, viewBox, ...otherProps } = props;
  return (
    <SvgIcon
      {...otherProps}
      style={style || { width: 16, height: 16 }}
      viewBox={viewBox || "0 0 16 16"}
    >
      <path d="M13.5312 3.0625L10.9375 0.46875C10.6875 0.21875 10.2188 0 9.875 0H1.5C0.65625 0 0 0.6875 0 1.5V12.5C0 13.3438 0.65625 14 1.5 14H12.5C13.3125 14 14 13.3438 14 12.5V4.125C14 3.78125 13.7812 3.3125 13.5312 3.0625ZM7 12C5.875 12 5 11.125 5 10C5 8.90625 5.875 8 7 8C8.09375 8 9 8.90625 9 10C9 11.125 8.09375 12 7 12ZM10 2.5V5.625C10 5.84375 9.8125 6 9.625 6H2.375C2.15625 6 2 5.84375 2 5.625V2.375C2 2.1875 2.15625 2 2.375 2H9.5C9.59375 2 9.6875 2.0625 9.78125 2.125L9.875 2.21875C9.9375 2.28125 10 2.40625 10 2.5Z" />
    </SvgIcon>
  );
};

export default SaveIcon;
