import { DateTime } from 'luxon';
import { createContext, useContext } from 'react';

export interface IScheduleAppointmentActionsMenuContext {
    open(dateTime: DateTime, userId: string | null, x: number, y: number): void;
}

export const ScheduleAppointmentActionsMenuContext =
    createContext<IScheduleAppointmentActionsMenuContext | null>(null);

export function useScheduleAppointmentActionsMenuContext() {
    const ctx = useContext(ScheduleAppointmentActionsMenuContext);
    if (!ctx) throw new Error('ScheduleAppointmentActionsMenuContext is not available');
    return ctx;
}
