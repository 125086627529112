import React, { useEffect, useState } from "react";

import InputBase from "@material-ui/core/InputBase";

import useStyles from "../Input/css";
import { InputCurrencyProps } from "./props";
import useCurrencyValueTemplate from "../../../hooks/useCurrencyValueTemplate";


export default function InputCurrency(props: InputCurrencyProps) {
  
  const { id, value, setValue, valueTemplate, placeholder, name, isValid, width, inputClass, onChange, onBlur, disabled, readOnly, onClick, maxLength, maxValue, autoFocus, isZeroAllowed } = props;
  
  const maxDigitsLength = maxLength ?? 10;
  const maxDecimals = 2;
  
  const classes = useStyles(width)();

  const templatedValue = useCurrencyValueTemplate(valueTemplate);
  
  const [displayValue, setDisplayValue] = useState<string | null>("");

  const [displayPlaceholder, setDisplayPlaceholder] = useState<string>(placeholder || "0.00");

  useEffect(() => {

    setDisplayPlaceholder(placeholder || templatedValue(0.00));

    if (value !== null && value >= 0) {
      let parsedValue = parseFloat(value.toString());
      setValue(parsedValue);
      setDisplayValue(numberWithCommas(templatedValue(parsedValue)));
    }
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, valueTemplate])


  const isError = (): boolean => {
    if (value && +value === 0 && !isZeroAllowed) return true;
    
    if (isValid === undefined) {
      return displayValue === "";
    }

    if (!isValid) {
      return false;
    }

    return !isValid();
  };

  const handleOnChange = (event: any) => {
    event.preventDefault();

    if (Number.isNaN(+event.target.value)) return;

    const value = event.target.value+"";
    const actualValue = value.replace(",", ".").replace(/[^\d.]/g, "");

    // verify decimals
    const pointIndex = value.indexOf(".");
    const hasDecimals = pointIndex>-1;

    if (hasDecimals) {
      if ((value.length-pointIndex-1) > maxDecimals) return;
    }
    else  {
      if (value.length > maxDigitsLength) return;
    }

    if (maxValue) {
      const value = +event.target.value;
      if (value > maxValue) return;
    }

    setDisplayValue((actualValue)+"");
  };

  const handleOnClick = (event: any) => {
    if (onClick) {
      onClick(event);
    }
  };

  const handleOnFocus = (event: any) => {
    let newDisplay: any;
    if ((value === 0 || Number.isNaN(value)) && !Number.isNaN(+event.target.value))
      newDisplay = "";
    else 
      newDisplay = (value || "")+"";

    setDisplayValue(newDisplay);
  };

  const handleOnBlur = (event: any) => {

    const value = +event.target.value;

    if (value === 0 && isZeroAllowed) {
      setValue(0);
      setDisplayValue(templatedValue(value));
      if (onBlur) onBlur(0);
      return;
    }

    if (displayValue === null || Number.isNaN(value)) return;

    if (displayValue === "" || (+displayValue === 0 && !isZeroAllowed)) {
      setDisplayValue(templatedValue(value));
      return;
    }

    let parsedValue = parseFloat(displayValue);

    if (!Number.isNaN(parsedValue)) {
      const numberFormatted = numberWithCommas(templatedValue(parsedValue));
      setDisplayValue(numberFormatted);
    }
    
    setValue(parsedValue);
    if (onBlur) onBlur(parsedValue);
  };

  const numberWithCommas = (x: string | number) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  return (
    <InputBase
      id={id}
      className={`${inputClass} ${classes.input} ${isError() ? classes.inputError : ""}`}
      classes={{
        focused: classes.inputFocused,
        disabled: classes.inputDisabled,
        input: classes.inputText,
      }}
      value={displayValue}
      name={name}
      placeholder={displayPlaceholder}
      onChange={handleOnChange}
      disabled={disabled ?? false}
      readOnly={readOnly}
      onClick={handleOnClick}
      onFocus={handleOnFocus}
      onBlur={handleOnBlur}
      autoFocus={autoFocus}
      inputProps={{
        maxLength: maxDigitsLength+maxDecimals+1
      }}
      type="text"
    />
  );
}