import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import Typography from "@material-ui/core/Typography";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Button from "@material-ui/core/Button";

import { TrashIcon, CrossIcon } from "../../../assets/icons";

import useStyles from "./css";
import { AppointmentDeleteModalProps } from "./props";
import { useAppSelector } from "../../../redux/hooks";

const AppointmentDeleteModal = (props: AppointmentDeleteModalProps) => {
  const { open, appointment, service, onClose, onDelete } = props;
  const classes = useStyles();
  const { t } = useTranslation(["general"]);
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const localeApp = useAppSelector(state => state.applicationInterface.locale);

  useEffect(() => {
    setDisableButton(false);
  }, [appointment]);

  const date = (startDate: Date, endDate: Date): string => {
    const start = new Date(startDate);
    const end = new Date(endDate);
      
    let weekDay = start.toLocaleString(localeApp, {
      weekday: "long",
    });

    weekDay = `${weekDay.charAt(0).toUpperCase()}${weekDay.slice(1)}`;

    const monthDay = start.toLocaleString(localeApp, {
      day: "2-digit",
      month: "long",
    });

    const startTime = start.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    const endTime = end.toLocaleString(localeApp, {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    });

    return `${weekDay}, ${monthDay} \u00B7 ${startTime} - ${endTime}hrs`;
  };

  const timeDifference = (today: Date, endDate: Date): string => {
    const hours = parseInt(
      `${
        (Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60 * 60)) % 24
      }`
    );
    const minutes = parseInt(
      `${(Math.abs(endDate.getTime() - today.getTime()) / (1000 * 60)) % 60}`
    );
    if (hours > 0 && minutes < 1) {
      return `${hours}hr`;
    } else if (hours < 1 && minutes > 0) {
      return `${minutes}min`;
    } else {
      return `${hours}hr ${minutes}min`;
    }
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className={classes.modal}
      open={open}
      onClose={onClose}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className={classes.modalPaper}>
          <div className={classes.modalHeader}>
            <div className={classes.modalClose}>
              <CrossIcon
                className={classes.closeButton}
                onClick={onClose}
                viewBox="0 0 14 14"
                style={{ width: 14, height: 14 }}
              />
            </div>
            <div className={classes.modalHeaderContent}>
              <div className={classes.modalCircle}>
                <TrashIcon />
              </div>
            </div>
          </div>
          <div className={classes.modalContent}>
            <div
              className={`${classes.modalCenter} ${classes.modalTitleSpace}`}
            >
              <Typography className={classes.modalTitleText}>
                {t("Delete the appointment?")}
              </Typography>
            </div>
            <div className={classes.modalCenter}>
              <div className={classes.separator} style={{ width: 217 }}></div>
            </div>
            <div className={classes.modalCenter}>
              <Typography className={classes.modalContentText}>
                {appointment?.customerName}
                {service ? ` - ${service}` : ""}
              </Typography>
            </div>
            <div className={classes.modalCenter}>
              <Typography className={classes.modalDateText}>
                {`${date(
                  appointment?.startDate.toJSDate(),
                  appointment?.endDate.toJSDate()
                )} (${timeDifference(
                  appointment?.startDate.toJSDate(),
                  appointment?.endDate.toJSDate()
                )})`}
              </Typography>
            </div>
            <div className={classes.modalActions}>
              <Button
                className={`${classes.button} ${classes.goBack}`}
                onClick={onClose}
              >
                {t("Go back")}
              </Button>
              <Button
                disabled={disableButton}
                className={`${classes.button} ${classes.deleteAppointment}`}
                onClick={() => {
                  setDisableButton(true);
                  onDelete(appointment);
                }}
              >
                {t("Delete appointment")}
              </Button>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
};

export default AppointmentDeleteModal;
