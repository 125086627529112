import { makeStyles } from "@material-ui/core/styles";

const useStyles = (width?: number | string) =>
  makeStyles((theme) => ({
    root: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "flex-start",
      alignItems: "flex-start",
      backgroundColor: "#FFFFFF", 
      position: "relative",
    },
    input: {
      backgroundColor: "#FFFFFF",
      height: 40,
      padding: "13px 0px 13px 12px",
      border: "solid 1px rgba(0, 0, 0, 0.1)",
      borderRadius: 5,
      width: "100%",
    },
    inputWidth: {
      width: "90%"
    },
    counter: {
      position: "absolute",
      bottom: 7,
      right: 7,
      fontSize: 12,
      color: "#B5B9C1",
      padding: "5px",
    },
    inputText: {
      ...theme.typography.txtBody2,
      fontWeight: "bold",
      color: theme.colors.grey6,
      "&::placeholder": {
        fontWeight: "normal",
        color: "#676767",
      },
    },
    inputFocused: {
      border: "solid 1px #6462F3",
      padding: "14px 0px 14px 12px",
      width: "auto",
    },
    inputDisabled: {
      color: "#676767",
    }
  }));

export default useStyles;
